import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Checkbox from 'library/common/commonComponents/Checkbox';
import SortIndicator from 'library/common/commonComponents/SortIndicator';

import styles from '../../fileManagerTable.module.scss';

export default function FileManagerTableHeader({
  isAllFilesSelected,
  toggleAllFilesCheckbox,
  sort,
  setSorting,
  groupInfo,
  isStreamFolder,
  isInReceiptFolder,
}) {
  const { t } = useTranslation();

  return (
    <tr>
      <td>
        <div className={styles.titleWrapper}>
          {groupInfo.manageFiles && (
            <div className={styles.checkboxContainer}>
              {!isStreamFolder && (
                <Checkbox
                  isChecked={isAllFilesSelected}
                  onChange={toggleAllFilesCheckbox}
                  className={styles.checkbox}
                />
              )}
            </div>
          )}

          <div
            className={cn(styles.tableHeaderItem, styles.tableHeaderItemSortable)}
            onClick={() => setSorting('fileId')}
          >
            <span className='translate'>{t('FileManager.Table.Name')}</span>
            <SortIndicator sort={sort} name='fileId' />
          </div>
        </div>
      </td>
      {isInReceiptFolder && (
        <td>
          <div className={styles.tableHeaderItem}>
            <span className='translate'>{t('FileManager.Table.PDF')}</span>
          </div>
        </td>
      )}
      {isInReceiptFolder && (
        <td>
          <div className={styles.tableHeaderItem}>
            <span className='translate'>{t('FileManager.Table.Info')}</span>
          </div>
        </td>
      )}
      {!isInReceiptFolder && <td />}
      {!isInReceiptFolder && <td />}
      <td className={cn(styles.textRight, styles.hideOnMobile)}>
        <div
          className={cn(styles.tableHeaderItem, styles.tableHeaderItemSortable)}
          onClick={() => setSorting('byteSize')}
        >
          <span className='translate'>{t('FileManager.Table.Size')}</span>
          <SortIndicator sort={sort} name='byteSize' />
        </div>
      </td>

      <td className={cn(styles.textRight, styles.hideOnMobile)}>
        <div
          className={cn(styles.tableHeaderItem, styles.tableHeaderItemSortable)}
          onClick={() => setSorting('editedAt')}
        >
          <span className='translate'>{t('FileManager.Table.Updated')}</span>
          <SortIndicator sort={sort} name='editedAt' />
        </div>
      </td>

      <td className={styles.textRight}>
        <div className={styles.tableHeaderItem}>
          <span className='translate'>{t('FileManager.Table.Likes')}</span>
          {groupInfo && groupInfo.createCommentStatus && (
            <span className='translate'>{t('FileManager.Table.and Comments')}</span>
          )}
        </div>
      </td>

      <td className={cn(styles.textRight, styles.hideOnMobile)}>
        <div className={styles.tableHeaderItem}>
          <span className='translate'>{t('FileManager.Table.Creator')}</span>
        </div>
      </td>

      <td className={styles.textRight}>
        <div className={styles.tableHeaderItem}>
          <span className='translate'>{t('FileManager.Table.Private')}</span>
        </div>
      </td>

      <td />
    </tr>
  );
}
