import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  bulkEditAdminRoleForKitaUsers,
  bulkEditEmployeeRoleForKitaUsers,
  bulkEditUserRoleForKitaUsers,
} from 'library/api/kita';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import store from 'main/store/configureStore';

import styles from './UserSelectionPopup.module.scss';

const MultiUserSelectionPopup = ({
  isLoading,
  isOpen,
  setOpen,
  userRole,
  activeKita,
  users,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const [usersToChange, setUsersToChange] = useState([]);

  const handleUserChange = user => {
    if (usersToChange.find(item => user.user.id === item.user.id)) {
      setUsersToChange(usersToChange.filter(item => item.user.id !== user.user.id));
    } else {
      setUsersToChange([...usersToChange, user]);
    }
  };

  const closePopup = () => {
    setOpen(false);
  };

  const handleUserRoleChange = async () => {
    const selectedUsers = usersToChange.map(user => user.user.id);
    if (selectedUsers.length === 0) {
      store.dispatch(
        showBottomNotification(t('Select users to change the role'), {
          isWarning: true,
        }),
      );
    }
    if (userRole === 'kita-admin') {
      await bulkEditAdminRoleForKitaUsers(activeKita.kitaId, selectedUsers).then(() => {
        onConfirm();
        store.dispatch(
          showBottomNotification(t('Set Users as Kita Admin successfully'), {
            isFail: false,
          }),
        );
      });
    }
    if (userRole === 'kita-employee') {
      await bulkEditEmployeeRoleForKitaUsers(activeKita.kitaId, selectedUsers).then(() => {
        onConfirm();
        store.dispatch(
          showBottomNotification(t('Set Users as Kita Employee successfully'), {
            isFail: false,
          }),
        );
      });
    }
    if (userRole === 'kita-user') {
      await bulkEditUserRoleForKitaUsers(activeKita.kitaId, selectedUsers).then(() => {
        onConfirm();
        store.dispatch(
          showBottomNotification(t('Set Users as Normal User successfully'), {
            isFail: false,
          }),
        );
      });
    }
  };

  const onSave = async () => {
    await handleUserRoleChange();
    closePopup();
  };

  useEffect(() => {
    setUsersToChange(users);
  }, [users]);

  return (
    <Popup
      isOpened={isOpen}
      closePopup={closePopup}
      header={t('Administration.UserSection.User Role Change')}
      footer={
        <div className={styles.buttonsContainer}>
          <ButtonWithLoader isLoading={isLoading} onClick={onSave}>
            {t('Confirm.Save')}
          </ButtonWithLoader>
          <Button onClick={closePopup}>{t('Confirm.Close')}</Button>
        </div>
      }
    >
      <div className={styles.groupList}>
        {users.map(user => (
          <div
            key={user.user.id}
            className={styles.groupElement}
            onClick={() => handleUserChange(user)}
          >
            <Checkbox
              key={user.user.id}
              isChecked={usersToChange.findIndex(item => item.user.id === user.user.id) > -1}
            />
            <div className={cn('notranslate', styles.avatar)}>
              <div className={styles.avatarBox}>
                <ProfileAvatar image={user} id={user.user.id} />
              </div>
              <div className={styles.avatarText} style={{ minWidth: '100px' }}>
                <div className={styles.groupName}>
                  {user.user.lastName} {user.user.firstName}
                </div>
                {user && user.parent && user.parent.kidsNames && (
                  <div className={styles.groupDesc}>{user.parent.kidsNames.toString()}</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Popup>
  );
};

export default MultiUserSelectionPopup;
