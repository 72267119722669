import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { getLanguages } from 'library/api/user';
import styles from './languageDropdown.module.scss';

export default function LanguageDropdown({
  submitLanguage,
  selectedLanguage,
  chooseLanguageText,
  isLefted,
}) {
  const [langOptions, setLanguageOptions] = useState([]);

  useEffect(() => {
    getLanguages().then(res => {
      const languageArray = res.data;
      const sortedLanguageArray = languageArray.sort((a, b) =>
        a.languageName.localeCompare(b.languageName),
      );
      setLanguageOptions(sortedLanguageArray);
    });
  }, []);

  const changeHandler = lang => {
    const selectedLang = langOptions.find(language => language.langCode === lang.target.value);

    submitLanguage(lang, selectedLang.id);
  };

  return (
    <div className={cn(styles.container, { [styles.containerLefted]: isLefted })}>
      <div className={styles.chooseLanguageText}>{chooseLanguageText}</div>
      <form>
        <select
          value={selectedLanguage}
          name='ChooseLanguage'
          className={styles.chooseLanguage}
          onChange={changeHandler}
        >
          {langOptions.map(lang => (
            <option value={lang.langCode} key={lang.langCode}>
              {lang.languageName}
            </option>
          ))}
        </select>
      </form>
    </div>
  );
}

LanguageDropdown.defaultProps = {
  submitLanguage: () => {},
  selectedLanguage: 'de',
  chooseLanguageText: 'text',
};

LanguageDropdown.propTypes = {
  submitLanguage: PropTypes.func,
  selectedLanguage: PropTypes.string,
  chooseLanguageText: PropTypes.string,
};
