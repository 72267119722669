import React, { useState } from 'react';

import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';

export function useDropdown(Dropdown, cancelAnimation) {
  const [isOpened, setIsOpened] = useState(false);

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsOpened(false),
  });

  function DropdownWrapper({ render, closeOnClick, ...rest }) {
    return (
      <div ref={outsideEventContainerRef}>
        <div
          onClick={
            closeOnClick
              ? () => {
                  if (cancelAnimation) {
                    cancelAnimation(false);
                  }
                  setIsOpened(!isOpened);
                }
              : () => {
                  if (cancelAnimation) {
                    cancelAnimation(false);
                  }
                  setIsOpened(true);
                }
          }
        >
          {render(<Dropdown isOpened={isOpened} {...rest} />)}
        </div>
      </div>
    );
  }

  return {
    isOpened,
    closeDropdown: () => setIsOpened(false),
    DropdownWrapper,
  };
}
