import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';

import styles from './surveyBody.module.scss';

export default function SurveyBody({
  surveyOptions,
  dispatch,
  hideMultipleCheckbox,
  multipleAnswerStatus,
  anonymousVotesStatus,
  anonymousVotesForAllStatus,
  hideResultStatus,
  displayRandomOrderStatus,
  allowUserAddSuggestionStatus,
  isEditing,
  endTimeStatus,
  endTimeDate,
  startTimeStatus,
  startTimeDate,
  setMultipleAnswerStatus,
  setAnonymousVotesStatus,
  setAnonymousVotesForAllStatus,
  setHideResultStatus,
  setDisplayRandomOrderStatus,
  setAllowUserAddSuggestionStatus,
  setEndTimeStatus,
  setEndTimeDate,
  setStartTimeStatus,
  setStartTimeDate,
  reminders,
  setReminders,
}) {
  const { t } = useTranslation();

  const reminderTypeOptions = [
    {
      value: 'days',
      label: t('Calendar.ReminderDaysBefore'),
    },
    {
      value: 'hours',
      label: t('Calendar.ReminderHoursBefore'),
    },
  ];

  const updateReminderSetting = (actionValue) => {
    let newRemindersForSurvey = [...reminders];
    switch (actionValue.type) {
      case 'clearReminders':
        newRemindersForSurvey = [];
        break;
      case 'addReminder':
        newRemindersForSurvey.push({ id: -1, type: 'days', value: 1, });
        break;
      case 'removeReminder':
        newRemindersForSurvey = newRemindersForSurvey.filter((r, i) => i !== actionValue.payload);
        break;
      case 'updateReminderType':
        newRemindersForSurvey = newRemindersForSurvey.map((r, i) => ({
          ...r,
          type: i === actionValue.payload.index ? actionValue.payload.type : r.type,
        }));
        break;
      case 'updateReminderValue':
        newRemindersForSurvey = newRemindersForSurvey.map((r, i) => ({
          ...r,
          value: i === actionValue.payload.index ? actionValue.payload.value : r.value,
        }));
        break;
      default:
        break;
    }
    setReminders(newRemindersForSurvey);
  };

  return (
    <>
      {surveyOptions.map((answer, index) => (
        <div className={styles.optionInput}>
          <Input
            key={answer.id}
            value={answer.optionName}
            placeholder={t('Surveys.Add answer')}
            className={styles.input}
            onChange={e =>
              dispatch({
                type: 'updateAnswerById',
                id: answer.id,
                optionName: e.target.value,
              })
            }
            maxLength={255}
            faIcon={getFaIcon(index, surveyOptions.length)}
            onFaIconClick={getOnFaIconClick(index, answer.id, surveyOptions.length, dispatch)}
          />
          <div className={styles.arrowIcons}>
            <button
              className={styles.arrowIconButton}
              disabled={index === surveyOptions.length - 1}
              onClick={changeOptionOrder(index, answer.id, 'moveDown', dispatch)}
            >
              <i className='fa fa-arrow-down' />
            </button>
            <button
              className={styles.arrowIconButton}
              disabled={index === 0}
              onClick={changeOptionOrder(index, answer.id,'moveUp', dispatch)}
            >
              <i className='fa fa-arrow-up' />
            </button>
          </div>
        </div>
      ))}
      <div className={styles.checkboxes}>
        {!hideMultipleCheckbox && (
          <Checkbox
            name={t('Surveys.Multiple answers per user')}
            isChecked={multipleAnswerStatus}
            onChange={() => setMultipleAnswerStatus(!multipleAnswerStatus)}
          />
        )}
        <Checkbox
          name={t('Surveys.Display answers in random order')}
          isChecked={displayRandomOrderStatus}
          onChange={() => setDisplayRandomOrderStatus(!displayRandomOrderStatus)}
        />
        <Checkbox
          name={t('Surveys.Anonymous votes open for admin')}
          isChecked={anonymousVotesStatus}
          onChange={() => {
            setAnonymousVotesStatus(!anonymousVotesStatus);
            setAnonymousVotesForAllStatus(false);
          }}
        />
        <Checkbox
          name={t('Surveys.Anonymous votes for all')}
          isChecked={anonymousVotesForAllStatus}
          onChange={() => {
            setAnonymousVotesForAllStatus(!anonymousVotesForAllStatus);
            setAnonymousVotesStatus(false);
          }}
        />
        <Checkbox
          name={t('Surveys.Hide results until poll is closed')}
          isChecked={hideResultStatus}
          onChange={() => setHideResultStatus(!hideResultStatus)}
        />
        <Checkbox
          name={t('Surveys.Allow user to add suggestions')}
          isChecked={allowUserAddSuggestionStatus}
          onChange={() => setAllowUserAddSuggestionStatus(!allowUserAddSuggestionStatus)}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Checkbox
            name={t('Surveys.Survey starttime')}
            isDisabled={isEditing}
            isChecked={startTimeStatus}
            onChange={() => setStartTimeStatus(!startTimeStatus)}
          />
          <div style={{ marginTop: '5px' }}>
            <DatePicker
              isDisabled={isEditing}
              selected={startTimeDate}
              onChange={date => {
                const currSelDate = new Date(startTimeDate);
                currSelDate.setMonth(date.getMonth());
                currSelDate.setFullYear(date.getFullYear());
                currSelDate.setDate(date.getDate());
                setStartTimeDate(currSelDate.getTime());
              }}
              langCode='Deutsch'
            />

            <DatePicker
              isDisabled={isEditing}
              selected={startTimeDate}
              onChange={date => {
                const currSelDate = new Date(startTimeDate);
                currSelDate.setHours(date.getHours());
                currSelDate.setMinutes(date.getMinutes());
                setStartTimeDate(currSelDate.getTime());
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Zeit'
              timeFormat='HH:mm'
              langCode='Deutsch'
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Checkbox
            name={t('Surveys.Survey endtime')}
            isDisabled={isEditing}
            isChecked={endTimeStatus}
            onChange={() => {
              if (endTimeStatus) {
                updateReminderSetting({ type: 'clearReminders' });
              }
              setEndTimeStatus(!endTimeStatus);
            }}
          />
          <div style={{ marginTop: '5px' }}>
            <DatePicker
              isDisabled={isEditing}
              selected={endTimeDate}
              onChange={date => {
                const currSelDate = new Date(endTimeDate);
                currSelDate.setMonth(date.getMonth());
                currSelDate.setFullYear(date.getFullYear());
                currSelDate.setDate(date.getDate());
                setEndTimeDate(currSelDate.getTime());
              }}
              langCode='Deutsch'
            />

            <DatePicker
              isDisabled={isEditing}
              selected={endTimeDate}
              onChange={date => {
                const currSelDate = new Date(endTimeDate);
                currSelDate.setHours(date.getHours());
                currSelDate.setMinutes(date.getMinutes());
                setEndTimeDate(currSelDate.getTime());
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Zeit'
              timeFormat='HH:mm'
              langCode='Deutsch'
            />
          </div>
        </div>
        <div className={styles.reminderContainer}>
          {reminders.map((r, i) => {
            return (
              <div className={styles.reminder}>
                <Input
                  disabled={isEditing}
                  value={r.value}
                  onChange={e =>
                    updateReminderSetting({
                      type: 'updateReminderValue',
                      payload: { index: i, value: e.target.value },
                    })
                  }
                  containerStyle={{ width: 60 }}
                  className={styles.reminderValueInput}
                />
                <Select
                  disabled={isEditing}
                  options={reminderTypeOptions}
                  onSelect={option =>
                    updateReminderSetting({
                      type: 'updateReminderType',
                      payload: { index: i, type: option.value },
                    })
                  }
                  selected={reminderTypeOptions.find(o => o.value === r.type)}
                  className={styles.reminderTypeSelect}
                />
                <Button
                  disabled={isEditing}
                  onClick={() => {
                    updateReminderSetting({ type: 'removeReminder', payload: i });
                  }}
                  className={styles.reminderRemoveButton}
                >
                  <i className='fa fa-minus' />
                </Button>
              </div>
            );
          })}
        </div>
        <div>
          {endTimeStatus && !isEditing && (
            <Button onClick={() => updateReminderSetting({ type: 'addReminder' })}>
              {t('Calendar.ReminderAdd')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export function getFaIcon(index, length) {
  return index === length - 1 ? 'fa-plus' : 'fa-trash';
}

export function getOnFaIconClick(index, id, length, dispatch) {
  return () =>
    dispatch({
      type: index === length - 1 ? 'addAnswer' : 'deleteAnswerById',
      id,
    });
}

export function changeOptionOrder(index, id, direction, dispatch) {
  return () =>
    dispatch({
      type: direction === 'moveDown' ? 'moveDown' : 'moveUp',
      index,
      id,
    });
}
