import React, { useState, useEffect, useRef } from 'react';

export default function Overview({ firstName, lastName, selectedPlaces }) {

  return (<>
    <div style={{ textAlign: "left" }}>
      <div style={{ fontWeight: "bold", fontSize: "18px" }}>{firstName}<br /><hr /></div>
      <div style={{ fontWeight: "bold", color: "#4f4e4b" }}>Vorname</div>
      <div style={{ fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}>{lastName}<br /><hr /></div>
      <div style={{ fontWeight: "bold", color: "#4f4e4b" }}>Nachname</div>
      <div style={{ fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}>{selectedPlaces.map(item => {
        return item.name + ", "
      })}<br /><hr /></div>
      <div style={{ fontWeight: "bold", color: "#4f4e4b" }}>Ort</div>
    </div>
  </>);

}