import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { GroupContext } from 'modules/Group/groupModule';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import EditImagePopup from 'library/common/commonComponents/Popups/EditImagePopup';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import useCachedImage from 'library/common/commonHooks/useCachedImage';
import DeleteButton from 'library/common/commonComponents/Buttons/DeleteButton';
import EditButton from 'library/common/commonComponents/Buttons/EditButton';
import useGroupImageUploading from '../../hooks/useGroupImageUploading';

import styles from './groupLogo.module.scss';

export default function GroupLogo({
  groupInfo,
  letter,
  showBottomNotification,
  patchGroupInfo,
  backgroundColor,
}) {
  const { setGroupDataInStore } = useContext(GroupContext);

  const {
    changeImage,
    progress,
    deleteImage,
    isDeletePopupOpened,
    closeDeletePopup,
    onDeleteBtnClick,
    deleteHeaderText,
    deleteBodyText,
    editImage,
    isEditPopupOpened,
    closeEditPopup,
    onEditBtnClick,
    editHeaderText,
  } = useGroupImageUploading({
    showBottomNotification,
    id: groupInfo.groupId,
    patchGroupInfo,
    type: 'logo',
    setGroupDataInStore,
  });
  const user = useSelector(state => state.userReducer);

  const { CachedImage, refreshImage } = useCachedImage({
    originalSrc: groupInfo.logoUrl,
    props: {
      alt: 'logo',
      className: cn(styles.uploadAvatarImage),
    },
  });

  useEffect(() => {
    setTimeout(() => {
      refreshImage();
    }, 300);
  }, [isEditPopupOpened]);

  useEffect(() => {
    refreshImage();
  }, [groupInfo.logoUrl]);

  const [isBounceAnimation, setIsBounceAnimation] = useState(false);
  useEffect(() => {
    setIsBounceAnimation(true);
    setTimeout(() => {
      setIsBounceAnimation(false);
    }, 400);
  }, [groupInfo.logoUrl]);

  const image = {
    unit: '%',
    width: groupInfo.groupLogoWidth,
    height: groupInfo.groupLogoWidth / groupInfo.groupLogoAspect,
    x: groupInfo.groupLogoXCoordinate,
    y: groupInfo.groupLogoYCoordinate,
    aspect: 1,
    url: groupInfo.logoUrl,
  };

  return (
    <div className={styles.logo}>
      <DeletePopup
        isOpened={isDeletePopupOpened}
        closePopup={closeDeletePopup}
        onDeleteBtnClick={onDeleteBtnClick}
        headerText={deleteHeaderText}
        bodyText={deleteBodyText}
      />
      <EditImagePopup
        isOpened={isEditPopupOpened}
        closePopup={closeEditPopup}
        onEditBtnClick={onEditBtnClick}
        headerText={editHeaderText}
        image={image}
        type='logo'
      />
      <div
        className={cn(styles.avatarContainer, 'animated', {
          bounceIn: isBounceAnimation,
        })}
        style={{ backgroundColor }}
      >
        {progress ? (
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              <div className={styles.progressInfo} style={{ width: progress + '%' }} />
            </div>
          </div>
        ) : (
          <div className={styles.uploadAvatarContainer}>
            {user.lastName !== 'Caterer' && groupInfo.groupAdminStatus && (
              <div className={styles.uploadAvatar}>
                <UploadButton onChange={changeImage} allowedExtensions={['image/*']} />
                {groupInfo.logoUrl && (
                  <>
                    <EditButton onClick={editImage} className={styles.uploadAvatarButton} />
                    <DeleteButton onClick={deleteImage} className={styles.uploadAvatarButton} />
                  </>
                )}
              </div>
            )}
            {groupInfo.logoUrl ? (
              <div className={styles.uploadAvatarImageContainer}>{CachedImage}</div>
            ) : (
              letter
            )}
          </div>
        )}
      </div>
    </div>
  );
}
