import React from 'react';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { isMobile } from 'react-device-detect';
import { sanitize } from 'dompurify';

function InputFormContractContainer({ children, ff, user }) {
  if (
    ff.inputType !== 'signatureImage' &&
    ff.inputType !== 'image' &&
    (!ff.labelRight || ff.labelRight.length === 0)
  ) {
    const styleObject = {
      marginLeft: ff.indent ? `${ff.indent}pt` : '10px',
      marginBottom: '1em',
      fontSize: '12pt',
      position: 'relative',
    };
    if (ff.labelLeft && ff.labelLeft !== '') {
      styleObject.display = 'grid';
      
      styleObject.gridTemplateColumns = (!isMobile)?'repeat(auto-fit, minmax(25%, 1fr))':'repeat(auto-fit, minmax(50%, 1fr))';
      styleObject.gridAutoRows = 'minmax(20px, auto)';
      styleObject.placeItems = 'center';
    }

    return (
      <div style={styleObject}>
        {user.currentWatsonLang !== 'de' ? (
          <WithWatsonTranslate
            data={{ text: ff.labelLeft, entityId: ff.id, entityType: 'formEditor' }}
            Component={({ html }) => (
              <span
                style={{ display: 'inline' }}
                dangerouslySetInnerHTML={{ __html: sanitize(html) }}
              />
            )}
          />
        ) : (
          ff.labelLeft
        )}{' '}
        {children}{' '}
        {!isMobile ? <div></div> : null}
        {!isMobile ? <div></div> : null}
      </div>
    );
  } else {
    return (
      <p
        style={{
          marginLeft: `${ff.indent}pt`,
          marginBottom: '1em',
          fontSize: '12pt',
          marginLeft: '10px',
        }}
      >
        {user.currentWatsonLang !== 'de' ? (
          <WithWatsonTranslate
            data={{ text: ff.labelLeft, entityId: ff.id, entityType: 'formEditor' }}
            Component={({ html }) => (
              <span
                style={{ display: 'inline' }}
                dangerouslySetInnerHTML={{ __html: sanitize(html) }}
              />
            )}
          />
        ) : (
          ff.labelLeft
        )}{' '}
        {children}{' '}
        {user.currentWatsonLang !== 'de' ? (
          <WithWatsonTranslate
            data={{ text: ff.labelRight, entityId: ff.id, entityType: 'formEditor' }}
            Component={({ html }) => (
              <span
                style={{ display: 'inline' }}
                dangerouslySetInnerHTML={{ __html: sanitize(html) }}
              />
            )}
          />
        ) : (
          ff.labelRight
        )}
      </p>
    );
  }
}

export default InputFormContractContainer;
