export function isValidDateTaxReceipt(taxDate) {
  const parsedDate = Date.parse(taxDate);
  if (isNaN(parsedDate) === false) {
    const dateObject = new Date(parsedDate);
    const dYear = dateObject.getFullYear();
    if (dYear < 1900 || dYear > 2100) {
      return false;
    }
  } else {
    return false;
  }
  return true;
}

export function convertDateToFolderName(dateToConvert) {
  const parsedDate = Date.parse(dateToConvert);
  if (isNaN(parsedDate) === false) {
    const dateObject = new Date(parsedDate);
    const dYear = dateObject.getFullYear();
    const month = dateObject.toLocaleString('default', { month: 'long' });
    return month + ' ' + dYear;
  }
  return dateToConvert;
}

export function convertDateToLocal(dateToConvert) {
  const parsedDate = Date.parse(dateToConvert);
  if (isNaN(parsedDate) === false) {
    const dateObject = new Date(parsedDate);
    return dateObject.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' });
  }
  return dateToConvert;
}
