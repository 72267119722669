import React from 'react';


export default function DefaultConsentText({ activeKita }) {

  return (<>
    <b>Einwilligungserklärung zur Nutzung der Anwendung</b>
    <br />
    <br />
    <p>
      Die Kita „{activeKita.kitaName}“ in {activeKita.city} stellt den Eltern in Zusammenarbeit
      mit der Link IT isi GmbH aus Nürnberg eine Kita-App „Stramplerbande“ („Anwendung“) zur
      Information der Eltern sowie zur Kommunikation mit Eltern der Einrichtung zur Verfügung.
      Für die Link IT isi GmbH genießt der Schutz Ihrer personenbezogenen Daten höchste
      Priorität. Ihre Daten gehören nicht zum Geschäftsmodell und Ihre Daten werden nur in dem
      für den Zweck unserer Anwendung erforderlichen Maße verarbeitet. Dabei halten wir uns
      stets an gesetzliche Grundlagen – insbesondere an die Anforderungen der
      Datenschutzgrundverordnung.
    </p>
    <p>
      In dieser Einwilligungserklärung informieren wir Sie über die Verarbeitung Ihrer
      personenbezogenen Daten und über Ihre Rechte im Rahmen des Online-Angebots. Die Kita-App
      wird in zertifizierten deutschen Rechenzentren betrieben, welche redundant ausgelegt sind
      und somit für eine hohe Stabilität und Sicherheit sorgen.
    </p>
    <br />

    <br />
    <p>
      Um Ihnen diesen Dienst anbieten zu können, benötigen wir allerdings Ihre
      Einwilligungserklärung sowie die auf der nachfolgenden Seite genannten personenbezogene
      Daten. Wir bitten Sie, die Daten zu vervollständigen und die Seite unterschrieben an uns
      zurückzusenden.
    </p>
    <br />
    <b>Informationspflichten</b>
    <br />
    <b>Erhebung von Daten bei der betroffenen Person § 17 DSG-EKD</b>
    <br />
    <br />
    <p>
      <b>1. Bezeichnung der Verarbeitungstätigkeit</b>
    </p>
    <br />
    <p>
      <b>Stramplerbande</b>
    </p>
    <br />
    <p>
      <b>2. Name und Kontaktdaten des Verantwortlichen</b>
    </p>
    <br />
    <p>
      <b>Verantwortliche Stelle für die Datenerhebung:</b>
    </p>
    <br />
    <table>
      <tr>
        <td>
          <b>Name der Einrichtung:</b>
        </td>
        <td>{activeKita.kitaName}</td>
      </tr>
      <tr>
        <td>
          <b>Ansprechpartner:</b>
        </td>
        <td>{activeKita.kitaChiefName}</td>
      </tr>
      <tr>
        <td>
          <b>Adresse der Einrichtung:</b>
        </td>
        <td>{`${activeKita.streetName} ${activeKita.houseNo}`}</td>
      </tr>
      <tr>
        <td />
        <td>{`${activeKita.zipCode} ${activeKita.city}`}</td>
      </tr>
      <tr>
        <td>
          <b>Telefon:</b>
        </td>
        <td>{activeKita.phoneNo}</td>
      </tr>
      <tr>
        <td>
          <b>E-Mail:</b>
        </td>
        <td>{activeKita.email}</td>
      </tr>
    </table>
    <br />
    <br />
    <p>
      <b>3. Kontaktdaten des Datenschutzbeauftragten</b>
    </p>
    <br />
    <table>
      <tr>
        <td>
          <b>Name:</b>
        </td>
        <td>{activeKita.dataProcessingName}</td>
      </tr>
      <tr>
        <td>
          <b>Unternehmen:</b>
        </td>
        <td>{activeKita.dataProcessingCompany}</td>
      </tr>
      <tr>
        <td>
          <b>Telefon:</b>
        </td>
        <td>{activeKita.dataProcessingPhone}</td>
      </tr>
      <tr>
        <td>
          <b>E-Mail:</b>
        </td>
        <td>{activeKita.dataProcessingEmail}</td>
      </tr>
    </table>
    <br />
    <br />
    <p>
      <b>4. Zwecke und Rechtsgrundlagen der Datenverarbeitung</b>
    </p>
    <br />
    <p>
      Wir verarbeiten personenbezogene Daten unter Beachtung der Bestimmungen der
      EU-Datenschutz- Grundverordnung (DSGVO), DSG-EKD, des Bayerischen Datenschutzgesetzes
      sowie aller weiteren maßgeblichen Gesetze.
    </p>
    <br />
    <p>
      Die Verarbeitung personenbezogener Daten erfolgt auf Basis Ihrer erteilten
      Einwilligungserklärung und dient ausschließlich dem Zweck der oben benannten
      Verarbeitungstätigkeit.
    </p>
    <br />
    <p>
      Sämtliche in die Plattform eingestellten Inhalte werden auf freiwilliger Basis und
      entsprechend der ergänzenden Nutzungshinweise eingestellt.
    </p>
    <br />
    <p>
      Die Rechtsgrundlage der Verarbeitung Ihrer personenbezogenen Daten richtet sich nach § 6
      Nr. 2 DSG-EKD.
    </p>
    <br />
    <p>
      <b>5. Empfänger oder Kategorien von Empfängern der personenbezogenen Daten</b>
    </p>
    <br />
    <p>
      Personenbezogene Daten sowie weitere in die Plattform eingestellte Inhalte werden seitens
      der verantwortlichen Stelle in keiner Weise kommerziell genutzt. Eine Weitergabe erfolgt
      nur im Sinne der Bereitstellung des genutzten Funktionsumfangs.
    </p>
    <br />
    <p>
      <b>6. Übermittlung von personenbezogenen Daten an ein Drittland</b>
    </p>
    <br />
    <p>
      Eine Übermittlung der durch uns erhobenen personenbezogenen Daten an ein Drittland erfolgt
      nicht.
    </p>
    <br />
    <p>
      <b>7. Dauer der Speicherung der personenbezogenen Daten</b>
    </p>
    <br />
    <p>
      Die mit Ihrer Einwilligungserklärung erhobenen personenbezogenen Daten werden mit Widerruf
      Ihrer Einwilligung bzw. mit Beendigung der Nutzung des Dienstes gelöscht. Sobald die
      Einrichtung dauerhaft verlassen wird, werden die Daten des Nutzers sowie der Account des
      Nutzers gelöscht. Inhaltsdaten werden nach 2 Jahren automatisiert gelöscht.
    </p>
    <br />
    <p>
      <b>8. Betroffenenrechte</b>
    </p>
    <br />
    <p>Nach der Datenschutz-Grundverordnung und dem DSG-EKD stehen Ihnen folgende Rechte zu:</p>
    <br />
    <ul>
      <li>
        Werden Ihre personenbezogenen Daten verarbeitet, so besteht das Recht, Auskunft über die
        zu Ihrer Person gespeicherten Daten zu erhalten (§ 19 DSG-EKD).
      </li>
      <li>
        Sollten unrichtige personenbezogene Daten verarbeitet werden, steht das Recht auf
        Berichtigung zu (§ 20 DSG-EKD).
      </li>
      <li>
        Liegen die gesetzlichen Voraussetzungen vor, so kann die Löschung oder Einschränkung der
        Verarbeitung verlangt sowie Widerspruch gegen die Verarbeitung eingelegt werden (§ 21,
        22 und 25 DSG-EKD).
      </li>
      <li>
        Mit Einwilligung in die Datenverarbeitung oder sofern ein Vertrag zur Datenverarbeitung
        besteht und die Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird,
        steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu (Art. 24 DSG-EKD).
      </li>
      <li>
        Sollten Sie von oben genannten Rechten Gebrauch machen, prüft die Einrichtung, ob die
        gesetzlichen Voraussetzungen hierfür erfüllt sind.
      </li>
      <li>
        Jede betroffene Person kann sich gemäß § 46 Abs. 1 DSG‑EKD unbeschadet weiterer
        Rechtsbehelfe mit einer Beschwerde an die zuständige Aufsichtsbehörde wenden, wenn sie
        der Ansicht ist, bei der Erhebung, Verarbeitung oder Nutzung ihrer personenbezogenen
        Daten durch kirchliche Stellen in ihren Rechten verletzt worden zu sein. Den zuständigen
        Beauftragten für den Datenschutz der EKD finden Sie unter{' '}
        <a href='www.datenschutz.ekd.de'>www.datenschutz.ekd.de</a>.
      </li>
    </ul>
    <br />
    <p>
      <b>9. Widerrufsrecht bei Einwilligung</b>
    </p>
    <br />
    <p>
      Die Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten durch eine entsprechende
      Erklärung kann jederzeit für die Zukunft widerrufen werden. Gleiches gilt für freiwillig
      zur Verfügung gestellte personenbezogene Daten. Die Rechtmäßigkeit der bis zum Widerruf
      erfolgten Datenverarbeitung aufgrund der Einwilligung oder freiwilligen Angabe, wird durch
      diesen Einspruch nicht berührt.
    </p>
    <br />
    <p>
      Mit Widerruf der Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten können ggf.
      Leistungen, für die wir diese Daten benötigen, nicht durchgeführt werden. Den Widerruf
      Ihrer Einwilligung richten Sie bitte schriftlich an o.g. Postadresse.
    </p>
    <br />
    <p>
      <b>10. Pflicht zur Bereitstellung der Daten</b>
    </p>
    <br />
    <p>
      Ohne Angabe Ihrer personenbezogenen Daten kann die Nutzung der Anwendung nicht angeboten
      werden.
    </p>
    <br />
    <p>
      <b>Ergänzende Nutzungshinweise</b>
    </p>
    <br />
    <p>
      <b>
        Allgemeine Pflichten des Nutzers und besondere Bestimmungen für die Nutzung der
        Anwendung
      </b>
    </p>
    <br />
    <p>
      Der Nutzer ist verpflichtet, bei der Nutzung der Anwendung sowie der Inhalte geltendes
      Recht sowie alle Rechte Dritter zu beachten. Es ist dem Nutzer insbesondere untersagt:
    </p>
    <br />
    <p>
      <b>1. Bild-, Ton- und Videodateien</b>
    </p>
    <br />
    <p>
      Bild-, Ton- und Videodateien, welche auf der Anwendung veröffentlicht werden, dürfen nur
      zum eigenen Gebrauch innerhalb der Anwendung angesehen werden. Sie dürfen nicht zur
      dauerhaften Speicherung heruntergeladen, ausgedruckt oder veröffentlicht werden. Eltern,
      die Dateien unbefugt herunterladen, drucken oder veröffentlichen, müssen mit zivil- und
      evtl. auch strafrechtlichen Konsequenzen rechnen. Die Einrichtung übernimmt keine Haftung
      für missbräuchlichen Gebrauch zur Verbreitung der vorgenannten Inhalte.
    </p>
    <br />
    <p>
      <b>2. Nicht erlaubte Inhalte</b>
    </p>
    <br />
    <p>
      Verwendung beleidigender oder verleumderischer Inhalte, unabhängig davon, ob diese Inhalte
      andere Nutzer, Mitarbeitende der Link IT isi GmbH oder andere Personen oder Unternehmen
      betreffen.
    </p>
    <br />
    <p>
      Verwendung pornografischer, gewaltverherrlichender, missbräuchlicher, sittenwidriger oder
      Jugendschutzgesetze verletzende Inhalte oder Bewerbung, Angebot und/oder Vertrieb von
      pornografischen, gewaltverherrlichenden, missbräuchlichen, sittenwidrigen oder
      Jugendschutzgesetze verletzende Waren oder Dienstleistungen.
    </p>
    <br />
    <p>
      <b>3. Geschützte Inhalte</b>
    </p>
    <br />
    <p>
      Verwendung von gesetzlich (z. B. durch das Urheber-, Marken-, Patent-, Geschmacksmuster-
      oder Gebrauchsmusterrecht) geschützten Inhalten, ohne dazu berechtigt zu sein.
    </p>
    <br />
    <p>
      <b>4. Erziehungsberechtigte Personen</b>
    </p>
    <br />
    <p>
      Änderungen bezüglich erziehungsberechtigter Personen sind unmittelbar an die Leitung der
      Kindertagespflege zu kommunizieren, insbesondere wenn damit die Sperre eines Accounts
      einhergeht.
    </p>
    <br />
    <p>
      <b>5. Keine Weitergabe an Dritte</b>
    </p>
    <br />
    <p>
      Der Account (Benutzername, E-Mail-Adresse, KiTa-Code) für den Zugang zur Anwendung darf
      unter keinen Umständen an Dritte Personen weitergegeben werden.
    </p>
    <br />
    <p>
      <b>Folgen bei missbräuchlichem Gebrauch der Anwendung</b>
    </p>
    <br />
    <p>
      Der Account von Nutzern kann unmittelbar und ohne Angabe von Gründen durch den
      Gruppenadministrator oder Mitarbeiter der Link IT isi GmbH gesperrt werden. Dies gilt
      insbesondere bei missbräuchlichem Gebrauch der Anwendung.
    </p>
    <br />
    <br />
  </>)
}