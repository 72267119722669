import { useState } from 'react';

import styles from './styles/useBlockShowing.module.scss';

export default function useBlockShowing() {
  const [blockClassNames, setBlockClassNames] = useState([styles.block]);

  const showBlock = () => showBlockHandler({ blockClassNames, setBlockClassNames });

  const hideBlock = () => hideBlockHandler({ setBlockClassNames });

  return {
    blockClassNames,
    showBlock,
    hideBlock,
  };
}

export function showBlockHandler({ blockClassNames, setBlockClassNames }) {
  if (blockClassNames.length !== 3) {
    setBlockClassNames([styles.block, styles.visible]);

    requestAnimationFrame(() => {
      setBlockClassNames([styles.block, styles.visible, styles.opacity]);
    });
  }
}

export function hideBlockHandler({ setBlockClassNames }) {
  setBlockClassNames([styles.block]);
}
