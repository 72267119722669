import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;

export const postWhiteboard = async data => {
  const apiResult = await axios.post(URL + '/savewhiteboard', {
    ...data,
  });
};

export const getWhiteboard = (groupId, kidsMode) => {
  return axios.get(`${URL}/getwhiteboard/${groupId}/kidsMode/${kidsMode}`);
};

export const deleteWhiteboard = whiteboardId => {
  return axios.post(`${URL}/deletewhiteboard/${whiteboardId}`);
};

