import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import Loader from 'library/common/commonComponents/Loader';
import Button from 'library/common/commonComponents/Buttons/Button';
import { approvePendingUser, deleteUserFromKita, approveAllPendingUsers, approveAllPendingUsersAllKita, allApprovePendingUser, deleteAllUserFromKita } from 'library/api/user';
import { getCreateAtAccountDte } from 'library/utilities/date';
import styles from '../../userFrameApproval.module.scss';

export default function UsersFrameApprovalTable({
  users,
  isLoading,
  setUsers,
  showBottomNotification,
  isUseForAllKita
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState({});
  const [isSubmittingAllUsers, setIsSubmittingAllUsers] = useState(false);

  const deleteUserFromArray = userId => {
    const usersArray = [...users];
    const newUsersArray = usersArray.filter(item => item.user.id !== userId);
    setUsers(newUsersArray);
  };

  const deleteUserKitaFromArray = (userId, kitaId) => {
    const usersArray = [...users];
    const newUsersArray = usersArray.filter(item => !(item.user.id === userId && item.kita.id === kitaId));
    setUsers(newUsersArray);
  };

  const handleClick = async (kitaId, userId, actionType, e) => {
    e.stopPropagation();
    if (isSubmitting[userId, kitaId]) return;
    setIsSubmitting({ ...isSubmitting, [userId]: true, [kitaId]: true });
    if (actionType === 'approve') {
      if (isUseForAllKita) {
        await allApprovePendingUser([userId], kitaId)
      }
      else {
        await approvePendingUser([userId]);
      }
    } else {
      try {
        if (isUseForAllKita) {
          await deleteAllUserFromKita({ kitaId, userId });
        }
        else {
          await deleteUserFromKita({ kitaId, userId });
        }

        showBottomNotification(t('BottomNotifications.Success'));
      } catch (err) {
        showBottomNotification(t('AccountSettings.NotificationsSection.Error'), { isFail: true });
      }
    }
    if (isUseForAllKita) {
      deleteUserKitaFromArray(userId, kitaId);
    }
    else {
      deleteUserFromArray(userId);
    }
    setIsSubmitting({ ...isSubmitting, userId: false, kitaId: false });
  };

  const approveAll = async e => {
    e.stopPropagation();
    if (isSubmittingAllUsers) return;
    try {
      setIsSubmittingAllUsers(true);
      if (isUseForAllKita) {
        await approveAllPendingUsersAllKita();
      }
      else {
        await approveAllPendingUsers();
      }
      showBottomNotification(t('BottomNotifications.Success'));
      setUsers([]);
    } catch (err) {
      showBottomNotification(t('AccountSettings.NotificationsSection.Error'), { isFail: true });
    } finally {
      setIsSubmittingAllUsers(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={cn(styles.headerText, styles.headerTextWithPadding)}>
                  <div className={styles.headerTextContainer}>
                    {t('Administration.UserSection.Name')}
                  </div>
                </th>
                <th className={styles.headerText}>
                  <div className={styles.headerTextContainer}>
                    {t('Administration.UserSection.Email')}
                  </div>
                </th>
                <th className={styles.headerText}>
                  <div className={styles.headerTextContainer}>
                    {t('Administration.UserSection.Created at')}
                  </div>
                </th>
                {
                  isUseForAllKita && (
                    <th className={styles.headerText}>
                      <div className={styles.headerTextContainer}>
                        {t('Administration.Kita.Kita')}
                      </div>
                    </th>
                  )
                }
                <th className={styles.headerText}>
                  <Button onClick={approveAll} className={styles.acceptButton}>
                    {isSubmittingAllUsers ? (
                      <Loader dotColor='#fff' dotSize='8px' className={styles.loader} />
                    ) : (
                      t('Administration.UserSection.Approve all')
                    )}
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>
                    <div className={cn('notranslate', styles.avatar)}>
                      <div className={styles.avatarBox}>
                        <ProfileAvatar image={user} id={user.user.id} />
                      </div>
                      <div className={styles.avatarText}>
                        <div className={styles.groupName}>
                          {user.user.firstName} {user.user.lastName}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='notranslate'>{user.user.email}</div>
                  </td>
                  <td className={styles.memberCount}>
                    {getCreateAtAccountDte(user.registrationCreatedOn)}
                  </td>
                  {
                    isUseForAllKita && (
                      <td className={styles.memberCount}>
                        {
                          user.kita.kitaName
                        }
                      </td>
                    )
                  }
                  <td className={styles.moreBtn}>
                    <Button
                      onClick={e => handleClick(isUseForAllKita ? user.kita.id : user.kitaId, user.user.id, 'approve', e)}
                      className={styles.acceptButton}
                    >
                      {isSubmitting[user.user.id, isUseForAllKita ? user.kita.id : user.kitaId] ? (
                        <Loader dotColor='#fff' dotSize='8px' className={styles.loader} />
                      ) : (
                        t('Administration.UserSection.Approve')
                      )}
                    </Button>
                    <Button
                      onClick={e => handleClick(isUseForAllKita ? user.kita.id : user.kitaId, user.user.id, 'decline', e)}
                      className={styles.denyButton}
                    >
                      {isSubmitting[user.user.id, isUseForAllKita ? user.kita.id : user.kitaId] ? (
                        <Loader dotColor='#fff' dotSize='8px' className={styles.loader} />
                      ) : (
                        t('Administration.UserSection.Decline')
                      )}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
