import React, { useState, useRef, useEffect } from 'react';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { getAllKidsForCurrentUser } from 'library/api/kids';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Popup from 'library/common/commonComponents/Popups/Popup';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { postGfzConsent, getGfzConsent } from 'library/api/user';
import { logout } from 'library/api/logout';
import SignatureCanvas from 'react-signature-canvas';

import styles from './gfzconsent.module.scss';

export default function GfzConsentModule({ userInfo, type, isAdminEditing }) {
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    kidNames: '',
    parentLastName: '',
    parentFirstName: '',
    parentEmail: '',
    signaturePlace: '',
    signatureDate: Date.now(),
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [popupOpened, setPopupOpened] = useState(false);

  const sigCanvas = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof type === 'undefined') {
      getAllKidsForCurrentUser().then(data => {
        if (data.data.length > 0) {
          let kidString = '';
          for (let i = 0; i < data.data.length; i++) {
            kidString += data.data[i].firstName + ' ' + data.data[i].lastName + ',';
          }
          setFormData(prev => {
            return { ...prev, kidNames: kidString.slice(0, -1) };
          });
        }
      });
    }
    if (type === 'profileFrame') {
      getGfzConsent(isAdminEditing ? userInfo.id : null).then(data => {
        if (data.data !== null) {
          sigCanvas.current.fromDataURL(data.data.signature);
          setFormData(prev => {
            return {
              kidNames: data.data.kidNames,
              parentLastName: data.data.parentLastName,
              parentFirstName: data.data.parentFirstName,
              parentEmail: data.data.parentEmail,
              signaturePlace: data.data.signaturePlace,
              signatureDate: data.data.signatureDate,
            };
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (typeof type === 'undefined') {
      if (typeof userInfo.email !== 'undefined') {
        setFormData(prev => {
          return {
            ...prev,
            parentFirstName: userInfo.firstName,
            parentLastName: userInfo.lastName,
            parentEmail: userInfo.email,
          };
        });
        setIsLoading(false);
      }
    }
  }, [userInfo]);

  function handleTextStateChange(newValue, property) {
    setFormData(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function isValidInput() {
    if (formData.signaturePlace === '' || sigCanvas.current.toData().length === 0) {
      return false;
    }
    return true;
  }

  return (
    !isLoading && (
      <div className={styles.wrapper}>
        {popupOpened &&
          <Popup
            isOpened={popupOpened}
            closePopup={() => setPopupOpened(false)}
            header={<div>{t('GfzConsent.Information')}</div>}
            body={<div>{t('GfzConsent.ToEnableTranslations')}</div>}
            footer={
              <div>
                <Button
                  onClick={() => {
                    setPopupOpened(false);
                    window.location.reload();
                  }}>
                  {t('GfzConsent.Cancel')}
                </Button>
                &nbsp;
                <Button
                  type='primary'
                  onClick={() => {
                    setPopupOpened(false);
                    window.location.href = '/account-settings/languages';
                  }}>
                  {t('GfzConsent.Continue')}
                </Button>

              </div>
            }
          >
          </Popup>
        }
        {type === 'profileFrame' && !isAdminEditing && (
          <>
            <b style={{ color: 'red' }}>
              Um Ihre Zustimmung zu widerrufen, löschen Sie bitte den Account
            </b>
            <br />
            <br />
          </>
        )}
        <b>Einwilligungserklärung zur Nutzung der GFZ-App</b>
        <br />
        <br />
        <p>
          Die Stiftung GFZ in Zürich stellt den Eltern in Zusammenarbeit mit der Link IT isi GmbH
          aus Nürnberg eine GFZ-App zur Information der Eltern sowie zur Kommunikation mit Eltern
          der Einrichtung zur Verfügung. Für die Link IT isi GmbH geniesst der Schutz Ihrer
          personenbezogenen Daten höchste Priorität. Ihre Daten gehören nicht zum Geschäftsmodell
          und Ihre Daten werden nur entsprechend der für den Zweck unserer GFZ-App erforderlichen
          Masse verarbeitet. Dabei halten wir uns stets an gesetzliche Grundlagen – insbesondere an
          die Anforderungen der Datenschutzgrundverordnung.
        </p>
        <br />
        <p>
          In dieser Einwilligungserklärung informieren wir Sie über die Verarbeitung Ihrer
          personenbezogenen Daten und über Ihre Rechte im Rahmen des Online-Angebots. Die GFZ-App
          wird in zertifizierten deutschen Rechenzentren betrieben, welche redundant ausgelegt sind
          und somit für eine hohe Stabilität und Sicherheit sorgen.
        </p>
        <br />
        <p>
          Um Ihnen diesen Dienst anbieten zu können, benötigen wir allerdings Ihre
          Einwilligungserklärung. Diese können Sie direkt in der App bestätigen.
        </p>
        <br />
        Name des(r) Kindes(r): <br />
        <Input
          type='text'
          value={formData.kidNames}
          onChange={e => handleTextStateChange(e.target.value, 'kidNames')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        <b>Elternteil:</b> <br />
        <br />
        Name:
        <Input
          type='text'
          value={formData.parentLastName}
          onChange={e => handleTextStateChange(e.target.value, 'parentLastName')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        Vorname:
        <Input
          type='text'
          value={formData.parentFirstName}
          onChange={e => handleTextStateChange(e.target.value, 'parentFirstName')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        E-Mail-Adresse:
        <Input
          type='text'
          value={formData.parentEmail}
          onChange={e => handleTextStateChange(e.target.value, 'parentEmail')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        <p>
          Ich habe die beigefügten Datenschutzhinweise (Seiten 3 + 4) und die ergänzenden
          Nutzungshinweise für die GFZ-App zur Kenntnis genommen, stimme diesen zu und willige in
          die Verarbeitung der vorstehend genannten personenbezogenen Daten ein. Dies schliesst auch
          die Speicherung dieser Einwilligung ein.
        </p>{' '}
        <br />
        Ort:
        <Input
          type='text'
          value={formData.signaturePlace}
          onChange={e => handleTextStateChange(e.target.value, 'signaturePlace')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        Datum:&nbsp;
        <div className={styles.dateInput}>
          <DatePicker
            selected={formData.signatureDate}
            onChange={date => {
              const currSelDate = new Date(formData.signatureDate);
              currSelDate.setMonth(date.getMonth());
              currSelDate.setFullYear(date.getFullYear());
              currSelDate.setDate(date.getDate());
              setFormData(prev => {
                return { ...prev, signatureDate: currSelDate.getTime() };
              });
            }}
            width='100%'
            langCode='Deutsch'
            maxDate={new Date()}
            disabled={type === 'profileFrame' ? true : false}
          />
        </div>
        <br />
        <div style={{ border: '1px solid black', width: '402px' }}>
          <SignatureCanvas
            ref={sigCanvas}
            penColor='black'
            canvasProps={{ width: 400, height: 180, className: 'sigCanvas' }}
          />
        </div>
        Unterschrift
        {typeof type === 'undefined' && (
          <i
            style={{ marginLeft: '290px', cursor: 'pointer' }}
            className='fa fa-eraser'
            onClick={() => {
              if (sigCanvas && sigCanvas.current) {
                sigCanvas.current.clear();
              }
            }}
          />
        )}
        <br />
        <br />
        {typeof type === 'undefined' && (
          <>
            <Button
              onClick={() => {
                if (isValidInput()) {
                  const gfzConsentTemp = {
                    ...formData,
                    signature: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'),
                  };

                  postGfzConsent(gfzConsentTemp).then(() => {
                    store.dispatch(
                      showBottomNotification(<div>Gespeichert</div>, { isFail: false }),
                    );
                    /*const myTimeout = setTimeout(() => {
                      window.location.reload();
                    }, 1000);*/
                    setPopupOpened(true);
                  });
                } else {
                  setErrorMessage('Bitte füllen Sie Ort und Unterschrift aus.');
                }
              }}
              type='primary'
            >
              Bestätigen
            </Button>
            &nbsp;
            <Button
              onClick={() => {
                logout();
              }}
              type='secondary'
            >
              Abbrechen
            </Button>
          </>
        )}
        <br />
        <div style={{ color: 'red' }}>{errorMessage}</div>
        <br />
        <center>
          <b>
            <p>Informationspflichten</p>
            <br />
            <p>Erhebung von Daten bei der betroffenen Person Art. 13 DSGVO</p>
          </b>
        </center>
        <br />
        <b>1. Bezeichnung der Verarbeitungstätigkeit</b> <br />
        <b>GFZ-App</b> <br />
        <br />
        <b>2. Name und Kontaktdaten des Verantwortlichen</b>
        <br />
        <p>Verantwortliche Stelle für die Datenerhebung:</p>
        <p>Name der Einrichtung: Stiftung GFZ</p>
        <p>Ansprechpartnerin: Natascha Knorpp</p>
        <p>Adresse der Einrichtung: Stiftung GFZ, Zeltweg 21, 8032 Zürich</p>
        <p>Telefon: 044 253 65 10 </p>
        <p>E-Mail: GFZ-App@gfz-zh.ch </p> <br />
        <b>3. Kontaktdaten der Datenschutzbeauftragten</b>
        <br />
        <p>Name: Ursina Herzog </p>
        <p>Unternehmen: Stiftung GFZ </p>
        <p>Telefon: 044 253 65 10 </p>
        <p>E-Mail: GFZ-App@gfz-zh.ch </p> <br />
        <b>4. Zwecke und Rechtsgrundlagen der Datenverarbeitung</b>
        <br />
        <p>
          Wir verarbeiten personenbezogene Daten unter Beachtung der Bestimmungen der
          EU-Datenschutz- Grundverordnung (DSGVO), des Bayerischen Datenschutzgesetzes sowie aller
          weiteren massgeblichen Gesetze.
        </p>
        <p>
          Die Verarbeitung personenbezogener Daten erfolgt auf Basis Ihrer erteilten
          Einwilligungserklärung und dient ausschliesslich dem Zweck der oben benannten
          Verarbeitungstätigkeit.
        </p>
        <p>
          Sämtliche in die Plattform eingestellten Inhalte werden auf freiwilliger Basis und
          entsprechend der ergänzenden Nutzungshinweise eingestellt.
        </p>
        <p>
          Die Rechtsgrundlage der Verarbeitung Ihrer personenbezogenen Daten richtet sich nach Art.
          6 Abs. 1 lit. a DSGVO.
        </p>
        <br />
        <b>5. Empfänger oder Kategorien von Empfängern der personenbezogenen Daten</b>
        <br />
        <p>
          Personenbezogene Daten sowie weitere in die Plattform eingestellte Inhalte werden seitens
          der verantwortlichen Stelle in keiner Weise kommerziell genutzt. Eine Weitergabe erfolgt
          nur im Sinne der Bereitstellung des genutzten Funktionsumfangs.
        </p>
        <br />
        <b>6. Übermittlung von personenbezogenen Daten an ein Drittland</b>
        <br />
        <p>
          Eine Übermittlung der durch uns erhobenen personenbezogenen Daten an ein Drittland erfolgt
          nicht.
        </p>
        <br />
        <b>7. Dauer der Speicherung der personenbezogenen Daten</b>
        <br />
        <p>
          Die mit Ihrer Einwilligungserklärung erhobenen personenbezogenen Daten werden mit Widerruf
          Ihrer Einwilligung bzw. mit Beendigung der Nutzung des Dienstes gelöscht. Sobald die
          Einrichtung dauerhaft verlassen wird, werden die Daten des Nutzers sowie der Account des
          Nutzers gelöscht. Inhaltsdaten werden nach 2 Jahren automatisiert gelöscht.
        </p>
        <br />
        <b>8. Betroffenenrechte</b>
        <br />
        <p>Nach der Datenschutz-Grundverordnung stehen Ihnen folgende Rechte zu:</p>
        <ul>
          <li>
            Werden Ihre personenbezogenen Daten verarbeitet, so besteht das Recht, Auskunft über die
            zu Ihrer Person gespeicherten Daten zu erhalten (Art. 15 DSGVO).
          </li>
          <li>
            Sollten unrichtige personenbezogene Daten verarbeitet werden, steht das Recht auf
            Berichtigung zu (Art. 16 DSGVO).
          </li>
          <li>
            Liegen die gesetzlichen Voraussetzungen vor, so kann die Löschung oder Einschränkung der
            Verarbeitung verlangt sowie Widerspruch gegen die Verarbeitung eingelegt werden (Art.
            17, 18 und 21 DSGVO).
          </li>
          <li>
            Mit Einwilligung in die Datenverarbeitung oder sofern ein Vertrag zur Datenverarbeitung
            besteht und die Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird,
            steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu (Art. 20 DSGVO).
          </li>
          <li>
            Sollten Sie von oben genannten Rechten Gebrauch machen, prüft die Einrichtung, ob die
            gesetzlichen Voraussetzungen hierfür erfüllt sind.
          </li>
          <li>
            Weiterhin besteht ein Beschwerderecht beim Bayerischen Landesbeauftragten für den
            Datenschutz. Wenden Sie sich dazu bitte an die für Sie zuständige Datenschutzbehörde
            oder an die für uns zuständige Datenschutzbehörde: BayLDA Bayerisches Landesamt für
            Datenschutzaufsicht Promenade 18 91522 Ansbach
          </li>
        </ul>
        <br />
        <b>9. Widerrufsrecht bei Einwilligung</b>
        <br />
        <p>
          Die Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten durch eine entsprechende
          Erklärung kann jederzeit für die Zukunft widerrufen werden. Gleiches gilt für freiwillig
          zur Verfügung gestellte personenbezogene Daten. Die Rechtmässigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung aufgrund der Einwilligung oder freiwilligen Angabe, wird durch
          diesen Einspruch nicht berührt.
        </p>
        <p>
          Mit Widerruf der Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten können ggf.
          Leistungen, für die wir diese Daten benötigen, nicht durchgeführt werden. Den Widerruf
          Ihrer Einwilligung richten Sie bitte schriftlich an o.g. Postadresse.
        </p>
        <br />
        <b>10. Pflicht zur Bereitstellung der Daten</b>
        <br />
        <p>
          Ohne Angabe Ihrer personenbezogenen Daten kann die Nutzung der GFZ-App nicht angeboten
          werden.
        </p>
        <br />
        <center>
          <b>
            <p>Ergänzende Nutzungshinweise</p>
            <br />
          </b>
        </center>{' '}
        <br />
        <b>
          Allgemeine Pflichten des Nutzers / der Nutzerin und besondere Bestimmungen für die Nutzung
          der GFZ-App:
        </b>
        <br />
        <p>
          Der Nutzer / die Nutzerin ist verpflichtet, bei der Nutzung von GFZ-App sowie der Inhalte
          geltendes Recht sowie alle Rechte Dritter zu beachten. Es ist dem Nutzer / der Nutzerin
          insbesondere untersagt:
        </p>
        <br />
        <b>1. Bild- und Videodateien</b>
        <br />
        <p>
          Bild- und Videodateien, welche auf GFZ-App veröffentlicht werden, dürfen nur zum eigenen
          Gebrauch gespeichert werden und nicht weiterverbreitet werden.
        </p>
        <br />
        <b>2. Nicht erlaubte Inhalte</b>
        <br />
        <p>
          Verwendung beleidigender oder verleumderischer Inhalte, unabhängig davon, ob diese Inhalte
          andere Nutzer, Link IT isi Mitarbeitende oder andere Personen oder Unternehmen betreffen.
        </p>
        <p>
          Verwendung pornografischer, gewaltverherrlichender, missbräuchlicher, sittenwidriger oder
          Jugendschutzgesetze verletzende Inhalte oder Bewerbung, Angebot und/oder Vertrieb von
          pornografischen, gewaltverherrlichenden, missbräuchlichen, sittenwidrigen oder
          Jugendschutzgesetze verletzende Waren oder Dienstleistungen.
        </p>
        <br />
        <b>3. Geschützte Inhalte</b>
        <br />
        <p>
          Verwendung von gesetzlich (z. B. durch das Urheber-, Marken-, Patent-, Geschmacksmuster-
          oder Gebrauchsmusterrecht) geschützten Inhalten, ohne dazu berechtigt zu sein.
        </p>
        <br />
        <b>4. Erziehungsberechtigte Personen</b>
        <br />
        <p>
          Änderungen bezüglich erziehungsberechtigter Personen sind unmittelbar an die Leitung der
          Kinderbetreuung zu kommunizieren, insbesondere wenn damit die Sperre eines Accounts
          einhergeht.
        </p>
        <br />
        <b>5. Keine Weitergabe an Dritte</b>
        <br />
        <p>
          Der Account (Benutzername, E-Mail-Adresse, Kita-Code) für den Zugang zur GFZ-App darf
          unter keinen Umständen an Dritte Personen weitergegeben werden.
        </p>
        <br />
        <b>Folgen bei missbräuchlichem Gebrauch der GFZ-App</b>
        <br />
        <p>
          Der Account von Nutzern / Nutzerinnen kann unmittelbar und ohne Angabe von Gründen durch
          den Gruppenadministrator / die Gruppenadministratorin oder Mitarbeitende der Link IT isi
          GmbH gesperrt werden. Dies gilt insbesondere bei missbräuchlichem Gebrauch der GFZ-App.
        </p>
        <br />
      </div>
    ));
}
