import React, { useCallback } from "react";
import { load } from "@finapi/web-form";
import { getFinApiPaymentWebForm, finalizePayment } from 'library/api/finAPI';

export default function FinApiWebForm({ paymentToken, webformUrl, closePopup }) {
  const createWebForm = useCallback((target) => {
    // Replace following line with real REST request to create Web Form and its token

    //await Promise.resolve("token");
    load(
      target,
      {
        token: paymentToken,
        // these are examples of how optional properties can be set:
        targetUrl: webformUrl,
        //layoutConfig: "xs",
        //customerSupportUrl: "https://finapi.io"
      },
      {
        onComplete: function () {
          // handle "on complete" event
          closePopup();
        },
        onFail: function () {
          // handle "on fail" event
          closePopup();
        },
        onAbort: function () {
          // handle "on abort" event
          closePopup();
        },
        onLoadError: function () {
          // handle "on load error" event
        }
      }
    );
  }, []);

  const containerRef = (container) => {
    if (container) {
      createWebForm(container);
    }
  };

  return <div ref={containerRef}></div>;
}
