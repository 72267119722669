import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;

export const createWikiEntry = (groupId, title, homePage, content, parentPage, attachments) => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('homePage', homePage);
  formData.append('content', content);
  formData.append('parentPage', parentPage);
  formData.append('deletedAttachments', []);

  if (attachments != null) {
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }
  }

  return axios.post(`${zuulURL}/wiki/create/${groupId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateWikiEntry = (
  groupId,
  wikiEntryId,
  title,
  homePage,
  content,
  parentPage,
  attachments,
  deletedAttachments,
) => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('wikiEntryId', wikiEntryId);
  formData.append('homePage', homePage);
  formData.append('content', content);
  formData.append('parentPage', parentPage);
  formData.append('deletedAttachments', deletedAttachments);

  if (attachments != null) {
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }
  }

  return axios.post(`${zuulURL}/wiki/create/${groupId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getWikiEntries = groupId => axios.get(`${zuulURL}/wiki/wikiEntries/${groupId}`);

export const deleteWikiEntry = (groupId, wikiEntryId) =>
  axios.post(`${zuulURL}/wiki/delete/${groupId}/${wikiEntryId}`);

export const getHomePage = groupId => axios.get(`${zuulURL}/wiki/homePage/${groupId}`);

export const getWikiEntryParentOptions = (groupId, wikiEntryId) =>
  axios.get(`${zuulURL}/wiki/parentOptions/${groupId}/${wikiEntryId}`);

export const getOrCreateWikiFolder = groupId => axios.put(`${zuulURL}/wiki/wikiFolder/${groupId}`);
