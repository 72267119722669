import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Label from 'library/common/commonComponents/Label';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import styles from './createNewAbsence.module.scss';
import DatePicker from 'library/common/commonComponents/DatePicker';

export default function CreateNewAbsence({ isOpened, onSubmit, onClose }) {
  const { t } = useTranslation();
  const timeRangeDefault = { startDate: null, endDate: null };
  const [deleteTimeRange, setDeleteTimeRange] = useState(timeRangeDefault);
  const [absenceMessage, setAbsenceMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const today = new Date();

  const handDateChange = (date, type) => {
    setDeleteTimeRange(prevState => ({
      ...prevState,
      [type]: date,
    }));
  };

  const handAbsenceMessage = e => {
    const { value } = e.target;
    setAbsenceMessage(value);
  };

  const handleSubmit = async () => {
    if (!deleteTimeRange.startDate || !deleteTimeRange.endDate) {
      setErrorMsg(t('Absences.PleaseSelectTimeRange'));
      return;
    }

    if (!absenceMessage) {
      setErrorMsg(t('Absences.PleaseInputAbsenceMessage'));
      return;
    }
    const startDateFormat = format(deleteTimeRange.startDate, 'dd.MM.yyyy');
    const endDateFormat = format(deleteTimeRange.endDate, 'dd.MM.yyyy');

    try {
      setIsSubmitting(true);
      await onSubmit(startDateFormat, endDateFormat, absenceMessage);
      handleClose();
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsSubmitting(false);
      setErrorMsg('');
      setDeleteTimeRange(timeRangeDefault);
      setAbsenceMessage('');
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={handleClose}
      size='extraSmall'
      header={t('Chat.NewAbsence')}
      footer={
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonCancel}>
            <Button onClick={handleClose}>{t('Chat.Cancel')}</Button>
          </div>
          <div className={styles.buttonSend}>
            <ButtonWithLoader
              type='primary'
              onClick={handleSubmit}
              className={styles.buttonWithLoader}
              isLoading={isSubmitting}
            >
              {t('Chat.Send')}
            </ButtonWithLoader>
          </div>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.absenceTimeRangeWrapper}>
          <div className={styles.dateStart}>
            <Label type='input'>{t('Calendar.Start Date')}</Label>
            <DatePicker
              selected={deleteTimeRange.startDate}
              onChange={date => handDateChange(date, 'startDate')}
              minDate={today}
            />
          </div>
          <div className={styles.endStart}>
            <Label type='input'>{t('Calendar.End Date')}</Label>
            <DatePicker
              selected={deleteTimeRange.endDate}
              onChange={date => handDateChange(date, 'endDate')}
              minDate={today}
            />
          </div>
        </div>
        <div className={styles.chatNameContainer}>
          <Label type='input'>{t('Chat.Absence.Message')}</Label>
          <TextArea value={absenceMessage} onChange={handAbsenceMessage} />
        </div>
        {!!errorMsg && <div className={styles.timeRangeError}>{errorMsg}</div>}
      </div>
    </Popup>
  );
}
