import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import FeedItemComments from '.';

export default connect(
  state => ({
    user: state.userReducer,
  }),
  () => ({ showBottomNotification }),
)(FeedItemComments);
