import React from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker from 'library/common/commonComponents/DatePicker';
import SortIndicator from 'library/common/commonComponents/SortIndicator';
import Input from 'library/common/commonComponents/Inputs/Input';
import Pagination from 'library/common/commonComponents/Pagination';
import Loader from 'library/common/commonComponents/Loader';
import UsersFrameListRow from '../UsersFrameRegistrationRow/UsersFrameRegistrationRowContainer';

import styles from '../../usersFrameRegistration.module.scss';

export default function UsersFrameRegistrationTable({
  kitaId,
  loadPage,
  isLoading,
  history,
  location,
  totalUsersCount,
  users,
  setSorting,
  sort,
  reloadPage,
  emailValue,
  setEmailValue,
  invitedByValue,
  setInvitedByValue,
  languageValue,
  setLanguageValue,
  createdAtValue,
  setCreatedAtValue,
  langCode,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <tr>
          <td className={styles.headerText} onClick={() => setSorting('user.email')}>
            <div className={styles.headerTextContainer}>
              {t('Administration.UserSection.Email')}
              <SortIndicator sort={sort} name='user.email' />
            </div>
          </td>
          <td
            className={styles.headerText}
            onClick={() => setSorting('registrationCreatedBy.lastName')}
          >
            <div className={styles.headerTextContainer}>
              {t('Administration.UserSection.Invited by')}
              <SortIndicator sort={sort} name='registrationCreatedBy.lastName' />
            </div>
          </td>
          <td className={styles.headerText} onClick={() => setSorting('user.language.langCode')}>
            <div className={styles.headerTextContainer}>
              {t('Administration.UserSection.Language')}
              <SortIndicator sort={sort} name='user.language.langCode' />
            </div>
          </td>
          <td className={styles.headerText} onClick={() => setSorting('createdAt')}>
            <div className={styles.headerTextContainer}>
              {t('Administration.UserSection.Created at')}
              <SortIndicator sort={sort} name='createdAt' />
            </div>
          </td>
          <td className={styles.headerText} onClick={() => setSorting('lastInvitedAt')}>
            <div className={styles.headerTextContainer}>
              {t('Administration.UserSection.Last Invited At')}
              <SortIndicator sort={sort} name='lastInvitedAt' />
            </div>
          </td>
          <td className={styles.headerText}>
            <div className={styles.headerTextContainer}>
              {t('Administration.UserSection.Actions')}
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <Input
              value={emailValue}
              onChange={e => setEmailValue(e.target.value)}
              className={styles.filtersSearchInput}
            />
          </td>
          <td>
            <Input
              value={invitedByValue}
              onChange={e => setInvitedByValue(e.target.value)}
              className={styles.filtersSearchInput}
            />
          </td>
          <td>
            <Input
              value={languageValue}
              onChange={e => setLanguageValue(e.target.value)}
              className={styles.filtersSearchInput}
            />
          </td>
          <td>
            <DatePicker
              selected={createdAtValue}
              onChange={setCreatedAtValue}
              customInput={<Input className={styles.filtersSearchInput} />}
              langCode={langCode}
              maxDate={new Date()}
            />
          </td>
          <td />
        </tr>

        {isLoading ? (
          <Loader className={styles.loader} />
        ) : (
          users.map(user => (
            <UsersFrameListRow key={user.id} user={user} kitaId={kitaId} reloadPage={reloadPage} />
          ))
        )}
      </table>
      {totalUsersCount / 50 > 1 && (
        <Pagination
          currentPage={parseInt(location.pathname.slice(35) || 1, 10)}
          history={history}
          numberOfPages={totalUsersCount ? Math.ceil(totalUsersCount / 50) : 1}
          onPageClick={loadPage}
        />
      )}
    </div>
  );
}
