import { getUserKitas } from 'library/api/kita';

export async function isNotOnlyPublicKitaMember() {
  const kitas = await getUserKitas();
  return filterIsNotOnlyPublicKitaMember(kitas.data);
}

export function filterIsNotOnlyPublicKitaMember(kitaarray) {
  if (kitaarray != null) {
    return (
      (kitaarray[0] != null && typeof kitaarray[0] !== 'undefined' && kitaarray.lengt > 1) ||
      (typeof kitaarray[0] !== 'undefined' &&
        typeof kitaarray[0].kitaId !== 'undefined' &&
        kitaarray[0].kitaId !== 1131)
    );
  } else {
    return true;
  }
}
