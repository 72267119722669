import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Card from 'library/common/commonComponents/to-refactor/commonCard';
import Input from 'library/common/commonComponents/to-refactor/commonInput';
import CardHeading from 'library/common/commonComponents/to-refactor/commonCard/CardHeading';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import CardWrapper from 'library/common/commonComponents/to-refactor/commonCardWrapper';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import styles from './resetPassword.module.scss';

function ResetPasswordModule({ fromState, handleInputChange, resetPassword, back }) {
  const { t } = useTranslation();
  useSiteTitle('ResetPassword');

  const {
    password,
    confirmPassword,
    isDisabled,
    isInputError,
    confirmPasswordError,
    passwordError,
    isReady,
    isBounceAnimation,
    errorMessage,
  } = fromState;

  if (!isReady) {
    return (
      <CardWrapper>
        <div className={cn(styles.login_container, 'animated')} />
      </CardWrapper>
    );
  }

  return (
    <CardWrapper>
      <Card
        className={cn(
          styles.login_container,
          'animated',
          isBounceAnimation && 'bounceIn',
          isInputError && 'shake',
        )}
      >
        <CardHeading className={styles.login_heading_padding_15}>
          <strong>{t('PasswordRecovery.Change')}</strong>
          <span className={styles.login_heading_text_sign_in}>
            {' '}
            {t('PasswordRecovery.your password')}
          </span>
        </CardHeading>
        <form onSubmit={resetPassword}>
          <div className={styles.formGroup}>
            <span className={cn(styles.password_label, !!passwordError && styles.error_label)}>
              {t('PasswordRecovery.New Password *')}
            </span>
            <Input
              className={styles.password_form}
              placeholder=''
              name='password'
              error={!!passwordError}
              value={password}
              type='password'
              onChange={handleInputChange}
            />
            {!!passwordError && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={passwordError} />
              </div>
            )}
          </div>
          <div>
            <span
              className={cn(styles.password_label, !!confirmPasswordError && styles.error_label)}
            >
              {t('PasswordRecovery.Confirm new Password *')}
            </span>
            <Input
              className={styles.confirmPassword}
              placeholder=''
              value={confirmPassword}
              error={!!confirmPasswordError}
              name='confirmPassword'
              type='password'
              onChange={handleInputChange}
            />
            {!!confirmPasswordError && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={confirmPasswordError} />
              </div>
            )}
          </div>
        </form>
        <br />
        {!!errorMessage && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={errorMessage} />
          </div>
        )}
        <div className={styles.signBtnContainer}>
          <ButtonWithLoader
            isLoading={isDisabled}
            onClick={!isDisabled ? resetPassword : undefined}
            type='primary'
          >
            <span className={styles.change_pwd_btn_text}>
              {t('PasswordRecovery.Change password')}
            </span>
          </ButtonWithLoader>
          <Button onClick={!isDisabled ? back : undefined} type='primary'>
            {<span className={styles.fp_back_btn_text}>{t('PasswordRecovery.Back')}</span>}
          </Button>
        </div>
      </Card>
    </CardWrapper>
  );
}

ResetPasswordModule.propTypes = {
  back: PropTypes.func.isRequired,
  fromState: PropTypes.shape({}).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export default ResetPasswordModule;
