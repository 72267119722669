import React, { useState, useReducer } from 'react';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './employeeItem.module.scss';
import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from 'library/common/commonComponents/Popups/Popup';
import DatePicker from 'library/common/commonComponents/DatePicker';
import HelpText from 'library/common/commonComponents/HelpText';
import Checkbox from 'library/common/commonComponents/Checkbox';
import cn from 'classnames';
import { getLegalBreakTimeFromWorktimeInMin } from 'library/utilities/employeeCheckInOut';
import { postEmployeeWorkingTimes } from 'library/api/employee';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import WorkingTimeChange from './WorkingTimeChange';

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export default function EmployeeItem({ employee, fetchEmployeeWorkingTimes, className, expanded }) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageIndex, setErrorMessageIndex] = useState(null);
  const [showWorkingHours, setShowWorkingHours] = useState(expanded);
  const [overtimeCalculationFrom, setOvertimeCalculationFrom] = useState(
    formatDateObject(employee.employeeInformation?.activeFrom),
  );
  const [autoCheckoutAtSeven, toggleAutoCheckoutAtSeven] = useReducer(
    prev => !prev,
    employee.employeeInformation ? employee.employeeInformation?.autoCheckoutAtSeven : true,
  );
  const [overtimeCalculationFromEnabled, setOvertimeCalculationFromEnabled] = useState(
    !employee.employeeInformation?.activeFrom,
  );
  const [
    isConfirmOvertimeCalculationFromPopupOpen,
    toggleIsConfirmOvertimeCalculationFromPopupOpen,
  ] = useReducer(prev => !prev, false);
  const [
    isConfirmWorkingTimeChangePopupOpen,
    toggleIsConfirmWorkingTimeChangePopupOpen,
  ] = useReducer(prev => !prev, false);
  const [
    isWarningNoWorkingTimeChangeDatePopupOpen,
    toggleIsWarningNoWorkingTimeChangeDatePopupOpen,
  ] = useReducer(prev => !prev, false);
  const [isDirty, setIsDirty] = useState(false);
  const [workingTimeChangeFrom, setWorkingTimeChangeFrom] = useState(
    employee.upcomingWorkingTimeDate ? new Date(employee.upcomingWorkingTimeDate) : null,
  );
  const [groupedWorkingHours, setGroupedWorkingHours] = useState(
    WEEKDAYS.map(day => {
      const dayLower = day.toLowerCase();

      return {
        weekday: t(`Administration.${day}`),
        time: [
          {
            name: `${dayLower}StartTime`,
            value: formatTimeObject(employee.firstShift?.[`${dayLower}StartTime`]),
            secondShift: `${dayLower}SecondStartTime`,
            secondShiftValue: formatTimeObject(employee.secondShift?.[`${dayLower}StartTime`]),
            available: `${dayLower}AvailableStartTime`,
            availableTime: formatTimeObject(employee.availableTime?.[`${dayLower}StartTime`]),
          },
          {
            name: `${dayLower}EndTime`,
            value: formatTimeObject(employee.firstShift?.[`${dayLower}EndTime`]),
            secondShift: `${dayLower}SecondEndTime`,
            secondShiftValue: formatTimeObject(employee.secondShift?.[`${dayLower}EndTime`]),
            available: `${dayLower}AvailableEndTime`,
            availableTime: formatTimeObject(employee.availableTime?.[`${dayLower}EndTime`]),
          },
        ],
        workingHoursDiff: subtractLegalBreakOfTimeString(
          formatTimeObject(employee.availableTime?.[`${dayLower}StartTime`]),
          formatTimeObject(employee.availableTime?.[`${dayLower}EndTime`]),
          formatTimeObject(employee.firstShift?.[`${dayLower}StartTime`]),
          formatTimeObject(employee.firstShift?.[`${dayLower}EndTime`]),
          formatTimeObject(employee.secondShift?.[`${dayLower}StartTime`]),
          formatTimeObject(employee.secondShift?.[`${dayLower}EndTime`]),
        ),
        availableTimeDiff: calculateTimeDifference(
          formatTimeObject(employee.availableTime?.[`${dayLower}StartTime`]),
          formatTimeObject(employee.availableTime?.[`${dayLower}EndTime`]),
        ),
      };
    }),
  );

  function formatTimeObject(time) {
    if (time === null) {
      return '';
    }
    if (typeof time === 'object') {
      const hours = time.hour.toString().padStart(2, 0);
      const minutes = time.minute.toString().padStart(2, 0);
      return `${hours}:${minutes}`;
    }
    return time;
  }

  function formatDateObject(date) {
    if (!date) return null;
    const { year, monthValue, dayOfMonth } = date;
    return new Date(year, monthValue - 1, dayOfMonth);
  }

  function minsFromTimeString(timeString) {
    if (!timeString) return 0;

    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  }

  const handleWorkingTimeChange = (dayIndex, timeIndex, value, property) => {
    setIsDirty(true);
    const newGroupedWorkingHours = [...groupedWorkingHours];
    newGroupedWorkingHours[dayIndex].time[timeIndex][property] = value;

    if (property === 'availableTime') {
      newGroupedWorkingHours[dayIndex].availableTimeDiff = calculateTimeDifference(
        newGroupedWorkingHours[dayIndex].time[0].availableTime,
        newGroupedWorkingHours[dayIndex].time[1].availableTime,
      );
    }

    newGroupedWorkingHours[dayIndex].workingHoursDiff = subtractLegalBreakOfTimeString(
      newGroupedWorkingHours[dayIndex].time[0].availableTime,
      newGroupedWorkingHours[dayIndex].time[1].availableTime,
      newGroupedWorkingHours[dayIndex].time[0].value,
      newGroupedWorkingHours[dayIndex].time[1].value,
      newGroupedWorkingHours[dayIndex].time[0].secondShiftValue,
      newGroupedWorkingHours[dayIndex].time[1].secondShiftValue,
    );

    setGroupedWorkingHours(newGroupedWorkingHours);
  };

  function addTimeStrings(time1, time2, time3) {
    // return '00:00' if time1 and time2 is empty
    if (!time1?.trim() && !time2?.trim() && !time3?.trim()) return '00:00';

    let totalMinutes = 0;
    let totalHours = 0;

    totalMinutes += minsFromTimeString(time1);
    totalMinutes += minsFromTimeString(time2);
    totalMinutes += minsFromTimeString(time3);

    totalHours = Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    // Format the result string
    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
  }

  function subtractLegalBreakOfTimeString(
    availableStart,
    availableEnd,
    firstShiftStart,
    firstShiftEnd,
    secondShiftStart,
    secondShiftEnd,
  ) {
    const availableTimeDiff = calculateTimeDifference(
      formatTimeObject(availableStart),
      formatTimeObject(availableEnd),
    );
    const firstShiftDiff = calculateTimeDifference(
      formatTimeObject(firstShiftStart),
      formatTimeObject(firstShiftEnd),
    );
    const secondShiftDiff = calculateTimeDifference(
      formatTimeObject(secondShiftStart),
      formatTimeObject(secondShiftEnd),
    );

    const time = addTimeStrings(availableTimeDiff, firstShiftDiff, secondShiftDiff);

    if (!time?.trim()) {
      return '00:00';
    }

    // calculate the total time between shifts
    const dates = [];
    const addDatesToArr = (start, end) => {
      if (!!start && !!end) {
        const startHours = parseInt(start.split(':')[0]);
        const startMinutes = parseInt(start.split(':')[1]);
        const endHours = parseInt(end.split(':')[0]);
        const endMinutes = parseInt(end.split(':')[1]);

        const startDate = new Date(new Date().setHours(startHours, startMinutes));
        const endDate = new Date(new Date().setHours(endHours, endMinutes));
        dates.push(startDate, endDate);
      }
    };
    addDatesToArr(availableStart, availableEnd);
    addDatesToArr(firstShiftStart, firstShiftEnd);
    addDatesToArr(secondShiftStart, secondShiftEnd);
    // sort all dates ascending
    dates.sort((a, b) => a - b);

    let totalAbsentTime = 0;
    for (let i = 1; i < dates.length; i++) {
      if (i % 2 === 0) {
        const differenceInMs = dates[i] - dates[i - 1];
        totalAbsentTime += differenceInMs / 60000;
      }
    }

    let totalMinutes = minsFromTimeString(time);

    // subtract the absent time from legal break time calculated from worktime, if negative set to 0
    const totalMinsLegalBreak = Math.max(
      getLegalBreakTimeFromWorktimeInMin(totalMinutes) - totalAbsentTime,
      0,
    );

    // calculate legal break for each shift
    const availableTimeLegalBreak = getLegalBreakTimeFromWorktimeInMin(
      minsFromTimeString(availableTimeDiff),
    );
    const firstTimeLegalBreak = getLegalBreakTimeFromWorktimeInMin(
      minsFromTimeString(firstShiftDiff),
    );
    const secondTimeLegalBreak = getLegalBreakTimeFromWorktimeInMin(
      minsFromTimeString(secondShiftDiff),
    );
    // max legal break can be 45 minutes
    const shiftLegalBreak = Math.min(
      availableTimeLegalBreak + firstTimeLegalBreak + secondTimeLegalBreak,
      45,
    );

    // use the max legal break, so when shifts are longer than 6 hours a legal break is guaranteed to be subtracted
    // should not exceed 45 minutes
    const legalBreak = Math.min(Math.max(totalMinsLegalBreak, shiftLegalBreak), 45);
    totalMinutes -= legalBreak;

    const totalHours = Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
  }

  function calculateTimeDifference(startTime, endTime) {
    if (startTime?.length > 0 && endTime?.length > 0 && startTime && endTime) {
      // Split the time strings into hours and minutes
      const startHours = parseInt(startTime.split(':')[0]);
      const startMinutes = parseInt(startTime.split(':')[1]);
      const endHours = parseInt(endTime.split(':')[0]);
      const endMinutes = parseInt(endTime.split(':')[1]);

      // Calculate the difference in minutes
      let minuteDifference = endHours * 60 + endMinutes - (startHours * 60 + startMinutes);

      // Handle negative difference (when end time is before start time)
      if (minuteDifference < 0) {
        minuteDifference += 24 * 60; // Add total minutes in a day
      }

      // Convert minutes back to hours and minutes for display
      const hoursDifference = Math.floor(minuteDifference / 60);
      const remainingMinutes = minuteDifference % 60;

      // Format the difference string (e.g., "02:00")
      const formattedDifference = `${hoursDifference
        .toString()
        .padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;

      // **Return the formatted string directly**
      return formattedDifference;
    }
  }

  const isValid = (day, dayIndex) => {
    const startFirstShift = day.time[0].value;
    const endFirstShift = day.time[1].value;
    const startSecondShift = day.time[0].secondShiftValue;
    const endSecondShift = day.time[1].secondShiftValue;
    const startAvailable = day.time[0].availableTime;
    const endAvailable = day.time[1].availableTime;

    const parseTime = time => {
      return parseInt(time.replace(':', ''), 10);
    };

    const isValidTimeRange = (start, end) => {
      // skip further validation if both are empty
      if (!start && !end) {
        return true;
      }
      // both start and end must be set
      if (!start || !end) {
        setErrorMessage(
          t('Administration.UserSection.Both start and end working time must be set.'),
        );
        setErrorMessageIndex(dayIndex);
        return false;
      }

      const startInt = parseTime(start);
      const endInt = parseTime(end);
      if (endInt - startInt <= 0) {
        setErrorMessage(t('Administration.End of working time has to be be later than start time'));
        setErrorMessageIndex(dayIndex);
        return false;
      }

      return true;
    };

    if (
      !isValidTimeRange(startFirstShift, endFirstShift) ||
      !isValidTimeRange(startSecondShift, endSecondShift) ||
      !isValidTimeRange(startAvailable, endAvailable)
    ) {
      return false;
    }

    const hasFirstShift = !!startFirstShift;
    const hasSecondShift = !!startSecondShift;
    const hasAvailableTime = !!startAvailable;

    if (!hasFirstShift && hasSecondShift) {
      setErrorMessage(t('Administration.First shift must be set when second shift is set'));
      setErrorMessageIndex(dayIndex);
      return false;
    }

    if (hasFirstShift && hasSecondShift) {
      if (parseTime(endFirstShift) > parseTime(startSecondShift)) {
        setErrorMessage(
          t('Administration.First shift must be before second shift and cannot overlap'),
        );
        setErrorMessageIndex(dayIndex);
        return false;
      }
    }

    if (hasAvailableTime) {
      if (
        hasFirstShift &&
        parseTime(startAvailable) < parseTime(endFirstShift) &&
        parseTime(endAvailable) > parseTime(startFirstShift)
      ) {
        setErrorMessage(t('Administration.Available time shift cannot overlap with first shift'));
        setErrorMessageIndex(dayIndex);
        return false;
      }
      if (
        hasSecondShift &&
        parseTime(startAvailable) < parseTime(endSecondShift) &&
        parseTime(endAvailable) > parseTime(startSecondShift)
      ) {
        setErrorMessage(t('Administration.Available time shift cannot overlap with second shift'));
        setErrorMessageIndex(dayIndex);
        return false;
      }
    }

    return true;
  };

  const formatDateToIso = date => {
    if (!date) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSaveButton = async () => {
    const newWorkingHours = {
      userId: employee.user.id,
      autoCheckoutAtSeven,
    };

    // set activeFrom when it was changed
    if (
      (overtimeCalculationFrom && !employee.employeeInformation?.activeFrom) ||
      (overtimeCalculationFrom &&
        new Date(overtimeCalculationFrom).getTime() !==
          formatDateObject(employee.employeeInformation?.activeFrom).getTime())
    ) {
      newWorkingHours.activeFrom = formatDateToIso(overtimeCalculationFrom);
    }

    // set working time active from when it was changed
    let workingTimeActiveFrom = null;
    if (!employee.upcomingWorkingTimeDate && workingTimeChangeFrom) {
      workingTimeActiveFrom = formatDateToIso(workingTimeChangeFrom);
    }

    if (isDirty) {
      const firstShift = {
        shiftType: 'FIRST_SHIFT',
        workingTimeActiveFrom,
      };
      const secondShift = {
        shiftType: 'SECOND_SHIFT',
        workingTimeActiveFrom,
      };
      const availableTime = {
        shiftType: 'AVAILABLE_TIME',
        workingTimeActiveFrom,
      };

      let error = false;
      groupedWorkingHours.every((day, dayIndex) => {
        if (!isValid(day, dayIndex)) {
          error = true;
          return false;
        } else {
          day.time.forEach(
            timeEntry => (
              (firstShift[timeEntry.name] = timeEntry.value),
              (secondShift[timeEntry.name] = timeEntry.secondShiftValue),
              (availableTime[timeEntry.name] = timeEntry.availableTime)
            ),
          );
          return true;
        }
      });
      if (error) {
        return;
      }
      newWorkingHours.firstShift = firstShift;
      newWorkingHours.secondShift = secondShift;
      newWorkingHours.availableTime = availableTime;
    }
    try {
      await postEmployeeWorkingTimes(newWorkingHours);
      await fetchEmployeeWorkingTimes(employee.user.id);
    } catch (err) {
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    }
  };

  const getTotalHours = property => {
    let totalMinutes = 0;
    let totalHours = 0;

    groupedWorkingHours.forEach(day => {
      if (!day[property]) return;
      totalMinutes += parseInt(day[property].split(':')[0]) * 60;
      totalMinutes += parseInt(day[property].split(':')[1]);
    });

    totalHours = Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className={cn(className, styles.employeeItemContainer)}>
      <div className={styles.employee} onClick={() => setShowWorkingHours(!showWorkingHours)}>
        <div>
          <img
            className={styles.profileAvatar}
            src={employee.user.modifiedProfileLogoUrl || defaultAvatar}
          />
        </div>
        <div className={styles.profileName}>{`${employee.user.firstName} ${
          employee.user.lastName
        }`}</div>

        <div className={styles.weeklyWorkingTime}>
          <i className='fa fa-lg fa-clock-o' />
          <div>
            <span className={styles.workingTimeLabel}>
              {t('Administration.Hours per week') + ' ' + getTotalHours('workingHoursDiff')}
            </span>
            <br />
            {`(${t('Administration.Of which available hours')} ${getTotalHours(
              'availableTimeDiff',
            )})`}
          </div>
        </div>

        <div className={styles.expandIcon}>
          <i className={showWorkingHours ? 'fa fa-lg fa-chevron-up' : 'fa fa-lg fa-chevron-down'} />
        </div>
      </div>

      {showWorkingHours ? (
        <>
          <div className={styles.weekContainer}>
            {groupedWorkingHours.map((day, dayIndex) => {
              return (
                <div
                  className={cn(
                    styles.weekdayContainer,
                    dayIndex === errorMessageIndex && styles.highlightError,
                  )}
                  key={day.weekday}
                >
                  <div className={styles.weekdayHeading}>
                    <span className={styles.weekdayLabel}>{day.weekday}</span>
                    <span className={styles.weekdayHours}>
                      <span className={styles.workingTimeLabel}>
                        {t('TimeSettings.Working Hours') + ' ' + day.workingHoursDiff}
                      </span>
                      {!!day.availableTimeDiff &&
                        day.availableTimeDiff !== '00:00' &&
                        ` (${t('Administration.Of which available hours')} ${
                          day.availableTimeDiff
                        })`}
                    </span>
                  </div>

                  <div className={styles.weekdayRow}>
                    <fieldset>
                      <legend>{t('Administration.Working time shift') + ' 1'}</legend>
                      <div className={styles.workingTime}>
                        <div>
                          <label htmlFor={day.time[0].name}>
                            {t('Administration.UserSection.Start of work')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[0].value}
                            onChange={e => {
                              handleWorkingTimeChange(dayIndex, 0, e.target.value, 'value');
                            }}
                            id={day.time[0].name}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                        <div>
                          <label htmlFor={day.time[1].name}>
                            {t('Administration.UserSection.End of work')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[1].value}
                            onChange={e => {
                              handleWorkingTimeChange(dayIndex, 1, e.target.value, 'value');
                            }}
                            id={day.time[1].name}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>{t('Administration.Working time shift') + ' 2'}</legend>
                      <div className={styles.workingTime}>
                        <div>
                          <label htmlFor={day.time[0].secondShift}>
                            {t('Administration.UserSection.Start of work')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[0].secondShiftValue}
                            onChange={e => {
                              handleWorkingTimeChange(
                                dayIndex,
                                0,
                                e.target.value,
                                'secondShiftValue',
                              );
                            }}
                            id={day.time[0].secondShift}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                        <div>
                          <label htmlFor={day.time[1].secondShift}>
                            {t('Administration.UserSection.End of work')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[1].secondShiftValue}
                            onChange={e => {
                              handleWorkingTimeChange(
                                dayIndex,
                                1,
                                e.target.value,
                                'secondShiftValue',
                              );
                            }}
                            id={day.time[1].secondShift}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>{t('TimeSettings.Verfügungszeit')}</legend>
                      <div className={styles.workingTime}>
                        <div>
                          <label htmlFor={day.time[0].available}>
                            {t('AccountSettings.AbsenceSettings.From time')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[0].availableTime}
                            onChange={e => {
                              handleWorkingTimeChange(dayIndex, 0, e.target.value, 'availableTime');
                            }}
                            id={day.time[0].available}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                        <div>
                          <label htmlFor={day.time[1].available}>
                            {t('AccountSettings.AbsenceSettings.To time')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[1].availableTime}
                            onChange={e => {
                              handleWorkingTimeChange(dayIndex, 1, e.target.value, 'availableTime');
                            }}
                            id={day.time[1].available}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              );
            })}
          </div>

          <WorkingTimeChange
            workingTimeChangeFrom={workingTimeChangeFrom}
            setWorkingTimeChangeFrom={setWorkingTimeChangeFrom}
            userId={employee.user.id}
            currentWorkingTime={groupedWorkingHours}
            upcomingWorkingTimeDate={employee.upcomingWorkingTimeDate}
            fetchEmployeeWorkingTimes={fetchEmployeeWorkingTimes}
          />

          <div className={styles.separator} />

          <Checkbox
            isChecked={autoCheckoutAtSeven}
            onChange={toggleAutoCheckoutAtSeven}
            className={styles.autoCheckoutAtSevenCheckbox}
            name={t('Administration.Will be automatically checked out at seven')}
          />

          <div className={styles.separator} />

          <div className={styles.datePickerContainer}>
            <div className={styles.caption}>
              <i className='fa fa-lg fa-play-circle' aria-hidden='true' />
              <label htmlFor='overtimeCalculationFrom'>
                {t('Administration.Time recording start')}
              </label>
            </div>

            <HelpText className={styles.helpText}>
              {t(
                'Administration.The start date from which the overtime should be calculated can be set here',
              )}
            </HelpText>

            <div className={styles.datePickerWrapper}>
              <DatePicker
                id='overtimeCalculationFrom'
                selected={overtimeCalculationFrom}
                onChange={date => setOvertimeCalculationFrom(date)}
                dateFormat='dd.MM.yyyy'
                dropdownMode='select'
                langCode={employee.user.currentWatsonLang}
                minDate={new Date()}
                disabled={!overtimeCalculationFromEnabled}
              />
              {!overtimeCalculationFromEnabled && (
                <Button
                  className={styles.enableOvertimeCalculationFromBtn}
                  onClick={toggleIsConfirmOvertimeCalculationFromPopupOpen}
                >
                  <i className='fa fa-pencil' />
                </Button>
              )}
            </div>
          </div>

          {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
          <Button
            onClick={() => {
              if (isDirty && !workingTimeChangeFrom) {
                toggleIsWarningNoWorkingTimeChangeDatePopupOpen();
              } else {
                toggleIsConfirmWorkingTimeChangePopupOpen();
              }
            }}
            type='primary'
            className={styles.saveButton}
          >
            <i className='fa fa-check' aria-hidden='true' /> {t('Administration.UserSection.Save')}
          </Button>

          <Popup
            size='small'
            isOpened={isConfirmOvertimeCalculationFromPopupOpen}
            closePopup={toggleIsConfirmOvertimeCalculationFromPopupOpen}
            header={<strong>{t('Administration.Warning')}</strong>}
            body={
              <p>
                {t(
                  'Administration.Are you sure you want to change the start date of the overtime calculation this might cause conflicts',
                )}
              </p>
            }
            footer={
              <div className={styles.reviewButtonGroup}>
                <Button onClick={toggleIsConfirmOvertimeCalculationFromPopupOpen}>
                  {t('Administration.Cancel')}
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    setOvertimeCalculationFromEnabled(true);
                    toggleIsConfirmOvertimeCalculationFromPopupOpen();
                  }}
                >
                  {t('Administration.Confirm')}
                </Button>
              </div>
            }
          />

          <Popup
            size='small'
            isOpened={isConfirmWorkingTimeChangePopupOpen}
            closePopup={toggleIsConfirmWorkingTimeChangePopupOpen}
            header={<strong>{t('Administration.Please confirm')}</strong>}
            body={
              <>
                <p>
                  {t('Administration.Entered working times will be active from')} <br />
                  <strong>
                    {!employee.upcomingWorkingTimeDate && workingTimeChangeFrom
                      ? new Date(workingTimeChangeFrom).toLocaleDateString('de-DE', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })
                      : t('Administration.Today')}
                  </strong>
                </p>

                <br />
                <p>
                  {t('Administration.Time recording start')} <br />
                  <strong>
                    {new Date(overtimeCalculationFrom).toLocaleDateString('de-DE', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}
                  </strong>
                </p>
              </>
            }
            footer={
              <div className={styles.reviewButtonGroup}>
                <Button onClick={toggleIsConfirmWorkingTimeChangePopupOpen}>
                  {t('Administration.Cancel')}
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    handleSaveButton();
                    toggleIsConfirmWorkingTimeChangePopupOpen();
                  }}
                >
                  {t('Administration.Confirm')}
                </Button>
              </div>
            }
          />

          <Popup
            size='small'
            isOpened={isWarningNoWorkingTimeChangeDatePopupOpen}
            closePopup={toggleIsWarningNoWorkingTimeChangeDatePopupOpen}
            header={<strong>{t('Administration.Warning')}</strong>}
            body={<p>{t('Administration.Please enter a working time change date')}</p>}
            footer={
              <div className={styles.reviewButtonGroup}>
                <Button
                  type='primary'
                  onClick={() => {
                    toggleIsWarningNoWorkingTimeChangeDatePopupOpen();
                    fetchEmployeeWorkingTimes(employee.user.id);
                  }}
                >
                  {t('Administration.Confirm')}
                </Button>
              </div>
            }
          />
        </>
      ) : null}
    </div>
  );
}
