import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { slideDown, slideUp } from 'slide-anim';

import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import { useToggleable } from './hooks/useToggleable';

import styles from './card.module.scss';

export default function Card({
  onClick,
  className,
  titleClassName,
  title,
  uniqName,
  children,
  dropdownOptions = [],
  isToggleable,
  cardRef,
  onToggle,
  titleChilderen,
  onFocus,
  onTitleClick,
}) {
  const { isOpened, toggleOption } = useToggleable(uniqName);

  useEffect(() => {
    if (onToggle) {
      onToggle(isOpened);
    }
    // eslint-disable-next-line
  }, [isOpened]);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const contentRef = useRef(null);
  if (contentRef.current && isToggleable) {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    if (isOpened) {
      slideDown(contentRef.current);
    } else {
      slideUp(contentRef.current);
    }
  }

  return (
    <div className={cn(styles.card, className)} onClick={onClick} ref={cardRef} onFocus={onFocus}>
      {title && (
        <div className={cn(styles.header, titleClassName)}>
          <div onClick={onTitleClick} className={styles.title}>
            {title}
          </div>
          {titleChilderen}
        </div>
      )}

      <div className={styles.moreBtn}>
        <MoreBtn
          dropdownOptions={isToggleable ? [toggleOption].concat(dropdownOptions) : dropdownOptions}
        />
      </div>

      <div
        className={cn(styles.content, {
          [styles.toggled]: (isFirstRender && isOpened) || isOpened,
        })}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
}
