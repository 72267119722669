import { getAppName } from 'library/api/tenant';
import { SET_TENANT_INFO } from 'library/common/commonConstants/tenantConstants';

export const getTenantInfo = () => async dispatch => {
  const appName = await getAppName().then(({ data }) => data);

  dispatch({
    type: SET_TENANT_INFO,
    payload: {
      appName,
    },
  });
};
