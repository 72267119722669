import { useState } from 'react';
import deepcopy from 'deepcopy';

import { confirmReturnReceipt, getReturnReceiptUsers } from 'library/api/returnReceipt';

export default function confirmReturnReceiptHandler({ user, id }) {
  let returnReceiptId = 0;

  const returnReceiptHandler = async () => {
    returnReceiptId = (await confirmReturnReceipt({
      posts: id,
      userId: user.id,
    })).data.id;
  };

  return {
    returnReceiptHandler,
  };
}
