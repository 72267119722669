import React, { useState } from 'react';
import useReactRouter from 'use-react-router';

import CreatePostInput from 'library/common/commonComponents/Inputs/CreatePostInput/CreatePostInputContainer';
import Feed from 'library/common/commonComponents/Feed/FeedContainer';
import ProfileSidePanel from 'library/common/commonComponents/Profile/ProfileSidePanel';

import styles from './profileStream.module.scss';

export default function ProfileStream({ match, currentUser, groups }) {
  const [newPost, setNewPost] = useState(null);
  function onCreate(post) {
    setNewPost({
      ...post,
    });
  }

  const profileId = parseInt(match.path.split('/')[2], 10);

  const { location } = useReactRouter();
  const postId = location.search.slice(1);
  return (
    <>
      <div className={styles.main}>
        {profileId === currentUser.id && (
          <CreatePostInput groupId={0} isInProfileStream onCreate={onCreate} />
        )}
        <Feed
          match={match}
          groupId={0}
          createCommentStatus
          isInProfileStream
          isPrivatePostsOnly={false}
          isPublicPostsOnly={false}
          newPost={newPost}
          postId={postId}
          profileId={profileId}
        />
      </div>

      <div className={styles.sidebar}>
        <ProfileSidePanel userId={currentUser.id} groups={groups} />
      </div>
    </>
  );
}
