import { connect } from 'react-redux';

import { setUserPermissions, setSelectedUsers } from 'library/common/commonActions/userActions';
import UsersFrameList from '.';

const mapDispatchToProps = {
  setUserPermissions,
  setSelectedUsers,
};

export const mapStateToProps = state => ({
  activeKita: state.kitaReducer.activeKita,
  superAdminStatus: state.userReducer.superAdminStatus,
  selectedUsers: state.userReducer.selectedUsers,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersFrameList);
