import React from 'react';
import { useTranslation } from 'react-i18next';

import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import styles from '../../userEditing.module.scss';

export default function UserEditingGeneral({ editingFields, setEditingFields, shouldDisableContactSettings }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.formGroup}>
        <Label type='input'>{t('Administration.UserSection.Phone Private')}</Label>
        <Input
          value={editingFields.phonePrivate}
          type='text'
          maxLength={255}
          onChange={e => setEditingFields({ ...editingFields, phonePrivate: e.target.value })}
          disabled={shouldDisableContactSettings}
        />
      </div>

      <div className={styles.formGroup}>
        <Label type='input'>{t('Administration.UserSection.Phone Work')}</Label>
        <Input
          value={editingFields.phoneWork}
          type='text'
          maxLength={255}
          onChange={e => setEditingFields({ ...editingFields, phoneWork: e.target.value })}
          disabled={shouldDisableContactSettings}
        />
      </div>

      <div className={styles.formGroup}>
        <Label type='input'>{t('Administration.UserSection.Mobile')}</Label>
        <Input
          value={editingFields.mobile}
          type='text'
          maxLength={255}
          onChange={e => setEditingFields({ ...editingFields, mobile: e.target.value })}
          disabled={shouldDisableContactSettings}
        />
      </div>

      <div className={styles.formGroup}>
        <Label type='input'>{t('Administration.UserSection.Fax')}</Label>
        <Input
          value={editingFields.fax}
          type='text'
          maxLength={255}
          onChange={e => setEditingFields({ ...editingFields, fax: e.target.value })}
          disabled={shouldDisableContactSettings}
        />
      </div>
    </div>
  );
}
