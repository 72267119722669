import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'library/common/commonComponents/Tooltip';

import styles from './fineshedLists.module.scss';

export default function FineshedLists({
  isOpened,
  hiddenTaskLists,
  onEditTaskList,
  onDeleteTaskList,
  manageTaskLists,
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(isOpened);

  useEffect(() => {
    setIsOpen(isOpened);
  }, [isOpened]);

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <i className='fa fa-check-square-o' />
        <div className={styles.title}>{t('Tasks.Finished Lists')}</div>
      </div>
      {isOpen && (
        <div className={styles.hiddenTaskListsContainer}>
          {hiddenTaskLists && hiddenTaskLists.length ? (
            hiddenTaskLists.map(hiddenTaskList => (
              <div key={hiddenTaskList.id} className={styles.hiddenTaskListContainer}>
                <div className={styles.taskListTitle}>{hiddenTaskList.taskListTitle}</div>
                {manageTaskLists && (
                  <div className={styles.taskListButtons}>
                    <div
                      className={styles.taskListButtonEdit}
                      onClick={() => onEditTaskList(hiddenTaskList)}
                    >
                      <Tooltip text={t('Tasks.Edit list')} isVisibleCondition>
                        <i className='fa fa-pencil' />
                      </Tooltip>
                    </div>
                    <div
                      className={styles.taskListButtonDelete}
                      onClick={() => onDeleteTaskList(hiddenTaskList)}
                    >
                      <Tooltip text={t('Tasks.Delete list')} isVisibleCondition>
                        <i className='fa fa-trash' />
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className={styles.emptyContainer}>{t('Tasks.No results found')}</div>
          )}
        </div>
      )}
    </div>
  );
}
