import React from 'react';
import style
  from "../../../Administration/administrationFrames/FormsFrame/FormsFrameFrames/FormsEditor/FormsEditor.module.scss";
import { getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow } from "../../../../library/utilities/forms";

export default function ParentalContributionWithHolidaysTable({
  formField,
  facilityType,
  relevantRowSchoolTime,
  relevantRowHolidays,
  submitData,
  setSubmitData }) {

  const priceRow0 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 0);
  const priceRow1 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 1);
  const priceRow2 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 2);
  const priceRow3 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 3);
  const priceRow4 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 4);
  const priceRow5 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 5);
  const priceRow6 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 6);
  const priceRow7 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 7);
  const priceRow8 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(facilityType, 8);

  const prices = [priceRow0, priceRow1, priceRow2, priceRow3, priceRow4, priceRow5, priceRow6, priceRow7, priceRow8];

  const priceRowHolidays0 = [null, null, null];
  const priceRowHolidays1 = [null, null, null];
  const priceRowHolidays2 = [null, null, null];
  const priceRowHolidays3 = [null, null, null];
  const priceRowHolidays4 = [null, null, null];
  const priceRowHolidays5 = [null, null, null];
  const priceRowHolidays6 = [206, 206, 206];
  const priceRowHolidays7 = [211, 211, 211];
  const priceRowHolidays8 = [216, 216, 216];

  const holidayPrices = [priceRowHolidays0, priceRowHolidays1, priceRowHolidays2, priceRowHolidays3, priceRowHolidays4, priceRowHolidays5, priceRowHolidays6, priceRowHolidays7, priceRowHolidays8];

  const monthsString = submitData ? submitData.formFields[`${formField.id}`] : '';

  const monthsInt = parseInt(monthsString, 10);
  const holidayMonths = !isNaN(monthsString) && !isNaN(monthsInt) && 0 <= monthsInt && monthsInt <= 12 ? 12 - monthsInt : 0;


  return (
    <div style={{ marginTop: '3em' }}>
      <table className={style.table}>
        <tr>
          <th></th>
          <th>Regelmäßige Buchungskategorie in der Schulzeit</th>
          <th></th>
          <th colSpan="3">Buchungskategorie in der Schulferienzeit</th>
        </tr>
        <tr>
          <th>Buchungszeit-Kategorien</th>
          <td>
            Buchung = <input
              type='number'
              className={style.inlineInput}
              value={monthsString}
              onChange={e => {
                setSubmitData({
                  ...submitData,
                  formFields: {
                    ...submitData.formFields,
                    [`${formField.id}`]: e.target.value,
                  },
                });
              }}
            /> Monate
          </td>
          <th>Beitrag in € monatlich</th>
          <td style={{ minWidth: '100px' }}>ab 15 Tage***) = 1 Monat förderfähig</td>
          <td style={{ minWidth: '100px' }}>ab 30 Tage***) = 2 Monate förderfähig</td>
          <td style={{ minWidth: '100px' }}>ab 45 Tage***) = 3 Monate förderfähig</td>
        </tr>
        <tr>
          <td>> 1 bis 2 Stunden **)</td>
          <td>{relevantRowSchoolTime === 0 ? priceRow0 : ''}</td>
          <td>{relevantRowSchoolTime === 0 ? priceRow0 : ''}</td>
          <td>{relevantRowHolidays === 0 && holidayMonths === 1 ? priceRowHolidays0[0] : ''}</td>
          <td>{relevantRowHolidays === 0 && holidayMonths === 2 ? priceRowHolidays0[1] : ''}</td>
          <td>{relevantRowHolidays === 0 && holidayMonths >= 3 ? priceRowHolidays0[2] : ''}</td>
        </tr>
        <tr>
          <td>> 2 bis 3 Stunden **)</td>
          <td>{relevantRowSchoolTime === 1 ? priceRow1 : ''}</td>
          <td>{relevantRowSchoolTime === 1 ? priceRow1 : ''}</td>
          <td>{relevantRowHolidays === 1 && holidayMonths === 1 ? priceRowHolidays1[0] : ''}</td>
          <td>{relevantRowHolidays === 1 && holidayMonths === 2 ? priceRowHolidays1[1] : ''}</td>
          <td>{relevantRowHolidays === 1 && holidayMonths >= 3 ? priceRowHolidays1[2] : ''}</td>
        </tr>
        <tr>
          <td>> 3 bis 4 Stunden **)</td>
          <td>{relevantRowSchoolTime === 2 ? priceRow2 : ''}</td>
          <td>{relevantRowSchoolTime === 2 ? priceRow2 : ''}</td>
          <td>{relevantRowHolidays === 2 && holidayMonths === 1 ? priceRowHolidays2[0] : ''}</td>
          <td>{relevantRowHolidays === 2 && holidayMonths === 2 ? priceRowHolidays2[1] : ''}</td>
          <td>{relevantRowHolidays === 2 && holidayMonths >= 3 ? priceRowHolidays2[2] : ''}</td>
        </tr>
        <tr>
          <td>> 4 bis 5 Stunden **)</td>
          <td>{relevantRowSchoolTime === 3 ? priceRow3 : ''}</td>
          <td>{relevantRowSchoolTime === 3 ? priceRow3 : ''}</td>
          <td>{relevantRowHolidays === 3 && holidayMonths === 1 ? priceRowHolidays3[0] : ''}</td>
          <td>{relevantRowHolidays === 3 && holidayMonths === 2 ? priceRowHolidays3[1] : ''}</td>
          <td>{relevantRowHolidays === 3 && holidayMonths >= 3 ? priceRowHolidays3[2] : ''}</td>
        </tr>
        <tr>
          <td>> 5 bis 6 Stunden **)</td>
          <td>{relevantRowSchoolTime === 4 ? priceRow4 : ''}</td>
          <td>{relevantRowSchoolTime === 4 ? priceRow4 : ''}</td>
          <td>{relevantRowHolidays === 4 && holidayMonths === 1 ? priceRowHolidays4[0] : ''}</td>
          <td>{relevantRowHolidays === 4 && holidayMonths === 2 ? priceRowHolidays4[1] : ''}</td>
          <td>{relevantRowHolidays === 4 && holidayMonths >= 3 ? priceRowHolidays4[2] : ''}</td>
        </tr>
        <tr>
          <td>> 6 bis 7 Stunden **)</td>
          <td>{relevantRowSchoolTime === 5 ? priceRow5 : ''}</td>
          <td>{relevantRowSchoolTime === 5 ? priceRow5 : ''}</td>
          <td>{relevantRowHolidays === 5 && holidayMonths === 1 ? priceRowHolidays5[0] : ''}</td>
          <td>{relevantRowHolidays === 5 && holidayMonths === 2 ? priceRowHolidays5[1] : ''}</td>
          <td>{relevantRowHolidays === 5 && holidayMonths >= 3 ? priceRowHolidays5[2] : ''}</td>
        </tr>
        <tr>
          <td>> 7 bis 8 Stunden **)</td>
          <td>{relevantRowSchoolTime === 6 ? priceRow6 : ''}</td>
          <td>{relevantRowSchoolTime === 6 ? priceRow6 : ''}</td>
          <td>{relevantRowHolidays === 6 && holidayMonths === 1 ? priceRowHolidays6[0] : ''}</td>
          <td>{relevantRowHolidays === 6 && holidayMonths === 2 ? priceRowHolidays6[1] : ''}</td>
          <td>{relevantRowHolidays === 6 && holidayMonths >= 3 ? priceRowHolidays6[2] : ''}</td>
        </tr>
        <tr>
          <td>> 8 bis 9 Stunden **)</td>
          <td>{relevantRowSchoolTime === 7 ? priceRow7 : ''}</td>
          <td>{relevantRowSchoolTime === 7 ? priceRow7 : ''}</td>
          <td>{relevantRowHolidays === 7 && holidayMonths === 1 ? priceRowHolidays7[0] : ''}</td>
          <td>{relevantRowHolidays === 7 && holidayMonths === 2 ? priceRowHolidays7[1] : ''}</td>
          <td>{relevantRowHolidays === 7 && holidayMonths >= 3 ? priceRowHolidays7[2] : ''}</td>
        </tr>
        <tr>
          <td>> 9 bis 10 Stunden **)</td>
          <td>{relevantRowSchoolTime === 8 ? priceRow8 : ''}</td>
          <td>{relevantRowSchoolTime === 8 ? priceRow8 : ''}</td>
          <td>{relevantRowHolidays === 8 && holidayMonths === 1 ? priceRowHolidays8[0] : ''}</td>
          <td>{relevantRowHolidays === 8 && holidayMonths === 2 ? priceRowHolidays8[1] : ''}</td>
          <td>{relevantRowHolidays === 8 && holidayMonths >= 3 ? priceRowHolidays8[2] : ''}</td>
        </tr>
      </table>

      {!isNaN(monthsInt) && 0 <= monthsInt && monthsInt <= 12 && relevantRowSchoolTime && relevantRowHolidays ? (
        <p style={{ marginTop: '2em' }}>Für {monthsInt} Monate {prices[relevantRowSchoolTime]} € + {holidayMonths} Monate {holidayMonths > 0 && holidayPrices[relevantRowHolidays][Math.min(holidayMonths - 1, 2)] !== null ? holidayPrices[relevantRowHolidays][Math.min(holidayMonths - 1, 2)] : 0} € = {monthsInt * prices[relevantRowSchoolTime] + holidayMonths * (holidayMonths > 0 ? holidayPrices[relevantRowHolidays][Math.min(holidayMonths - 1, 2)] : 0)} € / 12 Monate = {((monthsInt * prices[relevantRowSchoolTime] + holidayMonths * (holidayMonths > 0 ? holidayPrices[relevantRowHolidays][Math.min(holidayMonths - 1, 2)] : 0)) / 12).toFixed(2)} € / 1 Monat</p>
      ) : (
        <p style={{ marginTop: '2em' }}>Für ___ Monate ___ € + ___ Monate ___ € = ___ € / 12 Monate = ___ € / 1 Monat</p>
      )}

    </div>
  );
}
