import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from './groupAcceptPopup.module.scss';

export default function GroupAcceptPopup({ isShowPopup, closePopup, onConfirm, isSubmitting }) {
  const { t } = useTranslation();
  const link = t('Group.For further questions, please find our /Privacy Policy').split('/');

  return (
    <Popup
      isOpened={isShowPopup}
      closePopup={closePopup}
      showHeader={false}
      size='small'
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={closePopup}>{t('Confirm.Cancel')}</Button>
          <ButtonWithLoader onClick={onConfirm} type='primary' isLoading={isSubmitting}>
            {t('Confirm.Confirm')}
          </ButtonWithLoader>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Trans i18nKey='Confirm.Confirm Action' />
        </div>
        <div>{t('Group.AcceptText')}</div>
        <div className={styles.linkWrapper}>
          <span>{link[0]}</span>
          <Link to='/data-protection'>
            <span className={styles.link}>{link[1]}</span>
          </Link>
        </div>
      </div>
    </Popup>
  );
}
