import React, { useReducer, useState, useRef, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';

import { getGroupModules, getGroupPermission } from 'library/api/groups';
import { getKidsByGroupId } from 'library/api/kids';
import { logout } from 'library/api/logout';
import { getAllPostTemplates } from 'library/api/posts';
import Loader from 'library/common/commonComponents/Loader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from 'library/common/commonComponents/Popups/Popup';
import useGroupsLoading from 'library/common/commonHooks/groups/useGroupsLoading';
import { filterIsNotOnlyPublicKitaMember } from 'library/utilities/kitaChecks';
import Storage from 'library/utilities/storage';
import { isPublicUser } from 'library/utilities/user';
import betreuungsvertraegeKurzbeschreibung from 'resources/others/betreuungsvertraegeKurzbeschreibung.pdf';

import FeedItem from './feedFrames/feedItem';
import FeedFilter from './feedFrames/feedFilter';
import useFeedsLoading from './hooks/useFeedsLoading';
import { getIsGroupAdmin } from 'library/api/groups';

import styles from './feed.module.scss';
import { isAwoWW } from 'library/api/tenantConfig';

export const initialState = { filters: {} };

export function reducer(state, action) {
  switch (action.type) {
    case 'updateFilters':
      return { filters: action.filters };
    default:
      return state;
  }
}

export default function Feed({
  match,
  user,
  newPost,
  isPrivatePostsOnly,
  isPublicPostsOnly,
  onPostDelete,
  group,
  showBottomNotification,
  postId,
  isInProfileStream,
  isInPublicPage,
  selectedKitas,
  selectedGroups,
  isOnDashboard,
  kitas,
  activeKita,
  updateBreadcrumbs,
  reusableParentSurvey,
  setReusableParentSurvey,
  ...loadingOptions
}) {
  const prevCreatedPost = useRef(null);
  const [{ filters }, dispatch] = useReducer(reducer, initialState);
  const [canLoadFeeds, setCanLoadFeeds] = useState(false);
  const [canShowNotFound, setCanShowNotFound] = useState(false);
  const [postTemplates, setPostTemplates] = useState([]);
  const [kidsPerGroup, setKidsPerGroup] = useState({});
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [filterFavorite, setFilterFavorite] = useState(false);
  const filterUnchanged = useRef(true);
  useEffect(() => {
    if (filterUnchanged.current) {
      setFilterFavorite(isOnDashboard && !isPublicUser(user) && isAwoWW());
    }
  }, [user]);

  useEffect(() => {
    if (group && group.groupId) {
      getGroupPermission(group.groupId).then(res => {
        setGroupPermissions(res.data);
      });
      getIsGroupAdmin(group.groupId).then(res => {
        setIsGroupAdmin(res.data);
      });
    }
  }, [group]);

  useEffect(() => {
    return () => Storage.setItem('currentFilters', null);
  }, []);
  useEffect(() => {
    if (canLoadFeeds) {
      setTimeout(() => setCanShowNotFound(true), 200);
    }
  }, [canLoadFeeds]);

  useEffect(() => {
    prevCreatedPost.current = newPost;
    if (newPost) {
      addFeed({
        ...newPost,
        name: `${user.firstName} ${user.lastName}`,
        avatar: null,
        postlink: '#',
        userlink: '#',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPost]);

  let { groups, loadGroups } = useGroupsLoading();
  const [excludedGroupIds, setExcludedGroupIds] = useState([]);

  const favoriteGroups = groups.filter(group => group.favorite).map(group => group.id);
  const nonFavoriteGroups = groups.filter(group => !group.favorite).map(group => group.id);

  const {
    feeds,
    addFeed,
    isLoading,
    updateFeedById,
    deleteFeedById,
    hideFeedById,
    newFeeds,
    isFeedsEnded,
    reportToAdmin,
  } = useFeedsLoading({
    user,
    ...loadingOptions,
    postId,
    isPrivatePostsOnly,
    isPublicPostsOnly,
    filters,
    canLoadFeeds,
    isOnDashboard,
    isInProfileStream,
    isInPublicPage,
    selectedKitas,
    selectedGroups,
    filteredGroupsOnScroll: filterFavorite ? nonFavoriteGroups : [],
  });

  const favoriteFeeds = feeds.filter(post => post?.group?.favorite);
  const nonFavoriteFeeds = feeds.filter(post => !post?.group?.favorite);

  const loadMoreFavoriteFeeds = () => {
    newFeeds(favoriteGroups);
  };
  useEffect(() => {
    // initially load atleast 20 posts
    if (filterFavorite) {
      loadMoreFavoriteFeeds();
    }
  }, [filterFavorite]);

  const deleteFeed = async feed => {
    const res = await deleteFeedById(feed.id);
    if (res.success) {
      onPostDelete();
    }
    return res;
  };

  const handleHidePost = (currentStatus, postId) => {
    hideFeedById(postId, currentStatus);
  };

  const onReportToAdmin = (id, groupID, name) => {
    reportToAdmin(id, groupID, name);
  };

  const { t } = useTranslation();

  useEffect(() => {
    loadGroups();
    if (postId) {
      setCanLoadFeeds(true);
    }

    getAllPostTemplates().then(res => {
      setPostTemplates(res.data);
    });
    // eslint-disable-next-line
  }, []);

  const { location, history } = useReactRouter();
  const goBackToStream = () => {
    history.push(location.pathname);
  };

  useEffect(() => {
    const seenGroupIds = {};
    const feedsWithoutDuplicateGroups = feeds.filter(feed => {
      if (!seenGroupIds[feed.groupId]) {
        seenGroupIds[feed.groupId] = true;
        return true;
      }
      return false;
    });
    const feedsKidsPerGroup = feedsWithoutDuplicateGroups.map(async feed => {
      const data = await getKidsByGroupId(feed.groupId);
      return data?.data?.length ?? 0;
    });
    Promise.all(feedsKidsPerGroup).then(data => {
      setKidsPerGroup(
        feedsWithoutDuplicateGroups.reduce(
          (newKidsPerGroup, feed, index) => ({ ...newKidsPerGroup, [feed.groupId]: data[index] }),
          {},
        ),
      );
    });
  }, [JSON.stringify(feeds)]);

  useEffect(() => {
    const fetchParentSurveyModuleDeactivatedGroupIds = async () => {
      let groupList = [...groups];
      if (user.administrationAccess || user.superAdminStatus || (group && group.groupAdminStatus)) {
        if (groups && groups !== 'undefined' && groups.length > 0) {
          groupList = groupList.filter(
            item => item.groupName.slice(-11) !== '(Portfolio)' && !item.globalPublicGroup,
          );

          Promise.all(
            groupList.map(
              group =>
                new Promise(async resolve => {
                  getGroupModules(group.id).then(res => {
                    const parentSurveyModule = res.data.find(
                      module => module.moduleKey === 'parent_survey',
                    );
                    if (!parentSurveyModule || !parentSurveyModule.activeStatus) {
                      resolve(group.id);
                    }
                    resolve(null);
                  });
                }),
            ),
          ).then(data => {
            setExcludedGroupIds(data.filter(id => id !== null));
          });
        }
      }
    };

    fetchParentSurveyModuleDeactivatedGroupIds();
  }, [user, groups, group]);

  const isLikeFeatureAvailableInGroup = useMemo(() => {
    let postLikePermission;
    if (user.administrationAccess || user.superAdminStatus || (group && group.groupAdminStatus)) {
      const adminPermission = groupPermissions.find(item => item.adminRole);
      postLikePermission = adminPermission?.groupPermissions.find(
        permission => permission.groupPermissionTag === 'LIKE-POST',
      );
    } else {
      const memberPermission = groupPermissions.find(item => !item.adminRole);
      postLikePermission = memberPermission?.groupPermissions.find(
        permission => permission.groupPermissionTag === 'LIKE-POST',
      );
    }
    if (postLikePermission) {
      return postLikePermission?.selectedStatus;
    } else {
      return true;
    }
  }, [groupPermissions, user, group]);

  const availableGroups = useMemo(() => {
    let groupList = [];
    if (user.administrationAccess || user.superAdminStatus || (group && group.groupAdminStatus)) {
      if (groups && groups !== 'undefined' && groups.length > 0) {
        groupList = groups.filter(
          item =>
            item.groupName.slice(-11) !== '(Portfolio)' &&
            !item.globalPublicGroup &&
            !excludedGroupIds.includes(item.id),
        );
      }
    }

    return groupList;
  }, [user, groups, excludedGroupIds]);

  const feedsJSX = filterFavorite ? (
    <>
      <div className={styles.divider} />
      <h1 className={styles.filterSectionHeadline}>{t('Dashboard.InfoFromFavorite')}</h1>
      {favoriteFeeds.map(feed => (
        <FeedItem
          groups={availableGroups}
          kidsCount={kidsPerGroup[feed.groupId]}
          match={match}
          key={feed.id}
          {...feed}
          files={feed.files.sort((a, b) => a.sortOrder - b.sortOrder)}
          updateFeedById={updateFeedById}
          onDelete={() => deleteFeed(feed)}
          onHidePost={handleHidePost}
          onReportToAdmin={onReportToAdmin}
          hidePost={feed?.hidePosts?.findIndex(x => x.userId === user.id) !== -1}
          user={user}
          canBePublic
          isInGroup={!!group}
          group={group || feed.group}
          isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
          showBottomNotification={showBottomNotification}
          isInProfileStream={isInProfileStream}
          isOnDashboard={isOnDashboard}
          activeKita={activeKita}
          updateBreadcrumbs={updateBreadcrumbs}
          isNotOnlyPublicKitaMemberVar={filterIsNotOnlyPublicKitaMember(kitas)}
          kitaId={feed.kitaId}
          postTemplate={postTemplates.find(template => template.post.id === feed.id)}
          reusableParentSurvey={reusableParentSurvey}
          setReusableParentSurvey={setReusableParentSurvey}
          isGroupAdmin={isGroupAdmin}
        />
      ))}
      {!isLoading && favoriteGroups.length !== 0 && !isFeedsEnded[favoriteGroups] && (
        <div className={styles.loadMoreWrapper}>
          <Button type='primary' onClick={loadMoreFavoriteFeeds}>
            {t('Dashboard.loadMore')}
          </Button>
        </div>
      )}
      {isLoading && favoriteGroups.length !== 0 && !isFeedsEnded[favoriteGroups] && (
        <Loader className={styles.loader} />
      )}
      {(isFeedsEnded[favoriteGroups] || favoriteGroups.length == 0) && (
        <div className={styles.notFound}>{t('Dashboard.No further posts found')}</div>
      )}
      <div className={styles.divider} />
      <h1 className={styles.filterSectionHeadline}>{t('Dashboard.InfoFromOthers')}</h1>
      {nonFavoriteFeeds.map(feed => (
        <FeedItem
          groups={availableGroups}
          kidsCount={kidsPerGroup[feed.groupId]}
          match={match}
          key={feed.id}
          {...feed}
          files={feed.files.sort((a, b) => a.sortOrder - b.sortOrder)}
          updateFeedById={updateFeedById}
          onDelete={() => deleteFeed(feed)}
          onHidePost={handleHidePost}
          onReportToAdmin={onReportToAdmin}
          hidePost={feed?.hidePosts?.findIndex(x => x.userId === user.id) !== -1}
          user={user}
          canBePublic
          isInGroup={!!group}
          group={group || feed.group}
          isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
          showBottomNotification={showBottomNotification}
          isInProfileStream={isInProfileStream}
          isOnDashboard={isOnDashboard}
          activeKita={activeKita}
          updateBreadcrumbs={updateBreadcrumbs}
          isNotOnlyPublicKitaMemberVar={filterIsNotOnlyPublicKitaMember(kitas)}
          kitaId={feed.kitaId}
          postTemplate={postTemplates.find(template => template.post.id === feed.id)}
          reusableParentSurvey={reusableParentSurvey}
          setReusableParentSurvey={setReusableParentSurvey}
          isGroupAdmin={isGroupAdmin}
        />
      ))}
    </>
  ) : (
    <>
      {feeds.map(feed => (
        <FeedItem
          groups={availableGroups}
          kidsCount={kidsPerGroup[feed.groupId]}
          match={match}
          key={feed.id}
          {...feed}
          files={feed.files.sort((a, b) => a.sortOrder - b.sortOrder)}
          updateFeedById={updateFeedById}
          onDelete={() => deleteFeed(feed)}
          onHidePost={handleHidePost}
          onReportToAdmin={onReportToAdmin}
          hidePost={feed?.hidePosts?.findIndex(x => x.userId === user.id) !== -1}
          user={user}
          canBePublic
          isInGroup={!!group}
          group={group || feed.group}
          isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
          showBottomNotification={showBottomNotification}
          isInProfileStream={isInProfileStream}
          isOnDashboard={isOnDashboard}
          activeKita={activeKita}
          updateBreadcrumbs={updateBreadcrumbs}
          isNotOnlyPublicKitaMemberVar={filterIsNotOnlyPublicKitaMember(kitas)}
          kitaId={feed.kitaId}
          postTemplate={postTemplates.find(template => template.post.id === feed.id)}
          reusableParentSurvey={reusableParentSurvey}
          setReusableParentSurvey={setReusableParentSurvey}
          isGroupAdmin={isGroupAdmin}
        />
      ))}
    </>
  );

  return (
    <>
      {activeKita &&
        activeKita.lastAccess !== undefined &&
        activeKita.lastAccess == null &&
        window.location.hostname.toLowerCase().includes('awobamberg.safe2connect.org') && (
          <Popup
            isOpened
            closePopup={() => {
              window.location.reload();
            }}
            header={t('FirstLogin.Hint')}
            footer={
              <div>
                <div>{t('FirstLogin.Text')}</div>
                <br />
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  {t('FirstLogin.Confirm')}
                </Button>
              </div>
            }
          />
        )}

      {postId && (
        <Button type='primary' className={styles.backToStream} onClick={goBackToStream}>
          {t('Group.Back to stream')}
        </Button>
      )}
      {!postId && kitas && kitas.length > 0 && kitas[0] && kitas[0].kitaId && (
        <div>
          <FeedFilter
            updateFilters={newFilters => {
              Storage.setItem('currentFilters', newFilters);
              dispatch({
                type: 'updateFilters',
                filters: newFilters,
              });
            }}
            filterFavorite={
              isOnDashboard && !isPublicUser(user) && isAwoWW() ? filterFavorite : null
            }
            setFilterFavorite={
              isOnDashboard && !isPublicUser(user) && isAwoWW()
                ? value => {
                    filterUnchanged.current = false;
                    setFilterFavorite(value);
                  }
                : null
            }
            showVisibilityFilters
            showAuthorContentFilters={!isInProfileStream}
            userId={user && user.id}
            group={group}
            onFiltersLoad={() => requestAnimationFrame(() => setCanLoadFeeds(true))}
            postType={loadingOptions.postType}
          />
        </div>
      )}
      {!postId && kitas && !kitas.length && (
        <div className={styles.warningWrapper}>
          {t(
            'Dashboard.You will not be able to see any personal data unless you fill the corresponding Kita form',
          )}
        </div>
      )}
      {!postId &&
        activeKita &&
        activeKita.kitaId &&
        (activeKita.kitaId === 108 || activeKita.kitaId === 3627) &&
        isOnDashboard && (
          <div className={styles.warningWrapper}>
            {t(
              'Dashboard.To register for weekend care please use the calendar and the following link',
            )}{' '}
            <span
              style={{ color: 'hotpink', cursor: 'pointer' }}
              onClick={() => {
                history.push('/calendar');
              }}
            >
              {t('Dashboard.Calendarlink')}
            </span>
          </div>
        )}
      {!postId && isPublicUser(user) && isOnDashboard && (
        <div className={styles.warningWrapper}>
          <a
            className={styles.authButton}
            onClick={e => {
              e.preventDefault();
              localStorage.removeItem('selectedKitas');
              localStorage.removeItem('selectedGroups');
              logout();
            }}
            href='/'
          >
            <i className='fa fa-sign-in' aria-hidden='true' /> {t('Dashboard.Login')}
          </a>
          <a href='/loggedinregistration'>
            <i className='fa fa-user-plus' /> {t('Dashboard.Register now')}
          </a>
        </div>
      )}
      {!postId &&
        activeKita &&
        activeKita.description &&
        activeKita.description !== null &&
        activeKita.description.includes('Betreuungsverträge') &&
        isOnDashboard && (
          <div className={styles.warningWrapper}>
            <b>{t('Dashboard.CareContractInformation')}</b> <br />
            <br />
            <span>{t('Dashboard.DescriptionAccountSettings')}</span>
            <br />
            <br />
            <ul>
              <li>
                <a
                  href={betreuungsvertraegeKurzbeschreibung}
                  download='BetreuungsvertraegeKurzbeschreibung.pdf'
                >
                  <span
                    style={{ color: '#0000FF', cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    {t('Dashboard.DownloadCareContractShortDescription')}
                  </span>{' '}
                  <i className='fa fa-file-pdf-o' />
                </a>
              </li>
              <br />
              <li>
                <span
                  style={{ color: '#0000FF', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    history.push('/account-settings/profile/general');
                  }}
                >
                  {t('Dashboard.LinkToAccountSettings')}
                </span>
              </li>
              <br />
              <li>
                <span
                  style={{ color: '#0000FF', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    history.push('/forms/defaultContract/edit');
                  }}
                >
                  {t('Dashboard.LinkToCareContract')}
                </span>
              </li>
            </ul>
          </div>
        )}
      {feedsJSX}
      {isLoading && <Loader className={styles.loader} />}
      {!isLoading && canShowNotFound && feeds.length === 0 && (
        <div>
          {postId ? (
            <div className={styles.postNotFound}>
              <div className={styles.postNotFoundDescription}>{t('Filters.Oops description')}</div>
              <div className={styles.postNotFoundText}>{t('Filters.Oops not found')}</div>
            </div>
          ) : (
            <div className={styles.notFound}>{t('Filters.Not found')}</div>
          )}
        </div>
      )}
    </>
  );
}

Feed.defaultProps = {
  onPostDelete: () => {},
  isPublicPostsOnly: false,
};
