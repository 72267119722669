import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';

import { createKidDevelopmentDiaryEntry } from 'library/api/kidDevelopmentDiary';
import { getKidVerificationImage } from 'library/api/user';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Tooltip from 'library/common/commonComponents/Tooltip';
import store from 'main/store/configureStore';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import CheckinGroupSelectionPopup from './CheckinGroupSelectionPopup';
import PresenceTimePopup from './PresenceTimePopup';

import styles from './childRow.module.scss';

const ChildRow = ({
  isHortType,
  childObj,
  groupSelectionOnCheckinActive,
  notifyToParentsOnCheckInOutActive,
  verificationImages,
  handleUpdateKidStatus,
}) => {
  const { t } = useTranslation();

  const [note, setNote] = useState('');
  const [time, setTime] = useState('');
  const [isValidTime, setIsValidTime] = useState(true);
  const [isGroupSelectPopupOpen, setIsGroupSelectPopupOpen] = useState(false);
  const [isPresenceTimePopupOpen, setIsPresenceTimePopupOpen] = useState(false);
  const [curGroupName, setCurGroupName] = useState(childObj.groupName);
  const [openNoteFiled, setOpenNoteFiled] = useState(false);
  const [selectedKidVerificationImage, setSelectedKidVerificationImage] = useState(null);

  useEffect(() => {
    const date = new Date();
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const currentTime = `${hours}:${minutes}`;
    setTime(currentTime);
  }, []);

  const getStatus = () => {
    const status =
      childObj.checkInOutStatus === 'CHECKIN'
        ? 'CHECKIN'
        : childObj.kidAbsence
        ? childObj.kidAbsence.vacation
          ? 'VACATION'
          : 'SICK'
        : childObj.checkInOutStatus;

    return (
      <div
        className={cn(
          styles.statusContainer,
          status === 'VACATION' && styles.statusVacation,
          status === 'SICK' && styles.statusSick,
          status === 'CHECKIN' && styles.statusCheckin,
          status === 'CHECKOUT' && styles.statusCheckout,
        )}
      >
        <div
          className={styles.statusIconWrapper}
          onClick={() => {
            if (status === 'CHECKOUT' && groupSelectionOnCheckinActive) {
              handleGroupSelectionPopupOpen();
            } else {
              handleChangeKidStatus();
            }
          }}
        >
          <i
            className={cn(status === 'CHECKIN' ? 'fa fa-lg fa-folder' : 'fa fa-lg fa-folder-open')}
          />
        </div>
        <div className={styles.statusLabel}>
          {status === 'VACATION' && t('Checkinout.Vacation')}
          {status === 'SICK' && t('Checkinout.Sick')}
          {status === 'CHECKIN' && t('Checkinout.Checked In')}
          {status === 'CHECKOUT' && t('Checkinout.Checked Out')}
          {/* t('Checkinout.Absent' */}
        </div>
      </div>
    );
  };

  const handleGroupSelectionPopupOpen = () => {
    setIsGroupSelectPopupOpen(true);
    getKidVerificationImage(childObj.kidId)
      .then(res => {
        if (res.status === 200) {
          setSelectedKidVerificationImage(res.data);
        }
      })
      .catch(err => {
        setSelectedKidVerificationImage(null);
        console.log(err);
      });
  };

  const handlePresenceTimePopupOpen = () => {
    setIsPresenceTimePopupOpen(true);
  };

  const getNewStatus = () => {
    return childObj.checkInOutStatus === 'CHECKIN' ? 'CHECKOUT' : 'CHECKIN';
  };

  const handleChangeKidStatus = async () => {
    const newStatus = getNewStatus();
    const date = new Date();
    const [hours, minutes] = time.split(':');
    date.setHours(hours);
    date.setMinutes(minutes);
    const isoString = date.toISOString();
    await handleUpdateKidStatus({
      checkInOutType: newStatus,
      kidId: childObj.kidId,
      checkedDate: isoString,
      notifyToParents: notifyToParentsOnCheckInOutActive,
    });

    if (
      newStatus === 'CHECKOUT' &&
      typeof childObj.mainGroupName !== 'undefined' &&
      childObj.mainGroupName !== null &&
      childObj.mainGroupName !== '' &&
      groupSelectionOnCheckinActive
    ) {
      setCurGroupName(childObj.mainGroupName);
    }
  };

  const handleTimeChange = e => {
    const selectedTime = e.target.value;
    if (selectedTime < '06:00') {
      setIsValidTime(false);
      setTime('06:00');
    } else if (selectedTime > '18:00') {
      setIsValidTime(false);
      setTime('18:00');
    } else {
      setIsValidTime(true);
      setTime(selectedTime);
    }
  };

  const handleOpenNoteField = () => {
    setOpenNoteFiled(true);
  };

  return (
    <div className={styles.wrapper}>
      {isGroupSelectPopupOpen && (
        <CheckinGroupSelectionPopup
          isOpen={isGroupSelectPopupOpen}
          setOpen={setIsGroupSelectPopupOpen}
          selectedKidVerificationImage={selectedKidVerificationImage}
          verificationImages={verificationImages}
          handleChangeKidStatus={() => handleChangeKidStatus()}
          kid={childObj}
          curGroupName={curGroupName}
          setCurGroupName={setCurGroupName}
        />
      )}

      <PresenceTimePopup
        child={childObj}
        isShowPopup={isPresenceTimePopupOpen}
        closePopup={() => setIsPresenceTimePopupOpen(false)}
      />
      <div className={styles.photo}>
        <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
      </div>
      <div className={styles.name}>
        {childObj.name}
        {childObj.parent1Name !== null && (
          <span className={styles.parent}>{childObj.parent1Name}</span>
        )}
        {childObj.parent2Name !== null && (
          <span className={styles.parent}>{childObj.parent2Name}</span>
        )}
      </div>
      {isHortType && <div className={styles.class}>{childObj?.className}</div>}
      <div className={styles.group}>{curGroupName}</div>
      {isHortType && <div className={styles.group}>{childObj?.mainGroupName}</div>}
      <div className={styles.statusWrap}>
        <>
          {getStatus()}
          {openNoteFiled ? (
            <Input
              className={styles.note}
              value={note}
              placeholder={t('Checkinout.Note')}
              onChange={e => setNote(e.target.value)}
            />
          ) : (
            <div className={cn(styles.statusContainer, styles.statusCheckout)}>
              <div className={styles.statusIconWrapper} onClick={() => handleOpenNoteField()}>
                <i className='fa fa-lg fa-sticky-note' />
              </div>
              <div className={styles.statusLabel}>{t('Checkinout.Note')}</div>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <Input type={'time'} value={time} onChange={e => handleTimeChange(e)} />
            <Button
              onClick={() => {
                createKidDevelopmentDiaryEntry(childObj.kidId, 'checkinout', note)
                  .then(() => {
                    setNote('');
                    store.dispatch(
                      showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
                        isFail: false,
                      }),
                    );
                  })
                  .catch(() => {
                    store.dispatch(
                      showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
                        isFail: true,
                      }),
                    );
                  });
              }}
            >
              <i className='fa fa-arrow-right' />
            </Button>
            <Tooltip position='left' text={t('PresenceTime.Set for specific day before')}>
              <Button onClick={handlePresenceTimePopupOpen}>
                <i className='fa fa-plus' />
              </Button>
            </Tooltip>
          </div>
        </>
      </div>
    </div>
  );
};

ChildRow.propTypes = {
  isHortType: PT.bool.isRequired,
  childObj: PT.shape({
    kidId: PT.number.isRequired,
    name: PT.string.isRequired,
    groupLogoUrl: PT.string,
    parent1Name: PT.string,
    parent2Name: PT.string,
    checkInOutStatus: PT.string.isRequired,
  }).isRequired,
  handleUpdateKidStatus: PT.func.isRequired,
};

export default ChildRow;
