import React, { useEffect, useState } from 'react';
import styles from './arrivalRow.module.scss';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import PT from 'prop-types';
import { changeArrivedData } from 'library/api/kids';
import cn from 'classnames';

const ArrivalRow = ({ childObj }) => {

    const [arrivedData, setArrivedData] = useState(false);
    const [updateCount, setUpdateCount] = useState(1);
    useEffect(() => {
        var check = false
        if (childObj) {
            if (updateCount == 1) {
              check = childObj.kidInKita;
              setUpdateCount(updateCount+1);
            } else {
              check = arrivedData ? true : false;
            }
            setArrivedData(check);
          }
        if(childObj.kidInKita) {
            scheduleApiCall();
        }
    }, []);

    const handleChangeKidStatus = () => {
        changeArrivedData(childObj.id, !arrivedData);
        setArrivedData(!arrivedData)
    }

    function callApi() {
        if (childObj.kidInKita) {
            changeArrivedData(childObj.id, !childObj.kidInKita);
        }
    }

    function scheduleApiCall() {
        const now = new Date();
        const targetTime = new Date(now);
        targetTime.setHours(0, 0, 0, 0); // Set the target time to 20:50:00

        const timeUntilApiCall = targetTime - now;

        if (timeUntilApiCall > 0) {
            setTimeout(() => {
                callApi(); // Call your API function
                scheduleApiCall(); // Schedule the next call for the next day
            }, timeUntilApiCall);
        } else {
            // If it's already past 20:50, schedule the call for the next day
            targetTime.setDate(targetTime.getDate() + 1);
            const timeUntilNextApiCall = targetTime - now;
            setTimeout(() => {
                callApi(); // Call your API function
                scheduleApiCall(); // Schedule the next call for the next day
            }, timeUntilNextApiCall);
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.photo}>
                {childObj.groups != null && (
                    <img src={childObj.groups.logoUrl || defaultAvatar} alt='' />
                )}
                {childObj.groups == null && (
                    <img src={defaultAvatar} alt='' />
                )}
            </div>
            <div className={styles.name}>
                {childObj.firstName + " " + childObj.lastName}
                {childObj.parent1 !== null && childObj.parent1.firstName !== null && (
                    <span className={styles.parent}>{childObj.parent1.firstName}</span>
                )}
                {childObj.parent2 !== null && childObj.parent2.firstName !== null && (
                    <span className={styles.parent}>{childObj.parent2.firstName}</span>
                )}
            </div>
            <div className={styles.group}>
                {childObj.groups != null && childObj.groups.groupName != + null && (
                    <span>{childObj.groups.groupName}</span>)}
            </div>
            &nbsp;
            <div className={styles.trafficLight}>
                {(childObj.kidCanGoHomeOnTheirOwn || childObj.kidCanGoHomeOnlyToday) && (
                    <div className={styles.greenLight}></div>
                )}
                {!childObj.kidCanGoHomeOnTheirOwn && !childObj.kidCanGoHomeOnlyToday && (
                    <div className={styles.redLight}></div>
                )}
            </div>
            <div className={styles.statusWrap}>
                <div className={cn(styles.statusContainer, arrivedData && styles.statusCheckin, !arrivedData && styles.statusCheckout)}>
                    <div className={styles.statusIconWrapper} onClick={() => handleChangeKidStatus()}>
                        <i
                            className={cn((arrivedData === null || arrivedData == false) ? 'fa fa-lg fa-folder' : 'fa fa-lg fa-folder-open')}
                        />
                    </div>
                    <div className={styles.statusLabel}>
                        {(arrivedData === null || arrivedData == false) ? 'Not arrived' : 'Kid in Kita'}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ArrivalRow;