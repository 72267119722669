import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getKitaFields, updateSicknessesKitaSetting } from 'library/api/kitaFields';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Label from 'library/common/commonComponents/Label';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import styles from './SicknessesFrame.module.scss';

const SicknessesFrame = () => {
  const { t } = useTranslation();
  const { kitaId } = useSelector(state => state.kitaReducer.activeKita);
  const [sicknesses, setSicknesses] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getKitaFields(kitaId).then(res => {
      setSicknesses(res.data.sicknesses);
    });
  }, []);

  const handleUpdateSicknesses = value => {
    setSicknesses(value);
  };

  const onSaveClick = async () => {
    if (isSubmitting) {
      return;
    }
    try {
      setIsSubmitting(true);
      await updateSicknessesKitaSetting(kitaId, sicknesses);
      store.dispatch(
        showBottomNotification(t('BottomNotifications.Saved'), { isFail: false }),
      );
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      store.dispatch(
        showBottomNotification(t('BottomNotifications.Something went wrong'), { isFail: true }),
      );
    }
  };

  return (
    <>
      <div className={styles.inputGroup}>
        <Label type='input'>{t('Administration.KidSection.Sicknesses')}</Label>
        <TextArea value={sicknesses} onChange={e => handleUpdateSicknesses(e.target.value)} />
      </div>
      <ButtonWithLoader type='primary' onClick={onSaveClick} isLoading={isSubmitting}>
        <Trans i18nKey='AccountSettings.NotificationsSection.Save' />
      </ButtonWithLoader>
    </>
  );
};

export default SicknessesFrame;
