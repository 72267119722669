import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { deactivateKidsContractEndWithinAMonth } from "library/api/kids";
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { getKidContractEndDateListFromSelectedKita } from 'library/api/observationSheet';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Loader from 'library/common/commonComponents/Loader';
import store from 'main/store/configureStore';

import { ChildExitDateListRow } from './ChildExitDateListRow';

import styles from './ChildExitDateList.module.scss';

const ChildExitDateList = () => {
  const { t } = useTranslation();
  const { kitaId } = useSelector(state => state.kitaReducer.activeKita);
  const user = useSelector(state => state.userReducer);

  const [kidData, setKidData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getKidContractEndDateListFromSelectedKita().then(res => {
      setKidData(res.data);
      setIsLoading(false);
    });
  }, []);

  const handleDeactivateAll = async () => {
    try {
      setIsSubmitting(true);
      await deactivateKidsContractEndWithinAMonth();
      store.dispatch(showBottomNotification(t('BottomNotifications.Saved'), { isFail: false }));
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      store.dispatch(
        showBottomNotification(t('BottomNotifications.Something went wrong'), { isFail: true }),
      );
    }
  };

  return (
    <div className={styles.tableWrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <table className={styles.table}>
            <thead>
            <tr>
              <th className={styles.headerText}>
                <div style={{ paddingLeft: "50px" }} className={styles.headerTextContainer}>
                  {t("Administration.UserSection.Name")}
                </div>
              </th>
              <th className={styles.headerText}>
                <div className={styles.headerTextContainer}>
                  {t("Administration.UserSection.GroupName")}
                </div>
              </th>
              <th className={styles.headerText}>
                <div className={styles.headerTextContainer}>
                  {t("Administration.UserSection.ContractEndDate")}
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            {kidData.map(kid => (
              <ChildExitDateListRow
                key={kid.kidId}
                user={user}
                kid={kid}
                t={t}
              />
            ))}
            </tbody>
          </table>
          {kidData.length > 0 && (
            <div className={styles.deactivateButtonWrapper}>
              <ButtonWithLoader
                type="primary"
                isLoading={isSubmitting}
                onClick={handleDeactivateAll}
              >
                {t("Confirm.Deactivate All")}
              </ButtonWithLoader>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChildExitDateList;
