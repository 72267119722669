import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function useSiteTitle(title) {
  const { t } = useTranslation();

  useEffect(() => {
    if (title) {
      document.title = t(`Titles.${title}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    setTemplateTitle: (template, newTitle) =>
      (document.title = t(`Titles.${template}`).replace('{title}', newTitle)),
  };
}
