import { useState } from 'react';
import deepcopy from 'deepcopy';

import { createDislike, deleteDislike } from 'library/api/likes';
import DislikeButton from 'library/common/commonComponents/Buttons/DislikeButton';

export default function useDislikes({ dislikes, user, id, target, cb }) {
  const [localDislikes, setLocalDislikes] = useState((dislikes && dislikes.map(deepcopy)) || []);
  const [loading, setLoading] = useState(false);

  const isDisliked = localDislikes.findIndex(dislike => dislike.userId === user.id) !== -1;

  let dislikeId = 0;

  const dislikeHandler = async () => {
    if (loading) return;
    setLoading(true);

    if (isDisliked) {
      dislikeId = localDislikes.find(dislike => dislike.userId === user.id).id;
      await deleteDislike(dislikeId);
    } else {
      dislikeId = (await createDislike({
        [getDislikeTarget(target)]: id,
        userId: user.id,
      })).data.id;
    }

    const newDislikes = isDisliked
      ? localDislikes.filter(dislike => dislike.userId !== user.id)
      : localDislikes.concat({
          id: dislikeId,
          userId: user.id,
          name: `${user.firstName} ${user.lastName}`,
        });

    setLocalDislikes(newDislikes);
    if (cb) {
      cb(newDislikes);
    }
    setLoading(false);
  };

  return {
    localDislikes,
    isDisliked,
    dislikeHandler,
    DislikeButton,
  };
}

function getDislikeTarget(target) {
  switch (target) {
    case 'comment':
      return 'comments';
    case 'file':
      return 'posts';
    default:
      return 'posts';
  }
}
