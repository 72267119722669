import React, { useState } from 'react';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from "library/common/commonComponents/Popups/Popup";
import { useTranslation } from "react-i18next";
import Select from "library/common/commonComponents/Inputs/Select";


export default function TemplateEditPopup({
  showEditTemplate,
  setShowEditTemplate,
  updateOrCreateTemplate,
  editTemplateName,
  setEditTemplateName,
  editTemplateTags,
  setEditTemplateTags,
  existingTags,
}) {
  const { t } = useTranslation();

  return (
    <Popup
      size='small'
      isOpened={showEditTemplate}
      closePopup={() => setShowEditTemplate(false)}
      header={t('Administration.FormsEditorEditComponentCreateTemplatePopupHeader')}
      footer={
        <div>
          <Button onClick={updateOrCreateTemplate}>{t('Popup.Save')}</Button>
          <Button onClick={() => setShowEditTemplate(false)}>{t('Popup.Close')}</Button>
        </div>
      }
    >
      <div>
        <Label type='input'>
          {t('Administration.FormsEditorEditComponentCreateTemplatePopupNameLabel')}
        </Label>
        <Input
          value={editTemplateName}
          onChange={e => {
            setEditTemplateName(e.target.value);
          }}
          placeholder={t(
            'Administration.FormsEditorEditComponentCreateTemplatePopupNamePlaceholder',
          )}
        />
      </div>
      <div>
        <Label type='input'>
          {t('Administration.FormsEditorEditComponentCreateTemplatePopupTagsLabel')}
        </Label>
        {editTemplateTags.map((tag, i1) => {
          return (
            <div>
              {/*<Input
                value={tag}
                onChange={e => {
                  setEditTemplateTags(
                    editTemplateTags.map((tag2, i2) => {
                      if (i2 === i1) {
                        return e.target.value;
                      }

                      return tag2;
                    }),
                  );
                }}
                placeholder={t(
                  'Administration.FormsEditorEditComponentCreateTemplatePopupTagNamePlaceholder',
                )}
              />*/}
              <Select
                options={existingTags}
                onSelect={e => {
                  setEditTemplateTags(
                    editTemplateTags.map((tag2, i2) => {
                      if (i2 === i1)
                      {
                        return e.value;
                      }

                      return tag2;
                    }),
                  );
                }}
                selected={existingTags.find(et => et.value === tag) || null}
                searchTextAsOption={true}
                initialSearchText={tag}
                placeholder={t(
                  'Administration.FormsEditorEditComponentCreateTemplatePopupTagNamePlaceholder',
                )}
              />
              <Button
                onClick={() =>
                  setEditTemplateTags(
                    editTemplateTags.filter((tag2, i2) => {
                      return i2 !== i1;
                    }),
                  )
                }
              >
                <i className='fa fa-minus' />
              </Button>
            </div>
          );
        })}
        <Button onClick={() => setEditTemplateTags([...editTemplateTags, ''])}>
          <i className='fa fa-plus' />{' '}
          {t('Administration.FormsEditorEditComponentCreateTemplatePopupAddTagButtonLabel')}
        </Button>
      </div>
    </Popup>
  );
}
