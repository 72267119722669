import { useEffect, useState, useContext } from 'react';

import { getGroupsForAdmin } from 'library/common/commonActions/groupActions';
import { getGroupsOfInstitutionForGroupAdminOrInstitutionAdmin } from 'library/api/groups';
import { ReactReduxContext } from 'react-redux';

const defaultUsersList = [
  {
    createdAt: '',
    groupRole: {
      role: {
        roleName: 'Member',
      },
    },
    user: {
      firstName: '',
      id: '',
      lastName: '',
      userName: '',
    },
    checked: false,
  },
];

export default function useGroupUsersList(
  usersList,
  isCheckedAll,
  setIsCheckedAll,
  groupId,
  isAdmin,
) {
  const [tableData, setTableData] = useState(defaultUsersList);
  const [groupsForTransfer, setGroupsForTransfer] = useState([]);
  const [groupsForCopy, setGroupsForCopy] = useState([]);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const { store } = useContext(ReactReduxContext);
  useEffect(() => {
    const usersListTmp = usersList.slice();
    usersListTmp.forEach(user => {
      user.checked = false;
    });
    setTableData(usersListTmp.slice());
  }, [usersList]);

  useEffect(() => {
    setIsGroupsLoading(true);
    getGroupsOfInstitutionForGroupAdminOrInstitutionAdmin(groupId).then(res => {
      setIsGroupsLoading(false);
      const filtered = res.data.filter(
        object => object.id !== groupId && !object.groupName.endsWith(' (Portfolio)'),
      ); // matching with createPortfolioGroup in BE
      const sorted = filtered.sort((a, b) => a.groupName.localeCompare(b.groupName));
      setGroupsForTransfer(sorted);
      setGroupsForCopy(sorted);
    });
  }, []);

  const patchTableData = (id, data) => {
    const tableDataTmp = tableData.slice();

    if (data === 'multidelete') {
      return setTableData(tableDataTmp.filter(user => !user.checked));
    }

    const index = tableDataTmp.findIndex(item => item.user.id === id);

    if (data === 'delete') {
      tableDataTmp.splice(index, 1);
    } else {
      tableDataTmp[index] = { ...tableDataTmp[index], ...data };
    }

    if (tableDataTmp.filter(item => item.checked).length === tableDataTmp.length) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }

    setTableData(tableDataTmp.slice());
  };

  const onCheckAllHandler = () => {
    setTableData(tableData.map(item => ({ ...item, checked: !isCheckedAll })));
    setIsCheckedAll(!isCheckedAll);
  };

  return {
    tableData,
    setTableData,
    patchTableData,
    onCheckAllHandler,
    groupsForTransfer,
    groupsForCopy,
    isGroupsLoading,
  };
}
