import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import Checkbox from 'library/common/commonComponents/Checkbox';
import useKitaFields from 'library/common/commonHooks/useKitaFields';
import Loader from 'library/common/commonComponents/Loader';
import Select from 'library/common/commonComponents/Inputs/Select';

import styles from './kitaFieldsList.module.scss';
import HelpText from 'library/common/commonComponents/HelpText';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';

export default function KitaKidFieldsList() {
  const {
    kitas,
    kitaFields,
    kitaFieldsLoading,
    updateKitaFieldsLoading,
    selectedKita,
    setSelectedKita,
    updateKitaFields,
    kitaCustomFields,
  } = useKitaFields();

  const [fields, setFields] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [errorLimitCustomField, setErrorLimitCustomField] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (kitaFields) {
      setFields(kitaFields);
    }
  }, [kitaFields]);

  useEffect(() => {
    if (kitaCustomFields) {
      setCustomFields(kitaCustomFields);
    }
  }, [kitaCustomFields]);

  const { t } = useTranslation();

  const handleChange = (key, subKey) => e => {
    setFields({ ...fields, [key]: { ...fields[key], [subKey]: !fields[key][subKey] } });
  };

  const handleSubmit = () => {
    updateKitaFields(fields, customFields)
      .then(() => {
        dispatch(showBottomNotification(<Trans i18nKey='BottomNotifications.Saved' />));
      })
      .catch(() => {
        dispatch(
          showBottomNotification(<Trans i18nKey='BottomNotifications.Something went wrong' />, {
            isFail: true,
          }),
        );
      });
  };

  const onAddMoreField = () => {
    if (customFields.length === 20) {
      setErrorLimitCustomField(t('Administration.Kita.LimitCustomField'))
      return;
    }
    const newField = {
      id: Math.random(),
      parentCanEdit: true,
      shownOnKidProfile: true,
      fieldName: ''
    };

    setCustomFields([...customFields, newField]);
  }

  const onUpdateFieldName = (field, newValue) => {
    const updatedFields = customFields.map(f =>
      f.id === field.id ? { ...f, fieldName: newValue } : f
    );
    setCustomFields(updatedFields);
  };

  const handleChangeCustomField = (field, type, value) => {
    const updatedFields = customFields.map(f =>
      f.id === field.id ? { ...f, [type]: value } : f
    );
    setCustomFields(updatedFields);
  };

  const onRemoveCustomField = (id) => {
    const updatedFields = customFields.filter(field => field.id !== id);
    setCustomFields(updatedFields);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{t('Administration.Kita.KitaFieldsList')}</div>
        <div className={styles.text}>
          <HelpText>{t('Administration.Kita.Kita Fields List Helper Text')}</HelpText>
        </div>
      </div>

      <div className={styles.selectWrapper}>
        <Select options={kitas} onSelect={setSelectedKita} selected={selectedKita} />
      </div>

      <div>{kitaFieldsLoading && <Loader />}</div>

      {!kitaFieldsLoading && fields && (
        <div className={styles.formGroup}>
          <div className={styles.checkboxWrapper}>
            <p className={styles.headerText}>{t('Admininstation.Kita.CanParentEdit')}</p>
            <p className={styles.headerText}>{t('Admininstation.Kita.IsVisible?')}</p>
          </div>

          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.firstName.parentCanEdit}
              onChange={handleChange('firstName', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.firstName.isShownOnKidProfile}
              onChange={handleChange('firstName', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.First name')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.lastName.parentCanEdit}
              onChange={handleChange('lastName', 'parentCanEdit')}
            />

            <Checkbox
              isChecked={fields.lastName.isShownOnKidProfile}
              onChange={handleChange('lastName', 'isShownOnKidProfile')}
            />

            <p className={styles.label}>{t('Administration.UserSection.Last name')}</p>
          </div>

          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.gender.parentCanEdit}
              onChange={handleChange('gender', 'parentCanEdit')}
            />

            <Checkbox
              isChecked={fields.gender.isShownOnKidProfile}
              onChange={handleChange('gender', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.Gender')}</p>
          </div>

          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.street.parentCanEdit}
              onChange={handleChange('street', 'parentCanEdit')}
            />

            <Checkbox
              isChecked={fields.street.isShownOnKidProfile}
              onChange={handleChange('street', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.Street')}</p>
          </div>

          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.zip.parentCanEdit}
              onChange={handleChange('zip', 'parentCanEdit')}
            />

            <Checkbox
              isChecked={fields.zip.isShownOnKidProfile}
              onChange={handleChange('zip', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.Zip')}</p>
          </div>

          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.city.parentCanEdit}
              onChange={handleChange('city', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.city.isShownOnKidProfile}
              onChange={handleChange('city', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.City')}</p>
          </div>

          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.country.parentCanEdit}
              onChange={handleChange('country', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.country.isShownOnKidProfile}
              onChange={handleChange('country', 'isShownOnKidProfile')}
            />

            <p className={styles.label}>{t('Administration.UserSection.Country')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.group.parentCanEdit}
              onChange={handleChange('group', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.group.isShownOnKidProfile}
              onChange={handleChange('group', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.Group')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.state.parentCanEdit}
              onChange={handleChange('state', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.state.isShownOnKidProfile}
              onChange={handleChange('state', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.State')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.birthPlace.parentCanEdit}
              onChange={handleChange('birthPlace', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.birthPlace.isShownOnKidProfile}
              onChange={handleChange('birthPlace', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.BirthPlace')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.kidLanguage.parentCanEdit}
              onChange={handleChange('kidLanguage', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.kidLanguage.isShownOnKidProfile}
              onChange={handleChange('kidLanguage', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.KidLanguage')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.bookingTimeCategory.parentCanEdit}
              onChange={handleChange('bookingTimeCategory', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.bookingTimeCategory.isShownOnKidProfile}
              onChange={handleChange('bookingTimeCategory', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.BookingTimeCategory')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.requestForCareDate.parentCanEdit}
              onChange={handleChange('requestForCareDate', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.requestForCareDate.isShownOnKidProfile}
              onChange={handleChange('requestForCareDate', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.RequestForCareFrom')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.ageGroupAtCareStartDate.parentCanEdit}
              onChange={handleChange('ageGroupAtCareStartDate', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.ageGroupAtCareStartDate.isShownOnKidProfile}
              onChange={handleChange('ageGroupAtCareStartDate', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.AgeGroupAtCareStartDate')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.denomination.parentCanEdit}
              onChange={handleChange('denomination', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.denomination.isShownOnKidProfile}
              onChange={handleChange('denomination', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.Denomination')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.careRequestStatus.parentCanEdit}
              onChange={handleChange('careRequestStatus', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.careRequestStatus.isShownOnKidProfile}
              onChange={handleChange('careRequestStatus', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.CareRequestStatus')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.switchingChild.parentCanEdit}
              onChange={handleChange('switchingChild', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.switchingChild.isShownOnKidProfile}
              onChange={handleChange('switchingChild', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.SwitchingChild')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.contractStartDate.parentCanEdit}
              onChange={handleChange('contractStartDate', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.contractStartDate.isShownOnKidProfile}
              onChange={handleChange('contractStartDate', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.ContractStart')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.contractEndDate.parentCanEdit}
              onChange={handleChange('contractEndDate', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.contractEndDate.isShownOnKidProfile}
              onChange={handleChange('contractEndDate', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.ContractEnd')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.measlesVaccinationStatus.parentCanEdit}
              onChange={handleChange('measlesVaccinationStatus', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.measlesVaccinationStatus.isShownOnKidProfile}
              onChange={handleChange('measlesVaccinationStatus', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.MeaslesVaccinationStatus')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.measlesVaccinationDate.parentCanEdit}
              onChange={handleChange('measlesVaccinationDate', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.measlesVaccinationDate.isShownOnKidProfile}
              onChange={handleChange('measlesVaccinationDate', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.MeaslesVaccinationDate')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.birthday.parentCanEdit}
              onChange={handleChange('birthday', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.birthday.isShownOnKidProfile}
              onChange={handleChange('birthday', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.Birthday')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.allergies.parentCanEdit}
              onChange={handleChange('allergies', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.allergies.isShownOnKidProfile}
              onChange={handleChange('allergies', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.Allergies')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.medication.parentCanEdit}
              onChange={handleChange('medication', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.medication.isShownOnKidProfile}
              onChange={handleChange('medication', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.Medication')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.foodIntolerance.parentCanEdit}
              onChange={handleChange('foodIntolerance', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.foodIntolerance.isShownOnKidProfile}
              onChange={handleChange('foodIntolerance', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.FoodIntolerance')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.allowedFoodOrdersPerWeek.parentCanEdit}
              onChange={handleChange('allowedFoodOrdersPerWeek', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.allowedFoodOrdersPerWeek.isShownOnKidProfile}
              onChange={handleChange('allowedFoodOrdersPerWeek', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidsSection.NumberOfAllowedFoodOrdersPerWeek')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.about.parentCanEdit}
              onChange={handleChange('about', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.about.isShownOnKidProfile}
              onChange={handleChange('about', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.About')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.kidParent.parentCanEdit}
              onChange={handleChange('kidParent', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.kidParent.isShownOnKidProfile}
              onChange={handleChange('kidParent', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.Kid Parent')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.parentHasCustody.parentCanEdit}
              onChange={handleChange('parentHasCustody', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.parentHasCustody.isShownOnKidProfile}
              onChange={handleChange('parentHasCustody', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.Kid.Parent has custody')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.participateInExcursionsAllowed.parentCanEdit}
              onChange={handleChange('participateInExcursionsAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.participateInExcursionsAllowed.isShownOnKidProfile}
              onChange={handleChange('participateInExcursionsAllowed', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.ParticipateInExcursionsAllowed')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.exchangeWithOtherInstitutionsAllowed.parentCanEdit}
              onChange={handleChange('exchangeWithOtherInstitutionsAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.exchangeWithOtherInstitutionsAllowed.isShownOnKidProfile}
              onChange={handleChange('exchangeWithOtherInstitutionsAllowed', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.ExchangeWithOtherInstitutionsAllowed')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.photosAllowed.parentCanEdit}
              onChange={handleChange('photosAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.photosAllowed.isShownOnKidProfile}
              onChange={handleChange('photosAllowed', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.PhotosAllowed')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.showPhotosInInstitutionAllowed.parentCanEdit}
              onChange={handleChange('showPhotosInInstitutionAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.showPhotosInInstitutionAllowed.isShownOnKidProfile}
              onChange={handleChange('showPhotosInInstitutionAllowed', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.ShowPhotosInInstitutionAllowed')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.usePhotosInPortfolioAllowed.parentCanEdit}
              onChange={handleChange('usePhotosInPortfolioAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.usePhotosInPortfolioAllowed.isShownOnKidProfile}
              onChange={handleChange('usePhotosInPortfolioAllowed', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.UsePhotosInPortfolioAllowed')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.usePhotosInOtherChildsPortfolioAllowed.parentCanEdit}
              onChange={handleChange('usePhotosInOtherChildsPortfolioAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.usePhotosInOtherChildsPortfolioAllowed.isShownOnKidProfile}
              onChange={handleChange(
                'usePhotosInOtherChildsPortfolioAllowed',
                'isShownOnKidProfile',
              )}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.UsePhotosInOtherChildsPortfolioAllowed')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.usePhotosForAdvancedEducationAllowed.parentCanEdit}
              onChange={handleChange('usePhotosForAdvancedEducationAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.usePhotosForAdvancedEducationAllowed.isShownOnKidProfile}
              onChange={handleChange('usePhotosForAdvancedEducationAllowed', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.UsePhotosForAdvancedEducationAllowed')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.exchangeWithElementarySchoolAllowed.parentCanEdit}
              onChange={handleChange('exchangeWithElementarySchoolAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.exchangeWithElementarySchoolAllowed.isShownOnKidProfile}
              onChange={handleChange('exchangeWithElementarySchoolAllowed', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.ExchangeWithElementarySchoolAllowed')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.pickupBySiblingAllowed.parentCanEdit}
              onChange={handleChange('pickupBySiblingAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.pickupBySiblingAllowed.isShownOnKidProfile}
              onChange={handleChange('pickupBySiblingAllowed', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.PickupBySiblingAllowed')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.noNDAWithDoctor.parentCanEdit}
              onChange={handleChange('noNDAWithDoctor', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.noNDAWithDoctor.isShownOnKidProfile}
              onChange={handleChange('noNDAWithDoctor', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.NoNDAWithDoctor')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.noNDAWithSpecialService.parentCanEdit}
              onChange={handleChange('noNDAWithSpecialService', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.noNDAWithSpecialService.isShownOnKidProfile}
              onChange={handleChange('noNDAWithSpecialService', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.NoNDAWithSpecialService')}</p>
          </div>
          {!window.location.hostname
            .toLowerCase()
            .includes('diakoniehasenbergl-elternapp.safe2connect.org') && (
            <>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  isChecked={fields.beeStingerRemovalForbidden.parentCanEdit}
                  onChange={handleChange('beeStingerRemovalForbidden', 'parentCanEdit')}
                />
                <Checkbox
                  isChecked={fields.beeStingerRemovalForbidden.isShownOnKidProfile}
                  onChange={handleChange('beeStingerRemovalForbidden', 'isShownOnKidProfile')}
                />
                <p className={styles.label}>
                  {t('Administration.KidSection.BeeStingerRemovalForbidden')}
                </p>
              </div>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  isChecked={fields.contactParentsAfterBeeStingerRemoval.parentCanEdit}
                  onChange={handleChange('contactParentsAfterBeeStingerRemoval', 'parentCanEdit')}
                />
                <Checkbox
                  isChecked={fields.contactParentsAfterBeeStingerRemoval.isShownOnKidProfile}
                  onChange={handleChange(
                    'contactParentsAfterBeeStingerRemoval',
                    'isShownOnKidProfile',
                  )}
                />
                <p className={styles.label}>
                  {t('Administration.KidSection.ContactParentsAfterBeeStingerRemoval')}
                </p>
              </div>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  isChecked={fields.beeStingerRemovalAllowed.parentCanEdit}
                  onChange={handleChange('beeStingerRemovalAllowed', 'parentCanEdit')}
                />
                <Checkbox
                  isChecked={fields.beeStingerRemovalAllowed.isShownOnKidProfile}
                  onChange={handleChange('beeStingerRemovalAllowed', 'isShownOnKidProfile')}
                />
                <p className={styles.label}>
                  {t('Administration.KidSection.BeeStingerRemovalAllowed')}
                </p>
              </div>
            </>
          )}
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.forwardingAddressAndPhoneNumberAllowed.parentCanEdit}
              onChange={handleChange('forwardingAddressAndPhoneNumberAllowed', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.forwardingAddressAndPhoneNumberAllowed.isShownOnKidProfile}
              onChange={handleChange(
                'forwardingAddressAndPhoneNumberAllowed',
                'isShownOnKidProfile',
              )}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.ForwardingAddressAndPhoneNumberAllowed')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.kidWithSpecialNeeds.parentCanEdit}
              onChange={handleChange('kidWithSpecialNeeds', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.kidWithSpecialNeeds.isShownOnKidProfile}
              onChange={handleChange('kidWithSpecialNeeds', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.KidWithSpecialNeeds')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.kidCanGoHomeOnTheirOwn.parentCanEdit}
              onChange={handleChange('kidCanGoHomeOnTheirOwn', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.kidCanGoHomeOnTheirOwn.isShownOnKidProfile}
              onChange={handleChange('kidCanGoHomeOnTheirOwn', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.kidCanGoHomeOnTheirOwn')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.kidComesEarly.parentCanEdit}
              onChange={handleChange('kidComesEarly', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.kidComesEarly.isShownOnKidProfile}
              onChange={handleChange('kidComesEarly', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.kidComesEarly')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.bookingTimes.parentCanEdit}
              onChange={handleChange('bookingTimes', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.bookingTimes.isShownOnKidProfile}
              onChange={handleChange('bookingTimes', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.Booking times')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.holidayTimes.parentCanEdit}
              onChange={handleChange('holidayTimes', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.holidayTimes.isShownOnKidProfile}
              onChange={handleChange('holidayTimes', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.Holiday times')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.foodOrderedOverview.parentCanEdit}
              onChange={handleChange('foodOrderedOverview', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.foodOrderedOverview.isShownOnKidProfile}
              onChange={handleChange('foodOrderedOverview', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.Food ordered overview')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.authorizedPeople.parentCanEdit}
              onChange={handleChange('authorizedPeople', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.authorizedPeople.isShownOnKidProfile}
              onChange={handleChange('authorizedPeople', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.Authorized People')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.kidCode.parentCanEdit}
              onChange={handleChange('kidCode', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.kidCode.isShownOnKidProfile}
              onChange={handleChange('kidCode', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.KidCode')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.adebisID?.parentCanEdit}
              onChange={handleChange('adebisID', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.adebisID?.isShownOnKidProfile}
              onChange={handleChange('adebisID', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.UserSection.AdebisID')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields.qrCode.parentCanEdit}
              onChange={handleChange('qrCode', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields.qrCode.isShownOnKidProfile}
              onChange={handleChange('qrCode', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.Generate QR Code')}</p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields?.className?.parentCanEdit}
              onChange={handleChange('className', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields?.className?.isShownOnKidProfile}
              onChange={handleChange('className', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.Class Name when kita type is Hort')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields?.schoolName?.parentCanEdit}
              onChange={handleChange('schoolName', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields?.schoolName?.isShownOnKidProfile}
              onChange={handleChange('schoolName', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.School Name when kita type is Hort')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields?.mainGroup?.parentCanEdit}
              onChange={handleChange('mainGroup', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields?.mainGroup?.isShownOnKidProfile}
              onChange={handleChange('mainGroup', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>
              {t('Administration.KidSection.Main Group when kita type is Hort')}
            </p>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              isChecked={fields?.verificationPicture?.parentCanEdit}
              onChange={handleChange('verificationPicture', 'parentCanEdit')}
            />
            <Checkbox
              isChecked={fields?.verificationPicture?.isShownOnKidProfile}
              onChange={handleChange('verificationPicture', 'isShownOnKidProfile')}
            />
            <p className={styles.label}>{t('Administration.KidSection.VerificationPicture')}</p>
          </div>
          <div className={styles.questionContainer}>
            <Button className={styles.btnAddQuestion} onClick={()=> onAddMoreField()}>
              <i className='fa fa-plus' /> {t('Administration.KidSection.AddCustomField')}
            </Button>
            {
              errorLimitCustomField && (
                <div className={styles.errorLimitCustomField}>{errorLimitCustomField}</div>
              )
            }
          </div>
          <div className={styles.customFieldWrapper}>
            {
              customFields.map(field => (
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    isChecked={field.parentCanEdit}
                    onChange={()=> handleChangeCustomField(field, 'parentCanEdit', !field.parentCanEdit)}
                  />
                  <Checkbox
                    isChecked={field.shownOnKidProfile}
                    onChange={()=> handleChangeCustomField(field, 'shownOnKidProfile', !field.shownOnKidProfile)}
                  />
                  <p className={styles.label}>
                    <Input placeholder={t('Administration.KidSection.FieldName')} value={field.fieldName} onChange={(e)=> onUpdateFieldName(field, e.target.value)}/>
                    <i className='fa fa-minus' onClick={()=> onRemoveCustomField(field.id)}/>
                  </p>
                </div>
              ))
            }
          </div>

          <div className={styles.buttonWrapper}>
            <ButtonWithLoader
              onClick={handleSubmit}
              type='primary'
              isLoading={updateKitaFieldsLoading}
            >
              {t('KitaField.Update')}
            </ButtonWithLoader>
          </div>
        </div>
      )}
    </>
  );
}
