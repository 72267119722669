import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { sanitize } from 'dompurify';

import { isImage } from 'library/utilities/files';
import { getDeadlineTime } from 'library/utilities/date';
import MessageWithFiles from 'library/common/commonComponents/MessageWithFiles';
import { GroupContext } from 'modules/Group/groupModule';
// eslint-disable-next-line max-len
import CreateEditTask from 'library/common/commonComponents/TaskListsMain/TaskListsMainFrames/TaskLists/TaskListsFrames/TaskList/TaskListFrames/CreateEditTask';
import { getCanBePublicStatus } from 'library/utilities/posts';
import { getCalendarSettings } from 'library/api/calendar';
import { getTaskLists, updateTask } from 'library/api/tasks';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';

import styles from './feedItemTask.module.scss';

export default function FeedItemTask({
  inCalendarModule,
  onEventEdit,
  match,
  id,
  isEditing,
  setIsEditing,
  title,
  isScheduling,
  end,
  allDay,
  taskList,
  reviewRequired,
  taskStatus,
  assignedUsersList,
  responsibleUsersList,
  group,
  files,
  user,
  taskListId,
  description,
  privatePost,
  start,
  addToCalendar,
  checklist,
  updateFeedById,
  isTranslationAllowed,
}) {
  const { t } = useTranslation();

  let allImages;
  if (typeof files === 'undefined' || files === null) {
    allImages = null;
  } else {
    allImages = files.filter(file => isImage(file.fileId));
  }

  const [taskLists, setTaskLists] = useState(null);
  const [calendarSetting, setCalendarSettings] = useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const canBePublic = getCanBePublicStatus(group);
  const date = new Date();
  const isOverdue = date.getTime() > end;
  const deadline = isScheduling && getDeadlineTime(end, allDay, user.langCode);
  const isAssigned = useMemo(
    () => assignedUsersList && assignedUsersList.find(item => item.id === user.id),
    // eslint-disable-next-line
    [assignedUsersList],
  );
  const isResponsible = useMemo(
    () => responsibleUsersList && responsibleUsersList.find(item => item.id === user.id),
    // eslint-disable-next-line
    [responsibleUsersList],
  );
  const groupContext = useContext(GroupContext);
  const isInGroupPage = group && !groupContext;

  useEffect(() => {
    if (
      isEditing &&
      group &&
      group.groupModuleList &&
      group.groupModuleList.find(item => item.moduleKey === 'calendar') &&
      !calendarSetting
    ) {
      getCalendarSettings(group && group.groupId, group ? 'groupCalendar' : 'userProfileCalendar')
        .then(res => {
          if (res.status === 200) {
            setCalendarSettings(res.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [isEditing, group]);

  useEffect(() => {
    if (isEditing && !taskLists) {
      getTaskLists(group && group.groupId)
        .then(res => {
          if (res.status === 200) {
            if (inCalendarModule) {
              onEventEdit(res.data.content);
            } else {
              setTaskLists(res.data.content);
              setIsOpened(true);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else if (isEditing) {
      if (inCalendarModule) {
        onEventEdit(taskLists);
      }
      setIsOpened(true);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const handleSubmit = async updatedTask => {
    try {
      if (inCalendarModule) {
        await updateFeedById(id, updatedTask);
      } else {
        await updateTask(id, updatedTask);
        updateFeedById(
          id,
          {
            ...updatedTask,
            text: updatedTask.title,
            taskList: {
              color: taskLists.find(item => item.id === updatedTask.taskListId).color,
            },
            privatePost: !updatedTask.isPublic,
          },
          { withoutRequest: true },
        );
      }
      setIsEditing(false);
      setIsOpened(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    setIsOpened(false);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.headerContainer}
        style={{ borderLeftColor: taskList ? taskList.color : '#CCC' }}
      >
        <div className={styles.headerTop}>
          <Link
            to={
              isInGroupPage
                ? `/groups/${group.groupId}/tasks/${id}`
                : inCalendarModule
                ? `${match.url.split('/calendar')[0]}/tasks/${id}`
                : `${match.url.split('/stream')[0]}/tasks/${id}`
            }
          >
            <i className='fa fa-tasks' />
          </Link>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {isTranslationAllowed ? (
                <WithWatsonTranslate
                  data={{ text: title, entityId: id, entityType: 'task' }}
                  Component={({ html }) => (
                    <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                  )}
                />
              ) : (
                title
              )}
            </div>
            <div
              className={cn(
                styles.deadline,
                isScheduling && isOverdue && taskStatus !== 'completed' && styles.deadlineOverdue,
              )}
            >
              {isScheduling
                ? `${t('Tasks.Deadline at')} ${deadline}`
                : `${t('Tasks.No Scheduling set')}`}
            </div>
          </div>
        </div>
        <div
          className={styles.headerBottom}
          style={{
            background: reviewRequired
              ? taskStatus === 'pending'
                ? '#ededed'
                : taskStatus === 'in_progress'
                ? `linear-gradient(to right, #6fdbe8 33%, #ededed 33%)`
                : taskStatus === 'pending_review'
                ? `linear-gradient(to right, #6fdbe8 66%, #ededed 66%)`
                : taskStatus === 'completed'
                ? '#6fdbe8'
                : '#ededed'
              : taskStatus === 'pending'
              ? '#ededed'
              : taskStatus === 'in_progress'
              ? `linear-gradient(to right, #6fdbe8 50%, #ededed 50%)`
              : taskStatus === 'completed'
              ? '#6fdbe8'
              : '#ededed',
          }}
        />
      </div>
      <div className={styles.assignmentInfoContainer}>
        <div className={styles.assignmentInfoTitle}>
          <i className='fa fa-users' />
          {t('Tasks.Assignments')}
        </div>
        <div className={styles.assignmentInfoText}>
          {isAssigned && (
            <div className={styles.assignmentInfoTextAssigned}>
              <i className='fa fa-check' />
              {t('Tasks.You are assigned')}
            </div>
          )}
          {isResponsible && (
            <div className={styles.assignmentInfoTextResponsible}>
              <i className='fa fa-check' />
              {t('Tasks.You are responsible')}
            </div>
          )}
          {!isAssigned && !isResponsible && (
            <div className={styles.assignmentInfoTextAssigned}>
              <i className='fa fa-times' />
              {assignedUsersList && assignedUsersList.length !== 0
                ? t('Tasks.This task can only be processed')
                : group && group.processUnassignedTask
                ? t('Tasks.Anyone can work on this task')
                : t('Tasks.This task can only be processed')}
            </div>
          )}
        </div>
      </div>
      <Link
        to={
          isInGroupPage
            ? `/groups/${group.groupId}/task-in-lists/${taskListId}/${id}`
            : inCalendarModule
            ? `${match.url.split('/calendar')[0]}/task-in-lists/${taskListId}/${id}`
            : `${match.url.split('/stream')[0]}/task-in-lists/${taskListId}/${id}`
        }
      >
        <div className={styles.buttonOpenTask}>
          <i className='fa fa-eye' />
          {t('Tasks.Open Task')}
        </div>
      </Link>
      {files.length !== 0 && (
        <div className={styles.filesContainer}>
          <MessageWithFiles files={files} allImages={allImages} />
        </div>
      )}
      {isOpened && (
        <CreateEditTask
          user={user}
          task={{
            title,
            taskListId,
            description,
            isPublic: !privatePost,
            isScheduling,
            allDay,
            start,
            end,
            addToCalendar,
            assignedUsersList,
            responsibleUsersList,
            reviewRequired,
            checklist,
            files,
          }}
          isOpened={isOpened}
          onClose={handleClose}
          onSubmit={handleSubmit}
          canBePublic={canBePublic}
          taskLists={taskLists}
          taskListId={taskListId}
          groupId={group && group.groupId}
          canAddToCalendar={
            calendarSetting &&
            calendarSetting.calendarTypes.find(item => item.name === 'Task').enabled
          }
        />
      )}
    </div>
  );
}
