import React, { useState, useReducer } from 'react';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';
import styles from './overtimeEditHistoryPopup.module.scss';
import { useTranslation } from 'react-i18next';
import { getEmployeeInfoOvertimeHistory } from 'library/api/employee';
import { formatMilisToTimeString } from 'library/utilities/employeeCheckInOut';

export default function OvertimeEditHistoryPopup({ userId }) {
  const [showOvertimeHistoryPopup, toggleOvertimeHistoryPopup] = useReducer(state => !state, false);
  const { t } = useTranslation();
  const [overTimeHistory, setOverTimeHistory] = useState([]);
  const [isLoading, toggleIsLoading] = useReducer(state => !state, false);

  const getOvertimeHistory = async () => {
    toggleIsLoading();
    try {
      const res = await getEmployeeInfoOvertimeHistory(userId);
      setOverTimeHistory(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      toggleIsLoading();
    }
  };
  const resString =
    'user[id,firstName,lastName,modifiedProfileLogoUrl],changedBy[id,firstName,lastName],newOvertime,oldOvertime,newVacationDays,oldVacationDays, newSubtractLegalBreak';

  return (
    <>
      <Button
        size='sm'
        onClick={() => {
          toggleOvertimeHistoryPopup();
          getOvertimeHistory();
        }}
        className={styles.viewOvertimeHistory}
      >
        <i className='fa fa-history' aria-label='View overtime edit history' />
      </Button>

      <Popup
        size='large'
        isOpened={showOvertimeHistoryPopup}
        closePopup={toggleOvertimeHistoryPopup}
        header={<strong>{t('EmployeeCheckinout.History of overtime changes')}</strong>}
        body={
          <table className={styles.holidaysTable}>
            <thead className={styles.holidaysTableHeader}>
              <tr>
                <th>{t('EmployeeCheckinout.Old overtime')}</th>
                <th>{t('EmployeeCheckinout.New overtime')}</th>
                <th>{t('EmployeeCheckinout.Overtime change')}</th>
                <th>{t('EmployeeCheckinout.Edited on')}</th>
                <th>{t('EmployeeCheckinout.Initiated by')}</th>
              </tr>
            </thead>
            {isLoading ? (
              <Loader />
            ) : (
              <tbody>
                {overTimeHistory.map(employeeInfoChange => (
                  <tr key={employeeInfoChange.id}>
                    <td>{formatMilisToTimeString(employeeInfoChange.oldOvertime)}</td>
                    <td>{formatMilisToTimeString(employeeInfoChange.newOvertime)}</td>
                    <td>
                      {formatMilisToTimeString(
                        employeeInfoChange.newOvertime - employeeInfoChange.oldOvertime,
                      )}
                    </td>
                    <td>{new Date(employeeInfoChange.createdAt).toLocaleString('de-DE')}</td>
                    <td>{`${employeeInfoChange.changedBy.firstName} ${
                      employeeInfoChange.changedBy.lastName
                    }`}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        }
      />
    </>
  );
}
