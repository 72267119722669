import React from 'react';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';

import styles from './genericTwoButtonPopup.module.scss';

export default function GenericTwoButtonPopup({
  isOpened,
  headerText,
  closePopup,
  button1Text,
  button1Action,
  button2Text,
  button2Action,
  text,
}) {
  const handleClose = () => {
    if (closePopup) closePopup();
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={handleClose}
      size='extraSmall'
      header={headerText}
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={button1Action}>{button1Text}</Button>
          <Button onClick={button2Action}>{button2Text}</Button>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.text}>{text}</div>
      </div>
    </Popup>
  );
}
