import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { inviteUsersEmailAPI } from 'library/common/commonActions/groupActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import TagInput from 'library/common/commonComponents/Inputs/TagInput';

import styles from './inviteUsersPopup.module.scss';
import TextArea from '../../Inputs/TextArea';

export default function InviteUsersPopup(props) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const handleInvite = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await props.next();
    setIsSubmitting(false);
  };
  return (
    <Popup
      isOpened={props.isOpened}
      closePopup={props.closePopup}
      header={<Trans i18nKey='CreateGroup.Invite members' />}
      footer={
        <div className={styles.footerNavigation}>
          {!props.isInvitation && (
            <Button onClick={props.back} type='primary'>
              {t('CreateGroup.Back')}
            </Button>
          )}
          {!props.isInvitation && (
            <Button onClick={props.closePopup} type='primary'>
              {t('CreateGroup.Cancel Create Group')}
            </Button>
          )}
          {
            <ButtonWithLoader
              onClick={
                emailInput === ''
                  ? handleInvite
                  : () => {
                      setIsSubmitting(true);
                      inviteUsersEmailAPI({
                        groupId: props.groupId,
                        userEmail: emailInput.split(','),
                      }).then(data => {
                        setIsSubmitting(false);
                        window.location.reload();
                      });
                    }
              }
              type='primary'
              isLoading={isSubmitting}
            >
              {props.isInvitation ? t('CreateGroup.Send invitation') : t('CreateGroup.Done')}
            </ButtonWithLoader>
          }
        </div>
      }
    >
      <div className={cn(styles.container)}>
        <div className={cn(styles.tabLayout, styles.tabLayout_pick)}>
          <div className={styles.heading}>{t('CreateGroup.To invite users to this space')}</div>
          <TagInput
            setErrors={props.setErrors}
            onDataChange={props.onDataChange}
            value={props.value}
            groupId={props.groupId}
            errors={props.errors}
            isOnGroupCreation={props.isOnGroupCreation}
          />
        </div>
        {props.isSuperAdmin && (
          <div>
            <br />
            E-Mails (Getrennt mit Komma, keine Leerzeichen):
            <br />
            <TextArea
              onChange={e => {
                setEmailInput(e.target.value);
              }}
              value={emailInput}
            />
          </div>
        )}
      </div>
    </Popup>
  );
}

InviteUsersPopup.defaultProps = {
  value: {
    users: [],
  },
  errors: {
    invite: '',
  },
  onDataChange: () => {},
};
