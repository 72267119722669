import {
  SET_CREATE_GROUP_MODE,
  SET_GROUP_DATA,
  CLEAR_GROUP_DATA,
} from 'library/common/commonConstants/groupConstants';
import { getLetterInLogo } from 'library/utilities/groups';

const initialState = {
  isCreateGroupMode: false,
  groupId: '',
  letter: '',
  colorCode: '',
  avatar: '',
  logoUrl: null,
  groupLogoWidth: null,
  groupLogoAspect: null,
  groupLogoXCoordinate: null,
  groupLogoYCoordinate: null,
};

const GroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREATE_GROUP_MODE:
      return {
        ...state,
        isCreateGroupMode: action.payload,
      };
    case SET_GROUP_DATA:
      return {
        ...state,
        ...action.payload,
        groupId: action.payload.groupId || state.groupId,
        letter:
          (action.payload.groupName && getLetterInLogo(action.payload.groupName)) || state.letter,
        colorCode: action.payload.colorCode || state.colorCode,
      };
    case CLEAR_GROUP_DATA:
      return {
        ...state,
        groupId: '',
        letter: '',
        colorCode: '',
        avatar: '',
      };
    default:
      return state;
  }
};

export default GroupReducer;
