import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import { getAllKidsForCurrentUser } from 'library/api/kids';
import DatePicker from 'library/common/commonComponents/DatePicker';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import CheckBox from 'library/common/commonComponents/Checkbox';
import { postKitaConsent, getKitaConsent } from 'library/api/user';
import DefaultConsentText from './DefaultConsentText';
import DefaultConsentTextEnglish from './DefaultConsentTextEnglish';
import HimmelsAppConsentText from './HimmelsAppConsentText';
import { logout } from 'library/api/logout';
import SignatureCanvas from 'react-signature-canvas';

import styles from './kitaconsent.module.scss';

export default function KitaConsentModule({ userInfo, type, isAdminEditing, activeKita }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [checkBoxOneIsChecked, setCheckBoxOneIsChecked] = useState(false);
  const [checkBoxTwoIsChecked, setCheckBoxTwoIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    kidNames: '',
    parentLastName: '',
    parentFirstName: '',
    parentEmail: '',
    signaturePlace: '',
    signatureDate: Date.now(),
  });
  const [errorMessage, setErrorMessage] = useState('');

  const langCode = userInfo?.language?.langCode;

  const sigCanvas = useRef();

  useEffect(() => {
    if (typeof type === 'undefined') {
      getAllKidsForCurrentUser().then(data => {
        if (data.data.length > 0) {
          let kidString = '';
          for (let i = 0; i < data.data.length; i++) {
            kidString += data.data[i].firstName + ' ' + data.data[i].lastName + ',';
          }
          setFormData(prev => {
            return { ...prev, kidNames: kidString.slice(0, -1) };
          });
        }
      });
    }
    if (type === 'profileFrame') {
      getKitaConsent(isAdminEditing ? userInfo.id : null).then(data => {
        if (data.data !== null) {
          sigCanvas.current.fromDataURL(data.data.signature);
          setFormData(prev => {
            return {
              kidNames: data.data.kidNames,
              parentLastName: data.data.parentLastName,
              parentFirstName: data.data.parentFirstName,
              parentEmail: data.data.parentEmail,
              signaturePlace: data.data.signaturePlace,
              signatureDate: data.data.signatureDate,
            };
          });
        }
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (typeof type === 'undefined') {
      if (typeof userInfo.email !== 'undefined') {
        setFormData(prev => {
          return {
            ...prev,
            parentFirstName: userInfo.firstName,
            parentLastName: userInfo.lastName,
            parentEmail: userInfo.email,
          };
        });
        setIsLoading(false);
      }
    }
  }, [userInfo]);

  function handleTextStateChange(newValue, property) {
    setFormData(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function isValidInput() {
    if (
      formData.signaturePlace === '' ||
      sigCanvas.current.toData().length === 0 ||
      checkBoxOneIsChecked === false ||
      checkBoxTwoIsChecked === false
    ) {
      return false;
    }
    return true;
  }
  return (
    !isLoading && (
      <div className={styles.wrapper}>
        {type === 'profileFrame' && !isAdminEditing && (
          <>
            <b style={{ color: 'red' }}>
              {t('Consent.ToRevokeYourConsent')}
            </b>
            <br />
            <br />
          </>
        )}
        {window.location.hostname.toLowerCase().includes('netzwerk.himmels.app') ?
          <HimmelsAppConsentText activeKita={activeKita} /> : (langCode === 'en' ? <DefaultConsentTextEnglish activeKita={activeKita} /> :
            <DefaultConsentText activeKita={activeKita} />)
        }
        <p>
          <b>{t('Consent.DeclarationOfConsent')}</b>
        </p>
        <br />
        <p>
          <b>{t('Consent.ForUsageOfApplication')}</b>
        </p>
        <br />
        {t('Consent.KidNames')}: <br />
        <Input
          type='text'
          value={formData.kidNames}
          onChange={e => handleTextStateChange(e.target.value, 'kidNames')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        <b>{t('Consent.Parent')}:</b> <br />
        <br />
        {t('Consent.LastName')}:
        <Input
          type='text'
          value={formData.parentLastName}
          onChange={e => handleTextStateChange(e.target.value, 'parentLastName')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        {t('Consent.FirstName')}:
        <Input
          type='text'
          value={formData.parentFirstName}
          onChange={e => handleTextStateChange(e.target.value, 'parentFirstName')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        {t('Consent.Email')}:
        <Input
          type='text'
          value={formData.parentEmail}
          onChange={e => handleTextStateChange(e.target.value, 'parentEmail')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        <CheckBox
          name={t('Consent.IHaveReadTheInformation')}
          isChecked={type === 'profileFrame' ? true : checkBoxOneIsChecked}
          isDisabled={type === 'profileFrame' ? true : false}
          onChange={() => setCheckBoxOneIsChecked(!checkBoxOneIsChecked)}
        />
        <br />
        <CheckBox
          name={t('Consent.AdditionalHints')}
          isChecked={type === 'profileFrame' ? true : checkBoxTwoIsChecked}
          isDisabled={type === 'profileFrame' ? true : false}
          onChange={() => setCheckBoxTwoIsChecked(!checkBoxTwoIsChecked)}
        />
        <br />
        {t('Consent.Place')}:
        <Input
          type='text'
          value={formData.signaturePlace}
          onChange={e => handleTextStateChange(e.target.value, 'signaturePlace')}
          disabled={type === 'profileFrame' ? true : false}
        />
        <br />
        {t('Consent.Date')}:&nbsp;
        <div className={styles.dateInput}>
          <DatePicker
            selected={formData.signatureDate}
            onChange={date => {
              const currSelDate = new Date(formData.signatureDate);
              currSelDate.setMonth(date.getMonth());
              currSelDate.setFullYear(date.getFullYear());
              currSelDate.setDate(date.getDate());
              setFormData(prev => {
                return { ...prev, signatureDate: currSelDate.getTime() };
              });
            }}
            width='100%'
            langCode='Deutsch'
            maxDate={new Date()}
            disabled={type === 'profileFrame' ? true : false}
          />
        </div>
        <br />
        <div style={{ border: '1px solid black', width: '402px' }}>
          <SignatureCanvas
            ref={sigCanvas}
            penColor='black'
            canvasProps={{ width: 400, height: 180, className: 'sigCanvas' }}
          />
        </div>
        {t('Consent.Signature')}
        {typeof type === 'undefined' && (
          <i
            style={{ marginLeft: '290px', cursor: 'pointer' }}
            className='fa fa-eraser'
            onClick={() => {
              if (sigCanvas && sigCanvas.current) {
                sigCanvas.current.clear();
              }
            }}
          />
        )}
        <br />
        <br />
        {typeof type === 'undefined' && (
          <>
            <Button
              onClick={() => {
                if (isValidInput()) {
                  const kitaConsentTemp = {
                    ...formData,
                    signature: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'),
                  };

                  postKitaConsent(kitaConsentTemp).then(() => {
                    store.dispatch(
                      showBottomNotification(<div>Gespeichert</div>, { isFail: false }),
                    );
                    const myTimeout = setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  });
                } else {
                  setErrorMessage(
                    t('Consent.PleaseFillOut'),
                  );
                }
              }}
              type='primary'
            >
              {t('Consent.Confirm')}
            </Button>
            &nbsp;
            <Button
              onClick={() => {
                logout();
              }}
              type='secondary'
            >
              {t('Consent.Cancel')}
            </Button>
          </>
        )}
        <br />
        <div style={{ color: 'red' }}>{errorMessage}</div>
        <br />
      </div>
    )
  );
}
