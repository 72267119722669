import React, { useState } from 'react';
import cn from 'classnames';

import { getLetterInLogo } from 'library/utilities/groups';

import styles from './groupAvatar.module.scss';

export default function GroupAvatar({ group }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const body = (
    <div className={styles.avatar}>
      {group.logoUrl ? (
        <img
          src={group.logoUrl}
          alt='logo'
          className={cn(styles.avatarNotDefault, {
            [styles.hidden]: !isLoaded,
          })}
          onLoad={() => setIsLoaded(true)}
        />
      ) : (
        <div className={styles.avatarNotDefault} style={{ backgroundColor: group.colourCode }}>
          {group.groupName ? getLetterInLogo(group.groupName) : ''}
        </div>
      )}
    </div>
  );

  return body;
}
