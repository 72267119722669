import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Tooltip from 'library/common/commonComponents/Tooltip';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import { getUserLogoUrl } from 'library/utilities/user';
import TagInput from 'library/common/commonComponents/Inputs/TagInput';

import styles from './chatMessagesViewHeader.module.scss';

export default function ChatMessagesViewHeader({
  userId,
  chatId,
  userList,
  onLeave,
  isAnonymous,
  usersInChat,
  onAddUser,
  isKitaVirtual,
}) {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarsOpened, setAvatarsOpened] = useState(false);
  const [addOpened, setAddOpened] = useState(false);
  const [users, setUsers] = useState({ users: [] });
  const [error, setError] = useState(false);

  const handleLeave = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onLeave(userId, chatId);
    setIsSubmitting(false);
    setIsOpened(false);
  };

  const handleClose = () => {
    setError(false);
    setUsers({ users: [] });
    setAddOpened(false);
  };

  const handleAddUserChange = data => {
    setError(false);
    const newUsers = data.users.slice();
    const index = newUsers.length - 1;
    const alreadyInChat =
      index !== -1 && usersInChat.find(item => `${item.id}` === `${newUsers[index].id}`);
    if (alreadyInChat) {
      newUsers.splice(index, 1);
    }
    setUsers({ ...data, users: newUsers });
  };

  const handleAddUserSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    if (users.users.length === 0) {
      return setError('addUser');
    }
    setIsSubmitting(true);
    await onAddUser(chatId, users.users);
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <div className={styles.container}>
      <div className={styles.topRight}>
        {!isKitaVirtual && (
          <div className={styles.buttonAddUser}>
            <Button onClick={() => setAddOpened(true)}>
              <i className='fa fa-plus' />
              <span className={styles.buttonText}>{t('Chat.Add user')}</span>
            </Button>
          </div>
        )}
        <Tooltip className={styles.tooltip} text={t('Chat.Leave conversation')} isVisibleCondition>
          <Button onClick={() => setIsOpened(true)} className={styles.leaveButton} type='primary'>
            <i className='fa fa-sign-out' />
          </Button>
        </Tooltip>
        <Button onClick={() => setAvatarsOpened(true)} className={styles.avatarButton}>
          <div className={styles.avatarContainer}>
            {userList &&
              !isAnonymous &&
              userList.length > 0 &&
              userList
                .filter(user => user)
                .map(user => (
                  <img src={getUserLogoUrl(user.id)} alt='' className={styles.avatar} />
                ))[0]}
            <div
              className={
                userList.length < 10
                  ? styles.avatarSingleNumber
                  : userList.length < 99
                  ? styles.avatarDoubleNumber
                  : styles.avatarTrippleNumber
              }
            >
              <h2> {userList.length} </h2>
            </div>
          </div>
        </Button>
      </div>
      <Popup
        isOpened={isOpened}
        closePopup={() => setIsOpened(false)}
        size='small'
        header={<Trans i18nKey='Chat.Confirm leaving conversation' />}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button onClick={() => setIsOpened(false)}>{t('Chat.Cancel')}</Button>
            </div>
            <div className={styles.buttonLeave}>
              <ButtonWithLoader
                onClick={handleLeave}
                className={styles.buttonWithLoader}
                isLoading={isSubmitting}
              >
                {t('Chat.Leave')}
              </ButtonWithLoader>
            </div>
          </div>
        }
      >
        <div className={styles.confirmText}>{t('Chat.Do you really want to leave')}</div>
      </Popup>
      <Popup
        isOpened={avatarsOpened}
        closePopup={() => setAvatarsOpened(false)}
        size='small'
        header={<Trans i18nKey='Chat.All conversation participants' />}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonAvatarCancel}>
              <Button onClick={() => setAvatarsOpened(false)}>{t('Chat.Cancel')}</Button>
            </div>
          </div>
        }
      >
        <div
          className={
            userList.length > 3
              ? styles.grid
              : userList.length > 2
              ? styles.threeAvatar
              : userList.length > 1
              ? styles.twoAvatar
              : styles.oneAvatar
          }
        >
          {userList &&
            !isAnonymous &&
            userList.length > 0 &&
            userList
              .filter(user => user)
              .map(user => (
                <Tooltip
                  key={user.id}
                  text={
                    <span className='notranslate'>
                      {user.firstName} {user.lastName}
                    </span>
                  }
                  isVisibleCondition
                >
                  <div className={styles.avatarList}>
                    <span className={styles.avatarName}>
                      {user.firstName} {user.lastName}
                    </span>
                    <div className={styles.avatarInPopup}>
                      <ProfileAvatar
                        image={{ ...user, logoUrl: getUserLogoUrl(user.id) }}
                        id={user.id}
                      />
                    </div>
                  </div>
                </Tooltip>
              ))}
        </div>
      </Popup>
      <Popup
        isOpened={addOpened}
        closePopup={handleClose}
        size='small'
        header={t('Chat.Add more participants to your conversation')}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button onClick={handleClose}>{t('Chat.Cancel')}</Button>
            </div>
            <div className={styles.buttonSaveAddUser}>
              <ButtonWithLoader
                onClick={handleAddUserSubmit}
                className={styles.buttonWithLoader}
                isLoading={isSubmitting}
              >
                {t('Chat.Save')}
              </ButtonWithLoader>
            </div>
          </div>
        }
      >
        <div className={styles.addUserContainer}>
          <TagInput
            value={users}
            onDataChange={handleAddUserChange}
            errors={
              error === 'addUser' ? { invite: t('Chat.Recipient cannot be blank') } : { invite: '' }
            }
            showLabel={false}
            userId={userId}
          />
        </div>
      </Popup>
    </div>
  );
}
