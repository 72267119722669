/* eslint-disable */

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function(searchElement, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        // c. Increase k by 1.
        k++;
      }

      // 8. Return false
      return false;
    },
  });
}

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

/**
 * ES6 Proxy Polyfill
 * @version 2.0.1
 * @author Ambit Tsai <ambit_tsai@qq.com>
 * @license Apache-2.0
 * @see {@link https://github.com/ambit-tsai/es6-proxy-polyfill}
 */
(function(root, factory) {
  if (typeof window.define === 'function' && window.define.amd) {
    window.define(factory(root)); // AMD
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory(root); // CommonJS
  } else if (!root.Proxy) {
    root.Proxy = factory(root);
  }
})(
  (typeof window === 'object' && window) ||
    (typeof global === 'object' && global) ||
    (typeof this === 'object' && this) ||
    this,
  function(root) {
    // Constant variables
    var UNDEFINED; // => undefined
    var PROXY_TARGET = '[[ProxyTarget]]';
    var PROXY_HANDLER = '[[ProxyHandler]]';
    var GET = '[[Get]]';
    var SET = '[[Set]]';
    var CALL = '[[Call]]';
    var CONSTRUCT = '[[Construct]]';

    var Object = root.Object;
    var defineProperty = Object.defineProperty;
    var defineProperties = Object.defineProperties;
    var getPrototypeOf = Object.getPrototypeOf;
    var getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
    var supportES5 = defineProperties ? /\[native code\]/.test(defineProperties.toString()) : false;

    /**
     * The Proxy constructor
     * @constructor
     * @param {object} target
     * @param {object} handler
     */
    function Proxy(target, handler) {
      return createProxy(target, handler).proxy;
    }
    
    window.Proxy = Proxy;

    /**
     * Create a revocable Proxy object
     * @param {object} target
     * @param {object} handler
     * @returns {{proxy: object, revoke: function}}
     */
    Proxy.revocable = function(target, handler) {
      if (this instanceof Proxy.revocable) {
        throwTypeError('Proxy.revocable is not a constructor');
      }
      return createProxy(target, handler);
    };

    /**
     * Create a Proxy object
     * @param {object} target
     * @param {object} handler
     * @returns {{proxy: object, revoke: function}}
     */
    function createProxy(target, handler) {
      if (!isObject(target) || !isObject(handler)) {
        throwTypeError('Cannot create proxy with a non-object as target or handler');
      }
      var proxy;
      var internal = new InternalData(target, handler);
      if (typeof target === 'function') {
        proxy = proxyFunction(internal);
      } else if (target instanceof Array) {
        proxy = proxyArray(internal);
      } else {
        proxy = proxyObject(internal);
      }
      return {
        proxy: proxy,
        revoke: function() {
          internal[PROXY_TARGET] = UNDEFINED;
          internal[PROXY_HANDLER] = UNDEFINED;
        },
      };
    }

    /**
     * The object to store internal data
     * @constructor
     * @param {object} target
     * @param {object} handler
     */
    function InternalData(target, handler) {
      this[PROXY_TARGET] = target;
      this[PROXY_HANDLER] = handler;
    }

    /**
     * The implementation of internal method [[Get]]
     * @param {string} property
     * @param {object} receiver
     * @returns {any}
     */
    InternalData.prototype[GET] = function(property, receiver) {
      var handler = this[PROXY_HANDLER];
      validateProxyHanler(handler, 'get');
      if (handler.get == UNDEFINED) {
        return this[PROXY_TARGET][property];
      }
      if (typeof handler.get === 'function') {
        return handler.get(this[PROXY_TARGET], property, receiver);
      }
      throwTypeError("Trap 'get' is not a function: " + handler.get);
    };

    /**
     * The implementation of internal method [[Set]]
     * @param {string} property
     * @param {any} value
     * @param {object} receiver
     */
    InternalData.prototype[SET] = function(property, value, receiver) {
      var handler = this[PROXY_HANDLER];
      validateProxyHanler(handler, 'set');
      if (handler.set == UNDEFINED) {
        this[PROXY_TARGET][property] = value;
      } else if (typeof handler.set === 'function') {
        var result = handler.set(this[PROXY_TARGET], property, value, receiver);
        if (!result) {
          throwTypeError("Trap 'set' returned false for property '" + property + "'");
        }
      } else {
        throwTypeError("Trap 'set' is not a function: " + handler.set);
      }
    };

    /**
     * The implementation of internal method [[Call]]
     * @param {object} thisArg
     * @param {any[]} argList
     * @returns {any}
     */
    InternalData.prototype[CALL] = function(thisArg, argList) {
      var handler = this[PROXY_HANDLER];
      validateProxyHanler(handler, 'apply');
      if (handler.apply == UNDEFINED) {
        return this[PROXY_TARGET].apply(thisArg, argList);
      }
      if (typeof handler.apply === 'function') {
        return handler.apply(this[PROXY_TARGET], thisArg, argList);
      }
      throwTypeError("Trap 'apply' is not a function: " + handler.apply);
    };

    /**
     * The implementation of internal method [[Construct]]
     * @param {any[]} argList
     * @param {object} newTarget
     * @returns {object}
     */
    InternalData.prototype[CONSTRUCT] = function(argList, newTarget) {
      var handler = this[PROXY_HANDLER];
      validateProxyHanler(handler, 'construct');

      var newObj;
      if (handler.construct == UNDEFINED) {
        newObj = evaluateNew(this[PROXY_TARGET], argList);
      } else if (typeof handler.construct === 'function') {
        newObj = handler.construct(this[PROXY_TARGET], argList, newTarget);
      } else {
        throwTypeError("Trap 'construct' is not a function: " + handler.construct);
      }

      if (isObject(newObj)) {
        return newObj;
      } else {
        throwTypeError("Trap 'construct' returned non-object: " + newObj);
      }
    };

    /**
     * Validate the proxy hanler
     * @param {object} handler
     * @param {string} trap
     */
    function validateProxyHanler(handler, trap) {
      if (!handler) {
        throwTypeError("Cannot perform '" + trap + "' on a proxy that has been revoked");
      }
    }

    /**
     * Call constructor with 'new'
     * @param {function} F constructor
     * @param {any[]} argList
     * @returns {object}
     */
    function evaluateNew(F, argList) {
      var params = [];
      for (var i = 0, len = argList.length; i < len; ++i) {
        params.push('args[' + i + ']');
      }
      var executor = new Function('Ctor', 'args', 'return new Ctor(' + params.join(', ') + ')');
      return executor(F, argList);
    }

    /**
     * Throw a type error
     * @param {string} message
     */
    function throwTypeError(message) {
      throw new TypeError(message);
    }

    /**
     * Check if value is the language type of Object
     * @param {any} value
     * @returns {boolean}
     */
    function isObject(value) {
      return value ? typeof value === 'object' || typeof value === 'function' : false;
    }

    /**
     * Check if key is an own property of object
     * @param {object} obj
     * @param {string} key
     * @returns {boolean}
     */
    function hasOwnProperty(obj, key) {
      return Object.prototype.hasOwnProperty.call(obj, key);
    }

    /**
     * Hack `Object.getOwnPropertyNames`
     */
    var getOwnPropertyNames =
      Object.getOwnPropertyNames ||
      function(obj) {
        var names = [];
        for (var key in obj) {
          if (hasOwnProperty(obj, key)) {
            names.push(key);
          }
        }
        return names;
      };

    /**
     * Hack `Object.setPrototypeOf`
     */
    var setPrototypeOf =
      Object.setPrototypeOf ||
      function(obj, proto) {
        obj.__proto__ = proto;
        return obj;
      };

    /**
     * Hack `Object.create`
     */
    var objectCreate = supportES5
      ? Object.create
      : function(_, props) {
          return defineProperties({}, props);
        };

    /**
     * Hack `Object.assign`
     */
    var objectAssign =
      Object.assign ||
      function(target, source) {
        for (var key in source) {
          if (hasOwnProperty(source, key)) {
            target[key] = source[key];
          }
        }
        return target;
      };

    /**
     * Proxy function
     * @param {InternalData} internal
     * @returns {function}
     */
    function proxyFunction(internal) {
      var target = internal[PROXY_TARGET];

      function P() {
        return this instanceof P
          ? internal[CONSTRUCT](arguments, P)
          : internal[CALL](this, arguments);
      }
      P.prototype = target.prototype; // `prototype` is not configurable

      if (supportES5) {
        var descMap = observeProto(internal);
        var newProto = objectCreate(getPrototypeOf(target), descMap);
        setPrototypeOf(P, newProto);

        descMap = observeProperties(target, internal);
        for (var key in descMap) {
          if (hasOwnProperty(P, key)) delete descMap[key];
        }
        defineProperties(P, descMap);
      } else {
        objectAssign(P, target);
      }

      return P;
    }

    /**
     * Proxy array
     * @param {InternalData} internal
     * @returns {object} array-like object
     */
    function proxyArray(internal) {
      var target = internal[PROXY_TARGET];

      var descMap, newProto;
      if (supportES5) {
        descMap = observeProto(internal);
        // Fix: `concat` does not work correctly on array-like object
        descMap.concat.get = function() {
          var val = internal[GET]('concat', this);
          return val === Array.prototype.concat ? val.bind(target) : val;
        };
        newProto = objectCreate(getPrototypeOf(target), descMap);
      }

      descMap = observeProperties(target, internal);
      // Observe the change of `length`, and synchronize
      // the properties of Proxy object to target array
      descMap.length.set = function(value) {
        var lenDiff = value - target.length;
        internal[SET]('length', value, this);
        if (lenDiff) syncArrayElement(lenDiff, this, internal);
      };

      return objectCreate(newProto, descMap);
    }

    /**
     * Proxy object
     * @param {InternalData} internal
     * @returns {object}
     */
    function proxyObject(internal) {
      var target = internal[PROXY_TARGET];
      var descMap, newProto;
      if (supportES5) {
        descMap = observeProto(internal);
        newProto = objectCreate(getPrototypeOf(target), descMap);
      }

      descMap = observeProperties(target, internal);
      return objectCreate(newProto, descMap);
    }

    /**
     * Observe [[Prototype]]
     * @param {InternalData} internal
     * @returns {object} descriptors
     */
    function observeProto(internal) {
      var descMap = {};
      var proto = internal[PROXY_TARGET];
      while ((proto = getPrototypeOf(proto))) {
        var props = observeProperties(proto, internal);
        objectAssign(descMap, props);
      }
      return descMap;
    }

    /**
     * Observe properties
     * @param {object} obj
     * @param {InternalData} internal
     * @returns {object} descriptors
     */
    function observeProperties(obj, internal) {
      var names = getOwnPropertyNames(obj);
      var descMap = {};
      for (var i = names.length - 1; i >= 0; --i) {
        descMap[names[i]] = observeProperty(obj, names[i], internal);
      }
      return descMap;
    }

    /**
     * Observe property
     * @param {object} obj
     * @param {string} prop
     * @param {InternalData} internal
     * @returns {{get: function, set: function, enumerable: boolean, configurable: boolean}}
     */
    function observeProperty(obj, prop, internal) {
      var desc = getOwnPropertyDescriptor(obj, prop);
      return {
        get: function() {
          return internal[GET](prop, this);
        },
        set: function(value) {
          internal[SET](prop, value, this);
        },
        enumerable: desc.enumerable,
        configurable: desc.configurable,
      };
    }

    /**
     * Sync array element from P to target
     * @param {number} lenDiff
     * @param {object} P
     * @param {InternalData} internal
     */
    function syncArrayElement(lenDiff, P, internal) {
      var target = internal[PROXY_TARGET];
      if (lenDiff > 0) {
        for (var tLen = target.length, i = tLen - lenDiff; i < tLen; ++i) {
          var desc = getOwnPropertyDescriptor(P, i);
          if (desc) defineProperty(target, i, desc);
          else target[i] = UNDEFINED;
          desc = observeProperty(target, i, internal);
          defineProperty(P, i, desc);
        }
      } else {
        for (var i = target.length, pLen = i - lenDiff; i < pLen; ++i) {
          delete P[i];
        }
      }
    }

    return Proxy;
  },
);

(function() {
  if (typeof window.CustomEvent === 'function') return false; //If not IE

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();
