import React from 'react';

import config from 'main/config';

export default function AppLogo({ tenant, ...rest }) {
  const logoStyle = {
    height: '40px',
  };

  if (rest.isLoginPage) {
    logoStyle.height = '65px';
  }

  return (
    <img
      style={logoStyle}
      src={`${config.API_BASE_URI}/usermgmt/public/tenant/logo`}
      {...rest}
      alt=''
    />
  );
}
