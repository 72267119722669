import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'library/common/commonComponents/Loader';
import { getKitaUsersId, getFinalListOfPendingChildren, getUserById, getPendingKidsForCurrentKita } from 'library/api/user';
import HelpText from 'library/common/commonComponents/HelpText';
import ListTable from './ListTable';

import styles from './usersFrameChildren.module.scss';

export default function UsersFrameChildren({ activeKita }) {
  const { t } = useTranslation();
  
  const [kids, setKids] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleFetchItems = async () => {
      const pendingKidsData = await getPendingKidsForCurrentKita();
      setKids(pendingKidsData.data);
      setIsLoading(false);
    };
    handleFetchItems();
  }, []);

  return (
    <div>
      <div className={styles.title}>
        {t('Administration.UserSection.Pending children approvals')}
      </div>
      <div className={styles.text}>
        <HelpText>
          {t(
            'Administration.UserSection.The following list contains all children awaiting an approval',
          )}
        </HelpText>
      </div>
      {isLoading && <Loader />}
      {kids.length !== 0 && <ListTable kids={kids} />}
    </div>
  );
}
