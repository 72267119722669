import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Loader from 'library/common/commonComponents/Loader';

import styles from '../membershipRequestFrame.module.scss';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

export default function MembershipRequestOverviewTable(
  {
    membershipRequests,
    isLoading,
    selectedMembershipRequests,
    setSelectedMembershipRequests,
    handleSingleApprove,
    handleSingleReject
  }) {
  const { t } = useTranslation();

  const handleToggleAllMembershipRequestsSelection = () => {
    if (selectedMembershipRequests.length === membershipRequests.length) {
      setSelectedMembershipRequests([]);
    } else {
      setSelectedMembershipRequests(membershipRequests);
    }
  };

  const handleMembershipRequestSelection = membershipRequest => {
    if (selectedMembershipRequests.find(item => membershipRequest.userId === item.userId)) {
      setSelectedMembershipRequests(selectedMembershipRequests.filter(item => item.userId !== membershipRequest.userId));
    } else {
      setSelectedMembershipRequests([...selectedMembershipRequests, membershipRequest]);
    }
  };

  const onApprove = (membershipRequest) => {
    handleSingleApprove(membershipRequest);
  }

  const onReject = (membershipRequest) => {
    handleSingleReject(membershipRequest);
  }

  membershipRequests.sort((a, b) => a.userName.localeCompare(b.userName));

  return (
    <div>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.headerText}>
                  <div className={styles.headerTextContainer}>
                    {t('Membership.UserName')}
                  </div>
                </th>
                <th className={styles.headerText}>
                  <div className={styles.headerTextContainer}>
                    {t('Membership.GroupName')}
                  </div>
                </th>
                <th className={styles.headerText}>
                  {t('Membership.RequestTime')}
                </th>
                <th className={styles.headerText}>
                  <div className={styles.headerTextContainer}>
                    {t('Membership.RequestStatus')}
                  </div>
                </th>
                <th className={styles.headerText}>
                </th>
              </tr>
            </thead>
            <tbody>
              {membershipRequests.map((membershipRequest, index) => (
                <tr key={index}>
                  <td>
                    <div className={cn('notranslate', styles.avatar)}>
                      <img
                        style={{ maxWidth: '40px', marginRight: '10px' }}
                        src={membershipRequest.userLogoUrl || defaultAvatar}
                        alt=""
                      />
                      <div className={styles.avatarText}>
                        <div className={styles.name}>
                          <span>
                            {membershipRequest.userName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={cn('notranslate', styles.avatar)}>
                      <div className={styles.avatarText}>
                        <div className={styles.name}>
                          {membershipRequest.groupName && membershipRequest.groupName}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className={styles.requestStatusText}>{membershipRequest.requestStatus}</span>
                  </td>
                  <td>
                    <span>{new Date(membershipRequest.createdAt).toLocaleDateString('de-DE')}</span>
                  </td>
                  <td>
                    {membershipRequest.requestStatus === 'pending' && (
                      <div className={styles.actionButtonsWrapper}>
                        <Button size="sm" type='success' onClick={() => onApprove(membershipRequest)}>
                          {t('Administration.UserEditingChildren.Approve')}
                        </Button>
                        <Button size="sm" type='danger' onClick={() => onReject(membershipRequest)}>
                          {t('Administration.UserEditingChildren.Reject')}
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
