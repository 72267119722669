import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import styles from './groupCheckinoutSettings.module.scss';
import { getCheckInOutConfig, saveCheckInOutConfig } from '../../../../../library/api/kids';

export default function GroupCheckinoutSettings({ setIsSettingsOpen, groupId, isHortType }) {
  const { t } = useTranslation();

  const [wrapActive, setWrapActive] = useState(false);
  const [foodActive, setFoodActive] = useState(false);
  const [sleepActive, setSleepActive] = useState(false);
  const [pedagogicalObservationActive, setPedagogicalObservationActive] = useState(false);
  const [developmentDiaryActiveForParents, setDevelopmentDiaryActiveForParents] = useState(false);
  const [internalRemarkActive, setInternalRemarkActive] = useState(false);
  const [presenceDayOverviewActive, setPresenceDayOverviewActive] = useState(false);
  const [arrivalListActive, setArrivaListActive] = useState(false);
  const [arrivalTimeActive, setArrivalTimeActive] = useState(false);
  const [leavingTimeActive, setLeavingTimeActive] = useState(false);

  useEffect(() => {
    getCheckInOutConfig(groupId)
      .then(res => {
        setWrapActive(res.data.wrapActive);
        setFoodActive(res.data.foodActive);
        setSleepActive(res.data.sleepActive);
        setPedagogicalObservationActive(res.data.pedagogicalObservationActive);
        setDevelopmentDiaryActiveForParents(res.data.developmentDiaryActiveForParents);
        setInternalRemarkActive(res.data.internalRemarkActive);
        setPresenceDayOverviewActive(res.data.presenceDayOverviewActive);
        setArrivaListActive(res.data.arrivalListOverviewActive);
        setArrivalTimeActive(res.data.arrivalTimeActive);
        setLeavingTimeActive(res.data.leavingTimeActive);
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification('Fehler beim Abrufen der Einstellungen', {
            isFail: true,
          }),
        );
      });
  }, []);

  const submitSettings = () => {
    saveCheckInOutConfig(
      groupId,
      wrapActive,
      foodActive,
      sleepActive,
      pedagogicalObservationActive,
      developmentDiaryActiveForParents,
      internalRemarkActive,
      presenceDayOverviewActive,
      arrivalListActive,
      arrivalTimeActive,
      leavingTimeActive
    )
      .then(() => {
        store.dispatch(
          showBottomNotification('Einstellungen erfolgreich gespeichert', {
            isFail: false,
          }),
        );
        setIsSettingsOpen(false);
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(
            'Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut',
            {
              isFail: true,
            },
          ),
        );
      });
  };

  const settings = [
    { id: 'Wickeln', value: wrapActive, setValue: setWrapActive, label: 'WrapActive' },
    { id: 'Essen', value: foodActive, setValue: setFoodActive, label: 'FoodActive' },
    { id: 'Schlafen', value: sleepActive, setValue: setSleepActive, label: 'SleepActive' },
    {
      id: 'PedagogicalObservation',
      value: pedagogicalObservationActive,
      setValue: setPedagogicalObservationActive,
      label: 'Pedagogical observation active',
    },
    {
      id: 'ActiveForParents',
      value: developmentDiaryActiveForParents,
      setValue: setDevelopmentDiaryActiveForParents,
      label: 'Active for parents',
    },
    {
      id: 'InternalRemarkActive',
      value: internalRemarkActive,
      setValue: setInternalRemarkActive,
      label: 'InternalRemarkActive',
    },
    {
      id: 'PresenceDayOverview',
      value: presenceDayOverviewActive,
      setValue: setPresenceDayOverviewActive,
      label: 'Presence day overview active',
    },
    {
      id: 'ArrivalTimeActive',
      value: arrivalTimeActive,
      setValue: setArrivalTimeActive,
      label: 'Arrival time active',
    },
    {
      id: 'LeavingTimeActive',
      value: leavingTimeActive,
      setValue: setLeavingTimeActive,
      label: 'Leaving time active',
    },
  ];

  if (isHortType) {
    settings.push({
      id: 'ArrivalListActive',
      value: arrivalListActive,
      setValue: setArrivaListActive,
      label: 'ArrivalListActive',
    });
  }

  return (
    <div>
      <h2>{t('GroupCheckinout.Settings')}</h2>

      {settings.map((setting, index) => (
        <div key={setting.id}>
          <input
            className={[
              styles.smallRightMargin,
              styles.mediumBottomMargin,
              index === 0 ? styles.largeTopMargin : '',
            ].join(' ')}
            type='checkbox'
            id={`checkbox${setting.id}`}
            onClick={evt => {
              setting.setValue(evt.target.checked);
            }}
            onTouchEnd={evt => {
              setting.setValue(evt.target.checked);
            }}
            defaultChecked={setting.value}
            checked={setting.value}
          />
          <label htmlFor={`checkbox${setting.id}`}>
            {t(`GroupCheckinout.SettingsView.${setting.label}`)}
          </label>
        </div>
      ))}

      <Button type='primary' onClick={() => submitSettings()}>
        {t('GroupCheckinout.SettingsView.Save')}
      </Button>
      <Button type='secondary' onClick={() => setIsSettingsOpen(false)}>
        {t('GroupCheckinout.SettingsView.Back')}
      </Button>
    </div>
  );
}
