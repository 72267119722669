import React from 'react';
import cn from 'classnames';

import useFileInputChangeHandler from 'library/common/commonHooks/useFileInputChangeHandler';

import styles from './uploadButton.module.scss';

UploadButton.defaultProps = {
  allowedExtensions: ['*'],
};

export default function UploadButton({
  onChange,
  className,
  allowedExtensions,
  isMultiple,
  children,
  userPermissions,
  isInProfileStream,
  user,
  group,
  activeKita,
  groupId,
  files,
  maxFileSize,
  disabled,
}) {
  const onFileInputChange = useFileInputChangeHandler({
    onChange,
    allowedExtensions,
    userPermissions,
    isInProfileStream,
    user,
    group,
    activeKita,
    groupId,
    files,
    maxFileSize,
  });

  const disabledStyle = {
    pointerEvents: 'none',
    opacity: '0.5',
    background: '#CCC',
  };

  return (
    <div className={cn(styles.upload, className)} style={disabled ? disabledStyle : {}}>
      <label>
        <i className='fa fa-cloud-upload' />
        {children}
        <input
          type='file'
          onChange={onFileInputChange}
          name='loadImage'
          multiple={isMultiple}
          accept={allowedExtensions ? allowedExtensions.join(', ') : '*'}
        />
      </label>
    </div>
  );
}
