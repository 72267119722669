import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import last from 'lodash/last';

import useFileInputChangeHandler from 'library/common/commonHooks/useFileInputChangeHandler';

import ConfirmPopup from 'library/common/commonComponents/Popups/ConfirmPopup';
import { getCanBePublicStatus } from 'library/utilities/posts';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import FileManagerDeletePopup from '../FileManagerDeletePopup';
import FileManagerRenamePopup from '../FileManagerRenamePopup';
import FileManagerMovePopup from '../FileManagerMovePopup';
import FileManagerAddDirectionPopup from '../FileManagerAddDirectoryPopup';

import styles from './fileManagerButtons.module.scss';

export default function FileManagerButtons({
  showGoBack,
  goBack,
  selectedFiles,
  changePermanent,
  getPermanent,
  fileInput,
  onFileUpload,
  progress,
  deleteSelected,
  updateFileName,
  moveFilesToFolder,
  moveFiles,
  downloadSelected,
  downloadSelectedAsZip,
  downloadSelectedAsPDF,
  downloadSelectedAsPDFZip,
  makePublicSelected,
  makePrivateSelected,
  group,
  breadcrumbs,
  createNewFolder,
  updateCurrentFolder,
  updateFolder,
  updateBreadcrumbs,
  isProfilePage,
  userPermissions,
  user,
  activeKita,
}) {
  const canBePublic = getCanBePublicStatus(group);
  const [isSelectedOptionsOpened, setIsSelectedOptionsOpened] = useState(false);
  const [isSelectedEditFolderOpened, setIsSelectedEditFolderOpened] = useState(false);
  const selectedOptionsContainer = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsSelectedOptionsOpened(false),
  });
  const addDirectoryContainer = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsSelectedEditFolderOpened(false),
  });

  const [deletePopupIsOpened, setDeletePopupIsOpened] = useState(false);
  const [renamePopupIsOpened, setRenamePopupIsOpened] = useState(false);
  const [renameDirectoryPopupIsOpened, setRenameDirectoryPopupIsOpened] = useState(false);
  const [movePopupIsOpened, setMovePopupIsOpened] = useState(false);
  const [makeFolderPublicPopupIsOpened, setMakeFolderPublicPopupIsOpened] = useState(false);

  const [isOpenedAddingDirectoryPopup, setIsOpenedAddingDirectoryPopup] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onFileInputChange = useFileInputChangeHandler({
    onChange: onFileUpload,
    allowedExtensions: ['*'],
    userPermissions,
    isInProfileStream: isProfilePage,
    user,
    group,
    activeKita,
    groupId: group.groupId,
    files: undefined,
    maxFileSize: undefined,
  });

  const handleOnChange = async ({ target }) => {
    setIsSubmiting(true);
    await onFileInputChange({ target });
    target.value = '';
    setIsSubmiting(false);
  };

  const setEditPopupOpened = () => {
    setIsEditing(true);
    setIsOpenedAddingDirectoryPopup(true);
  };

  const onMakePublicClick = () => {
    if (selectedFiles && selectedFiles.filter(f => f.type === 'folder').length > 0) {
      setMakeFolderPublicPopupIsOpened(true);
    } else {
      makePublicSelected();
    }
  };

  const { t } = useTranslation();

  const currentFolder = last(breadcrumbs) || {};
  const isStreamFolder = currentFolder.type === 'streamFiles';

  return (
    <>
      <FileManagerDeletePopup
        onDeleteBtnClick={deleteSelected}
        isOpened={deletePopupIsOpened}
        closePopup={() => setDeletePopupIsOpened(false)}
        headerText={<Trans i18nKey='FileManager.Confirm delete file.Title' />}
        bodyText={t('FileManager.Confirm delete file.Text').replace(
          '{count}',
          selectedFiles.length,
        )}
      />
      {renamePopupIsOpened && (
        <FileManagerRenamePopup
          onSaveBtnClick={updateFileName}
          isOpened={renamePopupIsOpened}
          closePopup={() => setRenamePopupIsOpened(false)}
          headerText={t('FileManager.Update file name')}
          updateFileName={updateFileName}
          fileName={selectedFiles[0] && selectedFiles[0].fileId ? selectedFiles[0].fileId : ''}
        />
      )}
      {movePopupIsOpened && (
        <FileManagerMovePopup
          onSaveBtnClick={moveFiles}
          moveFilesToFolder={moveFilesToFolder}
          isOpened={movePopupIsOpened}
          closePopup={() => setMovePopupIsOpened(false)}
          headerText={t('FileManager.Move files')}
          moveFiles={moveFiles}
          selectedFiles={selectedFiles}
          groupId={group.groupId}
        />
      )}
      {
        renameDirectoryPopupIsOpened && (
          <FileManagerAddDirectionPopup
          isOpened={renameDirectoryPopupIsOpened}
          closePopup={() => setRenameDirectoryPopupIsOpened(false)}
          group={group}
          breadcrumbs={[selectedFiles[0] && {
            id:selectedFiles[0].id, 
            name:selectedFiles[0].name, 
            description:selectedFiles[0].description,
            publicFolder:true}]}
          updateFolder={updateFolder}
          updateCurrentFolder={updateCurrentFolder}
          isEditing={true}
          setIsEditing={(e)=>{}}
          updateBreadcrumbs={updateBreadcrumbs}
        />
        )
      }

      <FileManagerAddDirectionPopup
        isOpened={isOpenedAddingDirectoryPopup}
        closePopup={() => setIsOpenedAddingDirectoryPopup(false)}
        group={group}
        breadcrumbs={breadcrumbs}
        createNewFolder={createNewFolder}
        updateFolder={updateFolder}
        updateCurrentFolder={updateCurrentFolder}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        updateBreadcrumbs={updateBreadcrumbs}
      />

      <ConfirmPopup
        isOpened={makeFolderPublicPopupIsOpened}
        closePopup={() => setMakeFolderPublicPopupIsOpened(false)}
        onConfirm={() => {
          setMakeFolderPublicPopupIsOpened(false);
          makePublicSelected();
        }}
        text={t('FileManagerButtons.MakeFolderPublicAlertText')}
      />

      <div>
        {progress && (
          <div className={styles.progressBar}>
            <div className={styles.progressBarValue} style={{ width: progress + '%' }} />
          </div>
        )}
      </div>
      <div className={styles.buttons}>
        <div className={styles.buttonsLeft}>
          {selectedFiles.length > 0 && (
            <div ref={selectedOptionsContainer}>
              <Button
                className={cn(styles.button, styles.buttonOpen)}
                onClick={() => setIsSelectedOptionsOpened(!isSelectedOptionsOpened)}
                isActive={isSelectedOptionsOpened}
              >
                ({selectedFiles.length}) {t('FileManager.Selected items')}
                <i className='fa fa-caret-down' />
              </Button>
              <div
                className={cn(styles.selectedOptions, {
                  [styles.selectedOptionsOpened]: isSelectedOptionsOpened,
                })}
                onClick={() => setIsSelectedOptionsOpened(false)}
              >
                {group.manageFiles && (
                  <div
                    className={styles.selectedOptionsItem}
                    onClick={() => setDeletePopupIsOpened(true)}
                  >
                    <i className='fa fa-trash' /> {t('FileManager.Delete')}
                  </div>
                )}
                {!selectedFiles.find(file => file.type === 'folder') &&
                  !selectedFiles.find(file => file.signatureGroupId !== null) &&
                  group.groupId !== 0 &&
                  group.manageFiles && (
                    <div
                      className={styles.selectedOptionsItem}
                      onClick={() => setMovePopupIsOpened(true)}
                    >
                      <i className='fa fa-files-o' /> {t('FileManager.Move')}
                    </div>
                  )}

                {!selectedFiles.find(file => file.type === 'folder') &&
                  selectedFiles.length === 1 &&
                  group.manageFiles && (
                    <div
                      className={styles.selectedOptionsItem}
                      onClick={() => setRenamePopupIsOpened(true)}
                    >
                      <i className='fa fa-pencil' /> {t('FileManager.Rename')}
                    </div>
                  )}
                {selectedFiles.length === 1 && group.manageFiles &&
                selectedFiles.find(file => file.type === 'folder') && (
                  <div
                  className={styles.selectedOptionsItem}
                  onClick={() => setRenameDirectoryPopupIsOpened(true)}
                >
                  <i className='fa fa-pencil' /> {t('FileManager.Rename')}
                </div>
                )
                  
                }
                {selectedFiles.length > 0 && group.manageFiles && (
                  <div
                    className={styles.selectedOptionsItem}
                    onClick={() => {
                      downloadSelectedAsZip();
                    }}
                  >
                    <i className='fa fa-file-archive-o' /> {t('FileManager.DownloadZip')}
                  </div>
                )}
                {!selectedFiles.find(file => file.type === 'folder') && group.manageFiles && (
                  <div>
                    <div>
                      <div
                        className={styles.selectedOptionsItem}
                        onClick={() => {
                          downloadSelected();
                        }}
                      >
                        <i className='fa fa-download' /> {t('FileManager.Download')}
                      </div>
                      {!selectedFiles.find(
                        file => file && file.mimeType && !file.mimeType.startsWith('image'),
                      ) &&
                        selectedFiles.length === 1 && (
                          <div
                            className={styles.selectedOptionsItem}
                            onClick={() => {
                              downloadSelectedAsPDF();
                            }}
                          >
                            <i className='fa fa-file-pdf-o' /> {t('FileManager.DownloadPDF')}
                          </div>
                        )}
                    </div>

                    {selectedFiles.length !== 1 && (
                      <div>
                        {!selectedFiles.find(file => !file.mimeType.startsWith('image')) && (
                          <div
                            className={styles.selectedOptionsItem}
                            onClick={() => {
                              downloadSelectedAsPDFZip();
                            }}
                          >
                            <i className='fa fa-file-pdf-o' /> {t('FileManager.DownloadPDF')}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {!currentFolder ||
                  (currentFolder &&
                    (!('publicFolder' in currentFolder) || currentFolder.publicFolder) &&
                    currentFolder.name !== 'Signierte Dokumente' &&
                    currentFolder.name !== 'Wiki' && (
                      <>
                        {canBePublic && (
                          <div className={styles.selectedOptionsItem} onClick={onMakePublicClick}>
                            <i className='fa fa-unlock-alt' /> {t('FileManager.Make public')}
                          </div>
                        )}
                        <div className={styles.selectedOptionsItem} onClick={makePrivateSelected}>
                          <i className='fa fa-lock' /> {t('FileManager.Make private')}
                        </div>
                      </>
                    ))}
                {selectedFiles.find(file => file.postId) && group.manageFiles && (
                  <div className={styles.selectedOptionsItem} onClick={changePermanent}>
                    <i className={getPermanent() ? 'fa fa-unlock' : 'fa fa-lock'} />
                    {getPermanent() ? t('Post.Revoke permanent') : t('Post.Make permanent')}
                  </div>
                )}
              </div>
            </div>
          )}
          {showGoBack && (
            <Button className={cn(styles.button, styles.buttonGoBack)} onClick={goBack}>
              <i className='fa fa-arrow-left' />
            </Button>
          )}
        </div>

        <div className={styles.buttonsRight}>
          <div ref={addDirectoryContainer}>
            {group.addNewFiles && !isStreamFolder && !isProfilePage && (
              <div className={styles.buttonsContainer}>
                <Button
                  className={cn(styles.button, {
                    [styles.noBorder]: breadcrumbs.length > 0 && !isStreamFolder,
                  })}
                  onClick={() => setIsOpenedAddingDirectoryPopup(true)}
                >
                  <i className='fa fa-folder' /> {t('FileManager.Add directory')}
                </Button>
                {breadcrumbs.length > 0 && !isStreamFolder && (
                  <div
                    className={styles.caretIcon}
                    onClick={() => setIsSelectedEditFolderOpened(!isSelectedEditFolderOpened)}
                  >
                    <i className='fa fa-caret-down' />
                  </div>
                )}
                <div
                  className={cn(styles.selectedOptions, {
                    [styles.selectedOptionsOpened]: isSelectedEditFolderOpened,
                  })}
                  onClick={() => setIsSelectedEditFolderOpened(false)}
                >
                  <div className={styles.selectedOptionsItem} onClick={setEditPopupOpened}>
                    <i className='fa fa-pencil' /> {t('FileManager.Edit directory')}
                  </div>
                </div>
              </div>
            )}
          </div>
          {group.addNewFiles && !isStreamFolder && (
            <div className={styles.buttonsRightButton}>
              <label>
                <ButtonWithLoader type='success' className={styles.button} isLoading={isSubmiting}>
                  <i className='fa fa-cloud-upload' /> {t('FileManager.Add files')}
                  {!isSubmiting && (
                    <input type='file' ref={fileInput} onChange={handleOnChange} multiple />
                  )}
                </ButtonWithLoader>
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
