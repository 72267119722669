import { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';

import { getGroupsOfKita } from 'library/api/groups';
import useSorting from 'library/common/commonHooks/useSorting';

export default function useGroupsList(selectedKita, onlyPortfolioGroups) {
  const [searchValue, setSearchValue] = useState('');
  const [groups, setGroups] = useState([]);
  const { history, location } = useRouter();
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalGroupCount, setTotalGroupCount] = useState(0);

  const { sort, setSorting } = useSorting('groupName');

  const [publicOption, setPublicOption] = useState({ label: '', value: false });
  const [privateOption, setPrivateOption] = useState({ label: '', value: true });

  const options = [publicOption, privateOption];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const { t } = useTranslation();

  const loadGroups = visibility =>
    loadGroupsHandler({
      setIsLoading,
      setGroups,
      page,
      sort,
      searchValue,
      visibility: visibility || selectedOption.value,
      selectedKita,
      setTotalGroupCount,
      t,
      setPrivateOption,
      setPublicOption,
      onlyPortfolioGroups,
    });

  useEffect(() => {
    if (selectedKita.value) loadGroups();
    // eslint-disable-next-line
  }, [selectedKita, page, sort]);

  useEffect(() => {
    if (selectedKita.value)
      loadCountOfGroups({
        t,
        setPrivateOption,
        setPublicOption,
        selectedKita,
        setSelectedOption,
        onlyPortfolioGroups,
      });
    // eslint-disable-next-line
  }, [selectedKita.value]);

  useEffect(resetPageHandler({ setPage, location, history, loadGroups }), [
    searchValue,
    selectedOption.value,
  ]);

  return {
    groups,
    setPage,
    isLoading,
    history,
    location,
    totalGroupCount,
    searchValue,
    setSearchValue,
    setSorting,
    sort,
    options,
    selectedOption,
    setSelectedOption,
  };
}

export async function loadGroupsHandler({
  setIsLoading,
  setGroups,
  page,
  sort,
  searchValue,
  visibility,
  selectedKita,
  setTotalGroupCount,
  onlyPortfolioGroups,
}) {
  setIsLoading(true);

  getGroupsOfKita({
    page,
    sort,
    searchValue,
    kitaId: selectedKita.value,
    visibility,
    onlyPortfolioGroups,
  }).then(({ data }) => {
    const filteredData = filterPortfolio(data.content, onlyPortfolioGroups);
    setGroups(filteredData);
    setIsLoading(false);
    setTotalGroupCount(data.totalElements);
  });
}

export function filterPortfolio(data, onlyPortfolioGroups) {
  if (onlyPortfolioGroups) {
    return data.filter(group => group.groupName.includes('(Portfolio)'));
  } else {
    return data.filter(group => !group.groupName.includes('(Portfolio)'));
  }
}

export function loadCountOfGroups({
  t,
  setPrivateOption,
  setPublicOption,
  selectedKita,
  setSelectedOption,
  onlyPortfolioGroups,
}) {
  getGroupsOfKita({
    page: 1,
    sort: { name: 'createdAt', direction: 'asc' },
    searchValue: '',
    kitaId: selectedKita.value,
    visibility: false,
    onlyPortfolioGroups,
  }).then(({ data }) => {
    const temporaryData = data;
    data = filterPortfolio(data.content, onlyPortfolioGroups);

    const publicOption = {
      label: t('Administration.SpacesSection.PublicCount').replace(
        '{COUNT}',
        temporaryData.totalElements,
      ),
      value: false,
    };
    setPublicOption(publicOption);
    setSelectedOption(publicOption);
  });

  getGroupsOfKita({
    page: 1,
    sort: { name: 'createdAt', direction: 'asc' },
    searchValue: '',
    kitaId: selectedKita.value,
    visibility: true,
    onlyPortfolioGroups,
  }).then(({ data }) => {
    const temporaryData = data;
    data = filterPortfolio(data.content, onlyPortfolioGroups);
    setPrivateOption({
      label: t('Administration.SpacesSection.PrivateCount').replace(
        '{COUNT}',
        temporaryData.totalElements,
      ),
      value: true,
    });
  });
}

export function resetPageHandler({ setPage, location, history, loadGroups }) {
  return () => {
    setPage(1);

    const { pathname } = location;
    history.push(pathname.slice(0, pathname.lastIndexOf('/')) + '/1');

    loadGroups();
  };
}
