import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from 'react-i18next';

import { getGroupPermission } from 'library/api/groups';
import Loader from 'library/common/commonComponents/Loader';
import Card from 'library/common/commonComponents/Card';
import { getCanBePublicStatus } from 'library/utilities/posts';
import { getCalendarSettings } from 'library/api/calendar';
import { getTask, getTaskLists, updateTask, updateTaskStatus, deleteTask } from 'library/api/tasks';

import Task from '../Task';
import CreateEditTask from '../CreateEditTask';

import styles from './taskDetailView.module.scss';

export default function TaskDetailView({ history, match, group, user, showBottomNotification }) {
  const { t } = useTranslation();
  const { taskId } = match.params;
  const [task, setTask] = useState({
    assignedUsersList: [],
    responsibleUsersList: [],
    comments: [],
    likes: [],
    files: [],
  });
  const [taskLists, setTaskLists] = useState(null);
  const [calendarSetting, setCalendarSettings] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupPermissions, setGroupPermissions] = useState([]);
  const canBePublic = getCanBePublicStatus(group);

  useEffect(() => {
    setIsLoading(true);
    getTask(taskId)
      .then(res => {
        if (res.status === 200) {
          setTask(res.data);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        showBottomNotification(t('Tasks.Task not found'), { isFail: true });
        history.push(`${match.url.split('/tasks')[0]}/stream`);
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [taskId]);

  useEffect(() => {
    if (
      isEditing &&
      group &&
      group.groupModuleList.find(item => item.moduleKey === 'calendar') &&
      !calendarSetting
    ) {
      getCalendarSettings(group && group.groupId, group ? 'groupCalendar' : 'userProfileCalendar')
        .then(res => {
          if (res.status === 200) {
            setCalendarSettings(res.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [isEditing, group]);

  useEffect(() => {
    if (group && group.groupId) {
      getGroupPermission(group.groupId).then(res => {
        setGroupPermissions(res.data);
      });
    }
  }, [group]);

  useEffect(() => {
    if (isEditing && !taskLists) {
      setIsLoading(true);
      getTaskLists(group && group.groupId)
        .then(res => {
          if (res.status === 200) {
            setTaskLists(res.data.content);
            setIsOpened(true);
          }
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
        });
    } else if (isEditing) {
      setIsOpened(true);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const isLikeFeatureAvailableInGroup = useMemo(() => {
    let postLikePermission;
    if (user.administrationAccess || user.superAdminStatus || (group && group.groupAdminStatus)) {
      const adminPermission = groupPermissions.find(item => item.adminRole);
      postLikePermission = adminPermission?.groupPermissions.find(
        permission => permission.groupPermissionTag === 'LIKE-POST',
      );
    } else {
      const memberPermission = groupPermissions.find(item => !item.adminRole);
      postLikePermission = memberPermission?.groupPermissions.find(
        permission => permission.groupPermissionTag === 'LIKE-POST',
      );
    }
    if (postLikePermission) {
      return postLikePermission?.selectedStatus;
    } else {
      return true;
    }
  }, [groupPermissions, user, group]);

  const handleClosePopup = () => {
    setIsEditing(false);
    setIsOpened(false);
  };

  const handleSubmit = newTask => {
    return updateTask(task.id, { ...task, ...newTask })
      .then(res => {
        if (res.status === 200) {
          setTask(res.data);
        }
        handleClosePopup();
      })
      .catch(err => {
        console.log(err);
        handleClosePopup();
      });
  };

  const handleUpdateTaskStatus = updatedStatus => {
    return updateTaskStatus(task.id, { ...task, taskStatus: updatedStatus })
      .then(res => {
        if (res.status === 200) {
          setTask(res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleUpdateTaskChecklist = updatedChecklist => {
    return updateTask(task.id, { ...task, checklist: updatedChecklist })
      .then(res => {
        if (res.status === 200) {
          setTask(res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    return deleteTask(task.id)
      .then(res => {
        if (res.status === 200) {
          showBottomNotification(t('Tasks.The task has been deleted'));
          history.push(`${match.url.split('/tasks')[0]}/stream`);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card className={styles.container}>
      <Task
        {...task}
        manageTasks={group.createTask}
        onUpdateTaskChecklist={handleUpdateTaskChecklist}
        onUpdateTaskStatus={handleUpdateTaskStatus}
        onEdit={() => setIsEditing(true)}
        onDelete={handleDelete}
        isEditing={isOpened}
        user={user}
        groupInfo={group}
        proceedUnassignedTask={group && group.processUnassignedTask}
        isTaskDaetailView
        isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
      />
      {isOpened && (
        <CreateEditTask
          user={user}
          task={task}
          isOpened={isOpened}
          onClose={handleClosePopup}
          onSubmit={handleSubmit}
          canBePublic={canBePublic}
          taskLists={taskLists}
          taskListId={task.taskListId}
          groupId={group && group.groupId}
          canAddToCalendar={
            calendarSetting &&
            calendarSetting.calendarTypes.find(item => item.name === 'Task').enabled
          }
        />
      )}
    </Card>
  );
}
