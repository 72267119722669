import Input from 'library/common/commonComponents/Inputs/Input';
import Label from 'library/common/commonComponents/Label';
import RadioButton from 'library/common/commonComponents/RadioButton';
import Checkbox from 'library/common/commonComponents/Checkbox';
import {
  saveOrUpdateFinAPIPaymentSettings,
  getKitaPaymentSettings,
  loadKitaUser,
  createNewKitaUser,
} from 'library/api/finAPI';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Button from 'library/common/commonComponents/Buttons/Button';

export default function FinAPISettings({

}) {

  function handleTextStateChange(newValue, property) {
    setSettingsObject(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  useEffect(() => {
    getKitaPaymentSettings().then(data => {
      let settings = data.data;
      if (settings === null) {
        return;
      }

      let earliestPaymentDate = null;
      if (settings.earliestPaymentDate && settings.earliestPaymentDate.dayOfMonth) {

        const tempEarliestPaymentDate = new Date(
          settings.earliestPaymentDate.year,
          settings.earliestPaymentDate.monthValue - 1,
          settings.earliestPaymentDate.dayOfMonth,
        );
        console.log(tempEarliestPaymentDate);
        earliestPaymentDate = tempEarliestPaymentDate.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      }

      setSettingsObject(prev => {
        return {
          ...prev,
          clientId: settings.clientId,
          userId: settings.userId,
          userEmail: settings.userEmail,
          userPhone: settings.userPhone,
          paymentRecipientIBAN: settings.paymentRecipientIBAN,
          paymentRecipientName: settings.paymentRecipientName,
          earliestPaymentDate: earliestPaymentDate,
          isProductionEnvironment: settings.isProductionEnvironment,
          isProductionUser: settings.isProductionUser,
        };
      });

      loadKitaUser().then(data => {
        console.log(data.data);
      });


    });
  }, []);

  const [settingsObject, setSettingsObject] = useState({
    clientId: '',
    clientSecret: '',
    userId: '',
    userPassword: '',
    userEmail: '',
    userPhone: '',
    paymentRecipientIBAN: '',
    paymentRecipientName: '',
    earliestPaymentDate: new Date().toLocaleDateString('en-CA'),
    isProductionEnvironment: false,
    isProductionUser: false,
  });

  const { t } = useTranslation();

  return (<>
    <br />
    <h2>{t('FoodOrder.General payment settings')}</h2>
    <br />
    <Label type='input'>{t('FoodOrder.PaymentRecipientIBAN')}</Label>
    <Input
      type='text'
      value={settingsObject.paymentRecipientIBAN}
      onChange={e => handleTextStateChange(e.target.value, 'paymentRecipientIBAN')}
    />
    <br />
    <Label type='input'>{t('FoodOrder.PaymentRecipientName')}</Label>
    <Input
      type='text'
      value={settingsObject.paymentRecipientName}
      onChange={e => handleTextStateChange(e.target.value, 'paymentRecipientName')}
    />
    <br />
    <Label type='input'>{t('FoodOrder.Earliest payment date')}</Label>
    <Input
      type='date'
      value={settingsObject.earliestPaymentDate}
      onChange={e => handleTextStateChange(e.target.value, 'earliestPaymentDate')}
    />
    <br />
    <Label type='input'>{t('FoodOrder.Environment')}</Label>
    <RadioButton
      checked={!settingsObject.isProductionEnvironment}
      label={t('FoodOrder.Sandbox')}
      onClick={() => setSettingsObject(prev => {
        return { ...prev, isProductionEnvironment: !prev.isProductionEnvironment };
      })}
    />
    <RadioButton
      checked={settingsObject.isProductionEnvironment}
      label={t('FoodOrder.Production')}
      onClick={() => setSettingsObject(prev => {
        return { ...prev, isProductionEnvironment: !prev.isProductionEnvironment };
      })}
    />
    <br />
    <Button onClick={() => {
      saveOrUpdateFinAPIPaymentSettings(settingsObject).then(() => {

      });
    }}>{t('FoodOrder.Save')}</Button>
    <br />

    <>
      <br />
      <h2>{t('FoodOrder.FinAPI user')}</h2>
      <br />
      <Label type='input'>{t('FoodOrder.UserId')}</Label>
      <Input
        type='text'
        value={settingsObject.userId}
        onChange={e => handleTextStateChange(e.target.value, 'userId')}
      />
      <br />
      <Label type='input'>{t('FoodOrder.UserPassword')}</Label>
      <Input
        type='text'
        value={settingsObject.userPassword}
        onChange={e => handleTextStateChange(e.target.value, 'userPassword')}
      />
      <br />
      <Label type='input'>{t('FoodOrder.UserEmail')}</Label>
      <Input
        type='text'
        value={settingsObject.userEmail}
        onChange={e => handleTextStateChange(e.target.value, 'userEmail')}
      />
      <br />
      <Label type='input'>{t('FoodOrder.UserPhone')}</Label>
      <Input
        type='text'
        value={settingsObject.userPhone}
        onChange={e => handleTextStateChange(e.target.value, 'userPhone')}
      />
      <br />
      <Label type='input'>{t('FoodOrder.User environment')}</Label>
      <RadioButton
        checked={!settingsObject.isProductionEnvironment}
        label={t('FoodOrder.Sandbox')}
        onClick={() => setSettingsObject(prev => {
          return { ...prev, isProductionEnvironment: !prev.isProductionEnvironment };
        })}
      />
      <RadioButton
        checked={settingsObject.isProductionEnvironment}
        label={t('FoodOrder.Production')}
        onClick={() => setSettingsObject(prev => {
          return { ...prev, isProductionEnvironment: !prev.isProductionEnvironment };
        })}
      />
      <br />
      <Button onClick={() => {
        createNewKitaUser(settingsObject).then(() => {

        });
      }}>{t('FoodOrder.Create finApi user')}</Button>
    </>
    <>
      <br />
      <h2>{t('FoodOrder.FinAPI user')}</h2>
      <table>
        <tr><td>{t('FoodOrder.UserId')}</td><td>{settingsObject.userId}</td></tr>
        <tr><td>{t('FoodOrder.UserEmail')}</td><td>{settingsObject.userEmail}</td></tr>
        <tr><td>{t('FoodOrder.UserPhone')}</td><td>{settingsObject.userPhone}</td></tr>
        <tr><td>{t('FoodOrder.User environment')}</td><td>{settingsObject.isProductionUser ? t('FoodOrder.Production') : t('FoodOrder.Sandbox')}</td></tr>
      </table>
    </>
  </>);
}