import React from 'react';
import cn from 'classnames';
import styles from './radioButton.module.scss';

export default function RadioButton({ className, checked, label, onClick, disabled }) {
  const disabledStyle = {
    pointerEvents: 'none',
    opacity: '0.5',
  };

  return (
    <div style={disabled ? disabledStyle : {}} className={cn(styles.radioContainer, className)}>
      <div className={cn(styles.radio, checked && styles.checked)} onClick={onClick}>
        <div className={styles.dot} />
      </div>
      <span>{label}</span>
    </div>
  );
}
