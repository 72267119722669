import React, { useEffect, useState } from 'react';
import last from 'lodash/last';
import { useTranslation } from 'react-i18next';
// import { jsPDF } from "jspdf";

import { isImage, previewOrDownloadFile } from 'library/utilities/files';
import useGallery from 'library/common/commonHooks/useGallery';
import useSorting from 'library/common/commonHooks/useSorting';
import FileManagerTableHeader from './fileManagerTableFrames/FileManagerTableHeader';
import FileManagerTableRow from './fileManagerTableFrames/FileManagerTableRow';

import styles from './fileManagerTable.module.scss';

export default function FileManagerTable({
  isAllFilesSelected,
  groupInfo,
  folders,
  openFolder,
  breadcrumbs,
  selectFileHandler,
  selectedFiles,
  toggleAllFilesCheckbox,
  administrationAccess,
}) {
  const { t } = useTranslation();
  const { sort, setSorting } = useSorting('fileId');
  const currentFolder = last(breadcrumbs) || {};
  const isStreamFolder = currentFolder.type === 'streamFiles';
  const isRootFolder = breadcrumbs.length === 0;
  const isInReceiptFolder = breadcrumbs.length > 0 ? breadcrumbs[0].name === 'Belege' : false;

  const [imageFiles] = useState(
    folders.filter(item => item.type !== 'folder').filter(({ fileId }) => isImage(fileId)),
  );

  const { Gallery, openGallery } = useGallery(imageFiles);
  const openFile = file =>
    openFileHandler(
      file,
      folders.filter(item => item.type !== 'folder').filter(({ fileId }) => isImage(fileId)),
      openGallery,
    );

  const [sortedFiles, setSortedFiles] = useState(getSortedFiles(folders, sort));
  useEffect(() => {
    setSortedFiles(getSortedFiles(folders, sort));
  }, [folders, sort]);

  return folders.length > 0 ? (
    <div>
      <Gallery />
      <table className={styles.table}>
        <tbody>
          <FileManagerTableHeader
            isAllFilesSelected={isAllFilesSelected}
            toggleAllFilesCheckbox={toggleAllFilesCheckbox}
            sort={sort}
            setSorting={setSorting}
            groupInfo={groupInfo}
            isStreamFolder={isStreamFolder}
            isInReceiptFolder={isInReceiptFolder}
          />
          {sortedFiles.map(file => (
            <FileManagerTableRow
              key={file.id || -1}
              file={file}
              toggleFileCheckbox={() => selectFileHandler(file.id)}
              openFile={openFile}
              groupInfo={groupInfo}
              openFolder={openFolder}
              isStreamFolder={isStreamFolder}
              selectedFiles={selectedFiles}
              isRootFolder={isRootFolder}
              isInReceiptFolder={isInReceiptFolder}
              breadcrumbs={breadcrumbs}
              administrationAccess={administrationAccess}
            />
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div className={styles.emptyAttention}>
      <div className={styles.emptyAttentionTitle}>
        <span>{t('FileManager.This folder is empty')}</span>
      </div>
      <span>{t('FileManager.Upload files to the stream to fill this folder')}</span>
    </div>
  );
}

export const openFileHandler = ({ fileId, path, id }, files, openGallery) => {
  const index = files.findIndex(file => file.id === id);

  if (isImage(fileId)) {
    openGallery(files, {
      index,
    });
  } else {
    previewOrDownloadFile({ fileId, path, id });
  }
};

export function getSortedFiles(files, sort) {
  const arr = Array.from(files);
  const folders = arr.filter(file => file.type === 'folder');
  const nonFolders = arr.filter(file => file.type !== 'folder');

  folders
    .sort((a, b) => {
      const aValue = a.name || '';
      const bValue = b.name || '';

      if (sort.direction === 'asc') {
        return typeof aValue === 'string'
          ? bValue.localeCompare(aValue, undefined, { numeric: true, sensitivity: 'base' })
          : bValue - aValue;
      } else {
        return typeof aValue === 'string'
          ? aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' })
          : aValue - bValue;
      }
    })
    .reverse();

  nonFolders.sort((a, b) => {
    const aValue = a[sort.name] || '';
    const bValue = b[sort.name] || '';

    if (sort.direction === 'asc') {
      return typeof aValue === 'string'
        ? aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' })
        : aValue - bValue;
    } else {
      return typeof aValue === 'string'
        ? bValue.localeCompare(aValue, undefined, { numeric: true, sensitivity: 'base' })
        : bValue - aValue;
    }
  });
  const concatted = folders.concat(nonFolders);
  return concatted;
}
