import { useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Storage from 'library/utilities/storage';
import { getTranslations } from 'library/api/settings';
import { store } from 'main/store/configureStore';
import { changeWatsonLanguage } from '../commonActions/userActions';
import { setGoogleTranslateLang } from '../commonActions/changeLanguageActions';
import { updateUserGoogleLanguage } from '../../api/settings';
import { useSelector } from 'react-redux';

export default function useLanguages(onChange = () => {}) {
  const [selectedLanguage, setSelectedLanguage] = useState(Storage.getItem('locale'));
  const isLoggedIn = useSelector(({ authReducer }) => authReducer.isLoggedIn);

  const changeLanguage = (event, id, changeWatson = true) =>
    changeLanguageHandler({
      event,
      id,
      setSelectedLanguage,
      onChange,
      changeWatson,
      isLoggedIn,
    });

  return { selectedLanguage, changeLanguage };
}

export function mapStaticLangToWatsonLang(lang) {
  switch (lang) {
    case 'nl':
      return 'nb';
    case 'af':
    case 'sq':
    case 'fa':
      return 'en';
    default:
      return lang;
  }
}

export async function changeLanguageHandler({
  event,
  id,
  setSelectedLanguage,
  onChange,
  changeWatson,
  isLoggedIn,
}) {
  event.preventDefault();
  const { value } = event.target;

  await getTranslations(value).then(res => {
    i18n.use(initReactI18next).init({
      value,
      resources: {
        [value]: { translation: res.data },
      },

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

    i18n.changeLanguage(value);
    setSelectedLanguage(value);
    Storage.setItem('locale', value);
    Storage.setItem('localeId', id);

    onChange(value);

    // only continue and save language to account when changeWatson and user is logged in
    if (changeWatson && isLoggedIn) {
      const watsonLang = mapStaticLangToWatsonLang(value);
      setGoogleTranslateLang(watsonLang);
      updateUserGoogleLanguage(watsonLang);
      store.dispatch(changeWatsonLanguage(watsonLang));
    }
    setTimeout(() => {
      window.location.reload();
    }, 400);
  });
}
