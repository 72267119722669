import React, { useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { timeSince } from 'library/utilities/date';
import { getText } from 'library/utilities/notifications';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import { getUserLogoUrl } from 'library/utilities/user';
import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './notification.module.scss';

export default function Notification({
  isLast,
  notification,
  readNotification,
  user,
  toggle,
  checkbox,
}) {
  const { t } = useTranslation();
  const text = useRef(getText(notification, t));
  const [selectAll, setSelectAll] = useState(false);
  return (
    <>
      <div className={styles.deleteCheck}>
        <div>
          {checkbox && (
            <CheckBox
              className={styles.deletecheckbox}
              isChecked={notification.isChecked}
              onChange={() => toggle(notification.id)}
            />
          )}
        </div>

        <div
          className={cn(
            styles.notification,
            !notification.isRead && styles.unread,
            isLast && styles.noBorder,
          )}
          onClick={() => readNotification(notification)}
        >
          {notification.payload.actionBy && (
            <>
              <div className={styles.imageContainer}>
                <div className={styles.image}>
                  <ProfileAvatar
                    image={{
                      ...notification.payload.actionBy,
                      logoUrl: getUserLogoUrl(notification.payload.actionBy.userId),
                    }}
                    id={notification.payload.actionBy.userId}
                    withoutLink
                  />
                </div>
                {notification.logo && (
                  <img className={styles.logo} src={notification.logo} alt='' />
                )}
              </div>
            </>
          )}
          <div className={styles.content}>
            <div>{text.current}</div>
            <div className={styles.dateContainer}>
              <div className={styles.date}>
                {timeSince({ date: notification.createdAt, langCode: user.langCode })}
              </div>
              {!notification.isRead && <div className={styles.new}>NEW</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Notification.defaultProps = {
  isLast: false,
};

Notification.propTypes = {
  isLast: PropTypes.bool,
  notification: PropTypes.shape({}).isRequired,
};
