import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useCheckInOutKidsEvents from 'library/common/commonHooks/useCheckInOutKidsEvents';
import {
  getUserKids,
  checkInOutKid,
  getDevelopmentDiaryActiveForParents,
  getCheckInOutActiveForParents,
  checkInOutChangePickUpPerson,
} from 'library/api/kids';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Tabs from 'library/common/commonComponents/Tabs';
import ChildesContainer from 'library/common/commonComponents/CheckInOut/ChildesContainer';
import HistoryContainer from 'library/common/commonComponents/CheckInOut/HistoryContainer';
import KidLeavingTimeContainer from 'library/common/commonComponents/CheckInOut/KidLeavingTimeContainer';
import TagebuchHistorieContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/TagebuchHistorieContainer';

import styles from './calendarExportPopup.module.scss';
import EmployeeContainer from '../../CheckInOut/EmployeeContainer';
import EmployeeHistoryContainer from '../../CheckInOut/EmployeeHistoryContainer';
import EmployeeAbsenceContainer from '../../CheckInOut/EmployeeAbsenceContainer';

export default function CheckInOutPopup({ isOpened, closePopup, showKidCheckInOut }) {
  const user = useSelector(state => state.userReducer);
  const [currentTab, setCurrentTab] = useState(0);
  const { kids, updateKidStatus } = useCheckInOutKidsEvents(getUserKids, checkInOutKid);
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      title: t('Checkinout.Children'),
      component: (
        <ChildesContainer
          data={kids}
          handleUpdateKidStatus={updateKidStatus}
          checkInOutChangePickUpPerson={checkInOutChangePickUpPerson}
        />
      ),
      path: 'children-list',
    },
    {
      title: t('Checkinout.Children History'),
      component: <HistoryContainer />,
      path: 'children-history',
    },
    {
      title: t('Checkinout.Leaving Time'),
      component: <KidLeavingTimeContainer user={user} data={kids} />,
      path: 'children-leaving-time',
    },
  ]);

  useEffect(() => {
    const developmentDiaryActiveForParents = getDevelopmentDiaryActiveForParents();
    const checkinOutActiveForParents = getCheckInOutActiveForParents();

    Promise.all([developmentDiaryActiveForParents, checkinOutActiveForParents]).then(function (
      data,
    ) {
      const arrayTemp = [];

      if (showKidCheckInOut) {
        if (data[1].data === true) {
          arrayTemp.push({
            title: t('Checkinout.Children'),
            component: <ChildesContainer data={kids} handleUpdateKidStatus={updateKidStatus} />,
            path: 'children-list',
          });
          arrayTemp.push({
            title: t('Checkinout.Children History'),
            component: <HistoryContainer />,
            path: 'children-history',
          });
          arrayTemp.push({
            title: t('Checkinout.Leaving Time'),
            component: <KidLeavingTimeContainer user={user} data={kids} />,
            path: 'children-leaving-time',
          });
        }
        if (data[0].data === true) {
          arrayTemp.push({
            title: t('Checkinout.Diary'),
            component: <TagebuchHistorieContainer data={kids} />,
            path: 'children-history-diary',
          });
        }
      }
      if (arrayTemp.length > 0) {
        setTabs(arrayTemp);
      }
    });
  }, [kids]);

  return (
    <Popup
      size={['children-history'].includes(tabs[currentTab].path) ? 'ChildHistoryTab' : 'normalAndMin'}
      isOpened={isOpened}
      closePopup={closePopup}
      header={<strong>{t('Checkinout.CheckInOut overview')}</strong>}
    >
      <div className={styles.wrapper}>
        <Tabs
          tabs={tabs}
          currentTab={tabs[currentTab].path}
          onChange={(_, index) => setCurrentTab(index)}
        />
      </div>
    </Popup>
  );
}
