import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { sanitize } from 'dompurify';
import { format } from 'date-fns';

import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import { getUpdatedTime } from 'library/utilities/date';
import Button from 'library/common/commonComponents/Buttons/Button';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { getUserLogoUrl } from 'library/utilities/user';
import suggestionBoxIcon from 'resources/images/modules/suggestion_box_module.png';

import styles from './chatListItem.module.scss';

export default function ChatListItem({
  activeChatId,
  isActive,
  onClick,
  onDelete,
  lastMessage,
  chatName,
  userList,
  langCode,
  id,
  postType,
  isAnonymous,
  isSuggestionBox,
  chatSubjectLanguage,
  currentWatsonLang,
  avatars,
}) {
  if (isActive) {
    if (id !== activeChatId) {
      onClick();
    }
  }

  const { t } = useTranslation();
  if (!lastMessage) return null;
  const date = lastMessage.createdAt;

  const onTextClick = event => {
    if (event.target.tagName === 'A') event.stopPropagation();
  };

  return (
    <div className={cn(styles.container, isActive && styles.containerActive)} onClick={onClick}>
      <div className={styles.content}>
        <div className={styles.avatarContainer}>
          {lastMessage.user && !isAnonymous && (
            <div className={styles.avatar}>
              <ProfileAvatar
                withoutLink
                image={{ ...lastMessage.user, logoUrl: getUserLogoUrl(lastMessage.user.id) }}
                id={lastMessage.user.id}
              />
            </div>
          )}
          {isAnonymous && isSuggestionBox && (
            <img
              className={styles.suggestionBoxIcon}
              src={suggestionBoxIcon}
              alt='suggestion box icon'
            />
          )}
        </div>
        <div className={styles.lastMessageInfoContainer}>
          <div className={styles.header}>
            <div className={styles.userInfo}>
              {!isAnonymous ? (
                <>
                  {' '}
                  {`${lastMessage.user ? lastMessage.user.firstName : 'Test'} ${
                    lastMessage.user ? lastMessage.user.lastName : 'Test'
                  }`}
                </>
              ) : (
                t('SuggestionBox.title')
              )}
            </div>
            <div title={getUpdatedTime(lastMessage.createdAt, langCode)} className={styles.date}>
              {format(new Date(date), 'dd.MM.yyyy - HH:mm')}
            </div>
            <button
              className={styles.deleteButton}
              onClick={event => {
                event.stopPropagation();
                onDelete(id);
              }}
            >
              <i className='fa fa-trash' />
            </button>
          </div>
          <div className={styles.chatName}>
            {chatSubjectLanguage === currentWatsonLang ||
            ((chatSubjectLanguage === 'null' || chatSubjectLanguage === null) &&
              currentWatsonLang === 'de') ? (
              chatName
            ) : (
              <WithWatsonTranslate
                data={{ text: chatName, entityId: id, entityType: postType || '0' }}
                Component={({ html }) => (
                  <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                )}
              />
            )}
          </div>
          <div className={styles.textContainer}>
            {lastMessage.watsonLanguage === currentWatsonLang ||
            ((lastMessage.watsonLanguage === 'null' || lastMessage.watsonLanguage === null) &&
              currentWatsonLang === 'de') ? (
              <WithWatsonTranslate
                data={{
                  text: lastMessage.message,
                  entityId: id,
                  entityType: postType || '0',
                }}
                Component={({ html }) => (
                  <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: sanitize(html) }}
                    onClick={onTextClick}
                  />
                )}
                doNotTranslate
              />
            ) : (
              <WithWatsonTranslate
                data={{
                  text: lastMessage.message,
                  entityId: id,
                  entityType: postType || '0',
                }}
                Component={({ html }) => (
                  <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: sanitize(html) }}
                    onClick={onTextClick}
                  />
                )}
              />
            )}
            {!lastMessage.read && <div className={styles.newLabel}>NEW</div>}
          </div>
        </div>
      </div>
      {avatars && (
        <Button className={styles.avatarButton}>
          <div className={styles.avatarsContainer}>
            {userList &&
              !isAnonymous &&
              userList.length > 0 &&
              userList
                .filter(user => user)
                .map(user => (
                  <img src={getUserLogoUrl(user.id)} alt='' className={styles.avatars} />
                ))[0]}
            <div
              className={
                userList.length < 10
                  ? styles.avatarSingleNumber
                  : userList.length < 99
                  ? styles.avatarDoubleNumber
                  : styles.avatarTrippleNumber
              }
            >
              <h2> {userList.length} </h2>
            </div>
          </div>
        </Button>
      )}
    </div>
  );
}
