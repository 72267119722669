import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { updateKidGroup } from 'library/api/kids';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import ListItemWithLogoAvatar from 'library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import Popup from 'library/common/commonComponents/Popups/Popup';
import { getAllGroups } from 'library/api/groups';
import { getLetterInLogo } from 'library/utilities/groups';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from './CheckinGroupSelectionPopup.module.scss';

const CheckinGroupSelectionPopup = ({
  isOpen,
  setOpen,
  selectedKidVerificationImage,
  verificationImages,
  handleChangeKidStatus,
  kid,
  curGroupName,
  setCurGroupName,
}) => {
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groups, setGroups] = useState([]);
  const [step, setStep] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [verificationErrorMsg, setVerificationErrorMsg] = useState('');

  useEffect(() => {

    getAllGroups().then(({ data }) => {
      data = data.filter(obj => !obj.globalPublicGroup);
      const groupList = data.map(group => ({ ...group, label: group.groupName, value: group.id }))
        .filter(
          item => item.label.slice(-11) !== '(Portfolio)'
        );

      setGroups(groupList);
      if (groupList != null && groupList.length > 0 && selectedGroup == null) {
        const curGroup = curGroupName == null ? null : groupList.find(g => g.groupName === curGroupName); // would be better to use group id, but id given by childObj is (again) portfolio group id...

        setSelectedGroup(curGroup == null ? groupList[0] : curGroup);
      }
    });

  }, []);

  const handleGroupChange = group => {
    if (selectedGroup && selectedGroup.id === group.id) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(group);
    }
  };

  const gotoSecondStep = () => {
    if (!selectedGroup) {
      return;
    }
    if (window.location.hostname.toLowerCase().includes('gfz-app.ch')) {
      onSave();
      return;
    }
    setStep(2);
  };

  const gotoVerificationStep = () => {
    setStep(3);
  };

  const handleVerificationByImage = () => {
    if (window.location.hostname.toLowerCase().includes('gfz-app.ch')) {
      setVerificationErrorMsg('');
      return true;
    }
    if (!selectedKidVerificationImage) {
      setVerificationErrorMsg('Please set verification image on your profile!');
      return false;
    } else if (!selectedImage) {
      setVerificationErrorMsg('Please choose one!');
      return false;
    } else if (selectedImage?.id !== selectedKidVerificationImage?.id) {
      setVerificationErrorMsg('you picked incorrect image!');
      return false;
    } else {
      setVerificationErrorMsg('');
      return true;
    }
  };

  const closePopup = () => {
    setOpen(false);
  };

  const onSave = () => {
    const isVerified = handleVerificationByImage();
    if (isVerified) {
      handleChangeKidStatus();

      updateKidGroup(kid.kidId, selectedGroup.id);
      setCurGroupName(selectedGroup.groupName);

      closePopup();
    }
  };

  return (
    <Popup
      isOpened={isOpen}
      closePopup={closePopup}
      header={t('Checkinout.GroupSelectionPopupHeader')}
      footer={
        <div className={styles.buttonsContainer}>
          {step === 1 && (
            <>
              <div className={styles.buttonConfirm}>
                <Button onClick={gotoSecondStep}>
                  {t('Checkinout.GroupSelectionPopupButtonNext')}
                </Button>
              </div>
              <div className={styles.buttonCancel}>
                <Button onClick={closePopup}>
                  {t('Checkinout.GroupSelectionPopupButtonClose')}
                </Button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className={styles.buttonConfirm}>
                <Button onClick={gotoVerificationStep}>
                  {t('Checkinout.GroupSelectionPopupButtonConfirm')}
                </Button>
              </div>
              <div className={styles.buttonCancel}>
                <Button onClick={closePopup}>
                  {t('Checkinout.GroupSelectionPopupButtonClose')}
                </Button>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <div className={styles.buttonConfirm}>
                <Button onClick={onSave}>{t('Checkinout.GroupSelectionPopupButtonSave')}</Button>
              </div>
              <div className={styles.buttonCancel}>
                <Button onClick={closePopup}>
                  {t('Checkinout.GroupSelectionPopupButtonClose')}
                </Button>
              </div>
            </>
          )}
        </div>
      }
    >
      {step === 1 && (
        <div className={styles.groupList}>
          {groups.map(group => {
            if(group.kidsCount == 0) {
              return null
            }
            return (<div
              key={group.id}
              className={cn(styles.groupElement, group.id === selectedGroup?.id && styles.groupElementActive)}
              onClick={() => handleGroupChange(group)}
            >
              <Checkbox
                key={group.id}
                isChecked={group.id === selectedGroup?.id}
              />
              <ListItemWithLogoAvatar
                groupInfo={group}
                letter={getLetterInLogo(group.groupName)}
                backgroundColor={group.colorCode}
                className={styles.groupLogo}
                route={"check-in-popup"}
              />
              <div className={styles.groupName}>{group.label}</div>
            </div>)
          })}
        </div>
      )}
      {step === 2 && (
        <div className={styles.kidInfo}>
          <img src={kid.groupLogoUrl || defaultAvatar} alt='' />
          <span>{kid.name}</span>
        </div>
      )}
      {step === 3 && (
        <div className={styles.verificationImagesContainer}>
          <div className={styles.verificationImages}>
            {verificationImages.map(verificationImage => (
              <img
                key={verificationImage.id}
                src={verificationImage.url}
                alt=''
                onClick={() => setSelectedImage(verificationImage)}
                className={verificationImage.id === selectedImage?.id && styles.selectedImage}
                width={verificationImage.width}
              />
            ))}
          </div>
          {verificationErrorMsg && <span className={styles.verificationError}>{verificationErrorMsg}</span>}
        </div>
      )}
    </Popup>
  );
};

export default CheckinGroupSelectionPopup;
