export function getCanBePublicStatus(group) {
  if (!group) {
    return true;
  } else if (group.visibility) {
    return false;
  } else if (group.publicPostStatus) {
    return true;
  } else {
    return false;
  }
}

export function getCanCreatePublicPost(group) {
  if (!group) {
    return true;
  } else if (group.visibility) {
    return false;
  } else if (group.contentVisibility) {
    return true;
  } else {
    return false;
  }
}
