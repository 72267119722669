import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from 'library/common/commonComponents/Tabs';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import NotificationsFrame from 'modules/AccountSettings/accountSettingsFrames/NotificationsFrame';
// eslint-disable-next-line
import EmailSummariesFrame from 'modules/AccountSettings/accountSettingsFrames/EmailSummariesFrame/EmailSummariesFrameContainer';
import GeneralSettings from 'modules/AccountSettings/accountSettingsFrames/GeneralSettings';

import Language from './settingsFrames/Language/LanguageContainer';
import GlobalTools from './settingsFrames/GlobalTools';
import PaymentFinApi from './settingsFrames/PaymentFinApi';
import KitalinoInterfaceSettings from './settingsFrames/KitalinoInterfaceSettings';
import ScimSettings from 'modules/AccountSettings/accountSettingsFrames/ScimSettings';
import { getScimGlobalConfig } from 'library/api/kita';

import styles from './settingsFrame.module.scss';
import SaveCertificateFrame from 'modules/AccountSettings/accountSettingsFrames/SaveCertificateFrame';

const SettingsFrame = ({ currentTab, history, user, modules, activeKita }) => {
  useSiteTitle('AdministrationSettings');

  const { t } = useTranslation();
  const kitalinoModule = modules.find(module => module.moduleKey === 'kitalino_interface');

  const [tabs, setTabs] = useState([
    {
      title: t('Administration.SettingsSection.E-Mail summaries'),
      path: 'email-summaries',
      component: <EmailSummariesFrame admin />,
    },
    /* {
      title: t('Administration.SettingsSection.Notifications'),
      path: 'notifications',
      component: <NotificationsFrame admin />,
    }, */
  ]);


  const [scimConfig, setScimConfig] = useState(null);

  useEffect(() => {
    const fetchScimConfig = async () => {
      try {
        if (user.superAdminStatus) {
          return;
        }
        const res = (await getScimGlobalConfig()).data;
        if (res) {
          setScimConfig(res);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchScimConfig();
  }, []);

  useEffect(() => {
    if (user.superAdminStatus) {
      const tabsTemp = [
        {
          title: t('Administration.SettingsSection.Language'),
          path: 'language',
          component: <Language />,
        },
        {
          title: t('Administration.GeneralSettings'),
          path: 'general-settings',
          component: <GeneralSettings user={user} />,
        },
        ...tabs,
      ];

      if (user.email.includes('@stramplerbande') || user.email.includes('@link-it-isi')) {
        tabsTemp.push({
          title: t('Administration.SettingsSection.Notifications'),
          path: 'notifications',
          component: <NotificationsFrame admin />,
        });
        tabsTemp.push({
          title: t('Administration.Payment finAPI'),
          path: 'paymentfinapi',
          component: <PaymentFinApi />,
        });
      }

      tabsTemp.push({
        title: t('Administration.GlobalTools'),
        path: 'globaltools',
        component: <GlobalTools user={user} activeKita={activeKita} />,
      });

      tabsTemp.push({
        title: t('Administation.Kita.ScimSetting'),
        component: <ScimSettings user={user} />,
        path: 'scim-setting',
      });

      setTabs(tabsTemp);
    } else if (user.administrationAccess) {
      const tabsTemp = [
        ...tabs,
        {
          title: t('Administration.GeneralSettings'),
          path: 'general-settings',
          component: <GeneralSettings user={user} />,
        },
      ];
      if (kitalinoModule && kitalinoModule.activeStatus) {
        tabsTemp.push({
          title: t('Administration.Kitalino interface settings'),
          path: 'kitalinointerfacesettings',
          component: <KitalinoInterfaceSettings />,
        });
      }
      if (window.location.hostname.toLowerCase().includes('staging.stramplerbande.org') || window.location.hostname.toLowerCase().includes('kita-app-awo-lv-sh.safe2connect.org')) {
        tabsTemp.push({
          title: t('Administration.SettingsSection.Certificate activation'),
          path: 'certificate-activation',
          component: <SaveCertificateFrame admin />,
        });
      }
      setTabs(tabsTemp);
    }
  }, []);

  useEffect(() => {
    if (scimConfig && !user.superAdminStatus) {
      if (scimConfig.users) {
        const userIds = scimConfig.users.map(u => u.id);
        if (userIds.includes(user.id)) {
          const tabsTemp = [...tabs].filter(x => x.path !== 'scim-setting');
          tabsTemp.push({
            title: t('Administation.Kita.ScimSetting'),
            component: <ScimSettings user={user} />,
            path: 'scim-setting',
          });
          setTabs(tabsTemp);
        }
      }
    }
  }, [scimConfig])

  const onTabChange = tab => {
    history.push(`/administration/settings/${tab}`);
  };

  return (
    <div className={styles.container}>
      <Tabs tabs={tabs} currentTab={currentTab} onChange={onTabChange} />
    </div>
  );
};

export default SettingsFrame;
