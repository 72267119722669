import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import Button from 'library/common/commonComponents/Buttons/Button';
import { uploadLanguageFile, downloadLanguageFile } from 'library/api/settings';

import styles from './language.module.scss';

export default function Language({ showBottomNotification, superAdminStatus }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const uploadFile = files => {
    const data = new FormData();
    data.append('file', files[0]);
    setErrorMessage("");
    setIsLoading(true);

    uploadLanguageFile(data)
      .then(data => {
        showBottomNotification(t('BottomNotifications.Success'));
      })
      .catch(data => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
        setErrorMessage(data?.response?.data?.message);
      })
      .finally(data => {
        setIsLoading(false);
      });
  };

  const downloadFile = () => {
    downloadLanguageFile().then(res => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/vnd.ms-excel' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'languages.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className={styles.wrapper}>
      {superAdminStatus && (
        <>
          <UploadButton
            className={cn(styles.uploadButton, isLoading && styles.loading)}
            onChange={uploadFile}
            isMultiple={false}
          >
            {isLoading ? t('Administration.Loading') : t('Administration.Upload')}
          </UploadButton>
          <Button className={styles.uploadButton} onClick={downloadFile}>
            {t('Administration.Download')}
          </Button>
          <br />
          <div style={{ color: "red" }}>{errorMessage}</div>
        </>
      )}
    </div>
  );
}
