/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';

export default function useHelpModuleContent() {
  const { t } = useTranslation();
  return [
    {
      title: t('Help.Overview'),
      content: [
        {
          title: t('Help.Dashboard'),
          textElements: [
            t('Help.This is your dashboard'),
            t('Help.Any new activities or posts that might interest you will be displayed here'),
          ],
        },
        {
          title: t('Help.Notifications'),
          textElements: [
            t("Help.Don't lose track of things!"),
            t(
              'Help.This icon will keep you informed of activities and posts that concern you directly',
            ),
          ],
        },
        {
          title: t('Help.Account Menu'),
          textElements: [
            t(
              'Help.The account menu gives you access to your private settings and allows you to manage your public profile',
            ),
          ],
        },
        {
          title: t('Help.Group Menu'),
          textElements: [
            t(
              'Help.This is the most important menu and will probably be the one you use most often!',
            ),
            t('Help.Access all the groups you have joined and create new groups here'),
          ],
        },
        {
          title: t('Help.Kita Selection'),
          textElements: [
            t(
              'Help.If you have children in different Kitas, you have the option to select the different Kitas more quickly in the left menu bar',
            ),
          ],
        },
        {
          title: t('Help.Languages'),
          textElements: [t('Help.You can change the language here')],
        },
      ],
    },
    {
      title: t('Help.Groups'),
      content: [
        {
          title: t('Help.Group'),
          textElements: [
            t(
              'Help.Once you have joined or created a new group you can work on projects, discuss topics or just share information with other users',
            ),
            t(
              'Help.There are various tools to personalize a group, thereby making the work process more productive',
            ),
          ],
        },
        {
          title: t('Help.Group navigation menu'),
          textElements: [
            t(
              'Help.This is where you can navigate the group – where you find which modules are active or available for the particular group you are currently in These could be polls, tasks or notes for example',
            ),
            t("Help.Only the group admin can manage the group's modules"),
          ],
        },
        {
          title: t('Help.Group preferences'),
          textElements: [
            t(
              'Help.This menu is only visible for group admins Here you can manage your group settings, add/block members and activate/deactivate tools for this group',
            ),
          ],
        },
        {
          title: t('Help.Writing posts'),
          textElements: [t('Help.New posts can be written and posted here')],
        },
        {
          title: t('Help.Posts'),
          textElements: [
            t("Help.Yours, and other users' posts will appear here"),
            t('Help.These can then be liked or commented on'),
          ],
        },
        {
          title: t('Help.Group members'),
          textElements: [
            t('Help.All users who are a member of this group will be displayed here'),
            t(
              'Help.New members can be added by anyone who has been given access rights by the admin',
            ),
          ],
        },
      ],
    },
    {
      title: t('Help.User profile'),
      content: [
        {
          title: t('Help.User profile'),
          textElements: [
            t('Help.This is your public user profile, which can be seen by any registered user'),
          ],
        },
        {
          title: t('Help.Profile photo'),
          textElements: [
            t(
              'Help.Upload a new profile photo by simply clicking here or by drag&drop Do just the same for updating your cover photo',
            ),
          ],
        },
        {
          title: t('Help.Edit account'),
          textElements: [
            t(
              'Help.Click on this button to update your profile and account settings You can also add more information to your profile',
            ),
          ],
        },
        {
          title: t('Help.Profile menu'),
          textElements: [
            t(
              'Help.Just like in the space, the user profile can be personalized with various modules',
            ),
            t(
              'Help.You can see which modules are available for your profile by looking them in “Modules” in the account settings menu',
            ),
          ],
        },
        {
          title: t('Help.Profile stream'),
          textElements: [
            t(
              'Help.Each profile has its own pin board Your posts will also appear on the dashboards of those users who are following you',
            ),
          ],
        },
      ],
    },
  ];
}
