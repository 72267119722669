import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FriendRequestTableHeader from '../FriendRequestTableHeader';
import FriendRequestTableEmpty from '../FriendRequestTableEmpty';
import FriendRequestTableRow from '../FriendRequestTableRow';

import styles from './friendsList.module.scss';

export default function FriendsList({ friendsList, onUnFriend, user, history }) {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState(friendsList);

  const handleSorting = sortDirection => {
    const sortedTableData = tableData.slice();
    sortedTableData.sort((a, b) => {
      const user1 = a.receiverId === user.id ? a.requester : a.receiver;
      const user2 = b.receiverId === user.id ? b.requester : b.receiver;
      return user1.userName.toLowerCase() > user2.userName.toLowerCase()
        ? sortDirection === 'ASC'
          ? 1
          : -1
        : sortDirection === 'ASC'
        ? -1
        : 1;
    });
    setTableData(sortedTableData);
  };

  return (
    <div className={styles.container}>
      {tableData.length > 0 && (
        <div className={styles.description}>
          {t('AccountSettings.FriendsSection.Showing') +
            ` 1-${tableData.length} ` +
            t('AccountSettings.FriendsSection.of') +
            ` ${tableData.length} ` +
            t('AccountSettings.FriendsSection.items')}
        </div>
      )}
      <table className={styles.table}>
        <FriendRequestTableHeader onSort={handleSorting} />
        <tbody>
          {!tableData.length ? (
            <FriendRequestTableEmpty />
          ) : (
            tableData.map(item => (
              <FriendRequestTableRow
                key={item.id}
                history={history}
                id={item.receiverId === user.id ? item.requesterId : item.receiverId}
                userName={
                  item.receiverId === user.id ? item.requester.userName : item.receiver.userName
                }
                firstName={
                  item.receiverId === user.id ? item.requester.firstName : item.receiver.firstName
                }
                lastName={
                  item.receiverId === user.id ? item.requester.lastName : item.receiver.lastName
                }
                buttons={[
                  {
                    id: 1,
                    title: t('AccountSettings.FriendsSection.Unfriend'),
                    onClick: () =>
                      onUnFriend(
                        item.id,
                        item.receiverId === user.id ? item.requester.id : item.receiver.id,
                      ),
                    className: styles.unfriendButton,
                  },
                ]}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
