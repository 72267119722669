import axios from 'library/api/axios';

// Object which containins current pending requests;
const currentRequests = {};

const cachedRequest = ({ method, url, settings }) => {
  if (currentRequests[url] !== undefined) {
    return currentRequests[url];
  }
  const newRequest = axios[method](url, settings);
  newRequest.then(() => {
    delete currentRequests[url];
  });
  currentRequests[url] = newRequest;

  return newRequest;
};

export default cachedRequest;
