import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import RadioButton from 'library/common/commonComponents/RadioButton';

import styles from './disableModulePopup.module.scss';

export default function DisableModulePopup({
  isOpened,
  closePopup,
  onConfirm,
  text,
  justDiactivate,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState('DEACTIVATE');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setValue('DEACTIVATE');
    closePopup();
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onConfirm(value);
    setIsSubmitting(false);
    setValue('DEACTIVATE');
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={handleClose}
      size='extraSmall'
      header={<Trans i18nKey='Confirm.Confirm Action' />}
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={handleClose}>{t('Confirm.Cancel')}</Button>
          <ButtonWithLoader onClick={handleSubmit} type='primary' isLoading={isSubmitting}>
            {t('Confirm.Confirm')}
          </ButtonWithLoader>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.text}>{text}</div>
        <div className={styles.radioButtonsContainer}>
          {!justDiactivate && (
            <>
              <RadioButton
                checked={value === 'DEACTIVATE'}
                label={t('GroupBody.Modules.Disable module')}
                onClick={() => setValue('DEACTIVATE')}
                className={styles.radioButton}
              />
              <RadioButton
                checked={value === 'DELETE'}
                label={t('GroupBody.Modules.Delete content and disable module')}
                onClick={() => setValue('DELETE')}
                className={styles.radioButton}
              />
            </>
          )}
        </div>
      </div>
    </Popup>
  );
}
