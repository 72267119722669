import React from 'react';
import { store } from 'main/store/configureStore';
import styles from './playBlock.module.scss';

// eslint-disable
export default function PlayBlock({ file }) {
  const user = store.getState().userReducer;
  const group = store.getState().groupReducer;

  const isAdminStatus = user.administrationAccess || user.superAdminStatus;

  const imageDownloadModule = (group.groupAdminStatus || window.location.pathname === '/dashboard')
    ? store.getState().kitaReducer.modules.find(mod => mod.moduleKey === 'image_download')
    : group?.groupModuleList?.find(mod => mod.moduleKey === 'image_download');

  const downloadAvailable = isAdminStatus ? true : imageDownloadModule ? imageDownloadModule.activeStatus : false;

  return (
    <video
      onContextMenu={e => {
        if (downloadAvailable === false) {
          e.preventDefault();
        }
      }}
      className={styles.videoConteiner}
      controls
      preload='metadata'
      {...(!downloadAvailable ? { controlsList: 'nodownload' } : {})}
    >
      <source src={`${file.path}#t=0.01`} />
    </video>
  );
}
