import { UPDATE_FOLDERS, UPDATE_BREADCRUMBS, UPDATE_IMAGES_DIMENSIONS_STATE } from '../commonConstants/fileConstants';

const initialState = {
  folders: [],
  breadcrumbs: [],
  imagesDimensionsState: {},
};

const FileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case UPDATE_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    case UPDATE_IMAGES_DIMENSIONS_STATE:
      return {
        ...state,
        imagesDimensionsState: {...state.imagesDimensionsState, ...action.payload},
      };
    default:
      return state;
  }
};

export default FileReducer;
