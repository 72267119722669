import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8080)}/api`;

export const createKidDevelopmentDiaryEntry = (
  kidId,
  developmentDiaryType,
  additionalTextMessage = null,
  time,
) => {
  if (additionalTextMessage !== null && additionalTextMessage !== '') {
    return axios.post(`${URL}/kidDevelopmentDiary`, {
      kidId,
      developmentDiaryType,
      additionalTextMessage,
      time,
    });
  }
  return axios.post(`${URL}/kidDevelopmentDiary`, { kidId, developmentDiaryType, time });
};

export const getGroupKidWrapRows = groupId => {
  return axios.get(`${URL}/kidDevelopmentDiary/wrapToday/${groupId}`);
};

export const getGroupKidEatRows = groupId => {
  return axios.get(`${URL}/kidDevelopmentDiary/eatToday/${groupId}`);
};

export const getGroupKidSleepRows = groupId => {
  return axios.get(`${URL}/kidDevelopmentDiary/sleepToday/${groupId}`);
};

export const getKidsList = groupId => {
  return axios.get(`${URL}/kidDevelopmentDiary/getKidsList/${groupId}`);
};

export const getKidHistory = (kidId, date) => {
  return axios.get(`${URL}/kidDevelopmentDiary/history/${kidId}?date=${date}`);
};

export const deleteEntry = id => {
  return axios.delete(`${URL}/kidDevelopmentDiary/deleteEntry/${id}`);
};

export const editEntry = (id, newMessage, newDate) => {
  return axios.post(`${URL}/kidDevelopmentDiary/editEntry/${id}`, {
    message: newMessage,
    date: newDate,
  });
};

export const getKidsWithArrivalTimeByGroup = (kitaId, groupId) =>
  axios.get(`${URL}/kidDevelopmentDiary/getKidsWithArrivalTime/${kitaId}/${groupId}`);

export const getKidsWithLeavingTimeByGroup = (kitaId, groupId) =>
  axios.get(`${URL}/kidDevelopmentDiary/getKidsWithLeavingTime/${kitaId}/${groupId}`);
