import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { changeActivate2FA, getActivate2FA } from 'library/api/modules';
import { editConfig, getConfig } from 'library/api/kita';
import CheckBox from 'library/common/commonComponents/Checkbox';
import EmailVerification from 'library/common/commonComponents/EmailVerification';
import {
  shouldShowEmailVerification,
  generatePublicKeyCredential,
} from 'library/common/commonComponents/FingerprintVerification/util';

import styles from './authentication2fa.module.scss';

function Authentication2FA() {
  const { t } = useTranslation();
  const [active2FA, setActive2FA] = useState(false);
  const [displayEmailDialog, setDisplayEmailDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastLogin, setLastLogin] = useState(false);
  const [signature, setSignature] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [receivePrivateMessage, setReceivePrivateMessage] = useState(false);
  const [groupMandatoryField, setGroupMandatoryField] = useState(false);
  const [allowParentsAddingChild, setAllowParentsAddingChild] = useState(false);
  const [kidContactAvailableForEmployee, setKidContactAvailableForEmployee] = useState(false);
  const [specificPrivacyVisible, setSpecificPrivacyVisible] = useState(false);
  const [memberOptionVisible, setMemberOptionVisible] = useState(false);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = (await getActivate2FA()).data;
      setActive2FA(res);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    getConfig().then(res => {
      const kitaConfig = res.data;
      setLastLogin(kitaConfig.lastLoginVisible);
      setSignature(kitaConfig.signatureVisible);
      setCommunication(kitaConfig.communicationVisible);
      setReceivePrivateMessage(kitaConfig.receivePrivateMessage);
      setGroupMandatoryField(kitaConfig.groupMandatoryField);
      setAllowParentsAddingChild(kitaConfig.allowParentsAddingChild);
      setKidContactAvailableForEmployee(kitaConfig.kidContactAvailableForEmployee);
      setSpecificPrivacyVisible(kitaConfig.specificPrivacyVisible);
      setMemberOptionVisible(kitaConfig.memberOptionVisible);
    });
  }, []);

  const handle2Fa = async () => {
    if (await shouldShowEmailVerification()) {
      setDisplayEmailDialog(true);
    } else {
      try {
        await generatePublicKeyCredential();
        setIsLoading(true);
        const res = (await changeActivate2FA(active2FA ? 'DEACTIVATE' : 'ENABLE')).data;
        if (res) {
          setActive2FA(prevState => !prevState);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const changeConfig = (config) => {
    editConfig(config).then(res => {
      if (config === 'lastLogin') {
        setLastLogin(!lastLogin);
      } else if (config === 'signature') {
        setSignature(!signature);
      } else if (config === 'communication') {
        setCommunication(!communication);
      } else if (config === 'privateMessage') {
        setReceivePrivateMessage(!receivePrivateMessage);
      } else if (config === 'groupMandatoryField') {
        setGroupMandatoryField(!groupMandatoryField);
      } else if (config === 'allowParentsAddingChild') {
        setAllowParentsAddingChild(!allowParentsAddingChild);
      } else if (config === 'kidContactAvailableForEmployee') {
        setKidContactAvailableForEmployee(!kidContactAvailableForEmployee);
      } else if (config === 'specificPrivacyVisible') {
        setSpecificPrivacyVisible(!specificPrivacyVisible);
      } else if (config === 'memberOptionVisible') {
        setMemberOptionVisible(!memberOptionVisible);
      }
      setRefresh(!refresh);
    });
  };

  const handle2faWithEmailDialog = isSuccess => {
    if (isSuccess) {
      setIsLoading(true);
      changeActivate2FA(active2FA ? 'DEACTIVATE' : 'ENABLE')
        .then(res => {
          if (res.data) {
            setActive2FA(prevState => !prevState);
          }
          setIsLoading(false);
          setDisplayEmailDialog(false);
        })
        .catch(() => {
          setIsLoading(false);
          setDisplayEmailDialog(false);
        });
    } else {
      setDisplayEmailDialog(false);
    }
  };
  return (
    <div className={styles.form2faGroups}>
      <div className={styles.form2faLabel}>{t('GeneralSetting.Authentication2FA')}</div>
      <CheckBox
        className={styles.checkboxSpace}
        name={t('GeneralSetting.Activate two factor auth')}
        isChecked={active2FA}
        isLoading={isLoading}
        onChange={handle2Fa}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Last Login')}
        isChecked={lastLogin}
        isLoading={isLoading}
        onChange={() => changeConfig('lastLogin')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Signature Tab')}
        isChecked={signature}
        isLoading={isLoading}
        onChange={() => changeConfig('signature')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Communication')}
        isChecked={communication}
        isLoading={isLoading}
        onChange={() => changeConfig('communication')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Receive Private Message')}
        isChecked={receivePrivateMessage}
        isLoading={isLoading}
        onChange={() => changeConfig('privateMessage')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.EnableGroupMandatoryField')}
        isChecked={groupMandatoryField}
        isLoading={isLoading}
        onChange={() => changeConfig('groupMandatoryField')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('AllowParentsAddingChild')}
        isChecked={allowParentsAddingChild}
        isLoading={isLoading}
        onChange={() => changeConfig('allowParentsAddingChild')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('KidContactAvailableForEmployee')}
        isChecked={kidContactAvailableForEmployee}
        isLoading={isLoading}
        onChange={() => changeConfig('kidContactAvailableForEmployee')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('SpecificPrivacyVisible')}
        isChecked={specificPrivacyVisible}
        isLoading={isLoading}
        onChange={() => changeConfig('specificPrivacyVisible')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('MemberOptionVisible')}
        isChecked={memberOptionVisible}
        isLoading={isLoading}
        onChange={() => changeConfig('memberOptionVisible')}
      />
      {displayEmailDialog && (
        <EmailVerification
          forSetting={displayEmailDialog}
          handle2FAWithEmailDialog={handle2faWithEmailDialog}
        />
      )}
    </div>
  );
}

export default Authentication2FA;
