import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { approveKita, rejectKita } from 'library/api/kita';
// eslint-disable-next-line max-len
import ListItemWithLogoAvatar from 'library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';

import Button from 'library/common/commonComponents/Buttons/Button';

import styles from '../kitaFrameApproval.module.scss';

export default function KitaFrameApprovalAction({ kitas, currentKita }) {
  const { t } = useTranslation();

  const [isButtonsVisible, setIsButtonsVisible] = useState(true);
  const onApprove = () => {
    approveKita(currentKita, kitas.id).then(() => {
      store.dispatch(showBottomNotification(t('Administration.UserEditingChildren.Approved')));
      setIsButtonsVisible(false);
    });
  };

  const onReject = () => {
    rejectKita(currentKita, kitas.id).then(() => {
      store.dispatch(showBottomNotification(t('Administration.UserEditingChildren.Rejected')));
      setIsButtonsVisible(false);
    });
  };

  const checkIsKitaPending = () => {
    if (kitas.kitaProviderStatus === 'PENDING' && isButtonsVisible) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          <div className={styles.avatar}>
            <ListItemWithLogoAvatar
              groupInfo={{
                logoUrl: kitas.logoUrl,
                groupLogoXCoordinate: kitas.kitaLogoXCoordinate,
                groupLogoYCoordinate: kitas.kitaLogoYCoordinate,
                groupLogoWidth: kitas.kitaLogoWidth,
                groupLogoAspect: 1,
              }}
              letter={kitas.kitaName.trim()[0]}
              backgroundColor={kitas.colorCode}
              className={styles.avatarBox}
            />
            <div className={styles.avatarText}>
              <div className={styles.groupName}>{kitas.kitaName}</div>
            </div>
          </div>
        </td>
        <td>
          <div className={cn(styles.itemName, 'notranslate')}>{kitas.city}</div>
        </td>
        <td>
          <div className={cn(styles.itemName, 'notranslate')}>
            {kitas.kitaProviderStatus === 'PENDING'
              ? t('Administration.Provider.Pending')
              : kitas.kitaProviderStatus === 'rejected'
              ? t('Administration.Provider.Rejected')
              : t('Administration.Provider.Approved')}
          </div>
        </td>
        <td>
          {checkIsKitaPending() && (
            <React.Fragment>
              <Button type='success' className={styles.acceptBttn} onClick={onApprove}>
                {t('Administration.UserEditingChildren.Approve')}
              </Button>
              <Button type='danger' className={styles.rejectBttn} onClick={onReject}>
                {t('Administration.UserEditingChildren.Reject')}
              </Button>
            </React.Fragment>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
}
