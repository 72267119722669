import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from 'library/common/commonComponents/Tooltip';
import defaultImage from 'resources/images/defaultGalleryFolderImage.png';
import iconFullscreen from 'resources/images/fullscreen.svg';

import styles from '../../gallery.module.scss';

export default function GalleryDefaultItem({ onClick }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={cn(styles.gallery, styles.galleryNew)}>
        <Tooltip text={t('Gallery.Default gallery')}>
          <div className={styles.galleryTitle}>{t('Gallery.Default gallery')}</div>
        </Tooltip>
        <div className={styles.galleryImageContainer}>
          <img
            className={cn(styles.galleryImage, styles.galleryImageStub)}
            src={defaultImage}
            alt=''
          />
        </div>

        <div className={styles.galleryOverlay} onClick={onClick} />
        <img className={styles.galleryPlusIcon} src={iconFullscreen} alt='' />
      </div>
    </>
  );
}
