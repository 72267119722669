import { useRef, useState } from 'react';
import { slideUp, slideDown } from 'slide-anim';

export default function useCommentsToggle(isOpened) {
  const ref = useRef(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  if (ref.current) {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    if (isOpened) {
      slideDown(ref.current);
    } else {
      slideUp(ref.current);
    }
  }

  const commentsIsOpened = (isFirstRender && isOpened) || isOpened;

  return { ref, commentsIsOpened };
}
