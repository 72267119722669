import tlds from 'resources/others/tlds';

export function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateEmailWithTLD(email) {
  if (validateEmail(email)) {
    const emailSplit = email.split('.');
    const emailTLD = emailSplit[emailSplit.length - 1];

    if (tlds.includes(emailTLD.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
