import React, { useState } from 'react';
import PT from 'prop-types';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import TextCaret from 'library/common/commonComponents/TextCaret';
import DropdownInPortal from 'library/common/commonComponents/DropdownInPortal';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import { useTranslation } from 'react-i18next';
import { downloadExportFile } from 'library/api/calendar';
import styles from './exportRow.module.scss';

const ExportRow = ({ exportObj, onLoadingHandle, deleteExportHandler }) => {
  const [isOptionsDropdownOpened, setIsOptionsDropdownOpened] = useState(false);
  const outsideEventContainerRef = useOutsideEvent({
    click: true,
    handler: () => setIsOptionsDropdownOpened(false),
  });
  const { t } = useTranslation();
  const deleteExport = async id => {
    onLoadingHandle(true);
    await deleteExportHandler(id);
    onLoadingHandle(false);
  };

  const downloadFileHandler = fileType => {
    const hash = exportObj.link.substring(exportObj.link.lastIndexOf('/') + 1);
    downloadExportFile(hash, exportObj.name, fileType);
  };

  const dropdownOptions = [
    {
      faIcon: 'fa-download',
      title: t('Administration.UserSection.Download ICS'),
      onClick: () => downloadFileHandler('ICS'),
    },
    {
      isSeparator: true,
    },
    {
      faIcon: 'fa-download',
      title: t('Administration.UserSection.Download CSV'),
      onClick: () => downloadFileHandler('CSV'),
    },
    {
      isSeparator: true,
    },
    {
      faIcon: 'fa-trash',
      title: t('Post.Delete'),
      onClick: () => deleteExport(exportObj),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{exportObj.name}</span>
      <TextArea
        value={exportObj.link}
        placeholder=''
        onChange={() => {
          return false;
        }}
      />
      <div className={styles.optionMenu}>
        <Button
          isActive={isOptionsDropdownOpened}
          onClick={() => setIsOptionsDropdownOpened(true)}
          className={styles.menuBtn}
        >
          <div ref={outsideEventContainerRef}>
            <i className='fa fa-cog' />
            <TextCaret />
          </div>
        </Button>

        <DropdownInPortal
          dropdownOptions={dropdownOptions}
          isOptionsDropdownOpened={isOptionsDropdownOpened}
          setIsOptionsDropdownOpened={setIsOptionsDropdownOpened}
          outsideEventContainerRef={outsideEventContainerRef}
        />
      </div>
    </div>
  );
};

ExportRow.propTypes = {
  exportObj: PT.shape({
    id: PT.number.isRequired,
    name: PT.string.isRequired,
    link: PT.string.isRequired,
  }).isRequired,
  onLoadingHandle: PT.func.isRequired,
  deleteExportHandler: PT.func.isRequired,
};

export default ExportRow;
