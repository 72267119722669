import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { updateBreadcrumbs } from 'library/common/commonActions/fileActions';

import Feed from '.';

export default connect(
  state => ({
    user: state.userReducer,
    kitas: state.kitaReducer.kitas,
    activeKita: state.kitaReducer.activeKita,
  }),
  {
    showBottomNotification,
    updateBreadcrumbs,
  },
)(Feed);
