import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { uploadUserKitaImage, editUserKitaImage, deleteUserKitaImage } from 'library/api/kita';

export default function useKitaImageUploading({
  kitaId,
  showBottomNotification,
  patchImage,
  kita,
}) {
  const [progress, setProgress] = useState(null);
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);

  const { t } = useTranslation();
  const changeImage = ([image]) =>
    changeImageHandler({
      image,
      kitaId,
      kita,
      setProgress,
      showBottomNotification,
      patchImage,
      t,
    });

  const deleteImage = () => setIsDeletePopupOpened(true);
  const closeDeletePopup = () => setIsDeletePopupOpened(false);
  const onDeleteBtnClick = () => {
    deleteImageHandler({ kitaId, kita, patchImage });
    setIsDeletePopupOpened(false);
  };

  const deleteHeaderText = <Trans i18nKey='KITAsPanel.KitaImage.Deletion.Title' />;
  const deleteBodyText = t('KITAsPanel.KitaImage.Deletion.Text');

  const editImage = () => setIsEditPopupOpened(true);
  const closeEditPopup = () => setIsEditPopupOpened(false);
  const onEditBtnClick = payload => {
    editImageHandler({ kitaId, payload, patchImage, kita });
    setIsEditPopupOpened(false);
  };
  const editHeaderText = <Trans i18nKey='KITAsPanel.KitaImage.Editing.Title' />;

  return {
    changeImage,
    deleteImage,
    progress,
    isDeletePopupOpened,
    closeDeletePopup,
    onDeleteBtnClick,
    deleteHeaderText,
    deleteBodyText,
    isEditPopupOpened,
    editImage,
    closeEditPopup,
    onEditBtnClick,
    editHeaderText,
  };
}

export async function changeImageHandler({
  image,
  kitaId,
  setProgress,
  showBottomNotification,
  patchImage,
  t,
  kita,
}) {
  const { data } = await uploadUserKitaImage({
    kitaId,
    image,
    setProgress,
  });

  const kitaPayload = {
    unit: '%',
    x: kita.userKitaLogoXCoordinate,
    y: kita.userKitaLogoYCoordinate,
    width: kita.userKitaLogoWidth,
    height: kita.userKitaLogoWidth,
    aspect: 1,
    url: data.fileURL,
  };
  setProgress(null);
  patchImage(kitaPayload);
  showBottomNotification(t('BottomNotifications.Saved'));
}

export const deleteImageHandler = async ({ kitaId, kita, patchImage }) => {
  await deleteUserKitaImage(kitaId);
  patchImage({
    unit: '%',
    x: kita.kitaLogoUrlXCoordinate,
    y: kita.kitaLogoUrlYCoordinate,
    width: kita.kitaLogoUrlWidth,
    height: kita.kitaLogoUrlWidth / kita.kitaLogoUrlAspect,
    aspect: kita.kitaLogoUrlAspect,
    url: null,
  });
};

export const editImageHandler = async ({ kitaId, payload, patchImage }) => {
  const { imageWidth, xCoordinate, yCoordinate } = payload;
  await editUserKitaImage({ ...payload, kitaId });
  patchImage({ width: imageWidth, x: xCoordinate, y: yCoordinate });
};
