import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Card from 'library/common/commonComponents/to-refactor/commonCard';
import CardHeading from 'library/common/commonComponents/to-refactor/commonCard/CardHeading';
import Button from 'library/common/commonComponents/Buttons/Button';
import CardWrapper from 'library/common/commonComponents/to-refactor/commonCardWrapper';

import styles from './authSuccessMessage.module.scss';

function AuthSuccessMessage({ buttonLabel, buttonPress, message, subtitle, title }) {
  const { t } = useTranslation();
  return (
    <CardWrapper>
      <Card className={cn(styles.recovery_container)}>
        <CardHeading className={styles.recovery_heading_text_sign_in}>
          <strong>{`${t(title)} `}</strong>
          <span>{t(subtitle)}</span>
        </CardHeading>
        <CardHeading className={styles.recovery_heading_2}>
          <span>{t(message)}</span>
        </CardHeading>
        <div
          className={cn(styles.back_to_dash_container, 'd-flex flex-row justify-content-between')}
        >
          <Button onClick={buttonPress} type='primary'>
            {t(buttonLabel)}
          </Button>
        </div>
      </Card>
    </CardWrapper>
  );
}

AuthSuccessMessage.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonPress: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AuthSuccessMessage;
