import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import EmailSummariesFrameContainer from '.';

export default connect(
  null,
  {
    showBottomNotification,
  },
)(EmailSummariesFrameContainer);
