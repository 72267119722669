export const EMPLOYEE_ABSENCE_TYPES = Object.freeze({
  HOLIDAY: 'HOLIDAY',
  SICK: 'SICK',
  HOLIDAY_ON_OVERTIME: 'HOLIDAY_ON_OVERTIME',
  ABSENCE_ONE: 'ABSENCE_ONE',
  ABSENCE_TWO: 'ABSENCE_TWO',
  ABSENCE_THREE: 'ABSENCE_THREE',
  ABSENCE_FOUR: 'ABSENCE_FOUR',
  ABSENCE_FIVE: 'ABSENCE_FIVE',
});

export const EMPLOYEE_ABSENCE_ICONS = Object.freeze({
  HOLIDAY: 'fa-plane',
  SICK: 'fa-frown-o',
  HOLIDAY_ON_OVERTIME: 'fa-hourglass',
  ABSENCE_ONE: 'fa-users',
  ABSENCE_TWO: 'fa-battery-half',
  ABSENCE_THREE: 'fa-graduation-cap',
  ABSENCE_FOUR: 'fa-globe',
  ABSENCE_FIVE: 'fa-child',
});

export const EMPLOYEE_ABSENCE_ORDER = Object.freeze([
  'HOLIDAY',
  'SICK',
  'HOLIDAY_ON_OVERTIME',
  'ABSENCE_ONE',
  'ABSENCE_TWO',
  'ABSENCE_THREE',
  'ABSENCE_FOUR',
  'ABSENCE_FIVE',
]);
