import React from 'react';
import { Trans } from 'react-i18next';

export default function getModuleText(mod) {
  if (mod) {
    if (mod.moduleKey === 'translations') {
      return (
        <Trans i18nKey='Administration.ModulesSection.Module will be disabled for institution' />
      );
    } else {
      return <Trans i18nKey='GroupBody.Modules.Are you sure?' />;
    }
  } else return '';
}
