import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import Loader from 'library/common/commonComponents/Loader';
import calendarModule from 'resources/images/modules/calendar_module.png';
import filesModule from 'resources/images/modules/files_module.png';
import galleryModule from 'resources/images/modules/gallery_module.png';
import surveysModule from 'resources/images/modules/surveys_module.png';
import parentSurveyModule from 'resources/images/modules/parent_survey_module.png';
import tasksModule from 'resources/images/modules/tasks_module.png';
import translationsModule from 'resources/images/modules/translations_module.png';
import absenceModule from 'resources/images/modules/absence_module.png';
import portfolioModule from 'resources/images/modules/portfolio_module.png';
import checkInOutModule from 'resources/images/modules/checkinout_module.png';
import bazaarModule from 'resources/images/modules/bazaar_module.png';
import signatureModule from 'resources/images/modules/signature_module.png';
import foodOrderModule from 'resources/images/modules/food_order_module.png';
import wikiModule from 'resources/images/modules/wiki.png';
import coronaModule from 'resources/images/modules/corona_test.png';
import whiteboardModule from 'resources/images/modules/whiteboard_module.png';
import imageDownloadModule from 'resources/images/modules/image_download_module.png';

import styles from './module.module.scss';

const modulesImage = [
  {
    img: filesModule,
    moduleKey: 'files',
  },
  {
    img: absenceModule,
    moduleKey: 'absence',
  },
  {
    img: calendarModule,
    moduleKey: 'calendar',
  },
  {
    img: galleryModule,
    moduleKey: 'gallery',
  },
  {
    img: surveysModule,
    moduleKey: 'survey',
  },
  {
    img: tasksModule,
    moduleKey: 'tasks',
  },
  {
    img: translationsModule,
    moduleKey: 'translations',
  },
  {
    img: parentSurveyModule,
    moduleKey: 'parent_survey',
  },
  {
    moduleKey: 'portfolio',
    img: portfolioModule,
    title: 'Portfolio',
  },
  {
    img: checkInOutModule,
    moduleKey: 'checkinout',
  },
  {
    img: checkInOutModule,
    moduleKey: 'employee_checkinout',
  },
  {
    img: bazaarModule,
    moduleKey: 'bazaar',
  },
  {
    img: signatureModule,
    moduleKey: 'signature',
  },
  {
    img: foodOrderModule,
    moduleKey: 'food_order',
  },
  {
    img: wikiModule,
    moduleKey: 'wiki',
  },
  {
    img: coronaModule,
    moduleKey: 'corona_test',
  },
  {
    img: whiteboardModule,
    moduleKey: 'whiteboard',
  },
  {
    img: calendarModule,
    moduleKey: 'holiday_care',
  },
  {
    img: imageDownloadModule,
    moduleKey: 'image_download',
  },
  {
    img: calendarModule,
    moduleKey: 'working_hours',
  },
];

export default function Module({ match, module, active, setModuleHandler, errors, groupId }) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClick = async () => {
    if (isSubmitting || !module.moduleEnableDisableStatus) {
      return;
    }
    setIsSubmitting(true);
    await setModuleHandler(module);
    setIsSubmitting(false);
  };
  if (
    module.moduleId === 9 &&
    (groupId === 6 || groupId === 188 || groupId === 189 || groupId === 190)
  ) {
    module.moduleDescription = 'Ermöglicht es Umfragen für die Mitarbeiter zu erstellen';
    module.moduleName = 'Mitarbeiterbefragung';
  }

  const foundModule = modulesImage.find(image => module.moduleKey === image.moduleKey);
  let error;
  if (errors) {
    error = errors.find(err => err.moduleKey === module.moduleKey);
  }
  return (
    <div className={styles.module} key={module.moduleId}>
      <div className={styles.leftSection}>
        <div className={styles.module__image}>
          <img src={foundModule ? foundModule.img : tasksModule} alt='' />
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.rightSection__header}>
          <div className={styles.module__title}>{module.moduleName}</div>
          {module.activeStatus && (
            <div className={styles.module__activated}>{t('GroupBody.Modules.Activated')}</div>
          )}
        </div>
        <div className={styles.module__description}>{module.moduleDescription}</div>
        <div
          className={cn(
            styles.module__bttn,
            !module.moduleEnableDisableStatus && styles.module__bttn_disabled,
          )}
          onClick={handleClick}
        >
          {isSubmitting ? (
            <Loader dotColor='#fff' dotSize='8px' className={styles.buttonSubmitting} />
          ) : !module.activeStatus ? (
            t('GroupBody.Modules.Enable')
          ) : (
            t('GroupBody.Modules.Disable')
          )}{' '}
          {error && <span className={styles.error}> - {error.message}</span>}
        </div>
        {module.configureStatus && active ? (
          module.modulekey === 'calendar' ? (
            <Link to={`${match.url.split('/modules')[0]}/calendar/settings`}>
              <div className={cn(styles.module__bttn, styles.module__bttn_configure)}>
                {t('GroupBody.Modules.Configure')}
              </div>
            </Link>
          ) : (
            <div
              className={cn(styles.module__bttn, styles.module__bttn_configure)}
              onClick={() => {}}
            >
              {t('GroupBody.Modules.Configure')}
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}
