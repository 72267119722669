import React, { useState, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Wrapper from 'library/common/commonComponents/Wrapper';
import PageWithSidebar from 'library/common/commonComponents/PageWithSidebar';
import { GroupContext } from 'modules/Group/groupModule';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import styles from './wiki.module.scss';
import Card from '../Card';
import WikiEditPage from './WikiEditPage';
import WikiIndex from './WikiIndex';
import WikiContentPage from './WikiContentPage';
import { deleteWikiEntry, getHomePage, getOrCreateWikiFolder } from '../../../api/wiki';

export default function Wiki() {
  const { t } = useTranslation();
  const { groupInfo } = useContext(GroupContext);

  const [folderId, setFolderId] = useState(0);

  const menuItemIndex = {
    title: t('Wiki.Index'),
    faIcon: 'fa-file-o',
    onClick: () => {
      loadIndexPage();
    },
  };

  const menuItemNewPage = {
    title: t('Wiki.New Page'),
    faIcon: 'fa-plus',
    onClick: () => {
      loadCreationPage();
    },
  };

  const menuItemCancel = {
    title: t('Wiki.Cancel'),
    faIcon: 'fa-times',
    onClick: () => {
      loadIndexPage();
    },
  };

  const initialMenuItems = {
    index: menuItemIndex,
    newPage: menuItemNewPage,
  };

  const [menuItems, setMenuItems] = useState(initialMenuItems);
  const [activeComponent, setActiveComponent] = useState(null);
  const [homePage, setHomePage] = useState(null);

  const loadHomePage = () => {
    getHomePage(groupInfo.groupId).then(res => {
      setHomePage(res == null || res.data == null || res.data === '' ? null : res.data);
    });
  };

  useEffect(() => {
    loadHomePage();

    getOrCreateWikiFolder(groupInfo.groupId)
      .then(res => {
        setFolderId(res.data);
      })
      .catch(() => {
        store.dispatch(showBottomNotification(t('Wiki.An error occured'), { isFail: true }));
      });
  }, []);

  const reloadHomePage = () => {
    if (homePage != null) {
      const newMenuItems = Object.assign({}, menuItems);

      if ('home' in newMenuItems) {
        delete newMenuItems.home;
      }
      newMenuItems.home = {
        title: t('Wiki.Home'),
        faIcon: 'fa-home',
        onClick: () => {
          loadContentPage(homePage);
        },
      };

      setMenuItems(newMenuItems);

      if (activeComponent == null) {
        loadContentPage(homePage);
      }
    } else {
      if (activeComponent == null) {
        loadIndexPage();
      }
      if ('home' in menuItems) {
        const newMenuItems = Object.assign({}, menuItems);
        delete newMenuItems.home;
        setMenuItems(newMenuItems);
      }
    }
  };

  useEffect(() => {
    reloadHomePage();
  }, [homePage]);

  useEffect(() => {
    reloadHomePage();
  }, [activeComponent]);

  const loadContentPage = page => {
    const newMenuItems = {
      index: menuItemIndex,
      newPage: menuItemNewPage,
      edit: {
        title: t('Wiki.Edit'),
        faIcon: 'fa-edit',
        onClick: () => {
          loadEditPage(page);
        },
      },
    };

    setMenuItems(newMenuItems);
    setActiveComponent(<WikiContentPage page={page} />);
  };

  const loadIndexPage = () => {
    const newMenuItems = {
      index: menuItemIndex,
      newPage: menuItemNewPage,
    };

    setMenuItems(newMenuItems);
    setActiveComponent(<WikiIndex onWikiEntryClicked={loadContentPage} />);
  };

  const loadCreationPage = () => {
    const newMenuItems = {
      index: menuItemIndex,
      cancel: menuItemCancel,
    };

    setMenuItems(newMenuItems);
    setActiveComponent(
      <WikiEditPage
        page={null}
        onSave={() => {
          loadIndexPage();
          loadHomePage();
        }}
        folderId={folderId}
      />,
    );
  };

  const loadEditPage = page => {
    const newMenuItems = {
      index: menuItemIndex,
      cancel: menuItemCancel,
      delete: {
        title: t('Wiki.Delete Page'),
        faIcon: 'fa-trash',
        onClick: () => {
          deleteWikiEntry(groupInfo.groupId, page.wikiEntryId).then(() => {
            loadIndexPage();
            loadHomePage();
          });
        },
      },
    };

    setMenuItems(newMenuItems);
    setActiveComponent(
      <WikiEditPage
        page={page}
        onSave={() => {
          loadIndexPage();
          loadHomePage();
        }}
        folderId={folderId}
      />,
    );
  };

  return (
    <Wrapper>
      <Card
        className={styles.cardContainer}
        title={t('Wiki.Wiki title')}
        titleClassName={styles.bodyTitle}
      >
        <PageWithSidebar
          currentPage=''
          menuItems={menuItems}
          menuTitle={
            <h2>
              <Trans i18nKey='Signature.Actions' />
            </h2>
          }
        />
        {activeComponent}
      </Card>
    </Wrapper>
  );
}
