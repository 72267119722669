import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'library/common/commonComponents/Inputs/Input';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { addEditParentSurveySuggestion } from 'library/api/parentSurveys';

import styles from '../../feedItemParentSurvey.module.scss';

export default function FeedItemParentSurveyOptionEditing({
  questionId,
  optionId,
  answer,
  parentSurveyDispatch,
  isSubmiting,
  setEditSuggestion,
  setIsSubmiting,
                                                            parentSurveyId
}) {
  const { t } = useTranslation();

  const [localValue, setLocalValue] = useState(answer);
  const [inputError, setInputError] = useState(null);
  const saveOption = () =>
    saveOptionHandler({
      value: localValue,
      questionId,
      optionId,
      parentSurveyDispatch,
      setInputError,
      setEditSuggestion,
      setIsSubmiting,
      t,
      parentSurveyId,
    });
  return (
    <div className={styles.addSuggestionWrapper}>
      <div className={styles.addSuggestionContainer}>
        <Input
          value={localValue}
          placeholder={t('Surveys.Add answer')}
          className={styles.addSuggestionInput}
          onChange={e => setLocalValue(e.target.value)}
        />
        <ButtonWithLoader
          type='default'
          size='sm'
          className={styles.addSuggestionButton}
          onClick={saveOption}
          isLoading={isSubmiting}
        >
          {t('Post.Save')}
        </ButtonWithLoader>
      </div>
      {inputError && <div className={styles.error}>{inputError}</div>}
    </div>
  );
}

export function saveOptionHandler({
  value,
  questionId,
  optionId,
  parentSurveyDispatch,
  setInputError,
  setEditSuggestion,
  setIsSubmiting,
                                    parentSurveyId,
  t,
}) {
  if (value.trim().length === 0) {
    return setInputError(t('Surveys.Answer field cannot be blank'));
  }
  setIsSubmiting(true);
  addEditParentSurveySuggestion(parentSurveyId, questionId, optionId, value).then(data => {
    parentSurveyDispatch({
      type: 'editSuggestion',
      optionId,
      questionId,
      answer: value,
    });
    setIsSubmiting(false);
    setInputError(null);
    setEditSuggestion(null);
  });
}
