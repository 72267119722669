import React from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Comment from 'library/common/commonComponents/Comment';
import EditorWithButton from 'library/common/commonComponents/Inputs/EditorWithButton';
import useCommentsInput from 'library/common/commonHooks/useCommentsInput';

import styles from './commentsPopup.module.scss';

export default function CommentsPopup({
  id,
  user,
  comments,
  setComments,
  onDelete,
  updateCommentById,
  isOpened,
  closePopup,
  title,
}) {
  const { Editor, error, onSubmit, focusOnEditor, isSubmiting } = useCommentsInput({
    user,
    id,
    comments,
    setComments,
  });
  const { t } = useTranslation();

  return (
    <Popup
      isOpened={isOpened}
      onOpen={focusOnEditor}
      closePopup={closePopup}
      header={title}
      footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
    >
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={400}>
            {comments
              .sort((a, b) => a.id - b.id)
              .map(comment => (
                <Comment
                  user={user}
                  name={comment.name}
                  key={comment.id}
                  onDelete={onDelete}
                  updateCommentById={updateCommentById}
                  postId={id}
                  {...comment}
                />
              ))}
          </Scrollbars>
          <EditorWithButton
            Editor={Editor}
            error={error}
            onSubmit={onSubmit}
            btnText={t('Post.Send')}
            className={styles.editor}
            isSubmiting={isSubmiting}
          />
        </div>
      </div>
    </Popup>
  );
}
