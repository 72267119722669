import React from 'react';

import Card from 'library/common/commonComponents/Card';

import styles from './cardDropdown.module.scss';

export default function CardDropdown({ header, footer, children }) {
  return (
    <Card>
      <div className={styles.cardDropdown}>
        <div className={styles.header}>{header}</div>
        <div>{children}</div>
        <div className={styles.footer}>{footer}</div>
      </div>
    </Card>
  );
}
