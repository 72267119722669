import React from 'react';
import { useTranslation } from 'react-i18next';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from '../../../../../../../../../../library/common/commonComponents/Inputs/Select';

export default function TableParentalContributionEditView({ component, setComponent, formFields }) {
  const { t } = useTranslation();

  const bookingTimesTableOptions = formFields
    .filter(ff => ff.type === 'tableBookingTimes')
    .map(ff => {
      return {
        value: ff.name,
        label: ff.name,
      };
    });

  return (
    <div>
      <Label type='input'>{t('FormsEditor.ComponentEditorTimeSourceLabel')}</Label>
      <Select
        options={bookingTimesTableOptions}
        onSelect={e => {
          setComponent({
            ...component,
            timeSource: e.value,
          });
        }}
        selected={bookingTimesTableOptions.find(op => op.value === component.timeSource)}
        style={{ zIndex: '10000000000' }}
      />
    </div>
  );
}
