import React, { useState, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Tabs from 'library/common/commonComponents/Tabs';

import CreateEditTaskBasic from './CreateEditTaskFrames/CreateEditTaskBasic';
import CreateEditTaskScheduling from './CreateEditTaskFrames/CreateEditTaskSched';
import CreateEditTaskAssignment from './CreateEditTaskFrames/CreateEditTaskAssig';
import CreateEditTaskChecklist from './CreateEditTaskFrames/CreateEditTaskCheck';
import CreateEditTaskFiles from './CreateEditTaskFrames/CreateEditTaskFiles';

import styles from './createEditTask.module.scss';

export function reducer(state, action) {
  switch (action.type) {
    case 'setTitle':
      return { ...state, title: action.payload };
    case 'setMaxAssignedUserCount':
      return { ...state, maxAssignedUserCount: action.payload };
    case 'setTaskList':
      return { ...state, taskListId: +action.payload.value };
    case 'setDescription':
      return { ...state, description: action.payload };
    case 'setIsPublic':
      return { ...state, isPublic: !state.isPublic };
    case 'setIsScheduling':
      return { ...state, isScheduling: !state.isScheduling };
    case 'setAllDay':
      return { ...state, allDay: !state.allDay };
    case 'setStart':
      return { ...state, start: action.payload && action.payload.getTime() };
    case 'setEnd':
      return { ...state, end: action.payload && action.payload.getTime() };
    case 'setTimeZone':
      return { ...state, timeZone: action.payload.value };
    case 'setAddToCalendar':
      return { ...state, addToCalendar: !state.addToCalendar };
    case 'setAssignedUsersList':
      return { ...state, assignedUsersList: action.payload };
    case 'setResponsibleUsersList':
      return { ...state, responsibleUsersList: action.payload };
    case 'setreviewRequired':
      return { ...state, reviewRequired: !state.reviewRequired };
    case 'setChecklist':
      return { ...state, checklist: action.payload };
    case 'setDeletedFileIds':
      return { ...state, deletedFileIds: state.deletedFileIds.concat(action.payload) };
    case 'setFiles':
      return { ...state, files: action.payload };
    case 'setError':
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export default function CreateEditTask({
  task,
  isOpened,
  onClose,
  onSubmit,
  canBePublic,
  taskLists,
  user,
  taskListId,
  groupId,
  canAddToCalendar,
}) {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, {
    title: task.title || '',
    maxAssignedUserCount: task.maxAssignedUserCount || '',
    taskListId: taskListId || 1,
    description: task.description || '',
    isPublic: task.isPublic || false,
    isScheduling: task.isScheduling || false,
    allDay: task.allDay === undefined ? true : task.allDay,
    start: task.start || '',
    end: task.end || '',
    timeZone: '(UTC+02:00) Europe/Berlin',
    addToCalendar: task.addToCalendar || false,
    assignedUsersList: task.assignedUsersList || [],
    responsibleUsersList: task.responsibleUsersList || [],
    reviewRequired: task.reviewRequired || false,
    checklist:
      task.checklist && task.checklist.length
        ? task.checklist
        : [{ id: null, title: '', status: false }],
    deletedFileIds: [],
    files: task.files || [],
    error: {
      title: false,
      maxAssignedUserCount: false,
      startDateBlank: false,
      endDateBlank: false,
      startTimeBlank: false,
      endTimeBlank: false,
      startMoreEnd: false,
      assignedSuperAdmin: false,
      noResponseUser: false,
    },
  });
  const [activeTab, setActiveTab] = useState(0);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const tabs = [
    {
      title: t('Tasks.Basic'),
      path: 'basic',
      component: (
        <CreateEditTaskBasic
          createEditTaskState={state}
          createEditTaskdispatch={dispatch}
          taskLists={taskLists}
          canBePublic={canBePublic}
        />
      ),
    },
    {
      title: t('Tasks.Assignment'),
      path: 'assignment',
      component: (
        <CreateEditTaskAssignment
          createEditTaskState={state}
          createEditTaskdispatch={dispatch}
          user={user}
          groupId={groupId}
        />
      ),
    },
    {
      title: t('Tasks.Checklist'),
      path: 'checklist',
      component: (
        <CreateEditTaskChecklist createEditTaskState={state} createEditTaskdispatch={dispatch} />
      ),
    },
    {
      title: t('Tasks.Files'),
      path: 'files',
      component: (
        <CreateEditTaskFiles
          createEditTaskState={state}
          createEditTaskdispatch={dispatch}
          groupId={groupId}
        />
      ),
    },
  ];

  const schedulingTab = {
    title: t('Tasks.Scheduling'),
    path: 'scheduling',
    component: (
      <CreateEditTaskScheduling
        createEditTaskState={state}
        createEditTaskdispatch={dispatch}
        canAddToCalendar={canAddToCalendar}
        user={user}
      />
    ),
  };

  if (state.isScheduling) {
    tabs.splice(1, 0, schedulingTab);
  }

  const submit = async () => {
    if (isSubmiting) {
      return;
    }

    setIsSubmiting(true);

    let hasError = false;
    const newError = {
      title: false,
      maxAssignedUserCount: false,
      startDateBlank: false,
      endDateBlank: false,
      startTimeBlank: false,
      endTimeBlank: false,
      startMoreEnd: false,
      assignedSuperAdmin: false,
      noResponseUser: false,
    };

    if (state.title.trim() === '') {
      dispatch({ type: 'setError', payload: { ...newError, title: true } });
      setActiveTab(0);
    } else if (state.maxAssignedUserCount < 0 && state.maxAssignedUserCount !== '') {
      dispatch({ type: 'setError', payload: { ...newError, maxAssignedUserCount: true } });
      setActiveTab(0);
    } else if (state.reviewRequired && state.responsibleUsersList.length === 0) {
      dispatch({ type: 'setError', payload: { ...newError, noResponseUser: true } });
      setActiveTab(state.isScheduling ? 2 : 1);
    } else if (
      state.assignedUsersList.length &&
      state.assignedUsersList.find(item => item.superAdminStatus)
    ) {
      dispatch({ type: 'setError', payload: { ...newError, assignedSuperAdmin: true } });
      setActiveTab(state.isScheduling ? 2 : 1);
    } else if (state.isScheduling) {
      if (!state.start) {
        hasError = true;
        newError.startDateBlank = true;
      }
      if (!state.end) {
        hasError = true;
        newError.endDateBlank = true;
      }
      if (state.start > state.end) {
        hasError = true;
        newError.startMoreEnd = true;
      }
      if (hasError) {
        dispatch({ type: 'setError', payload: { ...newError } });
        setActiveTab(1);
      } else {
        await onSubmit(state);
      }
    } else {
      await onSubmit(state);
    }

    setIsSubmiting(false);
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={onClose}
      header={
        isOpened === 'add' ? (
          <Trans i18nKey='Tasks.Create new task' />
        ) : (
          <Trans i18nKey='Tasks.Edit task' />
        )
      }
      footer={
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonSave}>
            <ButtonWithLoader onClick={submit} type='primary' isLoading={isSubmiting}>
              {t('Tasks.Save')}
            </ButtonWithLoader>
          </div>
          <div className={styles.buttonCancel}>
            <Button onClick={onClose}>{t('Tasks.Cancel')}</Button>
          </div>
        </div>
      }
    >
      <div className={styles.wrapper}>
        <Tabs
          tabs={tabs}
          onChange={(_, index) => setActiveTab(index)}
          currentTab={tabs[activeTab].path}
        />
      </div>
    </Popup>
  );
}
