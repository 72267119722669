import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { sendGlobalNotification } from 'library/api/notifications';
import Checkbox from 'library/common/commonComponents/Checkbox';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';

import styles from './notificationFrame.module.scss';

export default function NotificationFrame({ showBottomNotification }) {
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [onlyAdminCheckbox, setOnlyAdminCheckbox] = useState(false);

  const sendMessage = () => {
    setIsError(false);
    if (!message.trim().length) {
      setIsError(true);
      return null;
    }
    setIsLoading(true);
    sendGlobalNotification(message.trim(), onlyAdminCheckbox)
      .then(() => {
        showBottomNotification(t('BottomNotifications.Success'));
        setMessage('');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={styles.wrapper}>
      <p className={styles.description}>
        {t('Administration.Notification.Notification will be send for all users')}
      </p>
      <Checkbox
        isChecked={onlyAdminCheckbox}
        onChange={() => {
          setOnlyAdminCheckbox(!onlyAdminCheckbox);
        }}
        name={t('Administration.Notification.Send only to admin and employee')}
        className={styles.checkbox}
      />
      <Input
        type='text'
        maxLength={512}
        error={isError}
        value={message}
        placeholder={t('Administration.Notification.message')}
        onChange={({ target }) => setMessage(target.value)}
      />
      {isError && (
        <InputErrorMsg errorMsg={t('Administration.Notification.Message cannot be blank')} />
      )}

      <ButtonWithLoader
        isLoading={isLoading}
        onClick={sendMessage}
        className={styles.send}
        type='primary'
      >
        {t('Administration.Notification.send')}
      </ButtonWithLoader>
    </div>
  );
}
