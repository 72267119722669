import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import Tabs from 'library/common/commonComponents/Tabs';
import FoodOrderKita from './FoodOrderKita';
import FoodOrderKitchen from './FoodOrderKitchen';
import FoodOrderParents from './FoodOrderParents';

export default function FoodOrderBody({
  groupId,
  groupInfo,
  showBottomNotification,
  activeKita,
  user,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const kitchen = {
    title: <Trans i18nKey='FoodOrder.Kitchen' />,
    path: 'kitchen',
    component: (
      <FoodOrderKitchen
        showBottomNotification={showBottomNotification}
        groupId={groupId}
        activeKita={activeKita}
        groupInfo={groupInfo}
      />
    ),
  };

  const kita = {
    title: <Trans i18nKey='FoodOrder.Kita' />,
    path: 'kita',
    component: (
      <FoodOrderKita
        showBottomNotification={showBottomNotification}
        groupId={groupId}
        userId={user.id}
        activeKita={activeKita}
        groupInfo={groupInfo}
      />
    ),
  };

  const parents = {
    title: <Trans i18nKey='FoodOrder.Parents' />,
    path: 'parents',
    component: (
      <FoodOrderParents
        showBottomNotification={showBottomNotification}
        groupId={groupId}
        activeKita={activeKita}
        groupInfo={groupInfo}
      />
    ),
  };
  const tabs = [];
  if (groupInfo.groupAdminStatus) {
    tabs.push(kitchen);
    tabs.push(kita);
  }
  if (!groupInfo.groupAdminStatus && user.lastName === 'Caterer') {
    tabs.push(kitchen);
  } else {
    tabs.push(parents);
  }

  return (
    <div>
      <Tabs
        tabs={tabs}
        onChange={(_, index) => setActiveTab(index)}
        currentTab={tabs[activeTab].path}
      />
    </div>
  );
}
