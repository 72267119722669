import config, { getPort } from 'main/config';
import { getToken } from 'library/utilities/token';

export function getUserFullName(user) {
  if (user) {
    const firstName = user.firstName
      ? user.firstName.length > 10
        ? getCuttedString(user.firstName, 10)
        : user.firstName
      : '';
    const lastName = user.lastName
      ? user.lastName.length > 10
        ? getCuttedString(user.lastName, 10)
        : user.lastName
      : '';
    return `${firstName} ${lastName}`;
  }
  return '';
}

export function getCuttedString(str, limit) {
  const currentLimit = limit || 10;
  return str ? (str.length > currentLimit ? str.slice(0, currentLimit) + '...' : str) : '';
}

export function getUserLogoUrl(userId) {
  const token = getToken();
  if (token == null || !('tkval' in token)) {
    return `${config.API_BASE_URI}${getPort(8080)}/public/user/${userId}/profile-picture`;
  }
  return `${config.API_BASE_URI}${getPort(
    8080,
  )}/public/user/${userId}/profile-picture?tkval=${encodeURIComponent(token.tkval)}`;
}

export function isPublicUser(user) {
  return user?.email?.toLowerCase().includes('standarduserawo');
}
