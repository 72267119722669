import { useState, useEffect } from 'react';

import { getSummariesConfiguration } from 'library/api/summaries';

export default function useEmailSummariesConfiguration() {
  const [isLoading, setIsLoading] = useState(true);
  const [configuration, setConfiguration] = useState({});

  useEffect(() => {
    getSummariesConfiguration().then(({ data }) => {
      setConfiguration(data);
      setIsLoading(false);
    });
  }, []);

  const patchConfiguration = payload => setConfiguration({ ...configuration, ...payload });

  return { configuration, isLoading, patchConfiguration };
}
