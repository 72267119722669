import React from 'react';
import cn from 'classnames';

import styles from './videoPreview.module.scss';

export default function VideoPreview({ className, file, onClick }) {
  return (
    <div className={cn(styles.container, className)} onClick={onClick}>
      <video className={styles.video} preload='metadata'>
        <source src={`${file.path}#t=0.01`} />
      </video>
      <i className={cn('fa fa-play', styles.icon)} />
    </div>
  );
}
