import React, { useState, useEffect } from 'react';
import Input from 'library/common/commonComponents/Inputs/Input';
import { connect } from 'react-redux';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import { addBgwNews, getBgwNews } from 'library/api/bgwChecklist';
import bgwLogo from 'resources/images/bgw/bgwLogo_png.png';
import NewsRow from './NewsRow';
import styles from './bgwnews.module.scss';

function BgwNews({ isSuperadmin }) {
  const [inputText, setInputText] = useState('');
  const [allNews, setAllNews] = useState([]);

  useEffect(() => {
    getBgwNews().then(data => {
      setAllNews(data.data.reverse());
    });
  }, []);
  return (
    <div>
      {isSuperadmin && (
        <>
          <TextArea
            type='text'
            value={inputText}
            onChange={e => setInputText(e.target.value)}
            maxLength={1024}
          />
          <br />
          <Button
            onClick={() => {
              addBgwNews(inputText).then(() => {
                window.location.reload();
              });
            }}
          >
            Absenden
          </Button>
        </>
      )}
      <div className={styles.title}>
        News
        <img
          src={bgwLogo}
          style={{ float: 'right', marginTop: '-4px', height: '50px' }}
          alt='Bgw'
        />
      </div>
      {allNews.map(data => {
        return <NewsRow data={data} />;
      })}
    </div>
  );
}

export default connect(state => ({
  isSuperadmin: state.userReducer.superAdminStatus,
}))(BgwNews);
