import React from 'react';
import cn from 'classnames';

import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './calendarFilters.module.scss';

export default function CalendarFilters({ filterList }) {
  return (
    <div className={styles.filtersContainer}>
      {filterList.map(filterItem => (
        <div key={filterItem.title} className={cn(styles.filterContainer, filterItem.className)}>
          <div className={styles.title}>{filterItem.title}</div>
          <div className={styles.checkBoxesContainer}>
            {filterItem.filters.filter((f) => !f.hidden).map(filter => (
              <CheckBox
                key={filter.id}
                className={styles.checkbox}
                name={filter.name}
                isChecked={filter.checked}
                onChange={filter.onChange}
                isLoading={filter.isLoading}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
