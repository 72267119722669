import React from 'react';
import { useTranslation } from 'react-i18next';

import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import FilePrewiev from 'library/common/commonComponents/FilePrewiev';
import useMultipleUpload from 'library/common/commonHooks/useMultipleUpload';

import styles from './createEditTaskFiles.module.scss';

export default function CreateEditTaskFiles({
  createEditTaskState,
  createEditTaskdispatch,
  groupId,
}) {
  const { t } = useTranslation();
  const { files, uploadFile, deleteFiles } = useMultipleUpload(createEditTaskState.files);

  const onFilesChange = (newFiles, deletedFile) => {
    createEditTaskdispatch({ type: 'setFiles', payload: newFiles });
    if (deletedFile && deletedFile.id) {
      createEditTaskdispatch({ type: 'setDeletedFileIds', payload: deletedFile.id });
    }
  };

  return (
    <div className={styles.container}>
      <UploadButton
        className={styles.uploadButton}
        onChange={file => uploadFile(file, onFilesChange)}
        isMultiple
        files={files}
        groupId={groupId}
      >
        {t('Calendar.Upload')}
      </UploadButton>
      <FilePrewiev newFiles={files} onDelete={file => deleteFiles(file, onFilesChange)} />
    </div>
  );
}
