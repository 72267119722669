import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api/formeditor`;
const usermanagementURL = `${config.API_BASE_URI}${getPort(8080)}/api`;

export const getForms = () => {
  return axios.get(`${URL}/list`);
};

export const saveForm = form => {
  return axios.post(`${URL}/save`, form);
};

export const importForm = formNaturalId => {
  return axios.post(`${URL}/import/${formNaturalId}`);
};

export const deleteForm = id => {
  return axios.delete(`${URL}/delete/${id}`);
};

export const getSettings = () => {
  return axios.get(`${URL}/settings`);
};

export const saveSettings = (defaultContractForm,allowParentsFillOut) => {
  return axios.post(`${URL}/settings`, {
    defaultContractForm: defaultContractForm,
    allowParentsFillOut: allowParentsFillOut,
  });
};

export const getTemplates = () => {
  return axios.get(`${URL}/templates`);
};

export const saveTemplate = (id, name, tags, formFieldId) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('name', name);
  formData.append('tags', tags);
  formData.append('formFieldId', formFieldId);

  return axios.post(`${URL}/template/save`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteTemplate = id => {
  return axios.delete(`${URL}/template/${id}`);
};

export const saveDefaultContractSubmission = (submission, newAttachments, deletedAttachments, sendInfoMail = false, kidOrUser) => {
  
  const formFieldsTemp = submission.formFields;

  for (const [key, value] of Object.entries(formFieldsTemp)) {
    if(key.startsWith("lastEditedAt_") || key.startsWith("lastEditedBy_")){
      delete formFieldsTemp[key];
    }
  }

  const formData = new FormData();
  formData.append("saveRequest", new Blob([JSON.stringify({
    defaultContractSubmissionDTO: {...submission, formFields: formFieldsTemp},
    deletedAttachments: deletedAttachments,
    sendInfoMail: sendInfoMail,
  })], {
    type: "application/json"
  }));

  for (let i = 0; i < newAttachments.length; i++) {
    formData.append('attachments[]', newAttachments[i]);
  }

  return axios.post(`${URL}/defaultContract/save/${kidOrUser}`, formData);
};

export const getDefaultContractSubmission = (formId, childId, kidOrUser) => {
  return axios.get(`${URL}/defaultContract/${formId}/${childId}/${kidOrUser}`);
};

export const getAllKidsWithNameAndIdFromSelectedKita = () => {
  return axios.get(`${usermanagementURL}/allKidsWithNameAndIdFromSelectedKita`);
};

export const getAllKidsForUserWithNameAndIdFromSelectedKita = () => {
  return axios.get(`${usermanagementURL}/getkidsforcurrentuserinselectedkita`);
};

export const getAllUsersWithNameAndIdFromSelectedKita = () => {
  return axios.get(`${usermanagementURL}/allUsersWithNameAndIdFromSelectedKita`);
};


export const getKidWithDetailsAndParentInformationById = (kidId) => {
  return axios.get(`${usermanagementURL}/kidWithDetailsAndParentInformationById/${kidId}`);
};

export const storePDFToContractFolder = (pdfData, childId) => {
  return axios.post(`${URL}/saveDefaultContractPDF/${childId}`, pdfData);
};

export const getUserSignatureImage = () => {
  return axios.get(`${usermanagementURL}/getUserSignatureImage`);
};

export const deleteUserSignatureImage = () => {
  return axios.delete(`${usermanagementURL}/deleteUserSignatureImage`);
};

export const storeUserSignatureImage = (userSignatureImageString) => {
  return axios.post(`${usermanagementURL}/storeUserSignatureImage`, userSignatureImageString);
};

export const getContractStatusMapForKids = (kidIds) => {
  const formData = new FormData();
  formData.append('kidIds', kidIds);

  return axios.get(`${URL}/contractStatusMap?${kidIds.map(
    id => `kidIds[]=${id}`,
  ).join("&")}`);
};
