import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './friendRequestTableEmpty.module.scss';

export default function FriendRequestTableEmpty() {
  const { t } = useTranslation();
  return (
    <tr>
      <td className={styles.tableEmpty} colSpan='4'>
        {t('AccountSettings.FriendsSection.No results found')}
      </td>
    </tr>
  );
}
