import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import UsersFrameRegistrationRow from '.';

export default connect(
  state => ({
    activeKita: state.kitaReducer.activeKita,
  }),
  { showBottomNotification },
)(UsersFrameRegistrationRow);
