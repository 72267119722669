import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useEditor from 'library/common/commonHooks/useEditor';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { checkLink } from 'library/utilities/checkLink';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import useMultipleUpload from 'library/common/commonHooks/useMultipleUpload';
import FilePrewiev from 'library/common/commonComponents/FilePrewiev';
import { getUploadFilesHandler } from 'library/utilities/getUploadFilesHandler';
import RecordButton from 'library/common/commonComponents/Buttons/RecordButton/RecordButtonContainer';
import { handleRNMessageHelper } from "library/utilities/handleRNMessageHelper";
import Loader from 'library/common/commonComponents/Loader';
import { VOICE_MESSAGE_AVAILABLE_APPS } from 'main/config';

import styles from './chatMessagesViewFooter.module.scss';

export default function ChatMessagesViewFooter({
  chatId,
  onSendMessage,
  showBottomNotification,
  user,
}) {
  const { t } = useTranslation();
  const [newText, setNewText] = useState('');
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSbMasterclass = window.location.hostname.toLowerCase().includes('sbmasterclass.safe2connect.org');

  const { files, uploadFile, deleteFiles, setFiles } = useMultipleUpload(null);
  const [isVoiceMessageAvailable, setIsVoiceMessageAvailable] = useState(user.administrationAccess || user.employee || isSbMasterclass);
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const uploadFilesHandler = getUploadFilesHandler({
    chatId,
    userId: user.id,
    uploadFile,
    showBottomNotification,
    t,
    setIsLoadingFile
  });
  const [progress, setProgress] = useState(null);

  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = newText;
  const { Editor, getEditorValue, updateValue } = useEditor({
    message: newText,
    placeholder: t('Chat.Message Placeholder'),
    onChange: () => {
      const value = getEditorValue();
      setError(false);
      setNewText(checkLink(value));
    },
    className: styles.textareaContent,
    isChat: true,
  });

  const handleSendMessage = async () => {
    if (isSubmitting) {
      return;
    }
    let message = newText;
    if (message.trim() === '' && !files.length) {
      return setError('sendMessage');
    }
    if (files.length && message.trim() === '') {
      message = '&nbsp;';
    }
    setIsSubmitting(true);
    await onSendMessage(chatId, message, files, setProgress);
    setProgress(null);
    setFiles([]);
    updateValue(document.createElement('div'));
    setIsSubmitting(false);
  };

  const checkVoiceMessageAvailability = (rnEvent) => {
    if (!rnEvent || !rnEvent.data) {
      return;
    }

    setIsVoiceMessageAvailable(user.administrationAccess || user.employee || VOICE_MESSAGE_AVAILABLE_APPS.includes(rnEvent.data) || isSbMasterclass);
  }

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      return;
    }

    [window, document].forEach(el => {
      el.addEventListener('message', handleRNMessageHelper('native.projectId', checkVoiceMessageAvailability));
    })

    return () => {
      [window, document].forEach(el => {
        el.removeEventListener('message', handleRNMessageHelper('native.projectId', checkVoiceMessageAvailability));
      });
    }
  }, [window.ReactNativeWebView]);

  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.editorContainer, error && styles.editorContainerError)}>
          <Editor />
          {error === 'sendMessage' && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={t('Chat.Message cannot be blank')} />
            </div>
          )}
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonSend}>
            <UploadButton
              className={styles.uploadButton}
              onChange={uploadFilesHandler}
              isMultiple
              maxFileSize={200}
              files={files}
            />

            {
              isVoiceMessageAvailable && (
                <div className={styles.voiceButton}>
                  <RecordButton
                    uploadFile={uploadFilesHandler}
                    voiceForPost={true}
                  />
                </div>
              )
            }

            <ButtonWithLoader
              className={styles.buttonWithLoader}
              onClick={handleSendMessage}
              isLoading={isSubmitting}
            >
              {t('Chat.Send')}
            </ButtonWithLoader>
          </div>
        </div>
      </div>
      <div className={styles.fileContainer}>
        {progress && files.length > 0 && (
          <div className={styles.progressBar}>
            <div className={styles.progressBarValue} style={{ width: progress + '%' }} />
          </div>
        )}
        {
          isLoadingFile && (
            <Loader dotSize='10px' className={styles.loader} />
          )
        }
        <FilePrewiev newFiles={files} onDelete={deleteFiles} />
      </div>
    </>
  );
}
