import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import {
  declineGfzKidLeaving,
  postGfzKidLeaving,
  getGfzKidLeaving,
  getAllKidsFromSelectedKita,
  getAllOwnKidsFromSelectedKita
} from "library/api/observationSheet";
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import RadioButton from 'library/common/commonComponents/RadioButton';
import { createPDFKidLeaving } from 'library/utilities/files';
import { removeWhiteBackgroundSignature } from 'library/utilities/signature';
import store from 'main/store/configureStore';
import { onGfzFormSubmitted } from '../util.js';

import gfzLogo from 'resources/images/gfz/gfz_logo.png';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader/index.jsx';

export default function KidLeaving({ isAdmin, isEmployee, userId, kitaName, kidId, formId, setActiveTab }) {
  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [isExistingForKid, setIsExistingForKid] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [kidLeaving, setKidLeaving] = useState({
    kitaName: '',
    groupName: '',
    todayDate: new Date().toISOString().split('T')[0],
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    contract: 'subv',
    leaveDate: new Date().toISOString().split('T')[0],
    parentSignature: null,
    parentSignatureDate: new Date().toISOString().split('T')[0],
    kitaSignature: null,
    kitaSignatureDate: new Date().toISOString().split('T')[0],
    kidId: null,
  });
  const sigCanvasParent = useRef();
  const sigCanvasKita = useRef();
  const isAdminOrEmployee = isAdmin || isEmployee;

  const user = useSelector(state => state.userReducer);
  const noKidSelected = Array.isArray(selectedKid) && selectedKid.length === 0;
  const getKitaKidsFunction = isAdminOrEmployee
    ? getAllKidsFromSelectedKita
    : getAllOwnKidsFromSelectedKita;
  const { t } = useTranslation();

  const [isParentOfKid, setIsParentOfKid] = useState(false);

  useEffect(() => {
    setIsParentOfKid(
      selectedKid &&
      selectedKid !== [] &&
      user.kids &&
      user.kids.find(k => k.id === selectedKid.id),
    );
    sigCanvasParent.current.clear();
    sigCanvasKita.current.clear();
  }, [selectedKid]);

  useEffect(() => {
    if (isParentOfKid) {
      sigCanvasParent.current.on();
    } else {
      sigCanvasParent.current.off();
    }

    if (isAdmin) {
      sigCanvasKita.current.on();
    } else {
      sigCanvasKita.current.off();
    }
  }, [isParentOfKid]);

  useEffect(() => {
    getKitaKidsFunction().then(data => {
      const unformattedKidsList = data.data;
      const formattedKidsList = unformattedKidsList.map(obj => {
        return {
          label: obj.firstName + ' ' + obj.lastName,
          id: isAdminOrEmployee ? obj.kidId : obj.id,
          portfolioGroupId: isAdminOrEmployee ? obj.portfolioGroupId : obj.groups.id,
          kidGroup: obj.kidGroup,
        };
      });
      setKidsList(formattedKidsList);
    });
    if (!isAdmin && !isParentOfKid) {
      setFormDisabled(true);
    }
  }, []);

  const loadGfzKidLeavingsForKid = (kid, selectedFormId) => {
    if (!isAdmin && !isParentOfKid) {
      setFormDisabled(false);
    }
    setIsCompleted(false);
    setIsExistingForKid(false);
    getGfzKidLeaving(kid.id, selectedFormId).then(res => {
      if (res != null && res.data != null) {
        setIsExistingForKid(true);
        setKidLeaving({
          ...res.data,
          kitaSignatureDate:
            res.data.kitaSignatureDate === null ? '' : res.data.kitaSignatureDate,
          parentSignatureDate:
            res.data.parentSignatureDate === null ? '' : res.data.parentSignatureDate,
        });

        if (res.data.parentSignature !== null) {
          sigCanvasParent.current.fromDataURL(res.data.parentSignature);
          setFormDisabled(true);
        }
        if (res.data.kitaSignature !== null) {
          sigCanvasKita.current.fromDataURL(res.data.kitaSignature);
          setFormDisabled(true);
        }
        if (res.data.parentSignature !== null && res.data.kitaSignature !== null) {
          setIsCompleted(true);
        }
      } else {
        setKidLeaving({
          kitaName: kitaName ? kitaName : '',
          groupName: kid.kidGroup ? kid.kidGroup.groupName : '',
          todayDate: new Date().toISOString().split('T')[0],
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          contract: 'subv',
          leaveDate: new Date().toISOString().split('T')[0],
          parentSignature: null,
          parentSignatureDate: new Date().toISOString().split('T')[0],
          kitaSignature: null,
          kitaSignatureDate: new Date().toISOString().split('T')[0],
          kidId: kid.id,
        });
      }
    });
  };

  useEffect(() => {
    if (
      kidId != null && typeof kidId !== 'undefined' &&
      formId != null && typeof formId !== 'undefined' &&
      selectedKid.length === 0
    ) {
      const kidToSelect = kidsList.filter(obj => obj.id === kidId);
      if (kidToSelect.length !== 1) {
        return;
      }

      setSelectedKid(kidToSelect[0]);
      loadGfzKidLeavingsForKid(kidToSelect[0], formId);
    }
  }, [kidId, formId, kidsList]);

  function resetForm() {
    setKidLeaving({
      kitaName: '',
      groupName: '',
      todayDate: new Date().toISOString().split('T')[0],
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      contract: 'subv',
      leaveDate: new Date().toISOString().split('T')[0],
      parentSignature: null,
      parentSignatureDate: new Date().toISOString().split('T')[0],
      kitaSignature: null,
      kitaSignatureDate: new Date().toISOString().split('T')[0],
      kidId: null,
    });
    setSelectedKid([]);
    sigCanvasParent.current.clear();
    sigCanvasKita.current.clear();
  }

  return (
    <>
      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} alt='' />
      <br />
      <br />
      <h2>{t('GFZForms.KidLeavingChildExit')}</h2>
      <br />
      <table>
        <tbody>
          <tr>
            <td>{t('GFZForms.KidLeavingKidName')}</td>
            <td>
              <Select
                options={kidsList}
                onSelect={e => {
                  setSelectedKid(e);
                  loadGfzKidLeavingsForKid(e, -1);
                }}
                selected={selectedKid}
                style={{ zIndex: '10000000000' }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.KidLeavingKita')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={kidLeaving.kitaName}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setKidLeaving(prev => ({
                    ...prev,
                    kitaName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.KidLeavingGroup')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={kidLeaving.groupName}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setKidLeaving(prev => ({
                    ...prev,
                    groupName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.KidLeavingDate')}</td>
            <td>
              <Input
                type='date'
                value={kidLeaving.todayDate}
                min={new Date().toISOString().split('T')[0]}
                disabled
                onChange={({ target }) => {
                  setKidLeaving(prev => ({
                    ...prev,
                    todayDate: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td style={{ minWidth: '120px' }}>{t('GFZForms.KidLeavingExistingCareDays')}</td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td style={{ border: 'none', minWidth: '90px' }}>
                      <CheckBox
                        isChecked={kidLeaving.monday}
                        isDisabled={formDisabled}
                        name={t('GfzForms.Monday')}
                        onChange={() => {
                          setKidLeaving(prev => ({
                            ...prev,
                            monday: !prev.monday,
                          }));
                        }}
                      />
                    </td>
                    <td style={{ border: 'none' }}>
                      <CheckBox
                        isChecked={kidLeaving.tuesday}
                        isDisabled={formDisabled}
                        name={t('GfzForms.Tuesday')}
                        onChange={() => {
                          setKidLeaving(prev => ({
                            ...prev,
                            tuesday: !prev.tuesday,
                          }));
                        }}
                      />
                    </td>
                    <td style={{ border: 'none' }}>
                      <CheckBox
                        isChecked={kidLeaving.wednesday}
                        isDisabled={formDisabled}
                        name={t('GfzForms.Wednesday')}
                        onChange={() => {
                          setKidLeaving(prev => ({
                            ...prev,
                            wednesday: !prev.wednesday,
                          }));
                        }}
                      />
                    </td>
                    <td style={{ border: 'none' }}>
                      <CheckBox
                        isChecked={kidLeaving.thursday}
                        isDisabled={formDisabled}
                        name={t('GfzForms.Thursday')}
                        onChange={() => {
                          setKidLeaving(prev => ({
                            ...prev,
                            thursday: !prev.thursday,
                          }));
                        }}
                      />
                    </td>
                    <td style={{ border: 'none' }}>
                      <CheckBox
                        isChecked={kidLeaving.friday}
                        isDisabled={formDisabled}
                        name={t('GfzForms.Friday')}
                        onChange={() => {
                          setKidLeaving(prev => ({
                            ...prev,
                            friday: !prev.friday,
                          }));
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.KidLeavingContract')}</td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td style={{ border: 'none' }}>
                      <RadioButton
                        checked={kidLeaving.contract === 'subv'}
                        disabled={formDisabled}
                        onClick={() => {
                          setKidLeaving(prev => ({
                            ...prev,
                            contract: 'subv',
                          }));
                        }}
                        label='subv.'
                      />
                    </td>
                    <td style={{ border: 'none' }}>
                      <RadioButton
                        checked={kidLeaving.contract === 'priv'}
                        disabled={formDisabled}
                        onClick={() => {
                          setKidLeaving(prev => ({
                            ...prev,
                            contract: 'priv',
                          }));
                        }}
                        label='privat'
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.KidLeavingExitDate')}</td>
            <td>
              <Input
                type='date'
                value={kidLeaving.leaveDate}
                disabled={formDisabled}
                min={new Date().toISOString().split('T')[0]}
                onChange={({ target }) => {
                  setKidLeaving(prev => ({
                    ...prev,
                    leaveDate: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      {t('GFZForms.KidLeavingForwardDocs')}
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GfzForms.SignatureParents')}{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasParent.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasParent}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={kidLeaving.parentSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled
            onChange={({ target }) => {
              setKidLeaving(prev => ({
                ...prev,
                parentSignatureDate: target.value,
              }));
            }}
          />
        </div>
      </div>
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GfzForms.SignatureKitaManagement')}{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasKita.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasKita}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={kidLeaving.kitaSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled
            onChange={({ target }) => {
              setKidLeaving(prev => ({
                ...prev,
                kitaSignatureDate: target.value,
              }));
            }}
          />
        </div>
      </div>
      <br />
      {noKidSelected && <div style={{ color: "red" }}><i className='fa fa-exclamation-triangle' /> {t('GFZForms.Please select a kid in the dropdown')}<br /></div>}
      <ButtonWithLoader
        isLoading={isSubmitting}
        disabled={isCompleted || (!isAdmin && !isParentOfKid) || noKidSelected}
        onClick={() => {
          setIsSubmitting(true);
          const parentSigned = !sigCanvasParent.current.isEmpty();
          const kitaSigned = !sigCanvasKita.current.isEmpty();

          let pdf = null;
          if (parentSigned && kitaSigned) {

            const pdfSaveData = {
              kidLeaving: kidLeaving,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
              parentSignature: kidLeaving.parentSignature !== null ? kidLeaving.parentSignature : removeWhiteBackgroundSignature(
                sigCanvasParent.current.getCanvas(),
              ).toDataURL(),
              kitaSignature: kidLeaving.kitaSignature !== null ? kidLeaving.kitaSignature : removeWhiteBackgroundSignature(
                sigCanvasKita.current.getCanvas(),
              ).toDataURL(),
            };

            pdf = createPDFKidLeaving(pdfSaveData);
          }


          postGfzKidLeaving({
            ...kidLeaving,
            parentSignature: sigCanvasParent.current.isEmpty()
              ? null
              : sigCanvasParent.current.toDataURL(),
            kitaSignature: sigCanvasKita.current.isEmpty()
              ? null
              : sigCanvasKita.current.toDataURL(),
          }, pdf).then((res) => {

            store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
            resetForm();
            setActiveTab(0);
          }).finally(() => {
            setIsSubmitting(false);
          });
        }}
        type='primary'
      >
        {t('GFZForms.KidLeavingSave')}
      </ButtonWithLoader>
      &nbsp;
      <Button
        disabled={isCompleted || (!isAdmin && !isParentOfKid)}
        onClick={() => {
          sigCanvasKita.current.clear();
          sigCanvasParent.current.clear();
          setFormDisabled(false);
        }}
      >
        {t('GFZForms.EditFormAndResetSignatures')}
      </Button>
      &nbsp;
      {(isParentOfKid || isAdmin) && isExistingForKid && !isCompleted && (
        <Button
          onClick={() => {
            declineGfzKidLeaving(selectedKid.id, kidLeaving.id).then(() => {
              store.dispatch(showBottomNotification(t('GfzForms.Refused'), { isFail: false }));
              resetForm();
            });
          }}
        >
          {t('GFZForms.Decline')}
        </Button>
      )}
    </>
  );
}
