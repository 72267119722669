import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCalendarSettings } from 'library/api/calendar';
import { getTaskLists, addTaskList, updateTaskList, deleteTaskList } from 'library/api/tasks';
import useTaskLists from 'library/common/commonHooks/useTaskLists';
import TaskListsMain from 'library/common/commonComponents/TaskListsMain';
import { getGroupPermission } from "../../../../library/api/groups";

export default function GroupTasks({
  groupInfo,
  isOpenedByDefault = [],
  openedTasksByDefault = [],
}) {
  const {
    groupId,
    groupAdminStatus,
    createTask,
    editDeleteTask,
    processUnassignedTask,
    groupModuleList,
  } = groupInfo;
  const {
    isLoading,
    taskLists,
    onDeleteTaskList,
    onAddTaskList,
    onUpdateTaskList,
    onAddTask,
    onDeleteTask,
    onUpdateTask,
    onUpdateTaskStatus,
  } = useTaskLists({
    taskModuleId: groupId,
    getTaskLists,
    addTaskList,
    updateTaskList,
    deleteTaskList,
  });

  const [canAddToCalendar, setCanAddToCalendar] = useState();
  const [groupPermissions, setGroupPermissions] = useState([]);

  const user = useSelector(state => state.userReducer);

  useEffect(() => {
    if (!groupModuleList.find(item => item.moduleKey === 'calendar')) {
      return setCanAddToCalendar(false);
    }
    getCalendarSettings(groupId, 'groupCalendar')
      .then(res => {
        if (res.status === 200) {
          const calendarModule = groupModuleList.find(item => item.moduleKey === 'calendar');
          const taskType = res.data.calendarTypes.find(item => item.name === 'Task');
          setCanAddToCalendar(calendarModule && taskType.enabled);
        }
      })
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [groupId]);

  useEffect(() => {
    if (groupId) {
      getGroupPermission(groupId).then(res => {
        setGroupPermissions(res.data);
      });
    }
  }, [groupId]);

  const isLikeFeatureAvailableInGroup = useMemo(() => {
    let postLikePermission;
    if (user.administrationAccess || user.superAdminStatus || groupAdminStatus) {
      const adminPermission = groupPermissions.find(item => item.adminRole);
      postLikePermission = adminPermission?.groupPermissions.find(
        permission => permission.groupPermissionTag === 'LIKE-POST',
      );
    } else {
      const memberPermission = groupPermissions.find(item => !item.adminRole);
      postLikePermission = memberPermission?.groupPermissions.find(
        permission => permission.groupPermissionTag === 'LIKE-POST',
      );
    }
    if (postLikePermission) {
      return postLikePermission?.selectedStatus;
    } else {
      return true;
    }
  }, [groupPermissions, user, groupAdminStatus]);

  return (
    <TaskListsMain
      openedTasksByDefault={openedTasksByDefault}
      isOpenedByDefault={isOpenedByDefault}
      isLoading={isLoading}
      taskLists={taskLists}
      onDeleteTaskList={onDeleteTaskList}
      onAddTaskList={onAddTaskList}
      onUpdateTaskList={onUpdateTaskList}
      onAddTask={onAddTask}
      onDeleteTask={onDeleteTask}
      onUpdateTask={onUpdateTask}
      onUpdateTaskStatus={onUpdateTaskStatus}
      isAdmin={groupAdminStatus}
      manageTaskLists={editDeleteTask}
      manageTasks={createTask}
      proceedUnassignedTask={processUnassignedTask}
      canAddToCalendar={canAddToCalendar}
      isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
    />
  );
}
