import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Select from 'library/common/commonComponents/Inputs/Select';

import styles from './movePicturePopup.module.scss';

export default function MovePicturePopup({ isOpened, closePopup, onMove, options, isSubmiting }) {
  const { t } = useTranslation();
  const [selectedGallery, setSelectedGallery] = useState();

  const handleClick = async () => {
    if (isSubmiting) {
      return;
    }
    await onMove(selectedGallery);
    closePopup();
  };

  return (
    <Popup
      size='extraSmall'
      isOpened={isOpened}
      closePopup={closePopup}
      header={t('Popup.MovePictures')}
      body={
        <div className={styles.popupText}>
          <div className={styles.label}>{t('Popup.Gallery')}</div>
          <Select
            options={options}
            onSelect={e => setSelectedGallery(e)}
            selected={selectedGallery}
          />
        </div>
      }
      footer={
        <div className={styles.popupBtns}>
          <Button onClick={closePopup}>{t('Popup.Cancel')}</Button>
          <ButtonWithLoader onClick={handleClick} type='primary' isLoading={isSubmiting}>
            {t('Popup.Save')}
          </ButtonWithLoader>
        </div>
      }
    />
  );
}
