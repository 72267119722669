import { useState, useEffect } from 'react';

import store from 'main/store/configureStore';
import { setKitaModules } from 'library/common/commonActions/kitaActions';
import { getKitaModules, changeActivate, setAsDefault } from 'library/api/modules';

const useKitaModules = ({ onError, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [reloadModules, setReloadModules] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getKitaModules()
      .then(res => {
        if (res.status === 200) {
          setModules(res.data);
          store.dispatch(setKitaModules(res.data));
        } else {
          onError();
        }
        setIsLoading(false);
      })
      .catch(err => {
        onError();
        setIsLoading(false);
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadModules]);

  const patchModules = module => {
    const newModules = modules.slice();
    const index = newModules.findIndex(item => item.moduleId === module.moduleId);
    if (index !== -1) {
      newModules.splice(index, 1, module);
    }
    setModules(newModules);
    store.dispatch(setKitaModules(newModules));
  };

  const onChangeActivate = (module, disableOption) => {
    return changeActivate(module.moduleId, disableOption)
      .then(res => {
        if (res.status === 200) {
          patchModules(module);
          onSuccess(module);
        } else {
          onError();
        }
        setIsLoading(false);
      })
      .catch(err => {
        onError();
        setIsLoading(false);
        console.log(err);
      });
  };

  const onSetAsDefault = module => {
    return setAsDefault(module)
      .then(res => {
        if (res.status === 200) {
          patchModules(module);
          onSuccess(module);
        } else {
          onError();
        }
        setIsLoading(false);
      })
      .catch(err => {
        onError();
        setIsLoading(false);
        console.log(err);
      });
  };

  return {
    isLoading,
    modules,
    onChangeActivate,
    onSetAsDefault,
    reloadModules: () => setReloadModules(!reloadModules),
  };
};

export default useKitaModules;
