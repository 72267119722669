import React from 'react';

export const CheckedIcon = ({ size = 18, color = 'currentColor' }) => {
  return (
    <svg x='0px' y='0px' viewBox='0 0 60 60' width={size} height={size} class=''>
      <g transform='matrix(1 0 0 1 0 0)'>
        <path
          d='M0,0v60h60V0H0z M44.495,22.329l-16,18C28.101,40.772,27.552,41,26.999,41c-0.439,0-0.88-0.144-1.249-0.438l-10-8  c-0.862-0.689-1.002-1.948-0.312-2.811c0.689-0.862,1.949-1.003,2.811-0.313l8.517,6.813l14.739-16.581  c0.732-0.826,1.998-0.901,2.823-0.166C45.154,20.239,45.229,21.503,44.495,22.329z'
          fill={color}
        />
      </g>
    </svg>
  );
};
