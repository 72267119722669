import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CardHeading from 'library/common/commonComponents/to-refactor/commonCard/CardHeading';
import Button from 'library/common/commonComponents/Buttons/Button';
import Wrapper from 'library/common/commonComponents/Wrapper';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import styles from './emailAlreadyConfirmed.module.scss';

export const onBackToHomePress = history => {
    window.location = '/login';
};

export default function EmailAlreadyConfirmed({ history }) {
    const { t } = useTranslation();
    useSiteTitle('EmailAlreadyConfirmed');

    return (
        <div className={styles.invalidLinkContainer}>
            <div className={styles.heading}>
                <Button type='primary' onClick={() => onBackToHomePress(history)}>
                    {t('Login.Sign in')}
                </Button>
            </div>
            <Wrapper>
                <div className={styles.card}>
                    <CardHeading className={styles.heading_1}>{t('Email.Invalid Link')}</CardHeading>
                    <CardHeading className={styles.heading_2}>
                        {t('Email.Confirmed or link is not valid.')}
                    </CardHeading>
                    <div className={styles.hr} />
                    <div className={styles.back_to_home_container}>
                        <Button type='primary' onClick={onBackToHomePress}>
                            {t('PasswordRecovery.Back to home')}
                        </Button>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

EmailAlreadyConfirmed.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};
