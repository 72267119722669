import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TextCaret from 'library/common/commonComponents/TextCaret';
import Select from 'library/common/commonComponents/Inputs/Select';
import { downloadExcel, getEnableKitaConfig } from 'library/api/kita';
import Pagination from 'library/common/commonComponents/Pagination';
import Loader from 'library/common/commonComponents/Loader';
import Button from 'library/common/commonComponents/Buttons/Button';
import HelpText from 'library/common/commonComponents/HelpText';
import Input from 'library/common/commonComponents/Inputs/Input';
import KitaFrameListTable from './kitaFrameListFrames/KitaFrameListTable';
import useKitaList from './hooks/useKitaList';
import { isAwoWW } from 'library/api/tenantConfig';

import styles from './kitaFrameList.module.scss';

export default function KitaFrameList({ setCreateKitaMode, superAdminStatus, newKita, user }) {
  const {
    kitas,
    loadPage,
    isLoading,
    history,
    location,
    totalKitasCount,
    searchValue,
    setSearchValue,
    setSorting,
    sort,
    deactivateKita,
    selectedOption,
    setSelectedOption,
    options,
    isDeactivated,
  } = useKitaList(newKita);

  const awoWW = isAwoWW();

  const [isExportDropdownOpened, setIsExportDropdownOpened] = useState(false);
  const [ableToCreateKita, setAbleToCreateKita] = useState(false);

  useEffect(() => {
    if (awoWW) {
      getEnableKitaConfig().then(res => {
        setAbleToCreateKita(res.data || superAdminStatus);
      });
    } else {
      setAbleToCreateKita(true);
    }
  }, [awoWW, superAdminStatus]);

  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Administration.Kita.Overview')}</div>
      <div className={styles.text}>
        <HelpText>{t('Administration.Kita.This overview contains a list of each KITA')}</HelpText>
      </div>
      {ableToCreateKita && (
        <div className={styles.buttons}>
          <Button
            type='success'
            size='sm'
            onClick={() => setCreateKitaMode(true)}
            className={styles.button}
            >
              <i className='fa fa-plus' /> {t('Administration.Kita.Add new kita')}
          </Button>
          <div>
            <Button
              type='light'
              size='sm'
              onClick={() => setIsExportDropdownOpened(true)}
              className={styles.button}
              isActive={isExportDropdownOpened}
            >
              <i className='fa fa-download' /> {t('Administration.Kita.Export')}{' '}
              <TextCaret color='#fff' />
            </Button>
            {isExportDropdownOpened && (
              <div
                onClick={() => setIsExportDropdownOpened(false)}
                className={styles.buttonsDropdown}
              >
                <Button size='sm' onClick={() => downloadTable('excel', isDeactivated)}>
                  XLSX
                </Button>
                <Button size='sm' onClick={() => downloadTable('csv', isDeactivated)}>
                  CSV
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles.filters}>
        <div className={styles.filtersSearch}>
          <Input
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            className={styles.filtersSearchInput}
            placeholder={t('Administration.Kita.Input placeholder')}
          />
        </div>

        {superAdminStatus && (
          <div className={styles.select}>
            <Select options={options} onSelect={setSelectedOption} selected={selectedOption} />
          </div>
        )}
      </div>

      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          {totalKitasCount / 50 > 1 && (
            <Pagination
              currentPage={parseInt(location.pathname.slice(21), 10)}
              history={history}
              numberOfPages={totalKitasCount ? Math.ceil(totalKitasCount / 50) : 1}
              onPageClick={loadPage}
            />
          )}
          <KitaFrameListTable
            kitas={kitas}
            setSorting={setSorting}
            sort={sort}
            deactivateKita={deactivateKita}
            superAdminStatus={superAdminStatus}
            user={user}
          />
        </>
      )}
    </div>
  );
}

export async function downloadTable(type, isDeactivated) {
  const { data } = await downloadExcel(type, isDeactivated);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', type === 'excel' ? 'Kita List.xlsx' : 'Kita List.csv');
  document.body.appendChild(link);
  link.click();
}
