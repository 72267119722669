import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import Input from 'library/common/commonComponents/Inputs/Input';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import EditButton from 'library/common/commonComponents/Buttons/EditButton';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import CheckBox from 'library/common/commonComponents/Checkbox';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';

import styles from './calendarSettingsOtherCalendars.module.scss';
import ColorPickerButton from 'library/common/commonComponents/Buttons/ColorPickerButton';

export default function CalendarSettingsOtherCalendars({
  submitiing,
  calendarSettingsState,
  calendarSettingsDispatch,
  onSubmit,
}) {
  const { t } = useTranslation();
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsColorPickerVisible(false),
  });
  const [event, setEvent] = useState({});
  const [idOpened, setIsOpened] = useState(false);
  const [error, setError] = useState('');

  const handleEdit = () => {
    if (submitiing) {
      return;
    }
    const newEventTypes = calendarSettingsState.calendarTypes.slice();
    const index = newEventTypes.findIndex(item => {
      return item.id === event.id;
    });
    if (index !== -1) {
      newEventTypes.splice(index, 1, event);
      onSubmit(event).then(res => {
        if (res.success) {
          calendarSettingsDispatch({ type: 'setOtherCalendarEventTypes', payload: newEventTypes });
          setEvent({});
          setIsOpened(false);
        } else {
          setError('error');
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.titleHeader}>{t('Calendar.Other Calendars Configuration')}</div>
        <div className={styles.tittleDescription}>
          {t('Calendar.Here you can manage calendar types')}
        </div>
      </div>
      {calendarSettingsState.calendarTypes.length > 0 && (
        <div className={styles.eventTypesViewList}>
          {calendarSettingsState.calendarTypes.map(item => {
            return (
              <div className={styles.eventTypesViewItem} key={item.id}>
                <div className={styles.eventTypesViewItemLeft}>
                  <div
                    className={styles.eventTypesViewItemColor}
                    style={{ backgroundColor: item.colourCode }}
                  />
                  <div className={styles.eventTypesViewItemTitle}>
                    {t('CalendarType.' + item.name) +
                      `${item.enabled ? '' : ` - (${t('Calendar.disabled')})`}`}
                  </div>
                </div>
                <div className={styles.eventTypesViewItemRight}>
                  <EditButton
                    className={styles.editButton}
                    onClick={() => {
                      setEvent(item);
                      setIsOpened(true);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className={styles.createEventContainer}>
        <Popup
          isOpened={idOpened}
          showHeader='true'
          closePopup={() => setIsOpened(false)}
          size='small'
          header={<Trans i18nKey='Calendar.Edit calendar' />}
          footer={
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonSave}>
                <ButtonWithLoader onClick={handleEdit} type='primary' isLoading={submitiing}>
                  {t('Calendar.Save')}
                </ButtonWithLoader>
              </div>
              <div className={styles.buttonCancel}>
                <Button onClick={() => setIsOpened(false)}>{t('Calendar.Cancel')}</Button>
              </div>
            </div>
          }
        >
          <div className={styles.inputWithColorPicker} ref={outsideEventContainerRef}>
            <Input disabled value={event.name} placeholder={t('Calendar.Name')} />
            {error && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={error} />
              </div>
            )}
            <div className={styles.colorPicker}>
              <div
                className={styles.label}
                onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
              >
                <div className={styles.current} style={{ background: event.colourCode }} />
              </div>
              {isColorPickerVisible && (
                <ColorPickerButton
                  color={event.colourCode}
                  className={styles.pickerComponent}
                  onClick={c =>
                    setEvent({
                      ...event,
                      colourCode: c.hex,
                    })
                  }
                />
              )}
            </div>
          </div>
          <CheckBox
            className={styles.checkbox}
            name={t('Calendar.Enabled')}
            isChecked={event.enabled}
            onChange={() => setEvent({ ...event, enabled: !event.enabled })}
          />
        </Popup>
      </div>
    </div>
  );
}
