import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './parentsFoodItem.module.scss';
import {
  formatPrice,
  isCancelOrderAllowed,
  isOrderAllowed,
} from '../../../../../../../../utilities/foodOrder';
import RadioButton from '../../../../../../RadioButton';

export default function ParentsFoodItem({
  id,
  mealId,
  name,
  description,
  price,
  date,
  ordered,
  onToggleOrdered,
  manualPayed,
  config,
  showBottomNotification,
  checkNumberOfMealsAreNotActive,
  allowedNumberOfOrders,
}) {
  const { t } = useTranslation();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const onSelect = () => {
    if (
      (!ordered && isOrderAllowed(date, config) && checkNumberOfMealsAreNotActive(mealId) <= (allowedNumberOfOrders - 1)) ||
      (ordered && isCancelOrderAllowed(date, config))
    ) {
      onToggleOrdered(id, mealId, !ordered);
    } else {
      if (!ordered && isOrderAllowed(date, config) && checkNumberOfMealsAreNotActive(mealId) > (allowedNumberOfOrders - 1)) {
        showBottomNotification(
          t('FoodOrder.ErrorMaxOrdersExceeded'),
          { isFail: true },
        );
      }
      else
        showBottomNotification(
          t('FoodOrder.The order cant be changed anymore - if you made a mistake refresh the site'),
          { isFail: true },
        );
    }
  };

  return (
    <div className={ordered ? cn(styles.foodItem, styles.greenline) : styles.foodItem}>
      <i
        className={cn(
          isDetailsOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down',
          styles.detailsIcon,
        )}
        onClick={() => setIsDetailsOpen(!isDetailsOpen)}
      />
      <div className={styles.top}>
        {name || t('FoodOrder.Meal')} ({formatPrice(price)} €)
        {manualPayed && (
          <>
            <br />
            <b style={{ color: '#006400' }}>{t('FoodOrder.Is payed')}</b>
          </>
        )}
      </div>
      <RadioButton className={styles.checkbox} onClick={() => onSelect()} checked={ordered} />
      {isDetailsOpen && (
        <div className={styles.details}>
          <strong>{t('FoodOrder.description')}</strong>
          <div className={styles.border}>{description || t('FoodOrder.No description')}</div>
          <strong>{t('FoodOrder.price')}</strong>
          <div className={styles.border}>{formatPrice(price)} €</div>
        </div>
      )}
    </div>
  );
}
