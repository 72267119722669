import axios from 'library/api/axios';

import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;

export const confirmReturnReceipt = payload => axios.post(URL + '/confirmreturnreceipt', payload);

export const getReturnReceiptUsers = postId =>
  axios.get(URL + `/getreturnreceiptgroupusers/${postId}`);

export function returnFormattedDateString(unixTimestamp) {
  const date = new Date(unixTimestamp);
  return date.toLocaleDateString('de-DE') + ' ' + date.toLocaleTimeString('de-DE');
}

export const sendReminderToAllUnread = postId =>
  axios.get(URL + `/sendReminderToAllUnread/${postId}`);
