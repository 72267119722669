import { useTranslation } from 'react-i18next';

import axios from 'library/api/axios';
import * as at from 'library/common/commonConstants/userConstants';
import { getToken } from 'library/utilities/token';
import config, { getPort, isDev } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
export const groupsURL = `${config.API_BASE_URI}${getPort(8080)}/api`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;

const { t } = useTranslation();

const graphqlForCalendarEvents =
  // eslint-disable-next-line max-len
  'acceptance,acceptanceCount,allDay,canceled,color,isTranslationAllowed,comments[isTranslationAllowed,files[fileId,path,id,mimeType],firstName,id,lastName,likes[firstName,id,lastName,userId],text,userId],declineCount,watsonLanguage,description,editable,end,endTime,eventCreatedDate,eventType[id,name,colourCode],eventUpdatedDate,files[fileId,path,id,mimeType],groupId,id,isPublic,likes[firstName,id,lastName,userId],location,maybeCount,moduleType,participationInfo[info,isAllowDecline,isAllowMaybe,maxNumberOfParticipants,participationEnum],postType,start,startTime,timeZone,title,user[avatar,email,firstName,id,lastName],assignedUsersList,firstName,lastName,responsibleUsersList,taskList[color],userId,avatar,taskCreatedDate,taskUpdatedDate,reviewRequired,isScheduling,addToCalendar,checklist[id,title,status],taskListId,group[groupId,groupAdminStatus,createCalenderEntry,editDeleteCalenderEntry,processUnassignedTask,groupName,groupLogoUrl,logoUrl,groupLogoXCoordinate,groupLogoYCoordinate,groupLogoWidth,groupLogoAspect,colorCode,visibility,publicPostStatus]';

export const getCalendarSettings = (id, calendarModulePlace) => {
  const calendarSettingsApi =
    calendarModulePlace === 'groupCalendar'
      ? `/groups/${id}/calendar-configuration/`
      : calendarModulePlace === 'kitaCalendar'
      ? `/kita/${id}/calendar-configuration/`
      : `/users/currentuser/calendar-configuration/`;

  return axios.get(URL + calendarSettingsApi);
};

export const setCalendarSettings = settings => {
  const payload = {
    calendarDisplayedInStream: settings.calendarDisplayedInStream,
    maxEventItems: settings.maxEventItems,
    participationMode: settings.participationMode,
    participationStateDeclineAllowed: settings.participationStateDeclineAllowed,
    participationStateMaybeAllowed: settings.participationStateMaybeAllowed,
    kidParticipationOptionAllowed: settings.kidParticipationOptionAllowed,
    snippetShown: settings.snippetShown,
    topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled:
      settings.topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled,
    upcomingEventsIntervalDays: settings.upcomingEventsIntervalDays,
    sortingOrder: settings.sortingOrder,
    enableBirthdayNotification: settings.enableBirthdayNotification,
  };
  return axios.put(URL + `/calendar-configuration/${settings.id}/`, payload);
};

export const getCalendarEventTypes = (calendarId, calendarModulePlace) => {
  const calendarEventTypesApi =
    calendarModulePlace === 'groupCalendar'
      ? `/event-types/group/${calendarId}/`
      : calendarModulePlace === 'kitaCalendar'
      ? `/event-types/kita/${calendarId}/`
      : `/event-types/currentuser/`;

  return axios.get(URL + calendarEventTypesApi);
};

export const createEventType = (calendarId, newEvent) =>
  axios.post(URL + `/calendar-configuration/${calendarId}/event-types`, newEvent);

export const updateEventType = event => axios.put(URL + `/event-types/${event.id}/`, event);

export const deleteEventType = eventId => axios.delete(URL + `/event-types/${eventId}/`);

export const updateCalendarType = event => axios.put(URL + `/calendar-types/${event.id}/`, event);

export const getCalendarEvents = (id, filters, calendarModulePlace, currentUserLanguage) => {
  const filterTemp = filters
    ? filters.find(item => item.name === 'attending').checked
      ? filters.find(filter => filter.name === 'myEvents').checked
        ? [
            'deleted:eq:0',
            'and',
            [
              `${
                calendarModulePlace === 'userProfileCalendar' ? 'myEventsProfile' : 'myEvents'
              }:eq:true`,
              'or',
              'attending:eq:true',
            ],
          ]
        : ['deleted:eq:0', 'and', 'attending:eq:true']
      : filters.find(filter => filter.name === 'myEvents').checked
      ? [
          'deleted:eq:0',
          'and',
          `${
            calendarModulePlace === 'userProfileCalendar' ? 'myEventsProfile' : 'myEvents'
          }:eq:true`,
        ]
      : ['deleted:eq:0']
    : ['deleted:eq:0'];

  const myProfileFilter = filters && filters.find(item => item.name === 'myProfile');
  const myGroupsFilter = filters && filters.find(item => item.name === 'myGroups');
  const myFriendsFilter = filters && filters.find(item => item.name === 'myFriends');

  const filterMap = {
    myProfile: myProfileFilter,
    myGroups: myGroupsFilter,
    myFriends: myFriendsFilter,
  };

  const filterArray = [];
  const allChecked = Object.keys(filterMap).every(
    filter => filterMap[filter] && filterMap[filter].checked,
  );
  const allUnChecked = Object.keys(filterMap).every(
    filter => filterMap[filter] && !filterMap[filter].checked,
  );

  if (allChecked || allUnChecked) {
    Object.keys(filterMap).forEach((filter, index) => {
      filterArray.push(`${filter}:eq:true`);
      if (index !== Object.keys(filterMap).length - 1) filterArray.push('or');
    });
  } else {
    const checkedFilters = Object.keys(filterMap).filter(filter => filterMap[filter]?.checked);
    checkedFilters.forEach((filter, index) => {
      filterArray.push(`${filter}:eq:true`);
      if (index !== checkedFilters.length - 1)
      {
        filterArray.push('or')
      }
    });

  }

  if (filterArray.length === 1) {
    filterTemp.splice(0, 0, ...filterArray, 'and');
  } else {
    filterTemp.splice(0, 0, filterArray, 'and');
  }

  if (
    calendarModulePlace === 'userProfileCalendar' &&
    !filters.find(item => item.name === 'attending').checked &&
    !filters.find(filter => filter.name === 'myEvents').checked
  ) {
    filterTemp.splice(
      0,
      0,
      [
        `${calendarModulePlace === 'userProfileCalendar' ? 'myEventsProfile' : 'myEvents'}:eq:true`,
        'or',
        'attending:eq:true',
      ],
      'and',
    );
  }

  const mod = (m, n) => {
    const res = m % n;
    return res >= 0 ? res : res + n;
  };

  const currentDate = filters
    ? filters.find(item => item.name === 'currentViewDate').value
    : new Date();
  const startDate = new Date(
    currentDate.getFullYear() - (currentDate.getMonth() === 0 ? 1 : 0),
    mod(currentDate.getMonth() - 1, 12),
  );
  const endDate = new Date(
    currentDate.getFullYear() + (currentDate.getMonth() >= 10 ? 1 : 0),
    (currentDate.getMonth() + 2) % 12,
  );
  const dateRange = [
    'and',
    `startDate:lt:${endDate.getTime()}`,
    'and',
    [
      `endDate:gt:${startDate.getTime()}`,
      'or',
      [
        ['repeatType:nn:', 'and', 'repeatType:ne:none'],
        'and',
        ['repeatEndDate:eq:0', 'or', `repeatEndDate:goe:${startDate.getTime()}`],
      ],
    ],
  ];

  const calendarURL =
    calendarModulePlace === 'groupCalendar'
      ? `/groups/${id}/calendar-events/page/`
      : calendarModulePlace === 'userProfileCalendar'
      ? '/currentUser/calendar-events/page'
      : `/calendar-events/page/`;

  return new Promise((resolve, reject) => {
    Promise.all([
      axios.post(URL + calendarURL, {
        filter: filterTemp.concat(dateRange),
        graphql: graphqlForCalendarEvents,
        size: 2000,
      }),
      axios.get(at.URLS.watsonLanguage),
    ])
      .then(responses => {
        if (responses[1].data.langCode && responses[1].data.langCode !== 'null') {
          const originalResponse = { ...responses[0] };
          const eventsResponse = responses[0].data.content.slice();
          if (eventsResponse.length) {
            const requests = [];
            for (let e = 0; e < eventsResponse.length; e++) {
              const event = eventsResponse[e];
              if (event.isTranslationAllowed && currentUserLanguage !== event.watsonLanguage) {
                const payload = {
                  entityId: event.id,
                  forced: event.edited,
                  entityType: 'event',
                  target: responses[1].data.langCode,
                  text: event.canceled ? `${event.title}(${t('Calendar.canceled')})` : event.title,
                  textIndex: `${event.title.substring(0, 3)}_${requests.length + 1}`,
                };
                if (event.edited) payload.forced = true;

                requests.push(
                  axios.post(
                    `${config.API_BASE_URI}${getPort(8091)}/api/dynamic/translation`,
                    payload,
                  ),
                );
              } else {
                requests.push(new Promise(solve => solve({ data: event.title })));
              }
            }
            Promise.all(requests)
              .then(translatedResponses => {
                originalResponse.data.content = eventsResponse.map((ev, index) => ({
                  ...ev,
                  title: translatedResponses[index].data,
                }));
                resolve(originalResponse);
              })
              .catch(err => reject(err));
          } else resolve(responses[0]);
        } else resolve(responses[0]);
      })
      .catch(err => reject(err));
  });
};

export const addCalendarEvent = (calendarIds, payload, calendarModulePlace) => {
  if (!Array.isArray(calendarIds)) {
    calendarIds = [calendarIds];
  }
  const formData = new FormData();
  const createdAt = new Date();
  formData.append('eventCreatedDate', createdAt.getTime());
  formData.append('graphql', graphqlForCalendarEvents);

  for (let i = 0; i < calendarIds.length; i++) {
    formData.append('multiGroups[]', calendarIds[i]);
  }

  formData.append('privatePost', false);

  if (payload.receivers) {
    formData.append('receivers', payload.receivers);
  }
  if (payload.parentParticipants) {
    for (let i = 0; i < payload.parentParticipants.length; i++) {
      formData.append('parentParticipants[]', payload.parentParticipants[i]);
    }
  }

  let fileNumber = 1;
  Object.keys(payload).forEach(key => {
    switch (key) {
      case 'title':
        return formData.append('text', payload[key]);
      case 'location':
        return formData.append('location', payload[key]);
      case 'streetMap':
          return formData.append('streetMap', payload[key]);
      case 'publicVisible':
          return formData.append('publicVisible', payload[key]);
      case 'allDay':
        return formData.append('allDayEvent', payload[key]);
      case 'color':
        return formData.append('colourCode', payload[key]);
      case 'description':
        return formData.append('description', payload[key]);
      case 'end':
        formData.append('endDate', payload[key]);
        formData.append('endTime', payload[key]);
        return;
      case 'eventType':
        return formData.append('eventTypeId', payload[key]);
      case 'participationInfo':
        if (
          payload[key].maxNumberOfParticipants !== '' &&
          payload[key].maxNumberOfParticipants > 0
        ) {
          formData.append('maxNumberOfParticipants', payload[key].maxNumberOfParticipants);
        }
        formData.append('participantInfo', payload[key].info);
        formData.append('participationMode', payload[key].participationEnum);
        formData.append('participationStateDeclineAllowed', payload[key].isAllowDecline);
        formData.append('participationStateMaybeAllowed', payload[key].isAllowMaybe);
        return;
      case 'start':
        formData.append('startDate', payload[key]);
        formData.append('startTime', payload[key]);
        return;
      case 'timeZone':
        return formData.append('timeZone', payload[key]);
      case 'files':
        payload[key].forEach(item => {
          formData.append(`file${fileNumber}`, item);
          fileNumber++;
        });
        return;
      case 'repeat':
        // eslint-disable-next-line no-case-declarations
        const { type, days, end, weekInterval, monthInterval } = payload[key];
        formData.append('repeatType', type);
        formData.append('repeatDays', days.join(','));
        if (end) formData.append('repeatEnd', end == 0 ? '0' : end.getTime());
        else formData.append('repeatEnd', '0');
        formData.append('repeatWeeklyInterval', weekInterval);
        if (monthInterval) {
          formData.append('repeatMonthlyInterval', monthInterval);
        }
        break;
      case 'reminders':
        for (const index in payload[key]) {
          formData.append('reminders[]', JSON.stringify(payload[key][index]));
        }
        break;
      default:
        return key;
    }
  });

  const addEventUrl =
    calendarModulePlace === 'groupCalendar'
      ? `/groups/multipleGroups/calendar-events`
      : '/currentuser/calendar-events/';

  return axios.post(zuulURL + addEventUrl, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getCalendarEventById = eventId =>
  axios.get(URL + `/calendar-events/${eventId}/`, {
    params: { graphql: graphqlForCalendarEvents },
  });

export const deleteCalendarEvent = eventId => axios.delete(URL + `/calendar-events/${eventId}/`);

export const getAceptenceToEvent = (eventId, param) =>
  axios.get(URL + `/calendar-events/${eventId}/users/`, {
    params: {
      acceptance: param,
    },
  });

export const setAceptenceToEvent = (eventId, param) =>
  axios.put(URL + `/calendar-events/${eventId}/acceptance/`, { acceptance: param });

export const updateCalendarKidParticipation = (eventId, kidId, action) =>
  axios.put(URL + `/calendar-events/${eventId}/kid-id/${kidId}/action/${action}`);

export const getDetailsOfParticipatingKidsForCalendarEvent = eventId =>
  axios.get(URL + `/getdetailsofparticipatingkidsforcalendarevent/${eventId}`);

export const updateCalendarEvent = (eventId, payload) => {
  const formData = new FormData();
  let newFiles;
  let fileNumber = 1;
  const updatedAt = new Date();
  formData.append('eventUpdatedDate', updatedAt.getTime());
  formData.append('graphql', graphqlForCalendarEvents);

  if (payload.parentParticipants) {
    for (let i = 0; i < payload.parentParticipants.length; i++) {
      formData.append('parentParticipants[]', payload.parentParticipants[i]);
    }
  }

  Object.keys(payload).forEach(key => {
    switch (key) {
      case 'title':
        return formData.append('text', payload[key]);
      case 'location':
        return formData.append('location', payload[key]);
      case 'streetMap':
        return formData.append('streetMap', payload[key]);
      case 'publicVisible':
        return formData.append('publicVisible', payload[key]);
      case 'isPublic':
        return formData.append('privatePost', !payload[key]);
      case 'allDay':
        return formData.append('allDayEvent', payload[key]);
      case 'color':
        return formData.append('colourCode', payload[key]);
      case 'description':
        return formData.append('description', payload[key]);
      case 'end':
        formData.append('endDate', payload[key]);
        formData.append('endTime', payload[key]);
        return;
      case 'eventType':
        return formData.append('eventTypeId', payload[key] ? payload[key].id || payload[key] : 0);
      case 'participationInfo':
        if (
          payload[key].maxNumberOfParticipants !== '' &&
          payload[key].maxNumberOfParticipants > 0
        ) {
          formData.append('maxNumberOfParticipants', payload[key].maxNumberOfParticipants);
        }
        formData.append('participantInfo', payload[key].info);
        formData.append('participationMode', payload[key].participationEnum);
        formData.append('participationStateDeclineAllowed', payload[key].isAllowDecline);
        formData.append('participationStateMaybeAllowed', payload[key].isAllowMaybe);
        return;
      case 'start':
        formData.append('startDate', payload[key]);
        formData.append('startTime', payload[key]);
        return;
      case 'timeZone':
        return formData.append('timeZone', payload[key]);
      case 'deletedFileIds':
        return formData.append('deletedFileIds', payload[key].join(','));
      case 'files':
        newFiles = payload[key].filter(newFile => !newFile.id);
        newFiles.forEach(item => {
          formData.append(`file${fileNumber}`, item);
          fileNumber++;
        });
        return;
      case 'canceled':
        return formData.append('canceled', payload[key]);
      case 'repeat':
        // eslint-disable-next-line no-case-declarations
        const { type, days, end, weekInterval, monthInterval } = payload[key];
        formData.append('repeatType', type);
        formData.append('repeatDays', days.join(','));
        if (end) formData.append('repeatEnd', end == 0 ? '0' : end.getTime());
        else formData.append('repeatEnd', '0');
        formData.append('repeatWeeklyInterval', weekInterval);
        if (monthInterval) {
          formData.append('repeatMonthlyInterval', monthInterval);
        }
        break;
      case 'reminders':
        for (const index in payload[key]) {
          formData.append('reminders[]', JSON.stringify(payload[key][index]));
        }
        break;
      default:
        return key;
    }
  });

  return axios.put(zuulURL + `/calendar-events/${eventId}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getTimeZoneList = () => axios.get(URL + `/timezones/`);

export const sendIcsFileViaEmail = (eventId, email) => {
  return axios.get(URL + `/calendar-events/${eventId}/ics/email`, { params: { email } });
};

export const getEventIcsFile = (eventId, eventName, email) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'meeting',
        url: URL + `/calendar-events/${eventId}/ics/`,
        token: getToken().access_token,
        eventId,
      }),
    );
    return null;
  }

  return axios
    .get(URL + `/calendar-events/${eventId}/ics/`, {
      responseType: 'blob',
    })
    .then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${eventName}.ics`);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getGroupsForDashboardCalendar = () =>
  axios.get(groupsURL + `/users/current/groups/module/calender`);

export const getSelectedGroupByUser = groupId =>
  axios.post(groupsURL + `/users/current/groups/${groupId}/specificgroup`);

export const getExportsGroup = groupId => axios.get(`${URL}/groups/${groupId}/exports`);

export const addExportGroup = (exportData, groupId) =>
  axios.post(`${URL}/groups/${groupId}/exports`, exportData);

export const importGroupEvents = (formData, groupId) =>
  axios.post(`${URL}/groups/${groupId}/calendar-imports`, formData);

export const getUserExports = () => axios.get(`${URL}/exports`);

export const addUserExport = exportData => axios.post(`${URL}/exports`, exportData);

export const removeUserExport = exportId => axios.delete(`${URL}/exports/${exportId}`);

export const importUserEvents = formData => axios.post(`${URL}/calendar-imports`, formData);

export const downloadExportFile = (exportId, exportName, exportType) => {
  return axios
    .get(`${URL}/calendar-exports/${exportId}?type=${exportType}`, {
      responseType: 'blob',
    })
    .then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${exportName}.${exportType.toLowerCase()}`);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const canCreateEvent = groupId =>
  axios.get(`${URL}/calendar-events/${groupId}/canCreateEvent`);

export const getGeoCoordinates = searchString => {
  return fetch(
    `https://nominatim.openstreetmap.org/search?q=${searchString}&format=geojson&limit=1`,
  )
    .then(response => response.json())
    .then(data => console.log(data));
};


export const downloadAllGroupCalendarExportsFromKita = kitaName => {
  return axios
    .get( URL + `/downloadAllGroupCalendarExportsFromKita`, {
      responseType: 'blob',
    })
    .then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${kitaName}_calendarexport.zip`);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const migrateCalendarEntries = kitaId => axios.post(`${URL}/migrateCalendarEntries/${kitaId}`);

export const hideCalendarEvent = (id, day) => axios.put(`${URL}/calendar-events/hide-day/${id}/${day}`);
