import React from 'react';
import { useTranslation } from 'react-i18next';
import { isImage, previewOrDownloadFile, createPDF } from 'library/utilities/files';
import styles from './wikicontentpage.module.scss';

export default function WikiContentPage({ page }) {
  const { t } = useTranslation();

  return (
    <>
      <h1 className={styles.title}>{page.title}</h1>

      <div className={styles.separator} />

      <div className={styles.contentWrapper}>
        <div
          className={styles.contentContainer}
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </div>
      {page.attachments.length > 0 && (
        <div className={styles.attachmentArea}>
          <h3>{t('Wiki.Attachments')}</h3>
          <ul>
            {page.attachments.map(item => {
              return (
                <li
                  onClick={() =>
                    previewOrDownloadFile({
                      fileId: item.fileName,
                      path: item.filePath,
                      id: item.fileId,
                    })
                  }
                >
                  {item.fileName}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
