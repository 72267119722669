import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Tabs from 'library/common/commonComponents/Tabs';

import styles from './employeeTimePopup.module.scss';
import EmployeeContainer from '../../CheckInOut/EmployeeContainer';
import EmployeeHistoryContainer from '../../CheckInOut/EmployeeHistoryContainer';
import EmployeeAbsenceContainer from '../../CheckInOut/EmployeeAbsenceContainer';

export default function EmployeeTimePopup({ isOpened, closePopup }) {
  const [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation();

  const employeeTimeTabs = [
    {
      title: t('Checkinout.Employee'),
      component: <EmployeeContainer />,
      path: 'employee-list',
    },
    {
      title: t('Checkinout.EmployeeHistory'),
      component: <EmployeeHistoryContainer />,
      path: 'employee-history',
    },
    {
      title: t('Checkinout.EmployeeAbsence'),
      component: <EmployeeAbsenceContainer />,
      path: 'employee-absences',
    },
  ];

  return (
    <Popup
      size={
        ['employee-history', 'employee-absences'].includes(employeeTimeTabs[currentTab].path)
          ? 'ChildHistoryTab'
          : 'normal'
      }
      isOpened={isOpened}
      closePopup={closePopup}
      header={<strong>{t('EmployeeCheckinout.Employee working time')}</strong>}
    >
      <div className={styles.wrapper}>
        <Tabs
          tabs={employeeTimeTabs}
          currentTab={employeeTimeTabs[currentTab].path}
          onChange={(_, index) => setCurrentTab(index)}
        />
      </div>
    </Popup>
  );
}
