import { createAction } from 'redux-actions';
import axiosInstance from 'library/api/axios';
import { useTranslation } from 'react-i18next';
import * as at from './registrationConstants';

const { t } = useTranslation();

const disableButton = createAction(at.SET_BUTTON_DISABLED);

export const registrationService = data => dispatch => {
  dispatch(disableButton(true));
  return axiosInstance
    .post(at.URLS.registration, data)
    .then(res => {
      switch (res.status) {
        case 200:
          return {
            status: res.status,
            result: true,
            reason: t('Registration.ok'),
          };
        case 208:
          return {
            status: res.status,
            result: false,
            reason: t('Registration.Username is already taken'),
          };
        default:
          return false;
      }
    })
    .catch(err => {
      dispatch(disableButton(true));

      if (err.response) {
        switch (err.response.status) {
          case 401:
            return {
              status: err.response.status,
              result: false,
              reason: t('Registration.User registration error'),
            };
          case 404:
            return {
              status: err.response.status,
              result: false,
              reason: t('Registration.Wrong registration code'),
            };
          case 406:
            return {
              status: err.response.status,
              result: false,
              reason: t('Registration.KITA code is wrong'),
            };
          case 422:
            return {
              status: err.response.status,
              result: false,
              reason: t(
                'Registration.Password should contain at least 1 letter and 1 number or special character',
              ),
            };
          default:
            return {
              status: err.response.status,
              result: false,
              reason: t('Registration.User registration error'),
            };
        }
      } else {
        return false;
      }
    });
};

export const checkVerificationCode = data => {
  return axiosInstance
    .get(at.URLS.verification + data)
    .then(res => {
      switch (res.status) {
        case 200:
          return true;
        default:
          return false;
      }
    })
    .catch(() => false);
};
