import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import AuthSuccessMessage from 'library/common/commonComponents/AuthSuccessMessage';
import BackgroundWrapper from 'library/common/commonComponents/to-refactor/commonBackgroundWrapper';
import { resetPasswordService, checkTokenAuthentication } from './resetPasswordActions';
import ResetPasswordModule from './resetPasswordModule';

export class ResetPasswordContainer extends Component {
  state = {
    password: '',
    confirmPassword: '',
    passwordError: '',
    confirmPasswordError: '',
    recoverySuccessfull: false,
    errorMessage: '',
    isInputError: false,
    isReady: false,
    isBounceAnimation: true,
  };

  componentWillMount() {
    const token = this.props.match.params.id;
    this.props.checkTokenAuthentication(token).then(res => {
      if (res) {
        this.setState({ isReady: true });
      } else {
        this.props.history.push('/recovery-link-error');
      }
    });
  }

  setDisabledFlag() {
    this.setState({
      isDisabled: false,
      isInputError: false,
    });
  }

  handleInputChange = event => {
    this.setState({
      passwordError: '',
      confirmPasswordError: '',
    });

    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  setErrorOnInput = () => {
    const { confirmPassword, password } = this.state;
    let passwordError = '';
    let confirmPasswordError = '';
    if (!password) {
      passwordError = i18n.t('PasswordRecovery.Password cannot be blank');
    }
    if (password.length < 8) {
      passwordError = i18n.t('PasswordRecovery.Password length should be 8 or more');
    }
    if (!confirmPassword) {
      confirmPasswordError = i18n.t('PasswordRecovery.Confirm password cannot be blank');
    }
    if (password && confirmPassword && password !== confirmPassword) {
      confirmPasswordError = i18n.t('PasswordRecovery.Password and confirm password do not match');
    }

    if (passwordError || confirmPasswordError) {
      this.setState(
        {
          passwordError,
          confirmPasswordError,
          isInputError: true,
          isBounceAnimation: false,
        },
        () => this.setDisabledFlag(),
      );
      return false;
    }
    this.setState({
      passwordError,
      confirmPasswordError,
      isInputError: false,
      isBounceAnimation: false,
    });
    return true;
  };

  backToLogin = () => {
    this.props.history.push('/login');
  };

  resetPassword = () => {
    const { password } = this.state;
    const resetPasswordToken = this.props.match.params.id;
    if (this.setErrorOnInput()) {
      this.props.resetPasswordService({ password, resetPasswordToken }).then(data => {
        if (data && data.success) {
          this.setState({ recoverySuccessfull: true });
        } else if (data && data.errorMessage) {
          this.setState({ errorMessage: data.errorMessage, isInputError: true }, () => {
            setTimeout(() => {
              this.setState({ errorMessage: '' });
            }, 3000);
            this.setDisabledFlag();
          });
        } else {
          this.setState(
            {
              passwordError: '',
              isInputError: true,
              confirmPasswordError: '',
            },
            () => this.setDisabledFlag(),
          );
        }
      });
    }
  };

  render() {
    return (
      <BackgroundWrapper>
        {this.state.recoverySuccessfull ? (
          <AuthSuccessMessage
            buttonLabel='PasswordRecovery.Back to login'
            buttonPress={this.backToLogin}
            title='PasswordRecovery.Password'
            subtitle='PasswordRecovery.Changed'
            message='PasswordRecovery.Your password has been changed successfully'
          />
        ) : (
          <ResetPasswordModule
            fromState={{ ...this.state }}
            handleInputChange={this.handleInputChange}
            back={() => {
              this.props.history.push('/login');
            }}
            resetPassword={this.resetPassword}
          />
        )}
      </BackgroundWrapper>
    );
  }
}

ResetPasswordContainer.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  checkTokenAuthentication: PropTypes.func.isRequired,
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  resetPasswordService: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default connect(
  mapStateToProps,
  { resetPasswordService, checkTokenAuthentication },
)(ResetPasswordContainer);
