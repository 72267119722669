import React from 'react';

import CalendarSettings from 'library/common/commonComponents/Calendar/CalendarFrames/CalendarSettings';
import TasksSettings from 'library/common/commonComponents/TaskListsMain/TaskListsMainFrames/TasksSettings';

import styles from './moduleSettings.module.scss';

export default function ModuleSettings({ module, showBottomNotification, activeKita }) {
  return (
    <div className={styles.container}>
      {module.moduleKey === 'calendar' ? (
        <CalendarSettings
          match={{ params: { id: activeKita.kitaId } }}
          location={{ state: { calendarModulePlace: 'kitaCalendar' } }}
        />
      ) : (
        <TasksSettings
          id={activeKita.kitaId}
          showBottomNotification={showBottomNotification}
          isKitaTaskModule
        />
      )}
    </div>
  );
}
