import React, { useState } from 'react';
import cn from 'classnames';
import DOMPurify, { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';

import styles from './messageWithFilesText.module.scss';

DOMPurify.setConfig({ ADD_ATTR: ['target'] });

export default function MessageWithFilesText({ text, entityId, entityType, isTranslationAllowed, messageEdited }) {
  const [isOpened, setIsOpened] = useState(true);

  const shrink = newRef => {
    if (newRef && newRef.offsetHeight >= 130) {
      setIsOpened(false);
    }
  };

  const { t } = useTranslation();

  return (
    <div className={styles.textContainer}>
      <div
        className={cn(styles.textContainerInner, { [styles.textContainerInnerOpened]: isOpened })}
      >
        {isTranslationAllowed ? (
          <WithWatsonTranslate
            data={{ text, entityId, entityType, forced: messageEdited }}
            getRef={shrink}
            Component={({ html }) => (
              <div className={styles.text}>
                <p dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
              </div>
            )}
          />
        ) : <p dangerouslySetInnerHTML={{ __html: sanitize(text) }} />}
      </div>

      {!isOpened && (
        <Button size='sm' className={styles.textContainerButton} onClick={() => setIsOpened(true)}>
          {t('Post.Show full text')}
        </Button>
      )}
    </div>
  );
}
