import React, { useState } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import Select from 'library/common/commonComponents/Inputs/Select';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Checkbox from 'library/common/commonComponents/Checkbox';
import RadioButton from 'library/common/commonComponents/RadioButton';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import {
  convertInputTypeNumberOptions,
  convertInputTypeNumberOption,
  getVoteBarWidth,
  sortHelper,
} from 'library/utilities/parentSurveys';
import {
  addEditParentSurveySuggestion,
  deleteParentSurveySuggestion,
} from 'library/api/parentSurveys';
import styles from './feedItemParentSurveyQuestion.module.scss';
import FeedItemParentSurveyOptionEditing from '../feedItemParentSurveyFrames/FeedItemParentSurveyOptionEditing';
import AutoGrowingTextArea from 'library/common/commonComponents/Inputs/AutoGrowingTextArea';

export default function FeedItemParentSurveyQuestion({
  question,
  isTranslationAllowed,
  parentSurveyDispatch,
  isSubmiting,
  isPublished,
  setIsSubmiting,
  parentSurveyIsClosed,
  userHasVoted,
  user,
  parentSurveyDataId,
  groupInfo,
  missedQuestionIds,
}) {
  const { t } = useTranslation();
  // TODO add <WithWatsonTranslate /> aka XSS Protection #nico.achterberg
  const getWidth = optionId => {
    return getVoteBarWidth(question, optionId) + '%';
  };
  const [suggestionText, setSuggestionText] = useState('');
  const [editSuggestion, setEditSuggestion] = useState(null);
  const [deleteSuggestion, setDeleteSuggestion] = useState(null);
  const canUserVote = true;

  const addSuggestion = () => {
    setIsSubmiting(true);
    if (suggestionText.trim().length > 0) {
      submitSuggestion(parentSurveyDataId, question.id, 0, suggestionText).then(data => {
        const option = data.data;
        parentSurveyDispatch({
          type: 'addSuggestion',
          optionId: option.id,
          createdBy: option.createdBy,
          questionId: question.id,
          answer: suggestionText,
        });
        setSuggestionText('');
        setIsSubmiting(false);
      });
    }
  };

  const onDeleteSuggestion = () => {
    setIsSubmiting(true);
    deleteParentSurveySuggestion(parentSurveyDataId, deleteSuggestion.optionId).then(data => {
      parentSurveyDispatch({
        type: 'deleteSuggestion',
        optionId: deleteSuggestion.optionId,
        questionId: deleteSuggestion.questionId,
      });
      setDeleteSuggestion(null);
      setIsSubmiting(false);
    });
  };
  const onSelect = () => {
    return question.parentSurveyQuestionOptions.find(element => element.checked)
      ? convertInputTypeNumberOption(
          question.parentSurveyQuestionOptions.find(element => element.checked),
        )
      : { value: '0', label: '0' };
  };

  // Sort number input 1 - 7
  if (question.inputType === 'number') {
    if (question.parentSurveyQuestionOptions) {
      question.parentSurveyQuestionOptions = sortHelper(
        question.parentSurveyQuestionOptions,
        'orderPosition',
      );
    }
  }
  return (
    <div className={styles.container}>
      {isTranslationAllowed ? (
        <WithWatsonTranslate
          data={{
            text: question.question,
            entityId: question.id,
            entityType: 'parent_survey',
          }}
          Component={({ html }) => (
            <h4 className={styles.question} dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
          )}
        />
      ) : (
        <h4
          className={styles.question}
          style={{
            color:
              missedQuestionIds &&
              missedQuestionIds.includes(question.id) &&
              question &&
              question.id
                ? 'red'
                : null,
          }}
          dangerouslySetInnerHTML={{ __html: sanitize(question.question) }}
        />
      )}
      <DeletePopup
        isOpened={deleteSuggestion}
        closePopup={() => setDeleteSuggestion(null)}
        onDeleteBtnClick={() => {
          onDeleteSuggestion();
        }}
        headerText={<Trans i18nKey='Surveys.Confirm suggestion delition.Title' />}
        bodyText={t('Surveys.Confirm suggestion delition.Text')}
        isSubmiting={isSubmiting}
      />

      {question.parentSurveyQuestionOptions.map(
        (option, index) =>
          ((option.suggestionAdded && (option.createdBy === user.id || option.merged)) ||
            !option.suggestionAdded ||
            (parentSurveyIsClosed &&
              ((option.suggestionAdded && (option.createdBy === user.id || option.merged)) ||
                user.administrationAccess === true))) && (
            <div className={styles.answerWrapper} key={option.id}>
              {editSuggestion && editSuggestion.optionId === option.id ? (
                <FeedItemParentSurveyOptionEditing
                  key={option.id}
                  answer={option.answer}
                  optionId={option.id}
                  questionId={question.id}
                  parentSurveyDispatch={parentSurveyDispatch}
                  isSubmiting={isSubmiting}
                  setEditSuggestion={setEditSuggestion}
                  setIsSubmiting={setIsSubmiting}
                  parentSurveyId={parentSurveyDataId}
                />
              ) : (
                <div className={styles.answerContainer}>
                  {canUserVote && (
                    <div
                      className={cn(styles.answerCheckbox, {
                        [styles.answerRadio]: !question.multipleAnswerStatus,
                      })}
                    >
                      {question.inputType === 'text' ? (
                        <>
                          {question.multipleAnswerStatus ? (
                            <Checkbox
                              isChecked={option.checked}
                              onChange={() => {
                                if (!parentSurveyIsClosed && !userHasVoted) {
                                  parentSurveyDispatch({
                                    type: 'changeVote',
                                    optionId: option.id,
                                    questionId: question.id,
                                    multipleAnswerStatus: question.multipleAnswerStatus,
                                  });
                                }
                              }}
                            />
                          ) : (
                            <RadioButton
                              checked={option.checked}
                              onClick={() => {
                                if (!parentSurveyIsClosed && !userHasVoted) {
                                  parentSurveyDispatch({
                                    type: 'changeVote',
                                    optionId: option.id,
                                    questionId: question.id,
                                    multipleAnswerStatus: question.multipleAnswerStatus,
                                  });
                                }
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  {question.inputType === 'text' ||
                  (question.inputType === 'number' && parentSurveyIsClosed) ||
                  (question.inputType === 'doubleDropdownImportance' && parentSurveyIsClosed) ? (
                    <>
                      <div className={styles.answerTextContainer}>
                        <div className={styles.answerText}>
                          {option.checked}
                          {isTranslationAllowed ? (
                            <WithWatsonTranslate
                              data={{
                                text: option.answer,
                                entityId: option.id,
                                entityType: 'parent survey answer',
                              }}
                              Component={({ html }) => (
                                <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                              )}
                            />
                          ) : (
                            <span dangerouslySetInnerHTML={{ __html: sanitize(option.answer) }} />
                          )}
                        </div>
                        <div className={styles.answerProgress}>
                          {((parentSurveyIsClosed && groupInfo.groupAdminStatus) ||
                            isPublished) && (
                            <>
                              <div
                                className={styles.answerProgressValue}
                                style={{ width: getWidth(option.id) }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  {((parentSurveyIsClosed && groupInfo.groupAdminStatus) || isPublished) && (
                    <div
                      className={
                        option.checked ? cn(styles.voted, styles.answerVotes) : styles.answerVotes
                      }
                    >
                      {option.counted_votes} {t('ParentSurvey.votes')}
                    </div>
                  )}

                  {option.suggestionAdded &&
                    !parentSurveyIsClosed &&
                    (user.id === option.createdBy || groupInfo.groupAdminStatus) && (
                      <div className={styles.suggestionControls}>
                        <Button
                          className={styles.suggestionControlButton}
                          size='sm'
                          onClick={() => {
                            setDeleteSuggestion({
                              optionId: option.id,
                              questionId: question.id,
                            });
                          }}
                        >
                          <i className='fa fa-trash' />
                        </Button>
                        <Button
                          className={styles.suggestionControlButton}
                          size='sm'
                          onClick={() => {
                            setEditSuggestion({
                              optionId: option.id,
                              questionId: question.id,
                            });
                          }}
                        >
                          <i className='fa fa-pencil' />
                        </Button>
                      </div>
                    )}
                </div>
              )}
            </div>
          ),
      )}

      {!parentSurveyIsClosed && question.inputType === 'number' && (
        <Select
          className={styles.numberSelect}
          options={convertInputTypeNumberOptions(question.parentSurveyQuestionOptions)}
          selected={onSelect()}
          onSelect={e => {
            parentSurveyDispatch({
              type: 'changeVoteTypeNumber',
              answer: e.value,
              questionId: question.id,
            });
          }}
        />
      )}
      {!parentSurveyIsClosed &&
        !userHasVoted &&
        question.inputType !== 'number' &&
        question.allowUserAddSuggestionStatus && (
          <div className={styles.addSuggestionContainer}>
            <AutoGrowingTextArea
              value={suggestionText}
              placeholder={t('Surveys.Add answer')}
              onChange={content => setSuggestionText(content)}
            />
            <ButtonWithLoader
              type='default'
              size='sm'
              className={styles.addSuggestionButton}
              onClick={addSuggestion}
              isSubmiting={isSubmiting}
            >
              {t('Post.Save')}
            </ButtonWithLoader>
          </div>
        )}
      {!parentSurveyIsClosed && question.inputType === 'doubleDropdownImportance' && (
        <div>
          <Select
            className={styles.doubleDropdown}
            options={question.parentSurveyQuestionOptions
              .sort((a, b) => (a.orderPosition < b.orderPosition ? -1 : 1))
              .map(o => {
                return { value: o.answer, label: o.answer };
              })}
            selected={(() => {
              const cur = question.parentSurveyQuestionOptions.find(element => element.checked);

              return cur ? { value: cur.id, label: cur.answer } : { value: '', label: t('') };
            })()}
            onSelect={e => {
              parentSurveyDispatch({
                type: 'changeVoteTypeNumber',
                answer: e.value,
                questionId: question.id,
              });
            }}
          />
          <p className={styles.doubleDropdownLabel}>{t('ParentSurvey.PersonalImportance')}</p>
          <Select
            className={styles.doubleDropdown}
            options={[
              { value: 'ParentSurvey.No information', label: t('ParentSurvey.No information') },
              { value: 'ParentSurvey.AnswerVery', label: t('ParentSurvey.AnswerVery') },
              { value: 'ParentSurvey.AnswerKindOf', label: t('ParentSurvey.AnswerKindOf') },
              { value: 'ParentSurvey.AnswerNotReally', label: t('ParentSurvey.AnswerNotReally') },
              { value: 'ParentSurvey.AnswerNotAtAll', label: t('ParentSurvey.AnswerNotAtAll') },
            ]}
            selected={(() => {
              let cur = question.parentSurveyQuestionOptions.find(element => element.checked);
              if (!cur) cur = question.parentSurveyQuestionOptions[0];

              return { value: cur.secondaryAnswer, label: t(cur.secondaryAnswer) };
            })()}
            onSelect={e => {
              parentSurveyDispatch({
                type: 'changeVoteSecondaryAnswer',
                answer: e.value,
                questionId: question.id,
              });
            }}
          />
        </div>
      )}

      <br />
    </div>
  );
}

export async function submitSuggestion(parentSurveyId, questionId, optionId, answer) {
  return addEditParentSurveySuggestion(parentSurveyId, questionId, optionId, answer);
}
