import React from 'react';

import Tooltip from 'library/common/commonComponents/Tooltip';

import { useTranslation } from 'react-i18next';
import styles from './fileManagerBreadcrumbs.module.scss';

export default function FileManagerBreadcrumbs({ isPrivateFolder, gotoHome, breadCrumbs }) {
  const { t } = useTranslation();

  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.breadcrumbsLeft}>
        <div className={styles.breadcrumbsHome} onClick={gotoHome}>
          <i className='fa fa-home fa-lg fa-fw' />
        </div>

        {breadCrumbs &&
          breadCrumbs.map(item => {
            return (
              <span className={styles.breadcrumbsItem} key={item.name}>
                {item.name}
              </span>
            );
          })}
      </div>

      {breadCrumbs.length > 0 && (
        <div>
          <Tooltip
            fontSize={12}
            text={t(
              isPrivateFolder ? 'FileManager.BreadcrumbsPrivate' : 'FileManager.BreadcrumbsPublic',
            )}
            isVisibleCondition
          >
            {isPrivateFolder ? (
              <i className='fa fa-lock fa-lg' />
            ) : (
              <i className='fa fa-unlock-alt fa-lg' />
            )}
          </Tooltip>
        </div>
      )}
    </div>
  );
}
