import React from 'react';
import { useTranslation } from 'react-i18next';
import Label from "library/common/commonComponents/Label";
import Input from "library/common/commonComponents/Inputs/Input";
import TextArea
  from "library/common/commonComponents/Inputs/TextArea";
import Checkbox from "library/common/commonComponents/Checkbox";
import RadioButton from "library/common/commonComponents/RadioButton";

export default function HeaderParagraphEditView({
  component,
  setComponent,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <Label type="input">{t('FormsEditor.ComponentEditorTextLabel')}</Label>
        {component.type === "header" ? (
          <Input
            value={component.text}
            onChange={e => setComponent({
              ...component,
              text: e.target.value,
            })}
            placeholder={t('FormsEditor.ComponentEditorTextPlaceholder')}
          />
        ) : (
          <TextArea
            placeholder={t('FormsEditor.ComponentEditorTextPlaceholder')}
            value={component.text}
            onChange={e => setComponent({
              ...component,
              text: e.target.value,
            })}
          />
        )}
      </div>
      <div>
        <Label type="input">{t('FormsEditor.ComponentEditorIndentLabel')}</Label>
        <Input
          value={component.indent}
          onChange={e => {
            setComponent({
              ...component,
              indent: e.target.value,
            });
          }}
          placeholder={t('FormsEditor.ComponentEditorIndentPlaceholder')}
        />
      </div>
      <div>
        <Label type="input">{t('FormsEditor.ComponentEditorFontSizeLabel')}</Label>
        <Input
          value={component.fontSize}
          onChange={e => setComponent({
            ...component,
            fontSize: e.target.value,
          })}
          placeholder={t('FormsEditor.ComponentEditorFontSizePlaceholder')}
        />
      </div>
      <div>
        <Checkbox
          isChecked={component.bold}
          onChange={() => setComponent({
            ...component,
            bold: !component.bold,
          })}
          name={t('FormsEditor.ComponentEditorBoldLabel')}
        />
      </div>
      <div>
        <Checkbox
          isChecked={component.italic}
          onChange={() => setComponent({
            ...component,
            italic: !component.italic,
          })}
          name={t('FormsEditor.ComponentEditorItalicLabel')}
        />
      </div>
      <div>
        <Checkbox
          isChecked={component.underlined}
          onChange={() => setComponent({
            ...component,
            underlined: !component.underlined,
          })}
          name={t('FormsEditor.ComponentEditorUnderlinedLabel')}
        />
      </div>
      <div>
        <Label type="input">{t('FormsEditor.ComponentEditorAlignmentLabel')}</Label>
        <RadioButton
          checked={component.alignment === "left"}
          label={t('FormsEditor.ComponentEditorAlignmentLeftLabel')}
          onClick={() => setComponent({
            ...component,
            alignment: "left",
          })}
        />
        <RadioButton
          checked={component.alignment === "center"}
          label={t('FormsEditor.ComponentEditorAlignmentCenterLabel')}
          onClick={() => setComponent({
            ...component,
            alignment: "center",
          })}
        />
        <RadioButton
          checked={component.alignment === "right"}
          label={t('FormsEditor.ComponentEditorAlignmentRightLabel')}
          onClick={() => setComponent({
            ...component,
            alignment: "right",
          })}
        />
      </div>
      
      <Checkbox
        name={t('FormsEditor.ComponentEditorMandatoryLabel')}
        isChecked={component.mandatory}
        onChange={() => {
          setComponent({
            ...component,
            mandatory: !component.mandatory,
          });
        }}
      />
    </div>
  );
}
