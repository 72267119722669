import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import AddAuthorizedPersonPopup from 'library/common/commonComponents/Popups/AddAuthorizedPersonPopup';
import Button from 'library/common/commonComponents/Buttons/Button';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import EditAuthorizedPersonPopup from '../Popups/EditAuthorizedPersonPopup';

import {
  createAuthorizedPickupPerson,
  deleteAuthorizedPickupPerson,
  getAuthorizedPickupPersons,
  updateAuthorizedPickupPerson,
} from '../../../api/user';
import styles from './AuthorizedPeople.module.scss';
import Loader from '../Loader';

const AuthorizedPeople = ({ kidId, disableEditing }) => {
  const [authorizedPersons, setAuthorizedPersons] = useState([]);
  const [personToDelete, setPersonToDelete] = useState();
  const [personToEdit, setPersonToEdit] = useState(null);
  const [personIdToEdit, setPersonIdToEdit] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [addSomeone, setAddSomeone] = useState(false);
  const [editSomeone, setEditSomeone] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [imageRev, setImageRev] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (kidId) {
      getAuthorizedPickupPersons(kidId)
        .then(res => {
          setAuthorizedPersons(res.data);
        })
    }
  }, []);

  const handleAddPerson = person => {
    setIsLoading(true);
    createAuthorizedPickupPerson(
      kidId,
      person.firstName,
      person.lastName,
      person.email,
      person.phonenumber,
      person.image,
    )
      .then(res => {
        setAuthorizedPersons(prev => [...prev, res.data]);
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('AuthorizedPersons.ErrorSaving'), { isFail: true }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleToEditPerson = personId => {
    setEditSomeone(true);
    authorizedPersons.forEach(value => {
      if (value.id === personId) {
        setPersonToEdit(value);
        setPersonIdToEdit(personId);
      }
    });
  };

  const handleEditPerson = modifiedPerson => {
    setIsLoading(true);
    updateAuthorizedPickupPerson(
      personIdToEdit,
      modifiedPerson.firstName,
      modifiedPerson.lastName,
      modifiedPerson.email,
      modifiedPerson.phonenumber,
      modifiedPerson.image,
      modifiedPerson.origImage,
    )
      .then(res => {
        let newAuthorizedPersons = [...authorizedPersons];
        newAuthorizedPersons = newAuthorizedPersons.map(element => {
          if (element.id === personIdToEdit) {
            element.firstName = modifiedPerson.firstName;
            element.lastName = modifiedPerson.lastName;
            element.email = modifiedPerson.email;
            element.phonenumber = modifiedPerson.phonenumber;
            element.image =
              res.data.image == null || res.data.image === ''
                ? ''
                : `${res.data.image}&rev=${imageRev}`;
            setImageRev(imageRev + 1);
            return element;
          } else return element;
        });
        setAuthorizedPersons(newAuthorizedPersons);
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('AuthorizedPersons.ErrorSaving'), { isFail: true }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteAuthorizedPerson = () => {
    setIsDeleting(true);

    deleteAuthorizedPickupPerson(personToDelete)
      .then(() => {
        setIsDeleting(false);

        setAuthorizedPersons(prev => prev.filter(value => value.id !== personToDelete));

        store.dispatch(
          showBottomNotification(t('AuthorizedPersons.SuccessDeleting'), { isFail: false }),
        );
      })
      .catch(() => {
        setIsDeleting(false);

        store.dispatch(
          showBottomNotification(t('AuthorizedPersons.ErrorDeleting'), { isFail: true }),
        );
      });
  };

  return (
    <>
      <div className={styles.authorizedPeopleContainer}>
        <div className={styles.authorizedPeopleHeader}>
          <div>{t('AuthorizedPersons.Image')}</div>
          <div>{t('AuthorizedPersons.LastName')}</div>
          <div>{t('AuthorizedPersons.FirstName')}</div>
          <div>{t('AuthorizedPersons.Phonenumber')}</div>
          <div></div>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles.authorizedPeopleGrid}>
          {authorizedPersons != null &&
            authorizedPersons.map((authorizedPerson) => (
              <div className={styles.authorizedPersonCard} key={authorizedPerson.id}>
                <div className={styles.personImage}>
                  {authorizedPerson.image ? (
                    <img
                      src={authorizedPerson.image}
                      alt={`Bild von ${authorizedPerson.firstName} ${authorizedPerson.lastName}`}
                    />
                  ) : (
                    <img src={defaultAvatar} alt='default avatar' />
                  )}
                </div>
                <div>{authorizedPerson.lastName}</div>
                <div>{authorizedPerson.firstName}</div>
                <div>{authorizedPerson.phonenumber}</div>
                <div className={styles.buttonColumn}>
                  <Button
                    onClick={() => handleToEditPerson(authorizedPerson.id)}
                    type='success'
                    disabled={disableEditing}
                  >
                    {t('AuthorizedPersons.Edit')}
                  </Button>
                  <Button
                    onClick={() => setPersonToDelete(authorizedPerson.id)}
                    type='danger'
                    disabled={disableEditing}
                  >
                    {t('AuthorizedPersons.Delete')}
                  </Button>
                </div>
              </div>
            ))}
        </div>
        )}
      </div>

      {(!authorizedPersons || authorizedPersons.length === 0) && (
        <p style={{ marginTop: '10px' }}>{t('AuthorizedPersons.NoPersonsAvailable')}</p>
      )}

      <Button
        type='light'
        className={styles.button}
        onClick={() => setAddSomeone(true)}
        disabled={disableEditing}
      >
        {t('AuthorizedPersons.Add')}
      </Button>
      <DeletePopup
        isOpened={personToDelete != null}
        closePopup={() => setPersonToDelete(undefined)}
        onDeleteBtnClick={handleDeleteAuthorizedPerson}
        headerText={t('AuthorizedPersonsDeleteConfirm.Title')}
        bodyText={t('AuthorizedPersonsDeleteConfirm.Text')}
        isSubmiting={isDeleting}
      />
      <AddAuthorizedPersonPopup
        isOpened={addSomeone}
        closePopup={() => setAddSomeone(false)}
        onAddBtnClick={person => {
          handleAddPerson(person);
        }}
        headerText={t('AuthorizedPersons.Add')}
        isSubmiting={isDeleting}
      />
      {personToEdit && (
        <EditAuthorizedPersonPopup
          isOpened={editSomeone}
          closePopup={() => {
            setEditSomeone(false);
            setPersonToEdit(null);
            setPersonIdToEdit(null);
          }}
          headerText={t('AuthorizedPersons.Edit')}
          isSubmiting={isDeleting}
          onEditBtnClick={person => {
            handleEditPerson(person);
          }}
          personToEdit={personToEdit}
        />
      )}
    </>
  );
};

export default AuthorizedPeople;
