import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { sanitize } from 'dompurify';

import { isImage } from 'library/utilities/files';
import { getDeadlineTime } from 'library/utilities/date';
import Loader from 'library/common/commonComponents/Loader';
import CheckBox from 'library/common/commonComponents/Checkbox';
import MessageWithFiles from 'library/common/commonComponents/MessageWithFiles';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';

import styles from './taskBody.module.scss';

export default function TaskBody({
  onUpdateTaskStatus,
  user,
  assignedUsersList,
  responsibleUsersList,
  isScheduling,
  end,
  reviewRequired,
  taskStatus,
  proceedUnassignedTask,
  description,
  checklist,
  files,
  onUpdateTaskChecklist,
  allDay,
  id,
  isTranslationAllowed,
}) {
  const { t } = useTranslation();

  let allImages;
  if (typeof files === 'undefined' || files === null) {
    allImages = null;
  } else {
    allImages = files.filter(file => isImage(file.fileId));
  }

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isSubmitingCheckbox, setIsSubmitingCheckbox] = useState(false);
  // eslint-disable-next-line
  const isAssigned = useMemo(() => assignedUsersList.find(item => item.id === user.id), [
    assignedUsersList,
  ]);
  // eslint-disable-next-line
  const isResponsible = useMemo(() => responsibleUsersList.find(item => item.id === user.id), [
    responsibleUsersList,
  ]);

  const handleUpdateTaskStatus = async status => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(true);
    await onUpdateTaskStatus(status);
    setIsSubmiting(false);
  };

  const canUpdateTaskStatus =
    isResponsible ||
    (isAssigned &&
      (taskStatus !== 'pending_review' && (!reviewRequired || taskStatus !== 'completed'))) ||
    (!assignedUsersList.length && proceedUnassignedTask && taskStatus !== 'pending_review');

  const date = new Date();
  const isOverdue = date.getTime() > end;
  const deadline = getDeadlineTime(end, allDay, user.langCode);

  const taskStatusButton =
    canUpdateTaskStatus &&
    getTaskStatusButtonOptions({
      taskStatus,
      reviewRequired,
      isResponsible,
      t,
      handleUpdateTaskStatus,
    });

  const handleUpdateChecklist = async (updatedItem, indexCheckbox) => {
    if (isSubmiting || isSubmitingCheckbox) {
      return;
    }
    setIsSubmiting(true);
    setIsSubmitingCheckbox(indexCheckbox);
    const newChecklist = checklist.slice();
    const index = newChecklist.findIndex(item => item.id === updatedItem.id);
    newChecklist.splice(index, 1, updatedItem);
    await onUpdateTaskChecklist(newChecklist);
    setIsSubmitingCheckbox(false);
    setIsSubmiting(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <div className={styles.assignmentInfoContainer}>
            <div className={styles.assignmentInfoTitle}>
              <i className='fa fa-users' />
              {t('Tasks.Assignments')}
            </div>
            <div className={styles.assignmentInfoText}>
              {isAssigned && (
                <div className={styles.assignmentInfoTextAssigned}>
                  <i className='fa fa-check' />
                  {t('Tasks.You are assigned')}
                </div>
              )}
              {isResponsible && (
                <div className={styles.assignmentInfoTextResponsible}>
                  <i className='fa fa-check' />
                  {t('Tasks.You are responsible')}
                </div>
              )}
              {!isAssigned && !isResponsible && (
                <div className={styles.assignmentInfoTextAssigned}>
                  <i className='fa fa-times' />
                  {assignedUsersList.length !== 0
                    ? t('Tasks.This task can only be processed')
                    : proceedUnassignedTask
                    ? t('Tasks.Anyone can work on this task')
                    : t('Tasks.This task can only be processed')}
                </div>
              )}
            </div>
          </div>
          <div className={styles.sheduleInfoContainer}>
            <div className={styles.sheduleInfoTitle}>
              <i className='fa fa-clock-o' />
              {t('Tasks.Scheduling')}
            </div>
            <div className={styles.sheduleInfoInfoText}>
              {isScheduling ? (
                <div
                  className={cn(
                    styles.sheduleInfoInfoText,
                    isOverdue && taskStatus !== 'completed' && styles.sheduleInfoInfoTextOverdue,
                  )}
                >
                  {`${t('Tasks.Deadline at')} ${deadline}`}
                </div>
              ) : (
                <div className={styles.sheduleInfoInfoText}>
                  {`${t('Tasks.No Scheduling set')}`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.headerRight}>
          {canUpdateTaskStatus &&
            (isSubmiting ? (
              <Loader dotColor='#777' dotSize='10px' className={styles.buttonSubmitting} />
            ) : (
              <div
                className={cn(
                  styles.taskStatusButtonContainer,
                  !taskStatusButton.dropDown.length && styles.buttonReset,
                )}
                onClick={taskStatusButton.onClick}
              >
                <i className={taskStatusButton.faIcon} />
                {taskStatusButton.title}
                <div
                  className={styles.taskStatusButtonDropdownConntainer}
                  onClick={e => e.stopPropagation()}
                >
                  <MoreBtn
                    dropdownOptions={taskStatusButton.dropDown}
                    className={styles.taskStatusButtonDropdown}
                    faIcon='fa fa-caret-down'
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      {description &&
        (isTranslationAllowed ? (
          <WithWatsonTranslate
            data={{ text: description, entityId: id, entityType: '5' }}
            Component={({ html }) => (
              <div
                className={styles.taskDescription}
                dangerouslySetInnerHTML={{ __html: sanitize(html) }}
              />
            )}
          />
        ) : (
          <div
            className={styles.taskDescription}
            dangerouslySetInnerHTML={{ __html: sanitize(description) }}
          />
        ))}
      {checklist.length !== 0 && (
        <div className={styles.checklistContainer}>
          <div className={styles.checklistContainerHeader}>
            <i className='fa fa-check-square-o' />
            {`${t('Tasks.Checklist')}:`}
          </div>
          <div className={styles.checklistItemsContainer}>
            {checklist.map((item, index) => (
              <CheckBox
                key={item.id}
                isDisabled={
                  (isSubmiting && isSubmitingCheckbox !== index + 1) ||
                  (isSubmitingCheckbox && isSubmitingCheckbox !== index + 1) ||
                  taskStatus === 'completed' ||
                  (!isAssigned &&
                    !isResponsible &&
                    (taskStatus === 'pending_review' ||
                      (!assignedUsersList.length && !proceedUnassignedTask))) ||
                  (!isResponsible && taskStatus === 'pending_review')
                }
                className={styles.checkbox}
                name={
                  isTranslationAllowed ? (
                    <WithWatsonTranslate
                      data={{ text: item.title, entityId: id, entityType: '5' }}
                      Component={({ html }) => (
                        <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                      )}
                    />
                  ) : (
                    item.title
                  )
                }
                isChecked={item.status}
                onChange={() => handleUpdateChecklist({ ...item, status: !item.status }, index + 1)}
                isLoading={isSubmitingCheckbox === index + 1}
              />
            ))}
          </div>
        </div>
      )}
      {files.length !== 0 && (
        <div className={styles.filesContainer}>
          <MessageWithFiles
            files={files}
            allImages={allImages}
            entityType='5'
            entityId={id}
            isTranslationAllowed={isTranslationAllowed}
          />
        </div>
      )}
    </div>
  );
}

export const getTaskStatusButtonOptions = ({
  taskStatus,
  reviewRequired,
  isResponsible,
  t,
  handleUpdateTaskStatus,
}) => {
  const buttonOptions = {
    faIcon: 'fa fa-play',
    title: t('Tasks.Begin Task'),
    onClick: () => handleUpdateTaskStatus('in_progress'),
    dropDown: reviewRequired
      ? isResponsible
        ? [
            {
              faIcon: 'fa fa-eye',
              title: t('Tasks.Ready For Review'),
              onClick: () => handleUpdateTaskStatus('pending_review'),
            },
            {
              faIcon: 'fa fa-check-square-o',
              title: t('Tasks.Finish Task'),
              onClick: () => handleUpdateTaskStatus('completed'),
            },
          ]
        : [
            {
              faIcon: 'fa fa-eye',
              title: t('Tasks.Ready For Review'),
              onClick: () => handleUpdateTaskStatus('pending_review'),
            },
          ]
      : [
          {
            faIcon: 'fa fa-check-square-o',
            title: t('Tasks.Finish Task'),
            onClick: () => handleUpdateTaskStatus('completed'),
          },
        ],
  };

  switch (taskStatus) {
    case 'pending':
      return buttonOptions;

    case 'in_progress':
      buttonOptions.faIcon = reviewRequired ? 'fa fa-eye' : 'fa fa-check-square-o';
      buttonOptions.title = reviewRequired ? t('Tasks.Ready For Review') : t('Tasks.Finish Task');
      buttonOptions.onClick = () =>
        handleUpdateTaskStatus(reviewRequired ? 'pending_review' : 'completed');
      buttonOptions.dropDown = reviewRequired
        ? isResponsible
          ? [
              {
                faIcon: 'fa fa-check-square-o',
                title: t('Tasks.Finish Task'),
                onClick: () => handleUpdateTaskStatus('completed'),
              },
              {
                faIcon: 'fa fa-undo',
                title: t('Tasks.Reset Task'),
                onClick: () => handleUpdateTaskStatus('pending'),
              },
            ]
          : [
              {
                faIcon: 'fa fa-undo',
                title: t('Tasks.Reset Task'),
                onClick: () => handleUpdateTaskStatus('pending'),
              },
            ]
        : [
            {
              faIcon: 'fa fa-undo',
              title: t('Tasks.Reset Task'),
              onClick: () => handleUpdateTaskStatus('pending'),
            },
          ];
      return buttonOptions;

    case 'pending_review':
      buttonOptions.faIcon = 'fa fa-check-square-o';
      buttonOptions.title = t('Tasks.Accept Task');
      buttonOptions.onClick = () => handleUpdateTaskStatus('completed');
      buttonOptions.dropDown = [
        {
          faIcon: 'fa fa-times-circle-o',
          title: t('Tasks.Reject Task'),
          onClick: () => handleUpdateTaskStatus('in_progress'),
        },
      ];
      return buttonOptions;

    case 'completed':
      buttonOptions.faIcon = reviewRequired ? 'fa fa-eye' : 'fa fa-undo';
      buttonOptions.title = reviewRequired ? t('Tasks.Continue Review') : t('Tasks.Reset Task');
      buttonOptions.onClick = () =>
        handleUpdateTaskStatus(reviewRequired ? 'pending_review' : 'pending');
      buttonOptions.dropDown = reviewRequired
        ? [
            {
              faIcon: 'fa fa-undo',
              title: t('Tasks.Reset Task'),
              onClick: () => handleUpdateTaskStatus('pending'),
            },
          ]
        : [];
      return buttonOptions;

    default:
      return buttonOptions;
  }
};
