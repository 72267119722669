import React, { useState } from 'react';
import Pagination from 'library/common/commonComponents/Pagination';

import exerciseitemStyle from './exerciseitem.module.scss';

export default function PortfolioExerciseItem({ modules }) {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className={exerciseitemStyle.container}>
      <div className={exerciseitemStyle.content}>
        {modules[currentPage - 1].image && (
          <img
            src={modules[currentPage - 1].image}
            alt=''
            style={{
              maxHeight: '200px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        )}
        <div>
          <div className={exerciseitemStyle.moduleTitle}>{modules[currentPage - 1].title}</div>
          <div className={exerciseitemStyle.moduleDuration}>
            Dauer: <span>{modules[currentPage - 1].duration}</span>
          </div>
          <div className={exerciseitemStyle.moduleGoals}>Ziele:</div>
          <ul className={exerciseitemStyle.moduleGoalsList}>
            {modules[currentPage - 1].goals.map(goal => {
              return <li>{goal}</li>;
            })}
          </ul>
          {modules[currentPage - 1].materials != null && modules[currentPage - 1].materials.length > 0 && (
            <>
              <div className={exerciseitemStyle.moduleGoals}>Material:</div>
              <ul className={exerciseitemStyle.moduleGoalsList}>
                {modules[currentPage - 1].materials.map(mat => {
                  return <li>{mat}</li>;
                })}
              </ul>
            </>
          )}
          <div className={exerciseitemStyle.verticalBorder} />
          <div className={exerciseitemStyle.text} dangerouslySetInnerHTML={{__html: modules[currentPage - 1].text}}></div>
        </div>
        <Pagination
          currentPage={currentPage}
          numberOfPages={modules.length}
          onPageClick={clickedPage => setCurrentPage(clickedPage)}
          withoutRouting
        />
      </div>
    </div>
  );
}
