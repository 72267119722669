import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Label from "library/common/commonComponents/Label";
import Input from "library/common/commonComponents/Inputs/Input";
import cn from 'classnames';
import style from "./ImageEditView.module.scss";
import RadioButton from "library/common/commonComponents/RadioButton";
import Button from "../../../../../../../../../../library/common/commonComponents/Buttons/Button";

export default function ImageEditView({
  component,
  setComponent,
  activeKita,
}) {
  const { t } = useTranslation();

  const [imageUploadError, setImageUploadError] = useState(null);

  const toDataURL = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };

  return (
    <div>
      <div>
        <Label type="input">{t('FormsEditor.ComponentEditorIndentLabel')}</Label>
        <Input
          value={component.indent}
          onChange={e => {
            setComponent({
              ...component,
              indent: e.target.value,
            });
          }}
          placeholder={t('FormsEditor.ComponentEditorIndentPlaceholder')}
        />
      </div>
      <div>
        <Label type="input">{t('FormsEditor.ComponentEditorAlignmentLabel')}</Label>
        <RadioButton
          checked={component.alignment === "left"}
          label={t('FormsEditor.ComponentEditorAlignmentLeftLabel')}
          onClick={() => setComponent({
            ...component,
            alignment: "left",
          })}
        />
        <RadioButton
          checked={component.alignment === "center"}
          label={t('FormsEditor.ComponentEditorAlignmentCenterLabel')}
          onClick={() => setComponent({
            ...component,
            alignment: "center",
          })}
        />
        <RadioButton
          checked={component.alignment === "right"}
          label={t('FormsEditor.ComponentEditorAlignmentRightLabel')}
          onClick={() => setComponent({
            ...component,
            alignment: "right",
          })}
        />
      </div>
      <div>
        <Label type="input">{t('FormsEditor.ComponentEditorScaleLabel')}</Label>
        <Input
          value={component.scale}
          onChange={e => {
            setComponent({
              ...component,
              scale: e.target.value,
            });
          }}
          placeholder={t('FormsEditor.ComponentEditorScalePlaceholder')}
        />
      </div>
      <div>
        <div className={cn(style.upload, style.uploadButton)}>
          <label>
            <i className='fa fa-cloud-upload' />
            <input
              type='file'
              onChange={(e) => {
                const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;

                if (!file)
                {
                  return;
                }

                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                  setComponent({
                    ...component,
                    data: reader.result,
                  });
                };
                reader.onerror = function (error) {
                  setImageUploadError(t('FormsEditor.ComponentEditorImageUploadErrorMsg'));
                };
              }}
              multiple={false}
              accept='image/*'
            />
            {t('FormsEditor.ComponentEditorImageUploadLabel')}
          </label>
        </div>
        {imageUploadError && (
          <p className={style.errorMsg}>{imageUploadError}</p>
        )}
        {component.data && (
          <div>
            <img src={component.data} className={style.imagePreview} width='200px' />
            <a
              onClick={() => setComponent({
                ...component,
                data: '',
              })}
              className={style.imagePreviewDeleteButton}
            >
              <i className="fa fa-trash"/> {t('FormsEditor.ComponentEditorImageUploadRemoveLabel')}
            </a>
          </div>
        )}
        {activeKita.kitaLogoUrl && (
          <Button onClick={() => {
            toDataURL(activeKita.kitaLogoUrl, (res) => {
              setComponent({
                ...component,
                data: res,
              });
            });
          }}>
            {t('FormsEditor.ComponentEditorUseKitaLogoButtonLabel')}
          </Button>
        )}
      </div>
    </div>
  );
}
