import React from 'react';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import styles from './listItemElement.module.scss';


export default function ListItemWithDelete({
  item,
  onDeleteButtonClick,
  id,
  groupIndex,
}) {
  
  return (
    <div key={item.id} className={styles.inputTags__item}>
      <div className={styles.photo}>
        <ProfileAvatar image={item} id={item.id} withoutLink />
      </div>
      <div className={styles.name}>{`${item.firstName} ${item.lastName}`}</div>
      <div className={styles.delete} onClick={()=>onDeleteButtonClick(id,groupIndex)}>
        <i className='fa fa-times-circle' />
      </div>
    </div>
  );
}

export function getClassByTheme(theme) {
  if (window.location.hostname.toLowerCase().includes('bvf4u.safe2connect.org') && theme == 'yellow') {
    return styles.themeBlue;
  }
  if (window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org') && theme == 'yellow') {
    return styles.themeBrown;
  }
  switch (theme) {
    case 'light':
      return styles.themeLight;
    case 'yellow':
      return styles.themeYellow;
    case 'dark':
      return styles.themeDark;

    default:
      return styles.themeDark;
  }
}

ListItemWithDelete.defaultProps = {
  iconColor: 'inherit',
};
