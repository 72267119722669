import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './foodOrderKitchen.module.scss';
import Button from '../../../Buttons/Button';
import FoodOrderMealPlan from './FoodOrderMealPlan';
import Loader from 'library/common/commonComponents/Loader';
import FoodOrders from './FoodOrders';
import {
  formatDate,
  getWeekNumber,
  getCurrentFrom,
  getPreviousMonday,
  getNextMonday,
  getSunday,
} from '../../../../../utilities/foodOrder';

export default function FoodOrderKitchen({ groupId, showBottomNotification, activeKita, groupInfo }) {
  const { t } = useTranslation();

  const [mealPlanIsOpen, setMealPlanIsOpen] = useState(true);
  const [mealOrdersIsOpen, setMealOrdersIsOpen] = useState(false);

  const [isWeekSelectionLoading, setIsWeekSelectionLoading] = useState(false);

  const toggleSecondNav = path => {
    switch (path) {
      case 'mealplan':
        setMealPlanIsOpen(true);
        setMealOrdersIsOpen(false);
        break;
      case 'mealorders':
        setMealOrdersIsOpen(true);
        setMealPlanIsOpen(false);
        break;
    }
  };

  const [from, setFrom] = useState(getCurrentFrom());

  const onDecreaseCW = () => {
    setFrom(getPreviousMonday(from));
  };

  const onIncreaseCW = () => {
    setFrom(getNextMonday(from));
  };

  return (
    <div>
      <div className={styles.secondnav}>
        <Button
          type={mealPlanIsOpen ? 'info' : 'secondary'}
          className={styles.mr2}
          onClick={() => toggleSecondNav('mealplan')}
        >
          {t('FoodOrder.Meal plan')}
        </Button>
        <Button
          type={mealOrdersIsOpen ? 'info' : 'secondary'}
          onClick={() => toggleSecondNav('mealorders')}
        >
          {t('FoodOrder.Orders')}
        </Button>
      </div>
      <br />
      {!isWeekSelectionLoading ? (
        <div className={styles.cwnavigationContainer}>
          <Button onClick={() => onDecreaseCW()}>
            <i className='fa fa-chevron-left' />
          </Button>
          <h3 className={styles.calendarWeek}>
            KW {getWeekNumber(from)[1]} : {formatDate(from)} - {formatDate(getSunday(from))}
          </h3>
          <Button onClick={() => onIncreaseCW()}>
            <i className='fa fa-chevron-right' />
          </Button>
        </div>
      ) : <Loader />
    }
      <br />
      {mealPlanIsOpen && (
        <FoodOrderMealPlan
          setIsWeekSelectionLoading={setIsWeekSelectionLoading}
          showBottomNotification={showBottomNotification}
          groupId={groupId}
          from={from}
          to={getSunday(from)}
        />
      )}
      {mealOrdersIsOpen && (
        <FoodOrders
          showBottomNotification={showBottomNotification}
          groupId={groupId}
          from={from}
          to={getSunday(from)}
          activeKita={activeKita}
          groupInfo={groupInfo}
        />
      )}
    </div>
  );
}
