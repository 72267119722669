import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createKidDevelopmentDiaryEntry } from 'library/api/kidDevelopmentDiary';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import Popup from 'library/common/commonComponents/Popups/Popup';

import store from 'main/store/configureStore';

import styles from './presenceTimePopup.module.scss';

const PresenceTimeTypeOptions = [
  { value: 'CHECKIN', label: 'Checked In' },
  { value: 'CHECKOUT', label: 'Checked Out' },
];

export default function PresenceTimePopup({
  child,
  isShowPopup,
  closePopup,
}) {
  const { t } = useTranslation();
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [type, setType] = useState(PresenceTimeTypeOptions[0]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDateChange = e => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
  };

  const handleTimeChange = e => {
    const selectedTime = e.target.value;
    if (selectedTime < '06:00') {
      setTime('06:00');
    } else if (selectedTime > '18:00') {
      setTime('18:00');
    } else {
      setTime(selectedTime);
    }
  };

  const handleConfirm = async () => {
    if (date === '' || time === '') {
      store.dispatch(
        showBottomNotification(t('BottomNotification.Invalid Presence time'), {
          isWarning: true,
        }),
      );
      return;
    }
    const formattedDate = new Date(date);
    const [hours, minutes] = time.split(':');
    formattedDate.setHours(hours);
    formattedDate.setMinutes(minutes);
    const unixTimeStamp = Math.floor(formattedDate.getTime());
    setIsSubmitting(true);
    createKidDevelopmentDiaryEntry(child.kidId, `presence-${type.value.toLowerCase()}`, '', unixTimeStamp)
      .then(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
            isFail: true,
          }),
        );
      }).finally(() => {
      setIsSubmitting(false);
      closePopup();
    });
  };

  return (
    <Popup
      isOpened={isShowPopup}
      closePopup={closePopup}
      size='small'
      header={t('Checkinout.SetPresenceTime')}
      footer={
        <div className={styles.buttonsContainer}>
          <Button onClick={closePopup}>{t('Confirm.Cancel')}</Button>
          <ButtonWithLoader onClick={handleConfirm} type='primary' isLoading={isSubmitting}>
            {t('Confirm.Confirm')}
          </ButtonWithLoader>
        </div>
      }
    >
      <div className={styles.container}>
        <Input
          type={'date'}
          max={new Date().toISOString().split('T')[0]}
          value={date}
          onChange={e => handleDateChange(e)}
        />
        <Input type={'time'} value={time} onChange={e => handleTimeChange(e)} />
        <Select
          options={PresenceTimeTypeOptions}
          onSelect={option => {
            setType(option);
          }}
          selected={type}
        />
      </div>
    </Popup>
  );
}
