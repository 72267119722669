import React from 'react';
import cn from 'classnames';

import styles from './memberCard.module.scss';

export default function MemberCard({ member }) {
  return (
    <div className={styles.memberCard}>
      <div className={cn(styles.memberCard__names, 'notranslate')}>
        <div className={styles.fullName}>{`${member.firstName} ${member.lastName}`}</div>
        <div className={styles.email}>{member.email}</div>
      </div>
    </div>
  );
}
