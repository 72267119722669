import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import Loader from 'library/common/commonComponents/Loader';
import { getMessageReadStatus } from 'library/api/chat';
import {
  getReturnReceiptUsers,
  returnFormattedDateString,
  sendReminderToAllUnread,
} from 'library/api/returnReceipt';

import styles from './returnReceiptPopup.module.scss';
import ReturnReceiptPopupHeader from './ReturnReceiptPopupHeader';

export default function ReturnReceiptPopup({
  isOpened,
  closePopup,
  title,
  postId,
  chatMessageId,
  group,
}) {
  const { t } = useTranslation();

  const [headerSelectboxState, setHeaderSelectboxState] = useState(0);
  const [initialUsers, setInitialUsers] = useState([]);
  const [initialUsersLoaded, setInitialUsersLoaded] = useState(false);
  const [searchBoxContent, setSearchBoxContent] = useState('');
  const [filterdUsers, setFilterdUsers] = useState([]);
  const [selectBoxUsers, setSelectBoxUsers] = useState([]);
  const [reminderSent, setReminderSent] = useState(false);

  useEffect(() => {
    if (typeof postId !== 'undefined') {
      getReturnReceiptUsers(postId).then(data => {
        setInitialUsers(data.data);
        setFilterdUsers(data.data);
        setSelectBoxUsers(data.data);
        setInitialUsersLoaded(true);
      });
    }
    if (typeof chatMessageId !== 'undefined') {
      getMessageReadStatus(chatMessageId).then(data => {
        setInitialUsers(data.data);
        setFilterdUsers(data.data);
        setSelectBoxUsers(data.data);
        setInitialUsersLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    if (initialUsersLoaded === true) {
      setFilterdUsers(loadUsersByTextSearchState(selectBoxUsers));
    }
  }, [searchBoxContent]);

  useEffect(() => {
    loadUsersBySelectboxState();
  }, [headerSelectboxState]);

  function loadUsersBySelectboxState() {
    if (initialUsersLoaded === true) {
      if (headerSelectboxState === 0) {
        setFilterdUsers(loadUsersByTextSearchState(initialUsers));
        setSelectBoxUsers(initialUsers);
      } else if (headerSelectboxState === 1) {
        const confirmed = initialUsers.filter(obj => obj.hasConfirmedReturnReceipt === true);
        setFilterdUsers(loadUsersByTextSearchState(confirmed));
        setSelectBoxUsers(confirmed);
      } else if (headerSelectboxState === 2) {
        const notConfirmed = initialUsers.filter(obj => obj.hasConfirmedReturnReceipt === false);
        setFilterdUsers(loadUsersByTextSearchState(notConfirmed));
        setSelectBoxUsers(notConfirmed);
      }
    }
  }

  function loadUsersByTextSearchState(users) {
    const textSearchFilter = users.filter(function(n) {
      return (
        `${n.firstName} ${n.lastName}`.toLowerCase().includes(searchBoxContent.toLowerCase()) ||
        `${n.lastName} ${n.firstName}`
          .toLowerCase()
          .includes(searchBoxContent.toLowerCase() || searchBoxContent === '')
      );
    });
    return textSearchFilter;
  }

  const notConfirmed = initialUsers.filter(obj => obj.hasConfirmedReturnReceipt === false);

  const notifyUnread = () => {
    sendReminderToAllUnread(postId).then(() => {
      setReminderSent(true);
    });
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      header={title}
      footer={
        <div className={styles.footerWrapper}>
          {!!group ? (
            <Button onClick={notifyUnread} disabled={reminderSent}>
              {reminderSent ? 'Benachrichtigungen verschickt' : 'Nicht bestätigte benachrichtigen'}
            </Button>
          ) : (
            <div />
          )}
          <Button onClick={closePopup}>{t('Popup.Close')}</Button>
        </div>
      }
    >
      {initialUsersLoaded ? (
        <div>
          <ReturnReceiptPopupHeader
            headerSelectboxState={headerSelectboxState}
            setHeaderSelectboxState={setHeaderSelectboxState}
            searchBoxContent={searchBoxContent}
            setSearchBoxContent={setSearchBoxContent}
            allUserCount={initialUsers.length}
            confirmedUserCount={
              initialUsers.filter(obj => obj.hasConfirmedReturnReceipt === true).length
            }
            notConfirmedUserCount={notConfirmed.length}
          />
          <div className={styles.container}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={568}>
              {filterdUsers.map(user => {
                const userId = user.userId || user.id;
                let returnReceiptStatus = t('Post.Return receipt not confirmed');
                if (user.hasConfirmedReturnReceipt === true) {
                  returnReceiptStatus = t('Post.Return receipt confirmed');
                  if (
                    typeof user.returnReceiptCreatedAt !== 'undefined' &&
                    user.returnReceiptCreatedAt !== null
                  ) {
                    returnReceiptStatus +=
                      ': ' + returnFormattedDateString(user.returnReceiptCreatedAt);
                  }
                }

                return (
                  <Link to={`/profile/${userId}`} key={userId} className={styles.item}>
                    <div className={styles.itemAvatar}>
                      <ProfileAvatar image={user} id={userId} withoutLink />
                    </div>

                    <div className={styles.itemInfo}>
                      <div className={cn(styles.itemName, 'notranslate')}>
                        {`${user.firstName} ${user.lastName}`}
                      </div>
                      {<div className={styles.itemStatus}>{returnReceiptStatus}</div>}
                    </div>
                  </Link>
                );
              })}
            </Scrollbars>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Popup>
  );
}

ReturnReceiptPopup.defaultProps = {
  users: [],
};
