import { connect } from 'react-redux';

import { setUnreadMessagesCountsKitas } from 'library/common/commonActions/chatActions';
import {
  updateCountOfNotifications,
  updateCountOfTaxReceipt,
} from 'library/common/commonActions/notificationsActions';
import TopbarNotifications from '.';

export default connect(
  state => ({
    activeKita: state.kitaReducer.activeKita,
    kitas: state.kitaReducer.kitas,
    unreadMessagesCountsKitas: state.chatReducer.unreadMessagesCountsKitas,
    unreadNotificationsCount: state.notificationsReducer.unreadNotificationsCount,
    unreadTaxReceiptCount: state.notificationsReducer.unreadTaxReceiptCount,
    user: state.userReducer,
  }),
  {
    setUnreadMessagesCountsKitas,
    updateCountOfNotifications,
    updateCountOfTaxReceipt,
  },
)(TopbarNotifications);
