import React, { useState, useEffect, useRef } from 'react';

import Portal from 'library/common/commonComponents/Portal';
import Dropdown from 'library/common/commonComponents/Dropdowns/Dropdown';

export default function DropdownInPortal({
  dropdownOptions,
  isOptionsDropdownOpened,
  setIsOptionsDropdownOpened,
  outsideEventContainerRef,
}) {
  const [{ top, left }, setCoordinates] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (outsideEventContainerRef.current) {
      setCoordinates(getCoordinates(outsideEventContainerRef.current, dropdownRef.current));
    }
    // eslint-disable-next-line
  }, [isOptionsDropdownOpened, outsideEventContainerRef]);

  const dropdownRef = useRef(null);

  return (
    <Portal isOpened={isOptionsDropdownOpened}>
      <div
        style={{
          position: 'absolute',
          left,
          top,
        }}
      >
        <Dropdown
          isOpened={isOptionsDropdownOpened}
          dropdownOptions={dropdownOptions}
          theme='light'
          dropdownRef={dropdownRef}
          onClick={() => setIsOptionsDropdownOpened(true)}
        />
      </div>
    </Portal>
  );
}

export function getCoordinates(content, dropdown) {
  const rect = content.getBoundingClientRect();

  if (dropdown) {
    const dropdownRect = dropdown.getBoundingClientRect();
    if (window.innerHeight <= dropdownRect.height + dropdownRect.y) {
      return {
        top: rect.top - dropdownRect.height + window.pageYOffset - 10,
        left: rect.left + content.offsetWidth + window.pageXOffset + 17,
      };
    }
  }

  return {
    top: rect.top + content.offsetHeight + window.pageYOffset + 8,
    left: rect.left + content.offsetWidth + window.pageXOffset + 17,
  };
}
