import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { isActiveNavLink } from 'library/utilities/router';
import ListItemElementCounter from './listItemFrames/ListItemElementCounter';

import styles from './listItemCounter.module.scss';

export default function ListItemCounter({ to, isNavLink, ...props }) {
  return to ? (
    isNavLink ? (
      // eslint-disable-next-line
      <NavLink isActive={isActiveNavLink.bind(this, to)} activeClassName={styles.active} to={to}>
        {/* eslint-disable-next-line */}
        <ListItemElementCounter {...props} />
      </NavLink>
    ) : (
      <Link to={to}>
        <ListItemElementCounter {...props} />
      </Link>
    )
  ) : (
    <ListItemElementCounter {...props} />
  );
}
