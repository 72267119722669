import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';
import PT from 'prop-types';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import CheckInOutTimePopup from '../../TimePopup/CheckInOutTimePopup';
import { createKidDevelopmentDiaryEntry } from 'library/api/kidDevelopmentDiary';
import { formatDateObjectToTimeString } from '../../../../../../utilities/dateMoment';
import styles from './childRow.module.scss';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

const ChildRow = ({ childObj, prepareDelete, prepareEdit }) => {
  const { t } = useTranslation();
  const [sleepStatus, setSleepStatus] = useState(childObj.sleepStatus);
  const [todayComments, setTodayComments] = useState(childObj.sleepTodayComments);
  const [timePopupOpened, setTimePopupOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [time, setTime] = useState('');
  const [isValidTime, setIsValidTime] = useState(true);
  useEffect(() => {
    const date = new Date();
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const currentTime = `${hours}:${minutes}`;
    setTime(currentTime);
  }, []);
  const handleTimeChange = e => {
    const selectedTime = e.target.value;
    if (selectedTime < '06:00') {
      setIsValidTime(false);
      setTime('06:00');
    } else if (selectedTime > '18:00') {
      setIsValidTime(false);
      setTime('18:00');
    } else {
      setIsValidTime(true);
      setTime(selectedTime);
    }
  };

  const toUnixTimestamp = timeString => {
    const date = new Date();

    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');

    // Set the hours and minutes on the date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Return the UNIX timestamp (in seconds)
    return Math.floor(date.getTime());
  };

  const handleSubmitFromTimePopup = (time, text) => {
    setIsSaving(true);
    createKidDevelopmentDiaryEntry(childObj.kidId, 'sleep', text, time)
      .then(res => {
        const sortedTodayComments = [...todayComments, res.data];
        sortedTodayComments.sort(function(a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        setTodayComments(sortedTodayComments);
        store.dispatch(
          showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
            isFail: true,
          }),
        );
      })
      .finally(() => {
        setIsSaving(false);
        setTimePopupOpened(false);
      });
  };

  const getStatus = () => {
    switch (sleepStatus) {
      case true:
        return (
          <>
            <span className={styles.statusIn}>{t('Checkinout.Sleeping')}</span>
            <div
              className={styles.changeStatusCheckIn}
              onClick={() => {
                setSleepStatus(!sleepStatus);
                createKidDevelopmentDiaryEntry(childObj.kidId, 'sleep');
              }}
            >
              <i className='fa fa-folder' />
            </div>
          </>
        );
      case false:
        return (
          <>
            <span className={styles.statusOut}>
              {t('Checkinout.Awake')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <div
              className={styles.changeStatusCheckOut}
              onClick={() => {
                setSleepStatus(!sleepStatus);
                createKidDevelopmentDiaryEntry(
                  childObj.kidId,
                  'sleep',
                  null,
                  toUnixTimestamp(time),
                );
              }}
            >
              <i className='fa fa-folder-open' />
            </div>
          </>
        );
      default:
        return <span />;
    }
  };

  return (
    <div className={styles.wrapper} key={childObj.kidId}>
      <div className={styles.photo}>
        <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
      </div>
      <div className={styles.name}>
        {childObj.name}
        {childObj.parent1Name !== null && (
          <span className={styles.parent}>{childObj.parent1Name}</span>
        )}
        {childObj.parent2Name !== null && (
          <span className={styles.parent}>{childObj.parent2Name}</span>
        )}
      </div>
      <div className={styles.statusWrap}>
        {getStatus()}

        <CheckInOutTimePopup
          submit={handleSubmitFromTimePopup}
          isSaving={isSaving}
          handleCloseTimePopup={() => setTimePopupOpened(false)}
          timePopupOpened={timePopupOpened}
        />
        <Input type={'time'} value={time} onChange={e => handleTimeChange(e)} />

        <Button onClick={() => setTimePopupOpened(true)}>{t('Checkinout.AddNote')}</Button>
      </div>
      {todayComments && todayComments.length > 0 && (
        <div>
          <h5>{t('Checkinout.TodaysComments')}</h5>
          <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
            {todayComments.map(c => {
              return (
                <li>
                  {formatDateObjectToTimeString(c.date)} "{c.message}"{' '}
                  <a
                    onClick={() =>
                      prepareDelete(c.id, () => {
                        setTodayComments(todayComments.filter(c2 => c2.id !== c.id));
                      })
                    }
                  >
                    <i className='fa fa-trash' />
                  </a>
                  <a
                    onClick={() =>
                      prepareEdit(c, (newText, newDate) => {
                        const sortedTodayComments = todayComments.map(c2 => {
                          if (c2.id === c.id) {
                            return {
                              ...c2,
                              message: newText,
                              date: newDate,
                            };
                          } else return c2;
                        });
                        sortedTodayComments.sort(function(a, b) {
                          return new Date(a.date) - new Date(b.date);
                        });
                        setTodayComments(sortedTodayComments);
                      })
                    }
                    style={{ marginLeft: '5px' }}
                  >
                    <i className='fa fa-pencil' />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

ChildRow.propTypes = {
  childObj: PT.shape({
    kidId: PT.number.isRequired,
    name: PT.string.isRequired,
    photo: PT.string,
    parent1Name: PT.string,
    parent2Name: PT.string,
  }).isRequired,
};

export default ChildRow;
