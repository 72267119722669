import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  getGroupsForDashboardCalendar,
  getSelectedGroupByUser,
  canCreateEvent,
} from 'library/api/calendar';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Popup from 'library/common/commonComponents/Popups/Popup';
import CheckBox from 'library/common/commonComponents/Checkbox';
import ListItemWithLogoAvatar from 'library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';
import { getLetterInLogo } from 'library/utilities/groups';
import store from 'main/store/configureStore';

import styles from './calendarChoose.module.scss';

export default function CalendarChooseTarget({
  isOpened,
  closePopup,
  onNext,
  onEnable,
  calendarSettings,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [targetOptions, setTargetOptions] = useState([
    {
      value: '0',
      label: t('Calendar.Profile Calendar'),
      info: {
        ...calendarSettings,
        groupId: 0,
        publicPostStatus: true,
        visibility: false,
        editDeleteCalenderEntry: true,
      },
    },
  ]);
  const [selectedTargetOptions, setSelectedTargetOptions] = useState([]);
  const [showCreateEventNoPermissionPopup, setShowCreateEventNoPermissionPopup] = useState(false);
  const [checkboxSelectionStates, setCheckboxSelectionStates] = useState({});

  const updateSelectionState = (key, val) => {
    const newSelectionStates = { ...checkboxSelectionStates };
    newSelectionStates[key] = val;
    setCheckboxSelectionStates(newSelectionStates);
  };

  const addOrRemoveSelection = (option, id) => {
    const contained =
      option.value === '0'
        ? selectedTargetOptions.filter(to => to.info.groupId === 0).length > 0
        : selectedTargetOptions.filter(to => to.info.groupId === id).length > 0;

    if (contained) {
      setSelectedTargetOptions(
        option.value === '0'
          ? selectedTargetOptions.filter(to => to.info.groupId !== 0)
          : selectedTargetOptions.filter(to => to.info.groupId !== id),
      );
      updateSelectionState(id, false);
    } else {
      const newSelectedTargetOptions = selectedTargetOptions.slice();
      newSelectedTargetOptions.push(option);
      setSelectedTargetOptions(newSelectedTargetOptions);
      updateSelectionState(id, true);
    }
  };

  useEffect(() => {
    if (isOpened === 'choose') {
      setIsLoading(true);
      getGroupsForDashboardCalendar()
        .then(res => {
          console.log(res);
          if (res.status === 200) {
            const defaultOption = {
              value: '0',
              label: t('Calendar.Profile Calendar'),
              info: {
                ...calendarSettings,
                groupId: 0,
                publicPostStatus: true,
                visibility: false,
              },
            };
            const groupListOptions = res.data.map(item => ({
              value: `${item.groupId}`,
              label: item.groupName,
              info: item,
              colourCode: item.colourCode,
              groupLogoAspect: item.groupLogoAspect,
              groupLogoUrl: item.groupLogoUrl,
              groupLogoWidth: item.groupLogoWidth,
              groupLogoXCoordinate: item.groupLogoXCoordinate,
              groupLogoYCoordinate: item.groupLogoYCoordinate,
            }));
            let options = [defaultOption].concat(groupListOptions);
            options = options.filter(item => item.label.slice(-11) !== '(Portfolio)');
            options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
            setTargetOptions(options);
          }
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return (
    <>
      <Popup
        isOpened={isOpened === 'choose'}
        closePopup={closePopup}
        header={<Trans i18nKey='Calendar.Choose target calendar' />}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonSubmit}>
              <Button
                onClick={() => onNext(selectedTargetOptions.map(to => to.info))}
                type='primary'
              >
                {t('Calendar.Next')}
              </Button>
            </div>
            <div>
              <Button onClick={closePopup}>{t('Calendar.Cancel')}</Button>
            </div>
          </div>
        }
      >
        <div className={styles.container}>
          {isLoading ? (
            <Loader />
          ) : (
            <div className={styles.groupContainer}>
              {targetOptions.map(group => (
                <div
                  className={styles.groupElement}
                  style={{
                    border:
                      group.info.id in checkboxSelectionStates &&
                      checkboxSelectionStates[group.info.id]
                        ? '1px solid black'
                        : 'none',
                  }}
                  onClick={() => {
                    if (
                      group.value === '0' ||
                      (checkboxSelectionStates && checkboxSelectionStates[group.info.id])
                    ) {
                      addOrRemoveSelection(group, group.info.id);
                      return;
                    }
                    setIsLoading(true);
                    getSelectedGroupByUser(group.info.id).then(res => {
                      const groupListOptions = res.data.map(item => ({
                        value: `${item.groupId}`,
                        label: item.groupName,
                        info: item,
                      }));

                      if (!groupListOptions || groupListOptions.length === 0) {
                        setShowCreateEventNoPermissionPopup(true);
                      } else {
                        canCreateEvent(groupListOptions[0].info.groupId)
                          .then(evRes => {
                            if (!evRes.data === true) {
                              setShowCreateEventNoPermissionPopup(true);
                            } else {
                              addOrRemoveSelection(groupListOptions[0], group.info.id);
                            }
                          })
                          .catch(err => {
                            store.dispatch(
                              showBottomNotification(t('Current user cannot create event.'), {
                                isFail: true,
                              }),
                            );
                          });
                      }
                      setIsLoading(false);
                    });
                  }}
                >
                  <CheckBox
                    isChecked={
                      group.info.id in checkboxSelectionStates &&
                      checkboxSelectionStates[group.info.id]
                    }
                  />
                  <ListItemWithLogoAvatar
                    groupInfo={{
                      logoUrl: group.groupLogoUrl,
                      groupLogoXCoordinate: group.groupLogoXCoordinate,
                      groupLogoYCoordinate: group.groupLogoYCoordinate,
                      groupLogoWidth: group.groupLogoWidth,
                      groupLogoAspect: group.groupLogoAspect,
                    }}
                    letter={getLetterInLogo(group.label)}
                    backgroundColor={group.colourCode}
                    className={styles.groupLogo}
                  />
                  <div className={styles.groupName}>{group.label}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Popup>

      <Popup
        isOpened={showCreateEventNoPermissionPopup}
        closePopup={() => setShowCreateEventNoPermissionPopup(false)}
        footer={
          <div className={styles.buttonsContainer}>
            <div>
              <Button onClick={() => setShowCreateEventNoPermissionPopup(false)}>
                {t('Calendar.Cancel')}
              </Button>
            </div>
          </div>
        }
      >
        <div className={styles.containerEnable}>
          {t('Calendar.CreateEventInsufficientPermissionsText')}
        </div>
      </Popup>

      <Popup
        isOpened={isOpened === 'enable'}
        closePopup={closePopup}
        header={<Trans i18nKey='Calendar.Add profile calendar' />}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonSubmit}>
              <Button onClick={onEnable} type='primary'>
                {t('Calendar.Enable')}
              </Button>
            </div>
            <div>
              <Button onClick={closePopup}>{t('Calendar.Cancel')}</Button>
            </div>
          </div>
        }
      >
        <div className={styles.containerEnable}>{t('Calendar.In order to add event')}</div>
      </Popup>
    </>
  );
}
