import React, { useEffect, useState } from 'react';
import { getKidsByGroupIdAndArrival } from "library/api/kids";
import ArrivalRow from './ArrivalRow';
import Loader from 'library/common/commonComponents/Loader';

import { useTranslation } from 'react-i18next';

import styles from './arrivalContainer.module.scss';

const ArrivalContainer = ({ groupId }) => {

    const { t } = useTranslation();
    const [arrivalData, setArrivalData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        setIsLoading(true);
        getKidsByGroupIdAndArrival(groupId).then((res) => {
            setArrivalData(res.data);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    return (
        <div className={styles.historyTable}>
            <div className={styles.header}>
                <div className={styles.avatar} />
                <div className={styles.name}>{t('Checkinout.Name')}</div>
                <div className={styles.groups}>{t('Checkinout.Group')}</div>
                <div className={styles.goHome}>{t('Checkinout.CanGoHomeOwn')}</div>
                <div className={styles.goHome}>{t('Checkinout.Arrived')}</div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (arrivalData.length > 0 && arrivalData.map(item => {
                return (
                    <ArrivalRow
                        childObj={item}
                    />
                );
            }))}
        </div>
    );
}

export default ArrivalContainer;