import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import useUsersList from 'library/common/commonHooks/useUsersList';
import UserEditing from 'library/common/commonComponents/UserEditing/UserEditingContainer';

import UsersFrameListResults from './usersFrameListFrames/UsersFrameListResults';
import UserFrameDeletion from './usersFrameListFrames/UserFrameDeletion';
import { getKitaUserById } from '../../../../../../library/api/user';

export default function UsersFrameList({
  location: routerLocation,
  activeKita,
  history: routerHistory,
  superAdminStatus,
  setUserPermissions,
  setSelectedUsers,
  selectedUsers,
  loggedInUser,
}) {
  useEffect(changeRoute({ routerLocation, routerHistory }), [routerLocation.pathname]);

  const {
    users,
    togglePermission,
    loadPage,
    isLoading,
    history,
    location,
    totalUsersCount,
    searchValue,
    setSearchValue,
    setSorting,
    sort,
    selectedOption,
    setSelectedOption,
    options,
    editingProfile,
    setEditingProfile,
    deletionProfile,
    setDeletionProfile,
    kitas,
    selectedKita,
    setSelectedKita,
    falsePermissions,
    toggleAllPermissions,
    reloadPage,
  } = useUsersList({ activeKita, setUserPermissions });

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const queryChildId = query.get('editChild');
  const queryUserId = query.get('editUserId');
  const queryTargetId = query.get('parentId')
    ? query.get('parentId')
    : query.get('editUserId')
    ? query.get('editUserId')
    : null;

  const [queryChildIdState, setQueryChildIdState] = useState(
    query && queryChildId ? queryChildId : null,
  );
  const [queryUserIdState, setQueryUserIdState] = useState(
    query && queryUserId ? queryUserId : null,
  );

  useEffect(() => {
    if ((queryChildIdState || queryUserIdState) && users.length > 0) {
      getKitaUserById(selectedKita.value, parseInt(queryTargetId, 10)).then(res => {
        if (res && res.data) {
          setEditingProfile({
            ...res.data.user,
            adminRole: res.data.kitaRoles.roles.adminRole,
            employee: res.data.employee,
            qrCodeScanner: res.data.qrCodeScanner,
            ableToEditUserProfiles: res.data.ableToEditUserProfiles,
          });
          history.replace('/administration/users/list/1');
        }
      });
    }
  }, [users]);

  return editingProfile ? (
    <UserEditing
      isAdminEditing
      kitaId={selectedKita.value}
      activeKita={selectedKita}
      editingProfile={editingProfile}
      setEditingProfile={setEditingProfile}
      setDeletionProfile={setDeletionProfile}
      history={history}
      editChildId={queryChildIdState ? parseInt(queryChildIdState, 10) : null}
      setQueryChildIdState={setQueryChildIdState}
      setQueryUserIdState={setQueryUserIdState}
    />
  ) : deletionProfile ? (
    <UserFrameDeletion
      kitaId={selectedKita.value}
      deletionProfile={deletionProfile}
      setEditingProfile={setEditingProfile}
      setDeletionProfile={setDeletionProfile}
    />
  ) : (
    <UsersFrameListResults
      activeKita={activeKita}
      falsePermissions={falsePermissions}
      toggleAllPermissions={toggleAllPermissions}
      users={users}
      togglePermission={togglePermission}
      loadPage={loadPage}
      isLoading={isLoading}
      history={history}
      location={location}
      totalUsersCount={totalUsersCount}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setSorting={setSorting}
      sort={sort}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      options={options}
      setEditingProfile={setEditingProfile}
      setDeletionProfile={setDeletionProfile}
      kitas={kitas}
      selectedKita={selectedKita}
      setSelectedKita={setSelectedKita}
      superAdminStatus={superAdminStatus}
      reloadPage={reloadPage}
      loggedInUser={loggedInUser}
      setSelectedUsers={setSelectedUsers}
      selectedUsers={selectedUsers}
    />
  );
}

export function changeRoute({ routerLocation, routerHistory }) {
  return () => {
    const { pathname } = routerLocation;
    const path = 'list';
    const hasIndex = pathname.slice(pathname.indexOf(path) + path.length);
    if (hasIndex.length === 0) {
      routerHistory.push('/administration/users/list/1');
    }
  };
}
