import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Card from 'library/common/commonComponents/Card';
import Label from 'library/common/commonComponents/Label';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';

import styles from './searchUser.module.scss';

export default function SearchUser({ user }) {
  const { t } = useTranslation();
  return (
    <div>
      <Card>
        <div className={styles.container}>
          <Link to={`/profile/${user.id}`}>
            <div className={styles.userInfo}>
              <div className={styles.avatar}>
                <ProfileAvatar image={user} id={user.id} />
              </div>
              <div className='notranslate'>
                {user.firstName} {user.lastName}
              </div>
            </div>
          </Link>

          <Label type='default'>{t('Search.User')}</Label>
        </div>
      </Card>
    </div>
  );
}
