import React from 'react';
import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';
import store from 'main/store/configureStore';
import { Trans } from 'react-i18next';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

export const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;

export const getTaskLists = groupId =>
  axios.post(URL + `/taskLists/page`, {
    graphql:
      // eslint-disable-next-line max-len
      'id,taskListTitle,color,groupId,hideWhenCompleted,isTranslationAllowed,isCompleted,tasks[id,isTranslationAllowed,title,permanent,taskStatus,description,reviewRequired,userId,allDay,isPublic,start,end,timeZone,isScheduling,addToCalendar,firstName,lastName,taskListId,taskList[id,taskListTitle,color,groupId,hideWhenCompleted,isCompleted],assignedUsersList[id,firstName,lastName,logoUrl,profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect],responsibleUsersList[id,firstName,lastName,logoUrl,profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect],checklist[id,title,status],files[id,fileId,path,mimeType],likes[id,userId,firstName,lastName],comments[id,firstName,lastName,logoUrl,profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect,userId,text,likes[id,userId,firstName,lastName],files[id,fileId,path,mimeType]]]',
    page: 1,
    size: 50,
    sort: ['createdAt', 'desc'],
    filter: [`groupId:eq:${groupId}`],
  });

export const addTaskList = (groupId, payload) =>
  axios.post(URL + `/groups/${groupId}/taskLists`, payload);

export const updateTaskList = (taskListId, payload) =>
  axios.put(URL + `/taskLists/${taskListId}`, payload);

export const deleteTaskList = taskListId => axios.delete(URL + `/taskLists/${taskListId}`);

export const addTask = (groupId, payload) => {
  const formData = new FormData();
  const createdAt = new Date();
  formData.append('taskCreatedDate', createdAt.getTime());
  formData.append('taskUpdatedDate ', createdAt.getTime());
  let fileNumber = 1;
  const assignedUsersList = payload.assignedUsersList.length
    ? payload.assignedUsersList.reduce((string, user) => {
        return (string = string === '' ? `${user.id}` : string + ',' + user.id);
      }, '')
    : '';
  const responsibleUsersList = payload.responsibleUsersList.length
    ? payload.responsibleUsersList.reduce((string, user) => {
        return (string = string === '' ? `${user.id}` : string + ',' + user.id);
      }, '')
    : '';
  const filteredChecklist = payload.checklist.filter(item => item.title.trim() !== '');
  const checklistJSON = JSON.stringify(filteredChecklist);
  Object.keys(payload).forEach(key => {
    switch (key) {
      case 'title':
        return formData.append('title', payload[key]);
      case 'maxAssignedUserCount':
        return formData.append('maxAssignedUserCount', payload[key]);
      case 'taskListId':
        return formData.append('taskListId', payload[key]);
      case 'description':
        return formData.append('description', payload[key]);
      case 'isPublic':
        formData.append('privatePost', !payload[key]);
        formData.append('isPublic', payload[key]);
        return;
      case 'isScheduling':
        return formData.append('isScheduling', payload[key]);
      case 'allDay':
        return formData.append('allDay', payload.isScheduling ? payload[key] : true);
      case 'start':
        return formData.append('start', payload.isScheduling ? payload[key] || '' : '');
      case 'end':
        return formData.append('end', payload.isScheduling ? payload[key] || '' : '');
      case 'timeZone':
        return formData.append('timeZone', payload[key]);
      case 'addToCalendar':
        return formData.append('addToCalendar', payload.isScheduling && payload[key]);
      case 'assignedUsersList':
        return formData.append('assignedUsersList', assignedUsersList);
      case 'responsibleUsersList':
        return formData.append('responsibleUsersList', responsibleUsersList);
      case 'reviewRequired':
        return formData.append('reviewRequired', payload[key]);
      case 'checklist':
        return formData.append('checklist', checklistJSON);
      case 'files':
        payload[key].forEach(item => {
          formData.append(`file${fileNumber}`, item);
          fileNumber++;
        });
        return;
      default:
        return key;
    }
  });

  return axios
    .post(zuulURL + `/groups/${groupId}/tasks`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .catch(err => {
      store.dispatch(
        showBottomNotification(<Trans i18nKey={err.response.data.message} />, {
          isFail: true,
        }),
      );
    });
};

export const updateTask = (taskId, payload) => {
  const formData = new FormData();
  const updateddAt = new Date();
  formData.append('taskUpdatedDate ', updateddAt.getTime());
  let fileNumber = 1;
  const assignedUsersList =
    payload.assignedUsersList && payload.assignedUsersList.length
      ? payload.assignedUsersList.reduce((string, user) => {
          return (string = string === '' ? `${user.id}` : string + ',' + user.id);
        }, '')
      : '';
  const responsibleUsersList =
    payload.responsibleUsersList && payload.responsibleUsersList.length
      ? payload.responsibleUsersList.reduce((string, user) => {
          return (string = string === '' ? `${user.id}` : string + ',' + user.id);
        }, '')
      : '';
  const filteredChecklist =
    payload.checklist && payload.checklist.filter(item => item.title.trim() !== '');
  const checklistJSON = JSON.stringify(filteredChecklist);
  Object.keys(payload).forEach(key => {
    switch (key) {
      case 'title':
        return formData.append('title', payload[key]);
      case 'maxAssignedUserCount':
        return formData.append('maxAssignedUserCount', payload[key]);
      case 'taskListId':
        return formData.append('taskListId', payload[key]);
      case 'description':
        return formData.append('description', payload[key]);
      case 'isPublic':
        formData.append('privatePost', !payload[key]);
        formData.append('isPublic', payload[key]);
        return;
      case 'isScheduling':
        return formData.append('isScheduling', payload[key]);
      case 'allDay':
        return formData.append('allDay', payload.isScheduling ? payload[key] : true);
      case 'start':
        return formData.append('start', payload.isScheduling ? payload[key] || '' : '');
      case 'end':
        return formData.append('end', payload.isScheduling ? payload[key] || '' : '');
      case 'timeZone':
        return formData.append('timeZone', payload[key]);
      case 'addToCalendar':
        return formData.append('addToCalendar', payload.isScheduling && payload[key]);
      case 'assignedUsersList':
        return formData.append('assignedUsersList', assignedUsersList);
      case 'responsibleUsersList':
        return formData.append('responsibleUsersList', responsibleUsersList);
      case 'reviewRequired':
        return formData.append('reviewRequired', payload[key]);
      case 'checklist':
        return formData.append('checklist', checklistJSON);
      case 'deletedFileIds':
        return formData.append('deletedFileIds', payload[key].join(','));
      case 'files':
        payload[key].forEach(item => {
          formData.append(`file${fileNumber}`, item);
          fileNumber++;
        });
        return;
      default:
        return key;
    }
  });

  return axios.put(zuulURL + `/tasks/${taskId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteTask = taskId => axios.delete(URL + `/tasks/${taskId}`);

export const updateTaskStatus = (taskId, task) =>
  axios.put(URL + `/tasks/${taskId}/status?status=${task.taskStatus}`);

export const getTask = taskId =>
  axios.get(URL + `/tasks/${taskId}`, {
    params: {
      graphql:
        // eslint-disable-next-line max-len
        'addToCalendar,permanent,allDay,isTranslationAllowed,assignedUsersList,checklist,comments,description,end,files,firstName,id,isPublic,isScheduling,lastName,likes,notify,responsibleUsersList,reviewRequired,start,taskList[id,color,groupId,hideWhenCompleted,id,isCompleted,taskListTitle],taskListId,posts[id,permanent],taskStatus,timeZone,title,userId',
    },
  });

export const getTasks = groupId =>
  axios.post(URL + `/tasks/page`, {
    page: 1,
    size: 50,
    sort: ['createdAt', 'desc'],
    filter: [`groupId:eq:${groupId}`],
  });

export const assignTaskToUser = taskId => axios.post(URL + `/assigntasktouser/${taskId}`);

export const unassignTaskFromUser = taskId => axios.post(URL + `/unassigntaskfromuser/${taskId}`);
