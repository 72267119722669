import React from 'react';
import cn from 'classnames';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import {
  isImage,
  isVideo,
  getFileIconByName,
  previewOrDownloadFile,
} from 'library/utilities/files';
import * as filesApi from 'library/api/files';
import Button from 'library/common/commonComponents/Buttons/Button';
import EditFilePopup from 'library/common/commonComponents/Popups/EditFilePopup';
import useGallery from 'library/common/commonHooks/useGallery';
import PlayBlock from 'library/common/commonComponents/PlayBlock';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';

import styles from './feedItemFile.module.scss';

export default function FeedItemFile({
  file,
  isPublic,
  groupId,
  userId,
  userLanguage,
  isEditing,
  closePopup,
  updateFeedById,
  text,
  id,
  group,
  updateBreadcrumbs,
  isTranslationAllowed,
}) {
  const { history } = useReactRouter();
  const redirectToFiles = () => {
    if (file.folderPath) updateBreadcrumbs(file.folderPath);
    redirectToFilesHandler({ history, groupId, userId, userLanguage });
  };

  const shouldShowImage = isImage(file.fileId);
  const shouldShowVideo = isVideo(file.fileId);

  const { Gallery, openGallery } = useGallery([file]);
  const openFile = newFile => openFileHandler(shouldShowImage, newFile, openGallery);

  const format = file.fileId.slice(file.fileId.lastIndexOf('.'));

  const saveFile = newValues =>
    saveFileHandler({
      updateFile: filesApi.updateFile,
      newValues,
      closePopup,
      format,
      updateFeedById,
      id,
      file,
    });

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <EditFilePopup
        initialData={{
          ...file,
          fileId: file.fileId.slice(0, file.fileId.lastIndexOf('.')),
          isPublic,
          description: text,
        }}
        saveFile={saveFile}
        isOpened={isEditing}
        closePopup={closePopup}
        group={group}
      />
      <Gallery />
      {text &&
        text !== 'null' &&
        (isTranslationAllowed ? (
          <WithWatsonTranslate
            data={{ text, entityId: id, entityType: 'post with file' }}
            Component={({ html }) => (
              <div className={styles.text} dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
            )}
          />
        ) : (
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
        ))}
      {shouldShowImage && (
        <div onClick={() => openFile(file)}>
          <img src={file.path} className={styles.img} alt={file.fileId} />
        </div>
      )}
      {shouldShowVideo && (
        <div className={styles.video}>
          <PlayBlock file={file} />
        </div>
      )}
      <div>
        <div className={styles.info}>
          {!shouldShowImage && (
            <div className={styles.icon}>
              <i className={cn('fa', getFileIconByName(file.fileId))} />
            </div>
          )}
          <div>
            <div className={styles.name} onClick={() => openFile(file)}>
              {file.fileId}
            </div>
            <div className={styles.size}>
              {t('Post.Size')}: {file.size}
            </div>
          </div>
        </div>
        <Button size='sm' onClick={redirectToFiles}>
          {t('Post.Open file folder')}
        </Button>
      </div>
    </div>
  );
}

export const openFileHandler = (shouldShowImage, file, openGallery) => {
  if (shouldShowImage) {
    openGallery([file]);
  } else {
    previewOrDownloadFile(file);
  }
};

export const saveFileHandler = ({
  newValues,
  closePopup,
  format,
  updateFeedById,
  id,
  file,
  updateFile,
}) => {
  closePopup();

  const fileId = newValues.fileId + format;
  updateFeedById(id, {
    privatePost: !newValues.isPublic,
    text: newValues.description,
  });
  updateFeedById(
    id,
    {
      files: [
        {
          ...file,
          fileId,
        },
      ],
    },
    { withoutRequest: true },
  );

  if (newValues.fileId !== file.fileId) {
    updateFile('/' + file.id, {
      fileId,
    });
  }
};

export const redirectToFilesHandler = ({ history, groupId, userId, userLanguage }) =>
  history.push(
    groupId
      ? userLanguage === 'Deutsch'
        ? `/groups/${groupId}/dateien`
        : `/groups/${groupId}/files`
      : `/profile/${userId}/files`,
  );
