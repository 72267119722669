import React from 'react';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './breakButton.module.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export default function BreakButton({ active, handleClick, breakTime }) {
  const { t } = useTranslation();

  return (
    <div className={styles.breakButtonContainer}>
      <label>{t('EmployeeCheckinout.Pause')}</label>
      <div className={cn(styles.breakButtonWrapper, active && styles.breakButtonWrapperStarted)}>
        <Button
          className={styles.button}
          type={active ? 'danger' : 'primary'}
          onClick={handleClick}
        >
          {active ? (
            <i className='fa fa-stop' aria-hidden='true' />
          ) : (
            <i className='fa fa-play' aria-hidden='true' />
          )}
        </Button>
        <Input className={styles.timeInput} value={breakTime} disabled={true} />
      </div>
    </div>
  );
}
