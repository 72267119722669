import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreateKita from 'library/common/commonComponents/CreateKita/CreateKitaContainer';
import Button from 'library/common/commonComponents/Buttons/Button';
import TextCaret from 'library/common/commonComponents/TextCaret';
// eslint-disable-next-line
import ListItemWithLogoAvatar from 'library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import { timeSince } from 'library/utilities/date';
import DropdownInPortal from 'library/common/commonComponents/DropdownInPortal';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';

import styles from '../../kitaFrameList.module.scss';

export default function KitaFrameListRow({ kita, deactivateKita, superAdminStatus, user }) {
  const { t } = useTranslation();
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);

  const dropdownOptions = getDropdownOptions({
    t,
    kita,
    deactivateKita,
    setIsEditPopupOpened,
    superAdminStatus,
  });
  const [isOptionsDropdownOpened, setIsOptionsDropdownOpened] = useState(false);
  const outsideEventContainerRef = useOutsideEvent({
    click: true,
    handler: () => setIsOptionsDropdownOpened(false),
  });

  return (
    <tr>
      <td>
        <div className={styles.avatar}>
          {isEditPopupOpened && (
            <CreateKita closePopup={() => setIsEditPopupOpened(false)} isEditing fields={kita} />
          )}
          <ListItemWithLogoAvatar
            groupInfo={{
              logoUrl: kita.logoUrl,
              groupLogoXCoordinate: kita.kitaLogoXCoordinate,
              groupLogoYCoordinate: kita.kitaLogoYCoordinate,
              groupLogoWidth: kita.kitaLogoWidth,
              groupLogoAspect: 1,
            }}
            letter={kita.kitaName.trim()[0]}
            backgroundColor={kita.colorCode}
            className={styles.avatarBox}
          />
          <div className={styles.avatarText}>
            <div className={styles.groupName}>{kita.kitaName}</div>
            {kita.description && <div className={styles.groupDesc}>{kita.description}</div>}
          </div>
        </div>
      </td>
      <td>{kita.city}</td>
      <td className={styles.memberCount}>
        {timeSince({ date: kita.createdAt, langCode: user.langCode })}
      </td>
      {(!window.location.hostname.toLowerCase().includes('awobamberg.safe2connect.org') ||
        (window.location.hostname.toLowerCase().includes('awobamberg.safe2connect.org') &&
          superAdminStatus)) && (
          <td className={styles.moreBtn}>
            <Button
              isActive={isOptionsDropdownOpened}
              onClick={() => setIsOptionsDropdownOpened(true)}
              className={styles.buttonsMarginLeft}
          >
              <div ref={outsideEventContainerRef}>
                <i className='fa fa-cog' />
                <TextCaret />
              </div>
            </Button>
            <DropdownInPortal
              dropdownOptions={dropdownOptions}
              isOptionsDropdownOpened={isOptionsDropdownOpened}
              setIsOptionsDropdownOpened={setIsOptionsDropdownOpened}
              outsideEventContainerRef={outsideEventContainerRef}
          />
          </td>
      )}
    </tr>
  );
}

export function getDropdownOptions({
  t,
  kita,
  deactivateKita,
  setIsEditPopupOpened,
  superAdminStatus,
}) {
  return [
    {
      faIcon: 'fa-pencil',
      title: t('Post.Edit'),
      onClick: () => setIsEditPopupOpened(true),
    },
    {
      faIcon: 'fa-unlock',
      title: t('Administration.Kita.Deactivate'),
      onClick: () => deactivateKita(kita.id),
      isHidden: !superAdminStatus,
    },
  ];
}
