import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import GroupCalendar from '.';

export default connect(
  state => ({
    user: state.userReducer,
  }),
  { showBottomNotification },
)(GroupCalendar);
