import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import CheckBox from 'library/common/commonComponents/Checkbox';
import Label from 'library/common/commonComponents/Label';
import TagInput from 'library/common/commonComponents/Inputs/TagInput';
import styles from './createEditTaskAssig.module.scss';

export default function CreateEditTaskAssignment({
  createEditTaskState,
  createEditTaskdispatch,
  user,
  groupId,
}) {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const handleAssignUser = data => {
    if (createEditTaskState.error.assignedSuperAdmin) {
      createEditTaskdispatch({
        type: 'setError',
        payload: { ...createEditTaskState.error, assignedSuperAdmin: false },
      });
    }
    createEditTaskdispatch({ type: 'setAssignedUsersList', payload: data.users });
  };

  const handleResponseUser = data => {
    if (createEditTaskState.error.noResponseUser) {
      createEditTaskdispatch({
        type: 'setError',
        payload: { ...createEditTaskState.error, noResponseUser: false },
      });
    }
    createEditTaskdispatch({ type: 'setResponsibleUsersList', payload: data.users });
  };

  const handleSelectMe = action => {
    const userList =
      action === 'assigned'
        ? createEditTaskState.assignedUsersList
        : createEditTaskState.responsibleUsersList;
    const alreadyExist = userList.find(item => item.id === user.id);
    if (alreadyExist) {
      return;
    }
    const newUserList = userList.concat(user);
    if (action === 'assigned') {
      handleAssignUser({ users: newUserList });
    } else {
      handleResponseUser({ users: newUserList });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.asignedUsersContainer}>
        <Label type='input'>{t('Tasks.Assigned user(s)')}</Label>
        <TagInput
          value={{ users: createEditTaskState.assignedUsersList }}
          onDataChange={handleAssignUser}
          errors={
            createEditTaskState.error.assignedSuperAdmin
              ? { invite: t('Tasks.Super Admin cannot be assigned') }
              : { invite: '' }
          }
          groupId={groupId}
          isGroupMembersOnly
          showLabel={false}
        />
        <div className={styles.asignedUsersDesctiption}>
          {t('Tasks.Leave empty to let anyone work on this task')}
        </div>
        {!user.superAdminStatus && (
          <div className={styles.selectMeButton} onClick={() => handleSelectMe('assigned')}>
            <i className='fa fa-check-circle-o' />
            {t('Tasks.Select Me')}
          </div>
        )}
      </div>
      <div className={styles.responsibleUsersContainer}>
        <Label type='input'>{t('Tasks.Responsible user(s)')}</Label>
        <TagInput
          value={{ users: createEditTaskState.responsibleUsersList }}
          onDataChange={handleResponseUser}
          errors={
            createEditTaskState.error.noResponseUser
              ? { invite: t('Tasks.Responsible user(s) cannot be blank') }
              : { invite: '' }
          }
          showLabel={false}
          groupId={groupId}
          isGroupMembersOnly
        />
        <div className={styles.selectMeButton} onClick={() => handleSelectMe('responsible')}>
          <i className='fa fa-check-circle-o' />
          {t('Tasks.Select Me')}
        </div>
      </div>
      <div className={styles.checkboxContainer}>
        <CheckBox
          className={styles.checkbox}
          name={t('Tasks.Review by responsible user required')}
          isChecked={createEditTaskState.reviewRequired}
          onChange={() => createEditTaskdispatch({ type: 'setreviewRequired' })}
        />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.infoButton} onClick={() => setIsOpened(!isOpened)}>
          <i className='fa fa-info-circle' />
        </div>
        {isOpened && (
          <div className={styles.infoDescription}>
            <Trans i18nKey='Tasks.info' />
          </div>
        )}
      </div>
    </div>
  );
}
