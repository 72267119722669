import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isAwoWW } from 'library/api/tenantConfig';
import Select from 'library/common/commonComponents/Inputs/Select';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Label from 'library/common/commonComponents/Label';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Loader from 'library/common/commonComponents/Loader';

import styles from './calendarSettingsDefaults.module.scss';

export default function CalendarSettingsDefaults({
  submitiing,
  calendarSettingsState,
  calendarSettingsDispatch,
  onSubmit,
  calendarId,
  getSettings,
  calendarModulePlace,
}) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getSettings(calendarId, calendarModulePlace)
      .then(res => {
        switch (res.status) {
          case 200:
            calendarSettingsDispatch({
              type: 'setSettings',
              payload: {
                id: res.data.id,
                participationMode: res.data.participationMode,
                participationStateDeclineAllowed: res.data.participationStateDeclineAllowed,
                participationStateMaybeAllowed: res.data.participationStateMaybeAllowed,
                kidParticipationOptionAllowed: res.data.kidParticipationOptionAllowed,
                calendarDisplayedInStream: res.data.calendarDisplayedInStream,
                maxEventItems: res.data.maxEventItems,
                snippetShown: res.data.snippetShown,
                topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled:
                  res.data.topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled,
                upcomingEventsIntervalDays: res.data.upcomingEventsIntervalDays,
                calendarTypes: res.data.calendarTypes,
                enableBirthdayNotification: res.data.enableBirthdayNotification
              },
            });
            setParticipantOption(
              res.data.participationMode === 'EVERYBODY_CAN_PARTICIPATE' || res.data.participationMode === 'KIDS_CAN_PARTICIPATE'
                ? participantOptions[1]
                : participantOptions[0],
            );
            setIsLoading(false);
            break;
          default:
            setIsLoading(false);
        }
      })
      .catch(err => {
        if (err.status || err.response.status) {
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line
  }, [calendarId]);

  const { t } = useTranslation();
  const participantOptions = [
    { value: 'NO_PARTICIPANTS', label: t('Calendar.No participants') },
    { value: 'EVERYBODY_CAN_PARTICIPATE', label: t('Calendar.Everybody can participate') },
    { value: 'KIDS_CAN_PARTICIPATE', label: t('Calendar.Kids can participate') },
    { value: 'INVITED_PARTICIPANTS_ONLY', label: t('Calendar.Invited participants only') },
  ];

  const [participantOption, setParticipantOption] = useState(participantOptions[0]);

  useEffect(() => {
    const participationMode = calendarSettingsState.participationMode === 'EVERYBODY_CAN_PARTICIPATE'
      ? participantOptions[1]
      : calendarSettingsState.participationMode === 'KIDS_CAN_PARTICIPATE'
        ? participantOptions[2]
        : calendarSettingsState.participationMode === 'INVITED_PARTICIPANTS_ONLY' ? participantOptions[3] :participantOptions[0];
    setParticipantOption(participationMode);
  }, [calendarSettingsState.participationMode]);

  const setParticipants = option => {
    calendarSettingsDispatch({ type: 'setCanParticipate', payload: option });
    setParticipantOption(option);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.titleHeader}>{t('Calendar.Default event settings')}</div>
        <div className={styles.tittleDescription}>
          {t('Calendar.Here you can configure default settings calendar')}
        </div>
      </div>
      <div className={styles.participationModeContainer}>
        <Label type='input'>{t('Calendar.Participation Mode')}</Label>
        <Select
          options={participantOptions}
          onSelect={setParticipants}
          selected={participantOption}
        />
      </div>
      {participantOption.value === 'EVERYBODY_CAN_PARTICIPATE' && (
        <div className={styles.checkboxContainer}>
          <CheckBox
            className={styles.checkbox}
            name={t('Calendar.Allow decline')}
            isChecked={calendarSettingsState.participationStateDeclineAllowed}
            onChange={() => calendarSettingsDispatch({ type: 'setAllowDecline' })}
          />
          <CheckBox
            className={styles.checkbox}
            name={t('Calendar.Allow maybe')}
            isChecked={calendarSettingsState.participationStateMaybeAllowed}
            onChange={() => calendarSettingsDispatch({ type: 'setAllowMaybe' })}
          />
        </div>
      )}
      {isAwoWW() && (
        <div className={styles.checkboxContainer}>
          <CheckBox
            className={styles.checkbox}
            name={t('Calendar.Allow Kid Participation Option')}
            isChecked={calendarSettingsState.kidParticipationOptionAllowed}
            onChange={() => calendarSettingsDispatch({ type: 'setAllowKidParticipationOption' })}
          />
        </div>
      )}
      <div className={styles.showInStreanCheckboxContainer}>
        <CheckBox
          className={styles.checkbox}
          name={t('Calendar.Show calendar entries in stream')}
          isChecked={calendarSettingsState.calendarDisplayedInStream}
          onChange={() => calendarSettingsDispatch({ type: 'setShowInStream' })}
        />
      </div>
      {
        calendarModulePlace === 'kitaCalendar' && (
          <>
            <div className={styles.participationModeContainer}>
              <Label type='input'>{t('Calendar.BirthdayNotification Mode')}</Label>
            </div>
            <div className={styles.showInStreanCheckboxContainer}>
              <CheckBox
                className={styles.checkbox}
                name={t('Calendar.EnableBirthdayNotification')}
                isChecked={calendarSettingsState.enableBirthdayNotification}
                onChange={() => calendarSettingsDispatch({ type: 'setEnableBirthdayNotification' })}
              />
            </div>
          </>
        )
      }
      <div className={styles.buttonContainer}>
        <ButtonWithLoader onClick={onSubmit} type='primary' isLoading={submitiing}>
          {t('Calendar.Save')}
        </ButtonWithLoader>
      </div>
    </div>
  );
}
