import React from 'react';
import classNames from 'classnames';
import './card-heading.module.css';

export default function CardHeading({children,className}) {
    return(
      <div className={classNames('card_heading_text',className)}>
        {children}
      </div>
    )
}