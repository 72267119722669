import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Loader from 'library/common/commonComponents/Loader';
import { resendInvitation, deleteInvitation } from 'library/api/user';
import { timeSince } from 'library/utilities/date';
import Tooltip from 'library/common/commonComponents/Tooltip';
import styles from '../../usersFrameRegistration.module.scss';

export default function UsersFrameRegistrationRow({
  activeKita,
  user,
  showBottomNotification,
  reloadPage,
}) {
  const { t } = useTranslation();

  const [isInvitingLoading, setIsInvitingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);

  const resendInvite = () =>
    resendInviteHandler({
      kitaId: activeKita.kitaId,
      userId: user.user.id,
      showBottomNotification,
      t,
      setIsInvitingLoading,
      reloadPage,
    });

  const deleteInvite = () =>
    deleteInviteHandler({
      kitaId: activeKita.kitaId,
      userId: user.user.id,
      showBottomNotification,
      t,
      setIsDeletingLoading,
      reloadPage,
    });

  return (
    <tr>
      <td>
        <div className={styles.avatar}>
          <div className={styles.avatarText}>
            <div className={cn(styles.groupName, 'notranslate')}>{user.user.email}</div>
            {user.user.description && (
              <div className={styles.groupDesc}>{user.user.description}</div>
            )}
          </div>
        </div>
      </td>
      <td>
        {user.registrationCreatedByFirstName} {user.registrationCreatedByLastName}
      </td>
      <td>{user.user.language.langCode}</td>
      <td className={styles.memberCount}>
        {timeSince({ date: user.createdAt, langCode: user.langCode })}
      </td>
      <td className={styles.memberCount}>
        {user.lastInvitedAt ?
          timeSince({ date: user.lastInvitedAt, langCode: user.langCode })
          : ''
        }
      </td>
      <td className={styles.iconWrapper}>
        <div className={styles.iconContainer} onClick={resendInvite}>
          {isInvitingLoading ? (
            <Loader dotSize='7px' />
          ) : (
            <Tooltip text={t('Administration.UserSection.Send invite again')} isVisibleCondition>
              <div className={styles.icon}>
                <i className='fa fa-envelope' />
              </div>
            </Tooltip>
          )}
        </div>

        <div className={styles.iconContainer} onClick={deleteInvite}>
          {isDeletingLoading ? (
            <Loader dotSize='7px' />
          ) : (
            <Tooltip text={t('Administration.UserSection.Cancel invite')} isVisibleCondition>
              <div className={styles.icon}>
                <i className='fa fa-trash' />
              </div>
            </Tooltip>
          )}
        </div>
      </td>
    </tr>
  );
}

export async function resendInviteHandler({
  kitaId,
  userId,
  showBottomNotification,
  t,
  setIsInvitingLoading,
  reloadPage,
}) {
  try {
    setIsInvitingLoading(true);
    await resendInvitation({ kitaId, userId });
    showBottomNotification(t('Administration.UserSection.Invite was sent'));
    reloadPage();
  } catch (ex) {
    showBottomNotification(t('BottomNotifications.Something went wrong'), { isFail: true });
  } finally {
    setIsInvitingLoading(false);
  }
}

export async function deleteInviteHandler({
  kitaId,
  userId,
  showBottomNotification,
  t,
  setIsDeletingLoading,
  reloadPage,
}) {
  try {
    setIsDeletingLoading(true);
    await deleteInvitation({ kitaId, userId });
    showBottomNotification(t('Administration.UserSection.Invite was deleted'));
    reloadPage();
  } catch (ex) {
    showBottomNotification(t('BottomNotifications.Something went wrong'), { isFail: true });
  } finally {
    setIsDeletingLoading(false);
  }
}
