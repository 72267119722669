import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'library/common/commonComponents/Loader';
import Card from 'library/common/commonComponents/Card';
import Tabs from 'library/common/commonComponents/Tabs';

import TaskLists from './TaskListsMainFrames/TaskLists';

import styles from './taskListsComponent.module.scss';

export default function TaskListsMain({
  isOpenedByDefault = [],
  openedTasksByDefault = [],
  isLoading,
  taskLists,
  onDeleteTaskList,
  onAddTaskList,
  onUpdateTaskList,
  isAdmin,
  manageTaskLists,
  manageTasks,
  proceedUnassignedTask,
  onAddTask,
  onDeleteTask,
  onUpdateTask,
  onUpdateTaskStatus,
  canAddToCalendar,
  isLikeFeatureAvailableInGroup
}) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      title: t('Tasks.Lists'),
      path: 'lists',
      component: isLoading ? (
        <Loader />
      ) : (
        <TaskLists
          openedTasksByDefault={openedTasksByDefault}
          isOpenedByDefault={isOpenedByDefault}
          isLoading={isLoading}
          taskLists={taskLists}
          onDeleteTaskList={onDeleteTaskList}
          onAddTaskList={onAddTaskList}
          onUpdateTaskList={onUpdateTaskList}
          onAddTask={onAddTask}
          onDeleteTask={onDeleteTask}
          onUpdateTask={onUpdateTask}
          onUpdateTaskStatus={onUpdateTaskStatus}
          isAdmin={isAdmin}
          manageTaskLists={manageTaskLists}
          manageTasks={manageTasks}
          proceedUnassignedTask={proceedUnassignedTask}
          canAddToCalendar={canAddToCalendar}
          isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
        />
      ),
    },
  ];

  return (
    <Card className={styles.container}>
      <Tabs
        tabs={tabs}
        onChange={(_, index) => setActiveTab(index)}
        currentTab={tabs[activeTab].path}
      />
    </Card>
  );
}
