import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Loader from 'library/common/commonComponents/Loader';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Button from 'library/common/commonComponents/Buttons/Button';
import Card from 'library/common/commonComponents/Card';
import Row from 'library/common/commonComponents/Row';
import Pagination from 'library/common/commonComponents/Pagination';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Notification from 'library/common/commonComponents/Notification/index';
import useNotificationsLoading from 'library/common/commonHooks/useNotificationsLoading';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import * as notificationsActions from 'library/common/commonActions/notificationsActions';
import * as notificationsApi from 'library/api/notifications';
import styles from './notifications.module.scss';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';

function NotificationsModule({
  history,
  match,
  updateCountOfNotifications,
  updateCountOfTaxReceipt,
  unreadNotificationsCount,
  activeKita,
  kitas,
  user,
}) {
  useSiteTitle('Notifications');
  const [checkbox, setCheckbox] = useState({
    COMMENT: true,
    ADMINISTRATIVE: true,
    CALENDAR: true,
    MESSAGE: true,
    CONVERSATIONS: true,
    TASK: true,
    TASK_REMINDER: true,
    NEW_CONTENT: true,
    FRIENDSHIP: true,
    LIKE: true,
    SPACE_MEMBERSHIP: true,
    FOLLOWING: true,
    MENTIONING: true,
  });

  const [unreadNotificationsActiveKita, setUnreadNotificationsActiveKita] = useState(0);

  const [filters, setFilters] = useState(checkbox);
  const onFilterSave = () => {
    history.push(match.path.replace(':page', 1));
    setFilters(checkbox);
  };

  const setIsChecked = key => () =>
    setCheckbox({
      ...checkbox,
      [key]: !checkbox[key],
    });

  const {
    isLoading,
    notifications,
    loadPage,
    totalNotificationsCount,
    markAllAsRead,
    readNotification,
  } = useNotificationsLoading({
    kitaId: activeKita ? activeKita.kitaId : '',
    isOpened: true,
    filters,
    unreadNotificationsCount,
    updateCountOfNotifications,
    updateCountOfTaxReceipt,
    activeKita,
    kitas,
    user,
  });

  const [finalNotifications, setFinalNotifications] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setFinalNotifications(
      notifications.map(obj => {
        return { ...obj, isChecked: selectAll ? true : false }; // Adding a new property isChecked to each object
      }),
    );
    setSelectAll(false);
  }, [notifications]);

  const toggleDeleteCheckBox = id => {
    const updatedArray = finalNotifications.map(obj => {
      if (obj.id === id) {
        return { ...obj, isChecked: !obj.isChecked };
      }
      return obj;
    });
    setFinalNotifications(updatedArray);
    setSelectAll(updatedArray.every(obj => obj.isChecked));
  };

  const toggleAllCheckboxes = () => {
    const updatedArray = finalNotifications.map(obj => {
      return { ...obj, isChecked: !selectAll };
    });
    setFinalNotifications([...updatedArray]);
    setSelectAll(!selectAll);
  };

  const deleteNotifications = () => {
    const checkedNotifications = finalNotifications
      .filter(item => item.isChecked === true)
      .map(element => {
        return element.id;
      });
    notificationsApi.deleteNotifications(checkedNotifications).then(() => {
      store.dispatch(
        showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
          isFail: false,
        }),
        loadPage(),
      );
      //window.location.reload()
    });
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (activeKita && unreadNotificationsCount) {
      setUnreadNotificationsActiveKita(
        unreadNotificationsCount[activeKita.kitaId] &&
          unreadNotificationsCount[activeKita.kitaId].count,
      );
    }
  }, [activeKita, unreadNotificationsCount]);

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <Row className={styles.row}>
          <Card
            className={styles.contentSide}
            title={
              <div className={styles.header}>
                <div>
                  <Trans i18nKey='Header.Topbar.Notifications.Notification Overview' />
                </div>
                {notifications && notifications.length > 0 ? (
                  <Button
                    type='primary'
                    className={styles.deleteNotification}
                    onClick={deleteNotifications}
                  >
                    <i class='fa fa-trash' />
                  </Button>
                ) : (
                  <></>
                )}
                {unreadNotificationsActiveKita > 0 && (
                  <div onClick={markAllAsRead} className={styles.headerMarkAll}>
                    <Trans i18nKey='Header.Topbar.Notifications.Mark all as read' />
                  </div>
                )}
              </div>
            }
          >
            <div className={styles.notificationContainer}>
              {notifications && notifications.length > 0 ? (
                <div className={styles.deleteCheckbox}>
                  <CheckBox isChecked={selectAll} onChange={toggleAllCheckboxes} />
                  <span className={styles.selectAllNotif}>{t('FoodOrder.Select all')}</span>
                </div>
              ) : (
                <></>
              )}
              {isLoading ? (
                <Loader className={styles.loader} />
              ) : (
                finalNotifications.map(notification => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    readNotification={readNotification}
                    user={user}
                    toggle={toggleDeleteCheckBox}
                    checkbox={true}
                  />
                ))
              )}
            </div>
            {!isLoading &&
              (notifications.length > 0 ? (
                <Pagination
                  currentPage={parseInt(match.params.page, 10)}
                  history={history}
                  numberOfPages={
                    totalNotificationsCount ? Math.ceil(totalNotificationsCount / 10) : 1
                  }
                  onPageClick={loadPage}
                />
              ) : (
                <div className={styles.empty}>You have no notifications</div>
              ))}
          </Card>
          <Card className={styles.widgetSide} title='Filter'>
            <div className={styles.filterContainer}>
              <CheckBox
                className={styles.checkbox}
                name={<Trans i18nKey='Notifications.Administrative' />}
                isChecked={checkbox.ADMINISTRATIVE}
                onChange={setIsChecked('ADMINISTRATIVE')}
              />
              <CheckBox
                className={styles.checkbox}
                name={<Trans i18nKey='Notifications.Tasks' />}
                isChecked={checkbox.TASK}
                onChange={setIsChecked('TASK')}
              />
              <CheckBox
                className={styles.checkbox}
                name={<Trans i18nKey='Notifications.Comments' />}
                isChecked={checkbox.COMMENT}
                onChange={setIsChecked('COMMENT')}
              />
              <CheckBox
                className={styles.checkbox}
                name={<Trans i18nKey='Notifications.New content' />}
                isChecked={checkbox.NEW_CONTENT}
                onChange={setIsChecked('NEW_CONTENT')}
              />
              <CheckBox
                className={styles.checkbox}
                name={<Trans i18nKey='Notifications.Friendship' />}
                isChecked={checkbox.FRIENDSHIP}
                onChange={setIsChecked('FRIENDSHIP')}
              />
              <CheckBox
                className={styles.checkbox}
                name={<Trans i18nKey='Notifications.Likes' />}
                isChecked={checkbox.LIKE}
                onChange={setIsChecked('LIKE')}
              />
              <CheckBox
                className={styles.checkbox}
                name={<Trans i18nKey='Notifications.Mentionings' />}
                isChecked={checkbox.MENTIONING}
                onChange={setIsChecked('MENTIONING')}
              />
              <br />
              <Button type='primary' onClick={onFilterSave} className={styles.saveFilterButton}>
                <Trans i18nKey='Notifications.Apply' />
              </Button>
            </div>
          </Card>
        </Row>
      </Wrapper>
    </KitaPanelWrapper>
  );
}

NotificationsModule.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      page: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(
  state => ({
    unreadNotificationsCount: state.notificationsReducer.unreadNotificationsCount,
    unreadTaxReceiptCount: state.notificationsReducer.unreadTaxReceiptCount,
    kitas: state.kitaReducer.kitas,
    activeKita: state.kitaReducer.activeKita,
    user: state.userReducer,
  }),
  {
    updateCountOfNotifications: notificationsActions.updateCountOfNotifications,
    updateCountOfTaxReceipt: notificationsActions.updateCountOfTaxReceipt,
  },
)(NotificationsModule);
