import React from 'react';
import Button from 'library/common/commonComponents/Buttons/Button';
import { useTranslation } from 'react-i18next';
import styles from './groupPrivate.module.scss';

export default function GroupPrivate({ history }) {
  const { t } = useTranslation();
  return (
    <div className={styles.privateGroup}>
      <div className={styles.privateGroup__heading}>{t('GroupBody.Private.Oooops')}</div>
      <div className={styles.privateGroup__content}>
        {t('GroupBody.Private.Group is invisible!')}
      </div>
      <div className={styles.privateGroup__footer}>
        <Button
          type='primary'
          onClick={() => history.push('/')}
          className={styles.saveFilterButton}
        >
          {t('GroupBody.Private.Back to dashboard')}
        </Button>
      </div>
    </div>
  );
}
