import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Select from 'library/common/commonComponents/Inputs/Select';
import HelpText from 'library/common/commonComponents/HelpText';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { putGroupSecurity, setGlobalGroupConfiguration } from 'library/api/groups';
import { GroupContext } from 'modules/Group/groupModule';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { getJoinPolicies } from 'library/common/commonActions/groupActions';

import styles from './groupBodySecurityGeneral.module.scss';

export function GroupBodySecurityGeneral({
  showBottomNotificationFromProps,
  isGlobal,
  reloadGlobalGroupInfo,
  activeKita,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const visibilitySelectOptions = [
    { value: true, label: t('GroupBody.Security.Private (Invisible)') },
    { value: false, label: t('GroupBody.Security.Public (Registered users only)') },
  ];

  const contentVisibilitySelectOptions = [
    { value: false, label: t('GroupBody.Security.Private') },
    { value: true, label: t('GroupBody.Security.Public') },
  ];
  if (!isGlobal) {
    contentVisibilitySelectOptions.unshift({
      value: activeKita.defaultGlobalGroupContentVisibility,
      label: activeKita.defaultGlobalGroupContentVisibility
        ? t('GroupBody.Security.Default (Public)')
        : t('GroupBody.Security.Default (Private)'),
    });
  }
  const groupContext = useContext(GroupContext);
  const { groupInfo, reloadGroupInfo } = groupContext;

  const [visibility, setVisibility] = useState(visibilitySelectOptions[0]);

  const [joinPolicy, setJoinPolicy] = useState(null);
  const [joinPolicySelectOptions, setJoinPolicySelectOptions] = useState();
  const [, setFetchedJOinPolicy] = useState();
  const [contentVisibility, setContentVisibility] = useState(contentVisibilitySelectOptions[0]);

  const onVisibilityChanged = e => {
    setVisibility(visibilitySelectOptions.find(item => item.value === e.value));
    if (e.value) {
      setContentVisibility(contentVisibilitySelectOptions.find(op => op.value === false));
      setJoinPolicy(joinPolicySelectOptions.find(item => item.defaultVisibility === 'private'));
    } else {
      setContentVisibility(contentVisibilitySelectOptions.find(op => op.value === true));
      setJoinPolicy(joinPolicySelectOptions.find(item => item.defaultVisibility === 'public'));
    }
  };
  useEffect(() => {
    getJoinPolicies({}).then(data => {
      const options = data.map(item => ({
        id: item.id,
        label: item.policyName,
        defaultVisibility: item.defaultVisibility,
      }));
      setJoinPolicySelectOptions(options);
      const option = options.find(item => item.id === groupInfo.groupJoinPolicy.id);
      if (option) {
        setJoinPolicy(option);
      }
      setFetchedJOinPolicy(data);
    });
    setVisibility(visibilitySelectOptions.find(item => item.value === groupInfo.visibility));
    setContentVisibility(
      groupInfo.defaultContentVisibilitySelected
        ? contentVisibilitySelectOptions[0]
        : groupInfo.contentVisibility
        ? contentVisibilitySelectOptions[contentVisibilitySelectOptions.length - 1]
        : contentVisibilitySelectOptions[contentVisibilitySelectOptions.length - 2],
    );
    // eslint-disable-next-line
  }, [groupInfo]);

  const onSave = async () => {
    if (isSubmitting) {
      return;
    }

    if (!joinPolicy) {
      showBottomNotificationFromProps(
        // t('GroupBody.Security.Please select join policy')
        'Please select join policy',
        { isWarning: true },
      );
      return;
    }
    setIsSubmitting(true);
    const data = {
      contentVisibility: contentVisibility.value,
      groupJoinpolicyId: joinPolicy.id,
      groupId: groupInfo.groupId,
      visibility: visibility.value,
      defaultContentVisibilitySelected:
        contentVisibility.label === t('GroupBody.Security.Default (Private)') ||
        contentVisibility.label === t('GroupBody.Security.Default (Public)'),
      groupContentVisibility: contentVisibility.value,
      groupVisibility: visibility.value,
    };

    (isGlobal ? setGlobalGroupConfiguration : putGroupSecurity)(data)
      .then(() => {
        if (reloadGroupInfo) reloadGroupInfo();
        if (reloadGlobalGroupInfo) {
          reloadGlobalGroupInfo({
            visibility: visibility.value,
            contentVisibility: contentVisibility.value,
            groupJoinPolicy: joinPolicy.id,
          });
        }
        showBottomNotificationFromProps(t('GroupBody.Security.Saved'));
      })
      .catch(() =>
        showBottomNotificationFromProps(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        }),
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <div className={styles.container}>
      {isGlobal && (
        <>
          <div className={styles.titleBig}>{t('Administration.SpacesSection.Group settings')}</div>
          <div className={styles.text}>
            <HelpText>{t('Administration.SpacesSection.Group settings text')}</HelpText>
          </div>
        </>
      )}

      <div className={styles.title}>{t('GroupBody.Security.Visibility')}</div>
      <Select
        options={visibilitySelectOptions}
        onSelect={onVisibilityChanged}
        selected={visibility}
      />
      <HelpText className={styles.selectText}>
        {t('GroupBody.Security.Choose the security')}
      </HelpText>

      <div className={styles.title}>{t('GroupBody.Security.Join Policy')}</div>
      <Select
        options={joinPolicySelectOptions}
        onSelect={setJoinPolicy}
        selected={joinPolicy}
        disabled={visibility.value}
      />
      <HelpText className={styles.selectText}>{t('GroupBody.Security.Choose membership')}</HelpText>

      <div className={styles.title}>{t('GroupBody.Security.Default content visibility')}</div>
      <Select
        options={contentVisibilitySelectOptions}
        onSelect={setContentVisibility}
        selected={contentVisibility}
        disabled={/*visibility.value*/true}
      />
      <HelpText className={styles.selectText}>
        {t('GroupBody.Security.Choose new or public')}
      </HelpText>

      <ButtonWithLoader type='primary' onClick={onSave} isLoading={isSubmitting}>
        {t('GroupBody.Security.Save')}
      </ButtonWithLoader>
    </div>
  );
}

const mapDispatchToProps = {
  showBottomNotificationFromProps: showBottomNotification,
};
export default connect(
  null,
  mapDispatchToProps,
)(GroupBodySecurityGeneral);
