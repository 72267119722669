import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import * as moment from 'moment';

import { logout } from 'library/api/logout';
import Pagination from 'library/common/commonComponents/Pagination';
import ListItemElement from 'library/common/commonComponents/ListItems/ListItem/listItemFrames/ListItemElement';
import Loader from 'library/common/commonComponents/Loader';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Card from 'library/common/commonComponents/Card';
import { searchPosts } from 'library/api/posts';
import FeedItem from 'library/common/commonComponents/Feed/feedFrames/feedItem';
import {
  updateFeedByIdHandler,
  deleteFeedByIdHandler,
} from 'library/common/commonComponents/Feed/hooks/useFeedsLoading';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import { downloadSearchPattern, uploadSearchPattern, getSearchPattern } from 'library/api/kita';
import SearchUser from './searchFrames/SearchUser';
import SearchGroup from './searchFrames/SearchGroup';

import styles from './searchModule.module.scss';
import SearchHeader from './searchFrames/SearchHeader';
import { isPublicUser } from 'library/utilities/user';
import { isAwoWW } from 'library/api/tenantConfig';

export default function SearchModule({ showBottomNotification, match, history, user }) {
  const { t } = useTranslation();
  useSiteTitle('Search');

  const [isLoading, setIsLoading] = useState();
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [canShowFeed, setCanShowFeed] = useState(false);
  const [page, setPage] = useState(1);
  const [counts, setCounts] = useState({
    group: 0,
    post: 0,
    category: 0,
  });
  const [category, setCategory] = useState('all');

  const [selectedGroups, setSelectedGroups] = useState({});
  const [prevSearchValue, setPrevSearchValue] = useState(null);

  const [calendarState, setCalendarState] = useState(false);
  const [postState, setPostState] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [from, setFrom] = useState(new Date().setFullYear(2018, 0, 1));
  const [to, setTo] = useState(new Date());
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [searchPattern, setSearchPattern] = useState(null);

  const totalElementsCount = Object.values(counts).reduce((a, b) => a + b, 0);
  const elementsCount = category === 'all' ? totalElementsCount : counts[category];

  const tokenRef = useRef(null);

  const setCalenderValue = data => {
    setCalendarState(data);
  };

  const setPostValue = data => {
    setPostState(data);
  };

  const onSubmit = () =>
    onSubmitHandler({
      searchValue,
      selectedGroups,
      calendarState,
      postState,
      userInfo,
      from,
      to,
      setCanShowFeed,
      setResults,
      setIsLoading,
      setCounts,
      page,
      category,
      prevSearchValue,
      setPrevSearchValue,
      setCategory,
      tokenRef,
      pattern: searchPattern?.pattern,
    });

  const onDownload = async () => {
    setIsDownloading(true);
    try {
      const { data } = await downloadSearchPattern({
        search: searchValue,
        from: moment(from).format('YYYY-MM-DD'),
        to: moment(to).format('YYYY-MM-DD'),
      });
      const blob = new Blob([data], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const dateFormat = moment(new Date()).format('DDMMYYYY');
      link.setAttribute('download', `AWO-Suche_${dateFormat}.txt`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const onUpload = event => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    setIsUploading(true);
    uploadSearchPattern(data)
      .then(({ data: res }) => {
        setSearchPattern(res);
        setSearchValue(res.search);
        onSubmit();
        showBottomNotification(t('BottomNotifications.Success'));
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  useEffect(() => {
    const fetchSearchPattern = async () => {
      const data = (await getSearchPattern()).data;
      if (data) {
        setSearchPattern(data);
        setSearchValue(data.search);
      }
    };
    fetchSearchPattern();
  }, []);

  useEffect(() => {
    if (searchPattern) {
      if (searchPattern.startDate) {
        setFrom(new Date(searchPattern.startDate));
      }
      if (searchPattern.endDate) {
        setTo(new Date(searchPattern.endDate));
      }
    }
  }, [searchPattern]);

  useEffect(() => {
    onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setPage(1);
    onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const dispatch = ({ type, id, newValues }) =>
    dispatchHandler({ type, id, newValues, results, setResults, counts, setCounts });

  const updateFeedById = async (id, newValues, options = {}) =>
    updateFeedByIdHandler({ options, id, newValues, dispatch, t });

  const deleteFeedById = id => deleteFeedByIdHandler({ id, dispatch, t });

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <SearchHeader
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSubmit={onSubmit}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          calendarStateParent={setCalenderValue}
          postStateParent={setPostValue}
          usersInfo={setUserInfo}
          setFromDate={setFrom}
          setToDate={setTo}
          user={user}
          onDownload={onDownload}
          onUpload={onUpload}
          isDownloading={isDownloading}
          isUploading={isUploading}
          searchPattern={searchPattern}
        />
        <Col>
          <Row>
            <div className={styles.wrapper}>
              {canShowFeed && totalElementsCount > 0 && (
                <div className={styles.menu}>
                  <Card title={<Trans i18nKey='Search.Search results' />}>
                    <ListItemElement
                      title={`${t('Search.All')} (${totalElementsCount})`}
                      onClick={() => setCategory('all')}
                      isActive={category === 'all'}
                      theme='light'
                      className={cn(styles.listItem, styles.listItemFirst)}
                    />
                    <ListItemElement
                      title={`${t('Search.Content')} (${counts.post})`}
                      onClick={() => setCategory('post')}
                      isActive={category === 'post'}
                      theme='light'
                      className={styles.listItem}
                    />
                    <ListItemElement
                      title={`${t('Search.Users')} (${counts.user})`}
                      onClick={() => setCategory('user')}
                      isActive={category === 'user'}
                      theme='light'
                      className={styles.listItem}
                    />
                    <ListItemElement
                      title={`${t('Search.Groups')} (${counts.group})`}
                      onClick={() => setCategory('group')}
                      isActive={category === 'group'}
                      theme='light'
                      className={styles.listItem}
                    />
                  </Card>
                </div>
              )}
              <div className={styles.body}>
                {isLoading && <Loader />}

                {canShowFeed && !isLoading && results.length > 0 ? (
                  <>
                    {isPublicUser(user) && (
                      <Card className={styles.noMatches}>
                        <div className={styles.warningWrapper}>
                          <a
                            className={styles.authButton}
                            onClick={e => {
                              e.preventDefault();
                              logout();
                            }}
                            href='/'
                          >
                            <i className='fa fa-sign-in' aria-hidden='true' />{' '}
                            {t('Dashboard.Login')}
                          </a>
                          <a href='/loggedinregistration'>
                            <i className='fa fa-user-plus' /> {t('Dashboard.Register now')}
                          </a>
                        </div>
                      </Card>
                    )}
                    {results.map(result =>
                      result.postType !== undefined ? (
                        <FeedItem
                          match={match}
                          key={result.id}
                          {...result}
                          updateFeedById={updateFeedById}
                          onDelete={() => deleteFeedById(result.id)}
                          user={user}
                          canBePublic
                          group={result.group}
                          showBottomNotification={showBottomNotification}
                          isOnDashboard
                          showKitaInfo={isAwoWW()}
                        />
                      ) : result.groupName ? (
                        <SearchGroup group={result} />
                      ) : (
                        <SearchUser user={result} />
                      ),
                    )}
                    {elementsCount / 10 > 1 && (
                      <Pagination
                        currentPage={page}
                        history={history}
                        numberOfPages={elementsCount ? Math.ceil(elementsCount / 10) : 1}
                        onPageClick={setPage}
                      />
                    )}
                  </>
                ) : (
                  canShowFeed &&
                  !isLoading && (
                    <Card className={styles.noMatches}>
                      {t('Search.Your search returned no matches')}
                    </Card>
                  )
                )}
              </div>
            </div>
          </Row>
        </Col>
      </Wrapper>
    </KitaPanelWrapper>
  );
}

export async function onSubmitHandler({
  searchValue,
  selectedGroups,
  calendarState,
  postState,
  userInfo,
  from,
  to,
  setCanShowFeed,
  setResults,
  setIsLoading,
  setCounts,
  page,
  category,
  prevSearchValue,
  setPrevSearchValue,
  setCategory,
  tokenRef,
  pattern,
}) {
  if (searchValue.trim().length === 0) return setCanShowFeed(false);

  let categoryToSearch = category;
  if (prevSearchValue !== searchValue) {
    setCategory('all');
    categoryToSearch = 'all';
    setPrevSearchValue(searchValue);
    setCanShowFeed(false);
  }

  setIsLoading(true);

  const { req, source } = searchPosts({
    page,
    text: searchValue,
    selectedGroups,
    calendarState,
    postState,
    userInfo,
    from,
    to,
    category: categoryToSearch,
    pattern,
  });

  if (tokenRef.current) {
    tokenRef.current.cancel();
  }
  tokenRef.current = source;

  req.then(({ data: { content, groupCount, postCount, userCount } }) => {
    setCanShowFeed(true);

    setResults(content);

    if (categoryToSearch === 'all') {
      setCounts({
        group: groupCount,
        post: postCount,
        user: userCount,
      });
    }

    setIsLoading(false);
  });
}

export function dispatchHandler({ type, id, newValues, results, setResults, counts, setCounts }) {
  if (type === 'updateFeedById') {
    setResults(
      results.map(result =>
        result.postType !== undefined && result.id === id
          ? {
              ...result,
              ...newValues,
            }
          : result,
      ),
    );
  } else if (type === 'deleteFeedById') {
    setResults(results.filter(result => result.postType === undefined || result.id !== id));
    setCounts({
      ...counts,
      post: counts.post - 1,
    });
  }
}
