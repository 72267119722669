import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import Language from '.';

export default connect(
  state => ({
    superAdminStatus: state.userReducer.superAdminStatus,
  }),
  {
    showBottomNotification,
  },
)(Language);
