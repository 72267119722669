import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Signature from '.';

const mapStateToProps = state => ({
  user: state.userReducer,
  documentData: state.signatureReducer.document,
  pageData: state.signatureReducer.page,
});

export default connect(
  mapStateToProps,
  {
    showBottomNotification,
  },
)(Signature);
