import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Card from 'library/common/commonComponents/Card';
import Tooltip from 'library/common/commonComponents/Tooltip';
import { getLetterInLogo } from 'library/utilities/groups';
import useLoadMore from 'library/common/commonHooks/useLoadMore';
import { loadMoreUserGroups } from 'library/api/user';
import Button from 'library/common/commonComponents/Buttons/Button';

import styles from './groupsListSidebar.module.scss';

export default function GroupsListSidebar({ title, uniqName, userId }) {
  const { t } = useTranslation();

  let { items, totalCount, itemsAreLoading, noMoreItems, loadItems } = useLoadMore({
    request: (data, params) => loadMoreUserGroups(userId, data, params),
    pageSize: 14,
  });

  const publicGroupCount = items.filter((group) => group.globalPublicGroup === true).length;
  totalCount-=publicGroupCount;

  return (
    <Card
      className={styles.cardContainer}
      title={
        totalCount ? (
          <>
            {title} ({totalCount})
          </>
        ) : (
          ''
        )
      }
      uniqName={uniqName}
      isToggleable
    >
      <div className={styles.container}>
        {items.map(group => (
          !group.globalPublicGroup &&
          <Tooltip text={group.groupName} key={group.id} isVisibleCondition>
            <Link to={'/groups/' + group.id}>
              <div className={styles.group} style={{ backgroundColor: group.colourCode || '#777' }}>
                {group.groupLogoUrl ? (
                  <img className={styles.logo} src={group.logoUrl} alt='logo' />
                ) : (
                  group.groupName && getLetterInLogo(group.groupName)
                )}
              </div>
            </Link>
          </Tooltip>
        ))}
        {noMoreItems === false && (
          <Button
            type='light'
            onClick={itemsAreLoading === false ? () => loadItems() : () => false}
            className={cn(styles.addMoreButton, true && styles.addMoreButton__loading)}
          >
            {itemsAreLoading ? (
              <div>
                <span />
                <span />
                <span />
              </div>
            ) : (
              t('Group.LoadMore')
            )}
          </Button>
        )}
      </div>
    </Card>
  );
}
