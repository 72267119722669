import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import CreatePostInput from '.';

export const mapStateToProps = state => ({
  user: state.userReducer,
});

export default connect(
  mapStateToProps,
  { showBottomNotification },
)(CreatePostInput);
