import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Card from 'library/common/commonComponents/Card';
import Label from 'library/common/commonComponents/Label';

import styles from './searchGroup.module.scss';

export default function SearchGroup({ group }) {
  const { t } = useTranslation();

  return (
    <div>
      <Card>
        <div className={styles.container}>
          <Link to={`/groups/${group.groupId}`} className={styles.group}>
            <div className={styles.logo} style={{ backgroundColor: group.colourCode }}>
              {group.logoUrl ? (
                <img src={group.logoUrl} alt='logo' className={styles.img} />
              ) : (
                <div className={styles.avatarGroupLetter}>
                  {group.groupName.trim()[0].toUpperCase()}
                </div>
              )}
            </div>
            <div>
              <div className={styles.groupTitle}>{group.groupName}</div>
              <div className={styles.groupDescription}>
                {group.groupDescription || <span>&nbsp;</span>}
              </div>
            </div>
          </Link>

          <Label type='default'>{t('Search.Group')}</Label>
        </div>
      </Card>
    </div>
  );
}
