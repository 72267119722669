import React, { useState } from 'react';
import Tabs from 'library/common/commonComponents/Tabs';
import { useTranslation } from 'react-i18next';
import BgwFAQ from 'modules/Bgw/FAQContainer/FAQContainer';
import BgwNews from 'modules/Bgw/NewsContainer';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import ExercisesContainer from './ExercisesContainer/ExercisesContainer';

import styles from './bgw.module.scss';
import ChecklistContainer from './ChecklistContainer/ChecklistContainer';

export default function BgwModule() {
  const [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation();

  const tabs = [
    {
      title: 'News',
      component: <BgwNews />,
      path: '/bgwnews',
    },
    {
      title: 'FAQs',
      component: <BgwFAQ />,
      path: '/bgwfaq',
    },
    {
      title: 'Übungen und Spiele für den Kita-Alltag',
      component: <ExercisesContainer />,
      path: '/bgwfaq/2',
    },
    {
      title: 'Checkliste/ To Do',
      component: <ChecklistContainer />,
      path: '/bgwfaq/3',
    },
  ];

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className={styles.container}>
          <Tabs
            tabs={tabs}
            currentTab={tabs[currentTab].path}
            onChange={(_, index) => setCurrentTab(index)}
          />
        </div>
      </Wrapper>
    </KitaPanelWrapper>
  );
}
