import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';

import { getKitaModules } from 'library/api/modules';
import {
  setUnreadMessagesCountsKitas,
} from 'library/common/commonActions/chatActions';
import { getGroupMembers } from 'library/common/commonActions/groupActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import CreateNewChat from 'library/common/commonComponents/Chat/ChatList/ChatListFrames/CreateNewChat/CreateNewChatContainer';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import useChatMessages from 'library/common/commonHooks/useChatMessages';
import store from 'main/store/configureStore';

import styles from './groupUsersListPopup.module.scss';
import { isAwoWW } from 'library/api/tenantConfig';
import { isPublicUser } from 'library/utilities/user';

export default function GroupUsersListPopup({ isOpened, closePopup, title, group, isAdmin }) {
  const [initialUsers, setInitialUsers] = useState([]);
  const [initialUsersLoaded, setInitialUsersLoaded] = useState(false);
  const [modules, setModules] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isNewChatPopupOpened, setIsNewChatPopupOpened] = useState(false);
  const [isRoleMember, setIsRoleMember] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.userReducer);
  const unreadMessagesCountsKitas = useSelector(state => state.chatReducer.unreadMessagesCountsKitas);
  const chatId = useSelector(state => state.chatReducer.chatId);
  const activeKita = useSelector(state => state.kitaReducer.activeKita);

  const {
    handleAddNewChat,
  } = useChatMessages({
    chatId,
    page: 0,
    history,
    unreadMessagesCountsKitas,
    setUnreadMessagesCountsKitas: (data) => store.dispatch(setUnreadMessagesCountsKitas(data)),
    showBottomNotification: (data) => store.dispatch(showBottomNotification(data)),
    activeKita,
    forceReload: true,
  });

  useEffect(() => {
    loadInitialUsers();
    getKitaModules().then((res) => {
      const chatModule = res.data.find(obj => obj.moduleKey == "chat");
      if(chatModule != null) {
        setModules(chatModule.activeStatus);
      }
    })
  }, []);

  async function loadInitialUsers() {
    const filters = [{ filterName: 'search' }];
    const groupMembersLoaded = async () => {
      const groupMembers = await getGroupMembers(
        group,
        filters,
        { sortBy: 'user.lastName', order: true },
        1,
        99999,
      );
      return groupMembers;
    };
    groupMembersLoaded().then(function (allGroupMembers) {
      let allGroupMembersMapped = allGroupMembers.data.content.map(a => a.user);
      if (!isAdmin) {
        allGroupMembersMapped = allGroupMembersMapped.filter(obj => obj.hideUser === false);
      }
      if (isAwoWW()) {
        allGroupMembersMapped = allGroupMembersMapped.filter(u=> !isPublicUser(u));
      }
      setInitialUsers(allGroupMembersMapped);
      setInitialUsersLoaded(true);

      const currentGroupUser = allGroupMembers.data.content.find(x => x.user.id === user.id);
      const isMember = !user.administrationAccess && !user.employee && !currentGroupUser?.groupRole?.role.adminRole;
      setIsRoleMember(isMember);
    });
  }

  const handleEnvelopClick = (member) => {
    if (member.id !== user.id) {
      setSelectedMember(member);
    } else {
      setSelectedMember(null);
    }
    setIsNewChatPopupOpened(true);
  };

  const goUserPage = userObj => {
    if (isAdmin) {
      history.push({
        pathname: '/administration/users/list/1',
        search: `?editUserId=${userObj.id}`,
      });
    }
  };

  return (
    <>
      <Popup
        isOpened={isOpened}
        closePopup={closePopup}
        header={title}
        footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
      >
        {initialUsersLoaded ? (
          <div>
            <div className={styles.container}>
              <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={568}>
                {initialUsers.map(member => {
                  const userId = member.userId || member.id;
                  return (
                    <div onClick={() => goUserPage(member)} key={userId} className={styles.item}>
                      <div className={styles.itemAvatar}>
                        <ProfileAvatar image={member} id={userId} withoutLink />
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <div className={styles.itemInfo}>
                          <div className={cn(styles.itemName, 'notranslate')}>
                            {`${member.firstName} ${member.lastName}`}
                          </div>
                        </div>
                        {modules && !(isRoleMember && member.blockUsersFromMessaging) && (
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              handleEnvelopClick(member);
                            }}
                          >
                            <i className='fa fa-envelope fa-2x' />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Scrollbars>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </Popup>
      <CreateNewChat
        isOpened={isNewChatPopupOpened}
        onSubmit={handleAddNewChat}
        onClose={() => setIsNewChatPopupOpened(false)}
        user={user}
        createNewChatRecipientsPreset={selectedMember !== null ? [selectedMember] : []}
        shouldCheckBlockUsersFromMessaging={isRoleMember}
      />
    </>
  );
}
