import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getKitaHolidays, setKitaHolidays } from 'library/api/kita';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Input from 'library/common/commonComponents/Inputs/Input';

import styles from './HolidayFrame.module.scss';

const initialRowData = [
  { name: 'Herbstferien', field: 'herbstferien' },
  { name: 'Weihnachtsferien', field: 'weihnachtsferien' },
  { name: 'Winterferien', field: 'winterferien' },
  { name: 'Osterferien', field: 'osterferien' },
  { name: 'Pfingstferien', field: 'pfingstferien' },
  { name: 'Sommerferien', field: 'sommerferien' },
];

const HolidayTimesFrame = () => {
  const { t } = useTranslation();

  const [defaultHolidays, setDefaultHolidays] = useState([]);
  const [additionalHolidays, setAdditionalHolidays] = useState([]);
  const [additionalHolidayLabel, setAdditionalHolidayLabel] = useState('');
  const [additionalHolidayStartDate, setAdditionalHolidayStartDate] = useState('');
  const [additionalHolidayEndDate, setAdditionalHolidayEndDate] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState([]);

  const { kitaId } = useSelector(state => state.kitaReducer.activeKita);

  const handleDefaultHolidayChange = (name, field) => (e) => {
    const target = defaultHolidays.find(row => row?.name === name);
    if (!target) {
      setDefaultHolidays([
        ...defaultHolidays,
        {
          name,
          [field]: e.target.value,
          isAdditional: false,
        }
      ]);
    } else {
      const updatedDefaultHolidaysData = [];
      defaultHolidays.forEach(item => {
        if (item.name === name) {
          if (item.startDate || item.endDate || e.target.value !== '') {
            updatedDefaultHolidaysData.push({
              ...item,
              [field]: e.target.value
            });
          }
        } else {
          updatedDefaultHolidaysData.push(item);
        }
      });

      setDefaultHolidays(updatedDefaultHolidaysData);
    }
    setIsDisabled(false);
  };

  const handleAdditionalHolidayChange = (name, field) => (e) => {
    const updatedAdditionalHolidaysData = additionalHolidays.map(item => {
      if (item.name === name) {
        return {
          ...item,
          [field]: e.target.value
        }
      } else {
        return item;
      }
    });

    setAdditionalHolidays(updatedAdditionalHolidaysData);
    setIsDisabled(false);
  };

  const handleAddRow = () => {
    if (!additionalHolidayLabel || !additionalHolidayStartDate || !additionalHolidayEndDate || additionalHolidayStartDate > additionalHolidayEndDate) {
      return;
    }
    if (!additionalHolidays.find(row => row.name === additionalHolidayLabel)) {
      setAdditionalHolidays([
        ...additionalHolidays,
        {
          name: additionalHolidayLabel,
          startDate: additionalHolidayStartDate,
          endDate: additionalHolidayEndDate,
          isAdditional: true,
        },
      ]);
      setAdditionalHolidayLabel('');
      setAdditionalHolidayStartDate('');
      setAdditionalHolidayEndDate('');
      setIsDisabled(false);
    }
  };

  const handleRemoveRow = (index) => {
    const updatedAdditionalHolidaysData = additionalHolidays.slice();
    updatedAdditionalHolidaysData.splice(index, 1);
    setAdditionalHolidays(updatedAdditionalHolidaysData);
    setIsDisabled(false);
  };

  const onSaveClick = () => {
    const payload = [];
    const errorData = [];
    [...defaultHolidays, ...additionalHolidays].forEach(holidaySetting => {
      if (
        (!holidaySetting?.startDate && holidaySetting?.endDate) ||
        (holidaySetting?.startDate && !holidaySetting?.endDate)
      ) {
        errorData.push(holidaySetting.name);
      } else if (holidaySetting?.startDate && holidaySetting?.endDate) {
        if (holidaySetting.startDate > holidaySetting.endDate || !holidaySetting.name) {
          errorData.push(holidaySetting.name);
        } else {
          payload.push(holidaySetting);
        }
      }
    });

    setErrors(errorData);
    if (errorData.length > 0) {
      return;
    }

    setIsSaving(true);
    setIsDisabled(true);
    setKitaHolidays(kitaId, payload)
      .then(res => {
        if (res.status === 200 && res.data.length > 0) {
          const defaultDays = [];
          const additionalDays = [];
          res.data.forEach((day) => {
            if (day.isAdditional) {
              additionalDays.push(day);
            } else {
              defaultDays.push(day);
            }
          });

          setAdditionalHolidayLabel('');
          setAdditionalHolidayStartDate('');
          setAdditionalHolidayEndDate('');
          setDefaultHolidays(defaultDays);
          setAdditionalHolidays(additionalDays);
        }
        setIsSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    getKitaHolidays(kitaId)
      .then(res => {
        if (res.status === 200 && res.data.length > 0) {
          const defaultDays = [];
          const additionalDays = [];
          res.data.forEach((day) => {
            if (day.isAdditional) {
              additionalDays.push(day);
            } else {
              defaultDays.push(day);
            }
          })
          setDefaultHolidays(defaultDays);
          setAdditionalHolidays(additionalDays);
        }
      });
  }, []);

  return (
    <div className={styles.holidaysContainer}>
      {/* <p className={styles.categoryTitle}>{t('Administration.KidSection.Holiday times')}</p> */}
      <div className={styles.holidayGrid}>
        <div className={styles.gridHeader}>
          <div>{t('Administration.Holiday')}</div>
          <div>{t('Administration.Holiday from')}</div>
          <div>{t('Administration.Holiday to')}</div>
        </div>
        {initialRowData.map(({ name }, index) => (
          <div className={styles.gridRow} key={index}>
            <div>{t(`Administration.KidSection.${name}`)}</div>
            <Input
              type='date'
              value={defaultHolidays.find(row => row?.name === name)?.startDate || ''}
              onChange={handleDefaultHolidayChange(name, 'startDate')}
              error={errors.includes(name)}
            />
            <Input
              type='date'
              value={defaultHolidays.find(row => row?.name === name)?.endDate || ''}
              onChange={handleDefaultHolidayChange(name, 'endDate')}
              error={errors.includes(name)}
            />
          </div>
        ))}
        <p className={styles.categoryTitle}>{t('Administration.KidSection.Additional Holidays')}</p>
        {additionalHolidays.map((row, index) => (
          <div className={styles.gridRow} key={index}>
            <Input
              value={row.name}
              onChange={handleAdditionalHolidayChange(row.name, 'name')}
              error={errors.includes(row.name)}
            />
            <Input
              type='date'
              value={row.startDate}
              onChange={handleAdditionalHolidayChange(row.name, 'startDate')}
              error={errors.includes(row.name)}
            />
            <Input
              type='date'
              value={row.endDate}
              onChange={handleAdditionalHolidayChange(row.name, 'endDate')}
              error={errors.includes(row.name)}
            />
            <div>
              <Button size="sm" onClick={() => handleRemoveRow(index)}>
                <i className='fa fa-minus' />
              </Button>
            </div>
          </div>
        ))}
        <div>{t(`Administration.Holiday new`)}</div>
        <div className={styles.gridRow}>
          <Input
            placeholder='name'
            value={additionalHolidayLabel}
            onChange={(e) => setAdditionalHolidayLabel(e.target.value)}
          />
          <Input
            type='date'
            value={additionalHolidayStartDate}
            onChange={(e) => setAdditionalHolidayStartDate(e.target.value)}
          />
          <Input
            type='date'
            value={additionalHolidayEndDate}
            onChange={(e) => setAdditionalHolidayEndDate(e.target.value)}
          />
          <div>
            <Button
              size='sm'
              disabled={!additionalHolidayLabel || !additionalHolidayStartDate || !additionalHolidayEndDate}
              onClick={handleAddRow}
            >
              <i className='fa fa-plus' />
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.footerActions}>
        <ButtonWithLoader
          type='primary'
          disabled={isDisabled}
          isLoading={isSaving}
          onClick={onSaveClick}
        >
          <Trans i18nKey='AccountSettings.NotificationsSection.Save' />
        </ButtonWithLoader>
      </div>
    </div>
  );
};

export default HolidayTimesFrame;
