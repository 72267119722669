import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tabs from 'library/common/commonComponents/Tabs';

import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import styles from './gfzforms.module.scss';

import MedicalAuthorization from './MedicalAuthorization';
import KidLeaving from './KidLeaving';
import ContractChanges from './ContractChanges';
import AddDays from './AddDays';
import GfzDocumentationOverview from './GfzDocumentationOverview';
import NannyContractAdjustments from './NannyContractAdjustments';
import NannyProtocol from './NannyProtocol';

function GfzForms({ user, kitaName, kitaId }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [kidId, setKidId] = useState(null);
  const [formId, setFormId] = useState(null);

  const [locationHost] = useState(window.location.hostname);

  const [adjustedActiveTab, setAdjustedActiveTab] = useState(false);
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const formParam = searchParams.get('form');
  const childIdParam = searchParams.get('child');

  const kitaTabs = [
    'gfzdocumentationoverview',
    'medicalauthorization',
    'kidleaving',
    'contractchanges',
    'adddays',
  ];
  const townDistrictTabs = [
    'gfzdocumentationoverview',
    'nannycontractadjustments',
    'nannyprotocol',
  ];
  const onlyAdminTabs = ['nannyprotocol'];
  const isAdmin = user.administrationAccess;

  let tabs = [
    {
      title: t('GfzDocumentation.MedicalAuthorization'),
      path: 'medicalauthorization',
      component: (
        <MedicalAuthorization
          isAdminOrEmployee={user.administrationAccess || user.employee}
          userId={user.id}
          kidId={kidId}
          formId={formId}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      title: t('GfzDocumentation.KidLeaving'),
      path: 'kidleaving',
      component: (
        <KidLeaving
          isAdmin={user.administrationAccess}
          isEmployee={user.employee}
          userId={user.id}
          kitaName={kitaName}
          kidId={kidId}
          formId={formId}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      title: t('GfzDocumentation.ContractChanges'),
      path: 'contractchanges',
      component: (
        <ContractChanges
          isAdmin={user.administrationAccess}
          isEmployee={user.employee}
          userId={user.id}
          kitaName={kitaName}
          kidId={kidId}
          formId={formId}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      title: t('GfzDocumentation.AdditionalDays'),
      path: 'adddays',
      component: (
        <AddDays
          isAdmin={user.administrationAccess}
          isEmployee={user.employee}
          userId={user.id}
          kitaName={kitaName}
          kidId={kidId}
          formId={formId}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      title: t('GfzDocumentation.NannyContractAdjustments'),
      path: 'nannycontractadjustments',
      component: (
        <NannyContractAdjustments
          isAdminOrEmployee={user.administrationAccess || user.employee}
          userId={user.id}
          kidId={kidId}
          kitaName={kitaName}
        />
      ),
    },
    {
      title: t('GfzDocumentation.NannyProtocol'),
      path: 'nannyprotocol',
      component: <NannyProtocol userId={user.id} kitaId={kitaId} />,
    },
  ];

  tabs.unshift({
    title: t('GfzDocumentation.Overview'),
    path: 'gfzdocumentationoverview',
    component: (
      <GfzDocumentationOverview
        showAll={user.administrationAccess || user.employee}
        user={user}
        kitaName={kitaName}
        setActiveTab={setActiveTab}
        setKidId={setKidId}
        setFormId={setFormId}
      />
    ),
  });

  if (!locationHost.toLowerCase().includes('localhost')) {
    tabs = tabs.filter(obj => {
      if (kitaName && kitaName.toLowerCase().includes('kita') && kitaTabs.includes(obj.path)) {
        return obj;
      }
      if (
        kitaName &&
        kitaName.toLowerCase().includes('stadtkreis') &&
        townDistrictTabs.includes(obj.path)
      ) {
        return obj;
      }
    });
  }
  tabs = tabs.filter(obj => {
    if (!onlyAdminTabs.includes(obj.path) || isAdmin) return obj;
  });

  //console.log(filteredTabs);

  if (formParam && !adjustedActiveTab) {
    const index = tabs.findIndex(t => t.path === formParam);
    if (index >= 0) {
      setActiveTab(index);

      if (childIdParam) {
        setKidId(parseInt(childIdParam, 10));
      }
    }
    setAdjustedActiveTab(true);
  }

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <Col>
          <Row>
            {tabs.length > 0 && (
              <div className={styles.container}>
                <Tabs
                  tabs={tabs}
                  onChange={(_, index) => {
                    setKidId(null);
                    setFormId(null);
                    setActiveTab(index);
                  }}
                  currentTab={tabs[activeTab].path}
                />
              </div>
            )}
          </Row>
        </Col>
      </Wrapper>
    </KitaPanelWrapper>
  );
}

export default connect(state => ({
  user: state.userReducer,
  kitaName: state.kitaReducer.activeKita.kitaName,
  kitaId: state.kitaReducer.activeKita.kitaId,
}))(GfzForms);
