import React, { useState } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import Card from 'library/common/commonComponents/Card';
import Carousel from 'library/common/commonComponents/Carousel';
import { getPathWithGroupId } from 'library/utilities/groups';

import styles from './gallerySnippet.module.scss';

export default function GallerySnippet({ gallerySnippet, match, history, groupInfo }) {
  const { galleryId, files = [] } = gallerySnippet || {};
  const { t } = useTranslation();
  const dropdownOptions = getGallerySnippetDropdownOptions({
    t,
    match,
    galleryId,
    history,
    groupInfo,
  });

  const [shouldInitCarousel, setShouldInitCarousel] = useState(false);
  return (
    galleryId > 0 &&
    files &&
    files.length > 0 && (
      <Card
        title={
          <>
            <i className={cn('fa fa-picture-o', styles.titleIcon)} />{' '}
            <Trans i18nKey='GallerySnippet.Gallery' />
          </>
        }
        uniqName='GallerySnippet'
        isToggleable
        dropdownOptions={dropdownOptions}
        onToggle={setShouldInitCarousel}
      >
        <div className={styles.container}>
          <Carousel
            imageLinks={files.map(({ filePath }) => filePath)}
            shouldInit={shouldInitCarousel}
          />
        </div>
      </Card>
    )
  );
}

export function getGallerySnippetDropdownOptions({ t, match, galleryId, history, groupInfo }) {
  const newPath = `${getPathWithGroupId(match.path)}/gallery`;
  return [
    {
      faIcon: 'fa-cogs',
      title: t('Gallery.Dropdown.Settings'),
      onClick: () => history.push(`${newPath}?settings`),
      isHidden: !groupInfo.addModifyGallery,
    },
    {
      faIcon: 'fa-arrow-circle-right',
      title: t('Gallery.Dropdown.Open Gallery'),
      onClick: () => history.push(`${newPath}?${galleryId}`),
    },
  ];
}
