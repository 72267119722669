import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
import AbsenceFrame from 'modules/AccountSettings/accountSettingsFrames/AbsenceFrame/index.jsx';
import Tabs from 'library/common/commonComponents/Tabs';
import styles from '../../CheckInOut/CheckInOutPopup/calendarExportPopup.module.scss';
import AbsenceHistoryFrame from '../../../../../modules/AccountSettings/accountSettingsFrames/AbsenceHistoryFrame';

export default function AbsencePopUp({
  kitaId,
  isOpened,
  closePopup,
  user,
  showBottomNotification,
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation();

  const memberSelectable =
    window.location.hostname.toLowerCase().includes('ivs.safe2connect.org') &&
    (user.employee || user.administrationAccess);

  const tabs = [
    {
      title: t('Absence.Report'),
      component: (
        <AbsenceFrame
          showBottomNotification={showBottomNotification}
          user={user}
          closeAbsencesPopUp={() => closePopup()}
          kitaId={kitaId}
          memberSelectable={memberSelectable}
        />
      ),
      path: 'absence-form',
    },
    {
      title: t('Absence.History'),
      component: (
        <AbsenceHistoryFrame
          showBottomNotification={showBottomNotification}
          user={user}
          closeAbsencesPopUp={() => closePopup()}
          memberSelectable={memberSelectable}
        />
      ),
      path: 'absence-history',
    },
  ];

  return (
    <Popup
      size={'absence-history' === tabs[currentTab].path ? 'ChildHistoryTab' : 'normal'}
      isOpened={isOpened}
      closePopup={closePopup}
      header={<strong>{t('AbsencePopUp.Header')}</strong>}
    >
      <div className={styles.wrapper}>
        <Tabs
          tabs={tabs}
          currentTab={tabs[currentTab].path}
          onChange={(_, index) => setCurrentTab(index)}
        />
      </div>
    </Popup>
  );
}
