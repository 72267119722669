import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import GroupWorkingHours from '.';

export default connect(
  state => ({
    user: state.userReducer,
  }),
  { showBottomNotification },
)(GroupWorkingHours);
