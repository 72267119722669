import { createAction } from 'redux-actions';
import {
  inititalTypeObject,
  GET_NOTIFICATION_SETTINGS_USER,
  GET_NOTIFICATION_SETTINGS_GLOBAL,
  SHOW_BOTTOM_NOTIFICATION,
  URLS,
  RESET_NOTIFICATIONS_STATE,
  UPDATE_COUNT_OF_NOTIFICATIONS,
  UPDATE_COUNT_OF_TAX_RECEIPT,
} from 'library/common/commonConstants/notificationsConstants';
import axiosInstance from 'library/api/axios';
import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8095)}/api`;

const updateUserNotificationSettings = createAction(GET_NOTIFICATION_SETTINGS_USER);
const updateGlobalNotificationSettings = createAction(GET_NOTIFICATION_SETTINGS_GLOBAL);
export const resetNotificationSettings = createAction(RESET_NOTIFICATIONS_STATE);

export const tranformAdminTypes = (notificationTypes, user) => {
  let globalConfiguration = {};
  if (notificationTypes && notificationTypes.length) {
    globalConfiguration = inititalTypeObject;
    notificationTypes.forEach(type => {
      globalConfiguration[type.type] = {
        ...type,
        userId: user.id,
      };
    });
  }
  return globalConfiguration;
};

export const tranformUserTypes = (notificationTypes, user) => {
  const configuration = {};
  if (notificationTypes && Object.keys(notificationTypes).length) {
    Object.keys(notificationTypes).forEach(key => {
      if (!configuration[key]) {
        configuration[key] = { ...inititalTypeObject };
      }
      if (notificationTypes[key].configurations && notificationTypes[key].configurations.length) {
        notificationTypes[key].configurations.forEach(type => {
          configuration[key][type.type] = {
            ...type,
            userId: user.id,
          };
        });
      }
    });
  }
  return configuration;
};

/**
 * NOTIFICATION CONFIGURATION
 * getNotificationConfiguration - get current notification configuration
 */
export const getNotificationConfiguration = ({ admin, user, cb } = {}) => {
  return async dispatch => {
    let url = `${URL}${URLS.notificationConfiguration}`;
    if (!admin) {
      url = `${URL}${URLS.notificationConfigurationUser}`;
    }
    return axiosInstance
      .get(url)
      .then(({ data, status }) => {
        if (status === 200) {
          if (admin) {
            dispatch(
              updateGlobalNotificationSettings({
                globalConfiguration: tranformAdminTypes(data.configurations, user),
                desktopNotificationAdmin: data.desktopConfiguration,
              }),
            );
          } else {
            dispatch(
              updateUserNotificationSettings({
                ...tranformUserTypes(data, user),
                desktopNotificationUser:
                  data.userConfiguration && data.userConfiguration.desktopConfiguration
              }),
            );
          }
        } else if (admin) {
          dispatch(
            updateGlobalNotificationSettings({
              globalConfiguration: {},
              desktopNotificationAdmin: {},
            }),
          );
        } else {
          dispatch(
            updateUserNotificationSettings({
              globalConfiguration: {},
              userConfiguration: {},
              desktopNotificationUser: {},
            }),
          );
        }
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        if (admin) {
          dispatch(
            updateGlobalNotificationSettings({
              globalConfiguration: {},
              desktopNotificationAdmin: {},
            }),
          );
        } else {
          dispatch(
            updateUserNotificationSettings({
              globalConfiguration: {},
              userConfiguration: {},
              desktopNotificationUser: {},
            }),
          );
        }
        if (cb) {
          cb();
        }
      });
  };
};

export const saveNotificationConfiguration = ({
  dataToSend,
  admin,
  desktopNotificationAdmin,
  desktopNotificationUser,
  userConfiguration,
  globalConfiguration,
} = {}) => {
  return async dispatch => {
    let url = `${URL}${URLS.notificationConfiguration}`;
    if (!admin) {
      url = `${URL}${URLS.notificationConfigurationUser}`;
    }
    return axiosInstance
      .put(url, dataToSend)
      .then(data => {
        if (data.status === 200) {
          if (admin) {
            dispatch(
              updateGlobalNotificationSettings({ desktopNotificationAdmin, globalConfiguration }),
            );
          } else {
            dispatch(
              updateUserNotificationSettings({
                desktopNotificationUser,
                globalConfiguration,
                userConfiguration,
              }),
            );
          }
          return { success: true };
        }
        return { success: false };
      })
      .catch(() => {
        return { success: false };
      });
  };
};

export const updateDesktopNotificationUser = desktopNotificationUser => dispatch => {
  dispatch(
    updateUserNotificationSettings({
      desktopNotificationUser,
    }),
  );
};

export const showBottomNotification = (data, options) => dispatch => {
  dispatch({
    type: SHOW_BOTTOM_NOTIFICATION,
    payload: data,
    notificationType: getNotificationType(options),
  });
};

export function getNotificationType(options = {}) {
  if (options.isFail) {
    return 'fail';
  } else if (options.isWarning) {
    return 'warning';
  } else {
    return 'success';
  }
}

export const updateCountOfNotifications = data => dispatch => {
  dispatch({
    type: UPDATE_COUNT_OF_NOTIFICATIONS,
    payload: data,
  });
};

export const updateCountOfTaxReceipt = data => dispatch => {
  dispatch({
    type: UPDATE_COUNT_OF_TAX_RECEIPT,
    payload: data,
  });
};
