import { useState, useEffect } from 'react';

import { getUserKitas } from 'library/api/kita';
import { sortKitaForFavorite } from 'library/utilities/sort';
import {setNewKita} from "../../../library/common/commonActions/kitaActions";

const dafauktKitaList = [
  {
    kitaId: '',
    kitaName: '',
    logoUrl: '',
    userKitaLogoUrl: '',
    selectedKitaStatus: '',
  },
];

export default function useGetUserKitas(isLoggedIn, newKita, setKitas, history, selectKita, user) {
  const [isLoading, setIsLoading] = useState(true);
  const [kitaList, setKitaList] = useState(dafauktKitaList);
  const [reloadkitaList, setReloadkitaList] = useState(false);
  const [newKitaName, setNewKitaName] = useState(null);
  const [kitaId, setKitaId] = useState(null);
  const [originalKitaList, setOriginalKitaList] = useState(null);

  const patchKita = params => {
    if (user.orderFavorites) {
      setKitaList(
        sortKitaForFavorite(kitaList.map(kita => (kita.kitaId === params.kitaId ? params : kita))),
      );
    } else {
      setKitaList(kitaList.map(kita => (kita.kitaId === params.kitaId ? params : kita)));
    }
  };

  const searchKitaList = name => {
    let filteredKitaList = originalKitaList;
    if (name.length >= 3) {
      filteredKitaList = originalKitaList.filter(
        kita => kita.kitaName.toLowerCase().search(name.toLowerCase()) !== -1,
      );
      if (user.orderFavorites) {
        filteredKitaList = sortKitaForFavorite(filteredKitaList);
      }
    }
    setKitaList(filteredKitaList);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      getUserKitas()
        .then(res => {
          switch (res.status) {
            case 200:
              setOriginalKitaList(res.data);
              setKitaList(res.data);
              setKitas(res.data);
              setIsLoading(false);
              if (newKitaName) {
                reloadAndGoToKitaCallback(res.data.find(kita => kita.kitaName === newKitaName));
              }
              else if (kitaId) {
                reloadAndGoToKitaCallback(res.data.find(kita => kita.kitaId === kitaId));
              }
              break;
            default:
          }
        })
        .catch(res => {
          switch (res.status) {
            case 401:
              setKitaList(dafauktKitaList.concat({ error: 401 }));
              setKitas(dafauktKitaList.concat({ error: 401 }));
              setOriginalKitaList(dafauktKitaList.concat({ error: 401 }));
              setIsLoading(false);
              break;
            default:
              setKitaList(dafauktKitaList);
              setOriginalKitaList(dafauktKitaList);
              setKitas(dafauktKitaList);
              setIsLoading(false);
          }
        });
    }
    return () => {
      setKitaList(dafauktKitaList);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadkitaList, setKitaList, isLoggedIn, newKita]);

  const reloadAndGoToKitaCallback = function(kita) {
    setNewKitaName(null);
    setKitaId(null);
    selectKita(kita);
  };

  const reloadAndGoToKita = function(kitaName) {
    setNewKitaName(kitaName);
    setKitaId(null);
    setReloadkitaList(!reloadkitaList);
  };

  const reloadAndGoToKitaById = function(id) {
    setNewKitaName(null);
    setKitaId(id);
    setReloadkitaList(!reloadkitaList);
  };

  return {
    isLoading,
    kitaList,
    setKitaList,
    reloadAndGoToKita,
    reloadAndGoToKitaById,
    patchKita,
    searchKitaList,
    originalKitaList,
  };
}
