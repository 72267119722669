import { useTranslation } from 'react-i18next';

import {
  isMatchesToMediaTypeArray,
  getFileExtension,
  isNotEncrypted,
} from 'library/utilities/files';
import { showBottomNotification as showNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import { checkUserFreeSpaceUsing } from 'library/api/files';

export default function useFileInputChangeHandler({
  onChange,
  allowedExtensions,
  userPermissions,
  isInProfileStream,
  user,
  group,
  activeKita,
  groupId,
  files: selectedFiles,
  maxFileSize,
}) {
  const { t } = useTranslation();

  const onFileInputChange = ({ target: { files } }) =>
    onFileChangedHandler({
      files,
      onChange,
      allowedExtensions,
      showBottomNotification: (...args) => store.dispatch(showNotification(...args)),
      t,
      userPermissions,
      isInProfileStream,
      user,
      group,
      activeKita,
      groupId,
      selectedFiles,
      maxFileSize,
    });

  return onFileInputChange;
}

export const onFileChangedHandler = async ({
  files,
  onChange,
  showBottomNotification,
  allowedExtensions,
  t,
  userPermissions,
  isInProfileStream,
  user,
  group,
  activeKita,
  groupId,
  selectedFiles,
  maxFileSize,
}) => {
  let showExtensionError = false;
  let sizeError = '';
  let showEncryptedError = false;
  let showExeError = false;
  let showPermissionError = '';
  const userId = user.id;
  const { kitaId } = activeKita;
  const activeGroupId = groupId || (group && group.groupId) || 0;
  const memberOfGroup =
    group && group.userList && group.userList.find(member => member.id === String(userId));
  const allowedFiles = Array.from(files)
    .filter(file => {
      if (isMatchesToMediaTypeArray(file.type, allowedExtensions, file.name)) {
        return true;
      } else {
        showExtensionError = true;
        return false;
      }
    })
    .filter(file => {
      const isFileMoreThan1GB = file.size / 1024 / 1024 / 1024 >= 1;
      const isFileMoreThan2GB = file.size / 1024 / 1024 / 1024 >= 2;

      if (maxFileSize) {
        if (file.size / 1024 / 1024 >= maxFileSize) {
          sizeError = "FileManager.You can't upload file size more than {FILE_SIZE}";
          return false;
        } else {
          return true;
        }
      }

      if (user.superAdminStatus || (memberOfGroup && memberOfGroup.groupAdminStatus)) {
        if (isFileMoreThan2GB) {
          sizeError = 'FileManager.File size should not more than 2GB';
          return false;
        } else {
          return true;
        }
      } else {
        if (
          (!isInProfileStream && isFileMoreThan2GB) ||
          (isInProfileStream && isFileMoreThan2GB && user.administrationAccess)
        ) {
          sizeError = 'FileManager.File size should not more than 2GB';
          return false;
        } else if (isInProfileStream && isFileMoreThan1GB && !user.administrationAccess) {
          sizeError = 'FileManager.File size should not more than 1GB';
          return false;
        } else {
          return true;
        }
      }
    });
  if (userPermissions && userPermissions.length) {
    Object.keys(files).forEach(item => {
      if (files[item] && !userPermissions[0].pictures && files[item].type.includes('image/')) {
        showPermissionError = 'FileValidation.Pictures';
      }
      if (files[item] && !userPermissions[0].videos && files[item].type.includes('video/')) {
        showPermissionError = 'FileValidation.Videos';
      }
      if (
        files[item] &&
        !userPermissions[0].others &&
        !files[item].type.includes('image/') &&
        !files[item].type.includes('video/')
      ) {
        showPermissionError = 'FileValidation.Others';
      }
    });
  }

  let processedFiles = [];
  if (!showPermissionError) {
    for (const file of allowedFiles) {
      const ext = getFileExtension(file.name);
      if (ext === 'zip') {
        if (await isNotEncrypted(file)) {
          processedFiles.push(file);
        } else {
          showEncryptedError = true;
        }
      } else if (ext === 'exe') {
        showExeError = true;
      } else {
        processedFiles.push(file);
      }
    }
  }

  if (showExeError) {
    showBottomNotification(t('FileManager.Exe file does not allow to upload'), {
      isFail: true,
    });
  }

  if (showExtensionError) {
    showBottomNotification(
      t('UploadButton.Allowed file formats').replace(
        '{formats}',
        allowedExtensions.map(ext => ext.slice(0, ext.indexOf('/'))).join(', '),
      ),
      {
        isFail: true,
      },
    );
  }

  if (showEncryptedError) {
    showBottomNotification(t('FileManager.Zip encrypted error'), {
      isFail: true,
    });
  }

  if (sizeError) {
    showBottomNotification(t(sizeError).replace('{FILE_SIZE}', `${maxFileSize}MB`), {
      isFail: true,
    });
  }

  if (showPermissionError) {
    showBottomNotification(t(showPermissionError), {
      isFail: true,
    });
  }

  let selectedFilesSize = 0;
  let newFilesSize = 0;
  if (selectedFiles && selectedFiles.length) {
    selectedFilesSize = selectedFiles.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.size;
    }, 0);
  }
  if (processedFiles.length) {
    newFilesSize = processedFiles.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.size;
    }, 0);

    const data =
      user.administrationAccess || user.superAdminStatus
        ? true
        : await checkUserFreeSpaceUsing({
            filesSize: (newFilesSize + selectedFilesSize) / 1024 / 1024, // size in Mb
            groupId: activeGroupId,
            kitaId,
            userId,
          });

    if (!data) {
      showBottomNotification(t('FileManager.Size error'), {
        isFail: true,
      });
      processedFiles = [];
    }
  }

  if (processedFiles.length) {
    await onChange(processedFiles);
  }
};
