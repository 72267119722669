import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Label from 'library/common/commonComponents/Label';
import Select from 'library/common/commonComponents/Inputs/Select';
import RadioButton from 'library/common/commonComponents/RadioButton';

import styles from './activateCoronaTestModulePopup.module.scss';
import { setIsOpened } from 'library/common/commonActions/kitaActions';
import { setKitaReligion } from 'library/api/kita';

export default function ActivateCoronaTestModulePopup({
  isOpened,
  setIsOpened,
  onChangeActivate,
  setAciveModule,
  module,
}) {

const {t} = useTranslation();
const [religion, setReligion] = useState({id: 0});
  return (
    <Popup
      isOpened={isOpened}
      closePopup={()=>{              
        setIsOpened(false);
        setAciveModule({});
      }}
      size='extraSmall'
      header={t('CoronaTest.Select religion')}
      footer={
        <div className={styles.footerNavigation}>
          <Button 
            onClick={()=>{
              setIsOpened(false);
              setAciveModule({});
            }}
          >
            {t('Confirm.Cancel')}
          </Button>
          {religion.id !== 0 && (
          <ButtonWithLoader 
            type='primary' 
            onClick={()=>{
              setKitaReligion(religion.id).then(()=>{
                onChangeActivate({ ...module, activeStatus: true }, 'ENABLE');
                setIsOpened(false);
                setAciveModule({});
              });
            }}
          >
            {t('Confirm.Confirm')}
          </ButtonWithLoader>
          )}
        </div>
      }
    >
      <div className={styles.container}>
        
        <div className={styles.text}>
        {t('CoronaTest.It is required to select the religion of the kita to proceed')}<br /><br />
          <Label type='input'>{t('Administration.Kita.Religion')}</Label>
          <Select
            options={[{label: t('Administration.Kita.Secular'), id: 1}, {label: t('Administration.Kita.Catholic'), id: 2}, {label: t('Administration.Kita.Protestant'), id: 3}]}
            onSelect={e => setReligion(e)}
            selected={religion}
            style={{ zIndex: '10000000000' }}
        />
        </div>
      </div>
    </Popup>
  );
}
