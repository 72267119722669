import React, { useState } from 'react';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import { importForm } from "library/api/formEditor";
import { useTranslation } from 'react-i18next';

import styles from './importFormPopup.module.scss';


export default function ImportFormPopup({
  isOpened,
  closePopup,
  store,
  showBottomNotification,
}) {

  const { t } = useTranslation();
  const [naturalIdInput, setNaturalIdInput] = useState('');

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      header={t('Administration.FormsOverviewImportTitle')}
      body={<>{t('Administration.FormsOverviewImportText')}<br /><br /><b><Input type='text' value={naturalIdInput} onChange={e => setNaturalIdInput(e.target.value)} /></b></>}
      footer={
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonSubmit}>
            <Button
              onClick={() => importForm(naturalIdInput).then(data => {
                store.dispatch(
                  showBottomNotification(
                    t('Administration.FormsImportSaveSuccess'),
                    { isFail: false },
                  ),
                );
              })}
              type='primary'
            >
              {t('Administration.FormsOverviewConfirmImport')}
            </Button>
          </div>
          <div className={styles.buttonCancel}>
            <Button onClick={closePopup}>{t('Popup.Close')}</Button>
          </div>
        </div>
      }
    >
    </Popup>
  );
}



