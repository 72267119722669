import React from 'react';

import BlockedGroupsListGroup from './blockedGropsListFrames/BlockedGroupsListGroup';

export default function BlockedGroupsList({ text, groups }) {
  return (
    <div>
      {text}

      {groups.map(group => (
        <BlockedGroupsListGroup group={group} />
      ))}
    </div>
  );
}
