import React from 'react';
import { useTranslation } from 'react-i18next';
import Label from "library/common/commonComponents/Label";
import Input from "library/common/commonComponents/Inputs/Input";
import Checkbox from "library/common/commonComponents/Checkbox";
import Button from "library/common/commonComponents/Buttons/Button";
import RadioButton from "library/common/commonComponents/RadioButton";

export default function DropdownEditView({
  component,
  setComponent,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Checkbox
        name={t('FormsEditor.ComponentEditorAllowParentsFillOutLabel')}
        isChecked={component.allowParentsFillOut}
        onChange={() => {
          setComponent({
            ...component,
            allowParentsFillOut: !component.allowParentsFillOut,
          });
        }}
      />
      <Checkbox
        name={t('FormsEditor.ComponentEditorAllowKitaFillOutLabel')}
        isChecked={component.allowKitaFillOut}
        onChange={() => {
          setComponent({
            ...component,
            allowKitaFillOut: !component.allowKitaFillOut,
          });
        }}
      />
      <br />
      <Label type="input">{t('FormsEditor.InputType')}</Label>
      <RadioButton
        checked={component.inputType === 'dropdown'}
        label={t('FormsEditor.DropdownType')}
        onClick={() => {
          setComponent({
            ...component,
            inputType: 'dropdown',
          });
        }}
      />
      <RadioButton
        checked={component.inputType === 'radio'}
        label={t('FormsEditor.RadioType')}
        onClick={() => {
          setComponent({
            ...component,
            inputType: 'radio',
          });
        }}
      />
      <br />
      <Label type="input">{t('FormsEditor.ComponentEditorUniqueNameLabel')}</Label>
      <Input
        value={component.name}
        onChange={e => {
          setComponent({
            ...component,
            name: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorUniqueNamePlaceholder')}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorIndentLabel')}</Label>
      <Input
        value={component.indent}
        onChange={e => {
          setComponent({
            ...component,
            indent: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorIndentPlaceholder')}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorLabelLeftLabel')}</Label>
      <Input
        value={component.labelLeft}
        onChange={e => {
          setComponent({
            ...component,
            labelLeft: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorLabelLeftPlaceholder')}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorLabelRightLabel')}</Label>
      <Input
        value={component.labelRight}
        onChange={e => {
          setComponent({
            ...component,
            labelRight: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorLabelRightPlaceholder')}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorOptionsLabel')}</Label>
      <table>
        {component.options.map((op, i1) => {
          return (
            <tr>
              <td>
                <Input
                  value={op}
                  onChange={e => {
                    setComponent({
                      ...component,
                      options: component.options.map((op2, i2) => {
                        if (i1 === i2) {
                          return e.target.value;
                        }
                        return op2;
                      })
                    })
                  }}
                  placeholder={t('FormsEditor.ComponentEditorOptionsPlaceholder')}
                />
              </td>
              <td>
                <RadioButton
                  checked={i1 === component.defaultOption}
                  label={t('FormsEditor.ComponentEditorOptionsDefaultLabel')}
                  onClick={() => {
                    setComponent({
                      ...component,
                      defaultOption: i1,
                    });
                  }}
                />
              </td>
              <td>
                <a onClick={() => {
                  setComponent({
                    ...component,
                    defaultOption: component.defaultOption === i1 ? 0 : component.defaultOption,
                    options: component.options.filter((op2, i2) => {
                      return i2 !== i1;
                    })
                  });
                }}><i className='fa fa-trash' /></a>
              </td>
            </tr>
          );
        })}
      </table>
      <Button onClick={() => {
        setComponent({
          ...component,
          options: [...component.options, ""],
        })
      }}>
        {t('FormsEditor.ComponentEditorOptionsAddButton')}
      </Button>

      <Checkbox
        name={t('FormsEditor.ComponentEditorMandatoryLabel')}
        isChecked={component.mandatory}
        onChange={() => {
          setComponent({
            ...component,
            mandatory: !component.mandatory,
          });
        }}
      />
    </div>
  );
}
