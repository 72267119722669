import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import Loader from 'library/common/commonComponents/Loader';
import { importAdebis } from 'library/api/user';
import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './usersFrameImportPopup.module.scss';

export default function AdebisImport({ showBottomNotification }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [dontSendInvitationMail, setDontSendInvitationMail] = useState(false);

  const uploadFile = files => {
    const data = new FormData();
    data.append('file', files[0]);

    setIsLoading(true);

    importAdebis(data, dontSendInvitationMail)
      .then(() => {
        showBottomNotification(t('BottomNotifications.Success'));
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <h2>{t('Administration.Adebis import')}</h2>
      <br />
      <div>{t('Administration.Adebis import description')}</div>
      <br />
      {!isLoading ? (
        <><div className={styles.checkboxContainer}>
          <CheckBox
            className={styles.checkbox}
            name={t('Administration.Do not send Invitation Mail')}
            isChecked={dontSendInvitationMail}
            onChange={() => setDontSendInvitationMail(!dontSendInvitationMail)} />
        </div><div>
            <UploadButton onChange={uploadFile} isMultiple={false} className={styles.uploadButton}>
              &nbsp;{t('Administration.Select and upload Adebis Excel file')}
            </UploadButton>
          </div></>
      ) : (
        <div>
          {t('Administration.Upload is in progress, please wait until it finished')}
          <br />
          <Loader />
        </div>
      )}
    </>
  );
}
