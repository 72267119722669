import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'library/common/commonComponents/Inputs/Input';
import HelpText from 'library/common/commonComponents/HelpText';
import Dropdown from 'library/common/commonComponents/Dropdowns/Dropdown';
import { getAllKidsFromSelectedKita } from 'library/api/observationSheet';
import { downloadFile } from 'library/utilities/files';
import { getBookingTimesExcel } from 'library/api/kids';
import Loader from 'library/common/commonComponents/Loader';
import Button from 'library/common/commonComponents/Buttons/Button';
import UsersFrameChildOverviewTable from './UsersFrameChildOverviewFrames/UsersFrameChildOverviewTable';

import styles from './usersFrameChildOverview.module.scss';
import { useDropdown } from '../../../../../../library/common/commonHooks/useDropdown';
import MultiKidDeletePopup from './UsersFrameChildOverviewFrames/MultiKidDeletePopup';
import MultiKidTransferPopup from './UsersFrameChildOverviewFrames/MuitiKidTransferPopup';

export default function UsersFrameChildOverview({ activeKita }) {
  const [searchValue, setSearchValue] = useState('');
  const [filteredKids, setFilteredKids] = useState([]);
  const [kidData, setKidData] = useState([]);
  const { t } = useTranslation();

  const [selectedKids, setSelectedKids] = useState([]);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isTransferPopupOpen, setIsTransferPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { DropdownWrapper } = useDropdown(Dropdown);

  const loadKidsInKita = useCallback(async () => {
    setIsLoading(true);
    const res = await getAllKidsFromSelectedKita();
    setKidData(res.data);
    setFilteredKids(res.data);
    setIsLoading(false);
  }, []);

  const onBulkActionConfirm = () => {
    setSelectedKids([]);
    loadKidsInKita();
  };

  useEffect(() => {
    loadKidsInKita().then(() => {});
  }, [loadKidsInKita]);

  const kidActionDropdownOptions = [
    {
      title: t('Action.Delete Children'),
      onClick: () => setIsDeletePopupOpen(true),
    },
    {
      title: t('Action.Transfer Children'),
      onClick: () => setIsTransferPopupOpen(true),
    },
  ];

  const filterKids = filterValue => {
    setFilteredKids(
      kidData.filter(kd => {
        const { firstName, lastName, groupName, kidId } = kd;

        const s = filterValue.toLowerCase();

        return (
          firstName.toLowerCase().includes(s) ||
          lastName.toLowerCase().includes(s) ||
          groupName.toLowerCase().includes(s) ||
          kidId.toString().includes(s)
        );
      }),
    );
  };

  const onChangeSearch = e => {
    filterKids(e.target.value);
    setSearchValue(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Administration.KidOverviewTitle')}</div>
      <div className={styles.actionButtons}>
        <Button
          onClick={() => {
            setIsLoading(true);
            getBookingTimesExcel()
              .then(res => {
                downloadFile(res, 'Buchungszeiten.xlsx', 'application/vnd.ms-excel');
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          type='success'
          size='sm'
        >
          <i className='fa fa-file-excel-o' /> {t('Administration.Booking times')}
        </Button>
        <DropdownWrapper
          dropdownOptions={kidActionDropdownOptions}
          closeOnClick
          render={dropdown => (
            <div className={styles.dropdownContainer}>
              <Button size='sm'>
                {t('Kids.Actions')} <i className='fa fa-angle-down' />
              </Button>
              {dropdown}
            </div>
          )}
        />
      </div>
      <div className={styles.text}>
        <HelpText>{t('Administration.KidOverviewDescription')}</HelpText>
      </div>
      <div className={styles.filters}>
        <div className={styles.filtersSearch}>
          <Input
            value={searchValue}
            onChange={onChangeSearch}
            className={styles.filtersSearchInput}
            placeholder={t('Administration.KidOverview.Input placeholder')}
          />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <UsersFrameChildOverviewTable
          kids={searchValue.length === 0 ? kidData : filteredKids}
          activeKita={activeKita}
          selectedKids={selectedKids}
          setSelectedKids={setSelectedKids}
        />
      )}
      <MultiKidDeletePopup
        activeKita={activeKita}
        isOpen={isDeletePopupOpen}
        setOpen={setIsDeletePopupOpen}
        selectedKids={selectedKids}
        refresh={onBulkActionConfirm}
      />
      <MultiKidTransferPopup
        activeKita={activeKita}
        isOpen={isTransferPopupOpen}
        setOpen={setIsTransferPopupOpen}
        selectedKids={selectedKids}
        refresh={onBulkActionConfirm}
      />
    </div>
  );
}
