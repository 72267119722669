import React from 'react';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import SuggestionBox from '../../library/common/commonComponents/SuggestionBox';

export default function DashboardSuggestionBox({ showBottomNotification, user, activeKita }) {
  useSiteTitle('Suggestion Box');

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <SuggestionBox
          user={user}
          showBottomNotification={showBottomNotification}
          activeKita={activeKita}
        />
      </Wrapper>
    </KitaPanelWrapper>
  );
}
