import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ChatMessagesViewFooter from '.';

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = {
  showBottomNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatMessagesViewFooter);
