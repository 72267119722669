import React from 'react';
import cn from 'classnames';

import { useDropdown } from 'library/common/commonHooks/useDropdown';
import Dropdown from 'library/common/commonComponents/Dropdowns/Dropdown';

import styles from './moreBtn.module.scss';

export default function MoreBtn({
  dropdownOptions = [],
  faIcon = 'fa-angle-down',
  className,
  children,
}) {
  const { isOpened, DropdownWrapper } = useDropdown(Dropdown);

  return (
    dropdownOptions.length > 0 && (
      <DropdownWrapper
        dropdownOptions={dropdownOptions}
        closeOnClick
        theme='dark'
        render={dropdown => (
          <div className={cn(styles.moreBtn, className, { [styles.active]: isOpened })}>
            {faIcon !== null && <i className={cn('fa', faIcon)} />}
            {children}
            {dropdown}
          </div>
        )}
      />
    )
  );
}
