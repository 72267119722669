import React, { useState, useEffect, useRef } from 'react';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';

export default function CaptureGeneralInformation({ changeCurrentPage, handleTextStateChange, formData, type }) {
  return (<>
    <b>Anmeldedaten eingeben:</b><br />
    <table>
      <tr>
        <td>Vorname des Kindes</td>
        <td>
          <Input
            type='text'
            value={formData.kidFirstName}
            onChange={e => handleTextStateChange(e.target.value, 'kidFirstName')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Nachname des Kindes</td>
        <td>
          <Input
            type='text'
            value={formData.kidLastName}
            onChange={e => handleTextStateChange(e.target.value, 'kidLastName')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Geburtsdatum des Kindes</td>
        <td>
          <Input
            type='date'
            value={formData.kidBirthday}
            onChange={e => handleTextStateChange(e.target.value, 'kidBirthday')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Straße und Hausnummer</td>
        <td>
          <Input
            type='text'
            value={formData.streetAndHouseNo}
            onChange={e => handleTextStateChange(e.target.value, 'streetAndHouseNo')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>PLZ und Ort</td>
        <td>
          <Input
            type='text'
            value={formData.zipAndCity}
            onChange={e => handleTextStateChange(e.target.value, 'zipAndCity')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Schule des Kindes</td>
        <td>
          <Input
            type='text'
            value={formData.kidSchool}
            onChange={e => handleTextStateChange(e.target.value, 'kidSchool')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Klasse</td>
        <td>
          <Input
            type='text'
            value={formData.kidClass}
            onChange={e => handleTextStateChange(e.target.value, 'kidClass')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Telefonnummer eines Erziehungsberechtigten</td>
        <td>
          <Input
            type='text'
            value={formData.legalGuardianPhone}
            onChange={e => handleTextStateChange(e.target.value, 'legalGuardianPhone')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Mail-Adresse eines Erziehungsberechtigten</td>
        <td>
          <Input
            type='text'
            value={formData.legalGuardianMail}
            onChange={e => handleTextStateChange(e.target.value, 'legalGuardianMail')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Name der Mutter</td>
        <td>
          <Input
            type='text'
            value={formData.motherName}
            onChange={e => handleTextStateChange(e.target.value, 'motherName')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Religion der Mutter</td>
        <td>
          <Input
            type='text'
            value={formData.motherReligion}
            onChange={e => handleTextStateChange(e.target.value, 'motherReligion')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Name des Vaters</td>
        <td>
          <Input
            type='text'
            value={formData.fatherName}
            onChange={e => handleTextStateChange(e.target.value, 'fatherName')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Religion des Vaters</td>
        <td>
          <Input
            type='text'
            value={formData.fatherReligion}
            onChange={e => handleTextStateChange(e.target.value, 'fatherReligion')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
      <tr>
        <td>Besondere Bedürfnisse des Kindes</td>
        <td>
          <Input
            type='text'
            value={formData.kidSpecialNeeds}
            onChange={e => handleTextStateChange(e.target.value, 'kidSpecialNeeds')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </td>
      </tr>
    </table>
    <br />
    <Button onClick={() => {
      changeCurrentPage(-1);
    }}>Zurück</Button>
    &nbsp;
    <Button onClick={() => {
      changeCurrentPage(1);
    }} type='primary'>Weiter</Button>
    <br />
    <br />
  </>)
}