import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Card from 'library/common/commonComponents/Card';
import useEditor from 'library/common/commonHooks/useEditor';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import useBlockShowing from 'library/common/commonHooks/useBlockShowing';
import { checkLink } from 'library/utilities/checkLink';
import { createDPP, createImprint } from 'library/api/kita';

import styles from './createDataprotectionInput.module.scss';

export default function CreateDataprotectionInput({
  groupId,
  onCreate,
  showBottomNotification,
  text,
  setIsEditing,
  setFunction,
  option,
  type,
}) {
  const { t } = useTranslation();

  const { blockClassNames, showBlock, hideBlock } = useBlockShowing();

  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(false);

  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = text;

  const { Editor, getEditorValue, updateValue } = useEditor({
    placeholder: t('Editor.Write your own'),
    message: wrappedMessage,
    onChange: () => {
      const value = getEditorValue();
      if (value.textContent.trim() !== '' && !value.querySelector('img')) {
        setError(null);
      }
    },
    groupId,
  });

  async function onSubmit() {
    if (isSending) {
      return;
    }
    const editorValue = getEditorValue();

    if (editorValue.textContent.trim() === '' && !editorValue.querySelector('img')) {
      return setError(t('Post.Message cannot be blank'));
    }

    setError(null);
    setIsSending(true);

    try {
      const post = {
        text: checkLink(editorValue),
        setting: option,
      };

      let { data } = [];
      if (type === 'dPP') {
        data = await createDPP(post);
      } else if (type === 'imprint') {
        data = await createImprint(post);
      }

      setFunction(data);
      if (onCreate) {
        onCreate({
          ...post,
          id: data.id,
          noRepeatAfterError: true,
          isTranslationAllowed: data.isTranslationAllowed,
        });
      }

      hideBlock();
      updateValue(document.createElement('div'));
    } catch (ex) {
      // eslint-disable-next-line
      if (ex && ex.response && ex.response.status === 413) {
        showBottomNotification(t('FileManager.Size error'), { isFail: true });
      } else if (ex && ex.response && ex.response.data && ex.response.data.message) {
        setError(t(ex.response.data.message));
      } else {
        setError('Something went wrong. Try again later');
      }
    } finally {
      setIsSending(false);
      setIsEditing(false);
      window.location.reload();
    }
  }

  return (
    <Card onClick={showBlock} onFocus={showBlock}>
      <div className={styles.body}>
        <Editor />
        {error && <div className={styles.error}>{error}</div>}
        <div className={classNames(...blockClassNames)}>
          <div className={styles.buttons}>
            <div className={styles.buttonsLeft}>
              <ButtonWithLoader type='info' onClick={onSubmit} isLoading={isSending}>
                {t('Editor.Submit')}
              </ButtonWithLoader>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
