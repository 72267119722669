import { batch } from 'react-redux';

import * as at from 'library/common/commonConstants/userConstants';
import store from 'main/store/configureStore';
import axiosInstance from 'library/api/axios';
import { getUsersByNameForChat } from 'library/api/tagging';
import Storage from 'library/utilities/storage';
import { updateLanguageTimezone } from 'library/api/user';
import { getGoogleTranslatorModuleEnabled } from 'library/common/commonSelectors/kitaSelectors';
import { updateUserGoogleLanguage } from '../../api/settings';

export const getUserWatsonConsent = async (dispatch, lang) =>
  axiosInstance.get(at.URLS.watsonConsent).then(res => {
    dispatch(changeWatsonUserConsent(res.data));
    if (!res.data) {
      if (lang && lang !== 'null') {
        updateUserGoogleLanguage(null);
      }
      dispatch(changeWatsonLanguage(null));
    }
  });

export const getUserWatsonLanguage = () => async dispatch => {
  return axiosInstance.get(at.URLS.watsonLanguage).then(res => {
    let watsonLang = null;
    if (res.data && res.data.langCode) {
      if (res.data.langCode !== 'null') {
        watsonLang = res.data.langCode.toLocaleLowerCase();
      }
    }

    getUserWatsonConsent(dispatch, watsonLang);

    if (getGoogleTranslatorModuleEnabled(store.getState())) {
      dispatch(changeWatsonLanguage(watsonLang));
    }

    return res;
  });
};

export const getUserStaticLanguage = async () => {
  axiosInstance.get(at.URLS.staticLanguage).then(res => {
    if (Storage.getItem('localeId') && Storage.getItem('localeId') !== res.data.langCode) {
      updateLanguageTimezone({
        languageId: Storage.getItem('localeId'),
      }).then(() => {
        Storage.removeItem('localeId');
      });
    }
    return res;
  });
};

export const loadUserInfo = token => async dispatch => {
  if (token) {
    return axiosInstance.get(at.URLS.profile).then(res => {
      batch(() => {
        dispatch({
          type: at.SET_USER_INFO,
          userInfo: res.data,
        });
        dispatch(getUserWatsonLanguage());
      });
      return res;
    });
  }
};

export const changeWatsonLanguage = language => dispatch =>
  dispatch({
    type: at.SET_WATSON_LANGUAGE,
    language,
  });

export const changeWatsonUserConsent = consent => dispatch =>
  dispatch({
    type: at.SET_WATSON_USER_CONSENT,
    consent,
  });

export const changeWatsonLanguages = languages => dispatch =>
  dispatch({
    type: at.SET_WATSON_LANGUAGES,
    languages,
  });

export const patchUserInfo = userInfo => dispatch =>
  dispatch({
    type: at.SET_USER_INFO,
    userInfo,
  });

export const getUsersByName = ({ name, groupId }, { groupInvite }) => {
  return axiosInstance
    .get(`${at.URLS.search}/${name}`, {
      params: {
        groupId,
        groupInvite,
      },
    })
    .then(res => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          return false;
      }
    });
};

export const getUsersByNameWithAlreadyUsers = payload => {
  return getUsersByNameForChat(payload).then(res => {
    switch (res.status) {
      case 200:
        return res.data;
      default:
        return false;
    }
  });
};

export const setUserPermissions = data => dispatch =>
  dispatch({
    type: at.SET_USER_PERMISSIONS,
    data,
  });

export const setSelectedUsers = data => dispatch =>
  dispatch({
    type: at.SET_SELECTED_USERS,
    data,
  });

export default loadUserInfo;
