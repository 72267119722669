import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'library/common/commonComponents/Inputs/Input';
import Authentication2FA from 'modules/AccountSettings/accountSettingsFrames/Authentication2FA';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import { getMailSubjectConfig, updateMailSubjectConfig } from 'library/api/modules';

import styles from './general-settings.module.scss';

function GeneralSettings({ user }) {
  const { t } = useTranslation();

  const [subject, setSubject] = useState('Kita-App');
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSubject = event => {
    setSubject(event.target.value);
  };

  const handleUpdateSubject = async () => {
    try {
      setIsLoading(true);
      await updateMailSubjectConfig(subject);
      store.dispatch(showBottomNotification(t('BottomNotifications.Saved')));
    } catch (error) {
      store.dispatch(
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = (await getMailSubjectConfig()).data;
        if (data && data.subject) {
          setSubject(data.subject);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={styles.generalSettingsContainer}>
      {user.superAdminStatus && (
        <div className={styles.formSettingGroups}>
          <div className={styles.formSettingLabel}>{t('GeneralSetting.MailSubject')}</div>
          <div className={styles.settingWrapper}>
            <Input value={subject} onChange={handleChangeSubject} maxLength={255} />
            <ButtonWithLoader onClick={() => handleUpdateSubject()} isLoading={isLoading}>
              {t('Popup.Save')}
            </ButtonWithLoader>
          </div>
        </div>
      )}
      <Authentication2FA />
    </div>
  );
}

export default GeneralSettings;
