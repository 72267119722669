import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Card from 'library/common/commonComponents/Card';
import Button from 'library/common/commonComponents/Buttons/Button';
import MembersItem from './membersFrames/MembersItem';

import styles from './members.module.scss';

function Members({ title, uniqName, members, loadMore, loading, noMoreMembers, user, groupInfo }) {
  const { t } = useTranslation();

  if (!user.administrationAccess && !user.employee && !groupInfo.groupAdminStatus) {
    members = members.filter(obj => obj.user.hideUser === false);
  }


  return (
    <Card title={title} uniqName={uniqName} isToggleable>
      <div className={styles.wrapper}>
        {members.map((member, index) => (
          <MembersItem member={member.user} key={`${member.user.id}_${index + 1}`} />
        ))}
        {noMoreMembers === false && (
          <Button
            type='light'
            onClick={loading === false ? () => loadMore() : () => false}
            className={cn(styles.addMoreButton, true && styles.addMoreButton__loading)}
          >
            {loading ? (
              <div>
                <span />
                <span />
                <span />
              </div>
            ) : (
              t('Group.LoadMore')
            )}
          </Button>
        )}
      </div>
    </Card>
  );
}


export default connect(
  state => ({
    user: state.userReducer,
  }),
)(Members);
