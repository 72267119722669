import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import {
  getAllKidsFromSelectedKita,
  getAllOwnKidsFromSelectedKita,
} from 'library/api/observationSheet';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import Dictaphone from 'library/common/commonComponents/Dictaphone';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import RadioButton from 'library/common/commonComponents/RadioButton';
import { createPDFNannyContractAdjustments } from 'library/utilities/files';
import { removeWhiteBackgroundSignature } from 'library/utilities/signature';
import store from 'main/store/configureStore';
import { onGfzFormSubmitted } from '../util.js';

import gfzLogo from 'resources/images/gfz/gfz_logo.png';

export default function NannyContractAdjustments({ isAdminOrEmployee, userId, kidId, kitaName }) {
  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);

  const [nannyContractAdjustments, setNannyContractAdjustments] = useState({
    responsiblePerson: '',
    cityDistrict: '',
    validFrom: '',
    limited: false,
    limitedUntil: '',
    regularCareTimes: true,
    careTimes: [
      {
        mondayFrom: '',
        tuesdayFrom: '',
        wednesdayFrom: '',
        thursdayFrom: '',
        fridayFrom: '',
        saturdayFrom: '',
        sundayFrom: '',
        mondayTo: '',
        tuesdayTo: '',
        wednesdayTo: '',
        thursdayTo: '',
        fridayTo: '',
        saturdayTo: '',
        sundayTo: '',
      },
    ],
    irregularCareTimesWeeklyHours: 0,
    irregularCareTimesText: '',
    dutyOfSupervision: false,
    specialNotes: '',
    parent1Signature: null,
    parent1SignatureDate: new Date().toISOString().split('T')[0],
    parent2Signature: null,
    parent2SignatureDate: new Date().toISOString().split('T')[0],
    kitaSignature: null,
    kitaSignatureDate: new Date().toISOString().split('T')[0],
    kidId: null,
  });

  const getKitaKidsFunction = isAdminOrEmployee
    ? getAllKidsFromSelectedKita
    : getAllOwnKidsFromSelectedKita;

  const sigCanvasParent1 = useRef();
  const sigCanvasParent2 = useRef();
  const sigCanvasKita = useRef();

  const user = useSelector(state => state.userReducer);

  const { t } = useTranslation();

  const [isParentOfKid, setIsParentOfKid] = useState(false);

  useEffect(() => {
    setIsParentOfKid(
      selectedKid &&
      selectedKid !== [] &&
      user.kids &&
      user.kids.find(k => k.id === selectedKid.id),
    );
    sigCanvasParent1.current.clear();
    sigCanvasParent2.current.clear();
    sigCanvasKita.current.clear();
  }, [selectedKid]);

  useEffect(() => {
    if (isParentOfKid) {
      sigCanvasParent1.current.on();
      sigCanvasParent2.current.on();
    } else {
      sigCanvasParent1.current.off();
      sigCanvasParent2.current.off();
    }

    if (isAdminOrEmployee) {
      sigCanvasKita.current.on();
    } else {
      sigCanvasKita.current.off();
    }
  }, [isParentOfKid]);

  useEffect(() => {
    if (kidId != null || typeof kidId !== 'undefined') {
      getKitaKidsFunction().then(data => {
        const unformattedKidsList = data.data;
        const formattedKidsList = unformattedKidsList.map(obj => {
          return {
            label: obj.firstName + ' ' + obj.lastName,
            id: obj.kidId,
            groups: obj.groups,
            kidGroup: obj.kidGroup,
          };
        });
        setKidsList(formattedKidsList);
      });
    }
    setNannyContractAdjustments(prev => ({
      ...prev,
      cityDistrict: kitaName,
    }));
  }, []);

  useEffect(() => {
    if (kidId != null && typeof kidId !== 'undefined' && selectedKid.length === 0) {
      getKitaKidsFunction().then(data => {
        const unformattedKidsList = data.data;
        const formattedKidsList = unformattedKidsList.map(obj => {
          return { label: obj.firstName + ' ' + obj.lastName, id: obj.kidId, groups: obj.groups };
        });
        setKidsList(formattedKidsList);
        const kidToSelect = formattedKidsList.filter(obj => obj.id === kidId);
        if (kidToSelect.length !== 1) {
          return;
        }

        setSelectedKid(kidToSelect[0]);
        setFormDisabled(false);
      });
    }
  }, [kidId, isAdminOrEmployee]);

  const resetForm = () => {
    setNannyContractAdjustments({
      responsiblePerson: '',
      cityDistrict: '',
      validFrom: '',
      limited: false,
      limitedUntil: '',
      regularCareTimes: true,
      careTimes: [
        {
          mondayFrom: '',
          tuesdayFrom: '',
          wednesdayFrom: '',
          thursdayFrom: '',
          fridayFrom: '',
          saturdayFrom: '',
          sundayFrom: '',
          mondayTo: '',
          tuesdayTo: '',
          wednesdayTo: '',
          thursdayTo: '',
          fridayTo: '',
          saturdayTo: '',
          sundayTo: '',
        },
      ],
      irregularCareTimesWeeklyHours: 0,
      irregularCareTimesText: '',
      dutyOfSupervision: false,
      specialNotes: '',
      parent1Signature: null,
      parent1SignatureDate: new Date().toISOString().split('T')[0],
      parent2Signature: null,
      parent2SignatureDate: new Date().toISOString().split('T')[0],
      kitaSignature: null,
      kitaSignatureDate: new Date().toISOString().split('T')[0],
      kidId: null,
    });
    setSelectedKid([]);
    sigCanvasParent1.current.clear();
    sigCanvasParent2.current.clear();
    sigCanvasKita.current.clear();
  };

  const handleTextStateChange = (newValue, property) => {
    setNannyContractAdjustments(prev => ({
      ...prev,
      [property]: newValue,
    }));
  };

  const setSpecialNotesText = text => {
    setNannyContractAdjustments(prev => ({
      ...prev,
      specialNotes: `${prev.specialNotes} ${text}`,
    }));
  };

  function renderCareTimesRow(careTime, index) {
    return (
      <>
        <tr>
          <td>{t('GFZForms.NannyContractAdjustmentsFrom')}</td>
          <td>
            <Input
              id={`monFrom${index}`}
              type='text'
              maxLength={5}
              value={careTime.mondayFrom}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          mondayFrom: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  const prevRowElem = document.getElementById(`sunTo${index - 1}`);
                  if (prevRowElem) {
                    prevRowElem.focus();
                  } else {
                    document
                      .getElementById(`sunTo${nannyContractAdjustments.careTimes.length - 1}`)
                      .focus();
                  }
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`monTo${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`tueFrom${index}`}
              type='text'
              maxLength={5}
              value={careTime.tuesdayFrom}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          tuesdayFrom: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`monTo${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`tueTo${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`wedFrom${index}`}
              type='text'
              maxLength={5}
              value={careTime.wednesdayFrom}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          wednesdayFrom: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`tueTo${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`wedTo${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`thuFrom${index}`}
              type='text'
              maxLength={5}
              value={careTime.thursdayFrom}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          thursdayFrom: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`wedTo${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`thuTo${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`friFrom${index}`}
              type='text'
              maxLength={5}
              value={careTime.fridayFrom}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          fridayFrom: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`thuTo${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`friTo${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`satFrom${index}`}
              type='text'
              maxLength={5}
              value={careTime.saturdayFrom}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          saturdayFrom: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`friTo${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`satTo${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`sunFrom${index}`}
              type='text'
              maxLength={5}
              value={careTime.sundayFrom}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          sundayFrom: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`satTo${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`sunTo${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZForms.NannyContractAdjustmentsUntil')}</td>
          <td>
            <Input
              id={`monTo${index}`}
              type='text'
              maxLength={5}
              value={careTime.mondayTo}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          mondayTo: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`monFrom${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`tueFrom${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`tueTo${index}`}
              type='text'
              maxLength={5}
              value={careTime.tuesdayTo}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          tuesdayTo: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`tueFrom${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`wedFrom${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`wedTo${index}`}
              type='text'
              maxLength={5}
              value={careTime.wednesdayTo}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          wednesdayTo: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`wedFrom${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`thuFrom${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`thuTo${index}`}
              type='text'
              maxLength={5}
              value={careTime.thursdayTo}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          thursdayTo: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`thuFrom${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`friFrom${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`friTo${index}`}
              type='text'
              maxLength={5}
              value={careTime.fridayTo}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          fridayTo: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`friFrom${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`satFrom${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`satTo${index}`}
              type='text'
              maxLength={5}
              value={careTime.saturdayTo}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          saturdayTo: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`satFrom${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`sunFrom${index}`).focus();
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Input
              id={`sunTo${index}`}
              type='text'
              maxLength={5}
              value={careTime.sundayTo}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.map((i, ind) => {
                      if (ind === index) {
                        return {
                          ...i,
                          sundayTo: target.value,
                        };
                      }

                      return i;
                    }),
                  };
                })
              }
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`sunFrom${index}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  const nextRowElem = document.getElementById(`monFrom${index + 1}`);
                  if (nextRowElem) {
                    nextRowElem.focus();
                  } else {
                    document.getElementById(`monFrom0`).focus();
                  }
                  e.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <Button
              onClick={() => {
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    careTimes: prev.careTimes.filter((i, ind) => ind !== index),
                  };
                });
              }}
            >
              <i className='fa fa-minus' />
            </Button>
          </td>
        </tr>
      </>
    );
  }

  function addCareTimesRow() {
    setNannyContractAdjustments(prev => {
      return {
        ...prev,
        careTimes: [
          ...prev.careTimes,
          {
            mondayFrom: '',
            tuesdayFrom: '',
            wednesdayFrom: '',
            thursdayFrom: '',
            fridayFrom: '',
            saturdayFrom: '',
            sundayFrom: '',
            mondayTo: '',
            tuesdayTo: '',
            wednesdayTo: '',
            thursdayTo: '',
            fridayTo: '',
            saturdayTo: '',
            sundayTo: '',
          },
        ],
      };
    });
  }

  function calculateHoursPerWeekHelper(from, to) {
    if (from === '' && to === '') {
      return 0;
    }

    const minutesFrom = timeStringToMinutes(from);
    const minutesTo = timeStringToMinutes(to);

    return minutesTo - minutesFrom;
  }

  function calculateMinutesPerWeek() {
    let minutesSum = 0;

    nannyContractAdjustments.careTimes.forEach(ct => {
      minutesSum += calculateHoursPerWeekHelper(ct.mondayFrom, ct.mondayTo);
      minutesSum += calculateHoursPerWeekHelper(ct.tuesdayFrom, ct.tuesdayTo);
      minutesSum += calculateHoursPerWeekHelper(ct.wednesdayFrom, ct.wednesdayTo);
      minutesSum += calculateHoursPerWeekHelper(ct.thursdayFrom, ct.thursdayTo);
      minutesSum += calculateHoursPerWeekHelper(ct.fridayFrom, ct.fridayTo);
      minutesSum += calculateHoursPerWeekHelper(ct.saturdayFrom, ct.saturdayTo);
      minutesSum += calculateHoursPerWeekHelper(ct.sundayFrom, ct.sundayTo);
    });

    return minutesSum;
  }

  function calculateHoursPerWeek() {
    const minutesSum = calculateMinutesPerWeek();

    const hours = Math.floor(minutesSum / 60);
    const minutes = minutesSum % 60;

    return `${hours.toString(10).padStart(2, '0')}:${minutes.toString(10).padStart(2, '0')}`;
  }

  function checkTimeFormatsHelper(time) {
    return time === '' || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
  }

  function checkTimeFormats() {
    const wrongFormats = nannyContractAdjustments.careTimes.filter(ct => {
      return !(
        checkTimeFormatsHelper(ct.mondayFrom) &&
        checkTimeFormatsHelper(ct.tuesdayFrom) &&
        checkTimeFormatsHelper(ct.wednesdayFrom) &&
        checkTimeFormatsHelper(ct.thursdayFrom) &&
        checkTimeFormatsHelper(ct.fridayFrom) &&
        checkTimeFormatsHelper(ct.saturdayFrom) &&
        checkTimeFormatsHelper(ct.sundayFrom) &&
        checkTimeFormatsHelper(ct.mondayTo) &&
        checkTimeFormatsHelper(ct.tuesdayTo) &&
        checkTimeFormatsHelper(ct.wednesdayTo) &&
        checkTimeFormatsHelper(ct.thursdayTo) &&
        checkTimeFormatsHelper(ct.fridayTo) &&
        checkTimeFormatsHelper(ct.saturdayTo) &&
        checkTimeFormatsHelper(ct.sundayTo)
      );
    });

    return wrongFormats.length === 0;
  }

  function checkFromTimesHaveToTimesHelper(from, to) {
    return (from === '' && to === '') || (from !== '' && to !== '');
  }

  function checkFromTimesHaveToTimes() {
    const errors = nannyContractAdjustments.careTimes.filter(ct => {
      return !(
        checkFromTimesHaveToTimesHelper(ct.mondayFrom, ct.mondayTo) &&
        checkFromTimesHaveToTimesHelper(ct.tuesdayFrom, ct.tuesdayTo) &&
        checkFromTimesHaveToTimesHelper(ct.wednesdayFrom, ct.wednesdayTo) &&
        checkFromTimesHaveToTimesHelper(ct.thursdayFrom, ct.thursdayTo) &&
        checkFromTimesHaveToTimesHelper(ct.fridayFrom, ct.fridayTo) &&
        checkFromTimesHaveToTimesHelper(ct.saturdayFrom, ct.saturdayTo) &&
        checkFromTimesHaveToTimesHelper(ct.sundayFrom, ct.sundayTo)
      );
    });

    return errors.length === 0;
  }

  function timeStringToMinutes(timeString) {
    const parts = timeString.split(':');

    return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
  }

  function checkFromTimesBeforeToTimesHelper(from, to) {
    if (from === '') return true;

    const minutesFrom = timeStringToMinutes(from);
    const minutesTo = timeStringToMinutes(to);

    return minutesTo > minutesFrom;
  }

  function checkFromTimesBeforeToTimes() {
    const errors = nannyContractAdjustments.careTimes.filter(ct => {
      return !(
        checkFromTimesBeforeToTimesHelper(ct.mondayFrom, ct.mondayTo) &&
        checkFromTimesBeforeToTimesHelper(ct.tuesdayFrom, ct.tuesdayTo) &&
        checkFromTimesBeforeToTimesHelper(ct.wednesdayFrom, ct.wednesdayTo) &&
        checkFromTimesBeforeToTimesHelper(ct.thursdayFrom, ct.thursdayTo) &&
        checkFromTimesBeforeToTimesHelper(ct.fridayFrom, ct.fridayTo) &&
        checkFromTimesBeforeToTimesHelper(ct.saturdayFrom, ct.saturdayTo) &&
        checkFromTimesBeforeToTimesHelper(ct.sundayFrom, ct.sundayTo)
      );
    });

    return errors.length === 0;
  }

  return (
    <>
      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} />
      <br />
      <br />
      <h2>{t('GFZForms.NannyContractAdjustmentsContractAdjustment')}</h2>
      <br />
      <table>
        <tr>
          <td>{t('GFZForms.NannyContractAdjustmentsKidName')}</td>
          <td>
            <Select
              options={kidsList}
              onSelect={e => {
                setSelectedKid(e);
                setFormDisabled(false);
              }}
              selected={selectedKid}
              style={{ zIndex: '10000000000' }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZForms.NannyContractAdjustmentsCaregiver')}</td>
          <td>
            <Input
              type='text'
              maxLength={50}
              value={nannyContractAdjustments.responsiblePerson}
              disabled={formDisabled}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    responsiblePerson: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZForms.NannyContractAdjustmentsUrbanDistrict')}</td>
          <td>{nannyContractAdjustments.cityDistrict}</td>
        </tr>
        <tr>
          <td>{t('GFZForms.NannyContractAdjustmentsValidFrom')}</td>
          <td>
            <Input
              type='date'
              value={nannyContractAdjustments.validFrom}
              disabled={formDisabled}
              min={new Date().toISOString().split('T')[0]}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    validFrom: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>
            <RadioButton
              checked={!nannyContractAdjustments.limited}
              disabled={formDisabled}
              label={t('GFZForms.NannyContractAdjustmentsUnlimited')}
              onClick={() => {
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    limited: false,
                  };
                });
              }}
            />
          </td>
          <td />
        </tr>
        <tr>
          <td>
            <RadioButton
              checked={nannyContractAdjustments.limited}
              disabled={formDisabled}
              label={t('GFZForms.NannyContractAdjustmentsLimitedTo')}
              onClick={() => {
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    limited: true,
                  };
                });
              }}
            />
            <Input
              type='date'
              value={nannyContractAdjustments.limitedUntil}
              disabled={formDisabled}
              min={new Date().toISOString().split('T')[0]}
              onChange={({ target }) =>
                setNannyContractAdjustments(prev => {
                  return {
                    ...prev,
                    limitedUntil: target.value,
                  };
                })
              }
            />
          </td>
          <td />
        </tr>
      </table>

      <h2 style={{ marginTop: '10px' }}>Betreuungsumfang</h2>

      <RadioButton
        checked={nannyContractAdjustments.regularCareTimes}
        disabled={formDisabled}
        label={t('GFZForms.NannyContractAdjustmentsCareTimes')}
        onClick={() => {
          setNannyContractAdjustments(prev => {
            return {
              ...prev,
              regularCareTimes: true,
            };
          });
        }}
      />

      {nannyContractAdjustments.regularCareTimes && (
        <div>
          <table>
            <tr>
              <th />
              <th>{t('GFZForms.NannyContractAdjustmentsMonday')}</th>
              <th>{t('GFZForms.NannyContractAdjustmentsTuesday')}</th>
              <th>{t('GFZForms.NannyContractAdjustmentsWednesday')}</th>
              <th>{t('GFZForms.NannyContractAdjustmentsThursday')}</th>
              <th>{t('GFZForms.NannyContractAdjustmentsFriday')}</th>
              <th>{t('GFZForms.NannyContractAdjustmentsSaturday')}</th>
              <th>{t('GFZForms.NannyContractAdjustmentsSunday')}</th>
              <th />
            </tr>
            {nannyContractAdjustments.careTimes.map((ct, i) => {
              return renderCareTimesRow(ct, i);
            })}
          </table>
          <br />
          {checkTimeFormats() ? (
            <>
              {!checkFromTimesHaveToTimes() ? (
                <>
                  <p style={{ color: 'red' }}>{t('GFZForms.NannyContractAdjustmentsEnterTime')}</p>
                  <br />
                </>
              ) : !checkFromTimesBeforeToTimes() ? (
                <>
                  <p style={{ color: 'red' }}>
                    Die &quot;von&quot;-Zeit muss vor der &quot;bis&quot;-Zeit liegen
                  </p>
                  <br />
                </>
              ) : (
                <p style={{ fontWeight: 'bold' }}>
                  Anzahl Stunden pro Woche: {calculateHoursPerWeek()}
                </p>
              )}
            </>
          ) : (
            <>
              <p style={{ color: 'red' }}>
                {t('GFZForms.NannyContractAdjustmentsEnterValidTimes')}
              </p>
              <br />
            </>
          )}
          <br />
          <Button onClick={addCareTimesRow}>
            <i className='fa fa-plus' /> Eintrag hinzufügen
          </Button>
        </div>
      )}

      <br />

      <RadioButton
        checked={!nannyContractAdjustments.regularCareTimes}
        disabled={formDisabled}
        label={t('GFZForms.NannyContractAdjustmentsIrregularTimes')}
        onClick={() => {
          setNannyContractAdjustments(prev => {
            return {
              ...prev,
              regularCareTimes: false,
            };
          });
        }}
      />

      {!nannyContractAdjustments.regularCareTimes && (
        <div>
          <p>{t('GFZForms.NannyContractAdjustmentsHoursPerWeek')}</p>
          <Input
            type='text'
            value={nannyContractAdjustments.irregularCareTimesWeeklyHours}
            disabled={formDisabled}
            onChange={({ target }) =>
              setNannyContractAdjustments(prev => {
                return {
                  ...prev,
                  irregularCareTimesWeeklyHours: target.value,
                };
              })
            }
          />

          <p style={{ marginTop: '5px' }}>Bemerkungen:</p>
          <Input
            type='text'
            value={nannyContractAdjustments.irregularCareTimesText}
            disabled={formDisabled}
            onChange={({ target }) =>
              setNannyContractAdjustments(prev => {
                return {
                  ...prev,
                  irregularCareTimesText: target.value,
                };
              })
            }
          />
        </div>
      )}

      <br />
      <br />

      <h2>{t('GFZForms.NannyContractAdjustmentsDutyOfSupervision')}</h2>

      <RadioButton
        checked={
          (nannyContractAdjustments.regularCareTimes && calculateMinutesPerWeek() >= 1500) ||
          (!nannyContractAdjustments.regularCareTimes &&
            nannyContractAdjustments.irregularCareTimesWeeklyHours >= 25)
        }
        label={t('GFZForms.NannyContractAdjustmentsYes')}
        onClick={() => {
          setNannyContractAdjustments(prev => {
            return {
              ...prev,
              dutyOfSupervision: true,
            };
          });
        }}
        disabled={true}
      />
      <RadioButton
        checked={
          (!nannyContractAdjustments.regularCareTimes || calculateMinutesPerWeek() < 1500) &&
          (nannyContractAdjustments.regularCareTimes ||
            nannyContractAdjustments.irregularCareTimesWeeklyHours < 25)
        }
        label={t('GFZForms.NannyContractAdjustmentsNo')}
        onClick={() => {
          setNannyContractAdjustments(prev => {
            return {
              ...prev,
              dutyOfSupervision: false,
            };
          });
        }}
        disabled={true}
      />

      <br />
      <br />

      <h2>{t('GFZForms.NannyContractAdjustmentsSpecialAgreements')}</h2>

      <div style={{ position: 'relative', left: '5px', width: '55px' }}>
        <Dictaphone disabled={formDisabled} insText={setSpecialNotesText} />
      </div>
      <br />
      <br />
      <TextArea
        value={nannyContractAdjustments.specialNotes}
        disabled={formDisabled}
        onChange={e => handleTextStateChange(e.target.value, 'specialNotes')}
        type='text'
        placeholder={t('GFZForms.NannyContractAdjustmentsTextAreaPlaceholder')}
      />

      <br />
      <br />

      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GFZForms.NannyContractAdjustmentsParent1Signature')}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasParent1.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasParent1}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={nannyContractAdjustments.parent1SignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled
            onChange={({ target }) =>
              setNannyContractAdjustments(prev => {
                return {
                  ...prev,
                  parent1SignatureDate: target.value,
                };
              })
            }
          />
        </div>
      </div>
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GFZForms.NannyContractAdjustmentsParent2Signature')}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasParent2.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasParent2}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={nannyContractAdjustments.parent2SignatureDate}
            min={new Date().toISOString().split('T')[0]}
            onChange={({ target }) =>
              setNannyContractAdjustments(prev => {
                return {
                  ...prev,
                  parent2SignatureDate: target.value,
                };
              })
            }
          />
        </div>
      </div>
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          Vermittlerin Tagesfamilien:{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasKita.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasKita}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={nannyContractAdjustments.kitaSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled
            onChange={({ target }) =>
              setNannyContractAdjustments(prev => {
                return {
                  ...prev,
                  kitaSignatureDate: target.value,
                };
              })
            }
          />
        </div>
      </div>
      <br />
      <Button
        onClick={() => {
          // Deployment trigger
          const pdfSaveData = {
            nannyContractAdjustments,
            selectedKid,
            setProgress,
            userId,
            parent1Signature: removeWhiteBackgroundSignature(
              sigCanvasParent1.current.getCanvas(),
            ).toDataURL(),
            parent2Signature: removeWhiteBackgroundSignature(
              sigCanvasParent2.current.getCanvas(),
            ).toDataURL(),
            kitaSignature: removeWhiteBackgroundSignature(
              sigCanvasKita.current.getCanvas(),
            ).toDataURL(),
          };

          const parent1Signed = !sigCanvasParent1.current.isEmpty();
          const parent2Signed = !sigCanvasParent2.current.isEmpty();
          const kitaSigned = !sigCanvasKita.current.isEmpty();

          let pdf = null;
          if (parent1Signed && parent2Signed && kitaSigned) {
            pdf = createPDFNannyContractAdjustments(pdfSaveData, calculateHoursPerWeek());
          }

          onGfzFormSubmitted(t, selectedKid, user, 'NannyContractAdjustments', pdf);

          store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
          resetForm();
        }}
        type='primary'
      >
        {t('GFZForms.NannyContractAdjustmentsSave')}
      </Button>
      &nbsp;
      <Button
        onClick={() => {
          sigCanvasKita.current.clear();
          sigCanvasParent1.current.clear();
          sigCanvasParent2.current.clear();
          setFormDisabled(false);
        }}
      >
        {t('GFZForms.EditFormAndResetSignatures')}
      </Button>
    </>
  );
}
