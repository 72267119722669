import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import UsersFrameImportPopup from '.';

const mapDispatchToProps = {
  showBottomNotification,
};

export default connect(
  null,
  mapDispatchToProps,
)(UsersFrameImportPopup);
