import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from "library/common/commonComponents/Checkbox";

import styles from './InputEditView.module.scss';

export default function InputEditView({ component, setComponent }) {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { t } = useTranslation();

  const [canToggleEditingByParentsOrKita, setCanToggleEditingByParentsOrKita] = useState(true);

  const inputTypeOptions = [
    {
      value: 'text',
      label: t('FormsEditor.ComponentEditorInputTypeText'),
    },
    {
      value: 'date',
      label: t('FormsEditor.ComponentEditorInputTypeDate'),
    },
    {
      value: 'email',
      label: t('FormsEditor.ComponentEditorInputTypeEmail'),
    },
    {
      value: 'number',
      label: t('FormsEditor.ComponentEditorInputTypeNumber'),
    },
    {
      value: 'tel',
      label: t('FormsEditor.ComponentEditorInputTypePhone'),
    },
    {
      value: 'time',
      label: t('FormsEditor.ComponentEditorInputTypeTime'),
    },
    {
      value: 'url',
      label: t('FormsEditor.ComponentEditorInputTypeUrl'),
    },
    {
      value: 'text',
      label: t('FormsEditor.ComponentEditorInputTypeText'),
    },
    {
      value: 'date',
      label: t('FormsEditor.ComponentEditorInputTypeDate'),
    },
    {
      value: 'email',
      label: t('FormsEditor.ComponentEditorInputTypeEmail'),
    },
    {
      value: 'number',
      label: t('FormsEditor.ComponentEditorInputTypeNumber'),
    },
    {
      value: 'tel',
      label: t('FormsEditor.ComponentEditorInputTypePhone'),
    },
    {
      value: 'time',
      label: t('FormsEditor.ComponentEditorInputTypeTime'),
    },
    {
      value: 'signatureImage',
      label: t('FormsEditor.ComponentEditorInputTypeSignatureImage'),
    },
    {
      value: 'image',
      label: t('FormsEditor.ComponentEditorInputTypeImage'),
    },
  ];

  const presetValueTypeOptions = [
    {
      value: '[[VornameKind]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeChildFirstName'),
    },
    {
      value: '[[NachnameKind]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeChildLastName'),
    },
    {
      value: '[[GeburtstagKind]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeChildBirthday'),
    },
    {
      value: '[[StadtKind]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeChildCity'),
    },
    {
      value: '[[GeschlechtKind]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeChildGender'),
    },
    {
      value: '[[BundeslandKind]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeChildState'),
    },
    {
      value: '[[StrasseKind]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeChildStreet'),
    },
    {
      value: '[[PostleitzahlKind]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeChildZip'),
    },
    {
      value: '[[VornameElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1FirstName'),
    },
    {
      value: '[[NachnameElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1LastName'),
    },
    {
      value: '[[GeburtstagElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1Birthday'),
    },
    {
      value: '[[StadtElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1City'),
    },
    {
      value: '[[EmailElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1Email'),
    },
    {
      value: '[[GeschlechtElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1Gender'),
    },
    {
      value: '[[MobilTelefonElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1MobilePhone'),
    },
    {
      value: '[[PrivatTelefonElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1PrivatePhone'),
    },
    {
      value: '[[ArbeitTelefonElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1WorkPhone'),
    },
    {
      value: '[[BundeslandElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1State'),
    },
    {
      value: '[[LandElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1Country'),
    },
    {
      value: '[[StrasseElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1Street'),
    },
    {
      value: '[[TitelElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1Title'),
    },
    {
      value: '[[PostleitzahlElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1Zip'),
    },
    {
      value: '[[MutterspracheElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1MotherLanguage'),
    },
    {
      value: '[[HauptspracheElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1MainLanguage'),
    },
    {
      value: '[[KonfessionElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1Denomination'),
    },
    {
      value: '[[ArbeitsplatzElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1WorkingPlace'),
    },
    {
      value: '[[FamilienstandElternteil1]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent1MaritalStatus'),
    },
    {
      value: '[[VornameElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2FirstName'),
    },
    {
      value: '[[NachnameElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2LastName'),
    },
    {
      value: '[[GeburtstagElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2Birthday'),
    },
    {
      value: '[[StadtElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2City'),
    },
    {
      value: '[[EmailElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2Email'),
    },
    {
      value: '[[GeschlechtElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2Gender'),
    },
    {
      value: '[[MobilTelefonElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2MobilePhone'),
    },
    {
      value: '[[PrivatTelefonElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2PrivatePhone'),
    },
    {
      value: '[[ArbeitTelefonElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2WorkPhone'),
    },
    {
      value: '[[BundeslandElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2State'),
    },
    {
      value: '[[LandElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2Country'),
    },
    {
      value: '[[StrasseElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2Street'),
    },
    {
      value: '[[TitelElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2Title'),
    },
    {
      value: '[[PostleitzahlElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2Zip'),
    },
    {
      value: '[[MutterspracheElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2MotherLanguage'),
    },
    {
      value: '[[HauptspracheElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2MainLanguage'),
    },
    {
      value: '[[KonfessionElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2Denomination'),
    },
    {
      value: '[[ArbeitsplatzElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2WorkingPlace'),
    },
    {
      value: '[[FamilienstandElternteil2]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeParent2MaritalStatus'),
    },
    {
      value: '[[KitaName]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeKitaName'),
    },
    {
      value: '[[KitaStrasse]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeKitaStreetName'),
    },
    {
      value: '[[KitaHausNr]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeKitaHouseNo'),
    },
    {
      value: '[[KitaPLZ]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeKitaZipCode'),
    },
    {
      value: '[[KitaStadt]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeKitaCity'),
    },
    {
      value: '[[TrägerName]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeCarrierName'),
    },
    {
      value: '[[TrägerVertretung]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeCarrierRepresentative'),
    },
    {
      value: '[[TrägerAdresse]]',
      label: t('FormsEditor.ComponentEditorPresetValueTypeCarrierAddress'),
    },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpened(prev => !prev);
  };
  const addPresetValueType = value => {
    const newPresetValueType =
      component.presetValueType !== null ? `${component.presetValueType} ${value}` : value;

    let disableAllowFillout = false;
    if (presetValueTypeOptions.some(op => newPresetValueType.includes(op.value))) {
      setCanToggleEditingByParentsOrKita(false);
      disableAllowFillout = true;
    }

    setComponent({
      ...component,
      presetValueType: newPresetValueType,
      allowParentsFillOut: component.allowParentsFillOut && !disableAllowFillout,
      allowKitaFillOut: component.allowKitaFillOut && !disableAllowFillout,
    });
  };

  return (
    <div>
      <Checkbox
        name={t('FormsEditor.ComponentEditorAllowParentsFillOutLabel')}
        isChecked={component.allowParentsFillOut}
        isDisabled={!canToggleEditingByParentsOrKita}
        onChange={() => {
          setComponent({
            ...component,
            allowParentsFillOut: !component.allowParentsFillOut,
          });
        }}
      />
      <Checkbox
        name={t('FormsEditor.ComponentEditorAllowKitaFillOutLabel')}
        isChecked={component.allowKitaFillOut}
        isDisabled={!canToggleEditingByParentsOrKita}
        onChange={() => {
          setComponent({
            ...component,
            allowKitaFillOut: !component.allowKitaFillOut,
          });
        }}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorUniqueNameLabel')}</Label>
      <Input
        value={component.name}
        onChange={e => {
          setComponent({
            ...component,
            name: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorUniqueNamePlaceholder')}
      />
      <Label type='input'>{t('FormsEditor.ComponentEditorIndentLabel')}</Label>
      <Input
        value={component.indent}
        onChange={e => {
          setComponent({
            ...component,
            indent: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorIndentPlaceholder')}
      />
      <Label type='input'>{t('FormsEditor.ComponentEditorLabelLeftLabel')}</Label>
      <Input
        value={component.labelLeft}
        onChange={e => {
          setComponent({
            ...component,
            labelLeft: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorLabelLeftPlaceholder')}
      />
      <Label type='input'>{t('FormsEditor.ComponentEditorLabelRightLabel')}</Label>
      <Input
        value={component.labelRight}
        onChange={e => {
          setComponent({
            ...component,
            labelRight: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorLabelRightPlaceholder')}
      />
      <Label type='input'>{t('FormsEditor.ComponentEditorInputTypeLabel')}</Label>
      <Select
        options={inputTypeOptions}
        onSelect={e => {
          setComponent({
            ...component,
            inputType: e.value,
          });
        }}
        selected={inputTypeOptions.find(it => it.value === component.inputType)}
      />
      {component.inputType === 'date' &&
        <>
          <Checkbox
            name={t('FormsEditor.ComponentEditorIsPastDateForbiddenLabel')}
            isChecked={component.isPastDateForbidden}
            onChange={() => {
              setComponent({
                ...component,
                isPastDateForbidden: !component.isPastDateForbidden,
              });
            }}
          />
          <Checkbox
            name={t('FormsEditor.ComponentEditorIsFutureDateForbiddenLabel')}
            isChecked={component.isFutureDateForbidden}
            onChange={() => {
              setComponent({
                ...component,
                isFutureDateForbidden: !component.isFutureDateForbidden,
              });
            }}
          />
          <Checkbox
            name={t('FormsEditor.ComponentEditorIsDefaultTodaySet')}
            isChecked={component.isDefaultTodaySet}
            onChange={() => {
              setComponent({
                ...component,
                isDefaultTodaySet: !component.isDefaultTodaySet,
              });
            }}
          />
        </>

      }
      <Label type='input'>{t('FormsEditor.ComponentEditorPlaceholderLabel')}</Label>
      <Input
        value={component.placeholder}
        onChange={e => {
          setComponent({
            ...component,
            placeholder: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorPlaceholderPlaceholder')}
      />
      <Label type='input'>{t('FormsEditor.ComponentEditorPresetValueTypeLabel')}</Label>
      <div style={{ display: 'flex' }}>
        <div className={styles.presetValueTypeInputContainer}>
          <Input
            value={component.presetValueType}
            onChange={e => {
              let disableAllowFillout = false;
              if (presetValueTypeOptions.some(op => e.target.value.includes(op.value))) {
                setCanToggleEditingByParentsOrKita(false);
                disableAllowFillout = true;
              }
              else {
                setCanToggleEditingByParentsOrKita(true);
              }

              setComponent({
                ...component,
                presetValueType: e.target.value,
                allowParentsFillOut: component.allowParentsFillOut && !disableAllowFillout,
                allowKitaFillOut: component.allowKitaFillOut && !disableAllowFillout,
              });
            }}
            placeholder={t('FormsEditor.ComponentEditorPresetValueTypePlaceholder')}
          />
        </div>
        <Button onClick={toggleDropdown}>
          <i className='fa fa-plus' />
        </Button>
      </div>
      <div style={{ display: isDropdownOpened ? 'block' : 'none' }}>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown}>
            {presetValueTypeOptions.map(({ value, label }) => (
              <div className={styles.dropdownItem} key={value}>
                <button className={styles.dropdownButton} onClick={() => addPresetValueType(value)}>
                  {label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>


      <Checkbox
        name={t('FormsEditor.ComponentEditorMandatoryLabel')}
        isChecked={component.mandatory}
        onChange={() => {
          setComponent({
            ...component,
            mandatory: !component.mandatory,
          });
        }}
      />
    </div>
  );
}
