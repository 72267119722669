import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isAwoWW } from 'library/api/tenantConfig';
import Tabs from 'library/common/commonComponents/Tabs';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import GroupsFrameSettings from './groupsFrameFrames/GroupsFrameSettings';
import GroupsFrameListWrapper from './groupsFrameFrames/GroupsFrameListWrapper';

import styles from './groupsFrame.module.scss';

export default function GroupsFrame({
  activeKita,
  user,
  superAdminStatus,
  setCreateGroupMode,
  setActiveKita,
  setKitas,
  kitas,
}) {
  useSiteTitle('AdministrationGroups');
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);
  const kita = kitas.find(item => item.kitaId === activeKita.kitaId) || {};
  const tabs = [
    {
      title: t('Administration.Spaces'),
      component: (
        <GroupsFrameListWrapper
          user={user}
          activeKita={activeKita}
          superAdminStatus={superAdminStatus}
          setCreateGroupMode={setCreateGroupMode}
          onlyPortfolioGroups={false}
        />
      ),
      path: '/groups/1',
    },
    {
      ...(!isAwoWW() && {
        title: t('Administration.Portfolio Groups'),
        component: (
          <GroupsFrameListWrapper
            user={user}
            activeKita={activeKita}
            superAdminStatus={superAdminStatus}
            setCreateGroupMode={setCreateGroupMode}
            onlyPortfolioGroups={true}
          />
        ),
        path: '/groups/2',
      }),
    },
    {
      title: t('AccountSettings.Settings'),
      component: (
        <GroupsFrameSettings
          kita={kita}
          setActiveKita={setActiveKita}
          setKitas={setKitas}
          kitas={kitas}
        />
      ),
      path: '/groups/settings',
    },
  ];

  return (
    <div className={styles.container}>
      <Tabs
        tabs={tabs}
        currentTab={tabs[currentTab].path}
        onChange={(_, index) => setCurrentTab(index)}
      />
    </div>
  );
}
