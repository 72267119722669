import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getGfzNannyProtocol,
  postGfzNannyProtocol,
  getGfzNannyList,
} from 'library/api/observationSheet';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import RadioButton from 'library/common/commonComponents/RadioButton';
import store from 'main/store/configureStore';
import NannyEditPopup from './NannyEditPopup';

import gfzLogo from 'resources/images/gfz/gfz_logo.png';

export default function NannyProtocol({ userId, kitaId }) {
  const [selectedNanny, setSelectedNanny] = useState(null);
  const [nannyList, setNannyList] = useState([]);
  const [nannyEditPopupOpen, setNannyEditPopupOpen] = useState(false);
  const [nannyProtocol, setNannyProtocol] = useState({
    nannyId: null,
    ownKids: [],
    otherKids: [],
    pets: '',
    openIssues: '',
    protocolEntries: [],
  });

  const { t } = useTranslation();

  useEffect(() => {
    getGfzNannyList(kitaId).then(data => {
      const unformattedNannyList = data.data;
      const formattedNannyList = unformattedNannyList.map(obj => {
        return { label: obj.name, value: obj.id };
      });
      setNannyList(formattedNannyList);
    });
  }, []);

  const handleAddOwnKidRow = () => {
    setNannyProtocol(prev => {
      return {
        ...prev,
        ownKids: [
          ...prev.ownKids,
          {
            name: '',
            birthday: '',
          },
        ],
      };
    });
  };

  const handleRemoveOwnKidRow = index => {
    setNannyProtocol(prev => {
      return {
        ...prev,
        ownKids: prev.ownKids.filter((k, i) => i !== index),
      };
    });
  };

  const renderOwnKidsRow = (kid, index) => {
    return (
      <tr>
        <td>
          <Input
            type='text'
            value={kid.name}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  ownKids: prev.ownKids.map((k, i) => {
                    if (i !== index) {
                      return k;
                    }

                    return {
                      ...k,
                      name: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            value={kid.birthday}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  ownKids: prev.ownKids.map((k, i) => {
                    if (i !== index) {
                      return k;
                    }

                    return {
                      ...k,
                      birthday: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Button onClick={() => handleRemoveOwnKidRow(index)}>
            <i className='fa fa-minus' />
          </Button>
        </td>
      </tr>
    );
  };

  const handleAddOtherKidRow = () => {
    setNannyProtocol(prev => {
      return {
        ...prev,
        otherKids: [
          ...prev.otherKids,
          {
            name: '',
            birthday: '',
            entryDate: '',
            exitDate: '',
            fpkstatus: '',
          },
        ],
      };
    });
  };

  const handleRemoveOtherKidRow = index => {
    setNannyProtocol(prev => {
      return {
        ...prev,
        otherKids: prev.otherKids.filter((k, i) => i !== index),
      };
    });
  };

  const renderOtherKidsRow = (kid, index) => {
    return (
      <tr>
        <td>
          <Input
            type='text'
            value={kid.name}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  otherKids: prev.otherKids.map((k, i) => {
                    if (i !== index) {
                      return k;
                    }

                    return {
                      ...k,
                      name: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            value={kid.birthday}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  otherKids: prev.otherKids.map((k, i) => {
                    if (i !== index) {
                      return k;
                    }

                    return {
                      ...k,
                      birthday: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            value={kid.entryDate}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  otherKids: prev.otherKids.map((k, i) => {
                    if (i !== index) {
                      return k;
                    }

                    return {
                      ...k,
                      entryDate: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            value={kid.exitDate}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  otherKids: prev.otherKids.map((k, i) => {
                    if (i !== index) {
                      return k;
                    }

                    return {
                      ...k,
                      exitDate: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            value={kid.fpkstatus}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  otherKids: prev.otherKids.map((k, i) => {
                    if (i !== index) {
                      return k;
                    }

                    return {
                      ...k,
                      fpkstatus: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Button onClick={() => handleRemoveOtherKidRow(index)}>
            <i className='fa fa-minus' />
          </Button>
        </td>
      </tr>
    );
  };

  const handleAddProtocolRow = () => {
    setNannyProtocol(prev => {
      return {
        ...prev,
        protocolEntries: [
          ...prev.protocolEntries,
          {
            date: '',
            type: 'incomingCall',
            content: '',
            agreements: '',
            done: false,
          },
        ],
      };
    });
  };

  const handleRemoveProtocolRow = index => {
    setNannyProtocol(prev => {
      return {
        ...prev,
        protocolEntries: prev.protocolEntries.filter((k, i) => i !== index),
      };
    });
  };

  const renderProtocolRow = (e, index) => {
    return (
      <tr>
        <td>
          <Input
            type='text'
            value={e.date}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((e, i) => {
                    if (i !== index) {
                      return e;
                    }

                    return {
                      ...e,
                      date: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <RadioButton
            checked={e.type === 'incomingCall'}
            onClick={() => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((pe, i) => {
                    if (i === index) {
                      return {
                        ...pe,
                        type: 'incomingCall',
                      };
                    }

                    return pe;
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <RadioButton
            checked={e.type === 'outgoingCall'}
            onClick={() => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((pe, i) => {
                    if (i === index) {
                      return {
                        ...pe,
                        type: 'outgoingCall',
                      };
                    }

                    return pe;
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <RadioButton
            checked={e.type === 'visit'}
            onClick={() => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((pe, i) => {
                    if (i === index) {
                      return {
                        ...pe,
                        type: 'visit',
                      };
                    }

                    return pe;
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <RadioButton
            checked={e.type === 'other'}
            onClick={() => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((pe, i) => {
                    if (i === index) {
                      return {
                        ...pe,
                        type: 'other',
                      };
                    }

                    return pe;
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <RadioButton
            checked={e.type === 'training'}
            onClick={() => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((pe, i) => {
                    if (i === index) {
                      return {
                        ...pe,
                        type: 'training',
                      };
                    }

                    return pe;
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            value={e.content}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((e, i) => {
                    if (i !== index) {
                      return e;
                    }

                    return {
                      ...e,
                      content: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Input
            type='text'
            value={e.agreements}
            onChange={({ target }) => {
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((e, i) => {
                    if (i !== index) {
                      return e;
                    }

                    return {
                      ...e,
                      agreements: target.value,
                    };
                  }),
                };
              });
            }}
          />
        </td>
        <td>
          <Checkbox
            isChecked={e.done}
            onChange={() =>
              setNannyProtocol(prev => {
                return {
                  ...prev,
                  protocolEntries: prev.protocolEntries.map((e, i) => {
                    if (i !== index) {
                      return e;
                    }

                    return {
                      ...e,
                      done: !e.done,
                    };
                  }),
                };
              })
            }
          />
        </td>
        <td>
          <Button onClick={() => handleRemoveProtocolRow(index)}>
            <i className='fa fa-minus' />
          </Button>
        </td>
      </tr>
    );
  };

  const resetForm = () => {
    setNannyProtocol({
      nannyId: null,
      ownKids: [],
      otherKids: [],
      pets: '',
      openIssues: '',
      protocolEntries: [],
    });
  };

  return (
    <>
      <NannyEditPopup
        isOpen={nannyEditPopupOpen}
        setOpen={setNannyEditPopupOpen}
        nannyList={nannyList}
        setNannyList={setNannyList}
        selectedNanny={selectedNanny}
        setSelectedNanny={setSelectedNanny}
        resetForm={resetForm}
      />

      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} />
      <br />
      <br />
      <h2>{t('GfzForms.NannyProtocolHistoryLog')}</h2>
      <br />
      <table>
        <tr>
          <td>{t('GfzForms.NannyProtocolNanny')}</td>
          <td>
            <Select
              options={nannyList}
              onSelect={e => {
                setSelectedNanny(e);
                getGfzNannyProtocol(e.value).then(res => {
                  console.log(res);
                  if (res.data != null) {
                    setNannyProtocol(res.data);
                  } else {
                    setNannyProtocol({
                      nannyId: e.value,
                      ownKids: [],
                      otherKids: [],
                      pets: '',
                      openIssues: '',
                      protocolEntries: [],
                    });
                  }
                });
              }}
              selected={selectedNanny}
              style={{ zIndex: '10000000000' }}
            />
          </td>
          <td style={{ maxWidth: '100px' }}>
            <Button onClick={() => setNannyEditPopupOpen(true)}>
              <i className='fa fa-pencil' /> {t('GfzForms.NannyProtocolManageNannies')}
            </Button>
          </td>
        </tr>
      </table>

      <br />
      <br />

      <h2>{t('GfzForms.NannyProtocolOwnChildren')}</h2>
      <br />
      <table>
        <tr>
          <th>{t('GfzForms.NannyProtocolName')}</th>
          <th>{t('GfzForms.NannyProtocolBorn')}</th>
          <th />
        </tr>
        {nannyProtocol.ownKids.map((k, i) => {
          return renderOwnKidsRow(k, i);
        })}
      </table>
      <br />
      <Button onClick={handleAddOwnKidRow}>
        <i className='fa fa-plus' /> {t('GfzForms.NannyProtocolAddRow')}
      </Button>

      <br />
      <br />
      <br />

      <h2>{t('GfzForms.NannyProtocolKidDay')}</h2>
      <br />
      <table>
        <tr>
          <th>{t('GfzForms.NannyProtocolName')}</th>
          <th>{t('GfzForms.NannyProtocolBorn')}</th>
          <th>{t('GfzForms.NannyProtocolEntry')}</th>
          <th>{t('GfzForms.NannyProtocolExit')}</th>
          <th>{t('GfzForms.NannyProtocolFPK_Status')}</th>
          <th />
        </tr>
        {nannyProtocol.otherKids.map((k, i) => {
          return renderOtherKidsRow(k, i);
        })}
      </table>
      <br />
      <Button onClick={handleAddOtherKidRow}>
        <i className='fa fa-plus' /> {t('GfzForms.NannyProtocolAddRow')}
      </Button>

      <br />
      <br />

      <table>
        <tr>
          <td>{t('GfzForms.NannyProtocolPets')}</td>
          <td>
            <Input
              type='text'
              value={nannyProtocol.pets}
              onChange={({ target }) => {
                setNannyProtocol(prev => {
                  return {
                    ...prev,
                    pets: target.value,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GfzForms.NannyProtocolPendingIssues')}</td>
          <td>
            <Input
              type='text'
              value={nannyProtocol.openIssues}
              onChange={({ target }) => {
                setNannyProtocol(prev => {
                  return {
                    ...prev,
                    openIssues: target.value,
                  };
                });
              }}
            />
          </td>
        </tr>
      </table>

      <br />
      <br />

      <table>
        <tr>
          <th>{t('GfzForms.NannyProtocolDate')}</th>
          <th>{t('GfzForms.NannyProtocolIncomingCall')}</th>
          <th>{t('GfzForms.NannyProtocolOutgoingCall')}</th>
          <th>{t('GfzForms.NannyProtocolConversationOrVisit')}</th>
          <th>{t('GfzForms.NannyProtocolOtherOrMail')}</th>
          <th>{t('GfzForms.NannyProtocolContinuingEducation')}</th>
          <th>{t('GfzForms.NannyProtocolContents')}</th>
          <th>{t('GfzForms.NannyProtocolDeals')}</th>
          <th>{t('GfzForms.NannyProtocolDone')}</th>
        </tr>
        {nannyProtocol.protocolEntries.map((e, i) => {
          return renderProtocolRow(e, i);
        })}
      </table>
      <br />
      <Button onClick={handleAddProtocolRow}>
        <i className='fa fa-plus' /> {t('GfzForms.NannyProtocolAddRow')}
      </Button>

      <br />
      <br />

      <Button
        onClick={() => {
          postGfzNannyProtocol(nannyProtocol).then(() => {
            store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
          });
        }}
        type='primary'
        disabled={selectedNanny == null}
      >
        {t('GfzForms.NannyProtocolSave')}
      </Button>
    </>
  );
}
