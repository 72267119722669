import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './employeeHolidayAvatar.module.scss';
import ProfileAvatar from '../../../ProfileAvatar';
import { useSelector } from 'react-redux';
import {
  EMPLOYEE_ABSENCE_ICONS,
  EMPLOYEE_ABSENCE_TYPES,
} from 'library/common/commonConstants/employee';
import {
  HolidayBadge,
  CustomAbsenceBadge,
} from 'library/common/commonComponents/CheckInOut/EmployeeHistoryContainer/EmployeeHistoryBadges';
import cn from 'classnames';

const EmployeeHolidayAvatar = ({ kitaEmployeeAbsenceDays }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.userReducer);
  const [showAllAbsences, setShowAllAbsences] = useState(false);
  const hasMultipleAbsences = kitaEmployeeAbsenceDays.length > 1;

  const absenceBadge = absence => {
    switch (absence.value) {
      case EMPLOYEE_ABSENCE_TYPES.HOLIDAY:
        return <HolidayBadge />;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_ONE:
        return <CustomAbsenceBadge index={0} text={absence.label} />;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_TWO:
        return <CustomAbsenceBadge index={1} text={absence.label} />;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_THREE:
        return <CustomAbsenceBadge index={2} text={absence.label} />;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_FOUR:
        return <CustomAbsenceBadge index={3} text={absence.label} />;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_FIVE:
        return <CustomAbsenceBadge index={4} text={absence.label} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={cn(styles.employeeHistory, hasMultipleAbsences && styles.clickable)}
      onClick={() => {
        if (!hasMultipleAbsences) return;
        setShowAllAbsences(prev => !prev);
      }}
    >
      <div className={styles.wrapper}>
        <ProfileAvatar className={styles.avatar} id={user.id} withoutLink />
        <div className={styles.name}>{`${user.firstName} ${user.lastName}`}</div>

        {!!kitaEmployeeAbsenceDays && (
          <div className={styles.middleColumn}>
            {/* show only holiday absence when it's collapsed which is default */}
            {(showAllAbsences ? kitaEmployeeAbsenceDays : kitaEmployeeAbsenceDays.slice(0, 1)).map(
              absence => {
                return (
                  <div key={absence.value}>
                    <span className={styles.badgeWrapper}>{absenceBadge(absence)}</span>
                    <b>{`${absence.daysTotal - absence.daysTaken} / ${absence.daysTotal}`}</b>{' '}
                    {t('Checkinout.Available days')}
                  </div>
                );
              },
            )}
          </div>
        )}

        {hasMultipleAbsences && (
          <div className={styles.expandIcon}>
            <i
              className={showAllAbsences ? 'fa fa-lg fa-chevron-up' : 'fa fa-lg fa-chevron-down'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeHolidayAvatar;
