import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import Loader from 'library/common/commonComponents/Loader';
import { getCuttedString } from 'library/utilities/user';

import ProfileHeader from './ProfilePanelFrames/ProfileHeader';
import ProfileLogo from './ProfilePanelFrames/ProfileLogo';

import styles from './profilePanel.module.scss';

export default function ProfilePanel({
  history,
  profileStatus,
  header,
  avatar,
  userName,
  userTitle,
  user,
  isSubmiting,
  onAddFriend,
  onCancelFriendRequest,
  onAcceptFriendRequest,
  onUnFriend,
  headerUploadProgress,
  avatarUploadProgress,
  onUploadProfileImg,
  onEditProfileImage,
  onDeleteProfileImg,
  userIsSuperAdmin,
  userIsAdmin,
  userEmail,
}) {
  const { t } = useTranslation();
  const userProfileButton = getUserProfileButton({
    history,
    profileStatus,
    t,
    onAddFriend,
    onCancelFriendRequest,
    onAcceptFriendRequest,
    onUnFriend,
  });

  return (
    <Col>
      <Row>
        <div className={styles.panel}>
          <div className={styles.panelTop}>
            <div
              className={cn(
                styles.panelTopBanner,
                header.imageUrl && styles.panelTopBannerWithImage,
              )}
            >
              <ProfileHeader
                profileStatus={profileStatus}
                header={header}
                headerUploadProgress={headerUploadProgress}
                onUploadProfileImg={onUploadProfileImg}
                onEditProfileImage={onEditProfileImage}
                onDeleteProfileImg={onDeleteProfileImg}
              />
              <div className={styles.textWrapper}>
                <div className={cn(styles.title, 'notranslate')}>
                  {userName}&nbsp;
                  {
                    `${((userTitle && userTitle !== '') || user.title && user.title !== '') &&
                    (window.location.hostname.toLowerCase().includes('indag.safe2connect.org') ||
                      window.location.hostname.toLowerCase().includes('dev2.safe2connect.org') ||
                      window.location.hostname.toLowerCase().includes('staging2.safe2connect.org'))
                      ? `(${getCuttedString(userTitle !== '' ? userTitle : user.title, 40)})`
                      : ''
                    }`}
                </div>
              </div>
              <ProfileLogo
                profileStatus={profileStatus}
                avatar={avatar}
                avatarUploadProgress={avatarUploadProgress}
                onUploadProfileImg={onUploadProfileImg}
                onEditProfileImage={onEditProfileImage}
                onDeleteProfileImg={onDeleteProfileImg}
              />
              <div className={styles.panelTopBannerFade} />
            </div>
          </div>

          <div className={styles.panelBottom}>
            <div className={styles.panelBottomWrapper}>
              <div className={styles.buttons}>
                {isSubmiting ? (
                  <Loader className={styles.loader} dotSize='8px' />
                ) : (
                  <div>
                    {profileStatus === 'superAdminProfile' || (userIsSuperAdmin && profileStatus !== 'owner')
                      ? null
                      : (
                        <div
                          className={cn(styles.userProfileButtonContainer, userProfileButton.className)}
                          onClick={userProfileButton.onClick}
                        >
                          {userProfileButton.faIcon !== '' && <i className={userProfileButton.faIcon} />}
                          {userProfileButton.title}
                          {(profileStatus === 'acceptFriendRequest' || profileStatus === 'friend') && (
                            <div
                              className={styles.userProfileButtonDropdownConntainer}
                              onClick={e => e.stopPropagation()}
                            >
                              <MoreBtn
                                dropdownOptions={userProfileButton.dropDown}
                                className={
                                  profileStatus === 'friend'
                                    ? styles.friendDropdown
                                    : styles.acceptDropdown
                                }
                                faIcon={`${profileStatus === 'friend' ? 'fa-check' : 'fa-caret-down'}`}
                              >
                                {profileStatus === 'friend' && (
                                  <>
                                    <div className={styles.dropDownTitle}>
                                      {t('ProfilePanel.Friends')}
                                    </div>
                                    <i className='fa fa-caret-down' />
                                  </>
                                )}
                              </MoreBtn>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Col>
  );
}

export const getUserProfileButton = ({
  history,
  profileStatus,
  t,
  // onAddFriend,
  onCancelFriendRequest,
  onAcceptFriendRequest,
  onUnFriend,
}) => {
  const buttonOptions = {
    faIcon: '',
    title: '',
    onClick: () => {},
    dropDown: [],
    className: '',
  };

  switch (profileStatus) {
    case 'owner':
      return {
        faIcon: '',
        title: t('ProfilePanel.Edit account'),
        onClick: () => history.push('/account-settings/profile/general'),
        dropDown: [],
        className: styles.editButton,
      };

    case 'notFriend':
      return '';
    /*       if (!window.location.hostname.toLowerCase().includes('ivs.safe2connect.org')) {
            return {
              faIcon: 'fa fa-plus',
              title: t('ProfilePanel.Add Friend'),
              onClick: () => onAddFriend(),
              dropDown: [],
              className: styles.addFriendButton,
            };
          }
          return ''; */

    case 'friendRequested':
      return {
        faIcon: '',
        title: t('ProfilePanel.Cancel friend request'),
        onClick: () => onCancelFriendRequest(),
        dropDown: [],
        className: styles.cancelFriendRequestButton,
      };

    case 'acceptFriendRequest':
      return {
        faIcon: '',
        title: t('ProfilePanel.Accept Friend Request'),
        onClick: () => onAcceptFriendRequest(),
        dropDown: [
          {
            faIcon: null,
            title: t('ProfilePanel.Deny friend request'),
            onClick: () => onCancelFriendRequest(),
          },
        ],
        className: styles.acceptFriendRequestButton,
      };

    case 'friend':
      return {
        faIcon: '',
        title: '',
        onClick: () => {},
        dropDown: [
          {
            faIcon: null,
            title: t('ProfilePanel.Unfriend'),
            onClick: () => onUnFriend(),
          },
        ],
        className: styles.friendButton,
      };

    default:
      return buttonOptions;
  }
};
