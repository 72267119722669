import React, { useEffect, useState } from 'react';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import store from 'main/store/configureStore';
import Dictaphone from 'library/common/commonComponents/Dictaphone';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {
  getAllKidsFromSelectedKita,
  postGfzObservationAnalysis,
  getGfzObservationAnalysis,
  deleteGfzObservationAnalysis,
} from 'library/api/observationSheet';
import { createPDFObservationAnalysis } from 'library/utilities/files';
import gfzLogo from 'resources/images/gfz/gfz_logo.png';
import { createKidPortfolioGroupIfNeeded } from 'library/api/kids';
import { useTranslation } from "react-i18next";

export default function ObservationAnalysis({ userId }) {
  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [observationAnalysis, setObservationAnalysis] = useState({
    date: '',
    observingPerson: '',
    kidWellBeing: '',
    groupDynamics: '',
    attachmentFigure: '',
    ambienceDuringObservation: '',
    kidInterested: '',
    kidCommitted: '',
    kidWithstand: '',
    kidExpression: '',
    kidView: '',
    kidCollaborateAndTakeResponsibility: '',
    learningType: '',
    kidDailyObservations: '',
    nextSteps: '',
    kidId: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    getAllKidsFromSelectedKita().then(data => {
      const unformattedKidsList = data.data;
      const formattedKidsList = unformattedKidsList.map(obj => {
        return { label: obj.firstName + ' ' + obj.lastName, id: obj.kidId, portfolioGroupId: obj.portfolioGroupId };
      });
      setKidsList(formattedKidsList);
    });
  }, []);

  function handleTextStateChange(newValue, property) {
    setObservationAnalysis(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function resetForm() {
    setObservationAnalysis(
      {
        date: '',
        observingPerson: '',
        kidWellBeing: '',
        groupDynamics: '',
        attachmentFigure: '',
        ambienceDuringObservation: '',
        kidInterested: '',
        kidCommitted: '',
        kidWithstand: '',
        kidExpression: '',
        kidView: '',
        kidCollaborateAndTakeResponsibility: '',
        learningType: '',
        kidDailyObservations: '',
        nextSteps: '',
        kidId: null,
      });
    setSelectedKid([]);
  }

  function setKidWellBeingText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, kidWellBeing: `${prev.kidWellBeing} ${text}` };
    });
  }

  function setGroupDynamicsText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, groupDynamics: `${prev.groupDynamics} ${text}` };
    });
  }

  function setAttachmentFigureText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, attachmentFigure: `${prev.attachmentFigure} ${text}` };
    });
  }

  function setAmbienceDuringObservationText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, ambienceDuringObservation: `${prev.ambienceDuringObservation} ${text}` };
    });
  }

  function setKidInterestedText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, kidInterested: `${prev.kidInterested} ${text}` };
    });
  }

  function setKidCommittedText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, kidCommitted: `${prev.kidCommitted} ${text}` };
    });
  }

  function setKidWithstandText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, kidWithstand: `${prev.kidWithstand} ${text}` };
    });
  }

  function setKidExpressionText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, kidExpression: `${prev.kidExpression} ${text}` };
    });
  }

  function setKidViewText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, kidView: `${prev.kidView} ${text}` };
    });
  }

  function setKidCollaborateAndTakeResponsibilityText(text) {
    setObservationAnalysis(prev => {
      return {
        ...prev,
        kidCollaborateAndTakeResponsibility: `${prev.kidCollaborateAndTakeResponsibility} ${text}`,
      };
    });
  }

  function setLearningTypeText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, learningType: `${prev.learningType} ${text}` };
    });
  }

  function setKidDailyObservationsText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, kidDailyObservations: `${prev.kidDailyObservations} ${text}` };
    });
  }

  function setNextStepsText(text) {
    setObservationAnalysis(prev => {
      return { ...prev, nextSteps: `${prev.nextSteps} ${text}` };
    });
  }

  return (
    <>
      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} />
      <br />
      <br />
      <h2>
        {t('GFZEducationalWork.ObservationAnalysis')}
      </h2>
      <br />
      <table>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisKidName')}
          </td>
          <td>
            <Select
              options={kidsList}
              onSelect={e => {
                setSelectedKid(e);
                getGfzObservationAnalysis(e.id).then(res => {
                  if (res.data != null) {
                    setObservationAnalysis(res.data);
                  } else {
                    setObservationAnalysis({
                      date: '',
                      observingPerson: '',
                      kidWellBeing: '',
                      groupDynamics: '',
                      attachmentFigure: '',
                      ambienceDuringObservation: '',
                      kidInterested: '',
                      kidCommitted: '',
                      kidWithstand: '',
                      kidExpression: '',
                      kidView: '',
                      kidCollaborateAndTakeResponsibility: '',
                      learningType: '',
                      kidDailyObservations: '',
                      nextSteps: '',
                      kidId: e.id,
                    });
                  }
                });
              }}
              selected={selectedKid}
              style={{ zIndex: '10000000000' }}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisAnalysisDate')}
          </td>
          <td>
            <Input
              type='date'
              value={observationAnalysis.date}
              onChange={({ target }) =>
                setObservationAnalysis(prev => {
                  return {
                    ...prev,
                    date: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisObserver')}
          </td>
          <td>
            <Input
              type='text'
              maxLength={50}
              value={observationAnalysis.observingPerson}
              onChange={({ target }) =>
                setObservationAnalysis(prev => {
                  return {
                    ...prev,
                    observingPerson: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
      </table>
      <br />
      {t('GFZEducationalWork.ObservationAnalysisAnalysisTime')} <br />
      <br />
      {t('GFZEducationalWork.ObservationAnalysisConditions')}
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisWellBeing')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidWellBeingText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.kidWellBeing}
              onChange={e => handleTextStateChange(e.target.value, 'kidWellBeing')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisParticularities')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setGroupDynamicsText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.groupDynamics}
              onChange={e => handleTextStateChange(e.target.value, 'groupDynamics')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisReferencePerson')} <br />
            {t('GFZEducationalWork.ObservationAnalysisRelationship')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setAttachmentFigureText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.attachmentFigure}
              onChange={e => handleTextStateChange(e.target.value, 'attachmentFigure')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisMood')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setAmbienceDuringObservationText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.ambienceDuringObservation}
              onChange={e => handleTextStateChange(e.target.value, 'ambienceDuringObservation')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      {t('GFZEducationalWork.ObservationAnalysisLearningDispositions')}
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>{t('GFZEducationalWork.ObservationAnalysisInterested')}</span>
            <br />
            <br />
            {t('GFZEducationalWork.ObservationAnalysisChildInterest')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidInterestedText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.kidInterested}
              onChange={e => handleTextStateChange(e.target.value, 'kidInterested')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>{t('GFZEducationalWork.ObservationAnalysisCommited')}</span>
            <br />
            <br />
            {t('GFZEducationalWork.ObservationAnalysisChildCommitment')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidCommittedText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.kidCommitted}
              onChange={e => handleTextStateChange(e.target.value, 'kidCommitted')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>
              {t('GFZEducationalWork.ObservationAnalysisStandingChallengesDifficulties')}
            </span>
            <br />
            <br />
            {t('GFZEducationalWork.ObservationAnalysisChildFirmlyStanding')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidWithstandText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.kidWithstand}
              onChange={e => handleTextStateChange(e.target.value, 'kidWithstand')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>{t('GFZEducationalWork.ObservationAnalysisExpressAndCommunicate')}</span>
            <br />
            <br />
            {t('GFZEducationalWork.ObservationAnalysisChildExpressAndCommunicate')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidExpressionText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.kidExpression}
              onChange={e => handleTextStateChange(e.target.value, 'kidExpression')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ color: 'red' }}>
              {t('GFZEducationalWork.ObservationAnalysisVideoSequence')}
            </span>
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>{t('GFZEducationalWork.ObservationAnalysisChildPerspective')}</span>
            <br />
            <br />
            {t('GFZEducationalWork.ObservationAnalysisChildReaction')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidViewText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.kidView}
              onChange={e => handleTextStateChange(e.target.value, 'kidView')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>
              {t('GFZEducationalWork.ObservationAnalysisLearningCommunityParticipation')}
            </span>
            <br />
            <br />
            {t('GFZEducationalWork.ObservationAnalysisLearningCommunityRecognition')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidCollaborateAndTakeResponsibilityText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.kidCollaborateAndTakeResponsibility}
              onChange={e =>
                handleTextStateChange(e.target.value, 'kidCollaborateAndTakeResponsibility')
              }
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      {t('GFZEducationalWork.ObservationAnalysisFocus')}
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisLearningAim')}
            <br />
            {t('GFZEducationalWork.ObservationAnalysisLearningSkills')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setLearningTypeText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.learningType}
              onChange={e => handleTextStateChange(e.target.value, 'learningType')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      {t('GFZEducationalWork.ObservationAnalysisEverydayObservations')}
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisChildShowingObservations')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidDailyObservationsText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.kidDailyObservations}
              onChange={e => handleTextStateChange(e.target.value, 'kidDailyObservations')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      {t('GFZEducationalWork.ObservationAnalysisNextStepIdeas')}
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr>
          <td>
            {t('GFZEducationalWork.ObservationAnalysisHaveNextStepIdeas')}
            <br />
            {t('GFZEducationalWork.ObservationAnalysisIdeaExamples')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setNextStepsText} />
            </div>
            <br />
            <br />
            <TextArea
              value={observationAnalysis.nextSteps}
              onChange={e => handleTextStateChange(e.target.value, 'nextSteps')}
              type='text'
              placeholder={t('GFZEducationalWork.ObservationAnalysisTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      <Button
        onClick={() => {
          postGfzObservationAnalysis(observationAnalysis).then(() => {
            store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
          });
        }}
        type='primary'
      >
        {t('GFZEducationalWork.ObservationAnalysisSave')}
      </Button>
      {selectedKid.portfolioGroupId !== null && (
        <Button
          onClick={() => {
            const pdfSaveData = {
              observationAnalysis: observationAnalysis,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
            }

            createKidPortfolioGroupIfNeeded(selectedKid.id).then(data => {
              createPDFObservationAnalysis(pdfSaveData).then(() => {
                if (observationAnalysis.hasOwnProperty('id') && observationAnalysis.id !== null) {
                  deleteGfzObservationAnalysis(observationAnalysis.id);
                }
                resetForm();
                store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
              });
            });

          }}
        >
          {t('GFZEducationalWork.ObservationAnalysisInKidPortfolioSave')}
        </Button>
      )}
      <br />
      <br />
      <div style={{ fontSize: '7px' }}>
        {t('GFZEducationalWork.ObservationAnalysisReference')}
      </div>
    </>
  );
}
