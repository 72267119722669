import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import CalendarKidParticipantsPopup from 'library/common/commonComponents/Popups/CalendarKidParticipantsPopup';
import Select from 'library/common/commonComponents/Inputs/Select';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { updateCalendarKidParticipation } from 'library/api/calendar';

import styles from './kidParticipationView.module.scss';
import Loader from 'library/common/commonComponents/Loader';

export default function KidParticipationView({ user, eventId, calendarKidParticipations, t }) {
  const [kidParticipationPopupOpen, setKidParticipationPopupOpen] = useState(false);
  const [selectedKid, setSelectedKid] = useState({
    value: null,
    label: t('Calendar.Select kid to participate'),
  });
  const [participatingKids, setParticipatingKids] = useState([]);
  const [userKids, setUserKids] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setParticipatingKids(getParticipatingKidsObjects());
    setUserKids(getFilteredUserKids());
  }, []);

  function getParticipatingKidsObjects() {
    if (
      user &&
      user.kids &&
      Array.isArray(user.kids) &&
      calendarKidParticipations &&
      Array.isArray(calendarKidParticipations)
    ) {
      const kidParticipantIds = calendarKidParticipations.map(kid => kid.kidId);
      const filteredKids = user.kids.filter(kid => kidParticipantIds.includes(kid.id));

      if (filteredKids.length > 0) {
        return filteredKids.map(kid => {
          return { value: kid.id, label: `${kid.firstName} ${kid.lastName}` };
        });
      }
    }
    return [];
  }

  function getFilteredUserKids() {
    if (
      user &&
      user.kids &&
      Array.isArray(user.kids) &&
      calendarKidParticipations &&
      Array.isArray(calendarKidParticipations)
    ) {
      const kidParticipantIds = calendarKidParticipations.map(kid => kid.kidId);
      const filteredKids = user.kids.filter(kid => !kidParticipantIds.includes(kid.id));
      return filteredKids;
    }
    return [];
  }

  return (
    <>
      {kidParticipationPopupOpen && (
        <CalendarKidParticipantsPopup
          isOpened={kidParticipationPopupOpen}
          closePopup={() => setKidParticipationPopupOpen(false)}
          eventId={eventId}
        />
      )}
      <div className={styles.participants}>
        {(user.administrationAccess || user.employee || user.superAdminStatus) && (
          <>
            <div className={styles.participantsTitle}>
              <i className={cn('fa fa-users', styles.participantsTitleIcon)} />{' '}
              {t('FeedItemEvent.Participants')}
            </div>
            <span
              className={cn(styles.participantsCountClickable)}
              onClick={() => {
                setKidParticipationPopupOpen(true);
              }}
            >
              {t('FeedItemEvent.Show participant list')}
            </span>
            <br />
            <br />
          </>
        )}

        {!isLoading ? (
          <>
            {userKids.length > 0 && (
              <>
                <div className={styles.participantsTitle}>
                  <i className={cn('fa fa-user', styles.participantsTitleIcon)} />{' '}
                  {t('FeedItemEvent.Select participant')}
                </div>
                <Select
                  disabled={userKids.length < 1}
                  options={userKids.map(kid => {
                    return { value: kid.id, label: `${kid.firstName} ${kid.lastName}` };
                  })}
                  selected={selectedKid}
                  onSelect={e => {
                    setIsLoading(true);
                    updateCalendarKidParticipation(eventId, e.value, 'REGISTER')
                      .then(data => {
                        participatingKids.push({ value: e.value, label: e.label });
                        setUserKids(userKids.filter(kid => kid.id != e.value));
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                />
                <br />
              </>
            )}

            <table>
              {participatingKids.map(participatingKid => {
                return (
                  <>
                    <tr>
                      <td>
                        <img src={defaultAvatar} style={{ width: '30px', height: '30px' }} />
                      </td>
                      <td>{participatingKid.label}</td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsLoading(true);
                          updateCalendarKidParticipation(
                            eventId,
                            participatingKid.value,
                            'UNREGISTER',
                          )
                            .then(data => {
                              setParticipatingKids(
                                participatingKids.filter(
                                  kid => kid.value !== participatingKid.value,
                                ),
                              );

                              if (user && user.kids && Array.isArray(user.kids)) {
                                const kidObject = user.kids.filter(
                                  kid => kid.id === participatingKid.value,
                                );
                                if (kidObject.length === 1) {
                                  userKids.push(kidObject[0]);
                                }
                              }
                            })
                            .finally(() => {
                              setIsLoading(false);
                            });
                        }}
                      >
                        <i style={{ color: 'red' }} className='fa fa-minus-square fa-lg' />
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
            <br />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
