import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import FinApiWebForm from '../FinApiWebForm';
import { getFinApiPaymentWebForm } from 'library/api/finAPI';
import styles from './pendingPaymentsPopup.module.scss';


export default function ParentsFoodOrderPendingPaymentsPopup({
  isOpened,
  closePopup,
  pendingPayments,
  groupId,
}) {
  const { t } = useTranslation();
  const [paymentToken, setPaymentToken] = useState("");
  const [webformUrl, setWebformUrl] = useState("");
  const [showPaymentButton, setShowPaymentButton] = useState(true);

  const paymentSum = pendingPayments.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0).toFixed(2);

  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <Popup
      size='large'
      isOpened={true}
      closePopup={closePopup}
      header={t('FoodOrder.Payment overview')}
      body={
        <>
          <table>
            <tr><th>{t('FoodOrder.ChildName')}</th><th>{t('FoodOrder.Date')}</th><th>{t('FoodOrder.OrderDate')}</th><th>{t('FoodOrder.Food')}</th><th>{t('FoodOrder.Price')}</th></tr>
            {pendingPayments.map(payment => {
              return <tr><td>{payment.childName}</td><td>{payment.date}</td><td>{payment.orderDate}</td><td>{payment.food && payment.food.name && payment.food.name}</td><td>{formatter.format(payment.price)}</td></tr>
            })}
            <tr><td colSpan={4}>{t('FoodOrder.Sum')}</td><td>{formatter.format(paymentSum)}</td></tr>
          </table>
          {(paymentToken !== '' && webformUrl !== '') &&
            <FinApiWebForm
              paymentSum={paymentSum}
              groupId={groupId}
              paymentToken={paymentToken}
              webformUrl={webformUrl}
              closePopup={closePopup}
            />
          }
        </>
      }
      footer={
        (paymentToken === '' && webformUrl === '' && showPaymentButton) &&
        <div>
          <Button
            onClick={() => {
              setShowPaymentButton(false);
              getFinApiPaymentWebForm(paymentSum, groupId).then(data => {
                setPaymentToken(data.data.id);
                const splittedURL = data.data.url.split('/');
                const url = `https://${splittedURL[2]}`;
                setWebformUrl(url);
              });
            }}
          >
            {t('FoodOrder.Pay')}
          </Button>
        </div>
      }
    />
  );
}
