import { useReducer, useEffect, useRef, useState } from 'react';

import { getDefaultGalleryFiles, getNonDefaultGalleryFiles } from 'library/api/galleries';
import useFileInputChangeHandler from 'library/common/commonHooks/useFileInputChangeHandler';
import { checkScrollLoading, useLoadingOnScroll } from 'library/common/commonHooks/useScrollLoading';
import Storage from 'library/utilities/storage';

export const initialState = { images: [], isLoading: false };

export function reducer(state, action) {
  switch (action.type) {
    case 'removeFiles':
      return { ...state, images: [] };
    case 'startLoading':
      return { ...state, isLoading: true };
    case 'endLoading':
      return { ...state, isLoading: false };
    case 'addImages':
      return { ...state, images: state.images.concat(action.images) };
    case 'deleteImage':
      return { ...state, images: state.images.filter(({ id }) => id !== action.id) };
    case 'deleteAllImages':
      return { ...state, images: [] };
    case 'updateDescription':
      return {
        ...state,
        images: state.images.map(image =>
          image.id === action.image.fileId
            ? {
                ...image,
                description: action.image.description,
                bazaarItemInfo: {
                  price: action.image.price,
                  description: action.image.bazaarItemInfoDescription,
                },
              }
            : image,
        ),
      };
    default:
      return state;
  }
}

export default function useGalleryFilesLoading({
  groupId,
  isDefaultGallery,
  galleryId,
  uploadFilesToGallery,
  privateGallery,
  user,
  activeKita,
  isBazaar,
}) {
  const [{ images, isLoading }, dispatch] = useReducer(reducer, initialState);
  const currentPaginationPage = useRef(1);
  const isFeedsLoading = useRef(false);
  const isImagesEnded = useRef(false);
  const [canLoadFiles, setCanLoadFiles] = useState(false);

  const onFileInputChange = useFileInputChangeHandler({
    onChange: payload =>
      onChangeHandler({ uploadFilesToGallery, galleryId, payload, privateGallery, dispatch }),
    allowedExtensions: ['image/*', 'image/*', 'video/mp4', 'video/x-m4v', 'video/*'],
    user,
    groupId,
    activeKita,
  });

  const [filters, setFilters] = useState({
    createdByMe: false,
    fromDate: null,
    showDateFilters: false,
    toDate: null,
    users: [],
  });

  const getImagesPayload = {
    isFeedsLoading,
    isImagesEnded,
    dispatch,
    currentPaginationPage,
    groupId,
    isDefaultGallery,
    galleryId,
  };

  useEffect(() => {
    if (canLoadFiles) {
      dispatch({ type: 'removeFiles' });
      currentPaginationPage.current = 1;
      isImagesEnded.current = false;
      getImages(getImagesPayload);
    }
    // eslint-disable-next-line
  }, [
    canLoadFiles,
    filters.createdByMe,
    filters.fromDate,
    filters.showDateFilters,
    filters.toDate,
    filters.toDate,
    filters.users,
  ]);

  useLoadingOnScroll(() => checkScrollLoading(() => getImages(getImagesPayload), 1.5));

  const onFiltersLoad = () => requestAnimationFrame(() => setCanLoadFiles(true));

  return {
    images,
    isLoading,
    dispatch,
    onFileInputChange,
    updateFilters: newFilters => {
      setFilters(newFilters);
      Storage.setItem('currentGalleryFilters', newFilters);
    },
    onFiltersLoad,
  };
}

export async function getImages({
  isFeedsLoading,
  isImagesEnded,
  dispatch,
  currentPaginationPage,
  groupId,
  isDefaultGallery,
  galleryId,
}) {
  if (isFeedsLoading.current || isImagesEnded.current) {
    return;
  }
  isFeedsLoading.current = true;

  dispatch({ type: 'startLoading' });

  await loadImages({
    currentPaginationPage,
    isImagesEnded,
    dispatch,
    groupId,
    isDefaultGallery,
    galleryId,
  });

  isFeedsLoading.current = false;
  dispatch({ type: 'endLoading' });
}

export async function loadImages({
  currentPaginationPage,
  isImagesEnded,
  dispatch,
  groupId,
  isDefaultGallery,
  galleryId,
}) {
  const filters = Storage.getItem('currentGalleryFilters');
  const pageSize = 50;
  try {
    const payload = {
      page: currentPaginationPage.current,
      pageSize,
      groupId,
      isDefaultGallery,
      galleryId,
      filters,
    };

    const { data } = await (isDefaultGallery ? getDefaultGalleryFiles : getNonDefaultGalleryFiles)(
      payload,
    );

    const content = data.content || data;

    if (content.length > 0) {
      dispatch({
        type: 'addImages',
        images: content,
      });
    } else if (content.length < pageSize) {
      isImagesEnded.current = true;
    }
    currentPaginationPage.current += 1;
  } catch (ex) {
    // eslint-disable-next-line
    console.error(ex);
  }
}

export async function onChangeHandler({
  uploadFilesToGallery,
  galleryId,
  payload,
  privateGallery,
  dispatch,
}) {
  const { id, files } = await uploadFilesToGallery({
    id: galleryId,
    images: payload,
    privatePost: privateGallery,
  });
  dispatch({ type: 'addImages', images: files.map(file => ({ ...file, posts: { id } })) });
}
