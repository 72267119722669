import i18n from 'i18next';
import sBInstance from 'library/api/axios';

import { URLS } from './resetPasswordConstants';

export const resetPasswordService = data => () => {
  return sBInstance
    .put(URLS.resetPassword, data)
    .then(res => {
      switch (res.status) {
        case 200:
          return { success: true };
        default:
          break;
      }
      return { success: false };
    })
    .catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            return {
              errorMessage: i18n.t('PasswordRecovery.Some fields are missing'),
              success: false,
            };
          case 401:
            return { success: false };
          case 404:
            return {
              errorMessage: i18n.t('PasswordRecovery.Invalid token or expired link'),
              success: false,
            };
          case 405:
            return {
              errorMessage: i18n.t('PasswordRecovery.Invalid token or expired link'),
              success: false,
            };
          case 422:
            return {
              errorMessage: i18n.t(
                'PasswordRecovery.Password should contain at least 1 letter and 1 number or special character',
              ),
              success: false,
            };
          default:
            return { errorMessage: i18n.t('PasswordRecovery.Error'), success: false };
        }
      }
      return false;
    });
};

export const checkTokenAuthentication = token => () => {
  return sBInstance
    .get(`${URLS.resetPassword}/${token}`)
    .then(res => {
      switch (res.status) {
        case 200:
          return true;
        default:
          return false;
      }
    })
    .catch(() => false);
};
