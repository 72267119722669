import React, { useState, useEffect, useRef } from 'react';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Button from 'library/common/commonComponents/Buttons/Button';
import RadioButton from 'library/common/commonComponents/RadioButton';

export default function CapturePaymentDecision({
  changeCurrentPage,
  handleTextStateChange,
  formData,
  type,
  setFormData,
}) {
  return (<>
    <b>Bezahlung</b>
    <br />
    <br />
    <RadioButton
      checked={formData.radioButton1}
      label={'50,- € bezahlen'}
      onClick={() => {
        setFormData(prev => {
          return { ...prev, radioButton1: true, radioButton2: false };
        });
      }}
    />
    <RadioButton
      checked={formData.radioButton2}
      label={'Ich habe mit Pfr. Windolf oder Diakon Thomas etwas anderes vereinbart'}
      onClick={() => {
        setFormData(prev => {
          return { ...prev, radioButton1: false, radioButton2: true };
        });
      }}
    />
    <br />
    <Button
      onClick={() => {
        changeCurrentPage(-1);
      }}
    >
      Zurück
    </Button>
    &nbsp;
    <Button
      onClick={() => {
        changeCurrentPage(1);
      }}
      type='primary'
    >
      Weiter
    </Button>
    <br />
    <br />
  </>);
}