import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useProfileLoading from 'library/common/commonHooks/useProfileLoading';
import Loader from 'library/common/commonComponents/Loader';
import Tabs from 'library/common/commonComponents/Tabs';

import FriendsList from './FriendsFrameFrames/FriendsList';
import RequestsList from './FriendsFrameFrames/RequestsList';
import SentRequestsList from './FriendsFrameFrames/SentRequestsList';

export default function FriendsFrame({ history, match, showBottomNotification, user, activeKita }) {
  const { t } = useTranslation();
  const {
    isLoading,
    friendRequestList,
    onAcceptFriendRequest,
    onCancelFriendRequest,
    onUnFriend,
  } = useProfileLoading({
    match,
    showBottomNotification,
    profileId: user.id,
    userId: user.id,
    isAccountSettingsPage: 'friendsSettings',
    history,
    kitaId: activeKita && activeKita.kitaId,
  });

  const friendsList = useMemo(
    () => friendRequestList.filter(item => item.statusType === 'ACCEPTED'),
    // eslint-disable-next-line
    [friendRequestList],
  );
  const requestsList = useMemo(
    () =>
      friendRequestList.filter(
        item => item.statusType === 'PENDING' && user.id === item.receiverId,
      ),
    // eslint-disable-next-line
    [friendRequestList],
  );
  const sentRequestsList = useMemo(
    () =>
      friendRequestList.filter(
        item => item.statusType === 'PENDING' && user.id === item.requesterId,
      ),
    // eslint-disable-next-line
    [friendRequestList],
  );

  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      title: `${t('AccountSettings.FriendsSection.Friends')} (${friendsList.length})`,
      path: 'friends',
      component: (
        <FriendsList
          friendsList={friendsList}
          onUnFriend={onUnFriend}
          user={user}
          history={history}
        />
      ),
    },
    {
      title: `${t('AccountSettings.FriendsSection.Requests')} (${requestsList.length})`,
      path: 'requests',
      component: (
        <RequestsList
          requestsList={requestsList}
          onAcceptFriendRequest={onAcceptFriendRequest}
          onCancelFriendRequest={onCancelFriendRequest}
          history={history}
        />
      ),
    },
    {
      title: `${t('AccountSettings.FriendsSection.Sent requests')} (${sentRequestsList.length})`,
      path: 'sentRequests',
      component: (
        <SentRequestsList
          sentRequestsList={sentRequestsList}
          onCancelFriendRequest={onCancelFriendRequest}
          history={history}
        />
      ),
    },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <Tabs
      tabs={tabs}
      onChange={(_, index) => setActiveTab(index)}
      currentTab={tabs[activeTab].path}
    />
  );
}
