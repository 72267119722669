import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './orderedMealItem.module.scss';
import { getDateFromFormatedDate } from '../../../../../../../utilities/foodOrder';

export default function OrderedMealItem({ id, date, foods, foodCounts, isActive }) {
  const { t } = useTranslation();
  if (foods.length === 0 && isActive) {
    foods = [
      {
        name: t('FoodOrder.No name'),
        id: -1,
        description: t('FoodOrder.No description'),
        price: 0,
      },
    ];
  }
  const initShowFurtherInformation = () => {
    return foods.map(() => false);
  };
  const [showFurtherInformation, setShowFurtherInformation] = useState(
    initShowFurtherInformation(),
  );

  const toggleShowFurtherInformation = index => {
    setShowFurtherInformation(
      showFurtherInformation.map((element, i) => (i === index ? !element : element)),
    );
  };

  const getOrderCountForFood = food => {
    if (food.id in foodCounts) {
      return foodCounts[food.id];
    }
    return 0;
  };

  return (
    <div className={styles.mealItem}>
      <div className={styles.mealItemHeader}>
        <strong>
          {getDateFromFormatedDate(date, t)} - {date}
        </strong>
      </div>
      <div className={styles.mealItemBody}>
        {isActive ? (
          <>
            <div className={styles.orderedFood}>
              {foods.map((food, index) => (
                <>
                  <div className={styles.orderedFood}>
                    <div className={styles.foodHeader}>
                      <div className={styles.orders}>
                        <strong>{getOrderCountForFood(food)}</strong>
                        <i className={cn('fa fa-cutlery', styles.icon)} />
                      </div>
                      <h3 className={styles.foodName}>{food.name}</h3>
                      <i
                        className={cn(
                          !showFurtherInformation[index]
                            ? 'fa fa-chevron-down'
                            : 'fa fa-chevron-up',
                          styles.showFurtherInformation,
                        )}
                        onClick={() => toggleShowFurtherInformation(index)}
                      />
                    </div>

                    {showFurtherInformation[index] && (
                      <div className={styles.showFurtherInformationContainer}>
                        <br />
                        <strong>{t('FoodOrder.description')}</strong>
                        <div className={styles.border}>
                          {food.description || t('FoodOrder.No description available')}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </>
        ) : (
          <>{t('FoodOrder.OrderedMealItem.not active')}</>
        )}
      </div>
    </div>
  );
}
