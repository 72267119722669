import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import styles from './wrapper.module.scss';

export function Wrapper({ children, isOpened }) {
  return <div className={cn(styles.wrapper, { [styles.wrapperWide]: !isOpened })}>{children}</div>;
}

export default connect(state => ({
  isOpened: state.kitaReducer.isOpened,
}))(Wrapper);
