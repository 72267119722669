import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from './publicGroupPostPopup.module.scss';

export default function PublicGroupPostPopup({popupOpened, setPopupOpened, onSubmit}) {
  const { t } = useTranslation();

  return (
    <Popup
      isOpened={popupOpened}
      closePopup={() => setPopupOpened(false)}
      header={t('Post.Public group')}
      footer={
        <div>
          <div>
            {t('Post.Please confirm, that you are creating a post for the global public group')}
            <br />
            <br />
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonSubmit}>
              <ButtonWithLoader
                onClick={() => {
                  onSubmit();
                  setPopupOpened(false);
                }}
                type='primary'
              >
                {t('Editor.Submit')}
              </ButtonWithLoader>
            </div>
            <div className={styles.buttonCancel}>
              <ButtonWithLoader onClick={() => setPopupOpened(false)}>
                {t('Calendar.Cancel')}
              </ButtonWithLoader>
            </div>
          </div>
        </div>
      }
  />
  );
}
