import { checkUserFreeSpaceForChat } from 'library/api/chat';

export const getUploadFilesHandler = ({
  deletedFilesSize,
  chatId,
  userId,
  uploadFile,
  showBottomNotification,
  t,
  setIsLoadingFile
}) => async filesToUpload => {
  const newFilesSize = [...filesToUpload].reduce((accumulator, currentValue) => {
    return accumulator + currentValue.size;
  }, 0);

  if (setIsLoadingFile) {
    setIsLoadingFile(true);
  }
  const canUploadFiles = chatId
    ? (await checkUserFreeSpaceForChat({
        filesSize: (newFilesSize + (deletedFilesSize || 0)) / 1024 / 1024,
        chatId,
        userId,
      })).data
    : true;
  if (setIsLoadingFile) {
    setIsLoadingFile(false);
  }
  if (canUploadFiles) {
    uploadFile(filesToUpload);
  } else {
    showBottomNotification(
      t(
        'Chat.Unable to upload files as size exceeds the preset limits, please free some memory and try again',
      ),
      {
        isFail: true,
      },
    );
  }
};
