import React, { useState } from 'react';
import bgwLogo from 'resources/images/bgw/bgwLogo_png.png';

export default function NewsRow({ data }) {
  const RE_URL = /\w+:\/\/\S+/g;

  function linkify(str) {
    let match;
    const results = [];
    let lastIndex = 0;
    while ((match = RE_URL.exec(str))) {
      const link = match[0];
      if (lastIndex !== match.index) {
        const text = str.substring(lastIndex, match.index);
        results.push(<span key={results.length}>{text}</span>);
      }
      results.push(
        <a key={results.length} href={link} target='_blank'>
          {link}
        </a>,
      );
      lastIndex = match.index + link.length;
    }
    if (results.length === 0) {
      return str;
    }
    if (lastIndex !== str.length) {
      results.push(<span key={results.length}>{str.substring(lastIndex)}</span>);
    }
    return results;
  }

  return (
    <>
      <div style={{ display: 'flex', whiteSpace: 'pre-line' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: '10px' }}>
            <img src={bgwLogo} />
          </div>
          <div>
            <center>{new Date(data.createdAt).toLocaleDateString('de-DE')}</center>
          </div>
        </div>
        <div style={{ marginLeft: '8px', padding: '10px' }}>{linkify(data.text)}</div>
      </div>
      <hr />
    </>
  );
}
