import {
  SET_LANGUAGE,
  SET_GOOGLE_TRANSLATE_AGREE,
  SET_GOOGLE_TRANSLATE_LANG,
} from '../commonConstants/commonConstants';

export const setLanguage = language => dispatch => {
  let locale = 'en-US';

  switch (language) {
    case 'en':
      locale = 'en-US';
      break;
    case 'de':
      locale = 'de-DE';
      break;
    default:
      break;
  }

  dispatch({
    type: SET_LANGUAGE,
    payload: { language, locale },
  });
};

export const setGoogleTranslateAgree = isAgree => dispatch => {
  dispatch({
    type: SET_GOOGLE_TRANSLATE_AGREE,
    payload: isAgree,
  });
};

export const setGoogleTranslateLang = language => dispatch => {
  dispatch({
    type: SET_GOOGLE_TRANSLATE_LANG,
    payload: language,
  });
};
