import React, { useState, useRef, useEffect, useCallback } from "react";
import styles from './fifthArticle.module.scss';
import { KitaPanelWrapper } from "library/common/commonComponents/KitaPanelWrapper";
import { Wrapper } from "library/common/commonComponents/Wrapper";
import Button from "library/common/commonComponents/Buttons/Button";
import shortid from "shortid";
import Popup from "library/common/commonComponents/Popups/Popup";
import { useTranslation } from "react-i18next";
import { getAwoKitaConnection, saveArticle } from "library/api/kita";
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import useReactRouter from 'use-react-router';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMatchesToMediaTypeArray } from "library/utilities/files";
import { deleteArticle, deleteArticleImage, getArticle, updateArticle, uploadImagesToArticle } from "library/api/articles";
import { connect } from 'react-redux';
import useEditor from "library/common/commonHooks/useEditor";
import { checkLink } from "library/utilities/checkLink";
import { sanitize } from "dompurify";
import Loader from 'library/common/commonComponents/Loader';

// Import React PDF components
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FifthArticle = ({ activeKita, user }) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [deletePopup, setDeletePopup] = useState(false);
    const [deleteID, setDeleteID] = useState(null);
    const [articleDescription, setArticleDescription] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isShowMode, setIsShowMode] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);
    const [articleData, setArticleData] = useState(null);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [noArticle, setNoArticle] = useState(false);
    const [articleId, setArticleId] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [awoConnection, setAWOConnection] = useState(null);

    const { location, history, match } = useReactRouter();
    const kitaId = match.params.id;

    const retrieveData = useCallback(() => {
        setIsLoading(true);
        getArticle(kitaId, 5).then((res) => {
            setArticleData(res.data);
            if (res.data) {
                setUpdateFlag(true);
                setArticleDescription(res.data.descriptionText);
                setArticleId(res.data.id);
                if (res?.data?.images) {
                    const imagesData = res.data.images.map((item) => ({
                        id: item.id,
                        fileId: item.fileName || item.fileId,
                        filetype: item.fileType || 'application/pdf',
                        filePath: item.filePath,
                        filesize: filesizes(item.fileSize),
                    }));
                    setSelectedFiles(imagesData);
                    setSelectedImages(imagesData);
                }
                setIsShowMode(true);
                setIsEditMode(false);
                setNoArticle(false);
                setIsLoading(false);
            } else {
                setEditor(true);
                setNoArticle(true);
                setIsLoading(false);
            }
        }).catch((item) => {
            setNoArticle(true);
            setEditor(true);
            setIsLoading(false);
        });
    }, [kitaId]);

    useEffect(() => {
        retrieveData();
    }, [retrieveData]);

    useEffect(() => {
        getAwoKitaConnection(kitaId).then((res) => {
            setAWOConnection(res.data);
        });
    }, [kitaId]);

    const canEdit = (activeKita.kitaId === awoConnection?.connectedKitaId && user.administrationAccess) || user.superAdminStatus;

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / (k ** i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const handleChange = (event) => {
        const files = [...event.target.files];

        const totalFiles = (selectedImages ? selectedImages.length : 0) + files.length;

        if (totalFiles > 5) {
            store.dispatch(
                showBottomNotification(t('Article.More than 5 files not allowed'), {
                    isFail: true,
                })
            );
            return;
        }

        files.forEach((file) => {
            const id = shortid.generate();
            const fileData = {
                id,
                fileId: file.name,
                filetype: file.type,
                filePath: null,
                filesize: filesizes(file.size),
            };

            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                    fileData.filePath = e.target.result;
                    setSelectedFiles((prevFiles) => [...prevFiles, fileData]);
                };
                reader.readAsDataURL(file);
            } else if (file.type === 'application/pdf') {
                fileData.filePath = URL.createObjectURL(file);
                setSelectedFiles((prevFiles) => [...prevFiles, fileData]);
            } else {
                store.dispatch(
                    showBottomNotification(t('Unsupported file type'), {
                        isFail: true,
                    })
                );
            }

            setSelectedImages((prev) => [...(prev || []), file]);
        });
    };

    const handleDeleteSelectFile = (id, index) => {
        setDeletePopup(true);
        setDeleteID(id);
        setDeleteIndex(index);
    };

    const deleteItem = (id, deleteIndex) => {
        if (selectedFiles && selectedFiles.length > 0) {
            setSelectedFiles(selectedFiles.filter((data) => data.id !== id));
        }
        if (selectedImages && selectedImages.length > 0) {
            setSelectedImages(selectedImages.filter((data, index) => index !== deleteIndex));
        }
        setIsLoading(true);
        deleteArticleImage(id).then((res) => {
            setIsLoading(false);
            store.dispatch(
                showBottomNotification(t('Article.Successfully Deleted'), {
                    isFail: false,
                })
            );
        }).catch(res => setIsLoading(false));
        setDeletePopup(false);
    };

    const handleFileUploadSubmit = async (event) => {
        event.preventDefault();
        event.target.reset(); // Reset form on submit

        if (selectedFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
            setSelectedFiles([]);
        } else {
            alert("Please select a file");
        }
    };

    const submitArticle = () => {
        if (checksBeforeUploading()) {
            setIsLoading(true);
            const editorValue = getEditorValue();
            let articleDesc = '';
            let changed = false;
            if (editorValue.innerHTML === '<p><img class="ProseMirror-separator" alt=""><br class="ProseMirror-trailingBreak"></p>') {
                articleDesc = articleDescription;
            } else {
                articleDesc = editorValue;
                changed = true;
            }
            if (!updateFlag) {
                uploadImagesToArticle(5, selectedImages, kitaId, changed ? checkLink(articleDesc) : articleDesc)
                    .then((res) => {
                        setIsLoading(false);
                        store.dispatch(
                            showBottomNotification(t('Article.Successful Saved'), {
                                isFail: false,
                            })
                        );
                        setEditor(false);
                        retrieveData();
                    })
                    .catch(res => setIsLoading(false));
            } else {
                const filteredItems = selectedImages.filter(item => !('id' in item));
                updateArticle(articleId, changed ? checkLink(articleDesc) : articleDesc, filteredItems)
                    .then((res) => {
                        setIsLoading(false);
                        store.dispatch(
                            showBottomNotification(t('Article.Successful Updated'), {
                                isFail: false,
                            })
                        );
                        setEditor(false);
                        retrieveData();
                    })
                    .catch(res => setIsLoading(false));
            }
        }
    };


    const checksBeforeUploading = () => {
        let sizeError = '';
        const maxFileSize = 200; // in MB
        const allowedExtensions = ['image/*', 'application/pdf'];
        let isValid = true;
        const files = selectedImages || [];

        files.forEach((file) => {
            if (!isMatchesToMediaTypeArray(file.type, allowedExtensions, file.name)) {
                store.dispatch(
                    showBottomNotification(t('Unsupported file type'), {
                        isFail: true,
                    })
                );
                isValid = false;
            }

            if (file.size / 1024 / 1024 >= maxFileSize) {
                sizeError = t("You can't upload files larger than {FILE_SIZE}").replace(
                    '{FILE_SIZE}',
                    `${maxFileSize}MB`
                );
                isValid = false;
            }
        });

        if (sizeError) {
            store.dispatch(
                showBottomNotification(sizeError, {
                    isFail: true,
                })
            );
            isValid = false;
        }

        if (!articleDescription) {
            store.dispatch(
                showBottomNotification(t('Article.Description should not be empty'), {
                    isFail: true,
                })
            );
            isValid = false;
        }

        return isValid;
    };

    const goToEditPage = () => {
        setIsShowMode(false);
        setIsEditMode(true);
        if (noArticle) {
            setEditor(true);
        }
    };

    const goToBackPage = () => {
        window.history.go(-1);
    };

    const deleteFullArticle = () => {
        setIsLoading(true);
        deleteArticle(articleId).then((res) => {
            store.dispatch(
                showBottomNotification(t('Article.Successfully Deleted'), {
                    isFail: false,
                })
            );
            setIsLoading(false);
            goToBackPage();
        }).catch(res => setIsLoading(false));
    };

    const { Editor, getEditorValue, updateValue } = useEditor({
        placeholder: t('Chat.Message Placeholder'),
        onChange: () => {
            const value = getEditorValue();
            setArticleDescription(checkLink(value));
        },
        className: styles.textareaContent,
    });

    useEffect(() => {
        // Cleanup function to remove event listener when component unmounts
        return () => {
            if (fileInputRef.current) {
                fileInputRef.current.removeEventListener("change", handleChange);
            }
            // Clean up object URLs
            selectedFiles.forEach((file) => {
                if (file.filetype === 'application/pdf' && file.filePath) {
                    URL.revokeObjectURL(file.filePath);
                }
            });
        };
    }, [fileInputRef.current, handleChange, selectedFiles]);

    const [editor, setEditor] = useState(false);

    const openEditor = () => {
        setEditor(true);
        const descWrapped = document.createElement('div');
        descWrapped.innerHTML = articleDescription;
        updateValue(descWrapped);
    };

    return (
        <KitaPanelWrapper>
            <Wrapper>
                <div className={styles.fileupload_view}>
                    <div className="col-md-6">
                        <div className={styles.card}>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <div className={styles.card_body}>
                                    <div className={styles.first_article_heading}>
                                        Noch Fragen?
                                    </div>
                                    {isEditMode && (
                                        <div>
                                            <span className={styles.first_article_title}>
                                                Gebt hier Kontaktdaten für weitere Fragen an
                                            </span>
                                            <div className={editor ? styles.editorShow : styles.editorNoShow}>
                                                <Editor />
                                            </div>
                                            {!editor && (
                                                <div className={styles.editText} onClick={() => openEditor()}>
                                                    <div
                                                        className={styles.eventInfoDescription}
                                                        dangerouslySetInnerHTML={{ __html: sanitize(articleDescription) }}
                                                    />
                                                </div>
                                            )}
                                            <div className={styles.kb_data_box}>
                                                <form onSubmit={handleFileUploadSubmit}>
                                                    <div className={styles.kb_file_upload}>
                                                        <div className={styles.file_upload_box}>
                                                            <input
                                                                type="file"
                                                                id="fileupload"
                                                                className={styles.file_upload_input}
                                                                ref={fileInputRef}
                                                                onChange={handleChange}
                                                                multiple
                                                                accept="image/*,application/pdf"
                                                            />
                                                            <span>
                                                                Drag & Drop oder
                                                                <span className={styles.file_link}> Wählen Sie Ihre Datei</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className={styles.kb_attach_box}>
                                                        {selectedFiles.map((data, index) => {
                                                            const { id, fileId, filePath, filetype } = data;
                                                            return (
                                                                <div className={styles.file_atc_box} key={id}>
                                                                    {filetype.startsWith('image/') ? (
                                                                        <div className={styles.file_image}>
                                                                            <img src={filePath} alt={fileId} />
                                                                        </div>
                                                                    ) : filetype === 'application/pdf' ? (
                                                                        <div className={styles.file_image}>
                                                                            <div className={styles.pdfFileText}>PDF</div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className={styles.file_image}>
                                                                            <i className="far fa-file-alt"></i>
                                                                        </div>
                                                                    )}
                                                                    <div className={styles.file_detail}>
                                                                        <h6>{fileId}</h6>
                                                                        <p>{data.filesize}</p>
                                                                        <div className={styles.file_actions}>
                                                                            {filetype === 'application/pdf' && (
                                                                                <a
                                                                                    href={filePath}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className={styles.file_action_btn}
                                                                                >
                                                                                    {t('View PDF')}
                                                                                </a>
                                                                            )}
                                                                            <button
                                                                                type="button"
                                                                                className={styles.file_action_btn}
                                                                                onClick={() => handleDeleteSelectFile(id, index)}
                                                                            >
                                                                                {t('Löschen')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div className={styles.kb_buttons_box}>
                                                        <Button
                                                            type="submit"
                                                            className={styles.submitButton}
                                                            onClick={() => submitArticle()}
                                                        >
                                                            Speichern
                                                        </Button>

                                                        <Button
                                                            onClick={() => goToBackPage()}
                                                            className={styles.backButton}
                                                            type="primary"
                                                        >
                                                            {t('Zurück zur Hauptseite')}
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}

                                    {isShowMode && (
                                        <>
                                            {!noArticle ? (
                                                <>
                                                    <div
                                                        className={styles.eventInfoDescription}
                                                        dangerouslySetInnerHTML={{ __html: sanitize(articleDescription) }}
                                                    />
                                                    <div className={styles.box}>
                                                        <Carousel
                                                            useKeyboardArrows={true}
                                                            className={styles.carousel}
                                                            renderThumbs={() =>
                                                                selectedFiles && selectedFiles.map((data, index) => {
                                                                    const { filetype, filePath, fileId } = data;
                                                                    if (filetype && filetype.startsWith('image/')) {
                                                                        return (
                                                                            <img
                                                                                key={index}
                                                                                alt={fileId}
                                                                                src={filePath}
                                                                                className={styles.thumb}
                                                                            />
                                                                        );
                                                                    } else if (filetype === 'application/pdf') {
                                                                        return (
                                                                            <div key={index} className={styles.pdfThumb}>
                                                                                <span className={styles.pdfThumbText}>PDF</span>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })
                                                            }
                                                        >
                                                            {selectedFiles && selectedFiles.map((data, index) => {
                                                                const { filetype, filePath, fileId } = data;
                                                                if (filetype && filetype.startsWith('image/')) {
                                                                    return (
                                                                        <div className={styles.slide} key={index}>
                                                                            <img alt={fileId} src={filePath} className={styles.img} />
                                                                        </div>
                                                                    );
                                                                } else if (filetype === 'application/pdf') {
                                                                    return (
                                                                        <div className={styles.slide} key={index}>
                                                                            <div className={styles.pdfSlide}>
                                                                                <Document file={filePath}>
                                                                                    <Page pageNumber={1} height={352} />
                                                                                </Document>
                                                                                <a
                                                                                    href={filePath}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className={styles.viewFullPdfLink}
                                                                                >
                                                                                    {t('View full PDF')}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            })}

                                                        </Carousel>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className={styles.noArticle}>
                                                    <p>{t('Article.No Articles to be shown')}</p>
                                                </div>
                                            )}
                                            {(
                                                <Button
                                                    onClick={() => goToEditPage()}
                                                    className={styles.editButton}
                                                    type="primary"
                                                >
                                                    {!noArticle ? t('Artikel bearbeiten') : t('Neuen Artikel erstellen')}
                                                </Button>
                                            )}
                                            {!noArticle && (
                                                <Button
                                                    onClick={() => deleteFullArticle()}
                                                    className={styles.backButton}
                                                    type="primary"
                                                >
                                                    {t('Artikel löschen')}
                                                </Button>
                                            )}
                                            <Button
                                                onClick={() => goToBackPage()}
                                                className={styles.backButton}
                                                type="primary"
                                            >
                                                {t('Zurück zur Hauptseite')}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {deletePopup && (
                    <Popup
                        closePopup={() => setDeletePopup(false)}
                        isOpened
                        footer={
                            <div className={styles.buttonsContainer}>
                                <div className={styles.buttonSubmit}>
                                    <Button onClick={() => deleteItem(deleteID, deleteIndex)} type="primary">
                                        {t('AccountSettings.AbsenceSettings.Yes')}
                                    </Button>
                                </div>
                                <div className={styles.buttonSubmit}>
                                    <Button onClick={() => setDeletePopup(false)} type="primary">
                                        {t('AccountSettings.AbsenceSettings.No')}
                                    </Button>
                                </div>
                            </div>
                        }
                    >
                        <div className={styles.containerEnable}>
                            <h2>Sind Sie sicher, dass Sie die Datei löschen möchten?</h2>
                        </div>
                    </Popup>
                )}
            </Wrapper>
        </KitaPanelWrapper>
    );
};

export default connect(
    (state) => ({
        activeKita: state.kitaReducer.activeKita,
        user: state.userReducer,
    })
)(FifthArticle);
