import { connect } from 'react-redux';

import { setLanguage } from 'library/common/commonActions/changeLanguageActions';
import Authorization from '.';
import {
  handleCheckBoxChange,
  loginService,
  resetEmailAndPasswordError,
  registrationService,
  msLoginService,
} from './authorizationActions';

const mapStateToProps = state => ({
  login: state.authorizationReducer,
  inputErrorState: state.inputErrorReducer,
  disableButtonState: state.buttonDisableReducer,
  language: state.languageReducer,
});

export default connect(
  mapStateToProps,
  {
    resetEmailAndPasswordError,
    handleCheckBoxChange,
    loginService,
    msLoginService,
    registrationService,
    setLanguage,
  },
)(Authorization);
