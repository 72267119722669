import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'use-react-router';

import * as kitaApi from 'library/api/kita';
import useSorting from 'library/common/commonHooks/useSorting';

export default function useKitaList(newKita) {
  const [searchValue, setSearchValue] = useState('');
  const [kitas, setKitas] = useState([]);
  const { history, location } = useRouter();
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalKitasCount, setTotalKitasCount] = useState(0);

  const { sort, setSorting } = useSorting('kitaName');
  const { t } = useTranslation();
  const options = [
    { label: t('Administration.Kita.Activated'), value: false },
    { label: t('Administration.Kita.Deactivated'), value: true },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const loadKitas = () =>
    loadKitasHandler({
      setIsLoading,
      setKitas,
      setTotalKitasCount,
      page,
      sort,
      searchValue,
      deactivated: selectedOption.value,
    });

  useEffect(() => {
    loadKitas();
    // eslint-disable-next-line
  }, [page, sort]);

  useEffect(resetPageHandler({ setPage, location, history, loadKitas }), [
    newKita,
    searchValue,
    selectedOption,
  ]);

  const deactivateKita = kitaId => deactivateKitaHandler({ kitaId, loadKitas });

  return {
    kitas,
    loadPage: setPage,
    isLoading,
    history,
    location,
    totalKitasCount,
    searchValue,
    setSearchValue,
    setSorting,
    sort,
    deactivateKita,
    options,
    selectedOption,
    setSelectedOption,
    isDeactivated: selectedOption.value,
  };
}

export function loadKitasHandler({
  setIsLoading,
  setKitas,
  setTotalKitasCount,
  page,
  sort,
  searchValue,
  deactivated,
}) {
  setIsLoading(true);
  return kitaApi.getAllKitas({ page, sort, searchValue, deactivated }).then(({ data }) => {
    setKitas(data.content);
    setTotalKitasCount(data.totalElements);
    setIsLoading(false);
  });
}

export async function deactivateKitaHandler({ loadKitas, kitaId }) {
  await kitaApi.deactivateKita(kitaId);
  loadKitas();
}

export function resetPageHandler({ setPage, location, history, loadKitas }) {
  return () => {
    setPage(1);

    const { pathname } = location;
    history.push(pathname.slice(0, pathname.lastIndexOf('/')) + '/1');

    loadKitas();
  };
}
