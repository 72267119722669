import { connect } from 'react-redux';

import { setNewKita } from 'library/common/commonActions/kitaActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import CreateKita from '.';

export default connect(
  state => ({ superAdminStatus: state.userReducer.superAdminStatus }),
  {
    setNewKita,
    showBottomNotification,
  },
)(CreateKita);
