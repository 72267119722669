import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { logout } from 'library/api/logout';
import { isAwoWW } from 'library/api/tenantConfig';
import { useDropdown } from 'library/common/commonHooks/useDropdown';
import Dropdown from 'library/common/commonComponents/Dropdowns/Dropdown';
import TextCaret from 'library/common/commonComponents/TextCaret';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import { getUserFullName, getCuttedString } from 'library/utilities/user';
import { isPublicUser } from 'library/utilities/user';

import styles from './topbarUserframe.module.scss';
import awoMember from 'resources/images/awoww/awoheart.png';

export default function TopbarUserframe({ user, kitaColor }) {
  const { t } = useTranslation();
  let dropdownOptions = [
    {
      faIcon: 'fa-user',
      title: t('Header.Topbar.Userframe.My profile'),
      to: `/profile/${user && user.id}/stream`,
      isStaff: false,
    },
    {
      faIcon: 'fa-edit',
      title: t('Header.Topbar.Userframe.Account settings'),
      to: '/account-settings/profile/general',
      isStaff: false,
    },
    {
      isSeparator: true,
      isStaff: true,
    },
    {
      faIcon: 'fa-cogs',
      title: t('Header.Topbar.Userframe.Administration'),
      to: '/administration/users/list/1',
      isStaff: true,
      isAdministration: true
    },
    {
      isSeparator: true,
      isStaff: false,
    },
    {
      faIcon: 'fa-sign-out',
      title: t('Header.Topbar.Userframe.Logout'),
      isStaff: false,
      onClick: () => {
        logout();
      },
    },
    {
      isSeparator: true,
      isStaff: false,
    },
    {
      to: '/privacy',
      title: t('Header.Bottombar.Menu.dataProtection'),
      faIcon: 'fa-info',
    },
    {
      to: '/imprint',
      title: t('Header.Bottombar.Menu.imprint'),
      faIcon: 'fa-info',
    },
  ];

  if (isPublicUser(user)) {
    dropdownOptions = dropdownOptions.filter(option => option.to === '/privacy' || option.to === '/imprint')
  }

  const { DropdownWrapper } = useDropdown(Dropdown);
  if (!user) return null;

  if (kitaColor === null) {
    kitaColor = '#afcb05';
  }
  const userFrameInlineStyles = {
    background: kitaColor,
  };
  if (window.location.hostname.toLowerCase().includes('bvf4u.safe2connect.org')) {
    userFrameInlineStyles.background = '#051957';
  }
  if (window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org')) {
    userFrameInlineStyles.background = '#d7790f';
  }

  return (
    <div className={styles.userFrame} style={userFrameInlineStyles}>
      {user.firstName && user.lastName && !isPublicUser(user) && (
        <div className={styles.nameWrapper}>
          <div className={cn(styles.name, 'notranslate')}>{getUserFullName(user)}</div>
          {user.title && <div className={styles.title}>{getCuttedString(user.title, 20)}</div>}
        </div>
      )}
      <div className={styles.avatar}>
        <ProfileAvatar image={user} id={user.id} withoutLink />
        {isAwoWW() && user.awoMember && (
          <div className={styles.awoMember}>
            <img src={awoMember} alt="AWO Member Icon" />
          </div>
        )}
      </div>
      <div className={styles.textCaret}>
        <TextCaret color='#fff' />
      </div>
      <DropdownWrapper
        dropdownOptions={dropdownOptions.filter(
          item => !item.isStaff || (item.isStaff && user.administrationAccess) || (item.isAdministration && user.ableToEditUserProfiles),
        )}
        closeOnClick
        kitaColor={kitaColor}
        render={dropdown => <div className={styles.dropdownContainer}>{dropdown}</div>}
      />
    </div>
  );
}
