import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../Popups/Popup';
import styles from './userChildrenAddPerKidCodePopup.module.scss';
import Button from '../../../../Buttons/Button';
import Input from '../../../../Inputs/Input';
import { addPerKidCode } from '../../../../../../api/kids';

export default function UserChildrenAddPerKidCodePopup({
  isOpen,
  setIsOpen,
  kitaId,
  setRefresh,
  userId,
}) {
  const { t } = useTranslation();
  const closePopup = () => {
    setIsOpen(false);
    setRefresh(false);
  };

  const submit = () => {
    if (message && kidCode) {
      addPerKidCode(kidCode, kitaId, userId)
        .then(res => {
          if (res.data.success) {
            setMessage({ text: t('Kid.Kid has been added'), isError: false });
          } else {
            switch (res.data.errorCode) {
              case 1:
                setMessage({ text: t('Kid.Kid has already been added'), isError: true });
                break;
              case 2:
                setMessage({ text: t('Kid.An error occured'), isError: true });
                break;
              case 3:
                setMessage({ text: t('Kid.Kita Code has not been found'), isError: true });
                break;
            }
          }
        })
        .catch(res => {
          setMessage({ text: t('Kid.An error occured'), isError: true });
        });
    } else {
      setMessage({ text: t('Kid.Please enter kid code'), isError: true });
    }
  };
  const [message, setMessage] = useState({ text: '', isError: true });
  const [kidCode, setKidCode] = useState('');
  return (
    <Popup
      size='small'
      isOpened={isOpen}
      closePopup={closePopup}
      header={<strong>{t('Kid.Add per kid code')}</strong>}
      footer={
        <div>
          <Button className={styles.mr2} onClick={submit}>
            {t('Kid.Add kid')}
          </Button>
          <Button onClick={() => closePopup()}>{t('Kid.Ok')}</Button>
        </div>
      }
    >
      <div className={styles.popupContainer}>
        <Input
          type='text'
          placeholder={t('Kid.KidCode')}
          onChange={e => setKidCode(e.target.value)}
        />
        {message.text && (
          <p className={message.isError ? styles.red : styles.green}>{message.text}</p>
        )}
      </div>
    </Popup>
  );
}
