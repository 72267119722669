import React, { useState, useEffect } from 'react';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Dictaphone from 'library/common/commonComponents/Dictaphone';
import Select from 'library/common/commonComponents/Inputs/Select';
import kidName from 'resources/images/gfz/gfz_kindname.png';
import gfzLogo from 'resources/images/gfz/gfz_logo.png';
import gfzFooter from 'resources/images/gfz/gfz_footer.png';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {
  getAllKidsFromSelectedKita,
  postGfzParentsTalk,
  getGfzParentsTalk,
  deleteGfzParentsTalk,
} from 'library/api/observationSheet';
import { createKidPortfolioGroupIfNeeded } from 'library/api/kids';
import { createPDFParentTalk } from 'library/utilities/files';
import { useTranslation } from 'react-i18next';

export default function ParentsTalk({ userId }) {

  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [parentsTalk, setParentsTalk] = useState({
    observationPeriod: '',
    appointment: '',
    interviewing: '',
    participatingPersons: '',
    kidsInterest: '',
    kidsEngagement: '',
    kidsWithstand: '',
    kidsExpressing: '',
    kidsResponsibility: '',
    kidsHealth: '',
    nextSteps: '',
    others: '',
    nextConversation: '',
    kidId: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    getAllKidsFromSelectedKita().then(data => {
      const unformattedKidsList = data.data;
      const formattedKidsList = unformattedKidsList.map(obj => {
        return { label: obj.firstName + ' ' + obj.lastName, id: obj.kidId, portfolioGroupId: obj.portfolioGroupId };
      });
      setKidsList(formattedKidsList);
    });
  }, []);

  function resetForm() {
    setParentsTalk(
      {
        observationPeriod: '',
        appointment: '',
        interviewing: '',
        participatingPersons: '',
        kidsInterest: '',
        kidsEngagement: '',
        kidsWithstand: '',
        kidsExpressing: '',
        kidsResponsibility: '',
        kidsHealth: '',
        nextSteps: '',
        others: '',
        nextConversation: '',
        kidId: null,
      });
    setSelectedKid([]);
  }

  function handleTextStateChange(newValue, property) {
    setParentsTalk(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function setKidsInterestText(text) {
    setParentsTalk(prev => {
      return { ...prev, kidsInterest: `${prev.kidsInterest} ${text}` };
    });
  }

  function setKidsEngagementText(text) {
    setParentsTalk(prev => {
      return { ...prev, kidsEngagement: `${prev.kidsEngagement} ${text}` };
    });
  }

  function setKidsWithstandText(text) {
    setParentsTalk(prev => {
      return { ...prev, kidsWithstand: `${prev.kidsWithstand} ${text}` };
    });
  }

  function setKidsExpressingText(text) {
    setParentsTalk(prev => {
      return { ...prev, kidsExpressing: `${prev.kidsExpressing} ${text}` };
    });
  }

  function setKidsResponsibilityText(text) {
    setParentsTalk(prev => {
      return { ...prev, kidsResponsibility: `${prev.kidsResponsibility} ${text}` };
    });
  }

  function setKidsHealthText(text) {
    setParentsTalk(prev => {
      return { ...prev, kidsHealth: `${prev.kidsHealth} ${text}` };
    });
  }

  function setNextStepsText(text) {
    setParentsTalk(prev => {
      return { ...prev, nextSteps: `${prev.nextSteps} ${text}` };
    });
  }

  function setOthersText(text) {
    setParentsTalk(prev => {
      return { ...prev, others: `${prev.others} ${text}` };
    });
  }

  function setNextConversationText(text) {
    setParentsTalk(prev => {
      return { ...prev, nextConversation: `${prev.nextConversation} ${text}` };
    });
  }

  return (
    <>
      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} />
      <br />
      <br />
      <h2>{t('GFZEducationalWork.ParentsTalkMeetingTitle')}</h2>
      <br />
      <table>
        <tr>
          <td>{t('GFZEducationalWork.ParentsTalk')}</td>
          <td>
            <Select
              options={kidsList}
              onSelect={e => {
                setSelectedKid(e);
                getGfzParentsTalk(e.id).then(res => {
                  if (res.data != null) {
                    setParentsTalk(res.data);
                  } else {
                    setParentsTalk({
                      observationPeriod: '',
                      appointment: '',
                      interviewing: '',
                      participatingPersons: '',
                      kidsInterest: '',
                      kidsEngagement: '',
                      kidsWithstand: '',
                      kidsExpressing: '',
                      kidsResponsibility: '',
                      kidsHealth: '',
                      nextSteps: '',
                      others: '',
                      nextConversation: '',
                      kidId: e.id,
                    });
                  }
                });
              }}
              selected={selectedKid}
              style={{ zIndex: '10000000000' }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.ParentsTalkObservationPeriod')}</td>
          <td>
            <Input
              type='text'
              maxLength={50}
              value={parentsTalk.observationPeriod}
              onChange={({ target }) =>
                setParentsTalk(prev => {
                  return {
                    ...prev,
                    observationPeriod: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.ParentsTalkAppointment')}</td>
          <td>
            <Input
              type='text'
              maxLength={50}
              value={parentsTalk.appointment}
              onChange={({ target }) =>
                setParentsTalk(prev => {
                  return {
                    ...prev,
                    appointment: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.ParentsTalkConversationalSkills')}</td>
          <td>
            <Input
              type='text'
              maxLength={50}
              value={parentsTalk.interviewing}
              onChange={({ target }) =>
                setParentsTalk(prev => {
                  return {
                    ...prev,
                    interviewing: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.ParentsTalkParticipants')}</td>
          <td>
            <Input
              type='text'
              maxLength={50}
              value={parentsTalk.participatingPersons}
              onChange={({ target }) =>
                setParentsTalk(prev => {
                  return {
                    ...prev,
                    participatingPersons: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
      </table>
      <br />
      <br />
      <div style={{ borderStyle: 'dotted' }}>
        <b>{t('GFZEducationalWork.ParentsTalk')}</b> {t('GFZEducationalWork.ParentsTalkSpecialistPrepare')}
        <br />
        <br />
        <img style={{ width: '100%', maxWidth: '500px', height: 'auto' }} src={kidName} />
      </div>
      <br />
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>{t('GFZEducationalWork.ParentsTalkDiscussionContent')}</td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildInterest')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsInterestText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.kidsInterest}
              onChange={e => handleTextStateChange(e.target.value, 'kidsInterest')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildCommitment')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsEngagementText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.kidsEngagement}
              onChange={e => handleTextStateChange(e.target.value, 'kidsEngagement')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildFirmlyStanding')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsWithstandText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.kidsWithstand}
              onChange={e => handleTextStateChange(e.target.value, 'kidsWithstand')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildExpress')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsExpressingText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.kidsExpressing}
              onChange={e => handleTextStateChange(e.target.value, 'kidsExpressing')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildResponsibility')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsResponsibilityText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.kidsResponsibility}
              onChange={e => handleTextStateChange(e.target.value, 'kidsResponsibility')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildHealth')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsHealthText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.kidsHealth}
              onChange={e => handleTextStateChange(e.target.value, 'kidsHealth')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildNextSteps')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setNextStepsText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.nextSteps}
              onChange={e => handleTextStateChange(e.target.value, 'nextSteps')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildMiscellaneous')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setOthersText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.others}
              onChange={e => handleTextStateChange(e.target.value, 'others')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ParentsTalkChildNextConversation')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setNextConversationText} />
            </div>
            <br />
            <br />
            <TextArea
              value={parentsTalk.nextConversation}
              onChange={e => handleTextStateChange(e.target.value, 'nextConversation')}
              type='text'
              placeholder={t('GFZEducationalWork.ParentsTalkTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      <Button
        onClick={() => {
          postGfzParentsTalk(parentsTalk).then(() => {
            store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
          });
        }}
        type='primary'
      >
        {t('GFZEducationalWork.ParentsTalkSave')}
      </Button>
      {selectedKid.portfolioGroupId !== null && (
        <Button
          onClick={() => {
            const pdfSaveData = {
              parentsTalk: parentsTalk,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
            }
            createKidPortfolioGroupIfNeeded(selectedKid.id).then(data => {
              createPDFParentTalk(pdfSaveData).then(() => {
                if (parentsTalk.hasOwnProperty('id') && parentsTalk.id !== null) {
                  deleteGfzParentsTalk(parentsTalk.id);
                }
                resetForm();
                store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
              });
            });

          }}
        >
          {t('GFZEducationalWork.ParentsTalkInKidPortfolioSave')}
        </Button>
      )}
      <br />
      <br />
      <img src={gfzFooter} style={{ maxWidth: '100%', height: 'auto' }} />
    </>
  );
}
