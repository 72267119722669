import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from "../../../../../../../library/common/commonComponents/Buttons/Button";
import DragAndDropList from "../DragAndDropList";
import style from "./FormPage.module.scss";

export default function FormPage({
  removeComponent,
  moveComponent,
  startEditComponent,
  renderComponent,
  sortByPosition,
  form,
  setForm,
  getFormFields,
  setAddComponentPopupOpen,
  setMoveComponentPopup,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <DragAndDropList
        removeComponent={removeComponent}
        moveArrowComponent={moveComponent}
        startEditComponent={startEditComponent}
        renderComponent={renderComponent}
        inputList={sortByPosition(getFormFields())}
        setMoveComponentPopup={setMoveComponentPopup}
        form={form}
        setForm={setForm}
      />
      <div className={style.addComponentButtonContainer}>
        <Button onClick={() => setAddComponentPopupOpen(true)} type='success'>
          <i className='fa fa-plus' /> {t('Administration.FormsEditorAddComponentText')}
        </Button>
      </div>
    </div>
  );
}
