import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8080)}`;

export const uploadLanguageFile = file => axios.post(URL + '/api/translations/import-excel/', file);

export const downloadLanguageFile = () =>
  axios.get(URL + '/api/translations/export-excel', { responseType: 'blob' });

export const getTranslations = data => axios.get(URL + '/public/translations-v2/' + data);

export const updateUserGoogleLanguage = languageCode =>
  axios.put(URL + `/api/users/current/translation/${languageCode}/`);

export const deleteUserGoogleLanguage = () => axios.delete(URL + '/api/users/current/translation/');

export const toggleUserConsentPost = () =>
  axios.post(URL + '/api/users/current/translationAllowed');

export const toggleFavoriteOrder = () => axios.post(URL + '/api/users/current/favoriteOrder');
