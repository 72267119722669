import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './foodOrderKita.module.scss';
import Button from '../../../Buttons/Button';
import FoodOrderKitaTabs from './FoodOrderKitaTabs';
import FoodOrderInvoice from './FoodOrderInvoice';

export default function FoodOrderKita({ groupId, userId, activeKita, groupInfo }) {
  const { t } = useTranslation();

  const [orderPlanisOpen, setOrderPlanIsOpen] = useState(true);
  const [monthlyInvoiceIsOpen, setMonthlyInvoiceIsOpen] = useState(false);

  const toggleSecondNav = path => {
    switch (path) {
      case 'orderPlan':
        setOrderPlanIsOpen(true);
        setMonthlyInvoiceIsOpen(false);
        break;
      case 'monthlyInvoice':
        setMonthlyInvoiceIsOpen(true);
        setOrderPlanIsOpen(false);
        break;
    }
  };
  return (
    <div>
      <div className={styles.secondnav}>
        <Button
          type={orderPlanisOpen ? 'info' : 'secondary'}
          className={styles.mr2}
          onClick={() => toggleSecondNav('orderPlan')}
        >
          {t('FoodOrder.Orders')}
        </Button>
        <Button
          type={monthlyInvoiceIsOpen ? 'info' : 'secondary'}
          onClick={() => toggleSecondNav('monthlyInvoice')}
        >
          {t('FoodOrder.Monthly invoices')}
        </Button>
      </div>
      {orderPlanisOpen && (
        <FoodOrderKitaTabs
          groupId={groupId}
          activeKita={activeKita}
          groupInfo={groupInfo}
          userId={userId}
        />
      )}
      {monthlyInvoiceIsOpen && (
        <FoodOrderInvoice groupId={groupId} activeKita={activeKita} groupInfo={groupInfo} />
      )}
    </div>
  );
}
