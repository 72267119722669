import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {
  setGoogleTranslateLang,
  setGoogleTranslateAgree,
} from 'library/common/commonActions/changeLanguageActions';
import { patchUserInfo } from 'library/common/commonActions/userActions';
import { getGoogleTranslatorModuleEnabled } from 'library/common/commonSelectors/kitaSelectors';

import AccountSettings from '.';

export const mapStateToProps = state => ({
  user: state.userReducer,
  activeKita: state.kitaReducer.activeKita,
  isGoogleTranslateAgree: state.languageReducer.isGoogleTranslateAgree,
  isGoogleTranslatorActive: getGoogleTranslatorModuleEnabled(state),
  modules: state.kitaReducer.modules,
});

export const mapDispatchToProps = {
  showBottomNotification,
  setGoogleTranslateLang,
  setGoogleTranslateAgree,
  patchUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettings);
