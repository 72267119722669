import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import SortIndicator from 'library/common/commonComponents/SortIndicator';
import KitaFrameListRow from '../KitaFrameListRow';

import styles from '../../kitaFrameList.module.scss';

export default function KitaFrameListTable({
  kitas,
  setSorting,
  sort,
  deactivateKita,
  superAdminStatus,
  user,
}) {
  //Test for deployment number 2
  const { t } = useTranslation();
  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <tr>
          <td
            className={cn(styles.headerText, styles.headerTextWithPadding)}
            onClick={() => setSorting('kitaName')}
          >
            <div className={styles.headerTextContainer}>
              {t('Administration.Kita.Kita Name')}
              <SortIndicator sort={sort} name='kitaName' />
            </div>
          </td>
          <td className={styles.headerText} onClick={() => setSorting('city')}>
            <div className={styles.headerTextContainer}>
              {t('Administration.Kita.Address')}
              <SortIndicator sort={sort} name='city' />
            </div>
          </td>
          <td className={styles.headerText} onClick={() => setSorting('createdAt')}>
            <div className={styles.headerTextContainer}>
              {t('Administration.Kita.Created On')}
              <SortIndicator sort={sort} name='createdAt' />
            </div>
          </td>
          <td />
        </tr>

        {kitas.map(kita => (
          <KitaFrameListRow
            key={kita.id}
            kita={kita}
            deactivateKita={deactivateKita}
            superAdminStatus={superAdminStatus}
            user={user}
          />
        ))}
      </table>
    </div>
  );
}
