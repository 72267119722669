import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import Loader from 'library/common/commonComponents/Loader';
import { getPermissionsForUser } from 'library/api/user';
import KitaListItem from './KitaListItem';
import AddNewKita from './AddNewKita';
import styles from './kitaList.module.scss';
import cachedRequest from '../../../../library/common/commonHooks/useCachedRequest';
import { URL } from '../../../../library/api/chat';
import { isAwoWW } from 'library/api/tenantConfig';

export function KitaList({
  history,
  isOpened,
  kitaList,
  addNewKita,
  setKita,
  isLoading,
  setActiveKitaFromProps,
  activeKitaFromParent,
  setActiveKitaFromParent,
  height,
  patchKita,
  unreadNotificationsCount,
  unreadTaxReceiptCount,
  setUserPermissions,
  userIsAdmin,
  superAdminStatus,
  setRenderedKitaList,
  setUnreadMessagesCountsKitas,
  unreadMessagesCountsKitas,
}) {
  const [active, setActive] = useState(null);
  const activeKita = active || kitaList.find(kita => kita.selectedKitaStatus);

  const kitaId = activeKita ? activeKita.kitaId : null;

  const getPermissions = async () => {
    const response = await getPermissionsForUser();

    if (response && response.data && setUserPermissions) {
      setUserPermissions(response.data);
    }
  };

  useEffect(() => {
    if (kitaId) {
      setActiveKitaFromProps(activeKita || kitaList[0]);
      setActive(kitaId);
    }

    // TODO: dirty hack - if we load this before all the kitas are fetched,
    //  the result contains only a part of the kitas and is cached, i.e.,
    //  the other kitas' unread counts are never fetched
    setTimeout(() => {
      const kitaIds = kitaList.map(k => k.kitaId);
      cachedRequest({
        method: 'get',
        url: `${URL}/chat/unread-count`,
        settings: {
          params: {
            kitaIds: kitaIds && kitaIds.length ? kitaIds.reduce((f, s) => `${f},${s}`) : '',
          },
        },
      }).then(({ data }) => {
        const newUnreadMessagesCounts = {};
        for (const [key, value] of Object.entries(data)) {
          if (key !== '-1') newUnreadMessagesCounts[key] = value.count;
        }
        setUnreadMessagesCountsKitas(newUnreadMessagesCounts);
      });
    }, 1000);
    // eslint-disable-next-line
  }, [kitaList]);

  const setActiveKita = id => {
    if (id !== active) {
      setActiveKitaFromProps(kitaList.find(kita => kita.kitaId === id));
      setKita(id);
      setActive(id);
      setActiveKitaFromParent(id);
    }
  };

  useEffect(() => {
    if (activeKitaFromParent && activeKitaFromParent !== active) {
      setActiveKita(activeKitaFromParent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKitaFromParent]);

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const orderKitas = kita => {
    patchKita(kita);
    setRenderedKitaList(kitaList);
  };

  const hexToRgb = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  return (
    !isAwoWW() &&( <div className={styles.kitaListContainer}>
      {isLoading ? (
        <Loader dotSize='13px' />
      ) : (
        <div style={{ height }}>
          <Scrollbars autoHide>
            {kitaList.map(kita => {
              const rgb = hexToRgb(kita.kitaColorCode);
              let darkText = false;
              if (rgb != null) {
                const luminance = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
                darkText = luminance > 220;
              }

              return (
                <KitaListItem
                  kita={kita}
                  key={kita.kitaId}
                  history={history}
                  isOpened={isOpened}
                  isActive={active === kita.kitaId}
                  setActiveKita={setActiveKita}
                  setKita={setKita}
                  patchKita={patchKita}
                  userIsAdmin={userIsAdmin}
                  superAdminStatus={superAdminStatus}
                  unreadNotificationsCount={unreadNotificationsCount}
                  unreadMessagesCount={
                    unreadMessagesCountsKitas[kita.kitaId]
                      ? unreadMessagesCountsKitas[kita.kitaId]
                      : 0
                  }
                  unreadTaxReceiptCount={unreadTaxReceiptCount}
                  orderKitas={orderKitas}
                  displayKitaImages={!superAdminStatus}
                  darkText={darkText}
                />
              );
            })}
            <AddNewKita isOpened={isOpened} onClick={addNewKita} />
            <div style={{ height: '100px' }} />
          </Scrollbars>
        </div>
      )}
    </div>)
  );
}

export default KitaList;
