import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function tabTypeMapping(type) {
  if (type === 'addDays') {
    return 4;
  }
  if (type === 'contractChanges') {
    return 3;
  }
  if (type === 'kidLeavings') {
    return 2;
  }
  if (type === 'medicalAuthorizations') {
    return 1;
  }
  return 0;
}

function getIconByStatus(status) {
  if (status === true) {
    return (
      <div style={{ color: 'green' }}>
        <i className='fa fa-check' />
      </div>
    );
  } else {
    return (
      <div style={{ color: '#f5721b' }}>
        <i className='fa fa-spinner' />
      </div>
    );
  }
}

export default function GfzDocumentationRow({ rowData, user, setActiveTab, setKidId, setFormId }) {
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState(" ");
  const [childValue, setChildValue] = useState(0);

  useEffect(() => {
    if(window.location.search.substring != null && window.location.search.length > 0) {
      const queryString = window.location.search.substring(1);
      const urlParams = new URLSearchParams(queryString);
      if(urlParams.get('form') === "MedicalAuthorization" || urlParams.get('form') === "KidLeaving") {
        setFormValue(urlParams.get('form') + "s");
      } else {
        setFormValue(urlParams.get('form'));
      }
      setChildValue(urlParams.get('child'));
    }
  })

  const translateGfzFormType = type => {
    if (type === 'addDays') {
      return t('GfzDocumentation.AdditionalDays');
    }
    if (type === 'contractChanges') {
      return t('GfzDocumentation.ContractChanges');
    }
    if (type === 'kidLeavings') {
      return t('GfzDocumentation.KidLeaving');
    }
    if (type === 'medicalAuthorizations') {
      return t('GfzDocumentation.MedicalAuthorization');
    }
    return '';
  };

  return (
    <tr onClick={() => {
      setFormId(rowData.formId);
      setKidId(rowData.kidId);
      setActiveTab(tabTypeMapping(rowData.formsType));
    }} style={{ cursor: "pointer", backgroundColor: formValue.toLowerCase() === rowData.formsType.toLowerCase() && Number(childValue) === rowData.kidId ? "#adf294" : "white" }}>
      <td>{rowData.date}</td>
      <td>{translateGfzFormType(rowData.formsType)}</td>
      <td>{rowData.kidName}</td>
      <td>{getIconByStatus(rowData.kitaSignStatus)}</td>
      <td>{getIconByStatus(rowData.parentSignStatus)}</td>
    </tr>
  );
}
