import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../library/common/commonComponents/Buttons/Button';
import Input from '../../../../../../library/common/commonComponents/Inputs/Input';
import AddFormComponentPopup from './FormsEditorFrames/AddFormComponentPopup';
import MoveFormComponentPopup from './FormsEditorFrames/MoveFormComponentPopup';
import EditFormComponentPopup from './FormsEditorFrames/EditFormComponentPopup';
import RadioButton from 'library/common/commonComponents/RadioButton';
import Label from '../../../../../../library/common/commonComponents/Label';
import { saveForm } from '../../../../../../library/api/formEditor';
import { isMobile } from 'react-device-detect';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ButtonWithLoader from '../../../../../../library/common/commonComponents/Buttons/ButtonWithLoader';
import FormPage from './FormPage';
import Tabs from 'library/common/commonComponents/Tabs';
import { getTableParentalContributionPriceByFacilityTypeAndRow } from '../../../../../../library/utilities/forms';
import defaultSignatureImage from 'resources/images/formEditor/defaultSignatureImage.png';
import RadioButtonGroupContainer from 'library/common/commonComponents/RadioButtonGroupContainer/RadioButtonGroupContainer';
import style from './FormsEditor.module.scss';

export default function FormsEditor({
  editForm,
  cancelEdit,
  reloadForms,
  templates,
  reloadTemplates,
  activeKita,
}) {
  const { t } = useTranslation();

  const [form, setForm] = useState(editForm);
  const [addComponentPopupOpen, setAddComponentPopupOpen] = useState(false);
  const [moveComponentPopup, setMoveComponentPopup] = useState({
    open: false,
    position: null,
    componentCount: null,
  });

  const [editComponentIndex, setEditComponentIndex] = useState(null);
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingAndClosing, setIsSavingAndClosing] = useState(false);

  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const [curPageNumberValue, setCurPageNumberValue] = useState('1');

  const getMaxPosition = page => {
    const formFields = form.formFields.filter(ff => ff.page.id === page.id);
    if (!formFields || formFields.length < 1) {
      return 0;
    }

    return sortByPosition(formFields)[formFields.length - 1].position;
  };

  const getPages = () => {
    const pages = [...form.newPages] || [];
    form.formFields.forEach(ff => {
      if (pages.filter(p => p.id === ff.page.id).length === 0) {
        pages.push(ff.page);
      }
    });

    return pages;
  };

  const instantiateComponent = c => {
    setForm({
      ...form,
      formFields: [
        ...form.formFields,
        {
          ...c,
          position: getMaxPosition(tabs[currentTab].page) + 1,
          page: tabs[currentTab].page,
        },
      ],
    });
  };

  const removeComponent = (pos, page) => {
    setForm({
      ...form,
      formFields: form.formFields
        .map(f => {
          if (f.position === pos && f.page.id === page.id) {
            return null;
          } else if (f.position > pos && f.page.id === page.id) {
            return {
              ...f,
              position: f.position - 1,
            };
          }

          return f;
        })
        .filter(f => f !== null),
    });
  };

  const moveComponent = (pos, page, dir) => {
    if (pos + dir < 1 || pos + dir > getMaxPosition(page)) {
      return;
    }

    const newPosition = pos + dir;

    const posSwitchFields = form.formFields.map(f => {
      if (f.position === pos && f.page.id === page.id) {
        return {
          ...f,
          position: newPosition,
        };
      } else if (
        f.position > pos &&
        f.page.id === page.id &&
        f.position <= newPosition &&
        dir > 0
      ) {
        return {
          ...f,
          position: f.position - 1,
        };
      } else if (
        f.position < pos &&
        f.page.id === page.id &&
        f.position >= newPosition &&
        dir < 0
      ) {
        return {
          ...f,
          position: f.position + 1,
        };
      }
      return f;
    });

    setForm({
      ...form,
      formFields: posSwitchFields,
    });
  };

  /*const moveComponent = (pos, page, dir) => {

    console.log(pos);
    console.log(page);
    console.log(dir);

    console.log(form.formFields);

    if (pos + dir < 0 || pos + dir > getMaxPosition(page)) {
      return;
    }

    const posSwitchFields = form.formFields.map(f => {
      if (f.position === pos && f.page.id === page.id) {
        return {
          ...f,
          position: f.position + dir,
        };
      } else if (f.position === pos + dir && f.page.id === page.id) {
        return {
          ...f,
          position: f.position - dir,
        };
      }

      return f;
    });

  setForm({
    ...form,
    formFields: posSwitchFields,
  });
};*/

  const startEditComponent = ff => {
    let globalIndex = null;

    for (let i = 0; i < form.formFields.length; i++) {
      const formField = form.formFields[i];
      if (formField.page.id === ff.page.id && formField.position === ff.position) {
        globalIndex = i;
        break;
      }
    }

    if (globalIndex == null || form.formFields[globalIndex].type === 'pagebreak') {
      return;
    }

    setEditComponentIndex(globalIndex);
  };

  const onComponentSave = (ff, index) => {
    setForm({
      ...form,
      formFields: form.formFields.map((f, i) => {
        if (i === index) {
          return ff;
        }

        return f;
      }),
    });

    setEditComponentIndex(null);
  };

  const save = close => {
    const curErrors = [];
    if (form.name.length === 0) {
      curErrors.push(t('FormsEditor.EmptyNameError'));
    }

    const pages = getPages();
    pages.forEach(p => {
      if (p.name.length === 0) {
        curErrors.push(t('FormsEditor.EmptyPageNameError').replace('{pageNumber}', p.pageNumber));
      }
    });

    setErrors(curErrors);

    if (curErrors.length > 0) {
      return;
    }

    if (close) {
      setIsSavingAndClosing(true);
    } else {
      setIsSaving(true);
    }
    saveForm(form)
      .then(res => {
        setForm({
          ...res.data,
          newPages: [],
        });
        store.dispatch(showBottomNotification(t('FormsEditor.SaveSuccess'), { isFail: false }));
        reloadForms();
        if (close) {
          cancelEdit();
        }
      })
      .catch(err => {
        store.dispatch(showBottomNotification(t('FormsEditor.SaveError'), { isFail: true }));
      })
      .finally(() => {
        setIsSavingAndClosing(false);
        setIsSaving(false);
      });
  };

  const closeMoveComponentPopup = () => {
    setMoveComponentPopup({ open: false, position: null, componentCount: null });
  };

  const renderComponent = (ff, inputWidth) => {
    if (ff.type === 'header') {
      return (
        <p
          style={{
            marginLeft: `${ff.indent}pt`,
            fontSize: `${ff.fontSize}pt`,
            fontWeight: ff.bold ? 'bold' : 'normal',
            fontStyle: ff.italic ? 'italic' : 'normal',
            textDecoration: ff.underlined ? 'underline' : 'none',
            textAlign: ff.alignment,
            color: ff.text === '' ? '#bbbbbb' : 'black',
          }}
        >
          {ff.text === '' ? t('FormsEditor.ComponentPlaceholderHeader') : ff.text}
        </p>
      );
    } else if (ff.type === 'paragraph') {
      return (
        <p
          style={{
            marginLeft: `${ff.indent}pt`,
            fontSize: `${ff.fontSize}pt`,
            fontWeight: ff.bold ? 'bold' : 'normal',
            fontStyle: ff.italic ? 'italic' : 'normal',
            textDecoration: ff.underlined ? 'underline' : 'none',
            textAlign: ff.alignment,
            color: ff.text === '' ? '#bbbbbb' : 'black',
            whiteSpace: 'pre-line',
          }}
        >
          {ff.text === '' ? t('FormsEditor.ComponentPlaceholderParagraph') : ff.text}
        </p>
      );
    } else if (ff.type === 'input') {
      if (ff.inputType === 'signatureImage') {
        return (
          <>
            <p style={{ marginLeft: `${ff.indent}pt`, fontSize: '12pt', display: 'inline' }}>
              {ff.labelLeft} <br />
              <input
                disabled
                type={'file'}
                className={style.inlineInput}
                style={{ width: inputWidth || '35%' }}
              />
              <br />
              <br />
              <img style={{ maxHeight: '130pt', maxWidth: '100%' }} src={defaultSignatureImage} />
              <br /> {ff.labelRight}
            </p>
          </>
        );
      } else if (ff.inputType === 'image') {
        return (
          <>
            <p style={{ marginLeft: `${ff.indent}pt`, fontSize: '12pt', display: 'inline' }}>
              {ff.labelLeft} <br />
              <input
                disabled
                type={'file'}
                className={style.inlineInput}
                style={{ width: inputWidth || '35%' }}
              />
              <br />
              <br /> {ff.labelRight}
            </p>
          </>
        );
      } else {
        return (
          <p style={{ marginLeft: `${ff.indent}pt`, fontSize: '12pt' }}>
            {ff.labelLeft}{' '}
            <input
              disabled
              type={ff.inputType}
              placeholder={
                ff.presetValueType && ff.presetValueType.length > 0
                  ? ff.presetValueType
                  : ff.placeholder
              }
              className={style.inlineInput}
              style={{ width: inputWidth || '35%' }}
            />{' '}
            {ff.labelRight}
          </p>
        );
      }
    } else if (ff.type === 'doubleInput') {
      return (
        <table className={style.table}>
          <tr>
            <td style={{ width: '50%' }}>{renderComponent(ff.inputLeft, '60%')}</td>
            <td style={{ width: '50%' }}>{renderComponent(ff.inputRight, '60%')}</td>
          </tr>
        </table>
      );
    } else if (ff.type === 'doubleDropdown') {
      return (
        <table className={style.table}>
          <tr>
            <td style={{ width: '50%' }}>{renderComponent(ff.dropdownLeft, '60%')}</td>
            <td style={{ width: '50%' }}>{renderComponent(ff.dropdownRight, '60%')}</td>
          </tr>
        </table>
      );
    } else if (ff.type === 'checkbox') {
      return (
        <p style={{ marginLeft: `${ff.indent}pt`, fontSize: '12pt' }}>
          {ff.labelLeft}{' '}
          <input
            disabled
            type='checkbox'
            checked={ff.defaultChecked}
            className={style.inlineCheckbox}
          />{' '}
          {ff.labelRight}
        </p>
      );
    } else if (ff.type === 'dropdown') {
      const defaultOption = Math.max(ff.defaultOption, 0);

      return (
        <p style={{ marginLeft: `${ff.indent}pt`, fontSize: '12pt' }}>
          {ff.labelLeft}{' '}
          {ff.inputType === 'radio' ? (
            <>
              <RadioButtonGroupContainer>
                {ff.options.map((op, i) => {
                  return <RadioButton label={op} checked={i === defaultOption} disabled={true} />;
                })}
              </RadioButtonGroupContainer>
            </>
          ) : (
            <select disabled>
              {ff.options.map((op, i) => {
                if (i === defaultOption) {
                  return <option selected>{op}</option>;
                }

                return <option>{op}</option>;
              })}
            </select>
          )}{' '}
          {ff.labelRight}
        </p>
      );
    } else if (ff.type === 'pagebreak') {
      return <p>{t('Administration.FormsEditorPageBreak')}</p>;
    } else if (ff.type === 'image') {
      return (
        <div>
          {ff.data && ff.data !== '' ? (
            <img
              src={ff.data}
              width={`${ff.scale}%`}
              style={{
                display: 'block',
                marginLeft: ff.alignment === 'left' ? `${ff.indent}pt` : 'auto',
                marginRight: ff.alignment === 'right' ? '0' : 'auto',
              }}
            />
          ) : (
            <p>{t('Administration.FormsEditorClickToEditImage')}</p>
          )}
        </div>
      );
    } else if (ff.type === 'tableBookingTimes') {
      return (
        <div>
          <table className={style.table}>
            <tr>
              <th />
              <th>Mo</th>
              <th>Di</th>
              <th>Mi</th>
              <th>Do</th>
              <th>Fr</th>
            </tr>
            {Array.from({ length: ff.numberFromToRows }, (_, i) => {
              return (
                <>
                  <tr>
                    <td>von</td>
                    <td>___ Uhr</td>
                    <td>___ Uhr</td>
                    <td>___ Uhr</td>
                    <td>___ Uhr</td>
                    <td>___ Uhr</td>
                  </tr>
                  <tr>
                    <td>bis</td>
                    <td>___ Uhr</td>
                    <td>___ Uhr</td>
                    <td>___ Uhr</td>
                    <td>___ Uhr</td>
                    <td>___ Uhr</td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td>= maximal gebuchte Zeit</td>
              <td>___ Stunden</td>
              <td>___ Stunden</td>
              <td>___ Stunden</td>
              <td>___ Stunden</td>
              <td>___ Stunden</td>
            </tr>
            <tr>
              <td colSpan='6'>Wochenstunden gesamt: ___ Stunden</td>
            </tr>
          </table>
          <br />
          Wochenstunden gesamt geteilt durch 5 Tage = Buchungskategorie:
          <br />
          <table className={style.table}>
            <tr>
              <th>Tägl. durchschnittlich</th>
              <th>mehr als 3-4 Std.</th>
              <th>mehr als 4-5 Std.</th>
              <th>mehr als 5-6 Std.</th>
              <th>mehr als 6-7 Std.</th>
              <th>mehr als 7-8 Std.</th>
              <th>mehr als 8-9 Std.</th>
              <th>mehr als 9-10 Std.</th>
            </tr>
            <tr>
              <td>Bitte ankreuzen</td>
              <td>
                <input disabled type='checkbox' checked={false} className={style.inlineCheckbox} />
              </td>
              <td>
                <input disabled type='checkbox' checked={false} className={style.inlineCheckbox} />
              </td>
              <td>
                <input disabled type='checkbox' checked={false} className={style.inlineCheckbox} />
              </td>
              <td>
                <input disabled type='checkbox' checked={false} className={style.inlineCheckbox} />
              </td>
              <td>
                <input disabled type='checkbox' checked={false} className={style.inlineCheckbox} />
              </td>
              <td>
                <input disabled type='checkbox' checked={false} className={style.inlineCheckbox} />
              </td>
              <td>
                <input disabled type='checkbox' checked={false} className={style.inlineCheckbox} />
              </td>
            </tr>
          </table>
        </div>
      );
    } else if (ff.type === 'tableParentalContribution') {
      return (
        <div>
          <table className={style.table}>
            <tr>
              <th>Durchschnittliche tägliche Buchungszeit</th>
              <th colSpan='3'>Elternbeitrag pro Monat</th>
              <th>Zu zahlender Beitrag pro Monat</th>
            </tr>
            <tr>
              <td />
              <td>
                Beiträge{' '}
                {ff.facilityType === 'krippe'
                  ? 'Krippe'
                  : ff.facilityType === 'hort'
                  ? 'Hort'
                  : 'Kindergarten'}
              </td>
              <td>Ermäßigung für Geschwisterkinder</td>
              <td>Elternbeitragszuschuss gemäß Art. 23 BayKiBiG **)</td>
              <td />
            </tr>
            <tr>
              <td>> 1 - 2 *)</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 0) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 2 - 3 *)</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 1) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 3 - 4 *)</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 2) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 4 - 5</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 3) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 5 - 6</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 4) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 6 - 7</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 5) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 7 - 8</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 6) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 8 - 9</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 7) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 9 - 10</td>
              <td>
                {getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 8) || ''}
              </td>
              <td />
              <td />
              <td />
            </tr>
          </table>
          <p>
            *) Diese Kategorien sind für Kinder ab 3 Jahren bis zur Einschulung nicht förderfähig.
          </p>
          <p>
            **) Der in Art. 23 BayKiBiG geregelte Elternbeitragszuschuss wird an die Eltern
            weitergegeben. Der aufgeführte Elternbeitrag verringert sich dementsprechend: "Der
            Zuschuss beträgt 100 Euro pro Monat und wird für die Zeit vom 1. September des
            Kalenderjahres, in dem das Kind das dritte Lebensjahr vollendet, bis zum Schuleintritt
            gewährt." (Art. 23 Abs. 3 Satz 2 BayKiBiG)
          </p>
        </div>
      );
    } else if (ff.type === 'tableParentalContributionWithHolidays') {
      return (
        <div>
          <table className={style.table}>
            <tr>
              <th />
              <th>Regelmäßige Buchungskategorie in der Schulzeit</th>
              <th />
              <th colSpan='3'>Buchungskategorie in der Schulferienzeit</th>
            </tr>
            <tr>
              <th>Buchungszeit-Kategorien</th>
              <td>
                Buchung ={' '}
                <input
                  disabled
                  type='number'
                  className={style.inlineInput}
                  style={{ width: inputWidth || '35%' }}
                />{' '}
                Monate
              </td>
              <th>Beitrag in € monatlich</th>
              <td>ab 15 Tage***) = 1 Monat förderfähig</td>
              <td>ab 30 Tage***) = 2 Monate förderfähig</td>
              <td>ab 45 Tage***) = 3 Monate förderfähig</td>
            </tr>
            <tr>
              <td>> 1 bis 2 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 2 bis 3 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 3 bis 4 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 4 bis 5 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 5 bis 6 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 6 bis 7 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 7 bis 8 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 8 bis 9 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>> 9 bis 10 Stunden **)</td>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          </table>

          <p style={{ marginTop: '2em' }}>
            Für ___ Monate ___ € + ___ Monate ___ € = ___ € / 12 Monate
          </p>
        </div>
      );
    }
  };

  const sortByPosition = ffs => {
    return [...ffs].sort((a, b) => a.position - b.position);
  };

  const getFormFieldsByPage = pageId => {
    return form.formFields.filter(ff => ff.page.id === pageId);
  };

  const rebuildTabs = form => {
    const pages = getPages();

    const pageTabs = pages.map(p => {
      return {
        title: p.name,
        component: (
          <FormPage
            removeComponent={removeComponent}
            moveComponent={moveComponent}
            startEditComponent={startEditComponent}
            renderComponent={renderComponent}
            sortByPosition={sortByPosition}
            form={form}
            setForm={setForm}
            setMoveComponentPopup={setMoveComponentPopup}
            getFormFields={() => getFormFieldsByPage(p.id)}
            setAddComponentPopupOpen={setAddComponentPopupOpen}
          />
        ),
        path: `page${p.pageNumber}`,
        page: p,
      };
    });

    pageTabs.sort((a, b) => a.page.pageNumber - b.page.pageNumber);

    setTabs(pageTabs);
  };

  useEffect(() => {
    rebuildTabs(form);
  }, [form]);

  useEffect(() => {
    if (tabs && tabs.length > currentTab) {
      setCurPageNumberValue(`${tabs[currentTab].page.pageNumber}`);
    }
  }, [currentTab]);

  const addNewFormPage = () => {
    let newPageId = -1;
    form.newPages.forEach(p => {
      if (p.id <= newPageId) {
        newPageId = p.id - 1;
      }
    });

    const pageObj = {
      id: newPageId,
      name: `Seite ${tabs.length + 1}`,
      pageNumber: tabs.length + 1,
    };

    setForm({
      ...form,
      newPages: [...form.newPages, pageObj],
    });
  };

  const handlePageNameUpdate = newName => {
    const curPage = tabs[currentTab].page;

    const updatedNewPages = form.newPages.map(p => {
      if (p.id === curPage.id) {
        return {
          ...p,
          name: newName,
        };
      }

      return p;
    });

    if (form.newPages.filter(p => p.id === curPage.id).length === 0) {
      updatedNewPages.push({
        ...curPage,
        name: newName,
      });
    }

    setForm({
      ...form,
      newPages: updatedNewPages,
      formFields: form.formFields.map(ff => {
        if (ff.page.id === curPage.id) {
          return {
            ...ff,
            page: {
              ...ff.page,
              name: newName,
            },
          };
        }

        return ff;
      }),
    });
  };

  const handlePageNumberUpdate = newNumber => {
    setCurPageNumberValue(newNumber);

    const intNumber = parseInt(newNumber, 10);
    if (isNaN(newNumber) || isNaN(intNumber) || intNumber < 1 || intNumber > tabs.length) {
      return;
    }

    const curPage = tabs[currentTab].page;
    const prevPageNum = curPage.pageNumber;

    const direction = Math.sign(prevPageNum - intNumber);
    if (direction === 0) {
      return;
    }

    const updatedNewPages = form.newPages.map(p => {
      if (p.id === curPage.id) {
        return {
          ...p,
          pageNumber: intNumber,
        };
      } else if (
        (direction > 0 && p.pageNumber >= intNumber && p.pageNumber < prevPageNum) ||
        (direction < 0 && p.pageNumber > prevPageNum && p.pageNumber <= intNumber)
      ) {
        return {
          ...p,
          pageNumber: p.pageNumber + direction,
        };
      }

      return p;
    });

    if (form.newPages.filter(p => p.id === curPage.id).length === 0) {
      updatedNewPages.push({
        ...curPage,
        pageNumber: intNumber,
      });
    }

    const pages = getPages();

    pages
      .filter(
        p =>
          p.id !== curPage.id &&
          ((direction < 0 && p.pageNumber > prevPageNum && p.pageNumber <= intNumber) ||
            (direction > 0 && p.pageNumber >= intNumber && p.pageNumber < prevPageNum)) &&
          form.newPages.filter(p2 => p2.id === p.id).length === 0,
      )
      .forEach(p => {
        updatedNewPages.push({
          ...p,
          pageNumber: p.pageNumber + direction,
        });
      });

    setForm({
      ...form,
      newPages: updatedNewPages,
      formFields: form.formFields.map(ff => {
        if (ff.page.id === curPage.id) {
          return {
            ...ff,
            page: {
              ...ff.page,
              pageNumber: intNumber,
            },
          };
        } else if (
          (direction < 0 && ff.page.pageNumber > prevPageNum && ff.page.pageNumber <= intNumber) ||
          (direction > 0 && ff.page.pageNumber >= intNumber && ff.page.pageNumber < prevPageNum)
        ) {
          return {
            ...ff,
            page: {
              ...ff.page,
              pageNumber: ff.page.pageNumber + direction,
            },
          };
        }

        return ff;
      }),
    });
    setCurrentTab(intNumber - 1);
  };

  const deleteCurFormPage = () => {
    if (!tabs || tabs.length <= 1) {
      return;
    }

    const curPageId = tabs[currentTab].page.id;

    if (currentTab === tabs.length - 1) {
      setCurrentTab(currentTab - 1);
    }

    setForm({
      ...form,
      formFields: form.formFields.filter(ff => ff.page.id !== curPageId),
      newPages: form.newPages.filter(p => p.id !== curPageId),
    });
  };

  return (
    <div className={style.container}>
      <AddFormComponentPopup
        isOpened={addComponentPopupOpen}
        setIsOpened={setAddComponentPopupOpen}
        instantiateComponent={instantiateComponent}
        templates={templates}
      />

      <EditFormComponentPopup
        closePopup={() => setEditComponentIndex(null)}
        editComponent={form.formFields[editComponentIndex]}
        onComponentSave={onComponentSave}
        index={editComponentIndex}
        templates={templates}
        reloadTemplates={reloadTemplates}
        formFields={form.formFields}
        existingNames={form.formFields
          .filter((ff, i) => i !== editComponentIndex && ff.name != null && ff.name.length > 0)
          .map(ff => ff.name)}
        pages={getPages()}
        activeKita={activeKita}
      />
      {moveComponentPopup.open && (
        <MoveFormComponentPopup
          isOpened={moveComponentPopup.open}
          closeMoveComponentPopup={closeMoveComponentPopup}
          moveComponentPopup={moveComponentPopup}
          moveComponent={moveComponent}
        />
      )}

      <h1>{t('Administration.FormsEditorTitle')}</h1>
      <div className={style.buttonsTopRight}>
        <Button onClick={cancelEdit}>
          <i className='fa fa-arrow-left' /> {t('Administration.FormsEditorBackButton')}
        </Button>
        <ButtonWithLoader onClick={() => save(false)} isLoading={isSaving}>
          <i className='fa fa-floppy-o' /> {t('Administration.FormsEditorSaveButton')}
        </ButtonWithLoader>
        <ButtonWithLoader onClick={() => save(true)} isLoading={isSavingAndClosing}>
          <i className='fa fa-floppy-o' /> {t('Administration.FormsEditorSaveAndCloseButton')}
        </ButtonWithLoader>
      </div>
      <div className={style.formDataContainer}>
        <Label type='input'>{t('Administration.FormsEditorNameInputLabel')}</Label>
        <Input
          value={form.name}
          onChange={e =>
            setForm({
              ...form,
              name: e.target.value,
            })
          }
          className={style.formNameInput}
          placeholder={t('Administration.FormsEditorNameInputPlaceholder')}
        />
        {errors && errors.length > 0 && (
          <ul>
            {errors.map(e => {
              return <li className={style.errorMsg}>{e}</li>;
            })}
          </ul>
        )}
      </div>
      {tabs && tabs.length > currentTab && (
        <div className={style.formDataContainer}>
          {tabs && tabs.length > 1 && (
            <div className={style.buttonsTopRight}>
              <Button onClick={deleteCurFormPage}>
                <i className='fa fa-trash' /> {t('Administration.FormsEditorDeletePage')}
              </Button>
            </div>
          )}

          <Label type='input'>{t('Administration.FormsEditorPageNameInputLabel')}</Label>
          <Input
            value={tabs[currentTab].page.name}
            onChange={e => handlePageNameUpdate(e.target.value)}
            className={style.formNameInput}
            placeholder={t('Administration.FormsEditorPageNameInputPlaceholder')}
          />
          <Label type='input'>{t('Administration.FormsEditorPageNumberInputLabel')}</Label>
          <Input
            value={curPageNumberValue}
            type='number'
            min='1'
            onChange={e => handlePageNumberUpdate(e.target.value)}
            className={style.formNameInput}
            placeholder={t('Administration.FormsEditorPageNumberInputPlaceholder')}
          />
        </div>
      )}
      <div className={style.wrapper}>
        <div className={style.buttonsTopRight}>
          <Button onClick={addNewFormPage}>
            <i className='fa fa-plus' /> {t('Administration.FormsEditorAddPage')}
          </Button>
        </div>
        {tabs.length > currentTab && (
          <Tabs
            tabs={tabs}
            currentTab={tabs[currentTab].path}
            onChange={(_, index) => setCurrentTab(index)}
          />
        )}
      </div>

      <div className={style.bottomButtonsWrapper}>
        <ButtonWithLoader
          onClick={() => save(false)}
          isLoading={isSaving}
          className={style.bottomButton}
        >
          <i className='fa fa-floppy-o' /> {t('Administration.FormsEditorSaveButton')}
        </ButtonWithLoader>
        <ButtonWithLoader
          onClick={() => save(true)}
          isLoading={isSavingAndClosing}
          className={style.bottomButton}
        >
          <i className='fa fa-floppy-o' /> {t('Administration.FormsEditorSaveAndCloseButton')}
        </ButtonWithLoader>
      </div>
    </div>
  );
}
