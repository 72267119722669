import React from 'react';
import { useTranslation } from 'react-i18next';

import { isImage } from 'library/utilities/files';
import EditorWithButton from 'library/common/commonComponents/Inputs/EditorWithButton';
import MessageWithFiles from 'library/common/commonComponents/MessageWithFiles';

export default function FeedItemCommon({
  isEditing,
  setIsEditing,
  editorProps,
  files,
  text,
  id,
  groupId,
  postType,
  isTranslationAllowed,
  isSigned,
  publicationTime,
  setPublicationTime,
  user,
}) {
  let allImages;
  if (typeof files === 'undefined' || files === null) {
    allImages = null;
  } else {
    allImages = files.filter(file => isImage(file.fileId));
  }

  const { t } = useTranslation();
  return isEditing ? (
    <EditorWithButton
      btnText={t('Post.Save')}
      closeBtnText={t('Confirm.Close')}
      {...editorProps}
      messgeFiles={files}
      publicationTime={publicationTime}
      setPublicationTime={setPublicationTime}
      onCloseEditor={() => setIsEditing(false)}
      user={user}
    />
  ) : (
    <MessageWithFiles
      files={files}
      allImages={allImages}
      text={text}
      entityId={id}
      entityType={postType}
      isTranslationAllowed={isTranslationAllowed}
      groupId={groupId}
      isSigned={isSigned}
    />
  );
}
