import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../../../../../library/common/commonComponents/Popups/Popup';
import Button from '../../../../../../../../library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';

import styles from './MoveFormComponentPopup.module.scss';

export default function MoveFormComponentPopup({ isOpened, closeMoveComponentPopup, moveComponentPopup, moveComponent }) {

  const { t } = useTranslation();

  const [positionToMoveTo, setPositionToMoveTo] = useState(null);

  useState(() => {
    setPositionToMoveTo(moveComponentPopup.position);
  }, [moveComponentPopup])

  const checkForValidNumber = (number, min, max) => {

    const parsedNumber = parseInt(number);
    const parsedMin = parseInt(min);
    const parsedMax = parseInt(max);
    if (!isNaN(parsedNumber)) {
      if (parsedNumber >= parsedMin && number <= parsedMax) {
        return true;
      }
    }
    else {
      if (number === '') {
        return true;
      }
    }
    return false;
  }

  return (
    <Popup
      size='normal'
      isOpened={isOpened}
      closePopup={() => { closeMoveComponentPopup() }}
      header={t('Administration.FormsEditorMoveComponentPopupHeader')}
      body={<div>
        <p>{t('Administration.FormsEditorPleaseSelectPositionOfComponent')}</p>
        <p>
          <Input
            type='number'
            min={1}
            max={moveComponentPopup.componentCount}
            value={positionToMoveTo}
            onChange={e => {
              if (checkForValidNumber(e.target.value, 1, moveComponentPopup.componentCount)) {
                setPositionToMoveTo(e.target.value);
              }
            }}
          />
        </p>
      </div>
      }
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={() => {
            closeMoveComponentPopup()
          }}>{t('Popup.Close')}</Button>

          <Button
            type='primary'
            onClick={() => {
              moveComponent(moveComponentPopup.position, moveComponentPopup.page, positionToMoveTo - moveComponentPopup.position);
              closeMoveComponentPopup()
            }}
            disabled={positionToMoveTo === '' || positionToMoveTo === null}
          >
            {t('Confirm.Confirm')}
          </Button>
        </div>
      }
    >
    </Popup>
  );
}
