import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import styles from './autoGrowingTextArea.module.scss';

const AutoGrowingTextarea = ({ value, onChange, placeholder, error, disabled }) => {
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
  };

  useEffect(resizeTextArea, [value]);

  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.autoGrowingTextareaContainer}>
      <textarea
        className={cn(
          styles.textarea,
          error && styles.input_border_error,
          !error && styles.input_border,
        )}
        ref={textAreaRef}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        rows={1}
      />
    </div>
  );
};

export default AutoGrowingTextarea;
