import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import Loader from 'library/common/commonComponents/Loader';
import { getGroupsByName } from 'library/api/groups';
import GroupAvatar from 'library/common/commonComponents/GroupAvatar';

import styles from '../tagInput.module.scss';

GroupsTagInput.defaultProps = {
  showLabel: true,
  target: 'groups',
};

export default function GroupsTagInput({
  target,
  setErrors,
  onDataChange,
  value,
  groupId,
  errors,
  showLabel,
  isFullWidth,
  isSmall,
  isOnGroupCreation,
  isGroupMembersOnly,
  userId,
}) {
  const { t } = useTranslation();

  const [foundResults, setFoundResults] = useState([]);
  const [searchingRequest, setSearchingRequest] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [activeUser, setActiveUser] = useState(null);

  const inputField = useRef(null);

  const clearInput = () => {
    setSearchingRequest('');
    setFoundResults([]);
    setActiveUser(null);
  };

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: clearInput,
  });
  const handleGroupInput = newValue =>
    handleUserInputHandler({
      target,
      newValue,
      setErrors,
      onDataChange,
      setSearchingRequest,
      setIsLoading,
      value,
      groupId,
      setFoundResults,
      isOnGroupCreation,
      isGroupMembersOnly,
      userId,
    });

  const addGroup = group => addGroupHandler({ group, value, onDataChange, clearInput });

  const onInputFocused = () => onInputFocusedHandler(setIsInputFocused);
  const onInputBlured = () => onInputBluredHandler(setIsInputFocused);


  return (
    <div className={cn(styles.search, { [styles.isSmall]: isSmall })}>
      {showLabel && (
        <div htmlFor='searchUser' className={styles.searchLabel}>
          {t('CreateGroup.Invites')}
        </div>
      )}
      <div className={styles.inputTagsWrapper} ref={outsideEventContainerRef}>
        <div
          className={cn(
            'notranslate',
            styles.inputTags,
            isInputFocused && styles.inputTags_focused,
            errors.invite !== '' && styles.inputTags_error,
          )}
          onClick={() => inputField.current.focus()}
        >
          <input
            type='text'
            className={cn(styles.inputTags__input, isFullWidth && styles.inputFullWidth)}
            ref={inputField}
            onFocus={onInputFocused}
            onBlur={onInputBlured}
            value={searchingRequest}
            onChange={e => handleGroupInput(e.target.value)}
            placeholder={t('CreateGroup.Add more')}
          />
        </div>
        {errors.invite !== '' && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={errors.invite} />
          </div>
        )}
        {searchingRequest && (
          <div className={styles.searchingResults}>
            {isLoading ? (
              <div className={styles.searchingResultsLoader}>
                <Loader />
              </div>
            ) : (
              <div className={styles.searchingResultsList}>
                {foundResults.length ? (
                  foundResults.map((item, index) => (
                    <div
                      key={item.id}
                      className={cn(
                        'notranslate',
                        {
                          [styles.searchingResultsListItemHidden]:
                            value.users.findIndex(user => `${item.id}` === `${user.id}`) !== -1 ||
                            (item.groupRegistrationStatus &&
                              target === 'users' &&
                              groupId &&
                              !isGroupMembersOnly),
                        },
                        styles.searchingResultsListItem,
                        activeUser === index && styles.searchingResultsListItemActive,
                      )}
                      onClick={() => {
                        addGroup(item);
                        inputField.current.focus();
                      }}
                      onMouseEnter={() => setActiveUser(index)}
                      onMouseLeave={() => setActiveUser(null)}
                    >
                      <div className={styles.searchingResultsListItemPhoto}>
                        <GroupAvatar group={item} />
                      </div>
                      <div
                        className='notranslate'
                        dangerouslySetInnerHTML={{
                          __html: highlightString(getResultName(item), searchingRequest),
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div className={styles.searchingResultsListEmpty}>
                    {searchingRequest.length > 2
                      ? t('CreateGroup.No groups found for the given query')
                      : t('CreateGroup.Please enter at least 3 character')}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function getResultName(item) {
  return item.groupName;
}

export function handleUserInputHandler({
    newValue,
    setErrors,
    setSearchingRequest,
    setIsLoading,
    groupId,
    setFoundResults,
    isGroupMembersOnly,
    value,
    userId,
}) {
  if (setErrors) {
    setErrors({ invite: '' });
  }
  setSearchingRequest(newValue);
  if (newValue.length > 2) {
    setIsLoading(true);
    getGroupsByName(newValue).then(data => {
        
        setFoundResults(data);
        setIsLoading(false);
    });
    setFoundResults([]);
  }
}

export function addGroupHandler({ group, value, onDataChange, clearInput }) {
  if (value.groups.findIndex(item => `${group.id}` === `${item.id}`) !== -1) {
    return null;
  }
  onDataChange({ ...value, ...{ groups: value.groups.concat(group) } });
  clearInput();
}

export function highlightString(str, subStr) {
  const htmlTemplate = [`<span style="background: #fff8e0">`, `</span>`];
  const indexStart = str.toUpperCase().indexOf(subStr.toUpperCase());
  if (indexStart === -1) {
    return str;
  }
  const indexEnd = indexStart + subStr.length + htmlTemplate[0].length;
  const result = str.slice(0, indexStart) + htmlTemplate[0] + str.slice(indexStart);
  return result.slice(0, indexEnd) + htmlTemplate[1] + result.slice(indexEnd);
}

export function onInputFocusedHandler(setIsInputFocused) {
  setIsInputFocused(true);
}

export function onInputBluredHandler(setIsInputFocused) {
  setIsInputFocused(false);
}
