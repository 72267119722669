
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Button from "../../../../../../../../library/common/commonComponents/Buttons/Button";

import ButtonWithLoader
  from "../../../../../../../../library/common/commonComponents/Buttons/ButtonWithLoader";

import Select from "../../../../../../../../library/common/commonComponents/Inputs/Select";
import Label from "../../../../../../../../library/common/commonComponents/Label";

import Popup from "../../../../../../../../library/common/commonComponents/Popups/Popup";

const SettingsPopup = ({ isOpened, closePopup, onCancelClick, onSaveClick, forms, setDefaultContractForm, defaultContractForm, isSavingSettings, allowParentsFillOut, setAllowParentsFillOut }) => {
  const { t } = useTranslation();
  return (
    <Popup
      size='normal'
      isOpened={isOpened}
      closePopup={closePopup}
      footer={
        <div>
          <Button
            onClick={onCancelClick}
          >
            {t('Popup.Cancel')}
          </Button>
          <ButtonWithLoader
            onClick={onSaveClick}
            type='primary'
            isLoading={isSavingSettings}
          >
            {t('Popup.Save')}
          </ButtonWithLoader>
        </div>
      }
    >
      <div>
        <h2>{t('Administration.FormsSettings')}</h2>
        <Label type="input">{t('Administration.FormsSettingsDefaultContractForm')}</Label>
        <Select
          options={[{
            value: -1,
            label: t('Administration.FormsSettingsDefaultContractFormNone'),
          }, ...forms.map(f => {
            return {
              value: f.id,
              label: f.name,
            }
          })]}
          onSelect={e => {
            if (e.value === -1) {
              setDefaultContractForm(null);
            }
            else {
              setDefaultContractForm(e);
            }
          }}
          selected={defaultContractForm == null ? {
            value: -1,
            label: t('Administration.FormsSettingsDefaultContractFormNone'),
          } : defaultContractForm}
        />
        <Checkbox
          name={t('Administration.FormsSettingsAllowParentsFillOut')}
          isChecked={allowParentsFillOut}
          onChange={() => { setAllowParentsFillOut(!allowParentsFillOut) }}
        />
      </div>
    </Popup>
  )
}

export default SettingsPopup