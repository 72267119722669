import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Tooltip from 'library/common/commonComponents/Tooltip';
import UsersListPopup from 'library/common/commonComponents/Popups/UsersListPopup';

import styles from './likeButtonCounter.module.scss';

export default function LikeButtonCounter({ likes, user, withLikesModal, group }) {
  const [isOpened, setIsOpened] = useState(false);

  const { t } = useTranslation();
  const youLikeText = t('Post.You like this');

  const likesCountIsVisible = likes.length > 0;
  function isGlobalPublicGroup() {
    if (group && group.globalPublicGroup && group.globalPublicGroup === true) {
      return true;
    }
    return false;
  }

  if (!likesCountIsVisible) {
    return null;
  }

  const maximumCountBeforeOthersString = 2;

  const userName = `${user.firstName} ${user.lastName}`;

  let tooltipText = null;
  if (likes.length <= maximumCountBeforeOthersString) {
    tooltipText = likes.map(like => (
      <React.Fragment key={like.id}>
        {getUserName(like, userName, youLikeText)} <br />
      </React.Fragment>
    ));
  } else {
    tooltipText = (
      <>
        {getUserName(likes[likes.length - 1], userName, youLikeText)}
        <br />
        and others
      </>
    );
  }

  return (
    <>
      {isOpened && (
        <UsersListPopup
          isOpened={isOpened}
          title={<Trans i18nKey='Post.Users who like this' />}
          closePopup={() => setIsOpened(false)}
          users={likes}
        />
      )}
      <Tooltip
        text={<span className='notranslate'>{tooltipText}</span>}
        isVisibleCondition={withLikesModal && likesCountIsVisible && !isGlobalPublicGroup()}
      >
        {likesCountIsVisible && (
          <div
            className={styles.likeTooltip}
            onClick={() => (withLikesModal && !isGlobalPublicGroup() ? setIsOpened(true) : {})}
          >
            ({likes.length})
          </div>
        )}
      </Tooltip>
    </>
  );
}

LikeButtonCounter.defaultProps = {
  likes: [],
};

const getUserName = ({ firstName, lastName, name: fullName }, userName, youLikeText) => {
  const name = fullName || `${firstName} ${lastName}`;
  return name === userName ? youLikeText : name;
};
