import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import { uploadFoodFile } from 'library/api/foodOrder';
import FoodOrderExcelInfoPopup from 'library/common/commonComponents/Popups/FoodOrderExcelInfoPopup';
import styles from './foodOrderMealPlan.module.scss';
import Button from '../../../../Buttons/Button';
import MealItem from './MealItem';
import {
  getMenu,
  getSettings,
  saveMenu,
  getIngredients,
  saveIngredients,
} from '../../../../../../api/foodOrder';
import {
  addDays,
  formatDate,
  getDateFromFormatedDate,
  getDateFromGermanDateString,
  getNextMonthStartAndEnd,
  getWeekDayIndexForGermanDateString,
  getWeekDayPerIndex,
  getWeekDayPerIndexSun0,
  isChangeFoodMenuAllowed,
} from '../../../../../../utilities/foodOrder';
import IngredientAddingPopup from 'library/common/commonComponents/Popups/IngredientAddingPopup';

export default function FoodOrderMealPlan({
  groupId,
  from,
  to,
  showBottomNotification,
  setIsWeekSelectionLoading,
}) {
  const { t } = useTranslation();

  const [defaultPrice, setDefaultPrice] = useState(0);
  const [infoPopupOpened, setInfoPopupOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [config, setConfig] = useState(null);
  const [mealChanges, setMealChanges] = useState({});
  const [ingredientPopupOpened, setIngredientPopupOpened] = useState(false);
  const [ingredientPopupIsSaving, setIngredientPopupIsSaving] = useState(false);

  const { nextMonthBeginning, nextMonthEnd } = getNextMonthStartAndEnd();

  const displayDateStrings = [];
  for (let d = new Date(from.getTime()); d.getTime() <= to.getTime(); d.setDate(d.getDate() + 1)) {
    displayDateStrings.push(formatDate(d));
  }

  const [ingredients, setIngredients] = useState([]);

  const initialMeal = {
    id: Math.random(),
    day: '',
    date: '',
    foods: [],
    isActive: false,
  };

  const initialFood = {
    id: Math.random(),
    name: '',
    description: '',
    price: defaultPrice,
  };

  const onIngredientSave = newRows => {
    setIngredientPopupIsSaving(true);
    //copy  referenceName and name fields of newRows items to formattedNewRows
    const formattedNewRows = newRows.map(({ referenceName, name }) => ({ referenceName, name }));
    saveIngredients(groupId, formattedNewRows)
      .then(() => {
        showBottomNotification(t('BottomNotifications.Success'));
        setIngredients(newRows);
      })
      .catch(error => {
        // Handle error
        console.error('Error saving ingredients:', error);
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIngredientPopupOpened(false);
        setIngredientPopupIsSaving(false);
      });
  };

  const loadIngredients = async () => {
    try {
      const response = await getIngredients(groupId);
      const loadedIngredients = response.data.map(({ referenceName, name }) => ({
        id: Math.random(),
        referenceName,
        name,
      }));
      setIngredients(loadedIngredients);
    } catch (error) {
      // Handle error
      console.error('Error loading ingredients:', error);
    }
  };

  useEffect(() => {
    loadIngredients();
  }, []);

  const handleOpenIngredientPopup = () => {
    setIngredientPopupOpened(true);
  };
  const handleCloseIngredientPopup = () => setIngredientPopupOpened(false);

  const registerMealChangeHelper = (newMealChanges, mealId, active) => {
    if (mealId in newMealChanges) {
      if (newMealChanges[mealId] !== active) {
        delete newMealChanges[mealId];
      }
    } else {
      newMealChanges[mealId] = active;
    }
  };

  const registerMealChange = (mealId, active) => {
    const newMealChanges = { ...mealChanges };
    registerMealChangeHelper(newMealChanges, mealId, active);
    setMealChanges(newMealChanges);
  };

  const registerMealChangesBulk = changes => {
    const newMealChanges = { ...mealChanges };
    changes.forEach(c => registerMealChangeHelper(newMealChanges, c.mealId, c.active));
    setMealChanges(newMealChanges);
  };

  const getInitalKWFoodItems = fromDate => {
    const initialFoodItems = [];

    if (config && !config.usePreorder) {
      for (let i = 0; i < 5; i++) {
        initialFoodItems.push({
          ...initialMeal,
          id: Math.random(),
          day: getWeekDayPerIndex(i, t),
          date: formatDate(addDays(fromDate, i)),
        });
      }
    } else {
      const start =
        fromDate.getTime() <= nextMonthBeginning.getTime() ? fromDate : nextMonthBeginning;
      const end = to.getTime() >= nextMonthEnd.getTime() ? to : nextMonthEnd;

      for (
        let d = new Date(start.getTime());
        d.getTime() <= end.getTime();
        d.setDate(d.getDate() + 1)
      ) {
        if (d.getDay() !== 0 && d.getDay() !== 6) {
          initialFoodItems.push({
            ...initialMeal,
            id: Math.random(),
            day: getWeekDayPerIndexSun0(d.getDay(), t),
            date: formatDate(d),
          });
        }
      }
    }

    return initialFoodItems;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [mealItems, dispatchMealItems] = useReducer(mealItemReducer, getInitalKWFoodItems(from));

  const submitMenu = withNotification => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    const menus = formatMealItems(mealItems);
    saveMenu(menus, groupId)
      .then(res => {
        if (withNotification) {
          showBottomNotification(t('FoodOrder.MealPlan.Save'));
          loadMenu();
          setMealChanges({});
        }
      })
      .catch(err => {
        showBottomNotification(t('FoodOrder.Notification.Error'), { isFail: true });
        setIsSaving(false);
      });
  };

  const formatMealItems = mealItems => {
    const data = [];
    for (let i = 0; i < mealItems.length; i++) {
      const meal = {
        id: mealItems[i].id >= 1 ? mealItems[i].id : 0,
        date: mealItems[i].date,
        foods: mealItems[i].foods.map(food => (food.id >= 1 ? food : { ...food, id: 0 })),
        isActive: mealItems[i].isActive,
      };
      data.push(meal);
    }
    return data;
  };

  useEffect(() => {
    getSettings(groupId).then(res => {
      setConfig(res.data);
    });
  }, []);

  const loadMenu = () => {
    setIsLoading(true);
    setIsWeekSelectionLoading(true);
    getMenu(
      groupId,
      (config && !config.usePreorder) || from.getTime() <= nextMonthBeginning.getTime()
        ? formatDate(from)
        : formatDate(nextMonthBeginning),
      (config && !config.usePreorder) || to.getTime() >= nextMonthEnd.getTime()
        ? formatDate(to)
        : formatDate(nextMonthEnd),
    )
      .then(res => {
        setDefaultPrice(res.data.defaultPrice);
        const { foodMenus } = res.data;
        const meals = foodMenus.map(meal => meal && { ...meal, isActive: !meal.inactive });
        const mealsWithoutSatAndSun = meals.filter(
          meal =>
            getWeekDayIndexForGermanDateString(meal.date) !== 0 &&
            getWeekDayIndexForGermanDateString(meal.date) !== 6,
        );
        dispatchMealItems({ type: 'insertMeals', meals: mealsWithoutSatAndSun });
        setIsLoading(false);
        setIsWeekSelectionLoading(false);
        setIsSaving(false);
      })
      .catch(err => {
        setIsLoading(false);
        setIsWeekSelectionLoading(false);
        setIsSaving(false);
      });
  };

  // Query defined meals on start and when calendarWeek changes
  useEffect(() => {
    dispatchMealItems({ type: 'setMeals', meals: getInitalKWFoodItems(from) });

    loadMenu();
  }, [from, config]);

  const selectAll = () => {
    dispatchMealItems({
      type: 'selectAll',
    });
  };

  const selectAllMonthly = () => {
    const changes = [];

    const newMealItems = mealItems.map(meal => {
      const monthYearString = `${nextMonthBeginning.getMonth() +
        1}.${nextMonthBeginning.getFullYear()}`;
      if (meal.date.endsWith(monthYearString) && isChangeFoodMenuAllowed(meal.date)) {
        changes.push({ mealId: meal.id, active: true });

        return {
          ...meal,
          isActive: true,
        };
      }

      return meal;
    });

    registerMealChangesBulk(changes);

    dispatchMealItems({ type: 'selectAllMonthly', newMealItems });
    showBottomNotification(t('FoodOrderKitchen.SelectAllMonthlyDone'));
  };

  const uploadFile = files => {
    const data = new FormData();
    data.append('file', files[0]);

    setIsLoading(true);

    uploadFoodFile(data, groupId)
      .then(() => {
        showBottomNotification(t('BottomNotifications.Success'));
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
        loadMenu();
      });
  };

  function mealItemReducer(state, action) {
    switch (action.type) {
      case 'addFood':
        return state.map(meal =>
          meal.id === action.mealId ? { ...meal, foods: [...meal.foods, action.food] } : meal,
        );
      case 'removeFood':
        return state.map(meal =>
          meal.id === action.mealId
            ? { ...meal, foods: meal.foods.filter(food => food.id !== action.foodId) }
            : meal,
        );
      case 'updateFood':
        return state.map(meal =>
          meal.id === action.mealId
            ? {
                ...meal,
                foods: meal.foods.map(food => (food.id === action.food.id ? action.food : food)),
              }
            : meal,
        );
      case 'setIsActive':
        return state.map(meal => {
          if (meal.id !== action.mealId) {
            return meal;
          }

          registerMealChange(meal.id, action.active);

          return { ...meal, isActive: action.active };
        });
      case 'selectAll':
        const newChanges = [];

        const res = state.map(meal => {
          const mealDate = getDateFromGermanDateString(meal.date).getTime();

          let fromTime = new Date(from.getTime());
          fromTime.setHours(0, 0, 0, 0);
          fromTime = fromTime.getTime();

          let toTime = new Date(to.getTime());
          toTime.setHours(0, 0, 0, 0);
          toTime = toTime.getTime();

          if (!isChangeFoodMenuAllowed(meal.date) || mealDate < fromTime || mealDate > toTime) {
            return meal;
          }

          newChanges.push({ mealId: meal.id, active: true });

          return { ...meal, isActive: true };
        });

        registerMealChangesBulk(newChanges);

        return res;
      case 'selectAllMonthly':
        return action.newMealItems;
      case 'setMeals':
        return [
          ...state,
          ...action.meals.filter(m => state.filter(m2 => m.date === m2.date).length === 0),
        ];
      case 'insertMeals':
        const result = [...state].map(m => {
          const newMeals = action.meals.filter(m2 => m2.date === m.date);
          const newMeal = newMeals.length > 0 ? newMeals[0] : null;

          return newMeal
            ? {
                ...newMeal,
                day: getDateFromFormatedDate(m.date, t),
                isActive: newMeal.id in mealChanges ? mealChanges[newMeal.id] : newMeal.isActive,
              }
            : m;
        });

        result.sort((a, b) => {
          const dateA = Date.parse(
            `${a.date
              .split('.')
              .reverse()
              .join('-')}T00:00:00`,
          );
          const dateB = Date.parse(
            `${b.date
              .split('.')
              .reverse()
              .join('-')}T00:00:00`,
          );

          return dateA - dateB;
        });

        return result;
      default:
        return state;
    }
  }

  return (
    !isLoading && (
      <>
        <div>
          <h2>{t('FoodOrder.Define menu')}</h2>
          <br />
          <div className={styles.buttonGroup}>
            <div>
              {config && config.usePreorder ? (
                <Button type='secondary' onClick={() => selectAllMonthly()}>
                  {t('FoodOrderKitchen.SelectAllMonth')} <i className='fa fa-check' />
                </Button>
              ) : (
                <Button type='secondary' onClick={() => selectAll()}>
                  {t('FoodOrder.Select all')} <i className='fa fa-check' />
                </Button>
              )}
            </div>
            <div>
              <UploadButton
                onChange={uploadFile}
                isMultiple={false}
                className={styles.uploadButton}
              >
                {t('FoodOrder.Upload food plan excel')}
              </UploadButton>
            </div>
            <div>
              <Button
                type='secondary'
                onClick={() => {
                  setInfoPopupOpened(true);
                }}
              >
                <i className='fa fa-info' />
              </Button>
            </div>
            <div>
              <Button type='secondary' onClick={handleOpenIngredientPopup}>
                {t('FoodOrder.Add Ingredients')}
              </Button>
            </div>
          </div>
          <br />
          <br />
          <div className={styles.foodContainer}>
            {mealItems.map(meal => {
              if (displayDateStrings.includes(meal.date)) {
                return (
                  <MealItem
                    key={meal.id}
                    id={meal.id}
                    day={meal.day}
                    date={meal.date}
                    foods={meal.foods}
                    groupId={meal.groupId}
                    isActive={meal.isActive}
                    initialFood={initialFood}
                    dispatchMealItems={dispatchMealItems}
                    showBottomNotification={showBottomNotification}
                  />
                );
              }

              return null;
            })}
          </div>
          <br />
          <ButtonWithLoader onClick={() => submitMenu(true)} type='primary' isLoading={isSaving}>
            {t('FoodOrder.Save')}
          </ButtonWithLoader>
          <br />
          <br />
          <FoodOrderExcelInfoPopup
            isOpened={infoPopupOpened}
            closePopup={() => setInfoPopupOpened(false)}
          />
          <IngredientAddingPopup
            isOpened={ingredientPopupOpened}
            closePopup={handleCloseIngredientPopup}
            onSave={onIngredientSave}
            initialRows={ingredients}
            isSaving={ingredientPopupIsSaving}
          />
        </div>
      </>
    )
  );
}
