import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateNoTimeHalfYear } from 'library/utilities/dateMoment';
import styles from './parentsInvoice.module.scss';
import Loader from '../../../../Loader';
import {
  formatDateToMMYYYY,
  formatPrice,
  generateParentInvoice,
  getCurrentMonthForInput,
  getDateFromFormatedDate,
} from '../../../../../../utilities/foodOrder';
import Button from '../../../../Buttons/Button';
import { parentsGetMonthlyBill } from '../../../../../../api/foodOrder';
import Input from '../../../../Inputs/Input';

export default function ParentsInvoice({ groupId, selectedChild, activeKita, groupInfo }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getCurrentMonthForInput());

  const getInitialDetailsVisible = orders => {
    return orders.map(order => false);
  };
  const [isDetailsVisible, setIsDetailsVisible] = useState(getInitialDetailsVisible([]));

  const toggleDetails = index => {
    const newDetailsVisible = [...isDetailsVisible];
    newDetailsVisible[index] = !newDetailsVisible[index];
    setIsDetailsVisible(newDetailsVisible);
  };

  const getOrderSum = () => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].price;
    }
    return sum;
  };
  const downloadPDF = () => {
    generateParentInvoice(t, data, activeKita.kitaName, selectedChild.label, groupInfo.groupName);
  };

  useEffect(() => {
    if (selectedChild) {
      setIsLoading(true);
      parentsGetMonthlyBill(groupId, selectedChild.id, formatDateToMMYYYY(selectedDate)).then(
        res => {
          setData(res.data);
          setIsLoading(false);
        },
      );
    }
  }, [selectedChild, selectedDate]);
  return (
    <div>
      <label>{t('FoodOrder.Select month')}</label>
      <Input
        className={styles.monthSelect}
        type='month'
        onChange={e => {
          setSelectedDate(e.target.value);
        }}
        value={selectedDate}
      />
      <br />
      <h2>{t('FoodOrder.Monthly invoices')}</h2>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <br />
          <Button onClick={() => downloadPDF()}>
            <i className='fa fa-file-pdf-o' /> {t('FoodOrder.export pdf')}
          </Button>
          <br />
          <br />
          <table className={styles.orderTable}>
            <thead>
              <tr>
                <th>{t('FoodOrder.Date')}</th>
                <th>{t('FoodOrder.Meal')}</th>
                <th>{t('FoodOrder.price')}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((element, index) => (
                <tr onClick={() => toggleDetails(index)}>
                  {window.innerWidth <= 700 ? (
                    isDetailsVisible[index] ? (
                      <td>
                        {formatDateNoTimeHalfYear(element.date)}
                        <br />
                        {getDateFromFormatedDate(element.date, t, true)}
                      </td>
                    ) : (
                      <td>{formatDateNoTimeHalfYear(element.date)} </td>
                    )
                  ) : (
                    <td>
                      {formatDateNoTimeHalfYear(element.date)}
                      <br />
                      {getDateFromFormatedDate(element.date, t, true)}
                    </td>
                  )}
                  <td style={{ hyphens: 'auto' }}>
                    {window.innerWidth <= 700
                      ? element.name
                        ? isDetailsVisible[index]
                          ? element.name
                          : element.name.length > 9
                          ? element.name.slice(0, 6) + '...'
                          : element.name
                        : t('FoodOrder.Meal')
                      : element.name || t('FoodOrder.Meal')}
                  </td>
                  <td>
                    {formatPrice(element.price)} €
                    {window.innerWidth <= 700 ? (
                      <i
                        style={{ paddingLeft: 'inherit' }}
                        className={
                          !isDetailsVisible[index] ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
                        }
                      />
                    ) : (
                      <p />
                    )}
                  </td>
                </tr>
              ))}
              <tr className={styles.bigtr}>
                <td>{t('FoodOrder.Sum')}</td>
                <td>{data.length}x</td>
                <td>{formatPrice(getOrderSum())} €</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
