import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from "dompurify";
import Button from "../../Button";
import {getCalendarEventById} from "../../../../../api/calendar";

export default function TemplateLine({ template, handleApplyTemplate, handleApplyCalendarTemplate, type }) {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [calendarEvent, setCalendarEvent] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleApplyCalendar = () => {
    handleApplyCalendarTemplate(template, calendarEvent);
  };

  useEffect(() => {
    if (!showDetails)
    {
      return;
    }

    getCalendarEventById(template.post.id).then(res => {
      setCalendarEvent(res.data);
      setStartDate(new Date(res.data.start));
      setEndDate(new Date(res.data.end));
    });
  }, [showDetails]);

  return (
    <div style={{ marginBottom: '10px' }}>
      <div
        style={{
          backgroundColor: 'grey',
          padding: '5px 10px',
          color: 'white',
          fontWeight: 'bold',
          border: '1px solid grey',
          borderRadius: '5px'
        }}
        onClick={() => {
          setShowDetails(!showDetails);
        }}
      >
        <p style={{ display: 'inline' }}>{template.name}</p>
        <i style={{ marginLeft: '10px' }} className={showDetails ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
      </div>
      {showDetails && (
        <div>
          {type === "calendar" ? (
            <div>
              <p style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>
                  {t('PostLoadTemplatePopup.CalendarTitle')}
                </span> <span>{calendarEvent.title}</span>
              </p>
              <p style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>{t('PostLoadTemplatePopup.CalendarDescription')}</span> <span
                  dangerouslySetInnerHTML={{ __html: sanitize(calendarEvent.description) }} />
              </p>
              <p style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>{t('PostLoadTemplatePopup.CalendarAllDay')}</span> <span>
                  {
                    calendarEvent.allDay
                      ? t('PostLoadTemplatePopup.CalendarAllDayYes')
                      : t('PostLoadTemplatePopup.CalendarAllDayNo')
                  }
                </span>
              </p>
              {startDate && (
                <p style={{ marginBottom: '10px' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('PostLoadTemplatePopup.CalendarFrom')}</span> <span>
                    {
                      `${
                        startDate.getDate().toString(10).padStart(2, "0")
                      }.${
                        (startDate.getMonth() + 1).toString(10).padStart(2, "0")
                      }.${
                        startDate.getFullYear()
                      } 
                      ${calendarEvent.allDay 
                        ? '' 
                        : `${startDate.getHours().toString(10).padStart(2, "0")}:${
                        startDate.getMinutes().toString(10).padStart(2, "0")}`
                      }`
                    }
                  </span>
                </p>
              )}
              {endDate && (
                <p style={{ marginBottom: '10px' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('PostLoadTemplatePopup.CalendarTo')}</span> <span>
                    {
                      `${
                        endDate.getDate().toString(10).padStart(2, "0")
                      }.${
                        (endDate.getMonth() + 1).toString(10).padStart(2, "0")
                      }.${
                        endDate.getFullYear()
                      } 
                      ${calendarEvent.allDay
                        ? ''
                        : `${endDate.getHours().toString(10).padStart(2, "0")}:${
                          endDate.getMinutes().toString(10).padStart(2, "0")}`
                      }`
                    }
                  </span>
                </p>
              )}
            </div>
          ) : (
            <div>
              <p
                style={{ marginBottom: '10px' }}
              >
                <span style={{ fontWeight: 'bold' }}>{t('PostLoadTemplatePopup.PostTextLabel')}</span> <span
                  dangerouslySetInnerHTML={{ __html: sanitize(template.post.text) }} />
              </p>
            </div>
          )}

          <Button onClick={() => {
            if (type === 'calendar')
            {
              handleApplyCalendar();
            }
            else
            {
              handleApplyTemplate();
            }
          }}>
            {t('PostLoadTemplatePopup.Apply')}
          </Button>
        </div>
      )}
    </div>
  );
}
