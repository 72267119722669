import React from 'react';
import cn from 'classnames';

import ListItem from 'library/common/commonComponents/ListItems/ListItem';

import styles from './dropdown.module.scss';

export default function Dropdown({
  isOpened,
  dropdownOptions,
  onClick,
  theme,
  dropdownRef,
  kitaColor,
}) {
  if (!kitaColor) {
    kitaColor = '#afcb05';
  }
  return (
    isOpened && (
      <div
        className={cn(styles.dropdown, getClassByTheme(theme))}
        style={{ backgroundColor: kitaColor.kitaColor }}
        onClick={onClick}
        ref={dropdownRef}
      >
        {dropdownOptions.map((option, index) =>
          option.isSeparator ? (
            // eslint-disable-next-line
            <DropdownSeparator key={index} />
          ) : option.isHidden ? null : (
            <ListItem
              theme={theme}
              {...option}
              // eslint-disable-next-line
              key={option.title + index}
              className={styles.listItem}
            />
          ),
        )}
      </div>
    )
  );
}

export const DropdownSeparator = () => (
  <div className={styles.separator} style={{ backgroundColor: '#FFFFFF', borderLeft: '#FFFFFF' }} />
);

export function getClassByTheme(theme) {
  if (
    window.location.hostname.toLowerCase().includes('bvf4u.safe2connect.org') &&
    theme === 'yellow'
  ) {
    return styles.themeBlue;
  }

  if (
    window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org') &&
    theme === 'yellow'
  ) {
    return styles.themeBrown;
  }

  switch (theme) {
    case 'light':
      return styles.themeLight;
    case 'yellow':
      return styles.themeYellow;
    case 'dark':
      return styles.themeDark;
    default:
      return styles.themeDark;
  }
}
