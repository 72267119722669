import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';

import * as filesApi from 'library/api/files';

import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import cn from 'classnames';
import { getGroupMembers } from 'library/common/commonActions/groupActions';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import MultiUserSignaturePopupHeader from './MultiUserSignaturePopupHeader';
import styles from './MultiUserSignaturePopup.module.scss';
import { findSignatureDataForUsers } from '../../../../api/signature';
import GenericTwoButtonPopup from '../GenericTwoButtonPopup';

export default function MultiUserSignaturePopup({
  isOpened,
  closePopup,
  title,
  group,
  files,
  currentUser,
}) {
  const { t } = useTranslation();

  const [headerSelectboxState, setHeaderSelectboxState] = useState(0);
  const [initialUsers, setInitialUsers] = useState([]);
  const [initialUsersLoaded, setInitialUsersLoaded] = useState(false);
  const [searchBoxContent, setSearchBoxContent] = useState('');
  const [filterdUsers, setFilterdUsers] = useState([]);
  const [selectBoxUsers, setSelectBoxUsers] = useState([]);
  const [clickedUser, setClickedUser] = useState(null);
  const [signatureUserCount, setSignatureUserCount] = useState(0);
  const [noneSignatureUserCount, setNoneSignatureUserCount] = useState(0);

  useEffect(() => {
    loadInitialUsers();
  }, []);

  useEffect(() => {
    if (initialUsersLoaded === true) {
      setFilterdUsers(loadUsersByTextSearchState(selectBoxUsers));
    }
  }, [searchBoxContent]);

  useEffect(() => {
    loadUsersBySelectboxState();
  }, [headerSelectboxState]);

  function loadUsersBySelectboxState() {
    if (initialUsersLoaded === true) {
      if (headerSelectboxState === 0) {
        setFilterdUsers(loadUsersByTextSearchState(initialUsers));
        setSelectBoxUsers(initialUsers);
      } else if (headerSelectboxState === 1) {
        const signed = initialUsers.filter(u => u.hasSignature === true);
        setFilterdUsers(loadUsersByTextSearchState(signed));
        setSelectBoxUsers(signed);
      } else if (headerSelectboxState === 2) {
        const notSigned = initialUsers.filter(u => u.hasSignature === false);
        setFilterdUsers(loadUsersByTextSearchState(notSigned));
        setSelectBoxUsers(notSigned);
      }
    }
  }

  function loadUsersByTextSearchState(users) {
    const textSearchFilter = users.filter(function(n) {
      return (
        `${n.firstName} ${n.lastName}`.toLowerCase().includes(searchBoxContent.toLowerCase()) ||
        `${n.lastName} ${n.firstName}`
          .toLowerCase()
          .includes(searchBoxContent.toLowerCase() || searchBoxContent === '')
      );
    });
    return textSearchFilter;
  }

  async function loadInitialUsers() {
    const filters = [{ filterName: 'search' }];
    const groupMembersLoaded = async () => {
      const groupMembers = await getGroupMembers(group, filters);
      return groupMembers;
    };
    groupMembersLoaded().then(async function(allGroupMembers) {
      let allGroupUsers = allGroupMembers.data.content
        .map(gm => gm.user)
        .filter(u => u.id !== currentUser.id);
      if (!files || files.length !== 1) {
        return;
      }
      const fileId = files[0].id;

      const signatureDataLoaded = async () => {
        const signatureData = await findSignatureDataForUsers(fileId, allGroupUsers.map(u => u.id));
        return signatureData.data;
      };
      signatureDataLoaded().then(signatureData => {
        let tempSignatureUserCount = 0;
        allGroupUsers = allGroupUsers.map(u => {
          u.hasSignature = u.id in signatureData;

          if (u.hasSignature) {
            tempSignatureUserCount+=1;
            const date = new Date(signatureData[u.id].created_at);
            u.signatureDate =
              date.toLocaleDateString('de-DE') + ' ' + date.toLocaleTimeString('de-DE');

            u.signedFile = signatureData[u.id].publicFileId;
          }
          
          return u;
        });

        setSignatureUserCount(tempSignatureUserCount);
        setNoneSignatureUserCount(allGroupUsers.length - tempSignatureUserCount);
        setInitialUsers(allGroupUsers);
        setFilterdUsers(allGroupUsers);
        setSelectBoxUsers(allGroupUsers);
        setInitialUsersLoaded(true);
      });
    });
  }

  async function openReadMode() {
    if (clickedUser.signedFile !== -1) {
      filesApi
        .downloadFile(clickedUser.signedFile)
        .then(data => {
          const fileURL = window.URL.createObjectURL(data.data);
          window.open(fileURL);
        })
        .catch(() =>
          store.dispatch(
            showBottomNotification(t('MultiUserSignature.The file could not be opened'), {
              isFail: true,
            }),
          ),
        );
    } else {
      store.dispatch(
        showBottomNotification(t('MultiUserSignature.The file was deleted'), { isFail: true }),
      );
    }

    setClickedUser(null);
  }

  function openEditMode() {
    if (clickedUser.signedFile !== -1) {
      // eslint-disable-next-line max-len
      window.location = `/signature/${
        clickedUser.signedFile
      }/${group}?referrer=${encodeURIComponent(window.location.href)}&overwrite=${
        files[0].id
      }&user=${clickedUser.id}`;
    } else {
      store.dispatch(
        showBottomNotification(t('MultiUserSignature.The file was deleted'), { isFail: true }),
      );
    }

    setClickedUser(null);
  }

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      header={title}
      footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
    >
      {clickedUser != null && (
        <GenericTwoButtonPopup
          isOpened={clickedUser != null}
          headerText='foo'
          closePopup={() => setClickedUser(null)}
          button1Text={t('MultiUserSignature.Read mode')}
          button1Action={() => openReadMode()}
          button2Text={t('MultiUserSignature.Add own signature')}
          button2Action={() => openEditMode()}
          text={t('MultiUserSignature.How do you want to open the signed file?')}
        />
      )}

      {initialUsersLoaded ? (
        <div>
          <MultiUserSignaturePopupHeader
            headerSelectboxState={headerSelectboxState}
            setHeaderSelectboxState={setHeaderSelectboxState}
            searchBoxContent={searchBoxContent}
            setSearchBoxContent={setSearchBoxContent}
            signatureUserCount={signatureUserCount}
            noneSignatureUserCount={noneSignatureUserCount}
          />

          <div className={styles.container}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={568}>
              {filterdUsers.map(user => {
                const userId = user.userId || user.id;
                let signatureStatus = t('Post.User has not signed');
                if (user.hasSignature) {
                  signatureStatus = t('Post.User has signed') + ': ' + user.signatureDate;
                }

                return (
                  <div className={styles.item}>
                    <div className={styles.itemAvatar}>
                      <ProfileAvatar image={user} id={userId} withoutLink />
                    </div>

                    {/* eslint-disable-next-line max-len */}
                    <div
                      onClick={() => (user.hasSignature ? setClickedUser(user) : {})}
                      key={user.id}
                      className={styles.itemInfo}
                    >
                      <div className={cn(styles.itemName, 'notranslate')}>
                        {`${user.firstName} ${user.lastName}`}
                      </div>
                      {<div className={styles.itemStatus}>{signatureStatus}</div>}
                    </div>
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Popup>
  );
}

MultiUserSignaturePopup.defaultProps = {
  users: [],
};
