import React, { useState, useRef, useEffect } from 'react';
import { slideUp, slideDown, slideStop } from 'slide-anim';
import { useTranslation } from 'react-i18next';

import DatePicker from 'library/common/commonComponents/DatePicker';
import Storage from 'library/utilities/storage';
import Label from 'library/common/commonComponents/Label';
import TagInput from 'library/common/commonComponents/Inputs/TagInput';
import Button from 'library/common/commonComponents/Buttons/Button';
import FeedFilterCheckboxItem from 'library/common/commonComponents/FeedFilterCheckboxItem';

import styles from './galleryFilter.module.scss';

export const defaultUsers = { users: [] };

export default function GalleryFilter({
  updateFilters,
  groupId,
  galleryId,
  user,
  onFiltersLoad,
  isProfilePage,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const filterOptionsRef = useRef(null);

  const toggleOptions = () => toggleOptionsHandler({ isOpened, setIsOpened, filterOptionsRef });

  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [users, setUsers] = useState(defaultUsers);
  const [showDateFilters, setShowDateFilters] = useState(false);
  const [fromDate, setFromDate] = useState(getYesterday());
  const [toDate, setToDate] = useState(new Date());
  const [createdByMe, setCreatedByMe] = useState(false);
  const [errors, setErrors] = useState({ invite: '' });

  const [isResetButtonVisible, setIsResetButtonVisible] = useState(false);

  const resetFilters = () =>
    resetFiltersHandler({
      setShowDateFilters,
      setCreatedByMe,
      setUsers,
      setIsResetButtonVisible,
    });

  useEffect(() => {
    getFiltersHandler({
      setShowDateFilters,
      setCreatedByMe,
      setUsers,
      setToDate,
      setFromDate,
      userId: user.id,
      galleryId,
      onFiltersLoad,
    });
    // eslint-disable-next-line
  }, [user.id]);

  useEffect(() => {
    let newAppliedFiltersCount = 0;

    if (users.users.length > 0) newAppliedFiltersCount++;
    if (createdByMe) newAppliedFiltersCount++;
    if (showDateFilters) newAppliedFiltersCount++;

    setAppliedFiltersCount(newAppliedFiltersCount);

    const payload = {
      showDateFilters,
      fromDate,
      toDate,
      createdByMe,
      users,
    };

    saveFiltersToStorage(user.id, galleryId, payload);
    updateFilters(payload);

    if (newAppliedFiltersCount) {
      setIsResetButtonVisible(true);
    } else {
      setIsResetButtonVisible(false);
    }
    // eslint-disable-next-line
  }, [showDateFilters, fromDate, toDate, createdByMe, users]);

  const { t } = useTranslation();

  return (
    <div className={styles.filter}>
      <div className={styles.filterHead}>
        <div onClick={toggleOptions} className={styles.filterHeadTitleWrapper}>
          <i className='fa fa-filter' />
          <div className={styles.filterHeadTitle}>
            {t('Filters.Filter')}
            {appliedFiltersCount > 0 && (
              <span className={styles.filterHeadTitleCount}>({appliedFiltersCount})</span>
            )}
          </div>
          <i className='fa fa-caret-down' />
        </div>

        {isResetButtonVisible && (
          <div className={styles.filterHeadButtonWrapper}>
            <Button size='sm' type='info' onClick={resetFilters}>
              {t('Filters.Reset')}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.filterOptions} ref={filterOptionsRef}>
        <div className={styles.filterOptionsInner}>
          {!isProfilePage && (
            <div className={styles.filterColumn}>
              <div className={styles.filterBlock}>
                <div className={styles.filterBlockTitle}>
                  <strong>{t('Filters.Content')}</strong>
                </div>
                <div className={styles.filterList}>
                  <div className={styles.filterListItem}>
                    <FeedFilterCheckboxItem
                      isChecked={createdByMe}
                      onClick={() => setCreatedByMe(!createdByMe)}
                      text={t('Filters.Created by me')}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={styles.filterColumn}>
            <div className={styles.filterBlock}>
              <div className={styles.filterBlockTitle}>
                <strong>{t('Filters.Sorting')}</strong>
              </div>
              <div className={styles.filterList}>
                <div className={styles.filterListItem}>
                  <FeedFilterCheckboxItem
                    isChecked={showDateFilters}
                    onClick={() => setShowDateFilters(!showDateFilters)}
                    text={t('Filters.Date range')}
                  />

                  {showDateFilters && (
                    <div className={styles.filterListItemSubtabs}>
                      <div className={styles.detepickers}>
                        <Label type='input'>{t('Calendar.Start Date')}</Label>
                        <div>
                          <DatePicker
                            selected={fromDate}
                            onChange={date => {
                              if (date) setFromDate(date);
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            langCode={user.langCode}
                          />
                        </div>
                        <Label type='input'>{t('Calendar.End Date')}</Label>
                        <div>
                          <DatePicker
                            selected={toDate}
                            onChange={date => {
                              if (date) setToDate(date);
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            langCode={user.langCode}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!isProfilePage && (
            <div className={styles.filterColumn}>
              <div className={styles.filterBlock}>
                <div className={styles.filterBlockTitle}>
                  <strong>{t('Filters.Users')}</strong>
                </div>
                <TagInput
                  groupId={groupId}
                  value={users}
                  onDataChange={setUsers}
                  setErrors={setErrors}
                  errors={errors}
                  showLabel={false}
                  isFullWidth
                  isSmall
                  target='users'
                  isGroupMembersOnly
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function toggleOptionsHandler({ isOpened, setIsOpened, filterOptionsRef }) {
  const duration = 700;

  setIsOpened(!isOpened);
  slideStop(filterOptionsRef.current);
  if (isOpened) {
    slideUp(filterOptionsRef.current, {
      duration,
    });
  } else {
    slideDown(filterOptionsRef.current, {
      duration,
    });
  }
}

export function resetFiltersHandler({
  setShowDateFilters,
  setCreatedByMe,
  setUsers,
  setIsResetButtonVisible,
}) {
  setShowDateFilters(false);
  setCreatedByMe(false);
  setUsers(defaultUsers);
  setIsResetButtonVisible(false);
}

export function getYesterday() {
  const date = new Date();
  date.setDate(date.getDate() - 7);

  return date;
}

export function saveFiltersToStorage(userId, galleryId, value) {
  if (!userId) return;
  Storage.setItem(`galleryFilters/${userId}/${galleryId || 'default'}`, value);
}

export function getFiltersFromStorage(userId, galleryId) {
  return Storage.getItem(`galleryFilters/${userId}/${galleryId || 'default'}`);
}

export function getFiltersHandler({
  setShowDateFilters,
  setCreatedByMe,
  setUsers,
  setToDate,
  setFromDate,
  userId,
  galleryId,
  onFiltersLoad,
}) {
  if (!userId) return;
  const filters = getFiltersFromStorage(userId, galleryId);
  if (!filters) {
    onFiltersLoad();
    return;
  }
  setShowDateFilters(filters.showDateFilters);
  setCreatedByMe(filters.createdByMe);
  setUsers(filters.users);
  setToDate(new Date(filters.toDate));
  setFromDate(new Date(filters.fromDate));
  onFiltersLoad(filters);
}
