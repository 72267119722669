export function mapAddDayTypeToPrice(type) {
  if (type === 'wholeDay') {
    return 160;
  }
  if (type === 'morningWithFood') {
    return 115;
  }
  if (type === 'afternoonWithoutFood') {
    return 85;
  }
  if (type === 'morningWithoutFood') {
    return 85;
  }
  if (type === 'afternoonWithFood') {
    return 115;
  }
  if (type === 'wholeDayAlt') {
    return 140;
  }
  if (type === 'morningWithFoodAlt') {
    return 100;
  }
  if (type === 'afternoonWithoutFoodAlt') {
    return 75;
  }
  if (type === 'morningWithoutFoodAlt') {
    return 75;
  }
  if (type === 'afternoonWithFoodAlt') {
    return 100;
  }
  return 0;
}
