import React , {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../../Popups/Popup';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { formatDateObjectToTimeString } from 'library/utilities/dateMoment';
import styles from './CheckInOutTimePopup.module.scss';

const CheckInOutTimePopup = ({isSaving,timePopupOpened,handleCloseTimePopup,submit}) => {
  const { t } = useTranslation();
  const [timeInput, setTimeInput] = useState(formatDateObjectToTimeString(new Date()));
  const [timeError, setTimeError] = useState('');
  const [text, setText] = useState('');
  
  const resetTimePopup = () =>{
    setTimeInput(formatDateObjectToTimeString(new Date()));
    setTimeError('');
  }
  const handleClosePopup = () =>{
    resetTimePopup();
    handleCloseTimePopup();
  }
  const handleSubmit = () =>{
    // validate time format
    const formatValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(timeInput);
    if (!formatValid) {
      setTimeError(t('CheckinoutTimePopup.TimeEntryFormatError'));
      return;
    }
    const parts = timeInput.split(':');
    const newDate = new Date();
    newDate.setHours(parseInt(parts[0], 10));
    newDate.setMinutes(parseInt(parts[1], 10));
    newDate.setSeconds(parts.length > 2 ? parseInt(parts[2], 10) : 0);
    const milliseconds = newDate.getTime();
    resetTimePopup();
    setText('');
    submit(milliseconds,text);
  }
  return (
    <Popup 
      isOpened={timePopupOpened}
      closePopup={handleClosePopup}
      header={t('CheckinoutTimePopup.Header')}
      body={
        <div>
          <Input
            value={text}
            placeholder={t('Checkinout.Note')}
            onChange={e => setText(e.target.value)}
            onRenderFocus={true}
          />
          <br />
          <Input 
            value={timeInput}
            placeholder={t('CheckinoutTimePopup.inputPlaceholder')}
            onChange={e => setTimeInput(e.target.value)}
          />
          {timeError && <p className={styles.errorText}>{timeError}</p>}
        </div>
      }
      footer={
        <div>
          <Button
            onClick={handleSubmit}
            className={styles.footerSubmitButton}
          >
            {t('CheckinoutTimePopup.Submit')}
          </Button>
          <ButtonWithLoader 
            className={styles.footerCancelButton}
            onClick={handleClosePopup} 
            isLoading={isSaving}
          >
          {t('CheckinoutTimePopup.Cancel')}
          </ButtonWithLoader>
        </div>
      }
    />
  )
}

export default CheckInOutTimePopup