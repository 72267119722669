import React from 'react';

import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Card from 'library/common/commonComponents/Card';
import HelpText from 'library/common/commonComponents/HelpText';
import KitaFrameApprovalAction from './kitaFrameApprovalAction';

import styles from './kitaFrameApproval.module.scss';

export default function KitaFrameApproval({ providerKitas, activeKita }) {
  const { t } = useTranslation();

  return (
    <Card className={styles.container}>
      <div className={styles.container}>
        <div className={styles.title}>{t('Administration.UserSection.Pending kita approvals')}</div>
        <div className={styles.text}>
          <HelpText>
            {t(
              'Administration.UserSection.The following list contains all kitas awaiting an approval',
            )}
          </HelpText>
        </div>
        <table>
          <tr>
            <td className={cn(styles.headerText, styles.headerTextWithThePadding)}>
              <div className={styles.headerTextWrapper}>
                {t('Administration.kitaFrameApproval.Name')}
              </div>
            </td>
            <td className={cn(styles.headerText, styles.headerText)}>
              <div className={styles.headerTextWrapper}>
                {t('Administration.kitaFrameApproval.Adress')}
              </div>
            </td>
            <td className={cn(styles.headerText, styles.headerText)}>
              <div className={styles.headerTextWrapper}>
                {t('Administration.kitaFrameApproval.Status')}
              </div>
            </td>
            <td className={cn(styles.headerText, styles.headerText)}>
              <div className={styles.headerTextWrapper}>
                {t('Administration.kitaFrameApproval.Action')}
              </div>
            </td>
          </tr>
          {providerKitas.map(kitas => (
            <KitaFrameApprovalAction kitas={kitas} currentKita={activeKita.kitaId} />
          ))}
        </table>
      </div>
    </Card>
  );
}
