import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { sanitize } from 'dompurify';

import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import Tooltip from 'library/common/commonComponents/Tooltip';
import EditGalleryPopup from 'library/common/commonComponents/Popups/EditGalleryPopup';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import defaultImage from 'resources/images/defaultGalleryImage.svg';
import iconFullscreen from 'resources/images/fullscreen.svg';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';

import styles from '../../gallery.module.scss';

export default function GalleryItem({
  gallery,
  onClick,
  deleteGallery,
  editGallery,
  galleries,
  groupInfo,
  user,
}) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const dropdownOptions = getDropdownOptions({
    t,
    setIsDeletePopupVisible,
    setIsEditing,
    isEditing,
  });

  const thumbnail = gallery.latestImageURL;

  const [isSubmiting, setIsSubmiting] = useState(false);
  const handleDeleteBtnClick = async () => {
    setIsSubmiting(true);
    await deleteGallery(gallery.id);
    setIsSubmiting(false);
    setIsDeletePopupVisible(false);
  };

  const watsonGalleryTitle = useCallback(() => (
    gallery.isTranslationAllowed ? (
      <WithWatsonTranslate
        data={{ text: gallery.galleryName, entityId: gallery.id, entityType: 'gallery' }}
        Component={({ html }) => (
          <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
        )}
      />) : gallery.galleryName
  ))

  return (
    <>
      {isDeletePopupVisible && (
        <DeletePopup
          isOpened={isDeletePopupVisible}
          closePopup={() => setIsDeletePopupVisible(false)}
          onDeleteBtnClick={handleDeleteBtnClick}
          headerText={<Trans i18nKey='Gallery.Deletion.Title' />}
          bodyText={t('Gallery.Deletion.Text')}
          isSubmiting={isSubmiting}
        />
      )}
      {isEditing && (
        <EditGalleryPopup
          title={<Trans i18nKey='Gallery.Edit gallery' />}
          isOpened={isEditing}
          closePopup={() => setIsEditing(false)}
          createGallery={payload => editGallery({ id: gallery.id, payload })}
          group={groupInfo}
          initialData={gallery}
          galleries={galleries}
        />
      )}
      <div className={cn(styles.gallery)}>
        {user.administrationAccess && (
          <div className={styles.moreBtn}>
            {groupInfo.addModifyGallery && <MoreBtn dropdownOptions={dropdownOptions} />}
          </div>
        )}

        <Tooltip text={watsonGalleryTitle()}>
          <div className={styles.galleryTitle}>
            {watsonGalleryTitle()}
          </div>
        </Tooltip>

        <div
          className={styles.galleryImageContainer}
          style={{ backgroundImage: thumbnail && `url(${thumbnail})` }}
        >
          {!thumbnail && (
            <img
              className={cn(styles.galleryImage, { [styles.galleryImageStub]: !thumbnail })}
              src={defaultImage}
              alt=''
            />
          )}
        </div>

        <div className={styles.galleryOverlay} onClick={onClick} />
        <img className={styles.galleryPlusIcon} src={iconFullscreen} alt='' />
      </div>
    </>
  );
}

export function getDropdownOptions({ t, setIsDeletePopupVisible, setIsEditing, isEditing }) {
  return [
    {
      faIcon: 'fa-trash-o',
      title: t('Post.Delete'),
      onClick: () => {
        setIsDeletePopupVisible(true);
      },
    },
    {
      faIcon: 'fa-pencil',
      title: t('Post.Edit'),
      onClick: () => {
        setIsEditing(!isEditing);
      },
    },
  ];
}
