import React from 'react';
import DropdownEditView from "../DropdownEditView";
import { useTranslation } from "react-i18next";

export default function DoubleDropdownEditView({ component, setComponent }) {

  const { t } = useTranslation();

  return (
    <div>
      <h2>{t('FormsEditor.ComponentEditorDropdownLeftHeader')}</h2>
      <DropdownEditView component={component.dropdownLeft} setComponent={(ff) => {
        setComponent({
          ...component,
          dropdownLeft: ff,
        });
      }} />
      <h2>{t('FormsEditor.ComponentEditorDropdownRightHeader')}</h2>
      <DropdownEditView component={component.dropdownRight} setComponent={(ff) => {
        setComponent({
          ...component,
          dropdownRight: ff,
        });
      }} />
    </div>
  );
}
