import { LOAD_PAGE, LOAD_DOCUMENT } from 'library/common/commonConstants/signatureConstants';
import * as filesApi from 'library/api/files';
import { PDFDocument } from 'pdf-lib';

export const loadDocument = fileId => async dispatch => {
  const docData = await filesApi.downloadFile(fileId);
  const doc = await PDFDocument.load(await docData.data.arrayBuffer());

  dispatch({
    type: LOAD_DOCUMENT,
    document: doc,
  });
};

export const loadPage = (doc, page) => async dispatch => {
  const pageDoc = await PDFDocument.create();
  const [currentPage] = await pageDoc.copyPages(doc, [page - 1]);
  pageDoc.addPage(currentPage);
  const pageData = await pageDoc.saveAsBase64({ dataUri: true });

  dispatch({
    type: LOAD_PAGE,
    page: pageData,
  });
};
