import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { approveChild, rejectChild } from 'library/api/user';

import Button from 'library/common/commonComponents/Buttons/Button';

import styles from '../usersFrameChildren.module.scss';

export default function ListRow({ kid, parentId, showBottomNotification }) {
  const { t } = useTranslation();


  const [isButtonsVisible, setIsButtonsVisible] = useState(true);
  const onApprove = () => {
    approveChild(parentId, kid.id).then(() => {
      showBottomNotification(t('Administration.UserEditingChildren.Approved'));
      setIsButtonsVisible(false);
    });
  };

  const onReject = () => {
    rejectChild(parentId, kid.id).then(() => {
      showBottomNotification(t('Administration.UserEditingChildren.Rejected'));
      setIsButtonsVisible(false);
    });
  };


  return (
    <>
      <tr className={styles.tableOfChildrenDefaultRow}>
        {isButtonsVisible && (
          <>
            <td>
              <div className={styles.avatar}>
                <div className={cn(styles.avatarText, 'notranslate')}>
                  <div className={styles.groupName}>
                    {kid.firstName} {kid.lastName}
                  </div>
                  {kid.userName && <div className={styles.groupDesc}>{kid.userName}</div>}
                </div>
              </div>
            </td>
            <td>
              <div>
                {kid.street} {kid.zip} {kid.city}
              </div>
            </td>
            <td>
              <div>{`${kid.parentFirstName} ${kid.parentLastName}`}</div>
            </td>
            <td>
              <Button type='success' className={styles.acceptBttn} onClick={onApprove}>
                {t('Administration.UserEditingChildren.Approve')}
              </Button>
              <Button type='danger' className={styles.rejectBttn} onClick={onReject}>
                {t('Administration.UserEditingChildren.Reject')}
              </Button>
            </td>
          </>
        )}
      </tr>
      <div className={`${styles.altTable} ${styles.altTableChild}`}>
        {isButtonsVisible && (
          <>
            <div className={styles.altTableRow}>
              <div>{t('Administration.UserEditingChildren.Name')}</div>
              <div className={styles.avatar}>
                <div className={cn(styles.avatarText, 'notranslate')}>
                  <div className={`${styles.groupName} ${styles.altTableRightText}`}>
                    {kid.firstName} {kid.lastName}
                  </div>
                  {kid.userName && (
                    <div className={`${styles.groupDesc} ${styles.altTableRightText}`}>
                      {kid.userName}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.altTableRow}>
              <div>{t('Administration.UserEditingChildren.Address')}</div>
              <div className={styles.altTableRightText}>{kid.address}</div>
            </div>
            <div className={styles.altTableRow}>
              <div>{t('Administration.UserEditingChildren.Actions')}</div>
              <div>
                <Button type='success' className={styles.altTableAcceptBttn} onClick={onApprove}>
                  {t('Administration.UserEditingChildren.Approve')}
                </Button>
                <Button type='danger' className={styles.altTableRejectBttn} onClick={onReject}>
                  {t('Administration.UserEditingChildren.Reject')}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
