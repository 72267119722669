import axios from 'library/api/axios';

import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;

export const createLike = payload => axios.post(URL + '/like', payload);

export const deleteLike = id => axios.delete(URL + '/like/' + id);

export const createDislike = payload => axios.post(URL + '/dislike', payload);

export const deleteDislike = id => axios.delete(URL + '/dislike/' + id);
