import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './emailVerification.module.scss';
import { sendVerificationCode, verifyVerificationCode, verifyPassword } from 'library/api/modules';
import Storage from 'library/utilities/storage';
import { shouldShowEmailVerification } from 'library/common/commonComponents/FingerprintVerification/util';
import { setForceRun2Fa } from 'library/common/commonActions/auth2faAction';

function EmailVerification({ forSetting, handle2FAWithEmailDialog, user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timeToRecheck = 2 * 60 * 60 * 1000; //2hours
  const storageKeyPrefix = 'status_verify_2af';

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailCode, setEmailCode] = useState('');
  const [password, setPassword] = useState('');
  const [emailNotification, setEmailNotification] = useState('');
  const [errorEmailNotification, setErrorEmailNotification] = useState('');
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [isUserPasswordMethod, setIsUserPasswordMethod] = useState(false);
  const isActive = useSelector(state => state.auth2FaReducer.isActive);
  const forceRun = useSelector(state => state.auth2FaReducer.forceRun);
  const activeKitaId = useSelector(state => state.kitaReducer.activeKita?.kitaId);
  const [intervalCounter, setIntervalCounter] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const loginTime = Storage.getItem('loginTime');
      if (!loginTime || forSetting || !isActive || showEmailDialog) {
        return;
      }
      const currentTime = new Date().getTime();
      setCurrentTime(currentTime);
      const elapsedTime = currentTime - parseInt(loginTime, 10);
      const verifyStatus = Storage.getItem(`${storageKeyPrefix}_${activeKitaId}_${user.id}`);
      if (elapsedTime >= timeToRecheck || (forceRun && verifyStatus !== 'verified')) {
        if (!(await shouldShowEmailVerification())) {
          return;
        }
        setShowEmailDialog(true);
      }
    };

    fetchData();
  }, [isActive, forceRun, intervalCounter]);

  useEffect(() => {
    if (!activeKitaId) {
      return;
    }
    const intervalId = setInterval(() => {
      Storage.removeItem(`${storageKeyPrefix}_${activeKitaId}_${user.id}`);
      setForceRun2Fa(true)(dispatch);
      setIntervalCounter(prevCounter => prevCounter + 1);
    }, timeToRecheck);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeKitaId]);

  useEffect(() => {
    const fetchDataForSetting = async () => {
      if (!(await shouldShowEmailVerification())) {
        return;
      }
      setShowEmailDialog(true);
    };

    if (forSetting) {
      fetchDataForSetting();
    }
  }, [forSetting]);

  useEffect(() => {
    if (!showEmailDialog) {
      setEmailCode('');
      setPassword('');
      setEmailNotification('');
      setErrorEmailNotification('');
    } else {
      // sendVerificationCode();
    }
  }, [showEmailDialog]);

  const handleCloseDialog = () => {
    setShowEmailDialog(false);
    if (forSetting) {
      handle2FAWithEmailDialog(false);
    }
  };
  const handleResendEmailVerification = async () => {
    try {
      await sendVerificationCode();
      setEmailNotification(t('EmailVerification.EmailWithCodeJustSent'));
      setErrorEmailNotification('');
    } catch (error) {
      setEmailNotification('Something went wrong');
    }
  };
  const handleVerification = async () => {
    setIsSubmitting(true);
    setEmailNotification('');
    try {
      if (isUserPasswordMethod) {
        const res = await passwordVerification();
        if (res) {
          setShowEmailDialog(false);
          Storage.setItem('loginTime', currentTime);
          Storage.setItem(`${storageKeyPrefix}_${activeKitaId}_${user.id}`, 'verified');
          setForceRun2Fa(false)(dispatch);
        } else {
          setErrorEmailNotification(t('EmailVerification.PasswordInCorrect'));
        }
      } else {
        const res = await emailVerification();
        if (res) {
          if (forSetting) {
            handle2FAWithEmailDialog(true);
          }
          setShowEmailDialog(false);
          Storage.setItem('loginTime', currentTime);
          Storage.setItem(`${storageKeyPrefix}_${activeKitaId}_${user.id}`, 'verified');
          setForceRun2Fa(false)(dispatch);
        } else {
          setErrorEmailNotification(t('EmailVerification.CodeInvalid'));
        }
      }

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const emailVerification = async () => {
    const res = (await verifyVerificationCode(emailCode)).data;
    return res;
  };

  const passwordVerification = async () => {
    const res = (await verifyPassword(password)).data;
    return res;
  };

  const handleUsePassword = () => {
    setIsUserPasswordMethod(true);
  };

  const handleUseEmail = () => {
    setIsUserPasswordMethod(false);
  };

  return (
    <Popup
      size={'extraSmall'}
      isOpened={showEmailDialog}
      closePopup={() => {
        handleCloseDialog();
      }}
      showCloseButton={!!forSetting}
      header={<strong>{t('EmailVerification.Title')}</strong>}
      body={
        <div className={styles.emailVerificationContainer}>
          <h4>{t('EmailVerification.SentCodeToYourEmail')}</h4>
          {isUserPasswordMethod ? (
            <Input
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              className={styles.emailCodeInput}
              placeholder={t('EmailVerification.Password')}
            />
          ) : (
            <Input
              value={emailCode}
              onChange={e => setEmailCode(e.target.value)}
              className={styles.emailCodeInput}
              placeholder={t('EmailVerification.Code')}
            />
          )}

          {emailNotification && <div className={styles.notification}>{emailNotification}</div>}
          {errorEmailNotification && (
            <div className={styles.errorNotification}>{errorEmailNotification}</div>
          )}
        </div>
      }
      footer={
        <div className={styles.emailFooter}>
          <ButtonWithLoader onClick={handleVerification} type='primary' isLoading={isSubmitting}>
            {t('EmailVerification.Verify')}
          </ButtonWithLoader>
          {intervalCounter && !forSetting ? (
            <>
              {isUserPasswordMethod ? (
                <div className={styles.resendWrapper}>
                  <span onClick={handleUseEmail} className={styles.btnResend}>
                    {t('EmailVerification.TryEmail')}
                  </span>
                </div>
              ) : (
                <>
                  <div className={styles.resendWrapper}>
                    <span>{t('EmailVerification.DidNotReceiveCode')}</span>
                    <span onClick={handleResendEmailVerification} className={styles.btnResend}>
                      {t('EmailVerification.Resend')}
                    </span>
                  </div>
                  <div className={styles.resendWrapper}>
                    <span onClick={handleUsePassword} className={styles.btnResend}>
                      {t('EmailVerification.TryPassword')}
                    </span>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={styles.resendWrapper}>
              <span>{t('EmailVerification.DidNotReceiveCode')}</span>
              <span onClick={handleResendEmailVerification} className={styles.btnResend}>
                {t('EmailVerification.Resend')}
              </span>
            </div>
          )}
        </div>
      }
    />
  );
}

export default EmailVerification;
