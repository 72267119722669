import {
  SET_CHAT_ID,
  SET_CHAT_SOCKET_SUBSCRIBED,
  OPEN_NEW_CHAT_WITH_RECIPIENT_SIGNAL,
  RESET_NEW_CHAT_WITH_RECIPIENT_SIGNAL_RECIPIENT,
  SET_UNREAD_MESSAGES_COUNTS_KITAS,
} from '../commonConstants/chatConstants';

export const setUnreadMessagesCountsKitas = data => dispatch => {
  dispatch({
    type: SET_UNREAD_MESSAGES_COUNTS_KITAS,
    payload: data,
  });
};

export const setSelectedChatId = data => dispatch => {
  dispatch({
    type: SET_CHAT_ID,
    payload: data,
  });
};

export const setChatSocketSubscribed = () => dispatch => {
  dispatch({
    type: SET_CHAT_SOCKET_SUBSCRIBED,
  });
};

export const openNewChatWithRecipient = recipient => dispatch => {
  dispatch({
    type: OPEN_NEW_CHAT_WITH_RECIPIENT_SIGNAL,
    payload: recipient,
  });
};

export const resetNewChatWithRecipientSignalRecipient = () => dispatch => {
  dispatch({
    type: RESET_NEW_CHAT_WITH_RECIPIENT_SIGNAL_RECIPIENT,
  });
};
