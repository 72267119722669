import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FriendRequestTableHeader from '../FriendRequestTableHeader';
import FriendRequestTableEmpty from '../FriendRequestTableEmpty';
import FriendRequestTableRow from '../FriendRequestTableRow';

import styles from './requestsList.module.scss';

export default function RequestsList({
  requestsList,
  onAcceptFriendRequest,
  onCancelFriendRequest,
  history,
}) {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState(requestsList);

  const handleSorting = sortDirection => {
    const sortedTableData = tableData.slice();
    sortedTableData.sort((a, b) =>
      a.requester.userName.toLowerCase() > b.requester.userName.toLowerCase()
        ? sortDirection === 'ASC'
          ? 1
          : -1
        : sortDirection === 'ASC'
        ? -1
        : 1,
    );
    setTableData(sortedTableData);
  };

  return (
    <div className={styles.container}>
      {tableData.length > 0 && (
        <div className={styles.description}>
          {t('AccountSettings.FriendsSection.Showing') +
            ` 1-${tableData.length} ` +
            t('AccountSettings.FriendsSection.of') +
            ` ${tableData.length} ` +
            t('AccountSettings.FriendsSection.items')}
        </div>
      )}
      <table className={styles.table}>
        <FriendRequestTableHeader onSort={handleSorting} />
        <tbody>
          {!tableData.length ? (
            <FriendRequestTableEmpty />
          ) : (
            tableData.map(item => (
              <FriendRequestTableRow
                key={item.id}
                id={item.requesterId}
                history={history}
                userName={item.requester.userName}
                firstName={item.requester.firstName}
                lastName={item.requester.lastName}
                buttons={[
                  {
                    id: 1,
                    title: t('AccountSettings.FriendsSection.Accept'),
                    onClick: () => onAcceptFriendRequest(item.id, item.requester.id),
                    className: styles.acceptButton,
                  },
                  {
                    id: 2,
                    title: t('AccountSettings.FriendsSection.Deny'),
                    onClick: () => onCancelFriendRequest(item.id, item.requester.id),
                    className: styles.denyButton,
                  },
                ]}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
