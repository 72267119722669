import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {
  setGoogleTranslateAgree,
  setGoogleTranslateLang,
} from 'library/common/commonActions/changeLanguageActions';
import LanguagesFrame from '.';

const mapStateToProps = state => ({
  user: state.userReducer,
  isGoogleTranslateAgree: state.languageReducer.isGoogleTranslateAgree,
  googleTranslateLang: state.languageReducer.googleTranslateLang,
});

export const mapDispatchToProps = {
  showBottomNotification,
  setGoogleTranslateAgree,
  setGoogleTranslateLang,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguagesFrame);
