import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import Label from 'library/common/commonComponents/Label';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import styles from './calendarImportPopup.module.scss';

export default function CalendarImportPopup({
  importFile,
  reloadEvents,
  isOpened,
  closePopup,
  showBottomNotification,
}) {
  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const submitFile = async () => {
    if (!file) {
      return setFileError(t('Administration.UserSection.Please upload a file'));
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      await importFile(formData);
      reloadEvents();

      showBottomNotification(t('BottomNotifications.Saved'));
      closePopup();
      // reloadPage();
    } catch (ex) {
      setFile(null);
      if (ex.response.data.message.includes('Invalid column')) {
        setFileError(t('Administration.UserSection.File has invalid structure'));
      } else {
        setFileError(ex.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popup
      size='normal'
      isOpened={isOpened}
      closePopup={closePopup}
      header={<strong>Calendar import</strong>}
      footer={
        <div className={styles.buttonsContainer}>
          <div>
            <ButtonWithLoader onClick={submitFile} type='primary' isLoading={isLoading}>
              {t('Calendar.Save')}
            </ButtonWithLoader>
          </div>
          <div>
            <Button onClick={closePopup}>{t('Calendar.Cancel')}</Button>
          </div>
        </div>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.inputGroup}>
          <Label type='input'>{t('Administration.CsvOrIcsFile')}</Label>
          <div>
            <UploadButton
              onChange={files => {
                setFileError('');
                setFile(files[0]);
              }}
              allowedExtensions={['.csv', '.ics']}
            />
          </div>
          {fileError && <InputErrorMsg errorMsg={fileError} />}
          {file && file.name}
        </div>
      </div>
    </Popup>
  );
}
