import React, { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import styles from './dictaphone.module.scss';

const Dictaphone = ({ insText, disabled }) => {

  function insertText(e) {
    insText(e.detail);
    [window, document].forEach(el =>
      el.removeEventListener('native.voicetotext', insertText),
    );
  }

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [isListening, setIsListening] = useState(false);
  if (!browserSupportsSpeechRecognition && !window.ReactNativeWebView) {
    return <span />;
  }

  function startStopSpeechRecognitionInApp() {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'voice_to_text',
      }),
    );
    return null;
  }

  return (
    <div style={{ position: 'absolute', right: '10px', pointerEvents: disabled === true ? "none" : "auto" }}>
      {isListening === false ? (
        <div
          className={styles.upload}
          onClick={() => {
            if (window.ReactNativeWebView) {
              [window, document].forEach(el =>
                el.addEventListener('native.voicetotext', insertText),
              );
              startStopSpeechRecognitionInApp();
            } else {
              SpeechRecognition.startListening({ continuous: false, language: 'de-DE' });
            }
            setIsListening(true);
          }}
        >
          <label>
            <i className='fa fa-commenting-o' />
          </label>
        </div>
      ) : (
        <div
          className={styles.upload}
          style={{ color: 'red' }}
          onClick={() => {
            if (window.ReactNativeWebView) {
              startStopSpeechRecognitionInApp();
            } else {
              SpeechRecognition.stopListening();
              insText(transcript);
              resetTranscript();
            }
            setIsListening(false);
          }}
        >
          <label>
            <i className='fa fa-circle' />
          </label>
        </div>
      )}
    </div>
  );
};
export default Dictaphone;
