import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import store from 'main/store/configureStore';
import dislikeIcon from 'resources/images/like/likeIcon.svg';
import undislikeIcon from 'resources/images/like/unlikeIcon.svg';
import DislikeButtonCounter from './dislikeButtonFrames/DislikeButtonCounter';

import styles from './dislikeButton.module.scss';

DislikeButton.defaultProps = {
  withDislikesModal: true,
  localDislikes: [],
};

export default function DislikeButton({
  className,
  localDislikes,
  isDisliked,
  dislikeHandler,
  withDislikesModal,
  group,
}) {
  const oldIsDisliked = useRef(isDisliked);
  const [isDislikeAnimating, setIsDislikeAnimating] = useState(false);
  useEffect(() => {
    if (oldIsDisliked.current !== isDisliked) {
      setIsDislikeAnimating(false);
      oldIsDisliked.current = isDisliked;
      if (isDisliked) {
        setIsDislikeAnimating(true);
      }
    }
  }, [isDisliked]);

  const user = store.getState().userReducer;

  return (
    <div
      className={cn(styles.likeButton, className)}
      onClick={withDislikesModal ? () => { } : dislikeHandler}
    >
      <div
        onClick={withDislikesModal ? dislikeHandler : () => { }}
        className={styles.likeIconWrapper}
      >
        {isDisliked ? (
          <img
            src={undislikeIcon}
            className={isDislikeAnimating ? styles.likeIconWithAnimation : ''}
            style={{ transform: 'rotate(180deg)' }}
            alt='undislike'
          />
        ) : (
          <img
            src={dislikeIcon}
            style={{ transform: 'rotate(180deg)' }}
            className={styles.removeLikeIcon}
            alt='dislike'
          />
        )}
      </div>
      <DislikeButtonCounter
        dislikes={localDislikes}
        user={user}
        withDislikesModal={withDislikesModal}
        group={group}
      />
    </div>
  );
}
