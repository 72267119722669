export const URLS = {
  baseUrl: 'http://sbdev.theleanapps.com:8095/api',
  notifications: '/notifications/_page',
  notificationConfiguration: '/notificationconfiguration/',
  notificationConfigurationUser: '/user/notificationconfigurations/',
};

export const GET_NOTIFICATION_SETTINGS_USER = '@@GET_NOTIFICATION_SETTINGS_USER';
export const GET_NOTIFICATION_SETTINGS_GLOBAL = '@@GET_NOTIFICATION_SETTINGS_GLOBAL';
export const GET_NOTIFICATIONS = '@@GET_NOTIFICATIONS';
export const SHOW_BOTTOM_NOTIFICATION = '@@SHOW_BOTTOM_NOTIFICATION';
export const RESET_NOTIFICATIONS_STATE = '@@RESET_NOTIFICATIONS_STATE';
export const UPDATE_COUNT_OF_NOTIFICATIONS = '@@UPDATE_COUNT_OF_NOTIFICATIONS';
export const UPDATE_COUNT_OF_TAX_RECEIPT = '@@UPDATE_COUNT_OF_TAX_RECEIPT';
export const ADD_EXTRA_NOTIFICATION = '@@ADD_EXTRA_NOTIFICATION';

export const INTIIAL_NOTIFICATION_SETTINGS_ARRAY = [
  { type: 'ADMINISTRATIVE', web: true, email: true, mobile: true },
  { type: 'CALENDAR', web: true, email: true, mobile: true },
  { type: 'MESSAGE', web: true, email: true, mobile: true },
  { type: 'CONVERSATION', web: true, email: true, mobile: true },
  { type: 'TASKS', web: true, email: true, mobile: true },
  { type: 'TASKS_REMINDER', web: true, email: true, mobile: true },
  { type: 'COMMENTS', web: true, email: true, mobile: true },
  { type: 'NEW_CONTENT', web: true, email: true, mobile: true },
  { type: 'FRIENDSHIP', web: true, email: true, mobile: true },
  { type: 'LIKES', web: true, email: true, mobile: true },
  { type: 'GROUP_MEMBERSHIP', web: true, email: true, mobile: true },
  { type: 'FOLLOWING', web: true, email: true, mobile: true },
  { type: 'MENTIONINGS', web: true, email: true, mobile: true },
  { type: 'SPACE_MEMBERSHIP', web: true, email: true, mobile: true },
  { type: 'ABSENCE_RETURN_RECEIPT', web: true, email: true, mobile: true },
];

export const inititalTypeObject = {
  ADMINISTRATIVE: {},
  CALENDAR: {},
  MESSAGE: {},
  CONVERSATION: {},
  TASKS: {},
  TASKS_REMINDER: {},
  COMMENTS: {},
  NEW_CONTENT: {},
  FRIENDSHIP: {},
  LIKES: {},
  GROUP_MEMBERSHIP: {},
  FOLLOWING: {},
  MENTIONINGS: {},
  ABSENCE_RETURN_RECEIPT: {},
};
