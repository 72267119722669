import React from 'react';
import cn from 'classnames';

import styles from './editButton.module.scss';

export default function EditButton({ onClick, className }) {
  return (
    <div className={cn(styles.edit, className)} onClick={onClick}>
      <i className='fa fa-edit' />
    </div>
  );
}
