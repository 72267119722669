import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import styles from './releasenotes.module.scss';

export default function ReleasenotesModule() {

  return (
    <>
      <KitaPanelWrapper>
        <Wrapper>
          <Col>
            <Row>
              <div className={styles.container}>
                <iframe
                  className={styles.iframecl}
                  src='https://kitaplatzonline.de/faqreleasenotes'
                  title='Hilfe'
                />
              </div>
            </Row>
          </Col>
        </Wrapper>
      </KitaPanelWrapper >
    </>
  );
}
