import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './onlyForKitaMember.module.scss';

export default function OnlyForKitaMember({ activeKita, children }) {
  const { t } = useTranslation();

  return activeKita && activeKita.kitaId ? (
    children
  ) : (
    <div className={styles.container}>{t('OnlyForKitaMember.Only for kita member')}</div>
  );
}
