import {
  SET_TIMERANGE_ABSENCE,
  SET_TIMERANGE_ABSENCE_LOADING,
  SET_TIMERANGE_ABSENCE_END_LOADING,
  DISABLE_TIMERANGE_ABSENCE,
} from 'library/common/commonConstants/timerangeAbsenceConstants';

export const setTimeRangeAbsence = data => dispatch => {
  dispatch({
    type: SET_TIMERANGE_ABSENCE,
    payload: data,
  });
};

export const setTimeRangeAbsenceLoading = () => dispatch => {
  dispatch({
    type: SET_TIMERANGE_ABSENCE_LOADING,
  });
};

export const setTimeRangeAbsenceEndLoading = () => dispatch => {
  dispatch({
    type: SET_TIMERANGE_ABSENCE_END_LOADING,
  });
};

export const disableTimeRangeAbsenceLoading = () => dispatch => {
  dispatch({
    type: DISABLE_TIMERANGE_ABSENCE,
  });
};
