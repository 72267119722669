import React, { useState, useReducer } from 'react';
import Button from 'library/common/commonComponents/Buttons/Button';
import HelpText from 'library/common/commonComponents/HelpText';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import styles from './workingTimeChange.module.scss';
import DatePicker from 'library/common/commonComponents/DatePicker';
import { useTranslation } from 'react-i18next';
import {
  getUpcomingEmployeeWorkingTimesChanges,
  deleteUpcomingEmployeeWorkingTimes,
} from 'library/api/employee';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import cn from 'classnames';

export default function WorkingTimeChange({
  workingTimeChangeFrom,
  setWorkingTimeChangeFrom,
  userId,
  currentWorkingTime,
  upcomingWorkingTimeDate,
  fetchEmployeeWorkingTimes,
}) {
  const { t } = useTranslation();
  const [isConfirmDeletePopupOpen, toggleIsConfirmDeletePopupOpen] = useReducer(
    state => !state,
    false,
  );
  const [isViewWorkingTimePopupOpen, toggleIsViewWorkingTimePopupOpen] = useReducer(
    state => !state,
    false,
  );
  const [isLoading, setIsLoading] = useState(false);

  const [workingTimeChange, setWorkingTimeChange] = useState(null);

  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const createTimeEntry = (day, firstShift, secondShift, availableTime, startEnd) => ({
    name: `${day}${startEnd}Time`,
    value: formatTimeObject(firstShift?.[`${day}${startEnd}Time`]),
    secondShift: `${day}Second${startEnd}Time`,
    secondShiftValue: formatTimeObject(secondShift?.[`${day}${startEnd}Time`]),
    available: `${day}Available${startEnd}Time`,
    availableTime: formatTimeObject(availableTime?.[`${day}${startEnd}Time`]),
  });

  const handleFetchWorkingTimeChange = async () => {
    setIsLoading(true);
    try {
      const { data } = await getUpcomingEmployeeWorkingTimesChanges(userId);

      const firstShift = data.find(shift => shift.shiftType === 'FIRST_SHIFT');
      const secondShift = data.find(shift => shift.shiftType === 'SECOND_SHIFT');
      const availableTime = data.find(shift => shift.shiftType === 'AVAILABLE_TIME');

      const workingTimeChange = days.map(day => ({
        weekday: t(`Administration.${day.charAt(0).toUpperCase() + day.slice(1)}`),
        time: [
          createTimeEntry(day, firstShift, secondShift, availableTime, 'Start'),
          createTimeEntry(day, firstShift, secondShift, availableTime, 'End'),
        ],
      }));

      setWorkingTimeChange(workingTimeChange);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function formatTimeObject(time) {
    if (time === null) {
      return '';
    }
    if (typeof time === 'object') {
      const hours = time.hour.toString().padStart(2, 0);
      const minutes = time.minute.toString().padStart(2, 0);
      return `${hours}:${minutes}`;
    }
    return time;
  }

  function isTimeDifferent(entryCurrent, entryChange, key) {
    if (entryCurrent.time[0][key] !== entryChange.time[0][key]) {
      return true;
    }
    if (entryCurrent.time[1][key] !== entryChange.time[1][key]) {
      return true;
    }
    return false;
  }

  async function handleConfirmDelete() {
    setIsLoading(true);
    try {
      await deleteUpcomingEmployeeWorkingTimes(userId);
      await fetchEmployeeWorkingTimes();
    } catch (err) {
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      toggleIsConfirmDeletePopupOpen();
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className={styles.workingTimeChangeContainer}>
        <div className={styles.caption}>
          <i className='fa fa-lg fa-refresh' aria-hidden='true' />
          <label htmlFor='workingTimeChangeFrom'>{t('Administration.Overtime change from')}</label>
        </div>
        <HelpText className={styles.helpText}>
          {t(
            'Administration.Set the date from which the working time change will be applied. If no date is set, the change will be applied from the current date onwards',
          )}
        </HelpText>

        <DatePicker
          id='workingTimeChangeFrom'
          selected={workingTimeChangeFrom}
          onChange={date => setWorkingTimeChangeFrom(date)}
          dateFormat='dd.MM.yyyy'
          dropdownMode='select'
          minDate={new Date()}
          disabled={!!upcomingWorkingTimeDate}
        />

        {!!upcomingWorkingTimeDate && (
          <div className={styles.buttonContainer}>
            <button
              className={styles.textButton}
              onClick={() => {
                toggleIsViewWorkingTimePopupOpen();
                handleFetchWorkingTimeChange();
              }}
            >
              <i className='fa fa-lg fa-eye' aria-hidden='true' />
              {t('Administration.View upcoming working time change')}
            </button>

            <button className={styles.textButton} onClick={toggleIsConfirmDeletePopupOpen}>
              <i className='fa fa-lg fa-trash' aria-hidden='true' />
              {t('Administration.Delete upcoming working time change')}
            </button>
          </div>
        )}
      </div>

      {/* Delete working time popup */}
      <Popup
        size='small'
        isOpened={isConfirmDeletePopupOpen}
        closePopup={toggleIsConfirmDeletePopupOpen}
        header={<strong>{t('Administration.Warning')}</strong>}
        body={
          <p>
            {t(
              'Administration.This change connot be undone. Are you sure you want to delete the upcoming working time change?',
            )}
          </p>
        }
        footer={
          <div>
            <div className={styles.reviewButtonGroup}>
              <Button onClick={toggleIsConfirmDeletePopupOpen}>{t('Administration.Cancel')}</Button>
              <Button type='primary' onClick={handleConfirmDelete}>
                {t('Administration.Confirm')}
              </Button>
            </div>
          </div>
        }
      />

      {/* View working time popup */}
      <Popup
        size='large'
        isOpened={isViewWorkingTimePopupOpen}
        closePopup={toggleIsViewWorkingTimePopupOpen}
        header={
          <strong>
            {`${t('Administration.Upcoming working time change')} (${new Date(
              upcomingWorkingTimeDate,
            ).toLocaleDateString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })})`}
          </strong>
        }
        body={
          <div className={styles.popupBody}>
            {isLoading ? (
              <Loader />
            ) : (
              workingTimeChange?.map((day, index, workingTimeChangeArr) => (
                <div key={day.weekday} className={styles.weekdayContainer}>
                  <h3 className={styles.weekday}>{day.weekday}</h3>
                  <div className={styles.shiftContainer}>
                    <div className={styles.newChangeLabel}>
                      <span>{t('Administration.Current')}</span>
                      <span>{t('Administration.New change')}</span>
                    </div>
                    <div className={styles.shiftColumn}>
                      <h4>{t('Administration.Working time shift') + ' 1'}</h4>
                      <span>
                        {currentWorkingTime[index].time[0].value
                          ? `${currentWorkingTime[index].time[0].value} - ${
                              currentWorkingTime[index].time[1].value
                            }`
                          : '-'}
                      </span>

                      <span
                        className={cn(
                          isTimeDifferent(
                            workingTimeChangeArr[index],
                            currentWorkingTime[index],
                            'value',
                          ) && styles.highlighted,
                        )}
                      >
                        {workingTimeChangeArr[index].time[0].value
                          ? `${workingTimeChangeArr[index].time[0].value} - ${
                              workingTimeChangeArr[index].time[1].value
                            }`
                          : '-'}
                      </span>
                    </div>
                    <div className={styles.shiftColumn}>
                      <h4>{t('Administration.Working time shift') + ' 2'}</h4>

                      <span>
                        {currentWorkingTime[index].time[0].secondShiftValue
                          ? `${currentWorkingTime[index].time[0].secondShiftValue} - ${
                              currentWorkingTime[index].time[1].secondShiftValue
                            }`
                          : '-'}
                      </span>

                      <span
                        className={cn(
                          isTimeDifferent(
                            workingTimeChangeArr[index],
                            currentWorkingTime[index],
                            'secondShiftValue',
                          ) && styles.highlighted,
                        )}
                      >
                        {workingTimeChangeArr[index].time[0].secondShiftValue
                          ? `${workingTimeChangeArr[index].time[0].secondShiftValue} - ${
                              workingTimeChangeArr[index].time[1].secondShiftValue
                            }`
                          : '-'}
                      </span>
                    </div>
                    <div className={styles.shiftColumn}>
                      <h4>{t('TimeSettings.Verfügungszeit')}</h4>
                      <span>
                        {currentWorkingTime[index].time[0].availableTime
                          ? `${currentWorkingTime[index].time[0].availableTime} - ${
                              currentWorkingTime[index].time[1].availableTime
                            }`
                          : '-'}
                      </span>

                      <span
                        className={cn(
                          isTimeDifferent(
                            workingTimeChangeArr[index],
                            currentWorkingTime[index],
                            'availableTime',
                          ) && styles.highlighted,
                        )}
                      >
                        {workingTimeChangeArr[index].time[0].availableTime
                          ? `${workingTimeChangeArr[index].time[0].availableTime} - ${
                              workingTimeChangeArr[index].time[1].availableTime
                            }`
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        }
      />
    </>
  );
}
