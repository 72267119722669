import { connect } from 'react-redux';

import { loadUserInfo, setUserPermissions } from 'library/common/commonActions/userActions';
import {
  setActiveKita,
  setKitaModules,
  setIsOpened,
  setKitas,
} from 'library/common/commonActions/kitaActions';
import { setUnreadMessagesCountsKitas } from 'library/common/commonActions/chatActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { getGoogleTranslatorModuleEnabled } from 'library/common/commonSelectors/kitaSelectors';
import KitaSidePanel from './KitaSidePanelModule';

const mapDispatchToProps = {
  loadUserProfile: loadUserInfo,
  setActiveKita,
  setKitaModules,
  setIsOpened,
  setKitas,
  setUserPermissions,
  showBottomNotification,
  setUnreadMessagesCountsKitas,
};

export default connect(
  state => ({
    isLoggedIn: state.authReducer.isLoggedIn,
    isAdmin: state.userReducer.administrationAccess,
    superAdminStatus: state.userReducer.superAdminStatus,
    newKita: state.kitaReducer.newKita,
    user: state.userReducer,
    isOpened: state.kitaReducer.isOpened,
    unreadNotificationsCount: state.notificationsReducer.unreadNotificationsCount,
    unreadMessagesCountsKitas: state.chatReducer.unreadMessagesCountsKitas,
    unreadTaxReceiptCount: state.notificationsReducer.unreadTaxReceiptCount,
    goToNewKita: state.kitaReducer.goToNewKita,
    goToKita: state.kitaReducer.goToKita,
    isGoogleTranslatorActive: getGoogleTranslatorModuleEnabled(state),
  }),
  mapDispatchToProps,
)(KitaSidePanel);
