import React, { useState } from 'react';
import cn from 'classnames';
import styles from './uploadImageButton.module.scss';

export default function UploadImageButton({ sketchWhiteboard, setMode, Tools }) {
  const handleFileInput = e => {
    console.log(e.target.files[0]);
    resizeImage({
      file: e.target.files[0],
      maxSize: 900,
      fileType: e.target.files[0].type,
    }).then(function(resizedImage) {
      sketchWhiteboard.current.addImg(URL.createObjectURL(resizedImage));
    });
    setMode(prev => {
      return { tool: Tools.Select, isErasing: false };
    });
  };

  var resizeImage = function(settings) {
    const {file} = settings;
    const {maxSize} = settings;
    const {fileType} = settings;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = function(dataURI) {
      const bytes =
        dataURI.split(',')[0].indexOf('base64') >= 0
          ? atob(dataURI.split(',')[1])
          : unescape(dataURI.split(',')[1]);
      const mime = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    };
    const resize = function() {
      let {width} = image;
      let {height} = image;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL(fileType);
      return dataURItoBlob(dataUrl);
    };
    return new Promise(function(ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }
      reader.onload = function(readerEvent) {
        image.onload = function() {
          return ok(resize());
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div>
      <div className={cn(styles.toolBarIcon)}>
        <label className={cn(styles.imageUpload)}>
          <input
            className={styles.fileInput}
            accept='image/*'
            type='file'
            onChange={handleFileInput}
          />
          <i className='fa fa-picture-o' />
        </label>
      </div>
    </div>
  );
}
