import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BookingTimes.module.scss';


const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];


const BookingTimes = ({ kid, setKid, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.bookingTimesContainer}>
      <b>{t('Administration.KidSection.Booking times')}</b>
      <br />
      <div className={styles.bookingGrid}>
        <div className={styles.gridHeader}>
          <div>{t('Administration.KidSection.Weekday')}</div>
          <div>{t('Administration.KidSection.From')}</div>
          <div>{t('Administration.KidSection.To')}</div>
        </div>

        {days.map(day => (
          <div className={styles.gridRow} key={day}>
            <div>{t(`Administration.KidSection.${day}`)}</div>
            <input
              disabled={disabled}
              type='time'
              value={kid[`bookingTime${day}From`] || ''}
              onChange={date => {
                setKid({ ...kid, [`bookingTime${day}From`]: date.target.value });
              }}
            />
            <input
              disabled={disabled}
              type='time'
              value={kid[`bookingTime${day}To`] || ''}
              onChange={date => {
                setKid({ ...kid, [`bookingTime${day}To`]: date.target.value });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingTimes;
