import React, { useRef, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import cn from 'classnames';
import SignatureCanvas from 'react-signature-canvas';

import Wrapper from 'library/common/commonComponents/Wrapper';
import PageWithSidebar from 'library/common/commonComponents/PageWithSidebar';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import Loader from 'library/common/commonComponents/Loader';
import ConfirmPopup from 'library/common/commonComponents/Popups/ConfirmPopup';
import store from 'main/store/configureStore';
import { loadPage, loadDocument } from 'library/common/commonActions/signatureActions';

import { Document, Page, pdfjs } from 'react-pdf';
import styles from './signature.module.scss';
import { finalize, overwrite } from '../../library/api/signature';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.min.js`;

export default function Signature({ match, documentData, pageData, showBottomNotification }) {
  useSiteTitle('Signature');

  const { t } = useTranslation();

  const signatureCanvasRef = useRef(null);
  const pageRef = useRef(null);

  const initialMenuItems = {
    save: {
      title: t('Signature.Save & Close'),
      faIcon: 'fa-floppy-o',
      onClick: () => saveSignature(),
    },
    cancel: {
      title: t('Signature.Cancel'),
      faIcon: 'fa-sign-out',
      onClick: () => {
        if (
          !signatureCanvasRef.current.isEmpty() ||
          Object.values(signatures).filter(v => v !== '').length > 0
        ) {
          setPopup({
            text: t('Signature.ConfirmCancel'),
            onConfirm: () => (window.location.href = referrer),
          });
        } else {
          window.location.href = referrer;
        }
      },
    },
    clear: {
      title: t('Signature.Clear page'),
      faIcon: 'fa-file-o',
      onClick: () => {
        signatureCanvasRef.current.clear();
        setPopup(null);
        saveCurrentSignatureState();
      },
    },
  };

  const [rendered, setRendered] = useState(false);
  const [popup, setPopup] = useState(null);
  const [curPage, setCurPage] = useState(1);
  const [menuItems, setMenuItems] = useState(initialMenuItems);
  const [signatures, setSignatures] = useState({});
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [overwriteFile, setOverwriteFile] = useState(null);
  const [overwriteUser, setOverwriteUser] = useState(null);
  const [referrer, setReferrer] = useState('/');
  const [editMode , setEditMode] = useState(false);

  const handleCanvasSize = () => {
    const { width, height } = documentData.getPage(curPage - 1).getSize();
    const ratio = height / width;
    const canvas = signatureCanvasRef.current.getCanvas();

    const parent = canvas.parentElement;

    const actualWidth = parent.clientWidth;
    const actualHeight = actualWidth * ratio;

    setCanvasWidth(actualWidth);

    canvas.style.width = `${actualWidth}px`;
    canvas.style.height = `${actualHeight}px`;

    canvas.width = actualWidth;
    canvas.height = actualHeight;

    if (signatureCanvasRef.current) {
      if (curPage in signatures) {
        const signature = signatures[curPage];

        signatureCanvasRef.current.fromDataURL(signature, {
          width: actualWidth,
          height: actualHeight,
        });
      }
    }
  };

  function resizeEventHandler() {
    window.removeEventListener('resize', resizeEventHandler);

    setRendered(false);
  }

  const renderData = () => {
    if (!signatureCanvasRef.current) return;

    setRendered(true);

    signatureCanvasRef.current.clear();

    setTimeout(() => handleCanvasSize(), 500);
  };

  const signatureToPDFImage = async (doc, data) => {
    return doc.embedPng(data);
  };

  const modifyPage = async (page, i) => {
    if (!(i in signatures) && i !== curPage) return;

    const sig =
      i === curPage
        ? signatureCanvasRef.current.toDataURL()
        : 'data:image/png;base64,' + signatures[i];
    const img = await signatureToPDFImage(documentData, sig);
    const { width, height } = page.getSize();
    page.drawImage(img, {
      x: 0,
      y: 0,
      width,
      height,
    });
  };

  const generatePDF = async () => {
    const pages = documentData.getPages();

    for (let i = 0; i < documentData.getPageCount(); i++) {
      modifyPage(pages[i], i + 1);
    }

    const pdfBytes = await documentData.save();

    return pdfBytes;
  };

  const saveCurrentSignatureState = () => {
    if (signatureCanvasRef.current.isEmpty()) {
      delete signatures[curPage];
      setSignatures(signatures);
    } else {
      setSignatures({
        ...signatures,
        [curPage]: signatureCanvasRef.current.toDataURL(),
      });
    }
  };

  const saveSignature = async () => {
    if (signatureCanvasRef.current) {
      generatePDF()
        .then(data => {
          if (overwriteFile) {
            overwrite(overwriteFile, overwriteUser, data)
              .then(() => {
                window.location.href = referrer;
              })
              .catch(() => {
                showBottomNotification(t('Signature.Save Error'), { isFail: true });
              });
          } else {
            finalize(match.params.document, match.params.groupId, data)
              .then(() => {
                window.location.href = referrer;
              })
              .catch(() => {
                showBottomNotification(t('Signature.Save Error'), { isFail: true });
              });
          }
        })
        .catch(() => {
          showBottomNotification(t('Signature.Save Error'), { isFail: true });
        });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const overwriteFileParam = params.get('overwrite');
    const overwriteUserParam = params.get('user');
    const referrerParam = params.get('referrer');
    // eslint-disable-next-line max-len
    if (
      overwriteFileParam != null &&
      !Number.isNaN(overwriteFileParam, 10) &&
      !Number.isNaN(parseInt(overwriteFileParam, 10), 10) &&
      // eslint-disable-next-line max-len
      overwriteUserParam != null &&
      !Number.isNaN(overwriteUserParam, 10) &&
      !Number.isNaN(parseInt(overwriteUserParam, 10), 10)
    ) {
      setOverwriteFile(parseInt(overwriteFileParam, 10));
      setOverwriteUser(parseInt(overwriteUserParam, 10));
    }
    if (referrerParam != null) {
      setReferrer(referrerParam);
    } else {
      setReferrer('/');
    }

    store.dispatch(loadDocument(match.params.document));

    window.addEventListener('resize', resizeEventHandler, true);
  }, [match.params.document]);

  useEffect(() => {
    if (documentData) {
      store.dispatch(loadPage(documentData, curPage));
      setRendered(false);
    }
  }, [curPage, documentData]);

  useEffect(() => {
    if (!rendered && documentData && pageData) {
      const mItems = initialMenuItems;
      if (curPage > 1) {
        mItems.previous = {
          title: t('Signature.Previous page'),
          faIcon: 'fa-backward',
          onClick: () => {
            setCurPage(curPage - 1);
          },
        };
      }
      if (curPage < documentData.getPageCount()) {
        mItems.next = {
          title: t('Signature.Next page'),
          faIcon: 'fa-forward',
          onClick: () => {
            setCurPage(curPage + 1);
          },
        };
      }
      setMenuItems(mItems);

      renderData();
    }
  }, [rendered, pageData]);

  useEffect(() => {
    if (signatureCanvasRef.current)
      signatureCanvasRef.current.off();
  }, [pageData])
  
  const toggleEditMode = () => {
    (editMode) ? signatureCanvasRef.current.off() : signatureCanvasRef.current.on();
    setEditMode((prev)=>!prev);
    
  };

  return (
    <Wrapper>
      <h1>
        <Trans i18nKey='Signature.Sign document' />
      </h1>
      <PageWithSidebar
        currentPage=''
        menuItems={menuItems}
        menuTitle={
          <h2>
            <Trans i18nKey='Signature.Actions' />
          </h2>
        }
      />

      {popup && (
        <ConfirmPopup
          text={popup.text}
          closePopup={() => setPopup(null)}
          onConfirm={popup.onConfirm}
          isOpened={popup != null}
        />
      )}

      {!pageData && <Loader />}
      {pageData && (
        <div>
          <div className={styles.signatureContainer}>
            {(editMode)?<div className={cn(styles.editButtonContainer, styles.deactiveMode)}>
              <button className={styles.editButton}  onClick={toggleEditMode}>
                 {t('Signature.clickToDeactivatePencil')}
                 <i className={'fa fa-lock'} />
              </button>
            </div>:
            <div className={cn(styles.editButtonContainer, styles.activeMode)}>
              <button className={styles.editButton}  onClick={toggleEditMode}>
                 {t('Signature.clickToActivatePencil')}
                 <i className={'fa fa-pencil'} />
              </button>
            </div>}
            <Document
              file={pageData}
              className={styles.signatureBackground}
              onLoadError={error => {
                console.log('Load error', error);
              }}
              onSourceError={error => {
                console.error('Source error', error);
              }}
              loading={<Loader />}
            >
              <Page
                ref={pageRef}
                pageNumber={1}
                width={canvasWidth}
                onLoadError={error => {
                  console.log('Load error', error);
                }}
                onRenderError={error => {
                  console.error('Source error', error);
                }}
              />
            </Document>
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor='black'
              canvasProps={{ className: styles.signatureCanvas }}
              onEnd={saveCurrentSignatureState}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
}
