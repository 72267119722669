import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Loader from 'library/common/commonComponents/Loader';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import CheckBox from 'library/common/commonComponents/Checkbox';
import RadioButton from 'library/common/commonComponents/RadioButton';
import SelectSpace from 'library/common/commonComponents/SelectSpace';
import Select from 'library/common/commonComponents/Inputs/Select';
import { getGroups } from 'library/api/groups';
import { patchSummariesConfiguration } from 'library/api/summaries';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import useEmailSummariesConfiguration from './hooks/useEmailSummariesConfiguration';

import styles from './emailSummariesFrame.module.scss';

export default function EmailSummariesFrame({ showBottomNotification }) {
  useSiteTitle('EmailSummaries');
  const { configuration, isLoading, patchConfiguration } = useEmailSummariesConfiguration();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectOptions = [
    { value: 'NEVER', label: t('AccountSettings.EmailSummariesSection.Never') },
    { value: 'HOURLY', label: t('AccountSettings.EmailSummariesSection.Hourly') },
    { value: 'DAILY', label: t('AccountSettings.EmailSummariesSection.Daily') },
    { value: 'WEEKLY', label: t('AccountSettings.EmailSummariesSection.Weekly') },
  ];

  const setIsChecked = key => () => {
    patchConfiguration({
      [key]: !configuration[key],
    });
  };

  const onRadioButtonClick = groupOperation => () => {
    patchConfiguration({ groupOperation });
  };

  const onItervalSelect = ({ value }) => {
    patchConfiguration({ mailingInterval: value });
  };

  const onSaveClick = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const { ids } = selectSpace.getGroupsAndIds();
    await patchSummariesConfiguration({ ...configuration, groups: ids });
    showBottomNotification(t('BottomNotifications.Saved'));
    setIsSubmitting(false);
  };
  const [selectSpace, setSelectSpace] = useState(null);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.description}>
            <Trans i18nKey='AccountSettings.EmailSummariesSection.E-Mail summaries are sent to inform' />
          </div>
          <div className={styles.description}>
            <Trans i18nKey='AccountSettings.EmailSummariesSection.On this page you can configure' />
          </div>
          <div className={styles.intervalContainer}>
            <div>
              <strong>
                <Trans i18nKey='AccountSettings.EmailSummariesSection.Interval' />
              </strong>
            </div>
            <Select
              data-test='email-summaries-select'
              options={selectOptions}
              onSelect={onItervalSelect}
              selected={selectOptions.find(({ value }) => value === configuration.mailingInterval)}
            />
            <div className={styles.description}>
              <Trans i18nKey='AccountSettings.EmailSummariesSection.You will only receive an e-mail' />
            </div>
          </div>

          <div className={styles.spacesContainer}>
            <div>
              <strong>
                <Trans i18nKey='AccountSettings.EmailSummariesSection.Spaces' />
              </strong>
            </div>
            <RadioButton
              data-test='radio-button-1'
              checked={configuration.groupOperation === 'EXCLUDE'}
              label={
                <Trans i18nKey='AccountSettings.EmailSummariesSection.Exclude spaces below from the mail summary' />
              }
              onClick={onRadioButtonClick('EXCLUDE')}
            />
            <RadioButton
              data-test='radio-button-2'
              checked={configuration.groupOperation === 'INCLUDE'}
              label={
                <Trans i18nKey='AccountSettings.EmailSummariesSection.Only include spaces below to the mail summary' />
              }
              onClick={onRadioButtonClick('INCLUDE')}
            />
            <div className={styles.spacesSelect}>
              <SelectSpace
                getSpaces={getSpaces}
                selectedGroups={configuration.groups}
                ref={ref => {
                  setSelectSpace(ref);
                }}
              />
            </div>
          </div>

          <div>
            <div>
              <strong>
                <Trans i18nKey='AccountSettings.EmailSummariesSection.Activities' />
              </strong>
            </div>
            <CheckBox
              className={styles.checkbox}
              data-test='checkbox-1'
              name={<Trans i18nKey='AccountSettings.EmailSummariesSection.Comments - Whenever' />}
              isChecked={configuration.comments}
              onChange={setIsChecked('comments')}
            />
            <CheckBox
              className={styles.checkbox}
              name={<Trans i18nKey='AccountSettings.EmailSummariesSection.Contents - Whenever' />}
              isChecked={configuration.contents}
              onChange={setIsChecked('contents')}
            />
            <CheckBox
              className={styles.checkbox}
              name={<Trans i18nKey='AccountSettings.EmailSummariesSection.Likes - Whenever' />}
              isChecked={configuration.likes}
              onChange={setIsChecked('likes')}
            />
          </div>
          <br />

          <ButtonWithLoader type='primary' onClick={onSaveClick} isLoading={isSubmitting}>
            <Trans i18nKey='AccountSettings.NotificationsSection.Save' />
          </ButtonWithLoader>
        </>
      )}
    </div>
  );
}

export async function getSpaces() {
  const newSpaces = {};
  const { data } = await getGroups();
  if (data) {
    data.forEach(space => {
      newSpaces[space.id] = space;
    });
  }
  return newSpaces;
}
