import { useState } from 'react';

export default function useSorting(name) {
  const [sort, setSort] = useState({
    name,
    direction: 'asc',
  });

  const setSorting = newName => setSortingHandler({ name: newName, sort, setSort });

  return { sort, setSorting };
}

export function setSortingHandler({ name, sort, setSort }) {
  if (sort.name === name) {
    setSort({
      name,
      direction: sort.direction === 'asc' ? 'desc' : 'asc',
    });
  } else {
    setSort({
      name,
      direction: 'asc',
    });
  }
}
