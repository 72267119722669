import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getMenuWithOrdersForAnyGroup, getSettings } from 'library/api/foodOrder';
import { getAllKidsFromSelectedKita } from 'library/api/observationSheet';
import {
  getChildrenByUserId,
  postKidAbsence,
  postMemberAbsence,
  putKidAbsence,
  putMemberAbsence,
} from 'library/api/user';
import { getKitaFields } from 'library/api/kitaFields';
import { absenceReportedSignal } from 'library/common/commonActions/absenceReportActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Select from 'library/common/commonComponents/Inputs/Select';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Label from 'library/common/commonComponents/Label';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import { formatDate } from 'library/utilities/date';
import { formatDate as formatFoodDate } from 'library/utilities/foodOrder';
import store from 'main/store/configureStore';
import moment from 'moment/min/moment-with-locales';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from './absenceFrame.module.scss';

export default function AbsenceFrame({
  kitaId,
  user,
  showBottomNotification,
  closeAbsencesPopUp,
  editItem,
  durationNumber,
  refreshHistory,
  memberSelectable = false,
}) {
  const { t } = useTranslation();

  const durations = [
    { value: 1, label: t('AccountSettings.AbsenceSettings.Only today') },
    { value: 2, label: t('AccountSettings.AbsenceSettings.Full day') },
    { value: 3, label: t('AccountSettings.AbsenceSettings.Multiple days') },
  ];

  const [kids, setKids] = useState([]);
  const [formChild, setFormChild] = useState(null);
  const [formDuration, setFormDuration] = useState(durations[0]);
  const [formFrom, setFormFrom] = useState(new Date());
  const [formFromTime, setFormFromTime] = useState(new Date());
  const [formToTime, setFormToTime] = useState(new Date());
  const [formTo, setFormTo] = useState(new Date());
  const [formReason, setFormReason] = useState('');
  const [formSickness, setFormSickness] = useState('');
  const [formSicknessCancellable, setFormSicknessCancellable] = useState(false);
  const [formIsContagious, setFormIsContagious] = useState(false);
  const [popup, setPopup] = useState(0);
  const [errors, setErrors] = useState({});
  const [foodConfig, setFoodConfig] = useState(null);
  const [foodOrders, setFoodOrders] = useState([]);
  const [cancellableFoodOrders, setCancellableFoodOrders] = useState([]);
  const [cancelFoodOrders, setCancelFoodOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formVacation, setFormVacation] = useState(false);
  const [newToEdit, setNewToEdit] = useState(new Date());
  const [newFromEdit, setNewFromEdit] = useState(new Date());
  const [tomorrow, setTomorrow] = useState(new Date());
  const [sicknesses, setSicknesses] = useState('');
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setTomorrow(new Date(Date.now() + 3600 * 1000 * 24));
    if (editItem) {
      const kid = {
        value: editItem.kid.id,
        label: editItem.kid.firstName + ' ' + editItem.kid.lastName,
        groupId: editItem.kidGroup ? editItem.kidGroup.id : null,
        isMember: !!editItem.kid.isMember,
      };
      setFormChild(kid);
      setFormDuration(durations[durationNumber]);
      setFormFrom(new Date(editItem.startDate));
      setFormFromTimeHandler(new Date(editItem.startDate));
      setFormToTimeHandler(new Date(editItem.endDate));
      setFormTo(new Date(editItem.endDate));
      setFormVacation(editItem.vacation);
      setFormReason(editItem.reason);
      setFormSickness(editItem.sickness);
      setNewFromEdit(new Date(editItem.startDate));
      setNewToEdit(new Date(editItem.endDate));
    }

    function setForm(kidsArray) {
      // if only one child prefill the form
      if (kidsArray.length === 1) {
        setFormChild(kidsArray[0]);
      }

      setKids(kidsArray);
    }

    // show all kids when user is employee or admin, otherwise only kids user has added
    if (user.employee || user.administrationAccess) {
      getAllKidsFromSelectedKita().then(res => {
        const kidsArray = res.data.map(item => {
          return {
            value: item.kidId,
            label: item.firstName + ' ' + item.lastName,
            groupId: item.groupId,
          };
        });
        if (memberSelectable) {
          kidsArray.unshift({
            isMember: true,
            value: user.id,
            label: user.firstName + ' ' + user.lastName,
          });
        }
        setForm(kidsArray);
      });
    } else {
      getChildrenByUserId(false).then(res => {
        let kidsArray = res.data.filter(item => item.deactivated == false);
        kidsArray = kidsArray.map(item => {
          return {
            value: item.id,
            label: item.firstName + ' ' + item.lastName,
            groupId: item.kidGroup ? item.kidGroup.id : null,
          };
        });
        if (memberSelectable) {
          kidsArray.unshift({
            isMember: true,
            value: user.id,
            label: user.firstName + ' ' + user.lastName,
          });
        }
        setForm(kidsArray);
      });
    }

    getKitaFields(kitaId).then(res => {
      setSicknesses(res.data.sicknesses);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadFoodOrders = () => {
    if (!formChild || !formChild.groupId || !foodConfig) {
      return;
    }

    getMenuWithOrdersForAnyGroup(
      formatFoodDate(formFrom),
      formatFoodDate(formTo),
      formChild.value,
    ).then(res => {
      const orders = res.data.foodMenus.filter(
        m => m.foods && m.foods.filter(f => f.ordered).length > 0,
      );
      const now = new Date();

      const lastCancelTime = moment(foodConfig.lastCancelFoodOrderTime, 'HH:mm:ss');
      const lastCancelDateTime = new Date();
      lastCancelDateTime.setHours(lastCancelTime.get('hour'));
      lastCancelDateTime.setMinutes(lastCancelTime.get('minute'));
      lastCancelDateTime.setSeconds(0);
      const dateStringNow = formatFoodDate(now);

      const cancellable = orders.filter(
        m => m.date !== dateStringNow || now.getTime() <= lastCancelDateTime.getTime(),
      );

      setFoodOrders(orders);
      if (kitaId === 1327 || kitaId === 2497) {
        setFoodOrders([]);
        setCancellableFoodOrders([]);
      } else {
        setFoodOrders(orders);
        setCancellableFoodOrders(cancellable);
      }
    });
  };

  useEffect(() => {
    // (re)load food config to find out until when cancelling is possible
    if (!formChild || !formChild.groupId || formChild.isMember || formChild.isAllKids) {
      return;
    }

    getSettings(formChild.groupId).then(res => {
      setFoodConfig(res.data);
    });
  }, [formChild]);

  useEffect(() => {
    cancellableFoodOrders.forEach(foodOrder => {
      getSettings(foodOrder.groupId).then(res => {
        if (res.data.sicknessCancelsOrders) {
          setFormSicknessCancellable(true);
          return;
        }
      });
    });
  }, [cancellableFoodOrders]);

  useEffect(() => {
    reloadFoodOrders();
  }, [formFrom, formTo, foodConfig]);

  useEffect(() => {
    if (formDuration.value === 1) {
      formFromTime.setHours(8, 0, 0, 0);
      formToTime.setHours(18, 0, 0, 0);
    }
  }, [formDuration, formFromTime]);

  const onDateChange = data => {
    switch (data.value) {
      case 1:
        setFormFrom(new Date());
        setFormFromTime(new Date());
        setFormToTime(new Date());
        setFormTo(new Date());
        break;
      case 2:
        setFormTo(formFrom);
        break;

      default:
        break;
    }
    setFormDuration(data);
  };

  const onEditDateChange = data => {
    setFormDuration(data);
  };

  const saveData = () => {
    let error = false;
    const errorsTmp = {};
    setErrors({});
    if (!formReason && !formVacation) {
      errorsTmp.reason = t('AccountSettings.AbsenceSettings.This field is required');
      error = true;
    }
    if (formChild === null) {
      errorsTmp.child = t('AccountSettings.AbsenceSettings.This field is required');
      error = true;
    }
    if (formDuration.value === 1) {
      const currentTime = new Date().getHours();
      if (currentTime >= 24) {
        const tommorow = new Date();
        tommorow.setDate(tommorow.getDate() + 1);
        setFormFrom(tommorow);
        setFormTo(tommorow);
      }
    }
    if (formFrom > formTo) {
      errorsTmp.to = t('AccountSettings.AbsenceSettings.End date can not be less then start');
      error = true;
    }
    setErrors(errorsTmp);
    if (!error) {
      if (formVacation) {
        editItem ? sendFormtoEdit() : sendForm();
      } else {
        setPopup(1);
      }
    }
  };

  const setFormFromHandler = data => {
    if (formDuration.value === 2) {
      setFormTo(data);
    }
    setFormFrom(data);
  };

  const setFormFromTimeHandler = data => {
    setFormFromTime(data);
  };

  const setFormToTimeHandler = data => {
    setFormToTime(data);
  };

  const resetForm = () => {
    setFormChild(kids[0]);
    setFormDuration(durations[0]);
    setFormFrom(new Date());
    setFormFromTime(new Date());
    setFormTo(new Date());
    setFormReason('');
    setFormSickness('');
    setFormSicknessCancellable(false);
    setFormIsContagious(false);
  };

  const sendFormByRow = async entity => {
    const data = {
      contagious: formIsContagious,
      startDate: formatDate(formFrom, formFromTime),
      endDate: formatDate(formTo, formToTime),
      kidId: entity.value,
      reason: formReason,
      sickness: formSickness,
      cancelFoodOrders:
        foodConfig &&
        foodConfig.sicknessCancelsOrders &&
        (cancelFoodOrders || formDuration.value === 1),
      groupId: entity.groupId,
      vacation: formVacation,
    };
    if (entity.isMember) {
      data.userId = entity.value;
    }
    const postMethod = entity.isMember ? postMemberAbsence : postKidAbsence;

    postMethod(data);
  };

  const sendForm = () => {
    setIsLoading(true);
    if (formChild.isAllKids) {
      const promises = kids
        .filter(kid => !kid.isAllKids && !kid.isMember)
        .map(kid => sendFormByRow(kid));

      Promise.all(promises).then(() => {
        store.dispatch(absenceReportedSignal());
        showBottomNotification(t('AccountSettings.AbsenceSettings.Report was sent'));
        dispatchEvent(new Event('onAbsenceCreated'));
        resetForm();
        setPopup(0);
        setIsLoading(false);
        if (closeAbsencesPopUp) {
          closeAbsencesPopUp(true);
        }
      });
      return;
    }

    sendFormByRow(formChild).then(() => {
      store.dispatch(absenceReportedSignal());
      showBottomNotification(t('AccountSettings.AbsenceSettings.Report was sent'));
      dispatchEvent(new Event('onAbsenceCreated'));
      resetForm();
      setPopup(0);
      setIsLoading(false);
      if (closeAbsencesPopUp) {
        closeAbsencesPopUp(true);
      }
    });
  };

  const sendFormtoEdit = () => {
    let startDate = formDuration.value === 2 ? newFromEdit : formFrom;
    if (formDuration.value === 3 && editItem?.vacation) {
      startDate = newFromEdit;
    }

    const data = {
      id: editItem.id,
      contagious: formIsContagious,
      startDate: formatDate(startDate, formFromTime),
      endDate:
        formDuration.value === 2
          ? formatDate(newFromEdit, formToTime)
          : formatDate(newToEdit, formToTime),
      reason: formReason,
      sickness: formSickness,
      cancelFoodOrders: cancelFoodOrders || formDuration.value === 1,
      groupId: formChild.groupId,
      vacation: formVacation,
    };
    if (formChild.isMember) {
      data.userId = editItem.kid.id;
    } else {
      data.kidId = editItem.kid.id;
    }
    setIsLoading(true);
    const putMethod = formChild.isMember ? putMemberAbsence : putKidAbsence;
    putMethod(data).then(() => {
      dispatchEvent(new Event('onAbsenceCreated'));
      resetForm();
      setPopup(0);
      refreshHistory();
      setIsLoading(false);
      if (closeAbsencesPopUp) {
        closeAbsencesPopUp(true);
      }
    });
  };

  const allKidsSick = () => {
    const all = kids.find(kid => kid.isAllKids === true);
    if (all) {
      setFormChild(all);
      return;
    }
    const newArray = [...kids];
    newArray.unshift({
      isAllKids: true,
      value: 'all',
      label: 'Alle Kinder',
    });
    setKids(newArray);
    setFormChild(newArray[0]);
  };

  if (!(kids.length || memberSelectable)) {
    return (
      <div className={styles.wrapper}>{t('AccountSettings.AbsenceSettings.You have no kids')}</div>
    );
  }

  const handleSubmit = () => {
    setIsOpened(false);
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <>
      <Popup
        isOpened={isOpened}
        closePopup={handleClose}
        size='extraSmall'
        header={t('Abscence.Which day should the abscence be recorded ')}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button onClick={() => handleSubmit('today')}>{t('Absence.today')}</Button>
            </div>
            <div className={styles.buttonSend}>
              <ButtonWithLoader
                type='primary'
                onClick={() => handleSubmit('tomorow')}
                className={styles.buttonWithLoader}
              >
                {t('Absence.tomorrow')}
              </ButtonWithLoader>
            </div>
          </div>
        }
      />
      <div className={styles.wrapper}>
        {kids.filter(kid => !kid.isMember).length && !editItem ? (
          <div className={styles.allKids}>
            <Button
              className={styles.saveButton}
              onClick={() => allKidsSick()}
              type='primary'
              disabled={
                isLoading ||
                formChild?.isAllKids === true ||
                (user.employee || user.administrationAccess)
              }
            >
              Alle krankmelden
            </Button>
          </div>
        ) : (
          <></>
        )}
        <div className={styles.inputChild}>
          <Label className={styles.label} type='input'>
            {t('AccountSettings.AbsenceSettings.Choose a child')}
          </Label>
          <Select
            options={kids}
            onSelect={data => setFormChild(data)}
            disabled={!!editItem}
            placeholder={t('AccountSettings.AbsenceSettings.Choose child')}
            selected={formChild}
            error={errors.child}
          />
          <InputErrorMsg errorMsg={errors.child} />
        </div>
        <div className={styles.inputDuration}>
          <Label className={styles.label} type='input'>
            {t('AccountSettings.AbsenceSettings.Duration select')}
          </Label>
          <Select
            options={durations}
            onSelect={editItem ? data => onEditDateChange(data) : data => onDateChange(data)}
            selected={formDuration}
          />
        </div>
        <div className={styles.dateWrapper}>
          {formDuration.value === 2 && (
            <div className={styles.dateStartWrapper}>
              <div className={styles.dateStart}>
                <Label className={styles.label} type='input'>
                  {t('AccountSettings.AbsenceSettings.From')}
                </Label>
                <DatePicker
                  selected={formFrom}
                  onChange={data => setFormFromHandler(data)}
                  minDate={new Date(Date.now() - 20 * 24 * 3600 * 1000)}
                  disabled={!!editItem}
                  // selectsStart
                  // startDate={formFrom}
                  // endDate={formTo}
                  langCode={user.langCode}
                />
              </div>
              <div className={styles.timeStart}>
                <Label className={styles.label} type='input'>
                  {t('AccountSettings.AbsenceSettings.From time')}
                </Label>
                <DatePicker
                  selected={formFromTime}
                  onChange={data => setFormFromTimeHandler(data)}
                  disabled={!!editItem}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  timeFormat='HH:mm'
                />
              </div>
              <div className={styles.timeStart}>
                <Label className={styles.label} type='input'>
                  {t('AccountSettings.AbsenceSettings.To time')}
                </Label>
                <DatePicker
                  selected={formToTime}
                  onChange={data => setFormToTimeHandler(data)}
                  disabled={!!editItem}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  timeFormat='HH:mm'
                />
              </div>
              {editItem && (
                <div className={styles.dateStart}>
                  <Label className={styles.label} type='input'>
                    {t('AccountSettings.AbsenceSettings.From')}
                  </Label>
                  <DatePicker
                    selected={newFromEdit}
                    onChange={data => setNewFromEdit(data)}
                    minDate={tomorrow}
                    // selectsStart
                    // startDate={formFrom}
                    // endDate={formTo}
                    langCode={user.langCode}
                  />
                </div>
              )}
            </div>
          )}
          {formDuration.value === 3 && (
            <React.Fragment>
              <div className={styles.dateStartWrapper}>
                <div className={styles.dateStart}>
                  <Label className={styles.label} type='input'>
                    {t('AccountSettings.AbsenceSettings.From')}
                  </Label>
                  <DatePicker
                    selected={formFrom}
                    onChange={data => setFormFromHandler(data)}
                    disabled={!!editItem}
                    minDate={new Date(Date.now() - 20 * 24 * 3600 * 1000)}
                    selectsStart
                    startDate={formFrom}
                    endDate={formTo}
                    langCode={user.langCode}
                  />
                </div>
                <div className={styles.timeStart}>
                  <Label className={styles.label} type='input'>
                    {t('AccountSettings.AbsenceSettings.From time')}
                  </Label>
                  <DatePicker
                    selected={formFromTime}
                    onChange={data => setFormFromTimeHandler(data)}
                    disabled={!!editItem}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='Time'
                    timeFormat='HH:mm'
                  />
                </div>
              </div>
              <div className={styles.dateEndWrapper}>
                <div className={styles.dateEnd}>
                  <Label className={styles.label} type='input'>
                    {t('AccountSettings.AbsenceSettings.To')}
                  </Label>
                  <DatePicker
                    selected={formTo}
                    selectsEnd
                    onChange={data => setFormTo(data)}
                    disabled={!!editItem}
                    minDate={formFrom}
                    startDate={formFrom}
                    endDate={formTo}
                    langCode={user.langCode}
                  />
                </div>
              </div>
              {editItem && (
                <div className={styles.editAbsenseMessageWrapper}>
                  <hr className={styles.divider} />
                  <Label className={styles.label} type='input'>
                    {t('AccountSettings.AbsenceSettings.Edit Absence Message')}
                  </Label>
                  <div className={styles.dateEndWrapper}>
                    {editItem && editItem.vacation && (
                      <div className={styles.dateStart}>
                        <Label className={styles.label} type='input'>
                          {t('AccountSettings.AbsenceSettings.From')}
                        </Label>
                        <DatePicker
                          selected={newFromEdit}
                          onChange={data => setNewFromEdit(data)}
                          minDate={tomorrow}
                          selectsStart
                          startDate={newFromEdit}
                          endDate={newToEdit}
                          langCode={user.langCode}
                        />
                      </div>
                    )}
                    {editItem && (
                      <div className={styles.dateEnd}>
                        <Label className={styles.label} type='input'>
                          {t('AccountSettings.AbsenceSettings.To')}
                        </Label>
                        <DatePicker
                          selected={newToEdit}
                          selectsEnd
                          onChange={data => setNewToEdit(data)}
                          minDate={formFrom > new Date() ? formFrom : tomorrow}
                          startDate={newFromEdit}
                          endDate={newToEdit}
                          langCode={user.langCode}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <InputErrorMsg errorMsg={errors.to} />
            </React.Fragment>
          )}
          {foodConfig &&
            foodConfig.sicknessCancelsOrders &&
            (formDuration.value === 2 || formDuration.value === 3) &&
            cancellableFoodOrders.length > 0 && (
              <Checkbox
                name={t('KidAbsence.CancelFoodOrders')}
                isChecked={cancelFoodOrders}
                onChange={() => {
                  setCancelFoodOrders(!cancelFoodOrders);
                }}
              />
            )}
        </div>
        <div>
          <Checkbox
            isChecked={formVacation}
            onChange={() => setFormVacation(!formVacation)}
            name={t('AccountSettings.AbsenceSettings.Vacation')}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <Label className={styles.label} type='input'>
            {t('AccountSettings.AbsenceSettings.Reason')}
          </Label>
          <TextArea
            error={errors.reason}
            value={formReason}
            onChange={e => setFormReason(e.target.value)}
            maxLength={500}
          />
          <div style={formReason.length === 500 ? { color: 'red' } : {}}>
            {formReason.length}/500 {t('AccountSettings.Characters')}
          </div>
          <InputErrorMsg errorMsg={errors.reason} />
        </div>
        {foodConfig && foodConfig.sicknessCancelsOrders && foodOrders.length > 0 && (
          <div style={{ marginTop: '2em', marginBottom: '1em' }}>
            <p>
              {t('KidAbsence.FoodOrderCancelOrderAmount').replace(
                '{orderAmount}',
                `${foodOrders.length}`,
              )}
            </p>
            {cancellableFoodOrders.length !== foodOrders.length && (
              <p>
                {t('KidAbsence.FoodOrderCancelUncancellableAmount').replace(
                  '{uncancellableAmount}',
                  `${foodOrders.length - cancellableFoodOrders.length}`,
                )}
              </p>
            )}
            {!formSicknessCancellable ||
              (!cancelFoodOrders &&
                formDuration.value !== 1 &&
                cancellableFoodOrders.length > 0 && (
                  <p style={{ color: 'red', marginTop: '1em' }}>
                    {t('KidAbsence.FoodOrderCancelDisabled').replace(
                      '{checkboxText}',
                      t('KidAbsence.CancelFoodOrders'),
                    )}
                  </p>
                ))}
            {formSicknessCancellable &&
              (cancelFoodOrders || formDuration.value === 1) &&
              cancellableFoodOrders.length > 0 && (
                <p style={{ color: 'green', marginTop: '1em' }}>
                  {t('KidAbsence.FoodOrderCancelEnabled')}
                </p>
              )}
          </div>
        )}
        {!editItem && (
          <>
            <div className={styles.footer}>
              <Button
                className={styles.saveButton}
                onClick={() => saveData()}
                type='primary'
                disabled={isLoading}
              >
                {t('AccountSettings.AbsenceSettings.Save')}
              </Button>
              <Button
                onClick={() => {
                  resetForm();
                  closeAbsencesPopUp();
                }}
                type='danger'
                disabled={isLoading}
              >
                {t('AccountSettings.AbsenceSettings.Cancel')}
              </Button>
            </div>
            {/* display loader when form was sent and it's vacation so no additional popup will be displayed */}
            {isLoading && formVacation && (
              <>
                <br />
                <Loader />
              </>
            )}
          </>
        )}
        {editItem && (
          <div className={styles.footer}>
            <Button className={styles.saveButton} onClick={() => saveData()} type='primary'>
              {t('AccountSettings.AbsenceSettings.Save')}
            </Button>
            <Button
              onClick={() => {
                resetForm();
                closeAbsencesPopUp();
              }}
              type='danger'
            >
              {t('AccountSettings.AbsenceSettings.Cancel')}
            </Button>
          </div>
        )}
        <Popup
          isOpened={popup === 1}
          closePopup={() => setPopup(0)}
          footer={
            !isLoading ? (
              <div className={styles.buttonsContainer}>
                <div className={styles.buttonSubmit}>
                  <Button onClick={() => setPopup(2)} type='primary'>
                    {t('AccountSettings.AbsenceSettings.Yes')}
                  </Button>
                </div>
                <div className={styles.buttonSubmit}>
                  <Button onClick={editItem ? sendFormtoEdit : sendForm} type='primary'>
                    {t('AccountSettings.AbsenceSettings.No')}
                  </Button>
                </div>
                <div className={styles.buttonCancel}>
                  <Button onClick={editItem ? sendFormtoEdit : sendForm}>
                    {t('AccountSettings.AbsenceSettings.No feedback')}
                  </Button>
                </div>
              </div>
            ) : (
              <Loader />
            )
          }
        >
          <div className={styles.containerEnable}>
            {formChild && formChild.isMember
              ? 'Sind Sie krank?'
              : t('AccountSettings.AbsenceSettings.Is your child sick?')}
          </div>
        </Popup>
        <Popup
          isOpened={popup === 2}
          closePopup={() => setPopup(0)}
          footer={
            !isLoading ? (
              <div className={styles.buttonsContainer}>
                <div className={styles.buttonSubmit}>
                  <Button
                    onClick={() => {
                      setPopup(3);
                      setFormIsContagious(true);
                    }}
                    type='primary'
                  >
                    {t('AccountSettings.AbsenceSettings.Contagiouns')}
                  </Button>
                </div>
                <div className={styles.buttonCancel}>
                  <Button onClick={editItem ? sendFormtoEdit : sendForm}>
                    {t('AccountSettings.AbsenceSettings.No')}
                  </Button>
                </div>
              </div>
            ) : (
              <Loader />
            )
          }
        >
          <div className={styles.containerEnable}>
            <div>{t('AccountSettings.AbsenceSettings.Is sickness contagiouns?')}</div>
            {sicknesses && <div>{sicknesses}</div>}
          </div>
        </Popup>
        <Popup
          isOpened={popup === 3}
          closePopup={() => setPopup(0)}
          header={t('AccountSettings.AbsenceSettings.Sickness description')}
          footer={
            !isLoading ? (
              <div className={styles.buttonsContainer}>
                <div className={styles.buttonSubmit}>
                  <Button onClick={editItem ? sendFormtoEdit : sendForm} type='primary'>
                    {t('AccountSettings.AbsenceSettings.Done')}
                  </Button>
                </div>
              </div>
            ) : (
              <Loader />
            )
          }
        >
          <div className={styles.containerEnable}>
            <TextArea
              value={formSickness}
              onChange={e => setFormSickness(e.target.value)}
              maxLength={500}
            />
            <div style={formSickness.length === 500 ? { color: 'red' } : {}}>
              {formSickness.length}/500 {t('AccountSettings.Characters')}
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
}
