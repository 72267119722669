import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import Gallery from '../Gallery';

export const mapStateToProps = state => ({
  activeKita: state.kitaReducer.activeKita,
  user: state.userReducer,
  modules: state.kitaReducer.modules,
  isBazaar: true,
});

export default connect(
  mapStateToProps,
  dispatch => ({ showBottomNotification: (...args) => dispatch(showBottomNotification(...args)) }),
)(Gallery);
