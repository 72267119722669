import React, { useEffect, useState, useContext } from 'react';
import { Trans } from 'react-i18next';

import { GroupContext } from 'modules/Group/groupModule';
import Card from 'library/common/commonComponents/Card';
import Tabs from 'library/common/commonComponents/Tabs';

// eslint-disable-next-line
import GroupBodySecurityGeneral from './GroupBodySecurityFrames/GroupBodySecurityGeneral/GroupBodySecurityGeneralContainer';
import GroupBodySecurityPermissions from './GroupBodySecurityFrames/GroupBodySecurityPermissions';

import styles from './groupBodySecurity.module.scss';

export default function GroupBodySecurity() {
  const { reloadGroupInfo } = useContext(GroupContext);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    return () => {
      reloadGroupInfo();
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    {
      title: <Trans i18nKey='GroupBody.Security.General' />,
      path: 'general',
      component: <GroupBodySecurityGeneral />,
    },
    {
      title: <Trans i18nKey='GroupBody.Security.Permissions' />,
      path: 'permissions',
      component: <GroupBodySecurityPermissions />,
    },
  ];

  return (
    <Card title={<Trans i18nKey='GroupBody.Security.Title' />} className={styles.body}>
      <Tabs
        tabs={tabs}
        onChange={(_, index) => setActiveTab(index)}
        currentTab={tabs[activeTab].path}
      />
    </Card>
  );
}
