import React, { useState } from 'react';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { exportActivity } from 'library/api/user';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import { Trans, useTranslation } from 'react-i18next';
import styles from './profileGdpr.module.scss';

export default function ProfileGdpr() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const exportData = () => {
    setIsLoading(true);
    exportActivity()
      .then(() => {
        setIsPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={styles.wrapper}>
      {t('AccountSettings.Profile.gdpr.Export all your activity')}
      <ul>
        <li>{t('AccountSettings.Profile.gdpr.Post')}</li>
        <li>{t('AccountSettings.Profile.gdpr.Comments')}</li>
        <li>{t('AccountSettings.Profile.gdpr.Messages')}</li>
        <li>{t('AccountSettings.Profile.gdpr.Tasks')}</li>
        <li>{t('AccountSettings.Profile.gdpr.Calendar')}</li>
        <li>{t('AccountSettings.Profile.gdpr.Uploaded files')}</li>
        <li>{t('AccountSettings.Profile.gdpr.Images')}</li>
        <li>{t('AccountSettings.Profile.gdpr.Videos')}</li>
      </ul>
      <ButtonWithLoader
        className={styles.exportButton}
        onClick={exportData}
        type='primary'
        isLoading={isLoading}
      >
        {t('AccountSettings.Profile.gdpr.Export')}
      </ButtonWithLoader>

      <Popup
        isOpened={isPopup}
        closePopup={() => setIsPopup(false)}
        header={t('AccountSettings.Profile.gdpr.Success')}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button onClick={() => setIsPopup(false)} type='primary'>
                {t('AccountSettings.Profile.gdpr.Close')}
              </Button>
            </div>
          </div>
        }
      >
        <div className={styles.modalBody}>
          <Trans i18nKey='AccountSettings.Profile.gdpr.We have got the request to export your file' />
        </div>
      </Popup>
    </div>
  );
}
