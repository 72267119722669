import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';
import DeleteButton from 'library/common/commonComponents/Buttons/DeleteButton';

import styles from './customQuestion.module.scss';

export default function CustomQuestion({ index, totalQuestionLength, questionInfo, dispatchQuestion }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.deleteButton}>
        <DeleteButton onClick={deleteQuestion(questionInfo?.id, dispatchQuestion)} />
        <button
          className={styles.arrowIconButton}
          disabled={index === totalQuestionLength - 1}
          onClick={changeOptionOrder(questionInfo?.id, 'moveDown', dispatchQuestion)}
        >
          <i className='fa fa-arrow-down' />
        </button>
        <button
          className={styles.arrowIconButton}
          disabled={index === 0}
          onClick={changeOptionOrder(questionInfo?.id,'moveUp', dispatchQuestion)}
        >
          <i className='fa fa-arrow-up' />
        </button>
      </div>
      <div className={styles.input}>
        <Input
          placeholder={t('Surveys.Ask something')}
          value={questionInfo?.question}
          className={styles.input}
          onChange={e =>
            dispatchQuestion({
              type: 'updateQuestion',
              questionId: questionInfo?.id,
              question: e.target.value,
            })
          }
          maxLength={255}
        />
      </div>
      {!questionInfo?.allowUserAddSuggestionStatus && questionInfo?.options?.map((option, index) => (
        <Input
          key={option.id}
          value={option.answer}
          placeholder={t('Surveys.Add answer')}
          className={styles.input}
          onChange={e =>
            dispatchQuestion({
              type: 'updateOption',
              questionId: questionInfo?.id,
              optionId: option.id,
              answer: e.target.value,
            })
          }
          maxLength={255}
          faIcon={getFaIcon(index, questionInfo?.options.length)}
          onFaIconClick={getOnFaIconClick(
            index,
            option.id,
            questionInfo?.id,
            questionInfo?.options.length,
            dispatchQuestion,
          )}
        />
      ))}
      <div className={styles.checkboxes}>
        <Checkbox
          name={t('Surveys.Multiple answers per user')}
          isChecked={questionInfo?.multipleAnswerStatus}
          onChange={() =>
            dispatchQuestion({
              type: 'updateMultipleAnswerStatus',
              questionId: questionInfo?.id,
              value: !questionInfo?.multipleAnswerStatus,
            })
          }
        />
        <Checkbox
          name={t('Surveys.Allow user to add suggestions')}
          isChecked={questionInfo?.allowUserAddSuggestionStatus}
          onChange={() =>
            dispatchQuestion({
              type: 'updateAllowUserAddSuggestionStatus',
              questionId: questionInfo?.id,
              value: !questionInfo?.allowUserAddSuggestionStatus,
            })
          }
        />
      </div>
      <br />
    </>
  );
}

export function getFaIcon(index, length) {
  return index === length - 1 ? 'fa-plus' : 'fa-trash';
}

export function deleteQuestion(questionId, dispatch) {
  return () =>
    dispatch({
      type: 'deleteQuestion',
      questionId,
    });
}

export function getOnFaIconClick(index, optionId, questionId, length, dispatch) {
  const type = index === length - 1 ? 'addOption' : 'deleteOption';
  return () =>
    dispatch({
      type,
      optionId,
      questionId,
    });
}

export function changeOptionOrder(questionId, direction, dispatch) {
  return () =>
    dispatch({
      type: 'moveQuestion',
      direction,
      questionId,
    });
}
