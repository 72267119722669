import { getActiveKitaAdmins } from '../../library/api/user.js';
import { onGfzFormSubmitByParent, onGfzFormSubmitByAdmin } from '../../library/api/observationSheet.js';

export const onGfzFormSubmitted = (t, kid, formId, user, formType, pdf) => {
  const kitaId = -1;
  if (kid && kid !== [] && user.kids && user.kids.find(k => k.id === kid.id)) {
    onGfzFormSubmitByParent(kid.id, formId, formType, pdf);
  } else {
    onGfzFormSubmitByAdmin(kid.id, formId, formType, pdf);
  }
};
