import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { format } from 'date-fns';
import { de, enGB } from 'date-fns/locale';


import { useDropdown } from 'library/common/commonHooks/useDropdown';

import TimeSelectionDropdown from './TimeSelectionDropdown';
import styles from './publicationTimeButton.module.scss';
import './datepicker.scss';

export default function PublicationTimeButton({ className, value, onChange, langCode }) {
  const { t } = useTranslation();
  const { isOpened, DropdownWrapper, closeDropdown } = useDropdown(TimeSelectionDropdown);

  return (
    <div className={cn(styles.publication, className)}>
      <DropdownWrapper
        render={dropdown => (
          <div className={cn(styles.dropdownContainer)}>
            <div className={cn(styles.button, { [styles.active]: isOpened })} role='button'>
              <div className={styles.buttonContainer}>
                <i className={cn('fa fa-clock-o', styles.clockIcon)} />
                {value && (
                  <span className={styles.buttonValue}>
                    {format(new Date(value), 'do MMM, HH:mm', { locale : langCode === 'English' || langCode === 'en' ? enGB : de })}
                  </span>
                )}
              </div>
            </div>
            {dropdown}
          </div>
        )}
        value={value}
        onApply={datetime => {
          onChange(datetime);
          requestAnimationFrame(closeDropdown);
        }}
      />
    </div>
  );
}
