import React from 'react';

import styles from './textCaret.module.scss';

export default function TextCaret({ children, color }) {
  return (
    <div className={styles.textCaret} style={{ borderTopColor: color }}>
      {children}
    </div>
  );
}
