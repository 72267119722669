import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../../../../../library/common/commonComponents/Popups/Popup';
import Button from '../../../../../../../../library/common/commonComponents/Buttons/Button';
import styles from './EditFormComponentPopup.module.scss';
import HeaderParagraphEditView from './EditFormComponentPopupFrames/HeaderParagraphEditView';
import InputEditView from './EditFormComponentPopupFrames/InputEditView';
import CheckboxEditView from './EditFormComponentPopupFrames/CheckboxEditView';
import DropdownEditView from './EditFormComponentPopupFrames/DropdownEditView';
import ImageEditView from './EditFormComponentPopupFrames/ImageEditView';
import { deleteTemplate, saveTemplate } from '../../../../../../../../library/api/formEditor';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import DeletePopup from '../../../../../../../../library/common/commonComponents/Popups/DeletePopup';
import ConfirmPopup from '../../../../../../../../library/common/commonComponents/Popups/ConfirmPopup';
import TemplateEditPopup from './EditFormComponentPopupFrames/TemplateEditPopup';
import DoubleInputEditView from './EditFormComponentPopupFrames/DoubleInputEditView';
import DoubleDropdownEditView from './EditFormComponentPopupFrames/DoubleDropdownEditView';
import TableBookingTimesEditView from './EditFormComponentPopupFrames/TableBookingTimesEditView';
import TableParentalContributionEditView from './EditFormComponentPopupFrames/TableParentalContributionEditView';
import TableParentalContributionWithHolidaysEditView from './EditFormComponentPopupFrames/TableParentalContributionWithHolidaysEditView';

export default function EditFormComponentPopup({
  closePopup,
  editComponent,
  index,
  onComponentSave,
  templates,
  reloadTemplates,
  existingNames,
  pages,
  activeKita,
  formFields,
}) {
  const { t } = useTranslation();

  const [component, setComponent] = useState(null);
  const [derivedTemplates, setDerivedTemplates] = useState([]);
  const [errors, setErrors] = useState([]);

  const [showEditTemplate, setShowEditTemplate] = useState(false);
  const [editTemplateId, setEditTemplateId] = useState(0);
  const [editTemplateName, setEditTemplateName] = useState('');
  const [editTemplateTags, setEditTemplateTags] = useState([]);

  const [showTemplateList, setShowTemplateList] = useState(false);

  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [isDeletingTemplate, setIsDeletingTemplate] = useState(false);

  const [showTemplateSaveConfirm, setShowTemplateSaveConfirm] = useState(false);

  const [existingTags, setExistingTags] = useState([]);

  const trySave = () => {
    if (validateComponent()) {
      if (derivedTemplates && derivedTemplates.length > 0) {
        setShowTemplateSaveConfirm(true);
      } else {
        saveComponent(fixDataTypes(component));
      }
    }
  };

  const fixDataTypes = c => {
    const fixedTypesComponent = {
      ...c,
      indent: parseInt(c.indent, 10),
    };

    if (fixedTypesComponent.type === 'header' || fixedTypesComponent.type === 'paragraph') {
      return {
        ...fixedTypesComponent,
        fontSize: parseInt(fixedTypesComponent.fontSize, 10),
      };
    } else if (fixedTypesComponent.scale === 'image') {
      return {
        ...fixedTypesComponent,
        scale: parseInt(fixedTypesComponent.scale, 10),
      };
    }

    return fixedTypesComponent;
  };

  const saveComponent = c => {
    onComponentSave(c, index);
  };

  useEffect(() => {
    setComponent(editComponent);
    setDerivedTemplates(
      !templates || !editComponent
        ? []
        : templates.filter(template => template.formField.id === editComponent.id),
    );
  }, [editComponent, templates]);

  useEffect(() => {
    const tagList = templates.map(tmp => tmp.tags);
    const tagSet = tagList.reduce((acc, cur) => {
      const res = [...acc];
      cur.forEach(c => {
        if (!acc.includes(c)) {
          res.push(c);
        }
      });

      return res;
    }, []);
    setExistingTags(
      tagSet.map(tg => {
        return { value: tg, label: tg };
      }),
    );
  }, [templates]);

  const checkIndent = (newErrors, c) => {
    const indent = parseInt(c.indent, 10);
    if (
      c.indent == null ||
      c.indent.length === 0 ||
      isNaN(c.indent) ||
      indent < 0 ||
      indent > 660
    ) {
      newErrors.push(t('FormsEditor.ComponentEditorErrorIndent'));
      return null;
    }

    return indent;
  };

  const validateComponent = () => {
    const isDoubleInput = component.type === 'doubleInput';
    const isDoubleDropdown = component.type === 'doubleDropdown';

    const newErrors = [];
    let indent1 = null;
    let indent2 = null;
    let indentOk = false;
    if (isDoubleInput) {
      indent1 = checkIndent(newErrors, component.inputLeft);
      indent2 = checkIndent(newErrors, component.inputRight);
      indentOk = indent1 != null && indent2 != null;
    } else if (isDoubleDropdown) {
      indent1 = checkIndent(newErrors, component.dropdownLeft);
      indent2 = checkIndent(newErrors, component.dropdownRight);
      indentOk = indent1 != null && indent2 != null;
    } else {
      indent1 = checkIndent(newErrors, component);
      indentOk = indent1 != null;
    }

    if (indentOk) {
      if (isDoubleInput) {
        setComponent({
          ...component,
          inputLeft: {
            ...component.inputLeft,
            indent: indent1,
          },
          inputRight: {
            ...component.inputRight,
            indent: indent2,
          },
        });
      } else if (isDoubleDropdown) {
        setComponent({
          ...component,
          dropdownLeft: {
            ...component.dropdownLeft,
            indent: indent1,
          },
          dropdownRight: {
            ...component.dropdownRight,
            indent: indent2,
          },
        });
      } else {
        setComponent({
          ...component,
          indent: indent1,
        });
      }
    }

    if (component.type === 'header' || component.type === 'paragraph') {
      const fontSize = parseInt(component.fontSize, 10);
      if (isNaN(component.fontSize) || fontSize < 5 || fontSize > 200) {
        newErrors.push(t('FormsEditor.ComponentEditorErrorFontSize'));
      } else {
        setComponent({
          ...component,
          fontSize: fontSize,
        });
      }
    } else if (component.type === 'image') {
      const scale = parseInt(component.scale, 10);
      if (isNaN(component.scale) || scale <= 0) {
        newErrors.push(t('FormsEditor.ComponentEditorErrorImageScale'));
      } else {
        setComponent({
          ...component,
          scale: scale,
        });
      }
    }

    if (
      component.name &&
      component.name.length > 0 &&
      existingNames.map(n => n.toLowerCase()).includes(component.name.toLowerCase())
    ) {
      newErrors.push(t('FormsEditor.ComponentEditorErrorNameAlreadyExists'));
    }

    setErrors(newErrors);

    return newErrors.length === 0;
  };

  const renderEditView = () => {
    if (component.type === 'header' || component.type === 'paragraph') {
      return <HeaderParagraphEditView component={component} setComponent={setComponent} />;
    } else if (component.type === 'input') {
      return <InputEditView component={component} setComponent={setComponent} />;
    } else if (component.type === 'doubleInput') {
      return <DoubleInputEditView component={component} setComponent={setComponent} />;
    } else if (component.type === 'checkbox') {
      return <CheckboxEditView component={component} setComponent={setComponent} pages={pages} />;
    } else if (component.type === 'dropdown') {
      return <DropdownEditView component={component} setComponent={setComponent} />;
    } else if (component.type === 'doubleDropdown') {
      return <DoubleDropdownEditView component={component} setComponent={setComponent} />;
    } else if (component.type === 'image') {
      return (
        <ImageEditView component={component} setComponent={setComponent} activeKita={activeKita} />
      );
    } else if (component.type === 'tableParentalContribution') {
      return (
        <TableParentalContributionEditView
          component={component}
          setComponent={setComponent}
          formFields={formFields}
        />
      );
    } else if (component.type === 'tableParentalContributionWithHolidays') {
      return (
        <TableParentalContributionWithHolidaysEditView
          component={component}
          setComponent={setComponent}
          formFields={formFields}
        />
      );
    } else if (component.type === 'tableBookingTimes') {
      return <TableBookingTimesEditView component={component} setComponent={setComponent} />;
    }
  };

  const updateOrCreateTemplate = () => {
    saveTemplate(editTemplateId, editTemplateName, editTemplateTags, component.id)
      .then(res => {
        store.dispatch(
          showBottomNotification(
            editTemplateId === 0
              ? t('Administration.FormsEditorEditComponentCreateTemplateSuccess')
              : t('Administration.FormsEditorEditComponentEditTemplateSuccess'),
            {
              isFail: false,
            },
          ),
        );
        setShowEditTemplate(false);
        reloadTemplates();
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('Administration.FormsEditorEditComponentCreateTemplateError'), {
            isFail: true,
          }),
        );
      });
  };

  const doDeleteTemplate = () => {
    setIsDeletingTemplate(true);
    const lastTemplate = derivedTemplates && derivedTemplates.length === 1;
    deleteTemplate(templateToDelete.id)
      .then(() => {
        store.dispatch(
          showBottomNotification(
            t('Administration.FormsEditorEditComponentDeleteTemplateSuccess'),
            {
              isFail: false,
            },
          ),
        );
        reloadTemplates();
        if (lastTemplate) {
          setShowTemplateList(false);
        }
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('Administration.FormsEditorEditComponentDeleteTemplateError'), {
            isFail: true,
          }),
        );
      })
      .finally(() => {
        setIsDeletingTemplate(false);
        setTemplateToDelete(null);
      });
  };

  const startEditTemplate = template => {
    setEditTemplateId(template.id);
    setEditTemplateName(template.name);
    setEditTemplateTags(template.tags);
    setShowEditTemplate(true);
  };

  const startCreateTemplate = () => {
    setEditTemplateId(0);
    setEditTemplateName('');
    setEditTemplateTags([]);
    setShowEditTemplate(true);
  };

  const startDeleteTemplate = template => {
    setTemplateToDelete(template);
  };

  return (
    <>
      <TemplateEditPopup
        showEditTemplate={showEditTemplate}
        setShowEditTemplate={setShowEditTemplate}
        updateOrCreateTemplate={updateOrCreateTemplate}
        editTemplateName={editTemplateName}
        setEditTemplateName={setEditTemplateName}
        editTemplateTags={editTemplateTags}
        setEditTemplateTags={setEditTemplateTags}
        existingTags={existingTags}
      />

      <Popup
        size='medium'
        isOpened={showTemplateList}
        closePopup={() => setShowTemplateList(false)}
        header={t('Administration.FormsEditorEditComponentShowTemplateListPopupHeader')}
        footer={
          <div>
            <Button onClick={startCreateTemplate}>
              {t('Administration.FormsEditorEditComponentShowTemplateListAddTemplateButtonLabel')}
            </Button>
            <Button onClick={() => setShowTemplateList(false)}>{t('Popup.Close')}</Button>
          </div>
        }
      >
        <ul>
          {derivedTemplates.map(template => {
            return (
              <li>
                {template.name}{' '}
                <a
                  onClick={() => {
                    startEditTemplate(template);
                  }}
                >
                  <i className='fa fa-pencil' />
                </a>{' '}
                <a
                  onClick={() => {
                    startDeleteTemplate(template);
                  }}
                >
                  <i className='fa fa-trash' />
                </a>
              </li>
            );
          })}
        </ul>
      </Popup>

      <DeletePopup
        isOpened={templateToDelete != null}
        closePopup={() => setTemplateToDelete(null)}
        onDeleteBtnClick={doDeleteTemplate}
        headerText={t('Administration.FormsEditorEditComponentDeleteTemplatePopupHeader')}
        bodyText={t('Administration.FormsEditorEditComponentDeleteTemplatePopupText')}
        isSubmiting={isDeletingTemplate}
      />

      <ConfirmPopup
        isOpened={showTemplateSaveConfirm}
        closePopup={() => setShowTemplateSaveConfirm(false)}
        onConfirm={() => {
          const templateDetachedComponent = {
            ...component,
            template: null,
          };
          setComponent(templateDetachedComponent);
          saveComponent(fixDataTypes(templateDetachedComponent));
          setShowTemplateSaveConfirm(false);
        }}
        text={t('Administration.FormsEditorEditComponentSaveTemplatePopupText')}
      />

      <Popup
        size='normal'
        isOpened={editComponent != null}
        closePopup={closePopup}
        header={t('Administration.FormsEditorEditComponentPopupHeader')}
        footer={
          <div>
            <Button onClick={trySave}>{t('Popup.Save')}</Button>
            <Button onClick={closePopup}>{t('Popup.Close')}</Button>
          </div>
        }
      >
        {(!derivedTemplates || derivedTemplates.length === 0) &&
          component &&
          !component.template &&
          ![
            'tableParentalContribution',
            'tableParentalContributionWithHolidays',
            'tableBookingTimes',
          ].includes(component.type) && (
            <Button onClick={startCreateTemplate}>
              {t('Administration.FormsEditorEditComponentPopupCreateTemplateButtonLabel')}
            </Button>
          )}
        {derivedTemplates && derivedTemplates.length > 0 && (
          <Button
            onClick={() => {
              setShowTemplateList(true);
            }}
          >
            {t('Administration.FormsEditorEditComponentPopupListTemplatesButtonLabel')}
          </Button>
        )}
        {component && renderEditView()}
        {errors && (
          <ul>
            {errors.map(e => {
              return <li className={styles.errorMsg}>{e}</li>;
            })}
          </ul>
        )}
      </Popup>
    </>
  );
}
