import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './membersEmpty.module.scss';

export default function MembersEmpty() {
  const { t } = useTranslation();
  return (
    <tr>
      <td className={styles.tableEmpty} colSpan='6'>
        {t('GroupPanel.No results found')}
      </td>
    </tr>
  );
}
