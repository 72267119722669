import { useState, useEffect } from 'react';
import { addTask, updateTask, updateTaskStatus, deleteTask } from 'library/api/tasks';

const useTaskLists = ({
  taskModuleId,
  getTaskLists,
  addTaskList,
  updateTaskList,
  deleteTaskList,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [taskLists, setTaskLists] = useState([]);
  const [reloadTaskLists, setReloadTaskLists] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTaskLists(taskModuleId)
      .then(res => {
        if (res.status === 200) {
          setTaskLists(res.data.content);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, [reloadTaskLists]);

  const patchTaskLists = (action, payload, taskListId) => {
    const newTaskLists = taskLists.slice();
    let taskListIndex;
    let taskIndex;

    switch (action) {
      case 'deleteTaskList':
        taskListIndex = newTaskLists.findIndex(newTaskList => newTaskList.id === payload.id);
        if (taskListIndex !== -1) {
          newTaskLists.splice(taskListIndex, 1);
        }
        setTaskLists(newTaskLists);
        break;

      case 'addTaskList':
        newTaskLists.splice(0, 0, payload);
        setTaskLists(newTaskLists);
        break;

      case 'updateTaskList':
        taskListIndex = newTaskLists.findIndex(newTaskList => newTaskList.id === payload.id);
        if (taskListIndex !== -1) {
          newTaskLists.splice(taskListIndex, 1, payload);
        }
        setTaskLists(newTaskLists);
        break;

      case 'deleteTask':
        taskListIndex = newTaskLists.findIndex(
          newTaskList => newTaskList.id === payload.taskListId,
        );
        if (taskListIndex !== -1) {
          const newTasks = newTaskLists[taskListIndex].tasks.slice();
          taskIndex = newTasks.findIndex(newTask => newTask.id === payload.id);
          if (taskIndex !== -1) {
            newTasks.splice(taskIndex, 1);
            newTaskLists[taskListIndex].tasks = newTasks;
          }
        }
        newTaskLists[taskListIndex].isCompleted =
          newTaskLists[taskListIndex].tasks.length &&
          !newTaskLists[taskListIndex].tasks.find(item => item.taskStatus !== 'completed');
        setTaskLists(newTaskLists);
        break;

      case 'addTask':
        taskListIndex = newTaskLists.findIndex(
          newTaskList => newTaskList.id === payload.taskListId,
        );
        if (taskListIndex !== -1) {
          const newTasks = newTaskLists[taskListIndex].tasks.slice();
          newTasks.splice(0, 0, payload);
          newTaskLists[taskListIndex].tasks = newTasks;
        }
        newTaskLists[taskListIndex].isCompleted = false;
        setTaskLists(newTaskLists);
        break;

      case 'updateTask':
        taskListIndex = newTaskLists.findIndex(
          newTaskList => newTaskList.id === payload.taskListId,
        );
        if (taskListIndex !== -1) {
          const newTasks = newTaskLists[taskListIndex].tasks.slice();
          taskIndex = newTasks.findIndex(newTask => newTask.id === payload.id);
          if (taskIndex !== -1) {
            newTasks.splice(taskIndex, 1);
            newTasks.splice(0, 0, payload);
            newTaskLists[taskListIndex].tasks = newTasks;
          } else {
            newTasks.splice(0, 0, payload);
            newTaskLists[taskListIndex].tasks = newTasks;
            const previousTaskListIndex = newTaskLists.findIndex(
              newTaskList => newTaskList.id === taskListId,
            );
            const previousTaskListTasks = newTaskLists[previousTaskListIndex].tasks.slice();
            const previousTaskIndex = previousTaskListTasks.findIndex(
              previousTask => previousTask.id === payload.id,
            );
            if (previousTaskIndex !== -1) {
              previousTaskListTasks.splice(previousTaskIndex, 1);
              newTaskLists[previousTaskListIndex].tasks = previousTaskListTasks;
              newTaskLists[previousTaskListIndex].isCompleted =
                newTaskLists[previousTaskListIndex].tasks.length &&
                !newTaskLists[previousTaskListIndex].tasks.find(
                  item => item.taskStatus !== 'completed',
                );
            }
          }
        }
        newTaskLists[taskListIndex].isCompleted =
          newTaskLists[taskListIndex].tasks.length &&
          !newTaskLists[taskListIndex].tasks.find(item => item.taskStatus !== 'completed');
        setTaskLists(newTaskLists);
        break;

      default:
    }
  };

  const onAddTaskList = taskList => {
    return addTaskList(taskModuleId, taskList)
      .then(res => {
        if (res.status === 200) {
          patchTaskLists('addTaskList', res.data);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onDeleteTaskList = taskList => {
    return deleteTaskList(taskList.id)
      .then(res => {
        if (res.status === 200) {
          patchTaskLists('deleteTaskList', taskList);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onUpdateTaskList = taskList => {
    return updateTaskList(taskList.id, taskList)
      .then(res => {
        if (res.status === 200) {
          patchTaskLists('updateTaskList', taskList);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onAddTask = task => {
    return addTask(taskModuleId, task)
      .then(res => {
        if (res.status === 200) {
          patchTaskLists('addTask', res.data);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onDeleteTask = task => {
    return deleteTask(task.id)
      .then(res => {
        if (res.status === 200) {
          patchTaskLists('deleteTask', task);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onUpdateTask = (task, id) => {
    return updateTask(task.id, task)
      .then(res => {
        if (res.status === 200) {
          patchTaskLists('updateTask', { ...task, ...res.data, checklist: res.data.checklist }, id);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onUpdateTaskStatus = task => {
    return updateTaskStatus(task.id, task)
      .then(res => {
        if (res.status === 200) {
          patchTaskLists('updateTask', task);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return {
    isLoading,
    taskLists,
    onDeleteTaskList,
    onAddTaskList,
    onUpdateTaskList,
    onAddTask,
    onDeleteTask,
    onUpdateTask,
    onUpdateTaskStatus,
    reloadTaskLists: () => setReloadTaskLists(!reloadTaskLists),
  };
};

export default useTaskLists;
