import React, { useState,  useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import {
  getCoronaTestGroupData,
  getCoronaTestConfig,
} from 'library/api/coronaTest';
import store from 'main/store/configureStore';


const ChildOverviewContainer = ({groupId}) => {

  const { t } = useTranslation();

  const [coronaOverview, setCoronaOverview] = useState([]);
  const [coronaOverviewFiltered, setCoronaOverviewFiltered] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selfTestOnly, setSelfTestsOnly] = useState(false);

  useEffect(()=>{
    getCoronaTestGroupData(groupId).then(data=>{
      setCoronaOverview(data.data);
      setCoronaOverviewFiltered(data.data);
      setIsLoading(false);
    });

    getCoronaTestConfig(store.getState().kitaReducer.activeKita.kitaId).then(res => {
      if (res.data.selfTestsOnly === true)
      {
        setSelfTestsOnly(true);
      }
      else
      {
        setSelfTestsOnly(false);
      }
    })
    .catch(() => {
      setSelfTestsOnly(false);
    });

  },[]);

  useEffect(() => {
    if(searchInput !== ''){
      const filtered = coronaOverview.filter(obj=>{
        return obj.firstName.toLowerCase().includes(searchInput.toLowerCase()) || obj.lastName.toLowerCase().includes(searchInput.toLowerCase()) || (obj.firstName.toLowerCase() + " " + obj.lastName.toLowerCase()).includes(searchInput.toLowerCase()) || (obj.lastName.toLowerCase() + " " + obj.firstName.toLowerCase()).includes(searchInput.toLowerCase());
      });
      setCoronaOverviewFiltered(filtered);
    }
    else{
      setCoronaOverviewFiltered(coronaOverview);
    }
  },[searchInput]);

  function isTestValid(date)
  {
    const testDate = new Date(date);
    if(date !== null && typeof date !== 'undefined'){
    const currentTime = new Date();
    const lastValidDate = testDate.setHours(testDate.getHours() + 24);
    if(currentTime < lastValidDate){
      return true;
    }
    }
    return false;
  }

  function dateIsToday(timeStamp){
    const todaysDate = new Date();
    const testDate = new Date(timeStamp);
    if(testDate.setHours(0,0,0,0) === todaysDate.setHours(0,0,0,0)){
      return true;
    }
    return false;
  }

  return (
    !isLoading ?(
    selfTestOnly ? 
    (
      (
        <>
        {t('CoronaTest.You can find an overview of all users with current valid corona tests')}<br /><br />
        {coronaOverview.length > 0 ? (
          <>
        <Input type='text' placeholder={t('CoronaTest.Search by name')} value={searchInput} onChange={e => setSearchInput(e.target.value)} /><br />
          <table>
            <tr>
              <td style={{fontWeight:"600", backgroundColor:"#ededed", borderRadius: "5px 5px 0px 0px", padding: "5px"}}>
              {t('CoronaTest.Name')}
              </td>
              <td style={{fontWeight:"600", backgroundColor:"#ededed", borderRadius: "5px 5px 0px 0px", padding: "5px"}}>
              {t('CoronaTest.Last valid test')}
              </td>
            </tr>

            {coronaOverviewFiltered.map((data)=>{
              return(
                <tr>
                  <td style={isTestValid(data.lastValidTestDate) ? {fontWeight:"600", backgroundColor:"#44DD66", color: "white", padding: "5px"} : {}}>
                    {`${data.lastName}, ${data.firstName}`}
                  </td>
                  <td style={isTestValid(data.lastValidTestDate) ? {fontWeight:"600", backgroundColor:"#44DD66", color: "white", padding: "5px"} : {}}>
                  {data.lastValidTestDate !== null && `${new Date(data.lastValidTestDate).toLocaleDateString('de-DE',{year: "numeric",month: "2-digit",day: "2-digit",})} ${new Date(data.lastValidTestDate).toLocaleTimeString('de-DE').replace(/(.*)\D\d+/, '$1')}`}
                  </td>
                </tr>
              );
            })}
          </table>
          </>
          ) : (<div>{t('CoronaTest.No results available')}</div>) }
        </>)
    ) : 
    (
    <>
    {t('CoronaTest.You can find an overview of all kids with current valid corona tests')}<br /><br />
    {coronaOverview.length > 0 ? (
      <>
    <Input type='text' placeholder={t('CoronaTest.Search by name')} value={searchInput} onChange={e => setSearchInput(e.target.value)} /><br />
      <table>
        <tr>
          <td style={{fontWeight:"600", backgroundColor:"#ededed", borderRadius: "5px 5px 0px 0px", padding: "5px"}}>
          {t('CoronaTest.Name')}
          </td>
          <td style={{fontWeight:"600", backgroundColor:"#ededed", borderRadius: "5px 5px 0px 0px", padding: "5px"}}>
          {t('CoronaTest.Validity date')}
          </td>
        </tr>
        {coronaOverviewFiltered.map((data)=>{
          return(
            <tr>
              <td style={{fontWeight:"600", backgroundColor: dateIsToday(data.lastValidTestDate) ? "#44DD66" : "#ffff66", color: dateIsToday(data.lastValidTestDate) ? "white" : "black", padding: "5px"}}>
                {`${data.lastName}, ${data.firstName}`}
              </td>
              <td style={{fontWeight:"600", backgroundColor:  dateIsToday(data.lastValidTestDate) ? "#44DD66" : "#ffff66", color: dateIsToday(data.lastValidTestDate) ? "white" : "black", padding: "5px"}}>
              {data.lastTimeslotWhereTestIsValid !== null && `${new Date(data.lastTimeslotWhereTestIsValid).toLocaleDateString('de-DE',{year: "numeric",month: "2-digit",day: "2-digit",})} ${new Date(data.lastTimeslotWhereTestIsValid).toLocaleTimeString('de-DE').replace(/(.*)\D\d+/, '$1')}`}
              </td>
            </tr>
          );
        })}
      </table>
      </>
      ) : (<div>{t('CoronaTest.No results available')}</div>) }
    </>)
    ) : (<Loader />)
  );
};

export default connect(state => ({
  groupId: state.groupReducer.groupId,
}))(ChildOverviewContainer);




