import React, { useEffect, useState, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getEmployeeAbsences,
  postEmployeeAbsence,
  reviewEmployeeAbsence,
  getEmployeeInformation,
  getAllEmployeeInformation,
  updateEmployeeAbsence,
  getEmployeeKitaAbsenceTypes,
} from 'library/api/employee';
import HelpText from 'library/common/commonComponents/HelpText';
import Loader from 'library/common/commonComponents/Loader';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Label from 'library/common/commonComponents/Label';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Select from 'library/common/commonComponents/Inputs/Select';
import Popup from 'library/common/commonComponents/Popups/Popup';
import styles from './userFrameEmployeeAbsences.module.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import EmployeeHolidayAvatar from './EmployeeHoldayAvatar';
import { getWorkingTimeByDay, getWorkingDaysCount } from 'library/utilities/employeeCheckInOut';
import { EMPLOYEE_ABSENCE_TYPES } from 'library/common/commonConstants/employee';
import EmployeeAbsencesOverview from './EmployeeAbsencesOverview';

export default function EmployeeAbsenceContainer() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startHalfDay, toggleStartHalfDay] = useReducer(prev => !prev, false);
  const [endHalfDay, toggleEndHalfDay] = useReducer(prev => !prev, false);
  const [absenceType, setAbsenceType] = useState('');
  const [employeeAbsences, setEmployeeAbsences] = useState(null);
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [workingDaysPerUser, setWorkingDaysPerUser] = useState(null);
  const [error, setError] = useState('');
  const [sickNoteWarningOpen, setSickNoteWarningOpen] = useState(false);
  const user = useSelector(state => state.userReducer);
  const { kitaId } = useSelector(state => state.kitaReducer.activeKita);
  const [employeeKitaAbsenceTypes, setEmployeeKitaAbsenceTypes] = useState([]);

  const getAbsencesAndEmployeeInfo = useCallback(() => {
    setIsLoading(true);

    Promise.all([
      getEmployeeAbsences(kitaId),
      getEmployeeInformation(user.id),
      getEmployeeKitaAbsenceTypes(),
    ])
      .then(([employeeAbsenceRes, employeeInfoRes, employeeKitaAbsenceTypesRes]) => {
        setEmployeeAbsences(employeeAbsenceRes.data);
        setEmployeeInfo(employeeInfoRes.data);

        const baseAbsenceTypes = [
          {
            label: t('Checkinout.Sick note'),
            value: EMPLOYEE_ABSENCE_TYPES.SICK,
          },
          {
            label: t('Checkinout.Vacation'),
            value: EMPLOYEE_ABSENCE_TYPES.HOLIDAY,
          },
        ];

        if (employeeKitaAbsenceTypesRes.data) {
          const {
            employeeKitaAbsenceTypes,
            holidayOnOvertimeAllowed,
          } = employeeKitaAbsenceTypesRes.data;
          const absenceTypesArray = employeeKitaAbsenceTypes
            .filter(absence => absence.name !== '')
            .map(absence => ({
              label: absence.name,
              value: absence.absenceType,
            }));

          holidayOnOvertimeAllowed &&
            absenceTypesArray.push({
              label: t('CheckInOut.Holiday on overtime'),
              value: EMPLOYEE_ABSENCE_TYPES.HOLIDAY_ON_OVERTIME,
            });

          setEmployeeKitaAbsenceTypes([...baseAbsenceTypes, ...absenceTypesArray]);
        } else {
          setEmployeeKitaAbsenceTypes([...baseAbsenceTypes]);
        }

        if (!user.administrationAccess) {
          setWorkingDaysPerUser({
            [employeeInfoRes.data.user.id]: getWorkingTimeByDay(employeeInfoRes.data),
          });
        }
      })
      .catch(err => {
        console.error(err);
      });

    if (user.administrationAccess) {
      getAllEmployeeInformation()
        .then(res => {
          const calculatedWorkingDays = {};
          res.data.forEach(employeeInfo => {
            calculatedWorkingDays[employeeInfo.user.id] = getWorkingTimeByDay(
              employeeInfo.employeeInformation,
            );
          });
          setWorkingDaysPerUser(calculatedWorkingDays);
        })
        .catch(err => {
          console.error(err);
        });
    }
    setIsLoading(false);
  }, [kitaId, user.id, user.administrationAccess, t]);

  useEffect(() => {
    getAbsencesAndEmployeeInfo();
  }, [getAbsencesAndEmployeeInfo]);

  // calculate number of days between two dates
  const getDaysCount = () => {
    if (!startDate || !endDate) {
      return 0;
    }
    let diffTime = new Date(endDate) - new Date(startDate);
    diffTime = Math.round(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 because we count the start date as well

    if (startHalfDay) diffTime -= 0.5;
    if (endHalfDay) diffTime -= 0.5;

    return Math.max(diffTime, 0.5);
  };

  const handleSickNote = () =>
    getDaysCount() >= 3 ? setSickNoteWarningOpen(true) : handleRequestAbsence();

  const handleRequestAbsence = async () => {
    if (!startDate || !endDate) {
      setError('dates');
      return;
    }

    if (!absenceType) {
      setError('type');
      return;
    }

    try {
      setIsLoading(true);

      await postEmployeeAbsence({
        userId: user.id,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        startHalfDay: startHalfDay,
        endHalfDay: endHalfDay,
        kitaId,
        absenceType: absenceType.value,
      });

      const res = await getEmployeeAbsences(kitaId);
      setEmployeeAbsences(res.data);

      setStartDate('');
      setEndDate('');
      setAbsenceType(null);
      setIsLoading(false);
      setError('');
      toggleStartHalfDay();
      toggleEndHalfDay();
    } catch (err) {
      console.error(err);
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
      setIsLoading(false);
    }
  };

  const handleReviewButtonClick = async (absenceId, status) => {
    try {
      await reviewEmployeeAbsence(absenceId, status);
      getAbsencesAndEmployeeInfo();
    } catch (err) {
      console.error(err);
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    }
  };

  const createKitaEmployeeAbsenceDays = (employeeInfo, employeeKitaAbsenceTypes) => {
    const absenceDaysPerTypeObject = employeeInfo?.employeeAbsenceDays?.reduce(
      (acc, absence) => ({
        ...acc,
        [absence.absenceType]: absence,
      }),
      {},
    );

    const kitaEmplyoeeAbsenceDays = [...employeeKitaAbsenceTypes]
      .filter(
        type =>
          type.value !== EMPLOYEE_ABSENCE_TYPES.HOLIDAY_ON_OVERTIME &&
          type.value !== EMPLOYEE_ABSENCE_TYPES.SICK,
      )
      .map(type => {
        return {
          value: type.value,
          label: type.label,
          daysTotal: absenceDaysPerTypeObject[type.value]?.daysTotal || 0,
          daysTaken: absenceDaysPerTypeObject[type.value]?.daysTaken || 0,
        };
      });
    return kitaEmplyoeeAbsenceDays;
  };

  const handleSubmitUpdateAbsence = async (
    absenceId,
    userId,
    startDate,
    endDate,
    startHalfDay,
    endHalfDay,
  ) => {
    try {
      setIsLoading(true);
      await updateEmployeeAbsence(absenceId, {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        startHalfDay: startHalfDay,
        endHalfDay: endHalfDay,
        userId,
      });
      const res = await getEmployeeAbsences(kitaId);
      setEmployeeAbsences(res.data);
    } catch (err) {
      console.error(err);
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = date => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Note: months are zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return (
    <div className={styles.container}>
      <Popup
        size='small'
        isOpened={sickNoteWarningOpen}
        closePopup={() => {
          setSickNoteWarningOpen(false);
        }}
        header={<strong>{t('CheckInOut.Sick note warning')}</strong>}
        body={t('CheckInOut.TheSickNoteDurationIsLongerThanThreeDays')}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button
                onClick={() => {
                  setSickNoteWarningOpen(false);
                  handleRequestAbsence();
                }}
              >
                {t('CheckInOut.Send sick note')}
              </Button>
            </div>
          </div>
        }
      />

      {!isLoading && (
        <EmployeeHolidayAvatar
          kitaEmployeeAbsenceDays={createKitaEmployeeAbsenceDays(
            employeeInfo,
            employeeKitaAbsenceTypes,
          )}
        />
      )}

      <section>
        <h2>{t('Checkinout.RequestAbsence')}</h2>
        <div className={styles.text}>
          <HelpText>
            {t('Checkinout.Here you can request absences like sick note or vacation')}
          </HelpText>
        </div>
        <div className={styles.dateInputsContainer}>
          <div className={styles.datePickerContainer}>
            <div className={styles.dateStart}>
              <Label type='input'>{t('Calendar.Start Date')}</Label>
              <DatePicker
                selected={startDate}
                onChange={date => {
                  setStartDate(date);
                }}
                langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
                minDate={new Date().setDate(new Date().getDate() - 20)} // 20 days in the past
              />
              <Checkbox
                className={styles.halfDayCheckbox}
                name={t('Checkinout.Half day')}
                isChecked={startHalfDay}
                onChange={toggleStartHalfDay}
              />
            </div>
            <div className={styles.dateStart}>
              <Label type='input'>{t('Calendar.End Date')}</Label>
              <DatePicker
                selected={endDate}
                onChange={date => {
                  setEndDate(date);
                }}
                langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
                minDate={new Date().setDate(new Date().getDate() - 20)} // 20 days in the past
              />
              <Checkbox
                className={styles.halfDayCheckbox}
                name={t('Checkinout.Half day')}
                isChecked={endHalfDay}
                onChange={toggleEndHalfDay}
              />
            </div>
          </div>

          <div>
            <Label type='input'>
              {`${t('Checkinout.Absence days')} / ${t('Checkinout.Total')}`}
            </Label>
            <div>
              {`${getWorkingDaysCount(
                startDate,
                endDate,
                startHalfDay,
                endHalfDay,
                workingDaysPerUser?.[user.id],
              )} / ${getDaysCount()}`}
            </div>
          </div>

          <div className={styles.absenceTypeContainer}>
            <Label type='input'>{t('Checkinout.Type of Absence')}</Label>
            <Select
              onSelect={setAbsenceType}
              selected={absenceType}
              options={employeeKitaAbsenceTypes}
            />
          </div>

          {error ? (
            <p className={cn(styles.dateInputError)}>
              {error === 'dates' && t('Checkinout.Start and end date has to be selected')}
              {error === 'type' && t('Checkinout.Absence type has to be selected')}
            </p>
          ) : null}

          <Button
            type='primary'
            disabled={isLoading}
            onClick={() => {
              absenceType === EMPLOYEE_ABSENCE_TYPES.SICK
                ? handleSickNote()
                : handleRequestAbsence();
            }}
          >
            {t('Checkinout.Send absence request')}
          </Button>
        </div>
      </section>
      {isLoading ? (
        <Loader />
      ) : (
        <EmployeeAbsencesOverview
          employeeAbsences={employeeAbsences}
          handleReviewButtonClick={handleReviewButtonClick}
          workingDaysPerUser={workingDaysPerUser}
          handleSubmitUpdateAbsence={handleSubmitUpdateAbsence}
          employeeKitaAbsenceTypes={employeeKitaAbsenceTypes}
        />
      )}
    </div>
  );
}
