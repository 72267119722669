import React from 'react';
import Card from 'library/common/commonComponents/Card';
import { useTranslation } from 'react-i18next';
import kitalinoInterfaceModule from 'resources/images/modules/kitalino_interface_module.png';

import styles from './kitalinoInterface.module.scss';
import Button from '../Buttons/Button';


export default function KitalinoInterface() {

  const { t } = useTranslation();

  return (
    <Card className={styles.container}>
      <h3>{t('KitalinoInterface.Kitalino interface')}</h3>
      <br />
      {t('KitalinoInterface.Kitalino interface description')}
      <br />
      <br />
      <img src={kitalinoInterfaceModule} />
      <br />
      <br />
      <Button
        onClick={() => {
          window.location.href = "https://kitaneu-stag-einrichtungen.kitalino.com";
        }}
        type='primary'>
        {t('KitalinoInterface.Go to kitalino')}
      </Button>
    </Card>
  );
}

