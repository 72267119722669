import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getEmployeeKitaAbsenceSettings } from 'library/api/employee';
import EmployeeAbsenceSettings from './EmployeeAbsenceSettings';
import KitaAbsenceSettings from './KitaAbsenceSettings';
import HelpText from 'library/common/commonComponents/HelpText';
import Loader from 'library/common/commonComponents/Loader';
import styles from './userFrameEmployeeNumberHolidays.module.scss';
import cn from 'classnames';
import { EMPLOYEE_ABSENCE_TYPES } from 'library/common/commonConstants/employee';

export default function UserFrameEmployeeNumberHolidays() {
  const { t } = useTranslation();
  const [employeeList, setEmployeeList] = useState([]);
  const [kitaAbsenceTypes, setKitaAbsenceTypes] = useState([]);
  const [holidayOnOvertimeAllowed, setHolidayOnOvertimeAllowed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSettings = useCallback(() => {
    setIsLoading(true);
    getEmployeeKitaAbsenceSettings()
      .then(({ data }) => {
        setEmployeeList(data?.employeeInformationDTOList || []);

        if (data?.employeeKitaAbsenceTypes) {
          const {
            absenceOneName,
            absenceTwoName,
            absenceThreeName,
            absenceFourName,
            absenceFiveName,
            holidayOnOvertimeAllowed,
          } = data.employeeKitaAbsenceTypes;

          const absenceTypes = [];
          if (absenceOneName)
            absenceTypes.push({
              absenceType: EMPLOYEE_ABSENCE_TYPES.ABSENCE_ONE,
              absenceName: absenceOneName,
            });
          if (absenceTwoName)
            absenceTypes.push({
              absenceType: EMPLOYEE_ABSENCE_TYPES.ABSENCE_TWO,
              absenceName: absenceTwoName,
            });
          if (absenceThreeName)
            absenceTypes.push({
              absenceType: EMPLOYEE_ABSENCE_TYPES.ABSENCE_THREE,
              absenceName: absenceThreeName,
            });
          if (absenceFourName)
            absenceTypes.push({
              absenceType: EMPLOYEE_ABSENCE_TYPES.ABSENCE_FOUR,
              absenceName: absenceFourName,
            });
          if (absenceFiveName)
            absenceTypes.push({
              absenceType: EMPLOYEE_ABSENCE_TYPES.ABSENCE_FIVE,
              absenceName: absenceFiveName,
            });
          setKitaAbsenceTypes(absenceTypes);
          setHolidayOnOvertimeAllowed(holidayOnOvertimeAllowed);
        }
      })
      .catch(err => {
        console.err(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const createKitaEmployeeAbsenceDays = (employeeInformation, kitaAbsenceTypes) => {
    const absenceDaysPerAbsenceType = {};
    employeeInformation?.employeeAbsenceDays?.forEach(absence => {
      absenceDaysPerAbsenceType[absence.absenceType] = absence;
    });

    const holidayAbsence = {
      absenceType: EMPLOYEE_ABSENCE_TYPES.HOLIDAY,
      absenceName: 'Checkinout.Vacation',
      daysTotal:
        parseFloat(absenceDaysPerAbsenceType?.[EMPLOYEE_ABSENCE_TYPES.HOLIDAY]?.daysTotal) || 0,
      daysTaken:
        parseFloat(absenceDaysPerAbsenceType?.[EMPLOYEE_ABSENCE_TYPES.HOLIDAY]?.daysTaken) || 0,
    };

    const customAbsences = kitaAbsenceTypes.map(absence => {
      return {
        absenceType: absence.absenceType,
        absenceName: absence.absenceName,
        daysTotal: parseFloat(absenceDaysPerAbsenceType?.[absence.absenceType]?.daysTotal) || 0,
        daysTaken: parseFloat(absenceDaysPerAbsenceType?.[absence.absenceType]?.daysTaken) || 0,
      };
    });
    return [holidayAbsence, ...customAbsences];
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Administration.Vacation and break setting')}</div>
      <div className={styles.text}>
        <HelpText>
          {t(
            'Administration.Here you can change the number of holidays every employee has per year and how legal break should be considered',
          )}
        </HelpText>
      </div>

      {!isLoading ? (
        <>
          <h3>{t('Administration.Day care absent settings')}</h3>
          <KitaAbsenceSettings
            kitaAbsenceTypes={kitaAbsenceTypes}
            holidayOnOvertimeAllowed={holidayOnOvertimeAllowed}
            fetchSettings={fetchSettings}
          />

          <h3>{t('Administration.Employee absent settings')}</h3>
          <div className={styles.employeeList}>
            {employeeList.map((employee, index) => {
              return (
                <EmployeeAbsenceSettings
                  key={employee.user.id}
                  employee={employee}
                  employeeAbsenceDays={createKitaEmployeeAbsenceDays(
                    employee.employeeInformation,
                    kitaAbsenceTypes,
                  )}
                  className={cn(index % 2 === 0 && styles.withBackground)}
                />
              );
            })}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
