import * as moment from 'moment';

export const formatHolidayEvents = events => {
  const formattedEvents = [];
  events.forEach(day => {
    formattedEvents.push({
      id: Math.random().toString(),
      groupId: '',
      moduleType: 'calendar',
      group: null,
      firstName: null,
      lastName: null,
      isTranslationAllowed: false,
      watsonLanguage: 'null',
      description: day.name,
      startTime: '',
      endTime: '',
      timeZone: '(UTC+02:00) Europe/Berlin',
      location: '',
      repeatType: 'none',
      repeatDays: 0,
      repeatEndDate: 0,
      repeatWeeklyInterval: 1,
      editable: false,
      color: day.isAdditional ? '#a237d8' : '#2fa61f',
      title: day.name,
      end: moment(day.endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      start: day.startDate,
    });
  });
  return formattedEvents;
};

export const formatHolidayCareEvents = (events, type = 'kita') => {
  const formattedEvents = [];
  events.forEach(event => {
    formattedEvents.push({
      id: Math.random().toString(),
      groupId: '',
      moduleType: 'calendar',
      group: null,
      firstName: null,
      lastName: null,
      isTranslationAllowed: false,
      watsonLanguage: 'null',
      description: event.name,
      startTime: '',
      endTime: '',
      timeZone: '(UTC+02:00) Europe/Berlin',
      location: '',
      repeatType: 'none',
      repeatDays: 0,
      repeatEndDate: 0,
      repeatWeeklyInterval: 1,
      editable: false,
      color: type === 'kita' ? '#d88599' : '#8c8cc5',
      title: event.name,
      end: moment(event.endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      start: event.startDate,
    });
  });
  return formattedEvents;
};

export const formatBirthdayEvents = (events, groupId) => {
  const formattedEvents = [];
  const yearNow = new Date().getFullYear();
  events.forEach(event => {
    const birthday = moment(event.birthday);
    const start = moment().set('year', yearNow).set('month', birthday.month()).set('date', birthday.date());
    const end = start.clone();
    formattedEvents.push({
      id: Math.random().toString(),
      groupId: groupId,
      moduleType: 'calendar',
      group: null,
      firstName: null,
      lastName: null,
      isTranslationAllowed: false,
      watsonLanguage: 'null',
      description: `${event.firstName} ${event.lastName}'s birthday`,
      startTime: '',
      endTime: '',
      timeZone: '(UTC+02:00) Europe/Berlin',
      location: '',
      repeatType: 'none',
      repeatDays: 0,
      repeatEndDate: 0,
      repeatWeeklyInterval: 1,
      editable: false,
      color: '#2fa61f',
      title: `${event.firstName} ${event.lastName}'s birthday`,
      end: end.format('YYYY-MM-DD'),
      start: start.format('YYYY-MM-DD'),
    });
  });

  return formattedEvents;
};

export const formatWorkingHoursEvents = (events, groupId, currentDate) => {
  const formattedEvents = [];
  for (let index = 0; index < 2; index++) {
    const selectedDate = moment(currentDate).clone().add(index, 'month');
    const daysInCurrentMonth = selectedDate.daysInMonth();
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      const date = selectedDate.date(day).isoWeekday();
      for (const event of events) {
        const { firstName, lastName, id } = event.user;
        const { mondayStartTime, mondayEndTime, tuesdayStartTime, tuesdayEndTime, wednesdayStartTime, wednesdayEndTime, thursdayStartTime, thursdayEndTime, fridayStartTime, fridayEndTime, saturdayStartTime, saturdayEndTime, sundayStartTime, sundayEndTime } = event.workingTime;
        const { colourCode } = event?.category || '#2fa61f'
        const valueOfWeek = {
          1: {
            start: mondayStartTime,
            end: mondayEndTime
          },
          2: {
            start: tuesdayStartTime,
            end: tuesdayEndTime
          },
          3: {
            start: wednesdayStartTime,
            end: wednesdayEndTime
          },
          4: {
            start: thursdayStartTime,
            end: thursdayEndTime
          },
          5: {
            start: fridayStartTime,
            end: fridayEndTime
          },
          6: {
            start: saturdayStartTime,
            end: saturdayEndTime
          },
          7: {
            start: sundayStartTime,
            end: sundayEndTime
          }
        }

        const value = valueOfWeek[date];
        if (!value.start || !value.end) {
          continue;
        }
        const start = selectedDate
          .date(day)
          .hour(value.start.hour)
          .minute(value.start.minute);
        const end = start.clone()
          .hour(value.end.hour)
          .minute(value.end.minute);

        formattedEvents.push({
          id: Math.random().toString(),
          moduleType: 'calendar',
          isWorkingHour: true,
          item: {
            startTime: moment().hour(value.start.hour).minute(value.start.minute).format('HH:mm'),
            endTime: moment().hour(value.end.hour).minute(value.end.minute).format('HH:mm'),
            date: date,
            userId: id,
          },
          group: null,
          firstName: firstName,
          lastName: lastName,
          isTranslationAllowed: false,
          watsonLanguage: 'null',
          description: `${firstName} ${lastName}`,
          startTime: '',
          endTime: '',
          timeZone: '(UTC+02:00) Europe/Berlin',
          location: '',
          repeatType: 'none',
          repeatDays: 0,
          repeatEndDate: 0,
          repeatWeeklyInterval: 1,
          editable: true,
          color: colourCode,
          title: `${firstName} ${lastName}`,
          end: end.format('YYYY-MM-DD HH:mm'),
          start: start.format('YYYY-MM-DD HH:mm'),
        });
      }
    }
  }

  return formattedEvents;
};
