import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

window.users = {};

export async function getUser(userId) {
  if (window.users[userId]) {
    return window.users[userId];
  } else {
    window.users[userId] = (await axios.get(
      `${config.API_BASE_URI}${getPort(8080)}/api/user/${userId}?graphql=firstName,lastName`,
    )).data;
    return window.users[userId];
  }
}
