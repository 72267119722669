import React, { useState } from 'react';
import styles from './kitaAbsenceSettings.module.scss';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import { useTranslation } from 'react-i18next';
import { updateEmployeeKitaAbsenceTypes } from 'library/api/employee';
import { CustomAbsenceIconBadge } from 'library/common/commonComponents/CheckInOut/EmployeeHistoryContainer/EmployeeHistoryBadges';

export default function KitaAbsenceSettings({
  kitaAbsenceTypes,
  holidayOnOvertimeAllowed: initialHolidayOnOvertimeAllowed,
  fetchSettings,
}) {
  const [absenceTypes, setAbsenceTypes] = useState([
    kitaAbsenceTypes?.[0]?.absenceName || '',
    kitaAbsenceTypes?.[1]?.absenceName || '',
    kitaAbsenceTypes?.[2]?.absenceName || '',
    kitaAbsenceTypes?.[3]?.absenceName || '',
    kitaAbsenceTypes?.[4]?.absenceName || '',
  ]);
  const [holidayOnOvertimeAllowed, setHolidayOnOvertimeAllowed] = useState(
    initialHolidayOnOvertimeAllowed,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const absenceInputIsValid = () => {
    // check if there are not absenceTypes in the middle of the array that are empty
    let absenceSet = false;
    for (let i = absenceTypes.length - 1; i >= 0; i--) {
      if (absenceTypes[i] === '' && absenceSet) {
        setError({ index: i, message: t('Administration.Absence types must be consecutive') });
        return false;
      }
      if (absenceTypes[i] !== '') {
        absenceSet = true;
      }
    }
    return true;
  };

  const handleSaveButton = async () => {
    if (!absenceInputIsValid()) return;

    setIsLoading(true);
    try {
      await updateEmployeeKitaAbsenceTypes({
        absenceOneName: absenceTypes[0],
        absenceTwoName: absenceTypes[1],
        absenceThreeName: absenceTypes[2],
        absenceFourName: absenceTypes[3],
        absenceFiveName: absenceTypes[4],
        holidayOnOvertimeAllowed: holidayOnOvertimeAllowed,
      });
      setError(null);
      await fetchSettings();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  return (
    <div className={styles.employeeKitaAbsenceTypes}>
      {absenceTypes.map((absenceType, index) => {
        return (
          <div key={index} className={styles.inputContainer}>
            <label htmlFor={`absenceType${index}`}>{index + 1}</label>
            <CustomAbsenceIconBadge index={index} />

            <Input
              id={`absenceType${index}`}
              className={styles.inputAbsenceType}
              type='text'
              value={absenceType}
              error={error?.index === index}
              onChange={e => {
                const newAbsenceTypes = [...absenceTypes];
                newAbsenceTypes[index] = e.target.value;
                setAbsenceTypes([...newAbsenceTypes]);
              }}
            />
          </div>
        );
      })}
      <Checkbox
        isChecked={holidayOnOvertimeAllowed}
        onChange={() => setHolidayOnOvertimeAllowed(prev => !prev)}
        className={styles.holidayOnOvertimeCheckbox}
        name={t('Administration.Holiday on overtime allowed')}
      />

      {!!error && <p className={styles.errorMessage}>{error.message}</p>}

      <div className={styles.saveButtonWrapper}>
        <Button
          className={styles.saveButton}
          onClick={handleSaveButton}
          type='primary'
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader isSmall={true} color='#fff' />
          ) : (
            <>
              <i className='fa fa-check' aria-hidden='true' />{' '}
              {t('Administration.UserSection.Save')}
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
