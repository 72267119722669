import React from 'react';
import { useTranslation } from 'react-i18next';
import msIcon from 'resources/images/icons/ms-ic.svg';
import styles from './microsoftLoginButton.module.scss';

const MicrosoftLoginButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div role='button' className={styles.msLoginWrapper} onClick={onClick}>
      <img alt='microsoft-login' src={msIcon} />
      <span>{t('Login.Sign in')}</span>
    </div>
  );
};

export default MicrosoftLoginButton;
