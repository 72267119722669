import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getGfzFormsOverview,
  getGfzFormsOverviewForCurrentUser,
} from 'library/api/observationSheet';
import Loader from 'library/common/commonComponents/Loader';
import GfzDocumentationRow from './GfzDocumentationRow';

export default function GfzDocumentationOverview({
  showAll,
  user,
  kitaName,
  setActiveTab,
  setKidId,
  setFormId,
}) {
  const [documentationOverviewData, setDocumentationOverviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  useEffect(() => {
    if (showAll != undefined) {

      const getGfzFormsOverviewFunction = showAll ? getGfzFormsOverview : getGfzFormsOverviewForCurrentUser;

      getGfzFormsOverviewFunction().then(data => {
        const formsOverview = data.data;
        formsOverview.sort((a, b) => {
          return new Date(b.date.split('.')[2], b.date.split('.')[1] - 1, b.date.split('.')[0]) - new Date(a.date.split('.')[2], a.date.split('.')[1] - 1, a.date.split('.')[0])
        });
        setDocumentationOverviewData(formsOverview);
      }).finally(() => {
        setIsLoading(false);
      })
    }
  }, [showAll]);

  return (
    !isLoading ?
      <div style={{ overflowY: "auto" }}>
        <h2>{t('GFZForms.Forms overview')}</h2>
        <br />
        <table>
          <tr>
            <th style={{ minWidth: "90px" }}>
              {t('GfzDocumentation.Last change')}
            </th>
            <th style={{ minWidth: "250px" }}>
              {t('GfzDocumentation.Form')}
            </th>
            <th style={{ minWidth: "90px" }}>
              {t('GfzDocumentation.Kid')}
            </th>
            <th style={{ minWidth: "90px" }}>
              {t('GfzDocumentation.KitaSignStatus')}
            </th>
            <th style={{ minWidth: "90px" }}>
              {t('GfzDocumentation.ParentSignStatus')}
            </th>
          </tr>
          {documentationOverviewData.map(rowData => {
            return <GfzDocumentationRow rowData={rowData} user={user} setActiveTab={setActiveTab} setKidId={setKidId} setFormId={setFormId} />
          })}
        </table>
      </div> : <Loader />
  );
}
