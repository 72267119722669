export const URLS = {
  logout: '/user/logout',
  refreshToken: '/oauth/token',
};
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const REMOVE_AUTH_TOKEN = 'REMOVE_AUTH_TOKEN';
export const RESET_INPUT_ERROR = '@@RESET_INPUT_ERROR';
export const SET_BUTTON_DISABLED = '@@SET_BUTTON_DISABLED';
export const SET_LANGUAGE = '@@SET_LANGUAGE';
export const SET_CREATE_GROUP_MODE = '@@SET_CREATE_GROUP_MODE';
export const SET_GOOGLE_TRANSLATE_AGREE = '@@SET_GOOGLE_TRANSLATE_AGREE';
export const SET_GOOGLE_TRANSLATE_LANG = '@@SET_GOOGLE_TRANSLATE_LANG';
