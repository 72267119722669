import React from 'react';

import { GroupContext } from 'modules/Group/groupModule';
// eslint-disable-next-line
import GroupBodySecurityGeneral from 'library/common/commonComponents/Group/GroupBody/GroupBodyFrames/GroupBodySecurity/GroupBodySecurityFrames/GroupBodySecurityGeneral/GroupBodySecurityGeneralContainer';

import styles from './groupsFrameSettings.module.scss';

export default function GroupsFrameSettings({ kita, setActiveKita, kitas, setKitas }) {
  const groupInfo = {
    visibility: kita.defaultGlobalGroupVisibility,
    contentVisibility: kita.defaultGlobalGroupContentVisibility,
    groupJoinPolicy: {
      id: kita.groupJoinPoliciesId,
    },
  };

  const reloadGlobalGroupInfo = data => {
    const newKitas =
      kitas &&
      kitas.map(item => {
        if (item.kitaId === kita.kitaId) {
          return {
            ...item,
            defaultGlobalGroupVisibility: data.visibility,
            defaultGlobalGroupContentVisibility: data.contentVisibility,
            groupJoinPoliciesId: data.groupJoinPolicy,
          };
        }
        return item;
      });
    setActiveKita({
      ...kita,
      defaultGlobalGroupVisibility: data.visibility,
      defaultGlobalGroupContentVisibility: data.contentVisibility,
      groupJoinPoliciesId: data.groupJoinPolicy,
    });
    if (newKitas && newKitas.length) setKitas(newKitas);
  };

  return (
    <GroupContext.Provider value={{ groupInfo }}>
      <div className={styles.container}>
        <GroupBodySecurityGeneral isGlobal reloadGlobalGroupInfo={reloadGlobalGroupInfo} />
      </div>
    </GroupContext.Provider>
  );
}
