import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import { getGroupKidEatRows } from 'library/api/kidDevelopmentDiary';
import ChildRow from './ChildRow';
import styles from './childesContainer.module.scss';
import store from '../../../../../../main/store/configureStore';
import { showBottomNotification } from '../../../../commonActions/notificationsActions';
import { deleteEntry, editEntry } from '../../../../../api/kidDevelopmentDiary';
import DeletePopup from '../../../Popups/DeletePopup';
import ButtonWithLoader from '../../../Buttons/ButtonWithLoader';
import Popup from '../../../Popups/Popup';
import Button from '../../../Buttons/Button';
import { formatDateObjectToTimeString } from '../../../../../utilities/dateMoment';

const EssenContainer = ({ checkedInKidIds, groupId }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [entryToDelete, setEntryToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updateComments, setUpdateComments] = useState(null);

  const [entryToEdit, setEntryToEdit] = useState(null);
  const [editComment, setEditComment] = useState('');
  const [editTime, setEditTime] = useState('');
  const [editError, setEditError] = useState('');
  const [isSavingEdits, setIsSavingEdits] = useState(false);

  const filteredDataPlus =
    searchValue.length > 0
      ? filteredData.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
      : filteredData;

  useEffect(() => {
    getGroupKidEatRows(groupId).then(res => {
      const newfilteredData =
        searchValue.length > 0
          ? res.data.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
          : res.data;
      setFilteredData(newfilteredData.filter(k => checkedInKidIds.includes(k.kidId)));
    });
  }, [searchValue]);

  const handleDeleteEntry = id => {
    setIsDeleting(true);
    deleteEntry(id)
      .then(() => {
        setEntryToDelete(null);
        setFilteredData(
          filteredData.map(i => {
            return {
              ...i,
              eatTodayComments: i.eatTodayComments.filter(c => c.id !== id),
            };
          }),
        );
        if (updateComments) {
          updateComments();
        }
        store.dispatch(
          showBottomNotification(t('KidDevelopmentDiary.DeleteSuccess'), { isFail: false }),
        );
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('KidDevelopmentDiary.DeleteError'), { isFail: true }),
        );
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const prepareDelete = (id, updateFunction) => {
    setEntryToDelete(id);
    setUpdateComments(() => updateFunction);
  };

  const prepareEdit = (c, updateFunction) => {
    setEntryToEdit(c);
    setEditComment(c.message);
    setEditTime(formatDateObjectToTimeString(c.date));
    setUpdateComments(() => updateFunction);
  };

  const handleEditEntry = (item, newComment, newTime) => {
    // validate time format
    const formatValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(newTime);
    if (!formatValid) {
      setEditError(t('KidDevelopmentDiary.EditEntryFormatError'));
      return;
    }

    const parts = newTime.split(':');
    const newDate = new Date();
    newDate.setHours(parseInt(parts[0], 10));
    newDate.setMinutes(parseInt(parts[1], 10));
    newDate.setSeconds(parts.length > 2 ? parseInt(parts[2], 10) : 0);

    setIsSavingEdits(true);

    editEntry(item.id, newComment, newDate.getTime())
      .then(() => {
        setEntryToEdit(null);
        setFilteredData(
          filteredData.map(i => {
            return {
              ...i,
              eatTodayComments: i.eatTodayComments.map(c => {
                if (c.id === item.id) {
                  return {
                    ...c,
                    message: newComment,
                    date: newDate,
                  };
                }

                return c;
              }),
            };
          }),
        );

        updateComments(newComment, newDate);

        store.dispatch(
          showBottomNotification(t('KidDevelopmentDiary.EditSuccess'), { isFail: false }),
        );
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('KidDevelopmentDiary.EditError'), { isFail: true }),
        );
      })
      .finally(() => {
        setIsSavingEdits(false);
      });
  };

  return (
    <div>
      <DeletePopup
        isOpened={entryToDelete !== null}
        closePopup={() => setEntryToDelete(null)}
        onDeleteBtnClick={() => handleDeleteEntry(entryToDelete)}
        headerText={t('KidDevelopmentDiary.ConfirmDeleteTitle')}
        bodyText={t('KidDevelopmentDiary.ConfirmDeleteText')}
        isSubmiting={isDeleting}
      />

      <Popup
        isOpened={entryToEdit != null}
        closePopup={() => {
          setEntryToEdit(null);
          setEditComment('');
          setEditTime('');
          setEditError('');
        }}
        header={t('DevelopmentDiary.EditCommentHeader')}
        body={
          <div>
            <div>
              <Input
                value={editComment}
                placeholder={t('DevelopmentDiary.EditCommentMessagePlaceholder')}
                onChange={e => setEditComment(e.target.value)}
                onRenderFocus={true}
              />
            </div>
            <div style={{ marginTop: '0.5em' }}>
              <Input
                value={editTime}
                placeholder={t('DevelopmentDiary.EditCommentTimePlaceholder')}
                onChange={e => setEditTime(e.target.value)}
              />
            </div>
            {editError && <p style={{ color: 'red', marginTop: '0.5em' }}>{editError}</p>}
          </div>
        }
        footer={
          <div>
            <Button
              onClick={() => {
                setEntryToEdit(null);
                setEditComment('');
                setEditTime('');
                setEditError('');
              }}
            >
              {t('Popup.Cancel')}
            </Button>
            <ButtonWithLoader
              onClick={() => handleEditEntry(entryToEdit, editComment, editTime)}
              type='primary'
              isLoading={isSavingEdits}
            >
              {t('Popup.Save')}
            </ButtonWithLoader>
          </div>
        }
      />

      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>
      <div className={styles.header}>
        <div className={styles.name}>{t('Checkinout.Name')}</div>
      </div>
      {filteredDataPlus.map(item => {
        return <ChildRow childObj={item} prepareDelete={prepareDelete} prepareEdit={prepareEdit} />;
      })}
    </div>
  );
};

export default EssenContainer;
