import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import PT from 'prop-types';

import { getKidsWithLeavingTimeByGroup } from 'library/api/kidDevelopmentDiary';
import { checkInOutKid, getCheckInOutKitaConfig } from 'library/api/kids';
import Input from 'library/common/commonComponents/Inputs/Input';
import useKidsWithBookingTimeEvents from 'library/common/commonHooks/useKidsWithBookingTimeEvents';
import ChildRow from './ChildRow';

import styles from './leavingTimeContainer.module.scss';

const LeavingTimeContainer = ({ kitaId, groupId, isHortType }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [notifyToParentsOnCheckInOutActive, setNotifyToParentsOnCheckInOutActive] = useState(false);

  const { kids, updateKidStatus } = useKidsWithBookingTimeEvents(
    async () => getKidsWithLeavingTimeByGroup(kitaId, groupId),
    checkInOutKid,
  );

  useEffect(() => {
    getCheckInOutKitaConfig().then(res => {
      setNotifyToParentsOnCheckInOutActive(res.data.checkinoutNotificationsActiveParent);
    });
  }, []);

  const filteredData = useMemo(() => {
    let data = [];
    if (searchValue) {
      data = kids.filter(
        item =>
          item.name?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
          item.groupName?.toLowerCase()?.includes(searchValue.toLowerCase()),
      );
    } else {
      data = kids.slice();
    }

    if (sortBy && sortOrder) {
      data.sort((prev, next) => {
        const prevValue = prev[sortBy];
        const nextValue = next[sortBy];

        const prevBookingTime = prev['bookingLeavingTime'];
        const nextBookingTime = next['bookingLeavingTime'];

        if (!prevBookingTime && nextBookingTime) {
          return 1;
        } else if (prevBookingTime && !nextBookingTime) {
          return -1;
        }

        if (!prevValue && !nextValue) {
          return 0;
        }

        if (!prevValue) {
          return sortOrder === 'ASC' ? -1 : 1;
        }

        if (!nextValue) {
          return sortOrder === 'ASC' ? 1 : -1;
        }

        if (sortOrder === 'ASC') {
          if (prevValue < nextValue) {
            return -1;
          }
          if (prevValue > nextValue) {
            return 1;
          }
          return 0;
        } else {
          if (prevValue > nextValue) {
            return -1;
          }
          if (prevValue < nextValue) {
            return 1;
          }
          return 0;
        }
      });
    }

    return data;
  }, [kids, searchValue, sortBy, sortOrder]);

  const handleSortChange = key => {
    let sortKey = key;
    let sortDirection;

    if (!sortBy || sortBy !== key) {
      sortDirection = 'ASC';
    } else if (sortOrder === 'DESC') {
      sortKey = '';
      sortDirection = '';
    } else {
      sortDirection = 'DESC';
    }

    setSortBy(sortKey);
    setSortOrder(sortDirection);
  };

  return (
    <div className={styles.leavingTimeContainerWrapper}>
      <div className={styles.searchInputWrap}>
        <Input
          placeholder={t('Checkinout.Search by name')}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>

      <div className={styles.header}>
        <div className={styles.name} onClick={() => handleSortChange('name')}>
          {t('Checkinout.Name')}
          {sortBy === 'name' && sortOrder && (
            <i className={sortOrder === 'ASC' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />
          )}
        </div>
        <div className={styles.group} onClick={() => handleSortChange('groupName')}>
          {t(`Checkinout.${isHortType ? 'Class' : 'Group'}`)}
          {sortBy === 'groupName' && sortOrder && (
            <i className={sortOrder === 'ASC' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />
          )}
        </div>
        <div className={styles.time} onClick={() => handleSortChange('bookingLeavingTime')}>
          {t('Checkinout.Leaving Time')}
          {sortBy === 'bookingLeavingTime' && sortOrder && (
            <i className={sortOrder === 'ASC' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />
          )}
        </div>
        <div className={styles.info} onClick={() => handleSortChange('info')}>
          <div>
            {t('Checkinout.Info')}
            {sortBy === 'info' && sortOrder && (
              <i className={sortOrder === 'ASC' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />
            )}
          </div>
        </div>
      </div>

      {filteredData.map(item => (
        <ChildRow key={item.kidId} childObj={item} handleUpdateKidStatus={updateKidStatus} notifyToParentsOnCheckInOutActive={notifyToParentsOnCheckInOutActive} />
      ))}
    </div>
  );
};

LeavingTimeContainer.propTypes = {
  kitaId: PT.number.isRequired,
  groupId: PT.number.isRequired,
  isHortType: PT.bool.isRequired,
};

export default LeavingTimeContainer;
