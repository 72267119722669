import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import Select from 'library/common/commonComponents/Inputs/Select';
import { GroupContext } from 'modules/Group/groupModule';
import {
  copyGroupMembers,
  deleteGroupMember,
  transferGroupMembers,
} from 'library/common/commonActions/groupActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import useGetGroupMemberList from '../../../hooks/useGetGroupMemberList';
import useGroupUsersList from '../../../hooks/useGroupUsersList';
import MultipleActions from './MembersFrames/MultipleActions';
import MembersTable from './MembersFrames/MembersTable';
import RemoveMembersPopup from './MembersFrames/RemoveMembersPopup';
import TransferMembersPopup from './MembersFrames/TransferMembersPopup';

import styles from './members.module.scss';

export const Members = ({ userId, showBottomNotificationFromProps, user: activeUser }) => {
  const groupContext = useContext(GroupContext);
  const { groupInfo, reloadGroupInfo, patchGroup } = groupContext;
  const { t } = useTranslation();
  const roleFilterOptions = [
    { value: 0, label: t('GroupPanel.Show all') },
    { value: 1, label: t('GroupPanel.Administrators') },
    { value: 2, label: t('GroupPanel.Members') },
  ];
  const [roleFilter, setRoleFilter] = useState(roleFilterOptions[0]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [openedPopup, setOpenedPopup] = useState('');
  const [isOpenedRemove, setIsOpenedRemove] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [isPopupLoading, setIsPopupLoading] = useState(false);
  const [isRemoveLoading, setIsRemovedLoading] = useState(false);
  const {
    isLoading,
    usersList,
    setFilters,
    setSorting,
    totalPages,
    currentPage,
    setCurrentPage,
    reloadMemberTableInfo,
  } = useGetGroupMemberList(groupInfo.groupId, groupInfo.userList, setIsCheckedAll);
  const {
    tableData,
    patchTableData,
    onCheckAllHandler,
    groupsForTransfer,
    isGroupsLoading,
  } = useGroupUsersList(
    usersList,
    isCheckedAll,
    setIsCheckedAll,
    groupInfo.groupId,
    activeUser.administrationAccess,
  );

  const [searchRequest, setSearchRequest] = useState('');
  const loginUser = tableData.find(item => item.user && userId === item.user.id);

  const isAdminStatus = activeUser.administrationAccess || activeUser.superAdminStatus;
  const isAdmin = isAdminStatus || (loginUser && loginUser.groupRole.role.adminRole);

  const checkNoGroupAdminsLeft = () => {
    const checkedAdmins = tableData.reduce((result, user) => {
      return (result =
        user.groupRole.role.adminRole && user.checked ? result.concat(user.user.id) : result);
    }, []);
    if (checkedAdmins.length) {
      const allAdminMembers = groupInfo.userList.filter(user => user.groupAdminStatus === true);
      return allAdminMembers.length === checkedAdmins.length;
    }
    return false;
  };

  const multipleAct = (withChildren, withSecondParent) => {
    if (checkNoGroupAdminsLeft()) {
      closeRemovePopup();
      setOpenedPopup('');
      setSelectedGroup('');
      showBottomNotificationFromProps(
        t('GroupBody.Members.Operation failed! Please assign other admin'),
        { isFail: true },
      );
      return;
    }

    const users = tableData.filter(item => item.checked);
    const userIds = users.map(user => `userIds=${user.user.id}`).join('&');
    const members = groupInfo.userList.slice();
    const newMembers = users.reduce((result, id) => {
      const index = result.findIndex(user => user.id === `${id.user.id}`);

      if (index !== -1) {
        result.splice(index, 1);
      }

      return result;
    }, members);
    const config = {
      withChildren,
      withSecondParent,
    };

    if (openedPopup === 'Transfer') {
      setIsPopupLoading(true);
      transferGroupMembers(userIds, groupInfo.groupId, selectedGroup, config)
        .then(res => {
          if (res.status === 200) {
            setIsPopupLoading(false);
            setOpenedPopup('');
            setSelectedGroup('');
            patchGroup({
              memberCount: groupInfo.memberCount - users.length,
              userList: newMembers,
            });
            reloadMemberTableInfo();
            showBottomNotificationFromProps(
              t('GroupBody.Members.Selected users in the current group were transferred'),
            );
          }
        })
        .catch(() => {
          setIsPopupLoading(false);
          setOpenedPopup('');
          setSelectedGroup('');
          showBottomNotificationFromProps(t('BottomNotifications.Something went wrong'), {
            isFail: true,
          });
        });
    } else if (openedPopup === 'Copy') {
      setIsPopupLoading(true);
      copyGroupMembers(userIds, groupInfo.groupId, selectedGroup, config)
        .then(res => {
          if (res.status === 200) {
            setIsPopupLoading(false);
            setOpenedPopup('');
            setSelectedGroup('');
            patchGroup({
              memberCount: groupInfo.memberCount - users.length,
              userList: newMembers,
            });
            reloadMemberTableInfo();
            showBottomNotificationFromProps(
              t('GroupBody.Members.Selected users in the current group were copied'),
            );
          }
        })
        .catch(() => {
          setIsPopupLoading(false);
          setOpenedPopup('');
          setSelectedGroup('');
          showBottomNotificationFromProps(t('BottomNotifications.Something went wrong'), {
            isFail: true,
          });
        });
    }
  };

  const multipleRemove = () => {
    if (checkNoGroupAdminsLeft()) {
      closeRemovePopup();
      showBottomNotificationFromProps(
        t('GroupBody.Members.Operation failed! Please assign other admin'),
        { isFail: true },
      );
      return;
    }

    const usersIds = tableData.reduce((result, user) => {
      return (result = user.checked ? result.concat(user.user.id) : result);
    }, []);
    const removedUsers = usersIds.map(id => `userIds=${id}`).join('&');

    setIsRemovedLoading(true);
    deleteGroupMember(groupInfo.groupId, removedUsers)
      .then(res => {
        setIsRemovedLoading(false);
        closeRemovePopup();
        if (res.status === 200) {
          patchTableData(usersIds, 'multidelete');

          if (usersIds.find(item => item === userId)) {
            reloadGroupInfo();
            showBottomNotificationFromProps(
              t('GroupBody.Members.All posts of the user in the current group was deleted'),
            );
          } else {
            const members = groupInfo.userList.slice();
            const newMembers = usersIds.reduce((result, id) => {
              const index = result.findIndex(user => user.id === `${id}`);

              if (index !== -1) {
                result.splice(index, 1);
              }

              return result;
            }, members);

            patchGroup({
              memberCount: groupInfo.memberCount - usersIds.length,
              userList: newMembers,
            });
            reloadMemberTableInfo();
            showBottomNotificationFromProps(
              t('GroupBody.Members.All posts of the user in the current group was deleted'),
            );
          }
        } else if (res.status === 422 || res.response.status === 422) {
          showBottomNotificationFromProps(
            t('GroupBody.Members.Operation failed! Please assign other admin'),
          );
        } else {
          console.log(res);
        }
      })
      .catch(err => {
        setIsRemovedLoading(false);
        closeRemovePopup();
        return console.log(err);
      });
  };

  const searchUsers = () => {
    setFilters([
      {
        filterName: 'search',
        filterValue: searchRequest,
      },
    ]);
  };

  const setRoleFilters = option => {
    setFilters([
      {
        filterName: 'search',
        filterValue: searchRequest,
      },
      {
        filterName: 'role',
        filterValue: +option.value,
      },
    ]);
    setRoleFilter(option);
  };

  const openTransferPopup = () => {
    setOpenedPopup('Transfer');
  };

  const openCopyPopup = () => {
    setOpenedPopup('Copy');
  };

  const closeTransferPopup = () => {
    setSelectedGroup('');
    setOpenedPopup('');
  };

  const openRemovePopup = () => {
    setIsOpenedRemove(true);
  };

  const closeRemovePopup = () => {
    setIsOpenedRemove(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className={styles.searching}>
          <div className={styles.searching__input}>
            <Input
              value={searchRequest}
              placeholder={t('GroupBody.Members.Search by')}
              onChange={e => setSearchRequest(e.target.value)}
            />
          </div>
          <div className={styles.searching__submit} onClick={searchUsers}>
            <i className='fa fa-search' />
          </div>
        </div>
        <div className={styles.roleFilter}>
          <Select options={roleFilterOptions} onSelect={setRoleFilters} selected={roleFilter} />
        </div>
      </div>
      <MultipleActions
        quantity={tableData && tableData.filter(item => item.checked).length}
        remove={openRemovePopup}
        transfer={openTransferPopup}
        copy={openCopyPopup}
        isAdmin={isAdmin}
      />
      <div className={styles.tableWrapper}>
        {isLoading ? (
          <Loader />
        ) : (
          <MembersTable
            patchTableData={patchTableData}
            isCheckedAll={isCheckedAll}
            onCheckAllHandler={onCheckAllHandler}
            tableData={tableData}
            setSorting={setSorting}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageClick={setCurrentPage}
            reloadTable={reloadMemberTableInfo}
            userId={userId}
            showBottomNotificationFromProps={showBottomNotificationFromProps}
            user={activeUser}
          />
        )}
      </div>
      <TransferMembersPopup
        isOpenedTransfer={Boolean(openedPopup)}
        closeTransferPopup={closeTransferPopup}
        multipleAct={multipleAct}
        setSelectedGroup={setSelectedGroup}
        isGroupsLoading={isGroupsLoading}
        groupsForTransfer={groupsForTransfer}
        isTransferLoading={isPopupLoading}
        selectedGroup={selectedGroup}
        openedPopup={openedPopup}
      />
      <RemoveMembersPopup
        isOpenedRemove={isOpenedRemove}
        closeRemovePopup={closeRemovePopup}
        multipleRemove={multipleRemove}
        isRemoveLoading={isRemoveLoading}
      />
    </div>
  );
};

export default connect(
  state => ({
    isLoggedIn: state.authReducer.isLoggedIn,
    userId: state.userReducer.id,
    user: state.userReducer,
  }),
  {
    showBottomNotificationFromProps: showBottomNotification,
  },
)(Members);
