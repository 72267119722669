import React, { useEffect, useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import Card from 'library/common/commonComponents/Card';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Label from 'library/common/commonComponents/Label';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { Trans, useTranslation } from 'react-i18next';
import { getKidGroupAbsence, getMemberGroupAbsence } from 'library/api/user';
import Button from 'library/common/commonComponents/Buttons/Button';
import { createPDFAbsence } from 'library/utilities/files';
import SortIndicator from 'library/common/commonComponents/SortIndicator';
import Input from 'library/common/commonComponents/Inputs/Input';
import ConfirmPopup from 'library/common/commonComponents/Popups/ConfirmPopup';
import { getGroupById } from 'library/api/groups';
import GroupAbsenceKidPopup from './GroupAbsenceKidPopup';
import AbsenceRow from './AbsenceRow';
import { deleteKidAbsenceByTimeRange, getAbsenceNotificationsActiveParent } from 'library/api/user';
import Checkbox from 'library/common/commonComponents/Checkbox';

import styles from './groupAbsence.module.scss';

const arraySearch = async (array, keyword) => {
  const searchTerm = keyword.toLowerCase();
  return array.filter(value => {
    return (
      value.firstName.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.lastName.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.reason.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.sickness.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.reportedBy.toLowerCase().match(new RegExp(searchTerm, 'g'))
    );
  });
};

const sliceDataToPages = data => {
  const perChunk = 10;
  return data.reduce((all, one, i) => {
    const ch = Math.floor(i / perChunk);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);
};

const sortList = (result, sorting) => {
  if (result.length > 0) {
    let sorted = [];
    if (sorting.direction === 'asc') {
      sorted = [...result].sort((a, b) =>
        a[sorting.name] > b[sorting.name]
          ? 1
          : a[sorting.name] === b[sorting.name]
          ? a.reportedDate > b.reportedDate
            ? 1
            : -1
          : -1,
      );
    } else {
      sorted = [...result].sort((a, b) =>
        a[sorting.name] > b[sorting.name]
          ? -1
          : a[sorting.name] === b[sorting.name]
          ? a.reportedDate > b.reportedDate
            ? -1
            : 1
          : 1,
      );
    }
    return sorted;
  }
  return [];
};

function GroupAbsence({ userLanguage, groupId, activeKita }) {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [nonSearchResult, setNonSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [sorting, setSorting] = useState({ direction: 'desc', name: 'startDate' });
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [actualPageData, setActualPageData] = useState([]);
  const [slicedData, setSlicedData] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [selectedKidForPopup, setSelectedKidForPopup] = useState({});
  const [showDeleteAbsenceConfirmPopup, setShowDeleteAbsenceConfirmPopup] = useState(false);
  const timeRangeDefault = {startDate: null, endDate: null};
  const [deleteTimeRange, setDeleteTimeRange] = useState(timeRangeDefault);
  const [selectedTimeRangeError, setSelectedTimeRangeError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [messageConfirmPopup, setMessageConfirmPopup] = useState('');
  const [messageToParent, setMessageToParent] = useState(false);

  const [showHoliday, setShowHoliday] = useState(true);
  const [showSickness, setShowSickness] = useState(true);

  const [formFrom, setFormFrom] = useState(new Date("2020-01-01"));
  const [formTo, setFormTo] = useState(new Date("2025-01-01"));

  const { t } = useTranslation();

  const loadData = async (from = new Date("2020-01-01"), to = new Date("2025-01-01"), withHoliday = true, withSickness = true) => {
    setIsLoading(true);
    await Promise.all([getKidGroupAbsence(groupId), getMemberGroupAbsence(groupId)]).then(res => {
      const { data: data1 } = res[0];
      const { data: data2 } = res[1];
      const sorted = sortList([...data1, ...data2], sorting);

      let kids = [];

      if (withHoliday) kids = kids.concat(sorted.filter(item => item.vacation == true));
      if (withSickness) kids = kids.concat(sorted.filter(item => item.vacation == false)); 

      // filter out all kids which startDate is between formFrom and formTo
      kids = kids.filter(item => {
        var startDate = new Date(item.startDate);
        if(isNaN(startDate)) {
          // for IOS Versions (< IOS 16) this is needed
          const s = item.startDate.split(" ")[0].split("-");
          startDate = new Date( s[0], parseInt(s[1]) - 1, s[2], 0, 0, 0 );
        }
        return startDate >= from && startDate <= to;
      });
      
      setResult(
        sortList(kids.map(absenceData => ({
          ...absenceData,
          reportedById:
            absenceData.reportedBy && absenceData.reportedBy.id ? absenceData.reportedBy.id : 0,
          reportedBy:
            absenceData.reportedBy &&
            absenceData.reportedBy.firstName &&
            absenceData.reportedBy.lastName
              ? absenceData.reportedBy.firstName + ' ' + absenceData.reportedBy.lastName
              : '',
          firstName: absenceData.kid ? absenceData.kid.firstName : absenceData.user.firstName,
          lastName: absenceData.kid ? absenceData.kid.lastName : absenceData.user.lastName,
          returnReceiptName:
            absenceData.returnReceiptUser === null
              ? ''
              : absenceData.returnReceiptUser.firstName +
                ' ' +
                absenceData.returnReceiptUser.lastName,
        })), sorting),
      );
    });
    await getGroupById(groupId).then(data => {
      setGroupName(data.data.groupName);
      setIsLoading(false);
    });
  };

  const increasePage = () => {
    if (page < slicedData.length - 1) {
      setPage(page + 1);
    }
  };

  const decreasePage = () => {
    if (page > 0) {
      setPage(prev => prev - 1);
    }
  };

  const sortBy = nameOfSortObject => {
    if (sorting.name === nameOfSortObject) {
      if (sorting.direction === 'asc') {
        setSorting({ direction: 'desc', name: nameOfSortObject });
      } else {
        setSorting({ direction: 'asc', name: nameOfSortObject });
      }
    } else {
      setSorting({ direction: 'asc', name: nameOfSortObject });
    }
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const sendToParentRes = await getAbsenceNotificationsActiveParent();
        setMessageToParent(sendToParentRes.data);
        await loadData();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setResult(sortList(result, sorting));
  }, [sorting]);

  useEffect(() => {
    if (searchValue.length >= 1) {
      setIsSearching(prev => {
        if (!prev) {
          setNonSearchResult(result);
        }
        return true;
      });
      arraySearch(nonSearchResult, searchValue).then(data => {
        setResult(data);
      });
    } else {
      setIsSearching(false);
      setResult(nonSearchResult);
    }
  }, [searchValue]);

  useEffect(() => {
    window.addEventListener('onAbsenceCreated', () => {
      loadData();
    });
    window.addEventListener('onAbsenceConfirm', () => {
      loadData();
    });
    return () => {
      window.removeEventListener('onAbsenceCreated', null);
      window.removeEventListener('onAbsenceConfirm', null);
    };
  }, []);

  useEffect(() => {
    const sliced = sliceDataToPages(result);
    setSlicedData(sliced);
  }, [result]);

  useEffect(() => {
    setActualPageData(slicedData.length === 0 ? [] : slicedData[page]);
  }, [page, slicedData]);

  function objectIsEmpty(object) {
    return (
      object &&
      Object.keys(object).length === 0 &&
      Object.getPrototypeOf(object) === Object.prototype
    );
  }

  const handleDeleteDateChange = (date, type) => {
    setDeleteTimeRange(prevState => ({
      ...prevState,
      [type]: date,
    }));
  };

  const handleSetForDeleteAbsencePopup = () => {
    setDeleteTimeRange(timeRangeDefault);
    setSelectedTimeRangeError('');
    setShowDeleteAbsenceConfirmPopup(true);
  };

  const setMessageAndShowConfirmPopup = () => {
    if(!deleteTimeRange.startDate || !deleteTimeRange.endDate) {
      setSelectedTimeRangeError(t('Absences.PleaseSelectTimeRange'));
      return;
    }
    const startDateFormat = format(deleteTimeRange.startDate, "dd.MM.yyyy");
    const endDateFormat = format(deleteTimeRange.endDate, "dd.MM.yyyy");
    const updatedMessage = `${t('Absences.DeleteAbsenceTimeRangeConfirm')} ${startDateFormat} - ${endDateFormat}?`;
    setMessageConfirmPopup(updatedMessage);
    setShowConfirmPopup(true);
  }

  const handleConfirmDeleteAbsence = ()=> {
    setIsSubmitting(true);
    const startDateFormat = format(deleteTimeRange.startDate, 'yyyy-MM-dd');
    const endDateFormat = format(deleteTimeRange.endDate, 'yyyy-MM-dd');
    deleteKidAbsenceByTimeRange({
      startDate: startDateFormat,
      endDate: endDateFormat,
      groupId,
    })
      .then(() => {
        setShowDeleteAbsenceConfirmPopup(false);
        setIsSubmitting(false);
        loadData();
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          setSelectedTimeRangeError(error.response.data.message);
        } else {
          setSelectedTimeRangeError('Something went wrong');
        }
        setIsSubmitting(false);
      });
  };

  return (
    <Card className={styles.wrapper}>
      <h1>{t('Absences.GroupAbsences')}</h1>
      <div>
        <div className={styles.checkbox}>
          <Checkbox
                isChecked={showHoliday}
                onChange={() => { setShowHoliday(!showHoliday); loadData(formFrom, formTo, !showHoliday, showSickness); }}
                name={t('AbsencePopup.History.showHoliday')}
          />
          <Checkbox
                isChecked={showSickness}
                onChange={() => { setShowSickness(!showSickness); loadData(formFrom, formTo, showHoliday, !showSickness); }}
                name={t('AbsencePopup.History.showSickness')}
          />
        </div>

        <div className={styles.dateWrapper}>
          <div className={styles.dateStart}>
            <Label className={styles.label} type='input'>
              {t('AccountSettings.AbsenceSettings.From')}
            </Label>
            <DatePicker
              selected={formFrom}
              onChange={data => { setFormFrom(data); loadData(data, formTo, !showHoliday, showSickness); }}
            />
          </div>
          <div className={styles.dateEnd}>
            <Label className={styles.label} type='input'>
              {t('AccountSettings.AbsenceSettings.To')}
            </Label>
            <DatePicker
              selected={formTo}
              onChange={data => { setFormTo(data); loadData(formFrom, data, !showHoliday, showSickness); }}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loader className={styles.loader} />}
      {(result.length !== 0 || isSearching) && (
        <div className={styles.tableWrapperOverflow}>
          <Button
            onClick={() => {
              createPDFAbsence(result, groupName, activeKita.kitaName);
            }}
            type='light'
            size='sm'
          >
            {t('Absences.Export')}
          </Button>
          <Button
            className={styles.absenceDeleteButton}
            onClick={handleSetForDeleteAbsencePopup}
            type='light'
            size='sm'
          >
            {t('Absences.Delete')}
          </Button>

          <Popup
            size={'extraSmall'}
            isOpened={showDeleteAbsenceConfirmPopup}
            closePopup={() => {
              setShowDeleteAbsenceConfirmPopup(false);
            }}
            header={
              <strong>
                {t(
                  'Absences.SelectTimeRange',
                )}
              </strong>
            }
            body={
              <>
                <div className={styles.absenceTimeRangeWrapper}>
                  <div className={styles.dateStart}>
                    <Label type='input'>{t('Calendar.Start Date')}</Label>
                    <DatePicker
                      selected={deleteTimeRange.startDate}
                      onChange={date => handleDeleteDateChange(date, 'startDate')}
                    />
                  </div>
                  <div className={styles.endStart}>
                    <Label type='input'>{t('Calendar.End Date')}</Label>
                    <DatePicker
                      selected={deleteTimeRange.endDate}
                      onChange={date => handleDeleteDateChange(date, 'endDate')}
                    />
                  </div>
                </div>
                {!!selectedTimeRangeError && (
                  <div className={styles.timeRangeError}>{selectedTimeRangeError}</div>
                )}
              </>
            }
            footer={
              <ButtonWithLoader onClick={()=> setMessageAndShowConfirmPopup()} type='primary' isLoading={isSubmitting}>
                {t('Absences.Delete')}
              </ButtonWithLoader>
            }
          />

          <ConfirmPopup
            isOpened={showConfirmPopup}
            closePopup={() => setShowConfirmPopup(false)}
            onConfirm={() => {
              setShowConfirmPopup(false);
              handleConfirmDeleteAbsence();
            }}
            text={messageConfirmPopup}
          />

          <div className={styles.filters}>
            <div className={styles.filtersSearch}>
              <Input
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                className={styles.filtersSearchInput}
                placeholder={t('GroupBody.Absences.Input placeholder')}
              />
            </div>
          </div>
          <table className={cn(styles.table, styles.absenceTable)}>
            <tbody>
              <tr>
                <th />
                <th className={cn(styles.headerText)} onClick={() => sortBy('firstName')}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.Child firstname' />
                    <SortIndicator sort={sorting} name='firstName' />
                  </div>
                </th>
                <th className={cn(styles.headerText)} onClick={() => sortBy('lastName')}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.Child Lastname' />
                    <SortIndicator sort={sorting} name='lastName' />
                  </div>
                </th>
                <th className={cn(styles.headerText)} onClick={() => sortBy('vacation')}>
                  <div className={styles.headerTextContainer} style={{ paddingRight: "8px" }}>
                    { "U / K" }
                  </div>
                </th>
                <th className={cn(styles.headerText)} onClick={() => sortBy('reason')}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.Reason' />
                    <SortIndicator sort={sorting} name='reason' />
                  </div>
                </th>
                <th className={cn(styles.headerText)} onClick={() => sortBy('sickness')}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.Sickness' />
                    <SortIndicator sort={sorting} name='sickness' />
                  </div>
                </th>
                <th className={cn(styles.headerText)} onClick={() => sortBy('reportedDate')}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.Reported Date' />
                    <SortIndicator sort={sorting} name='reportedDate' />
                  </div>
                </th>
                <th className={cn(styles.headerText)} onClick={() => sortBy('reportedBy')}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.Reported by' />
                    <SortIndicator sort={sorting} name='reportedBy' />
                  </div>
                </th>
                <th className={cn(styles.headerText)} onClick={() => sortBy('startDate')}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.Begin' />
                    <SortIndicator sort={sorting} name='startDate' />
                  </div>
                </th>
                <th className={cn(styles.headerText)} onClick={() => sortBy('endDate')}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.End' />
                    <SortIndicator sort={sorting} name='endDate' />
                  </div>
                </th>
                <th className={cn(styles.headerText)}>
                  <div className={styles.headerTextContainer}>
                    <Trans i18nKey='GroupBody.Absences.ReturnReceipt' />
                  </div>
                </th>
              </tr>
              {actualPageData.map((item, index) => (
                <AbsenceRow
                  key={index}
                  item={item}
                  index={index}
                  userLanguage={userLanguage}
                  setSelectedKidForPopup={setSelectedKidForPopup}
                  messageToParent={messageToParent}
                />
              ))}
            </tbody>
          </table>
          {!objectIsEmpty(selectedKidForPopup) && (
            <GroupAbsenceKidPopup
              popupOpen={!objectIsEmpty(selectedKidForPopup)}
              setSelectedKidForPopup={setSelectedKidForPopup}
              popupData={selectedKidForPopup}
            />
          )}
          <div className={styles.tablePaginationControl}>
            <div className={styles.pageArrowControl}>
              <div
                className={styles.doubleChevronLeft}
                disabled={page === 0}
                onClick={() => setPage(0)}
              >
                <i className='fa fa-chevron-left' />
                <i className='fa fa-chevron-left' />
              </div>
              <div className={styles.singleChevronLeft}>
                <i className='fa fa-chevron-left' onClick={decreasePage} />
              </div>
            </div>
            <div className={styles.pages}>
              {slicedData.map((item, index) => (
                <div
                  key={index}
                  className={cn(
                    styles.paginationItem,
                    index === page && styles.paginationItemSelected,
                  )}
                  onClick={() => setPage(index)}
                >
                  {index + 1}
                </div>
              ))}
            </div>
            <div className={styles.pageArrowControl}>
              <div className={styles.singleChevronRight}>
                <i className='fa fa-chevron-right' onClick={increasePage} />
              </div>
              <div
                className={styles.doubleChevronRight}
                disabled={page === slicedData.length - 1}
                onClick={() => setPage(slicedData.length - 1)}
              >
                <i className='fa fa-chevron-right' />
                <i className='fa fa-chevron-right' />
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}

const mapStateToProps = state => ({
  userLanguage: state.userReducer.langCode,
  groupId: state.groupReducer.groupId,
  activeKita: state.kitaReducer.activeKita,
});

export default connect(mapStateToProps)(GroupAbsence);
