import {
  CHECK_IN_OUT_ACTIVE_FOR_PARENTS_SIGNAL
} from 'library/common/commonConstants/moduleSettingsConstants';

const initialState = {
  checkInOutActiveForParentsSignal: false,
};

export default function ModuleSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_IN_OUT_ACTIVE_FOR_PARENTS_SIGNAL:
      return {
        ...state,
        checkInOutActiveForParentsSignal: !state.checkInOutActiveForParentsSignal,
      };
    default:
      return state;
  }
}
