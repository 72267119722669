import * as translationsTypes from '../commonConstants/translationsConstants';

const initialState = {};

const TranslationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case translationsTypes.WRITE_TRANSLATION_STRING:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};

export default TranslationsReducer;
