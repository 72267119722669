import React from 'react';
import cn from 'classnames';

import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';

import styles from './buttonWithLoader.module.scss';

export default function ButtonWithLoader({ isLoading, children, type, className, ...buttonProps }) {
  return (
    <Button
      className={cn({ [styles.isLoading]: isLoading }, getClassByType(type), className)}
      type={type}
      {...buttonProps}
    >
      {children}
      <Loader dotColor='#fff' dotSize='8px' className={styles.loader} />
    </Button>
  );
}

function getClassByType(type) {
  switch (type) {
    case 'info':
      return styles.buttonInfo;
    case 'primary':
      return styles.buttonPrimary;
    case 'danger':
      return styles.buttonDanger;
    default:
      return styles.buttonDefault;
  }
}
