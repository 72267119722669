import React, { useState, useEffect } from 'react';
import 'react-photoswipe/lib/photoswipe.css';
import { PhotoSwipe } from 'react-photoswipe';
import { isVideo } from 'library/utilities/files';
import { store } from 'main/store/configureStore';
import ContactBazaarSellerPopup from 'library/common/commonComponents/Popups/ContactBazaarSellerPopup';
import './styles/useGallery.scss';
import './styles/useGalleryAsBazaar.scss';
import { useTranslation } from 'react-i18next';
import {
  downloadFile,
  downloadFileChat,
} from '../commonComponents/Gallery/galleryFrames/GalleryItemImage';

import Button from '../commonComponents/Buttons/Button';

const initializeDownloadButton = async file => {
  try {
    let buttonElements = document.getElementsByClassName('pswp__top-bar');
    if (buttonElements && buttonElements.length > 0) {
      const preloaderElement = document.getElementsByClassName('pswp__preloader')[0];
      [buttonElements] = buttonElements;

      const buttonElement = document.createElement('button');
      buttonElement.id = 'pswp__button__download';
      buttonElement.className = 'pswp__button__download';

      buttonElement.innerHTML = `
        <span class="pswp__button__download__span">
          <i class="fa fa-download"></i>
        </span>
      `;

      buttonElement.onclick = function() {
        if (window.location.pathname.startsWith('/chat')) {
          downloadFileChat(file);
        } else {
          downloadFile(file);
        }
      };

      preloaderElement.before(buttonElement);
    }
  } catch (error) {
    console.log(error);
  }
};

const initializeRotationButton = () => {
  try {
    let buttonElements = document.getElementsByClassName('pswp__top-bar');
    if (buttonElements && buttonElements.length > 0) {
      const preloaderElement = document.getElementsByClassName('pswp__preloader')[0];
      [buttonElements] = buttonElements;

      const buttonElement = document.createElement('button');
      buttonElement.id = 'pswp__button__rotate';
      buttonElement.className = 'pswp__button__download';

      buttonElement.innerHTML = `
        <span class="pswp__button__download__span">
          <i class="fa fa-repeat"></i>
        </span>
      `;

      buttonElement.onclick = function() {
        for (const element of document.getElementsByClassName('pswp__img')) {
          if (element.style.transform === '') {
            element.style.transform = 'rotate(90deg)';
          } else if (element.style.transform === 'rotate(90deg)') {
            element.style.transform = 'rotate(180deg)';
          } else if (element.style.transform === 'rotate(180deg)') {
            element.style.transform = 'rotate(270deg)';
          } else {
            element.style.transform = '';
          }
        }
      };

      preloaderElement.before(buttonElement);
    }
  } catch (error) {
    console.log(error);
  }
};

const changeDownloadButtonLink = file => {
  const buttonElement = document.getElementById('pswp__button__download');
  if (buttonElement) {
    buttonElement.onclick = function() {
      if (window.location.pathname.startsWith('/chat')) {
        downloadFileChat(file);
      } else {
        downloadFile(file, '?original=true');
      }
    };
  }
};

const initializeSaveButton = (callback) => {
  try {
    let buttonElements = document.getElementsByClassName('pswp__top-bar');
    if (buttonElements && buttonElements.length > 0) {
      const preloaderElement = document.getElementsByClassName('pswp__preloader')[0];
      [buttonElements] = buttonElements;

      const buttonElement = document.createElement('button');
      buttonElement.id = 'pswp__button__save';
      buttonElement.className = 'pswp__button__download';

      buttonElement.innerHTML = `
        <span class="pswp__button__download__span">
          <i class="fa fa-save"></i>
        </span>
      `;

      buttonElement.onclick = function () {
        const newImg = document.querySelector('img.pswp__img');
        const style = window.getComputedStyle(newImg);
        const transform = style.getPropertyValue('transform');
        let rotationAngle = 0;

        if (transform && transform !== 'none') {
          const matrix = transform.split('(')[1].split(')')[0].split(',');
          const angle = Math.round(Math.atan2(matrix[1], matrix[0]) * (180 / Math.PI));
          rotationAngle = (angle < 0 ? angle + 360 : angle); // Convert negative angles to positive
        }
        if(!rotationAngle) {
          callback(null);
          return;
        }
        rotateImage(newImg.src, rotationAngle)
        .then((dataURL) => {
          const img = new Image();
          img.src = dataURL;
          const fileName = newImg.src.split('/').pop();
          const file = dataURLToFile(dataURL, fileName);
          callback(file);
        })
        .catch((error) => {
          callback(null);
        });
        function rotateImage(imageBase64, rotation) {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageBase64;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              let width, height;

              if (rotation === 180) {
                width = img.width;
                height = img.height;
              } else if (rotation === 270) {
                width = img.height;
                height = img.width;
              } else {
                width = img.height;
                height = img.width;
              }

              canvas.width = width;
              canvas.height = height;
              canvas.style.position = "absolute"
              const ctx = canvas.getContext('2d');

              if (rotation === 180) {
                ctx.translate(width, height);
                ctx.rotate(Math.PI);
              } else if (rotation === 270) {
                ctx.translate(0, height);
                ctx.rotate(-Math.PI / 2);
              } else {
                ctx.translate(width, height / width);
                ctx.rotate(Math.PI / 2);
              }

              ctx.drawImage(img, 0, 0);
              const url = canvas.toDataURL('image/jpeg');
              resolve(url);
            };

            img.onerror = reject;
          });
        }

        function dataURLToFile(dataURL, filename) {
          const mimeType = dataURL.split(',')[0].split(':')[1].split(';')[0];
          const byteString = atob(dataURL.split(',')[1]);
          const buffer = new ArrayBuffer(byteString.length);
          const uint8Array = new Uint8Array(buffer);
          for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: mimeType });
          return new File([blob], filename, { type: mimeType });
        }
      };

      preloaderElement.before(buttonElement);
    }
  } catch (error) {
    console.log(error);
  }
};

export default function useGallery(isBazaar = false, onSaveNewImage) {
  const user = store.getState().userReducer;
  const group = store.getState().groupReducer;

  const imageDownloadModule = (group.groupAdminStatus || window.location.pathname === '/dashboard')
    ? store.getState().kitaReducer.modules.find(mod => mod.moduleKey === 'image_download')
    : group?.groupModuleList?.find(mod => mod.moduleKey === 'image_download');

  const downloadAvailable =  imageDownloadModule ? imageDownloadModule.activeStatus : false;

  const [isGalleryVisible, setIsGalleryVisible] = useState(false);
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const LSImagesDownload = localStorage.getItem('images_download_active', 'yes');

  if (downloadAvailable) {
    localStorage.setItem('images_download_active', 'yes');
  } else if (LSImagesDownload) {
    localStorage.removeItem('images_download_active');
  }

  const openGallery = (files, options = {}) => {
    setIsGalleryVisible(true);
    const fullSizedFiles = files.map(file => {
      return { ...file, path: `${file.path}` };
    });
    setGalleryFiles(fullSizedFiles);
    setGalleryIndex(options.index ? options.index : 0);
  };

  const { kitaId } = store.getState().kitaReducer.activeKita;
  return {
    openGallery,
    Gallery: () => (
      <Gallery
        postType='3'
        downloadAvailable={downloadAvailable}
        isGalleryVisible={isGalleryVisible}
        setIsGalleryVisible={setIsGalleryVisible}
        galleryFiles={galleryFiles}
        galleryIndex={galleryIndex}
        kitaId={kitaId}
        isBazaar={isBazaar}
        onSaveNewImage={onSaveNewImage}
      />
    ),
  };
}

export function Gallery({
  isGalleryVisible,
  setIsGalleryVisible,
  galleryFiles,
  galleryIndex,
  downloadAvailable,
  kitaId,
  isBazaar,
  onSaveNewImage
}) {
  const { t } = useTranslation();
  const [slides, setSlides] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [sendMessagePopupOpen, setSendMessagePopupOpen] = useState(false);
  useEffect(() => {
    // Generate slides objects from images array
    if (galleryFiles.length) {
      const slidesTmp = galleryFiles.map(({ fileId, path }) => ({
        html: `
<          <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <video style="max-height: 100%;width: 600px" controls preload='metadata'>
              <source src="${path}#t=0.01" />
            </video>
          </div>>
        `,
        src: isVideo(fileId) ? null : `${path}`,
        w: 0,
        h: 0,
      }));
      setSlides(slidesTmp);
    }
  }, [galleryFiles]);

  // Calculate real image size for current slide on slide was changed (required by photoswipe library)
  const onSlideChanged = e => {
    const index = e.getCurrentIndex();
    setCurrentFile(galleryFiles[index]);
    if (onSaveNewImage && !document.getElementById('pswp__button__save')) {
      initializeSaveButton((img)=> {
        onSaveNewImage(img);
        setIsGalleryVisible(false);
      });
    }
    if (!document.getElementById('pswp__button__rotate')) {
      initializeRotationButton();
    }
    if (downloadAvailable) {
      if (!document.getElementById('pswp__button__download')) {
        initializeDownloadButton(galleryFiles[index]);
      } else {
        changeDownloadButtonLink(galleryFiles[index]);
      }
    }

    if (slides[index].w !== 0) return null;
    const slidesTmp = slides.slice();
    const img = new Image();

    img.onload = function() {
      slidesTmp[index].w = this.width;
      slidesTmp[index].h = this.height;

      setSlides(slidesTmp);
    };
    img.src = galleryFiles[index].path;
  };

  const onContactSeller = () => {
    setSendMessagePopupOpen(true);
  };

  const closePopup = () => {
    setSendMessagePopupOpen(false);
  };

  return (
    isGalleryVisible &&
    slides.length !== 0 && (
      <>
        <div
          className={
            currentFile && isBazaar && currentFile.bazaarItemInfo ? 'photoslideContainer' : ''
          }
        >
          <PhotoSwipe
            isOpen={isGalleryVisible}
            items={slides}
            afterChange={onSlideChanged}
            options={{
              index: galleryIndex,
              bgOpacity: 0.9,
              shareEl: false,
            }}
            onClose={() => setIsGalleryVisible(false)}
            close={() => setIsGalleryVisible(false)}
          />
        </div>
        {currentFile && isBazaar && currentFile.bazaarItemInfo && (
          <>
            <div className='imageDetailsContainerStyle scrollContainer'>
              <div className='imageDetails'>
                <div className='flex-1 imageDetailsItem'>
                  <h2>Titel</h2>
                  <div>{currentFile.description}</div>
                </div>

                <div className='flex-2 imageDetailsItem'>
                  <h2>Beschreibung</h2>
                  <div className='scroll'>{currentFile.bazaarItemInfo.description}</div>
                </div>

                <div className='flex-1 imageDetailsItem'>
                  <div className='price'>
                    <span>{t('Bazaar.Price')} </span>
                    <strong>{parseFloat(currentFile.bazaarItemInfo.price).toFixed(2)}€</strong>
                  </div>
                  <br />
                  <Button onClick={onContactSeller}>{t('Bazaar.Contact Seller')}</Button>
                </div>
              </div>

              <ContactBazaarSellerPopup
                closePopup={closePopup}
                isOpened={sendMessagePopupOpen}
                file={currentFile}
                kitaId={kitaId}
                receipientId={currentFile.userId}
              />
            </div>
          </>
        )}
      </>
    )
  );
}
