import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';

import Portal from 'library/common/commonComponents/Portal';

import styles from './tooltip.module.scss';

Tooltip.defaultProps = {
  position: 'top',
  isVisibleCondition: true,
  wrapperClassName: '',
};

export default function Tooltip({
  fontSize,
  text,
  children,
  isVisibleCondition,
  position,
  className,
  wrapperClassName,
}) {
  const [isOpened, setIsOpened] = useState(false);

  const tooltipRef = useRef(null);
  const [{ top, left }, setCoordinates] = useState({ top: 0, left: 0 });

  useEffect(() => {
    setCoordinates(getCoordinates(tooltipRef.current, position));
    // eslint-disable-next-line
  }, [isOpened]);

  return (
    <>
      <Portal isOpened={isOpened}>
        {isVisibleCondition && (
          <div
            style={{
              position: 'absolute',
              top,
              left,
            }}
          >
            <div
              className={cn(
                className,
                styles.tooltip,
                { [styles.tooltipIsOpened]: isOpened },
                getClassByPosition(position),
              )}
              style={{ fontSize }}
            >
              <div className={styles.tooltipText}>{text}</div>
            </div>
          </div>
        )}
      </Portal>
      <div
        onMouseEnter={() => setIsOpened(true)}
        onMouseLeave={() => setIsOpened(false)}
        ref={tooltipRef}
        className={wrapperClassName}
      >
        {children}
      </div>
    </>
  );
}

export function getCoordinates(content, position) {
  const rect = content.getBoundingClientRect();

  const top = getTopCoordinate(content, rect, position);
  const left = getLeftCoordinate(content, rect, position);

  return {
    top,
    left,
  };
}

export function getTopCoordinate(content, rect, position) {
  switch (position) {
    case 'right':
      return rect.top + window.pageYOffset + content.offsetHeight / 2;
    default:
      return rect.top + window.pageYOffset;
  }
}

export function getLeftCoordinate(content, rect, position) {
  window.c = content;
  switch (position) {
    case 'right':
      return rect.left + content.offsetWidth + window.pageXOffset;
    case 'left':
      return rect.left + window.pageXOffset;
    default:
      return rect.left + content.offsetWidth / 2 + window.pageXOffset;
  }
}

export function getClassByPosition(position) {
  switch (position) {
    case 'left':
      return styles.positionLeft;
    case 'right':
      return styles.positionRight;
    default:
      return null;
  }
}
