import React from 'react';


export default function DefaultConsentTextEnglish({ activeKita }) {

  return (<>
    <b>Declaration of consent to use the application</b>
    <br />
    <br />
    <p>
      The daycare center “{activeKita.kitaName}” in {activeKita.city}, in collaboration with Link IT isi GmbH from Nuremberg, provides parents with a daycare app “Stramplerbande” (“application”) for the purpose of informing parents and communicating with parents at the facility. For Link IT isi GmbH, the protection of your personal data has the highest priority. Your data is not part of the business model and your data will only be processed to the extent necessary for the purpose of our application. We always adhere to legal principles - in particular the requirements of the General Data Protection Regulation.
    </p>
    <p>
      In this declaration of consent we inform you about the processing of your personal data and your rights within the framework of the online offer. The daycare app is operated in certified German data centers, which are designed to be redundant and therefore ensure a high level of stability and security.
    </p>
    <br />

    <br />
    <p>
      However, in order to be able to offer you this service, we need your declaration of consent and the personal data mentioned on the following page. We ask you to complete the data and sign the page and send it back to us.
    </p>
    <br />
    <b>Information obligations</b>
    <br />
    <b>Collection of data from the data subject § 17 DSG-EKD</b>
    <br />
    <br />
    <p>
      <b>1. Name of the processing activity</b>
    </p>
    <br />
    <p>
      <b>Stramplerbande</b>
    </p>
    <br />
    <p>
      <b>2. Name and contact details of the person responsible</b>
    </p>
    <br />
    <p>
      <b>Responsible body for data collection:</b>
    </p>
    <br />
    <table>
      <tr>
        <td>
          <b>Name of the organization:</b>
        </td>
        <td>{activeKita.kitaName}</td>
      </tr>
      <tr>
        <td>
          <b>Contact person:</b>
        </td>
        <td>{activeKita.kitaChiefName}</td>
      </tr>
      <tr>
        <td>
          <b>Facility address:</b>
        </td>
        <td>{`${activeKita.streetName} ${activeKita.houseNo}`}</td>
      </tr>
      <tr>
        <td />
        <td>{`${activeKita.zipCode} ${activeKita.city}`}</td>
      </tr>
      <tr>
        <td>
          <b>Phone:</b>
        </td>
        <td>{activeKita.phoneNo}</td>
      </tr>
      <tr>
        <td>
          <b>E-mail:</b>
        </td>
        <td>{activeKita.email}</td>
      </tr>
    </table>
    <br />
    <br />
    <p>
      <b>3. Contact details of the data protection officer</b>
    </p>
    <br />
    <table>
      <tr>
        <td>
          <b>Surname:</b>
        </td>
        <td>{activeKita.dataProcessingName}</td>
      </tr>
      <tr>
        <td>
          <b>Company:</b>
        </td>
        <td>{activeKita.dataProcessingCompany}</td>
      </tr>
      <tr>
        <td>
          <b>Phone:</b>
        </td>
        <td>{activeKita.dataProcessingPhone}</td>
      </tr>
      <tr>
        <td>
          <b>E-mail:</b>
        </td>
        <td>{activeKita.dataProcessingEmail}</td>
      </tr>
    </table>
    <br />
    <br />
    <p>
      <b>4. Purposes and legal basis of data processing</b>
    </p>
    <br />
    <p>
      We process personal data in compliance with the provisions of the EU General Data Protection Regulation (GDPR), DSG-EKD, the Bavarian Data Protection Act and all other relevant laws.
    </p>
    <br />
    <p>
      The processing of personal data takes place on the basis of your declaration of consent and serves exclusively for the purpose of the processing activity mentioned above.
    </p>
    <br />
    <p>
      All content posted on the platform is posted on a voluntary basis and in accordance with the additional usage instructions.
    </p>
    <br />
    <p>
      The legal basis for processing your personal data is based on Section 6 No. 2 DSG-EKD.
    </p>
    <br />
    <p>
      <b>5. Recipients or categories of recipients of the personal data</b>
    </p>
    <br />
    <p>
      Personal data and other content posted on the platform will not be used commercially in any way by the responsible body. It will only be passed on in the sense of providing the range of functions used.
    </p>
    <br />
    <p>
      <b>6. Transfer of personal data to a third country</b>
    </p>
    <br />
    <p>
      The personal data we collect will not be transferred to a third country.
    </p>
    <br />
    <p>
      <b>7. Duration of storage of personal data</b>
    </p>
    <br />
    <p>
      The personal data collected with your declaration of consent will be deleted when you revoke your consent or when you end your use of the service. As soon as the facility is permanently left, the user's data and the user's account will be deleted. Content data is automatically deleted after 2 years.
    </p>
    <br />
    <p>
      <b>8. Rights of those affected</b>
    </p>
    <br />
    <p>According to the General Data Protection Regulation and the DSG-EKD you have the following rights:</p>
    <br />
    <ul>
      <li>
        If your personal data is processed, you have the right to receive information about the data stored about you (§ 19 DSG-EKD).
      </li>
      <li>
        If incorrect personal data is processed, you have the right to correction (§ 20 DSG-EKD).
      </li>
      <li>
        If the legal requirements are met, deletion or restriction of processing can be requested and an objection can be lodged against the processing (Sections 21, 22 and 25 DSG-EKD).
      </li>
      <li>
        With your consent to data processing or if there is a contract for data processing and the data processing is carried out using automated procedures, you may have a right to data portability (Art. 24 DSG-EKD).
      </li>
      <li>
        If you make use of the above-mentioned rights, the institution will check whether the legal requirements for this are met.
      </li>
      <li>
        In accordance with Section 46 (1) DSG EKD, any data subject may, without prejudice to further legal remedies, lodge a complaint with the responsible supervisory authority if they believe that their rights have been violated during the collection, processing or use of their personal data by church authorities to be. You can find the EKD data protection officer responsible at  <a href='www.datenschutz.ekd.de'>www.datenschutz.ekd.de</a>.
      </li>
    </ul>
    <br />
    <p>
      <b>9. Right to withdraw consent</b>
    </p>
    <br />
    <p>
      Consent to the processing of your personal data by means of a corresponding declaration can be revoked at any time in the future. The same applies to personal data provided voluntarily. The lawfulness of data processing based on consent or voluntary information until revocation is not affected by this objection.
    </p>
    <br />
    <p>
      If you revoke your consent to the processing of your personal data, services for which we need this data may not be able to be carried out. If you wish to revoke your consent, please send it in writing to the above postal address.
    </p>
    <br />
    <p>
      <b>10. Obligation to provide data</b>
    </p>
    <br />
    <p>
      The use of the application cannot be offered without providing your personal data.
    </p>
    <br />
    <p>
      <b>Additional usage instructions</b>
    </p>
    <br />
    <p>
      <b>
        General obligations of the user and special provisions for the use of the application
      </b>
    </p>
    <br />
    <p>
      The user is obliged to observe applicable law and all third-party rights when using the application and the content. The user is particularly prohibited from:
    </p>
    <br />
    <p>
      <b>1. Image, sound and video files</b>
    </p>
    <br />
    <p>
      Image, sound and video files published on the application may only be viewed within the application for your own use. They may not be downloaded, printed or published for permanent storage. Parents who download, print or publish files without authorization will face civil and possibly criminal consequences. The institution assumes no liability for improper use to distribute the aforementioned content.
    </p>
    <br />
    <p>
      <b>2. Prohibited Content</b>
    </p>
    <br />
    <p>
      Use of offensive or defamatory content, regardless of whether this content affects other users, employees of Link IT isi GmbH or other people or companies.
    </p>
    <br />
    <p>
      Use of pornographic, violent, abusive, immoral or youth protection law-violating content or advertising, offering and/or distribution of pornographic, violent, abusive, immoral or youth protection law-violating goods or services.
    </p>
    <br />
    <p>
      <b>3. Protected Content</b>
    </p>
    <br />
    <p>
      Use of content protected by law (e.g. by copyright, trademark, patent, design or utility model law) without being authorized to do so.
    </p>
    <br />
    <p>
      <b>4. Legal guardians</b>
    </p>
    <br />
    <p>
      Changes regarding persons with legal guardianship must be communicated immediately to the management of the childcare center, especially if this results in the blocking of an account.
    </p>
    <br />
    <p>
      <b>5. No passing on to third parties</b>
    </p>
    <br />
    <p>
      The account (user name, email address, KiTa code) for access to the application may not be passed on to third parties under any circumstances.
    </p>
    <br />
    <p>
      <b>Consequences if the application is used improperly</b>
    </p>
    <br />
    <p>
      Users' accounts can be blocked immediately and without giving reasons by the group administrator or Link IT isi GmbH employee. This particularly applies if the application is used improperly.
    </p>
    <br />
    <br />
  </>)
}