import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getAwoVirtualKitas } from 'library/api/kita';
import Card from 'library/common/commonComponents/Card';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Loader from 'library/common/commonComponents/Loader';
import Wrapper from 'library/common/commonComponents/Wrapper';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import styles from './awoWWOverview.module.scss';

const CityNode = ({ city, awoKitas }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='city-node'>
      <div className={styles.nodeHeader} onClick={() => setIsOpen(!isOpen)}>
        <i className={isOpen ? 'fa fa-minus' : 'fa fa-plus'} />
        {city}
      </div>
      {isOpen && (
        <div className={styles.contentPanel}>
          {awoKitas.map(vKita => {
            return (
              <div key={vKita.id} className={styles.kitaItem}>
                <Link className={styles.groupLink} to={`/awo-kitas/${vKita.id}/articles`}>
                  {vKita.kitaName}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const VirtualKitaTreeView = ({ virtualKitas }) => {
  const { t } = useTranslation();
  const awoKitasGroupByCity = getAWOKitasGroupByCity(virtualKitas);

  return (
    <div className='awo-virtual-tree-view'>
      {!!Object.keys(awoKitasGroupByCity).length && (
        <>
          <Card className={styles.cardContainer}>
            <p className={styles.groupListTitle}>{t('AWO.Articles Overview')}</p>
            {Object.keys(awoKitasGroupByCity).map(city => (
              <CityNode key={city} city={city} awoKitas={awoKitasGroupByCity[city]} />
            ))}
          </Card>
          <br />
        </>
      )}
    </div>
  );
};

// Function to organize groups by city and kita
const getAWOKitasGroupByCity = data => {
  // Group kitas by city
  const groupedKitas = data.reduce((acc, kita) => {
    const city = kita.kitaBelongedCity;
    if (!acc[city]) {
      acc[city] = [];
    }
    acc[city].push(kita);
    return acc;
  }, {});

  // Sort the cities and kitas within each city
  const sortedKitas = {};
  Object.keys(groupedKitas)
    .sort() // Sort the city names alphabetically
    .forEach(city => {
      sortedKitas[city] = groupedKitas[city].sort((a, b) => {
        // Sort kitas by kitaName alphabetically
        return a.kitaName.localeCompare(b.kitaName);
      });
    });

  return sortedKitas;
};

export default function AwoWWOverview({ showBottomNotification, user, activeKita }) {
  useSiteTitle('AwoWW profile Overview');

  const [virtualKitas, setVirtualKitas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPublicGroupList = useCallback(() => {
    setIsLoading(true);
    getAwoVirtualKitas().then(res => {
      setVirtualKitas(res.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchPublicGroupList();
  }, [fetchPublicGroupList]);

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className='awo-overview-page'>
          {isLoading ? <Loader /> : <VirtualKitaTreeView virtualKitas={virtualKitas} />}
        </div>
      </Wrapper>
    </KitaPanelWrapper>
  );
}
