import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import store from 'main/store/configureStore';
import likeIcon from 'resources/images/like/likeIcon.svg';
import likeIconAwo from 'resources/images/like/likeIconAwo.svg';
import unlikeIcon from 'resources/images/like/unlikeIcon.svg';
import unlikeIconAwo from 'resources/images/like/unlikeIconAwo.svg';
import LikeButtonCounter from './likeButtonFrames/LikeButtonCounter';

import styles from './likeButton.module.scss';

LikeButton.defaultProps = {
  withLikesModal: true,
  localLikes: [],
};

export default function LikeButton({
  className,
  localLikes,
  isLiked,
  likeHandler,
  withLikesModal,
  group,
}) {
  const oldIsLiked = useRef(isLiked);
  const [isLikeAnimating, setIsLikeAnimating] = useState(false);
  useEffect(() => {
    if (oldIsLiked.current !== isLiked) {
      setIsLikeAnimating(false);
      oldIsLiked.current = isLiked;
      if (isLiked) {
        setIsLikeAnimating(true);
      }
    }
  }, [isLiked]);

  const user = store.getState().userReducer;

  return (
    <div
      className={cn(styles.likeButton, className)}
      onClick={withLikesModal ? () => { } : likeHandler}
    >
      <div onClick={withLikesModal ? likeHandler : () => { }} className={styles.likeIconWrapper}>
        {isLiked ? (
          <img
            src={(window.location.hostname.toLowerCase().includes('staging2.safe2connect.org') || window.location.hostname.toLowerCase().includes('awo-immer-dabei.safe2connect.org')) ? unlikeIconAwo : unlikeIcon}
            className={isLikeAnimating ? styles.likeIconWithAnimation : ''}
            alt='unlike'
          />
        ) : (
          <img src={(window.location.hostname.toLowerCase().includes('staging2.safe2connect.org') || window.location.hostname.toLowerCase().includes('awo-immer-dabei.safe2connect.org')) ? likeIconAwo : likeIcon} className={styles.removeLikeIcon} alt='like' />
        )}
      </div>
      <LikeButtonCounter
        likes={localLikes}
        user={user}
        withLikesModal={withLikesModal}
        group={group}
      />
    </div>
  );
}
