import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './blockedGroupsListGroup.module.scss';

export default function BlockedGroupsListGroup({ group }) {
  const { t } = useTranslation();

  return (
    <Link to={`/groups/${group.id}`} className={styles.group}>
      <div className={styles.logo} style={{ backgroundColor: group.colourCode }}>
        {group.logoUrl ? (
          <img src={group.logoUrl} alt='logo' className={styles.img} />
        ) : (
          <div className={styles.avatarGroupLetter}>{group.groupName.trim()[0].toUpperCase()}</div>
        )}
      </div>
      <div>
        <div className={styles.groupTitle}>{group.groupName}</div>
        <div className={styles.groupMembers}>
          {group.memberCount} {t('AccountSettings.members')}
        </div>
      </div>
    </Link>
  );
}
