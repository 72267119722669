import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  formatDateOnlyTime,
  returnTimeDifferenceInMinutes,
  formatDateOnlyTimeGermanTimezone,
  now,
} from 'library/utilities/dateMoment';
import { adjustTimeZone } from 'library/utilities/date';

import styles from './dateRow.module.scss';

const DateRow = ({ date, groupedHistory, absences, wraps }) => {
  const [dateRowCollapsed, setDateRowCollapsed] = useState(true);
  const [eat, setEat] = useState([]);
  const [eatUndoCount, setEatUndoCount] = useState(0);
  const [sleep, setSleep] = useState([]);
  const [leaving, setLeaving] = useState([]);
  const [presenceTime, setPresenceTime] = useState([]);
  const [checkin, setCheckin] = useState([]);
  const [checkinout, setCheckinout] = useState([]);
  const [pedagogicalObservation, setPedagogicalObservation] = useState([]);
  const [foodOrder, setFoodOrder] = useState([]);
  const [sleepTime, setSleepTime] = useState({ duration: 0, start: '', end: null });
  const [eatTime, setEatTime] = useState('');
  const [checkInOutTime, setCheckInOutTime] = useState({
    duration: 0,
    start: '',
    end: null,
    personToPickUpChild: null,
  });

  const { t } = useTranslation();
  useEffect(() => {
    setEat(groupedHistory.filter(obj => obj.action === 'eat'));
    setEatUndoCount(groupedHistory.filter(obj => obj.action === 'eat-undo').length);
    setSleep(groupedHistory.filter(obj => obj.action === 'sleep'));
    setLeaving(groupedHistory.filter(obj => obj.action === 'leavingTime'));
    setCheckinout(groupedHistory.filter(obj => obj.action === 'checkinout'));
    setCheckin(groupedHistory.filter(obj => obj.action === 'CHECKIN' || obj.action === 'CHECKOUT'));
    setPedagogicalObservation(
      groupedHistory.filter(obj => obj.action === 'pedagogicalObservation'),
    );
    setFoodOrder(groupedHistory.filter(obj => obj.action.includes('food-order')));
    setPresenceTime(groupedHistory.filter(obj => obj.action.includes('presence')));
  }, []);

  useEffect(() => {
    if (sleep.length > 0) {
      setSleepTime(calculateTime(sleep));
    }
    if (eat.length > 0) {
      setEatTime(calculateTime(eat));
    }
    if (checkin.length > 0) {
      setCheckInOutTime(calculateTimeCheckInOut(checkin));
    }
  }, [sleep]);

  function formatMinutesToString(minutes) {
    if (minutes < 60) return minutes + ' min';
    else return Math.floor(minutes / 60) + ' h ' + (minutes % 60) + ' min';
  }

  function formatDateToString(date) {
    return (
      date
        .getDate()
        .toString()
        .padStart(2, '0') +
      '.' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '.' +
      date.getFullYear() +
      ' ' +
      extractTime(date)
    );
  }

  function extractTime(dateStr) {
    const date = new Date(dateStr);
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0'); // Use padStart to add leading zero if necessary
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  function calculateTimeCheckInOut(checkInOutArray) {
    if (checkInOutArray.length > 0) {
      if (checkInOutArray.length % 2 === 0) {
        for (let i = 0; i < checkInOutArray.length; i++) {
          if (i % 2 !== 0) {
            const startSleepTime = formatDateOnlyTime(
              adjustTimeZone(`${checkInOutArray[i - 1].date}:00`),
            );
            const endSleepTime = formatDateOnlyTime(
              adjustTimeZone(`${checkInOutArray[i].date}:00`),
            );
            const difference = returnTimeDifferenceInMinutes(startSleepTime, endSleepTime);
            return {
              duration: formatMinutesToString(difference),
              start: startSleepTime,
              end: endSleepTime,
              personToPickUpChild:
                checkInOutArray[i].personToPickUpChild ??
                checkInOutArray[i - 1].personToPickUpChild ??
                undefined,
            };
          }
        }
      } else {
        const startSleepTime = formatDateOnlyTimeGermanTimezone(checkInOutArray[0].date);

        return {
          duration: 0,
          start: startSleepTime,
          personToPickUpChild: checkInOutArray[0].personToPickUpChild ?? undefined,
        };
      }
    }
    return { duration: 0 };
  }

  function calculateTime(sleepArray) {
    const onlyActions = sleepArray.filter(obj => obj.additionalTextMessage === null);
    if (onlyActions.length > 0) {
      if (onlyActions.length % 2 === 0) {
        for (let i = 0; i < onlyActions.length; i++) {
          if (i % 2 !== 0) {
            const startSleepTime = formatDateOnlyTime(adjustTimeZone(onlyActions[i - 1].date));
            const endSleepTime = formatDateOnlyTime(adjustTimeZone(onlyActions[i].date));
            const difference = returnTimeDifferenceInMinutes(startSleepTime, endSleepTime);
            return {
              duration: formatMinutesToString(difference),
              start: startSleepTime,
              end: endSleepTime,
            };
          }
        }
      } else {
        const startSleepTime = formatDateOnlyTimeGermanTimezone(onlyActions[0].date);

        return {
          duration: 0,
          start: startSleepTime,
        };
      }
    }
    return { duration: 0 };
  }

  return (
    <>
      <div
        onClick={() => {
          setDateRowCollapsed(!dateRowCollapsed);
        }}
        className={styles.dateRowWrapper}
      >
        <div style={{ color: 'white', verticalAlign: 'middle' }}>
          <b>{date}</b>
          <i
            className={dateRowCollapsed ? 'fa fa-arrow-down' : 'fa fa-arrow-up'}
            style={{ color: 'white' }}
          />
        </div>
      </div>
      <br />

      {!dateRowCollapsed && (
        <>
          {absences.map(absence => {
            return (
              <>
                <div className={styles.rowItem}>
                  <div className={styles.rowItemTitle}>
                    <h3>{t('Checkinout.Time checked out')}</h3>
                  </div>
                  <div>
                    <div>
                      <>
                        <b>{t('Checkinout.Reason')}: </b>
                        {absence.reason === 'krankehit' ? 'Krankheit' : absence.reason}

                        <p>
                          Von {formatDateToString(new Date(absence.startDate))} bis{' '}
                          {formatDateToString(new Date(absence.endDate))}
                        </p>
                      </>
                    </div>
                  </div>
                </div>
                <br />
              </>
            );
          })}
          {checkin.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Check in or out')}</h3>
                </div>
                <div>
                  <div>
                    {checkInOutTime.end ? (
                      <>
                        <b>{t('Checkinout.Time checked in')}: </b>
                        {checkInOutTime.duration}
                        <p>
                          Von {checkInOutTime.start} bis {checkInOutTime.end}
                        </p>
                        {checkInOutTime.personToPickUpChild && (
                          <p>
                            <b>{t('Checkinout.PickupPerson')}:</b>{' '}
                            {checkInOutTime.personToPickUpChild}
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <b>Checkin Zeit: </b>
                        {checkInOutTime.start}
                        <p>
                          <b>eingecheckt seit: </b>
                          {formatMinutesToString(
                            returnTimeDifferenceInMinutes(
                              checkInOutTime.start,
                              now().format('HH:mm'),
                            ),
                          )}
                        </p>
                        {checkInOutTime.personToPickUpChild && (
                          <p>
                            <b>{t('Checkinout.PickupPerson')}:</b>{' '}
                            {checkInOutTime.personToPickUpChild}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <br />
            </>
          )}
          {presenceTime.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.PresenceTime')}</h3>
                </div>
                <div>
                  {presenceTime.map(obj => {
                    return (
                      <>
                        <div>
                          <b>
                            {obj.action.includes('checkin')
                              ? t('Presence.Checked In')
                              : t('Presence.Checked out')}
                            :{' '}
                          </b>{' '}
                          &nbsp;
                          <b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <br />
            </>
          )}
          {eat.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Eat')}</h3>
                </div>
                <div>
                  <div>
                    <b>Essenzeit: </b>
                    {eatTime.start}
                  </div>
                  <div>
                    <b>{t('Checkinout.Portions eaten')}: </b>
                    {eat.filter(obj => obj.additionalTextMessage === null).length - eatUndoCount}
                  </div>
                  {eat
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span>{obj.additionalTextMessage}</span>
                          <br />
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {leaving.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.LeavingTime')}</h3>
                </div>
                <div>
                  {leaving
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <div>
                            <b>{t('Checkinout.LeavingTime')}: </b> &nbsp;
                            <b>{formatDateOnlyTime(obj.date)}</b>
                          </div>
                          <div>
                            <b>{t('Checkinout.Note')}: </b>
                            <span>{obj.additionalTextMessage}</span>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {checkinout.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Note')}</h3>
                </div>
                <div>
                  {checkinout
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span>{obj.additionalTextMessage}</span>
                          <br />
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {pedagogicalObservation.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Pedagogical observation')}</h3>
                </div>
                <div>
                  {pedagogicalObservation
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span>{obj.additionalTextMessage}</span>
                          <br />
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {sleep.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Sleep')}</h3>
                </div>
                <div>
                  <div>
                    {sleepTime.end ? (
                      <>
                        <b>{t('Checkinout.Minutes asleep')}: </b>
                        {sleepTime.duration}
                        <p>
                          Von {sleepTime.start} bis {sleepTime.end}
                        </p>
                      </>
                    ) : (
                      <>
                        <b>Einschlafzeit: </b>
                        {sleepTime.start}
                        <p>
                          <b>schläft seit: </b>
                          {formatMinutesToString(
                            returnTimeDifferenceInMinutes(sleepTime.start, now().format('HH:mm')),
                          )}
                        </p>
                      </>
                    )}
                  </div>

                  {sleep
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <div key={obj.date}>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span>{obj.additionalTextMessage}</span>
                          <br />
                        </div>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {wraps.reverse().map(wrapEntry => {
            if (wrapEntry.additionalTextMessage) {
              return (
                <>
                  <div className={styles.rowItem}>
                    <div className={styles.rowItemTitle}>
                      <h3>{t('Checkinout.Wrap Notice')}</h3>
                    </div>
                    <div>
                      <div>
                        <b>Hinweis: </b>
                        {wrapEntry.additionalTextMessage}
                      </div>
                      <div>
                        <b>Zeit: </b>
                        {formatDateOnlyTime(wrapEntry.date)}
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              );
            } else {
              return (
                <>
                  <div className={styles.rowItem}>
                    <div className={styles.rowItemTitle}>
                      <h3>{t('Checkinout.Wrap')}</h3>
                    </div>
                    <div>
                      <div>
                        <b>Wickelzeit: </b>
                        {formatDateOnlyTime(wrapEntry.date)}
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              );
            }
          })}
          {foodOrder.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('FoodOrder.Food orders')}</h3>
                </div>
                <div>
                  {foodOrder
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <span>
                            {t('FoodOrder.Food order for')}
                            &nbsp;{obj.additionalTextMessage}
                            &nbsp;
                            {obj.action.includes('cancel')
                              ? t('FoodOrder.Canceled')
                              : t('FoodOrder.Created')}
                          </span>
                          <br />
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
        </>
      )}
    </>
  );
};

export default DateRow;
