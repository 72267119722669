import React from 'react';
import cn from 'classnames';

import styles from './textArea.module.scss';

export default function TextArea({
  placeholder,
  error,
  faIcon,
  className,
  value,
  onChange,
  onBlur,
  maxLength,
  tabIndex,
  disabled,
}) {
  return (
    <div className={cn(styles.inputContainer, disabled && styles.disabledTextArea)}>
      <textarea
        className={cn(className, error && styles.input_border_error, !error && styles.input_border)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        // eslint-disable-next-line
        maxLength={maxLength}
        tabIndex={tabIndex || 0}
        disabled={disabled}
      />

      {faIcon && (
        <div className={styles.inputIcon}>
          <i className={cn('fa', faIcon)} />
        </div>
      )}
    </div>
  );
}
