import React from 'react';
import cn from 'classnames';

import styles from './loader.module.scss';

export default function Loader({ className, dotSize, dotColor, isSmall }) {
  const dotStyle = {
    backgroundColor: dotColor,
    width: dotSize,
    height: dotSize,
  };

  return (
    <div className={cn(styles.loader, className)}>
      <div className={cn(styles.loaderDot, isSmall && styles.small)} style={dotStyle} />
      <div className={cn(styles.loaderDot, isSmall && styles.small)} style={dotStyle} />
      <div className={cn(styles.loaderDot, isSmall && styles.small)} style={dotStyle} />
    </div>
  );
}
