import React, { useState } from 'react';
import Picker from 'emoji-picker-react';
import styles from './emojiPickerButton.module.scss';

export default function EmojiPickerButton({ sketchWhiteboard, setMode, Tools }) {

  const [emojiPickerOpened, setEmojiPickerOpened] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    sketchWhiteboard.current.addText(emojiObject.emoji);
    setMode(prev => {
      return { ...prev, tool: Tools.Select };
    });
    setEmojiPickerOpened(false);
  };

  return (
    <div>
      <div
        className={styles.toolBarIcon}
        onClick={() => {
          setEmojiPickerOpened(!emojiPickerOpened)
        }}
      >

        <i className='fa fa-smile-o' />
      </div>
      {emojiPickerOpened &&
        <div>
          <Picker
            onEmojiClick={onEmojiClick}
            disableSearchBar={true}
            disableSkinTonePicker={true}
            native={true}
            groupVisibility={{
              flags: false,
            }}
          />
        </div>
      }
    </div>
  );
};