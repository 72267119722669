import { connect } from 'react-redux';

import { setCreateGroupMode } from 'library/common/commonActions/groupActions';
import { setActiveKita, setKitas } from 'library/common/commonActions/kitaActions';
import GroupsFrame from '.';

export default connect(
  state => ({
    activeKita: state.kitaReducer.activeKita,
    superAdminStatus: state.userReducer.superAdminStatus,
    user: state.userReducer,
    kitas: state.kitaReducer.kitas,
  }),
  { setCreateGroupMode, setActiveKita, setKitas },
)(GroupsFrame);
