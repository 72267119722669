import { useEffect, useState } from 'react';

export default function ContentAfterMount({ children }) {
  const [shouldShowImage, setShouldShowImage] = useState(false);

  useEffect(() => {
    setShouldShowImage(true);
  }, []);

  return (shouldShowImage && children) || null;
}
