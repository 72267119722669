import React, { useState, useRef, useEffect } from 'react';
import { SketchField, Tools } from 'react-sketch';

import { useHistory } from 'react-router-dom';

import cn from 'classnames';
import ColorPickerButtonCircle from 'library/common/commonComponents/Buttons/ColorPickerButtonCircle';
import CloseKidsModePopup from 'library/common/commonComponents/Popups/CloseKidsModePopup';
import star from 'resources/images/whiteboard/star.png';
import hart from 'resources/images/whiteboard/hart.png';
import sun from 'resources/images/whiteboard/sun.png';
import Input from 'library/common/commonComponents/Inputs/Input';
import { postWhiteboard } from 'library/api/whiteboard';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import UploadImageButton from './UploadImageButton';
import EmojiPickerButton from './EmojiPickerButton';
import RecordAudioFileButton from './RecordAudioFileButton';
import styles from './whiteboard.module.scss';

export default function Whiteboard({
  groupId,
  setActiveTab,
  userEmail,
  isNotInKidsMode,
  editingData,
}) {
  const handle = useFullScreenHandle();

  const [mode, setMode] = useState({
    tool: Tools.Pencil,
    isErasing: false,
  });
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  // temp line color for erasor function
  const [lineColor, setLineColor] = useState({ current: '#000000', temp: '#000000' });
  const [lineColorPickerOpened, setLineColorPickerOpened] = useState(false);
  const [lineWidth, setLineWidth] = useState({ current: 3, temp: 3 });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [text, setText] = useState('');
  const [isFullMenu, setIsFullMenu] = useState(false);

  const sketchWhiteboard = useRef();


  const history = useHistory();

  const downloadCanvas = () => {
    const url = sketchWhiteboard.current.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'whiteboard.png';
    link.href = url;
    link.click();
  };

  useEffect(() => {

    if (sketchWhiteboard && sketchWhiteboard.current) {
      sketchWhiteboard.current.disableTouchScroll();
    }


  }, [sketchWhiteboard.current])

  useEffect(() => {
    if (sketchWhiteboard && sketchWhiteboard.current && editingData !== null) {
      sketchWhiteboard.current.setBackgroundFromDataUrl(editingData.whiteboardData);
    }
  }, [editingData]);

  return (
    <>
      <div style={{ paddingBottom: '20px', border: '1px solid black' }}>
        <FullScreen handle={handle}>
          <div className={styles.toolBar}>
            <div
              className={cn(
                styles.toolBarIcon,
                mode.tool === Tools.Pencil &&
                mode.isErasing === false &&
                styles.toolBarIconSelected,
              )}
              onClick={() => {
                setMode(prev => {
                  return { tool: Tools.Pencil, isErasing: false };
                });
                setLineColor(prev => {
                  return { ...prev, current: lineColor.temp };
                });
                setLineWidth(prev => {
                  return { ...prev, current: lineWidth.temp };
                });
              }}
            >
              <i className='fa fa-pencil' />
            </div>
            {isFullMenu && (<>
              <div
                className={cn(styles.toolBarIcon, mode.tool === Tools.Line &&
                  mode.isErasing === false && styles.toolBarIconSelected)}
                onClick={() => {
                  setMode(prev => {
                    return { tool: Tools.Line, isErasing: false };
                  });
                  setLineColor(prev => {
                    return { ...prev, current: lineColor.temp };
                  });
                  setLineWidth(prev => {
                    return { ...prev, current: lineWidth.temp };
                  });
                }}
              >
                <i className='fa fa-minus' />
              </div>
              <div
                className={cn(styles.toolBarIcon, mode.tool === Tools.Rectangle &&
                  mode.isErasing === false && styles.toolBarIconSelected)}
                onClick={() => {
                  setMode(prev => {
                    return { tool: Tools.Rectangle, isErasing: false };
                  });
                  setLineColor(prev => {
                    return { ...prev, current: lineColor.temp };
                  });
                  setLineWidth(prev => {
                    return { ...prev, current: lineWidth.temp };
                  });
                }}
              >
                <i className='fa fa-square-o' />
              </div>
              <div
                className={cn(styles.toolBarIcon, mode.tool === Tools.Circle &&
                  mode.isErasing === false && styles.toolBarIconSelected)}
                onClick={() => {
                  setMode(prev => {
                    return { tool: Tools.Circle, isErasing: false };
                  });
                  setLineColor(prev => {
                    return { ...prev, current: lineColor.temp };
                  });
                  setLineWidth(prev => {
                    return { ...prev, current: lineWidth.temp };
                  });
                }}
              >
                <i className='fa fa-circle-o' />
              </div>
            </>)}
            <div
              className={cn(
                styles.toolBarIcon,
                mode.tool === Tools.Select && styles.toolBarIconSelected,
              )}
              onClick={() => {
                setMode(prev => {
                  return { tool: Tools.Select, isErasing: false };
                });
                setLineColor(prev => {
                  return { ...prev, current: lineColor.temp };
                });
                setLineWidth(prev => {
                  return { ...prev, current: lineWidth.temp };
                });
              }}
            >
              <i className='fa fa-mouse-pointer' />
            </div>
            <div
              className={cn(
                styles.toolBarIcon,
                mode.tool === Tools.Pencil && mode.isErasing === true && styles.toolBarIconSelected,
              )}
              onClick={() => {
                setMode(prev => {
                  return { tool: Tools.Pencil, isErasing: true };
                });
                setLineColor(prev => {
                  return { ...prev, current: '#ffffff' };
                });
                setLineWidth(prev => {
                  return { ...prev, current: 18 };
                });
              }}
            >
              <i className='fa fa-eraser' />
            </div>
            <div className={cn(styles.numberInputWrapper)}>
              <Input
                type='number'
                min={1}
                max={99}
                value={lineWidth.current}
                className={styles.numberInput}
                onChange={e => {
                  const { value } = e.target;
                  if ((value >= 1 && value <= 99) || value === '') {
                    setLineWidth(prev => {
                      return { current: value, temp: value };
                    });
                  }
                }}
              />
            </div>
            {isFullMenu && (<>
              <div
                className={cn(styles.toolBarIcon)}
                onClick={() => {
                  sketchWhiteboard.current.addImg(star);
                  setMode(prev => {
                    return { tool: Tools.Select, isErasing: false };
                  });
                }}
              >
                <i className='fa fa-star-o' />
              </div>
              <div
                className={cn(styles.toolBarIcon)}
                onClick={() => {
                  sketchWhiteboard.current.addImg(hart);
                  setMode(prev => {
                    return { tool: Tools.Select, isErasing: false };
                  });
                }}
              >
                <i className='fa fa-heart-o' />
              </div>
              <div
                className={cn(styles.toolBarIcon)}
                onClick={() => {
                  sketchWhiteboard.current.addImg(sun);
                  setMode(prev => {
                    return { tool: Tools.Select, isErasing: false };
                  });
                }}
              >
                <i className='fa fa-sun-o' />
              </div>
            </>)}
            {(isNotInKidsMode || (!isNotInKidsMode && isFullMenu)) && (
              <div
                className={cn(
                  styles.toolBarIcon,
                  mode.tool === Tools.Pan && styles.toolBarIconSelected,
                )}
                onClick={() => {
                  setMode(prev => {
                    return { tool: Tools.Pan, isErasing: false };
                  });
                }}
              >
                <i className='fa fa-arrows' />
              </div>
            )}
            <br />
            <UploadImageButton
              sketchWhiteboard={sketchWhiteboard}
              setMode={setMode}
              Tools={Tools}
            />
            <div
              className={cn(styles.toolBarIcon)}
              onClick={() => {
                downloadCanvas();
              }}
            >
              <i className='fa fa-download' />
            </div>
            <div
              className={cn(styles.toolBarIcon)}
              onClick={() => {
                postWhiteboard({
                  whiteboardData: sketchWhiteboard.current.toDataURL('image/png'),
                  groupId,
                  kidsMode: !isNotInKidsMode,
                  audioData: audioData !== null ? audioData.base64data : null,
                }).then(() => {
                  setActiveTab(1);
                });
              }}
            >
              <i className='fa fa-floppy-o' />
            </div>
            <div
              className={cn(styles.toolBarIcon)}
              onClick={() => {
                sketchWhiteboard.current.undo();
              }}
            >
              <i className='fa fa-undo' />
            </div>
            <div
              className={cn(styles.toolBarIcon)}
              onClick={() => {
                sketchWhiteboard.current.redo();
              }}
            >
              <i className='fa fa-repeat' />
            </div>
            <div
              className={cn(styles.toolBarIcon)}
              onClick={() => {
                sketchWhiteboard.current.clear();
                setBackgroundColor(prev => {
                  if (prev === '#ffffff') {
                    return 'white';
                  } else {
                    return '#ffffff';
                  }
                });
              }}
            >
              <i className='fa fa-trash' />
            </div>
            {(isNotInKidsMode || (!isNotInKidsMode && isFullMenu)) && (
              <div>
                <div className={cn(styles.textInputWrapper)}>
                  <div>
                    <Input
                      type='text'
                      value={text}
                      className={styles.textInput}
                      onChange={e => setText(e.target.value)}
                    />
                  </div>
                  <div
                    className={cn(styles.textInputPlus)}
                    onClick={() => {
                      sketchWhiteboard.current.addText(text);
                      setText('');
                      setMode(Tools.Select);
                    }}
                  >
                    <i className='fa fa-plus' />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div
                className={cn(styles.toolBarIcon)}
                style={{ color: lineColor.temp, backgroundColor: '#ffffff' }}
                onClick={() => {
                  setLineColorPickerOpened(!lineColorPickerOpened);
                }}
              >
                <i className='fa fa-paint-brush' />
              </div>
              {lineColorPickerOpened && (
                <div>
                  <ColorPickerButtonCircle
                    color={lineColor}
                    className={styles.pickerComponent}
                    onClick={c => {
                      setLineColor(prev => {
                        return { current: c.hex, temp: c.hex };
                      });
                      setMode(prev => {
                        return { tool: Tools.Pencil, isErasing: false };
                      });
                      setLineWidth(prev => {
                        return { ...prev, current: lineWidth.temp };
                      });
                      setLineColorPickerOpened(false);
                    }}
                  />
                </div>
              )}
            </div>
            {(isNotInKidsMode || (!isNotInKidsMode && isFullMenu)) && (
              <div
                className={cn(styles.toolBarIcon)}
                onClick={() => {
                  if (!isFullScreen) {
                    handle.enter();
                    setIsFullScreen(true);
                  } else {
                    handle.exit();
                    setIsFullScreen(false);
                  }
                }}
              >
                <i className='fa fa-arrows-alt' />
              </div>
            )}
            {(isNotInKidsMode || (!isNotInKidsMode && isFullMenu)) && (
              <div
                className={cn(styles.toolBarIcon)}
                onClick={() => {
                  if (isNotInKidsMode) {
                    history.push({
                      pathname: window.location.pathname,
                      search: '?mode=kids',
                    });
                  } else {
                    setIsPopupOpen(true);
                  }
                }}
              >
                <i className={isNotInKidsMode ? 'fa fa-lock' : 'fa fa-unlock'} />
              </div>
            )}
            <EmojiPickerButton
              sketchWhiteboard={sketchWhiteboard}
              setMode={setMode}
              Tools={Tools}
            />
            <RecordAudioFileButton setAudioData={setAudioData} />

            <div
              className={cn(styles.toolBarIcon)}
              onClick={() => {
                setIsFullMenu(!isFullMenu);
              }}
            >
              <i className={isFullMenu ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
            </div>

          </div>
          <div>
            <SketchField
              width='100%'
              height='480px'
              tool={mode.tool}
              backgroundColor={backgroundColor}
              lineColor={lineColor.current}
              lineWidth={lineWidth.current !== '' ? lineWidth.current : 3}
              ref={sketchWhiteboard}
            />
          </div>
          <CloseKidsModePopup
            isPopupOpen={isPopupOpen}
            setIsPopupOpen={setIsPopupOpen}
            userEmail={userEmail}
          />
        </FullScreen>
      </div>
      {audioData && audioData.blobData && (
        <>
          <br />
          <div>
            <audio
              src={audioData && audioData.blobData && URL.createObjectURL(audioData.blobData)}
              controls
            />
          </div>
        </>
      )}
    </>
  );
}
