import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PT from 'prop-types';
import Loader from 'library/common/commonComponents/Loader';
import ExportRow from './ExportRow';
import styles from './myExportsContainer.module.scss';

const MyExportsContainer = ({ data, deleteExportHandler }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.name}>{t('Administration.UserSection.Name')}</div>
            <div className={styles.url}>{t('Administration.UserSection.Url')}</div>
          </div>
          {data.map(item => {
            return (
              <ExportRow
                exportObj={item}
                onLoadingHandle={setIsLoading}
                deleteExportHandler={deleteExportHandler}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

MyExportsContainer.propTypes = {
  data: PT.arrayOf(PT.object).isRequired,
  deleteExportHandler: PT.func.isRequired,
};

export default MyExportsContainer;
