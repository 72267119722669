import React, { useState, useContext, useEffect, useRef } from 'react';
import { GroupContext } from 'modules/Group/groupModule';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import useEditor from 'library/common/commonHooks/useEditor';
import store from 'main/store/configureStore';
import useFileUploading from 'library/common/commonHooks/useFileUploading';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Loader from 'library/common/commonComponents/Loader';
import Checkbox from 'library/common/commonComponents/Checkbox';
import DragAndDrop from 'library/common/commonComponents/DragAndDrop';
import styles from './wikieditpage.module.scss';
import { createWikiEntry, updateWikiEntry, getWikiEntryParentOptions } from '../../../../api/wiki';
import Button from '../../Buttons/Button';

export default function WikiEditPage({ page, onSave, folderId }) {
  const { t } = useTranslation();
  const { groupInfo } = useContext(GroupContext);

  const [title, setTitle] = useState(page == null ? '' : page.title);
  const [isSaving, setIsSaving] = useState(false);
  const [isHomePage, setIsHomePage] = useState(page == null ? false : page.homePage);
  const [content, setContent] = useState(page == null ? '' : page.content);
  const [parentPage, setParentPage] = useState(page == null ? 'null' : page.parentWikiEntryId);
  const [parentOptions, setParentOptions] = useState([]);
  const [attachments, setAttachments] = useState(null);
  const [existingAttachments, setExistingAttachments] = useState(
    page == null ? [] : page.attachments,
  );
  const [deletedAttachments, setDeletedAttachments] = useState([]);

  let fileInputRef = useRef(null);

  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = content;
  const { Editor, getEditorValue } = useEditor({
    message: wrappedMessage,
    placeholder: t('Wiki.Edit Page Content'),
    onOpen: () => {},
    onClose: () => {},
    onChange: () => {
      const value = getEditorValue();

      setContent(value.innerHTML);
    },
    className: styles.input,
    isChat: false,
  });

  const { onFileUpload } = useFileUploading({
    groupId: groupInfo.groupId,
    moduleId: (groupInfo.groupModuleList.find(({ moduleKey }) => moduleKey === 'wiki') || {})
      .moduleId,
    folderId,
    isPrivateFolder: false,
  });

  useEffect(() => {
    getWikiEntryParentOptions(groupInfo.groupId, page == null ? null : page.wikiEntryId)
      .then(res => {
        setParentOptions(res.data);
      })
      .catch(() => {
        store.dispatch(showBottomNotification(t('Wiki.An error occured'), { isFail: true }));
      });
  }, []);

  const executeUpdate = async () => {
    if (page == null) {
      setIsSaving(true);

      if (onFileUpload == null) {
        store.dispatch(showBottomNotification(t('Wiki.File upload error'), { isFail: true }));
        return;
      }

      // await onFileUpload(attachments);

      createWikiEntry(groupInfo.groupId, title, isHomePage, content, parentPage, attachments)
        .then(() => {
          store.dispatch(
            showBottomNotification(t('Wiki.Wiki entry created successfully'), {
              isFail: false,
            }),
          );

          onSave();
          setIsSaving(false);
        })
        .catch(() => {
          store.dispatch(showBottomNotification(t('Wiki.An error occured'), { isFail: true }));
        });
    } else {
      setIsSaving(true);
      updateWikiEntry(
        groupInfo.groupId,
        page.wikiEntryId,
        title,
        isHomePage,
        content,
        parentPage,
        attachments,
        deletedAttachments,
      )
        .then(() => {
          store.dispatch(
            showBottomNotification(t('Wiki.Wiki entry updated successfully'), {
              isFail: false,
            }),
          );

          onSave();
          setIsSaving(false);
        })
        .catch(() => {
          store.dispatch(showBottomNotification(t('Wiki.An error occured'), { isFail: true }));
        });
    }
  };

  const renderWikiOptions = (options, indent) => {
    return options.length === 0
      ? null
      : options.map(item => {
          return (
            <>
              <option value={item.wikiEntryId}>
                {indent}
                {item.title}
              </option>
              {item.childParentOptions.length === 0
                ? null
                : renderWikiOptions(item.childParentOptions, indent + '--')}
            </>
          );
        });
  };

  const uploadFileHandler = async files => {
    setAttachments(Array.from(files));
  };

  const removeUploadedFile = index => {
    const newAttachments = attachments.slice(0);
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  const markAttachmentDeleted = (attachment, index) => {
    const newExistingAttachments = existingAttachments.slice(0);
    newExistingAttachments.splice(index, 1);
    setExistingAttachments(newExistingAttachments);

    const newDeletedAttachments = deletedAttachments.slice(0);
    newDeletedAttachments.push(attachment.id);
    setDeletedAttachments(newDeletedAttachments);
  };

  return (
    <div className={styles.pageContainer}>
      <h1>{page == null ? t('Wiki.New Page') : t('Wiki.Edit Page')}</h1>

      {isSaving && <Loader />}
      {!isSaving && (
        <>
          <Input
            value={title}
            className={styles.input}
            placeholder={t('Wiki.New Title')}
            onChange={e => setTitle(e.target.value)}
            maxLength={128}
          />
          <Editor />

          <Checkbox
            name={t('Wiki.Set as home page')}
            isChecked={isHomePage}
            onChange={() => setIsHomePage(!isHomePage)}
            className={styles.input}
          />

          <div className={styles.selectContainer}>
            <label htmlFor='parentSelect' className={styles.selectLabel}>
              {t('Wiki.Select parent page')}
            </label>
            <select
              id='parentSelect'
              onChange={e => setParentPage(e.target.value)}
              value={parentPage}
            >
              <option value='null'>{t('Wiki.No Parent Page')}</option>
              {renderWikiOptions(parentOptions, '')}
            </select>
          </div>

          {(attachments != null || existingAttachments.length > 0) && (
            <div className={styles.attachmentContainer}>
              <h2>{t('Wiki.Attachments')}</h2>
              <ul>
                {attachments != null
                  ? attachments.map((attachment, index) => {
                      return (
                        <li>
                          {attachment.name}{' '}
                          <i className='fa fa-times' onClick={() => removeUploadedFile(index)} />
                        </li>
                      );
                    })
                  : null}
                {existingAttachments.length > 0
                  ? existingAttachments.map((attachment, index) => {
                      return (
                        <li>
                          {attachment.fileName}{' '}
                          <i
                            className='fa fa-times'
                            onClick={() => markAttachmentDeleted(attachment, index)}
                          />
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          )}

          <input
            type='file'
            className={styles.fileInput}
            ref={input => (fileInputRef = input)}
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();
              uploadFileHandler(e.target.files);
            }}
            multiple
          />
          <div className={styles.fileDropArea} onClick={() => fileInputRef.click()}>
            <DragAndDrop onDrop={uploadFileHandler} isInProfileStream={false}>
              <p>{t('Wiki.Drop a file or click to add a file')}</p>
            </DragAndDrop>
          </div>

          <div className={styles.buttonWrapper}>
            <Button onClick={() => executeUpdate()} type='primary'>
              {t('Wiki.Save')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
