import React, { useState, useEffect, useContext, useReducer } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getCheckInOutConfig,
  getKidsStatusAndAbsencesByGroup,
  checkInOutKid,
} from 'library/api/kids';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import Card from 'library/common/commonComponents/Card';
import ArrivalListContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/ArrivalListContainer';
import ArrivalTimeContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/ArrivalTimeContainer';
import ChildesContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/ChildesContainer';
import EssenContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/EssenContainer';
import HistoryContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/HistoryContainer';
import InternalRemarkContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/InternalRemarkContainer';
import LeavingTimeContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/LeavingTimeContainer';
import PedagogicalObservationContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/PedagogicalObservation';
import PresenceDayOverview from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/PresenceDayOverview';
import SchlafenContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/SchlafenContainer';
import TagebuchHistorieContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/TagebuchHistorieContainer';
import WickelContainer from 'library/common/commonComponents/CheckInOut/CheckInOutPopup/WickelContainer';
import Tabs from 'library/common/commonComponents/Tabs';
import TextCaret from 'library/common/commonComponents/TextCaret';
import useCheckInOutKidsEvents from 'library/common/commonHooks/useCheckInOutKidsEvents';
import { GroupContext } from 'modules/Group/groupModule';
import GroupCheckinoutSettings from './GroupCheckinoutSettings';

import styles from './groupCheckinout.module.scss';

function GroupCheckinout() {
  const user = useSelector(state => state.userReducer);
  const kita = useSelector(state => state.kitaReducer);
  const [currentTab, setCurrentTab] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const { t } = useTranslation();
  const groupContext = useContext(GroupContext);
  const { groupInfo } = groupContext;
  const [tabConfig, setTabConfig] = useState(null);
  const [childrenCount, setChildrenCount] = useState(0);

  const { kids, updateKidStatus } = useCheckInOutKidsEvents(
    async () => getKidsStatusAndAbsencesByGroup(groupInfo.groupId),
    checkInOutKid,
  );

  const [kidData, setKidData] = useState(null);
  const [firstTab, setFirstTab] = useState([
    {
      title: `${t('Checkinout.Children')} (${childrenCount}/${kids.length})`,
      component: (
        <ChildesContainer
          data={kids}
          handleUpdateKidStatus={updateKidStatus}
          setKidData={setKidData}
        />
      ),
      path: 'children-history',
    },
  ]);

  useEffect(() => {
    var count = kidData ? kidData.filter(obj => obj.checkInOutStatus == 'CHECKIN').length : 0;
    setChildrenCount(count);
    setFirstTab();
    setTabs([
      {
        title: `${t('Checkinout.Children')} (${count}/${kids.length})`,
        component: (
          <ChildesContainer
            data={kids}
            handleUpdateKidStatus={updateKidStatus}
            setKidData={setKidData}
          />
        ),
        path: 'children-history',
      },
      ...tabs.slice(1),
    ]);
  }, [kidData]);

  useEffect(() => {
    if (kids) {
      setKidData(kids);
    }
  }, [kids]);

  const isHortType = (kita.activeKita.kitaTypeTranslations || []).includes('Hort');
  const kitaId = kita.activeKita.kitaId;

  const baseTabs = [
    {
      title: `${t('Checkinout.Children')} (${childrenCount}/${kids.length})`,
      component: (
        <ChildesContainer
          data={kids}
          handleUpdateKidStatus={updateKidStatus}
          setKidData={setKidData}
        />
      ),
      path: 'children-history',
    },
    {
      title: t('Checkinout.Children History'),
      component: <HistoryContainer groupOnly />,
      path: 'children-list',
    },
  ];

  const [tabs, setTabs] = useState(baseTabs);

  useEffect(() => {
    getCheckInOutConfig(groupInfo.groupId).then(res => {
      setTabConfig(res);
    });
  }, []);

  useEffect(() => {
    if (tabConfig !== null) {
      let res = tabConfig;
      const newTabs = [...baseTabs];

      if (res.data.arrivalListOverviewActive) {
        newTabs.splice(2, 0, {
          title: t('GroupCheckinout.ArrivalList'),
          component: <ArrivalListContainer groupId={groupInfo.groupId} />,
          path: 'arrivalList',
        });
      }

      if (res.data.sleepActive) {
        newTabs.splice(2, 0, {
          title: t('GroupCheckinout.Sleep'),
          component: (
            <SchlafenContainer
              checkedInKidIds={kids.filter(k => k.checkInOutStatus === 'CHECKIN').map(k => k.kidId)}
              groupId={groupInfo.groupId}
            />
          ),
          path: 'schlafen',
        });
      }

      if (res.data.foodActive) {
        newTabs.splice(2, 0, {
          title: t('GroupCheckinout.Food'),
          component: (
            <EssenContainer
              checkedInKidIds={kids.filter(k => k.checkInOutStatus === 'CHECKIN').map(k => k.kidId)}
              groupId={groupInfo.groupId}
            />
          ),
          path: 'essen',
        });
      }

      if (res.data.wrapActive) {
        newTabs.splice(2, 0, {
          title: t('GroupCheckinout.Wrap'),
          component: (
            <WickelContainer
              checkedInKidIds={kids.filter(k => k.checkInOutStatus === 'CHECKIN').map(k => k.kidId)}
              groupId={groupInfo.groupId}
            />
          ),
          path: 'wickeln',
        });
      }

      if (res.data.pedagogicalObservationActive) {
        newTabs.splice(2, 0, {
          title: t('GroupCheckinout.Pedagogical observation'),
          component: (
            <PedagogicalObservationContainer
              checkedInKidIds={kids.filter(k => k.checkInOutStatus === 'CHECKIN').map(k => k.kidId)}
              groupId={groupInfo.groupId}
            />
          ),
          path: 'pedagogicalobservation',
        });
      }

      if (res.data.presenceDayOverviewActive) {
        newTabs.splice(2, 0, {
          title: t('GroupCheckinout.Presence Day Overview'),
          component: <PresenceDayOverview kidIds={kids.map(k => k.kidId)} groupInfo={groupInfo} kita={kita.activeKita} />,
          path: 'presencedayoverview',
        });
      }

      if (res.data.internalRemarkActive && (user.employee || user.superAdminStatus)) {
        newTabs.splice(2, 0, {
          title: t('GroupCheckinout.Internal Remark'),
          component: (
            <InternalRemarkContainer
              checkedInKidIds={kids.filter(k => k.checkInOutStatus === 'CHECKIN').map(k => k.kidId)}
              data={kids}
              groupId={groupInfo.groupId}
            />
          ),
          path: 'internalremark',
        });
      }

      if (res.employee || user.superAdminStatus || groupInfo.groupAdminStatus) {
        if (res.data.arrivalTimeActive) {
          newTabs.push({
            title: t('Checkinout.Arrival Time'),
            component: <ArrivalTimeContainer kitaId={kitaId} groupId={groupInfo.groupId} isHortType={isHortType} />,
            path: 'arrival-time',
          });
        }

        if (res.data.leavingTimeActive) {
          newTabs.push({
            title: t('Checkinout.Leaving Time'),
            component: <LeavingTimeContainer kitaId={kitaId} groupId={groupInfo.groupId} isHortType={isHortType} />,
            path: 'leaving-time',
          });
        }
      }

      if (
        res.data.sleepActive ||
        res.data.foodActive ||
        res.data.wrapActive ||
        res.data.pedagogicalObservationActive
      ) {
        newTabs.push({
          title: t('GroupCheckinout.DiaryHistory'),
          component: <TagebuchHistorieContainer groupId={groupInfo.groupId} />,
          path: 'tagebuchhistorie',
        });
      }

      setTabs(newTabs);
    }
  }, [showSettings, kids, tabConfig]);

  const onSettingsToggle = show => {
    setCurrentTab(0);
    setShowSettings(show);

    getCheckInOutConfig(groupInfo.groupId).then(res => {
      setTabConfig(res);
    });
  };

  return (
    <Card className={styles.wrapper}>
      {showSettings ? (
        <GroupCheckinoutSettings
          setIsSettingsOpen={onSettingsToggle}
          groupId={groupInfo.groupId}
          isHortType={isHortType}
        />
      ) : (
        <>
          <MoreBtn
            dropdownOptions={[
              {
                faIcon: 'fa-cog',
                title: t('GroupCheckinout.Dropdown.Settings'),
                onClick: () => {
                  setShowSettings(true);
                },
              },
            ]}
            faIcon='fa-cog'
            className={styles.headerMoreBtn}
          >
            <TextCaret color='#555' />
          </MoreBtn>
          <Tabs
            tabs={tabs}
            currentTab={tabs[currentTab].path}
            onChange={(_, index) => setCurrentTab(index)}
          />
        </>
      )}
    </Card>
  );
}
const mapStateToProps = state => ({
  userLanguage: state.userReducer.langCode,
});
export default connect(mapStateToProps)(GroupCheckinout);
