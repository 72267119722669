import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tabs from 'library/common/commonComponents/Tabs';

import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import ObservationSheet from './ObservationSheet';
import ParentsTalk from './ParentsTalk';
import AcclimatizationDocumentation from './AcclimatizationDocumentation';

import styles from './help.module.scss';
import ObservationAnalysis from './ObservationAnalysis';
import ColleagueExchange from './ColleagueExchange';

function EducationalWork({ user }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [{
    title: t('GfzDocumentation.Observationsheet'),
    path: 'observationsheet',
    component: <ObservationSheet userId={user.id} />,
  },
  {
    title: t('GfzDocumentation.Parentstalk'),
    path: 'parentstalk',
    component: <ParentsTalk userId={user.id} />,
  },
  {
    title: t('GfzDocumentation.Acclimatizationdocumentation'),
    path: 'acclimatizationdocumentation',
    component: <AcclimatizationDocumentation userId={user.id} />,
  },
  {
    title: t('GfzDocumentation.ObservationAnalysis'),
    path: 'observationanalysis',
    component: <ObservationAnalysis userId={user.id} />,
  },
  {
    title: t('GfzDocumentation.ColleagueExchange'),
    path: 'colleagueexchange',
    component: <ColleagueExchange userId={user.id} />,
  },];

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <Col>
          <Row>
            <div className={styles.container}>
              <Tabs
                tabs={tabs}
                onChange={(_, index) => setActiveTab(index)}
                currentTab={tabs[activeTab].path}
              />
            </div>
          </Row>
        </Col>
      </Wrapper>
    </KitaPanelWrapper>
  );
}

export default connect(state => ({
  user: state.userReducer,
}))(EducationalWork);
