import React, { useState, useContext } from 'react';

import SurveysCreateInput from 'library/common/commonComponents/Inputs/SurveysCreateInput';
import Feed from 'library/common/commonComponents/Feed/FeedContainer';
import { GroupContext } from 'modules/Group/groupModule';

import styles from './surveys.module.scss';

export default function Surveys({ groupInfo, isUserAMember, activeKita }) {
  const { groupId, postCount } = groupInfo;
  const groupContext = useContext(GroupContext);
  const patchGroupInfo = groupContext.patchGroup;

  const [newPost, setNewPost] = useState(null);
  function onCreate(post) {
    setNewPost({
      ...post,
      group: groupInfo,
    });
    patchGroupInfo({ postCount: postCount + 1 });
  }

  return (
    <div className={styles.container}>
      {groupInfo.createSurvey && (
        <SurveysCreateInput
          groupInfo={groupInfo}
          activeKita={activeKita}
          onCreate={onCreate}
        />
      )}
      <Feed
        groupId={groupId}
        onPostDelete={() => patchGroupInfo({ postCount: postCount - 1 })}
        createCommentStatus={groupInfo.createCommentStatus}
        isPrivatePostsOnly={false}
        isPublicPostsOnly={false}
        newPost={newPost}
        group={groupInfo}
        postType={4}
      />
    </div>
  );
}
