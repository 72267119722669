import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getChildrenByUserId } from 'library/api/user';
import styles from './foodOrderParents.module.scss';
import Button from '../../../Buttons/Button';
import ParentsFoodOrder from './ParentsFoodOrder';
import ParentsInvoice from './ParentsInvoice';
import Select from '../../../Inputs/Select';
import Loader from '../../../Loader';
import IngredientShowingPopup from '../../../Popups/IngredientShowingPopup';
import { getIngredients } from 'library/api/foodOrder';

export default function FoodOrderParents({
  groupId,
  showBottomNotification,
  activeKita,
  groupInfo,
}) {
  const { t } = useTranslation();

  const [orderMealIsOpen, setOrderMealIsOpen] = useState(true);
  const [monthlyInvoiceIsOpen, setMonthlyInvoiceIsOpen] = useState(false);
  const [childs, setChilds] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [showIngredientsPopup, setShowIngredientsPopup] = useState(false);

  const handleOpenIngredientPopup = () => {
    setShowIngredientsPopup(true);
  };

  const handleCloseIngredientPopup = () => {
    setShowIngredientsPopup(false);
  };

  const loadIngredients = async () => {
    try {
      const response = await getIngredients(groupId);
      const loadedIngredients = response.data.map(({ referenceName, name }) => ({
        id: Math.random(),
        referenceName,
        name,
      }));
      setIngredients(loadedIngredients);
    } catch (error) {
      // Handle error
      console.error('Error loading ingredients:', error);
    }
  };

  useEffect(() => {
    loadIngredients();
  }, []);

  const toggleSecondNav = path => {
    switch (path) {
      case 'ordermeal':
        setOrderMealIsOpen(true);
        setMonthlyInvoiceIsOpen(false);
        break;
      case 'monthlyinvoice':
        setMonthlyInvoiceIsOpen(true);
        setOrderMealIsOpen(false);
        break;
      default:
        // Something went wrong if we end up here
        break;
    }
  };

  const onSelectChild = option => {
    setSelectedChild(option);
  };

  // Query childs
  useEffect(() => {
    setIsLoading(true);

    getChildrenByUserId(false).then(res => {
      const childList = [];
      for (let i = 0; i < res.data.length; i++) {
        childList.push({
          id: res.data[i].id,
          value: i,
          label: res.data[i].firstName,
          foodOrdersPerWeek: res.data[i].foodOrdersPerWeek,
        });
      }

      setChilds(childList);
      setSelectedChild(childList[0]);
    });

    // Remove timeout
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      <div className={styles.secondnav}>
        <Button
          type={orderMealIsOpen ? 'info' : 'secondary'}
          className={styles.mr2}
          onClick={() => toggleSecondNav('ordermeal')}
        >
          {t('FoodOrder.Order Meal')}
        </Button>
        <Button
          type={monthlyInvoiceIsOpen ? 'info' : 'secondary'}
          onClick={() => toggleSecondNav('monthlyinvoice')}
          className={styles.mr2}
        >
          {t('FoodOrder.Monthly invoices')}
        </Button>
        <Button type='secondary' onClick={handleOpenIngredientPopup}>
          {t('FoodOrder.Show Ingredients')}
        </Button>
      </div>
      <br />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <label>{t('FoodOrder.Select child')}</label>
          <Select
            className={styles.timeSelect}
            placeholder={t('FoodOrder.Select child')}
            selected={selectedChild}
            options={childs}
            onSelect={option => onSelectChild(option)}
          />

          <br />
          {orderMealIsOpen && (
            <ParentsFoodOrder
              selectedChild={selectedChild}
              groupId={groupId}
              groupName={groupInfo.groupName}
              showBottomNotification={showBottomNotification}
              activeKita={activeKita}
            />
          )}
          {monthlyInvoiceIsOpen && (
            <ParentsInvoice
              groupId={groupId}
              selectedChild={selectedChild}
              activeKita={activeKita}
              groupInfo={groupInfo}
            />
          )}
          <IngredientShowingPopup
            isOpened={showIngredientsPopup}
            closePopup={handleCloseIngredientPopup}
            ingredients={ingredients}
          />
        </>
      )}
    </div>
  );
}
