import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import HelpText from 'library/common/commonComponents/HelpText';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { validateEmail } from 'library/utilities/validation';
import { changeUserEmail } from 'library/api/user';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';

import styles from './profileChangeEmail.module.scss';

export default function ProfileChangeEmail({
  showBottomNotification,
  setProfileInfo,
  profileInfo,
}) {
  const [password, setPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [emailSentPopup, setEmailSentPopup] = useState(false);

  const changeEmail = () =>
    changeEmailHandler({
      newEmail,
      setNewEmail,
      password,
      setPassword,
      setEmailError,
      setPasswordError,
      setEmailSentPopup,
      t,
      showBottomNotification,
      isSubmitting,
      setIsSubmitting,
      setProfileInfo,
      profileInfo,
    });

  const { t } = useTranslation();
  return (
    <><div>
      <HelpText className={styles.selectText}>
        {t('AccountSettings.Your current E-mail address is')}{' '}
        <strong className='notranslate'>{profileInfo.email}</strong>.{' '}
        {t('AccountSettings.You can change your current E-mail address here')}
      </HelpText>
      <div className={styles.formGroup}>
        <Label type='input'>{t('AccountSettings.Current password')} *</Label>
        <Input
          value={password}
          type='password'
          onChange={e => setPassword(e.target.value)}
          error={passwordError} />
        {passwordError && <div className={styles.error}>{passwordError}</div>}
      </div>

      <div className={styles.formGroup}>
        <Label type='input'>{t('AccountSettings.New E-Mail address')} *</Label>
        <Input
          value={newEmail}
          type='text'
          onChange={e => setNewEmail(e.target.value)}
          error={emailError} />
        {emailError && <div className={styles.error}>{emailError}</div>}
      </div>

      <div className={styles.buttons}>
        <ButtonWithLoader type='primary' onClick={changeEmail} isLoading={isSubmitting}>
          {t('Post.Save')}
        </ButtonWithLoader>
      </div>
    </div><Popup
      isOpened={emailSentPopup}
      closePopup={() => setEmailSentPopup(false)}
      header={<div className={styles.containerEnable}>{t('AccountSettings.Confirm E-Mail address')}</div>}
      body={
        <div>
          <p><span style={{ fontWeight: 'bold' }}>{t('AccountSettings.Confirm E-Mail address message')}</span></p>
        </div>
      }
      footer={<div className={styles.buttonsContainer}>
        <div className={styles.buttonCancel}>
          <Button onClick={changeEmail}>{t('AccountSettings.Send Again')}</Button>
          <Button onClick={() => setEmailSentPopup(false)}>{t('AccountSettings.Back')}</Button>
          <Button onClick={() => setEmailSentPopup(false)}>{t('AccountSettings.Okay')}</Button>
        </div>
      </div>}
    >
      </Popup></>
  );
}

export async function changeEmailHandler({
  newEmail,
  setNewEmail,
  password,
  setPassword,
  setEmailError,
  setPasswordError,
  setEmailSentPopup,
  t,
  showBottomNotification,
  isSubmitting,
  setIsSubmitting,
  setProfileInfo,
  profileInfo,
}) {
  const trimmed = newEmail.trim();
  if (isSubmitting) {
    return;
  }
  let isError = false;

  if (password.trim().length === 0) {
    isError = true;
    setPasswordError(`${t('Registration.Password cannot be blank')}`);
  } else {
    setPasswordError('');
  }

  if (trimmed.length === 0) {
    isError = true;
    setEmailError(`${t('Administration.Kita.Email')} ${t('Administration.Kita.cannot be blank')}`);
  } else if (!validateEmail(trimmed)) {
    isError = true;
    setEmailError(t('Administration.Kita.Email is incorrect'));
  } else {
    setEmailError('');
  }

  if (isError) return;

  setIsSubmitting(true);

  changeUserEmail({ email: trimmed, password })
    .then(res => {
      if ((res.data && res.data.status === 208) || trimmed === profileInfo.email) {
        setEmailError(t('Administration.UserSection.Please select some other email'));
      } else {
        setEmailSentPopup(true);
        showBottomNotification(t('BottomNotifications.Saved'));
      }
    })
    .catch(ex => {
      switch (ex.response.data.message) {
        case 'Passsowrd is Incorrect':
          return setPasswordError(t('AccountSettings.Password is incorrect'));
        default:
          break;
      }
    })
    .finally(() => {
      setIsSubmitting(false);
    });
}
