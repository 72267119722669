import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setForceRun2Fa } from 'library/common/commonActions/auth2faAction';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Storage from 'library/utilities/storage';
import styles from './fingerprintVerification.module.scss';
import { generatePublicKeyCredential } from './util';

function FingerprintVerification({user}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timeToRecheck = 2 * 60 * 60 * 1000; //2hours
  const storageKeyPrefix = 'status_verify_2af';

  const isActive = useSelector(state => state.auth2FaReducer.isActive);
  const forceRun = useSelector(state => state.auth2FaReducer.forceRun);
  const activeKitaId = useSelector(state => state.kitaReducer.activeKita?.kitaId);
  const [intervalCounter, setIntervalCounter] = useState(0);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const checkAndGenerateCredential = async () => {
      const loginTime = Storage.getItem('loginTime');
      if (!loginTime || !isActive) {
        return;
      } else {
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - parseInt(loginTime, 10);
        const verifyStatus = Storage.getItem(`${storageKeyPrefix}_${activeKitaId}_${user.id}`);

        if (elapsedTime >= timeToRecheck || (forceRun && verifyStatus !== 'verified')) {
          try {
            await generatePublicKeyCredential(() => {
              Storage.setItem('loginTime', currentTime);
              Storage.setItem(`${storageKeyPrefix}_${activeKitaId}_${user.id}`, 'verified');
              setForceRun2Fa(false)(dispatch);
              setShowDialog(false);
            });
          } catch (error) {
            setShowDialog(true);
          }
        }
      }
    };
    checkAndGenerateCredential();
  }, [isActive, forceRun, intervalCounter]);

  useEffect(() => {
    if (!activeKitaId) {
      return;
    }
    const intervalId = setInterval(() => {
      Storage.removeItem(`${storageKeyPrefix}_${activeKitaId}_${user.id}`);
      setForceRun2Fa(true)(dispatch);
      setIntervalCounter(prevCounter => prevCounter + 1);
    }, timeToRecheck);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeKitaId]);

  const reAuthentication = () => {
    setIntervalCounter(prevCounter => prevCounter + 1);
  };

  return (
    <Popup
      size={'extraSmall'}
      isOpened={showDialog}
      showCloseButton={false}
      header={<strong>{t('Fingerprint.Title')}</strong>}
      body={
        <div className={styles.fingerprintContainer}>
          <div className={styles.errorMsg}>{t('Fingerprint.Error')}</div>
          <div className={styles.reAuth} onClick={reAuthentication}>
            {t('Fingerprint.ReAuthentication')}
          </div>
        </div>
      }
    />
  );
}

export default FingerprintVerification;
