import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';

import store from 'main/store/configureStore';
import { getAuthentication } from 'library/common/commonActions/authActions';
import { toggleDisclaimerModal } from 'library/common/commonActions/disclaimerActions';
import { getTenantInfo } from 'library/common/commonActions/tenantActions';
import { useMsalInstance } from 'main/msal';
import Routes from './main/Routes';

import 'resources/styles/resets.scss';
import 'resources/styles/global.scss';
import 'resources/styles/fonts/OpenSans.scss';
import 'animate.css';
import 'react-tabs/style/react-tabs.css';
import useEyeAble from './library/common/commonHooks/useEyeAble';

store.dispatch(getTenantInfo());
store
  .dispatch(getAuthentication())
  .then()
  .catch(() => false);

document.addEventListener('gesturestart', function(e) {
  e.preventDefault();
});

document.oncontextmenu = event => {
  if (localStorage.getItem('images_download_active') === null) {
    if (event && event.path) {
      for (let i = 0; i < event.path.length; i++) {
        if (event.path[i].tagName === 'IMG') {
          event.preventDefault();
        }
      }
    }
  }
};

document.addEventListener('mousedown', function(e) {
  if (e && e.path) {
    for (let i = 0; i < e.path.length; i++) {
      if (e.path[i].tagName === 'A') {
        const linkElement = e.path[i];
        if (
          linkElement.host !== window.location.host &&
          linkElement.host.indexOf(window.location.hostname) === -1 &&
          linkElement.attributes.href &&
          e.which === 1
        ) {
          e.preventDefault();
          document.body.style.overflow = 'hidden';
          store.dispatch(toggleDisclaimerModal(true, linkElement.attributes.href.value));
        }
      }
    }
  }
});

export function App() {
  const { isLoading, msalInstance } = useMsalInstance();

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission();
    }
  }, []);

  //useEyeAble();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        {!msalInstance ? (
          <Routes />
        ) : (
          <MsalProvider instance={msalInstance}>
            <Routes />
          </MsalProvider>
        )}
      </BrowserRouter>
    </Provider>
  );
}

export default App;
