import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './checkilistInput.module.scss';

export default function CheckilistInput({
  index,
  value,
  createEditTaskdispatch,
  createEditTaskState,
}) {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState(value);

  const onCahnge = str => {
    setNewValue(str);
    const newChecklist = createEditTaskState.checklist.slice();
    newChecklist.splice(index, 1, {
      ...newChecklist[index],
      title: str,
    });
    createEditTaskdispatch({ type: 'setChecklist', payload: newChecklist });
  };

  return (
    <div className={styles.container}>
      <Input
        type='text'
        placeholder={t('Tasks.Add checkpoint')}
        value={newValue}
        onChange={e => onCahnge(e.target.value)}
        maxLength={255}
      />
    </div>
  );
}
