import React, { useEffect, useState } from 'react';
import Checkbox from 'library/common/commonComponents/Checkbox';
import { useTranslation } from 'react-i18next';

import styles from './ChildFoodOrderedTable.module.scss';

const ChildFoodOrdered = ({ kid, setKid, disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      <b>{t('Administration.KidSection.Food ordered overview')}</b>
      <br />
      <table style={{ width: '100%' }} className={styles.bookingTableInput}>
        <tr>
          <th>{t('Administration.KidSection.Weekday')}</th>
          <th />
        </tr>
        <tr>
          <td>{t('Administration.KidSection.Monday')}</td>
          <td>
            <Checkbox
              isChecked={kid.foodOrderedMonday}
              onChange={() => setKid({ ...kid, foodOrderedMonday: !kid.foodOrderedMonday })}
              name={t('Administration.KidSection.FoodIsOrdered')}
              isDisabled={disabled}
            />
          </td>
        </tr>
        <tr>
          <td>{t('Administration.KidSection.Tuesday')}</td>
          <td>
            <Checkbox
              isChecked={kid.foodOrderedTuesday}
              onChange={() => setKid({ ...kid, foodOrderedTuesday: !kid.foodOrderedTuesday })}
              name={t('Administration.KidSection.FoodIsOrdered')}
              isDisabled={disabled}
            />
          </td>
        </tr>
        <tr>
          <td>{t('Administration.KidSection.Wednesday')}</td>
          <td>
            <Checkbox
              isChecked={kid.foodOrderedWednesday}
              onChange={() => setKid({ ...kid, foodOrderedWednesday: !kid.foodOrderedWednesday })}
              name={t('Administration.KidSection.FoodIsOrdered')}
              isDisabled={disabled}
            />
          </td>
        </tr>
        <tr>
          <td>{t('Administration.KidSection.Thursday')}</td>
          <td>
            <Checkbox
              isChecked={kid.foodOrderedThursday}
              onChange={() => setKid({ ...kid, foodOrderedThursday: !kid.foodOrderedThursday })}
              name={t('Administration.KidSection.FoodIsOrdered')}
              isDisabled={disabled}
            />
          </td>
        </tr>
        <tr>
          <td>{t('Administration.KidSection.Friday')}</td>
          <td>
            <Checkbox
              isChecked={kid.foodOrderedFriday}
              onChange={() => setKid({ ...kid, foodOrderedFriday: !kid.foodOrderedFriday })}
              name={t('Administration.KidSection.FoodIsOrdered')}
              isDisabled={disabled}
            />
          </td>
        </tr>
      </table>
    </>
  );
};

export default ChildFoodOrdered;
