import { connect } from 'react-redux';

import { setCreateKitaMode } from 'library/common/commonActions/kitaActions';
import KitaFrameList from '.';

export const mapStateToProps = state => ({
  superAdminStatus: state.userReducer.superAdminStatus,
  newKita: state.kitaReducer.newKita,
  user: state.userReducer,
});

export default connect(
  mapStateToProps,
  { setCreateKitaMode },
)(KitaFrameList);
