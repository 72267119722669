import React from 'react';
import cn from 'classnames';

import ListItemWithLogoAvatar from './listItemWithLogoElementFrames/ListItemWithLogoAvatar';

import styles from './listItemWithLogoElement.module.scss';
import { isAwoWW } from 'library/api/tenantConfig';

export default function ListItemWithLogoElement({
  title,
  description,
  onClick,
  letter,
  backgroundColor,
  content,
  groupInfo,
  isShortDescription,
}) {
  return (
    <div
      className={cn('notranslate', styles.link, isAwoWW() && styles.activeAwoWW)}
      onClick={onClick || (() => {})}
    >
      <ListItemWithLogoAvatar
        groupInfo={groupInfo}
        letter={letter}
        backgroundColor={backgroundColor}
      />

      <div className={styles.linkWrapper}>
        <div className={styles.linkTitle}>{title}</div>
        <div
          className={cn(styles.linkDescription, {
            [styles.linkDescriptionShort]: isShortDescription,
          })}
        >
          {description}
        </div>
        {content}
      </div>
    </div>
  );
}
