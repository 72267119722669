import React, { useState } from 'react';
import cn from 'classnames';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import { useTranslation } from 'react-i18next';
import styles from './multipleActions.module.scss';

export default function MultipleActions({ quantity, remove, transfer, copy, isAdmin }) {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsOpened(false),
  });
  if (!quantity) return '';
  return (
    <div
      onClick={() => setIsOpened(!isOpened)}
      ref={outsideEventContainerRef}
      className={cn(styles.multipleActions, isOpened && styles.multipleActions_active)}
    >
      <div className={styles.title}>
        ({quantity}) {t('GroupPanel.Selected items')}
        {isOpened ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' />}
      </div>
      <div className={styles.dropdown}>
        <div className={styles.item} onClick={remove}>
          {t('GroupPanel.Remove')}
        </div>
        {isAdmin && (
          <div className={styles.item} onClick={transfer}>
            {t('GroupPanel.Transfer')}
          </div>
        )}
        {isAdmin && (
          <div className={styles.item} onClick={copy}>
            {t('Confirm.Copy')}
          </div>
        )}
      </div>
    </div>
  );
}
