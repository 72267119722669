import { useState } from 'react';

import { getGroupsByName, getGroupsPayloadWithBazaarGroup } from 'library/api/groups';
import cachedRequest from '../useCachedRequest';

export default function useGroupsLoading() {
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  async function loadGroups(name, withFavorites = false) {
    setIsLoading(true);

    let payload = getGroupsPayloadWithBazaarGroup;
    if (withFavorites) {
      payload.settings = {
        params: {
          withFavorites: true,
        },
      };
    }

    try {
      setGroups(name ? await getGroupsByName(name, false) : (await cachedRequest(payload)).data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    groups,
    isLoading,
    loadGroups,
  };
}
