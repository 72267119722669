import { useState } from 'react';
import deepcopy from 'deepcopy';

import { createLike, deleteLike } from 'library/api/likes';
import LikeButton from 'library/common/commonComponents/Buttons/LikeButton';

export default function useLikes({ likes, user, id, target, cb }) {
  const [localLikes, setLocalLikes] = useState((likes && likes.map(deepcopy)) || []);
  const [loading, setLoading] = useState(false);

  const isLiked = localLikes.findIndex(like => like.userId === user.id) !== -1;

  let likeId = 0;

  const likeHandler = async () => {
    if (loading) return;
    setLoading(true);

    if (isLiked) {
      likeId = localLikes.find(like => like.userId === user.id).id;
      await deleteLike(likeId);
    } else {
      likeId = (await createLike({
        [getLikeTarget(target)]: id,
        userId: user.id,
      })).data.id;
    }

    const newLikes = isLiked
      ? localLikes.filter(like => like.userId !== user.id)
      : localLikes.concat({
          id: likeId,
          userId: user.id,
          name: `${user.firstName} ${user.lastName}`,
        });

    setLocalLikes(newLikes);
    if (cb) {
      cb(newLikes);
    }
    setLoading(false);
  };

  return {
    localLikes,
    isLiked,
    likeHandler,
    LikeButton,
  };
}

function getLikeTarget(target) {
  switch (target) {
    case 'comment':
      return 'comments';
    case 'file':
      return 'posts';
    default:
      return 'posts';
  }
}
