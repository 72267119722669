export default function getNextHalfHour() {
    const date = new Date();
    const minutes = date.getMinutes();
    let hours = date.getHours();

    if (minutes < 30) {
        date.setMinutes(30);
    } else {
        date.setMinutes(0);
        date.setHours(++hours);
    }

    return date;
}
