import React from 'react';
import classNames from 'classnames';
import styles from './input.module.scss';

export default function Input({
  type,
  name,
  error,
  placeholder,
  faIcon,
  className,
  onChange,
  onBlur,
  value,
  onEnterDown,
  tabIndex,
}) {
  return (
    <div className={styles.inputContainer}>
      <input
        type={type}
        name={name}
        tabIndex={tabIndex || 0}
        className={classNames(
          className,
          error && styles.input_border_error,
          !error && styles.input_border,
        )}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        onKeyDown={e => {
          if (onEnterDown && e.key === 'Enter') {
            onEnterDown();
          }
        }}
      />
      {faIcon && (
        <div className={styles.inputIcon}>
          <i className={classNames('fa', faIcon)} />
        </div>
      )}
    </div>
  );
}
