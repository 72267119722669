import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextCaret from 'library/common/commonComponents/TextCaret';
import BottombarNavItem from '../bottombarNavItem';
import BottombarHamburgerDropdown from './bottombarHamburgerFrames/bottombarHamburgerDropdown';
import { isAwoWW } from '../../../../../../../api/tenantConfig';

import styles from './bottombarHamburger.module.scss';

export default function BottombarHamburger({ links }) {
  const { t } = useTranslation();
  const isAWO = isAwoWW();

  const [dropdownIsOpened, setDropdownIsOpened] = useState(false);

  return (
    <div className={isAWO ? styles.awoBottomHamburger : styles.bottombarHamburger}>
      <BottombarNavItem
        title={
          <div>
            {t('Header.Bottombar.Menu.menu')}
            <TextCaret />
          </div>
        }
        faIcon='fa-align-justify'
        onClick={() => {
          if (!dropdownIsOpened) {
            setDropdownIsOpened(true);
          }
        }}
      >
        <BottombarHamburgerDropdown
          links={links}
          isOpened={dropdownIsOpened}
          closeDropdown={() => setDropdownIsOpened(false)}
        />
      </BottombarNavItem>
    </div>
  );
}
