import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './friendRequestTableHeader.module.scss';

export default function FriendRequestTableHeader({ onSort }) {
  const { t } = useTranslation();
  const [sortDirection, setSortDirection] = useState('DESC');

  const handleSort = () => {
    onSort(sortDirection);
    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
  };

  return (
    <thead className={styles.container}>
      <tr>
        <th onClick={handleSort} className={styles.tableHeaderColumn}>
          {t('AccountSettings.FriendsSection.Username')}
        </th>
        <th className={styles.tableHeaderColumn}>
          {t('AccountSettings.FriendsSection.First name')}
        </th>
        <th className={styles.tableHeaderColumn}>
          {t('AccountSettings.FriendsSection.Last name')}
        </th>
        <th className={styles.tableHeaderColumn}>{t('AccountSettings.FriendsSection.Actions')}</th>
      </tr>
    </thead>
  );
}
