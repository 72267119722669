import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import Loader from 'library/common/commonComponents/Loader';
import { getUsersByNameWithAlreadyUsers } from 'library/common/commonActions/userActions';
import { getGroupUsersByName, getGroupMembers } from 'library/common/commonActions/groupActions';
import { getGroupsByName } from 'library/api/groups';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import GroupAvatar from 'library/common/commonComponents/GroupAvatar';

import RecipientsDropdown from 'library/common/commonComponents/Dropdowns/RecipientsDropdown';
import styles from './selectTagInput.module.scss';
import OutsideAlerter from './OutsideAlerter';

SelectTagInput.defaultProps = {
  showLabel: true,
  target: 'users',
};

export default function SelectTagInput({
  target,
  setErrors,
  onDataChange,
  onGroupChange,
  value,
  groupId,
  errors,
  showLabel,
  isFullWidth,
  isSmall,
  isOnGroupCreation,
  isGroupMembersOnly,
  userId,
  user,
  shouldCheckBlockUsersFromMessaging
}) {
  const { t } = useTranslation();

  const [foundResults, setFoundResults] = useState([]);
  const [searchingRequest, setSearchingRequest] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [dropdownOpened, setDropdownOpened] = useState([]);

  const inputField = useRef(null);

  const clearInput = () => {
    setSearchingRequest('');
    setFoundResults([]);
    setActiveUser(null);
  };

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: clearInput,
  });
  const handleUserInput = newValue =>
    handleUserInputHandler({
      target,
      newValue,
      setErrors,
      onDataChange,
      setSearchingRequest,
      setIsLoading,
      value,
      groupId,
      setFoundResults,
      isOnGroupCreation,
      isGroupMembersOnly,
      userId,
      user,
      shouldCheckBlockUsersFromMessaging
    });

  const addUser = user => addUserHandler({ user, value, onDataChange, clearInput });

  const onInputFocused = () => onInputFocusedHandler(setIsInputFocused);
  const onInputBlured = () => onInputBluredHandler(setIsInputFocused);

  const deleteUser = index => {
    const users = value.users.slice();
    users.splice(index, 1);
    onDataChange({ users });
  };

  const deleteGroup = (index, groupIndex) => {
    const groups = [...value.groups];

    groups[groupIndex].splice(index, 1);

    if (groups[groupIndex].length === 0) groups.splice(groupIndex, 1);

    onGroupChange({ groups });
  };
  const onMoreClick = id => {
    setDropdownOpened(prevDropdownOpened => {
      const copy = [...prevDropdownOpened];
      copy[id] = !copy[id];
      return copy;
    });
  };

  useEffect(() => {
    // add or remove dropdowns statuses
    setDropdownOpened(Array(value.groups.length).fill(false));
  }, [value.groups.length]);

  const outsideClicked = id => {
    setDropdownOpened(prevDropdownOpened => {
      const copy = [...prevDropdownOpened];
      copy[id] = false;
      return copy;
    });
  };

  return (
    <div className={cn(styles.search, { [styles.isSmall]: isSmall })}>
      {showLabel && (
        <div htmlFor='searchUser' className={styles.searchLabel}>
          {t('CreateGroup.Invites')}
        </div>
      )}
      <div className={styles.inputTagsWrapper} ref={outsideEventContainerRef}>
        <div
          className={cn(
            'notranslate',
            styles.inputTags,
            isInputFocused && styles.inputTags_focused,
            errors.invite !== '' && styles.inputTags_error,
          )}
          onClick={() => inputField.current.focus()}
        >
          {value.users.map((userItem, index) => (
            <div key={userItem.id} className={styles.inputTags__item}>
              <div className={styles.photo}>
                {target === 'users' ? (
                  <ProfileAvatar image={userItem} id={userItem.id} withoutLink />
                ) : (
                  <GroupAvatar group={userItem} />
                )}
              </div>
              <div className={styles.name}>{getResultName(userItem, target)}</div>
              <div className={styles.delete} onClick={() => deleteUser(index)}>
                <i className='fa fa-times-circle' />
              </div>
            </div>
          ))}
          {value.groups.map((groupItem, groupIndex) => {
            const firstGroupUser =
              groupItem.length > 0 ? `${groupItem[0].firstName} ${groupItem[0].lastName} ` : '';
            return (
              <OutsideAlerter key={firstGroupUser} clicked={() => outsideClicked(groupIndex)}>
                <div className={styles.inputTags__item} onClick={() => onMoreClick(groupIndex)}>
                  <div className={styles.name}>
                    <div>{value.groupNames[groupIndex]}</div>
                  </div>
                  <i className='fa fa-chevron-down' />
                </div>

                <RecipientsDropdown
                  isOpened={dropdownOpened[groupIndex]}
                  items={groupItem}
                  groupIndex={groupIndex}
                  onDeleteButtonClick={deleteGroup}
                />
              </OutsideAlerter>
            );
          })}
          <input
            type='text'
            className={cn(styles.inputTags__input, isFullWidth && styles.inputFullWidth)}
            ref={inputField}
            onFocus={onInputFocused}
            onBlur={onInputBlured}
            value={searchingRequest}
            onChange={e => handleUserInput(e.target.value)}
            placeholder={t('CreateGroup.Add more')}
          />
        </div>
        {errors.invite !== '' && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={errors.invite} />
          </div>
        )}
        {searchingRequest && (
          <div className={styles.searchingResults}>
            {isLoading ? (
              <div className={styles.searchingResultsLoader}>
                <Loader />
              </div>
            ) : (
              <div className={styles.searchingResultsList}>
                {foundResults.length ? (
                  foundResults.map((item, index) => (
                    <div
                      key={item.id}
                      className={cn(
                        'notranslate',
                        {
                          [styles.searchingResultsListItemHidden]:
                            value.users.findIndex(user => `${item.id}` === `${user.id}`) !== -1 ||
                            (item.groupRegistrationStatus &&
                              target === 'users' &&
                              groupId &&
                              !isGroupMembersOnly),
                        },
                        styles.searchingResultsListItem,
                        activeUser === index && styles.searchingResultsListItemActive,
                      )}
                      onClick={() => {
                        addUser(item);
                        inputField.current.focus();
                      }}
                      onMouseEnter={() => setActiveUser(index)}
                      onMouseLeave={() => setActiveUser(null)}
                    >
                      <div className={styles.searchingResultsListItemPhoto}>
                        {target === 'users' ? (
                          <ProfileAvatar image={item} id={item.id} withoutLink />
                        ) : (
                          <GroupAvatar group={item} />
                        )}
                      </div>
                      <div
                        className='notranslate'
                        dangerouslySetInnerHTML={{
                          __html: highlightString(getResultName(item, target), searchingRequest),
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div className={styles.searchingResultsListEmpty}>
                    {searchingRequest.length > 2
                      ? target === 'users'
                        ? t('CreateGroup.No users found for the given query')
                        : t('CreateGroup.No groups found for the given query')
                      : t('CreateGroup.Please enter at least 3 character')}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function getResultName(item, target) {
  return target === 'users' ? `${item.firstName} ${item.lastName}` : item.groupName;
}

export function handleUserInputHandler({
  target,
  newValue,
  setErrors,
  setSearchingRequest,
  setIsLoading,
  groupId,
  setFoundResults,
  isGroupMembersOnly,
  value,
  userId,
  user,
  shouldCheckBlockUsersFromMessaging
}) {
  if (setErrors) {
    setErrors({ invite: '' });
  }
  setSearchingRequest(newValue);
  if (newValue.length > 2) {
    setIsLoading(true);

    if (target === 'users') {
      if (groupId) {
        if (isGroupMembersOnly) {
          const filters = [{ filterName: 'search', filterValue: newValue }];
          getGroupMembers(groupId, filters).then(res => {
            const userList = res.data.content.reduce((array, item) => {
              return (array = item.user.superAdmin ? array : array.concat(item.user));
            }, []);
            setFoundResults(userList);
            setIsLoading(false);
          });
        } else {
          getGroupUsersByName({ name: newValue, groupId }).then(data => {
            setFoundResults(data);
            setIsLoading(false);
          });
        }
      } else {
        const alreadySelectedUsers = value.users.map(user => user.id);
        getUsersByNameWithAlreadyUsers({ search: newValue, alreadySelectedUsers }).then(data => {
          // userId: 43319 is official
          // userId: 41541 is imprisoned
          // filter to hide user 43319 from user 41541 and vice versa
          if (!user.administrationAccess && !user.superAdminStatus && shouldCheckBlockUsersFromMessaging) {
            data = data.filter(obj => !obj.blockUsersFromMessaging);
          }
          if (userId === 43319) {
            const filtered = data.filter(obj => obj.id !== '41541');
            setFoundResults(filtered);
          } else if (userId === 41541) {
            const filtered = data.filter(obj => obj.id !== '43319');
            setFoundResults(filtered);
          } else {
            setFoundResults(data);
          }
          setIsLoading(false);
        });
      }
    } else if (target === 'groups') {
      getGroupsByName(newValue).then(data => {
        setFoundResults(data);
        setIsLoading(false);
      });
    }
    setFoundResults([]);
  }
}

export function addUserHandler({ user, value, onDataChange, clearInput }) {
  if (value.users.findIndex(item => `${user.id}` === `${item.id}`) !== -1) {
    return null;
  }
  onDataChange({ ...value, ...{ users: value.users.concat(user) } });
  clearInput();
}

export function highlightString(str, subStr) {
  const htmlTemplate = [`<span style="background: #fff8e0">`, `</span>`];
  const indexStart = str.toUpperCase().indexOf(subStr.toUpperCase());
  if (indexStart === -1) {
    return str;
  }
  const indexEnd = indexStart + subStr.length + htmlTemplate[0].length;
  const result = str.slice(0, indexStart) + htmlTemplate[0] + str.slice(indexStart);
  return result.slice(0, indexEnd) + htmlTemplate[1] + result.slice(indexEnd);
}

export function onInputFocusedHandler(setIsInputFocused) {
  setIsInputFocused(true);
}

export function onInputBluredHandler(setIsInputFocused) {
  setIsInputFocused(false);
}
