import React from 'react';
import { Trans } from 'react-i18next';

import GroupsListSidebar from './ProfileSidePanelFrames/GroupsListSidebar';

import styles from './profileSidePanel.module.scss';

export default function ProfileSidePanel({ groups, userId }) {
  return (
    <div className={styles.container}>
      {groups.length > 0 && (
        <GroupsListSidebar
          title={<Trans i18nKey='ProfileBody.SideBar.Member in these groups' />}
          uniqName='memberInGroups'
          userId={userId}
        />
      )}
    </div>
  );
}
