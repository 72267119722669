import { connect } from 'react-redux';

import { toggleDisclaimerModal } from 'library/common/commonActions/disclaimerActions';

import LinkDisclaimer from '.';

const mapStateToProps = state => ({
  link: state.disclaimerReducer.link,
});

export default connect(
  mapStateToProps,
  { toggleDisclaimerModal },
)(LinkDisclaimer);
