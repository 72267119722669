import React, { useState } from 'react';
import Tabs from 'library/common/commonComponents/Tabs';
import UsersFrameEmployee from './UsersFrameEmployee';
import UserFrameEmployeeTimeRecording from './UserFrameEmployeeTimeRecording';
import UserFrameEmployeeNumberHolidays from './UserFrameEmployeeNumberHolidays';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function UsersFrameEmployeeWrapper({ kitaId, modules }) {
  const [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation();

  const emplyeeCheckInOutModule = modules.find(element => {
    return element.moduleKey === 'employee_checkinout';
  });

  const employeeCheckInOutModuleIsActive = emplyeeCheckInOutModule?.activeStatus;

  let tabs = [
    {
      title: 'Übersicht',
      component: <UsersFrameEmployee kitaId={kitaId} modules={modules} />,
      path: 'employee',
    },
  ];

  if (employeeCheckInOutModuleIsActive) {
    tabs.push(
      {
        title: t('Administration.UserSection.TimeRecording'),
        component: <UserFrameEmployeeTimeRecording kitaId={kitaId} modules={modules} />,
        path: 'employee-timerecording',
      },
      {
        title: t('Administration.Vacation and break setting'),
        component: <UserFrameEmployeeNumberHolidays />,
        path: 'employee-holidays',
      },
    );
  }

  return (
    <div>
      <Tabs
        tabs={tabs}
        currentTab={tabs[currentTab].path}
        onChange={(_, index) => setCurrentTab(index)}
      />
    </div>
  );
}

export const mapStateToProps = state => ({
  kitaId: state.kitaReducer.activeKita.kitaId,
  modules: state.kitaReducer.modules,
});
export default connect(mapStateToProps)(UsersFrameEmployeeWrapper);
