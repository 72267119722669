import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { deleteGroup, deletePosts } from 'library/api/groups';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { GroupContext } from 'modules/Group/groupModule';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { isValidDate } from 'library/utilities/date';

import styles from './deleteFrame.module.scss';

export function DeleteFrame({
  history,
  showBottomNotificationFromProps,
  deleteGroupFromProps,
  deletePostsFromProps,
  closeDeleteMode,
  type,
}) {
  const groupContext = useContext(GroupContext);
  const { groupInfo } = groupContext;
  const patchGroupInfo = groupContext.patchGroup;
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [dateLimit, setDateLimit] = useState(new Date().toISOString().slice(0, 10));
  const [errors, setErrors] = useState({
    password: '',
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const onSubmit = () =>
    onSubmitHandler({
      validateForm,
      setIsDisabled,
      isDisabled,
      type,
      deleteGroupFromProps,
      deletePostsFromProps,
      groupInfo,
      password,
      dateLimit,
      onApiSuccess,
      setErrors,
      errors,
      t,
    });

  const onApiSuccess = () =>
    onApiSuccessHandler({
      type,
      showBottomNotificationFromProps,
      history,
      patchGroupInfo,
      closeDeleteMode,
      t,
    });
  
  const validateForm = () => validateFormHandler({ password, dateLimit, t, setErrors, type });

  const handlePasswordChange = value =>
    handlePasswordChangeHandler({ setErrors, setPassword, value });

  const handleDateLimitChange = value =>
    handleDateLimitChangeHandler({ setErrors, setDateLimit, value });
  return (
    <div className={styles.container}>
      <div className={styles.inputGroup}>
        <div className={styles.description}>
          <p>
            {type === 'group'
              ? t('GroupBody.Settings.Are you sure, that you want to delete this space')
              : t('GroupBody.Settings.Are you sure, that you want to delete all posts')}
          </p>
          <p>{t('GroupBody.Settings.Please provide your password to continue')}</p>
        </div>
        <Label type='input'>{t('GroupBody.Settings.Your password')} *</Label>
        <Input
          error={errors.password !== ''}
          value={password}
          type='password'
          onChange={e => handlePasswordChange(e.target.value)}
        />
        {errors.password !== '' && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={errors.password} />
          </div>
        )}
        <br />
        {type === 'posts' && 
        <div>
        <Label type='input'>{t('GroupBody.Settings.Date limit')} *</Label>
        <Input
          type='date'
          onChange={e => {
            handleDateLimitChange(e.target.value);
          }}
          value={dateLimit}
        />
        </div>
        }
        {errors.dateLimit !== '' && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={errors.dateLimit} />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <ButtonWithLoader type='danger' onClick={onSubmit} isLoading={isDisabled}>
          {t('GroupBody.Settings.Delete')}
        </ButtonWithLoader>
      </div>
    </div>
  );
}

DeleteFrame.defaultProps = {
  deleteGroupFromProps: deleteGroup,
  deletePostsFromProps: deletePosts,
};

export default withRouter(
  connect(
    null,
    {
      showBottomNotificationFromProps: showBottomNotification,
    },
  )(DeleteFrame),
);

export function onSubmitHandler({
  validateForm,
  setIsDisabled,
  isDisabled,
  type,
  deleteGroupFromProps,
  deletePostsFromProps,
  groupInfo,
  password,
  dateLimit,
  onApiSuccess,
  setErrors,
  errors,
  t,
}) {
  if (isDisabled) {
    return;
  }
  if (validateForm()) {
    setIsDisabled(true);
    return (type === 'group'
      ? deleteGroupFromProps(groupInfo.groupId, password)
      : deletePostsFromProps(groupInfo.groupId, password, dateLimit)
    )
      .then(res => {
        setIsDisabled(false);
        switch (res.status) {
          case 200:
            onApiSuccess();
            break;
          default:
            break;
        }
      })
      .catch(res => {
        setIsDisabled(false);
        switch (res.response.status) {
          case 422:
            setErrors({
              ...errors,
              ...{ password: t('GroupBody.Settings.Password is incorrect') },
            });
            break;
          case 404:
            setErrors({
              ...errors,
              ...{ password: t('GroupBody.Settings.GroupId is not present') },
            });
            break;
          default:
            setErrors({
              ...errors,
              ...{
                password: t(
                  `GroupBody.Settings.Unable to delete ${type === 'group' ? 'Group' : 'Posts'}`,
                ),
              },
            });
            break;
        }
      });
  }
}

export function onApiSuccessHandler({
  type,
  showBottomNotificationFromProps,
  history,
  patchGroupInfo,
  closeDeleteMode,
  t,
}) {
  if (type === 'group') {
    showBottomNotificationFromProps(t('GroupBody.Settings.Group successfull deleted'));
    history.push('/');
  } else {
    //closeDeleteMode();
    showBottomNotificationFromProps(t('GroupBody.Settings.All posts successfull deleted'));
    window.location.reload();
  }
}

export function validateFormHandler({ password, dateLimit, t, setErrors, type }) {
  let isError = false;
  let errorsTemplate = {
    password: '',
  };
  if (!password.length) {
    errorsTemplate = {
      ...errorsTemplate,
      ...{ password: t('GroupBody.Settings.Password is required') },
    };
    isError = true;
  }

  if (type === 'posts' && !isValidDate(dateLimit)) {
    errorsTemplate = {
      ...errorsTemplate,
      ...{ dateLimit: t('GroupBody.Settings.Valid date is required') },
    };
    isError = true;
  }
  setErrors(errorsTemplate);
  if (!isError) {
    return true;
  } else {
    return false;
  }
}

export function handlePasswordChangeHandler({ setErrors, setPassword, value }) {
  const errorsTemplate = {
    password: '',
  };
  setErrors(errorsTemplate);
  setPassword(value);
}

export function handleDateLimitChangeHandler({ setErrors, setDateLimit, value }) {
  const errorsTemplate = {
    dateLimit: '',
  };
  setErrors(errorsTemplate);
  setDateLimit(value);
}
