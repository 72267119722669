import React from 'react';
import cn from 'classnames';

import styles from '../../gallery.module.scss';

export default function GalleryAddButton({ onClick, text }) {
  return (
    <div className={cn(styles.gallery, styles.galleryNew)} onClick={onClick}>
      <div className={cn(styles.galleryTitle, styles.galleryTitleWrap)}>
        <i className={cn('fa fa-plus', styles.galleryTitleIcon)} /> {text}
      </div>

      <div className={styles.galleryImageContainer}>
        <i className={cn('fa fa-plus', styles.galleryImage)} />
      </div>

      <div className={styles.galleryOverlay} />
    </div>
  );
}
