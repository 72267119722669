import React, { useState } from 'react';

import CheckilistInput from './CheckilistInput';
import styles from './createEditTaskCheck.module.scss';

export default function CreateEditTaskChecklist({ createEditTaskState, createEditTaskdispatch }) {
  const [checklist, setChecklist] = useState(createEditTaskState.checklist);

  const handleAddChecklist = () => {
    setChecklist(checklist.concat({ id: null, title: '', status: false }));
    createEditTaskdispatch({
      type: 'setChecklist',
      payload: createEditTaskState.checklist.concat({ id: null, title: '', status: false }),
    });
  };

  const handleDeleteChecklist = index => {
    const newChecklist = createEditTaskState.checklist.slice();
    newChecklist.splice(index, 1);
    setChecklist(newChecklist);
    createEditTaskdispatch({ type: 'setChecklist', payload: newChecklist });
  };

  return (
    <div className={styles.container}>
      {checklist.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${item.title}${i}`} className={styles.inputContainer}>
          <CheckilistInput
            index={i}
            value={item.title}
            createEditTaskState={createEditTaskState}
            createEditTaskdispatch={createEditTaskdispatch}
          />
          {i === createEditTaskState.checklist.length - 1 ? (
            <div className={styles.addChecklistButton} onClick={handleAddChecklist}>
              <i className='fa fa-plus' />
            </div>
          ) : (
            <div className={styles.deleteChecklistButton} onClick={() => handleDeleteChecklist(i)}>
              <i className='fa fa-trash-o' />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
