import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import ListItemWithLogoAvatar from 'library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import Popup from 'library/common/commonComponents/Popups/Popup';
import { getLetterInLogo } from 'library/utilities/groups';

import styles from './MultiGroupSelectionPopup.module.scss';
import Input from '../../Inputs/Input';

const MultiGroupSelectionPopup = ({ isLoading, isOpen, setOpen, groups, handleCopy }) => {
  const { t } = useTranslation();

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [manualId, setManualId] = useState('');

  const handleGroupChange = group => {
    if (selectedGroups.find(item => group.id === item.id)) {
      setSelectedGroups(selectedGroups.filter(item => item.id !== group.id));
    } else {
      setSelectedGroups([...selectedGroups, group]);
    }
  };

  const closePopup = () => {
    setOpen(false);
  };

  const onSave = async () => {
    let selectedGroupsTemp = selectedGroups;
    if (manualId.trim() !== '') {
      selectedGroupsTemp = [...selectedGroups, { id: manualId }]
    }
    await handleCopy(selectedGroupsTemp);
    setManualId('');
    closePopup();
  };

  return (
    <Popup
      isOpened={isOpen}
      closePopup={closePopup}
      header={t('GroupPanel.Copy to Groups')}
      footer={
        <div className={styles.buttonsContainer}>
          <ButtonWithLoader isLoading={isLoading} onClick={onSave}>
            {t('Confirm.Save')}
          </ButtonWithLoader>
          <Button onClick={closePopup}>{t('Confirm.Close')}</Button>
        </div>
      }
    >
      <div className={styles.groupList}>
        {groups.map(group => (
          <div
            key={group.id}
            className={styles.groupElement}
            onClick={() => handleGroupChange(group)}
          >
            <Checkbox
              key={group.id}
              isChecked={selectedGroups.findIndex(item => item.id === group.id) > -1}
            />
            <ListItemWithLogoAvatar
              groupInfo={group}
              letter={getLetterInLogo(group.groupName)}
              backgroundColor={group.colorCode}
              className={styles.groupLogo}
            />
            <div className={styles.groupName}>{group.groupName}</div>
          </div>
        ))}
        <div
          className={styles.groupElement}
        >
          <Input type='text' value={manualId} onChange={e => setManualId(e.target.value)} />
          <div className={styles.groupName}>&nbsp;Id</div>
        </div>
      </div>
    </Popup>
  );
};

export default MultiGroupSelectionPopup;
