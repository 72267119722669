import React, { useState, useEffect, useRef } from 'react';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import RadioButton from 'library/common/commonComponents/RadioButton';

export default function BaptismInformation({
  changeCurrentPage,
  handleTextStateChange,
  formData,
  setFormData,
  type,
}) {

  const [uploadedImageInvalid, setUploadedImageInvalid] = useState(false);
  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <b>Taufinformationen</b>
        <br />
        <table>
          <tr>
            <td style={{ minWidth: "150px" }}>Taufdatum des Kindes</td>
            <td>
              <Input
                type='date'
                value={formData.baptismDate}
                onChange={e => handleTextStateChange(e.target.value, 'baptismDate')}
                disabled={type === 'profileFrame' ? true : false}
              />
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: "150px" }}>Taufpfarrei des Kindes</td>
            <td>
              <Input
                type='text'
                value={formData.parish}
                onChange={e => handleTextStateChange(e.target.value, 'parish')}
                disabled={type === 'profileFrame' ? true : false}
              />
              <br />
              <Button
                onClick={() => {
                  handleTextStateChange('Christus Erlöser', 'parish');
                }}
                disabled={type === 'profileFrame' ? true : false}
              >
                Christus Erlöser als Taufpfarrei wählen
              </Button>
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: "150px" }}>Taufschein des Kindes (nur bei anderer Taufpfarrei als Christus Erlöser)</td>
            <td>
              <Input
                type='file'
                disabled={type === 'profileFrame' ? true : false}
                accept='image/*'
                onChange={e => {
                  if (e.target.files[0].type.startsWith('image') && (e.target.files[0].size / 1000000) <= 15) {
                    convertBase64(e.target.files[0]).then(data => {
                      setFormData({ ...formData, baptismCertificate: data });
                      setUploadedImageInvalid(false);
                    });
                  } else {
                    setUploadedImageInvalid(true);
                  }
                }}
              />
              {uploadedImageInvalid && <><br /><b style={{ color: "red" }}>Bitte laden Sie eine Bilddatei mit einer maximalen Größe von 15MB hoch</b><br /></>}
              {formData && formData.baptismCertificate && formData.baptismCertificate !== null && formData.baptismCertificate !== '' &&
                <>
                  <br />
                  <img
                    style={{ maxHeight: '500pt', maxWidth: '500pt' }}
                    src={formData.baptismCertificate}
                  />
                </>

              }
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: "150px" }}>Pfarrei des Wohnorts</td>
            <td>
              <Input
                type='text'
                value={formData.parishLivingPlace}
                onChange={e => handleTextStateChange(e.target.value, 'parishLivingPlace')}
                disabled={type === 'profileFrame' ? true : false}
              />
              <br />
              <Button
                onClick={() => {
                  handleTextStateChange('Christus Erlöser', 'parishLivingPlace');
                }}
                disabled={type === 'profileFrame' ? true : false}
              >
                Christus Erlöser als Pfarrei des Wohnorts wählen
              </Button>
            </td>
          </tr>
        </table>
        <br />
        <Button
          onClick={() => {
            changeCurrentPage(-1);
          }}
        >
          Zurück
        </Button>
        &nbsp;
        <Button
          onClick={() => {
            changeCurrentPage(1);
          }}
          type='primary'
        >
          Weiter
        </Button>
        <br />
        <br />
      </div>
    </>
  );
}
