import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URLConfig = `${config.API_BASE_URI}${getPort(8091)}/api`;

export const getFolders = payload =>
  axios
    .get(URLConfig + '/folder', {
      params: {
        ...payload,
      },
    })
    .then(res => res.data)
    .catch(err => err);

export const getFoldersForMovingFiles = payload =>
  axios
    .get(URLConfig + '/foldersformovingfiles', {
      params: {
        ...payload,
      },
    })
    .then(res => res.data)
    .catch(err => err);

export const createFolder = payload => axios.post(URLConfig + '/folder', payload);

export const deleteFolders = folderIds => axios.delete(URLConfig + `/folder/${folderIds}`);

export const editFolder = payload => axios.put(URLConfig + '/folder/', payload);

export const changePublicFolders = (folderIds, isPublic) =>
  axios.put(URLConfig + `/folder/${folderIds}/${isPublic}`);

export const deleteAllDuplicateFolders = () => axios.delete(`${URLConfig}/deleteAllDuplicateFolders`);
