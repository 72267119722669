import React from 'react';
import {
  minutesToTimeString,
  tableBookingTimeCalculateWeeklyHours,
  tableBookingTimesCalculateAverage,
  tableBookingTimesCalculateHoursSum,
  tableBookingTimesCalculateHoursSumMultiple,
  tableBookingTimesGetStartEndPairs,
} from '../../../../library/utilities/forms';
import Button from 'library/common/commonComponents/Buttons/Button';
import style from '../../../Administration/administrationFrames/FormsFrame/FormsFrameFrames/FormsEditor/FormsEditor.module.scss';

export default function BookingTimesTable({ formField, submitData, setSubmitData, cannotEdit }) {
  function isValidTime(time) {
    const splittedTime = time.split(':');
    if (+splittedTime[0] >= 7 && +splittedTime[0] < 17) {
      if (
        +splittedTime[1] === 0 ||
        +splittedTime[1] === 30 ||
        +splittedTime[1] === 3 ||
        +splittedTime[1] === 1 ||
        +splittedTime[1] === 15 ||
        +splittedTime[1] === 45
      ) {
        return true;
      }
    } else if (+splittedTime[0] === 17) {
      if (
        +splittedTime[1] === 0 ||
        +splittedTime[1] === 15 ||
        +splittedTime[1] === 30 ||
        +splittedTime[1] === 3
      ) {
        return true;
      }
    }
    return false;
  }
  console.log(submitData);
  const renderFromToRow = i => {
    return (
      <>
        <tr>
          <td>von</td>
          <td>
            <input
              id={`${formField.id}-monFrom${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-monFrom${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-monFrom${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-monFrom${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (!e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-monTo${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
          <td>
            <input
              id={`${formField.id}-tueFrom${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-tueFrom${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-tueFrom${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-tueFrom${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-monTo${i}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`${formField.id}-tueTo${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
          <td>
            <input
              id={`${formField.id}-wedFrom${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-wedFrom${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-wedFrom${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-wedFrom${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-tueTo${i}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`${formField.id}-wedTo${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
          <td>
            <input
              id={`${formField.id}-thuFrom${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-thuFrom${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-thuFrom${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-thuFrom${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-wedTo${i}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`${formField.id}-thuTo${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
          <td>
            <input
              id={`${formField.id}-friFrom${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-friFrom${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-friFrom${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-friFrom${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-thuTo${i}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`${formField.id}-friTo${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
        </tr>
        <tr>
          <td>bis</td>
          <td>
            <input
              id={`${formField.id}-monTo${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-monTo${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-monTo${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-monTo${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-monFrom${i}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`${formField.id}-tueFrom${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
          <td>
            <input
              id={`${formField.id}-tueTo${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-tueTo${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-tueTo${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-tueTo${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-tueFrom${i}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`${formField.id}-wedFrom${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
          <td>
            <input
              id={`${formField.id}-wedTo${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-wedTo${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-wedTo${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-wedTo${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-wedFrom${i}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`${formField.id}-thuFrom${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
          <td>
            <input
              id={`${formField.id}-thuTo${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-thuTo${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-thuTo${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-thuTo${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-thuFrom${i}`).focus();
                  e.preventDefault();
                } else if (e.keyCode === 9) {
                  document.getElementById(`${formField.id}-friFrom${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
          <td>
            <input
              id={`${formField.id}-friTo${i}`}
              type='time'
              value={submitData ? submitData.formFields[`${formField.id}-friTo${i}`] : ''}
              readOnly={cannotEdit}
              onChange={e => {
                if (isValidTime(e.target.value)) {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-friTo${i}`]: e.target.value,
                    },
                  });
                } else {
                  setSubmitData({
                    ...submitData,
                    formFields: {
                      ...submitData.formFields,
                      [`${formField.id}-friTo${i}`]: '',
                    },
                  });
                }
              }}
              onKeyDown={e => {
                if (e.shiftKey && e.keyCode === 9) {
                  document.getElementById(`${formField.id}-friFrom${i}`).focus();
                  e.preventDefault();
                }
              }}
            />{' '}
            Uhr
          </td>
        </tr>
        <tr><td colSpan={6}>
          <Button onClick={() => {
            setSubmitData({
              ...submitData,
              formFields: {
                ...submitData.formFields,
                [`${formField.id}-tueFrom${i}`]: submitData.formFields[`${formField.id}-monFrom${i}`],
                [`${formField.id}-tueTo${i}`]: submitData.formFields[`${formField.id}-monTo${i}`],
                [`${formField.id}-wedFrom${i}`]: submitData.formFields[`${formField.id}-monFrom${i}`],
                [`${formField.id}-wedTo${i}`]: submitData.formFields[`${formField.id}-monTo${i}`],
                [`${formField.id}-thuFrom${i}`]: submitData.formFields[`${formField.id}-monFrom${i}`],
                [`${formField.id}-thuTo${i}`]: submitData.formFields[`${formField.id}-monTo${i}`],
                [`${formField.id}-friFrom${i}`]: submitData.formFields[`${formField.id}-monFrom${i}`],
                [`${formField.id}-friTo${i}`]: submitData.formFields[`${formField.id}-monTo${i}`],
              },
            });

          }}>Zeit Montag für restliche Woche übertragen</Button>
        </td></tr>
      </>
    );
  };

  const renderFromToRows = () => {
    return Array.from({ length: formField.numberFromToRows }, (_, i) => renderFromToRow(i + 1));
  };

  return (
    <div>
      <table className={style.table}>
        <tr>
          <th />
          <th>Mo</th>
          <th>Di</th>
          <th>Mi</th>
          <th>Do</th>
          <th>Fr</th>
        </tr>
        {renderFromToRows()}
        <tr>
          <td>= maximal gebuchte Zeit</td>
          <td>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('mon', submitData, formField),
              ),
            ) || '-'}{' '}
            Stunden
          </td>
          <td>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('tue', submitData, formField),
              ),
            ) || '-'}{' '}
            Stunden
          </td>
          <td>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('wed', submitData, formField),
              ),
            ) || '-'}{' '}
            Stunden
          </td>
          <td>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('thu', submitData, formField),
              ),
            ) || '-'}{' '}
            Stunden
          </td>
          <td>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('fri', submitData, formField),
              ),
            ) || '-'}{' '}
            Stunden
          </td>
        </tr>
        <tr>
          <td colSpan='6'>
            Wochenstunden gesamt:{' '}
            {minutesToTimeString(tableBookingTimeCalculateWeeklyHours(formField, submitData)) ||
              '-'}{' '}
            Stunden
          </td>
        </tr>
      </table>
      <br />
      Wochenstunden gesamt geteilt durch 5 Tage = Buchungskategorie:
      <br />
      <table className={style.table}>
        <tr>
          <th>Tägl. durchschnittlich</th>
          <th>mehr als 3-4 Std.</th>
          <th>mehr als 4-5 Std.</th>
          <th>mehr als 5-6 Std.</th>
          <th>mehr als 6-7 Std.</th>
          <th>mehr als 7-8 Std.</th>
          <th>mehr als 8-9 Std.</th>
          <th>mehr als 9-10 Std.</th>
        </tr>
        <tr>
          <td>Bitte ankreuzen</td>
          <td>
            <input
              disabled
              type='checkbox'
              checked={
                180 < tableBookingTimesCalculateAverage(formField, submitData) &&
                tableBookingTimesCalculateAverage(formField, submitData) <= 240
              }
              className={style.inlineCheckbox}
            />
          </td>
          <td>
            <input
              disabled
              type='checkbox'
              checked={
                240 < tableBookingTimesCalculateAverage(formField, submitData) &&
                tableBookingTimesCalculateAverage(formField, submitData) <= 300
              }
              className={style.inlineCheckbox}
            />
          </td>
          <td>
            <input
              disabled
              type='checkbox'
              checked={
                300 < tableBookingTimesCalculateAverage(formField, submitData) &&
                tableBookingTimesCalculateAverage(formField, submitData) <= 360
              }
              className={style.inlineCheckbox}
            />
          </td>
          <td>
            <input
              disabled
              type='checkbox'
              checked={
                360 < tableBookingTimesCalculateAverage(formField, submitData) &&
                tableBookingTimesCalculateAverage(formField, submitData) <= 420
              }
              className={style.inlineCheckbox}
            />
          </td>
          <td>
            <input
              disabled
              type='checkbox'
              checked={
                420 < tableBookingTimesCalculateAverage(formField, submitData) &&
                tableBookingTimesCalculateAverage(formField, submitData) <= 480
              }
              className={style.inlineCheckbox}
            />
          </td>
          <td>
            <input
              disabled
              type='checkbox'
              checked={
                480 < tableBookingTimesCalculateAverage(formField, submitData) &&
                tableBookingTimesCalculateAverage(formField, submitData) <= 540
              }
              className={style.inlineCheckbox}
            />
          </td>
          <td>
            <input
              disabled
              type='checkbox'
              checked={540 < tableBookingTimesCalculateAverage(formField, submitData)}
              className={style.inlineCheckbox}
            />
          </td>
        </tr>
      </table>
    </div>
  );
}
