import { useState, useEffect } from 'react';
import useRouter from 'use-react-router';

import { setActiveKita } from 'library/utilities/kitaEffects';
import useSorting from 'library/common/commonHooks/useSorting';
import { getKitaUsers } from 'library/api/user';

export default function useUsersRegistrationList({ isPending, activeKita }) {
  const [emailValue, setEmailValue] = useState('');
  const [invitedByValue, setInvitedByValue] = useState('');
  const [languageValue, setLanguageValue] = useState('');
  const [createdAtValue, setCreatedAtValue] = useState('');
  const [users, setUsers] = useState([]);
  const { history, location } = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const { sort, setSorting } = useSorting('user.userName');
  const [selectedKita, setSelectedKita] = useState({});
  const [shouldReloadPage, setShouldReloadPage] = useState(false);
  const [deletionProfile, setDeletionProfile] = useState(null);

  useEffect(setActiveKita({ activeKita, setSelectedKita }), [activeKita]);

  const loadUsers = () =>
    loadUsersHandler({
      setIsLoading,
      setUsers,
      setTotalUsersCount,
      page,
      sort,
      status: false,
      isPending,
      emailValue,
      invitedByValue,
      languageValue,
      createdAtValue,
      selectedKita,
    });

  useEffect(() => {
    if (shouldReloadPage) {
      loadUsers();
      setShouldReloadPage(false);
    }
    // eslint-disable-next-line
  }, [shouldReloadPage]);

  const reloadPage = () => setShouldReloadPage(true);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line
  }, [page, sort, selectedKita.value]);

  useEffect(() => {
    if (deletionProfile === null) {
      loadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletionProfile]);

  useEffect(resetPageHandler({ setPage, loadUsers }), [
    emailValue,
    invitedByValue,
    languageValue,
    createdAtValue,
  ]);

  return {
    users,
    loadPage: setPage,
    isLoading,
    history,
    location,
    totalUsersCount,
    setSorting,
    sort,
    reloadPage,
    emailValue,
    setEmailValue,
    invitedByValue,
    setInvitedByValue,
    languageValue,
    setLanguageValue,
    createdAtValue,
    setCreatedAtValue,
    setSearchValue: setEmailValue,
    searchValue: emailValue,
    deletionProfile,
    setDeletionProfile,
    selectedKita,
    setSelectedKita,
  };
}

export async function loadUsersHandler({
  setIsLoading,
  setUsers,
  setTotalUsersCount,
  page,
  sort,
  emailValue,
  invitedByValue,
  languageValue,
  createdAtValue,
  status,
  isPending,
  selectedKita,
}) {
  if (!selectedKita.value) return;
  setIsLoading(true);
  const { data } = await getKitaUsers({
    kitaId: selectedKita.value,
    page,
    sort,
    emailValue,
    invitedByValue,
    languageValue,
    createdAtValue,
    status,
    isPending,
  });

  setUsers(data.content);
  setTotalUsersCount(data.totalElements);
  setIsLoading(false);
}

export function resetPageHandler({ setPage, loadUsers }) {
  return () => {
    setPage(1);
    loadUsers();
  };
}
