import { useSelector } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { getKitaUserFields, updateKitaUserFields } from 'library/api/userFields';

// Chosen kita is used when kita fields are needed for a kita that is not the active kita
export default function useKitaUserFields(chosenKita) {
  const [kitaUserFields, setKitaUserFields] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const activeKita = useSelector(state => state.kitaReducer.activeKita);

  const [selectedKita, setSelectedKita] = useState({
    value: activeKita.kitaId,
    label: activeKita.kitaName,
  });
  const kitasRaw = useSelector(state => state.kitaReducer.kitas);

  const kitas = useMemo(
    () => kitasRaw?.map(kita => ({ value: kita.kitaId, label: kita.kitaName })) ?? [],
    kitasRaw,
  );
  const [kitaUserFieldsLoading, setKitaUserFieldsLoading] = useState(false);
  const [updateKitaUserFieldsLoading, setUpdateKitaFieldsLoading] = useState(false);

  useEffect(() => {
    // Fetch Kita fields here only if chosen kita is not defined
    if (!chosenKita) {
      if (selectedKita.value) {
        setKitaUserFieldsLoading(true);
        getKitaUserFields(selectedKita.value)
          .then(res => {
            setCustomFields(res.data.customFields);
            setKitaUserFields(res.data.fields);
            setKitaUserFieldsLoading(false);
          })
          .catch(() => {
            setKitaUserFieldsLoading(false);
          });
      }
    }
  }, [selectedKita.value]);

  // This will run only if chosenKita is passed
  useEffect(() => {
    const id = chosenKita?.value ?? chosenKita?.kitaId;
    if (id) {
      setKitaUserFieldsLoading(true);
      getKitaUserFields(id)
        .then(res => {
          setCustomFields(res.data.customFields);
          setKitaUserFields(res.data.fields);
          setKitaUserFieldsLoading(false);
        })
        .catch(() => {
          setKitaUserFieldsLoading(false);
        });
    }
  }, [chosenKita?.value, chosenKita?.kitaId]);

  const update = async (fields, customFields) => {
    try {
      const removeEmptyCustomField = customFields.filter(x => !!x.fieldName);
      setUpdateKitaFieldsLoading(true);
      await updateKitaUserFields(selectedKita.value, { fields, customFields: removeEmptyCustomField });
      return true;
    } catch (err) {
      throw err;
    } finally {
      setUpdateKitaFieldsLoading(false);
    }
  };

  if (chosenKita) {
    return {
      kitaUserFields,
      kitaUserFieldsLoading,
      kitaCustomFields: customFields,
    };
  }

  return {
    kitaUserFields,
    kitaUserFieldsLoading,
    kitas,
    selectedKita,
    setSelectedKita,
    updateKitaUserFields: update,
    updateKitaUserFieldsLoading,
    kitaCustomFields: customFields,
  };
}
