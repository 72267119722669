import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import axiosInstance from 'library/api/axios';
import Loader from 'library/common/commonComponents/Loader';
import { getLanguages, updateLanguageTimezone } from 'library/api/user';
import Select from 'library/common/commonComponents/Inputs/Select';
import * as at from 'library/common/commonConstants/userConstants';
import useProfileLoading from 'library/common/commonHooks/useProfileLoading';
import useLanguages from 'library/common/commonHooks/useLanguages';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import styles from './settingsFrame.module.scss';
import Checkbox from '../../../../library/common/commonComponents/Checkbox';
import { toggleFavoriteOrder } from '../../../../library/api/settings';

export default function SettingsFrame({
  history,
  match,
  activeKita,
  showBottomNotification,
  user,
  patchUserInfo,
}) {
  useSiteTitle('Settings');
  const { profileInfo, isLoading } = useProfileLoading({
    profileId: user.id,
    userId: user.id,
    match,
    history,
    showBottomNotification,
    isAccountSettingsPage: true,
    kitaId: activeKita.kitaId,
  });
  const [languageOptions, setLanguageOptions] = useState([]);
  const [languageOption, setLanguageOption] = useState({ label: '', value: '' });
  const [orderFavorites, setOrderFavorites] = useState(user.orderFavorites);
  const [isLanguagesLoading, setIsLanguagesLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (!profileInfo.id) return;

    Promise.all([axiosInstance(at.URLS.staticLanguage), getLanguages()]).then(responses => {
      const [currentLanguage, languagesArray] = responses;

      const { langCode } = currentLanguage.data;
      const options = languagesArray.data.map(item => ({
        ...item,
        label: item.languageName,
        value: item.id,
      }));
      options.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      setLanguageOptions(options);
      // setLanguageOption(options.find(option => option.langCode === langCode));
      setIsLanguagesLoading(false);
    });
  }, [profileInfo]);

  const { t } = useTranslation();

  const { changeLanguage, selectedLanguage } = useLanguages();

  useEffect(() => {
    if (languageOptions && languageOptions.length > 0 && selectedLanguage) {
      const curLang = languageOptions.find(l => l.langCode === selectedLanguage);

      if (curLang) {
        setLanguageOption(curLang);
      }
    }
  }, [languageOptions]);

  useEffect(() => {
    if (isLoading || isLanguagesLoading || isInitialLoad) return;

    updateLanguageTimezone({
      languageId: languageOption.value,
    }).then(() => {
      const updatedUserInfo = { ...user, langCode: languageOption.languageName };
      showBottomNotification(t('BottomNotifications.Saved'));
      patchUserInfo(updatedUserInfo);
      changeLanguage({
        preventDefault: () => {},
        target: { value: languageOption.langCode },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageOption]);

  const onOrderFavoritesChange = () => {
    toggleFavoriteOrder().then(() => {
      setOrderFavorites(!orderFavorites);
      window.location.reload(false);
    });
  };

  const onChangeLanguage = option => {
    setIsInitialLoad(false);
    setLanguageOption(option);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <div className={styles.selectContainer}>
        {languageOptions.length !== 0 && (
          <>
            <Trans i18nKey='AccountSettings.Language' />
            <Select
              options={languageOptions}
              onSelect={onChangeLanguage}
              selected={languageOption}
              openedClassName={styles.selectOpen}
              isNoTranslated
            />
          </>
        )}
        <br />
        <strong>{t('KitaFavorites.settings label')}</strong>
        <Checkbox
          className={styles.checkboxSpace}
          isChecked={orderFavorites}
          onChange={onOrderFavoritesChange}
          name={t('KitaFavorites.settings')}
        />
      </div>
    </div>
  );
}
