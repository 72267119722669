import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupContext } from 'modules/Group/groupModule';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Loader from 'library/common/commonComponents/Loader';
import { getWikiEntries } from '../../../../api/wiki';
import styles from './wikiindex.module.scss';

export default function WikiIndex({ onWikiEntryClicked }) {
  const { t } = useTranslation();
  const { groupInfo } = useContext(GroupContext);

  const [wikiEntries, setWikiEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const renderWikiEntryList = entries => {
    return entries.length === 0 ? null : (
      <ul>
        {entries.map(item => {
          return (
            <li key={item.wikiEntryId} className={styles.wikiEntryItem}>
              <div onClick={() => onWikiEntryClicked(item)}>
                <i className='fa fa-file' />
                <span> {item.title}</span>
              </div>
              {renderWikiEntryList(item.childWikiEntries)}
            </li>
          );
        })}
      </ul>
    );
  };

  useEffect(() => {
    getWikiEntries(groupInfo.groupId)
      .then(res => {
        setWikiEntries(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        store.dispatch(showBottomNotification(t('Wiki.An error occured'), { isFail: true }));
      });
  }, []);

  return (
    <>
      <h1>{t('Wiki.Index')}</h1>
      {isLoading && <Loader />}
      {!isLoading && renderWikiEntryList(wikiEntries)}
    </>
  );
}
