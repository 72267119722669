import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import deepcopy from 'deepcopy';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Label from 'library/common/commonComponents/Label';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import { getCanBePublicStatus } from 'library/utilities/posts';

import styles from './editGalleryPopup.module.scss';

EditGalleryPopup.defaultProps = {
  initialData: {
    galleryName: '',
    description: '',
    privateGallery: true,
  },
};
export default function EditGalleryPopup({
  title,
  isOpened,
  closePopup,
  initialData,
  createGallery,
  group,
  galleries,
}) {
  const fields = deepcopy(initialData);
  const [galleryName, setGalleryName] = useState(fields.galleryName);
  const [description, setDescription] = useState(fields.description);
  const [privateGallery, setPrivateGallery] = useState(fields.privateGallery);
  const [titleError, setTitleError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setGalleryName(fields.galleryName);
    setDescription(fields.description);
    setPrivateGallery(fields.privateGallery);
    // eslint-disable-next-line
  }, [initialData]);
  const { t } = useTranslation();

  const onSubmit = () =>
    onSubmitHandler({
      galleryName,
      description,
      privateGallery,
      setTitleError,
      createGallery,
      closePopup,
      t,
      galleries,
      galleryId: initialData.id,
      isSubmitting,
      setIsSubmitting,
    });

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='small'
      header={title}
      body={
        <div className={cn(styles.container)}>
          <div className={styles.inputGroup}>
            <Label type='input'>{t('EditGalleryPopup.Title')}</Label>
            <Input
              value={galleryName}
              onChange={({ target }) => {
                setGalleryName(target.value);
                if (target.value.trim().length > 0) {
                  setTitleError(null);
                }
              }}
              maxLength={255}
              error={titleError}
            />
            {titleError && <div className={styles.error}>{titleError}</div>}
          </div>

          <div className={styles.inputGroup}>
            <Label type='input'>{t('EditGalleryPopup.Description')}</Label>
            <TextArea
              value={description}
              onChange={({ target }) => setDescription(target.value)}
              maxLength={1000}
            />
          </div>

          {/*getCanBePublicStatus(group) && (
            <CheckBox
              name={t('EditGalleryPopup.Make this gallery public')}
              isChecked={!privateGallery}
              onChange={() => setPrivateGallery(!privateGallery)}
            />
          )*/}
        </div>
      }
      footer={
        <div>
          <ButtonWithLoader
            onClick={onSubmit}
            type='primary'
            className={styles.footerBtnLeft}
            isLoading={isSubmitting}
          >
            {t('Popup.Save')}
          </ButtonWithLoader>
          <Button onClick={closePopup}>{t('Popup.Close')}</Button>
        </div>
      }
    />
  );
}

export async function onSubmitHandler({
  galleryName,
  description,
  privateGallery,
  setTitleError,
  createGallery,
  closePopup,
  t,
  galleries,
  galleryId,
  isSubmitting,
  setIsSubmitting,
}) {
  if (isSubmitting) {
    return;
  }
  if (galleryName.trim().length === 0) {
    setTitleError(t('EditGalleryPopup.Title cannot be blank'));
  } else if (
    galleries.find(gallery => gallery.galleryName === galleryName && gallery.id !== galleryId)
  ) {
    setTitleError(t('EditGalleryPopup.Gallery name must be unique'));
  } else {
    setIsSubmitting(true);
    setTitleError(null);
    await createGallery({
      galleryName,
      description,
      privateGallery,
    });
    setIsSubmitting(false);
    closePopup();
  }
}
