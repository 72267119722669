import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Checkbox from 'library/common/commonComponents/Checkbox';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import useGallery from 'library/common/commonHooks/useGallery';
import styles from './editAuthorizedPopup.module.scss';
import FilePrewiev from '../../FilePrewiev';

const initial = {
  email: '',
  firstName: '',
  lastName: '',
  phonenumber: '',
  image: '',
  privacyAccepted: false,
};
export default function EditAuthorizedPersonPopup({
  isOpened,
  closePopup,
  onEditBtnClick,
  headerText,
  isSubmiting,
  personToEdit,
}) {
  const [person, setPerson] = useState(personToEdit);

  const [error, setError] = useState('');
  const [previewFiles, setPreviewFiles] = useState([]);

  const [file, setFile] = useState(null);

  const [newImage, setNewImage] = useState(null);

  const [origFileDeleted, setOrigFileDeleted] = useState(false);

  const handleSaveNewImage = (img) => {
    setNewImage(img)
  }

  const { Gallery, openGallery } = useGallery(false, handleSaveNewImage);

  useEffect(() => {
    if (person.image && person.image !== '') {
      const tmpFile = {};
      const tmpPreviewFiles = [];
      tmpFile.path = person.image;
      tmpFile.fileId = person.lastName + '.jpg';
      tmpPreviewFiles.push(tmpFile);
      setPreviewFiles(tmpPreviewFiles);
    }
  }, []);

  const { t } = useTranslation();
  const handleDeleteFile = () => {
    const newPerson = person;
    newPerson.image = '';
    setPerson(newPerson);
    setPreviewFiles([]);
    setOrigFileDeleted(true);
    setNewImage(null);
  };

  const handleClick = async () => {
    if (isSubmiting) {
      return;
    }
    if (person.lastName === '' || person.firstName === '') {
      setError(t('AuthorizedPersonPopup.ErrorName'));
      return;
    }
    if (!person.privacyAccepted) {
      setError(t('AuthorizedPersonPopup.ErrorPrivacy'));
      return;
    }

    await onEditBtnClick({
      email: person.email,
      lastName: person.lastName,
      firstName: person.firstName,
      phonenumber: person.phonenumber,
      image: newImage || file,
      origImage: origFileDeleted ? null : personToEdit.image,
    });
    setPerson(initial);
    closePopup();
  };

  const onFilesChange = event => {
    setFile(event.target.files[0]);
    setNewImage(null);

    const itemPath = URL.createObjectURL(event.target.files[0]);
    const tmpFile = {};
    const tmpPreviewFiles = [];
    tmpFile.path = itemPath;
    tmpFile.fileId = event.target.files[0].name;
    tmpPreviewFiles.push(tmpFile);
    setPreviewFiles(tmpPreviewFiles);
  };

  const handleOpenGallery = (file) => {
    openGallery([file]);
  }

  return (
    <Popup
      size='extraSmall'
      isOpened={isOpened}
      closePopup={closePopup}
      header={headerText}
      body={
        <div>
          {error && (
            <div className={styles.popupText}>
              <span style={{ color: 'red' }}>{error}</span>
            </div>
          )}
          <Input
            className={styles.popupInput}
            placeholder={t('AuthorizedPersonPopup.Email')}
            value={person.email}
            onChange={event => setPerson({ ...person, email: event.target.value })}
          />
          <Input
            className={styles.popupInput}
            placeholder={t('AuthorizedPersons.FirstName')}
            value={person.firstName}
            onChange={event => setPerson({ ...person, firstName: event.target.value })}
          />
          <Input
            className={styles.popupInput}
            placeholder={t('AuthorizedPersons.LastName')}
            value={person.lastName}
            onChange={event => setPerson({ ...person, lastName: event.target.value })}
          />
          <Input
            className={styles.popupInput}
            placeholder={t('AuthorizedPersons.Phonenumber')}
            value={person.phonenumber}
            onChange={event => setPerson({ ...person, phonenumber: event.target.value })}
          />

          {previewFiles.length > 0 ? (
            <>
              <FilePrewiev newFiles={previewFiles} onDelete={handleDeleteFile} onOpenGallery={handleOpenGallery} />
              <Gallery />
            </>
          ) : (
            <div className={cn(styles.upload, styles.uploadButton)}>
              <label>
                <i className='fa fa-cloud-upload' />
                <input type='file' onChange={onFilesChange} multiple={false} accept='image/*' />
                {t('Upload Image')}
              </label>
            </div>
          )}
          <Checkbox
            className={styles.popupInput}
            name={t('AuthorizedPersonPopup.Privacy')}
            isChecked={person.privacyAccepted}
            onChange={() => setPerson({ ...person, privacyAccepted: !person.privacyAccepted })}
          />
        </div>
      }
      footer={
        <div className={styles.popupBtns}>
          <Button onClick={closePopup}>{t('Popup.Cancel')}</Button>
          <ButtonWithLoader onClick={handleClick} type='primary' isLoading={isSubmiting}>
            {t('AuthorizedPersonPopup.Edit')}
          </ButtonWithLoader>
        </div>
      }
    />
  );
}
