const developmentEnv = v => {
  // check environment variable for local backend development profile
  // set environment variable REACT_APP_DEV_PROFILE to 'local', 'noris-dev' or 'staging'
  let devProfile = process.env.REACT_APP_DEV_PROFILE || '';
  let proto = 'https';
  let protows = 'wss';
  let hostname;
  let tenant = v ? `${v}` : '';
  let domain = v ? '.safe2connect.org' : '.stramplerbande.org';

  if (devProfile === 'local') {
    proto = 'http';
    protows = 'ws';
    // ignore tenant for local_backend
    tenant = '';
    hostname = 'localhost';
    domain = '';
  } else if (devProfile === 'staging') {
    hostname = 'staging';
  } else if (devProfile === 'prod') {
    hostname = '';
    domain = 'stramplerbande.org';
  } else {
    // default to dev
    hostname = 'dev';
  }
  if (v === 5) {
    // override always to mobile when v equals 5
    proto = 'https';
    hostname = 'mobile';
    tenant = '';
    domain = '.stramplerbande.org';
  }

  return {
    api: `${proto}://${hostname}${tenant}${domain}`,
    ws: `${protows}://${hostname}${tenant}${domain}/wsapp`,
  };
};

const productionEnv = {
  api: window.location.origin,
  ws: `wss://${window.location.host}/wsapp`,
};

export const isDev = window.location.hostname === 'localhost';

const currentEnv = isDev ? developmentEnv(0) : productionEnv;

export default {
  API_BASE_URI: currentEnv.api,
  WS_URI: currentEnv.ws,
};

const portsToServiceNameMap = {
  8080: 'usermgmt',
  8091: 'posts',
  8095: 'notification',
};

export function getPort(port) {
  return `/${portsToServiceNameMap[port]}`;
}

export const VOICE_MESSAGE_AVAILABLE_APPS = ['mt-sb52']
