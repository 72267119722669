import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './foodItem.module.scss';
import Input from '../../../../../../Inputs/Input';
import Button from '../../../../../../Buttons/Button';
import TextArea from '../../../../../../Inputs/TextArea';
import DeletePopup from '../../../../../../Popups/DeletePopup';
import { formatPrice } from '../../../../../../../../utilities/foodOrder';

export default function FoodItem({ id, name, description, price, removeFood, updateFood }) {
  const { t } = useTranslation();

  const [localName, setLocalName] = useState(name);
  const [localDescription, setLocalDescription] = useState(description);
  const [localPrice, setLocalPrice] = useState(formatPrice(price));
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  useEffect(() => {
    updateFood({ id, name: localName, price: localPrice, description: localDescription });
  }, [localName, localDescription, localPrice]);
  return (
    <div>
      <label>{t('FoodOrder.name')}</label>
      <Input
        type='text'
        value={localName}
        placeholder={t('FoodOder.Placeholder.name')}
        onChange={e => setLocalName(e.target.value)}
      />
      <br />

      <label>{t('FoodOrder.description')}</label>
      <TextArea
        value={localDescription}
        placeholder={t('FoodOder.Placeholder.description')}
        onChange={e => setLocalDescription(e.target.value)}
      />
      <br />
      <label>{t('FoodOrder.price')}</label>
      <Input
        type='number'
        value={localPrice}
        faIcon='fa-eur'
        min={0}
        step={0.01}
        onChange={e => setLocalPrice(e.target.value)}
        onBlur={e => setLocalPrice(formatPrice(e.target.value))}
      />
      <br />
      <Button type='danger' onClick={() => setIsDeletePopupOpen(true)}>
        <i className='fa fa-times' /> {t('FoodOrder.delete')}
      </Button>
      <DeletePopup
        isOpened={isDeletePopupOpen}
        closePopup={() => setIsDeletePopupOpen(false)}
        onDeleteBtnClick={() => removeFood(id)}
        headerText={<Trans i18nKey='FoodOrder.delete' />}
        bodyText={t('FoodOrder.Popup.delete.text')}
      />
    </div>
  );
}
