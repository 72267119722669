import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from 'library/common/commonComponents/Popups/Popup';

import styles from './colorPickerPopup.module.scss';
import ColorPickerButton from '../../Buttons/ColorPickerButton';

export default function ColorPickerPopup({
  isOpened,
  closePopup,
  onSaveButtonClick,
  headerText,
  backgroundColor,
}) {
  const { t } = useTranslation();
  const [color, setColor] = useState(backgroundColor);

  return (
    <Popup
      size='small'
      isOpened={isOpened}
      closePopup={closePopup}
      header={headerText}
      body={
        <div className={styles.popupText}>
          <ColorPickerButton color={color} onClick={setColor} />
        </div>
      }
      footer={
        <div className={styles.popupBtns}>
          <Button
            onClick={() => {
              onSaveButtonClick(color);
              closePopup();
            }}
            type='primary'
          >
            {t('Popup.Save')}
          </Button>
          <Button onClick={closePopup} type='primary'>
            {t('Popup.Cancel')}
          </Button>
        </div>
      }
    />
  );
}
