import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import styles from './sidePanel.module.scss';
import Input from '../Inputs/Input';
import { isAwoWW } from 'library/api/tenantConfig';

export function SidePanel({
  children,
  isOpened,
  onOpen,
  containerRef,
  searchKitaList,
  originalKitaList,
}) {
  const { t } = useTranslation();
  return (
    <div
      ref={containerRef}
      className={isAwoWW() ? cn(styles.sidePanelContainerOnlyForColor) : cn(styles.sidePanelContainer, isOpened && styles.openedContainer)}
    >
      {originalKitaList && originalKitaList.length > 5 && isOpened && !isAwoWW() && (
        <Input
          className={styles.searchBar}
          onChange={e => searchKitaList(e.target.value)}
          type='text'
          placeholder={t('Search.search')}
        />
      )}

      {isOpened && children}
      {!isAwoWW() && (<div className={styles.sidePanelOpenButtonContainer}>
        <div className={cn(styles.sidePanelOpenButton)} onClick={() => onOpen(!isOpened)}>
          <i className={isOpened ? 'fa fa-chevron-left' : 'fa fa-chevron-right'} />
        </div>
      </div>)}
    </div>
  );
}

export default SidePanel;

SidePanel.defaultProps = {
  onOpen: () => {},
};
