import React from 'react';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { sanitize } from 'dompurify';

const CheckboxFormContractContainer = ({ children, ff, user }) => {
  if (!ff.labelRight || ff.labelRight.length == 0)
    return (
      <div
        style={{
          marginLeft: `${ff.indent}pt`,
          marginBottom: '1em',
          fontSize: '12pt',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(50%, 1fr))',
          gridAutoRows: 'minmax(20px, auto)',
          position: 'relative',
          maxWidth: '500px',
          marginLeft: '10px',
        }}
      >
        {user.currentWatsonLang !== 'de' ? (
          <WithWatsonTranslate
            data={{ text: ff.labelLeft, entityId: ff.id, entityType: 'formEditor' }}
            Component={({ html }) => <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />}
          />
        ) : (
          ff.labelLeft
        )}{' '}
        {children}
      </div>
    );
  else
    return (
      <p
        style={{
          marginLeft: `${ff.indent}pt`,
          marginBottom: '1em',
          fontSize: '12pt',
          marginLeft: '10px',
          display: !ff.labelLeft || ff.labelLeft.length == 0 ? 'block' : 'inline',
        }}
      >
        {user.currentWatsonLang !== 'de' ? (
          <WithWatsonTranslate
            data={{ text: ff.labelLeft, entityId: ff.id, entityType: 'formEditor' }}
            Component={({ html }) => <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />}
          />
        ) : (
          ff.labelLeft
        )}{' '}
        {children}{' '}
        {user.currentWatsonLang !== 'de' ? (
          <WithWatsonTranslate
            data={{ text: ff.labelRight, entityId: ff.id, entityType: 'formEditor' }}
            Component={({ html }) => <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />}
            display={!ff.labelLeft || ff.labelLeft.length == 0 ? 'block' : 'inline'}
          />
        ) : (
          ff.labelRight
        )}
      </p>
    );
};

export default CheckboxFormContractContainer;
