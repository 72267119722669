import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getCalendarEvents,
  getCalendarSettings,
  deleteCalendarEvent,
  addCalendarEvent,
  updateCalendarEvent,
} from 'library/api/calendar';
import { getGroupHolidayCares } from 'library/api/groups';
import { getKitaHolidayCares } from 'library/api/kita';
import { getKitaHolidays } from 'library/api/kita';
import { inviteUsersAPI } from 'library/common/commonActions/groupActions';
import Calendar from 'library/common/commonComponents/Calendar';
import useCalendarEvents from 'library/common/commonHooks/useCalendarEvents';
import { formatHolidayEvents, formatHolidayCareEvents, formatBirthdayEvents } from 'library/utilities/formatEvents';
import { getBirthdayEventByGroup } from 'library/api/user';

const initialFilters = [
  { id: 1, name: 'attending', checked: false },
  { id: 2, name: 'myEvents', checked: false },
  { id: 3, name: 'currentViewDate', value: new Date() },
  {
    id: 4,
    name: 'showAbsences',
    checked: window.location.hostname.toLowerCase().includes('gfz-app.ch'),
  },
  { id: 5, name: 'publicHolidays', checked: false },
  { id: 6, name: 'holidays', checked: false },
  { id: 7, name: 'groupHolidayCare', checked: false },
  { id: 8, name: 'kitaHolidayCare', checked: false },
  { id: 9, name: 'kidBirthdayEvent', checked: false },
];

export default function GroupCalendar({ showBottomNotification, groupInfo, match, user }) {
  const { t } = useTranslation();

  const {
    defaultGroup,
    groupId,
    groupAdminStatus,
    createCalenderEntry,
    editDeleteCalenderEntry,
    processUnassignedTask,
  } = groupInfo;

  const [holidayEvents, setHolidayEvents] = useState([]);
  const [groupHolidayCareEvents, setGroupHolidayCareEvents] = useState([]);
  const [kitaHolidayCareEvents, setKitaHolidayCareEvents] = useState([]);
  const [birthdayEvents, setBirthdayEvents] = useState([]);
  const showBirthdayButton = user.administrationAccess || user.employee;

  const { kitaId } = useSelector(state => state.kitaReducer.activeKita);

  const defaultFilters = useMemo(() => {
    if (defaultGroup) {
      return [...initialFilters, { id: 6, name: 'holidaySettings', checked: false }];
    }
    return initialFilters;
  }, [defaultGroup]);

  const getEditableValue = (ownerId, task) => {
    if (!task) {
      return editDeleteCalenderEntry && (groupAdminStatus || user.id === ownerId);
    }
    const isResponsible = task.responsibleUsersList.find(item => item.id === user.id);
    const canEditTask =
      user.id === ownerId ||
      (!task.assignedUsersList.length && processUnassignedTask) ||
      groupAdminStatus ||
      isResponsible;
    return canEditTask;
  };

  const {
    events,
    calendarSettings,
    deleteEvent,
    addEvent,
    updateEvent,
    getEventInfo,
    filters,
    setFilters,
    reloadEvents,
  } = useCalendarEvents(
    getCalendarEvents,
    getCalendarSettings,
    deleteCalendarEvent,
    addCalendarEvent,
    updateCalendarEvent,
    defaultFilters,
    groupId,
    getEditableValue,
    'groupCalendar',
    user.currentWatsonLang,
    groupAdminStatus,
  );

  const holidaySettingOptionChange = () => {
    setFilters(
      filters.map(item => {
        return item.id === 6 ? { ...item, checked: !item.checked } : item;
      }),
    );
    const isHolidaySettingChecked = filters.find(item => item.id === 6)?.checked;
    if (!isHolidaySettingChecked) {
      getKitaHolidays(kitaId).then(res => {
        if (res.status === 200 && res.data.length > 0) {
          const formattedEvents = formatHolidayEvents(res.data);
          setHolidayEvents(formattedEvents);
        }
      });
    } else {
      setHolidayEvents([]);
    }
  };

  const groupHolidayCareOptionChange = () => {
    setFilters(
      filters.map(item => {
        return item.id === 7 ? { ...item, checked: !item.checked } : item;
      }),
    );
    const isGroupHolidayCareChecked = filters.find(item => item.id === 7)?.checked;
    if (!isGroupHolidayCareChecked) {
      getGroupHolidayCares(groupId).then(res => {
        if (res.status === 200 && res.data.length > 0) {
          const formattedEvents = formatHolidayCareEvents(res.data, 'group');
          setGroupHolidayCareEvents(formattedEvents);
        }
      });
    } else {
      setGroupHolidayCareEvents([]);
    }
  };

  const kitaHolidayCareOptionChange = () => {
    setFilters(
      filters.map(item => {
        return item.id === 8 ? { ...item, checked: !item.checked } : item;
      }),
    );
    const isKitaHolidayCareChecked = filters.find(item => item.id === 8)?.checked;
    if (!isKitaHolidayCareChecked) {
      getKitaHolidayCares(kitaId).then(res => {
        if (res.status === 200 && res.data.length > 0) {
          const formattedEvents = formatHolidayCareEvents(res.data);
          setKitaHolidayCareEvents(formattedEvents);
        }
      });
    } else {
      setKitaHolidayCareEvents([]);
    }
  };

  const kidBirthdayEventOptionChange = () => {
    setFilters(
      filters.map(item => {
        return item.id === 9 ? { ...item, checked: !item.checked } : item;
      }),
    );
  };

  const filterList = [
    {
      title: t('Calendar.Filter events'),
      filters: [
        {
          id: 1,
          name: t("Calendar.I'm attending"),
          checked: filters.reduce((result, item) => {
            return item.id === 1 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 1 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 2,
          name: t('Calendar.My events'),
          checked: filters.reduce((result, item) => {
            return item.id === 2 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 2 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 3,
          name: t('Calendar.Show absences'),
          checked: filters.reduce((result, item) => {
            return item.id === 4 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 4 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 4,
          name: t('Calendar.Public Holidays'),
          checked: filters.reduce((result, item) => {
            return item.id === 5 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 5 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 5,
          name: t('Calendar.Holidays'),
          checked: filters.reduce((result, item) => {
            return item.id === 6 ? item.checked : result;
          }, ''),
          hidden: !defaultGroup,
          onChange: holidaySettingOptionChange,
        },
        {
          id: 6,
          name: t('Calendar.Group Holiday Care'),
          checked: filters.reduce((result, item) => {
            return item.id === 7 ? item.checked : result;
          }, ''),
          hidden: !defaultGroup,
          onChange: groupHolidayCareOptionChange,
        },
        {
          id: 7,
          name: t('Calendar.Kita Holiday Care'),
          checked: filters.reduce((result, item) => {
            return item.id === 8 ? item.checked : result;
          }, ''),
          hidden: !defaultGroup,
          onChange: kitaHolidayCareOptionChange,
        },
        {
          id: 8,
          name: t('Calendar.KidBirthday'),
          checked: filters.reduce((result, item) => {
            return item.id === 9 ? item.checked : result;
          }, ''),
          hidden: !showBirthdayButton,
          onChange: kidBirthdayEventOptionChange,
        },
      ],
    },
  ];

  const onAddEvent = async (event) => {
    await inviteUsersAPI({
      groupId: groupId,
      userGroup: event.parentParticipants,
    });
    return addEvent({ ...event, groupId, userId: user.id }, 'groupCalendar');
  };

  useEffect(()=> {
    const fetchKidBirthdayEventData = async() => {
      const isBirthdayEventChecked = filters.find(item => item.id === 9)?.checked;
      if(isBirthdayEventChecked) {
        const currentDate = filters ? filters.find(item => item.name === 'currentViewDate').value : new Date();
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const nextMonthDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        const endDate = new Date(nextMonthDate - 1);
        const birthdayEvents = (await getBirthdayEventByGroup(groupId, startDate.getTime(), endDate.getTime())).data;
        const formattedEvents = formatBirthdayEvents(birthdayEvents, groupId);
        setBirthdayEvents(formattedEvents);
      }
      else {
        setBirthdayEvents([]);
      }
    }
    fetchKidBirthdayEventData();
  },[filters])

  return (
    <Calendar
      showBottomNotification={showBottomNotification}
      events={[...events, ...holidayEvents, ...groupHolidayCareEvents, ...kitaHolidayCareEvents, ...birthdayEvents]}
      calendarSettings={calendarSettings}
      canCreateEvent={createCalenderEntry}
      getEventInfo={getEventInfo}
      addEvent={onAddEvent}
      updateEvent={updateEvent}
      deleteEvent={deleteEvent}
      isAdmin={groupAdminStatus}
      match={match}
      filterList={filterList}
      reloadEvents={reloadEvents}
      groupInfo={groupInfo}
      user={user}
      filters={filters}
      setFilters={setFilters}
      calendarModulePlace='groupCalendar'
      getEditableValue={getEditableValue}
    />
  );
}
