import i18n from 'i18next';
import { enGB, de } from 'date-fns/locale';
import { format } from 'date-fns';

export function timeSince({ date, startingDate = new Date(), langCode, isInTheFuture = false }) {
  const dateFormat = langCode === 'English' ? 'd MMM yyyy' : 'dd.MM.yyyy';

  if (isInTheFuture) {
    return format(date, `${dateFormat} HH:mm`);
  }

  const seconds = Math.floor((startingDate - date) / 1000);

  let interval = Math.floor(seconds / 31536000 / 2592000 / 86400);
  if (interval > 1) {
    return format(date, dateFormat);
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 48) {
    return format(date, dateFormat);
  }
  if (interval > 23) {
    return i18n.t('Date.a day ago');
  }
  if (interval > 1) {
    return i18n.t('Date.about {interval} hours ago').replace('{interval}', interval);
  }

  interval = Math.floor(seconds / 60);
  if (interval > 59) {
    return i18n.t('Date.about an hour ago');
  }

  if (interval > 1) {
    return i18n.t('Date.{interval} minutes ago').replace('{interval}', interval);
  }

  return i18n.t('Date.less than a minute ago');
}

export function getUpdatedTime(updatedAt, langCode) {
  const dateFormat = langCode === 'English' ? 'd MMM yyyy' : 'dd.MM.yyyy';
  return format(new Date(updatedAt), dateFormat);
}

export function getDateRange(startDate, endDate, allDay, langCode) {
  const dateFormat = allDay
    ? langCode === 'English'
      ? 'd MMMM yyyy'
      : 'dd MMMM yyyy'
    : langCode === 'English'
    ? 'd MMMM yyyy, HH:mm'
    : 'dd MMMM yyyy, HH:mm';

  const locale = langCode === 'English' ? enGB : de;
  return startDate === endDate
    ? format(new Date(startDate), dateFormat, { locale })
    : format(new Date(startDate), dateFormat, { locale }) +
        ' - ' +
        format(new Date(endDate), dateFormat, { locale });
}

export function getDeadlineTime(deadlineTime, allDay, langCode) {
  const locale = langCode === 'English' ? enGB : de;

  return format(
    new Date(deadlineTime),
    langCode === 'English'
      ? `dd/MM/yyyy${allDay ? '' : ' HH:mm'}`
      : `dd.MM.yyyy${allDay ? '' : ' HH:mm'}`,
    { locale },
  );
}

export function getCreateAtAccountDte(createdAt, dateFormat = 'dd.MM.yyyy HH:mm:ss') {
  return createdAt ? format(new Date(createdAt), dateFormat) : '-';
}

export function isValidDate(dateString) {
  // First check for the pattern
  var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

  if (!regex_date.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  var parts = dateString.split('-');
  var day = parseInt(parts[2], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false;
  }

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export const formatTimeToLanguage = (date, langCode) => {
  try {
    const fixBelowTen = original => (original < 10 ? `0${original}` : original);
    const originalTime = new Date(`${date.split(' ').join('T')}:00`);
    let localeDateString = originalTime.toLocaleDateString(langCode);

    let symbol = '/';
    let dateArray = localeDateString.split('/');
    if (langCode === 'de') {
      symbol = '.';
      dateArray = localeDateString.split('.');
    }
    localeDateString = `${fixBelowTen(dateArray[0])}${symbol}${fixBelowTen(dateArray[1])}${symbol}${
      dateArray[2]
    }`;
    const hours = originalTime.getHours();
    const minutes = originalTime.getMinutes();

    return `${localeDateString} ${fixBelowTen(hours)}:${fixBelowTen(minutes)}`;
  } catch (error) {
    return date;
  }
};

export function adjustTimeZone(dateString) {
  const formatString = `${dateString.replace(' ', 'T')}.000+0000`;

  return new Date(formatString);
}

export function getDigitString(num) {
  if (num > 9) return `${num}`;
  return `0${num}`;
}

export function formatDate(day, time) {
  const dateString = `${day.getFullYear()}-${getDigitString(day.getMonth() + 1)}-${getDigitString(
    day.getDate(),
  )}`;
  const timeString = `${getDigitString(time.getHours())}:${getDigitString(time.getMinutes())}`;
  return `${dateString} ${timeString}`;
}

export function isBookingTimeOver(bookingTime) {
  if (!bookingTime) return false;

  const currentTime = format(new Date(), 'HH:mm');
  const [bookingHH, bookingMM] = bookingTime.split(':').map(Number);
  const [currentHH, currentMM] = currentTime.split(':').map(Number);

  const totalMinOfBooking = bookingHH * 60 + bookingMM;
  const totalMinOfCurrent = currentHH * 60 + currentMM;

  return totalMinOfCurrent - totalMinOfBooking >= 5;
}
