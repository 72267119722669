import { useEffect, useState } from 'react';

export default function useLoadMore({ request, pageSize }) {
  const [itemsState, setItemsState] = useState({
    items: [],
    totalCount: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsAreLoading, setItemsAreLoading] = useState(false);
  const [noMoreItems, setNoMoreItems] = useState(false);

  const loadItems = first => {
    if (itemsAreLoading) return;
    setItemsAreLoading(true);

    request(
      {
        page: first ? 1 : currentPage,
        filter: ['deleted:eq:0'],
        size: pageSize,
      },
      { headers: { 'Content-Type': 'application/json' } },
    )
      .then(itemResponse => {
        setItemsState({
          ...itemsState,
          totalCount: itemResponse.data.totalElements,
          items: [...itemsState.items, ...itemResponse.data.content],
        });
        if (
          itemResponse.data.totalElements ===
          itemsState.items.length + itemResponse.data.content.length
        ) {
          setNoMoreItems(true);
        } else {
          setCurrentPage(currentPage + 1);
        }
      })
      .finally(() => {
        setItemsAreLoading(false);
      });
  };

  useEffect(() => {
    loadItems(true);
  }, []);

  return {
    items: itemsState.items,
    totalCount: itemsState.totalCount,
    itemsAreLoading,
    noMoreItems,
    loadItems,
  };
}
