import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import { getModules } from 'library/common/commonActions/groupActions';
import { getKitaModules } from 'library/api/modules';

import calendarModule from 'resources/images/modules/calendar_module.png';
import filesModule from 'resources/images/modules/files_module.png';
import galleryModule from 'resources/images/modules/gallery_module.png';
import surveysModule from 'resources/images/modules/surveys_module.png';
import parentSurveyModule from 'resources/images/modules/parent_survey_module.png';
import tasksModule from 'resources/images/modules/tasks_module.png';
import translationsModule from 'resources/images/modules/translations_module.png';
import absenceModule from 'resources/images/modules/absence_module.png';
import portfolioModule from 'resources/images/modules/portfolio_module.png';
import checkInOutModule from 'resources/images/modules/checkinout_module.png';
import bazaarModule from 'resources/images/modules/bazaar_module.png';
import signatureModule from 'resources/images/modules/signature_module.png';
import foodOrderModule from 'resources/images/modules/food_order_module.png';
import wikiModule from 'resources/images/modules/wiki.png';
import coronaModule from 'resources/images/modules/corona_test.png';
import chatModule from 'resources/images/modules/chat_module.png';
import whiteboardModule from 'resources/images/modules/whiteboard_module.png';
import imageDownloadModule from 'resources/images/modules/image_download_module.png';

import styles from './addModulesPopup.module.scss';

const modulesImage = [
  {
    moduleKey: 'absence',
    img: absenceModule,
    title: 'Absence',
  },
  {
    moduleKey: 'files',
    img: filesModule,
    title: 'Files',
  },
  {
    moduleKey: 'calendar',
    img: calendarModule,
    title: 'Calendar',
  },
  {
    moduleKey: 'gallery',
    img: galleryModule,
    title: 'Gallery',
  },
  {
    moduleKey: 'image_download',
    img: imageDownloadModule,
    title: 'Image Download',
  },
  {
    moduleKey: 'survey',
    img: surveysModule,
    title: 'Surveys',
  },
  {
    moduleKey: 'tasks',
    img: tasksModule,
    title: 'Tasks',
  },
  {
    moduleKey: 'translations',
    img: translationsModule,
    title: 'Translations',
  },
  {
    moduleKey: 'parent_survey',
    img: parentSurveyModule,
    title: 'Parent Survey',
  },
  {
    moduleKey: 'portfolio',
    img: portfolioModule,
    title: 'Portfolio',
  },
  {
    moduleKey: 'checkinout',
    img: checkInOutModule,
    title: 'CheckInOut',
  },
  {
    moduleKey: 'employee_checkinout',
    img: checkInOutModule,
    title: 'EmployeeCheckInOut',
  },
  {
    moduleKey: 'bazaar',
    img: bazaarModule,
    title: 'Bazaar',
  },
  {
    moduleKey: 'signature',
    img: signatureModule,
    title: 'Signature',
  },
  {
    moduleKey: 'food_order',
    img: foodOrderModule,
    title: 'Food Order',
  },
  {
    moduleKey: 'wiki',
    img: wikiModule,
    title: 'Wiki',
  },
  {
    moduleKey: 'corona_test',
    img: coronaModule,
    title: 'Corona Test',
  },
  {
    moduleKey: 'chat',
    img: chatModule,
    title: 'Chat',
  },
  {
    moduleKey: 'whiteboard',
    img: whiteboardModule,
    title: 'Whiteboard',
  },
  {
    moduleKey: 'holiday_care',
    img: calendarModule,
    title: 'Holiday care',
  },
];

export default function AddModulesPopup(props) {
  const { t } = useTranslation();

  useEffect(() => {
    getKitaModules().then(res => {
      const parsedModules = res.data
        .filter(
          mod =>
            mod.moduleKey !== 'translations' &&
            mod.moduleKey !== 'employee_portfolio' &&
            mod.moduleKey !== 'chat' &&
            mod.moduleKey !== 'suggestion_box' &&
            mod.moduleKey !== 'bazaar' &&
            mod.moduleKey !== 'tax_consultant',
        )
        .map(mod => ({
          ...mod,
          moduleEnableDisableStatus: mod.groupModuleStatus !== 'Always_Activated',
        }));

      const secondFilter = parsedModules.filter(el => {
        return el.activeStatus === true;
      });

      const thirdFilter = secondFilter.map(mod => ({
        ...mod,
        activeStatus:
          mod.groupModuleStatus === 'Always_Activated' ||
          (mod.moduleKey === 'files' && mod.groupModuleStatus === 'Activated') ||
          (mod.moduleKey === 'calendar' && mod.groupModuleStatus === 'Activated') ||
          (mod.moduleKey === 'gallery' && mod.groupModuleStatus === 'Activated') ||
          (mod.moduleKey === 'survey' && mod.groupModuleStatus === 'Activated') ||
          (mod.moduleKey === 'tasks' && mod.groupModuleStatus === 'Activated'),
        groupModuleStatus: mod.groupModuleStatus === 'Always_Activated',
      }));

      setModules(thirdFilter);
      const activeModules =
        thirdFilter && thirdFilter.length
          ? thirdFilter.reduce((result, item) => {
              return (result = item.activeStatus ? result.concat(item.moduleId) : result);
            }, [])
          : [];

      props.onDataChange({ modules: activeModules });
    });
    // eslint-disable-next-line
  }, []);

  const [modules, setModules] = useState([]);

  const pushModules = id => {
    const modulesTemp = props.value.modules.slice();
    const index = modulesTemp.indexOf(id);

    if (index === -1) {
      modulesTemp.push(id);
    } else {
      modulesTemp.splice(index, 1);
    }

    props.onDataChange({ modules: modulesTemp });
    setModules(
      modules.map(mod => ({
        ...mod,
        activeStatus: mod.moduleId === id ? !mod.activeStatus : mod.activeStatus,
      })),
    );
  };

  function getModuleImage(item) {
    const pic = modulesImage.find(image => item.moduleKey === image.moduleKey);
    if (pic != null && typeof pic.img !== 'undefined') {
      return pic.img;
    } else return '';
  }

  return (
    <Popup
      isOpened={props.isOpened}
      closePopup={props.closePopup}
      size='medium'
      header={<Trans i18nKey='CreateGroup.Add Modules' />}
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={props.back} type='primary'>
            {t('CreateGroup.Back')}
          </Button>
          <Button onClick={props.next} type='primary'>
            {t('CreateGroup.Next')}
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.modules}>
          {modules.map(item => (
            <div className={styles.module} key={item.moduleId}>
              <div className={styles.leftSection}>
                <div className={styles.module__image}>
                  <img src={getModuleImage(item)} alt='' />
                </div>
              </div>
              <div className={styles.rightSection}>
                <div className={styles.module__title}>{item.moduleName}</div>
                <div className={styles.module__description}>{item.moduleDescription}</div>
                <div
                  className={cn(
                    styles.module__select,
                    !item.moduleEnableDisableStatus && styles.module__select_disabled,
                  )}
                  onClick={() => item.moduleEnableDisableStatus && pushModules(item.moduleId)}
                >
                  {item.moduleEnableDisableStatus && !item.activeStatus
                    ? t('CreateGroup.Enable')
                    : t('CreateGroup.Disable')}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Popup>
  );
}

AddModulesPopup.defaultProps = {
  getModulesFromProps: getModules,
};
