import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { createKidLeavingTime, updateKidLeavingTime } from 'library/api/kids';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Select from 'library/common/commonComponents/Inputs/Select';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Label from 'library/common/commonComponents/Label';
import Loader from 'library/common/commonComponents/Loader';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import { formatDateObjectToTimeString } from 'library/utilities/dateMoment';

import styles from './kidLeavingTimePopupContainer.module.scss';

export default function KidLeavingTimePopupContainer({
  user,
  leavingTimes,
  kidOptions,
  editItem,
  onAdd,
  onEdit,
  closePopUp,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [kids, setKids] = useState([]);
  const [kid, setKid] = useState(null);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [leavingTime, setLeavingTime] = useState(new Date());
  const [note, setNote] = useState('');
  const [errors, setErrors] = useState({
    child: '',
    note: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (editItem) {
      const selectedKid = {
        value: editItem.kid.id,
        label: editItem.kid.firstName + ' ' + editItem.kid.lastName,
      };
      setKid(selectedKid);
      setBookingDate(new Date(editItem.bookingDate));
      const [hour, min] = editItem.leavingTime.split(':');
      const formattedLeavingTime = new Date();
      formattedLeavingTime.setHours(hour, min);
      setLeavingTime(formattedLeavingTime);
      setNote(editItem.note);
    }
    const kidsArray = kidOptions.map(item => ({
      value: item.kidId,
      label: item.name,
    }));
    setKids(kidsArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveData = () => {
    let error = false;
    const errorsTmp = {
      child: '',
      note: '',
    };
    if (!note) {
      errorsTmp.note = t('AccountSettings.AbsenceSettings.This field is required');
      error = true;
    } else {
      if (note.length > 500) {
        errorsTmp.note = t('AccountSettings.AbsenceSettings.Maximum length is 500');
        error = true;
      }
    }
    if (kid === null) {
      errorsTmp.child = t('AccountSettings.AbsenceSettings.This field is required');
      error = true;
    }
    setErrors(errorsTmp);
    if (!error) {
      editItem ? sendFormtoEdit() : sendForm();
    }
  };

  const sendForm = () => {
    const data = {
      kidId: kid.value,
      bookingDate: moment(bookingDate).format('YYYY-MM-DD'),
      leavingTime: moment(leavingTime).format('HH:mm'),
      note: note,
    };
    const existedRecord = leavingTimes.find(
      row => row.kid.id === data.kidId && row.bookingDate === data.bookingDate,
    );

    if (!!existedRecord) {
      dispatch(
        showBottomNotification('Booking Date for this kid was already set!', { isWarning: true }),
      );
      return;
    }

    setIsSubmitting(true);
    createKidLeavingTime(data)
      .then(res => {
        if (res.status === 200) {
          onAdd({
            ...res.data,
            leavingTime: formatDateObjectToTimeString(res.data.leavingTime),
          });
          closePopUp();
        }
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const sendFormtoEdit = () => {
    const data = {
      bookingDate: moment(bookingDate).format('YYYY-MM-DD'),
      leavingTime: moment(leavingTime).format('HH:mm'),
      note: note,
    };
    const existedRecord = leavingTimes.find(
      row =>
        row.id !== editItem.id &&
        row.kid.id === editItem.kid.id &&
        row.bookingDate === data.bookingDate,
    );
    if (!!existedRecord) {
      dispatch(
        showBottomNotification('Booking Date for this kid was already set!', { isWarning: true }),
      );
      return;
    }

    setIsSubmitting(true);
    updateKidLeavingTime(editItem.id, data)
      .then(res => {
        if (res.status === 200) {
          onEdit(editItem.id, data);
          closePopUp();
        }
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (!kids.length) {
    return (
      <div className={styles.wrapper}>{t('AccountSettings.AbsenceSettings.You have no kids')}</div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputChild}>
        <Label className={styles.label} type='input'>
          {t('AccountSettings.AbsenceSettings.Choose a child')}
        </Label>
        <Select
          options={kids}
          onSelect={data => setKid(data)}
          disabled={editItem ? true : false}
          placeholder={t('AccountSettings.AbsenceSettings.Choose child')}
          selected={kid}
          error={!!errors.child}
        />
        <InputErrorMsg errorMsg={errors.child} />
      </div>
      <div className={styles.dateWrapper}>
        <div className={styles.dateStartWrapper}>
          <div className={styles.dateStart}>
            <Label className={styles.label} type='input'>
              {t('AccountSettings.AbsenceSettings.From')}
            </Label>
            <DatePicker
              selected={bookingDate}
              onChange={data => setBookingDate(data)}
              minDate={new Date()}
              selectsStart
              startDate={bookingDate}
              langCode={user.langCode}
            />
          </div>
          <div className={styles.timeStart}>
            <Label className={styles.label} type='input'>
              {t('AccountSettings.AbsenceSettings.From time')}
            </Label>
            <DatePicker
              selected={leavingTime}
              onChange={data => setLeavingTime(data)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              timeFormat='HH:mm'
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Label className={styles.label} type='input'>
          {t('AccountSettings.Note')}
        </Label>
        <TextArea
          error={!!errors.note}
          value={note}
          onChange={e => setNote(e.target.value)}
          maxLength={500}
        />
        <div style={note.length > 500 ? { color: 'red' } : {}}>
          {note.length}/500 {t('AccountSettings.Characters')}
        </div>
        <InputErrorMsg errorMsg={errors.note} />
      </div>
      <>
        <div className={styles.footer}>
          <Button
            className={styles.saveButton}
            onClick={() => saveData()}
            type='primary'
            disabled={isSubmitting}
          >
            {t('AccountSettings.AbsenceSettings.Save')}
          </Button>
          <Button onClick={closePopUp} type='danger' disabled={isSubmitting}>
            {t('AccountSettings.AbsenceSettings.Cancel')}
          </Button>
        </div>
        {isSubmitting && (
          <>
            <br />
            <Loader />
          </>
        )}
      </>
    </div>
  );
}
