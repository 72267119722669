import React from 'react';
import { useTranslation } from 'react-i18next';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';

export default function TableBookingTimesEditView({ component, setComponent }) {
  const { t } = useTranslation();

  return (
    <div>
      <Label type='input'>{t('FormsEditor.ComponentEditorUniqueNameLabel')}</Label>
      <Input
        value={component.name}
        onChange={e => {
          setComponent({
            ...component,
            name: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorUniqueNamePlaceholder')}
      />
    </div>
  );
}
