import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './linkDisclaimer.module.scss';

export default function LinkDisclaimer({ link, toggleDisclaimerModal }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const onCancelHandler = function() {
    setTimeout(function() {
      toggleDisclaimerModal(false);
      document.body.style.overflow = 'unset';
    }, 150);

    setShow(false);
  };

  const proceedHandler = function() {
    window.open(link, '_blank');
    onCancelHandler();
  };

  useEffect(function() {
    setTimeout(() => {
      setShow(true);
    });
  }, []);

  return (
    <div className={`${styles.disclaimer} ${show && styles.open}`}>
      <div onClick={onCancelHandler} className={styles.disclaimerBackground} />
      <div className={styles.disclaimerWindowWrapper}>
        <div className={styles.disclaimerWindow}>
          <p>{t('Disclaimer.Disclaimer')}</p>
          <p>{t('Disclaimer.Leaving our website')}</p>
          <p>{t('Disclaimer.We are not responsible')}</p>
          <div className={styles.disclaimerControls}>
            <div
              onClick={proceedHandler}
              className={`${styles.disclaimerButton} ${styles.disclaimerButtonAccept}`}
            >
              {t('Registration.ok')}
            </div>
            <div onClick={onCancelHandler} className={styles.disclaimerButton}>
              {t('CookiesMessage.Cancel')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
