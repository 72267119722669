import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'library/common/commonComponents/Inputs/Input';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from '../../feedItemSurvey.module.scss';

export default function FeedItemSurveyOptionEditing({ value, id, onEdit, isSubmiting }) {
  const { t } = useTranslation();

  const [localValue, setLocalValue] = useState(value);
  const [inputError, setInputError] = useState(null);
  const saveOption = () => saveOptionHandler({ value: localValue, id, onEdit, setInputError, t });
  return (
    <div className={styles.addSuggestionWrapper}>
      <div className={styles.addSuggestionContainer}>
        <Input
          value={localValue}
          placeholder={t('Surveys.Add answer')}
          className={styles.addSuggestionInput}
          onChange={e => setLocalValue(e.target.value)}
        />
        <ButtonWithLoader
          type='default'
          size='sm'
          className={styles.addSuggestionButton}
          onClick={saveOption}
          isLoading={isSubmiting}
        >
          {t('Post.Save')}
        </ButtonWithLoader>
      </div>
      {inputError && <div className={styles.error}>{inputError}</div>}
    </div>
  );
}

export function saveOptionHandler({ value, id, onEdit, setInputError, t }) {
  if (value.trim().length === 0) {
    return setInputError(t('Surveys.Answer field cannot be blank'));
  }

  setInputError(null);
  onEdit(id, value);
}
