import React, { useContext, useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  getKids,
  getKidsByGroupPortfolio,
  createKidPortfolioGroupIfNeeded,
} from 'library/api/kids';
import { GroupContext } from 'modules/Group/groupModule';
import Card from 'library/common/commonComponents/Card';
import { getLetterInLogo } from 'library/utilities/groups';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from './portfolio.module.scss';

export default function Portfolio() {
  const { history } = useReactRouter();
  const { t } = useTranslation();
  const [kids, setKids] = useState([]);
  const groupContext = useContext(GroupContext);
  const { groupInfo } = groupContext;
  const kidIdList = [];

  useEffect(() => {
    getKidsByGroupPortfolio(groupInfo.groupId).then(res => {
      const sorted = res.data.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setKids(sorted);
    });
  }, []);

  return (
    <Card className={styles.container}>
      <div className={styles.container}>
        <h1>{t('Portfolio.Childrens portfolio')}</h1>
        <table className={styles.portfolioTable}>
          <thead>
            <tr>
              <th className={cn(styles.headerText)}>
                <div className={cn(styles.headerTextWrapper, styles.headerTextWithThePadding)}>
                  {t('Portfolio.Name')}
                </div>
              </th>
              <th className={cn(styles.headerText, styles.headerText)}>
                <div className={styles.headerTextWrapper}>{t('Portfolio.Birthday')}</div>
              </th>
              <th className={cn(styles.headerText, styles.headerText)}>
                <div className={styles.headerTextWrapper}>{t('Portfolio.Parents')}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {kids.map(kid => {
              // eslint-disable-next-line radix
              const kidBDay = new Date(parseInt(`${kid.birthday}`));
              const kidBirthday = kidBDay.toLocaleString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              });
              return (
                <tr key={kid.kidId}>
                  <td
                    onClick={() => {
                      createKidPortfolioGroupIfNeeded(kid.kidId).then(data => {
                        history.push(`/groups/${data.data}`);
                      });
                    }}
                  >
                    <div className={cn(styles.groupBox, styles.item)}>
                      <div className={styles.itemAvatar}>
                        <img src={kid.groupLogoUrl || defaultAvatar} />
                      </div>
                      <div className={cn(styles.itemName, 'notranslate')}>
                        {kid.name || `${kid.firstName} ${kid.lastName}`}
                      </div>
                    </div>
                  </td>
                  <td className={styles.itemInfo}>
                    {kid.birthday && (
                      <div className={cn(styles.itemName, 'notranslate')}>{kidBirthday}</div>
                    )}
                  </td>
                  <td className={styles.itemInfo}>
                    <div className={cn(styles.itemName, 'notranslate')}>
                      {`${kid.parent1FirstName} ${kid.parent1LastName}`}
                      {kid.parent2FirstName && (
                        <>
                          <br />
                          {`${kid.parent2FirstName} ${kid.parent2LastName}`}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
