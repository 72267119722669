import axios from 'library/api/axios';
import { URLS } from 'library/common/commonConstants/commonConstants';
import { getToken, removeToken } from 'library/utilities/token';
import { setAuthentication } from 'library/common/commonActions/authActions';

import store from 'main/store/configureStore';

let refreshTokenRequest = null;

export const refreshToken = config => dispatch => {
  const loginFormData = new FormData();
  loginFormData.append('refresh_token', getToken().refresh_token);
  loginFormData.append('grant_type', 'refresh_token');
  loginFormData.append('clientId', store.getState().userReducer.id);

  const encodedString = btoa(`strampleradmin:strampleradmin123`);

  if (!refreshTokenRequest) {
    refreshTokenRequest = axios
      .post(URLS.refreshToken, loginFormData, {
        headers: {
          Authorization: `Basic ${encodedString}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        refreshTokenRequest = null;
        return res;
      });
  }

  return refreshTokenRequest
    .then(res => {
      switch (res.status) {
        case 200:
          dispatch(
            setAuthentication({
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
              tkval: res.data.tkval,
            }),
          );

          if (window && window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'login',
                authorization: res.data.access_token
              })
            )
          }

          if (config.data && JSON.parse(config.data).noRepeatAfterError) {
            return;
          }

          return axios.request({
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${res.data.access_token}`,
            },
          });
        default:
          return false;
      }
    })
    .catch(() => {
      if (window.rnOnLogout) {
        window.rnOnLogout(getToken().access_token);
      }
      removeToken();
      window.location = '/login';
    });
};
