import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Select from 'library/common/commonComponents/Inputs/Select';
import store from 'main/store/configureStore';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Dictaphone from 'library/common/commonComponents/Dictaphone';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { sanitize } from 'dompurify';
import { createPDFObservationSheet } from 'library/utilities/files';
import { useTranslation } from 'react-i18next';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { createKidPortfolioGroupIfNeeded } from 'library/api/kids';
import { deletePost } from 'library/api/posts';
import {
  getAllKidsFromSelectedKita,
  postGfzObservationSheet,
  getGfzObservationSheet,
  deleteGfzObservationSheet,
} from 'library/api/observationSheet';
import gfzLogo from 'resources/images/gfz/gfz_logo.png';
import Loader from 'library/common/commonComponents/Loader';

//Beobachtungsbogen
export default function ObservationSheet({ userId }) {

  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [observationSheet, setObservationSheet] = useState({
    time: '',
    observingPerson: '',
    tookImages: false,
    tookFilm: false,
    startingPosition: '',
    courseOfAction: '',
    pointOfView: '',
    kidId: null,
  });
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { history } = useReactRouter();


  const { t } = useTranslation();
  useEffect(() => {
    getAllKidsFromSelectedKita().then(data => {
      const unformattedKidsList = data.data;
      const formattedKidsList = unformattedKidsList.map(obj => {
        return { label: obj.firstName + ' ' + obj.lastName, id: obj.kidId, portfolioGroupId: obj.portfolioGroupId };
      });
      setKidsList(formattedKidsList);
    });
  }, []);

  function resetForm() {
    setObservationSheet(
      {
        time: '',
        observingPerson: '',
        tookImages: false,
        tookFilm: false,
        startingPosition: '',
        courseOfAction: '',
        pointOfView: '',
        kidId: null,
      });
    setSelectedKid([]);
    setImages([]);
    setVideos([]);
  }

  function handleTextStateChange(newValue, property) {
    setObservationSheet(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function setStartingPostitionText(text) {
    setObservationSheet(prev => {
      return { ...prev, startingPosition: `${prev.startingPosition} ${text}` };
    });
  }

  function setCourseOfActionText(text) {
    setObservationSheet(prev => {
      return { ...prev, courseOfAction: `${prev.courseOfAction} ${text}` };
    });
  }

  function setPointOfViewText(text) {
    setObservationSheet(prev => {
      return { ...prev, pointOfView: `${prev.pointOfView} ${text}` };
    });
  }

  return (
    !isLoading ?
      <>
        <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} />
        <br />
        <br />
        <h2>
          {t('GFZEducationalWork.ObservationSheetHeader')}
        </h2>
        <br />
        <table>
          <tr>
            <td>
              {t('GFZEducationalWork.ObservationSheetKidName')}
            </td>
            <td>
              <Select
                options={kidsList}
                onSelect={e => {
                  setSelectedKid(e);
                  getGfzObservationSheet(e.id).then(res => {
                    if (res.data != null) {
                      setObservationSheet(res.data);

                    } else {
                      setObservationSheet({
                        time: '',
                        observingPerson: '',
                        tookImages: false,
                        tookFilm: false,
                        startingPosition: '',
                        courseOfAction: '',
                        pointOfView: '',
                        kidId: e.id,
                      });
                    }
                  });
                }}
                selected={selectedKid}
                style={{ zIndex: '10000000000' }}
              />
            </td>
          </tr>
          <tr>
            <td>
              {t('GFZEducationalWork.ObservationSheetPeriod')}
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={observationSheet.time}
                onChange={({ target }) =>
                  setObservationSheet(prev => {
                    return {
                      ...prev,
                      time: target.value,
                    };
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              {t('GFZEducationalWork.ObservationSheetObservingPerson')}
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={observationSheet.observingPerson}
                onChange={({ target }) =>
                  setObservationSheet(prev => {
                    return {
                      ...prev,
                      observingPerson: target.value,
                    };
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <CheckBox
                name={t('GFZEducationalWork.ObservationSheetTookImages')}
                isChecked={observationSheet.tookImages}
                onChange={() => {
                  setObservationSheet(prev => {
                    return {
                      ...prev,
                      tookImages: !prev.tookImages,
                    };
                  });
                }}
              /><br />
              <input type="file"
                accept="image/*"
                multiple="multiple"
                onChange={(e) => {
                  setImages(e.target.files);
                }}
              />
              <br />
              <br />

              {(observationSheet.gfzObservationSheetFile && observationSheet.gfzObservationSheetFile.length > 0 && selectedKid.portfolioGroupId) && (
                <div>
                  {observationSheet.gfzObservationSheetFile.filter(file => file.file.mimeType.startsWith("image")).map(data => {
                    return (<><span
                      onClick={() => {
                        history.push(`/groups/${selectedKid.portfolioGroupId}/stream?${data.file.postId}`);
                      }}
                    >{data.file.fileId} <i className='fa fa-file-image-o' /></span>&nbsp;&nbsp;
                      <span onClick={() => {
                        setPostToDelete((data.file.postId));
                        setDeletePopupOpened(true);
                      }}
                      ><i className='fa fa-trash' /></span><br /><br /></>)
                  })}
                </div>
              )}
            </td>
          </tr>
          <td colSpan={2}>
            <CheckBox
              name={t('GFZEducationalWork.ObservationSheetTookFilm')}
              isChecked={observationSheet.tookFilm}
              onChange={() => {
                setObservationSheet(prev => {
                  return {
                    ...prev,
                    tookFilm: !prev.tookFilm,
                  };
                });
              }}
            /><br />
            <input type="file"
              accept="video/*"
              multiple="multiple"
              onChange={(e) => {
                setVideos(e.target.files);
              }}
            />
            <br />
            <br />

            {(observationSheet.gfzObservationSheetFile && observationSheet.gfzObservationSheetFile.length > 0 && selectedKid.portfolioGroupId) && (
              <div>
                {observationSheet.gfzObservationSheetFile.filter(file => file.file.mimeType.startsWith("video")).map(data => {
                  return (<><span
                    onClick={() => {
                      history.push(`/groups/${selectedKid.portfolioGroupId}/stream?${data.file.postId}`);
                    }}
                  >{data.file.fileId} <i className='fa fa-file-image-o' /></span>&nbsp;&nbsp;
                    <span onClick={() => {
                      setPostToDelete((data.file.postId));
                      setDeletePopupOpened(true);
                    }}
                    ><i className='fa fa-trash' /></span><br /><br /></>)
                })}
              </div>
            )}
          </td>
        </table>
        <br />
        {t('GFZEducationalWork.ObservationSheetObservingTime')} <br />
        <br />
        {t('GFZEducationalWork.ObservationSheetObserving')}
        <br />
        <br />
        <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
          <tr>
            <td>
              {t('GFZEducationalWork.ObservationSheetInitialDescriptionTitle1')}
              <br />
              {t('GFZEducationalWork.ObservationSheetInitialDescriptionTitle2')}
              <div style={{ position: 'relative', left: '5px', width: '55px' }}>
                <Dictaphone insText={setStartingPostitionText} />
              </div>
              <br />
              <br />
              <TextArea
                value={observationSheet.startingPosition}
                onChange={e => handleTextStateChange(e.target.value, 'startingPosition')}
                type='text'
                placeholder={t('GFZEducationalWork.ObservationSheetTextAreaPlaceholder')}
              />
            </td>
          </tr>
          <tr>
            <td>
              {t('GFZEducationalWork.ObservationSheetCourseOfActionDescriptionTitle1')}
              <br />
              {t('GFZEducationalWork.ObservationSheetCourseOfActionDescriptionTitle2')}
              <div style={{ position: 'relative', left: '5px', width: '55px' }}>
                <Dictaphone insText={setCourseOfActionText} />
              </div>
              <br />
              <br />
              <TextArea
                value={observationSheet.courseOfAction}
                onChange={e => handleTextStateChange(e.target.value, 'courseOfAction')}
                type='text'
                placeholder={t('GFZEducationalWork.ObservationSheetTextAreaPlaceholder')}
              />
            </td>
          </tr>
          <tr>
            <td>
              {t('GFZEducationalWork.ObservationSheetChildPerspectiveTitle1')}
              <br />
              {t('GFZEducationalWork.ObservationSheetChildPerspectiveTitle2')}{' '}
              <div style={{ position: 'relative', left: '5px', width: '55px' }}>
                <Dictaphone insText={setPointOfViewText} />
              </div>
              <br />
              <br />
              <TextArea
                value={observationSheet.pointOfView}
                onChange={e => handleTextStateChange(e.target.value, 'pointOfView')}
                type='text'
                placeholder={t('GFZEducationalWork.ObservationSheetTextAreaPlaceholder')}
              />
            </td>
          </tr>
        </table>
        <br />
        <Button
          onClick={() => {
            setIsLoading(true);
            createKidPortfolioGroupIfNeeded(selectedKid.id).then(data => {
              const formData = new FormData();
              delete observationSheet.gfzObservationSheetFile;
              Object.keys(observationSheet).forEach(key => formData.append(key, observationSheet[key]));
              formData.append('portfolioGroupId', selectedKid.portfolioGroupId ? selectedKid.portfolioGroupId : null);
              images.forEach(image => {
                formData.append('files', image);
              });
              videos.forEach(video => {
                formData.append('files', video);
              });
              postGfzObservationSheet(formData).then(() => {

                getGfzObservationSheet(selectedKid.id).then(res => {
                  if (res.data != null) {
                    setObservationSheet(res.data);

                  } else {
                    setObservationSheet({
                      time: '',
                      observingPerson: '',
                      tookImages: false,
                      tookFilm: false,
                      startingPosition: '',
                      courseOfAction: '',
                      pointOfView: '',
                      kidId: selectedKid.id,
                    });
                  }
                });

                store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
              }).finally(() => {
                setIsLoading(false);
              });
            });
          }}
          type='primary'
        >
          {t('GFZEducationalWork.ObservationSheetSave')}
        </Button>
        <Button
          onClick={() => {
            const pdfSaveData = {
              observationSheet: observationSheet,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
            }
            createKidPortfolioGroupIfNeeded(selectedKid.id).then(data => {
              const formData = new FormData();
              Object.keys(observationSheet).forEach(key => formData.append(key, observationSheet[key]));
              formData.append('portfolioGroupId', selectedKid.portfolioGroupId ? selectedKid.portfolioGroupId : null);
              images.forEach(image => {
                formData.append('files', image);
              });
              videos.forEach(video => {
                formData.append('files', video);
              });

              createPDFObservationSheet(pdfSaveData).then(() => {
                if (observationSheet.hasOwnProperty('id') && observationSheet.id !== null) {
                  deleteGfzObservationSheet(observationSheet.id);
                }
                resetForm();
                store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
              });
            });
          }}
        >
          {t('GFZEducationalWork.ObservationSheetInKidPortfolioSave')}
        </Button>
        <br />
        <br />
        <Popup
          size='large'
          isOpened={deletePopupOpened}
          closePopup={() => { setPostToDelete(null); setDeletePopupOpened(false); }}
          header={<strong>{t('GFZEducationalWork.ObservationSheetDeleteConfirmHeader')}</strong>}
          body={<>{t('GFZEducationalWork.ObservationSheetDeleteConfirmContent')}</>}
        >
          <center>
            <Button onClick={() => {
              if (postToDelete != null) {
                setIsLoading(true);
                deletePost(postToDelete, userId).then(data => {
                  setIsLoading(false);
                  const filteredObservationSheetFile = observationSheet.gfzObservationSheetFile.filter(data => data.file.postId !== postToDelete);
                  setObservationSheet({ ...observationSheet, gfzObservationSheetFile: filteredObservationSheetFile });
                });
                setDeletePopupOpened(false);
              }
            }}>
              {t('GFZEducationalWork.ObservationSheetConfirm')}
            </Button>
          </center>
        </Popup>
        <div style={{ fontSize: '7px' }}>
          {t('GFZEducationalWork.ObservationSheetReference')}
        </div>
      </> : <Loader />
  );
}
