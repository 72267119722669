import React, { useState, useContext } from 'react';

import Feed from 'library/common/commonComponents/Feed/FeedContainer';
import { GroupContext } from 'modules/Group/groupModule';

import styles from './signature.module.scss';

export default function Signature({ groupInfo, isUserAMember }) {
  const { groupId, postCount } = groupInfo;
  const groupContext = useContext(GroupContext);
  const patchGroupInfo = groupContext.patchGroup;

  const [newPost] = useState(null);

  return (
    <div className={styles.container}>
      <Feed
        groupId={groupId}
        onPostDelete={() => patchGroupInfo({ postCount: postCount - 1 })}
        createCommentStatus={groupInfo.createCommentStatus}
        isPrivatePostsOnly={false}
        isPublicPostsOnly={false}
        newPost={newPost}
        group={groupInfo}
        postType={11}
      />
    </div>
  );
}
