import React, { useState } from 'react';

import TaskHeader from './TaskFrames/TaskHeader';
import TaskHeaderDetail from './TaskFrames/TaskHeaderDetail';
import TaskBody from './TaskFrames/TaskBody';
import TaskComment from './TaskFrames/TaskComment';

import styles from './task.module.scss';

export default function Task({
  isOpenedByDefault = false,
  id,
  onUpdateTaskChecklist,
  onUpdateTaskStatus,
  onEdit,
  onDelete,
  isEditing,
  taskStatus,
  title,
  description,
  isScheduling,
  end,
  assignedUsersList,
  responsibleUsersList,
  reviewRequired,
  checklist,
  files,
  comments,
  likes,
  user,
  groupInfo,
  userId,
  proceedUnassignedTask,
  allDay,
  isTaskDaetailView,
  taskList,
  start,
  permanent,
  isTranslationAllowed,
  manageTasks,
  maxAssignedUserCount,
  isLikeFeatureAvailableInGroup,
  likeFeatureEnabled = true
}) {
  const [isOpened, setIsOpened] = useState(isOpenedByDefault);
  const [newComments, setNewComments] = useState(comments);
  // used to force component to rerender
  const [render, setRender] = useState(false);

  const reRender = () => {
    setRender(!render);
  };

  return (
    <div className={styles.container}>
      {isTaskDaetailView ? (
        <TaskHeaderDetail
          isTranslationAllowed={isTranslationAllowed}
          permanent={permanent}
          manageTasks={manageTasks}
          id={id}
          isOpened={isOpened}
          onOpen={() => setIsOpened(!isOpened)}
          onUpdateTaskStatus={onUpdateTaskStatus}
          onEdit={onEdit}
          onDelete={onDelete}
          isEditing={isEditing}
          taskStatus={taskStatus}
          title={title}
          end={end}
          isScheduling={isScheduling}
          assignedUsersList={assignedUsersList}
          responsibleUsersList={responsibleUsersList}
          reviewRequired={reviewRequired}
          comments={newComments}
          user={user}
          creatorId={userId}
          proceedUnassignedTask={proceedUnassignedTask}
          isAdmin={groupInfo.groupAdminStatus}
          start={start}
          allDay={allDay}
          taskList={taskList}
        />
      ) : (
        <TaskHeader
          isTranslationAllowed={isTranslationAllowed}
          permanent={permanent}
          isOpened={isOpened}
          onOpen={() => setIsOpened(!isOpened)}
          onUpdateTaskStatus={onUpdateTaskStatus}
          onEdit={onEdit}
          onDelete={onDelete}
          isEditing={isEditing}
          id={id}
          taskStatus={taskStatus}
          title={title}
          end={end}
          isScheduling={isScheduling}
          assignedUsersList={assignedUsersList}
          responsibleUsersList={responsibleUsersList}
          reviewRequired={reviewRequired}
          comments={newComments}
          user={user}
          manageTasks={manageTasks}
          creatorId={userId}
          proceedUnassignedTask={proceedUnassignedTask}
          isAdmin={groupInfo.groupAdminStatus}
          editDeleteTask={groupInfo.editDeleteTask}
          maxAssignedUserCount={maxAssignedUserCount}
        />
      )}
      {isOpened && (
        <div className={styles.openedContainer}>
          <TaskBody
            user={user}
            assignedUsersList={assignedUsersList}
            responsibleUsersList={responsibleUsersList}
            isScheduling={isScheduling}
            end={end}
            reviewRequired={reviewRequired}
            taskStatus={taskStatus}
            proceedUnassignedTask={proceedUnassignedTask}
            description={description}
            checklist={checklist}
            files={files}
            onUpdateTaskChecklist={onUpdateTaskChecklist}
            onUpdateTaskStatus={onUpdateTaskStatus}
            allDay={allDay}
            isTranslationAllowed={isTranslationAllowed}
          />
          <TaskComment
            likes={likes}
            comments={comments}
            setNewComments={setNewComments}
            assignedUsersList={assignedUsersList}
            isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
            likeFeatureEnabled={likeFeatureEnabled}
            id={id}
            user={user}
            group={groupInfo}
            reRender={reRender}
          />
        </div>
      )}
    </div>
  );
}
