import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';
import { postEntity } from 'library/common/commonConstants/graphql';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api/survey/`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api/survey/`;

export const createSurvey = survey => {
  const payload = {
    ...survey,
    graphql: postEntity,
  };
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      payload[key].forEach(item => {
        formData.append(`files`, item);
      });
    } else if (key === 'options') {
      payload[key].forEach(option => {
        formData.append(`options`, option);
      });
    } else if (key === 'reminders') {
      if (payload[key].length === 0) {
        formData.delete('reminders[]');
      } else if (payload[key].length === 1) {
        formData.append('reminders[]', JSON.stringify(payload[key][0]));
        formData.append(
          'reminders[]',
          JSON.stringify({
            id: -1,
            type: 'virtual',
            value: Math.ceil(payload.endTimeDate / (60 * 60 * 1000)),
          }),
        );
      } else {
        for (const index in payload[key]) {
          formData.append('reminders[]', JSON.stringify(payload[key][index]));
        }
      }
    } else {
      formData.append(key, payload[key]);
    }
  });

  return axios.post(zuulURL, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateSurvey = (id, payload) =>
  axios.put(URL + id, { ...payload, graphql: postEntity });

export const voteForSurvey = (id, voteIds) =>
  axios.post(`${URL}${id}/option/vote`, {
    voteIds,
    graphql: postEntity,
  });

export const unvoteForSurvey = id =>
  axios.delete(`${URL}${id}/option/vote`, {
    data: {
      graphql: postEntity,
    },
  });

export const addEditSurveySuggestion = (surveyId, suggestionId, optionName) =>
  axios.post(`${URL}${surveyId}/option`, [
    {
      id: suggestionId,
      optionName,
      graphql: postEntity,
    },
  ]);

export const deleteSurveySuggestion = (surveyId, suggestionId) =>
  axios.delete(`${URL}${surveyId}/option/${suggestionId}`, {
    graphql: postEntity,
  });
