import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import InviteUsersToKitaPopup from 'library/common/commonComponents/Popups/InviteUsersToKitaPopup';
import { downloadExcel } from 'library/api/user';
import Button from 'library/common/commonComponents/Buttons/Button';
import HelpText from 'library/common/commonComponents/HelpText';
import TextCaret from 'library/common/commonComponents/TextCaret';
import { invitationService } from 'modules/Administration/administrationActions';

import UsersFrameRegistrationTable from './usersFrameRegistrationFrames/UsersFrameRegistrationTable';
import styles from './usersFrameRegistration.module.scss';
import useUsersRegistrationList from './../UsersFrameRegistration/hooks/useUsersRegistrationList';

export default function UsersFrameRegistration({
  activeKita,
  history: routerHistory,
  location: routerLocation,
  langCode
}) {
  useEffect(changeRoute({ routerLocation, routerHistory }), [routerLocation.pathname]);

  const [isInvitaionModal, setIsInvitationModal] = useState(false);
  const [isExportDropdownOpened, setIsExportDropdownOpened] = useState(false);

  const { ...tableProps } = useUsersRegistrationList({ isPending: true, activeKita });

  const downloadTable = type =>
    downloadTableHandler({
      type,
      kitaId: activeKita.kitaId,
      emailValue : tableProps.emailValue,
      invitedByValue: tableProps.invitedByValue,
      languageValue: tableProps.languageValue,
      createdAtValue: tableProps.createdAtValue,
      status: tableProps.status,
      isPending: tableProps.isPending,
      sort: tableProps.sort,
    });

  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {isInvitaionModal && (
        <InviteUsersToKitaPopup
          isOpened
          closePopup={() => setIsInvitationModal(false)}
          invitationService={invitationService}
        />
      )}
      <div className={styles.title}>
        {t('Administration.UserSection.Pending user registration')}
      </div>
      <div className={styles.text}>
        <HelpText>
          {t(
            // eslint-disable-next-line
            'Administration.UserSection.The following list contains all pending sign-ups and invites',
          )}
        </HelpText>
      </div>
      <div className={styles.buttons}>
        <Button
          type='light'
          size='sm'
          onClick={setIsExportDropdownOpened}
          className={styles.button}
          isActive={isExportDropdownOpened}
        >
          <i className='fa fa-download' /> {t('Administration.Kita.Export')}{' '}
          <TextCaret color='#fff' />
        </Button>
        {isExportDropdownOpened && (
          <div onClick={() => setIsExportDropdownOpened(false)} className={styles.buttonsDropdown}>
            <Button size='sm' onClick={() => downloadTable('excel')}>
              XLSX
            </Button>
            <Button size='sm' onClick={() => downloadTable('csv')}>
              CSV
            </Button>
          </div>
        )}
      </div>

      <UsersFrameRegistrationTable
        kitaId={activeKita.kitaId}
        {...tableProps}
        sort={tableProps.sort}
        emailValue={tableProps.emailValue}
        invitedByValue={tableProps.invitedByValue}
        languageValue={tableProps.languageValue}
        createdAtValue={tableProps.createdAtValue}
        status={tableProps.status}
        isPending={tableProps.isPending}
        location={routerLocation}
        langCode={langCode}
      />
    </div>
  );
}

export async function downloadTableHandler({ type, kitaId, ...filters }) {
  const { data } = await downloadExcel({ type, kitaId, ...filters, isPending: true });
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    type === 'excel' ? 'Pending registrations list.xlsx' : 'Pending registrations list.csv',
  );
  document.body.appendChild(link);
  link.click();
}

export function changeRoute({ routerLocation, routerHistory }) {
  return () => {
    const { pathname } = routerLocation;
    const path = 'pending';
    const hasIndex = pathname.slice(pathname.indexOf(path) + path.length);
    if (hasIndex.length === 0) {
      routerHistory.push('/administration/users/pending/1');
    }
  };
}
