import React, { useState, useEffect } from 'react';

import { getArticleConfig, getAwoKitaConnection, setUserKita } from 'library/api/kita';
import { getKitaModules } from 'library/api/modules';
import { goToKita, setKitaModules } from 'library/common/commonActions/kitaActions';
import loadUserInfo from 'library/common/commonActions/userActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import { KitaPanelWrapper } from 'library/common/commonComponents/KitaPanelWrapper';
import { Wrapper } from 'library/common/commonComponents/Wrapper';
import { AWOMEMBER_LINK } from 'library/common/commonConstants/kitaConstants';
import { getToken } from 'library/utilities/token';
import store from 'main/store/configureStore';

import styles from './articleMainPage.module.scss';

import useReactRouter from 'use-react-router';

export function ArticleMainPage() {
  const [config, setConfig] = useState({});
  const [awoConnection, setAWOConnection] = useState(null);

  const { location, history, match } = useReactRouter();
  const kitaId = match.params.id;

  useEffect(() => {
    getArticleConfig().then(res => {
      setConfig(res.data);
    });
  }, []);

  useEffect(() => {
    getAwoKitaConnection(kitaId).then((res) => {
      setAWOConnection(res.data);
    });
  }, [kitaId]);

  const goToDashboardPage = link => {
    if (!awoConnection?.connectedKitaId) {
      store.dispatch(
        showBottomNotification('Keine angeschlossene Kita!', {
          isWarning: true,
        }),
      );
    } else {
      localStorage.setItem('selectedKitas', JSON.stringify([awoConnection.connectedKitaId]));
      window.location.href = link;
    }
  };

  const goToArticlePage = link => {
    history.push(`/awo-kitas/${kitaId}/${link}`);
  };

  const goToAboutPage = () => {
    window.location.href = AWOMEMBER_LINK;
  };

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className={styles.mainContainer}>
          <p className={styles.kitaNameHeader}>{awoConnection?.kitaName} - das sind wir!</p>
          <div className={styles.articleContainer}>
            <div className={styles.rowContainer}>
              <Button
                type='primary'
                onClick={() => goToArticlePage('firstArticle')}
                className={styles.buttonStyle}
              >
                <span>Was wir tun und was uns besonders macht</span>
              </Button>
              <Button
                type='primary'
                onClick={() => goToArticlePage('secondArticle')}
                className={styles.buttonStyle}
              >
                Gestern – Heute – Morgen
              </Button>
              <Button
                type='primary'
                onClick={() => goToArticlePage('thirdArticle')}
                className={styles.buttonStyle}
              >
                Unser Vorstand
              </Button>
            </div>
            <div className={styles.rowContainer}>
              <Button
                type='primary'
                onClick={() => goToArticlePage('fourthArticle')}
                className={styles.buttonStyle}
              >
                Unser "Zuhause“, unsere Begegnungsstätte
              </Button>
              <Button
                type='primary'
                onClick={() => goToArticlePage('fifthArticle')}
                className={styles.buttonStyle}
              >
                Noch Fragen?
              </Button>
              <div className={styles.buttonEmpty} />
            </div>
          </div>
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.rowFooterContainer}>
            {awoConnection?.connectedKitaId && awoConnection?.connectionActivated && (
              <>
                <div className={styles.buttonWrap}>
                  <Button
                    onClick={() => goToDashboardPage('/calendar')}
                    className={styles.footerButton}
                  >
                    <i className='fa fa-calendar' />
                  </Button>
                  <span className={styles.footerSpan}>Was, wann, wo?</span>
                </div>
                <div className={styles.buttonWrap}>
                  <Button
                    onClick={() => goToDashboardPage('/dashboard')}
                    className={styles.footerButton}
                  >
                    <i className='fa fa-book' />
                  </Button>
                  <span className={styles.footerSpan}>Aktuelles</span>
                </div>
              </>
            )}
            <div className={styles.buttonWrap}>
              <Button onClick={goToAboutPage} className={styles.footerButton}>
                <i className='fa fa-heart' />
              </Button>
              <span className={styles.footerSpan}>Mitglied werden</span>
            </div>
          </div>
        </div>
      </Wrapper>
    </KitaPanelWrapper>
  );
}
