import React from 'react';
import InputEditView from "../InputEditView";
import {useTranslation} from "react-i18next";

export default function DoubleInputEditView({ component, setComponent }) {

  const { t } = useTranslation();

  return (
    <div>
      <h2>{t('FormsEditor.ComponentEditorInputLeftHeader')}</h2>
      <InputEditView component={component.inputLeft} setComponent={(ff) => {
        setComponent({
          ...component,
          inputLeft: ff,
        });
      }} />
      <h2>{t('FormsEditor.ComponentEditorInputRightHeader')}</h2>
      <InputEditView component={component.inputRight} setComponent={(ff) => {
        setComponent({
          ...component,
          inputRight: ff,
        });
      }} />
    </div>
  );
}
