import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { uploadGroupImage, deleteGroupImage, editGroupImage } from 'library/api/groups';

export default function useGroupImageUploading({
  id,
  showBottomNotification,
  type,
  groupInfo,
  patchGroupInfo,
  setGroupDataInStore,
}) {
  const [progress, setProgress] = useState(null);
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);

  const { t } = useTranslation();

  const changeImage = async ([image]) => {
    await changeImageHandler({
      image,
      id,
      setProgress,
      showBottomNotification,
      type,
      groupInfo,
      patchGroupInfo,
      setGroupDataInStore,
      t,
    });
  };

  const deleteImage = () => setIsDeletePopupOpened(true);
  const closeDeletePopup = () => setIsDeletePopupOpened(false);
  const onDeleteBtnClick = async () => {
    await deleteImageHandler({ id, type, patchGroupInfo, groupInfo, setGroupDataInStore });
    setIsDeletePopupOpened(false);
  };
  const deleteHeaderText = <Trans i18nKey='GroupImage.Deletion.Title' />;
  const deleteBodyText = getDeleteBodyText(type, t);

  const editImage = () => setIsEditPopupOpened(true);
  const closeEditPopup = () => setIsEditPopupOpened(false);
  const onEditBtnClick = async payload => {
    await editImageHandler({ id, type, payload, patchGroupInfo, setGroupDataInStore });
    setIsEditPopupOpened(false);
    window.location.reload();
  };
  const editHeaderText = getEditHeaderText(type, Trans);

  return {
    changeImage,
    deleteImage,
    progress,
    isDeletePopupOpened,
    closeDeletePopup,
    onDeleteBtnClick,
    deleteHeaderText,
    deleteBodyText,
    isEditPopupOpened,
    editImage,
    closeEditPopup,
    onEditBtnClick,
    editHeaderText,
  };
}

export async function changeImageHandler({
  image,
  id,
  setProgress,
  type,
  groupInfo,
  patchGroupInfo,
  setGroupDataInStore,
}) {
  const imageAspect = type === 'logo' ? 1 : 5.9;
  const imageWidth = 50;
  const { data } = await uploadGroupImage({
    id,
    image,
    imageAspect,
    imageWidth,
    type,
    setProgress,
  });

  const groupPayload = () => {
    if (type === 'logo') {
      return {
        groupLogoAspect: imageAspect,
        logoUrl: data.fileURL,
        groupLogoWidth: 50,
        groupLogoXCoordinate: 25,
        groupLogoYCoordinate: 25,
        groupId: id,
      };
    }
    return {
      groupHeaderAspect: imageAspect,
      headerUrl: data.fileURL,
      groupHeaderWidth: 50,
      groupHeaderXCoordinate: 25,
      groupHeaderYCoordinate: 25,
      groupId: id,
    };
  };
  setProgress(null);
  setGroupDataInStore({ ...groupInfo, ...groupPayload });
  patchGroupInfo(groupPayload);
  window.location.reload();
}

export const deleteImageHandler = async ({
  id,
  type,
  patchGroupInfo,
  groupInfo,
  setGroupDataInStore,
}) => {
  await deleteGroupImage(id, type);
  const payload = type === 'logo' ? { logoUrl: null } : { headerUrl: null };
  patchGroupInfo(payload);
  setGroupDataInStore({ ...groupInfo, ...payload });
  window.location.reload();
};

export const getDeleteBodyText = (type, t) =>
  type === 'logo' ? t('GroupImage.Deletion.Logo.Text') : t('GroupImage.Deletion.Header.Text');

export const editImageHandler = async ({
  id,
  payload,
  type,
  patchGroupInfo,
  setGroupDataInStore,
}) => {
  await editGroupImage({ ...payload, id });

  const groupPayload =
    type === 'logo'
      ? {
          groupLogoAspect: payload.imageAspect,
          groupLogoWidth: payload.imageWidth,
          groupLogoXCoordinate: payload.xCoordinate,
          groupLogoYCoordinate: payload.yCoordinate,
          groupId: id,
        }
      : {
          groupHeaderAspect: payload.imageAspect,
          groupHeaderWidth: payload.imageWidth,
          groupHeaderXCoordinate: payload.xCoordinate,
          groupHeaderYCoordinate: payload.yCoordinate,
          groupId: id,
        };
  setGroupDataInStore(groupPayload);
  patchGroupInfo(groupPayload);
};

export const getEditHeaderText = (type, TransComponent) => (
  <TransComponent
    i18nKey={type === 'logo' ? 'GroupImage.Editing.LogoText' : 'GroupImage.Editing.HeaderText'}
  />
);
