import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';

import styles from './newCustomQuestion.module.scss';

export default function NewCustomQuestion({ newQuestion, setNewQuestion }) {
  const { t } = useTranslation();

  const handleOptionChange = (optionId, newOptionValue) => {
    const tempQuestion = {...newQuestion};
    tempQuestion.options = tempQuestion.options.map(option => option.id === optionId
      ? { ...option, answer: newOptionValue }
      : option);
    setNewQuestion(tempQuestion);
  };

  const handleOptionIconClick = (index, optionId, length) => {
    if (index === length - 1) {
      const tempQuestion = {...newQuestion};
      tempQuestion.options.push({
        id: Math.random(),
        orderPosition: length,
        answer: '',
      });
      setNewQuestion(tempQuestion);
    } else {
      setNewQuestion({
        ...newQuestion,
        options: newQuestion.options.filter(option => option.id !== optionId),
      });
    }
  };

  return (
    <>
      <div className={styles.input}>
        <Input
          placeholder={t('Surveys.Ask something')}
          value={newQuestion?.question}
          className={styles.input}
          onChange={e =>
            setNewQuestion({
              ...newQuestion,
              question: e.target.value,
            })
          }
          maxLength={255}
        />
      </div>
      {newQuestion?.options?.map((option, index) => (
        <Input
          key={option.id}
          value={option.answer}
          placeholder={t('Surveys.Add answer')}
          className={styles.input}
          onChange={e => handleOptionChange(option.id, e.target.value)}
          maxLength={255}
          faIcon={getFaIcon(index, newQuestion?.options.length)}
          onFaIconClick={() => handleOptionIconClick(index, option.id, newQuestion?.options.length)}
        />
      ))}
      <div className={styles.checkboxes}>
        <Checkbox
          name={t('Surveys.Multiple answers per user')}
          isChecked={newQuestion?.multipleAnswerStatus}
          onChange={() =>
            setNewQuestion({
              ...newQuestion,
              multipleAnswerStatus: !newQuestion?.multipleAnswerStatus,
            })
          }
        />
        <Checkbox
          name={t('Surveys.Allow user to add suggestions')}
          isChecked={newQuestion?.allowUserAddSuggestionStatus}
          onChange={() =>
            setNewQuestion({
              ...newQuestion,
              allowUserAddSuggestionStatus: !newQuestion?.allowUserAddSuggestionStatus,
            })
          }
        />
      </div>
      <br />
    </>
  );
}

export function getFaIcon(index, length) {
  return index === length - 1 ? 'fa-plus' : 'fa-trash';
}
