import React from 'react';
import cn from 'classnames';

import { isAwoWW } from 'library/api/tenantConfig';
import store from 'main/store/configureStore';

import styles from './button.module.scss';

export default function Button({
  children,
  isFullWidth,
  isActive,
  className,
  onClick,
  type,
  size,
  disabled,
  alignText,
}) {
  const activeKita = store.getState().kitaReducer.activeKita;

  return (
    <div
      className={cn(
        className,
        styles.button,
        { [styles.fullWidth]: isFullWidth },
        { [styles.active]: isActive },
        isAwoWW() && type && getClassByType(type),
        isAwoWW() && !type && !activeKita?.kitaColorCode && getClassByType('awoww'),
        !isAwoWW() && getClassByType(type),
        getClassBySize(size),
        getClassByDisable(disabled),
        getClassByAlignText(alignText),
      )}
      {...isAwoWW() && !type && activeKita?.kitaColorCode && {
        style: {
          background: activeKita?.kitaColorCode,
          color: '#ffffff',
        },
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

function getClassByType(type) {
  switch (type) {
    case 'info':
      return styles.buttonInfo;
    case 'primary':
      return styles.buttonPrimary;
    case 'danger':
      return styles.buttonDanger;
    case 'warning':
      return styles.buttonWarning;
    case 'success':
      return styles.buttonSuccess;
    case 'light':
      return styles.buttonLight;
    case 'awoww':
      return styles.buttonAwoww;
    default:
      return styles.buttonDefault;
  }
}

function getClassBySize(size) {
  switch (size) {
    case 'sm':
      return styles.buttonSmall;
    default:
      return styles.buttonMedium;
  }
}

function getClassByDisable(disabled) {
  if (disabled) {
    return styles.buttonDisabled;
  }
}

function getClassByAlignText(alignText) {
  switch (alignText) {
    case 'left':
      return styles.buttonTextLeft;
    case 'right':
      return styles.buttonTextRight;
    default:
      return styles.buttonTextCenter;
  }
}
