import React, { useReducer, useState } from 'react';
import { updateCheckInOutTime } from 'library/api/employee';
import styles from './editEmployeeCheckInOutTime.module.scss';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import Label from 'library/common/commonComponents/Label';
import Popup from 'library/common/commonComponents/Popups/Popup';
import cn from 'classnames';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { useTranslation } from 'react-i18next';

export default function EditEmployeeCheckInOutTime({ checkInOut, handleGetUnapprovedCheckInOuts }) {
  const [isEditMode, toggleEditMode] = useReducer(prev => !prev, false);
  const [checkInOutTime, setCheckInOutTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const isRejected = checkInOut.reviewStatus === 'REJECTED';

  const handleConfirmEdit = async () => {
    const [hours, minutes] = checkInOutTime.split(':').map(Number);
    const newActionDate = new Date(checkInOut.actionDate);
    newActionDate.setHours(hours, minutes, 0, 0);
    try {
      await updateCheckInOutTime({
        userId: checkInOut.userId,
        checkInOutType: checkInOut.checkInOutType,
        checkInOutId: checkInOut.id,
        checkedDate: newActionDate,
      });

      await handleGetUnapprovedCheckInOuts();
      toggleEditMode();
      setErrorMessage('');
      store.dispatch(
        showBottomNotification(t('EmployeeCheckinout.Working time updated'), 'success'),
      );
    } catch ({ response }) {
      const errorMessage = response?.data?.message || 'An unknown error occurred';

      setErrorMessage(errorMessage);
    }
  };

  const handleEditCheckInOut = () => {
    const actionDate = new Date(checkInOut.actionDate);
    const hours = actionDate.getHours();
    const minutes = actionDate.getMinutes();
    setCheckInOutTime(
      `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`,
    );

    toggleEditMode();
  };

  const formateTime = dateString => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
  };

  <i className='fa fa-sign-out' aria-hidden='true' />;

  // return no edit buttons since reject entries cannot be edited
  if (isRejected) {
    return (
      <span className={styles.checkInOutTime}>
        {checkInOut.checkInOutType === 'CHECKIN' && <strong>{t('Checkinout.IsCheckedIn')}</strong>}
        {checkInOut.checkInOutType === 'CHECKOUT' && (
          <strong>{t('Checkinout.IsCheckedOut')}</strong>
        )}
        {formateTime(checkInOut.actionDate)}
      </span>
    );
  }

  return (
    <>
      <span className={styles.checkInOutTime}>
        {checkInOut.checkInOutType === 'CHECKIN' && <strong>{t('Checkinout.IsCheckedIn')}</strong>}
        {checkInOut.checkInOutType === 'CHECKOUT' && (
          <strong>{t('Checkinout.IsCheckedOut')}</strong>
        )}
        {formateTime(checkInOut.actionDate)}
        {!isEditMode && (
          <Button
            size='sm'
            onClick={e => {
              e.stopPropagation();
              handleEditCheckInOut();
            }}
            className={styles.editCheckInOutButton}
          >
            <i className='fa fa-pencil' aria-label='Edit working time' />
          </Button>
        )}
      </span>

      {isEditMode && (
        <div
          className={cn(
            styles.editCheckInOutControls,
            checkInOut.checkInOutType === 'CHECKIN' && styles.checkInControls,
            checkInOut.checkInOutType === 'CHECKOUT' && styles.checkOutControls,
          )}
        >
          <Label htmlFor='time' type='input'>
            checkInControls
            {checkInOut.checkInOutType === 'CHECKIN' && t('EmployeeCheckinout.New checkIn time')}
            {checkInOut.checkInOutType === 'CHECKOUT' && t('EmployeeCheckinout.New checkOut time')}
          </Label>
          <div className={styles.timeInputContainer}>
            <Input
              className={styles.timeInput}
              type='time'
              value={checkInOutTime}
              onChange={e => {
                setCheckInOutTime(e.target.value);
              }}
              id='time'
              min='00:00'
              max='23:59'
              error={errorMessage}
            />
            <div className={styles.editCheckInOutButtonGroup}>
              <Button onClick={() => handleConfirmEdit()} className={styles.approveBtn}>
                <i className='fa fa-check' aria-label='Confirm edit working time' />
              </Button>
              <Button
                onClick={() => {
                  toggleEditMode();
                  setErrorMessage('');
                }}
                className={styles.cancelBtn}
              >
                <i className='fa fa-times' aria-label='Cancel edit working time' />
              </Button>
            </div>
          </div>
          {!!errorMessage && <p className={styles.errorMessageContainer}>{errorMessage}</p>}
        </div>
      )}
    </>
  );
}
