import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { logout } from 'library/api/logout';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { deleteProfileAccount } from 'library/api/user';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';

import styles from './profileDeleteAccountAllowed.module.scss';

export default function ProfileDeleteAccountAllowed() {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const deleteProfile = () =>
    deleteProfileHandler({
      password,
      setPasswordError,
      t,
      isSubmitting,
      setIsSubmitting,
    });

  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.question}>
        {t('AccountSettings.Are you sure that you want to delete your account?')}
      </div>
      <div className={styles.questionWarning}>
        {t('AccountSettings.All your personal data will be irrevocably deleted')}
      </div>
      <div className={cn(styles.formGroup, styles.input)}>
        <Label type='input'>{t('AccountSettings.Your password')} *</Label>
        <Input
          value={password}
          type='password'
          onChange={e => setPassword(e.target.value)}
          placeholder={t('AccountSettings.Enter your password to continue')}
          error={passwordError}
        />
        {passwordError && <div className={styles.error}>{passwordError}</div>}
      </div>

      <ButtonWithLoader
        type='danger'
        onClick={() => setIsDeletePopupVisible(true)}
        isLoading={isSubmitting}
      >
        {t('AccountSettings.Delete account')}
      </ButtonWithLoader>
      {isDeletePopupVisible && (
        <DeletePopup
          isOpened={isDeletePopupVisible}
          closePopup={() => setIsDeletePopupVisible(false)}
          onDeleteBtnClick={deleteProfile}
          headerText={t('AccountSettings.Confirm Delete Account')}
          bodyText={t('AccountSettings.You will be logged out on deleting your account')}
          isSubmiting={isSubmitting}
        />
      )}
    </div>
  );
}

export async function deleteProfileHandler({
  password,
  setPasswordError,
  t,
  isSubmitting,
  setIsSubmitting,
}) {
  if (isSubmitting) {
    return;
  }
  let isError = false;

  if (password.trim().length === 0) {
    isError = true;
    setPasswordError(t('Registration.Password cannot be blank'));
  } else {
    setPasswordError('');
  }

  if (isError) return;

  setIsSubmitting(true);
  try {
    await deleteProfileAccount(password);
    setIsSubmitting(false);
    logout();
  } catch (ex) {
    switch (ex.response.data.message) {
      case 'Passsowrd is Incorrect':
        return setPasswordError(t('AccountSettings.Password is incorrect'));
      case 'Account cant be deleted because user is the last admin of kita':
        return setPasswordError(
          t(
            // eslint-disable-next-line
            'AccountSettings.Account cant be deleted because you are the last admin of the kita',
          ),
        );
      default:
        break;
    }
  } finally {
    setIsSubmitting(false);
  }
}
