import React from 'react';
import * as Sentry from '@sentry/react';

import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Button from 'library/common/commonComponents/Buttons/Button';
import { isDev } from './main/config';

export default function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary
      onError={() => {
        setTimeout(
          () => {
            window.location.reload();
          },
          isDev ? 5000000 : 5000,
        );
      }}
      fallback={() => (
        <React.Fragment>
          <Col>
            <Row>
              <div
                style={{
                  padding: '50px 20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh',
                  width: '100vw',
                }}
              >
                <div
                  style={{
                    maxWidth: '700px',
                    textAlign: 'center',
                    fontSize: '15px',
                  }}
                >
                  <h2 style={{ paddingBottom: '30px' }}>
                    Something went wrong. Please refresh the page or it will be automatically
                    refreshed in 5 seconds.
                  </h2>

                  <Button onClick={() => window.location.reload()} type='primary'>
                    Refresh
                  </Button>
                </div>
              </div>
            </Row>
          </Col>
        </React.Fragment>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
