import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from 'library/common/commonComponents/Popups/Popup';
import FolderTree from 'react-folder-tree';
import 'react-folder-tree/dist/style.css';
import { getFoldersForMovingFiles } from 'library/api/folder';
import Loader from 'library/common/commonComponents/Loader';

import styles from './fileManagerMovePopup.module.scss';

export default function FileManagerMovePopup({
  isOpened,
  closePopup,
  headerText,
  groupId,
  moveFilesToFolder,
}) {
  const { t } = useTranslation();

  const [folderData, setFolderData] = useState([]);
  const [confirmPopupOpened, setConfirmPopupOpened] = useState(false);
  const [nodeClickData, setNodeClickData] = useState(null);

  useEffect(() => {
    getFoldersForMovingFiles({
      groupId,
    }).then(res => {
      const formattedData = rename(res);
      collapseAndSortFolders(formattedData);
      setFolderData({ name: 'Stammverzeichnis', id: 0, isOpen: true, children: formattedData });
    });
  }, []);

  function collapseAndSortFolders(object) {
    const sortedData = object.sort((a, b) => a.name.localeCompare(b.name));
    sortedData.forEach(item => {
      item.isOpen = false;
      if (item.children.length > 0) {
        collapseAndSortFolders(item.children);
      }
    });
  }

  function rename(value) {
    if (!value || typeof value !== 'object') return value;
    if (Array.isArray(value)) return value.map(rename);
    return Object.fromEntries(
      Object.entries(value).map(([k, v]) => [{ subfolders: 'children' }[k] || k, rename(v)]),
    );
  }

  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();
    setNodeClickData(nodeData);
    setConfirmPopupOpened(true);
  };

  return (
    <Popup
      size='extraSmall'
      isOpened={isOpened}
      closePopup={closePopup}
      header={headerText}
      body={
        <div>
          {t('FileManager.Please click on a folders name to transfer the selected files into')}
          <br />
          <br />
          {folderData.length !== 0 ? (
            <FolderTree
              data={folderData}
              onNameClick={onNameClick}
              showCheckbox={false}
              initCheckedStatus='custom'
              initOpenStatus='custom'
              readOnly
            />
          ) : (
            <Loader />
          )}
          {confirmPopupOpened && (
            <Popup
              size='extraSmall'
              isOpened={isOpened}
              closePopup={closePopup}
              header={headerText}
              body={
                <div>
                  {t(
                    'FileManager.Please confirm to move the selected files into the selected folder',
                  )}{' '}
                  {nodeClickData.name}
                </div>
              }
              footer={
                <div className={styles.popupBtns}>
                  <Button
                    onClick={() => {
                      moveFilesToFolder(nodeClickData.id);
                      setConfirmPopupOpened(false);
                      closePopup();
                    }}
                    type='primary'
                  >
                    {t('Calendar.Save')}
                  </Button>
                  <Button onClick={closePopup}>{t('Popup.Cancel')}</Button>
                </div>
              }
            />
          )}
        </div>
      }
      footer={
        <div className={styles.popupBtns}>
          <Button onClick={closePopup}>{t('Popup.Cancel')}</Button>
        </div>
      }
    />
  );
}
