import { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';

import { getKitas, setActiveKita } from 'library/utilities/kitaEffects';
import useSorting from 'library/common/commonHooks/useSorting';
import {
  getKitaUsers,
  getUploadPermissions,
  setUploadPermissions,
  getFalsePermissionsUsers,
  setUploadPermissionsForAll,
  getPermissionsForUser,
} from 'library/api/user';

export default function useUsersList({ isPending, activeKita, setUserPermissions }) {
  const [emailValue, setEmailValue] = useState('');
  const [invitedByValue, setInvitedByValue] = useState('');
  const [languageValue, setLanguageValue] = useState('');
  const [createdAtValue, setCreatedAtValue] = useState('');

  const [users, setUsers] = useState([]);
  const { history, location } = useRouter();
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalUsersCount, setTotalUsersCount] = useState(0);

  const { sort, setSorting } = useSorting('user.lastName');

  const [selectedKita, setSelectedKita] = useState({});
  useEffect(setActiveKita({ activeKita, setSelectedKita }), [activeKita]);
  const [kitas, setKitas] = useState([]);
  useEffect(getKitas({ setKitas }), []);

  const { t } = useTranslation();
  const options = [{ label: t('Administration.UserSection.Activated users'), value: false },
  { label: t('Administration.UserSection.Deactivated users'), value: true }];

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [falsePermissions, setFalsePermissions] = useState([]);

  const loadUsers = () =>
    loadUsersHandler({
      setIsLoading,
      setUsers,
      setTotalUsersCount,
      page,
      sort,
      status: selectedOption.value,
      isPending,
      emailValue,
      invitedByValue,
      languageValue,
      createdAtValue,
      selectedKita,
      setFalsePermissions,
    });

  const [shouldReloadPage, setShouldReloadPage] = useState(false);
  useEffect(() => {
    if (!editingProfile && shouldReloadPage) {
      loadUsers();
      setShouldReloadPage(false);
    }
    // eslint-disable-next-line
  }, [shouldReloadPage]);

  const reloadPage = () => setShouldReloadPage(true);

  const [deletionProfile, setDeletionProfile] = useState(null);
  const [editingProfile, setEditingProfile] = useState(null);
  useEffect(() => {
    if (!editingProfile) {
      loadUsers();
    }
    // eslint-disable-next-line
  }, [page, sort, selectedKita.value, editingProfile]);

  useEffect(() => {
    if (editingProfile) setDeletionProfile(null);
  }, [editingProfile]);

  useEffect(() => {
    if (editingProfile === null && deletionProfile === null) loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingProfile, deletionProfile]);

  useEffect(resetPageHandler({ setPage, loadUsers }), [
    emailValue,
    invitedByValue,
    languageValue,
    createdAtValue,
    selectedOption,
  ]);

  const getPermissions = async () => {
    const response = await getPermissionsForUser();

    if (response && response.data && setUserPermissions) {
      setUserPermissions(response.data);
    }
  };

  const togglePermission = async (userId, permissionType) => {
    const foundUser = users.find(({ user }) => user.id === userId);

    if (foundUser[permissionType] === false) {
      setFalsePermissions({
        ...falsePermissions,
        [permissionType]: falsePermissions[permissionType].filter(id => id !== userId),
      });
    } else {
      setFalsePermissions({
        ...falsePermissions,
        [permissionType]: falsePermissions[permissionType].concat(userId),
      });
    }

    await setUploadPermissions([
      {
        userId,
        pictures: foundUser.pictures,
        others: foundUser.others,
        videos: foundUser.videos,
        [permissionType]: !foundUser[permissionType],
      },
    ]);
    getPermissions();

    setUsers(
      users.map(user =>
        user.user.id === userId ? { ...user, [permissionType]: !user[permissionType] } : user,
      ),
    );
  };

  const toggleAllPermissions = permissionType => {
    if (falsePermissions[permissionType].length > 0) {
      setUploadPermissionsForAll({
        userId: null,
        [permissionType]: true,
      });
      setFalsePermissions({ ...falsePermissions, [permissionType]: [] });
      setUsers(users.map(user => ({ ...user, [permissionType]: true })));
    } else {
      setUploadPermissionsForAll({
        userId: null,
        [permissionType]: false,
      });
      setFalsePermissions({
        ...falsePermissions,
        [permissionType]: users.map(user => user.user.id),
      });
      setUsers(users.map(user => ({ ...user, [permissionType]: false })));
    }
    getPermissions();
  };

  return {
    users,
    togglePermission,
    loadPage: setPage,
    isLoading,
    history,
    location,
    totalUsersCount,
    setSorting,
    sort,
    options,
    selectedOption,
    setSelectedOption,
    editingProfile,
    setEditingProfile,
    reloadPage,
    emailValue,
    setEmailValue,
    invitedByValue,
    setInvitedByValue,
    languageValue,
    setLanguageValue,
    createdAtValue,
    setCreatedAtValue,
    setSearchValue: setEmailValue,
    searchValue: emailValue,
    deletionProfile,
    setDeletionProfile,
    kitas,
    selectedKita,
    setSelectedKita,
    falsePermissions,
    toggleAllPermissions,
  };
}

export async function loadUsersHandler({
  setIsLoading,
  setUsers,
  setTotalUsersCount,
  page,
  sort,
  emailValue,
  invitedByValue,
  languageValue,
  createdAtValue,
  status,
  isPending,
  selectedKita,
  setFalsePermissions,
}) {
  if (!selectedKita.value) return;
  setIsLoading(true);
  const { data } = await getKitaUsers({
    kitaId: selectedKita.value,
    page,
    sort,
    emailValue,
    invitedByValue,
    languageValue,
    createdAtValue,
    status,
    isPending,
  });
  
  const ids = data.content.map(({ user }) => user.id).toString();
  const { data: permissions } = await getUploadPermissions(ids);

  const { data: falsePermissions } = await getFalsePermissionsUsers();

  setFalsePermissions(falsePermissions);

  const result = [];
  for (const user of data.content) {
    const findedPermission = permissions.find(permission => user.user.id === permission.userId);
    if (findedPermission) {
      result.push({
        ...user,
        pictures: findedPermission.pictures,
        videos: findedPermission.videos,
        others: findedPermission.others,
      });
    } else {
      result.push({
        ...user,
        pictures: true,
        videos: true,
        others: true,
      });
    }
  }

  setUsers(result);
  setTotalUsersCount(data.totalElements);
  setIsLoading(false);
}

export function resetPageHandler({ setPage, loadUsers }) {
  return () => {
    setPage(1);

    loadUsers();
  };
}
