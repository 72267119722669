import './polyfills';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import ErrorBoundary from './sentry';
import configureI18n from './i18n';
import App from './App';

const location = window.location.toString();
if (location.includes('www')) {
  window.location.href = window.location.toString().replace('www.', '');
}

async function render() {
  await configureI18n();

  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    document.getElementById('root'),
  );
}

render();
