import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllUnapprovedPickUpPersonsOfKita } from 'library/api/kids';
import { deleteAuthorizedPickupPerson, approveAuthorizedPickupPerson } from 'library/api/user';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import HelpText from 'library/common/commonComponents/HelpText';
import styles from './userFrameAuthorizedToPickUp.module.scss';

export default function UsersFrameAuthorizedToPickUp() {
  const { t } = useTranslation();
  const [listOfPickUpPersonsToApprove, setListOfPickUpPersonsToApprove] = useState([]);

  useEffect(() => {
    getAllUnapprovedPickUpPersonsOfKita().then(data => {
      setListOfPickUpPersonsToApprove(data.data);
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t('Administration.UserSection.Pending pick up person approval')}
      </div>
      <div className={styles.text}>
        <HelpText>
          {t(
            'Administration.UserSection.The following list contains all pick up person awaiting an approval',
          )}
        </HelpText>
      </div>

      <table className={styles.table}>
        <tr>
          <th>{t('Administration.UserSection.Pick up person name')}</th>
          <th>{t('Administration.UserSection.Kid name')}</th>
          <th>{t('Administration.UserSection.Parent names')}</th>
          <th>{t('Administration.UserSection.Action')}</th>
        </tr>
        {listOfPickUpPersonsToApprove.map(obj => {
          return (
            <tr>
              <td>{obj.pickUpPersonName}</td>
              <td>{obj.kidName}</td>
              <td>
                {obj.parent1Name}
                {obj.parent2Name !== null && `, ${obj.parent2Name}`}
              </td>
              <td>
                <Button
                  type='success'
                  className={styles.acceptBttn}
                  onClick={() => {
                    approveAuthorizedPickupPerson(obj.pickUpPersonId).then(data => {
                      const filteredPickUpPersonList = listOfPickUpPersonsToApprove.filter(
                        pickUpObject => pickUpObject.pickUpPersonId !== obj.pickUpPersonId,
                      );
                      setListOfPickUpPersonsToApprove(filteredPickUpPersonList);
                      store.dispatch(
                        showBottomNotification(t('BottomNotifications.Saved'), { isFail: false }),
                      );
                    });
                  }}
                >
                  {t('Administration.UserSection.Approve')}
                </Button>
                &nbsp;
                <Button
                  type='danger'
                  className={styles.rejectBttn}
                  onClick={() => {
                    deleteAuthorizedPickupPerson(obj.pickUpPersonId).then(data => {
                      const filteredPickUpPersonList = listOfPickUpPersonsToApprove.filter(
                        pickUpObject => pickUpObject.pickUpPersonId !== obj.pickUpPersonId,
                      );
                      setListOfPickUpPersonsToApprove(filteredPickUpPersonList);
                      store.dispatch(
                        showBottomNotification(t('BottomNotifications.Saved'), { isFail: false }),
                      );
                    });
                  }}
                >
                  {t('Administration.UserSection.Delete')}
                </Button>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}
