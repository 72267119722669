import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import useCommentsInput from 'library/common/commonHooks/useCommentsInput';
import Label from 'library/common/commonComponents/Label';
import { updateTaxReceiptInfo } from 'library/api/files';
import { convertDateToLocal } from 'library/utilities/taxConsultant';
import styles from './receiptPopup.module.scss';

export default function ReceiptPopup({
  id,
  user,
  comments,
  setComments,
  isOpened,
  closePopup,
  file,
  isReceiptConfirmed,
  setIsReceiptConfirmed,
  administrationAccess,
}) {
  const { focusOnEditor } = useCommentsInput({
    user,
    id,
    comments,
    setComments,
  });

  const [confirmApiObject, setConfirmApiObject] = useState({
    id: file.taxReceipt.id,
    receiptConfirmed: isReceiptConfirmed,
  });

  const { t } = useTranslation();

  function getConfirmedString() {
    if (isReceiptConfirmed == null) {
      return t('FileManager.Unedited');
    }
    if (isReceiptConfirmed) {
      return t('FileManager.Approved');
    }
    if (!isReceiptConfirmed) {
      return t('FileManager.Declined');
    }
  }


  return (
    <Popup
      isOpened={isOpened}
      onOpen={focusOnEditor}
      closePopup={closePopup}
      header={t('FileManager.Receipt')}
      footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
    >
      <div className={styles.container}>
        <img src={file.path} style={{ maxHeight: '300px' }} />
        <div className={styles.formGroup}>
          <Label type='input'>{t('FileManager.ReceiptType')}</Label>
          <br />
          {file && file.taxReceipt && file.taxReceipt.receiptType && file.taxReceipt.receiptType && file.taxReceipt.receiptType === 'Bewirtungsbeleg' 
          && t('FileManager.Hospitality receipt')}
          {file && file.taxReceipt && file.taxReceipt.receiptType && file.taxReceipt.receiptType && file.taxReceipt.receiptType === 'Andere Belege' 
          && t('FileManager.Other receipts')}
        </div>
        <div className={styles.formGroup}>
          <Label type='input'>{t('FileManager.Date')}</Label>
          <br />
          {file &&
            file.taxReceipt &&
            file.taxReceipt.receiptType &&
            convertDateToLocal(file.taxReceipt.date)}
        </div>
        {file &&
          file.taxReceipt &&
          file.taxReceipt.receiptType &&
          file.taxReceipt.receiptType === 'Bewirtungsbeleg' && (
            <div>
              <div className={styles.formGroup}>
                <Label type='input'>{t('FileManager.Occasion')}</Label>
                <br />
                {file && file.taxReceipt && file.taxReceipt.receiptType && file.taxReceipt.reason}
              </div>
              <div className={styles.formGroup}>
                <Label type='input'>{t('FileManager.People')}</Label>
                <br />
                {file && file.taxReceipt && file.taxReceipt.receiptType && file.taxReceipt.persons}
              </div>
              <div className={styles.formGroup}>
                <Label type='input'>{t('FileManager.State')}</Label>
                <br />
                {file && file.taxReceipt && file.taxReceipt.receiptType && getConfirmedString()}
              </div>
            </div>
          )}
        <br />
        {administrationAccess &&
        <div>
          <Button
            onClick={() => {
              setConfirmApiObject(prevState => {
                prevState.receiptConfirmed = true;
                return prevState;
              });
              setIsReceiptConfirmed(true);
              updateTaxReceiptInfo(confirmApiObject);
            }}
            disabled={!!isReceiptConfirmed}
          >
            {t('FileManager.Approve')}
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              setConfirmApiObject(prevState => {
                prevState.receiptConfirmed = false;
                return prevState;
              });
              setIsReceiptConfirmed(false);
              updateTaxReceiptInfo(confirmApiObject);
            }}
            disabled={isReceiptConfirmed ? false : isReceiptConfirmed != null}
          >
            {t('FileManager.Decline')}
          </Button>
        </div>
        }
      </div>
    </Popup>
  );
}
