export function validateSurvey({ question, surveyOptions, t }) {
  const errors = [];

  if (question.trim().length === 0) {
    errors.push(t('Surveys.Question cannot be blank'));
  }

  const notEmptySurveysLength = surveyOptions.filter(
    ({ optionName }) => optionName.trim().length > 0,
  ).length;

  if (notEmptySurveysLength === 0) {
    errors.push(t('Surveys.Answers cannot be blank'));
  } else if (notEmptySurveysLength === 1) {
    errors.push(t('Surveys.Please specify at least 2 answers!'));
  }

  return errors;
}

export function getCanUserVoteStatus({ surveyOptions, user, surveyOpenStatus }) {
  let result = null;
  try {
    result =
      !surveyOptions.find(({ surveyOptionVotes }) =>
        surveyOptionVotes.find(vote => vote.userId === user.id),
      ) && surveyOpenStatus;
  } catch (ex) {
    console.log(surveyOptions);
  }
  return result;
}

export function changeOrderIfSurvey(post) {
  return post.postType === 4
    ? {
        ...post,
        surveyOptions: post.displayRandomOrderStatus
          ? post.surveyOptions.sort(() => Math.random() - 0.5)
          : post.surveyOptions.sort((a, b) => a?.sequence - b?.sequence),
      }
    : post;
}

export function sortSurveySuggestionsBySequence(displayRandomOrderStatus, surveyOptions) {
  return surveyOptions.sort((a, b) => a?.sequence - b?.sequence);
}
