import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import Label from 'library/common/commonComponents/Label';
import Popup from 'library/common/commonComponents/Popups/Popup';
import styles from './editButtons.module.scss';
import cn from 'classnames';
import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function EditButtons({ value, handleSubmit, employeeName }) {
  const { t } = useTranslation();
  const [isEditMode, toggleEditMode] = useReducer(prev => !prev, false);
  const [newOvertime, setNewOvertime] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');
  const [isConfimPopupOpen, toggleIsConfirmPopupOpen] = useReducer(prev => !prev, false);

  const validateOvertimeInput = () => {
    const regex = /^[0-9:-]*$/;
    if (!regex.test(newOvertime)) {
      setErrorMessage(t('EmployeeCheckinout.Invalid overtime input'));
      return;
    }

    if (Number(newOvertime) === 0) {
      toggleIsConfirmPopupOpen();
      return;
    }

    const isNegative = newOvertime[0] === '-';
    const overtime = isNegative ? newOvertime.substring(1) : newOvertime;
    const [hours, minutes] = overtime.split(':').map(Number);
    if (minutes > 59 || isNaN(hours) || isNaN(minutes)) {
      setErrorMessage(t('EmployeeCheckinout.Invalid overtime input'));
      return;
    }

    toggleIsConfirmPopupOpen();
  };

  const handlePopupSubmit = () => {
    const resetState = () => {
      setErrorMessage('');
      toggleEditMode();
      toggleIsConfirmPopupOpen();
    };

    if (Number(newOvertime) === 0) {
      handleSubmit(0);
      resetState();
      return;
    }

    const isNegative = newOvertime[0] === '-';
    const overtime = isNegative ? newOvertime.substring(1) : newOvertime;
    const [hours, minutes] = overtime.split(':').map(Number);
    let overtimeInMs = (hours * 60 + minutes) * 60 * 1000;
    overtimeInMs *= isNegative ? -1 : 1;

    handleSubmit(overtimeInMs);
    resetState();
  };

  return (
    <div className={styles.EditButtonsContainer}>
      <div className={styles.entryTimestamp}>
        <Popup
          size='small'
          isOpened={isConfimPopupOpen}
          closePopup={toggleIsConfirmPopupOpen}
          header={<strong>{t('EmployeeCheckinout.Warning')}</strong>}
          body={
            <div className={styles.popupBody}>
              <p>
                {t(
                  'EmployeeCheckinout.This change connot be undone. Are you sure you want to send the overtime change?',
                )}
              </p>
              <p className={styles.popupOvertimeCaption}>
                <strong>{`${employeeName} - ${t('EmployeeCheckinout.Old overtime')}`}</strong>
                <br /> {value}
              </p>
              <p className={styles.popupOvertimeCaption}>
                <strong>{`${employeeName} - ${t('EmployeeCheckinout.New overtime')}`}</strong>
                <br /> {newOvertime}
              </p>
            </div>
          }
          footer={
            <div>
              <div className={styles.reviewButtonGroup}>
                <Button onClick={toggleIsConfirmPopupOpen}>{t('EmployeeCheckinout.Cancel')}</Button>
                <Button type='primary' onClick={handlePopupSubmit}>
                  {t('EmployeeCheckinout.Confirm')}
                </Button>
              </div>
            </div>
          }
        />

        {isEditMode && (
          <div className={styles.editCheckInOutControls}>
            <Label htmlFor='overtime' type='input'>
              {t('EmployeeCheckinout.New overtime')}
            </Label>
            <div className={styles.overtimeInputContainer}>
              <Input
                className={styles.overtimeInput}
                type='text'
                value={newOvertime}
                onChange={e => {
                  setNewOvertime(e.target.value);
                }}
                id='overtime'
                error={errorMessage}
              />
              <div className={styles.editCheckInOutButtonGroup}>
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    validateOvertimeInput();
                  }}
                  className={styles.approveBtn}
                >
                  <i className='fa fa-lg fa-check' aria-label='Confirm edit working time' />
                </Button>
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    toggleEditMode();
                  }}
                  className={styles.cancelBtn}
                >
                  <i className='fa fa-lg fa-times' aria-label='Cancel edit working time' />
                </Button>
              </div>
            </div>
            {!!errorMessage && <p className={styles.errorMessageContainer}>{errorMessage}</p>}
          </div>
        )}
      </div>

      {!isEditMode && (
        <Button
          size='sm'
          onClick={e => {
            e.stopPropagation();
            toggleEditMode();
          }}
          className={styles.editCheckInOutButton}
        >
          <i className='fa fa-pencil' aria-label='Edit working time' />
        </Button>
      )}
    </div>
  );
}
