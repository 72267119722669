import axios from 'library/api/axios';

import config, { getPort } from 'main/config';

const baseURL = `${config.API_BASE_URI}${getPort(8080)}`;

export const getKitaFields = kitaId => axios.get(`${baseURL}/api/kidFieldsSettings/${kitaId}`);

export const updateKitaFields = (kitaId, body) =>
  axios.put(`${baseURL}/api/kidFieldsSettings/updateFromJson?kitaId=${kitaId}`, body);

export const updateSicknessesKitaSetting = (kitaId, sicknesses) =>
  axios.put(`${baseURL}/api/kidFieldsSettings/updateSicknesses?kitaId=${kitaId}`, {sicknesses});
