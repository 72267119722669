import React, { useEffect, useReducer, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';

import { getCalendarSettings, hideCalendarEvent } from 'library/api/calendar';
import { getAllKitasWithAllData } from 'library/api/kita';
import { getTask } from 'library/api/tasks';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Card from 'library/common/commonComponents/Card';
import Button from 'library/common/commonComponents/Buttons/Button';
// eslint-disable-next-line max-len
import { isAwoWW } from 'library/api/tenantConfig';
import CreateEditTask from 'library/common/commonComponents/TaskListsMain/TaskListsMainFrames/TaskLists/TaskListsFrames/TaskList/TaskListFrames/CreateEditTask';
import { checkIsProviderRelevant } from 'library/utilities/groups';
import { getCanBePublicStatus } from 'library/utilities/posts';
import Storage from 'library/utilities/storage';

import CalendarChooseTarget from './CalendarFrames/CalendarChooseTarget';
import CalendarCreateEditEvent from './CalendarFrames/CalendarCreateEditEvent';
import CalendarEvent from './CalendarFrames/CalendarEvent';
import CalendarFilters from './CalendarFrames/CalendarFilters';
import CalendarImportExport from './CalendarFrames/CalendarImportExport';
import calendarConfig from './calendarConfig';

import styles from './calendar.module.scss';

import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './fullcalendarCustomButtonsStyle.scss';
import CalendarInspectAbsencePopup from './CalendarFrames/CalendarInspectAbsencePopup';
import CalendarWorkingHoursPopup from './CalendarFrames/CalendarWorkingHoursPopup';

import { germanHolidays } from './germanHolidays';
import { providerReducer } from '../Inputs/CreatePostInput';
import ProviderPostPopup from '../Popups/ProviderPostPopup';

export default function Calendar({
  showBottomNotification,
  events,
  calendarSettings,
  canCreateEvent,
  getEventInfo,
  addEvent,
  updateEvent,
  deleteEvent,
  filterList,
  isAdmin,
  match,
  groupInfo,
  user,
  filters,
  setFilters,
  calendarModulePlace,
  getEditableValue,
  isUserProfileCalendarEnabled,
  onEnableUserProfileCalendar,
  reloadEvents,
  showCalendarImportExport = true,
  initialView,
  eventTimeFormat,
}) {
  const locale = Storage.getItem('locale');
  const { t } = useTranslation();
  const calendarComponentRef = React.createRef();
  const [isChooseTargetOpened, setIsChooseTargetOpened] = useState(false);
  const [isCreateEventOpen, setIsCreateEventOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEventOpen, setIsEventOpen] = useState(false);
  const [eventData, setEventData] = useState({});
  const [taskLists, setTaskLists] = useState([]);
  const [editTaskIsOpen, setEditTaskIsOpen] = useState(false);
  const [isEventInfoLoading, setIisEventInfoLoading] = useState(false);
  const [selectGroupPopupOpen, setSelectGroupPopupOpen] = useState(false);
  const [inspectingAbsence, setInspectingAbsence] = useState(null);
  const [eventToSubmit, setEventToSubmit] = useState(null);

  const activeKita = useSelector(state => state.kitaReducer.activeKita);
  const [providers, dispatchProviders] = useReducer(providerReducer, []);
  const [openPopup, setOpenPopup] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isOpenWorkingHourDialog, setIsOpenWorkingHourDialog] = useState(false);
  const [workingHourEvent, setWorkingHourEvent] = useState(null);

  let isProviderRelevant = checkIsProviderRelevant(activeKita, groupInfo);

  useEffect(() => {
    if (isProviderRelevant) {
      getAllKitasWithAllData().then(res => {
        // eslint-disable-next-line no-unused-vars
        const kitaArray = res.data.content.map(kita => {
          return {
            id: kita.id,
            label: kita.kitaName,
            providerId: kita.providerId,
            selected: false,
            kitaProviderStatus: kita.kitaProviderStatus,
          };
        });
        const providerArray = [];

        // eslint-disable-next-line array-callback-return
        kitaArray.map(kita => {
          if (kita.providerId === activeKita.kitaId && kita.kitaProviderStatus === 'approved') {
            providerArray.push(kita);
          }
        });
        dispatchProviders({ type: 'set', providers: providerArray });
      });
    }
  }, []);

  const handleDateClick = dateClickInfo => {
    if (!canCreateEvent) {
      return;
    }
    setEventData(dateClickInfo);
    if (calendarModulePlace === 'dashboardCalendar') {
      setIsChooseTargetOpened('choose');
    } else {
      setIsCreateEventOpen(true);
    }
  };

  const onEventClick = async eventClickInfo => {
    if (isEventInfoLoading) {
      return;
    }
    setIisEventInfoLoading(true);
    const result = await getEventInfo(eventClickInfo.event);
    if (result.success) {
      setEventData({...result.event, hideDay: eventClickInfo.event.extendedProps.repeatType !== 'none' ? eventClickInfo.event.startStr: undefined});
      setIsEventOpen(true);
    } else {
      console.log(result.error);
    }
    setIisEventInfoLoading(false);
  };

  const onTaskClick = async eventClickInfo => {
    const {
      userId,
      assignedUsersList,
      responsibleUsersList,
      group,
    } = eventClickInfo.event.extendedProps;
    getTask(eventClickInfo.event.id)
      .then(res => {
        if (res.status === 200) {
          setEventData({
            ...res.data,
            postType: eventClickInfo.event.extendedProps.postType,
            editable: getEditableValue(userId, { assignedUsersList, responsibleUsersList }, group),
            group,
          });
          setIsEventOpen(true);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onAbsenceClick = eventClickInfo => {
    setInspectingAbsence(eventClickInfo.event.extendedProps.absence);
  };

  const onAddEvent = async event => {
    if (isProviderRelevant) {
      setEventToSubmit(event);
      setOpenPopup(true);
    } else {
      const result = await addEvent(event);
      if (result.success) {
        setIsCreateEventOpen(false);
        setEventData(result.newEvents[0]);
        setIsEventOpen(true);
        if (reloadEvents) {
          reloadEvents();
        }
      }
    }
  };

  const onSubmit = async () => {
    if (isSending) {
      return;
    }

    setIsSending(true);
    let receivers = [];
    if (providers) {
      receivers = providers.filter(provider => provider.selected).map(provider => provider.id);
    }

    const result = await addEvent({
      ...eventToSubmit,
      receivers,
    });
    if (result.success) {
      setIsCreateEventOpen(false);
      setEventData(result.newEvents[0]);
      setOpenPopup(false);
      setIsEventOpen(true);
      if (reloadEvents) {
        reloadEvents();
      }
    }

    setIsSending(false);
  };

  const handleEventUpdate = async newValues => {
    const result = await updateEvent({ ...eventData, ...newValues });
    if (result.success) {
      if (result.event) {
        setIsCreateEventOpen(false);
        setEditTaskIsOpen(false);
        setIsEditing(false);
        setEventData(result.event);
        setIsEventOpen(true);
      } else {
        setEditTaskIsOpen(false);
        setEventData({});
        setIsEventOpen(false);
        showBottomNotification(t('Tasks.The task has been removed'));
      }
      if (reloadEvents) {
        reloadEvents();
      }
    } else {
      console.log(result.error);
    }
  };

  const handleEventDelete = async deletedEvent => {
    await deleteEvent(deletedEvent);
    setIsEventOpen(false);
    setEventData({});
    showBottomNotification(
      deletedEvent.postType === 2
        ? t('Calendar.The event has been deleted')
        : t('Tasks.The task has been deleted'),
    );
    if (reloadEvents) {
      reloadEvents();
    }
  };

  const handleEdit = data => {
    if (calendarModulePlace !== 'groupCalendar' && eventData.group && eventData.group.groupId) {
      return getCalendarSettings(eventData.group.groupId, 'groupCalendar')
        .then(res => {
          if (res.status === 200) {
            setEventData({ ...eventData, calendarSettings: res.data });
            setIsEventOpen(false);
            if (eventData.postType === 2) {
              setIsEditing(true);
              setIsCreateEventOpen(true);
            } else {
              setTaskLists(data);
              setEditTaskIsOpen(true);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setIsEventOpen(false);
      if (eventData.postType === 2) {
        setIsEditing(true);
        setIsCreateEventOpen(true);
      } else {
        setTaskLists(data);
        setEditTaskIsOpen(true);
      }
    }
  };

  const handleEventChange = async event => {
    if (event.extendedProps.postType === 2) {
      updateEvent({
        end: event.end ? event.end.getTime() : event.start.getTime(),
        id: event.id,
        postType: event.extendedProps.postType,
        start: event.start ? event.start.getTime() : event.end.getTime(),
      });
    } else if (event.extendedProps.isWorkingHour) {
      updateEvent(event);
    } else {
      updateEvent({
        title: event.title,
        description: event.extendedProps.description,
        files: event.extendedProps.files,
        reviewRequired: event.extendedProps.reviewRequired,
        allDay: event.allDay,
        isPublic: event.extendedProps.isPublic,
        timeZone: event.extendedProps.timeZone,
        isScheduling: event.extendedProps.isScheduling,
        addToCalendar: event.extendedProps.addToCalendar,
        checklist: event.extendedProps.checklist,
        responsibleUsersList: event.extendedProps.responsibleUsersList,
        assignedUsersList: event.extendedProps.assignedUsersList,
        taskListId: event.extendedProps.taskListId,
        end: event.end ? event.end.getTime() : event.start.getTime(),
        id: event.id,
        postType: event.extendedProps.postType,
        start: event.start ? event.start.getTime() : event.end.getTime(),
        editable: event.durationEditable,
      });
    }
  };

  const handleCloseChooseTarget = () => {
    setIsChooseTargetOpened(false);
    setEventData({});
  };

  const handleNext = groups => {
    if (!groups || groups.length === 0) {
      setSelectGroupPopupOpen(true);
      return;
    }

    if (groups.find(g => g.groupId === 0) && !isUserProfileCalendarEnabled) {
      setIsChooseTargetOpened('enable');
    } else {
      setEventData({
        ...eventData,
        group: null,
        multiGroups: groups,
        participationInfo: {
          participationEnum: groups.participationMode,
          isAllowDecline: groups.participationStateDeclineAllowed,
          isAllowMaybe: groups.participationStateMaybeAllowed,
          maxNumberOfParticipants: '',
          info: '',
        },
      });
      setIsChooseTargetOpened(false);
      setIsCreateEventOpen(true);
    }
  };

  const handleEnable = async () => {
    await onEnableUserProfileCalendar();
    setIsChooseTargetOpened('choose');
  };

  const handleEditWorkingHour = event => {
    setIsOpenWorkingHourDialog(true);
    setWorkingHourEvent(event);
  };

  const handleHideEvent = (event) => {
    hideCalendarEvent(eventData.id, event).then(()=> {
      if (reloadEvents) {
        reloadEvents();
      }
      setIsEventOpen(false)
    });
  }

  const personalCalendarEvents = events.map(event =>
    event.allDay
      ? {
          ...event,
          end:
            (event.end - event.start) / 1000 < 60 * 60 * 24
              ? event.end
              : addDays(event.end, 1).getTime(),
        }
      : event,
  );

  // add static public holidays when filter is enabled
  const publicHolidaysFilter = filters.find(filter => filter.name === 'publicHolidays');
  let calendarEvents = publicHolidaysFilter?.checked
    ? [...personalCalendarEvents, ...germanHolidays]
    : personalCalendarEvents;

  return (
    <>
      <Popup
        isOpened={selectGroupPopupOpen}
        closePopup={() => setSelectGroupPopupOpen(false)}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button onClick={() => setSelectGroupPopupOpen(false)}>{t('Calendar.Cancel')}</Button>
            </div>
          </div>
        }
      >
        <div className={styles.containerEnable}>{t('Calendar.PleaseSelectAtLeastOneGroup')}</div>
      </Popup>

      <CalendarWorkingHoursPopup
        event={workingHourEvent}
        isOpen={isOpenWorkingHourDialog}
        setOpen={setIsOpenWorkingHourDialog}
        reloadEvents={reloadEvents}
      />

      <CalendarInspectAbsencePopup
        absence={inspectingAbsence}
        setAbsence={setInspectingAbsence}
        reloadEvents={reloadEvents}
        isAdmin={
          user.administrationAccess ||
          user.superAdminStatus ||
          (groupInfo && groupInfo.groupAdminStatus)
        }
      />

      <Card>
        {!isAwoWW() && (
          <div className={styles.calendarHeader}>
            {!!filterList.length && <CalendarFilters filterList={filterList} />}
            {showCalendarImportExport && (
              <CalendarImportExport
                filters={filters}
                reloadEvents={reloadEvents}
                groupInfo={groupInfo}
              />
            )}

            {(isAdmin || isUserProfileCalendarEnabled) && (
              <Link
                to={{
                  pathname:
                    calendarModulePlace === 'groupCalendar'
                      ? 'calendarSettings'
                      : `/profile/${user.id}/calendar/settings`,
                  state: { calendarModulePlace },
                }}
                className={styles.calendarSettingsButton}
              >
                <i className='fa fa-cog' />
              </Link>
            )}
          </div>
        )}
        <div className={styles.calendarContainer}>
          <FullCalendar
            {...calendarConfig}
            initialView={initialView}
            eventTimeFormat={eventTimeFormat}
            ref={calendarComponentRef}
            events={calendarEvents}
            dateClick={dateClickInfo => handleDateClick(dateClickInfo)}
            select={selectionInfo => handleDateClick(selectionInfo)}
            eventClick={eventClickInfo => {
              if (eventClickInfo.event.extendedProps.postType === 2) {
                onEventClick(eventClickInfo);
              } else if (eventClickInfo.event.extendedProps.isAbsenceEvent) {
                onAbsenceClick(eventClickInfo);
              } else if (eventClickInfo.event.extendedProps.isWorkingHour) {
                handleEditWorkingHour(eventClickInfo);
              } else {
                onTaskClick(eventClickInfo);
              }
            }}
            eventDrop={eventDropInfo => handleEventChange(eventDropInfo.event)}
            eventResize={eventResizeInfo => handleEventChange(eventResizeInfo.event)}
            datesSet={calendar => {
              const lastViewedDate =
                filters.find(item => item.name === 'currentViewDate') &&
                filters.find(item => item.name === 'currentViewDate').value;
              const currentViewDate = calendar.view.currentStart;
              if (lastViewedDate.getMonth() !== currentViewDate.getMonth()) {
                setFilters(
                  filters.map(item => {
                    return item.name === 'currentViewDate'
                      ? { ...item, value: currentViewDate }
                      : item;
                  }),
                );
              }
            }}
            eventRender={info => {
              if (info.event.extendedProps.postType === 5) {
                const icon = document.createElement('i');
                icon.classList.add('fa');
                icon.classList.add('fa-tasks');
                info.el.prepend(icon);
                info.el.classList.add(`${styles.taskEvent}`);
              }
            }}
            locale={locale}
          />
        </div>
        {isCreateEventOpen && (
          <CalendarCreateEditEvent
            isOpened={isCreateEventOpen}
            onClose={() => {
              setIsCreateEventOpen(false);
              setIsEditing(false);
            }}
            eventData={eventData}
            calendarSettings={
              calendarModulePlace !== 'groupCalendar' && eventData.group && eventData.group.groupId
                ? eventData.group
                : calendarSettings
            }
            onSubmit={isEditing ? handleEventUpdate : onAddEvent}
            canBePublic={getCanBePublicStatus(eventData.group || groupInfo)}
            isEditing={isEditing}
            langCode={user.langCode}
          />
        )}
        {editTaskIsOpen && (
          <CreateEditTask
            user={user}
            task={eventData}
            isOpened={editTaskIsOpen}
            onClose={() => setEditTaskIsOpen(false)}
            onSubmit={handleEventUpdate}
            canBePublic={getCanBePublicStatus(groupInfo || eventData.group)}
            taskLists={taskLists}
            taskListId={eventData.taskListId}
            groupId={groupInfo ? groupInfo.groupId : eventData.group && eventData.group.groupId}
            canAddToCalendar={
              calendarModulePlace !== 'groupCalendar' && eventData.group && eventData.group.groupId
                ? eventData.group &&
                  eventData.group.calendarTypes.find(item => item.name === 'Task').enabled
                : calendarSettings &&
                  calendarSettings.calendarTypes.find(item => item.name === 'Task').enabled
            }
            calendarModulePlace={calendarModulePlace}
          />
        )}
        {isEventOpen && (
          <CalendarEvent
            isOpened={isEventOpen}
            closePopup={() => setIsEventOpen(false)}
            eventData={eventData}
            updateEvent={handleEventUpdate}
            deleteEvent={handleEventDelete}
            groupInfo={groupInfo}
            onEdit={handleEdit}
            user={user}
            match={match}
            calendarModulePlace={calendarModulePlace}
            onHideDay={handleHideEvent}
          />
        )}
        {isChooseTargetOpened && (
          <CalendarChooseTarget
            isOpened={isChooseTargetOpened}
            closePopup={handleCloseChooseTarget}
            onNext={handleNext}
            onEnable={handleEnable}
            calendarSettings={calendarSettings}
          />
        )}
        {openPopup && isProviderRelevant && (
          <ProviderPostPopup
            providers={providers}
            isOpened
            closePopup={() => setOpenPopup(false)}
            submit={onSubmit}
            isLoading={isSending}
            dispatchProviders={dispatchProviders}
          />
        )}
      </Card>
    </>
  );
}
