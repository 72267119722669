import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Label from 'library/common/commonComponents/Label';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Input from 'library/common/commonComponents/Inputs/Input';

import styles from './editMediaPopup.module.scss';
import TextArea from '../../Inputs/TextArea';

export default function EditMediaPopup({
  isOpened,
  closePopup,
  description,
  editMedia,
  isBazaar,
  file,
}) {
  const [localDescription, setLocalDescription] = useState(isBazaar ? 'test' : description || '');
  const [localBazaarItemDescription, setLocalBazaarItemDescription] = useState(
    file ? (file.bazaarItemInfo ? file.bazaarItemInfo.description : '') : '',
  );

  const [localPrice, setLocalPrice] = useState(
    file ? (file.bazaarItemInfo ? file.bazaarItemInfo.price : 0) : 0,
  );
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    setLocalDescription(description || '');
    // eslint-disable-next-line
  }, [description]);
  const { t } = useTranslation();

  const onSubmit = () => {
    if (isSubmiting) {
      return;
    }
    onSubmitHandler({
      description: localDescription,
      bazaarItemInfoDescription: localBazaarItemDescription,
      price: localPrice,
      closePopup,
      t,
      editMedia,
      setIsSubmiting,
    });
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='small'
      header={<Trans i18nKey='Gallery.Edit media' />}
      body={
        <div className={cn(styles.container)}>
          <div className={styles.inputGroup}>
            {isBazaar && (
              <>
                <Label type='input'>{t('Bazaar.Title')}</Label>
                <Input
                  value={localDescription}
                  onChange={({ target }) => setLocalDescription(target.value)}
                  maxLength={1000}
                />
                <br />
              </>
            )}
            <Label type='input'>{t('EditGalleryPopup.Description')}</Label>
            <TextArea
              value={isBazaar ? localBazaarItemDescription : localDescription}
              onChange={({ target }) =>
                isBazaar
                  ? setLocalBazaarItemDescription(target.value)
                  : setLocalDescription(target.value)
              }
              maxLength={1000}
            />
            {isBazaar && (
              <>
                <br />
                <Label type='input'>{t('Bazaar.Price')}</Label>
                <Input
                  type='number'
                  pattern='[0-9]'
                  min='0'
                  value={localPrice}
                  onChange={({ target }) => setLocalPrice(target.value)}
                  maxLength={1000}
                  faIcon='fa-eur'
                />
              </>
            )}
          </div>
        </div>
      }
      footer={
        <div>
          <ButtonWithLoader
            onClick={onSubmit}
            type='primary'
            className={styles.footerBtnLeft}
            isLoading={isSubmiting}
          >
            {t('Popup.Save')}
          </ButtonWithLoader>
          <Button onClick={closePopup}>{t('Popup.Close')}</Button>
        </div>
      }
    />
  );
}

export async function onSubmitHandler({
  description,
  price,
  bazaarItemInfoDescription,
  closePopup,
  editMedia,
  setIsSubmiting,
}) {
  setIsSubmiting(true);
  await editMedia({
    description,
    price,
    bazaarItemInfoDescription,
  });
  setIsSubmiting(false);
  closePopup();
}
