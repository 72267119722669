import React, { useState, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cn from 'classnames';
import { sanitize } from 'dompurify';

import { getDateRange } from 'library/utilities/date';
import { updatePermanentStatus } from 'library/api/posts';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Tooltip from 'library/common/commonComponents/Tooltip';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import Label from 'library/common/commonComponents/Label';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from './taskHeaderDetail.module.scss';

export default function TaskHeaderDetail({
  isOpened,
  onOpen,
  onEdit,
  onDelete,
  isEditing,
  taskStatus,
  title,
  isScheduling,
  end,
  assignedUsersList,
  responsibleUsersList,
  user,
  creatorId,
  proceedUnassignedTask,
  isAdmin,
  start,
  allDay,
  taskList,
  manageTasks,
  permanent,
  id,
  isTranslationAllowed,
}) {
  const { t } = useTranslation();
  const [deleteTaskIsOpened, setDeleteTaskIsOpened] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  // eslint-disable-next-line
  const isResponsible = useMemo(() => responsibleUsersList.find(item => item.id === user.id), [
    responsibleUsersList,
  ]);

  const canEditTask =
    user.id === creatorId ||
    (!assignedUsersList.length && proceedUnassignedTask) ||
    isAdmin ||
    isResponsible;

  const dropdownSetter = perm =>
    canEditTask
      ? [
        {
          faIcon: 'fa-pencil',
          title: isEditing ? t('Tasks.Cancel edit task') : t('Tasks.Edit task dropdown'),
          onClick: () => {
            onEdit();
          },
        },
        {
          faIcon: 'fa-trash-o',
          title: t('Tasks.Delete task'),
          onClick: () => {
            setDeleteTaskIsOpened(true);
          },
        },
        manageTasks
          ? {
            faIcon: perm ? 'fa-unlock' : 'fa-lock',
            title: perm ? t('Post.Revoke permanent') : t('Post.Make permanent'),
            onClick: () => changePermanentOfTask(perm),
          }
          : null,
      ].filter(option => option !== null)
      : [];

  const [dropdownOptions, setDropdownOptions] = useState(dropdownSetter(permanent));

  const changePermanentOfTask = currentStatus => {
    let success = true;
    updatePermanentStatus(id)
      .then(() => {
        setDropdownOptions(dropdownSetter(!currentStatus));
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        store.dispatch(
          showBottomNotification(
            <Trans i18nKey={`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`} />,
            { isFail: !success },
          ),
        );
      });
  };

  const date = new Date();

  if (!isOpened) {
    onOpen();
  }

  const handleDelete = async () => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(true);
    await onDelete();
    setDeleteTaskIsOpened(false);
    setIsSubmiting(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerTop}>
        <div className={styles.headerTopLeft}>
          <div
            className={styles.titleContainer}
            style={{ borderLeftColor: taskList && taskList.color }}
          >
            <i className='fa fa-tasks' />
            {isTranslationAllowed ? (
              <WithWatsonTranslate
                data={{ text: title, entityId: id, entityType: '5' }}
                Component={({ html }) => (
                  <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                )}
              />
            ) : title}
          </div>
          {isScheduling && (
            <div className={styles.timeRange}>
              {getDateRange(start, end, allDay, user && user.langCode)}
            </div>
          )}
          <div className={cn(styles.userInfo, 'notranslate')}>
            {`${user.firstName} ${user.lastName}`}
          </div>
        </div>
        <div className={styles.headerTopRight}>
          <div className={styles.moreButtonsContainer}>
            <MoreBtn
              faIcon='fa fa-cog'
              dropdownOptions={dropdownOptions}
              className={styles.moreButtons}
            >
              <div className={styles.moreButtonIcon}>
                <i className='fa fa-caret-down' />
              </div>
            </MoreBtn>
          </div>
          <div className={styles.labelContainer}>
            {taskStatus === 'pending' ? (
              <Label type='default' className={styles.pendingLabel}>
                <i className='fa fa fa-info-circle' /> {t('Tasks.PENDING')}
              </Label>
            ) : taskStatus === 'in_progress' ? (
              <Label type='default' className={styles.inProgressLabel}>
                <i className='fa fa fa-edit' /> {t('Tasks.IN PROGRESS')}
              </Label>
            ) : taskStatus === 'pending_review' ? (
              <Label type='default' className={styles.pendingReviewLabel}>
                <i className='fa fa fa-exclamation-triangle' /> {t('Tasks.PENDING REVIEW')}
              </Label>
            ) : taskStatus === 'completed' ? (
              <Label type='default' className={styles.completedLabel}>
                <i className='fa fa-check-square' /> {t('Tasks.COMPLETED')}
              </Label>
            ) : null}
            {end && date.getTime() > end && taskStatus !== 'completed' && (
              <Label type='default' className={styles.overdueLabel}>
                <i className='fa fa fa-exclamation-triangle' /> {t('Tasks.OVERDUE')}
              </Label>
            )}
          </div>
        </div>
      </div>
      <div className={styles.separate} />
      <div className={styles.headerBottom}>
        {responsibleUsersList.length !== 0 && (
          <div className={styles.responsibleUsersListContainer}>
            <div className={styles.responsibleUsersListTitle}>{t('Tasks.Responsible')}</div>
            <div className={styles.responsibleUsersListText}>
              {responsibleUsersList.length !== 0 &&
                responsibleUsersList.map(item => (
                  <Tooltip
                    key={item.id}
                    text={
                      <span className='notranslate'>
                        {item.firstName} {item.lastName} {t('Tasks.is responsible')}
                      </span>
                    }
                    isVisibleCondition
                  >
                    {item.avatar ? (
                      <img
                        className={styles.avatar}
                        src={item.avatar}
                        alt={`${item.firstName} avatar`}
                      />
                    ) : (
                      <img className={styles.avatar} src={defaultAvatar} alt='default avatar' />
                    )}
                  </Tooltip>
                ))}
            </div>
          </div>
        )}
        <div className={styles.assignedContainer}>
          <div className={styles.assignedTitle}>{t('Tasks.Assigned')}</div>
          <div className={styles.assignedText}>
            {assignedUsersList.length !== 0 ? (
              assignedUsersList.map(item => (
                <Tooltip
                  key={item.id}
                  text={
                    <span className='notranslate'>
                      {item.firstName} {item.lastName} {t('Tasks.is assigned')}
                    </span>
                  }
                  isVisibleCondition
                >
                  <div className={styles.avatar}>
                    <ProfileAvatar image={item} id={item.id} withoutLink />
                  </div>
                </Tooltip>
              ))
            ) : (
              <div className={styles.assignedTextEmpty}>
                {t('Tasks.Any user can work on this task')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.deleteTaskContainer}>
        <Popup
          size='extraSmall'
          isOpened={deleteTaskIsOpened}
          header={<Trans i18nKey='Tasks.Confirm Action' />}
          closePopup={() => setDeleteTaskIsOpened(false)}
          footer={
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonCancel}>
                <Button onClick={() => setDeleteTaskIsOpened(false)}>{t('Tasks.Cancel')}</Button>
              </div>
              <div className={styles.buttonConfirm}>
                <ButtonWithLoader onClick={handleDelete} type='primary' isLoading={isSubmiting}>
                  {t('Tasks.Confirm')}
                </ButtonWithLoader>
              </div>
            </div>
          }
        >
          <div className={styles.wrapper}>
            {t('Tasks.Do you really want to perform this action')}
          </div>
        </Popup>
      </div>
    </div>
  );
}
