import React, { useState, useRef, useEffect } from "react";

import style from './DragAndDropList.module.scss';



const DragAndDropList = ({
    inputList,
    removeComponent,
    moveArrowComponent,
    startEditComponent,
    renderComponent,
    form,
    setForm,
    setMoveComponentPopup,
}) => {
    const [list, setList] = useState([]);

    useEffect(() => {
        setList(inputList);
    }, [inputList]);

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = () => {
        const copyListItems = [...list];

        const updatedListItems = copyListItems.map((item) => {
            if (dragOverItem.current === item.position)
                return {
                    ...item,
                    position: dragItem.current,
                };
            else if (dragItem.current === item.position)
                return {
                    ...item,
                    position: dragOverItem.current,
                };
            else
                return item;
        });

        dragItem.current = null;
        dragOverItem.current = null;
        updatedListItems.sort((a, b) => a.position - b.position);
        setList(updatedListItems);
        setForm({
            ...form,
            formFields: updatedListItems,
        });

    };

    return (
        <>
            {list && list.map((item, index) => (
                <div
                    className={style.component}
                    draggable={false}
                    onDragStart={(e) => {
                      // dragStart(e, index)
                    }}
                    onDragEnter={(e) => {
                      // dragEnter(e, index)
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnd={() => {
                      // drop();
                    }}
                    key={index}
                >
                    <div className={style.componentActionButtons}>
                        <i className='fa fa-trash' onClick={() => removeComponent(item.position, item.page)} />
                        <i className='fa fa-arrow-up' onClick={() => moveArrowComponent(item.position, item.page, -1)} />
                        <i className='fa fa-arrow-down' onClick={() => moveArrowComponent(item.position, item.page, 1)} />
                        <span onClick={() => { setMoveComponentPopup(prev => { return { open: true, position: item.position, componentCount: form.formFields.length, page: item.page } }) }}>{item.position}</span>
                    </div>
                    <div onClick={() => startEditComponent(item)}>{renderComponent(item)}</div>
                </div>
            ))}
        </>
    );
};

export default DragAndDropList;
