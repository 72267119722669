import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import Loader from 'library/common/commonComponents/Loader';
import styles from './usersFrameKitaAdmins.module.scss';
import { getUserLogoUrl } from 'library/utilities/user';

import useCachedImage from 'library/common/commonHooks/useCachedImage';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';

export default function UsersFrameKitaAdmins({ activeKita, data, setEditingProfile }) {

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.container}>
      {!isLoading ? (
        <div className={styles.kitaAdminList}>
          {data.data.content.map((item) => (
            <>
              <div
                className={styles.kitaAdminUser}
                onClick={() => setEditingProfile({
                  ...item.user,
                  adminRole: item.kitaRoles.roles.adminRole,
                  employee: item.employee,
                  qrCodeScanner: item.qrCodeScanner,
                  ableToEditUserProfiles: item.ableToEditUserProfiles,
                  awoMember: item.awoMember,
                })}
              >
                <div className={styles.profileAvatarWrapper}>
                  <ProfileAvatar id={item.user.id} />
                </div>
                <div className={styles.memberName}>{`${item.user.firstName} ${item.user.lastName}`}</div>
                <div className={styles.memberEmail}>{item.user.email}</div>
              </div>
              <hr />
            </>
          ))}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
