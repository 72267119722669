import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { createEmployeePortfolioGroupIfNeeded, enableDisableCheckbox } from 'library/api/user';
import useReactRouter from 'use-react-router';
import Checkbox from 'library/common/commonComponents/Checkbox';

import styles from './../userFrameEmployee.module.scss';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';

export default function EmployeeItem({ data, header, categories, count, kitaId, setIsLoading, portfolioModuleIsActive }) {
  const { history } = useReactRouter();
  const { t } = useTranslation();

  const clickItem = () => {
    if (portfolioModuleIsActive()) {
      setIsLoading(true);
      createEmployeePortfolioGroupIfNeeded(data.id).then(data => {
        history.push(`/groups/${data.data}/stream`);
        setIsLoading(false);
      });
    } else {
      history.push(`/profile/${data.id}`);
    }
  }

  useEffect(() => {
    let subArray = [false, false, false, false];
    categories.forEach((item, index) => {
      if (item.userIDs != []) {
        item.userIDs.forEach((element) => {
          if (data.id === element) {
            subArray[index] = true
          }
        })
      }
    })
    setCheckBoxArray(subArray);
  }, [categories])

  const [checkBoxArray, setCheckBoxArray] = useState(Array(4).fill(false))


  const handleCheckboxChange = (checkboxValue) => {
    let updatedCheckBoxes = [...checkBoxArray];
    let payloadCheckBox = false;
    if (updatedCheckBoxes[checkboxValue]) {
      payloadCheckBox = !updatedCheckBoxes[checkboxValue]
      updatedCheckBoxes[checkboxValue] = !updatedCheckBoxes[checkboxValue]
    } else {
      updatedCheckBoxes.forEach((item, index) => {
        if (index == checkboxValue) {
          payloadCheckBox = !item
          updatedCheckBoxes[index] = !item
        } else {
          updatedCheckBoxes[index] = false
        }
      })
    }
    let payloadArray = []

    updatedCheckBoxes.forEach((item, index) => {
      let payload = {}
      if (categories[index] != null) {
        payload = {
          "userID": data.id,
          "kitaID": kitaId,
          "categoryID": categories[index].categoryID,
          "checked": item
        }
        payloadArray.push(payload)
      }
    })
    enableDisableCheckbox(payloadArray).then((res) => {
      store.dispatch(
        showBottomNotification(t('BottomNotifications.Saved'), {
          isFail: false,
        })
      );
      count = count + 1
    })
    setCheckBoxArray(updatedCheckBoxes);
  };

  return (
    <>
      <tr>
        <td
          onClick={() => clickItem()}>
          <img
            style={{ height: '50px', width: '50px' }}
            src={data.modifiedProfileLogoUrl || defaultAvatar}
          />
        </td>
        <td
          onClick={() => clickItem()}>{`${data.firstName} ${data.lastName}`}</td>
        <td
          onClick={() => clickItem()} className={styles.emailContainer}>{data.email}</td>
        <td><Checkbox isDisabled={header[0] === ""} isChecked={checkBoxArray[0]}
          onChange={() => handleCheckboxChange(0)}></Checkbox></td>
        <td><Checkbox isDisabled={header[1] === ""} isChecked={checkBoxArray[1]}
          onChange={() => handleCheckboxChange(1)}></Checkbox></td>
        <td><Checkbox isDisabled={header[2] === ""} isChecked={checkBoxArray[2]}
          onChange={() => handleCheckboxChange(2)}></Checkbox></td>
        <td><Checkbox isDisabled={header[3] === ""} isChecked={checkBoxArray[3]}
          onChange={() => handleCheckboxChange(3)}></Checkbox></td>
      </tr>
    </>
  );
}
