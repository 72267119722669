import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import { sendBazaarMessage } from 'library/api/bazaar';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from './contactBazaarSellerPopup.scss';
import TextArea from '../../Inputs/TextArea';
import ProfileAvatar from '../../ProfileAvatar';
import { getUser } from '../../../../utilities/api';

export default function ContactBazaarSellerPopup({
  title,
  isOpened,
  closePopup,
  file,
  kitaId,
  receipientId,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [resultText, setResultText] = useState('');
  const [fileUser, setFileUser] = useState({});

  useEffect(() => {
    getUser(file.userId).then(result => setFileUser(result));
  });
  const { t } = useTranslation();

  const onSubmit = () => {
    const subject = t('Bazaar.Bazaar') + ': ' + file.description;
    let chatMessage = t('Bazaar.Im interested') + ' (' + file.description + '): ' + message;
    if (!message) {
      chatMessage =
        t('Bazaar.Im interested') + ' (' + file.description + ') ' + t('Bazaar.Stil available');
    }
    onSubmitHandler({
      subject,
      message: chatMessage,
      receipientId,
      kitaId,
      setIsSubmitting,
      t,
      setResultText,
      setMessage,
    });
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='small'
      header={title}
      body={
        <div className={cn(styles.container)}>
          <div className={styles.inputGroup}>
            <label>{t('Bazaar.Seller')}</label>
            <div className='bazaar-avatar-container'>
              <div className='bazaar-avatar'>
                <ProfileAvatar id={file.userId} withoutLink />
              </div>
              <div className='bazaar-avatar-text'>
                {fileUser ? fileUser.firstName + ' ' + fileUser.lastName : ''}
              </div>
            </div>
            <label>
              <strong>{t('Bazaar.Message Label')}</strong>
            </label>
            <TextArea
              value={message}
              onChange={e => setMessage(e.target.value)}
              type='text'
              placeholder={t('Bazaar.Message')}
            />
            <p>{t('Bazaar.Hint')}</p>
          </div>
        </div>
      }
      footer={
        <div>
          <ButtonWithLoader
            onClick={onSubmit}
            type='primary'
            className={styles.footerBtnLeft}
            isLoading={isSubmitting}
          >
            {t('Bazaar.Send Message')}
          </ButtonWithLoader>
          <Button onClick={closePopup}>{t('Popup.Close')}</Button>
          {resultText && (
            <>
              <p className='success'>{resultText}</p>
            </>
          )}
        </div>
      }
    />
  );
}

export async function onSubmitHandler({
  subject,
  message,
  receipientId,
  kitaId,
  setIsSubmitting,
  t,
  setResultText,
  setMessage,
}) {
  setIsSubmitting(true);
  sendBazaarMessage({ subject, message, receipientId, kitaId }).then(res => {
    setIsSubmitting(false);
    setMessage('');
    setResultText(t('Bazaar.Message sent'));
  });
}
