import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import Popup from 'library/common/commonComponents/Popups/Popup';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import { useTranslation } from 'react-i18next';

export default function CloseKidsModePopup({isPopupOpen, setIsPopupOpen, userEmail}){

  const { t } = useTranslation();
  const history = useHistory();
  const [inputValue, setInputValue] = useState('');

  return (
    <Popup
      isOpened={isPopupOpen}
      closePopup={() => setIsPopupOpen(false)}
      header={t('WhiteBoard.Exit kids mode')}
      footer={
        <div>
          <p>
            {t('WhiteBoard.Please type in your accounts email to exit the kids mode')}
          </p>
          <br />
          <p>
            <Input
              type='text'
              //error={error.subject}
              value={inputValue}
              onChange={e=>{
                if(e.target.value.trim().toLowerCase() === userEmail.trim().toLowerCase()){
                  history.push({
                    pathname: window.location.pathname,
                    search: ''
                });
                setInputValue('');
                setIsPopupOpen(false);
                }
                else{
                  setInputValue(e.target.value);
                }

               }
              }
            />
          </p>
          <br />
          <div>
            <Button onClick={()=>{
              setIsPopupOpen(false);
            }}>
              {t('WhiteBoard.Cancel')}
            </Button>
          </div>
        </div>
      }
    />
  )

}