import React, { useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { updateKidContractEndDate } from 'library/api/kids';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Input from 'library/common/commonComponents/Inputs/Input';
import store from 'main/store/configureStore';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from './ChildExitDateList.module.scss';

export const ChildExitDateListRow = ({ user, kid, t }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contractEndDate, setContractEndDate] = useState(kid.contractEndDate ?? null);

  const history = useHistory();

  const handleKidContractEndDateChange = contractEndDate => {
    setContractEndDate(contractEndDate);
  };

  const goChildPage = (kid) => {
    history.push({
      pathname: '/administration/users/list/1',
      search: `?editChild=${kid.kidId}&parentId=${kid.parent1Id}`
    });
  };

  const onSaveClick = async () => {
    if (isSubmitting) {
      return;
    }
    try {
      setIsSubmitting(true);
      await updateKidContractEndDate(kid.kidId, new Date(contractEndDate));
      store.dispatch(showBottomNotification(t('BottomNotifications.Saved'), { isFail: false }));
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      store.dispatch(
        showBottomNotification(t('BottomNotifications.Something went wrong'), { isFail: true }),
      );
    }
  };

  return (
    <tr key={kid.kidId}>
      <td
      onClick={() => goChildPage(kid)}
      >
        <div className={cn('notranslate', styles.avatar)}>
          <img
            style={{ maxWidth: '40px', marginRight: '10px' }}
            src={kid.groupLogoUrl || defaultAvatar}
            alt=''
          />
          <div className={styles.avatarText}>
            <div className={styles.name}>
              <span>
                {kid.firstName} {kid.lastName}
              </span>
              <span className={styles.parent}>
                {kid.parent1FirstName || ''} {kid.parent1LastName || ''}
              </span>
              <span className={styles.parent}>
                {kid.parent2FirstName || ''} {kid.parent2LastName || ''}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td
      onClick={() => goChildPage(kid)}
      >
        <div className={cn('notranslate', styles.avatar)}>
          <div className={styles.avatarText}>
            <div className={styles.name}>{kid.groupName && kid.groupName}</div>
          </div>
        </div>
      </td>
      <td>
        <div className={styles.buttonsContainer}>
          <DatePicker
            onChange={contractEndDate => handleKidContractEndDateChange(contractEndDate)}
            selected={contractEndDate && new Date(contractEndDate)}
            customInput={<Input />}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            langCode={user.langCode}
          />
          <ButtonWithLoader
            type='primary'
            size='sm'
            isLoading={isSubmitting}
            onClick={onSaveClick}
            disabled={!contractEndDate}
          >
            {t('Confirm.Save')}
          </ButtonWithLoader>
        </div>
      </td>
    </tr>
  );
};
