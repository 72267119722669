import React, { useState, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import cn from 'classnames';
import { sanitize } from 'dompurify';

import { isPublicUser } from 'library/utilities/user';
import Storage from 'library/utilities/storage';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Button from 'library/common/commonComponents/Buttons/Button';
import Tooltip from 'library/common/commonComponents/Tooltip';
import TextCaret from 'library/common/commonComponents/TextCaret';
import Dropdown from 'library/common/commonComponents/Dropdowns/Dropdown';
import { useDropdown } from 'library/common/commonHooks/useDropdown';
import { getConfig } from 'library/api/kita';
import {
  putGroupNotification,
  putPostVisibility,
  putUserMembership,
  setGlobalPublicGroup,
  toggleUserGroupFavoriteStatus,
  getAWOUserGroups,
} from 'library/api/groups';
import { isAwoWW } from 'library/api/tenantConfig';
import InviteUsersPopup from 'library/common/commonComponents/Popups/InviteUsersPopup';
import GroupUsersListPopup from 'library/common/commonComponents/Popups/GroupUsersListPopup';
import GroupAWOUsersListPopup from 'library/common/commonComponents/Popups/groupAWOUsersListPopup';
import GroupKidsListPopup from 'library/common/commonComponents/Popups/GroupKidsListPopup';
import GroupInfoPopup from 'library/common/commonComponents/Popups/GroupInfoPopup';
import Loader from 'library/common/commonComponents/Loader';
import {
  inviteUsersAPI,
  joinPublicGroupAPI,
  requestGroupMembershipAPI,
} from "library/common/commonActions/groupActions";
import { GroupContext } from 'modules/Group/groupModule';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';

import GroupLogo from './groupPanelFrames/GroupLogo';
import GroupHeader from './groupPanelFrames/GroupHeader';

import styles from './groupPanel.module.scss';

GroupPanel.defaultProps = {
  showCounts: true,
};

export function GroupPanel({
  letter,
  backgroundColor,
  title,
  description,
  type,
  match,
  history,
  showBottomNotificationFromProps,
  userInfo,
  showJoinButton,
  id,
  activeKita,
}) {
  const user = useSelector(state => state.userReducer);
  const isAWO = isAwoWW();
  const isSafeToConnectType = (activeKita.kitaTypeTranslations || []).includes('Safe2Connect');

  const groupContext = useContext(GroupContext);
  const { groupInfo } = groupContext;
  const patchGroupInfo = groupContext.patchGroup;
  const [isUserInvitationVisible, setIsUserInvitationVisible] = useState(false);
  const [data, setData] = useState({ users: [] });
  const [showGroupInfo, setShowGroupInfo] = useState(false);
  const [awoMembers, setAWOMembers] = useState([]);
  const [errors, setErrors] = useState({
    invite: '',
  });
  const { t } = useTranslation();

  const [isUsersListPopupOpened, setIsUsersListPopupOpened] = useState(false);
  const [isKidsListPopupOpened, setIsKidsListPopupOpened] = useState(false);
  const [isAWOUsersListPopupOpened, setIsAWOUsersListPopupOpened] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [onToggling, setOnToggling] = useState(false);
  const [memberOptionVisible, setMemberOptionVisible] = useState(false);

  const { isOpened: isOptionsDropdownOpened, DropdownWrapper } = useDropdown(Dropdown);

  const notificationsChange = () => {
    putGroupNotification(groupInfo.groupId, !groupInfo.notificationStatus).then(() => {
      patchGroupInfo({ notificationStatus: !groupInfo.notificationStatus });
    });
  };

  const postVisibilityChange = () => {
    putPostVisibility(groupInfo.groupId, !groupInfo.postVisibilityStatus).then(() => {
      patchGroupInfo({ postVisibilityStatus: !groupInfo.postVisibilityStatus });
    });
  };

  const checkUserIsLastAdmin = () => {
    if (groupInfo.groupAdminStatus) {
      const adminMembers = groupInfo.userList.filter(usr => usr.groupAdminStatus === true);
      return adminMembers.length === 1 && adminMembers.find(usr => usr.id === `${userInfo.id}`);
    }
    return false;
  };

  const handleGroupFavoriteToggle = () => {
    setOnToggling(true);
    toggleUserGroupFavoriteStatus(groupId, user.id, !isFavorite)
      .then(() => {
        setIsFavorite(!isFavorite);
        const groupFavoriteActionKey = isFavorite
          ? 'GroupPanel.Remove group from favorite'
          : 'GroupPanel.Add group to favorite';
        showBottomNotificationFromProps(t(groupFavoriteActionKey));
      })
      .catch(err => {
        showBottomNotificationFromProps(t('GroupPanel.Invalid Action'), { isFail: true });
      }).finally(() => {
        setOnToggling(false);
      });
  };

  const cancelGlobalGroupMembership = async () => {
    try {
      await setGlobalPublicGroup(groupInfo.groupId, { consentAccepted: false });
      showBottomNotificationFromProps(t('GroupPanel.Success'));
      history.push('/dashboard');
    } catch (err) {
      showBottomNotificationFromProps(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
    }
  };

  const cancelMembership = () => {
    if (checkUserIsLastAdmin()) {
      showBottomNotificationFromProps(
        t('GroupBody.Members.Operation failed! Please assign other admin'),
        { isFail: true },
      );
      return;
    }

    if (groupInfo.globalPublicGroup) {
      cancelGlobalGroupMembership();
      return;
    }

    putUserMembership(groupInfo.groupId).then(() => {
      patchGroupInfo({ showCancelMembership: false });
      showBottomNotificationFromProps(t('GroupPanel.Success'));
      history.push('/');
    });
  };
  const staffdropdownOptions = [
    {
      faIcon: 'fa fa-cogs',
      title: t('GroupPanel.Settings'),
      to: `${match.url}/settings`,
    },
    {
      faIcon: 'fa-lock',
      title: t('GroupPanel.Security'),
      to: `${match.url}/security`,
    },
    {
      faIcon: 'fa-rocket',
      title: t('GroupPanel.Modules'),
      to: `${match.url}/modules`,
    },
    {
      faIcon: 'fa-group',
      title: t('GroupPanel.Members'),
      to: `${match.url}/members`,
    },
  ];

  const locale = Storage.getItem('locale') || 'de';
  const dropdownOptions = [
    [
      {
        faIcon: groupInfo.notificationStatus ? t('fa-bell-o') : t('fa-bell'),
        onClick: notificationsChange,
        title: groupInfo.notificationStatus
          ? t('GroupPanel.Dont receive notifications for new content')
          : t('GroupPanel.Receive Notifications for new content'),
        addClassName: `notification-${locale}`,
      },
      groupInfo.showReceiveNotification,
    ],
    [
      {
        faIcon: 'fa-times',
        onClick: cancelMembership,
        title: t('GroupPanel.Cancel Membership'),
      },
      groupInfo.showCancelMembership && !isAwoWW() && !isPublicUser(user),
    ],
    [
      {
        faIcon: groupInfo.postVisibilityStatus ? t('fa-eye-slash') : t('fa-eye'),
        onClick: postVisibilityChange,
        title: groupInfo.postVisibilityStatus
          ? t('GroupPanel.Hide posts on dashboard')
          : t('GroupPanel.Show posts on dashboard'),
        tooltip: {
          text: groupInfo.postVisibilityStatus
            ? t('GroupPanel.This option will hide content at dashboard')
            : t('GroupPanel.This option will show content at dashboard'),
          position: 'left',
          isVisibleCondition: true,
        },
      },
      groupInfo.showHidePostOnDashboard,
    ],
  ].reduce((filteredOptions, option) => {
    return (filteredOptions = option[1] ? filteredOptions.concat(option[0]) : filteredOptions);
  }, []);

  const handleSendMembershipRequest = async () => {
    await requestGroupMembershipAPI(groupInfo.groupId);
    showBottomNotificationFromProps(t('GroupPanel.User has sent membership request for group!'));
  }

  const closeInvitation = () => {
    setIsUserInvitationVisible(false);
    setData({ users: [] });
    setErrors({ ...errors, ...{ invite: '' } });
  };
  const inviteUsers = () => {
    if (!data.users.length) {
      setErrors({ ...errors, ...{ invite: t('GroupPanel.This field cannot be blank') } });
      return null;
    }
    return inviteUsersAPI({
      groupId: groupInfo.groupId,
      userGroup: data.users.map(item => +item.id),
    }).then(d => {
      if (d) {
        patchGroupInfo({ memberCount: groupInfo.memberCount + data.users.length });
        patchGroupInfo({ userList: groupInfo.userList.concat(data.users) });
        closeInvitation();
        showBottomNotificationFromProps(t('GroupPanel.User has been invited'));
      }
    });
  };

  const currentDropdownOptions = groupInfo.groupAdminStatus
    ? staffdropdownOptions.concat(dropdownOptions)
    : dropdownOptions;

  const { groupId, visibility, showCancelMembership, groupAdminStatus } = groupInfo;
  const showCounts = visibility ? showCancelMembership : showCancelMembership || groupAdminStatus;

  useEffect(() => {
    getConfig().then((res) => {
      const kitaConfig = res.data;
      setMemberOptionVisible(kitaConfig.memberOptionVisible);
    })
  }, []);

  useEffect(() => {
    setIsFavorite(groupInfo.favorite || false);
  }, [groupInfo.favorite]);

  useEffect(() => {
    if (groupInfo.groupId && isAWO) {
      getAWOUserGroups(groupInfo.groupId).then(data => {
        setAWOMembers(data.data || []);
      });
    }
  }, [groupInfo.groupId]);


  return (
    <Col>
      <Row>
        <div className={styles.panel}>
          <div className={styles.panelTop}>
            <div
              className={cn(
                styles.panelTopBanner,
                groupInfo.headerUrl && styles.panelTopBannerWithImage,
              )}
            >
              <GroupHeader
                groupInfo={groupInfo}
                showBottomNotification={showBottomNotificationFromProps}
                patchGroupInfo={patchGroupInfo}
              />
              {!user.superAdminStatus && isAWO && !isPublicUser(user) && (
                <>
                  {onToggling ? (
                    <div className={styles.favoriteIconWrapper}>
                      <Loader dotSize={8} />
                    </div>
                  ) : (
                    <div className={styles.favoriteIconWrapper} onClick={handleGroupFavoriteToggle}>
                      {isFavorite ? (
                        <Tooltip text={t('GroupPanel.Remove Favorite')}>
                          <i className="fa fa-star" />
                        </Tooltip>
                      ) : (
                        <Tooltip text={t('Checkinout.Add Favorite')}>
                          <i className="fa fa-star-o" />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </>
              )}
              <div className={styles.groupInfoWrapper}>
                <div className={styles.textWrapper}>
                  <div className={cn(styles.title, type === 'profile' && styles.titleThin)}>
                    {title}
                  </div>
                  {!groupInfo.globalPublicGroup &&
                    <span className={styles.title}>({activeKita?.kitaName})</span>
                  }
                </div>
                <div className={styles.description}>
                  {id !== undefined ? (
                    <WithWatsonTranslate
                      data={{ text: description || '&nbsp;', entityId: id, entityType: '0' }}
                      Component={({ html }) => (
                        <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                      )}
                    />
                  ) : (
                    description
                  )}
                </div>
              </div>
              <GroupLogo
                groupInfo={groupInfo}
                letter={letter}
                showBottomNotification={showBottomNotificationFromProps}
                patchGroupInfo={patchGroupInfo}
                backgroundColor={backgroundColor}
              />
              <div className={styles.panelTopBannerFade} />
            </div>
          </div>
          <div className={styles.panelBottom}>
            <div className={styles.panelBottomWrapper}>
              {user.lastName !== 'Caterer' && (
                <>
                  {showCounts ? (
                    <div className={styles.stats}>
                      <div className={styles.statsItem}>
                        <i
                          className={`${styles.statsItemIcon} ${styles.statsPostsIcon
                            } fa fa-file-text`}
                          aria-hidden='true'
                        />
                        <div className={styles.statsItemTitle}>
                          <span className={styles.statsItemCount}>{groupInfo.postCount}</span>
                          {t('GroupPanel.Posts')}
                        </div>
                      </div>
                      {!groupInfo.globalPublicGroup &&
                        groupId !== 2093 &&
                        groupId !== 2105 &&
                        groupId !== 1175786483 &&
                        groupInfo.userList &&
                        !isPublicUser(user) && (
                          <>
                            <div className={styles.statsItemDivider} />

                            <div
                              className={cn(styles.statsItem, styles.statsItemLink)}
                              onClick={() => setIsUsersListPopupOpened(true)}
                            >
                              <i
                                className={`${styles.statsItemIcon} fa fa-users`}
                                aria-hidden='true'
                              />
                              <div className={styles.statsItemTitle}>
                                <span className={styles.statsItemCount}>
                                  {groupInfo.memberCount}
                                </span>
                                {t('GroupPanel.Members')}
                              </div>
                            </div>
                          </>
                        )}
                      {!groupInfo.globalPublicGroup &&
                        groupId !== 2093 &&
                        groupId !== 2105 &&
                        groupId !== 1175786483 &&
                        !isSafeToConnectType &&
                        (awoMembers.length > 0 || groupInfo.defaultGroup) &&
                        isAWO && !isPublicUser(user) && memberOptionVisible &&
                        (userInfo.administrationAccess || groupInfo.groupAdminStatus) ? (
                        <>
                          <div className={styles.statsItemDivider} />
                          <div
                            className={cn(styles.statsItem, styles.statsItemLink)}
                            onClick={() => setIsAWOUsersListPopupOpened(true)}
                          >
                            <i
                              className={`${styles.statsItemIcon} fa fa-child`}
                              aria-hidden='true'
                            />
                            <div className={styles.statsItemTitle}>
                              <span className={styles.statsItemCount}>{awoMembers.length}</span>
                              {t('GroupPanel.Awo members')}
                            </div>
                          </div>
                        </>
                      ) : null}
                      {!groupInfo.globalPublicGroup &&
                        groupId !== 2093 &&
                        groupId !== 2105 &&
                        groupId !== 1175786483 &&
                        !isSafeToConnectType &&
                        (groupInfo.kidsCount || groupInfo.defaultGroup) &&
                        (groupInfo.kidsCount > 0 || groupInfo.defaultGroup) &&
                        !isAWO && !window.location.hostname.toLowerCase().includes('sbmasterclass.safe2connect.org') && (userInfo.administrationAccess || userInfo.employee) ? (
                        <>
                          <div className={styles.statsItemDivider} />
                          <div
                            className={cn(styles.statsItem, styles.statsItemLink)}
                            onClick={() => setIsKidsListPopupOpened(true)}
                          >
                            <i
                              className={`${styles.statsItemIcon} fa fa-child`}
                              aria-hidden='true'
                            />
                            <div className={styles.statsItemTitle}>
                              <span className={styles.statsItemCount}>
                                {groupInfo.kidsCount && groupInfo.kidsCount > 0
                                  ? groupInfo.kidsCount
                                  : ''
                                }
                              </span>
                              {t('GroupPanel.Kids')}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <div className={styles.noInformationAttention}>
                      <div>{t('GroupPanel.You will be able to see details')}</div>
                      {groupInfo &&
                        groupInfo.groupJoinPolicy &&
                        groupInfo.groupJoinPolicy.policyCode &&
                        groupInfo.groupJoinPolicy.policyCode === 'open' && (
                          <div style={{ marginLeft: '15px' }}>
                            <Button
                              type='primary'
                              onClick={() => joinPublicGroupAPI(groupInfo.groupId)}
                            >
                              <i className='fa fa-sign-in' /> {t('GroupPanel.Join')}
                            </Button>
                          </div>
                        )}
                      {groupInfo &&
                        groupInfo.groupJoinPolicy &&
                        groupInfo.groupJoinPolicy.policyCode &&
                        groupInfo.groupJoinPolicy.policyCode === 'inviterequest' && (
                          <div style={{ marginLeft: '15px' }}>
                            <Button
                              type='primary'
                              onClick={handleSendMembershipRequest}
                            >
                              <i className='fa fa-sign-in' /> {t('GroupPanel.Request membership')}
                            </Button>
                          </div>
                        )}
                    </div>
                  )}

                  <div className={styles.buttons}>
                    {showCounts && (
                      <Button type='primary' onClick={() => setShowGroupInfo(true)}>
                        <i className='fa fa-info' /> {t('GroupPanel.Info')}
                      </Button>
                    )}
                    {groupInfo.inviteMemberStatus && (
                      <Button
                        type='primary'
                        onClick={() => setIsUserInvitationVisible(true)}
                        className={styles.buttonsMarginLeft}
                      >
                        <i className='fa fa-plus' /> {t('GroupPanel.Invite')}
                      </Button>
                    )}
                    {!isPublicUser(user) && currentDropdownOptions.length > 0 && (
                      <DropdownWrapper
                        dropdownOptions={currentDropdownOptions}
                        closeOnClick
                        theme='light'
                        render={dropdown => (
                          <Button
                            isActive={isOptionsDropdownOpened}
                            className={styles.buttonsMarginLeft}
                          >
                            <i className='fa fa-cog' />
                            <TextCaret />
                            {dropdown}
                          </Button>
                        )}
                      />
                    )}
                    {showJoinButton &&
                      !groupInfo.showCancelMembership &&
                      !groupInfo.inviteMemberStatus && (
                        <JoinGroupButton groupJoinPolicyId={groupInfo.groupJoinPolicy.id} />
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Row>
      <InviteUsersPopup
        isOpened={isUserInvitationVisible}
        isInvitation='true'
        value={data}
        setErrors={setErrors}
        groupId={groupInfo.groupId}
        next={inviteUsers}
        onDataChange={setData}
        closePopup={closeInvitation}
        errors={errors}
        isSuperAdmin={userInfo.superAdminStatus}
      />

      <GroupInfoPopup
        isPopupOpen={showGroupInfo}
        setIsPopupOpen={setShowGroupInfo}
        activeKita={activeKita}
        groupInfo={groupInfo}
      />

      {groupInfo.memberCount !== 0 && (
        <GroupUsersListPopup
          isOpened={isUsersListPopupOpened}
          closePopup={() => setIsUsersListPopupOpened(false)}
          title={<Trans i18nKey='Post.Members' />}
          group={groupInfo.groupId}
          isAdmin={groupInfo.groupAdminStatus || user.administrationAccess || user.employee}
        />
      )}

      {awoMembers.length > 0 && isAWO && (
        <GroupAWOUsersListPopup
          isOpened={isAWOUsersListPopupOpened}
          closePopup={() => setIsAWOUsersListPopupOpened(false)}
          title={t('GroupPanel.Awo members')}
          group={groupInfo.groupId}
          isAdmin={groupInfo.groupAdminStatus || user.administrationAccess || user.employee}
          members={awoMembers}
        />
      )}

      {(groupInfo.kidsCount > 0 || groupInfo.defaultGroup) && isKidsListPopupOpened === true && (
        <GroupKidsListPopup
          isOpened={isKidsListPopupOpened}
          closePopup={() => setIsKidsListPopupOpened(false)}
          title={t('GroupPanel.Kids')}
          groupId={groupInfo.groupId}
          groupAdminStatus={groupInfo.groupAdminStatus}
          groupModules={groupInfo.groupModuleList}
          userInfo={userInfo}
        />
      )}
    </Col>
  );
}

export function JoinGroupButton({ groupJoinPolicyId }) {
  const { t } = useTranslation();
  if (groupJoinPolicyId === 2) {
    return <Button type='primary'>{t('GroupPanel.Request Membership')}</Button>;
  } else if (groupJoinPolicyId === 3) {
    return <Button type='primary'>{t('GroupPanel.Become member')}</Button>;
  }

  return null;
}

GroupPanel.defaultProps = {
  groupInfo: {
    userList: [],
  },
  showJoinButton: false,
};

const mapStateToProps = state => ({
  userInfo: state.userReducer,
});

const mapDispatchToProps = {
  inviteUsersAPI,
  showBottomNotificationFromProps: showBottomNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupPanel);
