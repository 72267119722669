import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';

import { autoCheckoutButtonAllKids } from 'library/api/kids';

export default function KidAutoCheckout() {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);

  return (<>
    <h3>Checkout all kids</h3>
    <br />
    <br />
    <Button
      onClick={() => {
        setConfirmationPopupOpen(true);
      }}
    >
      {t('GobalTools.Checkout all kids')}
    </Button>
    {confirmationPopupOpen &&
      <Popup
        isOpened={confirmationPopupOpen}
        closePopup={() => {
          setConfirmationPopupOpen(false);
        }}
        header={t('GlobalTools.Checkout all kids')}
        footer={
          <div>
            {!isLoading ? (<>
              <Button
                onClick={() => {
                  setConfirmationPopupOpen(false)
                }}
              >
                {t('Popup.Cancel')}
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  setIsLoading(true);
                  autoCheckoutButtonAllKids().then(data => {

                  }).finally(() => {
                    setIsLoading(false);
                    setConfirmationPopupOpen(false);
                  });

                }

                }
                type='primary'
              >
                {t('GobalTools.Confirm')}
              </Button></>
            ) : <Loader />}
          </div>
        }
      />
    }
    <br />
    <br />
    <hr />
  </>);
}