import React from 'react';
import styles from './parentsFoodOrderMealItem.module.scss';
import ParentsFoodItem from './ParentsFoodItem';

export default function ParentsFoodOrderMealItem({
  id,
  day,
  date,
  foods,
  inactive,
  onToggleOrdered,
  config,
  showBottomNotification,
  checkNumberOfMealsAreNotActive,
  allowedNumberOfOrders,
}) {

  return (
    <div className={styles.mealItem}>
      <div className={styles.mealItemHeader}>
        <strong>
          {day} - {date}
        </strong>
      </div>
      <div className={styles.mealItemBody}>
        {!inactive &&
          foods.map(food => (
            <>
              <ParentsFoodItem
                id={food.id}
                name={food.name}
                description={food.description}
                price={food.price}
                ordered={food.ordered}
                manualPayed={food.manualPayed}
                mealId={id}
                onToggleOrdered={onToggleOrdered}
                config={config}
                date={date}
                showBottomNotification={showBottomNotification}
                checkNumberOfMealsAreNotActive={checkNumberOfMealsAreNotActive}
                allowedNumberOfOrders={allowedNumberOfOrders}
              />
            </>
          ))}
      </div>
    </div>
  );
}
