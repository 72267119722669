import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import Loader from 'library/common/commonComponents/Loader';
import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './usersFrameImportPopup.module.scss';
import { importMember } from 'library/api/user';

export default function MmeberInport({ showBottomNotification }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [dontSendInvitationMail, setDontSendInvitationMail] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const uploadFile = files => {
    const data = new FormData();
    data.append('file', files[0]);

    setIsLoading(true);

    importMember(data, dontSendInvitationMail)
      .then(data => {
        showBottomNotification(t('BottomNotifications.Success'));
        setErrorMessages(data.data);
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <h2>{t('Administration.Member import')}</h2>
      <br />
      <div>{t('Administration.Member import description')}</div>
      <br />
      {!isLoading ? (
        <>
          <div className={styles.checkboxContainer}>
            <CheckBox
              className={styles.checkbox}
              name={t('Administration.Do not send Invitation Mail')}
              isChecked={dontSendInvitationMail}
              onChange={() => setDontSendInvitationMail(!dontSendInvitationMail)}
            />
          </div>
          <div>
            <UploadButton
              onChange={uploadFile}
              isMultiple={false}
              className={styles.uploadButton}
              allowedExtensions={['.xlsx']}
            >
              &nbsp;{t('Administration.Select and upload Adebis Excel file')}
            </UploadButton>
          </div>
        </>
      ) : (
        <div>
          {t('Administration.Upload is in progress, please wait until it finished')}
          <br />
          <Loader />
        </div>
      )}
      <br />
      {errorMessages.length > 0 && (
        <>
          {t('Administration.Error messages')}
          <br />
          {errorMessages.map(data => {
            return <div key={data}>{data}</div>;
          })}
        </>
      )}
    </>
  );
}
