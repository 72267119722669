import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import styles from './select.module.scss';

export default function Select({
  selected,
  onSelect,
  options,
  className,
  openedClassName,
  disabled,
  placeholder,
  error,
  isNoTranslated,
  tabIndex,
  searchTextAsOption,
  initialSearchText,
}) {
  const [opened, setOpened] = useState(false);
  const [searchText, setSearchText] = useState(selected == null ? initialSearchText || '' : '');
  const [isSearching, setIsSearching] = useState(false);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);

  const toggleOpened = () => {
    if (opened && !searchTextAsOption) {
      setSearchText(selected ? selected.label : '');
      setIsSearching(false);
    }
    setOpened(!opened);
  };

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => {
      setOpened(false);
      if (!searchTextAsOption) {
        setSearchText(selected ? selected.label : '');
      }
      setIsSearching(false);
    },
  });

  useEffect(() => {
    setSearchText(selected && selected.label ? selected.label : '');
    setIsSearching(false);
  }, [selected]);

  useEffect(() => {
    if (selected == null) {
      setSearchText(initialSearchText || '');
    }
  }, [initialSearchText]);

  return (
    <div
      className={cn(
        styles.selectContainer,
        opened && styles.openedContainer,
        className,
        disabled && styles.selectContainer_disabled,
        error && styles.error,
        { notranslate: isNoTranslated },
      )}
      ref={outsideEventContainerRef}
    >
      <input
        type='text'
        className={cn(styles.searchInput, disabled && styles.selectContainer_disabled)}
        value={searchText}
        tabIndex={tabIndex || 0}
        onChange={e => {
          setSearchText(e.target.value);
          setIsSearching(true);
          if (searchTextAsOption) {
            onSelect({ value: e.target.value, label: e.target.value, isUserInput: true });
          }
        }}
        placeholder={currentPlaceholder}
        onFocus={() => {
          if (!isSearching && !searchTextAsOption) {
            setSearchText('');
            setCurrentPlaceholder(selected ? selected.label : placeholder);
          }
          if (!opened && !disabled) {
            setIsSearching(true);
            setOpened(true);
          }
        }}
        disabled={disabled}
      />
      {((options != null && options.length > 0) || !searchTextAsOption) && (
        <div className={styles.caretContainer} onClick={!disabled ? toggleOpened : () => {}}>
          <i className={opened ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
        </div>
      )}

      <div
        className={cn(
          styles.selectOptionsContainer,
          opened && styles.opened,
          opened && openedClassName,
        )}
      >
        {options &&
          options
            .filter(
              option =>
                !isSearching ||
                !searchText ||
                (option &&
                  option.label &&
                  option.label.toLowerCase().includes(searchText.toLowerCase())),
            )
            .map((option, i) => (
              <div
                key={`${option.value}${i + 1}`}
                className={styles.selectOption}
                onClick={() => {
                  setOpened(false);
                  setSearchText(option ? option.label : '');
                  onSelect(option);
                }}
              >
                {option.label}
              </div>
            ))}
      </div>
    </div>
  );
}

Select.defaultProps = {
  className: '',
  disabled: false,
  placeholder: '',
  error: false,
  options: [],
};

Select.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
};
