import React, { useRef, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import MessageWithFiles from 'library/common/commonComponents/MessageWithFiles';
import ReturnReceiptPopup from 'library/common/commonComponents/Popups/ReturnReceiptPopup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import { getUpdatedTime } from 'library/utilities/date';
import { getUserLogoUrl } from 'library/utilities/user';
import suggestionBoxIcon from 'resources/images/modules/suggestion_box_module.png';

import styles from './chatMessagesViewBody.module.scss';

export default function ChatMessagesViewBody({
  messageId,
  user,
  createdAt,
  message,
  userId,
  onEdit,
  files,
  allImages,
  chatId,
  isLastChat,
  isKitaVirtual,
  isTranslationAllowed,
  edited,
  isAnonymous,
  isSuggestionBox,
  watsonLanguage,
  currentWatsonLang,
  // deletedBy,
  deletedByOwner = false,
}) {
  if (
    watsonLanguage === currentWatsonLang ||
    ((watsonLanguage === 'null' || watsonLanguage === null) && currentWatsonLang === 'de')
  ) {
    isTranslationAllowed = false;
  }

  const { t } = useTranslation();
  const chatContent = useRef(null);
  const [isReturnReceiptPopupOpened, setIsReturnReceiptOpened] = useState(false);

  useEffect(() => {
    if (isLastChat) {
      chatContent.current.scrollIntoView();
    }
  }, []);

  // const currentUserId = userId + '';
  if (deletedByOwner && (user.id || user) === userId) {
  // if (deletedBy.includes(currentUserId)) {
    return <div ref={chatContent} />;
  }

  return (
    user && (
      <div
        className={
          (user.id || user) === userId && !isKitaVirtual ? styles.content : styles.othercontent
        }
        ref={chatContent}
      >
        <div className={styles.avatarContainer}>
          <div className={styles.avatar}>
            {isAnonymous && isSuggestionBox ? (
              <img
                className={styles.suggestionBoxIcon}
                src={suggestionBoxIcon}
                alt='suggestion box icon'
              />
            ) : (
              <ProfileAvatar
                withoutLink
                image={{
                  ...user,
                  logoUrl: getUserLogoUrl(user.id),
                }}
                id={user.id}
              />
            )}
          </div>
        </div>
        <div className={styles.messageContainer}>
          <div className={styles.header}>
            <div className={styles.userInfo}>
              {!isAnonymous ? (
                <>
                  {isSuggestionBox && <strong>{t('SuggestionBox.title') + ' '}</strong>}
                  {`${user.firstName || 'Test'} ${user.lastName || 'Test'}`}
                </>
              ) : (
                <strong>{t('SuggestionBox.title')}</strong>
              )}
            </div>
            <div title={getUpdatedTime(createdAt, user.langCode)} className={styles.date}>
              {format(new Date(createdAt), 'dd.MM.yyyy - HH:mm')}
            </div>
            <div
              className={styles.readReceiptButton}
              onClick={() => {
                setIsReturnReceiptOpened(!isReturnReceiptPopupOpened);
              }}
            >
              <i className='fa fa-check' />
            </div>
          </div>
          <MessageWithFiles
            messageEdited={edited}
            text={message}
            files={files || []}
            allImages={allImages}
            entityId={messageId}
            entityType='0'
            isTranslationAllowed={isTranslationAllowed}
            isChatMessages={true}
          />
        </div>

        {(user.id || user) === userId && !isKitaVirtual && (
          <>
            <div className={styles.editButton} onClick={() => onEdit(chatId, messageId)}>
              <i className='fa fa-trash' />
            </div>
            {isReturnReceiptPopupOpened && (
              <ReturnReceiptPopup
                isOpened={isReturnReceiptPopupOpened}
                closePopup={() => setIsReturnReceiptOpened(false)}
                title={t('Post.Return receipt')}
                chatMessageId={messageId}
              />
            )}
          </>
        )}
      </div>
    )
  );
}
