import React from 'react';
import bgwLogo from 'resources/images/bgw/bgwLogo_png.png';
import ChecklistTable from './Tables/ChecklistTable/ChecklistTable';

import checklistStyle from './checklist.module.scss';
import InputTable from './Tables/InputTable/InputTable';

export default function ChecklistContainer() {
  return (
    <div>
      <div className={checklistStyle.title}>
        To Do – Liste:
        <img
          src={bgwLogo}
          style={{ float: 'right', marginTop: '-4px', height: '50px' }}
          alt='Bgw'
        />
      </div>

      <InputTable />

      <div className={checklistStyle.title} style={{ marginTop: '20px' }}>
        Checkliste/ To Do
        <img
          src={bgwLogo}
          style={{ float: 'right', marginTop: '-4px', height: '50px' }}
          alt='Bgw'
        />
      </div>

      <ChecklistTable />

      <div className={checklistStyle.disclaimerContent}>
        <div className={checklistStyle.disclaimerTitle}>Versicherungsschutz</div>
        <div className={checklistStyle.disclaimerText}>
          Generell gilt: Alle Akteure sind beim regulären Aufenthalt in der KiTa und bei Aktivitäten
          der KiTa versichert. Für die Beschäftigten bei privaten und wohltätigen Trägern ist die
          BGW der zuständige Unfallversicherungsträger, für die Beschäftigten in kommunalen
          Einrichtungen und für die Kinder ist immer die Zuständigkeit der jeweiligen Unfallkasse
          oder des Gemeindeunfallversicherungsverbandes gegeben, solange Gemeinnützigkeit gegeben
          ist. Für gewerbsmäßige Einrichtungen ist die BGW zuständig (Beschäftigte und Kinder), für
          rechtlich unselbständige Betriebskindergärten ist der Unfallversicherungsträger des
          Hauptunternehmens zuständig. Unentgeltlich mithelfende Eltern (z.B. Ausflugsbegleitung,
          Aufsicht, Sommerfest, Renovierungsarbeiten) sind ebenfalls versichert. Es kommt nicht
          darauf an, ob Eltern bei dieser Tätigkeit mitgliedschaftliche Verpflichtungen erfüllen.
        </div>
      </div>

      <div>
        Quelle: <a href='https://www.bgw-online.de/'>BGW</a>, Veranschaulichungsbilder: ©BGW/Michael Hüter
      </div>
    </div>
  );
}
