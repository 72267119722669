import React, { useMemo } from 'react';
import cn from 'classnames';

import { isImage, getFileIconByName, previewOrDownloadFile } from 'library/utilities/files';
import { SortableContainer, SortableItem } from '../Sortable';

import styles from './filePrewiev.module.scss';

export default function FilePrewiev({ newFiles, onDelete, download, onOpenGallery, onFilesSort }) {
  const ids = useMemo(() => newFiles.map(file => file.fileId), [newFiles]);
  const isSortable = !!onFilesSort;

  return (
    <SortableContainer
      ids={ids}
      isSortable={isSortable}
      onSortComplete={newIds => {
        const updatedFilesOrder = newIds.map(id => newFiles.find(file => file.fileId === id));
        if (onFilesSort) {
          onFilesSort(updatedFilesOrder);
        }
      }}
    >
      {newFiles.length > 0 && (
        <ul className={styles.demoPicturesContainer}>
          {newFiles.map(file => (
            <SortableItem
              id={file.fileId}
              className={styles.demoPicturesContainerList}
              key={file.fileId + (file.lastModified || file.id)}
            >
              {
                isSortable && (
                  <span className={styles.dragHandle}>
                    <i className='fa fa-ellipsis-v' />
                  </span>
                )
              }

              {isImage(file.fileId) ? (
                <i className='fa fa-file-image-o' />
              ) : (
                <i className={cn('fa', getFileIconByName(file.fileId))} />
              )}

              <div className={styles.demoPicturesContainerListPrewiev}>
                {download ? (
                  <div onPointerDown={(e) => e.stopPropagation()} className={styles.fileLink} onClick={() => previewOrDownloadFile(file)}>
                    {file.fileId}
                  </div>
                ) : (
                  <div onPointerDown={(e) => e.stopPropagation()} onClick={() => onOpenGallery && onOpenGallery(file)}>{file.fileId}</div>
                )}
                {isImage(file.fileId) && (
                  <div className={styles.prewievImageWrapper}>
                    <img src={file.path} alt={file.fileId} />
                  </div>
                )}
              </div>
              {onDelete && (
                <div className={styles.delete} onPointerDown={(e) => e.stopPropagation()} onClick={() => onDelete(file)}>
                  <i className='fa fa-trash-o' />
                </div>
              )}
            </SortableItem>
          ))}
        </ul>
      )}
    </SortableContainer>
  );
}
