import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Label from 'library/common/commonComponents/Label';
import Tabs from 'library/common/commonComponents/Tabs';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import Input from 'library/common/commonComponents/Inputs/Input';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import MyExportsContainer from './MyExportsContainer';

import styles from './calendarExportPopup.module.scss';

export default function CalendarExportPopup({
  exports,
  deleteExport,
  saveExport,
  filters,
  isOpened,
  closePopup,
  showBottomNotification,
}) {
  const { t } = useTranslation();
  const [calendarExportName, setCalendarExportName] = useState('');
  const [exportFileNameError, setExportFileNameError] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  const getFilterName = name => {
    switch (name) {
      case 'attending':
        return 'isAttendedDownload';
      case 'myEvents':
        return 'isMyEventsDownload';
      case 'myProfile':
        return 'isMyProfileDownload';
      case 'myGroups':
        return 'isMyGroupsDownload';
      case 'myFriends':
        return 'isMyFriendsDownload';
      default:
        return '';
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const submitExport = async () => {
    const selectedFilters = filters.filter(item => item.checked);
    const requestFilters = selectedFilters.map(item => getFilterName(item.name));
    // eslint-disable-next-line no-sequences
    const requestFields = requestFilters.reduce((a, b) => ((a[b] = true), a), {});
    if (Object.values(requestFields).length > 0) {
      requestFields.name = calendarExportName;
    }

    if (calendarExportName.length === 0) {
      return setExportFileNameError(
        t('Administration.UserSection.Calendar export name can not be blank'),
      );
    }

    setIsLoading(true);

    try {
      await saveExport(
        Object.values(requestFields).length > 0
          ? requestFields
          : {
              name: calendarExportName,
            },
      );
      setCalendarExportName('');
      showBottomNotification(t('BottomNotifications.Saved'));
    } catch (ex) {
      setExportFileNameError(ex.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const tabs = [
    {
      title: t('Administration.UserSection.New export'),
      component: (
        <div>
          <div className={styles.inputGroup}>
            <Label type='input'>
              {t('Administration.UserSection.Calendar export name')}{' '}
              <span className={styles.required}>*</span>
            </Label>
            <div className={styles.fileNameWrap}>
              <Input
                value={calendarExportName}
                onChange={e => setCalendarExportName(e.target.value)}
                placeholder=''
              />
            </div>
            {exportFileNameError && <InputErrorMsg errorMsg={exportFileNameError} />}
          </div>
          <div className={styles.buttonsContainer}>
            <div>
              <ButtonWithLoader onClick={submitExport} type='primary' isLoading={isLoading}>
                {t('Administration.UserSection.Generate export url')}
              </ButtonWithLoader>
            </div>
          </div>
        </div>
      ),
      path: 'new-export',
    },
    {
      title: t('Administration.UserSection.My exports'),
      component: <MyExportsContainer data={exports} deleteExportHandler={deleteExport} />,
      path: 'my-exports',
    },
  ];

  return (
    <Popup
      size='large'
      isOpened={isOpened}
      closePopup={closePopup}
      header={<strong>{t('Administration.UserSection.Calendar export')}</strong>}
    >
      <div className={styles.wrapper}>
        <Tabs
          tabs={tabs}
          currentTab={tabs[currentTab].path}
          onChange={(_, index) => setCurrentTab(index)}
        />
      </div>
    </Popup>
  );
}
