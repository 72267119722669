import { useLayoutEffect, useRef } from 'react';

export function useOutsideEvent({
  click,
  mouseDown,
  touchEnd,
  touchStart,
  handler,
  ownerDocument = document,
}) {
  const outsideContainerRef = useRef(null);

  useLayoutEffect(() => {
    function handleOutsideEvent(e) {
      if (
        handler !== undefined &&
        outsideContainerRef.current !== null &&
        e.target instanceof Node
      ) {
        if (!outsideContainerRef.current.contains(e.target)) {
          handler(e);
        }
      }
    }

    if (click) {
      ownerDocument.addEventListener('click', handleOutsideEvent, false);
    }
    if (mouseDown) {
      ownerDocument.addEventListener('mousedown', handleOutsideEvent, false);
    }
    if (touchEnd) {
      ownerDocument.addEventListener('touchend', handleOutsideEvent, false);
    }
    if (touchStart) {
      ownerDocument.addEventListener('touchstart', handleOutsideEvent, false);
    }

    return () => {
      ownerDocument.removeEventListener('click', handleOutsideEvent, false);
      ownerDocument.removeEventListener('mousedown', handleOutsideEvent, false);
      ownerDocument.removeEventListener('touchend', handleOutsideEvent, false);
      ownerDocument.removeEventListener('touchstart', handleOutsideEvent, false);
    };
  }, [click, mouseDown, touchEnd, touchStart, handler, ownerDocument]);

  return outsideContainerRef;
}
