import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Tooltip from 'library/common/commonComponents/Tooltip';
import UsersListPopup from 'library/common/commonComponents/Popups/UsersListPopup';

import styles from './dislikeButtonCounter.module.scss';

export default function DislikeButtonCounter({ dislikes, user, withDislikesModal, group }) {
  const [isOpened, setIsOpened] = useState(false);

  const { t } = useTranslation();
  const youDislikeText = t('Post.You dislike this');

  const dislikesCountIsVisible = dislikes.length > 0;
  function isGlobalPublicGroup() {
    if (group && group.globalPublicGroup && group.globalPublicGroup === true) {
      return true;
    }
    return false;
  }

  if (!dislikesCountIsVisible) {
    return null;
  }

  const maximumCountBeforeOthersString = 2;

  const userName = `${user.firstName} ${user.lastName}`;

  let tooltipText = null;
  if (dislikes.length <= maximumCountBeforeOthersString) {
    tooltipText = dislikes.map(dislike => (
      <React.Fragment key={dislike.id}>
        {getUserName(dislike, userName, youDislikeText)} <br />
      </React.Fragment>
    ));
  } else {
    tooltipText = (
      <>
        {getUserName(dislikes[dislikes.length - 1], userName, youDislikeText)}
        <br />
        and others
      </>
    );
  }

  return (
    <>
      {isOpened && (
        <UsersListPopup
          isOpened={isOpened}
          title={<Trans i18nKey='Post.Users who like this' />}
          closePopup={() => setIsOpened(false)}
          users={dislikes}
        />
      )}
      {dislikesCountIsVisible && (
        <div
          className={styles.likeTooltip}
          //onClick={() => (withDislikesModal && !isGlobalPublicGroup() ? setIsOpened(true) : {})}
        >
          ({dislikes.length})
        </div>
      )}
    </>
  );
}

DislikeButtonCounter.defaultProps = {
  dislikes: [],
};

const getUserName = ({ firstName, lastName, name: fullName }, userName, youDislikeText) => {
  const name = fullName || `${firstName} ${lastName}`;
  return name === userName ? youDislikeText : name;
};
