import React, { useState, useEffect, useRef } from 'react';
import Button from 'library/common/commonComponents/Buttons/Button';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { logout } from 'library/api/logout';
import Input from 'library/common/commonComponents/Inputs/Input';
import CheckBox from 'library/common/commonComponents/Checkbox';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import { postCaritasConsent, getCaritasConsent } from 'library/api/user';
import SignatureCanvas from 'react-signature-canvas';

export default function CaritasConsentModule({ userInfo, type, isAdminEditing, activeKita }) {
  function handleTextStateChange(newValue, property) {
    setFormData(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function isValidInput() {
    if (formData.signaturePlace === '' || sigCanvas.current.toData().length === 0) {
      return false;
    }
    return true;
  }

  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    parentNames: '',
    workCts: '',
    address: '',
    phoneMail: '',
    childFullName: '',
    childBirthday: new Date().toLocaleDateString('en-CA'),
    checkBox1Checked: false,
    checkBox2Checked: false,
    checkBox3Checked: false,
    allergies: '',
    signatureDate: new Date().toLocaleDateString('en-CA'),
  });

  useEffect(() => {
    if (type === 'profileFrame') {
      getCaritasConsent(isAdminEditing ? userInfo.id : null).then(data => {
        if (data.data !== null) {
          sigCanvas.current.fromDataURL(data.data.signature);
          let signatureDate = null;
          if (data.data.signatureDate && data.data.signatureDate.dayOfMonth) {
            const tempSignatureDate = new Date(
              data.data.signatureDate.year,
              data.data.signatureDate.monthValue,
              data.data.signatureDate.dayOfMonth,
            );
            signatureDate = tempSignatureDate.toLocaleDateString('en-CA', {
              // you can use undefined as first argument
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
          }
          let childBirthday = null;
          if (data.data.childBirthday && data.data.childBirthday.dayOfMonth) {
            const tempChildBirthday = new Date(
              data.data.childBirthday.year,
              data.data.childBirthday.monthValue,
              data.data.childBirthday.dayOfMonth,
            );
            childBirthday = tempChildBirthday.toLocaleDateString('en-CA', {
              // you can use undefined as first argument
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
          }

          setFormData(prev => {
            return {
              parentNames: data.data.parentNames,
              workCts: data.data.workCts,
              address: data.data.address,
              phoneMail: data.data.phoneMail,
              childFullName: data.data.childFullName,
              childBirthday: childBirthday,
              checkBox1Checked: data.data.checkBox1Checked,
              checkBox2Checked: data.data.checkBox2Checked,
              checkBox3Checked: data.data.checkBox3Checked,
              allergies: data.data.allergies,
              signatureDate: signatureDate,
            };
          });
        }
      });
      setIsLoading(false);
    }
  }, []);

  const [errorMessage, setErrorMessage] = useState('');

  const sigCanvas = useRef();

  return (
    <>
      <center>
        <h3>Anmeldebogen</h3>
      </center>
      <center>
        <h3>Wochenend-Betreuung Kita Rastpfuhl</h3>
      </center>
      <br />
      <h3>Angaben zur/m Antragsteller/in</h3>
      <table>
        <tr>
          <td>Name der Erziehungsberechtigen:</td>
          <td>
            <Input
              type='text'
              value={formData.parentNames}
              onChange={e => handleTextStateChange(e.target.value, 'parentNames')}
              disabled={type === 'profileFrame' ? true : false}
            />
          </td>
        </tr>
        <tr>
          <td>Tätigkeit im Hause der cts:</td>
          <td>
            <Input
              type='text'
              value={formData.workCts}
              onChange={e => handleTextStateChange(e.target.value, 'workCts')}
              disabled={type === 'profileFrame' ? true : false}
            />
          </td>
        </tr>
        <tr>
          <td>Adresse:</td>
          <td>
            <Input
              type='text'
              value={formData.address}
              onChange={e => handleTextStateChange(e.target.value, 'address')}
              disabled={type === 'profileFrame' ? true : false}
            />
          </td>
        </tr>
        <tr>
          <td>Telefon/E-Mail:</td>
          <td>
            <Input
              type='text'
              value={formData.phoneMail}
              onChange={e => handleTextStateChange(e.target.value, 'phoneMail')}
              disabled={type === 'profileFrame' ? true : false}
            />
          </td>
        </tr>
      </table>
      <br />
      <h3>Angaben zum Kind</h3>
      <table>
        <tr>
          <td>Name, Vorname des Kindes:</td>
          <td>
            <Input
              type='text'
              value={formData.childFullName}
              onChange={e => handleTextStateChange(e.target.value, 'childFullName')}
              disabled={type === 'profileFrame' ? true : false}
            />
          </td>
        </tr>
        <tr>
          <td>Geb. am:</td>
          <td>
            <Input
              type='date'
              value={formData.childBirthday}
              onChange={e => handleTextStateChange(e.target.value, 'childBirthday')}
              disabled={type === 'profileFrame' ? true : false}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <CheckBox
              name='m'
              isChecked={formData.checkBox1Checked}
              onChange={() => {
                setFormData(prev => {
                  return { ...prev, checkBox1Checked: !prev.checkBox1Checked };
                });
              }}
              isDisabled={type === 'profileFrame' ? true : false}
            />
            <CheckBox
              name='w'
              isChecked={formData.checkBox2Checked}
              onChange={() => {
                setFormData(prev => {
                  return { ...prev, checkBox2Checked: !prev.checkBox2Checked };
                });
              }}
              isDisabled={type === 'profileFrame' ? true : false}
            />
            <CheckBox
              name='d'
              isChecked={formData.checkBox3Checked}
              onChange={() => {
                setFormData(prev => {
                  return { ...prev, checkBox3Checked: !prev.checkBox3Checked };
                });
              }}
              isDisabled={type === 'profileFrame' ? true : false}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            Besonderheiten, die zu beachten sind (Allergien, Unverträglichkeiten, usw.):
            <br />
            <TextArea
              value={formData.allergies}
              onChange={e => handleTextStateChange(e.target.value, 'allergies')}
              disabled={type === 'profileFrame' ? true : false}
            />
          </td>
        </tr>
      </table>
      <br />
      <h3>Zeitrahmen</h3>
      <table>
        <tr>
          <td>Block 1</td>
          <td>Block 2</td>
          <td>Block 3</td>
          <td>Block 4</td>
        </tr>
        <tr>
          <td>Samstag</td>
          <td>Samstag</td>
          <td>Sonntag</td>
          <td>Sonntag</td>
        </tr>
        <tr>
          <td>05:45 – 14:15 Uhr</td>
          <td>13:45 – 22:15 Uhr</td>
          <td>05:45 – 14:15 Uhr</td>
          <td>13:45 – 22:15 Uhr</td>
        </tr>
      </table>
      <br />
      <br />
      <h3>Eingewöhnung</h3>
      Um eine gute Begleitung des Kindes in seiner individuellen Bindungsbeziehung zu gewährleisten,
      ist eine Eingewöhnungsphase einzuplanen. Je nach Alter und Entwicklungsstand der Kinder
      sollten diese mindestens einmal vor Beginn der Wochenendbetreuung die Räumlichkeiten in
      Begleitung ihrer Eltern kennenlernen. In dieser Zeit werden die Kinder erst einmal gemeinsam
      mit Mutter oder Vater im Beisein einer pädagogischen Fachkraft die Räume erkunden. Im zweiten
      Schritt übernimmt eine Fachkraft die Betreuung des Kindes. Die Eltern nehmen am Rande des
      Geschehens Platz und bleiben in der Beobachterrolle. Fühlt das Kind sich sicher, kann es
      zukünftig alleine in der Betreuung bleiben.
      <br />
      <br />
      <h3>Kleidung</h3>
      Ersatzkleidung und Hausschuhe sind für den Betreuungszeitraum bei den Wochenend-Kids bereit zu
      stellen. Den Kindern steht während der Betreuungszeit vor Ort eine Garderobe zur Verfügung, in
      welcher Schuhe, Jacke und Wechselkleider aufbewahrt werden können. Die
      Personensorgeberechtigten sind verpflichtet, das Kind witterungsabhängig angemessen zu kleiden
      und bei Abholen alle Kleidungsstücke des Kindes aus der Garderobe mitzunehmen.
      <br />
      <br />
      <h3>Schlafen</h3>
      Die Kinder haben die Möglichkeit mittags oder abends zu schlafen. Die zu benötigende
      Bettwäsche ist von zu Hause mitzubringen.
      <br />
      <br />
      <h3>Wickeln</h3>
      Die Personensorgeberechtigten sind verpflichtet, für die Zeit der Betreuung ausreichend
      Pampers, Pflegetücher und sonstige Hygieneartikel (Salben etc.) zur Verfügung zu stellen.
      <br />
      <br />
      <h3>Regelung in Krankheitsfällen</h3>
      Ein Kind mit akuten Krankheiten wie z.B. Erbrechen, Durchfall, Ausschlag, Halsschmerzen usw.
      kann die Wochenendbetreuung Wochenend-Kids nicht besuchen. Stellen die Fachkräfte der
      Kindertagesstätte diese oder andere Krankheitsanzeichen fest, benachrichtigen sie sofort die
      Eltern/Personensorgeberechtigten zur Abholung der Kinder. Die Eltern müssen jeder Zeit
      erreichbar sein. Medikamente können nicht verabreicht werden.
      <br />
      <br />
      <h3>Masernimpfschutz</h3>
      Das Gesetz sieht vor, dass alle Kinder ab dem vollendeten ersten Lebensjahr beim Eintritt in
      die Schule oder den Kindergarten die von der Ständigen Impfkommission empfohlenen
      Masern-Impfungen vorweisen müssen. Dies gilt auch bei der Betreuung durch
      Kindertagespflegepersonen. Dem entsprechend muss für das zu betreuende Kind der Nachweis über
      die Masernimpfung erfolgen.
      <br />
      <br />
      <h3>Datenschutz</h3>
      Die personenbezogenen Daten des Kindes werden nur für Zwecke des Abschlusses und der
      vertragsmäßigen Durchführung und Abwicklung des Betreuungsvertrages erhoben, verarbeitet und
      genutzt. Personenbezogene Daten werden nicht an Dritte weitergegeben.
      <br />
      <br />
      Die Daten werden nur so lange gespeichert, bis der Zweck erfüllt ist, zu dem sie erhoben
      wurden, oder bis ein Widerruf erfolgt ist.
      <br />
      <br />
      <table>
        <tr>Saarbrücken, den</tr>
        <tr>
          <Input
            type='date'
            value={formData.signatureDate}
            onChange={e => handleTextStateChange(e.target.value, 'signatureDate')}
            disabled={type === 'profileFrame' ? true : false}
          />
        </tr>
      </table>
      <div style={{ border: '1px solid black', width: '402px' }}>
        <SignatureCanvas
          ref={sigCanvas}
          penColor='black'
          canvasProps={{ width: 400, height: 180, className: 'sigCanvas' }}
        />
      </div>
      Unterschrift
      {typeof type === 'undefined' && (
        <i
          style={{ marginLeft: '290px', cursor: 'pointer' }}
          className='fa fa-eraser'
          onClick={() => {
            if (sigCanvas && sigCanvas.current) {
              sigCanvas.current.clear();
            }
          }}
        />
      )}
      <br />
      <br />
      {typeof type === 'undefined' && (
        <>
          <Button
            onClick={() => {
              if (isValidInput()) {
                const caritasConsentTemp = {
                  ...formData,
                  signature: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'),
                };

                postCaritasConsent(caritasConsentTemp).then(() => {
                  store.dispatch(showBottomNotification(<div>Gespeichert</div>, { isFail: false }));
                  const myTimeout = setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                });
              } else {
                setErrorMessage('Bitte füllen Sie die Unterschrift aus.');
              }
            }}
            type='primary'
          >
            Bestätigen
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              logout();
            }}
            type='secondary'
          >
            Abbrechen
          </Button>
        </>
      )}
      <br />
      <div style={{ color: 'red' }}>{errorMessage}</div>
      <br />
    </>
  );
}
