import React from 'react';
import styles from './table.module.scss';
import Pagination from '../Pagination';

function Table({ header, rows }) {
  return (
    <div>
      <table className={styles.tableContainer}>
        <thead>
          {header.map((h, i) => (
            <th key={i}>{h}</th>
          ))}
        </thead>
        <tbody>
          {rows.map((r, i) => (
            <tr key={i}>
              {r.map((c, j) => (
                <td key={j}>{c}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* TODO: add pagination for larger tables */}
      {/* <Pagination
        total={rows?.length}
        pageSize={10}
        current={1}
        onChange={page => console.log(page)}
      /> */}
    </div>
  );
}

export default Table;
