import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { slideDown, slideUp } from 'slide-anim';
import Label from 'library/common/commonComponents/Label';
import SelectTagInput from 'library/common/commonComponents/Inputs/TagInput/SelectTagInput';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Loader from 'library/common/commonComponents/Loader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Card from 'library/common/commonComponents/Card';
import SelectSpace from 'library/common/commonComponents/SelectSpace';
import { getSpaces } from 'modules/AccountSettings/accountSettingsFrames/EmailSummariesFrame';
import CheckBox from 'library/common/commonComponents/Checkbox';
import styles from './searchHeader.module.scss';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

export default function SearchHeader({
  searchValue,
  setSearchValue,
  onSubmit,
  selectedGroups,
  setSelectedGroups,
  calendarStateParent,
  postStateParent,
  usersInfo,
  setFromDate,
  setToDate,
  user,
  onDownload,
  onUpload,
  isDownloading,
  isUploading,
  searchPattern
}) {
  const { t } = useTranslation();

  const [newChatInfo, setNewChatInfo] = useState({
    groups: [],
    users: [],
    subject: '',
    message: '',
  });

  const [formFrom, setFormFrom] = useState(new Date().setFullYear(2018,0,1));
  const [formTo, setFormTo] = useState(new Date());
  const [calendarState, setCalendarState] = useState(false);
  const [postState, setPostState] = useState(false);

  useEffect(() => {
    if(searchPattern) {
      if(searchPattern.startDate) {
        setFormFrom(new Date(searchPattern.startDate));
      }
      if(searchPattern.endDate) {
        setFormTo(new Date(searchPattern.endDate));
      }
    }
  }, [searchPattern]);

  const handleAddUser = data => {
    setError(prevError => ({ ...prevError, users: false }));
    usersInfo(data.users);
    setNewChatInfo(prevChatInfo => ({ ...prevChatInfo, users: data.users }));
  };

  const handleGroupsUpdate = data => {
    setNewChatInfo(prevChatInfo => ({ ...prevChatInfo, groups: data.groups }));
  };

  const [error, setError] = useState({
    users: false,
    subject: false,
    message: false,
  });

  const [, setSelectSpace] = useState(null);
  const containerRef = useRef(null);

  const setCalendarStateForParent = () => {
    calendarStateParent(!calendarState);
    setCalendarState(!calendarState);
  }

  const setPostStateForParent = () => {
    postStateParent(!postState);
    setPostState(!postState);
  }

  const setFrom = (e) => {
    setFormFrom(e);
    setFromDate(e);
  }

  const setTo = (e) => {
    setFormTo(e);
    setToDate(e);
  }

  const [isOpened, setIsOpened] = useState(false);
  const toggleContainer = () => toggleContainerHandler({ isOpened, containerRef, setIsOpened });

  return (
    <Col>
      <Row>
        <Card title={<Trans i18nKey='Search.Search' />}>
          <div className={styles.container}>
            <div className={styles.inputContainer}>
              <Input
                className={styles.input}
                placeholder={t('Search.Placeholder')}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
              <Button size='sm' className={styles.inputButton} onClick={onSubmit}>
                <Trans i18nKey='Search.Search' />
              </Button>
            </div>
            <div className={styles.uploadContainer}>
              <div className={styles.uploadBtnWrapper}>
                <ButtonWithLoader size='sm' className={styles.downloadBtn} onClick={onDownload} isLoading={isDownloading}>
                {t('Search.Download')}
                </ButtonWithLoader>
                <div className={cn(styles.upload, styles.uploadButton)}>
                  {!isUploading ? (
                    <label>
                      <i className='fa fa-cloud-upload' />
                      <input type='file' onChange={onUpload} multiple={false} accept='.txt' />
                      {t('Search.Upload')}
                    </label>
                  ): (
                    <Loader dotColor='#fff' dotSize='8px' />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.advancedText} onClick={toggleContainer}>
              <i className={cn('fa fa-caret-right', styles.advancedTextIcon)} />{' '}
              {t('Search.Advanced search settings')}
            </div>

            <div
              className={styles.advancedContainer}
              ref={containerRef}
              style={{ display: 'none' }}
            >
              <div className={styles.advancedContainerText}>
                {t('Search.Search only in certain groups')}
                <SelectSpace
                  getSpaces={getSpaces}
                  selectedGroups={selectedGroups}
                  onSpaceSelect={setSelectedGroups}
                  ref={ref => {
                    setSelectSpace(ref);
                  }}
                />
              </div>
              <div className={styles.dateWrapper}>
                <div className={styles.dateStartWrapper}>
                  <div className={styles.dateStart}>
                    <Label className={styles.label} type='input'>
                      {t('AccountSettings.AbsenceSettings.From')}
                    </Label>
                    <DatePicker
                      selected={formFrom}
                      onChange={(e) => setFrom(e)}
                      selectsStart
                      langCode={user.langCode}
                    />
                  </div>
                </div>
                <div className={styles.dateEndWrapper}>
                  <div className={styles.dateEnd}>
                    <Label className={styles.label} type='input'>
                      {t('AccountSettings.AbsenceSettings.To')}
                    </Label>
                    <DatePicker
                      selected={formTo}
                      selectsEnd
                      onChange={(e) => setTo(e)}
                      minDate={formFrom}
                      langCode={user.langCode}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.usersInput}>
                <Label type='input'>
                  {t('Search.Created By')}
                </Label>
                <SelectTagInput
                  value={newChatInfo}
                  onDataChange={handleAddUser}
                  onGroupChange={handleGroupsUpdate}
                  errors={
                    error.users
                      ? {
                        invite:
                          error.users === 'empty'
                            ? t('Chat.Recipient cannot be blank')
                            : t('Chat.You cannot send a message to yourself'),
                      }
                      : { invite: '' }
                  }
                  showLabel={false}
                  userId={user.id}
                  user={user}
                />
              </div>
              <Label type='input'>
                {t('Search.Type of content')}
              </Label>
              <CheckBox
                className={styles.checkbox}
                name={t('Search.Calendar')}
                isChecked={calendarState}
                onChange={() => setCalendarStateForParent()}
              />
              <CheckBox
                className={styles.checkbox}
                name={t('Seach.Post')}
                isChecked={postState}
                onChange={() => setPostStateForParent()}
              />
            </div>
          </div>
        </Card>
      </Row>
    </Col>
  );
}

export function toggleContainerHandler({ isOpened, containerRef, setIsOpened }) {
  if (isOpened) {
    slideUp(containerRef.current);
  } else {
    slideDown(containerRef.current);
  }
  setIsOpened(!isOpened);
}
