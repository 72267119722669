import { SET_ACTIVE_2FA, SET_FORCE_RUN_2FA } from '../commonConstants/auth2faConstants';

export const setActive2Fa = payload => dispatch => {
  dispatch({
    type: SET_ACTIVE_2FA,
    payload,
  });
};

export const setForceRun2Fa = payload => dispatch => {
  dispatch({
    type: SET_FORCE_RUN_2FA,
    payload,
  });
};
