import { batchActions } from 'redux-batched-actions';
import { createAction } from 'redux-actions';
import cryptoJS from 'crypto-js';

import axiosInstance from 'library/api/axios';
import { setAuthentication } from 'library/common/commonActions/authActions';
import Storage from 'library/utilities/storage';
import { loadUserInfo } from 'library/common/commonActions/userActions';
import { removeToken } from 'library/utilities/token';
import * as at from './authorizationConstants';

const disableButton = createAction(at.SET_BUTTON_DISABLED);
const setEmailErrorType = createAction(at.SET_EMAIL_ERROR);
const setPasswordErrorType = createAction(at.SET_PASSWORD_ERROR);
const checkFlag = createAction(at.SET_CHECK_FLAG);

export const loginService = data => dispatch => {
  const deviceId = cryptoJS.MD5(navigator.userAgent.toLowerCase());
  const loginFormData = new FormData();
  loginFormData.append('username', data.email);
  loginFormData.append('password', data.password);
  loginFormData.append('langCode', data.language);
  loginFormData.append('grant_type', 'password');
  loginFormData.append('device_id', deviceId);
  loginFormData.append('certificate', Storage.getItem('certificate'))

  dispatch(disableButton(true));
  if (Storage.getItem('rememberMe')) {
    removeToken();
  }
  return axiosInstance
    .post(at.URLS.login, loginFormData)
    .then(res => {
      switch (res.status) {
        case 200:
          dispatch(
            setAuthentication({
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
              tkval: res.data.tkval,
            }),
          );

          if (window && window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'login',
                authorization: res.data.access_token,
              }),
            );
          }

          const rememberMe = Storage.getItem('rememberMe');
          if (rememberMe === true && window.rnOnLogin) {
            window.rnOnLogin(res.data.access_token);
          }
          dispatch(loadUserInfo(res.data.access_token));
          return { status: 200 };
        default:
          return { status: res.status };
      }
    })
    .catch(err => {
      dispatch(disableButton(true));
      if (err.response && err.response.data.message) {
        return { status: 401, isPendingRegistration: true, message: err.response.data.message };
      }
      return { status: 401 };
    });
};

export const msLoginService = data => dispatch => {
  const deviceId = cryptoJS.MD5(navigator.userAgent.toLowerCase());
  const loginFormData = new FormData();
  loginFormData.append('type', 'mssso');
  loginFormData.append('langCode', data.language);
  loginFormData.append('idToken', data.idToken);
  loginFormData.append('password', '');
  loginFormData.append('grant_type', 'password');
  loginFormData.append('device_id', deviceId);

  dispatch(disableButton(true));
  if (Storage.getItem('rememberMe')) {
    removeToken();
  }
  return axiosInstance
    .post(at.URLS.login, loginFormData)
    .then(res => {
      switch (res.status) {
        case 200:
          dispatch(
            setAuthentication({
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
              tkval: res.data.tkval,
              isPublicLogin: data.isPublicLogin,
            }),
          );

          const rememberMe = Storage.getItem('rememberMe');
          if (rememberMe === true && window.rnOnLogin) {
            window.rnOnLogin(res.data.access_token);
          }

          dispatch(loadUserInfo(res.data.access_token));

          return { status: 200 };
        default:
          return { status: res.status };
      }
    })
    .catch(err => {
      dispatch(disableButton(true));
      if (err.response && err.response.data.message) {
        return { status: 401, isPendingRegistration: true, message: err.response.data.message };
      }
      return { status: 401 };
    });
};

export const resetEmailAndPasswordError = () => dispatch => {
  dispatch(batchActions([setEmailErrorType(''), setPasswordErrorType('')]));
};

export const handleCheckBoxChange = isChecked => dispatch => {
  dispatch(checkFlag(isChecked));
};

export const registrationService = (email, langCode) => dispatch => {
  dispatch(disableButton(true));
  return axiosInstance
    .put(at.URLS.verification, { email, langCode })
    .then(res => {
      switch (res.status) {
        case 200:
          return 200;
        case 208:
          return 208;
        default:
          return false;
      }
    })
    .catch(() => {
      dispatch(disableButton(true));
      return false;
    });
};

export const registrationServiceWithoutDispatch = (email, langCode) => {
  return axiosInstance
    .put(at.URLS.verification, { email, langCode })
    .then(res => {
      switch (res.status) {
        case 200:
          return 200;
        case 208:
          return 208;
        default:
          return false;
      }
    })
    .catch(() => {
      return false;
    });
};
