import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import RadioButton from 'library/common/commonComponents/RadioButton';

import styles from './setDefaultModulePopup.module.scss';

export default function SetDefaultModulePopup({ module, isOpened, closePopup, onConfirm }) {
  const { t } = useTranslation();
  const moduleName = {
    Files: <Trans i18nKey='Administration.ModulesSection.files' />,
    Calendar: <Trans i18nKey='Administration.ModulesSection.calendar' />,
    Gallery: <Trans i18nKey='Administration.ModulesSection.gallery' />,
    Survey: <Trans i18nKey='Administration.ModulesSection.surveys' />,
    Tasks: <Trans i18nKey='Administration.ModulesSection.tasks' />,
    Translations: <Trans i18nKey='Administration.ModulesSection.Dynamic translator' />,
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [groupValue, setGroupValue] = useState(module.groupModuleStatus || 'Activated');
  const [userProfileValue, setUserProfileValue] = useState(module.userModuleStatus || 'Activated');

  const handleClose = () => {
    setGroupValue('activated');
    setUserProfileValue('activated');
    closePopup();
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onConfirm(groupValue, userProfileValue);
    setIsSubmitting(false);
    setGroupValue('activated');
    setUserProfileValue('activated');
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={handleClose}
      size='small'
      header={moduleName[module.moduleName]}
      footer={
        <div className={styles.footerNavigation}>
          <ButtonWithLoader onClick={handleSubmit} type='primary' isLoading={isSubmitting}>
            {t('Confirm.Save')}
          </ButtonWithLoader>
          <Button onClick={handleClose}>{t('Confirm.Close')}</Button>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.description}>
          {t('Administration.ModulesSection.Here you can choose')}
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.groupButtonsContainer}>
            <div className={styles.title}>{t('Administration.ModulesSection.Groups')}</div>
            <div className={styles.radioButtonsContainer}>
              <RadioButton
                checked={groupValue === 'Deactivated'}
                label={t('Administration.ModulesSection.Deactivated')}
                onClick={() => setGroupValue('Deactivated')}
                className={styles.radioButton}
              />
              <RadioButton
                checked={groupValue === 'Activated'}
                label={t('Administration.ModulesSection.Activated')}
                onClick={() => setGroupValue('Activated')}
                className={styles.radioButton}
              />
              <RadioButton
                checked={groupValue === 'Always_Activated'}
                label={t('Administration.ModulesSection.Always activated')}
                onClick={() => setGroupValue('Always_Activated')}
                className={styles.radioButton}
              />
            </div>
          </div>
          {module.moduleKey !== 'survey' &&
            module.moduleKey !== 'tasks' &&
            module.moduleKey !== 'absence' &&
            module.moduleKey !== 'image_download' &&
            module.moduleKey !== 'employee_portfolio' &&
            module.moduleKey !== 'chat' &&
            module.moduleKey !== 'corona_test' &&
            module.moduleKey !== 'tax_consultant' && (
              <div className={styles.userProfilesButtonsContainer}>
                <div className={styles.title}>
                  {t('Administration.ModulesSection.User Profiles')}
                </div>
                <div className={styles.radioButtonsContainer}>
                  <RadioButton
                    checked={userProfileValue === 'Deactivated'}
                    label={t('Administration.ModulesSection.Deactivated')}
                    onClick={() => setUserProfileValue('Deactivated')}
                    className={styles.radioButton}
                  />
                  <RadioButton
                    checked={userProfileValue === 'Activated'}
                    label={t('Administration.ModulesSection.Activated')}
                    onClick={() => setUserProfileValue('Activated')}
                    className={styles.radioButton}
                  />
                  <RadioButton
                    checked={userProfileValue === 'Always_Activated'}
                    label={t('Administration.ModulesSection.Always activated')}
                    onClick={() => setUserProfileValue('Always_Activated')}
                    className={styles.radioButton}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </Popup>
  );
}
