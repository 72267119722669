import React, { useRef, useEffect } from 'react';

export default function DragAndDrop({ children, onDrop, canUpload }) {
  const dropRef = useRef(null);

  const handleDrop = e => handleDropHandler(e, onDrop, canUpload);

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

    return removeEventListeners({ div, handleDrop, handleDrag, handleDragIn, handleDragOut });
  });

  return <div ref={dropRef}>{children}</div>;
}

// eslint-disable-next-line
export function removeEventListeners({ div, handleDrop, handleDragIn, handleDragOut, handleDrag }) {
  return () => {
    div.removeEventListener('dragenter', handleDragIn);
    div.removeEventListener('dragleave', handleDragOut);
    div.removeEventListener('dragover', handleDrag);
    div.removeEventListener('drop', handleDrop);
  };
}

export function handleDrag(e) {
  e.preventDefault();
  e.stopPropagation();
}

export function handleDragIn(e) {
  e.preventDefault();
  e.stopPropagation();
}

export function handleDragOut(e) {
  e.preventDefault();
  e.stopPropagation();
}

export function handleDropHandler(e, onDrop, canUpload) {
  if (!canUpload) {
    return;
  }
  e.preventDefault();
  e.stopPropagation();

  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    onDrop(e.dataTransfer.files);
  }
}

DragAndDrop.defaultProps = {
  canUpload: true,
};
