import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

import Popup from '../../../Popups/Popup';
import Button from '../../../Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import ButtonWithLoader from '../../../Buttons/ButtonWithLoader';
import { updateEmployeeWorkingHoursByUserId } from 'library/api/employee';
import styles from './calendarWorkingHour.module.scss';

export default function CalendarWorkingHoursPopup({ event, isOpen, setOpen, reloadEvents }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [userId, setUserId] = useState(null);
  const [date, setDate] = useState(null);

  const [selectedTimeRangeError, setSelectedTimeRangeError] = useState(null);

  useEffect(() => {
    if (event && event.event.extendedProps?.item) {
      const { startTime, endTime, userId, date } = event.event.extendedProps.item;
      setStartTime(startTime);
      setEndTime(endTime);
      setUserId(userId);
      setDate(date);
      setSelectedTimeRangeError(null);
    }
  }, [event]);

  const handleUpdateWorkingHour = async () => {
    if (!startTime || !endTime) {
      setSelectedTimeRangeError(
        t('Administration.UserSection.Both start and end working time must be set'),
      );
      return;
    }
    const startFormat = moment(startTime, 'HH:mm');
    const endFormat = moment(endTime, 'HH:mm');
    if (startFormat.isAfter(endFormat)) {
      setSelectedTimeRangeError(
        t('Administration.End of working time has to be be later than start time'),
      );
      return;
    }

    try {
      setIsLoading(true);
      await updateEmployeeWorkingHoursByUserId(userId, startTime, endTime, date);
      reloadEvents({
        userId,
        startTime,
        endTime,
        date,
      });
    } catch (error) {
      console.log(error);
      setSelectedTimeRangeError('Something went wrong');
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  return (
    <Popup
      isOpened={isOpen}
      closePopup={() => setOpen(false)}
      size='small'
      header={t('CalendarWorkingHour.Title')}
      body={
        <>
          <div className={styles.workingTimeRangeWrapper}>
            <div>
              <label>{t('Administration.UserSection.Start of work')}</label>
              <Input
                type='time'
                value={startTime}
                onChange={e => {
                  setStartTime(e.target.value);
                }}
                min='00:00'
                max='23:59'
              />
            </div>
            <div>
              <label>{t('Administration.UserSection.End of work')}</label>
              <Input
                type='time'
                value={endTime}
                onChange={e => {
                  setEndTime(e.target.value);
                }}
                min='00:00'
                max='23:59'
              />
            </div>
          </div>
          {!!selectedTimeRangeError && (
            <div className={styles.workingHourTimeRangeError}>{selectedTimeRangeError}</div>
          )}
        </>
      }
      footer={
        <div>
          <ButtonWithLoader
            className={styles.btnUpdateWorkingHour}
            onClick={() => handleUpdateWorkingHour()}
            isLoading={isLoading}
          >
            {t('Popup.Save')}
          </ButtonWithLoader>
          <Button onClick={() => setOpen(false)}>{t('Popup.Close')}</Button>
        </div>
      }
    />
  );
}
