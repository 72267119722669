import i18n from 'i18next';
import sBInstance from 'library/api/axios';
import * as aT from './forgotPasswordConstants';

export const sendEmailForResetPassword = email => () => {
  return sBInstance
    .put(`${aT.URLS.resetPasswordPath}/${email}`)
    .then(res => {
      if (res.status === 200) {
        return { success: true };
      }
      if (res.status === 404) {
        return { success: false, error: i18n.t('PasswordRecovery.Invalid email address') };
      }
      return { success: false };
    })
    .catch(err => {
      if (err.response && err.response.status === 500) {
        return { success: false, error: i18n.t('PasswordRecovery.Invalid email address') };
      }
      return { success: false };
    });
};
