import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

const useCalendarExportEvents = (
  getUserExports,
  deleteUserExport,
  saveUserExport,
  importFile,
  groupInfo,
) => {
  const { t } = useTranslation();
  const [exports, setExports] = useState([]);

  useEffect(() => {
    if (groupInfo === undefined) {
      getUserExports()
        .then(res => {
          if (res.status === 200) {
            setExports(res.data || []);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  const updateUserExports = (action, uExport) => {
    const exportsCopy = exports.slice();
    let index;
    switch (action) {
      case 'delete':
        index = exportsCopy.findIndex(exp => exp.id === uExport.id);
        if (index !== -1) {
          exportsCopy.splice(index, 1);
        }
        setExports(exportsCopy);
        break;

      case 'add':
        setExports(exportsCopy.concat(uExport));
        break;

      default:
    }
  };

  const onDeleteUserExport = uExport => {
    return deleteUserExportRequest(uExport);
  };

  const deleteUserExportRequest = uExport => {
    return deleteUserExport(uExport.id)
      .then(res => {
        if (res.status === 200) {
          updateUserExports('delete', uExport);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onAddExport = exportData => {
    return saveUserExport(exportData)
      .then(res => {
        if (res.status === 200) {
          updateUserExports('add', res.data);
        }
      })
      .catch(err => {
        switch (err.status || err.response.status) {
          case 400:
            store.dispatch(
              showBottomNotification(t(err.response.data.message), {
                isFail: true,
              }),
            );
            return {
              success: false,
              error: 'error',
            };
          default:
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  const onImport = formData => {
    return importFile(formData)
      .then(res => {
        if (res.status === 200) {
          store.dispatch(
            showBottomNotification(t('BottomNotifications.Saved'), {
              isFail: true,
            }),
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return {
    exports,
    deleteUserExport: onDeleteUserExport,
    saveUserExport: onAddExport,
    importUserFile: onImport,
  };
};

export default useCalendarExportEvents;
