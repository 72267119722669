import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Select from 'library/common/commonComponents/Inputs/Select';

import styles from './feedItemParentSurveyFilter.module.scss';

export default function FeedItemParentSurveyFilter({ questions, onFilterChange, resetUsersCompletedFilteredCount }) {
  const { t } = useTranslation();

  // Converts the questions to options for the select question input
  const convertInputTypeNumberQuestion = parentSurveyQuestions => {
    const options = [];
    for (let i = 0; i < parentSurveyQuestions.length; i++) {
      options.push({
        value: parentSurveyQuestions[i].id,
        label: parentSurveyQuestions[i].question,
      });
    }
    return options;
  };

  // Converts the questions to options for the select answer input
  const convertInputTypeNumberAnswer = option => {
    const options = [];
    if (option) {
      for (let i = 0; i < option.length; i++) {
        options.push({ value: option[i].id, label: option[i].answer });
      }
      return options;
    }
    return 0;
  };

  // Finds the question object from a select option
  const getOriginalFromOption = (original, option) => {
    return original.find(question => question.id === option.value);
  };

  const selectQuestionOption = value => {
    if (value === "none")
    {
      resetUsersCompletedFilteredCount();
    }
    setSelectedQuestionOption(questionSelectOptions.find(question => question.value === value));
  };

  const selectAnswerOption = value => {
    setSelectedAnswerOption(answerSelectOptions.find(answer => answer.value === value));

    const question = getOriginalFromOption(filteredQuestions, selectedQuestionOption);
    let questionId = -1;
    if (question) {
      questionId = question.id;
    }

    let optionId = -1;
    if (originalAnswers) {
      const option = getOriginalFromOption(
        originalAnswers.parentSurveyQuestionOptions,
        answerSelectOptions.find(answer => answer.value === value),
      );
      if (option) {
        optionId = option.id;
      }
    }
    onFilterChange({
      questionId,
      optionId,
    });
  };

  // Only use the questions with inputType === 'number' for filtering
  // const filteredQuestions = questions.filter(question => question.inputType === 'number');
  const filteredQuestions = questions;

  const questionSelectOptions = convertInputTypeNumberQuestion(filteredQuestions);

  // Adds the initial option to the questions array
  questionSelectOptions.unshift({ value: 'none', label: 'Kein Filter' });

  const [selectedQuestionOption, setSelectedQuestionOption] = useState(questionSelectOptions[0]);

  const originalAnswers = getOriginalFromOption(filteredQuestions, selectedQuestionOption);
  let answerSelectOptions = [{ value: '0', label: '0' }];
  if (originalAnswers) {
    const answerOptions = getOriginalFromOption(filteredQuestions, selectedQuestionOption)
      .parentSurveyQuestionOptions;
    answerSelectOptions = convertInputTypeNumberAnswer(answerOptions);

    // Sort options 1 to 7
    answerSelectOptions.sort((a, b) => {
      if (a.value < b.value) return -1;
      return a.value > b.value ? 1 : 0;
    });
  }

  const [selectedAnswerOption, setSelectedAnswerOption] = useState(answerSelectOptions[0]);

  return (
    <>
      <div>{t('ParentSurvey.Filter results')}</div>
      <div className={styles.filterContainer}>
        <Select
          className={styles.numberSelect}
          options={questionSelectOptions}
          selected={selectedQuestionOption}
          onSelect={e => selectQuestionOption(e.value)}
        />
        <Select
          className={cn(styles.numberSelect, styles.filterSelect)}
          options={answerSelectOptions}
          selected={selectedAnswerOption}
          onSelect={e => selectAnswerOption(e.value)}
        />
      </div>
    </>
  );
}
