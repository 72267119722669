import React, { useEffect, useState } from 'react';
import { KitaPanelWrapper } from 'library/common/commonComponents/KitaPanelWrapper';
import { Wrapper } from 'library/common/commonComponents/Wrapper';
import { connect } from 'react-redux';
import styles from './thirdArticle.module.scss';
import { getThirdArticle, saveThirdArticle } from 'library/api/articles';
import useReactRouter from 'use-react-router';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { getUserLogoUrl } from 'library/utilities/user';
import { getAwoKitaConnection } from 'library/api/kita';

const ThirdArticle = ({ activeKita, user }) => {
    const { t } = useTranslation();
    const { match } = useReactRouter();
    const kitaId = match.params.id;
    const [profiles, setProfiles] = useState(null); // Start with null to indicate loading
    const [isEditing, setIsEditing] = useState(false);
    const [originalProfiles, setOriginalProfiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [deletedProfileIds, setDeletedProfileIds] = useState([]); // Track deleted profiles
    const [isLoading, setIsLoading] = useState(false);
    const [awoConnection, setAWOConnection] = useState(null);

    const goToBackPage = () => {
        window.history.go(-1);
    }

    const refresh = () => {
        setIsLoading(true);
        getThirdArticle(kitaId, 3)
            .then((res) => {
                setIsLoading(false);
                if (res && res.data) {
                    // Map over fetched profiles to align data with frontend fields
                    const fetchedProfiles = res.data.map((profile) => ({
                        id: profile.id,
                        firstName: profile.firstName || '',
                        functionText: profile.functionText || '',
                        contactDate: profile.contactDate || '',
                        image: profile.images && profile.images.length > 0 ? profile.images[0].filePath : null,
                        imageFile: null, // Initialize imageFile to null
                        imageDeleted: false, // Initialize imageDeleted to false
                    }));

                    setProfiles(fetchedProfiles);
                    setOriginalProfiles(fetchedProfiles);
                } else {
                    setProfiles([]);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching profiles:', error);
                setProfiles([]);
            });
    }

    useEffect(() => {
        getAwoKitaConnection(kitaId).then((res) => {
            setAWOConnection(res.data);
        });
    }, [kitaId]);

    const canEdit = (activeKita.kitaId === awoConnection?.connectedKitaId && user.administrationAccess) || user.superAdminStatus;

    useEffect(() => {
        // Fetch profiles from the backend
        refresh();
    }, [kitaId]);

    const handleInputChange = (index, field, value) => {
        const newProfiles = [...profiles];
        newProfiles[index][field] = value;
        setProfiles(newProfiles);
    };

    const handleImageUpload = (index, file) => {
        const newProfiles = [...profiles];
        newProfiles[index].image = URL.createObjectURL(file);
        newProfiles[index].imageFile = file; // Store the actual File object
        newProfiles[index].imageDeleted = false; // Reset imageDeleted flag
        setProfiles(newProfiles);
    };

    const handleImageDelete = (index) => {
        const newProfiles = [...profiles];
        newProfiles[index].image = null;
        newProfiles[index].imageFile = null;
        newProfiles[index].imageDeleted = true; // Flag indicating the image has been deleted
        setProfiles(newProfiles);
    };

    const isProfileFilled = (profile) => {
        return profile.firstName || profile.functionText || profile.contactDate;
    };

    const addProfile = () => {
        if (profiles.length < 10) {
            const existingIds = profiles.map((p) => p.id);
            const newId = existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1;
            setProfiles([
                ...profiles,
                {
                    id: null,
                    firstName: '',
                    functionText: '',
                    contactDate: '',
                    image: null,
                    imageFile: null,
                    imageDeleted: false,
                },
            ]);
        }
    };

    const handleEditToggle = () => {
        // Save the current profiles before editing
        setOriginalProfiles(profiles);

        if (profiles.length === 0) {
            setProfiles([
                {
                    id: null,
                    firstName: '',
                    functionText: '',
                    contactDate: '',
                    image: null,
                    imageFile: null,
                    imageDeleted: false,
                },
            ]);
        }

        setIsEditing(true);
    };

    const handleSave = () => {

        for (let i = 0; i < profiles.length; i++) {
            const profile = profiles[i];
            if (!profile.firstName && !profile.functionText && !profile.contactDate) {
                store.dispatch(
                    showBottomNotification(t('Article.Please fill at least one field in each profile.'), {
                        isFail: true,
                    }),
                );
                return; // Prevent save operation
            }
        }
        // Prepare FormData
        const formData = new FormData();

        profiles.forEach((profile, index) => {
            if (profile.id != null) {
                // Only append the id if it's not null or undefined
                formData.append(`profiles[${index}].id`, profile.id);
            }
            formData.append(`profiles[${index}].firstName`, profile.firstName || '');
            formData.append(`profiles[${index}].functionText`, profile.functionText || '');
            formData.append(`profiles[${index}].contactDate`, profile.contactDate || '');

            if (profile.imageFile) {
                formData.append(`profiles[${index}].image`, profile.imageFile);
            }

            if (profile.imageDeleted) {
                formData.append(`profiles[${index}].imageDeleted`, 'true');
            }
        });

        // Append deleted profile IDs
        deletedProfileIds.forEach((id) => {
            formData.append('deletedProfileIds', id);
        });

        setIsLoading(true);
        // Send the FormData to the backend
        saveThirdArticle(kitaId, 3, formData)
            .then((response) => {
                setIsLoading(false);
                setIsEditing(false);
                setErrorMessage('');
                setDeletedProfileIds([]);
                refresh();
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error saving profiles:', error);
            });
    };

    const handleCancel = () => {
        setProfiles(originalProfiles);
        setDeletedProfileIds([]);
        setIsEditing(false);
        refresh();
    };

    const handleDeleteProfile = (index) => {
        const profileToDelete = profiles[index];
        const newProfiles = [...profiles];
        newProfiles.splice(index, 1);
        setProfiles(newProfiles);

        if (profileToDelete.id) {
            setDeletedProfileIds([...deletedProfileIds, profileToDelete.id]);
        }
    };

    if (profiles === null) {
        return (
            <KitaPanelWrapper>
                <Wrapper>
                    <div>Loading...</div>
                </Wrapper>
            </KitaPanelWrapper>
        );
    }

    return (
        <KitaPanelWrapper>
            <Wrapper>
                <div className={styles.first_article_heading}>{t('Unser Vorstand')}</div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <><><div>{isEditing && (<div className={styles.warningMessage}>
                        * Es können bis zu 10 Profile hochgeladen werden
                    </div>)}</div><div className={styles.profileContainer}>
                            {profiles.length === 0 && !isEditing && (

                                <><p className={styles.noProfile}>Keine Profile gefunden</p><><div className={styles.noProfilesMessage}>
                                    {canEdit && <Button
                                        className={styles.saveButton}
                                        onClick={handleEditToggle}
                                    >
                                        {t('Erstellen Sie neue Profile')}
                                    </Button>}

                                    <Button onClick={() => goToBackPage()} className={styles.cancelButton}>
                                        {t('Zurück zur Hauptseite')}
                                    </Button>
                                </div></></>
                            )}
                            {profiles.length > 0 &&
                                profiles.map((profile, index) => (
                                    <div key={index} className={styles.profileCard}>
                                        {isEditing && (
                                            <Button
                                                className={styles.deleteProfileButton}
                                                onClick={() => handleDeleteProfile(index)}
                                            >
                                                Löschen
                                            </Button>
                                        )}
                                        <div className={styles.profileContent}>
                                            <div className={styles.imageSection}>
                                                <div className={styles.imageWrapper}>
                                                    {profile.image ? (
                                                        <>
                                                            <img
                                                                src={profile.image || defaultAvatar}
                                                                alt="Profile"
                                                                className={styles.profileImage} />
                                                            {isEditing && (
                                                                <button
                                                                    className={styles.deleteImageButton}
                                                                    onClick={() => handleImageDelete(index)}
                                                                >
                                                                    ✖
                                                                </button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {isEditing ? (
                                                                <label className={styles.imageUploadLabel}>
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => handleImageUpload(index, e.target.files[0])}
                                                                        className={styles.imageInput} />
                                                                    <span className={styles.plusIcon}><i className='fa fa-upload' /></span>
                                                                </label>
                                                            ) : (
                                                                <div className={styles.imagePlaceholder}>
                                                                    <img src={defaultAvatar} alt="" />
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.inputSection}>
                                                {['firstName', 'functionText', 'contactDate'].map((field) => (
                                                    <div key={field} className={styles.inputGroup}>
                                                        <label className={styles.inputLabel}>
                                                            {field === 'firstName'
                                                                ? 'Vorname, Name'
                                                                : field === 'functionText'
                                                                    ? 'Funktion'
                                                                    : 'Kontaktdaten'}
                                                        </label>
                                                        {isEditing ? (
                                                            <input
                                                                type="text"
                                                                value={profile[field]}
                                                                onChange={(e) => handleInputChange(index, field, e.target.value)}
                                                                className={styles.inputField} />
                                                        ) : (
                                                            <div className={styles.textField}>
                                                                {profile[field] || '-'}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* Plus Symbol Section */}
                            {isEditing && profiles.length < 10 && (
                                <div className={styles.plusSection}>
                                    <button
                                        className={styles.addButton}
                                        disabled={profiles.length > 0 &&
                                            !isProfileFilled(profiles[profiles.length - 1])}
                                        onClick={addProfile}
                                    >
                                        +
                                        <br />
                                        <p className={styles.moreProfiles}>Weitere Profile hinzufügen
                                        </p>
                                    </button>
                                </div>
                            )}
                        </div></><div>{isEditing ? (
                            <div className={styles.editButtonsContainer}>
                                {canEdit && <Button className={styles.saveButton} onClick={handleSave}>
                                    {t('Speichern')}
                                </Button>}
                                <Button className={styles.cancelButton} onClick={handleCancel}>
                                    {t('Zurück')}
                                </Button>
                            </div>
                        ) : (
                            profiles.length > 0 && (
                                <div className={styles.editButtonContainer}>
                                    {canEdit && <Button className={styles.editButton} onClick={handleEditToggle}>
                                        {t('Artikel bearbeiten')}
                                    </Button>}
                                    <Button onClick={() => goToBackPage()} className={styles.cancelButton}>
                                        {t('Zurück zur Hauptseite')}
                                    </Button>
                                </div>
                            )
                        )}</div></>
                )}
            </Wrapper>
        </KitaPanelWrapper>
    );
};

export default connect((state) => ({
    activeKita: state.kitaReducer.activeKita,
    user: state.userReducer,
}))(ThirdArticle);
