import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enGB, de } from 'date-fns/locale';
import { setMinutes, setHours, startOfDay, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

registerLocale('de', de);
registerLocale('en', enGB);

export default function CustomDatePicker({
  selected,
  onChange,
  customInput,
  langCode,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  timeFormat,
  disabled,
  peekNextMonth,
  showMonthYearPicker,
  showFullMonthYearPicker,
  showMonthDropdown,
  showYearDropdown,
  dropdownMode,
  minDate,
  selectsStart,
  startDate,
  endDate,
  maxDate,
  tabIndex,
  dateFormat,
  allowCustomHeader
}) {
  const { t } = useTranslation();
  let customDateFormat;
  if (showTimeSelectOnly) {
    customDateFormat = 'HH:mm';
  } else if (dateFormat) {
    customDateFormat = dateFormat;
  } else {
    customDateFormat = 'dd.MM.yyyy';
  }

  const isShowFullDateTime = !!showTimeSelect && !showTimeSelectOnly;
  const minTime = isShowFullDateTime
    ? selected && startOfDay(selected).getTime() > startOfDay(minDate).getTime()
      ? new Date(selected.getFullYear(), selected.getMonth(), selected.getDate())
      : minDate
    : undefined;

  const maxTime = isShowFullDateTime ? setHours(setMinutes(minDate, 45), 23) : undefined;
  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
    <div className="custom-datepicker-header">
      <p>{t('Post.SchedulePost')}</p>
      <div className="default-datepicker-header">
        {!prevMonthButtonDisabled && (
          <button onClick={decreaseMonth} className="react-datepicker__navigation react-datepicker__navigation--previous">
            Previous Month
          </button>
        )}

        <h2>{date.toLocaleDateString(langCode === 'English' || langCode === 'en' ? 'en' : 'de', { month: 'long', year: 'numeric' })}</h2>

        {!nextMonthButtonDisabled && (
          <button onClick={increaseMonth} className="react-datepicker__navigation react-datepicker__navigation--next">
            Next Month
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className={isShowFullDateTime ? 'show-date-time' : null}>
      <DatePicker
        disabled={!!disabled}
        selected={selected}
        onChange={onChange}
        renderCustomHeader={allowCustomHeader ? renderCustomHeader : null}
        customInput={customInput || null}
        showTimeSelect={!!showTimeSelect}
        showTimeSelectOnly={!!showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        timeFormat={timeFormat}
        peekNextMonth={!!peekNextMonth}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showFullMonthYearPicker}
        showMonthDropdown={!!showMonthDropdown}
        showYearDropdown={!!showYearDropdown}
        dropdownMode={dropdownMode}
        minDate={minDate ? minDate : parseISO('1970-01-01')}
        maxDate={maxDate ? maxDate : parseISO('2050-12-31')}
        minTime={minTime}
        maxTime={maxTime}
        selectsStart={!!selectsStart}
        startDate={startDate}
        endDate={endDate}
        locale={langCode === 'English' || langCode === 'en' ? 'en' : 'de'}
        dateFormat={customDateFormat}
        tabIndex={tabIndex || 0}
      />
    </div>
  );
}

/*
in case we need English date format
  dateFormat={
    !showTimeSelectOnly ? (langCode === 'Deutsch' ? 'dd.MM.yyyy' : 'MM/dd/yyyy') : 'HH:mm'
  }
*/
