import React, { useEffect, useState } from 'react';

import Loader from 'library/common/commonComponents/Loader';
import { getGroupsBeforeDeletion } from 'library/api/user';
import ProfileDeleteAccountBlocked from './profileDeleteAccountFrames/ProfileDeleteAccountBlocked';
import ProfileDeleteAccountAllowed from './profileDeleteAccountFrames/ProfileDeleteAccountAllowed';

export default function ProfileDeleteAccount({ userId }) {
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getGroupsBeforeDeletion({ userId }).then(({ data }) => {
      setGroups(data);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : groups.length > 0 ? (
        <ProfileDeleteAccountBlocked groups={groups} />
      ) : (
        <ProfileDeleteAccountAllowed />
      )}
    </div>
  );
}
