import {
  ABSENCE_REPORTED_SIGNAL,
} from 'library/common/commonConstants/absenceReportConstants';

const initialState = {
  absenceReported: false,
};

export default function AbsenceReportReducer(state = initialState, action) {
  switch (action.type) {
    case ABSENCE_REPORTED_SIGNAL:
      return {
        ...state,
        absenceReported: !state.absenceReported,
      };
    default:
      return state;
  }
}
