import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment/min/moment-with-locales';

import { uploadFilesToGroup } from 'library/api/files';
import { parentsGetMonthlyBill } from '../api/foodOrder';

export const getWeekNumber = d => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const addLeadingZeros = value => {
  return ('0' + value).slice(-2);
};

export const isChangeFoodMenuAllowed = mealDate => {
  let allowed = true;
  const now = new Date();
  const day = mealDate.substring(0, 2);
  const month = mealDate.substring(3, 5);
  const year = mealDate.substring(6, 10);
  const mealDateObject = getDateFromDayMonthYear(day, month, year);
  // Check if food has been served already
  if (mealDateObject.getTime() <= now.getTime()) {
    allowed = false;
  }
  return allowed;
};

export const isOrderAllowed = (mealDate, config) => {
  const now = moment();
  const parsedMealDate = moment(mealDate, 'DD.MM.yyyy');
  parsedMealDate.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (config.useWeeklyPreorder) {
    const currentWeekday = now.isoWeekday();
    const currentHours = now.hour();

    let earliestOrderTime;
    let latestOrderDay = 3; //Wednesday
    let latestOrderHours = 10; //10am
    if (config.groupId === 53757859 || config.groupId === 323) {
      latestOrderDay = 4;
      latestOrderHours = 23;
    }
    if (config.groupId === 1175795409 || config.groupId === 1175785469) {
      latestOrderDay = 4;
      latestOrderHours = 12;
    }
    if (config.groupId === 1187267661 || config.groupId === 1187271919) {
      latestOrderDay = 4;
      latestOrderHours = 8;
    }

    if (
      currentWeekday <= latestOrderDay - 1 ||
      (currentWeekday === latestOrderDay && currentHours < latestOrderHours)
    ) {
      earliestOrderTime = now.add(1, 'weeks').startOf('isoWeek');
    } else {
      earliestOrderTime = now.add(2, 'weeks').startOf('isoWeek');
    }

    return parsedMealDate.isSameOrAfter(earliestOrderTime);
  } else {
    const orderAllowedTime = moment(config.lastFoodOrderTime, 'HH:mm:ss');
    const lastOrderTimeForMeal = parsedMealDate.set({
      hour: orderAllowedTime.get('hour'),
      minute: orderAllowedTime.get('minute'),
      second: 0,
      millisecond: 0,
    });

    if (config.orderTimeYesterday) {
      let yesterdayOfMealOrderDate = moment(lastOrderTimeForMeal).subtract(1, 'days');

      // If the mealOrder's date is Monday
      if (parsedMealDate.day() === 1) {
        // Set yesterday as the previous Friday(weekend days)
        yesterdayOfMealOrderDate = moment(lastOrderTimeForMeal).subtract(3, 'days');
      }

      if (now > yesterdayOfMealOrderDate) {
        return false;
      }
    } else {
      if (now > lastOrderTimeForMeal) {
        return false;
      }
    }

    if (config.usePreorder) {
      const mealMonth = parsedMealDate.month();
      const mealYear = parsedMealDate.year();

      const month = now.month();
      const year = now.year();
      const day = now.date();

      let monthDiff = mealMonth - month;
      if (monthDiff < 0 || mealYear > year) {
        monthDiff += (mealYear - year) * 12;
      }

      if ((day < 26 && monthDiff < 1) || (day >= 26 && monthDiff < 2)) {
        return false;
      }
    }

    return true;
  }
};

export const isCancelOrderAllowed = (mealDate, config) => {
  const now = moment();
  const parsedMealDate = moment(mealDate, 'DD.MM.yyyy');
  parsedMealDate.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const orderCancelAllowedTime = moment(config.lastCancelFoodOrderTime, 'HH:mm:ss');
  if (config.useWeeklyPreorder && !orderCancelAllowedTime.isValid()) {
    const currentWeekday = now.isoWeekday();
    const currentHours = now.hour();

    let earliestOrderTime;

    if (currentWeekday <= 2 || (currentWeekday === 3 && currentHours < 10)) {
      earliestOrderTime = now.add(1, 'weeks').startOf('isoWeek');
    } else {
      earliestOrderTime = now.add(2, 'weeks').startOf('isoWeek');
    }

    return parsedMealDate.isSameOrAfter(earliestOrderTime);
  } else {
    const lastCancelTimeForMeal = parsedMealDate.set({
      hour: orderCancelAllowedTime.get('hour'),
      minute: orderCancelAllowedTime.get('minute'),
      second: 0,
      millisecond: 0,
    });
    if (config.cancelTimeYesterday) {
      let yesterdayOfMealOrderDate = moment(lastCancelTimeForMeal).subtract(1, 'days');

      // If the mealOrder's date is Monday
      if (parsedMealDate.day() === 1) {
        // Set yesterday as the previous Friday(weekend days)
        yesterdayOfMealOrderDate = moment(lastCancelTimeForMeal).subtract(3, 'days');
      }

      if (now > yesterdayOfMealOrderDate) {
        return false;
      }
    } else {
      if (now > lastCancelTimeForMeal) {
        return false;
      }
    }
    return true;
  }
};

export const getWeekDayIndexForGermanDateString = dateString => {
  return getDateFromGermanDateString(dateString).getDay();
};

// "10:54:00" -> date
export const parseLastCancelFoodOrderTime = (time, mealDateObject) => {
  const now = new Date();
  now.setFullYear(mealDateObject.getFullYear());
  now.setMonth(mealDateObject.getMonth());
  now.setDate(mealDateObject.getDate());

  const { hour, minute, second } = extractHoursMinutesSecondsFromTime(time);
  now.setHours(hour);
  now.setMinutes(minute);
  now.setSeconds(second);
  return now;
};

// "10:54:00" -> date - 1
export const parseLastFoodOrderTime = (time, mealDateObject) => {
  const now = new Date();
  now.setFullYear(mealDateObject.getFullYear());
  now.setMonth(mealDateObject.getMonth());
  now.setDate(mealDateObject.getDate());
  // const yesterday = subtractDays(now, 1);
  // const mealDateObjectCopy = mealDateObject;
  const { hour, minute, second } = extractHoursMinutesSecondsFromTime(time);
  now.setHours(hour);
  now.setMinutes(minute);
  now.setSeconds(second);
  return now;
};

// "10:54:00" -> 10 hour, 54min, 0 seconds
export const extractHoursMinutesSecondsFromTime = time => {
  const hour = time.substring(0, 2);
  const minute = time.substring(3, 5);
  const second = time.substring(6, 8);
  return { hour, minute, second };
};

// formats from yyyy-mm to mm.yyyy
export const formatDateToMMYYYY = date => {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  return month + '.' + year;
};

export const formatDate = date => {
  return (
    addLeadingZeros(date.getDate()) +
    '.' +
    addLeadingZeros(date.getMonth() + 1) +
    '.' +
    date.getFullYear()
  );
};

// Formats a date from 2021-07-18 to 18.07.2021
export const formatDateString = date => {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);

  const day = date.substring(8, 10);

  return day + '.' + month + '.' + year;
};

// Formats a date from 2021-07 to 07.2021
export const formatMonthYearDateString = date => {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  return month + '.' + year;
};

export const hasGermanDateStringFormat = date => {
  return date.includes('.');
};

export const subtractDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export const getDateFromDayMonthYear = (day, month, year) => {
  return new Date(parseInt(year, 10), parseInt(month - 1, 10), parseInt(day, 10));
};

export const getDateFromGermanDateString = date => {
  const year = date.substring(6, 10);
  const month = date.substring(3, 5);
  const day = date.substring(0, 2);

  return getDateFromDayMonthYear(day, month, year);
};

export const getWeekDayPerIndex = (index, t) => {
  switch (index) {
    case 0:
      return t('FoodOrder.Monday');
    case 1:
      return t('FoodOrder.Tuesday');
    case 2:
      return t('FoodOrder.Wednesday');
    case 3:
      return t('FoodOrder.Thursday');
    case 4:
      return t('FoodOrder.Friday');
    case 5:
      return t('FoodOrder.Saturday');
    case 6:
      return t('FoodOrder.Sunday');
  }
};
export const getWeekDayPerIndexSun0 = (index, t, isShortForm) => {
  if (isShortForm) {
    switch (index) {
      case 0:
        return t('FoodOrder.Su');
      case 1:
        return t('FoodOrder.Mo');
      case 2:
        return t('FoodOrder.Tu');
      case 3:
        return t('FoodOrder.We');
      case 4:
        return t('FoodOrder.Th');
      case 5:
        return t('FoodOrder.Fr');
      case 6:
        return t('FoodOrder.Sa');
    }
  } else {
    switch (index) {
      case 0:
        return t('FoodOrder.Sunday');
      case 1:
        return t('FoodOrder.Monday');
      case 2:
        return t('FoodOrder.Tuesday');
      case 3:
        return t('FoodOrder.Wednesday');
      case 4:
        return t('FoodOrder.Thursday');
      case 5:
        return t('FoodOrder.Friday');
      case 6:
        return t('FoodOrder.Saturday');
    }
  }
};

export const getWeekDayByDate = (date, t) => {
  const parts = date.split('.');

  const d = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));

  return getWeekDayPerIndexSun0(d.getDay(), t);
};

export const formatPrice = price => {
  return parseFloat(price)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const getDateFromFormatedDate = (date, t, isShortForm = false) => {
  return getWeekDayPerIndexSun0(
    getDateFromDayMonthYear(date.substring(0, 2), date.substring(3, 5), date.substring(6)).getDay(),
    t,
    isShortForm,
  );
};

export const getCurrentDate = () => {
  const date = new Date();
  return (
    ('0' + date.getDate()).slice(-2) +
    '.' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '.' +
    date.getFullYear()
  );
};

// 19.08.2021 -> 2021-08-19
export const formatGermanDateForInput = date => {
  const year = date.substring(6, 10);
  const month = date.substring(3, 5);
  const day = date.substring(0, 2);
  return year + '-' + month + '-' + day;
};
export const getCurrentFrom = () => {
  return getMonday(new Date());
};

export const getMonday = date => {
  let dayOfWeek = date.getDay();
  if (dayOfWeek === 0) {
    dayOfWeek = 7;
  }
  const daysToSubtract = dayOfWeek - 1;

  return subtractDays(date, daysToSubtract);
};

export const getPreviousMonday = date => {
  return subtractDays(date, 7);
};

export const getNextMonday = date => {
  return addDays(date, 7);
};

export const getSunday = date => {
  return addDays(date, 6);
};

export const getCurrentMonthForInput = () => {
  const d = new Date();
  return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
};

export const orderByDateASC = data => {
  data.sort((a, b) =>
    getDateFromGermanDateString(a.date) > getDateFromGermanDateString(b.date)
      ? 1
      : getDateFromGermanDateString(b.date) > getDateFromGermanDateString(a.date)
      ? -1
      : 0,
  );
};
// PDF
export const generateParentInvoiceDocumentDataForFoodOrderOrderConfirmation = async (
  t,
  groupId,
  groupName,
  kitaName,
  childId,
  childName,
  months,
) => {
  const result = {};

  for (const month of months) {
    const res = await parentsGetMonthlyBill(groupId, childId, formatDateToMMYYYY(month));

    if (res && res.data) {
      const doc = generateParentInvoiceDocumentData(t, res.data, kitaName, childName, groupName);

      result[`${month}.pdf`] = doc.output('dataurlstring');
    }
  }

  return result;
};

export const generateParentInvoiceDocumentData = (t, data, kitaName, childName, groupName) => {
  const doc = new jsPDF();
  doc.setFontSize(14);
  doc.text(childName + ' ' + t('FoodOrder.PDF.ChildInvoice') + ' - ' + groupName, 10, 10);
  doc.setFontSize(11);
  let height = 30;
  const columnStartX = [];
  const pageMaxHeight = 250;
  const fontSize = 11;
  doc.setFontSize(fontSize);
  let page = 1;
  addPDFFooter(doc, page, fontSize, kitaName, groupName);
  // Headers
  columnStartX.push(10);
  columnStartX.push(35);
  columnStartX.push(70);
  columnStartX.push(160);

  drawTableHeaderChildInvoice(doc, t, height, columnStartX);
  height += 10;
  for (let i = 0; i < data.length; i++) {
    const blockHeight = 20 + data[i].length * 9;
    if (height + blockHeight > pageMaxHeight && data[i].length < 22) {
      doc.addPage();
      page++;
      addPDFFooter(doc, page, fontSize, kitaName, groupName);
      height = 30;
      drawTableHeaderChildInvoice(doc, t, height, columnStartX);
      height += 15;
    }

    const order = data[i];
    doc.text(order.date, columnStartX[0], height);
    doc.text(getDateFromFormatedDate(order.date, t), columnStartX[1], height);
    doc.text(doc.splitTextToSize(order.name || t('FoodOrder.Meal'), 80), columnStartX[2], height);
    doc.text(formatPrice(order.price) + '€', columnStartX[3], height);
    height += 11;
  }

  // total sum
  height -= 6;
  doc.setLineWidth(0.1);
  doc.line(10, height, 205, height);
  doc.setLineWidth(0.1);
  height += 1;
  doc.line(10, height, 205, height);
  height += 4;
  doc.text(t('FoodOrder.Sum'), columnStartX[2], height);
  doc.text(formatPrice(getTotalSumChild(data)) + '€', columnStartX[3], height);
  // doc.text(formatPrice(getTotalSum(data)) + " €",columnStartX[2],height);

  return doc;
};

export const generateParentInvoice = (t, data, kitaName, childName, groupName) => {
  const doc = generateParentInvoiceDocumentData(t, data, kitaName, childName, groupName);

  doc.save(childName + t('FoodOrder.PDF.ChildInvoice.filename') + '.pdf');
};

function getTotalSumChild(orders) {
  let sum = 0;
  for (let i = 0; i < orders.length; i++) {
    sum += orders[i].price;
  }
  return sum;
}
function drawTableHeaderChildInvoice(doc, t, height, columnStartX) {
  doc.text(t('FoodOrder.Date'), columnStartX[0], height);
  doc.text(t('FoodOrder.Day'), columnStartX[1], height);
  doc.text(t('FoodOrder.Meal'), columnStartX[2], height);
  doc.text(t('FoodOrder.PDF.price'), columnStartX[3], height);
  doc.setLineWidth(1);
  height += 2;
  doc.line(10, height, 205, height);
}

export const generateKitaInvoicePDF = (t, data, date, kitaName, groupName) => {
  const doc = new jsPDF();
  doc.setFontSize(14);
  doc.text(t('FoodOrder.PDF.KitaInvoice') + ' - ' + groupName, 10, 10);
  doc.setFontSize(11);
  doc.text(formatDateToMMYYYY(date), 10, 15);
  let height = 30;
  const columnStartX = [];
  const pageMaxHeight = 250;
  const fontSize = 10;
  doc.setFontSize(fontSize);
  let page = 1;
  addPDFFooter(doc, page, fontSize, kitaName, groupName);
  // Headers
  columnStartX.push(10);
  columnStartX.push(80);
  columnStartX.push(170);

  drawTableHeaderInvoice(doc, t, height, columnStartX);
  height += 10;
  for (let i = 0; i < data.length; i++) {
    const blockHeight = 15 + data[i].orders.length * 12;
    if (height + blockHeight > pageMaxHeight && data[i].orders.length < 22) {
      doc.addPage();
      page++;
      addPDFFooter(doc, page, fontSize, kitaName, groupName);
      height = 30;
      drawTableHeaderInvoice(doc, t, height, columnStartX);
      height += 15;
    }
    const kid = data[i];
    doc.text(kid.kidName, columnStartX[0], height);
    doc.text('x' + kid.orders.length, columnStartX[1], height);
    doc.text(formatPrice(extractTotalSum(kid.orders)) + ' €', columnStartX[2], height);
    height += 2;
    doc.setLineWidth(0.5);
    doc.line(10, height, 205, height);

    height += 5;
    for (let j = 0; j < data[i].orders.length; j++) {
      const order = data[i].orders[j];
      doc.text(order.date, columnStartX[0] + 10, height);
      const sizedText = doc.splitTextToSize(order.name, 80);
      const textHeight = Math.ceil(doc.getTextDimensions(sizedText).h);
      doc.text(sizedText, columnStartX[1], height);
      doc.text(formatPrice(order.price) + ' €', columnStartX[2], height);
      height += textHeight > 2 ? textHeight : 2;
      doc.setLineWidth(0.05);
      doc.line(10, height, 205, height);
      height += 5;
    }
    height += 5;
  }

  // total sum
  height -= 6;
  doc.setLineWidth(0.1);
  doc.line(10, height, 205, height);
  doc.setLineWidth(0.1);
  height += 1;
  doc.line(10, height, 205, height);
  height += 4;
  doc.text(t('Total'), columnStartX[1], height);
  doc.text(formatPrice(getTotalSum(data)) + ' €', columnStartX[2], height);

  doc.save(t('FoodOrder.PDF.KitaInvoice.filename') + '.pdf');
};

function getTotalSum(orders) {
  let sum = 0;
  for (let i = 0; i < orders.length; i++) {
    for (let j = 0; j < orders[i].orders.length; j++) {
      sum += orders[i].orders[j].price;
    }
  }
  return sum;
}

function extractTotalSum(orders) {
  let sum = 0;
  for (let i = 0; i < orders.length; i++) {
    sum += orders[i].price;
  }
  return sum;
}

function drawTableHeaderInvoice(doc, t, height, columnStartX) {
  doc.text(t('FoodOrder.PDF.child'), columnStartX[0], height);
  doc.text(t('FoodOrder.PDF.orders'), columnStartX[1], height);
  doc.text(t('FoodOrder.PDF.price'), columnStartX[2], height);
  doc.setLineWidth(1);
  height += 2;
  doc.line(10, height, 205, height);
}

export const generateKitaFoodOrdersPDF = (
  t,
  data,
  date,
  kitaName,
  groupName,
  uploadToFilesModuleData,
) => {
  return new Promise((resolve, reject) => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.text(t('FoodOrder.PDF.KitaOrders') + ' - ' + groupName, 10, 10);
    doc.setFontSize(12);
    doc.text(formatDateString(date), 10, 15);
    doc.setFontSize(11);

    const headers = [['Kind', 'Gruppe', ...(data.foods || []).map(food => food.name)]];

    const bodyData = (data.orders || []).map(order => [
      order.childName,
      order.kidGroupName,
      ...(data.foods || []).map(food => (order.food.id === food.id ? 'x' : '')),
    ]);

    doc.autoTable({
      theme: 'striped',
      margin: { top: 25, bottom: 30, left: 10, right: 10 },
      styles: { fontSize: 12 },
      head: headers,
      body: bodyData,
    });

    const pageCount = doc.internal.getNumberOfPages();
    const fontSize = 12;
    doc.setFontSize(fontSize);
    for (let page = 1; page <= pageCount; page++) {
      doc.setPage(page);
      addPDFFooter(doc, page, fontSize, kitaName, groupName);
    }

    if (uploadToFilesModuleData.uploadToFilesModule) {
      const formData = new FormData();
      const blobPDF = doc.output('blob');
      formData.append(
        'files',
        new File(
          [blobPDF],
          `Essensbestellungsübersicht_${new Date().toLocaleDateString('de-DE')}.pdf`,
        ),
      );
      formData.append('userId', uploadToFilesModuleData.userId);
      formData.append('privatePost', true);
      formData.append('groupId', uploadToFilesModuleData.groupId);
      uploadFilesToGroup(formData, uploadToFilesModuleData.setProgress).then(data => {
        resolve(null);
      });
    } else {
      doc.save(t('FoodOrder.PDF.KitaOrders.filename') + '.pdf');
    }
  });
};

function drawTableHeader(
  doc,
  width,
  height,
  data,
  rowHeight,
  firstColumnWidth,
  secondColumnWidth,
  columnStartX,
  ordersToGo,
) {
  doc.text('Kind', width, height);
  width += firstColumnWidth;
  doc.line(width - 2, 25, width - 2, 25 + getTableLineHeight(ordersToGo, rowHeight));

  doc.text('Gruppe', width, height);
  width += secondColumnWidth;
  doc.line(width - 2, 25, width - 2, 25 + getTableLineHeight(ordersToGo, rowHeight));

  for (let i = 0; i < data.foods.length; i++) {
    const food = data.foods[i];
    columnStartX.push(width);
    doc.text(food.name, width, height);
    width += getTextWidth(food.name);
    doc.setLineWidth(0.05);
    const lineHeight = getTableLineHeight(ordersToGo, rowHeight);
    doc.line(width - 2, 25, width - 2, 25 + lineHeight);
  }

  doc.setLineWidth(0.5);
  height += 2;
  doc.line(10, height, 205, height);
}

function getTableLineHeight(elements, rowHeight) {
  let result = elements * rowHeight + 10;
  if (result >= 246) {
    result = 246;
  }
  return result;
}
function getTextWidth(name) {
  let widthToAdd = name.length * 2.9;
  if (name.length >= 10 && name.length < 15) {
    widthToAdd = name.length * 2.65;
  } else if (name.length >= 15) {
    widthToAdd = name.length * 2.4;
  }
  return widthToAdd;
}
function getIndexByFoodId(foodId, foods) {
  for (let i = 0; i < foods.length; i++) {
    if (foods[i].id === foodId) {
      return i;
    }
  }
  return -1;
}

export const generateKitchenFoodOrdersPDF = (t, orderedMeals, kitaName, groupName) => {
  const doc = new jsPDF();
  doc.setFontSize(14);
  doc.text(t('FoodOrder.PDF.KitchenOrders') + ' - ' + groupName, 10, 10);

  doc.setFontSize(12);
  let height = 5;

  const fontSize = 13;
  doc.setFontSize(fontSize);
  const page = 1;
  addPDFFooter(doc, page, fontSize, kitaName, groupName);
  for (let i = 0; i < orderedMeals.length; i++) {
    const meal = orderedMeals[i];
    height += 16;
    doc.text(getDateFromFormatedDate(meal.date, t) + ' - ' + meal.date, 10, height);

    doc.setLineWidth(0.3);
    height += 2;
    doc.line(10, height, 65, height);

    if (!meal.inactive && meal.foods.length === 0) {
      meal.foods.push({
        name: t('FoodOrder.Meal'),
        id: -1,
        description: t('FoodOrder.No description'),
        price: 0,
      });
    }
    for (let j = 0; j < meal.foods.length; j++) {
      const food = meal.foods[j];
      let foodCount = 0;
      if (food.id in meal.foodCounts) {
        foodCount = meal.foodCounts[food.id];
      }
      height += 7;
      doc.text(food.name + ' - ' + foodCount, 10, height);
    }
  }
  doc.save(t('FoodOrder.PDF.KitchenOrders.filename') + '.pdf');
};

export const generateKitaWeeklyFoodOrdersPDF = (t, orderedMeals, from, to, kitaName) => {
  const doc = new jsPDF();
  doc.setFontSize(14);
  doc.text(t('FoodOrder.PDF.KitaOrdersWeekly') + ' - ' + from + ' to ' + to, 10, 20);

  let height = 15;
  const pageHeight = doc.internal.pageSize.getHeight();

  const fontSize = 13;
  doc.setFontSize(fontSize);

  let page = 1;
  addPDFFooter(doc, page, fontSize, kitaName, '');

  orderedMeals.forEach(meal => {
    height += 16;
    if (height > pageHeight - 25) {
      doc.addPage();
      page++;
      addPDFFooter(doc, page, fontSize, kitaName, '');
      height = 20;
    }
    doc.text(meal.date, 10, height);

    doc.setLineWidth(0.3);
    height += 2;
    doc.line(10, height, 65, height);

    if (meal.food) {
      meal.food.forEach(item => {
        height += 7;
        if (height > pageHeight - 25) {
          doc.addPage();
          page++;
          addPDFFooter(doc, page, fontSize, kitaName, '');
          height = 20;
        }

        doc.text(item.foodName, 10, height);
        doc.setLineWidth(0.3);
        height += 2;
        doc.line(10, height, 65, height);

        item.kids.forEach(kid => {
          height += 7;
          if (height > pageHeight - 25) {
            doc.addPage();
            page++;
            addPDFFooter(doc, page, fontSize, kitaName, '');
            height = 20;
          }

          doc.text(kid.name + '---' + (kid.group || t('FoodOrder.No group available')), 10, height);
        });
      });
    }
  });

  doc.save(t('FoodOrder.PDF.KitaWeeklyOrders.filename') + '.pdf');
};

function addPDFFooter(doc, page, fontSize, kitaName, groupName) {
  doc.text(page + '', 195, 285);
  doc.setFontSize(fontSize - 2);
  doc.setFont(undefined, 'italic');
  doc.setLineWidth(0.2);
  doc.line(10, 275, 200, 275);
  doc.text(kitaName + ' - ' + groupName, 10, 280);
  doc.text('Generated by Link IT isi GmbH', 10, 285);
  doc.setFont(undefined, 'normal');
  doc.setFontSize(fontSize);
}

export const checkOnlyOneFoodPerMeal = meals => {
  return meals.filter(m => m.foods && m.foods.length > 1).length === 0;
};

export const getPreorderMonthMeals = (meals, nextMonthBeginning) => {
  const monthYearString = `${nextMonthBeginning.getMonth() +
    1}.${nextMonthBeginning.getFullYear()}`;

  return meals.filter(m => m.date.endsWith(monthYearString));
};

export const getNextMonthStartAndEnd = () => {
  const now = new Date();
  const nextMonthBeginning =
    now.getDate() < 26
      ? new Date(now.getFullYear() + (now.getMonth() === 11 ? 1 : 0), (now.getMonth() + 1) % 12, 1)
      : new Date(now.getFullYear() + (now.getMonth() >= 10 ? 1 : 0), (now.getMonth() + 2) % 12, 1);
  const nextMonthEnd = new Date(
    nextMonthBeginning.getFullYear() + (nextMonthBeginning.getMonth() === 11 ? 1 : 0),
    (nextMonthBeginning.getMonth() + 1) % 12,
    0,
  );

  return { nextMonthBeginning, nextMonthEnd };
};
