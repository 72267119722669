import { useState, useEffect } from 'react';

import { getGroupById, setGlobalPublicGroup, getKitaByById } from 'library/api/groups';
import { setUserKita } from 'library/api/kita';
import { defaultGroupState } from '../groupConstants';

export default function useGroupLoading(id, setGroupDataInStore, history, activeKita) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [groupInfo, setGroupInfo] = useState(defaultGroupState);
  const [reloadGroupInfo, setReloadGroupInfo] = useState(false);
  const [oldGroupInfo, setOldGroupInfo] = useState('');
  const [kitaInfoByGroup, setKitaInfoByGroup] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getKitaByById(id).then(kitaRes => {
      const kitaInfo = kitaRes.data;
      setKitaInfoByGroup(kitaInfo);
    });

    return () => {
      setGroupInfo(defaultGroupState);
    };
    // eslint-disable-next-line
  }, [id, reloadGroupInfo]);

  useEffect(() => {
    if (activeKita?.kitaId && kitaInfoByGroup?.kitaId) {
      if (activeKita?.kitaId !== kitaInfoByGroup?.kitaId) {
        setUserKita(kitaInfoByGroup.kitaId).then(res => {
          if (res && res.status === 200) {
            window.location.reload();
          }
        });
      } else {
        setGroupinfo();
      }
    } else {
      setGroupinfo();
    }
  }, [activeKita, kitaInfoByGroup]);

  const setGroupinfo = () => {
    getGroupById(id)
      .then(res => {
        switch (res.status) {
          case 200:
            const hasWhiteboard = oldGroupInfo?.groupModuleList?.some(
              l => l.moduleKey === 'whiteboard',
            );
            setGroupInfo({
              ...res.data,
              isPrivate: res.data.visibility,
              groupModuleList: !hasWhiteboard
                ? [...res.data.groupModuleList]
                : [
                    ...res.data.groupModuleList,
                    {
                      moduleName: 'Whiteboard',
                      moduleKey: 'whiteboard',
                      moduleDescription: 'Whiteboard',
                      activeStatus: true,
                      configureStatus: false,
                    },
                  ],
            });
            setGroupDataInStore(res.data);
            setIsLoading(false);
            setOldGroupInfo(res.data);
            break;
          case 203:
            setGroupInfo({
              ...defaultGroupState,
              error: 203,
            });
            setIsLoading(false);
            break;
          default:
        }
      })
      .catch(res => {
        if (res.response) {
          switch (res.response.status) {
            case 406:
              setGroupInfo({
                ...defaultGroupState,
                error: 406,
              });
              setIsLoading(false);
              break;
            case 404:
              setGroupInfo({
                ...defaultGroupState,
                error: 404,
              });
              setIsLoading(false);
              break;
            case 423:
              setIsShowPopup(true);
              setIsLoading(false);
              break;
            default:
              setGroupInfo({
                ...defaultGroupState,
              });
              setIsLoading(false);
          }
        }
      });
  };

  const closePopup = () => {
    setIsShowPopup(false);
    history.push('/dashboard');
  };

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);
      await setGlobalPublicGroup(id, { consentAccepted: true });
      setIsShowPopup(false);
      setReloadGroupInfo(true);
      setIsSubmitting(false);
    } catch (err) {
      closePopup();
    }
  };

  return {
    isLoading,
    isShowPopup,
    groupData: groupInfo,
    isSubmitting,
    closePopup,
    handleConfirm,
    reloadGroupInfo: () => setReloadGroupInfo(!reloadGroupInfo),
  };
}
