export function validateSuggestionBoxMessage(message, subject, t) {
  let errors = { subject: false, message: false };
  let valid = true;
  if (message.trim() === '') {
    errors = { ...errors, message: true };
    valid = false;
  }

  if (subject.trim() === '') {
    errors = { ...errors, subject: true };
    valid = false;
  }
  return { valid, errors };
}
