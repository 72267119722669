import { connect } from 'react-redux';

import { patchUserInfo } from 'library/common/commonActions/userActions';
import UserEditing from '.';

const mapStateToProps = state => ({
  user: state.userReducer,
  currentUserActiveKita: state.kitaReducer.activeKita,
});
const mapDispatchToProps = {
  patchUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserEditing);
