import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { getSettings } from 'library/api/formEditor';
import Loader from 'library/common/commonComponents/Loader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import { generateContract } from 'library/utilities/forms';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import styles from '../../usersFrameChildOverview.module.scss';

export default function UsersFrameChildOverviewTable(
  {
    kids,
    isLoading,
    activeKita,
    selectedKids,
    setSelectedKids,
  }) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleToggleAllKidsSelection = () => {
    if (selectedKids.length === kids.length) {
      setSelectedKids([]);
    } else {
      setSelectedKids(kids);
    }
  };

  const handleKidSelection = kid => {
    if (selectedKids.find(item => kid.kidId === item.kidId)) {
      setSelectedKids(selectedKids.filter(item => item.kidId !== kid.kidId));
    } else {
      setSelectedKids([...selectedKids, kid]);
    }
  };

  const goChildPage = (kid) =>{
    history.push({
      pathname: '/administration/users/list/1',
      search: `?editChild=${kid.kidId}&parentId=${kid.parent1Id}`
    });
  };

  const [defaultContractForm, setDefaultContractForm] = useState(null);

  kids.sort((a, b) => a.firstName.localeCompare(b.firstName));

  useEffect(() => {
    getSettings().then((res) => {
      setDefaultContractForm(res.data.defaultContractForm);
    });
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <div className={styles.headerTextContainer}>
                    <Checkbox
                      isChecked={selectedKids.length === kids.length}
                      onChange={handleToggleAllKidsSelection}
                    />
                  </div>
                </th>
                <th className={styles.headerText}>
                  <div style={{ paddingLeft: '50px' }} className={styles.headerTextContainer}>
                    {t('Administration.UserSection.Name')}
                  </div>
                </th>
                <th className={styles.headerText}>
                  <div className={styles.headerTextContainer}>
                    {t('Administration.UserSection.GroupName')}
                  </div>
                </th>
                {defaultContractForm && (
                  <th>
                    {t('Administration.ChildOverviewGenerateContract')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {kids.map(kid => (
                <tr key={kid.kidId}>
                  <td>
                    <div className={styles.headerTextContainer}>
                      <Checkbox
                        isChecked={selectedKids.findIndex(item => item.kidId === kid.kidId) > -1}
                        onChange={() => handleKidSelection(kid)}
                      />
                    </div>
                  </td>
                  <td onClick={()=>goChildPage(kid)}>
                    <div className={cn('notranslate', styles.avatar)}>
                      <img
                        style={{ maxWidth: '40px', marginRight: '10px' }}
                        src={kid.groupLogoUrl || defaultAvatar}
                        alt=""
                      />
                      <div className={styles.avatarText}>
                        <div className={styles.name}>
                          <span>
                            {kid.firstName} {kid.lastName}
                          </span>
                          <span className={styles.parent}>
                            {kid.parent1FirstName || ""} {kid.parent1LastName || ""}
                          </span>
                          <span className={styles.parent}>
                            {kid.parent2FirstName || ""} {kid.parent2LastName || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td onClick={()=>goChildPage(kid)}>
                    <div className={cn('notranslate', styles.avatar)}>
                      <div className={styles.avatarText}>
                        <div className={styles.name}>
                          {kid.groupName && kid.groupName}
                        </div>
                      </div>
                    </div>
                  </td>
                  {defaultContractForm && (
                    <td>
                      <Button onClick={() => {
                        generateContract(t, kid, activeKita);
                      }}>
                        <i className="fa fa-file-pdf-o" />
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
