import React, { useContext, useEffect } from 'react';
import last from 'lodash/last';
import cn from 'classnames';

import Card from 'library/common/commonComponents/Card';
import Loader from 'library/common/commonComponents/Loader';
import DragAndDrop from 'library/common/commonComponents/DragAndDrop';
import useFileUploading from 'library/common/commonHooks/useFileUploading';
import { GroupContext } from 'modules/Group/groupModule';
import FileManagerBreadcrumbs from './fileManagerFrames/FileManagerBreadcrumbs';
import FileManagerButtons from './fileManagerFrames/FileManagerButtons/FileManagerButtonsContainer';
import FileManagerTable from './fileManagerFrames/FileManagerTable';
import useFiles from './hooks/useFiles';
import FileManagerContext from './fileManagerContext';

import styles from './fileManager.module.scss';

export default function FileManager({
  id,
  user,
  showBottomNotification,
  isProfilePage,
  folders,
  updateFolders,
  updateBreadcrumbs,
  breadcrumbs,
}) {
  const { groupInfo, patchGroup } = useContext(GroupContext);
  const {
    isLoading,
    streamFiles,
    onUpload,
    deleteSelected,
    updateFileName,
    moveFilesToFolder,
    downloadSelected,
    downloadSelectedAsZip,
    downloadSelectedAsPDF,
    downloadSelectedAsPDFZip,
    makePublicSelected,
    makePrivateSelected,
    updateFile,
    getChildFiles,
    getChildStreamFiles,
    createNewFolder,
    getRootFilesHandler,
    selectFileHandler,
    selectedFiles,
    isAllFilesSelected,
    toggleAllFilesCheckbox,
    updateCurrentFolder,
    setSelectedFiles,
    updateFolder,
    getPermanent,
    changePermanent,
    ...fileManagerTableProps
  } = useFiles(
    id,
    isProfilePage,
    groupInfo,
    updateFolders,
    breadcrumbs,
    folders,
    showBottomNotification,
  );

  const currentFolder = last(breadcrumbs) || {};

  useEffect(() => {
    setSelectedFiles([]);
    // eslint-disable-next-line
  }, [breadcrumbs]);

  useEffect(() => {
    return () => updateBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onFileUpload, ...fileManagerButtonsProps } = useFileUploading({
    userId: user.id,
    groupId: groupInfo.groupId,
    moduleId: (groupInfo.groupModuleList.find(({ moduleKey }) => moduleKey === 'files') || {})
      .moduleId,
    increasePostsCount: length => patchGroup({ postCount: groupInfo.postCount + length }),
    folderId: currentFolder.id,
    isPrivateFolder:
      currentFolder && 'publicFolder' in currentFolder && !currentFolder.publicFolder,
  });

  const openFolder = (folderId, groupId, name, description, publicFolder, isStreamFolder) => {
    if (isStreamFolder) {
      updateBreadcrumbs([
        ...breadcrumbs,
        { id: folderId, name, description, publicFolder, type: 'streamFiles' },
      ]);
    } else {
      updateBreadcrumbs([...breadcrumbs, { id: folderId, description, publicFolder, name }]);
    }
  };

  const goBackHandler = () => {
    const updatedBreadcrumbs = [...breadcrumbs];
    updatedBreadcrumbs.pop();

    updateBreadcrumbs(updatedBreadcrumbs);
  };

  const gotoHome = () => {
    getRootFilesHandler();
    updateBreadcrumbs([]);
  };

  const uploadFileHandler = async file => {
    await onFileUpload(file);

    updateCurrentFolder();
  };

  return (
    <FileManagerContext.Provider value={{ user, showBottomNotification, updateFile }}>
      <Card className={styles.container}>
        <DragAndDrop onDrop={uploadFileHandler} isInProfileStream={isProfilePage}>
          <FileManagerBreadcrumbs
            gotoHome={gotoHome}
            isPrivateFolder={!currentFolder.publicFolder}
            breadCrumbs={breadcrumbs}
          />

          <FileManagerButtons
            goBack={goBackHandler}
            showGoBack={breadcrumbs && !!breadcrumbs.length}
            selectedFiles={selectedFiles}
            onFileUpload={uploadFileHandler}
            deleteSelected={deleteSelected}
            updateFileName={updateFileName}
            moveFilesToFolder={moveFilesToFolder}
            downloadSelected={downloadSelected}
            downloadSelectedAsZip={downloadSelectedAsZip}
            downloadSelectedAsPDF={downloadSelectedAsPDF}
            downloadSelectedAsPDFZip={downloadSelectedAsPDFZip}
            makePublicSelected={makePublicSelected}
            makePrivateSelected={makePrivateSelected}
            createNewFolder={createNewFolder}
            updateCurrentFolder={updateCurrentFolder}
            updateFolder={updateFolder}
            group={groupInfo}
            breadcrumbs={breadcrumbs}
            updateBreadcrumbs={updateBreadcrumbs}
            isProfilePage={isProfilePage}
            getPermanent={getPermanent}
            changePermanent={changePermanent}
            {...fileManagerButtonsProps}
          />

          {isLoading ? (
            <Loader className={styles.loader} />
          ) : (
            <div className={cn(styles.tableWrapper, 'notranslate')}>
              <FileManagerTable
                folders={folders}
                selectFileHandler={selectFileHandler}
                selectedFiles={selectedFiles}
                groupInfo={groupInfo}
                getChildFiles={getChildFiles}
                openFolder={openFolder}
                breadcrumbs={breadcrumbs}
                administrationAccess={user.administrationAccess}
                isAllFilesSelected={isAllFilesSelected}
                toggleAllFilesCheckbox={toggleAllFilesCheckbox}
                {...fileManagerTableProps}
              />
            </div>
          )}
        </DragAndDrop>
      </Card>
    </FileManagerContext.Provider>
  );
}
