import { LOAD_DOCUMENT, LOAD_PAGE } from '../commonConstants/signatureConstants';

const initialState = {
  document: null,
  page: null,
};

const SignatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case LOAD_DOCUMENT:
      return {
        ...state,
        document: action.document,
      };
    default:
      return state;
  }
};

export default SignatureReducer;
