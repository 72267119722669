import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

import Calendar from 'library/common/commonComponents/Calendar';
import {
  getAllEmployeeWorkingHoursByGroupId,
  updateEmployeeWorkingHoursByUserId,
} from 'library/api/employee';
import { formatWorkingHoursEvents } from 'library/utilities/formatEvents';

export default function GroupWorkingHours({ showBottomNotification, groupInfo, user }) {
  const { t } = useTranslation();

  const { groupId } = groupInfo;

  const [events, setEvents] = useState([]);
  const [employeeWorkingHours, setEmployeeWorkingHours] = useState([]);
  const [calendarSettings, setCalendarSettings] = useState({});
  const [filters, setFilters] = useState([{ id: 1, name: 'currentViewDate', value: new Date() }]);

  const setTime = (date, timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return moment(date)
      .hour(hours)
      .minute(minutes);
  };

  useEffect(() => {
    const fetchWorkingHours = async () => {
      try {
        const data = (await getAllEmployeeWorkingHoursByGroupId(groupId)).data;
        setEmployeeWorkingHours(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchWorkingHours();
  }, []);

  useEffect(() => {
    if (employeeWorkingHours.length) {
      const currentDate = filters
        ? filters.find(item => item.name === 'currentViewDate').value
        : new Date();
      const formattedEvents = formatWorkingHoursEvents(employeeWorkingHours, groupId, currentDate);
      setEvents(formattedEvents);
    }
  }, [filters, employeeWorkingHours]);

  const reloadEvents = ({ userId, startTime, endTime, date }) => {
    const updatedEvents = events.map(x => {
      if (x.item.userId === userId && x.item.date === date) {
        return {
          ...x,
          item: {
            ...x.item,
            startTime: startTime,
            endTime: endTime,
          },
          start: setTime(x.start, startTime).format('YYYY-MM-DD HH:mm'),
          end: setTime(x.end, endTime).format('YYYY-MM-DD HH:mm'),
        };
      }
      return x;
    });

    setEvents(updatedEvents);
  };

  const updateEvent = event => {
    if (!event) {
      return;
    }
    const { start, end } = event;
    const { userId, date } = event.extendedProps.item;
    const startTime = moment(start).format('HH:mm');
    const endTime = moment(end).format('HH:mm');
    const updatedEvents = events.map(x => {
      if (x.item.userId === userId && x.item.date === date) {
        return {
          ...x,
          item: {
            ...x.item,
            startTime: startTime,
            endTime: endTime,
          },
          start: setTime(x.start, startTime).format('YYYY-MM-DD HH:mm'),
          end: setTime(x.end, endTime).format('YYYY-MM-DD HH:mm'),
        };
      }
      return x;
    });

    setEvents(updatedEvents);
    updateEmployeeWorkingHoursByUserId(userId, startTime, endTime, date).then();
  };

  return (
    <Calendar
      showBottomNotification={showBottomNotification}
      events={[...events]}
      calendarSettings={calendarSettings}
      filterList={[]}
      filters={filters}
      setFilters={setFilters}
      groupInfo={groupInfo}
      user={user}
      showCalendarImportExport={false}
      reloadEvents={reloadEvents}
      updateEvent={updateEvent}
      initialView='timeGridWeek'
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false,
        omitZeroMinute: false,
      }}
    />
  );
}
