import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import { getAllKitas, setUserKita } from 'library/api/kita';
import { isAwoWW } from 'library/api/tenantConfig';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Input from 'library/common/commonComponents/Inputs/Input';
import useSorting from 'library/common/commonHooks/useSorting';
import ListItemWithLogoAvatar from 'library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import Loader from 'library/common/commonComponents/Loader';

import styles from './InstitutionSearch.module.scss';

function InstitutionSearch() {
  const { t } = useTranslation();
  const sort = useSorting('kitaName');

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [kitas, setKitas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setSearchValue('');
      setKitas([]);
    }
  }, [open]);

  const performSearch = async query => {
    if (!query) {
      setKitas([]);
      return;
    }
    try {
      setIsLoading(true);
      const result = (await getAllKitas({
        page: 1,
        sort: sort.sort,
        searchValue: query,
        deactivated: false,
      })).data;
      setKitas(result.content);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedPerformSearch = useCallback(debounce(query => performSearch(query), 500), []);

  const handleInputChange = e => {
    const value = e.target.value;
    setSearchValue(value);
    debouncedPerformSearch(value);
  };

  const handleSelectKita = async kita => {
    await setUserKita(kita.id);
    window.location.reload();
  };

  return (
    <>
      <div className={styles.institutionSearchContainer} onClick={() => setOpen(true)}>
        <i
          className={cn(
            isAwoWW() ? styles.iconAWOTextSearch : styles.iconTextSearch,
            'fa fa-search',
          )}
        />
        <span className={styles.textSearch}>{t('Administration.Kita.Kita')}</span>
      </div>
      <Popup
        isOpened={open}
        closePopup={() => setOpen(false)}
        header={t('InstitutionSearch.SearchTitle')}
        body={
          <div className={styles.searchPopupContainer}>
            <div className={styles.inputSearchWrapper}>
              <Input
                placeholder={t('InstitutionSearch.SearchPlaceHolder')}
                value={searchValue}
                onChange={handleInputChange}
              />
              <i className={cn(styles.icon, 'fa fa-search')} />
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.kitaTableList}>
                {kitas.map(kita => (
                  <div className={styles.item} onClick={() => handleSelectKita(kita)}>
                    <ListItemWithLogoAvatar
                      groupInfo={{
                        logoUrl: kita.logoUrl,
                        groupLogoXCoordinate: kita.kitaLogoXCoordinate,
                        groupLogoYCoordinate: kita.kitaLogoYCoordinate,
                        groupLogoWidth: kita.kitaLogoWidth,
                        groupLogoAspect: 1,
                      }}
                      letter={kita.kitaName.trim()[0]}
                      backgroundColor={kita.colorCode}
                      className={styles.avatarBox}
                    />
                    <div>{kita.kitaName}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        }
      />
    </>
  );
}

export default InstitutionSearch;
