import React, { useState } from 'react';
import Tabs from 'library/common/commonComponents/Tabs';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import Card from 'library/common/commonComponents/Card';

import Whiteboard from './WhiteboardDraw';
import WhiteboardOverview from './WhiteboardOverview';
import PortfolioKid from './PortfolioKid';


import styles from './whiteboard.module.scss';

function WhiteboardTabs({ groupId, defaultGroup, userEmail }) {
  const [activeTab, setActiveTab] = useState(0);
  const [editingData, setEditingData] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const isNotInKidsMode = !(queryString.parse(location.search).mode && queryString.parse(location.search).mode === 'kids');

  let tabs = [];
  if (!defaultGroup) {
    tabs = [
      {
        title: t('Whiteboard.Insert'),
        path: 'insert',
        component: <Whiteboard groupId={groupId} setActiveTab={setActiveTab} userEmail={userEmail} isNotInKidsMode={isNotInKidsMode} editingData={editingData} />,
      },
      {
        title: t('Whiteboard.Overview'),
        path: 'overview',
        component: <WhiteboardOverview groupId={groupId} isNotInKidsMode={isNotInKidsMode} setActiveTab={setActiveTab} setEditingData={setEditingData} />,
      },
    ];
  } else {
    tabs = [
      {
        title: t('Portfolio.Select kid'),
        path: 'selectkid',
        component: <PortfolioKid userEmail={userEmail} isNotInKidsMode={isNotInKidsMode} />,
      },
    ];
  }

  return (
    <Card className={styles.container}>
      <div className={styles.container}>
        <Tabs
          tabs={tabs}
          onChange={(_, index) => setActiveTab(index)}
          currentTab={tabs[activeTab].path}
        />
      </div>
    </Card>
  );
}

export const mapStateToProps = state => ({
  userEmail: state.userReducer.email,
  groupId: state.groupReducer.groupId,
  defaultGroup: state.groupReducer.defaultGroup,
});
export default connect(mapStateToProps)(WhiteboardTabs);
