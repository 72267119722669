import React, { createElement } from 'react';
import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
} from '@dnd-kit/core';
import {
  sortableKeyboardCoordinates,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const SortableContainer = ({ ids, children, onSortComplete, isSortable }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const currentIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      const newOrder = arrayMove(ids, currentIndex, newIndex);
      onSortComplete && onSortComplete(newOrder);
    }
  };

  return (
    <>
      {isSortable ? (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={ids} strategy={verticalListSortingStrategy}>
            {children}
          </SortableContext>
        </DndContext>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export const SortableItem = ({ children, id, ...restProps }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isOver,
  } = useSortable({ id });

  const style = {
    position: 'relative',
    cursor: isDragging ? 'grabbing' : 'grab',
    backgroundColor: '#ffffff',
    zIndex: isDragging ? 1 : 0,
    outline: isDragging ? '1px solid #6fdbe8' : '1px solid transparent',
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li ref={setNodeRef} style={style} {...restProps} {...attributes} {...listeners}>
      {children}
    </li>
  );
};
