import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_SETTINGS_USER,
  GET_NOTIFICATION_SETTINGS_GLOBAL,
  SHOW_BOTTOM_NOTIFICATION,
  RESET_NOTIFICATIONS_STATE,
  UPDATE_COUNT_OF_NOTIFICATIONS,
  UPDATE_COUNT_OF_TAX_RECEIPT,
} from '../commonConstants/notificationsConstants';
import { REMOVE_AUTH_TOKEN } from '../commonConstants/commonConstants';

export const INITIAL_STATE_NOTIFICATION_REDUCER = {
  desktopNotificationAdmin: null,
  desktopNotificationUser: null,
  globalConfiguration: null,
  userConfiguration: null,
  notifications: null,
  total: 0,
  unread: 0,
  isBottomNotification: '',
  notificationType: 'success',
  unreadNotificationsCount: {},
  unreadTaxReceiptCount: {},
};

export default (state = INITIAL_STATE_NOTIFICATION_REDUCER, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_SETTINGS_USER:
      return {
        ...state,
        ...action.payload,
      };
    case GET_NOTIFICATION_SETTINGS_GLOBAL:
      return {
        ...state,
        globalConfiguration: action.payload.globalConfiguration,
        desktopNotificationAdmin: action.payload.desktopNotificationAdmin,
      };
    case GET_NOTIFICATIONS:
      return { ...state, ...action.payload };
    case SHOW_BOTTOM_NOTIFICATION:
      return {
        ...state,
        isBottomNotification: action.payload,
        notificationType: action.notificationType,
      };
    case RESET_NOTIFICATIONS_STATE:
      return {
        ...INITIAL_STATE_NOTIFICATION_REDUCER,
        unreadNotificationsCount: state.unreadNotificationsCount,
      };
    case REMOVE_AUTH_TOKEN:
      return { ...INITIAL_STATE_NOTIFICATION_REDUCER };
    case UPDATE_COUNT_OF_NOTIFICATIONS:
      return { ...state, unreadNotificationsCount: action.payload };
    case UPDATE_COUNT_OF_TAX_RECEIPT:
      return { ...state, unreadTaxReceiptCount: action.payload };
    default:
      return state;
  }
};
