import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import RadioButton from 'library/common/commonComponents/RadioButton';
import Loader from 'library/common/commonComponents/Loader';

import styles from '../../members.module.scss';

const TransferMembersPopup = ({
  isOpenedTransfer,
  closeTransferPopup,
  multipleAct,
  setSelectedGroup,
  isGroupsLoading,
  groupsForTransfer,
  isTransferLoading,
  selectedGroup,
  openedPopup,
}) => {
  const { t } = useTranslation();
  const [withChildren, setWithChildren] = useState(false);
  const [withSecondParent, setWithSecondParent] = useState(false);

  const handleClick = () => {
    multipleAct(withChildren, withSecondParent);
  };

  const handleWithChildrenChange = () => {
    if (withChildren) {
      setWithSecondParent(false);
    }
    setWithChildren(!withChildren);
  };

  const handleWithSecondParentChange = () => {
    if (!withSecondParent) {
      setWithChildren(true);
    }
    setWithSecondParent(!withSecondParent);
  };

  return (
    <Popup
      isOpened={isOpenedTransfer}
      showHeader={false}
      size='small'
      footer={
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonCancel}>
            <Button onClick={closeTransferPopup}>{t('Confirm.Cancel')}</Button>
          </div>
          <div>
            <ButtonWithLoader onClick={handleClick} type='primary' isLoading={isTransferLoading}>
              {t(`Confirm.${openedPopup}`)}
            </ButtonWithLoader>
          </div>
        </div>
      }
    >
      <div className={styles.headerPopup}>{t('GroupPanel.Transfer to Group')}</div>
      <div className={styles.subheaderPopup}>
        {t('GroupPanel.Please select your group into which you want to move members')}
      </div>
      <div className={styles.bodyPopup}>
        {isGroupsLoading ? (
          <Loader />
        ) : (
          groupsForTransfer.map(item => (
            <div key={item.id}>
              <RadioButton
                checked={selectedGroup === item.id}
                label={item.groupName}
                onClick={() => setSelectedGroup(item.id)}
              />
            </div>
          ))
        )}
        {!groupsForTransfer.length && (
          <div>{t(`GroupPanel.You don't have administration permission to Transfer to Group`)}</div>
        )}
      </div>
      {openedPopup === 'Transfer' && (
        <div className={styles.checkboxContainer}>
          <Checkbox
            name={t('GroupBody.Members.Transfer child as well')}
            isChecked={withChildren}
            onChange={handleWithChildrenChange}
          />
          <Checkbox
            name={t('GroupBody.Members.Transfer second parent of child as well')}
            isChecked={withSecondParent}
            onChange={handleWithSecondParentChange}
          />
        </div>
      )}
    </Popup>
  );
};

export default TransferMembersPopup;
