import * as tenantTypes from '../commonConstants/tenantConstants';

const initialState = {
  isLoading: false,
  info: {},
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case tenantTypes.START_TENANT_INFO_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case tenantTypes.SET_TENANT_INFO:
      return {
        ...state,
        isLoading: false,
        info: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
