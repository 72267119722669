import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Card from 'library/common/commonComponents/Card';
import ListItem from 'library/common/commonComponents/ListItems/ListItem';
import CalendarSettings from 'library/common/commonComponents/Calendar/CalendarFrames/CalendarSettings';
import { isAwoWW } from 'library/api/tenantConfig';


import styles from './profileBody.module.scss';

ProfileBody.defaultProps = {
  showMemberInformation: true,
};

export default function ProfileBody({
  showBottomNotification,
  menuTitle,
  menuItems,
  showMemberInformation,
  user,
  groups,
}) {


  if (isAwoWW()) {
    menuItems = menuItems.filter(md => md.moduleKey !== "gallery" && md.moduleKey !== "files");
  }

  return (
    showMemberInformation && (
      <Col>
        <Row>
          <div className={styles.wrapper}>
            <div className={styles.menu}>
              <Card title={menuTitle}>
                {menuItems.map(menuItem => (
                  <ListItem
                    theme='light'
                    className={styles.listItem}
                    key={menuItem.to}
                    isNavLink
                    {...menuItem}
                  />
                ))}
              </Card>
            </div>

            <div className={styles.body}>
              <Switch>
                <Redirect exact from='/profile/:id' to='/profile/:id/stream' />
                {menuItems.map(menuItem => (
                  <Route
                    key={menuItem.to}
                    path={menuItem.to}
                    exact
                    render={({ match: localMatch, history: localHistory }) =>
                      menuItem.component ? (
                        <menuItem.component
                          {...menuItem.props || {}}
                          match={localMatch}
                          history={localHistory}
                          currentUser={user}
                          groups={groups}
                          showBottomNotification={showBottomNotification}
                        />
                      ) : null
                    }
                  />
                ))}
                <Route path='/profile/:id/calendar/settings' component={CalendarSettings} />
              </Switch>
            </div>
          </div>
        </Row>
      </Col>
    )
  );
}
