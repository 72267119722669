import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import { createPost } from 'library/api/posts';
import Button from 'library/common/commonComponents/Buttons/Button';
import Tooltip from 'library/common/commonComponents/Tooltip';
import { getCanBePublicStatus } from 'library/utilities/posts';
import { GroupContext } from 'modules/Group/groupModule';
import Task from './TaskListFrames/Task';
import CreateEditTask from './TaskListFrames/CreateEditTask';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import styles from './taskList.module.scss';

export default function TaskList({
  isOpened,
  taskListTitle,
  openedTasks = [],
  color,
  tasks,
  onEditTaskList,
  onDeleteTaskList,
  onCreatePDFForTaskList,
  onAddTask,
  onDeleteTask,
  onUpdateTask,
  onUpdateTaskStatus,
  manageTaskLists,
  manageTasks,
  proceedUnassignedTask,
  taskLists,
  id,
  user,
  canAddToCalendar,
  isLikeFeatureAvailableInGroup,
  handlePublishTaskList
}) {
  const { t } = useTranslation();
  const { groupInfo } = useContext(GroupContext);
  const canBePublic = getCanBePublicStatus(groupInfo);
  const [isOpen, setIsOpen] = useState(isOpened);
  const [createEditTaskIsOpened, setCreateEditTaskIsOpened] = useState(false);
  const [editTask, setEditTask] = useState({});

  useEffect(() => {
    setIsOpen(isOpened);
  }, [isOpened]);

  const handleEditTask = task => {
    setEditTask(task);
    setCreateEditTaskIsOpened('edit');
  };

  const handleClosePopup = () => {
    setEditTask({});
    setCreateEditTaskIsOpened(false);
  };
  
  const publishTaskList = async () => {
    const groupId = groupInfo.groupId ?? 0;
    const link = `/groups/${groupId}/task-lists/${id}`;
    const name = sanitize(taskListTitle);
    const post = {
      groupId,
      text: t('Tasks.Task list published', { name, link }),
      privatePost: false,
      postType: 0,
      receivers: [],
      requestReturnReceipt: false,
      publicVisible: true
    };

    store.dispatch(
      showBottomNotification(
        "BottomNotifications.Task list published",
        { isFail: false },
      ),
    );

    await createPost(post, () => {});
    await handlePublishTaskList();
  };

  const handleSubmit = async newTask => {
    if (createEditTaskIsOpened === 'add') {
      await onAddTask(newTask);
    } else {
      await onUpdateTask({ ...editTask, ...newTask }, id);
    }
    handleClosePopup();
  };

  const handleCreateTaskListPDF = () => {
    onCreatePDFForTaskList();
  };

  const handleUpdateTaskStatus = (task, updatedStatus) =>
    onUpdateTaskStatus({ ...task, taskStatus: updatedStatus });

  const handleUpdateTaskChecklist = (task, updatedChecklist) =>
    onUpdateTask({ ...task, checklist: updatedChecklist });

  return (
    <div className={styles.container} style={{ borderLeftColor: color }}>
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles.headerLeft}>
          <i className='fa fa-bars' />
          <div className={styles.title}>
            {taskListTitle === 'Other Tasks' ? (
              t('Tasks.Other Tasks')
            ) : (
              <span dangerouslySetInnerHTML={{ __html: sanitize(taskListTitle) }} />
            )}
          </div>
          {tasks && tasks.length > 0 && (
            <div className={styles.taskCount}>{`(${tasks.length})`}</div>
          )}
          {isOpen ? <i className='fa fa-caret-up' /> : <i className='fa fa-caret-down' />}
          {taskListTitle !== 'Other Tasks' && manageTaskLists && (
            <div className={styles.taskListButtons}>
              <div className={styles.taskListButtonEdit} onClick={onEditTaskList}>
                <Tooltip text={t('Tasks.Edit list')} isVisibleCondition>
                  <i className='fa fa-pencil' />
                </Tooltip>
              </div>
              <div className={styles.taskListButtonDelete} onClick={onDeleteTaskList}>
                <Tooltip text={t('Tasks.Delete list')} isVisibleCondition>
                  <i className='fa fa-trash' />
                </Tooltip>
              </div>
            </div>
          )}
        </div>
        <div className={styles.headerRightWrapper}>
          {manageTasks && (
            <Tooltip text={t('Tasks.Add task')} isVisibleCondition>
              <div
                className={styles.iconButton}
                onClick={e => {
                  setCreateEditTaskIsOpened('add');
                  e.stopPropagation();
                }}
              >
                <i className='fa fa-plus' />
              </div>
            </Tooltip>
          )}
          {(groupInfo.groupAdminStatus || user.administrationAccess || user.employee) && (
            <Tooltip text={t('Tasks.Save as PDF')} isVisibleCondition>
              <div
                className={styles.iconButton}
                onClick={e => {
                  e.stopPropagation();
                  handleCreateTaskListPDF();
                }}
              >
                <i className='fa fa-download' />
              </div>
            </Tooltip>
          )}
          {(groupInfo.groupAdminStatus || user.administrationAccess || user.employee) && (
            <Button
              type='info'
              size='sm'
              onClick={e => {
                e.stopPropagation();
                publishTaskList();
              }}
              className={styles.postButton}
            >
              <i className='fa fa-paper-plane' />
            </Button>
          )}
        </div>
      </div>
      {isOpen && (
        <div className={styles.tasksContainer}>
          {tasks && tasks.length ? (
            tasks.map(task => (
              <Task
                key={task.id}
                {...task}
                manageTasks={manageTasks}
                isOpenedByDefault={openedTasks.find(openedTask => openedTask == task.id)}
                proceedUnassignedTask={proceedUnassignedTask}
                onEdit={() => handleEditTask(task)}
                onDelete={() => onDeleteTask(task)}
                onUpdateTaskChecklist={updatedChecklist =>
                  handleUpdateTaskChecklist(task, updatedChecklist)
                }
                onUpdateTaskStatus={updatedStatus => handleUpdateTaskStatus(task, updatedStatus)}
                user={user}
                groupInfo={groupInfo}
                isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
              />
            ))
          ) : (
            <div className={styles.emptyContainer}>{t('Tasks.No pending tasks found')}</div>
          )}
        </div>
      )}
      {createEditTaskIsOpened && (
        <CreateEditTask
          user={user}
          task={editTask}
          isOpened={createEditTaskIsOpened}
          onClose={handleClosePopup}
          onSubmit={handleSubmit}
          canBePublic={canBePublic}
          taskLists={taskLists}
          taskListId={id}
          groupId={groupInfo && groupInfo.groupId}
          canAddToCalendar={canAddToCalendar}
        />
      )}
    </div>
  );
}
