import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';
import { getUser } from 'library/utilities/api';

export const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;
export const URLnotification = `${config.API_BASE_URI}${getPort(8095)}/api`;

export const uploadFilesToGroup = async (payload, setProgress) => {
  const { data } = await axios.post(zuulURL + '/file/_module', payload, {
    onUploadProgress: progressEvent => {
      setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
    },
  });

  const files = [];
  for (const file of data) {
    const user = await getUser(file.userId);
    files.push({
      ...file,
      id: file.posts.id,
      comments: [],
      likes: [],
      user,
    });
  }

  return files;
};

export const getGroupFiles = async (groupId, isProfilePage) => {
  const getFilesUrl = isProfilePage ? `/users/current/profile/posts` : `/groups/post/page`;
  const filter = isProfilePage
    ? ['postType:eq:1', 'and', 'deleted:eq:0']
    : [`groupId:eq:${groupId}`, 'and', 'postType:eq:1', 'and', 'deleted:eq:0'];
  const {
    data: { content },
  } = await axios.post(URL + getFilesUrl, {
    graphql:
      // eslint-disable-next-line
      'id,text,userId,privatePost,files[id,fileId,path,format,size,byteSize,editedAt,streamUpload],userId,user,posts[groupId,privatePost,permanent],comments[id,userId,text,createdAt,editedAt,likes[id,userId]],likes[id,userId],userId,logoUrl,profileLogoAspect,profileLogoWidth,profileLogoXCoordinate,profileLogoYCoordinate',
    filter,
    page: 1,
    size: '10000',
    sort: ['createdAt', 'desc'],
  });

  const files = [];
  for (const post of content) {
    const [file] = post.files;
    const comments = [];
    for (const comment of post.comments) {
      try {
        const commentUser = await getUser(comment.userId);
        const commentLikes = [];

        for (const like of comment.likes) {
          try {
            const likeUser = await getUser(like.userId);

            commentLikes.push({
              ...like,
              name: `${likeUser.firstName} ${likeUser.lastName}`,
            });
          } catch (ex) {
            console.log(ex);
          }
        }

        comments.push({
          ...comment,
          name: `${commentUser.firstName} ${commentUser.lastName}`,
          likes: commentLikes,
        });
      } catch (ex) {
        console.log(ex);
      }
    }
    if (file) {
      try {
        const user = await getUser(post.userId);
        files.push({
          ...file,
          comments,
          likes: post.likes,
          id: post.id,
          isPublic: !post.privatePost,
          description: post.text,
          user,
          userId: post.userId,
          logoUrl: post.logoUrl,
          profileLogoAspect: post.profileLogoAspect,
          profileLogoWidth: post.profileLogoWidth,
          profileLogoXCoordinate: post.profileLogoXCoordinate,
          profileLogoYCoordinate: post.profileLogoYCoordinate,
        });
      } catch (ex) {
        console.log(ex);
      }
    }
  }

  return files;
};

export const updateFile = (id, payload) => axios.patch(zuulURL + '/file' + id, payload);
export const updateFilesName = (id, fileName) =>
  axios.post(zuulURL + '/fileName/' + id, null, { params: { fileId: fileName } });

export const downloadFile = (id, original = '') =>
  axios.get(`${URL}/file/${id}/download${original}`, { responseType: 'blob' });

export const downloadFileChat = id =>
  axios.get(`${URLnotification}/chat/file/${id}/download`, { responseType: 'blob' });

export const getStreamFiles = async (groupId, isProfilePage, folderId) => {
  let getStreamFilesUrl = isProfilePage
    ? `file/stream/currentuser/page`
    : `file/stream/groups/${groupId}/page`;

  getStreamFilesUrl = folderId ? `${getStreamFilesUrl}/${folderId}` : getStreamFilesUrl;

  const {
    data: { content },
  } = await axios.post(`${URL}/${getStreamFilesUrl}`, {
    page: 1,
    size: 10000,
    sort: ['createdAt', 'desc'],
    graphql:
      // eslint-disable-next-line max-len
      'id,fileId,path,createdAt,editedAt,mimeType,byteSize,size,description,userId,firstName,lastName,posts[privatePost,permanent,id,comments[firstName,lastName,id,userId,text,createdAt,editedAt,likes[id,userId]],likes[id,userId]]',
  });

  return content.map(item => ({
    ...item,
    user: { firstName: item.firstName, lastName: item.lastName },
  }));
};

export const checkUserFreeSpaceUsing = async ({ filesSize, groupId, kitaId, userId }) => {
  const { data } = await axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/user/storage`, {
    params: {
      filesSize,
      groupId,
      kitaId,
      userId,
    },
  });
  return data;
};

export const getFilesInFolder = async (groupId, isProfilePage, folderId) => {
  let getStreamFilesUrl = `file/groups/${isProfilePage ? 0 : groupId}/page/`;
  getStreamFilesUrl = folderId ? `${getStreamFilesUrl}${folderId}` : getStreamFilesUrl;

  try {
    const {
      data: { content },
    } = await axios.post(`${URL}/${getStreamFilesUrl}`, {
      page: 1,
      size: 10000,
      sort: ['createdAt', 'desc'],
      graphql:
        // eslint-disable-next-line max-len
        'id,fileId,path,createdAt,editedAt,mimeType,byteSize,size,description,userId,firstName,lastName,posts[privatePost,permanent,id,comments[firstName,lastName,id,userId,text,createdAt,editedAt,likes[id,userId]],likes[id,userId]],taxReceipt[id,date,persons,reason,receiptType,receiptConfirmed],signatureGroupId',
    });

    return content.map(item => ({
      ...item,
      user: { firstName: item.firstName, lastName: item.lastName },
    }));
  } catch (err) {
    return [];
  }
};

export const updateTaxReceiptInfo = requestBody => axios.post(`${URL}/receiptinfo`, requestBody);

export const deleteFiles = fileIds => axios.delete(`${URL}/file/${fileIds}`);

export const moveFileToFolder = (fileIds, folderId) =>
  axios.post(`${URL}/file/${fileIds}/move/${folderId}`);

export const getStreamFilesSize = async groupId => {
  try {
    const { data } = await axios.get(`${URL}/file/stream/groups/${groupId}/size`);
    return data;
  } catch (err) {
    return null;
  }
};

export const updatePrivacyOfMultipleFiles = payload =>
  axios.post(zuulURL + '/file/updatePrivacy', payload);


export const migrateFolders = kitaId => axios.post(`${URL}/migrateFolders/${kitaId}`);

