import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import styles from './messagePopup.module.scss';

export const MessagePopup = ({
  isBottomNotification,
  notificationType,
  showBottomNotificationFromProps,
}) => {
  useEffect(() => {
    if (isBottomNotification) {
      setTimeout(() => showBottomNotificationFromProps(''), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottomNotification]);
  return (
    <div className={cn(styles.messagePopup, isBottomNotification && styles.messagePopup_visible)}>
      {notificationType === 'success' ? (
        <i className='fa fa-check' />
      ) : notificationType === 'fail' ? (
        <i className={cn('fa fa-exclamation-circle', styles.error)} />
      ) : notificationType === 'warning' ? (
        <i className={cn('fa fa-exclamation-triangle', styles.warning)} />
      ) : null}{' '}
      {isBottomNotification}
    </div>
  );
};

const mapStateToProps = state => ({
  isBottomNotification: state.notificationsReducer.isBottomNotification,
  notificationType: state.notificationsReducer.notificationType,
});

const mapDispatchToProps = {
  showBottomNotificationFromProps: showBottomNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessagePopup);
