import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './inputErrorMsg.module.scss';

export default function InputErrorMsg({ className, errorMsg }) {
  return <div className={cn(styles.errorMsg, className)}>{errorMsg}</div>;
}
InputErrorMsg.defaultProps = {
  className: '',
};

InputErrorMsg.propTypes = {
  className: PropTypes.string,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
