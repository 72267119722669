import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validateTokenAndChangeEmail } from 'library/api/user'

import PropTypes from 'prop-types';

export class ConfirmEmailContainer extends Component {

    componentWillMount = () => {
        const token = this.props.match.params.token;
        validateTokenAndChangeEmail(token).then((res) => {
            if (res.status == 200) {
                window.location = '/login';
            } else {
                window.location = '/email-confirmed-error';
            }
        }).catch((err) => {
            window.location = '/email-confirmed-error';
        })
    }

    render() {
        return (null);
    }
}


ConfirmEmailContainer.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    validateTokenAndChangeEmailService: PropTypes.func.isRequired,
};


export default connect()(ConfirmEmailContainer);