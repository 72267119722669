import Button from 'library/common/commonComponents/Buttons/Button';
import React, { useState, useEffect } from 'react';
import Storage from 'library/utilities/storage';


export default function SaveCertificateFrame() {

  const [certificateActive, setCertificateActive] = useState(Storage.getItem('certificate') !== null);

  return (<>
    <h2>Zertifikatsverwaltung</h2>
    <br />
    <table>
      <tr>
        <td>
          <div>
            {certificateActive ?
              <Button type='primary' onClick={() => { Storage.removeItem('certificate'); setCertificateActive(false); }}>Zertifikat entfernen</Button> :
              <Button
                type='primary'
                onClick={() => {
                  Storage.setItem('certificate', `-----BEGIN CERTIFICATE-----
MIIErDCCApQCFGqClFuK1jJrl3aCPIrDq7lkcBhzMA0GCSqGSIb3DQEBCwUAMBcx FTATBgNVBAMMDEJhZWxkdW5nLmNvbTAeFw0yNDExMjExMzI2NDBaFw0yNTExMjEx MzI2NDBaMA4xDDAKBgNVBAMMA0JvYjCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCC AgoCggIBAMv99+5qm9YVcDtKxH0CmLoWlhC4fTo8OdJBzRgLFDk4SanDsEjRN2ks 5hQ4IXtlyGrvMrd3pFPzSO6Nmdxz8hNEGF54kva0ovywtXKKFmq/dN3a53LUg/pT afkzviDRCLys2pzWWTH3KwUGhF2wGnEA3YU8yMkLMavwr+xW5AvK/P2zA6S+LTOF I+pa9HTAit255zP6fATqn4z3Uy5G4hwjZLmfWCw7NFjL9NRm736IaF1ck3EZfWGC RbWYIqDINReOkSfkwN1bG575CCKg/S1XPCgEvNoUahGc3cJRfWcBbZQZCmL3PN+D Ip5+KZA5X0trYGw0FrgG3xZnqORtY3OBWSsgC8Lcj/04QzYiwR9+VX7ZWWiUj9wS CtSNaVPJzCY5/1NavTKx9rR2pwFsS427GusbN+dGhcXvS0ogMDRaaJ9M54yHXlEf jHOBKAMZGedVq+0TjeBMVuA+cZmIVaXPGrnWvrw/7QBkcaUnuIXsXUaouK1TBAa8 lFhaG6bFyu8de1zpZG2xfnGMulFnqPI0593RtxHykuxmB/rYjV0J+yJPdE6LF7yw MX3qDxhc6QKniQyHv1uw3zAkvUHZlkysmD5P4uXNRBdh2c39r/Vz4UJBKDTarREF Hmql9f+gJvlgero5YM9lEKhVobhy2xNBf/4yGoyiMBtLFdf2c29zAgMBAAEwDQYJ KoZIhvcNAQELBQADggIBAGbL1YfwzXgxCPTekb5uwQH8CRcA/vDxv63If3Ad1EMe 0bzwL1Rc6iJDE2urilTCfQcJ//TWiRZdsc8fiXs5yehS8j8Sy+r2Fgw8L+YcYy/y Z7ycbOgpApUpqey+f8O7jJUZmlcZjOH9Dt2H03PqHpBnEjTNyMmoo7U3D/KkN+YW P9lA2zusBLy+FmV+7Ircnr2IiaxP0VTlq2ZnIiEtN0ozNvenE2fsNRjSruJjRc5P +ce98y5Gn1dno8B0Uj2XnlewL2FWYxHRvoUrnGKMY5T3DtNZkyGBq02YBmIq7a78 Nl12LFGax+WT4OYcP9ychyPWGESIeiXXBQphGHRXfj1NKgVua7DJc1nX7KzrBBUG gjyavPuEiHXjAaDRc5lvzg2Fpk9yucaBOMU2GLkgOcVv1tntO0tcueBooV48QC57 ZArwFwAHByDHlJdjAQdkuyvQauqolj5x4E5121gHPdH4NEm4bE6OOaW40nUTtkRX KNkN1bsyEI6aOvTL+hzEROA6wIseTjkzTT6HrpuheyIVV6ZuJmqUwTKR2N1Ua+cy OGNwjNUmDKSBoUfHHIdFvfNlrcs8jCXmXxoCLsJxhuzKJbOcAq/Q/TuJ4r3VOtNV g0KCXPHN6RHcf3N+SWsu+PWSrGWLcsrImpjeI0hB7YYCqma+EglHVnNYuVIP4jk8
-----END CERTIFICATE-----`);
                  setCertificateActive(true);
                }}
              >Zertifikat speichern</Button>}</div>
        </td>
        <td>
          {certificateActive ?
            <div><i style={{ color: "green" }} className='fa fa-shield' /><b> Mitarbeiterzertifikat gespeichert </b></div> :
            <div><i style={{ color: "red" }} className='fa fa-shield' /><b> Mitarbeiterzertifikat nicht gespeichert </b></div>
          }
        </td>

      </tr>
    </table>
    <br />
    <br />
    <code>
      -----BEGIN CERTIFICATE-----<br />
      MIIErDCCApQCFGqClFuK1jJrl3aCPIrDq7lkcBhzMA0GCSqGSIb3DQEBCwUAMBcx
      FTATBgNVBAMMDEJhZWxkdW5nLmNvbTAeFw0yNDExMjExMzI2NDBaFw0yNTExMjEx
      MzI2NDBaMA4xDDAKBgNVBAMMA0JvYjCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCC
      AgoCggIBAMv99+5qm9YVcDtKxH0CmLoWlhC4fTo8OdJBzRgLFDk4SanDsEjRN2ks
      5hQ4IXtlyGrvMrd3pFPzSO6Nmdxz8hNEGF54kva0ovywtXKKFmq/dN3a53LUg/pT
      afkzviDRCLys2pzWWTH3KwUGhF2wGnEA3YU8yMkLMavwr+xW5AvK/P2zA6S+LTOF
      I+pa9HTAit255zP6fATqn4z3Uy5G4hwjZLmfWCw7NFjL9NRm736IaF1ck3EZfWGC
      RbWYIqDINReOkSfkwN1bG575CCKg/S1XPCgEvNoUahGc3cJRfWcBbZQZCmL3PN+D
      Ip5+KZA5X0trYGw0FrgG3xZnqORtY3OBWSsgC8Lcj/04QzYiwR9+VX7ZWWiUj9wS
      CtSNaVPJzCY5/1NavTKx9rR2pwFsS427GusbN+dGhcXvS0ogMDRaaJ9M54yHXlEf
      jHOBKAMZGedVq+0TjeBMVuA+cZmIVaXPGrnWvrw/7QBkcaUnuIXsXUaouK1TBAa8
      lFhaG6bFyu8de1zpZG2xfnGMulFnqPI0593RtxHykuxmB/rYjV0J+yJPdE6LF7yw
      MX3qDxhc6QKniQyHv1uw3zAkvUHZlkysmD5P4uXNRBdh2c39r/Vz4UJBKDTarREF
      Hmql9f+gJvlgero5YM9lEKhVobhy2xNBf/4yGoyiMBtLFdf2c29zAgMBAAEwDQYJ
      KoZIhvcNAQELBQADggIBAGbL1YfwzXgxCPTekb5uwQH8CRcA/vDxv63If3Ad1EMe
      0bzwL1Rc6iJDE2urilTCfQcJ//TWiRZdsc8fiXs5yehS8j8Sy+r2Fgw8L+YcYy/y
      Z7ycbOgpApUpqey+f8O7jJUZmlcZjOH9Dt2H03PqHpBnEjTNyMmoo7U3D/KkN+YW
      P9lA2zusBLy+FmV+7Ircnr2IiaxP0VTlq2ZnIiEtN0ozNvenE2fsNRjSruJjRc5P
      +ce98y5Gn1dno8B0Uj2XnlewL2FWYxHRvoUrnGKMY5T3DtNZkyGBq02YBmIq7a78
      Nl12LFGax+WT4OYcP9ychyPWGESIeiXXBQphGHRXfj1NKgVua7DJc1nX7KzrBBUG
      gjyavPuEiHXjAaDRc5lvzg2Fpk9yucaBOMU2GLkgOcVv1tntO0tcueBooV48QC57
      ZArwFwAHByDHlJdjAQdkuyvQauqolj5x4E5121gHPdH4NEm4bE6OOaW40nUTtkRX
      KNkN1bsyEI6aOvTL+hzEROA6wIseTjkzTT6HrpuheyIVV6ZuJmqUwTKR2N1Ua+cy
      OGNwjNUmDKSBoUfHHIdFvfNlrcs8jCXmXxoCLsJxhuzKJbOcAq/Q/TuJ4r3VOtNV
      g0KCXPHN6RHcf3N+SWsu+PWSrGWLcsrImpjeI0hB7YYCqma+EglHVnNYuVIP4jk8
      <br />-----END CERTIFICATE-----
    </code>

  </>);
}