import React from 'react';
import { Trans } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { URLS } from 'modules/Auth/Authorization/authorizationConstants';
import Storage from 'library/utilities/storage';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { checkMailMessage } from './axios';

const errorHandler = (refreshFn, removeFn) => error => {
  if (error === checkMailMessage) {
    return { status: 401, data: 'checkEmail' };
  }

  if (error.response) {
    const loginURL = URLS.login;

    switch (error.response.status) {
      case 401: {
        return handler401({ error, loginURL, refreshFn, removeFn });
      }
      case 403: {
        return handler403({ error });
      }
      case 413: {
        return handler413({ error });
      }
      case 400:
      case 404:
      case 422:
      case 423:
        return Promise.reject(error);
      default:
        if (error.response && error.response.data) {
          Sentry.captureException(error.response.data);
        } else if (error) {
          Sentry.captureException(error);
        }
        /* store.dispatch(
          showBottomNotification(<Trans i18nKey='BottomNotifications.Something went wrong' />, {
            isFail: true,
          }),
        ); */
        return Promise.reject(error);
    }
  }
};

export default errorHandler;

export function handler401({ error, loginURL, refreshFn, removeFn }) {
  if (Storage.getItem('rememberMe')) {
    if (error.response.config.url !== loginURL) {
      return refreshFn(error.response.config);
    }
  } else if (error.response.config.url !== loginURL) {
    removeFn();
  }

  return Promise.reject(error);
}

export function handler403({ error }) {
  if (
    error.response.data &&
    error.response.data.message === 'Unable to upload file as it appears to be infected'
  ) {
    store.dispatch(
      showBottomNotification(
        <Trans i18nKey='Post.Unable to upload file as it appears to be infected' />,
        { isFail: true },
      ),
    );
  } else if (
    error.response.data &&
    error.response.data.message === 'Chat file upload threshold exceeded'
  ) {
    store.dispatch(
      showBottomNotification(
        // eslint-disable-next-line max-len
        <Trans i18nKey='Chat.Unable to upload files as size exceeds the preset limits, please free some memory and try again' />,
        { isFail: true },
      ),
    );
  }

  return Promise.reject(error);
}

export function handler413({ error }) {
  if (error.response.data && error.response.data.message === 'Payload Too Large') {
    store.dispatch(
      showBottomNotification(<Trans i18nKey='FileManager.Your storage limit is full' />, {
        isFail: true,
      }),
    );
  }

  return Promise.reject(error);
}
