import React, { useState, useEffect, useRef } from 'react';
import Button from 'library/common/commonComponents/Buttons/Button';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Input from 'library/common/commonComponents/Inputs/Input';
import CheckBox from 'library/common/commonComponents/Checkbox';
import { postChristusErloeserConsent, sendPersonalMessageToDiakon } from 'library/api/user';
import SignatureCanvas from 'react-signature-canvas';
import Loader from 'library/common/commonComponents/Loader';

export default function ImageConsent({
  changeCurrentPage,
  handleTextStateChange,
  formData,
  type,
  setFormData,
}) {
  const sigCanvas = useRef();
  const [exchangeButtonClicked, setExchangeButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (type === 'profileFrame') {
      if (formData.signature !== null && formData.signature !== '') {
        sigCanvas.current.fromDataURL(formData.signature);
      }
    }
  }, []);

  return (
    !isLoading ?
      <>
        <b>Einverständniserklärung</b>
        <br />
        <br />
        <CheckBox
          name='Ich willige ein, dass der Name meines/unseres Kindes im Rahmen der
              Erstkommunion-Vorbereitung veröffentlicht wird, dass ein Gruppenfoto im Pfarrbrief GEMEINSAM
              erscheint, dass die Fotos der Erstkommunionfeiern mit den anderen Familien geteilt werden
              (passwortgeschützt) und dass einige Gottesdienste in St. Maximilian Kolbe (jeden Sonn- und
              Feiertag im 10.30 Uhr-Gottesdienst) gestreamt werden. Diese Gottesdienste können nur live
              angeschaut werden, sie werden nicht gespeichert.'
          isChecked={formData.checkBox1}
          onChange={() => {
            setFormData(prev => {
              return { ...prev, checkBox1: !prev.checkBox1 };
            });
          }}
          isDisabled={type === 'profileFrame' ? true : false}
        />
        <CheckBox
          name='Falls ein geteiltest Sorgerecht besteht, ist das zweite Elternteil mit der
              Sakramentenspendung einverstanden.'
          isChecked={formData.checkBox2}
          onChange={() => {
            setFormData(prev => {
              return { ...prev, checkBox2: !prev.checkBox2 };
            });
          }}
          isDisabled={type === 'profileFrame' ? true : false}
        />
        <CheckBox
          name='Ich weiß/wir wissen, dass die Erklärung unseres
              Einverständnisses völlig freiwillig und jederzeit widerruflich ist.'
          isChecked={formData.checkBox3}
          onChange={() => {
            setFormData(prev => {
              return { ...prev, checkBox3: !prev.checkBox3 };
            });
          }}
          isDisabled={type === 'profileFrame' ? true : false}
        />
        <br />
        <br />
        <table>
          <tr>
            <td>Gespräch mit Diakon Thomas anfragen</td>
            <td>
              {exchangeButtonClicked === false ?
                <Button
                  onClick={() => {
                    sendPersonalMessageToDiakon().then(data => {
                      setExchangeButtonClicked(true);
                    });
                  }}
                  disabled={type === 'profileFrame' ? true : false}
                >
                  Gespräch anfragen
                </Button>
                : <div style={{ color: "green" }}>Gespräch mit Diakon Thomas wurde angefragt. Bitte warten Sie auf Rückmeldung.</div>}
            </td>
          </tr>
        </table>
        <br />
        <br />
        <table>
          <tr>
            <Input
              type='date'
              value={formData.signatureDate}
              onChange={e => handleTextStateChange(e.target.value, 'signatureDate')}
              disabled={type === 'profileFrame' ? true : false}
              style={{ width: "400px" }}
            />
          </tr>
        </table>
        <div style={{ border: '1px solid black', width: '402px' }}>
          <SignatureCanvas
            ref={sigCanvas}
            penColor='black'
            canvasProps={{ width: 400, height: 180, className: 'sigCanvas' }}
          />
        </div>
        Unterschrift
        {typeof type === 'undefined' && (
          <i
            style={{ marginLeft: '290px', cursor: 'pointer' }}
            className='fa fa-eraser'
            onClick={() => {
              if (sigCanvas && sigCanvas.current) {
                sigCanvas.current.clear();
              }
            }}
          />
        )}
        <br />
        <br />
        <Button
          onClick={() => {
            changeCurrentPage(-1);
          }}
        >
          Zurück
        </Button>
        &nbsp;
        <Button
          onClick={() => {
            const christusErloeserConsentTemp = {
              ...formData,
              signature: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'),
            };
            setIsLoading(true);
            postChristusErloeserConsent(christusErloeserConsentTemp).then(() => {
              store.dispatch(showBottomNotification(<div>Gespeichert</div>, { isFail: false }));
              const myTimeout = setTimeout(() => {
                window.location.reload();
              }, 1000);
            });
          }}
          type='primary'
          disabled={type === 'profileFrame' ? true : false}
        >
          Ich bin einverstanden
        </Button>
        <br />
        <br />
      </> : <Loader />
  );
}
