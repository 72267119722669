import React, { useState, useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import ColorPickerButton from '../../Buttons/ColorPickerButton';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Label from 'library/common/commonComponents/Label';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import { getJoinPolicies } from 'library/common/commonActions/groupActions';

import styles from './groupCreationPopup.module.scss';

export default function GroupCreationPopup({
  isOpened,
  closePopup,
  next,
  value,
  onDataChange,
  errors,
  blockRequest,
  getJoinPoliciesFromProps,
}) {
  const { t } = useTranslation();
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [joinPolicies, setJoinPolicies] = useState([]);

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsColorPickerVisible(false),
  });

  const onDrag = c => {
    onDataChange({ color: c.hex });
  };

  useEffect(() => {
    if (!blockRequest) {
      getJoinPoliciesFromProps({})
        .then(data => {
          setJoinPolicies(data);
          onDataChange({ joinPolicy: data[0].id });
        })
        .catch(ex => console.log(ex));
      // eslint-disable-next-line
    }
  }, []);
  if (!joinPolicies) return null;
  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='small'
      header={<Trans i18nKey='CreateGroup.Create new space' />}
      footer={
        <Button onClick={next} type='primary'>
          {t('CreateGroup.Next')}
        </Button>
      }
    >
      <div className={cn(styles.container, isDropDownOpened && styles.container_extended)}>
        <div className={styles.inputGroup}>
          <Label type='input'>{t('CreateGroup.Name')} *</Label>
          <div className={styles.inputWithColorPicker} ref={outsideEventContainerRef}>
            <Input
              error={errors.title !== ''}
              value={value.title}
              placeholder={t('CreateGroup.Space name')}
              onChange={e => onDataChange({ title: e.target.value })}
              maxLength={45}
            />
            {errors.title !== '' && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={errors.title} />
              </div>
            )}

            <div className={styles.colorPicker}>
              <div
                className={styles.label}
                onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
              >
                <div className={styles.current} style={{ background: value.color }} />
              </div>
              {isColorPickerVisible && (
                <ColorPickerButton
                  color={value.color}
                  className={styles.pickerComponent}
                  onClick={onDrag}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.inputGroup}>
          <Label type='input'>{t('CreateGroup.Description')}</Label>
          <TextArea
            placeholder={t('CreateGroup.space description')}
            value={value.description}
            error={errors.description !== ''}
            onChange={e => onDataChange({ description: e.target.value })}
          />
          {errors.description !== '' && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={errors.description} />
            </div>
          )}
        </div>
        <div className={cn(styles.dropDown, isDropDownOpened && styles.dropDown_opened)}>
          <div
            className={styles.dropDown__title}
            onClick={() => setIsDropDownOpened(!isDropDownOpened)}
          >
            {t('CreateGroup.Advanced access settings')}
          </div>
          <div className={styles.dropDown__content}>
            <div className={styles.inputGroup}>
              <Label type='input'>{t('CreateGroup.Visibility')}</Label>
              <div className={styles.inputCheckbox}>
                <input
                  type='radio'
                  name='visibility'
                  value='false'
                  checked={value.visibility === false}
                  onChange={() => onDataChange({ visibility: false })}
                  id='visibility1'
                />
                <label htmlFor='visibility1'>
                  {t('CreateGroup.Public (Members only)')}
                  <div className={styles.mark} />
                </label>
              </div>
              <div className={styles.inputCheckbox}>
                <input
                  type='radio'
                  name='visibility'
                  value='true'
                  checked={value.visibility === true}
                  onChange={() => onDataChange({ visibility: true })}
                  id='visibility2'
                />
                <label htmlFor='visibility2'>
                  {t('CreateGroup.Private (Invisible)')}
                  <div className={styles.mark} />
                </label>
              </div>
            </div>
            {!value.visibility && (
              <div className={styles.inputGroup}>
                <Label type='input'>{t('CreateGroup.Join Policy')}</Label>
                {joinPolicies.map(item => (
                  <div key={item.id} className={styles.inputCheckbox}>
                    <input
                      type='radio'
                      name='joinPolicy'
                      value={item.id}
                      onChange={() => onDataChange({ joinPolicy: item.id })}
                      checked={value.joinPolicy === item.id}
                      id={`joinPolicy${item.id}`}
                    />
                    <label htmlFor={`joinPolicy${item.id}`}>
                      {item.policyName}
                      <div className={styles.mark} />
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
}

GroupCreationPopup.defaultProps = {
  getJoinPoliciesFromProps: getJoinPolicies,
  errors: {
    title: '',
    description: '',
  },
  value: {
    title: '',
  },
};
