import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from 'library/common/commonComponents/Popups/Popup';

import styles from './editImagePopup.module.scss';

const date = Date.now();

export default function EditImagePopup({
  isOpened,
  closePopup,
  onEditBtnClick,
  headerText,
  image,
  type,
  showRotation
}) {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(image);
  const { t } = useTranslation();
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    setCrop(image);
    setRotation(image.rotation || 0);
  }, [isOpened]);

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const handleRoteImage = () => {
    setRotation(rotation + 90);
  }

  return (
    <Popup
      size='small'
      isOpened={isOpened}
      closePopup={closePopup}
      header={headerText}
      body={
        <div className={styles.imagePopupContainer}>
          {
            imgRef?.current && showRotation && (
              <div onClick={handleRoteImage} className={styles.btnRotate}>
                 <i className='fa fa-repeat' />
              </div>
            )
          }
          <div className={styles.popupText}>
            <ReactCrop
              src={`${image.url}&t=${date}&original=true`}
              onImageLoaded={onLoad}
              crop={crop}
              imageStyle={{ imageRendering: '-webkit-optimize-contrast', transform: `rotate(${rotation}deg)` }}
              onChange={(_, c) => setCrop(c)}
            />
          </div>
        </div>
      }
      footer={
        <div className={styles.popupBtns}>
          <Button
            onClick={() => {
              onEditBtnClick({
                type,
                imageAspect: crop.aspect,
                imageWidth: crop.width,
                xCoordinate: crop.x || 1,
                yCoordinate: crop.y || 1,
                rotation
              });
              closePopup();
            }}
            type='primary'
          >
            {t('Popup.Save')}
          </Button>
          <Button onClick={closePopup} type='primary'>
            {t('Popup.Cancel')}
          </Button>
        </div>
      }
    />
  );
}
