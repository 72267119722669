export const handleRNMessageHelper = (messageName, callback) => (message) => {
  const ev = JSON.parse(message.data);

  if (!ev || !ev.name) {
    console.warn('RN Event has no name! Aborting.')
    return;
  }

  if (ev.name === messageName) {
    callback(ev);
  }
}
