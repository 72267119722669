import React from 'react';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 12,
  },
  section: {
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 30,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  kitaAndGroupLabel: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 20,
      color: 'grey',
    },
  table: {
    display: 'table',
    marginLeft: '0',
    marginTop: '0',
    marginBottom: 'auto',
    marginRight: 'auto',
  },
  tableHeader: {
    textAlign: 'center',
    fontWeight: 'bold',
    borderTop: 'none',
  },
  tableRow: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #CCC',
  },
  tableCell: {
    borderRight: '1px solid #CCC',
    borderBottom: '1px solid #CCC',
  },
  firstTableCell: {
    borderLeft: '1px solid #CCC',
  },
  green: {
    backgroundColor: 'green',
    color: 'black',
  },
  red: {
    color: 'black',
    backgroundColor: 'red',
  },
  normal: {
    color: 'black',
    backgroundColor: 'transparent',
  },
  nameCellsStyle: {
    textAlign: 'left',
    width: '210px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  notNameCellsStyle: {
    minHeight: '20px',
    width: '20px',
  },
  square: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  yellow: {
    backgroundColor: 'yellow',
  },
  purple: {
    backgroundColor: 'purple',
  },
  colorSquaresContainer: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  colorSquareContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
});

// Define table components
const Table = ({ children }) => <View style={styles.table}>{children}</View>;

const TableHeader = ({ children }) => (
  <View style={[styles.tableRow, styles.tableHeader]}>{children}</View>
);

const TableBody = ({ children }) => <View>{children}</View>;

const TableRow = ({ children }) => <View style={styles.tableRow}>{children}</View>;

const TableCell = ({ children, header, color, length, index, isName }) => {
  let cellColorStyle = styles.normal;
  switch (color) {
    case 'green':
      cellColorStyle = styles.green;
      break;
    case 'red':
      cellColorStyle = styles.red;
      break;
    case 'purple':
      cellColorStyle = styles.purple;
      break;
    case 'yellow':
      cellColorStyle = styles.yellow;
      break;
    default:
      cellColorStyle = styles.normal;
  }
  return (
    <Text
      style={[
        styles.tableCell,
        header && styles.tableHeader,
        index === 0 ? styles.firstTableCell : {},
        cellColorStyle,
        !isName ? styles.notNameCellsStyle : styles.nameCellsStyle,
        { fontSize: `10px` },
      ]}
    >
      {children}
    </Text>
  );
};

// Define component for table headers
const TableHeaderComponent = ({ headers }) => (
  <TableHeader>
    <TableRow>
      {headers.map((headerItem, index) => (
        <TableCell
          header
          index={index}
          key={headerItem.key}
          isName={headerItem.key === 'name' ? true : false}
          color='normal'
          length={Math.floor((100 / headers.length) * 100) / 100}
          size={Math.floor((50 / headers.length) * 100) / 100}
        >
          <Text>{headerItem.title}</Text>
        </TableCell>
      ))}
    </TableRow>
  </TableHeader>
);

// Define component for table rows
const TableRowsComponent = ({ headers, rows }) => (
  <TableBody>
    {rows.map((row, index) => (
      <TableRow key={index}>
        {headers.map((header, index) => {
          let rowTitle = '';
          switch (row[header.key]) {
            case 'yellow':
              break;
            case 'purple':
              break;
            case 'green':
              break;
            case 'red':
              break;
            case 'white':
              break;
            default:
              rowTitle = row[header.key];
          }
          return (
            <TableCell
              header={false}
              index={index}
              isName={header.key === 'name' ? true : false}
              key={header.key}
              color={row[header.key]}
              length={Math.floor((100 / headers.length) * 100) / 100}
              size={Math.floor((50 / headers.length) * 100) / 100}
            >
              <Text>{rowTitle}</Text>
            </TableCell>
          );
        })}
      </TableRow>
    ))}
  </TableBody>
);

const { t } = useTranslation();

const PdfDocument = ({ title, headers, rows, groupName, kitaName }) => (
  <Document>
    <Page style={styles.page} orientation='landscape'>
      <View style={styles.section}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.colorSquaresContainer}>
          <View style={styles.colorSquareContainer}>
            <View style={[styles.square, styles.green]} />
            <Text>Anwesend</Text>
          </View>
          <View style={styles.colorSquareContainer}>
            <View style={[styles.square, styles.yellow]} />
            <Text>Abwesend</Text>
          </View>
          <View style={styles.colorSquareContainer}>
            <View style={[styles.square, styles.red]} />
            <Text>Krank</Text>
          </View>
          <View style={styles.colorSquareContainer}>
            <View style={[styles.square, styles.purple]} />
            <Text>Urlaub</Text>
          </View>
        </View>
        <Table>
          <TableHeaderComponent headers={headers} />
          <TableRowsComponent headers={headers} rows={rows} />
        </Table>
      </View>
      <Text
        style={styles.kitaAndGroupLabel}
        render={() => kitaName == null ? '' : (groupName == null ? kitaName : `${kitaName} - ${groupName}`)}
        fixed
      />
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export const downloadTotalPdf = async (headers, rows, title, fileName, groupName, kitaName) => {
  const blob = pdf(<PdfDocument title={title} headers={headers} rows={rows} groupName={groupName} kitaName={kitaName} />).toBlob();
  blob.then(data => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  });
};

export const downloadKidPdf = async (headers, rows, title, fileName, groupName, kitaName) => {
  const blob = pdf(<PdfDocument title={title} headers={headers} rows={rows} groupName={groupName} kitaName={kitaName} />).toBlob();
  blob.then(data => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  });
};
