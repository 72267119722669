import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Storage from 'library/utilities/storage';
import { addNewKita } from 'library/api/kita';

export default function useAddNewKita({ location, isLoggedIn, showBottomNotification }) {
  const { t } = useTranslation();
  Storage.removeItem('invite-kita-code');

  const [isOpened, setIsOpened] = useState(false);
  const [inviteKitaCodeError, setInviteKitaCodeError] = useState('');
  const [inviteKitaCode, setInviteKitaCode] = useState(getInviteKitaCode(location));
  const [inviteKitaName, setInviteKitaName] = useState(getInviteKitaName(location));

  useEffect(() => {
    if (inviteKitaCode) {
      if (isLoggedIn) {
        setIsOpened(true);
      } else {
        Storage.setItem('invite-kita-code', inviteKitaCode);
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = kitaCode =>
    onSubmitHandler({
      kitaCode,
      setInviteKitaCode,
      setInviteKitaCodeError,
      setInviteKitaName,
      setIsSubmitting,
      setIsOpened,
      t,
      showBottomNotification,
    });

  return {
    isOpened,
    openPopup: () => setIsOpened(true),
    closePopup: () => {
      setInviteKitaCode('');
      setInviteKitaCodeError('');
      setInviteKitaName('');
      setIsOpened(false);
    },
    inviteKitaCode,
    setInviteKitaCode,
    inviteKitaCodeError,
    setInviteKitaCodeError,
    isSubmitting,
    onSubmit,
    inviteKitaName,
  };
}

export function getInviteKitaCode(location) {
  const { pathname } = location;
  const acceptKitaInviteStr = 'accept-kita-invite';
  const acceptKitaIndex = pathname.indexOf(acceptKitaInviteStr);

  if (acceptKitaIndex === -1) return '';

  return pathname.slice(acceptKitaIndex + acceptKitaInviteStr.length + 1);
}

export function getInviteKitaName(location) {
  const { search } = location;
  const kitaNameStr = '?kitaName=';
  const kitaNameIndex = search.indexOf(kitaNameStr);

  if (kitaNameIndex === -1) return '';

  return decodeURI(search.slice(kitaNameIndex + kitaNameStr.length));
}

export async function onSubmitHandler({
  kitaCode,
  setInviteKitaCode,
  setInviteKitaCodeError,
  setInviteKitaName,
  setIsSubmitting,
  setIsOpened,
  t,
  showBottomNotification,
}) {
  if (kitaCode.trim().length === 0) {
    return setInviteKitaCodeError(t('KITAsPanel.KITA code cannot be blank'));
  }
  setInviteKitaCodeError('');
  setIsSubmitting(true);

  try {
    const { data } = await addNewKita(kitaCode.trim());

    setInviteKitaCode('');
    setInviteKitaName('');
    setIsOpened(false);
    if (data) {
      showBottomNotification(t('KITAsPanel.Your request has been sent to admin for approval'));
    } else {
      window.location.reload();
    }
  } catch (err) {
    if (err.response && err.response.status === 403 && err.response.data.message) {
      setInviteKitaCodeError(t(`KITAsPanel.${err.response.data.message}`));
    } else {
      setInviteKitaCodeError(t('KITAsPanel.Invalid Kita Code'));
    }
  } finally {
    setIsSubmitting(false);
  }
}
