const staticTenantDataProtectionData = [
  {
    city: 'Neustadt an der Aisch',
    company: 'Steuerkanzlei Schlicker',
    email: 'info@steuerkanzlei-schlicker.de',
    name: 'Michael Schlicker',
    phone: '09161/8726272',
    street: 'Katharinenweg 18',
    zip: '91413',
    url: 'schlicker.safe2connect.org',
  },
];
export default staticTenantDataProtectionData;
