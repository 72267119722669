import React from 'react';
import cn from 'classnames';

import MessageWithFiles from 'library/common/commonComponents/MessageWithFiles';
import CreateDataprotectionInput from 'library/common/commonComponents/Inputs/CreateDataprotectionInput';

import styles from 'library/common/commonComponents/Inputs/EditorWithButton/editorWithButton.module.scss';

export default function DataProtectionFeedCommon({
  text,
  id,
  isTranslationAllowed,
  isEditing,
  editorProps,
  setIsEditing,
  setFunction,
  option,
  type,
}) {
  return text || isEditing ? (
    isEditing ? (
      <div className={cn(styles.editorContainer)}>
        <CreateDataprotectionInput
          text={text}
          {...editorProps}
          setIsEditing={setIsEditing}
          setFunction={setFunction}
          option={option}
          type={type}
        />
      </div>
    ) : (
      <MessageWithFiles text={text} entityId={id} isTranslationAllowed={isTranslationAllowed} />
    )
  ) : null;
}
