import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import Input from 'library/common/commonComponents/Inputs/Input';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import DeleteButton from 'library/common/commonComponents/Buttons/DeleteButton';
import EditButton from 'library/common/commonComponents/Buttons/EditButton';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Loader from 'library/common/commonComponents/Loader';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import ColorPickerButton from 'library/common/commonComponents/Buttons/ColorPickerButton';

import styles from './calendarSettingsEventTypes.module.scss';

export default function CalendarSettingsEventTypes({
  calendarId,
  submitiing,
  calendarSettingsState,
  calendarSettingsDispatch,
  getEventTypes,
  onCreate,
  onDelete,
  onEdit,
  calendarModulePlace,
}) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getEventTypes(calendarId, calendarModulePlace)
      .then(res => {
        switch (res.status) {
          case 200:
            calendarSettingsDispatch({
              type: 'setSettings',
              payload: {
                eventTypes: res.data,
              },
            });
            setIsLoading(false);
            break;
          default:
            setIsLoading(false);
        }
      })
      .catch(err => {
        if (err.status || err.response.status) {
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line
  }, [calendarId]);

  const { t } = useTranslation();
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [confirmIsOpened, setConfirmIsOpened] = useState(false);
  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsColorPickerVisible(false),
  });
  const [action, setAction] = useState({
    name: '',
    isOpened: false,
  });
  const [event, setEvent] = useState({
    name: '',
    colourCode: '#59d6e4',
    eventTypeLevel:
      calendarModulePlace === 'kitaCalendar'
        ? 'kita'
        : calendarModulePlace === 'userProfileCalendar'
        ? 'user'
        : 'group',
  });
  const [error, setError] = useState('');

  const handleChange = e => {
    setError('');
    setEvent({
      ...event,
      name: e.target.value,
    });
  };

  const handleCreate = () => {
    if (submitiing) {
      return;
    }
    if (!event.name.trim()) {
      setError(t('Calendar.Name cannot be blank'));
      return;
    }
    const newEventTypes = calendarSettingsState.eventTypes.slice();
    const alreadyExist = newEventTypes.find(item => {
      return calendarModulePlace === 'kitaCalendar'
        ? item.name.toLowerCase() === event.name.toLowerCase()
        : item.eventTypeLevel !== 'kita' && item.name.toLowerCase() === event.name.toLowerCase();
    });
    if (alreadyExist) {
      setError(t('Calendar.The given name is already in use'));
      return;
    }

    onCreate(event).then(res => {
      if (res.success) {
        calendarSettingsDispatch({
          type: 'setEventType',
          payload: newEventTypes.concat(res.event),
        });
        setEvent({
          name: '',
          colourCode: '#59d6e4',
          eventTypeLevel:
            calendarModulePlace === 'kitaCalendar'
              ? 'kita'
              : calendarModulePlace === 'userProfileCalendar'
              ? 'user'
              : 'group',
        });
        setAction({
          name: '',
          isOpened: false,
        });
      } else {
        setError('error');
      }
    });
  };

  const handleEdit = () => {
    if (submitiing) {
      return;
    }
    if (!event.name.trim()) {
      setError(t('Calendar.Name cannot be blank'));
      return;
    }

    const newEventTypes = calendarSettingsState.eventTypes.slice();
    const alreadyExist = newEventTypes.find(item => {
      return calendarModulePlace === 'kitaCalendar'
        ? item.id !== event.id && item.name.toLowerCase() === event.name.toLowerCase()
        : item.eventTypeLevel !== 'kita' &&
            item.id !== event.id &&
            item.name.toLowerCase() === event.name.toLowerCase();
    });

    if (alreadyExist) {
      setError(t('Calendar.The given name is already in use'));
      return;
    }

    const index = newEventTypes.findIndex(item => {
      return calendarModulePlace === 'kitaCalendar'
        ? item.id === event.id
        : item.eventTypeLevel !== 'kita' && item.id === event.id;
    });
    if (index !== -1) {
      newEventTypes.splice(index, 1, event);
      onEdit(event).then(res => {
        if (res.success) {
          calendarSettingsDispatch({ type: 'setEventType', payload: newEventTypes });
          setEvent({
            name: '',
            colourCode: '#59d6e4',
            eventTypeLevel:
              calendarModulePlace === 'kitaCalendar'
                ? 'kita'
                : calendarModulePlace === 'userProfileCalendar'
                ? 'user'
                : 'group',
          });
          setAction({
            name: '',
            isOpened: false,
          });
        } else {
          setError('error');
        }
      });
    }
  };

  const handleDelete = () => {
    if (submitiing) {
      return;
    }
    const newEventTypes = calendarSettingsState.eventTypes.slice();
    const index = newEventTypes.findIndex(item => {
      return calendarModulePlace === 'kitaCalendar'
        ? item.name.toLowerCase() === event.name.toLowerCase()
        : item.eventTypeLevel !== 'kita' && item.name.toLowerCase() === event.name.toLowerCase();
    });
    if (index !== -1) {
      newEventTypes.splice(index, 1);
      onDelete(event).then(res => {
        if (res.success) {
          calendarSettingsDispatch({ type: 'setEventType', payload: newEventTypes });
          setEvent({
            name: '',
            colourCode: '#59d6e4',
            eventTypeLevel:
              calendarModulePlace === 'kitaCalendar'
                ? 'kita'
                : calendarModulePlace === 'userProfileCalendar'
                ? 'user'
                : 'group',
          });
          setAction({
            name: '',
            isOpened: false,
          });
          setConfirmIsOpened(false);
        } else {
          setError('error');
        }
      });
    }
  };

  const handleClose = () => {
    setAction({
      name: '',
      isOpened: false,
    });
    setEvent({
      name: '',
      colourCode: '#59d6e4',
      eventTypeLevel:
        calendarModulePlace === 'kitaCalendar'
          ? 'kita'
          : calendarModulePlace === 'userProfileCalendar'
          ? 'user'
          : 'group',
    });
    setError('');
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.titleHeader}>{t('Calendar.Event Type Configuration')}</div>
        <div className={styles.tittleDescription}>
          {t('Calendar.Here you can manage your event types')}
        </div>
      </div>
      {calendarSettingsState.eventTypes.length > 0 && (
        <div className={styles.eventTypesViewContainer}>
          <div className={styles.eventTypesViewTitle}>
            {`${`${t('Calendar.Showing')} ` +
              `1-${calendarSettingsState.eventTypes.length} ${t('Calendar.of')} ` +
              `${calendarSettingsState.eventTypes.length} ${t('Calendar.items')}`}`}
          </div>
          <div className={styles.eventTypesViewList}>
            {calendarSettingsState.eventTypes.map(item => {
              return (
                <div className={styles.eventTypesViewItem} key={item.id}>
                  <div className={styles.eventTypesViewItemLeft}>
                    <div
                      className={styles.eventTypesViewItemColor}
                      style={{ backgroundColor: item.colourCode }}
                    />
                    <div className={styles.eventTypesViewItemTitle}>
                      {item.name}
                    </div>
                  </div>
                  <div className={styles.eventTypesViewItemRight}>
                    {item.eventTypeLevel === 'kita' && calendarModulePlace !== 'kitaCalendar' ? (
                      <div className={styles.eventTypesViewItemType}>(global)</div>
                    ) : (
                      <div className={styles.eventTypesViewItemTypeEditContainer}>
                        <EditButton
                          className={styles.editButton}
                          onClick={() => {
                            setEvent(item);
                            setAction({
                              name: 'edit',
                              isOpened: true,
                            });
                          }}
                        />
                        <DeleteButton
                          className={styles.deleteButton}
                          onClick={() => {
                            setEvent(item);
                            setConfirmIsOpened(true);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <Button
          className={styles.createButton}
          onClick={() =>
            setAction({
              name: 'create',
              isOpened: true,
            })
          }
        >
          <i className='fa fa-plus' />
          {t('Calendar.Create new type')}
        </Button>
      </div>
      <div className={styles.createEventContainer}>
        <Popup
          showHeader='true'
          isOpened={action.isOpened}
          closePopup={handleClose}
          size='small'
          header={
            action.name === 'create' ? (
              <Trans i18nKey='Calendar.Create new event type' />
            ) : (
              <Trans i18nKey='Calendar.Edit event type' />
            )
          }
          footer={
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonSave}>
                <ButtonWithLoader
                  onClick={action.name === 'create' ? handleCreate : handleEdit}
                  type='primary'
                  isLoading={submitiing}
                >
                  {t('Calendar.Save')}
                </ButtonWithLoader>
              </div>
              <div className={styles.buttonCancel}>
                <Button onClick={handleClose}>{t('Calendar.Cancel')}</Button>
              </div>
            </div>
          }
        >
          <div className={styles.inputWithColorPicker} ref={outsideEventContainerRef}>
            <Input
              error={error}
              value={event.name}
              placeholder={t('Calendar.Name')}
              onChange={handleChange}
            />
            {error && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={error} />
              </div>
            )}
            <div className={styles.colorPicker}>
              <div
                className={styles.label}
                onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
              >
                <div className={styles.current} style={{ background: event.colourCode }} />
              </div>
              {isColorPickerVisible && (
                <ColorPickerButton
                  color={event.colourCode}
                  className={styles.pickerComponent}
                  onClick={c =>
                    setEvent({
                      ...event,
                      colourCode: c.hex,
                    })
                  }
                />
              )}
            </div>
          </div>
        </Popup>
      </div>
      <div className={styles.confirmContainer}>
        <Popup
          showHeader='true'
          isOpened={confirmIsOpened}
          closePopup={() => setConfirmIsOpened(false)}
          size='small'
          header={<Trans i18nKey='Calendar.Confirm Deletion' />}
          footer={
            <div className={styles.confirmButtonsContainer}>
              <div className={styles.buttonCancel}>
                <Button onClick={() => setConfirmIsOpened(false)}>{t('Calendar.Cancel')}</Button>
              </div>
              <div className={styles.buttonDelete}>
                <ButtonWithLoader onClick={handleDelete} type='primary' isLoading={submitiing}>
                  {t('Calendar.Delete')}
                </ButtonWithLoader>
              </div>
            </div>
          }
        >
          <div className={styles.confirmText}>{t('Calendar.Do you really want to delete')}</div>
        </Popup>
      </div>
    </div>
  );
}
