import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { deleteComment, updateComment, updateCommentStatus } from 'library/api/comments';
import Card from 'library/common/commonComponents/Card';
import EditorWithButton from 'library/common/commonComponents/Inputs/EditorWithButton';
import Comment from 'library/common/commonComponents/Comment';
import useCommentsInput from 'library/common/commonHooks/useCommentsInput';
import useComments from './hooks/useComments';
import useCommentsToggle from './hooks/useCommentsToggle';
import store from 'main/store/configureStore';

import styles from './feedItemComments.module.scss';

export default function FeedItemComments({
  postId,
  user,
  comments,
  setComments,
  isOpened,
  showBottomNotification,
  createCommentStatus,
  groupId,
  activeKita,
  usersTitle,
  isInProfileStream,
  isLikeFeatureAvailableInGroup,
  likeFeatureEnabled,
  isGroupAdmin
}) {
  const { t } = useTranslation();

  const { setShowAllComments, shouldRenderShowAllButton, commentsToDisplay } = useComments(
    comments,
  );

  const { ref, commentsIsOpened } = useCommentsToggle(isOpened);

  const { Editor, error, onSubmit, progress, focusOnEditor, isSubmiting } = useCommentsInput({
    user,
    id: postId,
    comments,
    setComments,
    groupId,
  });

  const [isOpenedOld, setIsOpenedOld] = useState(isOpened);
  useEffect(() => {
    if (isOpened !== isOpenedOld) {
      setIsOpenedOld(isOpened);
      if (isOpened) {
        focusOnEditor();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  const onDelete = id => {
    return deleteComment(id)
      .then(res => {
        if (res.status === 200) {
          setComments(comments.filter(comment => comment.id !== id));
          showBottomNotification(t('BottomNotifications.The content has been deleted'));
        }
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      });
  };

  async function updateCommentById(id, payload) {
    try {
      const { data } = await updateComment(id, payload);
      const newComment = {
        ...comments.find(comment => comment.id === id),
        text: data.text,
        files: data.files,
        editedAt: new Date().getTime(),
      };
      const newComments = comments.map(comment => (comment.id === id ? newComment : comment));
      setComments(newComments);

      showBottomNotification(t('BottomNotifications.Saved'));
    } catch (ex) {
      showBottomNotification(t('BottomNotifications.Something went wrong'));
      // eslint-disable-next-line
      console.log(ex);
    }
  }

  async function approveCommentById(id) {
    try {
      await updateCommentStatus(id, 'APPROVED');
      setComments(cms=> cms.map(c=> c.id === id ? {...c, approveStatus: 'APPROVED'} : c));
      store.dispatch(
        showBottomNotification(t('Post.CommentIsApproved'))
      );
    } catch (error) {
      showBottomNotification(t('BottomNotifications.Something went wrong'));
    }
  }

  async function rejectCommentById(id) {
    try {
      await updateCommentStatus(id, 'REJECTED');
      setComments(cms=> cms.map(c=> c.id === id ? {...c, approveStatus: 'REJECTED'} : c));
      store.dispatch(
        showBottomNotification(t('Post.CommentIsRejected'))
      );
    } catch (error) {
      showBottomNotification(t('BottomNotifications.Something went wrong'));
    }
  }

  return (
    <Card cardRef={ref} className={cn(styles.comments, { [styles.isOpened]: commentsIsOpened })}>
      {shouldRenderShowAllButton && (
        <>
          <div className={styles.showAllButton} onClick={() => setShowAllComments(true)}>
            {t('Post.Show all {count} comments').replace('{count}', comments.length)}
          </div>
          <div className={styles.hr} />
        </>
      )}

      {commentsToDisplay.map(comment => (
        <Comment
          user={user}
          key={comment.id}
          onDelete={onDelete}
          updateCommentById={updateCommentById}
          approveCommentById={()=> approveCommentById(comment.id)}
          rejectCommentById={()=> rejectCommentById(comment.id)}
          isGroupAdmin={isGroupAdmin}
          postId={postId}
          activeKita={activeKita}
          usersTitle={usersTitle}
          isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
          likeFeatureEnabled={likeFeatureEnabled}
          {...comment}
        />
      ))}
      {createCommentStatus && (
        <EditorWithButton
          Editor={Editor}
          error={error}
          onSubmit={onSubmit}
          btnText={t('Post.Send')}
          className={styles.editor}
          isSubmiting={isSubmiting}
          isInProfileStream={isInProfileStream}
          groupId={groupId}
          user={user}
        />
      )}
      {progress && (
        <div className={styles.progressBar}>
          <div className={styles.progressBarValue} style={{ width: progress + '%' }} />
        </div>
      )}
    </Card>
  );
}
