import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import useReactRouter from "use-react-router";

import { getAllMembershipRequestOfGroup } from "library/common/commonActions/groupActions";
import Card from 'library/common/commonComponents/Card';
import Tabs from 'library/common/commonComponents/Tabs';

import MembersFrame from './GroupBodyMembersFrames/Members';
import MembershipRequestFrame from './GroupBodyMembersFrames/MembershipRequestFrame';

import styles from './groupBodyMembers.module.scss';

export default function GroupBodyMembers() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [membershipRequestList, setMembershipRequestList] = useState([]);
  const [membershipRequestCount, setMembershipRequestCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { match } = useReactRouter();
  const groupId = match.params.id;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const res = await getAllMembershipRequestOfGroup(groupId);
    setMembershipRequestList(res.data);
    setMembershipRequestCount(res.data.length);
    setIsLoading(false);
  }, [groupId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const tabs = [
    {
      title: t('GroupBody.Members.Members'),
      path: 'member-list',
      component: <MembersFrame />,
    },
    {
      title: `${t('GroupBody.Members.Membership Requests')} (${membershipRequestCount})`,
      path: 'membership-requests',
      component: (
        <MembershipRequestFrame
          membershipRequestList={membershipRequestList}
          isLoading={isLoading}
          reloadData={fetchData}
        />
      ),
    },
  ];
  return (
    <Card title={<Trans i18nKey='GroupBody.Members.Title' />} className={styles.body}>
      <Tabs
        tabs={tabs}
        onChange={(_, index) => setActiveTab(index)}
        currentTab={tabs[activeTab].path}
      />
    </Card>
  );
}
