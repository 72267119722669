import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import DefaultContractEdit from 'modules/DefaultContractEdit';
import DefaultContractOverview from 'modules/DefaultContractOverview';
import DefaultContractChangePopup from 'modules/DefaultContractChangePopup';
import Tabs from 'library/common/commonComponents/Tabs';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import styles from './defaultContractEditWrapper.module.scss';
import HelpText from 'library/common/commonComponents/HelpText';
import { useTranslation } from 'react-i18next';

function DefaultContractEditWrapper({ user }) {

  console.log(user);
  const [currentTab, setCurrentTab] = useState(0);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { t } = useTranslation();
  const tabs = [
    {
      title: t('DefaultContractEdit.EditContract'),
      component: <DefaultContractEdit />,
      path: 'edit-contracts',
    },
    {
      title: t('DefaultContractEdit.OverviewContract'),
      component: <DefaultContractOverview />,
      path: 'overview-contracts',
    },
  ];

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className={styles.container}>
          <div className={styles.header}>
            {(user.employee || user.administrationAccess) && <DefaultContractChangePopup settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />}
            <span className={styles.title}>{t('DefaultContractEdit.Header')}</span>
            <HelpText>{t('DefaultContractEdit.description')}</HelpText>
          </div>
          <Tabs
            tabs={tabs}
            currentTab={tabs[currentTab].path}
            onChange={(_, index) => setCurrentTab(index)}
            styles={{ marginLeft: '150px' }}
          />
        </div>
      </Wrapper>
    </KitaPanelWrapper>
  );
}

export default connect(state => ({
  user: state.userReducer,
}))(DefaultContractEditWrapper);