import React , { useEffect } from 'react';
import cn from 'classnames';

import styles from './recipientsDropdown.module.scss';
import ListItemWithDelete from '../../ListItems/ListItemWithDelete/ListItem';

export default function RecipientsDropdown({
  isOpened,
  items,
  onDeleteButtonClick,
  theme,
  dropdownRef,
  groupIndex,
}) {
  
  return (
    isOpened ? (
      <div
        className={cn(styles.dropdown, getClassByTheme(theme))}
        ref={dropdownRef}
      >
        {items.length>0 && items.map((item,index) =>(
          <div key={item.id}>
            <ListItemWithDelete
              item={item}
              theme={theme}
              groupIndex={groupIndex}
              id={index}
              className={styles.listItem}
              onDeleteButtonClick={onDeleteButtonClick}
            />
          
          </div>
        ),
        )}
      </div>
    ):
      <div />
  );
}

export const DropdownSeparator = () => (
  <div className={styles.separator} style={{ backgroundColor: '#FFFFFF', borderLeft: '#FFFFFF' }} />
);

export function getClassByTheme(theme) {
  if (
    window.location.hostname.toLowerCase().includes('bvf4u.safe2connect.org') &&
    theme === 'yellow'
  ) {
    return styles.themeBlue;
  }

  if (
    window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org') &&
    theme === 'yellow'
  ) {
    return styles.themeBrown;
  }

  switch (theme) {
    case 'light':
      return styles.themeLight;
    case 'yellow':
      return styles.themeYellow;
    case 'dark':
      return styles.themeDark;
    default:
      return styles.themeDark;
  }
}
