import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';

import Tabs from 'library/common/commonComponents/Tabs';
import store from 'main/store/configureStore';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import { getUserWatsonLanguage } from 'library/common/commonActions/userActions';

import useKitaModules from './hooks/useKitaModules';
import Installed from './ModulesFrameFrames/Installed';
import ModuleSettings from './ModulesFrameFrames/ModuleSettings/ModuleSettingsContainer';

import styles from './modulesFrame.module.scss';

function ModulesFrame({ showBottomNotification }) {
  useSiteTitle('AdministrationModules');
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);
  const [activeModule, setActiveModule] = useState({});

  const onError = () =>
    showBottomNotification(t('BottomNotifications.Something went wrong'), { isFail: true });
  const onSuccess = module => {
    if (module.moduleKey === 'translations') {
      if (module.activeStatus) {
        setTimeout(() => {
          store.dispatch(getUserWatsonLanguage());
        }, 500);
      }
    }
    showBottomNotification(t('BottomNotifications.Saved'));
  };

  const { isLoading, modules, onSetAsDefault, onChangeActivate } = useKitaModules({
    onError,
    onSuccess,
  });

  const handleOpenSettings = module => {
    setActiveModule(module);
    setIsSettingsOpened(true);
  };

  const tabs = [
    {
      title: t('Administration.ModulesSection.Installed'),
      path: 'modules',
      component: (
        <Installed
          isLoading={isLoading}
          modules={modules}
          onOpenSettings={handleOpenSettings}
          onSetAsDefault={onSetAsDefault}
          onChangeActivate={onChangeActivate}
        />
      ),
    },
  ];

  return isSettingsOpened ? (
    <ModuleSettings module={activeModule} showBottomNotification={showBottomNotification} />
  ) : (
    <div className={styles.container}>
      <div className={styles.title}>
        {<Trans i18nKey='Administration.ModulesSection.Modules directory' />}
      </div>
      <Tabs
        tabs={tabs}
        onChange={(_, index) => setActiveTab(index)}
        currentTab={tabs[activeTab].path}
      />
    </div>
  );
}

export default connect(state => ({
  langCode: state.userReducer.langCode,
}))(ModulesFrame);
