import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;

export const alreadySigned = fileId => axios.get(zuulURL + '/signature/exists/' + fileId);

export const finalize = (fileId, groupId, data) =>
  axios.post(zuulURL + '/signature/finalize/' + fileId + '/' + groupId, data);

export const overwrite = (fileId, userId, data) =>
  axios.post(zuulURL + '/signature/overwrite/' + fileId + '/' + userId, data);

export const onSignaturePostEnabled = (fileId, groupId) =>
  axios.post(zuulURL + '/signature/enable/' + fileId + '/' + groupId);

export const findSignatureDataForUsers = (fileId, userIds) =>
  axios.get(
    zuulURL +
      '/signature/findUserData/' +
      fileId +
      (userIds.length > 0 ? '?users=' + userIds.join('&users=') : ''),
  );
