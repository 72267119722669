import React from 'react';
import styles from './employeeReviewCheckInOutList.module.scss';
import { useTranslation } from 'react-i18next';
import { reviewManualEmployeeCheckInOut } from 'library/api/employee';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ReviewButtons from 'library/common/commonComponents/Buttons/ReviewButtons';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import EditEmployeeCheckInOutTime from '../../EditEmployeeCheckInOutTime';

export default function EmployeeReviewCheckInOutList({
  unapprovedCheckInOuts,
  setUnapprovedCheckInOuts,
  handleGetUnapprovedCheckInOuts,
  setIsLoading,
}) {
  const { t } = useTranslation();
  const user = useSelector(state => state.userReducer);

  const handleReviewButtonClick = async (employeeCheckInId, employeeCheckOutId, reviewStatus) => {
    const checkInOut = {
      employeeCheckInId,
      employeeCheckOutId,
      reviewStatus,
    };

    setIsLoading(true);
    try {
      await reviewManualEmployeeCheckInOut(checkInOut);
      // skip refetching by updating local state
      let updatedUnapprovedCheckInOuts = [...unapprovedCheckInOuts].map(checkInOut => {
        if (checkInOut.id === employeeCheckInId || checkInOut.id === employeeCheckOutId) {
          checkInOut.reviewStatus = reviewStatus;
        }
        return checkInOut;
      });

      // hide approved checkInOuts since they don't need to be reviewed
      updatedUnapprovedCheckInOuts = updatedUnapprovedCheckInOuts.filter(
        checkInOut => checkInOut.reviewStatus !== 'APPROVED',
      );

      setUnapprovedCheckInOuts(updatedUnapprovedCheckInOuts);

      store.dispatch(
        showBottomNotification(
          reviewStatus === 'APPROVED'
            ? t('EmployeeCheckinout.Working time approved')
            : t('EmployeeCheckinout.Working time rejected'),
          {
            isSuccess: true,
          },
        ),
      );
    } catch (err) {
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formateDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', {
      weekday: 'short',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const formatNote = note => {
    const autoCheckOut = 'EmployeeCheckinout.Automatic checkout';
    if (note?.includes(autoCheckOut)) {
      return note.replace(autoCheckOut, t(autoCheckOut));
    } else {
      return note;
    }
  };

  return (
    <div className={styles.container}>
      <h2>{t('EmployeeCheckinout.Review manual working time entries')}</h2>

      {unapprovedCheckInOuts.length !== 0 ? (
        <table className={styles.holidaysTable}>
          <thead className={styles.holidaysTableHeader}>
            <tr>
              <th className={styles.statusColumn}>{t('EmployeeCheckinout.Status')}</th>
              <th className={styles.nameColumn}>{t('EmployeeCheckinout.Employee')}</th>
              <th className={styles.periodColumn}>{`${t('EmployeeCheckinout.Period')} / ${t(
                'EmployeeCheckinout.Reason',
              )}`}</th>
              <th />
            </tr>
          </thead>
          <tbody className={styles.tableBodyCheckInOuts}>
            {unapprovedCheckInOuts.map((checkInOut, index, array) => {
              // return row every second iteration to group checkin and checkout
              if (index % 2 === 1) {
                return (
                  <tr key={checkInOut.id}>
                    <td
                      className={cn(
                        styles.statusColumn,
                        checkInOut.reviewStatus === 'APPROVED' && styles.statusAccepted,
                        checkInOut.reviewStatus === 'REJECTED' && styles.statusDeclined,
                        checkInOut.reviewStatus === 'OPEN' && styles.statusOpen,
                      )}
                    >
                      <i
                        className={cn(
                          'fa',
                          'fa-2x',
                          checkInOut.reviewStatus === 'APPROVED' && 'fa-check-circle',
                          checkInOut.reviewStatus === 'REJECTED' && 'fa-minus-circle',
                          checkInOut.reviewStatus === 'OPEN' && 'fa-question-circle',
                        )}
                        aria-hidden='true'
                      />
                    </td>
                    <td>{checkInOut.name}</td>
                    <td className={styles.checkInOutDateTime}>
                      <span className={styles.checkDate}>{formateDate(checkInOut.actionDate)}</span>
                      <br />
                      <span className={styles.statusIn}>
                        <EditEmployeeCheckInOutTime
                          checkInOut={array[index - 1]}
                          handleGetUnapprovedCheckInOuts={handleGetUnapprovedCheckInOuts}
                        />
                      </span>
                      <span className={styles.statusOut}>
                        <EditEmployeeCheckInOutTime
                          checkInOut={checkInOut}
                          handleGetUnapprovedCheckInOuts={handleGetUnapprovedCheckInOuts}
                        />
                      </span>
                      <br />
                      <span className={styles.reason}>{formatNote(checkInOut.note)}</span>
                    </td>
                    {(user.administrationAccess || user.superAdminStatus) && (
                      <td>
                        <ReviewButtons
                          handleReviewButtonClick={(id, status) =>
                            handleReviewButtonClick(id.checkInId, id.checkOutId, status)
                          }
                          id={{
                            checkInId: array[index - 1].id,
                            checkOutId: checkInOut.id,
                          }}
                          reviewStatus={checkInOut.reviewStatus}
                        />
                      </td>
                    )}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      ) : (
        <p className={styles.noManualCheckInOuts}>
          {t('EmployeeCheckinout.All manual working time entries are reviewed')}
        </p>
      )}
    </div>
  );
}
