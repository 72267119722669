import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Storage from 'library/utilities/storage';

const toggleablePrefix = 'toggleablestatus-';

export function useToggleable(name) {
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState(getToggleableStatus(name));

  const toggleOption = isOpened
    ? {
        faIcon: 'fa-minus-square',
        title: t('Toggleable.Collapse'),
      }
    : {
        faIcon: 'fa-plus-square',
        title: t('Toggleable.Expand'),
      };

  toggleOption.onClick = () => {
    Storage.setItem(toggleablePrefix + name, !isOpened);
    setIsOpened(!isOpened);
  };

  return { isOpened, toggleOption };
}

function getToggleableStatus(name) {
  switch (Storage.getItem(toggleablePrefix + name)) {
    case false: {
      return false;
    }
    default: {
      return true;
    }
  }
}
