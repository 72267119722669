import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import React, { useState } from 'react';
import HelpText from 'library/common/commonComponents/HelpText';
import {
  transferAllKidsToKitalino,
} from 'library/api/kids';

import styles from './kitalinoInterface.module.scss';
import Loader from 'library/common/commonComponents/Loader';

export default function KitalinoInterfaceSettings() {

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  return (
    !isLoading ?
      <>
        <div className={styles.title}>{t('Administration.Kitalino settings')}</div>
        <div className={styles.text}>
          <HelpText>{t('Administration.Kitalino settings description')}</HelpText>
        </div>
        <Button
          onClick={() => {
            setIsLoading(true);
            transferAllKidsToKitalino().finally(() => {
              setIsLoading(false);
            });
          }}
        >{t('Administration.Export all kids to kitalino')}</Button>
      </> : <Loader />

  );
}
