import React, { useEffect, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import Module from 'library/common/commonComponents/Module';
import DisableModulePopup from 'library/common/commonComponents/Popups/DisableModulePopup';
import { getUserModules, changeModuleStatus } from 'library/api/user';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import getModuleText from 'library/utilities/getModuleText';

import styles from './modulesFrame.module.scss';
import { isAwoWW } from 'library/api/tenantConfig';

export function modulesReducer(state, action) {
  switch (action.type) {
    case 'SET_MODULES':
      return action.modules;
    case 'TOGGLE_MODULE': {
      return state.map(module =>
        module.moduleId === action.moduleId
          ? { ...module, activeStatus: !module.activeStatus }
          : module,
      );
    }
    default:
      return state;
  }
}

export default function ModulesFrame({ user, match, showBottomNotification }) {
  useSiteTitle('Modules');
  const [modules, dispatch] = useReducer(modulesReducer, []);
  const [confirmProps, setConfirmProps] = useState({
    text: '',
    closePopup: () => { },
    onConfirm: () => { },
    isOpened: false,
    isTranslationsModule: false,
  });

  useEffect(() => {
    getUserModules(user.id).then(({ data }) => {
      let tempModules = data.modules;
      if (isAwoWW()) {
        tempModules = tempModules.filter(md => md.moduleKey !== "gallery" && md.moduleKey !== "files");
      }
      dispatch({ type: 'SET_MODULES', modules: tempModules })
    });
    // eslint-disable-next-line
  }, []);

  const changeModule = (module, radioButtonValue) =>
    changeModuleHandler({ module, radioButtonValue, showBottomNotification, t, dispatch });

  const setModule = module =>
    setModuleHandler({ module, setConfirmProps, confirmProps, changeModule, t });

  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          {t('GroupBody.Modules.Enhance your profile with modules')}
        </div>
        <div className={styles.modules}>
          <div className={styles.moduleWrapper}>
            {modules.map(item => (
              <Module
                key={item.moduleId}
                match={match}
                module={item}
                setModuleHandler={setModule}
              />
            ))}
          </div>
        </div>
      </div>
      <DisableModulePopup {...confirmProps} />
    </>
  );
}

export function setModuleHandler({ module, setConfirmProps, confirmProps, changeModule }) {
  if (module.activeStatus) {
    setConfirmProps({
      text: getModuleText(module),
      closePopup: () => {
        setConfirmProps({ ...confirmProps, ...{ isOpened: false } });
      },
      onConfirm: async radioButtonValue => {
        await changeModule(module, radioButtonValue);
        return setConfirmProps({ ...confirmProps, ...{ isOpened: false } });
      },
      isOpened: true,
      isTranslationsModule: module.moduleKey === 'translations',
    });
  } else {
    return changeModule(module);
  }
}

export function changeModuleHandler({
  module,
  radioButtonValue,
  showBottomNotification,
  t,
  dispatch,
}) {
  const { moduleId, activeStatus } = module;

  return changeModuleStatus({
    moduleStatus: activeStatus ? radioButtonValue : 'ENABLE',
    moduleId,
  })
    .then(() => {
      showBottomNotification(t('GroupBody.Modules.Saved'));
      dispatch({
        type: 'TOGGLE_MODULE',
        moduleId,
      });
    })
    .catch(() =>
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      }),
    );
}
