import React, { useEffect, useRef } from 'react';
import blueimp from 'blueimp-gallery';
import 'blueimp-gallery/css/blueimp-gallery.min.css';

import { getNodeList } from 'library/utilities/dom';

Carousel.defaultProps = {
  showControls: false,
};
export default function Carousel({ imageLinks, showControls, shouldInit }) {
  const containerRef = useRef(null);
  useEffect(initBlueimp(shouldInit, imageLinks, containerRef), [shouldInit]);

  return (
    <div ref={containerRef} className='blueimp-gallery blueimp-gallery-carousel'>
      <div className='slides' />
      {/* eslint-disable-next-line */}
      <h3 className='title' />
      {/* eslint-disable-next-line */}
      {showControls && (
        <>
          {/* eslint-disable-next-line */}
          <a className='prev'>‹</a>
          {/* eslint-disable-next-line */}
          <a className='next'>›</a>
          {/* eslint-disable-next-line */}
          <a className='close'>×</a>
          {/* eslint-disable-next-line */}
          <a className='play-pause' />
          <ol className='indicator' />
        </>
      )}
    </div>
  );
}

export function initBlueimp(shouldInit, imageLinks, containerRef) {
  return () => {
    if (shouldInit) {
      blueimp(getNodeList(imageLinks), {
        container: containerRef.current,
        carousel: true,
        continue: true,
      });
    }
  };
}
