import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const usermanagementURL = `${config.API_BASE_URI}${getPort(8080)}/api`;

export const postCoronaTest = async data => {
  const apiResult = await axios.post(URL + '/savecoronatest', {
    ...data,
  });
};

export const getCoronaTest = kidId => {
  return axios.get(`${URL}/getparentcoronatesthistory/${kidId}`);
};

export const getCoronaTestGroupData = groupId => {
  return axios.get(`${URL}/getcoronatestgroupoverview/${groupId}`);
};

export const getCoronaTestConfig = kitaId => {
  return axios.get(`${URL}/getcoronatestconfig/${kitaId}`);
};

export const getCoronaTestConsent = () => {
  return axios.get(`${URL}/getcoronatestconsent`);
};

export const getCoronaTestShareConsent = () => {
  return axios.get(`${URL}/getcoronatestshareconsent`);
};

export const updateCoronaTestConsent = (gaveShareConsent = false) => {
  return axios.post(`${URL}/updatecoronatestconsent`, null, { params: { gaveShareConsent } });
};
export const updateCoronaTestShareConsent = () => {
  return axios.post(`${URL}/updatecoronatestshareconsent`);
};
