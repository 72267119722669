import React, { useState } from 'react';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import styles from './employeeAbsenceSettings.module.scss';
import cn from 'classnames';
import Loader from 'library/common/commonComponents/Loader';
import { updateEmployeeInformation } from 'library/api/employee';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

export default function EmployeeAbsenceSettings({ employee, employeeAbsenceDays, className }) {
  const { t } = useTranslation();
  const [absences, setAbsences] = useState(employeeAbsenceDays);
  const [subtractLegalBreak, setSubtractLegalBreak] = useState(
    employee.employeeInformation?.subtractLegalBreak,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveButton = async data => {
    setIsLoading(prev => !prev);
    try {
      await updateEmployeeInformation(data);
    } catch (error) {
      store.dispatch(showBottomNotification(t(error), { isFail: true }));
    }
    setIsLoading(false);
  };

  return (
    <div className={cn(className, styles.employeeItemContainer)}>
      <div className={styles.profile}>
        <div>
          <img
            className={styles.profileAvatar}
            src={employee.user.modifiedProfileLogoUrl || defaultAvatar}
          />
        </div>
        <div className={styles.profileName}>{`${employee.user.firstName} ${
          employee.user.lastName
        }`}</div>
      </div>
      <div className={styles.employeeInfoContainer}>
        <h3>{t('Administration.Number of absent days')}</h3>
        <div className={styles.holidaysInputContainer}>
          {/* heading */}
          <div className={cn(styles.holidaysInputWrapper, styles.heading)}>
            <div className={styles.daysLabel}>{t('Administration.Absence')}</div>
            <span className={styles.daysAvailable}>{t('Administration.Available')}</span>
            <div className={styles.holidaysInput}>{t('Administration.Total')}</div>
          </div>

          {absences.map((absence, index) => {
            return (
              <div key={absence.absenceType} className={styles.holidaysInputWrapper}>
                <div>
                  <label className={styles.daysLabel} htmlFor='numberDays'>
                    {t(absence.absenceName)}
                  </label>
                </div>
                <span className={styles.daysAvailable}>
                  {`${absence.daysTotal - absence.daysTaken}`}
                </span>

                <Input
                  id='numberDays'
                  type='number'
                  min='0'
                  step='0.5'
                  value={absence.daysTotal}
                  onChange={e => {
                    const newAbsenceDays = [...absences];
                    newAbsenceDays[index].daysTotal = parseFloat(e.target.value);
                    setAbsences([...newAbsenceDays]);
                  }}
                  className={styles.holidaysInput}
                />
              </div>
            );
          })}

          <Checkbox
            isChecked={subtractLegalBreak}
            onChange={() => setSubtractLegalBreak(prev => !prev)}
            className={styles.subtractLegalBreakCheckbox}
            name={t(
              'Administration.Subtract legal break from working time and consider manual break',
            )}
          />
        </div>

        <Button
          onClick={() => {
            handleSaveButton({
              userId: employee.user.id,
              subtractLegalBreak: subtractLegalBreak,
              employeeAbsenceDays: absences.map(absence => {
                return {
                  absenceType: absence.absenceType,
                  daysTotal: parseFloat(absence.daysTotal),
                };
              }),
            });
          }}
          type='primary'
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader isSmall={true} color='#fff' />
          ) : (
            <>
              <i className='fa fa-check' aria-hidden='true' />
              {t('Administration.UserSection.Save')}
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
