import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import Checkbox from 'library/common/commonComponents/Checkbox';
import useKitaFields from 'library/common/commonHooks/useKitaFields';
import Loader from 'library/common/commonComponents/Loader';
import Select from 'library/common/commonComponents/Inputs/Select';

import styles from './kitaOpeningHours.module.scss';
import store from 'main/store/configureStore';
import HelpText from 'library/common/commonComponents/HelpText';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Input from 'library/common/commonComponents/Inputs/Input';
import { getKitaOpeningHours, saveKitaOpeningHours } from 'library/api/kita';
import Button from 'library/common/commonComponents/Buttons/Button';

export default function KitaOpeningHours(activeKita) {
    const { t } = useTranslation();

    const [count, setCount] = useState(0)

    useEffect(() => {
        let interimOpeningHours = openingHours
        getKitaOpeningHours(activeKita.activeKita.activeKita.kitaId).then((res) => {
            if (res.data) {
                const response = res.data;
                interimOpeningHours["monday"]["startTime"] = response["mondayStart"] == null ? '' : formatBackendTime(response["mondayStart"])
                interimOpeningHours["monday"]["endTime"] = response["mondayEnd"] == null ? '' : formatBackendTime(response["mondayEnd"])
                interimOpeningHours["tuesday"]["startTime"] = response["tuesdayStart"] == null ? '' : formatBackendTime(response["tuesdayStart"])
                interimOpeningHours["tuesday"]["endTime"] = response["tuesdayEnd"] == null ? '' : formatBackendTime(response["tuesdayEnd"])
                interimOpeningHours["wednesday"]["startTime"] = response["wednesdayStart"] == null ? '' : formatBackendTime(response["wednesdayStart"])
                interimOpeningHours["wednesday"]["endTime"] = response["wednesdayEnd"] == null ? '' : formatBackendTime(response["wednesdayEnd"])
                interimOpeningHours["thursday"]["startTime"] = response["thursdayStart"] == null ? '' : formatBackendTime(response["thursdayStart"])
                interimOpeningHours["thursday"]["endTime"] = response["thursdayEnd"] == null ? '' : formatBackendTime(response["thursdayEnd"])
                interimOpeningHours["friday"]["startTime"] = response["fridayStart"] == null ? '' : formatBackendTime(response["fridayStart"])
                interimOpeningHours["friday"]["endTime"] = response["fridayEnd"] == null ? '' : formatBackendTime(response["fridayEnd"])
                interimOpeningHours["saturday"]["startTime"] = response["saturdayStart"] == null ? '' : formatBackendTime(response["saturdayStart"])
                interimOpeningHours["saturday"]["endTime"] = response["saturdayEnd"] == null ? '' : formatBackendTime(response["saturdayEnd"])
                interimOpeningHours["sunday"]["startTime"] = response["sundayStart"] == null ? '' : formatBackendTime(response["sundayStart"])
                interimOpeningHours["sunday"]["endTime"] = response["sundayEnd"] == null ? '' : formatBackendTime(response["sundayEnd"])
                setOpeningHours(interimOpeningHours)
                validateOpeningHours({ ...openingHours })
            }
        })
    }, [count])



    const saveOpeningHours = () => {
        const payload = {
            "kitaID": activeKita.activeKita.activeKita.kitaId,
            "mondayStart": formatTime(openingHours["monday"]["startTime"]),
            "mondayEnd": formatTime(openingHours["monday"]["endTime"]),
            "tuesdayStart": formatTime(openingHours["tuesday"]["startTime"]),
            "tuesdayEnd": formatTime(openingHours["tuesday"]["endTime"]),
            "wednesdayStart": formatTime(openingHours["wednesday"]["startTime"]),
            "wednesdayEnd": formatTime(openingHours["wednesday"]["endTime"]),
            "thursdayStart": formatTime(openingHours["thursday"]["startTime"]),
            "thursdayEnd": formatTime(openingHours["thursday"]["endTime"]),
            "fridayStart": formatTime(openingHours["friday"]["startTime"]),
            "fridayEnd": formatTime(openingHours["friday"]["endTime"]),
            "saturdayStart": formatTime(openingHours["saturday"]["startTime"]),
            "saturdayEnd": formatTime(openingHours["saturday"]["endTime"]),
            "sundayStart": formatTime(openingHours["sunday"]["startTime"]),
            "sundayEnd": formatTime(openingHours["sunday"]["endTime"])
        }
        saveKitaOpeningHours(payload).then((res) => {
            store.dispatch(
                showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
                    isFail: false,
                }),
                setCount(count + 1)
            )
        })
    }

    const formatBackendTime = (timeString) => {
        // Split the time string into hours, minutes, and seconds
        const [hours, minutes] = timeString.split(':');

        // Format the time as "HH:MM"
        return `${hours}:${minutes}`;
    };

    const formatTime = (timeString) => {
        if (timeString == "") {
            return null;
        }
        // Parse the time string
        const time = new Date(`1970-01-01T${timeString}`);
        // Extract hours, minutes, and seconds
        const hours = ('0' + time.getHours()).slice(-2); // Ensure two digits
        const minutes = ('0' + time.getMinutes()).slice(-2); // Ensure two digits
        const seconds = ('0' + time.getSeconds()).slice(-2); // Ensure two digits
        // Construct the formatted time string
        const formattedTime = `${hours}:${minutes}:${seconds}`;
        return formattedTime;
    };

    const [initialOpeningHoursData, setInitialOpeningHoursData] = useState([
        { name: 'Monday', field: 'monday' },
        { name: 'Tuesday', field: 'tuesday' },
        { name: 'Wednesday', field: 'wednesday' },
        { name: 'Thursday', field: 'thursday' },
        { name: 'Friday', field: 'friday' },
        { name: 'Saturday', field: 'saturday' },
        { name: 'Sunday', field: 'sunday' }]);

    const [openingHours, setOpeningHours] = useState({
        monday: { startTime: '', endTime: '' },
        tuesday: { startTime: '', endTime: '' },
        wednesday: { startTime: '', endTime: '' },
        thursday: { startTime: '', endTime: '' },
        friday: { startTime: '', endTime: '' },
        saturday: { startTime: '', endTime: '' },
        sunday: { startTime: '', endTime: '' },
    });

    const [totalHours, setTotalHours] = useState(0);

    const handleTimeChange = (day, field, value) => {
        const updatedHours = { ...openingHours };
        const oldValue = updatedHours[day][field];
        updatedHours[day][field] = value;

        if (field === 'endTime' && value && updatedHours[day].startTime && value < updatedHours[day].startTime) {
            // If end time is less than start time, revert to previous value
            updatedHours[day][field] = oldValue;
        } else if (field === 'startTime' && value && updatedHours[day].endTime && value > updatedHours[day].endTime) {
            // If start time is greater than end time, revert to previous value
            updatedHours[day][field] = oldValue;
        } else {
            setOpeningHours(updatedHours);
            validateOpeningHours(updatedHours);
        }
    };

    const validateOpeningHours = (hours) => {
        // Validation logic to ensure end time is not less than start time
        for (const day in hours) {
            const { startTime, endTime } = hours[day];
            if (startTime && endTime && endTime < startTime) {
                store.dispatch(
                    showBottomNotification(t('AccountSettings.AbsenceSettings.End date can not be less then start'), {
                        isFail: true,
                    }),
                );
                return; // Do not calculate total hours if validation fails
            }
        }
        calculateTotalHours(hours);
    };

    const calculateTotalHours = (hours) => {
        let totalHours = 0;
        for (const day in hours) {
            const { startTime, endTime } = hours[day];
            if (startTime && endTime) {
                const start = new Date(`1970-01-01T${startTime}`);
                const end = new Date(`1970-01-01T${endTime}`);
                const diff = (end - start) / (1000 * 60 * 60); // Difference in hours
                totalHours += diff;
            }
        }

        // Convert total hours to hours and minutes format
        const hoursPart = Math.floor(totalHours);
        const minutesPart = Math.round((totalHours - hoursPart) * 60);

        // Construct the total hours string
        let totalHoursString = '';
        if (hoursPart > 0) {
            totalHoursString += `${hoursPart} ${hoursPart === 1 ? 'hour' : 'hours'}`;
        }
        if (minutesPart > 0) {
            totalHoursString += ` ${minutesPart} ${minutesPart === 1 ? 'minute' : 'minutes'}`;
        }
        if (!totalHoursString) {
            totalHoursString = '0 hours';
        }

        setTotalHours(totalHoursString);
    };

    const handleDeleteRow = (field) => {
        // Create a copy of the openingHours object
        const updatedOpeningHours = { ...openingHours };

        // Remove the startTime and endTime values associated with the specified field
        updatedOpeningHours[field] = {
            startTime: '',
            endTime: ''
        };

        // Update the state with the modified openingHours object
        setOpeningHours(updatedOpeningHours);
    };

    return (
        <><div className={styles.holidaysContainer}>
            <div className={styles.holidayGrid}>
                <div className={styles.gridHeader}>
                    <div>{t('Administration.Day')}</div>
                    <div>{t('Administration.Holiday from')}</div>
                    <div>{t('Administration.Holiday to')}</div>
                </div>
                {initialOpeningHoursData.length > 0 && initialOpeningHoursData?.map(({ name, field }, index) => (
                    <div className={styles.gridRow} key={index}>
                        <div>{t(name)}</div>
                        <Input
                            type='time'
                            value={openingHours[field]?.startTime}
                            onChange={(e) => handleTimeChange(field, 'startTime', e.target.value)} />
                        <Input
                            type='time'
                            value={openingHours[field]?.endTime}
                            onChange={(e) => handleTimeChange(field, 'endTime', e.target.value)} />
                        <Button className={styles.deleteButton} onClick={() => handleDeleteRow(field)}>
                            <i className={styles.trashButton} class="fa fa-trash"></i>
                        </Button>
                    </div>
                ))}
            </div>
        </div>
            <br></br>
            <div className={styles.lastContainer}>
                <p className={styles.totalHours}>{t('Administration.KidSection.Total Hours')}: {totalHours}</p>
                <Button type="primary" onClick={saveOpeningHours} className={styles.saveButton}>{t('Chat.Save')}</Button>
            </div></>
    )
}