import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';
import { postEntity } from 'library/common/commonConstants/graphql';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api/parentsurvey/`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api/parentsurvey/`;

export const createParentSurvey = parentSurvey => {
  const payload = {
    ...parentSurvey,
    graphql: postEntity,
  };

  return axios.post(zuulURL, JSON.stringify(payload));
};

export const updateParentSurvey = ({ id, parentSurvey }) => {
  const payload = {
    ...parentSurvey,
    graphql: postEntity,
  };
  return axios.put(URL + id, { ...payload });
};

export const submitVotes = (id, parentSurveyQuestionOptionIds, secondaryAnswers) => {
  return axios.post(URL + id + '/vote', {
    parentSurveyQuestionOptionIds,
    parentSurveyQuestionOptionSecondaryAnswers: secondaryAnswers,
    graphql: postEntity,
  });
};

export const copyParentSurveyToGroups = (surveyId, groupIds) => {
  return axios.post(URL + `${surveyId}/copy?${groupIds}`);
};

export const publishParentSurvey = (id, payload) => {
  return axios.put(URL + id + '/publish', { ...payload, graphql: postEntity });
};

export const resetParentSurvey = (id, payload) => {
  return axios.put(URL + id + '/reset', { ...payload, graphql: postEntity });
};

export const endParentSurvey = (id, payload) => {
  return axios.put(URL + id + '/end', { ...payload, graphql: postEntity });
};

export const voteForSurvey = (id, voteIds) =>
  axios.post(`${URL}${id}/option/vote`, {
    voteIds,
    graphql: postEntity,
  });

export const addEditParentSurveySuggestion = (
  parentSurveyId,
  parentSurveyQuestionId,
  optionId,
  answer,
) =>
  axios.put(`${URL}${parentSurveyId}/${parentSurveyQuestionId}/suggestion`, {
    answer,
    optionId: optionId || 0,
    graphql: postEntity,
  });

export const deleteParentSurveySuggestion = (parentSurveyId, optionId) =>
  axios.post(`${URL}${parentSurveyId}/${optionId}/suggestion/delete`, {
    optionId,
    graphql: postEntity,
  });
