import React, { useEffect } from 'react';

import useCachedImage from 'library/common/commonHooks/useCachedImage';

import styles from './bottombarGroupLogo.module.scss';

export default function BottombarGroupLogo({ groupInfo, letter }) {
  const { CachedImage, refreshImage } = useCachedImage({
    originalSrc: groupInfo.logoUrl,
    props: {
      alt: 'logo',
      className: styles.logoImg,
      key: 'bottomGroupLogo',
    },
  });

  useEffect(() => {
    setTimeout(() => {
      refreshImage();
    }, 300);
  }, [groupInfo.logoUrl]);

  return groupInfo && groupInfo.logoUrl ? (
    <div className={styles.wrapper}>{CachedImage}</div>
  ) : (
    letter
  );
}
