import { useSelector } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { getKitaFields, updateKitaFields } from 'library/api/kitaFields';

// Chosen kita is used when kita fields are needed for a kita that is not the active kita
export default function useKitaFields(chosenKita) {
  const [kitaFields, setKitaFields] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const activeKita = useSelector(state => state.kitaReducer.activeKita);

  const [selectedKita, setSelectedKita] = useState({
    value: activeKita.kitaId,
    label: activeKita.kitaName,
  });
  const kitasRaw = useSelector(state => state.kitaReducer.kitas);

  const kitas = useMemo(
    () => kitasRaw?.map(kita => ({ value: kita.kitaId, label: kita.kitaName })) ?? [],
    kitasRaw,
  );
  const [kitaFieldsLoading, setKitaFieldsLoading] = useState(false);
  const [updateKitaFieldsLoading, setUpdateKitaFieldsLoading] = useState(false);

  useEffect(() => {
    // Fetch Kita fields here only if chosen kita is not defined
    if (!chosenKita) {
      if (selectedKita.value) {
        setKitaFieldsLoading(true);
        getKitaFields(selectedKita.value)
          .then(res => {
            setCustomFields(res.data.customFields);
            setKitaFields(res.data.fields);
            setKitaFieldsLoading(false);
          })
          .catch(() => {
            setKitaFieldsLoading(false);
          });
      }
    }
  }, [selectedKita.value]);

  // This will run only if chosenKita is passed
  useEffect(() => {
    const id = chosenKita?.value ?? chosenKita?.kitaId;
    if (id) {
      setKitaFieldsLoading(true);
      getKitaFields(id)
        .then(res => {
          setCustomFields(res.data.customFields);
          setKitaFields(res.data.fields);
          setKitaFieldsLoading(false);
        })
        .catch(() => {
          setKitaFieldsLoading(false);
        });
    }
  }, [chosenKita?.value, chosenKita?.kitaId]);

  const update = async (fields, customFields) => {
    try {
      const removeEmptyCustomField = customFields.filter(x=>!!x.fieldName);
      setUpdateKitaFieldsLoading(true);
      await updateKitaFields(selectedKita.value, {fields, customFields: removeEmptyCustomField});
      return true;
    } catch (err) {
      throw err;
    } finally {
      setUpdateKitaFieldsLoading(false);
    }
  };

  if (chosenKita) {
    return {
      kitaFields,
      kitaFieldsLoading,
      kitaCustomFields: customFields
    };
  }

  return {
    kitaFields,
    kitaFieldsLoading,
    kitas,
    selectedKita,
    setSelectedKita,
    updateKitaFields: update,
    updateKitaFieldsLoading,
    kitaCustomFields: customFields,
  };
}
