import React from 'react';
import cn from 'classnames';

import styles from './label.module.scss';

export default function Label({ type, children, backgroundColor, className }) {
  return (
    <div
      style={{ background: backgroundColor }}
      className={cn(styles.label, getClassByType(type), className)}
    >
      {children}
    </div>
  );
}

export function getClassByType(type) {
  switch (type) {
    case 'info':
      return styles.labelInfo;
    case 'info_gray':
      return styles.labelInfoGray;
    case 'input':
      return styles.labelInput;
    case 'default':
      return styles.labelDefault;
    case 'success':
      return styles.labelSuccess;
    case 'danger':
      return styles.labelDanger;
    default:
      return styles.labelInfo;
  }
}
