import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import BackgroundWrapper from 'library/common/commonComponents/to-refactor/commonBackgroundWrapper';
import Card from 'library/common/commonComponents/to-refactor/commonCard';
import Input from 'library/common/commonComponents/to-refactor/commonInput';
import CardHeading from 'library/common/commonComponents/to-refactor/commonCard/CardHeading';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import CardWrapper from 'library/common/commonComponents/to-refactor/commonCardWrapper';
import AuthSuccessMessage from 'library/common/commonComponents/AuthSuccessMessage';
import { validateEmail } from 'library/utilities/validation';

import styles from './forgotPassword.module.scss';

import { sendEmailForResetPassword } from './forgotPasswordActions';

export class ForgotPassword extends Component {
  state = {
    bounce: true,
    email: '',
    emailError: '',
    errorMessage: '',
    isDisabled: false,
    resetEmailSent: false,
    inputError: '',
  };

  onHandleChangeValue = event => {
    if (this.state.emailError || this.state.errorMessage) {
      this.setState({ emailError: '', inputError: false, errorMessage: '' });
    }
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  backPress = () => {
    this.props.history.push('/login');
  };

  resetPassword = () => {
    const { email } = this.state;
    const trimmed = email.trim();
    if (this.checkInputForPasswordRecovery(trimmed)) {
      this.setState({ isDisabled: true, inputError: false }, async () => {
        const { success } = await this.props.sendEmailForResetPassword(trimmed);
        if (!success) {
          this.setState({
            emailError: i18n.t("PasswordRecovery.Email address doesn't exist"),
            isDisabled: false,
          });
        } else {
          this.setState({
            resetEmailSent: true,
            isDisabled: false,
          });
        }
      });
    } else {
      this.setState({ inputError: true, resetEmailSent: false });
    }
  };

  checkInputForPasswordRecovery(email) {
    const trimmed = email.trim();
    if (!trimmed) {
      this.setState({
        inputError: false,
        errorMessage: '',
        emailError: i18n.t('PasswordRecovery.Email cannot be blank'),
        bounce: false,
      });
      return false;
    }

    if (!validateEmail(trimmed)) {
      this.setState({
        inputError: false,
        errorMessage: '',
        emailError: i18n.t('Signup.You did not use an valid email'),
        bounce: false,
      });
      return false;
    }

    this.setState({
      emailError: '',
      inputError: false,
      errorMessage: '',
      bounce: false,
    });
    return true;
  }

  renderForgotPasswordModule() {
    const { bounce, email, emailError, errorMessage, isDisabled, inputError } = this.state;
    return (
      <CardWrapper>
        <Card
          className={cn(styles.fp_card_container, 'animated', bounce && 'bounceIn', {
            shake: inputError,
          })}
        >
          <CardHeading className={styles.fp_heading_padding_15}>
            <strong>{i18n.t('PasswordRecovery.Password')}</strong>
            <span className={styles.fp_heading_text_password}>
              {i18n.t('PasswordRecovery.recovery')}
            </span>
          </CardHeading>
          <CardHeading className={styles.fp_heading_2}>
            <span>{i18n.t('PasswordRecovery.Just enter email')}</span>
          </CardHeading>
          <div>
            <Input
              data-test='email-text-input'
              className={cn(styles.email_form)}
              placeholder={i18n.t('PasswordRecovery.Your email')}
              name='email'
              error={!!emailError}
              value={email}
              type='text'
              onChange={this.onHandleChangeValue}
              onEnterDown={!isDisabled ? this.resetPassword : null}
            />
            {!!emailError && (
              <div className={styles.error_msg} data-test='email-error'>
                <InputErrorMsg errorMsg={emailError} />
              </div>
            )}
          </div>
          <div className={styles.error_msg}>
            <InputErrorMsg
              data-test='error-message'
              errorMsg={errorMessage && !errorMessage !== 'Error' && errorMessage}
            />
          </div>
          <div className={styles.btnContainer}>
            <ButtonWithLoader
              data-test='button-reset'
              type='primary'
              isLoading={isDisabled}
              onClick={!isDisabled ? this.resetPassword : null}
            >
              {i18n.t('PasswordRecovery.Reset password')}
            </ButtonWithLoader>

            <Button
              data-test='button-back'
              type='primary'
              isFullWidth={false}
              onClick={!isDisabled ? this.backPress : null}
            >
              {i18n.t('PasswordRecovery.Back')}
            </Button>
          </div>
        </Card>
      </CardWrapper>
    );
  }

  render() {
    return (
      <BackgroundWrapper>
        {!this.state.resetEmailSent && this.renderForgotPasswordModule()}
        {this.state.resetEmailSent && (
          <AuthSuccessMessage
            buttonLabel='PasswordRecovery.Back to home'
            buttonPress={this.backPress}
            title='PasswordRecovery.Password'
            subtitle='PasswordRecovery.recovery'
            message="PasswordRecovery.We've sent you an email"
          />
        )}
      </BackgroundWrapper>
    );
  }
}

export default connect(
  null,
  { sendEmailForResetPassword },
)(ForgotPassword);

ForgotPassword.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  sendEmailForResetPassword: PropTypes.func.isRequired,
};
