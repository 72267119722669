export const URLS = {
  search: '/user/search',
  profile: '/profile',
  watsonLanguage: '/users/current/translation/',
  staticLanguage: '/users/current/static-translation/',
  watsonConsent: '/users/current/translationAllowed',
};

const PREFIX = '@@user';

export const START_LOADING = `${PREFIX}START_LOADING`;
export const END_LOADING = `${PREFIX}END_LOADING`;
export const SET_USER_INFO = `${PREFIX}SET_USER_INFO`;
export const SET_USER_PERMISSIONS = `${PREFIX}SET_USER_PERMISSIONS`;
export const SET_WATSON_LANGUAGE = `${PREFIX}SET_WATSON_LANGUAGE`;
export const SET_WATSON_LANGUAGES = `${PREFIX}SET_WATSON_LANGUAGES`;
export const SET_WATSON_USER_CONSENT = `${PREFIX}SET_WATSON_USER_CONSENT`;
export const SET_SELECTED_USERS = `${PREFIX}SET_SELECTED_USERS`;
