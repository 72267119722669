import * as actionTypes from '../commonConstants/userConstants';
import { REMOVE_AUTH_TOKEN } from '../commonConstants/commonConstants';

const initialState = {
  isLoading: false,
  id: null,
  firstName: null,
  lastName: null,
  orderFavorites: false,
  userPermissions: null,
  watsonIsActivated: false,
  currentWatsonLang: null,
  currentWatsonLangs: null,
  currentWatsonUserConsent: false,
  selectedUsers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.END_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_WATSON_LANGUAGE:
      return {
        ...state,
        currentWatsonLang: action.language,
      };
    case actionTypes.SET_WATSON_LANGUAGES:
      return {
        ...state,
        currentWatsonLangs: action.languages,
      };
    case actionTypes.SET_WATSON_USER_CONSENT:
      return {
        ...state,
        currentWatsonUserConsent: action.consent,
      };
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        ...action.userInfo,
        id: state.id || parseInt(action.userInfo.id, 10),
      };
    case REMOVE_AUTH_TOKEN:
      return { ...initialState };
    case actionTypes.SET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.data,
      };
    case actionTypes.SET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.data,
      };
    default:
      return state;
  }
};
