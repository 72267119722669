import * as aT from 'library/common/commonConstants/commonConstants';
import { setToken, getToken, removeToken, removeLoginTime } from 'library/utilities/token';

export const setAuthentication = data => dispatch => {
  setToken(data);

  dispatch({
    type: aT.SET_AUTH_TOKEN,
    payload: data,
  });
};

export const getAuthentication = () => dispatch => {
  const user = getToken();
  return new Promise((resolve, reject) => {
    if (user) {
      const tokenObject = {
        token: user,
        isLoggedIn: true,
      };

      dispatch({
        type: aT.GET_AUTH_TOKEN,
        payload: tokenObject,
      });

      resolve(user);
    } else {
      reject(null);
    }
  });
};

export const removeAuthentication = () => dispatch => {
  removeToken();
  removeLoginTime();
  dispatch({
    type: aT.REMOVE_AUTH_TOKEN,
    payload: {},
  });
};

export function deleteDeviceToken(){
  return new Promise(function(resolve, reject){
    [window, document].forEach(el =>
      el.addEventListener('deviceTokenRemoved', e => {
        resolve(e.detail);
      }),
    );
    window.rnOnLogout(getToken() && getToken().access_token);
  });
};
