import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

export const ColorPickerButton = ({ onClick, color, onMouseUp, className }) => {
  const [currentColor, setCurrentColor] = useState(color);

  return (
    <div>
      <SketchPicker
        styles={{ zindex: 10000 }}
        color={currentColor}
        onChange={hex => setCurrentColor(hex)}
        onChangeComplete={onClick}
        onMouseUp={onMouseUp}
        disableAlpha={true}
      />
    </div>
  );
};

export default ColorPickerButton;
