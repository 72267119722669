import { useState, useEffect, useReducer } from 'react';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';

import { getPathWithGroupId } from 'library/utilities/groups';
import * as galleriesApi from 'library/api/galleries';
import { getGalleryFiles, filterDependingOnBazaar } from 'library/utilities/gallery';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';

export function galleriesReducer(state, action) {
  switch (action.type) {
    case 'setGalleries':
      return action.galleries;
    case 'addGallery':
      return state.concat(action.gallery);
    case 'deleteGallery':
      return state.filter(({ id }) => id !== action.id);
    case 'updateGallery':
      return state.map(gallery => (gallery.id === action.id ? action.gallery : gallery));
    default:
      return state;
  }
}

export default function useGalleryLoading(
  groupId,
  groupInfo,
  patchGroup,
  userId,
  kitaId,
  isProfilePage,
  isBazaar,
) {
  const { history, match } = useReactRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [galleries, dispatch] = useReducer(galleriesReducer, []);
  const [openedGalleryId, setOpenedGalleryId] = useState(null);

  useEffect(() => {
    if (!openedGalleryId) {
      setIsLoading(true);
      galleriesApi.getGalleries({ groupId, userId, kitaId, isProfilePage }).then(response => {
        if (response && response.data) {
          const galleriesFiltered = filterDependingOnBazaar(isBazaar, response.data.content);
          dispatch({ type: 'setGalleries', galleries: galleriesFiltered });
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [openedGalleryId]);

  const createGallery = payload =>
    createGalleryHandler({
      payload,
      groupId,
      dispatch,
      groupInfo,
      patchGroup,
      setOpenedGalleryId,
      userId,
      kitaId,
      isProfilePage,
      isBazaar,
    });
  const deleteGallery = id =>
    deleteGalleryHandler({ id, dispatch, groupInfo, patchGroup, galleries });
  const editGallery = ({ id, payload }) => editGalleryHandler({ id, payload, dispatch });

  const deleteImage = ({ postId, fileId }) =>
    deleteImageHandler({ postId, fileId, dispatch, groupInfo, patchGroup, galleries });

  const deleteAllImagesFromGallery = ({ galleryId, filesDispatch }) => {
    return deleteAllImagesFromGalleryHandler({
      galleryId,
      dispatch,
      filesDispatch,
      groupInfo,
      patchGroup,
      galleries
    });
  };

  const editImageDescription = ({
    description,
    price,
    bazaarItemInfoDescription,
    postId,
    fileId,
  }) => {
    editImageDescriptionHandler({
      description,
      price,
      bazaarItemInfoDescription,
      postId,
      fileId,
      dispatch,
    });
  };

  const [progress, setProgress] = useState(null);
  const { t } = useTranslation();
  const uploadFilesToGallery = ({ id, images, privatePost }) =>
    uploadFilesToGalleryHandler({
      id,
      images,
      privatePost,
      setProgress,
      patchGroup,
      groupInfo,
      t,
      isBazaar,
    });

  return {
    isLoading,
    galleries,
    createGallery,
    deleteGallery,
    editGallery,
    uploadFilesToGallery,
    progress,
    deleteImage,
    editImageDescription,
    openedGalleryId,
    setOpenedGalleryId: value => {
      setOpenedGalleryId(value);
      if (!value) {
        const domain = isBazaar ? 'bazaar' : 'gallery';
        history.push(`${getPathWithGroupId(match.path)}/${domain}`);
        setIsLoading(true);
      }
    },
    deleteAllImagesFromGallery,
  };
}

export async function createGalleryHandler({
  payload,
  groupId,
  dispatch,
  groupInfo,
  patchGroup,
  setOpenedGalleryId,
  userId,
  kitaId,
  isProfilePage,
  isBazaar,
}) {
  payload.isBazaar = isBazaar;
  const { data } = await galleriesApi.createGallery({
    payload,
    groupId,
    userId,
    kitaId,
    isProfilePage,
  });
  if (groupInfo.gallerySnippet.latestSelected) {
    patchGroup({ gallerySnippet: { ...groupInfo.gallerySnippet, galleryId: data.id, files: [] } });
  }
  dispatch({ type: 'addGallery', gallery: data });
  setOpenedGalleryId(data.id);
}

export async function deleteGalleryHandler({ id, dispatch, groupInfo, patchGroup, galleries }) {
  await galleriesApi.deleteGallery({ id });
  dispatch({ type: 'deleteGallery', id });
  if (groupInfo.gallerySnippet.galleryId === id) {
    const latestGallery = galleries[galleries.length - 2];
    if (latestGallery) {
      patchGroup({
        gallerySnippet: {
          ...groupInfo.gallerySnippet,
          galleryId: latestGallery.id,
          files: getGalleryFiles(latestGallery),
        },
      });
    }
  }
}

export async function editGalleryHandler({ id, payload, dispatch }) {
  const { data } = await galleriesApi.editGallery({ id, payload });
  dispatch({ type: 'updateGallery', id, gallery: data });
}

export async function uploadFilesToGalleryHandler({
  id,
  images,
  privatePost,
  setProgress,
  patchGroup,
  groupInfo,
  t,
}) {
  try {
    const { data } = await galleriesApi.uploadFilesToGallery({
      id,
      images,
      privatePost,
      setProgress,
    });

    patchGroup({ postCount: groupInfo.postCount + images.length });

    return data;
  } catch (ex) {
    if (ex && ex.response && ex.response.status === 413) {
      store.dispatch(showBottomNotification(t('FileManager.Size error'), { isFail: true }));
    }
    return { files: [] };
  } finally {
    setProgress(null);
  }
}

export async function deleteImageHandler({
  postId,
  fileId,
  dispatch,
  groupInfo,
  patchGroup,
  galleries,
}) {
  const { groupId } = groupInfo;
  const res = await galleriesApi.deleteImageFromGallery({ postId, fileId, groupId });
  const { data } = res;

  if (data === null) {
    return res;
  }

  dispatch({ type: 'updateGallery', id: data.id, gallery: data });
  if (groupInfo.gallerySnippet.latestSelected) {
    const latestGallery = galleries[galleries.length - 2];
    if (latestGallery) {
      patchGroup({
        gallerySnippet: {
          ...groupInfo.gallerySnippet,
          galleryId: latestGallery.id,
          files: getGalleryFiles(data),
        },
      });
    }
  }
}

export async function deleteAllImagesFromGalleryHandler({
  galleryId,
  dispatch,
  filesDispatch,
  groupInfo,
  patchGroup,
  galleries,
}) {
  const { groupId } = groupInfo;
  const res = await galleriesApi.deleteAllImagesFromGallery({ galleryId, groupId });
  const { data } = res;
  filesDispatch({ type: 'deleteAllImages' });

  if (data === null) {
    return res;
  }

  dispatch({ type: 'updateGallery', id: data.id, gallery: data });
  if (groupInfo.gallerySnippet.latestSelected) {
    const latestGallery = galleries[galleries.length - 2];
    if (latestGallery) {
      patchGroup({
        gallerySnippet: {
          ...groupInfo.gallerySnippet,
          galleryId: latestGallery.id,
          files: getGalleryFiles(data),
        },
      });
    }
  }

  return res;
}

export async function editImageDescriptionHandler({
  description,
  price,
  bazaarItemInfoDescription,
  postId,
  fileId,
  dispatch,
}) {
  const { data } = await galleriesApi.updateImageDescription({
    postId,
    fileId,
    description,
    price,
    bazaarItemInfoDescription,
  });
  dispatch({ type: 'updateGallery', id: data.id, gallery: data });
}
