import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllCategories, getAllKitaEmployee, saveEditAndDeleteCategory } from 'library/api/user';
import EmployeeItem from './EmployeeItem';
import HelpText from 'library/common/commonComponents/HelpText';
import Loader from 'library/common/commonComponents/Loader';

import styles from './userFrameEmployee.module.scss';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import ColorPickerButton from 'library/common/commonComponents/Buttons/ColorPickerButton';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';



export default function UsersFrameEmployee({ kitaId, modules }) {
  const { t } = useTranslation();
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([])
  const [interimHeaders, setInterimHeaders] = useState([]);
  const [count, setCount] = useState(0);
  const [checkBoxUserIds, setCheckBoxUserIds] = useState([]);

  useEffect(() => {
    getAllKitaEmployee(kitaId).then(data => {
      setEmployeeList(data.data);
      setIsLoading(false);
    });
    getAllCategories(kitaId).then(data => {
      setCategories(data.data);
    })
  }, [count]);

  useEffect(() => {
    let i = 0
    let tempColor = [...colorPicker]
    categories.forEach((item) => {
      initialHeaders[i] = item.categoryName
      tempColor[i] = item.colourCode ? item.colourCode : "#000000"
      i = i + 1
    })
    setColorPicker(tempColor)
    setHeaders(initialHeaders)
    setEditableHeaders([...initialHeaders])
    setInterimHeaders([...initialHeaders])
  }, [categories])

  function portfolioModuleIsActive() {
    const filteredModules = modules.filter(obj => obj.moduleKey === 'employee_portfolio');
    if (filteredModules.length === 1) {
      if (filteredModules[0].activeStatus === true) {
        return true;
      }
    }
    return false;
  }
  portfolioModuleIsActive();

  const initialHeaders = ["", "", "", ""];
  const [headers, setHeaders] = useState(initialHeaders);
  const [editableHeaders, setEditableHeaders] = useState([...initialHeaders]);
  const [editMode, setEditMode] = useState(Array(initialHeaders.length).fill(false));

  const handleHeaderChange = (index, value) => {
    const updatedHeaders = [...editableHeaders];
    updatedHeaders[index] = value;
    setEditableHeaders(updatedHeaders);
  };

  const toggleEditMode = (index) => {
    const updatedEditMode = [...editMode];
    updatedEditMode[index] = !editMode[index];
    setEditMode(updatedEditMode);
  };

  const saveHeader = (index, value) => {
    setHeaders([...editableHeaders]);
    let payload = {}
    if (interimHeaders[index] != "" && interimHeaders[index] != editableHeaders[index] && editableHeaders[index] != "") {
      payload = {
        "categoryID": categories[index].categoryID,
        "categoryName": editableHeaders[index],
        "colourCode": colorPicker[index],
        "mode": "edit"
      }
    } else if (interimHeaders[index] != "" && editableHeaders[index] == "") {
      payload = {
        "userIDs": employeeList.map((item) => { return item.id }),
        "categoryID": Number(categories[index].categoryID),
        "mode": "delete"
      }
    } else if (interimHeaders[index] == "" && editableHeaders[index] == "") {
    } else {
      payload = {
        "userIDs": employeeList.map((item) => { return item.id }),
        "kitaID": kitaId,
        "categoryName": editableHeaders[index],
        "mode": "save"
      }
    }
    saveEditAndDeleteCategory(payload).then((res) => {
      store.dispatch(
        showBottomNotification(t('CalendarAbsencePopup.ConfirmSuccess'), {
          isFail: false,
        }),
        setCount(count + 1)
      );
    })
    toggleEditMode(index);
  };

  const [colorPicker, setColorPicker] = useState(Array(initialHeaders.length).fill("#000000"));

  const changeColor = (color, index) => {
    const updatedColor = [...colorPicker];
    updatedColor[index] = color.hex;
    const payload = {
      "categoryID": categories[index].categoryID,
      "categoryName": editableHeaders[index],
      "colourCode": updatedColor[index],
      "mode": "edit"
    }
    saveEditAndDeleteCategory(payload).then((res) => {
      setCount(count + 1)
    })
    setColorPicker(updatedColor);
  }

  const [isColorPickerVisible, setIsColorPickerVisible] = useState(Array(initialHeaders.length).fill(false));

  const toggleColor = (index) => {
    const updatedColor = [...isColorPickerVisible];
    updatedColor[index] = !isColorPickerVisible[index];
    setIsColorPickerVisible(updatedColor);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Administration.UserSection.Employee overview')}</div>
      <div className={styles.text}>
        <HelpText>
          {t('Administration.UserSection.The following list contains all employee')}
        </HelpText>
      </div>
      <br />
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            {headers.map((header, index) => (
              <th key={index} className={styles.thEmployee}>
                <div className={styles.colorPicker}>
                  <div
                    className={styles.label}
                    onClick={() => toggleColor(index)}
                  >
                    <div className={styles.current} style={{ background: colorPicker[index] }} />
                  </div>
                  <div className={styles.relativeContainer}>
                    <div className={styles.absoluteContainer}>
                      {isColorPickerVisible[index] && header != "" && (<ColorPickerButton
                        color={colorPicker[index]}
                        className={styles.pickerComponent}
                        onClick={c =>
                          changeColor(c, index)
                        }
                      />)}
                    </div>
                  </div>
                </div>
                {editMode[index] ? (
                  <>
                    <input
                      type="text"
                      className={styles.textAreaHeader}
                      value={editableHeaders[index]}
                      onChange={(e) => handleHeaderChange(index, e.target.value)}
                    />
                    <Button onClick={(e) => saveHeader(index, e.target.value)} type="primary">
                      <i className='fa fa-save' />
                    </Button>
                  </>
                ) : (
                  <>
                    {header}
                    <Button onClick={() => toggleEditMode(index)} type="primary">
                      <i className='fa fa-edit' />
                    </Button>
                  </>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            employeeList.map(data => {
              return (
                <EmployeeItem
                  key={data.id}
                  data={data}
                  header={editableHeaders}
                  categories={categories}
                  count={count}
                  kitaId={kitaId}
                  setIsLoading={setIsLoading}
                  portfolioModuleIsActive={portfolioModuleIsActive}
                />
              );
            })
          ) : (
            <Loader />
          )}
        </tbody>
      </table>
    </div>
  );
}
