import React from 'react';
import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';

import styles from './cookiesMessage.module.scss';

export default function CookiesMessage({ cancel, confirm }) {
  const { t } = useTranslation();

  return (
    <Popup
      isOpened
      closePopup={cancel}
      header={<strong>{t('CookiesMessage.Hinweis zum Datenschutz')}</strong>}
      body={
        <div>
          <div className={styles.p}>
            {t('CookiesMessage.Stramplerbande schützt Ihre Privatsphäre')}
          </div>
          <div className={styles.p}>
            {t(
              'CookiesMessage.Wir verwenden Cookies zur Verarbeitung Ihrer personenbezogenen Daten',
            )}
          </div>
          <div className={styles.p}>
            {t('CookiesMessage.Sie sollten bei der Einwahl im Internet-Café')}
          </div>
          <div className={styles.p}>
            {t(
              // eslint-disable-next-line
              'CookiesMessage.Wir werden Sie nicht mit Werbung belästigen oder Ihre Informationen zu Marketingzwecke an Dritte weitergeben',
            )}
          </div>
          <div className={styles.p}>
            {t('CookiesMessage.Wir verarbeiten Daten auf Grundlage Ihrer Einwilligung')}{' '}
            {t('CookiesMessage.LinkPrivacy')}{' '}
            <a href='/auth-privacy' target='_blank'>
              {t('CookiesMessage.Privacy Policy')}
            </a>
            .
          </div>
        </div>
      }
      footer={
        <div className={styles.buttons}>
          <div>
            <Button type='primary' size='sm' onClick={cancel} className={styles.button}>
              {t('CookiesMessage.Cancel')}
            </Button>
          </div>
          <div>
            <Button type='primary' size='sm' onClick={confirm}>
              {t('CookiesMessage.Confirm')}
            </Button>
          </div>
        </div>
      }
    />
  );
}
