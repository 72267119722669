import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getToken } from 'library/utilities/token';

import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import useHelpModuleContent from './hooks/useHelpModuleContent';

import styles from './help.module.scss';

export default function HelpModule({ user }) {

  const content = useHelpModuleContent();
  const { t } = useTranslation();

  function isAdminUser() {
    if (user.administrationAccess || user.employee || user.superAdminStatus) {
      return true;
    }
    return false;
  }

  function getFAQLink() {
    if (window.location.hostname.toLowerCase().includes('awo-immer-dabei.safe2connect.org') || window.location.hostname.toLowerCase().includes('indag.safe2connect.org') || window.location.hostname.toLowerCase().includes('staging2.safe2connect.org') || window.location.hostname.toLowerCase().includes('dev2.safe2connect.org')) {
      return 'https://kitaplatzonline.de/faq-awo-immer-dabei';
    } else {
      if (isAdminUser()) {
        return 'https://kitaplatzonline.de/faqadmin';
      } else {
        return 'https://kitaplatzonline.de/faqmember';
      }
    }
  }

  return (
    <>
      <KitaPanelWrapper>
        <Wrapper>
          <Col>
            <Row>
              <div className={styles.container}>
                <iframe
                  className={styles.iframecl}
                  src={getFAQLink()}
                  title='Hilfe'
                />
              </div>
            </Row>
          </Col>
        </Wrapper>
      </KitaPanelWrapper>
    </>
  );
}
