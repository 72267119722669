import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import UsersFrameChildren from '.';

export default connect(
  null,
  { showBottomNotification },
)(UsersFrameChildren);
