import * as actionTypes from '../commonConstants/commonConstants';

const initialState = {
    isDisabled: false
}
const ButtonDisableReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BUTTON_DISABLED:
            return {
                ...state,
                isDisabled: action.payload
            }
        default:
            return state;
    }
}

export default ButtonDisableReducer;