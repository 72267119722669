import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import BackgroundWrapper from 'library/common/commonComponents/to-refactor/commonBackgroundWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import useLanguages from 'library/common/commonHooks/useLanguages';
import LanguageDropdown from 'library/common/commonComponents/Dropdowns/LanguageDropdown';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import RegistrationFrame from './authorizationFrames/RegistrationFrame';
import LoginFrame from './authorizationFrames/loginFrame';

import styles from './authorization.module.scss';

export default function Authorization(props) {
  const { t } = useTranslation();
  useSiteTitle('Authorization');

  const { selectedLanguage, changeLanguage } = useLanguages();

  return (
    <BackgroundWrapper>
      <div className={styles.wrapper}>
        <LoginFrame
          loginService={props.loginService}
          msLoginService={props.msLoginService}
          handleCheckBoxChange={props.handleCheckBoxChange}
        />
        <RegistrationFrame registrationService={props.registrationService} />
        <Wrapper>
          <div className={styles.links}>
            <Link className={styles.link} to='/auth-imprint'>
              {t('Header.Bottombar.Menu.imprint')}
            </Link>
            <Link className={styles.link} to='/auth-privacy'>
              {t('Header.Bottombar.Menu.dataProtection')}
            </Link>
          </div>

          <div className={styles.languageDropDown}>
            <LanguageDropdown
              chooseLanguageText={t('Login.Choose language')}
              submitLanguage={(lang, id) => changeLanguage(lang, id, false)}
              selectedLanguage={selectedLanguage}
            />
          </div>
        </Wrapper>
      </div>
    </BackgroundWrapper>
  );
}
