// Determines wether errors have to be shown
import { jsPDF } from 'jspdf';
import { downloadBase64FileIfIsInAppView } from 'library/utilities/files';

const secondaryAnswerOptions = [
  'ParentSurvey.No information',
  'ParentSurvey.AnswerVery',
  'ParentSurvey.AnswerKindOf',
  'ParentSurvey.AnswerNotReally',
  'ParentSurvey.AnswerNotAtAll',
];

export function validateParentSurvey({ parentSurveyData, t }) {
  const errors = [];
  // check for empty questions
  const emptyQuestions = parentSurveyData.questions.filter(
    question => deleteHTML(question.question) === '',
  );
  if (emptyQuestions.length > 0) {
    errors.push(t('Surveys.Question cannot be blank'));
  }
  for (const question of parentSurveyData.questions) {
    if (
      question.inputType !== 'header' &&
      question.allowUserAddSuggestionStatus === false &&
      question.options.length === 0
    ) {
      errors.push(
        t('ParentSurvey.Please add at least one answer as option or allow the user to add answers'),
      );
      break;
    }
  }
  // Checks if atleast one question was entered
  if (parentSurveyData.questions.length <= 0) {
    errors.push(t('ParentSurvey.There has to be atleast one question'));
  }
  // check if name is empty
  if (deleteHTML(parentSurveyData.name).length <= 0) {
    errors.push(t('ParentSurvey.Please choose a title'));
  }

  return errors;
}

function deleteHTML(html) {
  const nHTML = document.createElement('div');
  nHTML.innerHTML = html;
  return nHTML.textContent;
}

// validates the parent survey when a user fills out the parent survey in their feed
export function validateParentSurveySubmit(parentSurveyData, t) {
  const errors = [];
  let missingQuestions = [];
  const missingQuestionIds = flattenQuestions(parentSurveyData.questions)
    .map(question =>
      question.parentSurveyQuestionOptions && question.parentSurveyQuestionOptions.length > 0
        ? question.parentSurveyQuestionOptions.filter(
            option =>
              !option.checked ||
              (question.inputType === 'doubleDropdownImportance' && !option.secondaryAnswer),
          ).length === question.parentSurveyQuestionOptions.length
          ? question.id
          : null
        : null,
    )
    .filter(value => value !== null);

  // Checks if there is a question where no option has been checked
  const emptyOption = flattenQuestions(parentSurveyData.questions)
    .map(question =>
      question.parentSurveyQuestionOptions && question.parentSurveyQuestionOptions.length > 0
        ? question.parentSurveyQuestionOptions.filter(
            option =>
              !option.checked ||
              (question.inputType === 'doubleDropdownImportance' && !option.secondaryAnswer),
          ).length === question.parentSurveyQuestionOptions.length
        : false,
    )
    .filter(value => value).length;
  if (emptyOption > 0) {
    errors.push(t('ParentSurvey.Please choose an answer for every question'));
  }
  if (missingQuestionIds && Array.isArray(missingQuestionIds) && missingQuestionIds.length > 0) {
    missingQuestions = missingQuestionIds;
  }
  return { errors, missingQuestions };
}

function extractVotesWithSubQuestions(questions) {
  if (!questions) return [];

  let result = [];

  questions.forEach(q => {
    result = result.concat(q.parentSurveyQuestionOptions.filter(op => op.checked).map(op => op.id));
    const subQuestionOptionIds = extractVotesWithSubQuestions(q.subQuestions);
    result = result.concat(subQuestionOptionIds);
  });

  return result;
}

export function extractVotes(parentSurveyData) {
  const checkedOptionIdsArr = extractVotesWithSubQuestions(parentSurveyData.questions);

  return removeDuplicates(checkedOptionIdsArr);
}

function removeDuplicates(arr) {
  const s = new Set(arr);
  const it = s.values();
  return Array.from(it);
}

// Combines the questionsInfos and questionCatalog to prepare them for submitting to the backend
export function buildParentSurveyData(questionInfos, parentSurveyName) {
  console.log('questionInfos: ', [...questionInfos]);
  const questions = [];

  questionInfos.forEach(question => {
    if (question.type === 'own_question') {
      if (question.question.trim() !== '') {
        const filteredOptions = question?.allowUserAddSuggestionStatus
          ? []
          : question.options.filter(option => option.answer.trim() !== '');
        questions.push({ ...question, options: filteredOptions });
      }
    } else if (question.type === 'prepared' && (question.checked || question.preChecked)) {
      questions.push({ ...question });
    }
  });

  console.log('questions: ', [...questions]);
  return {
    name: parentSurveyName,
    questions,
  };
}

export function getTotalVotesCompleted(parentSurvey) {
  const userIds = [];
  if (!parentSurvey || !parentSurvey.questions) {
    return;
  }

  for (let i = 0; i < parentSurvey.questions.length; i++) {
    for (let j = 0; j < parentSurvey.questions[i].parentSurveyQuestionOptions.length; j++) {
      for (
        let z = 0;
        z <
        parentSurvey.questions[i].parentSurveyQuestionOptions[j].parentSurveyQuestionOptionVote
          .length;
        z++
      ) {
        const userId =
          parentSurvey.questions[i].parentSurveyQuestionOptions[j].parentSurveyQuestionOptionVote[z]
            .createdBy;
        if (!userIds.includes(userId)) {
          userIds.push(userId);
        }
      }
    }
  }
  return userIds.length;
}

export function getUserHasVoted(parentSurvey, userId) {
  let hasVoted = false;
  if (parentSurvey && parentSurvey.questions) {
    for (let i = 0; i < parentSurvey.questions.length; i++) {
      for (let j = 0; j < parentSurvey.questions[i].parentSurveyQuestionOptions.length; j++) {
        for (
          let z = 0;
          z <
          parentSurvey.questions[i].parentSurveyQuestionOptions[j].parentSurveyQuestionOptionVote
            .length;
          z++
        ) {
          if (
            parentSurvey.questions[i].parentSurveyQuestionOptions[j].parentSurveyQuestionOptionVote[
              z
            ].createdBy === userId
          ) {
            hasVoted = true;
            break;
          }
        }
      }
    }
  }

  return hasVoted;
}

/**
 * A user can vote on a parent survey once for every child he has. This function returns the number of times he has voted already.
 */
export function getUserVotes(parentSurvey, userId) {
  const votesGiven = Math.max(
    ...parentSurvey.questions.map(question =>
      question.parentSurveyQuestionOptions.reduce(
        (votesCount, option) =>
          votesCount +
          option.parentSurveyQuestionOptionVote.filter(vote => vote.createdBy === userId).length,
        0,
      ),
    ),
  );

  return votesGiven;
}

// Gets the options for a inputType = 'number' question and converts them to fit into a select dropdown
export function convertInputTypeNumberOptions(options) {
  const selectOptions = [];
  for (let i = 0; i < options.length; i++) {
    selectOptions.push(convertInputTypeNumberOption(options[i]));
  }
  return sortHelper(selectOptions, 'value');
}

export const sortHelper = (data, sortBy) => {
  const findElement = data.find(x => typeof x[sortBy] !== 'number');

  if (typeof findElement === 'object') {
    if (!findElement.hasOwnProperty('sortBy')) {
      return data;
    }
  } else {
    return data;
  }

  if (findElement) {
    // sort by localeCompare
    return data.sort((a, b) =>
      a[sortBy].localeCompare(b[sortBy], undefined, { numeric: true, sensitivity: 'base' }),
    );
  }
  // sort by number
  else return data.sort((a, b) => a[sortBy] - b[sortBy]);
};

export function convertInputTypeNumberOption(option) {
  return { value: option.answer, label: option.answer };
}

// Split long questions into the right length to make them fit onto the pdf
function splitQuestion(question) {
  const words = question.split(' ');
  const maxLettersPerRow = 70;
  let letterCounter = 0;
  const rows = [];
  let wordStorage = [];
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    letterCounter += word.length;
    if (letterCounter > maxLettersPerRow) {
      wordStorage.push(word);
      rows.push(wordStorage.join(' '));
      wordStorage = [];
      letterCounter = 0;
    } else {
      wordStorage.push(word);
    }
  }
  rows.push(wordStorage.join(' '));

  return rows;
}

const setText = (oldHeight, text, doc, left = 10) => {
  const width = doc.internal.pageSize.getWidth();
  const sizedText = doc.splitTextToSize(text, width - (10 + left));
  const textHeight = Math.ceil(doc.getTextDimensions(sizedText).h);
  doc.text(sizedText, left, oldHeight);
  return textHeight;
};

const removeHTML = html => {
  const nHTML = document.createElement('div');
  nHTML.innerHTML = html;
  if (nHTML.textContent === '') {
    return ' ';
  }
  return nHTML.textContent;
};

export const flattenQuestions = questions => {
  if (!questions) return [];

  let result = [];
  questions.forEach(q => {
    result.push(q);
    if (q.subQuestions) {
      result = result.concat(
        flattenQuestions(
          q.subQuestions.sort((a, b) => (a.orderPosition < b.orderPosition ? -1 : 1)),
        ),
      );
    }
  });

  return result;
};

function getSecondaryAnswerCounts(questionsFlattened) {
  const secondaryAnswerCounts = {};

  questionsFlattened.forEach(q => {
    if (q.inputType !== 'doubleDropdownImportance') return;

    secondaryAnswerCounts[q.id] = Object.fromEntries(secondaryAnswerOptions.map(sao => [sao, 0]));

    q.parentSurveyQuestionOptions.forEach(qo => {
      qo.parentSurveyQuestionOptionVote.forEach(v => {
        if (
          v.secondaryAnswer &&
          v.secondaryAnswer !== '' &&
          v.secondaryAnswer in secondaryAnswerCounts[q.id]
        ) {
          secondaryAnswerCounts[q.id][v.secondaryAnswer] += 1;
        }
      });
    });
  });

  return secondaryAnswerCounts;
}

function calculateSurveyIdMap(questions) {
  let curId = 1;
  const anonymityMappings = {};
  const result = {};

  questions.forEach(q => {
    q.parentSurveyQuestionOptions.forEach(qo => {
      result[qo.id] = [];
      qo.parentSurveyQuestionOptionVote.forEach(v => {
        if (!(v.createdBy in anonymityMappings)) {
          anonymityMappings[v.createdBy] = curId;
          curId++;
        }
        const surveyId = anonymityMappings[v.createdBy];

        result[qo.id].push(surveyId);

        if (v.secondaryAnswer && v.secondaryAnswer !== '') {
          if (!(`${q.id}${v.secondaryAnswer}` in result)) {
            result[`${q.id}${v.secondaryAnswer}`] = [];
          }

          if (!result[`${q.id}${v.secondaryAnswer}`].includes(surveyId)) {
            result[`${q.id}${v.secondaryAnswer}`].push(surveyId);
          }
        }
      });
    });
  });
  return result;
}

const returnQuestionTopic = (question, t) => {
  if (
    !(
      window.location.hostname.toLowerCase().includes('staging1.safe2connect.org') ||
      window.location.hostname.toLowerCase().includes('localhost') ||
      window.location.hostname.toLowerCase().includes('awobamberg.safe2connect.org')
    )
  ) {
    return '';
  }
  if (question === 'Angebote im Bereich Sport und Bewegung') {
    return t('ParentSurvey.How satisfied are you with the following offerings at your facility');
  }
  if (question === 'Bringsituation') {
    return t(
      'ParentSurvey.How satisfied are you with the following situations in your institution',
    );
  }
  if (question === 'Optische Gestaltung der Räume') {
    return t(
      'ParentSurvey.Please rate your satisfaction with the following framework conditions in your facility',
    );
  }
  if (question === 'Informationen über die pädagogische Konzeption') {
    return t('ParentSurvey.How satisfied are you with information about the following things');
  }
  if (question === 'Elternabende') {
    return t('ParentSurvey.How satisfied are you with the following things about our facility');
  }
  return '';
};

export function createAndDownloadPDF(parentSurvey, kitaName, t, anonymous = false) {
  let height = 20;
  // eslint-disable-next-line new-cap
  const doc = new jsPDF();
  doc.setFontSize(16);
  const splittedParentSurveyTitle = parentSurvey.title.split(/<\/p>/);
  for (const paragraph of splittedParentSurveyTitle) {
    const newTitle = removeHTML(paragraph);
    height += setText(height, newTitle, doc);
  }

  doc.setFontSize(13);
  height += setText(
    height,
    parentSurvey.total_votes_completed
      ? 'Anzahl Teilnehmer: ' +
          parentSurvey.total_votes_completed +
          (parentSurvey.filteredParticipantCount !== null
            ? ` (insgesamt), ${parentSurvey.filteredParticipantCount} (${(
                (parentSurvey.filteredParticipantCount / parentSurvey.total_votes_completed) *
                100
              ).toFixed(2)}%, gefiltert)`
            : '')
      : '',
    doc,
  );

  doc.setLineWidth(0.3);
  doc.line(10, height, 120, height);

  const pageMaxHeight = 250;
  const fontSize = 13;
  doc.setFontSize(fontSize);
  let page = 1;
  addPDFFooter(doc, page, fontSize, kitaName);

  const questionsFlattened = flattenQuestions(parentSurvey.questions);

  const secondaryAnswerCounts = getSecondaryAnswerCounts(questionsFlattened);

  for (let i = 0; i < questionsFlattened.length; i++) {
    const sortedOptions = questionsFlattened[i].parentSurveyQuestionOptions.sort((a, b) =>
      a.orderPosition < b.orderPosition ? -1 : 1,
    );

    const blockHeight = 20 + sortedOptions.length * 5;
    if (height + blockHeight > pageMaxHeight) {
      doc.addPage();
      page++;
      addPDFFooter(doc, page, fontSize, kitaName);
      height = 0;
    }
    height += 20;
    const rows = splitQuestion(questionsFlattened[i].question);
    for (let j = 0; j < rows.length; j++) {
      // doc.text(rows[j], 10, height);
      // height += 8;
      const optionalQuestionTopic = returnQuestionTopic(rows[j], t);
      doc.setFont(undefined, 'bold');
      if (optionalQuestionTopic !== '') {
        height += setText(height, optionalQuestionTopic, doc);
        height += 10;
      }
      height += setText(height, rows[j].replace(/<[^>]+>/g, ''), doc);
      doc.setFont(undefined, 'normal');
    }
    if (questionsFlattened[i].inputType === 'header') {
      height -= 10;
    }

    for (let j = 0; j < sortedOptions.length; j++) {
      if (height > pageMaxHeight) {
        doc.addPage();
        page++;
        addPDFFooter(doc, page, fontSize, kitaName);
        height = 15;
      }
      height += 10;
      // doc.text(sortedOptions[j].answer, 15, height);
      // height += 7;
      height += setText(height, sortedOptions[j].answer, doc);
      height += 5;
      // Draw vote bar
      doc.setDrawColor(0);
      doc.setFillColor(220, 220, 220);
      doc.roundedRect(15, height - 5, 100, 6, 3, 3, 'F');

      doc.setDrawColor(0);
      doc.setFillColor(153, 204, 255);
      doc.roundedRect(
        15,
        height - 5,
        getVoteBarWidth(questionsFlattened[i], sortedOptions[j].id),
        6,
        3,
        3,
        'f',
      );

      doc.text((sortedOptions[j].counted_votes || '0').toString() + ' Stimmen', 120, height);
    }

    if (questionsFlattened[i].inputType === 'doubleDropdownImportance') {
      if (height > pageMaxHeight) {
        doc.addPage();
        page++;
        addPDFFooter(doc, page, fontSize, kitaName);
        height = 15;
      }

      doc.setFont(undefined, 'bold');
      height += 15;
      height += setText(height, t('ParentSurvey.PersonalImportance'), doc);
      doc.setFont(undefined, 'normal');

      // eslint-disable-next-line no-loop-func
      Object.keys(secondaryAnswerCounts[questionsFlattened[i].id]).forEach(k => {
        if (height > pageMaxHeight) {
          doc.addPage();
          page++;
          addPDFFooter(doc, page, fontSize, kitaName);
          height = 15;
        }
        height += 10;
        height += setText(height, `${t(k)}`, doc);
        height += 5;

        doc.setDrawColor(0);
        doc.setFillColor(220, 220, 220);
        doc.roundedRect(15, height - 5, 100, 6, 3, 3, 'F');

        doc.setDrawColor(0);
        doc.setFillColor(153, 204, 255);
        doc.roundedRect(
          15,
          height - 5,
          getSecondaryAnswerVoteBarWidth(questionsFlattened[i], k, secondaryAnswerCounts),
          6,
          3,
          3,
          'f',
        );

        doc.text(`${secondaryAnswerCounts[questionsFlattened[i].id][k]} Stimmen`, 120, height);
      });
    }
  }
  if (parentSurvey.isNormalSurvey && anonymous === false) {
    height += 20;
    for (let i = 0; i < questionsFlattened.length; i++) {
      const sortedOptions = questionsFlattened[i].parentSurveyQuestionOptions.sort((a, b) =>
        a.orderPosition < b.orderPosition ? -1 : 1,
      );
      for (let x = 0; x < sortedOptions.length; x++) {
        if (height + 50 > pageMaxHeight) {
          doc.addPage();
          page++;
          addPDFFooter(doc, page, fontSize, kitaName);
          height = 15;
        }
        doc.setFont(undefined, 'bold');
        height += setText(height, sortedOptions[x].answer, doc);
        doc.setFont(undefined, 'normal');
        const tempAnswers = [];
        if (sortedOptions[x].surveyOptionVotes) {
          for (let z = 0; z < sortedOptions[x].surveyOptionVotes.length; z++) {
            tempAnswers.push(
              sortedOptions[x].surveyOptionVotes[z].firstName +
                ' ' +
                sortedOptions[x].surveyOptionVotes[z].lastName,
            );
            if (height + 10 > pageMaxHeight) {
              doc.addPage();
              page++;
              addPDFFooter(doc, page, fontSize, kitaName);
              height = 15;
            }
          }
        }
        height += 5;
        height += setText(
          height,
          tempAnswers.length > 0 ? tempAnswers.toString() : 'Keine Stimmen',
          doc,
          20,
        );
        doc.line(10, height, 120, height);
        height += 5;
      }
    }
  }
  // var blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
  // var blobUrl = URL.createObjectURL(blobPDF);
  // console.log(blobPDF);
  // console.log(doc.output('datauristring'));
  // window.open(blobUrl);
  if (window.ReactNativeWebView) {
    const splitted = doc.output('datauristring').split(',');
    const rawBase64 = splitted.length > 1 ? splitted[1] : splitted[0];
    downloadBase64FileIfIsInAppView(
      `${removeHTML(parentSurvey.title)}.pdf`,
      'application/pdf',
      rawBase64,
    );
  } else {
    doc.save(`${removeHTML(parentSurvey.title)}.pdf`);
  }

  // console.log(doc.output('datauristring'));
  // base64(dataUriToBinary(doc.output('datauri')))
  // console.log(doc.output('datauri'));
  // downloadURI(doc.output('datauri'), 'test.pdf');
  // doc.save(`${removeHTML(parentSurvey.title)}.pdf`);
}

// function downloadURI(uri, name) {
//   const link = document.createElement('a');
//   link.download = name;
//   link.href = uri;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// delete link;
// }
function addPDFFooter(doc, page, fontSize, kitaName) {
  doc.text(page + '', 195, 285);

  doc.setFontSize(fontSize - 2);
  doc.setFont(undefined, 'italic');
  doc.text(kitaName, 10, 280);
  doc.text('Statistics generated by Link IT isi GmbH', 10, 285);
  doc.setFont(undefined, 'normal');
  doc.setFontSize(fontSize);
}

function getSecondaryAnswerVoteBarWidth(question, answer, secondaryAnswerCounts) {
  const answers = Object.keys(secondaryAnswerCounts[question.id]);

  let totalVotes = 0;
  answers.forEach(a => {
    totalVotes += secondaryAnswerCounts[question.id][a];
  });

  if (totalVotes <= 0) {
    return 0;
  }

  return (secondaryAnswerCounts[question.id][answer] / totalVotes) * 100;
}

export function getVoteBarWidth(question, optionId) {
  const totalVotes = question.parentSurveyQuestionOptions.reduce(
    (totalVotesInner, option) => totalVotesInner + (option.counted_votes || 0),
    0,
  );
  const optionValue =
    question.parentSurveyQuestionOptions.filter(option => option.id === optionId)[0]
      .counted_votes || 0;

  if (totalVotes <= 0) {
    return 0;
  }
  return (optionValue / totalVotes) * 100;
}
export function createAndDownloadCSV(parentSurvey, t) {
  const titleHTML = document.createElement('div');
  titleHTML.innerHTML = parentSurvey.title;
  const textTitle = titleHTML.textContent;

  const questionsFlattened = flattenQuestions(parentSurvey.questions);
  const secondaryAnswerCounts = getSecondaryAnswerCounts(questionsFlattened);
  const surveyIdMap = calculateSurveyIdMap(questionsFlattened);

  // Create CSV Header
  const optionLength = questionsFlattened.map(
    question => question.parentSurveyQuestionOptions.length,
  );
  const optionLengthMax = Math.max(...optionLength);
  const headers = ['Question'];
  for (let i = 0; i < optionLengthMax; i++) {
    headers.push('Answer' + (i + 1));
    headers.push('VotesAnswer' + (i + 1));
    headers.push('Fragebogen-Nr.');
  }

  // Insert answers into rows
  const rows = [headers];
  for (let i = 0; i < questionsFlattened.length; i++) {
    const sortedOptions = questionsFlattened[i].parentSurveyQuestionOptions.sort((a, b) =>
      a.orderPosition < b.orderPosition ? -1 : 1,
    );

    const questionHTML = document.createElement('div');
    questionHTML.innerHTML = questionsFlattened[i].question;
    const textQuestion = questionHTML.textContent;

    const row = [textQuestion];
    for (let j = 0; j < sortedOptions.length; j++) {
      row.push(sortedOptions[j].answer);
      row.push(sortedOptions[j].counted_votes);
      row.push(
        surveyIdMap[sortedOptions[j].id].length === 0
          ? ''
          : surveyIdMap[sortedOptions[j].id].join(','),
      );
    }
    rows.push(row);

    if (questionsFlattened[i].id in secondaryAnswerCounts) {
      const secondaryAnswerRow = [t('ParentSurvey.PersonalImportance')];

      Object.keys(secondaryAnswerCounts[questionsFlattened[i].id]).forEach(k => {
        secondaryAnswerRow.push(t(k));
        secondaryAnswerRow.push(secondaryAnswerCounts[questionsFlattened[i].id][k]);
        secondaryAnswerRow.push(
          !(`${questionsFlattened[i].id}${k}` in surveyIdMap) ||
            surveyIdMap[`${questionsFlattened[i].id}${k}`].length === 0
            ? ''
            : surveyIdMap[`${questionsFlattened[i].id}${k}`].join(','),
        );
      });
      rows.push(secondaryAnswerRow);
    }
  }

  // Insert inputType === 'number' fields

  // Create CSV File
  let csvContent = 'data:text/csv;charset=utf-8,';
  rows.forEach(function(rowArray) {
    const row = rowArray.join(';');
    csvContent += row + '\r\n';
  });

  // Download CSV File
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${textTitle}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
}

export function sortQuestions(questions) {
  // helper function to be called for all questions and subquestions
  function helpeFunctionSortQuestions(questions) {
    const orderedQuestion = [...questions];
    orderedQuestion.sort((a, b) => {
      if (a.orderPosition < b.orderPosition) return -1;
      return a.orderPosition > b.orderPosition ? 1 : 0;
    });
    // Order Options
    for (let i = 0; i < orderedQuestion.length; i++) {
      orderedQuestion[i].parentSurveyQuestionOptions = orderedQuestion[
        i
      ].parentSurveyQuestionOptions.sort((a, b) => {
        if (a.orderPosition < b.orderPosition) return -1;
        return a.orderPosition > b.orderPosition ? 1 : 0;
      });
    }
    return orderedQuestion;
  }

  // order all questions and their options
  const result = helpeFunctionSortQuestions(questions);

  // order all subquestions and their options
  for (const question of result) {
    if (Array.isArray(question.subQuestions) && question.subQuestions) {
      question.subQuestions = helpeFunctionSortQuestions(question.subQuestions);
    }
  }

  return result;
}

export function getQuestionCatalogueAwoOnline(t) {
  let catalog = [
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.Please indicate in which area your child is currently being cared for',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Creche') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Kindergarten') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Hort / school child care') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.How long has your child been in the facility'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.1 year and less') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.2 years') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.3 years') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.4 years') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.5 years') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.6 years and more') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.Which criterion was decisive for the decision to register your child in our facility',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        {
          id: Math.random(),
          orderPosition: 0,
          answer: t('ParentSurvey.Proximity to place of residence'),
        },
        {
          id: Math.random(),
          orderPosition: 1,
          answer: t('ParentSurvey.Reputation of the institution'),
        },
        {
          id: Math.random(),
          orderPosition: 2,
          answer: t('ParentSurvey.Siblings already registered at the facility'),
        },
        {
          id: Math.random(),
          orderPosition: 3,
          answer: t('ParentSurvey.Facility concept'),
        },
        {
          id: Math.random(),
          orderPosition: 4,
          answer: t('ParentSurvey.Personal recommendation'),
        },
        {
          id: Math.random(),
          orderPosition: 5,
          answer: t('ParentSurvey.Favorable transport connections'),
        },
        {
          id: Math.random(),
          orderPosition: 6,
          answer: t('ParentSurvey.Proximity to work'),
        },
        {
          id: Math.random(),
          orderPosition: 7,
          answer: t('ParentSurvey.Only promise for a place'),
        },
        {
          id: Math.random(),
          orderPosition: 8,
          answer: t('ParentSurvey.Opening hours'),
        },
        {
          id: Math.random(),
          orderPosition: 9,
          answer: t('ParentSurvey.Number of closed days per year'),
        },
        {
          id: Math.random(),
          orderPosition: 10,
          answer: t('ParentSurvey.Facilities (rooms, open spaces, etc.) of the facility'),
        },
        {
          id: Math.random(),
          orderPosition: 11,
          answer: t('ParentSurvey.Miscellaneous'),
        },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Did you get the place in our facility at the desired time'),
      inputType: 'text',
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.a Yes') },
        {
          id: Math.random(),
          orderPosition: 1,
          answer: t(
            'ParentSurvey.No, we would have liked to have had a place in the facility earlier',
          ),
        },
        {
          id: Math.random(),
          orderPosition: 2,
          answer: t(
            'ParentSurvey.No, we would have liked to have had a place in the facility later',
          ),
        },
      ],
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        // eslint-disable-next-line max-len
        'ParentSurvey.If you indicated that you would have liked the place earlier, by how many months was admission to the facility delayed from your desired admission date',
      ),
      inputType: 'text',
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Not applicable') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.1 month') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.2 months') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.3 months') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.4 months') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.5 months') },
        { id: Math.random(), orderPosition: 6, answer: t('ParentSurvey.6 months') },
        { id: Math.random(), orderPosition: 7, answer: t('ParentSurvey.More than 6 months') },
      ],
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Do the opening hours of the facility meet your needs'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.a Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.b No') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Which opening time would be ideal for you'),
      inputType: 'number',
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No answer') },
        { id: Math.random(), orderPosition: 1, answer: '06:00' },
        { id: Math.random(), orderPosition: 2, answer: '06:30' },
        { id: Math.random(), orderPosition: 3, answer: '07:00' },
        { id: Math.random(), orderPosition: 4, answer: '07:30' },
        { id: Math.random(), orderPosition: 5, answer: '08:00' },
        { id: Math.random(), orderPosition: 6, answer: '08:30' },
        { id: Math.random(), orderPosition: 7, answer: '09:00' },
      ],
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Which closing time would be ideal for you'),
      inputType: 'number',
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No answer') },
        { id: Math.random(), orderPosition: 1, answer: '12:00' },
        { id: Math.random(), orderPosition: 2, answer: '12:30' },
        { id: Math.random(), orderPosition: 3, answer: '13:00' },
        { id: Math.random(), orderPosition: 4, answer: '13:30' },
        { id: Math.random(), orderPosition: 5, answer: '14:00' },
        { id: Math.random(), orderPosition: 6, answer: '14:30' },
        { id: Math.random(), orderPosition: 7, answer: '15:00' },
        { id: Math.random(), orderPosition: 8, answer: '15:30' },
        { id: Math.random(), orderPosition: 9, answer: '16:00' },
        { id: Math.random(), orderPosition: 10, answer: '16:30' },
        { id: Math.random(), orderPosition: 11, answer: '17:00' },
        { id: Math.random(), orderPosition: 12, answer: '17:30' },
        { id: Math.random(), orderPosition: 13, answer: '18:00' },
      ],
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Do you need extra care for your child when it goes to school'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Yes, in a crèche') },
        {
          id: Math.random(),
          orderPosition: 1,
          answer: t('ParentSurvey.Yes, in a school child care facility'),
        },
        {
          id: Math.random(),
          orderPosition: 2,
          answer: t('ParentSurvey.Yes, in the lunchtime care at school'),
        },
        {
          id: Math.random(),
          orderPosition: 3,
          answer: t('ParentSurvey.Yes, in a day school'),
        },
        {
          id: Math.random(),
          orderPosition: 4,
          answer: t('ParentSurvey.Yes, but only during the holiday periods'),
        },
        {
          id: Math.random(),
          orderPosition: 5,
          answer: t('ParentSurvey.f No'),
        },
        {
          id: Math.random(),
          orderPosition: 6,
          answer: t('ParentSurvey.My child is already at school'),
        },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Offers in the field of sports and exercise'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Offers in the field of health & nutrition'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Offers in the field of nature & environment'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Music offers'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Art offers'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Offers in the area of ​​language skills'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Offers at various festivals and celebrations'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Offers of excursions'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Bring situation'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
        { id: Math.random(), orderPosition: 6, answer: t('ParentSurvey.Not applicable') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Pickup situation'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
        { id: Math.random(), orderPosition: 6, answer: t('ParentSurvey.Not applicable') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Rest periods or sleeping'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
        { id: Math.random(), orderPosition: 6, answer: t('ParentSurvey.Not applicable') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Acclimatization'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
        { id: Math.random(), orderPosition: 6, answer: t('ParentSurvey.Not applicable') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Design of free time'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
        { id: Math.random(), orderPosition: 6, answer: t('ParentSurvey.Not applicable') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Homework help'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
        { id: Math.random(), orderPosition: 6, answer: t('ParentSurvey.Not applicable') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Optical design of the rooms'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Interior of the rooms'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Design of the outdoor facilities for the children'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Equipping the facility with learning and playing materials'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Balance of the menu for breakfast & snacks'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Balance of the menu at the hot lunch'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Taste of the warm lunch'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Information about the pedagogical conception'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Information about your childs development'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Information about your rights and obligations'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Information about the work of the parent council'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Information about the activities of the institution'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Parents evenings'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Letters to parents'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Individual meetings with an appointment (development meetings)'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Conversations between door and hinge'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Info wall/postings'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Kita app AWO online - Bamberg'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Opportunities for parents to participate'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Permanent caregivers for your child'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Opportunities for your child to participate'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Culture of conversation in our institution'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Dealing with complaints / criticism'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.Overall, how satisfied are you with the order and cleanliness of the facility',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: true,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.a Satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.b Rather unsatisfied') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.Do you have the impression that your child enjoys visiting our facility',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: true,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.a Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.b Part/part') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.How do you rate the facility overall'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Grade 1 (= very good)') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Grade 2 (=good)') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Grade 3 (=satisfactory)') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Grade 4 (=sufficient)') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.Grade 5 (=poor)') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.Grade 6 (=insufficient)') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        // eslint-disable-next-line max-len
        'ParentSurvey.How likely is it that you would recommend our facility to a friend or colleague? (Please rate on a scale of 0 to 10, where 0 is unlikely and 10 is extremely likely)',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.10') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.9') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.8') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.7') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.6') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.5') },
        { id: Math.random(), orderPosition: 6, answer: t('ParentSurvey.4') },
        { id: Math.random(), orderPosition: 7, answer: t('ParentSurvey.3') },
        { id: Math.random(), orderPosition: 8, answer: t('ParentSurvey.2') },
        { id: Math.random(), orderPosition: 9, answer: t('ParentSurvey.1') },
        { id: Math.random(), orderPosition: 10, answer: t('ParentSurvey.0') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.Here you have the opportunity to express wishes and suggestions in relation to our facility',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: true,
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.How satisfied were you with this survey'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.191 Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.192 Rather satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.193 Part/part') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.194 Rather unsatisfied') },
        { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.195 Very dissatisfied') },
        { id: Math.random(), orderPosition: 5, answer: t('ParentSurvey.196 I can not judge') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.Here you can express your wishes and suggestions regarding the survey',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: true,
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      checked: false,
      type: 'prepared',
    },
  ];

  catalog = catalog.map((question, index) =>
    question ? { ...question, orderPosition: index } : null,
  );
  return catalog;
}

export function getQuestionCatalogue(t) {
  let catalog = [
    {
      id: Math.random(),
      question: t('ParentSurvey.How old is your child'),
      inputType: 'number',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: 1 },
        { id: Math.random(), orderPosition: 1, answer: 2 },
        { id: Math.random(), orderPosition: 2, answer: 3 },
        { id: Math.random(), orderPosition: 3, answer: 4 },
        { id: Math.random(), orderPosition: 4, answer: 5 },
        { id: Math.random(), orderPosition: 5, answer: 6 },
        { id: Math.random(), orderPosition: 6, answer: 7 },
        { id: Math.random(), orderPosition: 7, answer: 8 },
        { id: Math.random(), orderPosition: 8, answer: 9 },
        { id: Math.random(), orderPosition: 9, answer: 10 },
        { id: Math.random(), orderPosition: 10, answer: 11 },
        { id: Math.random(), orderPosition: 11, answer: 12 },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.How long has your child been with us'),
      inputType: 'number',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: 1 },
        { id: Math.random(), orderPosition: 1, answer: 2 },
        { id: Math.random(), orderPosition: 2, answer: 3 },
        { id: Math.random(), orderPosition: 3, answer: 4 },
        { id: Math.random(), orderPosition: 4, answer: 5 },
        { id: Math.random(), orderPosition: 5, answer: 6 },
        { id: Math.random(), orderPosition: 6, answer: 7 },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Does your child enjoy going to our facility'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.No') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.What does your child dislike in our kindergarten'),
      inputType: 'text',
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      multipleAnswerStatus: true,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.What does your child particularly like about our kindergarten'),
      inputType: 'text',
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      multipleAnswerStatus: true,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Do our opening times meet your needs'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.No') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.If rather no, what (basic) times do you need'),
      inputType: 'text',
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      multipleAnswerStatus: true,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.How do you get along with the kindergarten app – Stramplerbande'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Good') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Okay') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Bad') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.How well do you feel informed about the app'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Good') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Okay') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Bad') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Ideas for the Stramplerbande'),
      inputType: 'text',
      multipleAnswerStatus: true,
      allowUserAddSuggestionStatus: false,
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.Did you experience the acclimatization as technically competent'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.No') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.How satisfied are you (in general) with lunch'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Moderate') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Unhappy') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        // eslint-disable-next-line max-len
        'ParentSurvey.How do you feel about the cooperation with the daycare staff? (approachable, capable of criticism, open, etc.)',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Very satisfied') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Satisfied') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.Moderate') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.Unhappy') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.Short discussions about the delivery and pick-up times are available and informative',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.No') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.What is most important to you about your child in a personal conversation',
      ),
      inputType: 'text',
      multipleAnswerStatus: true,
      allowUserAddSuggestionStatus: false,
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t("ParentSurvey.Will the caregivers tell me about my child's level of development"),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.No') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        // eslint-disable-next-line max-len
        'ParentSurvey.The offers in the day care center are diverse and address different interests? (Music, movement, painting, handicrafts, etc.)',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.No') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.We miss that'),
      inputType: 'text',
      multipleAnswerStatus: true,
      allowUserAddSuggestionStatus: false,
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t(
        'ParentSurvey.Do the children have enough time to play and discover independently',
      ),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.Yes') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.Could be more') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.No') },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.HowComfortableChild'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.AnswerVeryComfortable') },
        { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerComfortable') },
        { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerNotVeryComfortable') },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotComfortable') },
      ],
      checked: false,
      type: 'prepared',
      subQuestions: [
        {
          id: Math.random(),
          question: t('ParentSurvey.HowComfortableChildWhatCouldHelp'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: true,
          orderPosition: 0,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
          ],
          checked: false,
          type: 'prepared',
        },
      ],
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.DecisiveFactor'),
      inputType: 'text',
      multipleAnswerStatus: true,
      allowUserAddSuggestionStatus: true,
      options: [
        {
          id: Math.random(),
          orderPosition: 0,
          answer: t('ParentSurvey.DecisiveFactorRecommendation'),
        },
        {
          id: Math.random(),
          orderPosition: 1,
          answer: t('ParentSurvey.DecisiveFactorConceptConvinced'),
        },
        {
          id: Math.random(),
          orderPosition: 2,
          answer: t('ParentSurvey.DecisiveFactorProtestantProfile'),
        },
        { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.DecisiveFactorRooms') },
        {
          id: Math.random(),
          orderPosition: 4,
          answer: t('ParentSurvey.DecisiveFactorOnlyFreePlace'),
        },
        {
          id: Math.random(),
          orderPosition: 5,
          answer: t('ParentSurvey.DecisiveFactorCloseToResidence'),
        },
        {
          id: Math.random(),
          orderPosition: 6,
          answer: t('ParentSurvey.DecisiveFactorSiblingAlreadyThere'),
        },
      ],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.WhatDoYouParticularlyLike'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: true,
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      checked: false,
      type: 'prepared',
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.InOurKitaVarious'),
      inputType: 'header',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
      subQuestions: [
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousEnoughRoomAndTime'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 0,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousDevelopIndependence'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 1,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousSocialEmotionalDevelopment'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 2,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousChristianValues'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 3,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousNatureAndEnvironment'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 4,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousNaturalScienceAndTechnology'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 5,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousLanguageDevelopment'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 6,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousDifferentMusicalOffers'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 7,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousArtisticPossibilities'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 8,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousFestivities'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 9,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousSchoolPreparation'),
          inputType: 'doubleDropdownImportance',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 10,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.InOurKitaVariousAdditionalNotes'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: true,
          orderPosition: 11,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
          ],
          checked: false,
          type: 'prepared',
        },
      ],
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.ParentRelationsAndInformationTools'),
      inputType: 'header',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
      subQuestions: [
        {
          id: Math.random(),
          question: t('ParentSurvey.ParentRelationsAndInformationToolsParentChildEvents'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 0,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.ParentRelationsAndInformationToolsParentsEvenings'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 1,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.ParentRelationsAndInformationToolsParentsTalks'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 2,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t(
            'ParentSurvey.ParentRelationsAndInformationToolsFestivalsAndReligiousServices',
          ),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 3,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.ParentRelationsAndInformationToolsOrganizationalInformation'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 4,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.ParentRelationsAndInformationToolsDevelopmentDocumentation'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 5,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.ParentRelationsAndInformationToolsWebsite'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 6,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.ParentRelationsAndInformationToolsDesiresAndSuggestions'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: true,
          orderPosition: 7,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
          ],
          checked: false,
          type: 'prepared',
        },
      ],
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.HappyWithEquipment'),
      inputType: 'header',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
      subQuestions: [
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithEquipmentRoomDesign'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 0,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithEquipmentTidiness'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 1,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithEquipmentGarden'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 2,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithEquipmentToysAndLearningMaterials'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 3,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithEquipmentAdditionalNotes'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: true,
          orderPosition: 4,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
          ],
          checked: false,
          type: 'prepared',
        },
      ],
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.HappyWithCatering'),
      inputType: 'header',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
      subQuestions: [
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithCateringAssortment'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 0,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithCateringAmount'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 1,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithCateringPrice'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 2,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithCateringAdditionalNotes'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: true,
          orderPosition: 3,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
          ],
          checked: false,
          type: 'prepared',
        },
      ],
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.HappyWithGeneralConditions'),
      inputType: 'header',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      checked: false,
      type: 'prepared',
      subQuestions: [
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithGeneralConditionsOpeningTimes'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 0,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithGeneralConditionsClosingDays'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 1,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithGeneralConditionsParentContribution'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: false,
          orderPosition: 2,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
            { id: Math.random(), orderPosition: 1, answer: t('ParentSurvey.AnswerVery') },
            { id: Math.random(), orderPosition: 2, answer: t('ParentSurvey.AnswerKindOf') },
            { id: Math.random(), orderPosition: 3, answer: t('ParentSurvey.AnswerNotReally') },
            { id: Math.random(), orderPosition: 4, answer: t('ParentSurvey.AnswerNotAtAll') },
          ],
          checked: false,
          type: 'prepared',
        },
        {
          id: Math.random(),
          question: t('ParentSurvey.HappyWithGeneralConditionsAdditionalNotes'),
          inputType: 'text',
          multipleAnswerStatus: false,
          allowUserAddSuggestionStatus: true,
          orderPosition: 3,
          options: [
            { id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') },
          ],
          checked: false,
          type: 'prepared',
        },
      ],
    },
    {
      id: Math.random(),
      question: t('ParentSurvey.AnythingElse'),
      inputType: 'text',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: true,
      options: [{ id: Math.random(), orderPosition: 0, answer: t('ParentSurvey.No information') }],
      checked: false,
      type: 'prepared',
    },
  ];

  catalog = catalog.map((question, index) =>
    question ? { ...question, orderPosition: index } : null,
  );
  return catalog;
}

export function getQuestionCatalogueEmployee(t) {
  let catalog = [
    {
      id: Math.random(),
      question: t('Wie lange sind Sie bereits in unserer Einrichtung tätig'),
      inputType: 'number',
      multipleAnswerStatus: false,
      allowUserAddSuggestionStatus: false,
      options: [
        { id: Math.random(), orderPosition: 0, answer: '1' },
        { id: Math.random(), orderPosition: 1, answer: '2' },
        { id: Math.random(), orderPosition: 2, answer: '3' },
        { id: Math.random(), orderPosition: 3, answer: '4' },
        { id: Math.random(), orderPosition: 4, answer: '5' },
        { id: Math.random(), orderPosition: 5, answer: '6' },
        { id: Math.random(), orderPosition: 6, answer: '7' },
        { id: Math.random(), orderPosition: 7, answer: '8' },
        { id: Math.random(), orderPosition: 8, answer: '9' },
      ],
      checked: false,
      type: 'prepared',
    },
  ];

  catalog = catalog.map((question, index) =>
    question ? { ...question, orderPosition: index } : null,
  );
  return catalog;
}

export function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}
