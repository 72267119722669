import React from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCreateAtAccountDte } from 'library/utilities/date';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import styles from './historyRow.module.scss';

import { formatDateOnlyTime } from 'library/utilities/dateMoment';
import { adjustTimeZone } from '../../../../../../utilities/date';

const HistoryRow = ({ childObj, groupOnly, setEntryToDelete, setEntryToEdit }) => {

  const { t } = useTranslation();
  const getStatus = () => {
    switch (childObj.checkInOutStatus) {
      case 'CHECKIN':
        return <span className={styles.statusIn}>{t('Checkinout.Checked In')}</span>;
      case 'CHECKOUT':
        return <span className={styles.statusOut}>{t('Checkinout.Checked Out')}</span>;
      default:
        return <span className={styles.statusAbsent}>{t('Checkinout.Absent')}</span>;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.photo}>
        <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
      </div>
      <div className={styles.name}>
        {childObj.name}
        {childObj.parent1Name !== null && (
          <span className={styles.parent}>{childObj.parent1Name}</span>
        )}
        {childObj.parent2Name !== null && (
          <span className={styles.parent}>{childObj.parent2Name}</span>
        )}
      </div>
      <div className={styles.executivePerson}>
        <img
          src={
            childObj.initiator && childObj.initiator.photo
              ? childObj.initiator.photo
              : defaultAvatar
          }
          alt=''
        />
        {/*initiator can be null if deleted*/}
        {childObj.initiator}
      </div>
      <div className={styles.status}>{getStatus()}</div>
      <div className={styles.group}>{childObj.groupName}</div>
      <div className={styles.pickupPerson}>{childObj.authorizedToPickUpChild !== null ? childObj.authorizedToPickUpChild : ""}</div>
      <div className={styles.note}>
        {childObj.notes && (
          <ul>
            {childObj.notes.map(note => {
              return (
                <li>
                  <span style={{ fontWeight: 'bold' }}>
                    {formatDateOnlyTime(adjustTimeZone(note.date))}
                  </span>{' '}
                  {note.additionalTextMessage}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className={styles.date}>{getCreateAtAccountDte(childObj.actionDate)}</div>
      {groupOnly && new Date(childObj.actionDate).toDateString() === new Date().toDateString() && (
        <div>
          <a onClick={() => setEntryToEdit(childObj)}>
            <i className='fa fa-pencil' />
          </a>
          <a onClick={() => setEntryToDelete(childObj.id)}>
            <i className='fa fa-trash' />
          </a>
        </div>
      )}
    </div>
  );
};

HistoryRow.propTypes = {
  childObj: PT.shape({
    kidId: PT.number.isRequired,
    name: PT.string.isRequired,
    photo: PT.string,
    parent1Name: PT.string,
    parent2Name: PT.string,
    actionDate: PT.string.isRequired,
    checkInOutStatus: PT.string.isRequired,
  }).isRequired,
};

export default HistoryRow;
