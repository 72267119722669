import { UPDATE_FOLDERS, UPDATE_BREADCRUMBS, UPDATE_IMAGES_DIMENSIONS_STATE } from 'library/common/commonConstants/fileConstants';

export const updateFolders = data => dispatch => {
  dispatch({
    type: UPDATE_FOLDERS,
    payload: data,
  });
};

export const updateBreadcrumbs = data => dispatch => {
  dispatch({
    type: UPDATE_BREADCRUMBS,
    payload: data,
  });
};

export const updateImagesDimensionsState = data => dispatch => {
  dispatch({
    type: UPDATE_IMAGES_DIMENSIONS_STATE,
    payload: data,
  });
};
