import React, { useState } from 'react';

import styles from './collapsible.module.scss';

export default function Collapsible({ title, body, previewImage }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: '#ededed',
        borderRadius: '5px',
        width: '49%',
        minWidth: '320px',
        flexGrow: '1',
      }}
    >
      <div
        style={{
          backgroundColor: '#ededed',
          fontWeight: 'bold',
          padding: '5px',
          minHeight: '50px',
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {title}
        <br />
        {previewImage !== null && !isOpen && (
          <img src={previewImage} alt='' style={{ maxHeight: '200px', maxWidth: '90%' }} />
        )}
        <i
          className={isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
          style={{ float: 'right' }}
        />
      </div>
      {isOpen && <div style={{ padding: '5px' }}>{body}</div>}
    </div>
  );
}
