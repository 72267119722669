import { useTranslation } from 'react-i18next';
import axiosInstance from 'library/api/axios';
import * as at from './administrationConstants';

const { t } = useTranslation();

export const invitationService = payload => {
  return axiosInstance
    .post(at.URLS.invitation, payload)
    .then(res => {
      switch (res.status) {
        case 200:
          return {
            status: res.status,
            result: true,
            reason: t('Administration.UserSection.Successfully Sent invite link'),
          };
        case 208:
          return {
            status: res.status,
            result: false,
            reason: t('Administration.UserSection.User is already registered with'),
          };
        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 503:
            return {
              status: err.response.status,
              result: false,
              reason: t('Administration.UserSection.Unable to send email invite'),
            };
          case 406:
            return {
              status: err.response.status,
              result: false,
              reason: t(
                "Administration.UserSection.If someone who doesn't have access, send the request",
              ),
            };
          default:
            return {
              status: err.response.status,
              result: false,
              reason: t('Administration.UserSection.Users invitation error'),
            };
        }
      } else {
        return false;
      }
    });
};
