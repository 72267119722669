import { getCredentialModules } from 'library/api/modules';

export async function generatePublicKeyCredential(callback) {
  if (await shouldShowEmailVerification()) {
    return;
  }

  const publicKeyData = (await getCredentialModules()).data;
  const byteValues = publicKeyData.challenge
    .slice(1, -1)
    .split(', ')
    .map(hex => parseInt(hex, 16));
  const publicKey = {
    ...publicKeyData,
    user: { ...publicKeyData.user, id: new Uint8Array(16) },
    challenge: new Uint8Array(byteValues),
  };
  const credential = await navigator.credentials.create({
    publicKey: publicKey,
  });

  if (callback) {
    callback();
  }
  return credential.id;
}

export async function shouldShowEmailVerification() {
  const userAgent = navigator.userAgent;
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(userAgent);
  const fingerprintSupport = await checkFingerprintSupport();
  return !isMobileDevice || !fingerprintSupport;
}

async function checkFingerprintSupport() {
  if (!window.PublicKeyCredential) {
    return false;
  }

  try {
    const isSupported = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    return isSupported;
  } catch (error) {
    return false;
  }
}
