import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import SearchModule from './searchModule';

export const mapStateToProps = state => ({ user: state.userReducer });

export default connect(
  mapStateToProps,
  {
    showBottomNotification,
  },
)(SearchModule);
