import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { getConfigureTaskModule, setConfigureTaskModule } from 'library/api/modules';
import Loader from 'library/common/commonComponents/Loader';
import Card from 'library/common/commonComponents/Card';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from './tasksSettings.module.scss';

export default function TasksSettings({ id, showBottomNotification }) {
  const { t } = useTranslation();
  const [taskSettings, setTaskSettings] = useState({
    id: null,
    showSnippet: true,
    showSnippetInGroup: true,
    maxTasksItems: 5,
    showGlobalTaskMenuItem: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const onError = () =>
    showBottomNotification(t('BottomNotifications.Something went wrong'), { isFail: true });
  const onSuccess = () => showBottomNotification(t('BottomNotifications.Saved'));

  useEffect(() => {
    setIsLoading(true);
    getConfigureTaskModule(id)
      .then(res => {
        if (res.status === 200) {
          setTaskSettings({
            ...res.data,
            maxTasksItems: res.data.maxTasksItems || 5,
          });
        } else {
          onError();
        }
        setIsLoading(false);
      })
      .catch(err => {
        onError();
        setIsLoading(false);
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = () => {
    if (taskSettings.maxTasksItems < 1) {
      return setError(true);
    }
    setIsSubmitting(true);
    setConfigureTaskModule(taskSettings.id, taskSettings)
      .then(res => {
        if (res.status === 200) {
          onSuccess();
        } else {
          onError();
        }
        setIsSubmitting(false);
      })
      .catch(err => {
        onError();
        setIsSubmitting(false);
        console.log(err);
      });
  };

  const handleMaxTasksItemsChange = value => {
    setError(false);
    setTaskSettings({ ...taskSettings, maxTasksItems: value });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card title={<Trans i18nKey='Tasks.Task module configuration' />} className={styles.body}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.titleHeader}>{t('Tasks.Your tasks snippet')}</div>
          <div className={styles.tittleDescription}>{t('Tasks.Shows a widget with tasks')}</div>
        </div>
        <div className={styles.checkboxContainerSnippet}>
          <CheckBox
            className={styles.checkbox}
            name={t('Tasks.Show snippet')}
            isChecked={taskSettings.showSnippet}
            onChange={() =>
              setTaskSettings({ ...taskSettings, showSnippet: !taskSettings.showSnippet })
            }
          />
        </div>
        <div className={styles.checkboxContainer}>
          <CheckBox
            className={styles.checkbox}
            name={t('Tasks.Show snippet in Group')}
            isChecked={taskSettings.showSnippetInGroup}
            onChange={() =>
              setTaskSettings({
                ...taskSettings,
                showSnippetInGroup: !taskSettings.showSnippetInGroup,
              })
            }
          />
          <div className={styles.description}>
            {t('Tasks.Shows the widget also on the dashboard of groups')}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <Label type='input'>{t('Tasks.Max tasks items')}</Label>
          <Input
            value={taskSettings.maxTasksItems}
            error={error}
            type='number'
            min={1}
            onChange={e => handleMaxTasksItemsChange(+e.target.value)}
          />
          {error && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={t('Tasks.Max tasks items must be no less than 1')} />
            </div>
          )}
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.titleHeader}>{t('Tasks.Global task menu item')}</div>
          <div className={styles.tittleDescription}>
            {t('Tasks.Displays a global task menu item on the main menu')}
          </div>
        </div>
        <div className={styles.checkboxContainer}>
          <CheckBox
            className={styles.checkbox}
            name={t('Tasks.Show global task menu item')}
            isChecked={taskSettings.showGlobalTaskMenuItem}
            onChange={() =>
              setTaskSettings({
                ...taskSettings,
                showGlobalTaskMenuItem: !taskSettings.showGlobalTaskMenuItem,
              })
            }
          />
        </div>
        <div className={styles.buttonContainer}>
          <ButtonWithLoader onClick={handleSubmit} type='primary' isLoading={isSubmitting}>
            {t('Calendar.Save')}
          </ButtonWithLoader>
        </div>
      </div>
    </Card>
  );
}
