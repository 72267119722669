import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';

import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';

import styles from './groupAWOUsersListPopup.module.scss';

export default function GroupAWOUsersListPopup({ isOpened, closePopup, title, isAdmin, members }) {
  const [initialUsers, setInitialUsers] = useState([]);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setInitialUsers(
      members.map(m => {
        return {
          ...m.user,
        };
      }),
    );
  }, [members]);

  const goUserPage = userObj => {
    if (isAdmin) {
      history.push({
        pathname: '/administration/users/list/1',
        search: `?editUserId=${userObj.id}`,
      });
    }
  };

  return (
    <>
      <Popup
        isOpened={isOpened}
        closePopup={closePopup}
        header={title}
        footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
      >
        <div>
          <div className={styles.container}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={568}>
              {initialUsers.map(member => {
                const userId = member.userId || member.id;
                return (
                  <div onClick={() => goUserPage(member)} key={userId} className={styles.item}>
                    <div className={styles.itemAvatar}>
                      <ProfileAvatar image={member} id={userId} withoutLink />
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <div className={styles.itemInfo}>
                        <div className={cn(styles.itemName, 'notranslate')}>
                          {`${member.firstName} ${member.lastName}`}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        </div>
      </Popup>
    </>
  );
}
