import React from 'react';
import Popup from 'library/common/commonComponents/Popups/Popup';
import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import styles from './ingredientShowingPopup.module.scss';

const IngredientShowingPopup = ({ isOpened, closePopup, ingredients }) => {
  const { t } = useTranslation();

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='medium'
      header={t('FoodOrder.Show Ingredients')}
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={closePopup}>{t('FoodOrder.OK')}</Button>
        </div>
      }
    >
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('FoodOrder.Reference Name')}</th>
              <th>{t('FoodOrder.Name')}</th>
            </tr>
          </thead>
          <tbody>
            {ingredients.map(ingredient => (
              <tr key={ingredient.id}>
                <td>{ingredient.referenceName}</td>
                <td>{ingredient.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Popup>
  );
};

export default IngredientShowingPopup;
