import React, { useState } from 'react';

import Loader from 'library/common/commonComponents/Loader';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import styles from './usersFrameKitaAdmins.module.scss';

export default function UsersFrameAwoMembers({ activeKita, data, setEditingProfile }) {

  const [isLoading, setIsLoading] = useState(false);

  return (

    <div className={styles.container}>
      {!isLoading ? (
        <div className={styles.awoMemberList}>
          {data.data.content.map((item) => (
            <>
              <div
                className={styles.awoMemberItem}
                onClick={() => setEditingProfile({
                  ...item.user,
                  adminRole: item.kitaRoles.roles.adminRole,
                  employee: item.employee,
                  qrCodeScanner: item.qrCodeScanner,
                  ableToEditUserProfiles: item.ableToEditUserProfiles,
                  awoMember: item.awoMember,
                })}
              >
                <div className={styles.profileAvatarWrapper}>
                  <ProfileAvatar id={item.user.id} />
                </div>
                <div className={styles.memberName}>{`${item.user.firstName} ${item.user.lastName}`}</div>
                <div className={styles.memberEmail}>{item.user.email}</div>
              </div>
              <hr />
            </>
          ))}
        </div>
      ) : (
        <Loader />
      )
      }
    </div>
  );
}
