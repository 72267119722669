import React from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import styles from './activateEmployeeCheckInOutPopup.module.scss';

export default function ActivateEmployeeCheckInOutPopup({
  isOpened,
  setIsOpened,
  onChangeActivate,
  setAciveModule,
  module,
}) {
  const { t } = useTranslation();
  return (
    <Popup
      isOpened={isOpened}
      closePopup={() => {
        setIsOpened(false);
        setAciveModule({});
      }}
      size='extraSmall'
      header={t('EmployeeCheckInOutPopup.Activate Employee Checkin / Checkout Module')}
      footer={
        <div className={styles.footerNavigation}>
          <Button
            onClick={() => {
              setIsOpened(false);
              setAciveModule({});
            }}
          >
            {t('Confirm.Cancel')}
          </Button>
          <Button
            onClick={() => {
              onChangeActivate({ ...module, activeStatus: true }, 'ENABLE');
              setIsOpened(false);
              setAciveModule({});
            }}
            type='primary'
          >
            {t('Confirm.Confirm')}
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.text}>
          {t(
            'EmployeeCheckInOutPopup.For this module fees of 10 EUR per month plus VAT apply would you like to unlock the module',
          )}
        </div>
      </div>
    </Popup>
  );
}
