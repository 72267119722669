import React from 'react';
import { useTranslation } from 'react-i18next';
import Label from "library/common/commonComponents/Label";
import Input from "library/common/commonComponents/Inputs/Input";
import Checkbox from "library/common/commonComponents/Checkbox";
import Button from "../../../../../../../../../../library/common/commonComponents/Buttons/Button";
import Select from "../../../../../../../../../../library/common/commonComponents/Inputs/Select";

export default function CheckboxEditView({
  component,
  setComponent,
  pages,
}) {
  const { t } = useTranslation();

  const conditionOptions = [
    {
      value: true,
      label: t('FormsEditor.ComponentEditorConditionActionsTrueLabel'),
    },
    {
      value: false,
      label: t('FormsEditor.ComponentEditorConditionActionsFalseLabel'),
    },
  ];

  const conditionActionTypes = [
    {
      value: 'ENABLE_PAGE',
      label: t('FormsEditor.ComponentEditorConditionActionsEnablePageActionLabel'),
    },
    {
      value: 'DISABLE_PAGE',
      label: t('FormsEditor.ComponentEditorConditionActionsDisablePageActionLabel'),
    }
  ];

  const pageOptions = pages ? pages.map(p => {
    return {
      value: p.id,
      label: `${p.name}`,
    };
  }) : [];

  const renderConditionActionsArgs = (a, index) => {
    if (["ENABLE_PAGE", "DISABLE_PAGE"].includes(a.action))
    {
      return (
        <Select
          options={pageOptions}
          onSelect={e => {
            setComponent({
              ...component,
              conditionActions: component.conditionActions.map((a2, i) => {
                if (i === index)
                {
                  return {
                    ...a2,
                    args: e.value,
                  }
                }
                return a2;
              })
            });
          }}
          selected={pageOptions.filter(po => po.value === parseInt(a.args, 10))[0]}
        />
      );
    }

    return "";
  };

  return (
    <div>
      <Checkbox
        name={t('FormsEditor.ComponentEditorAllowParentsFillOutLabel')}
        isChecked={component.allowParentsFillOut}
        onChange={() => {
          setComponent({
            ...component,
            allowParentsFillOut: !component.allowParentsFillOut,
          });
        }}
      />
      <Checkbox
        name={t('FormsEditor.ComponentEditorAllowKitaFillOutLabel')}
        isChecked={component.allowKitaFillOut}
        onChange={() => {
          setComponent({
            ...component,
            allowKitaFillOut: !component.allowKitaFillOut,
          });
        }}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorUniqueNameLabel')}</Label>
      <Input
        value={component.name}
        onChange={e => {
          setComponent({
            ...component,
            name: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorUniqueNamePlaceholder')}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorIndentLabel')}</Label>
      <Input
        value={component.indent}
        onChange={e => {
          setComponent({
            ...component,
            indent: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorIndentPlaceholder')}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorLabelLeftLabel')}</Label>
      <Input
        value={component.labelLeft}
        onChange={e => {
          setComponent({
            ...component,
            labelLeft: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorLabelLeftPlaceholder')}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorLabelRightLabel')}</Label>
      <Input
        value={component.labelRight}
        onChange={e => {
          setComponent({
            ...component,
            labelRight: e.target.value,
          });
        }}
        placeholder={t('FormsEditor.ComponentEditorLabelRightPlaceholder')}
      />
      <Checkbox
        name={t('FormsEditor.ComponentEditorDefaultCheckedLabel')}
        isChecked={component.defaultChecked}
        onChange={() => {
          setComponent({
            ...component,
            defaultChecked: !component.defaultChecked,
          });
        }}
      />
      <Label type="input">{t('FormsEditor.ComponentEditorConditionActionsLabel')}</Label>
      <table>
        <tbody>
        {component.conditionActions.map((a, i1) => {
          return (
            <>
              <tr>
                <td>
                  <Select
                    options={conditionOptions}
                    onSelect={e => {
                      setComponent({
                        ...component,
                        conditionActions: component.conditionActions.map((a2, i2) => {
                          if (i1 === i2)
                          {
                            return {
                              ...a2,
                              checkboxCondition: e.value,
                            }
                          }
                          return a2;
                        })
                      });
                    }}
                    selected={conditionOptions.filter(op => op.value === a.checkboxCondition)[0]}
                  />
                </td>
                <td>
                  <a onClick={() => {
                    setComponent({
                      ...component,
                      conditionActions: component.conditionActions.filter((a2, i2) => {
                        return i2 !== i1;
                      })
                    });
                  }}><i className='fa fa-trash' /></a>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Select
                    options={conditionActionTypes}
                    onSelect={e => {
                      setComponent({
                        ...component,
                        conditionActions: component.conditionActions.map((a2, i2) => {
                          if (i1 === i2)
                          {
                            return {
                              ...a2,
                              action: e.value,
                              args: ["ENABLE_PAGE", "DISABLE_PAGE"].includes(e.value) && pageOptions.length > 0 ? `${pageOptions[0].id}` : "",
                            }
                          }
                          return a2;
                        })
                      });
                    }}
                    selected={conditionActionTypes.filter(actionType => actionType.value === a.action)[0]}
                  />
                </td>
              </tr>
              {renderConditionActionsArgs(a, i1)}
            </>
          );
        })}
        </tbody>
      </table>
      <Button onClick={() => {
        setComponent({
          ...component,
          conditionActions: [...component.conditionActions, {
            checkboxCondition: true,
            action: conditionActionTypes[0].value,
            args: pageOptions.length > 0 ? pageOptions[0].id : "",
          }],
        })
      }}>
        {t('FormsEditor.ComponentEditorOptionsAddButton')}
      </Button>
    </div>
  );
}
