import * as actionTypes from '../commonConstants/commonConstants';

const initialState = {
    isInputError: false,
}
const InputErrorReducer=(state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.RESET_INPUT_ERROR:
            return {
                ...state,
                isInputError: action.payload
            }
        default:
            return state;
    }
}

export default InputErrorReducer;