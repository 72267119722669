import React, { useEffect, useState } from 'react';

import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { formatDateNoTime } from 'library/utilities/dateMoment';
import { getKidHistory } from 'library/api/kidDevelopmentDiary';
import DateRow from './DateRow';

import styles from './childRow.module.scss';
import { adjustTimeZone } from 'library/utilities/date';

const ChildRow = ({
  childObj,
  wrapActive,
  foodActive,
  sleepActive,
  pedagogicalObservation,
  kidAbsences,
}) => {
  const [historie, setHistorie] = useState([]);
  const [groupedHistory, setGroupedHistory] = useState([]);
  // 'wraps' refers to the number of times the child has been changed
  const [wraps, setWraps] = useState([]);
  const [hideChildDetails, setHideChildDetails] = useState(true);

  function returnGermanDatesOfArray(array) {
    const tempArray = [];
    for (const element of array) {
      const formattedDate = formatDateNoTime(element.date);
      if (!tempArray.includes(formattedDate)) {
        tempArray.push(formattedDate);
      }
    }
    return tempArray;
  }

  function getGroupedObjectByTime(array) {
    const grouped = array.reduce((grpdHistory, entry) => {
      const formattedDate = formatDateNoTime(entry.date);

      if (grpdHistory[formattedDate] == null) {
        grpdHistory[formattedDate] = [];
      }
      grpdHistory[formattedDate].push(entry);
      return grpdHistory;
    }, {});

    return grouped;
  }

  function filterFutureDates(dates) {
    const today = new Date(); // Get today's date
  
    return dates.filter(dateString => {
      const date = new Date(dateString.split('.').reverse().join('-')); // Convert string to Date object
      return date <= today; // Filter by future dates (greater than today)
    });
  }

  function allDateRows() {
    const neededDateRowsFromHistory = returnGermanDatesOfArray(historie);
    let neededDates = [];
    if (typeof kidAbsences !== 'undefined') {
      // calculates how many DateRows are needed to cover each absence
      kidAbsences.forEach(absence => {
        const startDate = new Date(absence.startDate);
        const endDate = new Date(absence.endDate);

        const dateDifferenceMilliseconds = endDate - startDate;
        const dateDifferenceDays = dateDifferenceMilliseconds / (1000 * 60 * 60 * 24);

        const daysThatNeedToBeCovered =
          1 +
          Math.floor(dateDifferenceDays) +
          (new Date(
            startDate.getTime() + Math.floor(dateDifferenceDays) * 60 * 60 * 24 * 1000,
          ).getDay() != endDate.getDay()
            ? 1
            : 0);

        for (let i = 0; i < daysThatNeedToBeCovered; i++) {
          const shouldBeCovered = new Date(startDate.getTime() + i * 60 * 60 * 24 * 1000);
          const dateIsAlreadyCovered = neededDateRowsFromHistory.find(dateString => {
            const dateStringSplit = dateString.split('.');
            const date = new Date(dateStringSplit[2], dateStringSplit[1] - 1, dateStringSplit[0]);

            return (
              date.getDate() == shouldBeCovered.getDate() &&
              date.getMonth() == shouldBeCovered.getMonth() &&
              date.getFullYear() == shouldBeCovered.getFullYear()
            );
          });
          if (!dateIsAlreadyCovered) {
            neededDates.push(shouldBeCovered);
          }
        }
      }, []);
    }

    const formattedDates = neededDateRowsFromHistory
      .concat(
        neededDates.map(
          date =>
            `${date
              .getDate()
              .toString()
              .padStart(2, '0')}.${(date.getMonth() + 1)
                .toString()
                .padStart(2, '0')}.${date.getFullYear()}`,
        ),
      );

    const futureDates = filterFutureDates(formattedDates); // Filter future dates

    return futureDates.sort((a, b) => {
      const dateA = new Date(a.split('.').reverse().join('-'));
      const dateB = new Date(b.split('.').reverse().join('-'));
      return dateB - dateA; // Sort in descending order (newest to oldest)
    });

    return [];
  }

  function getAbsencesForDay(date) {
    if (!kidAbsences) return [];

    return kidAbsences.filter(absence => {
      let startDate = new Date(absence.startDate);
      let endDate = new Date(absence.endDate);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      if (date > startDate && date < endDate) return true;
      return false;
    });
  }
  function getWrapsForDay(date) {
    if (!wraps) return [];

    return wraps.filter(wrap => {
      const wrapDate = new Date(wrap.date);
      if (
        wrapDate.getDate() == date.getDate() &&
        wrapDate.getMonth() == date.getMonth() &&
        wrapDate.getFullYear() == date.getFullYear()
      )
        return true;
      return false;
    });
  }

  useEffect(() => {
    const curDate = new Date();
    const month = curDate.getUTCMonth() + 1;
    const year = curDate.getUTCFullYear();

    const monthYearString =
      month.toString().padStart(2, '0') + '.' + year.toString().padStart(4, '0');

    getKidHistory(childObj.kidId || childObj.id, monthYearString).then(res => {
      const mappedCheckinouts = res.data.kidCheckInOuts.map(obj => {
        return {
          action: obj.checkInOutType,
          date: obj.checkDate,
          personToPickUpChild: obj.personToPickUpChild ?? null,
        };
      });

      // find out how many wraps should be displayed
      const wraps = res.data.kidDevelopmentDiaries.reduce((wrapStack, currentEntry) => {
        if (currentEntry.action === 'wrap') return [...wrapStack, currentEntry];
        if (currentEntry.action === 'wrap-undo') {
          // remove last wrap that doesn't have an additional text message
          const lastWrap = wrapStack.reverse().find(wrap => !wrap.additionalTextMessage);
          return wrapStack.filter(wrap => wrap !== lastWrap);
        }
        return wrapStack;
      }, []);
      const wrapsAdjustedForTimeZone = wraps.map(wrap => ({
        ...wrap,
        date: adjustTimeZone(wrap.date),
      }));
      setWraps(wrapsAdjustedForTimeZone);

      const concattedArrayData = mappedCheckinouts.concat(res.data.kidDevelopmentDiaries);

      const sorted = concattedArrayData.sort(function (a, b) {
        const dateA = new Date(a.date.replace(' ', 'T'));
        const dateB = new Date(b.date.replace(' ', 'T'));

        const dateNoTimeA = new Date(dateA.toDateString());
        const dateNoTimeB = new Date(dateB.toDateString());

        if (dateNoTimeA.getTime() !== dateNoTimeB.getTime()) {
          return -(dateNoTimeA.getTime() - dateNoTimeB.getTime());
        }

        return dateA - dateB;
      });

      setHistorie(sorted);
      setGroupedHistory(getGroupedObjectByTime(sorted));
    });
  }, []);

  return (
    <div>
      <div
        className={styles.wrapper}
        onClick={() => {
          setHideChildDetails(!hideChildDetails);
        }}
      >
        <div className={styles.photo}>
          <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
        </div>
        <div className={styles.name}>
          {childObj.name || `${childObj.firstName} ${childObj.lastName}`}
          {childObj.parent1Name !== null && (
            <span className={styles.parent}>{childObj.parent1Name}</span>
          )}
          {childObj.parent2Name !== null && (
            <span className={styles.parent}>{childObj.parent2Name}</span>
          )}
        </div>
        <div>
          <i className={hideChildDetails ? 'fa fa-arrow-down' : 'fa fa-arrow-up'} />
        </div>
      </div>
      {hideChildDetails === false && (
        <div>
          {allDateRows().map(obj => {
            return (
              <div key={childObj.kidId + ':' + obj}>
                <DateRow
                  date={obj}
                  groupedHistory={groupedHistory[obj] ?? []}
                  wrapActive={wrapActive}
                  foodActive={foodActive}
                  sleepActive={sleepActive}
                  pedagogicalObservation={pedagogicalObservation}
                  absences={getAbsencesForDay(
                    new Date(
                      obj
                        .split('.')
                        .reverse()
                        .join('-'),
                    ),
                  )}
                  wraps={getWrapsForDay(
                    new Date(
                      obj
                        .split('.')
                        .reverse()
                        .join('-'),
                    ),
                  )}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ChildRow;
