import React from 'react';
import {  useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import foodOrderExcelUploadImage from 'resources/images/foodOrder/fooduploadinfo.png';

import styles from './foodOrderExcelInfoPopup.module.scss';

export default function FoodOrderExcelInfoPopup({isOpened, closePopup}) {
  const { t } = useTranslation();

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='medium'
      header={t('FoodOrder.Explanation for food plan excel upload')}
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={closePopup}>{t('FoodOrder.Close')}</Button>
        </div>
      }
    >
      <div className={styles.container}>
        Gerichte können über eine zuvor erstellte Excel Tabelle hochgeladen werden. <br /><br />
        Angefangen in der ersten Zeile der Excel Tabelle, stellt jede Zeile ein Gericht dar. Es können keine Zeilen übersprungen werden. <br /><br />
        In den Spalten werden die Eigenschaften der Gerichte festgelegt. <br /><br />
        <b>Spalte A:</b><br />
        Hier muss das Datum stehen, an dem das Gericht angeboten wird. Das Format des Datums muss folgendes sein: tt.mm.jjjj <br /><br />
        <b>Spalte B:</b><br />
        Hier muss der Titel des Gerichts stehen.<br /><br />
        <b>Spalte C:</b><br />
        Hier kann die Beschreibung des Gerichts stehen (optional).<br /><br />
        <b>Spalte D:</b><br />
        Hier kann der Preis des Gerichts stehen (optional). Wenn kein Preis eingetragen wird, wird der in den Konfigurationen festgelegte Standardpreis verwendet.<br /><br />
        <img src={foodOrderExcelUploadImage} />
      </div>
    </Popup>
  );
}
