import { useState } from 'react';

export default function useComments(comments) {
  const [showAllComments, setShowAllComments] = useState(comments.length < 3);
  if (comments.length < 3 && !showAllComments) {
    setShowAllComments(true);
  }

  const shouldRenderShowAllButton = !showAllComments && comments.length > 2;

  const commentsToDisplay = showAllComments ? comments : getNotAllComments(comments);

  return { setShowAllComments, shouldRenderShowAllButton, commentsToDisplay };
}

export function getNotAllComments(comments) {
  const newComments = comments.filter(comment => comment.isNew);
  const oldCommentsLength = comments.length - newComments.length;

  const oldCommentsToShow =
    oldCommentsLength > 2 ? comments.slice(oldCommentsLength - 2, oldCommentsLength) : [];

  return oldCommentsToShow.concat(newComments);
}
