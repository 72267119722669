import { useState, useEffect } from 'react';
import { getGroupMembers } from 'library/common/commonActions/groupActions';

const defaultUsersList = [
  {
    createdAt: '',
    groupRole: {
      role: {
        roleName: 'Member',
      },
    },
    user: {
      firstName: '',
      id: '',
      lastName: '',
      userName: '',
    },
  },
];

export default function useGetGroupMemberList(id, userList, setIsCheckedAll) {
  const [usersList, setUsersList] = useState(defaultUsersList);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [sortBy, setSorting] = useState({ sortBy: 'user.lastName', order: true });
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reloadMemberTableInfo, setReloadMemberTableInfo] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getGroupMembers(id, filters, sortBy, currentPage)
      .then(res => {
        switch (res.status) {
          case 200:
            setUsersList(res.data.content);
            setTotalPages(res.data.totalPages);
            setIsLoading(false);
            break;
          case 203:
            setUsersList([]);
            setTotalPages(1);
            setCurrentPage(1);
            setIsLoading(false);
            break;
          default:
        }
      })
      .catch(res => {
        switch (res.status || res.response.status) {
          case 500:
            setUsersList([]);
            setTotalPages(1);
            setCurrentPage(1);
            setIsLoading(false);
            break;
          default:
            setUsersList(defaultUsersList);
            setTotalPages(1);
            setCurrentPage(1);
            setIsLoading(false);
        }
      });
    return () => {
      setUsersList([]);
      setIsCheckedAll(false);
    };
    // eslint-disable-next-line
  }, [id, filters, reloadMemberTableInfo, sortBy, currentPage, userList.length]);

  const addFilter = newFilters => {
    const filtersTemp = filters.slice();

    newFilters.forEach(filter => {
      const index = filtersTemp.findIndex(item => item.filterName === filter.filterName);
      if (index !== -1) {
        filtersTemp.splice(index, 1, filter);
      } else {
        filtersTemp.push(filter);
      }
    });

    setFilters(filtersTemp.slice());
  };

  return {
    isLoading,
    usersList,
    setFilters: newFilters => addFilter(newFilters),
    setSorting: sort =>
      setSorting({
        sortBy: sort.sortBy,
        order: sortBy.sortBy === sort.sortBy ? !sortBy.order : true,
      }),
    totalPages,
    currentPage,
    setCurrentPage: page => setCurrentPage(page),
    reloadMemberTableInfo: () => setReloadMemberTableInfo(!reloadMemberTableInfo),
  };
}
