import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from "../../../../../../library/common/commonComponents/Buttons/Button";
import style from "./FormsOverview.module.scss";
import Loader from "../../../../../../library/common/commonComponents/Loader";
import ConfirmPopup from "../../../../../../library/common/commonComponents/Popups/ConfirmPopup";
import ImportFormPopup from 'library/common/commonComponents/Popups/ImportFormPopup';
import { deleteForm, getSettings, saveSettings } from "../../../../../../library/api/formEditor";

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import SettingsPopup from './FormsOverviewFrames/SettingsPopup';
import { settingsChangedSignal } from "../../../../../../library/common/commonActions/formEditorActions";
import Checkbox from 'library/common/commonComponents/Checkbox';
import Popup from 'library/common/commonComponents/Popups/Popup';
import { editFormConfig, getFormConfig } from 'library/api/kita';

export default function FormsOverview({
  createNewForm,
  forms,
  isLoading,
  startEditForm,
  reloadForms,
}) {
  const { t } = useTranslation();

  const [formToDelete, setFormToDelete] = useState(null);

  const [formToExport, setFormToExport] = useState(null);
  const [importFormPopupOpen, setImportFormPopupOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const [allowParentsFillOut, setAllowParentsFillOut] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [settings, setSettings] = useState(null);

  const [defaultContractForm, setDefaultContractForm] = useState(null);
  const [kidCheckbox, setKidCheckbox] = useState(true);
  const [userCheckbox, setUserCheckbox] = useState(false);

  useEffect(() => {
    reloadSettings();
  }, [forms]);

  const reloadSettings = () => {
    getSettings().then(res => {
      setSettings(res.data);
      resetSettingsInputs(res.data);
    });

    getFormConfig().then((res) => {
      if (res && res.data) {
        if (res.data) {
          setKidCheckbox(false);
          setUserCheckbox(true);
        } else {
          setUserCheckbox(false);
          setKidCheckbox(true);
        }
      }
    })
  };

  const resetSettingsInputs = (s) => {
    if (!s || !s.allowParentsFillOut) {
      setAllowParentsFillOut(false);
    }
    else {
      setAllowParentsFillOut(s.allowParentsFillOut);
    }
    if (!s || !s.defaultContractForm) {
      setDefaultContractForm({
        value: -1,
        label: t('Administration.FormsSettingsDefaultContractFormNone'),
      });
    }
    else {
      setDefaultContractForm({
        value: s.defaultContractForm.id,
        label: s.defaultContractForm.name,
      });
    }
  };

  const doSaveSettings = () => {
    setIsSavingSettings(true);
    saveSettings(defaultContractForm === null ? null : defaultContractForm.value, allowParentsFillOut).then(() => {
      store.dispatch(
        showBottomNotification(
          t('Administration.FormsSettingsSaveSuccess'),
          { isFail: false },
        ),
      );
      reloadSettings();
      setSettingsOpen(false);
      store.dispatch(settingsChangedSignal());
    }).catch(() => {
      store.dispatch(
        showBottomNotification(
          t('Administration.FormsSettingsSaveError'),
          { isFail: true },
        ),
      );
    }).finally(() => {
      setIsSavingSettings(false);
    });
  };

  const startDeleteForm = () => {
    deleteForm(formToDelete.id).then(res => {
      store.dispatch(
        showBottomNotification(
          t('Administration.FormsOverviewDeleteSuccess'),
          { isFail: false },
        ),
      );
      reloadForms();
    }).catch(err => {
      store.dispatch(
        showBottomNotification(
          t('Administration.FormsOverviewDeleteError'),
          { isFail: true },
        ),
      );
    }).finally(() => {
      setFormToDelete(null);
    })
  };

  const changePopupInput = (selection) => {
    if (selection == "Kids") {
      setUserCheckbox(false);
      setKidCheckbox(true);
      editFormConfig("kids").then((res) => {

      })
    } else {
      setKidCheckbox(false);
      setUserCheckbox(true);
      editFormConfig("users").then((res) => {

      })
    }
  }

  return (
    <><div className={style.container}>
      <div className={style.settingsButtonContainer}>
        <Button onClick={() => setSettingsOpen(true)}><i className="fa fa-cog" /></Button>
      </div>
      <SettingsPopup
        isOpened={settingsOpen}
        closePopup={() => {
          setSettingsOpen(false);
          resetSettingsInputs(settings);
        }}
        onCancelClick={() => {
          setSettingsOpen(false);
          resetSettingsInputs(settings);
        }}
        onSaveClick={() => doSaveSettings()}
        forms={forms}
        setDefaultContractForm={setDefaultContractForm}
        defaultContractForm={defaultContractForm}
        allowParentsFillOut={allowParentsFillOut}
        setAllowParentsFillOut={setAllowParentsFillOut}
        isSavingSettings={isSavingSettings} />
      <ConfirmPopup
        isOpened={formToDelete != null}
        closePopup={() => setFormToDelete(null)}
        onConfirm={startDeleteForm}
        text={t('Administration.FormsOverviewDeleteConfirmText')} />

      {formToExport != null &&
        <ConfirmPopup
          isOpened={formToExport != null}
          closePopup={() => setFormToExport(null)}
          onConfirm={() => { setFormToExport(null); }}
          text={<>{t('Administration.FormsOverviewExportText')}<br /><br /><b>{formToExport.naturalId}</b></>} />}
      <ImportFormPopup
        isOpened={importFormPopupOpen}
        closePopup={() => { setImportFormPopupOpen(false); }}
        store={store}
        showBottomNotification={showBottomNotification} />

      <h1>{t('Administration.FormsOverviewTitle')}</h1>
      <div className={style.pageContent}>
        <div className={style.addNewButton}>
          <Button
            onClick={() => setOpenPopup(true)}
            type='success'
            size='sm'
          >
            <i className='fa fa-plus' /> {t('Administration.FormsOverviewAddFormButton')}
          </Button>
        </div>
        <div className={style.addNewButton} style={{ marginTop: "5px" }}>
          <Button
            onClick={() => { setImportFormPopupOpen(true); }}
            type='success'
            size='sm'
          >
            <i className='fa fa-envelope' /> {t('Administration.FormsOverviewImportFormButton')}
          </Button>
        </div>
        {isLoading && (
          <Loader />
        )}
        {!isLoading && forms && forms.length > 0 ? (
          <div>
            <h2>{t('Administration.FormsOverviewListHeader')}</h2>

            <ul>
              {forms.map(f => {
                return (
                  <p>{f.name} <a onClick={() => startEditForm(f)}><i className="fa fa-pencil" /></a> <a onClick={() => setFormToDelete(f)}><i className="fa fa-trash" /></a> <a onClick={() => setFormToExport(f)}><i className="fa fa-envelope-open-o" /></a></p>);
              })}
            </ul>
          </div>
        ) : (
          <h2>{t('Administration.FormsOverviewListNoFormsHeader')}</h2>
        )}
      </div>
    </div><Popup
        isOpened={openPopup}
        closePopup={() => setOpenPopup(false)}
        header={t('Forms.SelectKidsAndUser')}
        body={<div className={style.checkBoxContainer}>
          <Checkbox
            className={style.checkbox}
            name={t('Forms.Kids')}
            isChecked={kidCheckbox}
            onChange={() => changePopupInput('Kids')}
          />
          <Checkbox
            className={style.checkbox}
            name={t('Forms.Users')}
            isChecked={userCheckbox}
            onChange={() => changePopupInput('Users')}
          />
        </div>}
        footer={<div>
          <Button className={style.proceedButton} onClick={createNewForm}>{t('Popup.Proceed')}</Button>
          <Button onClick={() => setOpenPopup(false)}>{t('Popup.Cancel')}</Button>
        </div>} /></>
  );
}
