import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { GroupContext } from 'modules/Group/groupModule';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import EditImagePopup from 'library/common/commonComponents/Popups/EditImagePopup';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import DeleteButton from 'library/common/commonComponents/Buttons/DeleteButton';
import EditButton from 'library/common/commonComponents/Buttons/EditButton';
import useCachedImage from 'library/common/commonHooks/useCachedImage';
import useGroupImageUploading from '../../hooks/useGroupImageUploading';

import styles from './groupHeader.module.scss';

export default function GroupHeader({ groupInfo, showBottomNotification, patchGroupInfo }) {
  const [isBounceAnimation, setIsBounceAnimation] = useState(false);
  const { setGroupDataInStore } = useContext(GroupContext);
  const {
    changeImage,
    progress,
    deleteImage,
    isDeletePopupOpened,
    closeDeletePopup,
    onDeleteBtnClick,
    deleteHeaderText,
    deleteBodyText,
    editImage,
    isEditPopupOpened,
    closeEditPopup,
    onEditBtnClick,
    editHeaderText,
  } = useGroupImageUploading({
    showBottomNotification,
    id: groupInfo.groupId,
    patchGroupInfo,
    type: 'header',
    groupInfo,
    setGroupDataInStore,
  });
  const user = useSelector(state => state.userReducer);

  useEffect(() => {
    setIsBounceAnimation(true);
    setTimeout(() => {
      setIsBounceAnimation(false);
    }, 400);
  }, [groupInfo.headerUrl]);

  const image = {
    unit: '%',
    x: groupInfo.groupHeaderXCoordinate,
    y: groupInfo.groupHeaderYCoordinate,
    width: groupInfo.groupHeaderWidth,
    height: groupInfo.groupHeaderWidth / groupInfo.groupHeaderAspect,
    aspect: 5.9,
    url: groupInfo.headerUrl,
  };

  const { CachedImage } = useCachedImage({
    originalSrc: groupInfo.headerUrl,
    props: {
      alt: 'header',
      className: cn(styles.groupHeaderImage, 'animated', {
        bounceIn: isBounceAnimation,
      }),
    },
  });

  return (
    <div>
      <DeletePopup
        isOpened={isDeletePopupOpened}
        closePopup={closeDeletePopup}
        onDeleteBtnClick={onDeleteBtnClick}
        headerText={deleteHeaderText}
        bodyText={deleteBodyText}
      />
      <EditImagePopup
        isOpened={isEditPopupOpened}
        closePopup={closeEditPopup}
        onEditBtnClick={onEditBtnClick}
        headerText={editHeaderText}
        image={image}
        type='header'
      />
      <div className={styles.groupHeader}>
        {groupInfo.headerUrl && CachedImage}
        {progress ? (
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              <div className={styles.progressInfo} style={{ width: progress + '%' }} />
            </div>
          </div>
        ) : (
          <div className={styles.uploadAvatarContainer}>
            {user.lastName !== 'Caterer' && groupInfo.groupAdminStatus && (
              <div className={styles.uploadAvatar}>
                <UploadButton onChange={changeImage} allowedExtensions={['image/*']} />
                {groupInfo.headerUrl && (
                  <>
                    <EditButton onClick={editImage} className={styles.uploadAvatarButton} />
                    <DeleteButton onClick={deleteImage} className={styles.uploadAvatarButton} />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
