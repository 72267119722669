import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';

import styles from './addNewKitaPopup.module.scss';

export default function AddNewKitaPopup({
  isOpened,
  closePopup,
  inviteKitaCode,
  setInviteKitaCode,
  inviteKitaCodeError,
  setInviteKitaCodeError,
  isSubmitting,
  onSubmit,
  inviteKitaName,
}) {
  const { t } = useTranslation();

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='small'
      header={<Trans i18nKey='KITAsPanel.Add new KITA' />}
      body={
        <div className={cn(styles.container)}>
          {isSubmitting ? (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          ) : (
            <div className={styles.inputGroup}>
              <Label type='input'>{t('Registration.KITA code')}</Label>
              <Input
                value={inviteKitaCode}
                onChange={({ target }) => {
                  setInviteKitaCodeError('');
                  setInviteKitaCode(target.value);
                }}
                error={inviteKitaCodeError}
              />
              {inviteKitaCodeError && <div className={styles.error}>{inviteKitaCodeError}</div>}
              {inviteKitaName && (
                <div className={styles.kitaName}>
                  <strong>{inviteKitaName}</strong>
                </div>
              )}
            </div>
          )}
        </div>
      }
      footer={
        <div>
          <Button
            onClick={() => onSubmit(inviteKitaCode)}
            type='primary'
            className={styles.footerBtnLeft}
          >
            {t('Popup.Save')}
          </Button>
          <Button onClick={closePopup}>{t('Popup.Cancel')}</Button>
        </div>
      }
    />
  );
}
