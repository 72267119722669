import React from 'react';
import { uploadFilesToGroup } from 'library/api/files';
import { pdf, Page, Text, Image, Document, StyleSheet, View } from '@react-pdf/renderer';
import { formatUserBirthdayDateString } from 'library/utilities/dateMoment';

import {
  getDefaultContractSubmission,
  getSettings,
  storePDFToContractFolder,
} from '../api/formEditor';
import defaultSignatureImage from 'resources/images/formEditor/defaultSignatureImage.png';

import checkedPng from 'resources/images/checkboxPdf/checked.png';
import uncheckedPng from 'resources/images/checkboxPdf/unchecked.png';

export const generateContract = (t, child, activeKita, pageNumber = null, user = null, setProgress = null) => {
  getSettings().then(res => {
    if (res.data && res.data.defaultContractForm) {
      getDefaultContractSubmission(res.data.defaultContractForm.id, child.kidId || child.id).then(
        subRes => {
          generateAndDownloadContractPDF(
            t,
            res.data.defaultContractForm,
            child,
            activeKita,
            subRes.data,
            pageNumber,
            user,
            setProgress,
          );
        },
      );
    }
  });
};

function addHeaderOrParagraph(ff) {
  const textStyleObject = { marginBottom: '10pt' };

  if (ff.alignment === 'left') {
    textStyleObject.textAlign = 'left';
  } else if (ff.alignment === 'center') {
    textStyleObject.textAlign = 'center';
  } else if (ff.alignment === 'right') {
    textStyleObject.textAlign = 'right';
  }
  if (ff.indent && ff.indent !== null && ff.indent !== 0) {
    textStyleObject.marginLeft = `${ff.indent}pt`;
  }
  //if(Number.isInteger(ff.scale)){
  if (ff.fontSize && ff.fontSize != null && ff.fontSize !== 0) {
    textStyleObject.fontSize = `${ff.fontSize}pt`;
  }

  //}
  if (ff.bold && !ff.italic) {
    textStyleObject.fontFamily = 'Times-Bold';
  }
  if (ff.italic && !ff.bold) {
    textStyleObject.fontFamily = 'Times-Italic';
  }
  if (ff.italic && ff.bold) {
    textStyleObject.fontFamily = 'Times-BoldItalic';
  }
  if (ff.underlined) {
    textStyleObject.textDecoration = 'underline';
  }

  return (
    <>
      <Text style={textStyleObject}>{ff.text}</Text>
    </>
  );
}

function addInputOrDropdown(
  ff,
  submissionValue,
  child,
  activeKita,
  lastEditedAt,
  lastEditedBy,
  isDoubleInput,
  isDoubleDropdown,
) {
  const textStyleObjectLeft = { marginBottom: '10pt', fontSize: '12pt' };
  const textStyleObjectRight = { ...textStyleObjectLeft };
  if (ff.indent && ff.indent !== null && ff.indent !== 0) {
    textStyleObjectLeft.marginLeft = `${ff.indent}pt`;
  }

  const hasRightText = ff.labelRight != null && ff.labelRight.replace(' ', '') !== '';
  const hasLeftText = ff.labelLeft != null && ff.labelLeft.replace(' ', '') !== '';
  if (isDoubleInput || isDoubleDropdown) {
    textStyleObjectLeft.width = `40%`;
    textStyleObjectRight.width = `50%`;
  } else if (!hasRightText && hasLeftText) {
    //textStyleObjectLeft.width = "40%";
    //textStyleObjectRight.width = "60%";
    textStyleObjectLeft.width = '20%';
    textStyleObjectRight.width = '20%';
  }

  let gapValue = '';
  if (submissionValue) {
    gapValue = submissionValue;
  } else {
    gapValue = ff.presetValueType || '';

    gapValue = gapValue
      .replace('[[VornameKind]]', child.firstName || '')
      .replace('[[NachnameKind]]', child.lastName || '')
      .replace('[[GeburtstagKind]]', child.birthday || '')
      .replace('[[StadtKind]]', child.city || '')
      .replace('[[GeschlechtKind]]', updateGenderStringToGerman(child.gender) || '')
      .replace('[[BundeslandKind]]', child.state || '')
      .replace('[[StrasseKind]]', child.street || '')
      .replace('[[PostleitzahlKind]]', child.street || '')

      .replace('[[VornameElternteil1]]', child.parent1FirstName || '')
      .replace('[[NachnameElternteil1]]', child.parent1LastName || '')
      .replace(
        '[[GeburtstagElternteil1]]',
        formatUserBirthdayDateString(child.parent1Birthday) || '',
      )
      .replace('[[StadtElternteil1]]', child.parent1City || '')
      .replace('[[EmailElternteil1]]', child.parent1Email || '')
      .replace('[[GeschlechtElternteil1]]', updateGenderStringToGerman(child.parent1Gender) || '')
      .replace('[[MobilTelefonElternteil1]]', child.parent1Mobile || '')
      .replace('[[PrivatTelefonElternteil1]]', child.parent1PhonePrivate || '')
      .replace('[[ArbeitTelefonElternteil1]]', child.parent1PhoneWork || '')
      .replace('[[BundeslandElternteil1]]', child.parent1State || '')
      .replace('[[LandElternteil1]]', child.parent1Country || '')
      .replace('[[StrasseElternteil1]]', child.parent1Street || '')
      .replace('[[TitelElternteil1]]', child.parent1Title || '')
      .replace('[[PostleitzahlElternteil1]]', child.parent1Zip || '')
      .replace('[[MutterspracheElternteil1]]', child.parent1MotherLanguage || '')
      .replace('[[HauptspracheElternteil1]]', child.parent1MainLanguage || '')
      .replace('[[KonfessionElternteil1]]', child.parent1Denomination || '')
      .replace('[[ArbeitsplatzElternteil1]]', child.parent1WorkingPlace || '')
      .replace('[[FamilienstandElternteil1]]', child.parent1MaritalStatus || '')

      .replace('[[VornameElternteil2]]', child.parent2FirstName || '')
      .replace('[[NachnameElternteil2]]', child.parent2LastName || '')
      .replace(
        '[[GeburtstagElternteil2]]',
        formatUserBirthdayDateString(child.parent2Birthday) || '',
      )
      .replace('[[StadtElternteil2]]', child.parent2City || '')
      .replace('[[EmailElternteil2]]', child.parent2Email || '')
      .replace('[[GeschlechtElternteil2]]', updateGenderStringToGerman(child.parent2Gender) || '')
      .replace('[[MobilTelefonElternteil2]]', child.parent2Mobile || '')
      .replace('[[PrivatTelefonElternteil2]]', child.parent2PhonePrivate || '')
      .replace('[[ArbeitTelefonElternteil2]]', child.parent2PhoneWork || '')
      .replace('[[BundeslandElternteil2]]', child.parent2State || '')
      .replace('[[LandElternteil2]]', child.parent2Country || '')
      .replace('[[StrasseElternteil2]]', child.parent2Street || '')
      .replace('[[TitelElternteil2]]', child.parent2Title || '')
      .replace('[[PostleitzahlElternteil2]]', child.parent2Zip || '')
      .replace('[[MutterspracheElternteil2]]', child.parent2MotherLanguage || '')
      .replace('[[HauptspracheElternteil2]]', child.parent2MainLanguage || '')
      .replace('[[KonfessionElternteil2]]', child.parent2Denomination || '')
      .replace('[[ArbeitsplatzElternteil2]]', child.parent2WorkingPlace || '')
      .replace('[[FamilienstandElternteil2]]', child.parent2MaritalStatus || '')

      .replace('[[KitaName]]', activeKita.kitaName)
      .replace('[[KitaStrasse]]', activeKita.streetName)
      .replace('[[KitaHausNr]]', activeKita.houseNo)
      .replace('[[KitaPLZ]]', activeKita.zipCode)
      .replace('[[KitaStadt]]', activeKita.city);
  }

  return ff.inputType && (ff.inputType === 'signatureImage' || ff.inputType === 'image') ? (
    <>
    <View>
      <Text>
      {ff.labelLeft && ff.labelLeft !== null && ff.labelLeft !== '' && (
        <Text style={textStyleObjectLeft}>
          {ff.labelLeft && ff.labelLeft !== null && ff.labelLeft !== '' ? ff.labelLeft + ' ' : ''}
        </Text>
      )}
      </Text>
      {ff.inputType === 'signatureImage' ? (
        <Image
          src={gapValue && gapValue !== null && gapValue !== '' ? gapValue : defaultSignatureImage}
          style={{ maxHeight: '130pt', objectFit: 'scale-down' }}
        />
      ) : gapValue && gapValue !== null && gapValue !== '' ? (
        <Image
          src={gapValue && gapValue !== null && gapValue !== '' ? gapValue : defaultSignatureImage}
          style={{ maxHeight: '130pt', objectFit: 'scale-down' }}
        />
      ) : (
        <></>
      )}

      <Text>
      {ff.labelRight && ff.labelRight !== null && ff.labelRight !== '' && (
        <Text style={textStyleObjectRight}>
          {ff.labelRight && ff.labelRight !== null && ff.labelRight !== ''
            ? ' ' + ff.labelRight
            : ''}
        </Text>
      )}
      {/*<Text style={textStyleObject}>
        {`${lastEditedAt} ${lastEditedBy}`}
    </Text>*/}
    </Text>
    </View>
    </>
  ) : (
    <>
      {hasRightText ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: isDoubleInput || isDoubleDropdown ? '50%' : '100%',
          }}
        >
          <Text style={textStyleObjectLeft}>
            {ff.labelLeft && ff.labelLeft !== null && ff.labelLeft !== '' ? ff.labelLeft + ' ' : ''}
            {ff.inputType && ff.inputType === 'date'
              ? formatUserBirthdayDateString(gapValue)
              : gapValue}
            {ff.labelRight && ff.labelRight !== null && ff.labelRight !== ''
              ? ' ' + ff.labelRight
              : ''}
          </Text>
        </View>
      ) : (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: isDoubleInput || isDoubleDropdown ? '50%' : '100%',
          }}
        >
          <Text style={textStyleObjectLeft}>
            {ff.labelLeft && ff.labelLeft !== null && ff.labelLeft !== '' ? ff.labelLeft + ' ' : ''}
          </Text>
          <Text style={textStyleObjectRight}>
            {ff.inputType && ff.inputType === 'date'
              ? formatUserBirthdayDateString(gapValue)
              : gapValue}
            {ff.labelRight && ff.labelRight !== null && ff.labelRight !== ''
              ? ' ' + ff.labelRight
              : ''}
          </Text>
        </View>
      )}
    </>
  );
}

function addCheckBox(ff, submissionValue) {
  let checkBoxStyleObjectLeft = { marginBottom: '10pt', fontSize: '12pt' };
  const checkBoxStyleObjectRight = { ...checkBoxStyleObjectLeft };
  if (ff.indent && ff.indent !== null && ff.indent !== 0) {
    checkBoxStyleObjectLeft.marginLeft = `${ff.indent}pt`;
  }
  let checked = false;
  if (submissionValue === 'true') {
    checked = true;
  }

  const hasRightText = ff.labelRight != null && ff.labelRight.replace(' ', '') !== '';

  if (!hasRightText) {
    checkBoxStyleObjectLeft.width = '40%';
    checkBoxStyleObjectRight.width = '60%';
  }

  return (
    <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <Text style={checkBoxStyleObjectLeft}>{ff.labelLeft || ''} </Text>
      <Text style={checkBoxStyleObjectRight}>
        <Image
          src={checked ? checkedPng : uncheckedPng}
          style={{ height: '11pt', width: '11pt' }}
        />{' '}
        {ff.labelRight || ''}
      </Text>
    </View>
  );
}

function addPageBreak() {
  return <Text break />;
}

function addImage(ff) {
  if (ff.data !== null && ff.data !== '') {
    const imageStyleObject = { maxWidth: '575px', marginBottom: '10pt' };

    if (ff.alignment === 'left') {
      imageStyleObject.display = 'block';
      imageStyleObject.marginRight = 'auto';
    } else if (ff.alignment === 'center') {
      imageStyleObject.display = 'block';
      imageStyleObject.marginRight = 'auto';
      imageStyleObject.marginLeft = 'auto';
    } else if (ff.alignment === 'right') {
      imageStyleObject.display = 'block';
      imageStyleObject.marginLeft = 'auto';
    }
    if (ff.indent && ff.indent !== null && ff.indent !== 0) {
      imageStyleObject.marginLeft = `${ff.indent}pt`;
    }

    if (Number.isInteger(ff.scale)) {
      imageStyleObject.width = `${ff.scale}%`;
    }
    return (
      <>
        <Image src={ff.data} style={imageStyleObject} />
      </>
    );
  }
}

function addTableBookingTimes(ff, submitData, submissionValues) {
  const id = ff.id;

  const avg = tableBookingTimesCalculateAverage(ff, submitData);

  return (
    <>
      <View style={[styles.table, styles.smallMarginTop, styles.smallMarginBottom]}>
        <View style={[styles.row, styles.bold, styles.tableHeader]}>
          <Text style={styles.tabBTT1Col1} />
          <Text style={styles.tabBTT1Col2}>Mo</Text>
          <Text style={styles.tabBTT1Col3}>Di</Text>
          <Text style={styles.tabBTT1Col4}>Mi</Text>
          <Text style={styles.tabBTT1Col5}>Do</Text>
          <Text style={styles.tabBTT1Col6}>Fr</Text>
        </View>
        {Array.from({ length: ff.numberFromToRows }, (_, i) => {
          const j = i + 1;
          return (
            <>
              <View style={[styles.row]}>
                <Text style={styles.tabBTT1Col1}>von</Text>
                <Text style={styles.tabBTT1Col2}>
                  {`${id}-monFrom${j}` in submissionValues
                    ? submissionValues[`${id}-monFrom${j}`]
                    : ''}
                </Text>
                <Text style={styles.tabBTT1Col3}>
                  {`${id}-tueFrom${j}` in submissionValues
                    ? submissionValues[`${id}-tueFrom${j}`]
                    : ''}
                </Text>
                <Text style={styles.tabBTT1Col4}>
                  {`${id}-wedFrom${j}` in submissionValues
                    ? submissionValues[`${id}-wedFrom${j}`]
                    : ''}
                </Text>
                <Text style={styles.tabBTT1Col5}>
                  {`${id}-thuFrom${j}` in submissionValues
                    ? submissionValues[`${id}-thuFrom${j}`]
                    : ''}
                </Text>
                <Text style={styles.tabBTT1Col6}>
                  {`${id}-friFrom${j}` in submissionValues
                    ? submissionValues[`${id}-friFrom${j}`]
                    : ''}
                </Text>
              </View>
              <View style={[styles.row]}>
                <Text style={styles.tabBTT1Col1}>bis</Text>
                <Text style={styles.tabBTT1Col2}>
                  {`${id}-monTo${j}` in submissionValues ? submissionValues[`${id}-monTo${j}`] : ''}
                </Text>
                <Text style={styles.tabBTT1Col3}>
                  {`${id}-tueTo${j}` in submissionValues ? submissionValues[`${id}-tueTo${j}`] : ''}
                </Text>
                <Text style={styles.tabBTT1Col4}>
                  {`${id}-wedTo${j}` in submissionValues ? submissionValues[`${id}-wedTo${j}`] : ''}
                </Text>
                <Text style={styles.tabBTT1Col5}>
                  {`${id}-thuTo${j}` in submissionValues ? submissionValues[`${id}-thuTo${j}`] : ''}
                </Text>
                <Text style={styles.tabBTT1Col6}>
                  {`${id}-friTo${j}` in submissionValues ? submissionValues[`${id}-friTo${j}`] : ''}
                </Text>
              </View>
            </>
          );
        })}
        <View style={[styles.row]}>
          <Text style={styles.tabBTT1Col1}>= maximal gebuchte Zeit</Text>
          <Text style={styles.tabBTT1Col2}>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('mon', submitData, ff),
              ),
            ) || '-'}{' '}
            Stunden
          </Text>
          <Text style={styles.tabBTT1Col3}>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('tue', submitData, ff),
              ),
            ) || '-'}{' '}
            Stunden
          </Text>
          <Text style={styles.tabBTT1Col4}>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('wed', submitData, ff),
              ),
            ) || '-'}{' '}
            Stunden
          </Text>
          <Text style={styles.tabBTT1Col5}>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('thu', submitData, ff),
              ),
            ) || '-'}{' '}
            Stunden
          </Text>
          <Text style={styles.tabBTT1Col6}>
            {minutesToTimeString(
              tableBookingTimesCalculateHoursSumMultiple(
                tableBookingTimesGetStartEndPairs('fri', submitData, ff),
              ),
            ) || '-'}{' '}
            Stunden
          </Text>
        </View>
        <View style={[styles.row, styles.bold]}>
          <Text style={styles.fullWidthCol}>
            Wochenstunden gesamt:{' '}
            {minutesToTimeString(tableBookingTimeCalculateWeeklyHours(ff, submitData)) || '-'}{' '}
            Stunden
          </Text>
        </View>
      </View>
      <Text style={[styles.smallText, styles.smallMarginTop, styles.smallMarginBottom]}>
        Wochenstunden gesamt geteilt durch 5 Tage = Buchungskategorie:
      </Text>
      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.tableHeader]}>
          <Text style={styles.tabBTT2Col}>Tägl. durchschnittlich</Text>
          <Text style={styles.tabBTT2Col}>mehr als 3 bis 4 Std.</Text>
          <Text style={styles.tabBTT2Col}>mehr als 4 bis 5 Std.</Text>
          <Text style={styles.tabBTT2Col}>mehr als 5 bis 6 Std.</Text>
          <Text style={styles.tabBTT2Col}>mehr als 6 bis 7 Std.</Text>
          <Text style={styles.tabBTT2Col}>mehr als 7 bis 8 Std.</Text>
          <Text style={styles.tabBTT2Col}>mehr als 8 bis 9 Std.</Text>
          <Text style={styles.tabBTT2Col}>mehr als 9 bis 10 Std.</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabBTT2Col}>Bitte ankreuzen</Text>
          <Text style={styles.tabBTT2Col}>
            <Image
              src={180 < avg && avg <= 240 ? checkedPng : uncheckedPng}
              style={{ height: '10pt', width: '10pt' }}
            />
          </Text>
          <Text style={styles.tabBTT2Col}>
            <Image
              src={240 < avg && avg <= 300 ? checkedPng : uncheckedPng}
              style={{ height: '10pt', width: '10pt' }}
            />
          </Text>
          <Text style={styles.tabBTT2Col}>
            <Image
              src={300 < avg && avg <= 360 ? checkedPng : uncheckedPng}
              style={{ height: '10pt', width: '10pt' }}
            />
          </Text>
          <Text style={styles.tabBTT2Col}>
            <Image
              src={360 < avg && avg <= 420 ? checkedPng : uncheckedPng}
              style={{ height: '10pt', width: '10pt' }}
            />
          </Text>
          <Text style={styles.tabBTT2Col}>
            <Image
              src={420 < avg && avg <= 480 ? checkedPng : uncheckedPng}
              style={{ height: '10pt', width: '10pt' }}
            />
          </Text>
          <Text style={styles.tabBTT2Col}>
            <Image
              src={480 < avg && avg <= 540 ? checkedPng : uncheckedPng}
              style={{ height: '10pt', width: '10pt' }}
            />
          </Text>
          <Text style={styles.tabBTT2Col}>
            <Image
              src={540 < avg ? checkedPng : uncheckedPng}
              style={{ height: '10pt', width: '10pt' }}
            />
          </Text>
        </View>
      </View>
    </>
  );
}

function addTableParentalContribution(ff, form, submitData) {
  const relevantRow = tableParentalContributionCalculateRelevantRow(
    form,
    submitData,
    ff.timeSource,
  );

  const priceRow0 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 0);
  const priceRow1 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 1);
  const priceRow2 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 2);
  const priceRow3 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 3);
  const priceRow4 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 4);
  const priceRow5 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 5);
  const priceRow6 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 6);
  const priceRow7 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 7);
  const priceRow8 = getTableParentalContributionPriceByFacilityTypeAndRow(ff.facilityType, 8);

  return (
    <>
      <View style={[styles.table, styles.smallMarginTop]}>
        <View style={[styles.row, styles.bold, styles.tableHeader]}>
          <Text style={styles.tabPCCol1}>Durchschnittliche tägliche Buchungszeit</Text>
          <Text style={styles.tabPCColExtraWide}>Elternbeitrag pro Monat</Text>
          <Text style={styles.tabPCCol6}>zu zahlender Beitrag pro Monat</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1} />
          <Text style={styles.tabPCCol2}>
            Beiträge{' '}
            {ff.facilityType === 'krippe'
              ? 'Krippe'
              : ff.facilityType === 'hort'
              ? 'Hort'
              : 'Kindergarten'}
          </Text>
          <Text style={styles.tabPCCol4}>
            Ermäßigung für Geschwisterkinder{' '}
            <Image
              src={getSiblingsDiscountActive(submitData, ff.id) ? checkedPng : uncheckedPng}
              style={{ height: '12pt', width: '12pt' }}
            />
          </Text>
          <Text style={styles.tabPCCol5}>
            Elternbeitragszuschuss gemäß Art. 23 BayKiBiG **){' '}
            <Image
              src={getSubsidyValue(submitData, ff.id) > 0 ? checkedPng : uncheckedPng}
              style={{ height: '12pt', width: '12pt' }}
            />
          </Text>
          <Text style={styles.tabPCCol6} />
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 1 - 2 *)</Text>
          <Text style={styles.tabPCCol2}>{priceRow0 ? `${priceRow0} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 0
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 0 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 0
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow0,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 2 - 3 *)</Text>
          <Text style={styles.tabPCCol2}>{priceRow1 ? `${priceRow1} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 1
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 1 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 1
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow1,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 3 - 4 *)</Text>
          <Text style={styles.tabPCCol2}>{priceRow2 ? `${priceRow2} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 2
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 2 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 2
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow2,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 4 - 5</Text>
          <Text style={styles.tabPCCol2}>{priceRow3 ? `${priceRow3} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 3
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 3 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 3
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow3,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 5 - 6</Text>
          <Text style={styles.tabPCCol2}>{priceRow4 ? `${priceRow4} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 4
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 4 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 4
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow4,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 6 - 7</Text>
          <Text style={styles.tabPCCol2}>{priceRow5 ? `${priceRow5} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 5
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 5 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 5
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow5,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 7 - 8</Text>
          <Text style={styles.tabPCCol2}>{priceRow6 ? `${priceRow6} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 6
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 6 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 6
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow6,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 8 - 9</Text>
          <Text style={styles.tabPCCol2}>{priceRow7 ? `${priceRow7} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 7
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 7 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 7
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow7,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCCol1}>> 9 - 10</Text>
          <Text style={styles.tabPCCol2}>{priceRow8 ? `${priceRow8} €` : ''}</Text>
          <Text style={styles.tabPCCol4}>
            {relevantRow === 8
              ? `${getSiblingsDiscountActive(submitData, ff.id) ? getSiblingsDiscount() : 0} €`
              : ''}
          </Text>
          <Text style={styles.tabPCCol5}>
            {relevantRow === 8 ? `${getSubsidyValue(submitData, ff.id)} €` : ''}
          </Text>
          <Text style={styles.tabPCCol6}>
            {relevantRow === 8
              ? `${tableParentalContributionCalculateTotalPriceForRow(
                  priceRow8,
                  submitData,
                  ff.id,
                )} €`
              : ''}
          </Text>
        </View>
      </View>
      <Text style={[styles.smallText, styles.smallMarginTop]}>
        *) Diese Kategorien sind für Kinder ab 3 Jahren bis zur Einschulung nicht förderfähig.
      </Text>
      <Text style={[styles.smallText, styles.smallMarginBottom]}>
        **) Der in Art. 23 BayKiBiG geregelte Elternbeitragszuschuss wird an die Eltern
        weitergegeben. Der aufgeführte Elternbeitrag verringert sich dementsprechend: "Der Zuschuss
        beträgt 100 Euro pro Monat und wird für die Zeit vom 1. September des Kalenderjahres, in dem
        das Kind das dritte Lebensjahr vollendet, bis zum Schuleintritt gewährt." (Art. 23 Abs. 3
        Satz 2 BayKiBiG)
      </Text>
    </>
  );
}

function addTableParentalContributionWithHolidays(ff, form, submitData) {
  const relevantRowHolidays = tableParentalContributionCalculateRelevantRow(
    form,
    submitData,
    ff.timeSource,
  );
  const relevantRowSchoolTime = tableParentalContributionCalculateRelevantRow(
    form,
    submitData,
    ff.timeSourceHolidays,
  );

  const priceRow0 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    0,
  );
  const priceRow1 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    1,
  );
  const priceRow2 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    2,
  );
  const priceRow3 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    3,
  );
  const priceRow4 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    4,
  );
  const priceRow5 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    5,
  );
  const priceRow6 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    6,
  );
  const priceRow7 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    7,
  );
  const priceRow8 = getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(
    ff.facilityType,
    8,
  );

  const prices = [
    priceRow0,
    priceRow1,
    priceRow2,
    priceRow3,
    priceRow4,
    priceRow5,
    priceRow6,
    priceRow7,
    priceRow8,
  ];

  const priceRowHolidays0 = [1, 2, 3];
  const priceRowHolidays1 = [1, 2, 3];
  const priceRowHolidays2 = [1, 2, 3];
  const priceRowHolidays3 = [1, 2, 3];
  const priceRowHolidays4 = [1, 2, 3];
  const priceRowHolidays5 = [1, 2, 3];
  const priceRowHolidays6 = [1, 2, 3];
  const priceRowHolidays7 = [1, 2, 3];
  const priceRowHolidays8 = [1, 2, 3];

  const holidayPrices = [
    priceRowHolidays0,
    priceRowHolidays1,
    priceRowHolidays2,
    priceRowHolidays3,
    priceRowHolidays4,
    priceRowHolidays5,
    priceRowHolidays6,
    priceRowHolidays7,
    priceRowHolidays8,
  ];

  const monthsString = submitData ? submitData.formFields[`${ff.id}`] : '';

  const monthsInt = parseInt(monthsString, 10);
  const holidayMonths =
    !isNaN(monthsString) && !isNaN(monthsInt) && 0 <= monthsInt && monthsInt <= 12
      ? 12 - monthsInt
      : 0;

  return (
    <>
      <View style={[styles.table, styles.smallMarginTop]}>
        <View style={[styles.row, styles.bold, styles.tableHeader]}>
          <Text style={styles.tabPCHCol1} />
          <Text style={styles.tabPCHCol2}>Regelmäßige Buchungskategorie in der Schulzeit</Text>
          <Text style={styles.tabPCHCol3}>Elternbeitrag pro Monat</Text>
          <Text style={styles.tabPCHColExtraWide}>Buchungskategorie in der Schulferienzeit</Text>
        </View>
        <View style={[styles.row, styles.bold, styles.tableHeader]}>
          <Text style={styles.tabPCHCol1}>Buchungszeit Kategorien</Text>
          <Text style={styles.tabPCHCol2}>Buchung = {holidayMonths} Monate</Text>
          <Text style={styles.tabPCHCol3}>Beitrag in € monatlich</Text>
          <Text style={styles.tabPCHCol4}>ab 15 Tage***) = 1 Monat förderfähig</Text>
          <Text style={styles.tabPCHCol5}>ab 30 Tage***) = 2 Monate förderfähig</Text>
          <Text style={styles.tabPCHCol6}>ab 45 Tage***) = 3 Monate förderfähig</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 1 bis 2 Stunden **)</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 0 ? priceRow0 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 0 ? priceRow0 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 0 && holidayMonths === 1 ? priceRowHolidays0[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 0 && holidayMonths === 2 ? priceRowHolidays0[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 0 && holidayMonths >= 3 ? priceRowHolidays0[2] : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 2 bis 3 Stunden **)</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 1 ? priceRow1 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 1 ? priceRow1 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 1 && holidayMonths === 1 ? priceRowHolidays1[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 1 && holidayMonths === 2 ? priceRowHolidays1[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 1 && holidayMonths >= 3 ? priceRowHolidays1[2] : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 3 bis 4 Stunden **)</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 2 ? priceRow2 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 2 ? priceRow2 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 2 && holidayMonths === 1 ? priceRowHolidays2[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 2 && holidayMonths === 2 ? priceRowHolidays2[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 2 && holidayMonths >= 3 ? priceRowHolidays2[2] : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 4 bis 5 Stunden</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 3 ? priceRow3 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 3 ? priceRow3 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 3 && holidayMonths === 1 ? priceRowHolidays3[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 3 && holidayMonths === 2 ? priceRowHolidays3[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 3 && holidayMonths >= 3 ? priceRowHolidays3[2] : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 5 bis 6 Stunden</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 4 ? priceRow4 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 4 ? priceRow4 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 4 && holidayMonths === 1 ? priceRowHolidays4[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 4 && holidayMonths === 2 ? priceRowHolidays4[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 4 && holidayMonths >= 3 ? priceRowHolidays4[2] : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 6 bis 7 Stunden</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 5 ? priceRow5 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 5 ? priceRow5 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 5 && holidayMonths === 1 ? priceRowHolidays5[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 5 && holidayMonths === 2 ? priceRowHolidays5[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 5 && holidayMonths >= 3 ? priceRowHolidays5[2] : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 7 bis 8 Stunden</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 6 ? priceRow6 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 6 ? priceRow6 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 6 && holidayMonths === 1 ? priceRowHolidays6[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 6 && holidayMonths === 2 ? priceRowHolidays6[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 6 && holidayMonths >= 3 ? priceRowHolidays6[2] : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 8 bis 9 Stunden</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 7 ? priceRow7 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 7 ? priceRow7 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 7 && holidayMonths === 1 ? priceRowHolidays7[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 7 && holidayMonths === 2 ? priceRowHolidays7[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 7 && holidayMonths >= 3 ? priceRowHolidays7[2] : ''}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.tabPCHCol1}>> 9 bis 10 Stunden</Text>
          <Text style={styles.tabPCHCol2}>{relevantRowSchoolTime === 8 ? priceRow8 : ''}</Text>
          <Text style={styles.tabPCHCol3}>{relevantRowSchoolTime === 8 ? priceRow8 : ''}</Text>
          <Text style={styles.tabPCHCol4}>
            {relevantRowHolidays === 8 && holidayMonths === 1 ? priceRowHolidays8[0] : ''}
          </Text>
          <Text style={styles.tabPCHCol5}>
            {relevantRowHolidays === 8 && holidayMonths === 2 ? priceRowHolidays8[1] : ''}
          </Text>
          <Text style={styles.tabPCHCol6}>
            {relevantRowHolidays === 8 && holidayMonths >= 3 ? priceRowHolidays8[2] : ''}
          </Text>
        </View>
      </View>
      <Text>
        {!isNaN(monthsInt) &&
        0 <= monthsInt &&
        monthsInt <= 12 &&
        relevantRowSchoolTime &&
        relevantRowHolidays ? (
          <Text style={[styles.smallText, styles.largeMarginTop]}>
            Für {monthsInt} Monate {prices[relevantRowSchoolTime]} € + {holidayMonths} Monate{' '}
            {holidayMonths > 0
              ? holidayPrices[relevantRowHolidays][Math.min(holidayMonths - 1, 2)]
              : 0}{' '}
            € ={' '}
            {monthsInt * prices[relevantRowSchoolTime] +
              holidayMonths *
                (holidayMonths > 0
                  ? holidayPrices[relevantRowHolidays][Math.min(holidayMonths - 1, 2)]
                  : 0)}{' '}
            € / 12 Monate
          </Text>
        ) : (
          <Text style={[styles.smallText, styles.largeMarginTop]}>
            Für ___ Monate ___ € + ___ Monate ___ € = ___ € / 12 Monate
          </Text>
        )}
      </Text>
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Times-Roman',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    width: '100%',
    fontSize: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #CCC',
  },
  bold: {
    fontWeight: 'bold',
  },
  tableHeader: {
    borderTop: 'none',
  },
  tabBTT1Col1: {
    width: '10%',
    borderRight: '1px solid #CCC',
  },
  tabBTT1Col2: {
    width: '18%',
    borderRight: '1px solid #CCC',
  },
  tabBTT1Col3: {
    width: '18%',
    borderRight: '1px solid #CCC',
  },
  tabBTT1Col4: {
    width: '18%',
    borderRight: '1px solid #CCC',
  },
  tabBTT1Col5: {
    width: '18%',
    borderRight: '1px solid #CCC',
  },
  tabBTT1Col6: {
    width: '18%',
  },
  fullWidthCol: {
    width: '100%',
  },
  tabBTT2Col: {
    width: '12.5%',
  },
  tabPCCol1: {
    width: '17%',
    height: '100%',
    borderRight: '1px solid #CCC',
  },
  tabPCCol2: {
    width: '15%',
    borderRight: '1px solid #CCC',
  },
  tabPCCol3: {
    width: '17%',
    borderRight: '1px solid #CCC',
  },
  tabPCCol4: {
    width: '17%',
    borderRight: '1px solid #CCC',
  },
  tabPCCol5: {
    width: '17%',
    borderRight: '1px solid #CCC',
  },
  tabPCCol6: {
    width: '17%',
  },
  tabPCColExtraWide: {
    width: '49%',
    borderRight: '1px solid #CCC',
  },
  smallText: {
    fontSize: 10,
  },
  smallMarginTop: {
    marginTop: 15,
  },
  smallMarginBottom: {
    marginBottom: 15,
  },
  largeMarginTop: {
    marginTop: 50,
  },
  tabPCHCol1: {
    width: '17%',
    height: '100%',
    borderRight: '1px solid #CCC',
  },
  tabPCHCol2: {
    width: '19%',
    borderRight: '1px solid #CCC',
  },
  tabPCHCol3: {
    width: '13%',
    borderRight: '1px solid #CCC',
  },
  tabPCHCol4: {
    width: '17%',
    borderRight: '1px solid #CCC',
  },
  tabPCHCol5: {
    width: '17%',
    borderRight: '1px solid #CCC',
  },
  tabPCHCol6: {
    width: '17%',
  },
  tabPCHColExtraWide: {
    width: '51%',
  },
});

const MyDocument = ({ form, child, activeKita, submission }) => (
  <Document>
    <Page style={styles.body}>
      {form.formFields
        .sort((a, b) => {
          const pageA = a.page.pageNumber;
          const pageB = b.page.pageNumber;

          if (pageA !== pageB) {
            return pageA - pageB;
          }

          return a.position - b.position;
        })
        .map(ff => {
          let submissionValue = null;
          let lastEditedAt = null;
          let lastEditedBy = null;
          if (submission && ff.id in submission.formFields) {
            submissionValue = submission.formFields[ff.id];

            lastEditedAt =
              `lastEditedAt_${ff.id}` in submission.formFields
                ? submission.formFields[`lastEditedAt_${ff.id}`]
                : null;
            lastEditedBy =
              `lastEditedBy_${ff.id}` in submission.formFields
                ? submission.formFields[`lastEditedBy_${ff.id}`]
                : null;
          }

          if (ff.type === 'header' || ff.type === 'paragraph') {
            return addHeaderOrParagraph(ff);
          } else if (ff.type === 'input' || ff.type === 'dropdown') {
            return addInputOrDropdown(
              ff,
              submissionValue,
              child,
              activeKita,
              lastEditedAt,
              lastEditedBy,
            );
          } else if (ff.type === 'doubleInput') {
            const lastEditedAtInputLeft =
              `lastEditedAt_${ff.inputLeft.id}` in submission.formFields
                ? submission.formFields[`lastEditedAt_${ff.inputLeft.id}`]
                : null;
            const lastEditedByInputLeft =
              `lastEditedBy_${ff.inputLeft.id}` in submission.formFields
                ? submission.formFields[`lastEditedBy_${ff.inputLeft.id}`]
                : null;

            const lastEditedAtInputRight =
              `lastEditedAt_${ff.inputRight.id}` in submission.formFields
                ? submission.formFields[`lastEditedAt_${ff.inputRight.id}`]
                : null;
            const lastEditedByInputRight =
              `lastEditedBy_${ff.inputRight.id}` in submission.formFields
                ? submission.formFields[`lastEditedBy_${ff.inputRight.id}`]
                : null;

            return (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {addInputOrDropdown(
                  ff.inputLeft,
                  submission.formFields[ff.inputLeft.id],
                  child,
                  activeKita,
                  lastEditedAtInputLeft,
                  lastEditedByInputLeft,
                  true,
                  false,
                )}
                {addInputOrDropdown(
                  ff.inputRight,
                  submission.formFields[ff.inputRight.id],
                  child,
                  activeKita,
                  lastEditedAtInputRight,
                  lastEditedByInputRight,
                  true,
                  false,
                )}
              </View>
            );
          } else if (ff.type === 'doubleDropdown') {
            const lastEditedAtDropdownLeft =
              `lastEditedAt_${ff.dropdownLeft.id}` in submission.formFields
                ? submission.formFields[`lastEditedAt_${ff.dropdownLeft.id}`]
                : null;
            const lastEditedByDropdownLeft =
              `lastEditedBy_${ff.dropdownLeft.id}` in submission.formFields
                ? submission.formFields[`lastEditedBy_${ff.dropdownLeft.id}`]
                : null;

            const lastEditedAtDropdownRight =
              `lastEditedAt_${ff.dropdownRight.id}` in submission.formFields
                ? submission.formFields[`lastEditedAt_${ff.dropdownRight.id}`]
                : null;
            const lastEditedByDropdownRight =
              `lastEditedBy_${ff.dropdownRight.id}` in submission.formFields
                ? submission.formFields[`lastEditedBy_${ff.dropdownRight.id}`]
                : null;

            return (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {addInputOrDropdown(
                  ff.dropdownLeft,
                  submission.formFields[ff.dropdownLeft.id],
                  child,
                  activeKita,
                  lastEditedAtDropdownLeft,
                  lastEditedByDropdownLeft,
                  false,
                  true,
                )}
                {addInputOrDropdown(
                  ff.dropdownRight,
                  submission.formFields[ff.dropdownRight.id],
                  child,
                  activeKita,
                  lastEditedAtDropdownRight,
                  lastEditedByDropdownRight,
                  false,
                  true,
                )}
              </View>
            );
          } else if (ff.type === 'checkbox') {
            return addCheckBox(ff, submissionValue);
          } else if (ff.type === 'pagebreak') {
            return addPageBreak();
          } else if (ff.type === 'image') {
            return addImage(ff);
          } else if (ff.type === 'tableBookingTimes') {
            return addTableBookingTimes(ff, submission, submission.formFields);
          } else if (ff.type === 'tableParentalContribution') {
            return addTableParentalContribution(ff, form, submission);
          } else if (ff.type === 'tableParentalContributionWithHolidays') {
            return addTableParentalContributionWithHolidays(ff, form, submission);
          }
        })}
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

const generateAndDownloadContractPDF = (
  t,
  form,
  child,
  activeKita,
  submission,
  pageNumber,
  user,
  setProgress,
) => {

  let filteredFormFields = form.formFields.filter(
    formField =>
      formField.page.name !== 'Vollmacht' &&
      formField.page.name !==
        'Einwilligungserklärung zur Nutzung des sozialen Netzwerks Stramplerbande' &&
      formField.page.name !== 'Seite 22',
  );

  if(typeof pageNumber !== 'undefined' && pageNumber !== null){
    filteredFormFields = form.formFields.filter(
      formField =>
        formField.page.pageNumber === pageNumber
    );
  }

  let filteredForm = { ...form, formFields: filteredFormFields };

  const checkboxActionEffects = determineCheckboxConditionActionEffects(form, submission);

  checkboxActionEffects.forEach(e => {
    switch (e.action) {
      case 'ENABLE_PAGE':
        break;
      case 'DISABLE_PAGE':
        const pageId = e.args[0];

        filteredForm = {
          ...filteredForm,
          formFields: filteredForm.formFields.filter(ff => ff.page.id !== pageId),
        };

        break;
    }
  });

  const blob = pdf(
    <MyDocument
      form={filteredForm}
      child={child}
      activeKita={activeKita}
      submission={submission}
    />,
  ).toBlob();
  blob.then(data => {
    downloadFile(data, `Vertrag ${child.firstName} ${child.lastName}.pdf`);

    if(typeof pageNumber !== 'undefined' && pageNumber !== null){
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = function() {
      const base64data = reader.result;
      storePDFToContractFolder(base64data.replace('data:application/pdf;base64,', ''), child.kidId);
    };

    if (user !== null && setProgress !== null) {
      let formData = new FormData();
      formData.append(
        'files',
        new File([data], `Vertrag ${child.firstName} ${child.lastName}.pdf`),
      );
      formData.append('userId', user.id);
      formData.append('privatePost', true);
      formData.append('groupId', 0);
      uploadFilesToGroup(formData, setProgress);

      if (child.parent1Id && child.parent1Id !== user.id) {
        formData = new FormData();
        formData.append(
          'files',
          new File([data], `Vertrag ${child.firstName} ${child.lastName}.pdf`),
        );
        formData.append('userId', child.parent1Id);
        formData.append('privatePost', true);
        formData.append('groupId', 0);
        uploadFilesToGroup(formData, setProgress);
      }

      if (child.parent2Id && child.parent2Id !== user.id) {
        formData = new FormData();
        formData.append(
          'files',
          new File([data], `Vertrag ${child.firstName} ${child.lastName}.pdf`),
        );
        formData.append('userId', child.parent2Id);
        formData.append('privatePost', true);
        formData.append('groupId', 0);
        uploadFilesToGroup(formData, setProgress);
      }
    }
  }
  });
};

const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export function updateGenderStringToGerman(genderString) {
  if (genderString === 'Male') {
    return 'Männlich';
  }
  if (genderString === 'Female') {
    return 'Weiblich';
  }
  if (genderString === 'Custom') {
    return 'Divers';
  }
  return genderString;
}

export function getTableParentalContributionPriceByFacilityTypeAndRow(type, row) {
  const prices = [
    { krippe: null, kindergarten: null, hort: null },
    { krippe: null, kindergarten: null, hort: 185 },
    { krippe: 390, kindergarten: null, hort: 190 },
    { krippe: 400, kindergarten: 220, hort: 195 },
    { krippe: 410, kindergarten: 225, hort: 200 },
    { krippe: 420, kindergarten: 230, hort: 205 },
    { krippe: 430, kindergarten: 235, hort: 210 },
    { krippe: 450, kindergarten: 250, hort: 215 },
    { krippe: 460, kindergarten: 260, hort: 220 },
  ];

  return row < prices.length ? (type in prices[row] ? prices[row][type] : null) : null;
}

export function getTableParentalContributionWithHolidaysPriceByFacilityTypeAndRow(type, row) {
  const prices = [
    { krippe: null, kindergarten: null, hort: null },
    { krippe: null, kindergarten: null, hort: 185 },
    { krippe: 390, kindergarten: null, hort: 190 },
    { krippe: 400, kindergarten: 220, hort: 195 },
    { krippe: 410, kindergarten: 225, hort: 200 },
    { krippe: 420, kindergarten: 230, hort: 205 },
    { krippe: 430, kindergarten: 235, hort: 210 },
    { krippe: 450, kindergarten: 250, hort: 215 },
    { krippe: 460, kindergarten: 260, hort: 220 },
  ];

  return row < prices.length ? (type in prices[row] ? prices[row][type] : null) : null;
}

export function timeStringToMinutes(timeString) {
  const parts = timeString.split(':');

  return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
}

export function calculateMinutesFromTimes(from, to) {
  if (from === '' && to === '') {
    return 0;
  }

  const minutesFrom = timeStringToMinutes(from);
  const minutesTo = timeStringToMinutes(to);

  return minutesTo - minutesFrom;
}

export function checkTimeFormat(time) {
  return time !== '' && /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

export function minutesToTimeString(totalMinutes) {
  if (totalMinutes == null) {
    return null;
  }

  const hours = Math.trunc(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export function tableBookingTimesGetStartEndPairs(day, submitData, ff) {
  const result = [];

  if (!submitData) {
    return Array(ff.numberFromToRows).fill(['', '']);
  }

  for (let i = 1; i <= ff.numberFromToRows; i++) {
    result.push([
      submitData.formFields[`${ff.id}-${day}From${i}`],
      submitData.formFields[`${ff.id}-${day}To${i}`],
    ]);
  }

  return result;
}

export function tableBookingTimesCalculateHoursSumMultiple(startEndPairs) {
  const result = startEndPairs.map(p => tableBookingTimesCalculateHoursSum(p[0], p[1]));

  const validTimes = result.filter(t => t != null);

  if (validTimes.length === 0) {
    return null;
  }

  return validTimes.reduce((acc, a) => acc + a, 0);
}

export function tableBookingTimesCalculateHoursSum(start, end) {
  const startValid = checkTimeFormat(start);
  const endValid = checkTimeFormat(end);

  if (!startValid || !endValid) {
    return null;
  }

  return calculateMinutesFromTimes(start, end);
}

export function tableBookingTimeCalculateWeeklyHours(ff, submitData) {
  const mon = tableBookingTimesCalculateHoursSumMultiple(
    tableBookingTimesGetStartEndPairs('mon', submitData, ff),
  );
  const tue = tableBookingTimesCalculateHoursSumMultiple(
    tableBookingTimesGetStartEndPairs('tue', submitData, ff),
  );
  const wed = tableBookingTimesCalculateHoursSumMultiple(
    tableBookingTimesGetStartEndPairs('wed', submitData, ff),
  );
  const thu = tableBookingTimesCalculateHoursSumMultiple(
    tableBookingTimesGetStartEndPairs('thu', submitData, ff),
  );
  const fri = tableBookingTimesCalculateHoursSumMultiple(
    tableBookingTimesGetStartEndPairs('fri', submitData, ff),
  );

  return mon + tue + wed + thu + fri;
}

export function tableBookingTimesCalculateAverage(ff, submitData) {
  const weeklyMinutes = tableBookingTimeCalculateWeeklyHours(ff, submitData);

  return Math.round(weeklyMinutes / 5);
}

export function tableParentalContributionCalculateRelevantRow(
  form,
  submitData,
  bookingTimesTableName,
) {
  const bookingTimesTables = form.formFields.filter(field => field.name === bookingTimesTableName);

  let relevantRow = null;

  if (submitData && bookingTimesTables && bookingTimesTables.length > 0) {
    const avgInMins = tableBookingTimesCalculateAverage(bookingTimesTables[0], submitData);

    if (60 < avgInMins && avgInMins <= 120) {
      relevantRow = 0;
    } else if (120 < avgInMins && avgInMins <= 180) {
      relevantRow = 1;
    } else if (180 < avgInMins && avgInMins <= 240) {
      relevantRow = 2;
    } else if (240 < avgInMins && avgInMins <= 300) {
      relevantRow = 3;
    } else if (300 < avgInMins && avgInMins <= 360) {
      relevantRow = 4;
    } else if (360 < avgInMins && avgInMins <= 420) {
      relevantRow = 5;
    } else if (420 < avgInMins && avgInMins <= 480) {
      relevantRow = 6;
    } else if (480 < avgInMins && avgInMins <= 540) {
      relevantRow = 7;
    } else if (540 < avgInMins) {
      relevantRow = 8;
    }
  }

  return relevantRow;
}

export function determineCheckboxConditionActionEffects(form, submissionData) {
  if (!submissionData || !submissionData.formFields || !form) {
    return [];
  }

  const result = [];

  form.formFields.forEach(ff => {
    if (ff.type === 'checkbox') {
      const checkboxValue =
        `${ff.id}` in submissionData.formFields
          ? submissionData.formFields[`${ff.id}`] === 'true'
          : false;
      ff.conditionActions.forEach(a => {
        if (a.checkboxCondition === checkboxValue) {
          switch (a.action) {
            case 'ENABLE_PAGE':
              break;
            case 'DISABLE_PAGE':
              const pageId = parseInt(a.args, 10);

              result.push({
                action: a.action,
                args: [pageId],
              });
              break;
          }
        }
      });
    }
  });

  return result;
}

export function getSiblingsDiscount() {
  return 15;
}

export function getSiblingsDiscountActive(submitData, formFieldId) {
  if (
    !submitData ||
    !submitData.formFields ||
    !(`${formFieldId}-siblingsDiscount` in submitData.formFields)
  ) {
    return false;
  }

  return submitData.formFields[`${formFieldId}-siblingsDiscount`] === 'true';
}

export function tableParentalContributionCalculateTotalPriceForRow(price, submitData, formFieldId) {
  const subsidyInt = parseInt(getSubsidyValue(submitData, formFieldId), 10);

  return (
    price -
    (getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0) -
    (isNaN(subsidyInt) ? 0 : subsidyInt)
  );
}

export function getSubsidyValue(submitData, formFieldId) {
  return submitData && submitData.formFields && `${formFieldId}-subsidy` in submitData.formFields
    ? submitData.formFields[`${formFieldId}-subsidy`]
    : '0';
}

export const SUBSIDY_AMOUNT = 100;
