import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import last from 'lodash/last';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

import { updatePermanentStatus } from 'library/api/posts';
import { createPDF } from 'library/utilities/files';

import {
  getStreamFiles,
  getFilesInFolder,
  deleteFiles,
  getStreamFilesSize,
  downloadFile,
  updateFilesName,
  moveFileToFolder,
} from 'library/api/files';
import {
  getFolders,
  createFolder,
  deleteFolders,
  changePublicFolders,
  editFolder,
} from 'library/api/folder';
import { updatePrivacyOfMultipleFiles } from '../../../../api/files';

export default function useFiles(
  targetId,
  isProfilePage,
  groupInfo,
  updateFolders,
  breadcrumbs,
  folders,
  showBottomNotification,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isAllFilesSelected, toggleIsAllFilesSelected] = useState(false);
  const folderIds = selectedFiles.filter(file => file.type === 'folder').map(folder => folder.id);
  const fileIds = selectedFiles.filter(file => file.type === 'file').map(file => file.id);
  const { t } = useTranslation();

  useEffect(() => {
    const currentFolder = last(breadcrumbs) || {};
    const isStreamFolder = currentFolder.type === 'streamFiles';
    const isRoot = !breadcrumbs.length;

    if (isRoot) {
      getRootFiles({
        id: targetId,
        setIsLoading,
        t,
        isProfilePage,
        groupInfo,
        updateFolders,
      });
    } else if (isStreamFolder) {
      getChildStreamFiles(groupInfo.groupId);
    } else {
      getChildFiles(currentFolder.id, groupInfo.groupId, currentFolder.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const updateCurrentFolder = () => {
    const currentFolder = last(breadcrumbs) || {};

    if (!currentFolder.id) {
      getRootFilesHandler();
    } else if (currentFolder.type === 'streamFiles') {
      getChildStreamFiles(groupInfo.groupId);
    } else {
      getChildFiles(currentFolder.id, groupInfo.groupId);
    }
  };

  function defineZipFileName() {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    return today + '.zip';
  }

  const deleteSelected = () =>
    deleteSelectedHandler({
      updateCurrentFolder,
      setSelectedFiles,
      toggleIsAllFilesSelected,
      folderIds,
      fileIds,
      showBottomNotification,
      t,
    });

  const moveFilesToFolder = async folderId => {
    setIsLoading(true);
    const selectedFilesIds = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      selectedFilesIds.push(selectedFiles[i].id);
    }
    moveFileToFolder(selectedFilesIds.toString(), folderId).then(() => {
      updateCurrentFolder();
      setSelectedFiles([]);
      setIsLoading(false);
    });
  };
  const downloadSelectedAsZip = async () => {
    const zip = new JSZip();
    setIsLoading(true);
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].type === 'folder') {
        await getFilesAndFolders(selectedFiles[i].id, selectedFiles[i].name, zip);
      }
      if (selectedFiles[i].type === 'file') {
        const resp = await downloadFile(selectedFiles[i].id);
        zip.file(selectedFiles[i].fileId, resp.data);
      }
    }

    zip
      .generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
          level: 5,
        },
      })
      .then(function(content) {
        saveAs(
          content,
          selectedFiles.length === 1 && selectedFiles[0].type === 'folder'
            ? selectedFiles[0].name + '_' + defineZipFileName()
            : 'Downloads_' + defineZipFileName(),
        );
        setSelectedFiles([]);
        setIsLoading(false);
      });
  };

  const updateFileName = async function(newFileName) {
    await updateFilesName(selectedFiles[0].id, newFileName);
    updateCurrentFolder();
    setSelectedFiles([]);
  };

  const downloadSelectedAsPDF = async () => {
    for (let i = 0; i < selectedFiles.length; i++) {
      const { path, taxReceipt } = selectedFiles[i];
      setIsLoading(true);
      createPDF(path, taxReceipt).then(resp => {
        saveAs(
          resp,
          selectedFiles[i].fileId
            .split('.')
            .slice(0, -1)
            .join('.') + '.pdf',
        );
        setSelectedFiles([]);
        setIsLoading(false);
      });
    }
  };

  const downloadSelectedAsPDFZip = async () => {
    const zip = new JSZip();
    for (let i = 0; i < selectedFiles.length; i++) {
      const { path } = selectedFiles[i];
      setIsLoading(true);
      const resp = await createPDF(path);
      zip.file(
        selectedFiles[i].fileId
          .split('.')
          .slice(0, -1)
          .join('.') + '.pdf',
        resp,
      );
    }

    zip
      .generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
          level: 5,
        },
      })
      .then(function(content) {
        saveAs(content, 'Downloads_' + defineZipFileName());
        setSelectedFiles([]);
        setIsLoading(false);
      });
  };

  async function getFilesAndFolders(idOfParentFolder, path, zip) {
    const foldersData = await getFolders({
      graphql: 'id,name,description,publicFolder,size',
      groupId: groupInfo.groupId,
      parentFolderId: idOfParentFolder,
    });

    const files = await getFilesInFolder(groupInfo.groupId, isProfilePage, idOfParentFolder);
    for (let i = 0; i < files.length; i++) {
      const resp = await downloadFile(files[i].id);
      zip.file(path + '/' + files[i].fileId, resp.data);
    }

    const pathTemp = path;
    for (let i = 0; i < foldersData.length; i++) {
      path = pathTemp + '/' + foldersData[i].name;
      zip.folder(path);
      await getFilesAndFolders(foldersData[i].id, path, zip);
    }
  }

  const downloadSelected = async () => {
    for (let i = 0; i < selectedFiles.length; i++) {
      downloadFile(selectedFiles[i].id).then(resp => {
        saveAs(resp.data, selectedFiles[i].fileId);
      });
    }
  };

  const makePublicSelected = () =>
    makePublicSelectedHandler({
      selectedFiles,
      updateCurrentFolder,
      setSelectedFiles,
      toggleIsAllFilesSelected,
      folderIds,
    });

  const makePrivateSelected = () =>
    makePrivateSelectedHandler({
      selectedFiles,
      updateCurrentFolder,
      setSelectedFiles,
      toggleIsAllFilesSelected,
      folderIds,
    });

  const updateFile = file => updateFileHandler({ file, folders, updateFolders, breadcrumbs });

  const getChildFiles = (folderId, groupId) =>
    getChildFilesHandler({ folderId, setIsLoading, groupId, updateFolders, isProfilePage, t });

  const getChildStreamFiles = groupId =>
    getChildStreamFilesHandler({ setIsLoading, groupId, updateFolders, isProfilePage });

  const createNewFolder = data => createFolderHandler(data);

  const updateFolder = data => updateFolderHandler(data);

  const getRootFilesHandler = () =>
    getRootFiles({ id: targetId, setIsLoading, t, isProfilePage, groupInfo, updateFolders });

  const toggleAllFilesCheckbox = () => {
    const allFiles = folders.filter(file => !file.isFilesFromStream);
    const allSelectedFiles = allFiles.map(file => {
      if (file.type === 'folder') {
        return {
          id: file.id,
          fileId: file.id,
          type: 'folder',
          name: file.name,
          description: file.description,
          permanent: file.posts && file.posts.permanent,
        };
      } else {
        return {
          id: file.id,
          fileId: file.fileId,
          mimeType: file.mimeType,
          type: 'file',
          permanent: file.posts && file.posts.permanent,
          taxReceipt: file.taxReceipt,
          signatureGroupId: file.signatureGroupId,
          path: file.path,
          name: file.name,
          postId: file.posts && file.posts.id,
        };
      }
    });

    if (isAllFilesSelected) {
      toggleIsAllFilesSelected(false);
      setSelectedFiles([]);
    } else {
      toggleIsAllFilesSelected(true);
      setSelectedFiles(allSelectedFiles);
    }
  };

  const selectFileHandler = id => {
    const allFiles = folders.filter(file => !file.isFilesFromStream);
    const isSelectedFile = selectedFiles.find(item => item.id === id);
    const file = folders.find(item => item.id === id);
    const type = file.type === 'folder' ? 'folder' : 'file';
    const permanent = file.posts && file.posts.permanent;
    const postId = file.posts && file.posts.id;
    const { fileId, path, name, taxReceipt, mimeType, signatureGroupId, description } = file;

    const newFiles = isSelectedFile
      ? selectedFiles.filter(item => item.id !== id)
      : [
          ...selectedFiles,
          {
            id,
            type,
            permanent,
            postId,
            fileId,
            path,
            name,
            description,
            taxReceipt,
            mimeType,
            signatureGroupId,
          },
        ];

    setSelectedFiles(newFiles);

    if (newFiles.length === allFiles.length) {
      toggleIsAllFilesSelected(true);
    }
    if (newFiles.length !== allFiles.length && isAllFilesSelected) {
      toggleIsAllFilesSelected(false);
    }
  };

  const getPermanent = () => !selectedFiles.find(file => !file.permanent);

  const changePermanent = () => {
    let success = true;
    const perm = getPermanent();
    Promise.all(
      selectedFiles
        .filter(file => file.permanent === perm)
        .map(file => updatePermanentStatus(file.postId)),
    )
      .then(() => {
        updateCurrentFolder();
        setSelectedFiles([]);
        toggleIsAllFilesSelected(false);
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        showBottomNotification(
          t(`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`),
          { isFail: !success },
        );
      });
  };

  return {
    isLoading,
    deleteSelected,
    updateFileName,
    moveFilesToFolder,
    downloadSelected,
    downloadSelectedAsZip,
    downloadSelectedAsPDF,
    downloadSelectedAsPDFZip,
    makePublicSelected,
    makePrivateSelected,
    updateFile,
    getChildFiles,
    getChildStreamFiles,
    createNewFolder,
    getRootFilesHandler,
    selectFileHandler,
    selectedFiles,
    isAllFilesSelected,
    toggleAllFilesCheckbox,
    updateCurrentFolder,
    setSelectedFiles,
    updateFolder,
    getPermanent,
    changePermanent,
  };
}

export async function getRootFiles({
  id,
  setIsLoading,
  t,
  isProfilePage,
  groupInfo,
  updateFolders,
}) {
  setIsLoading(true);
  try {
    const files = await getFilesInFolder(id, isProfilePage, null);
    const data = await getFolders({
      graphql: 'id,name,description,publicFolder,size',
      groupId: groupInfo.groupId,
    });
    const streamSize = await getStreamFilesSize(id);
    const folders = data.map(folder => ({ ...folder, type: 'folder' }));
    const defaultFolder = {
      type: 'folder',
      isFilesFromStream: true,
      id: 0,
      description: t('FileManager.Table.You can find all files'),
      name: t('FileManager.Table.Files from the stream'),
      publicFolder: true,
      size: streamSize,
    };

    updateFolders(files.concat([defaultFolder, ...folders]));
  } catch (err) {
    updateFolders([]);
  } finally {
    setIsLoading(false);
  }
}

export const getChildFilesHandler = async ({
  folderId,
  setIsLoading,
  groupId,
  updateFolders,
  isProfilePage,
  t,
}) => {
  setIsLoading(true);

  try {
    const files = await getFilesInFolder(groupId, isProfilePage, folderId);
    const foldersData = await getFolders({
      graphql: 'id,name,description,publicFolder,size',
      groupId,
      parentFolderId: folderId,
    });
    const folders = foldersData.map(folder => ({ ...folder, type: 'folder' }));

    if (!folderId) {
      const defaultFolder = {
        type: 'folder',
        isFilesFromStream: true,
        id: 0,
        description: t('FileManager.Table.You can find all files'),
        name: t('FileManager.Table.Files from the stream'),
        publicFolder: true,
      };

      updateFolders(files.concat([defaultFolder, ...folders]));
      return;
    }

    updateFolders(files.concat(folders));
  } catch (error) {
    updateFolders([]);
  } finally {
    setIsLoading(false);
  }
};

export const getChildStreamFilesHandler = async ({
  setIsLoading,
  groupId,
  updateFolders,
  isProfilePage,
}) => {
  setIsLoading(true);

  try {
    const files = await getStreamFiles(groupId, isProfilePage, null);

    updateFolders(files);
  } catch (error) {
    updateFolders([]);
  } finally {
    setIsLoading(false);
  }
};

export async function createFolderHandler(data) {
  try {
    await createFolder(data);
  } catch (error) {
    console.error(error);
  }
}

export async function updateFolderHandler(data) {
  try {
    await editFolder(data);
  } catch (error) {
    console.error(error);
  }
}

export async function deleteSelectedHandler({
  updateCurrentFolder,
  setSelectedFiles,
  toggleIsAllFilesSelected,
  folderIds,
  fileIds,
  showBottomNotification,
  t,
}) {
  try {
    if (fileIds.length) await deleteFiles(fileIds);
    if (folderIds.length) await deleteFolders(folderIds);

    setSelectedFiles([]);
    toggleIsAllFilesSelected(false);
    updateCurrentFolder();
  } catch (err) {
    setSelectedFiles([]);
    toggleIsAllFilesSelected(false);
    showBottomNotification(t("FileManager.You don't have permission to perform this action"), {
      isFail: true,
    });
  }
}

export async function makePublicSelectedHandler({
  selectedFiles,
  updateCurrentFolder,
  setSelectedFiles,
  toggleIsAllFilesSelected,
  folderIds,
}) {
  const files = selectedFiles.filter(f => !folderIds.includes(f.id));

  if (files.length) {
    await updatePrivacyOfMultipleFiles({
      posts: files.map(f => f.id),
      privatePost: 0,
    });
  }
  if (folderIds.length) {
    await changePublicFolders(folderIds, true);
  }

  updateCurrentFolder();
  setSelectedFiles([]);
  toggleIsAllFilesSelected(false);
}

export async function makePrivateSelectedHandler({
  selectedFiles,
  updateCurrentFolder,
  setSelectedFiles,
  toggleIsAllFilesSelected,
  folderIds,
}) {
  const files = selectedFiles.filter(f => !folderIds.includes(f.id));

  if (files.length) {
    await updatePrivacyOfMultipleFiles({
      posts: files.map(f => f.id),
      privatePost: 1,
    });
  }
  if (folderIds.length) {
    await changePublicFolders(folderIds, false);
  }

  updateCurrentFolder();
  setSelectedFiles([]);
  toggleIsAllFilesSelected(false);
}

export function updateFileHandler({ file, folders, updateFolders, breadcrumbs }) {
  const newFiles = [...folders];
  const index = newFiles.findIndex(item => item.id === file.id);
  const updatedFile = breadcrumbs.length
    ? { ...newFiles[index], posts: { ...newFiles[index].posts, comments: file.comments } }
    : { ...newFiles[index], comments: file.comments };

  if (index !== -1) {
    newFiles.splice(index, 1, updatedFile);
  }
  updateFolders(newFiles);
}
