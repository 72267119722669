import React, {useState, useEffect} from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import SortIndicator from 'library/common/commonComponents/SortIndicator';
import Checkbox from 'library/common/commonComponents/Checkbox';
import UsersFrameListRow from '../UsersFrameListRow';

import styles from '../../usersFrameList.module.scss';
import { getConfig } from 'library/api/kita';

export default function UsersFrameListTable({
  users,
  setSorting,
  sort,
  setEditingProfile,
  setDeletionProfile,
  togglePermission,
  falsePermissions,
  toggleAllPermissions,
  selectedKita,
  loggedInUser,
  handleUserSelection,
  selectedUsers,
  allUsersAreSelected,
  handleToggleAllUsersSelection,
  showOnlyParents,
  toggleParentDisplay,
}) {
  const { t } = useTranslation();
  const filteredUsers = (showOnlyParents) ? users.filter(user => user.parent !== null) : users;
  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {
    getConfig().then((res) => {
      const kitaConfig = res.data;
      setShowLogin(kitaConfig.lastLoginVisible);
    })
  }, [])


  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <tbody>
            <tr>
              {loggedInUser.administrationAccess && (<td>
                <div className={styles.headerTextContainer}>
                  <Checkbox
                    checkboxTextClass={styles.titleCheckboxTitle}
                    isChecked={allUsersAreSelected}
                    onChange={handleToggleAllUsersSelection}
                    className={`${styles.checkbox} ${styles.titleCheckbox}`}
                  />
                </div>
              </td>)}
              <td
                className={styles.headerText}
                onClick={() => setSorting('user.lastName')}
              >
                <div className={styles.headerTextContainer}>
                  {t('Administration.UserSection.Name')}
                  <SortIndicator sort={sort} name='user.lastName' />
                </div>
              </td>
              <td className={styles.headerText}>
                <div className={styles.headerTextContainer}>
                  {t('Administration.UserSection.Groups')}
                </div>
              </td>
              {showLogin && <td className={styles.headerText} onClick={() => setSorting('lastAccess')}>
                <div className={styles.headerTextContainer}>
                  {t('Administration.UserSection.Last login')}
                  <SortIndicator sort={sort} name='lastAccess' />
                </div>
              </td>}
              <td />
              {/* ***********
              <td className={styles.headerText}>
                <div className={cn(styles.headerTextContainer, styles.headerTextContainerCheckbox)}>
                  {t('Parents')}
                  <Checkbox
                    checkboxTextClass={styles.titleCheckboxTitle}
                    isChecked={showOnlyParents}
                    onChange={toggleParentDisplay}
                    className={`${styles.checkbox} ${styles.titleCheckbox}`}
                  />
                </div>
              </td>
              *********** */}
              <td className={styles.headerText}>
                <div className={cn(styles.headerTextContainer, styles.headerTextContainerCheckbox)}>
                  {t('Administration.UserSection.Pictures')}
                  <Checkbox
                    checkboxTextClass={styles.titleCheckboxTitle}
                    isChecked={falsePermissions.pictures.length === 0}
                    onChange={() => toggleAllPermissions('pictures')}
                    className={`${styles.checkbox} ${styles.titleCheckbox}`}
                  />
                </div>
              </td>
              <td className={styles.headerText}>
                <div className={cn(styles.headerTextContainer, styles.headerTextContainerCheckbox)}>
                  {t('Administration.UserSection.Videos')}
                  <Checkbox
                    checkboxTextClass={styles.titleCheckboxTitle}
                    isChecked={falsePermissions.videos.length === 0}
                    onChange={() => toggleAllPermissions('videos')}
                    className={`${styles.checkbox} ${styles.titleCheckbox}`}
                  />
                </div>
              </td>
              <td className={styles.headerText}>
                <div className={cn(styles.headerTextContainer, styles.headerTextContainerCheckbox)}>
                  {t('Administration.UserSection.Others')}
                  <Checkbox
                    checkboxTextClass={styles.titleCheckboxTitle}
                    isChecked={falsePermissions.others.length === 0}
                    onChange={() => toggleAllPermissions('others')}
                    className={`${styles.checkbox} ${styles.titleCheckbox}`}
                  />
                </div>
              </td>
            </tr>

            {filteredUsers.map(user => (
              <UsersFrameListRow
                loggedInUser={loggedInUser}
                setEditingProfile={setEditingProfile}
                key={user.id}
                user={user}
                setDeletionProfile={setDeletionProfile}
                showLogin={showLogin}
                togglePermission={togglePermission}
                selectedKita={selectedKita}
                handleUserSelection={()=>handleUserSelection(user.user.id)}
                userIsSelected={selectedUsers.includes(user.user.id)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
