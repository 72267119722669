import React, { useState, useContext } from 'react';

import ParentSurveysCreateInput from 'library/common/commonComponents/ParentSurveysCreateInput';
import Feed from 'library/common/commonComponents/Feed/FeedContainer';
import { GroupContext } from 'modules/Group/groupModule';

import styles from './parentSurveys.module.scss';

export default function ParentSurveys({ groupInfo }) {
  const { groupId, postCount } = groupInfo;
  const groupContext = useContext(GroupContext);
  const patchGroupInfo = groupContext.patchGroup;

  const [newPost, setNewPost] = useState(null);
  const [reusableParentSurvey, setReusableParentSurvey] = useState(null);
  const [newReusing, setNewReusing] = useState(false);

  const handleSetReusableParentSurvey = (item) => {
    setReusableParentSurvey(item);
    setNewReusing(true);
  }
  function onCreate(post) {
    setNewPost({
      ...post.data,
      group: groupInfo,
    });
    patchGroupInfo({ postCount: postCount + 1 });
  }
  return (
    <div className={styles.container}>
      {groupInfo.createSurvey && groupInfo.groupAdminStatus && (
        <ParentSurveysCreateInput
          groupInfo={groupInfo}
          parentSurvey={reusableParentSurvey}
          newReusing={newReusing}
          setNewReusing={setNewReusing}
          onCreate={onCreate}
        />
      )}

      <Feed
        groupId={groupId}
        onPostDelete={() => patchGroupInfo({ postCount: postCount - 1 })}
        createCommentStatus={groupInfo.createCommentStatus}
        isPrivatePostsOnly={false}
        isPublicPostsOnly={false}
        newPost={newPost}
        group={groupInfo}
        postType={9}
        reusableParentSurvey={reusableParentSurvey}
        setReusableParentSurvey={(item) => handleSetReusableParentSurvey(item)}
      />
    </div>
  );
}
