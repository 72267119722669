import Button from 'library/common/commonComponents/Buttons/Button';
import React, { useState, useEffect } from 'react';
import { getAdminLogsForCurrentKita } from "library/api/kita";

import {
  dateObjectToString,
} from 'library/utilities/dateMoment';


export default function LogsFrame() {

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getAdminLogsForCurrentKita().then(data => {
      //console.log(data.data);
      setTableData(data.data);
    });
  }, [])

  return (
    <table style={{ display: "block", overflowX: "auto", whiteSpace: "nowrap" }}>
      <tr><th>Aktion</th><th >Zeitpunkt</th><th>Gruppenname</th><th>Email</th><th>Vorname</th><th>Nachname</th></tr>
      {tableData.map(data => {
        return <tr><td>Gruppenaustritt</td><td>{dateObjectToString(new Date(data.createdAt))}</td><td>{data.groupName}</td><td>{data.userEmail}</td><td>{data.userFirstName}</td><td>{data.userLastName}</td></tr>
      })}
    </table>
  )

}