import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';
import Button from '../Button';
import Popup from '../../Popups/Popup';
import { getAllPostTemplates } from '../../../../api/posts';
import { showBottomNotification } from '../../../commonActions/notificationsActions';
import TemplateLine from './TemplateLine';
import { getCalendarEventById } from '../../../../api/calendar';

import styles from './postLoadTemplateButton.module.scss';

export default function PostLoadTemplateButton({
  updateText,
  loadCalendarEventFromTemplate,
  type,
}) {
  const { t } = useTranslation();
  const [popupOpened, setPopupOpened] = useState(false);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    getAllPostTemplates()
      .then(res => {
        const temps =
          type === 'calendar'
            ? res.data.filter(template => template.post.moduleType === 'calendar')
            : res.data.filter(template => template.post.moduleType !== 'calendar');
        setTemplates(temps);
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('PostLoadTemplatePopup.LoadError'), { isFail: true }),
        );
      });
  }, []);

  const handleApplyCalendarTemplate = (template, calendarEvent) => {
    loadCalendarEventFromTemplate(template, calendarEvent);
    setPopupOpened(false);
  };

  const handleApplyTemplate = template => {
    updateText(template.post.text);
    setPopupOpened(false);
  };

  return (
    <>
      {templates != null && templates.length > 0 ? (
        <>
          <Popup
            isOpened={popupOpened}
            closePopup={() => setPopupOpened(false)}
            header={t('PostLoadTemplatePopup.Header')}
            body={
              <div>
                {templates.map(template => (
                  <TemplateLine
                    key={template.id}
                    template={template}
                    handleApplyTemplate={() => handleApplyTemplate(template)}
                    handleApplyCalendarTemplate={handleApplyCalendarTemplate}
                    type={type}
                  />
                ))}
              </div>
            }
            footer={
              <div>
                <Button onClick={() => setPopupOpened(false)}>{t('Popup.Cancel')}</Button>
              </div>
            }
          />

          <Button
            onClick={() => {
              setPopupOpened(true);
            }}
            className={styles.templateButton}
          >
            <i className='fa fa-object-group' aria-hidden='true' aria-label='Template laden' style={{ fontSize: "15px", verticalAlign: "middle" }} />
          </Button>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
