import React, { useState, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import FormsOverview from "./FormsFrameFrames/FormsOverview";
import FormsEditor from "./FormsFrameFrames/FormsEditor";
import {getForms, getTemplates} from "../../../../library/api/formEditor";

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {connect} from "react-redux";

function FormsFrame({ activeKita }) {
  const { t } = useTranslation();

  const [forms, setForms] = useState([]);

  const emptyForm = {
    id: -1,
    name: "",
    creator: -1,
    formFields: [],
    newPages: [],
  };

  const [editForm, setEditForm] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [templates, setTemplates] = useState([]);

  const reloadForms = () => {
    setIsLoading(true);
    getForms().then(res => {
      setForms(res.data);
    }).catch(err => {
      store.dispatch(
        showBottomNotification(
          t('FormsEditor.LoadFormsError'),
          { isFail: true },
        ),
      );
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const reloadTemplates = () => {
    setIsLoading(true);
    getTemplates().then(res => {
      setTemplates(res.data);
    }).catch(() => {
      store.dispatch(
        showBottomNotification(
          t('FormsEditor.LoadTemplatesError'),
          { isFail: true },
        ),
      );
    }).finally(() => {
      setIsLoading(false);
    })
  };

  const createNewForm = () => {
    setEditForm(emptyForm);
  };

  const startEditForm = (f) => {
    setEditForm(f);
  };

  useEffect(() => {
    reloadForms();
    reloadTemplates();
  }, []);

  return (
    <div>
      {editForm ? (
        <FormsEditor
          editForm={editForm}
          cancelEdit={() => setEditForm(null)}
          reloadForms={reloadForms}
          templates={templates}
          reloadTemplates={reloadTemplates}
          activeKita={activeKita}
        />
      ) : (
        <FormsOverview
          createNewForm={createNewForm}
          forms={forms}
          isLoading={isLoading}
          startEditForm={startEditForm}
          reloadForms={reloadForms}
        />
      )}
    </div>
  );
}

export default connect(state => ({
  activeKita: state.kitaReducer.activeKita,
}))(FormsFrame);
