import {
  SET_CHAT_ID,
  SET_CHAT_SOCKET_SUBSCRIBED,
  OPEN_NEW_CHAT_WITH_RECIPIENT_SIGNAL,
  RESET_NEW_CHAT_WITH_RECIPIENT_SIGNAL_RECIPIENT,
  SET_UNREAD_MESSAGES_COUNTS_KITAS,
} from '../commonConstants/chatConstants';

const initialState = {
  chatId: 0,
  chatSocketSubscribed: false,
  openNewChatWithRecipient: {
    recipient: null,
    signal: false,
  },
  unreadMessagesCountsKitas: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_SOCKET_SUBSCRIBED:
      return {
        ...state,
        chatSocketSubscribed: true,
      };
    case SET_CHAT_ID:
      return {
        ...state,
        chatId: action.payload,
      };
    case SET_UNREAD_MESSAGES_COUNTS_KITAS:
      return {
        ...state,
        unreadMessagesCountsKitas: action.payload,
      };
    case OPEN_NEW_CHAT_WITH_RECIPIENT_SIGNAL:
      return {
        ...state,
        openNewChatWithRecipient: {
          recipient: action.payload,
          signal: !state.openNewChatWithRecipient.signal,
        },
      };
    case RESET_NEW_CHAT_WITH_RECIPIENT_SIGNAL_RECIPIENT:
      return {
        ...state,
        openNewChatWithRecipient: {
          recipient: null,
          signal: state.openNewChatWithRecipient.signal,
        },
      };
    default:
      return state;
  }
};
