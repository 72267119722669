import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllEmployeeWorkingTimes, postEmployeeWorkingTimes } from 'library/api/employee';
import EmployeeItem from './EmployeeItem';
import HelpText from 'library/common/commonComponents/HelpText';
import Loader from 'library/common/commonComponents/Loader';
import styles from './userFrameEmployeeTimeRecording.module.scss';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

export default function UsersFrameEmployeeTimeRecording({ kitaId, modules }) {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keepExpanded, setKeepExpanded] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    getAllEmployeeWorkingTimes()
      .then(res => {
        setEmployees(res.data);
      })
      .catch(err => {
        console.error(err);
        store.dispatch(
          showBottomNotification(t(err.response.data.message), {
            isFail: true,
          }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSaveWorkingHours = async newWorkingHours => {
    setIsLoading(true);
    try {
      await postEmployeeWorkingTimes(newWorkingHours);
      await fetchEmployeeWorkingTimes();
    } catch (err) {
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEmployeeWorkingTimes = async userId => {
    setIsLoading(true);
    setKeepExpanded(userId);
    try {
      const { data } = await getAllEmployeeWorkingTimes();
      setEmployees(data);
    } catch (err) {
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Administration.UserSection.TimeRecording')}</div>
      <div className={styles.text}>
        <HelpText>
          {t(
            'Administration.UserSection.The following list shows the working hours of all employees',
          )}
        </HelpText>
      </div>
      <br />
      {!isLoading && employees ? (
        <div className={styles.employeeItemsContainer}>
          {employees.map(employee => {
            return (
              <EmployeeItem
                key={employee.user?.id}
                employee={employee}
                setIsLoading={setIsLoading}
                handleSaveWorkingHours={handleSaveWorkingHours}
                fetchEmployeeWorkingTimes={fetchEmployeeWorkingTimes}
                className={styles.withBackground}
                expanded={keepExpanded === employee.user?.id}
              />
            );
          })}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
