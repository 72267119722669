import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { GroupContext } from 'modules/Group/groupModule';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Loader from 'library/common/commonComponents/Loader';
import Wrapper from 'library/common/commonComponents/Wrapper';
import ProfilePanel from 'library/common/commonComponents/Profile/ProfilePanel';
import ProfileBody from 'library/common/commonComponents/Profile/ProfileBody/ProfileBodyContainer';
import useProfileLoading from 'library/common/commonHooks/useProfileLoading';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import { getKitaInfoByUserId } from 'library/api/user';
import { setUserKita } from 'library/api/kita';
import { isAwoWW } from 'library/api/tenantConfig';

export default function Profile({ history, match, showBottomNotification, user, activeKita }) {
  const {
    isLoading,
    isSubmiting,
    profileInfo,
    onAddFriend,
    onAcceptFriendRequest,
    onCancelFriendRequest,
    onUnFriend,
    headerUploadProgress,
    avatarUploadProgress,
    onUploadProfileImg,
    onEditProfileImage,
    onDeleteProfileImg,
  } = useProfileLoading({
    profileId: +match.params.id,
    match,
    showBottomNotification,
    userId: user.id,
    kitaId: activeKita && activeKita.kitaId,
  });

  const awoWW = isAwoWW();
  const { setTemplateTitle } = useSiteTitle();
  const [kitaInfo, setKitaInfo] = useState(null);
  const profileId = match.params.id;

  useEffect(() => {
    if (!profileId) return;
    if (awoWW) {
      getKitaInfoByUserId(profileId).then(kitaRes => {
        const kitaInfo = kitaRes.data;
        setKitaInfo(kitaInfo);
      });
    }
  }, [profileId, awoWW]);

  useEffect(() => {
    if (activeKita && activeKita?.kitaId && kitaInfo && awoWW) {
      if (activeKita.kitaId !== kitaInfo) {
        setUserKita(kitaInfo).then(res => {
          if (res && res.status === 200) {
            window.location.reload();
          }
        });
      }
    }
  }, [kitaInfo, awoWW]);

  useEffect(() => {
    if (!profileInfo) return;
    setTemplateTitle('UserProfile', `${profileInfo.firstName} ${profileInfo.lastName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo]);

  return (
    <KitaPanelWrapper>
      <Wrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <ProfilePanel
              history={history}
              profileStatus={profileInfo.profileStatus}
              header={profileInfo.header}
              avatar={profileInfo.avatar}
              userName={`${profileInfo.firstName} ${profileInfo.lastName}`}
              user={user}
              userTitle={profileInfo.title}
              isSubmiting={isSubmiting}
              onAddFriend={onAddFriend}
              onCancelFriendRequest={onCancelFriendRequest}
              onAcceptFriendRequest={onAcceptFriendRequest}
              onUnFriend={onUnFriend}
              headerUploadProgress={headerUploadProgress}
              avatarUploadProgress={avatarUploadProgress}
              onUploadProfileImg={onUploadProfileImg}
              onEditProfileImage={onEditProfileImage}
              onDeleteProfileImg={onDeleteProfileImg}
              userIsSuperAdmin={user.superAdminStatus}
              userIsAdmin={user.administrationAccess}
              userEmail={profileInfo.email}
            />
            {(profileInfo.profileStatus === 'owner' ||
              (!user.superAdminStatus && profileInfo.profileStatus === 'friend')) && (
              <GroupContext.Provider
                value={{
                  patchGroup: () => {},
                  groupInfo: {
                    groupId: 0,
                    addModifyGallery: true,
                    createPostStatus: true,
                    gallerySnippet: {},
                    groupModuleList: profileInfo.modules,
                    visibility: false,
                    publicPostStatus: true,
                    contentVisibility: true,
                    manageFiles: true,
                    addNewFiles: true,
                    createCommentStatus: true,
                    groupAdminStatus: true,
                  },
                  reloadGroupInfo: () => {},
                }}
              >
                <ProfileBody
                  menuTitle={<Trans i18nKey='ProfileMenu' />}
                  menuItems={profileInfo.modules}
                  groups={profileInfo.groups}
                />
              </GroupContext.Provider>
            )}
          </>
        )}
      </Wrapper>
    </KitaPanelWrapper>
  );
}
