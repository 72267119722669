import React from 'react';
import styles from './employeeHistoryBadges.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { EMPLOYEE_ABSENCE_ICONS } from 'library/common/commonConstants/employee';

function EmployeeHistoryBadges({ faCode, text, className }) {
  return (
    <div className={cn(styles.badgesContainer, className)}>
      <i className={`fa ${faCode}`} />
      <span>{text}</span>
    </div>
  );
}

export function HolidayBadge() {
  const { t } = useTranslation();

  return (
    <EmployeeHistoryBadges
      faCode={EMPLOYEE_ABSENCE_ICONS.HOLIDAY}
      text={t('Checkinout.Vacation')}
      className={styles.holiday}
    />
  );
}

export function SickBadge() {
  const { t } = useTranslation();

  return (
    <EmployeeHistoryBadges
      faCode={EMPLOYEE_ABSENCE_ICONS.SICK}
      text={t('EmployeeCheckinout.Sick')}
      className={styles.sick}
    />
  );
}

export function HolidayOnOvertimeBadge() {
  const { t } = useTranslation();

  return (
    <EmployeeHistoryBadges
      faCode={EMPLOYEE_ABSENCE_ICONS.HOLIDAY_ON_OVERTIME}
      text={t('CheckInOut.Holiday on overtime')}
      className={styles.holidayOnOvertime}
    />
  );
}

export function PublicHolidayBadge({ text }) {
  return (
    <EmployeeHistoryBadges faCode='fa-calendar' text={text} className={styles.publicHoliday} />
  );
}

// index for custom absence 1-5
export function CustomAbsenceIconBadge({ index }) {
  return (
    <div
      className={cn(styles.badgesContainer, styles.iconBadge, styles[`absence${index}`])}
      aria-hidden='true'
    >
      <i className={`fa ${getAbsenceIconFromIndex(index)}`} />
    </div>
  );
}

// index for custom absence 1-5
export function CustomAbsenceBadge({ index, text }) {
  return (
    <EmployeeHistoryBadges
      className={styles[`absence${index}`]}
      key={index}
      faCode={getAbsenceIconFromIndex(index)}
      text={text}
    />
  );
}

function getAbsenceIconFromIndex(index) {
  switch (index) {
    case 0:
      return EMPLOYEE_ABSENCE_ICONS.ABSENCE_ONE;
    case 1:
      return EMPLOYEE_ABSENCE_ICONS.ABSENCE_TWO;
    case 2:
      return EMPLOYEE_ABSENCE_ICONS.ABSENCE_THREE;
    case 3:
      return EMPLOYEE_ABSENCE_ICONS.ABSENCE_FOUR;
    case 4:
      return EMPLOYEE_ABSENCE_ICONS.ABSENCE_FIVE;
    default:
      return EMPLOYEE_ABSENCE_ICONS.ABSENCE_ONE;
  }
}
