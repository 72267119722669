import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { returnFormattedDateString } from 'library/api/returnReceipt';

export default function ReturnReceiptFooter({ returnReceiptHandler, userId, rrm }) {

  const { t } = useTranslation();

  const returnReceiptConfirmStyles = {
    fontSize: '11px',
    fontWeight: '700',
    color: '#ff0000',
    marginBottom: '0',
    cursor: 'pointer',

    '&:hover': {
      color: '#fa7474',
    },
  };

  function userHasConfirmedReturnReceipt(rrm)
  {
    if (typeof rrm !== 'undefined') {
      const obj = rrm.find(x => x.userId === userId);
      if(typeof obj !== 'undefined')
      {
        return {userId: obj.userId, createdAt: obj.createdAt};
      }
      else return false;
    }
    return false;
  }
  
  const hasConfirmedReturnReceipt = userHasConfirmedReturnReceipt(rrm);

  const [returnReceiptConfirmStylesState, setReturnReceiptConfirmStylesState] = useState(
    hasConfirmedReturnReceipt !== false
      ? {
          ...returnReceiptConfirmStyles,
          color: 'green',
          cursor: 'auto',
        }
      : returnReceiptConfirmStyles,
  );

  const [returnReceiptConfirmTextState, setReturnReceiptConfirmTextState] = useState(
    hasConfirmedReturnReceipt !== false
       ? t('Post.Return receipt is read') + ": " + returnFormattedDateString(hasConfirmedReturnReceipt.createdAt) 
      : t('Post.Return receipt mark as read'),
  );

  return (
    <div
      style={returnReceiptConfirmStylesState}
      onClick={() => {
        if(returnReceiptConfirmTextState === t('Post.Return receipt mark as read'))
        {
        setReturnReceiptConfirmStylesState({
          ...returnReceiptConfirmStylesState,
          color: 'green',
          cursor: 'auto',
        });
        
        if (hasConfirmedReturnReceipt === false) {
          returnReceiptHandler();
        }
          setReturnReceiptConfirmTextState( t('Post.Return receipt is read') + ": " + returnFormattedDateString(Date.now())); 
      }
      }}
    >
      {returnReceiptConfirmTextState}
    </div>
  );
}
