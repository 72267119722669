import React from 'react';
import classNames from "classnames";
import styles from "./cardWrapper.module.css";

export default function CardWrapper({ children }) {
  return (
    <div className={classNames('container', styles.padding_top_100)}>
      {children}
    </div>
  );
}