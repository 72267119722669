import {
  SETTINGS_CHANGED_SIGNAL,
} from 'library/common/commonConstants/formEditorConstants';

const initialState = {
  settingsChanged: false,
};

export default function FormEditorReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_CHANGED_SIGNAL:
      return {
        ...state,
        settingsChanged: !state.settingsChanged,
      };
    default:
      return state;
  }
}
