import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Loader from 'library/common/commonComponents/Loader';
import { getLetterInLogo } from 'library/utilities/groups';
import styles from './selectSpace.module.scss';

export class SelectSpace extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      Object.keys(state.selectedSpaces).length === 0 &&
      props.selectedGroups &&
      props.selectedGroups.length &&
      props.selectedGroups[0].colourCode
    ) {
      const selectedSpaces = {};
      props.selectedGroups.forEach(item => {
        selectedSpaces[item.groupId] = item;
      });
      return { selectedSpaces };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.mouseDown = null;
    this.inputRef = null;
    this.selectSpaces = null;
    this.state = {
      allSpaces: {},
      availableSpaces: {},
      currentHeight: 45,
      input: '',
      inputFocused: false,
      loading: true,
      selectedSpaces: {},
    };
  }

  componentDidMount() {
    this.getSpaces();
    this.mouseDown = document.addEventListener('mousedown', this.handleClickOutside);
  }

  async getSpaces() {
    const allSpaces = await this.props.getSpaces();
    const selectedSpaces = {};
    const groups = [];
    const { selectedGroups } = this.props;
    if (selectedGroups && selectedGroups.length) {
      selectedGroups.forEach(group => {
        selectedSpaces[group.groupId] = allSpaces[group.groupId];
        groups.push({
          ...allSpaces[group.groupId],
          groupId: group.groupId,
        });
      });
    }
    this.props.updateDesktopNotificationUser(groups);
    this.setState({ allSpaces, loading: false, selectedSpaces });
  }

  getGroupsAndIds = () => {
    const ids = [];
    const groups = [];
    if (Object.keys(this.state.selectedSpaces).length) {
      Object.keys(this.state.selectedSpaces).forEach(key => {
        if (this.state.selectedSpaces[key]) {
          ids.push(this.state.selectedSpaces[key].id);
          groups.push({
            ...this.state.selectedSpaces[key],
            groupId: this.state.selectedSpaces[key].id,
          });
        }
      });
    }
    return { ids, groups };
  };

  handleClickOutside = event => {
    if (this.selectSpaces && event && !this.selectSpaces.contains(event.target)) {
      this.setState({ inputFocused: false });
    }
  };

  onInputChange = event => {
    const { allSpaces } = this.state;
    const tempAvailable = {};
    if (event.target.value && allSpaces && Object.keys(allSpaces).length > 0) {
      Object.keys(allSpaces).map(key => {
        if (
          allSpaces[key].groupName.toLowerCase().includes(event.target.value.toLowerCase()) &&
          !this.state.selectedSpaces[[key]]
        ) {
          tempAvailable[key] = allSpaces[key];
          return true;
        }
        return false;
      });
    }
    this.setState({
      availableSpaces: tempAvailable,
      input: event.target.value,
      inputFocused: !!Object.keys(tempAvailable).length,
    });
  };

  onInputFocus = () => {
    this.setState({ currentHeight: this.selectSpaces.clientHeight + 5, inputFocused: true });
  };

  onSpaceClose = key => () => {
    this.setState(prevState => {
      const tempSpaces = { ...prevState.selectedSpaces };
      if (tempSpaces[key]) {
        delete tempSpaces[key];
      }
      this.setState({ selectedSpaces: tempSpaces });

      if (this.props.onSpaceSelect) {
        this.props.onSpaceSelect(tempSpaces);
      }
    });
  };

  onSpaceClick = (key, space) => () => {
    this.setState(state => {
      const tempSpaces = { ...state.selectedSpaces };
      const tempAvailable = { ...state.availableSpaces };
      if (!tempSpaces[key]) {
        delete tempAvailable[key];
        tempSpaces[key] = {
          ...space,
          groupId: space.id,
        };
      }
      if (this.inputRef) {
        this.inputRef.focus();
      }

      if (this.props.onSpaceSelect) {
        this.props.onSpaceSelect(tempSpaces);
      }

      return {
        availableSpaces: {},
        input: '',
        inputFocused: false,
        selectedSpaces: tempSpaces,
      };
    });
  };

  render() {
    const {
      availableSpaces,
      currentHeight,
      input,
      inputFocused,
      loading,
      selectedSpaces,
    } = this.state;
    let showLoader = true;
    if (selectedSpaces && Object.keys(selectedSpaces).length) {
      showLoader = false;
    } else if (!loading) {
      showLoader = false;
    }
    return (
      <div
        className={cn('notranslate', styles.inputContainer, inputFocused && styles.inputFocused)}
        ref={ref => {
          this.selectSpaces = ref;
        }}
      >
        {selectedSpaces &&
          Object.keys(selectedSpaces).length > 0 &&
          Object.keys(selectedSpaces).map((key, i) => {
            const space = selectedSpaces[key];
            return (
              space && (
                <div className={styles.space} key={key} data-test='selected-space'>
                  {space.logoUrl ? (
                    <img className={styles.spaceImg} src={space.logoUrl} alt={space.groupName} />
                  ) : (
                    <div className={styles.spaceImg} style={{ background: space.colourCode }}>
                      {space.groupName && getLetterInLogo(space.groupName)}
                    </div>
                  )}
                  <span className={styles.spaceName}>{space.groupName}</span>
                  <button
                    type='button'
                    data-test='selected-space-close'
                    onClick={this.onSpaceClose(key)}
                  >
                    <i className={cn('fa fa-close', styles.closeSpace)} />
                  </button>
                </div>
              )
            );
          })}
        {!showLoader && (
          <input
            ref={ref => {
              this.inputRef = ref;
            }}
            data-test='text-input'
            className={styles.input}
            type='text'
            onChange={this.onInputChange}
            onFocus={this.onInputFocus}
            value={input}
            placeholder='Add group'
          />
        )}
        {inputFocused && availableSpaces && Object.keys(availableSpaces).length > 0 && (
          <div
            className={styles.availableSpaces}
            style={{ top: currentHeight }}
            data-test='available-spaces'
          >
            {Object.keys(availableSpaces).map(key => {
              const space = availableSpaces[key];
              return (
                <div className={styles.space} key={key} onClick={this.onSpaceClick(key, space)}>
                  {space.logoUrl ? (
                    <img src={space.logoUrl} alt={space.groupName} />
                  ) : (
                    <div className={styles.spaceImg} style={{ background: space.colourCode }}>
                      {space.groupName && getLetterInLogo(space.groupName)}
                    </div>
                  )}
                  <span className={styles.spaceName}>{space.groupName}</span>
                </div>
              );
            })}
          </div>
        )}
        {showLoader && (
          <div className={styles.loadingContainer}>
            <Loader dotSize='8px' className={styles.loader} />
          </div>
        )}
      </div>
    );
  }
}

SelectSpace.defaultProps = {
  updateDesktopNotificationUser: () => {},
};

SelectSpace.propTypes = {
  getSpaces: PropTypes.func.isRequired,
  updateDesktopNotificationUser: PropTypes.func,
};

export default SelectSpace;
