import React, { useState } from 'react';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { useTranslation } from 'react-i18next';
import styles from './ingredientAddingPopup.module.scss';

const IngredientAddingPopup = ({ onSave, initialRows, isOpened, closePopup, isSaving }) => {
  const [rows, setRows] = useState(initialRows);

  const { t } = useTranslation();

  const handleSave = () => {
    onSave(rows);
  };

  const handleCancel = () => {
    closePopup();
    setRows(initialRows);
  };
  const handleAddRow = () => {
    const newRow = {
      id: Math.random(),
      referenceName: '',
      name: '',
    };
    setRows([...rows, newRow]);
  };
  const handleDeleteRow = id => {
    setRows(rows.filter(row => row.id !== id));
  };
  const handleNameChange = (id, value) => {
    setRows(rows.map(row => (row.id === id ? { ...row, name: value } : row)));
  };
  const handleReferenceNameChange = (id, value) => {
    setRows(rows.map(row => (row.id === id ? { ...row, referenceName: value } : row)));
  };
  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='medium'
      header={t('FoodOrder.Add Ingredients')}
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={handleCancel}>{t('FoodOrder.Cancel')}</Button>
          <ButtonWithLoader onClick={handleSave} type='primary' isLoading={isSaving}>
            {t('FoodOrder.Save')}
          </ButtonWithLoader>
        </div>
      }
    >
      <div className={styles.container}>
        {rows.map(row => (
          <div className={styles.row} key={row.id}>
            <Input
              type='text'
              value={row.referenceName}
              onChange={e => handleReferenceNameChange(row.id, e.target.value)}
            />
            <Input
              type='text'
              value={row.name}
              onChange={e => handleNameChange(row.id, e.target.value)}
            />
            <Button onClick={() => handleDeleteRow(row.id)}>Delete</Button>
          </div>
        ))}
        <Button onClick={handleAddRow}>+</Button>
      </div>
    </Popup>
  );
};

export default IngredientAddingPopup;
