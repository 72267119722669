import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from 'library/common/commonComponents/Tooltip';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';

import styles from './membersItem.module.scss';

export default function MembersItem({ member }) {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.userReducer); 
  const isAdmin = user.administrationAccess;

  if (!member) {
    return null;
  }
  const goUserPage = (userObj) =>{
    if(isAdmin)
    {
      history.push({
        pathname: '/administration/users/list/1',
        search: `?editUserId=${userObj.id}`
      });
    }
  }
  return (
    <div className={cn(styles.member, { [styles.admin]: member.groupAdminStatus })} key={member.id}>
      <Tooltip text={getMemberText(member, t)} isVisibleCondition>
        <div className={styles.memberAvatar}>
          <div onClick={()=>goUserPage(member)}>
            <ProfileAvatar image={member} id={member.id} withoutLink />
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export function getMemberText({ groupAdminStatus, firstName, lastName }, t) {
  return (
    <div>
      {groupAdminStatus ? (
        <>
          {t('GroupBody.Members.Group Admin')}
          <br />
        </>
      ) : (
        ''
      )}
      {firstName} {lastName}
    </div>
  );
}
