import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;

export const saveOrUpdateFinAPIPaymentSettings = (data) => {
  return axios.post(`${URL}/finAPI/saveKitaPaymentSettings`, data);
};

export const createNewUserInDatabase = (data) => {
  return axios.post(`${URL}/finAPI/createnewuserindatabase`, data);
};

export const getKitaPaymentSettings = () => {
  return axios.get(`${URL}/finAPI/getKitaPaymentSettings`);
};

export const getUnpaidFoodOrders = (groupId) => {
  return axios.get(`${URL}/finAPI/getUnpaidFoodOrders/${groupId}`);
};

export const getPaymentWebform = () => {
  return axios.get(`${URL}/finAPI/paymentwebform`);
};

export const getFinApiPaymentWebForm =(paymentSumFromFrontend, groupId)=> {
  return axios.get(`${URL}/finAPI/paymentwebform/${paymentSumFromFrontend}/groupId/${groupId}`);
}

export const finalizePayment =(token)=> {
  return axios.post(`${URL}/finAPI/finalizePayment/${token}`);
}

export const createNewClientUser =(data)=> {
  return axios.post(`${URL}/finAPI/createnewclientuser`, data);
}

export const loadClientUser =()=> {
  return axios.get(`${URL}/finAPI/loadclientuser`);
}


export const createNewKitaUser =(data)=> {
  return axios.post(`${URL}/finAPI/createnewkitauser`, data);
}

export const loadKitaUser =()=> {
  return axios.get(`${URL}/finAPI/loadkitauser`);
}


