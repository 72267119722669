import React from 'react';
import cn from 'classnames';

import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Card from 'library/common/commonComponents/Card';
import ListItem from 'library/common/commonComponents/ListItems/ListItem';

import styles from './pageWithSidebar.module.scss';

export default function PageWithSidebar({ currentPage, menuTitle, menuItems }) {
  const activeTab = currentPage || Object.keys(menuItems)[0];
  const title = menuItems[activeTab] && menuItems[activeTab].bodyTitle;

  return (
    <Col>
      <Row>
        <div className={styles.wrapper}>
          <div className={styles.menu}>
            <Card className={styles.menu__padding_bottom} title={menuTitle}>
              {Object.keys(menuItems).map((key, i) => (
                <ListItem
                  theme='light'
                  className={cn(
                    styles.listItem,
                    menuItems[key].path === activeTab && styles.selected,
                  )}
                  key={`${menuItems[key].title}${i + 1}`}
                  isNavLink
                  {...menuItems[key]}
                />
              ))}
            </Card>
          </div>

          <div className={cn(styles.body, !title && styles.noTitle)}>
            {title && <div className={styles.body__title}>{title}</div>}
            {menuItems[activeTab] && (
              <div className={styles.body__content}>{menuItems[activeTab].component}</div>
            )}
          </div>
        </div>
      </Row>
    </Col>
  );
}
