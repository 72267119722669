import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import deepcopy from 'deepcopy';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Label from 'library/common/commonComponents/Label';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import useEditor from "library/common/commonHooks/useEditor";
import { getCanBePublicStatus } from 'library/utilities/posts';

import styles from './editFilePopup.module.scss';

export default function EditFilePopup({ isOpened, closePopup, initialData, saveFile, group }) {
  const fields = deepcopy(initialData);
  const [fileId, setFileId] = useState(fields.fileId);
  const [isPublic, setIsPublic] = useState(fields.isPublic);
  const [fileIdError, setFileIdError] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    setFileId(fields.fileId);
    setIsPublic(fields.isPublic);
    // eslint-disable-next-line
  }, [initialData]);
  const { t } = useTranslation();

  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = fields.description;
  const { Editor, getEditorValue, updateValue } = useEditor({
    message: wrappedMessage,
    placeholder: t('FileManager.Description'),
    onChange: () => {
      const value = getEditorValue();
    },
  });

  const onSubmit = async () => {
    const description = getEditorValue();
    setIsSubmiting(true);
    if (fileId.trim().length === 0) {
      setFileIdError(t('FileManager.File name field is required'));
    } else {
      setFileIdError(null);
      await saveFile({
        fileId,
        description: description.innerHTML,
        isPublic,
      });
    }
    setIsSubmiting(false);
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='small'
      header={<Trans i18nKey='EditFilePopup.Edit file' />}
      body={
        <div className={cn(styles.container)}>
          <div className={styles.inputGroup}>
            <Label type='input'>{t('EditFilePopup.File Name')}</Label>
            <Input
              value={fileId}
              onChange={({ target }) => {
                setFileId(target.value);
                if (target.value.trim().length > 0) {
                  setFileIdError(null);
                }
              }}
              error={fileIdError}
            />
            {fileIdError && <div className={styles.error}>{fileIdError}</div>}
          </div>

          <div className={styles.inputGroup}>
            <Label type='input'>{t('EditFilePopup.Description')}</Label>
            <Editor />
          </div>

          {/*getCanBePublicStatus(group) && (
            <CheckBox
              name={t('EditFilePopup.Is Public')}
              isChecked={isPublic}
              onChange={() => setIsPublic(!isPublic)}
            />
          )*/}
        </div>
      }
      footer={
        <div>
          <ButtonWithLoader
            onClick={onSubmit}
            type='primary'
            className={styles.footerBtnLeft}
            isLoading={isSubmiting}
          >
            {t('Popup.Save')}
          </ButtonWithLoader>
          <Button onClick={closePopup}>{t('Popup.Cancel')}</Button>
        </div>
      }
    />
  );
}
