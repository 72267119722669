import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from './confirmPopup.module.scss';

export default function ConfirmPopup({ isOpened, closePopup, onConfirm, text }) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onConfirm();
    setIsSubmitting(false);
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='extraSmall'
      header={<Trans i18nKey='Confirm.Confirm Action' />}
      footer={
        <div className={styles.footerNavigation}>
          <Button onClick={closePopup}>{t('Confirm.Cancel')}</Button>
          <ButtonWithLoader onClick={handleConfirm} type='primary' isLoading={isSubmitting}>
            {t('Confirm.Confirm')}
          </ButtonWithLoader>
        </div>
      }
    >
      <div className={styles.container}>{text}</div>
    </Popup>
  );
}
