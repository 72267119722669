import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from 'library/common/commonComponents/Tabs';
import FoodOrderSettings from '../FoodOrderGeneralSettings';
import FinApiSettings from '../FinAPISettings';



export default function FoodOrderSettingsTabs({
  showBottomNotification,
  user,
  groupId,
  setIsSettingsOpen,
  activeKita,
  groupInfo,
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation();

  const tabs = [
    {
      title: t('FoodOrder.General settings'),
      component: <FoodOrderSettings
        showBottomNotification={showBottomNotification}
        user={user}
        groupId={groupInfo.groupId}
        setIsSettingsOpen={setIsSettingsOpen}
        activeKita={activeKita}
      />,
      path: 'general-food-order-settings',
    }
  ];

  if (user.superAdminStatus) {
    tabs.push({
      title: t('FoodOrder.Payment settings'),
      component: <FinApiSettings />,
      path: 'payment-food-order-settings',
    })
  }

  return (

    <Tabs
      tabs={tabs}
      currentTab={tabs[currentTab].path}
      onChange={(_, index) => setCurrentTab(index)}
    />

  );
}
