import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import EditImagePopup from 'library/common/commonComponents/Popups/EditImagePopup';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import EditButton from 'library/common/commonComponents/Buttons/EditButton';
import ColorPickerButton from 'library/common/commonComponents/Buttons/ColorPickerButton';
import DeleteButton from 'library/common/commonComponents/Buttons/DeleteButton';
import Tooltip from 'library/common/commonComponents/Tooltip';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import { editUserKitaColor, toggleKitaFavorite } from 'library/api/kita';
import { isAwoWW } from 'library/api/tenantConfig';
import WithGoogleTranslate from 'library/common/commonComponents/WithGoogleTranslate';
import useKitaImageUploading from './hooks/useKitaImageUploading';

import styles from './kitaListItem.module.scss';
import ColorPickerPopup from 'library/common/commonComponents/Popups/ColorPickerPopup';

export default function KitaListItem({
  history,
  isOpened,
  isActive,
  setActiveKita,
  kita,
  patchKita,
  unreadNotificationsCount,
  unreadMessagesCount,
  unreadTaxReceiptCount,
  userIsAdmin,
  superAdminStatus,
  orderKitas,
  displayKitaImages,
  darkText,
}) {
  const [image, setImage] = useState(getImage(kita));

  const [isColorPickerPopupOpen, setIsColorPickerPopupOpen] = useState(false);

  const [isOpenedEditPanel, setIsOpenedEditPanel] = useState(false);
  const [isOpenedEditPanelTop, setIsOpenedEditPanelTop] = useState(false);
  const [isFavorite, setIsFavorite] = useState(kita.favorite);
  const [backgroundColor, setBackgroundColor] = useState(
    kita.userKitaColorCode || kita.kitaColorCode || '#6fdbe8',
  );
  const [imageURL, setImageURL] = useState();
  useEffect(() => {
    patchKita({
      ...kita,
      userKitaColorCode: backgroundColor,
      ...image,
      userKitaLogoUrl: image.url,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setImageURL(`${image.url}&t=${Date.now()}`);
  }, [backgroundColor, image]);

  const patchImage = payload => setImage({ ...image, ...payload });

  const {
    changeImage,
    progress,
    deleteImage,
    isDeletePopupOpened,
    closeDeletePopup,
    onDeleteBtnClick,
    deleteHeaderText,
    deleteBodyText,
    editImage,
    isEditPopupOpened,
    closeEditPopup,
    onEditBtnClick,
    editHeaderText,
  } = useKitaImageUploading({
    showBottomNotification: () => {},
    kitaId: kita.kitaId,
    kita,
    patchImage,
  });
  const unreadNotificationsActiveKita =
    unreadNotificationsCount[kita.kitaId] && unreadNotificationsCount[kita.kitaId].count;

  const unreadTaxReceiptActiveKita =
    unreadTaxReceiptCount[kita.kitaId] && unreadTaxReceiptCount[kita.kitaId].count;

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => {
      setIsOpenedEditPanel(false);
    },
  });

  const outsideEventContainerRefTop = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => {
      setIsOpenedEditPanelTop(false);
    },
  });

  const changeBackgroundColor = colorCode =>
    changeBackgroundColorHandler({ kitaId: kita.kitaId, colorCode, setBackgroundColor });

  const onKitaFavorite = () => {
    toggleKitaFavorite({ kitaId: kita.kitaId, isFavorite: !isFavorite }).then(() => {
      setIsFavorite(!isFavorite);
      orderKitas({ ...kita, favorite: !isFavorite });
    });
  };
  return (
    <div
      className={cn(
        styles.kitaListItemContainer,
        isActive &&
          cn(
            styles.kitaListItemContainerActive,
            isAwoWW() && styles.kitaListItemContainerAwoWwActive,
          ),
        isOpened && styles.opened,
      )}
      onClick={() => {
        setActiveKita(kita.kitaId);
        history.push('/');
      }}
    >
      <DeletePopup
        isOpened={isDeletePopupOpened}
        closePopup={closeDeletePopup}
        onDeleteBtnClick={onDeleteBtnClick}
        headerText={deleteHeaderText}
        bodyText={deleteBodyText}
      />
      <EditImagePopup
        isOpened={isEditPopupOpened}
        closePopup={closeEditPopup}
        onEditBtnClick={onEditBtnClick}
        headerText={editHeaderText}
        image={image}
        type='logo'
      />
      <ColorPickerPopup
        isOpened={isColorPickerPopupOpen}
        closePopup={() => setIsColorPickerPopupOpen(false)}
        headerText={'Change color'}
        onSaveButtonClick={changeBackgroundColor}
        backgroundColor={backgroundColor}
      />

      <div
        className={cn(
          styles.kitaListItemAvatarWrapper,
          isActive && (isAwoWW() ? styles.aoWwActive : styles.active),
        )}
      >
        <div className={styles.kitaListItemAvatarContainer} style={{ background: backgroundColor }}>
          <Tooltip
            text={<WithGoogleTranslate>{kita.kitaName}</WithGoogleTranslate>}
            position='right'
            wrapperClassName={styles.kitaListItemAvatarContainerInner}
          >
            {progress ? (
              <div className={styles.progressContainer}>
                <div className={styles.progress}>
                  <div className={styles.progressInfo} style={{ width: progress + '%' }} />
                </div>
              </div>
            ) : image.url && displayKitaImages ? (
              <img src={imageURL} alt='logo' className={styles.kitaListItemAvatarContainerLogo} />
            ) : (
              <div
                className={
                  styles.linkAvatar + ' ' + (darkText ? styles.textDark : styles.textLight)
                }
              >
                {getKitaLogoLetters(kita.kitaName)}
              </div>
            )}
          </Tooltip>
          {!progress && (
            <>
              <div
                className={cn(
                  styles.logoEditingContainerTop,
                  isOpenedEditPanelTop && styles.openedContainer,
                )}
                ref={outsideEventContainerRefTop}
              >
                <div
                  className={styles.logoEditingContainerEventWrapper}
                  onClick={() => setIsOpenedEditPanelTop(!isOpenedEditPanelTop)}
                >
                  <i className={isOpenedEditPanelTop ? 'fa fa-caret-down' : 'fa fa-caret-up'} />
                </div>
                <div
                  className={cn(
                    styles.logoEditingOptionsContainer,
                    isOpenedEditPanelTop && styles.opened,
                  )}
                >
                  <div className={styles.logoEditingButtons} onClick={onKitaFavorite}>
                    {isFavorite ? <i className='fa fa-star' /> : <i className='fa fa-star-o' />}
                  </div>
                </div>
              </div>
            </>
          )}

          {!progress &&
            userIsAdmin &&
            (kita.virtual && !superAdminStatus ? (
              <></>
            ) : (
              <div
                className={cn(
                  styles.logoEditingContainer,
                  isOpenedEditPanel && styles.openedContainer,
                )}
                ref={outsideEventContainerRef}
              >
                <div
                  className={styles.logoEditingContainerEventWrapper}
                  onClick={() => setIsOpenedEditPanel(!isOpenedEditPanel)}
                >
                  <i className={isOpenedEditPanel ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
                </div>
                <div
                  className={cn(
                    styles.logoEditingOptionsContainer,
                    isOpenedEditPanel && styles.opened,
                  )}
                >
                  <UploadButton
                    className={styles.logoEditingButtons}
                    onChange={e => {
                      changeImage(e);
                    }}
                    allowedExtensions={['image/*']}
                  />
                  {image.url ? (
                    <>
                      <EditButton className={styles.logoEditingButtons} onClick={editImage} />
                      <DeleteButton
                        className={styles.logoEditingButtonsDelete}
                        onClick={deleteImage}
                      />
                    </>
                  ) : (
                    <div
                      className={styles.logoEditingButtons}
                      onClick={() => setIsColorPickerPopupOpen(true)}
                    >
                      <i className='fa fa-tint' />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div>
        {unreadNotificationsActiveKita + unreadMessagesCount > 0 && (
          <div className={styles.unreadNotifications}>
            {unreadNotificationsActiveKita + unreadMessagesCount}
          </div>
        )}
        {unreadTaxReceiptActiveKita > 0 && (
          <div className={styles.unreadBottomNotifications}>{unreadTaxReceiptActiveKita}</div>
        )}
      </div>
    </div>
  );
}

export function getKitaLogoLetters(kitaName) {
  if (kitaName) {
    const [firstLetter, secondLetter] = kitaName
      .trim()
      .split(' ')
      .map(word => (word && word.length > 0 ? word[0].toUpperCase() : null))
      .filter(word => word != null);
    return secondLetter ? firstLetter + secondLetter : firstLetter;
  }
}

export function changeBackgroundColorHandler({ kitaId, colorCode, setBackgroundColor }) {
  const hex = colorCode.hex;
  setBackgroundColor(hex);
  editUserKitaColor({ kitaId, colorCode: hex });
}

export function getImage(kita) {
  return kita.userKitaLogoUrl
    ? {
        unit: '%',
        x: kita.userKitaLogoXCoordinate,
        y: kita.userKitaLogoYCoordinate,
        width: kita.userKitaLogoWidth,
        height: kita.userKitaLogoWidth,
        aspect: 1,
        url: kita.userKitaLogoUrl,
      }
    : {
        unit: '%',
        x: kita.kitaLogoXCoordinate,
        y: kita.kitaLogoYCoordinate,
        width: kita.kitaLogoWidth,
        height: kita.kitaLogoWidth,
        aspect: 1,
        url: kita.kitaLogoUrl,
      };
}
