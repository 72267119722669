import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import QRCode from 'qrcode';

import { getGroupsForChildDropdown } from 'library/api/groups';
import { setKidHolidayTimes, deleteParent2, generateQRCodeForUser } from 'library/api/kids';
import { getConfig, getKitaVerificationImages } from 'library/api/kita';
import {
  getChildrenByUserId,
  getCountries,
  addKid,
  updateKid,
  deleteKid,
  deactivateKid,
  getKidVerificationImage,
  setKidVerificationImage,
} from 'library/api/user';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Checkbox from 'library/common/commonComponents/Checkbox';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Label from 'library/common/commonComponents/Label';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import RadioButton from 'library/common/commonComponents/RadioButton';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';

import AuthorizedPeople from '../../../AuthorizedPeople';
import BookingTimes from '../../../BookingTimes';
import ChildFoodOrderedTable from '../../../ChildFoodOrderedTable';
import HolidayTimes from '../../../HolidayTimes';
import DeletePopup from '../../../Popups/DeletePopup';
import ProfileAvatar from '../../../ProfileAvatar';
import UserChildrenAddPerKidCodePopup from './UserChildrenAddPerKidCodePopup';
import styles from './userChildren.module.scss';
import { QRCodeSVG } from 'qrcode.react';

const defaultKid = {
  gender: null,
  country: '',
  firstName: '',
  lastName: '',
  street: '',
  zip: '',
  city: '',
  className: '',
  schoolName: '',
  state: '',
  birthday: null,
  about: '',
  birthPlace: '',
  kidLanguage: '',
  bookingTimeCategorie: null,
  requestForCareDate: null,
  ageGroupAtCareStartDate: '',
  denomination: '',
  careReqestStatus: null,
  switchingChildStatus: null,
  contractStartDate: null,
  contractEndDate: null,
  measlesVaccinationStatus: null,
  measlesVaccinationDate: null,
  foodOrdersPerWeek: 5,
  allergies: '',
  medication: '',
  foodIntolerance: '',

  participateInExcursionsAllowed: false,
  exchangeWithOtherInstitutionsAllowed: false,
  photosAllowed: false,
  showPhotosInInstitutionAllowed: false,
  usePhotosInPortfolioAllowed: false,
  usePhotosInOtherChildsPortfolioAllowed: false,
  usePhotosForAdvancedEducationAllowed: false,
  exchangeWithElementarySchoolAllowed: false,
  pickupBySiblingAllowed: false,
  noNDAWithDoctor: false,
  noNDAWithSpecialService: false,
  beeStingerRemovalForbidden: false,
  contactParentsAfterBeeStingerRemoval: false,
  beeStingerRemovalAllowed: false,
  forwardingAddressAndPhoneNumberAllowed: false,
  kidWithSpecialNeeds: false,
  kidCanGoHomeOnTheirOwn: false,
  kidComesEarly: false,

  bookingTimeMondayFrom: null,
  bookingTimeMondayTo: null,
  bookingTimeTuesdayFrom: null,
  bookingTimeTuesdayTo: null,
  bookingTimeWednesdayFrom: null,
  bookingTimeWednesdayTo: null,
  bookingTimeThursdayFrom: null,
  bookingTimeThursdayTo: null,
  bookingTimeFridayFrom: null,
  bookingTimeFridayTo: null,

  bookingHerbstferienTimeFrom: null,
  bookingHerbstferienTimeTo: null,
  bookingWeihnachtsferienTimeFrom: null,
  bookingWeihnachtsferienTimeTo: null,
  bookingWinterferienTimeFrom: null,
  bookingWinterferienTimeTo: null,
  bookingOsterferienTimeFrom: null,
  bookingOsterferienTimeTo: null,
  bookingPfingstferienTimeFrom: null,
  bookingPfingstferienTimeTo: null,
  bookingSommerferienTimeFrom: null,
  bookingSommerferienTimeTo: null,

  foodOrderedMonday: false,
  foodOrderedTuesday: false,
  foodOrderedWednesday: false,
  foodOrderedThursday: false,
  foodOrderedFriday: false,

  parent1Custody: false,
  parent2Custody: false,
};

export default function UserChildren({
  user,
  showBottomNotification,
  activeKita,
  kitaId,
  isAdminEditing,
  editChildId,
  automaticallyDisplayKidCreationScreen,
  kitaFields,
  kitaCustomFields,
  groupMandatoryField,
}) {
  const { t } = useTranslation();

  const [kids, setKids] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreation, setIsCreation] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [progress, setProgress] = useState(0);
  const [holidayTimes, setHolidayTimes] = useState([]);
  const [verificationImages, setVerificationImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [customFields, setCustomFields] = useState([]);

  const isHortType = (activeKita?.kitaTypeTranslations || []).includes('Hort');
  const disableEditing = !isAdminEditing && (kitaId === 2955 || kitaId === 2997 || kitaId === 2957);

  const isFieldDisabled = key => {
    if (disableEditing || (!isAdminEditing && key && !kitaFields?.[key]?.parentCanEdit)) {
      return true;
    }
  };

  const isCustomFieldDisabled = id => {
    if (disableEditing || (!isAdminEditing && !kitaCustomFields.find(x=>x.id ===id)?.parentCanEdit)) {
      return true;
    }
  };

  const getEditKid = kidList => {
    if (editChildId) {
      const editKids = kidList.filter(k => k.id === editChildId && k.parent1Status !== 'rejected');
      if (editKids && editKids.length > 0) {
        return editKids[0];
      }
    }

    return null;
  };

  const isAwobamberg = window.location.hostname
    .toLowerCase()
    .includes('awobamberg.safe2connect.org');

  function addLeadingZero(n) {
    return n > 9 ? '' + n : '0' + n;
  }

  function convertKidBookingTimeObjectIfNeeded(kidBookingTime) {
    if (kidBookingTime === null) {
      return null;
    }
    if (typeof kidBookingTime === 'object') {
      return `${addLeadingZero(kidBookingTime.hour)}:${addLeadingZero(kidBookingTime.minute)}:00`;
    }
    return kidBookingTime;
  }

  //needed because booking times are stored in time format in database and time object is returned but frontend time input needs time string
  function replaceBookingTimes(kidObject) {
    const newArray = [];
    kidObject.forEach(kObject => {
      const updatedObject = {
        ...kObject,
        bookingTimeMondayFrom: convertKidBookingTimeObjectIfNeeded(kObject.bookingTimeMondayFrom),
        bookingTimeMondayTo: convertKidBookingTimeObjectIfNeeded(kObject.bookingTimeMondayTo),
        bookingTimeTuesdayFrom: convertKidBookingTimeObjectIfNeeded(kObject.bookingTimeTuesdayFrom),
        bookingTimeTuesdayTo: convertKidBookingTimeObjectIfNeeded(kObject.bookingTimeTuesdayTo),
        bookingTimeWednesdayFrom: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingTimeWednesdayFrom,
        ),
        bookingTimeWednesdayTo: convertKidBookingTimeObjectIfNeeded(kObject.bookingTimeWednesdayTo),
        bookingTimeThursdayFrom: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingTimeThursdayFrom,
        ),
        bookingTimeThursdayTo: convertKidBookingTimeObjectIfNeeded(kObject.bookingTimeThursdayTo),
        bookingTimeFridayFrom: convertKidBookingTimeObjectIfNeeded(kObject.bookingTimeFridayFrom),
        bookingTimeFridayTo: convertKidBookingTimeObjectIfNeeded(kObject.bookingTimeFridayTo),
        bookingHerbstferienTimeFrom: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingHerbstferienTimeFrom,
        ),
        bookingHerbstferienTimeTo: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingHerbstferienTimeTo,
        ),
        bookingWeihnachtsferienTimeFrom: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingWeihnachtsferienTimeFrom,
        ),
        bookingWeihnachtsferienTimeTo: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingWeihnachtsferienTimeTo,
        ),
        bookingWinterferienTimeFrom: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingWinterferienTimeFrom,
        ),
        bookingWinterferienTimeTo: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingWinterferienTimeTo,
        ),
        bookingOsterferienTimeFrom: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingOsterferienTimeFrom,
        ),
        bookingOsterferienTimeTo: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingOsterferienTimeTo,
        ),
        bookingPfingstferienTimeFrom: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingPfingstferienTimeFrom,
        ),
        bookingPfingstferienTimeTo: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingPfingstferienTimeTo,
        ),
        bookingSommerferienTimeFrom: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingSommerferienTimeFrom,
        ),
        bookingSommerferienTimeTo: convertKidBookingTimeObjectIfNeeded(
          kObject.bookingSommerferienTimeTo,
        ),
      };
      newArray.push(updatedObject);
    });

    return newArray;
  }

  const [qrCodeImg, setQrCodeImage] = useState(0);

  useEffect(() => {
    getKitaVerificationImages(kitaId).then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const result = res.data;
        result.sort((a, b) => a.sequence - b.sequence);
        setVerificationImages(result);
      }
    });
  }, [kitaId]);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    setTimeout(() => {}, 1000);

    if (!refresh) {
      if (isAdminEditing) {
        getChildrenByUserId(true, user.id)
          .then(res => {
            const replacedKidData = replaceBookingTimes(res.data);
            const editKid = getEditKid(replacedKidData);
            setKids(replacedKidData);
            setKid(editKid || replacedKidData[0]);

            if (editKid) {
              startCreation(editKid);
            }
          })
          .then(() => {
            getCountriesAndChildGroups();
          });
      } else {
        getChildrenByUserId(true)
          .then(res => {
            const replacedKidData = replaceBookingTimes(res.data);
            const editKid = getEditKid(replacedKidData);

            setKids(replacedKidData);
            setKid(editKid || replacedKidData[0]);

            if (editKid) {
              startCreation(editKid);
            }
          })
          .then(() => {
            getCountriesAndChildGroups();
          });
      }
      setRefresh(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleClosePopUp = () => {
    setIsPopupVisible(false);
  };

  const qrCodeRef = useRef(null);

  const generatePDF = () => {
    const uploadToFilesModuleData = {
      uploadToFilesModule: false,
      userId: user.id,
      groupId: kid.id,
      setProgress: setProgress,
    };

    var qr = null;

    const idString = kid.naturalId;
    QRCode.toDataURL(idString + ' kid', function(err, url) {
      qr = url;
    });

    generateQRCodeForUser(t, kid, uploadToFilesModuleData, qr);
  };

  const [kid, setKid] = useState(null);

  const genderOptions = [
    { label: t('Administration.UserSection.Not specified'), value: null },
    { label: t('Administration.UserSection.Male'), value: 'Male' },
    { label: t('Administration.UserSection.Female'), value: 'Female' },
    { label: t('Administration.UserSection.Custom'), value: 'Custom' },
  ];

  const bookingTimeCategories = [
    { label: t('Administration.KidSection.Not specified'), value: null },
    { label: t('Administration.KidSection.OneToTwoHours'), value: 'OneToTwoHours' },
    { label: t('Administration.KidSection.TwoToTwoThree'), value: 'TwoToThreeHours' },
    { label: t('Administration.KidSection.ThreeToFourHours'), value: 'ThreeToFourHours' },
    { label: t('Administration.KidSection.FourToFiveHours'), value: 'FourToFiveHours' },
    { label: t('Administration.KidSection.FiveToSixHours'), value: 'FiveToSixHours' },
    { label: t('Administration.KidSection.SixToSevenHours'), value: 'SixToSevenHours' },
    { label: t('Administration.KidSection.SevenToEightHours'), value: 'SevenToEightHours' },
    { label: t('Administration.KidSection.EightToNineHours'), value: 'EightToNineHours' },
    { label: t('Administration.KidSection.NineToTenHours'), value: 'NineToTenHours' },
  ];

  const careRequestStatuses = [
    { label: t('Administration.KidSection.Not specified'), value: null },
    { label: t('Administration.KidSection.CommitmentByParents'), value: 'CommitmentByParents' },
    { label: t('Administration.KidSection.CommitmentByKita'), value: 'CommitmentByKita' },
    { label: t('Administration.KidSection.ContractSigned'), value: 'ContractSigned' },
  ];

  const allowedFoodOrdersPerWeekOptions = [
    { label: t('0'), value: 0 },
    { label: t('1'), value: 1 },
    { label: t('2'), value: 2 },
    { label: t('3'), value: 3 },
    { label: t('4'), value: 4 },
    { label: t('5'), value: 5 },
  ];

  const switchingChildStatuses = [
    { label: t('Administration.KidSection.Not specified'), value: null },
    { label: t('Administration.KidSection.Yes'), value: 'Yes' },
    { label: t('Administration.KidSection.No'), value: 'No' },
    { label: t('Administration.KidSection.Unknown'), value: 'Unknown' },
  ];

  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedBookingTimeCategorie, setSelectedBookingTimeCategorie] = useState(null);
  const [selectedCareRequestStatus, setSelectedCareRequestStatus] = useState(null);
  const [selectedFoodOrderNumber, setSelectedFoodOrderNumber] = useState(null);
  const [selectedSwitchingChildStatus, setSelectedSwitchingChildStatus] = useState(null);

  const [countryOptions, setCountryOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    if (kid && !selectedGender) {
      setSelectedGender(
        genderOptions.find(gender => gender.value === kid.gender) || genderOptions[0],
      );
    }

    if (kid && !selectedBookingTimeCategorie) {
      setSelectedBookingTimeCategorie(
        bookingTimeCategories.find(
          bookingTimeCategorie => bookingTimeCategorie.value === kid.bookingTimeCategorie,
        ) || bookingTimeCategories[0],
      );
    }

    if (kid && !selectedCareRequestStatus) {
      setSelectedCareRequestStatus(
        careRequestStatuses.find(
          careRequestStatus => careRequestStatus.value === kid.careRequestStatus,
        ) || careRequestStatuses[0],
      );
    }

    if (kid && !selectedSwitchingChildStatus) {
      setSelectedSwitchingChildStatus(
        switchingChildStatuses.find(
          switchingChildStatus => switchingChildStatus.value === kid.switchingChildStatus,
        ) || switchingChildStatuses[0],
      );
    }

    if (kid && !selectedCountry && countryOptions.length > 0) {
      setSelectedCountry(
        countryOptions.find(country => country.value === kid.country) || countryOptions[0],
      );
    }
    if (kid && kid.kidGroup && groupOptions.length > 0) {
      setSelectedGroup(
        groupOptions.find(group => kid.kidGroup && group.value === kid.kidGroup.id) ||
          groupOptions[0],
      );
    }
    if (kid && kid.kidMainGroup && groupOptions.length > 0) {
      setSelectedMainGroup(
        groupOptions.find(group => kid.kidMainGroup && group.value === kid.kidMainGroup.id) ||
          groupOptions[0],
      );
    }
  }, [kid, countryOptions, groupOptions]);

  useEffect(()=> {
    if (!kitaCustomFields) {
      return;
    }
    const fields = kitaCustomFields.filter(x => x.shownOnKidProfile);
    const updatedFields = fields.map(f => {
      const fieldValue = kid?.kidCustomFieldValues?.find(x => x.customKidFieldsSettingsId === f.id);
      return { ...f, value: fieldValue?.value || false };
    });
    setCustomFields(updatedFields);
  }, [kid, kitaCustomFields])

  useEffect(() => {
    if (kid) {
      setSelectedCountry(
        countryOptions.find(country => country.value === +kid.country) || countryOptions[0],
      );
      setSelectedGender(
        genderOptions.find(gender => gender.value === kid.gender) || genderOptions[0],
      );
      setSelectedBookingTimeCategorie(
        bookingTimeCategories.find(
          bookingTimeCategorie => bookingTimeCategorie.value === kid.bookingTimeCategorie,
        ) || bookingTimeCategories[0],
      );
      setSelectedCareRequestStatus(
        careRequestStatuses.find(
          careRequestStatus => careRequestStatus.value === kid.careRequestStatus,
        ) || careRequestStatuses[0],
      );
      setSelectedSwitchingChildStatus(
        switchingChildStatuses.find(
          switchingChildStatus => switchingChildStatus.value === kid.switchingChildStatus,
        ) || switchingChildStatuses[0],
      );
      setSelectedGroup(
        groupOptions.find(group => kid.kidGroup && group.value === kid.kidGroup.id) ||
          groupOptions[0],
      );
      setSelectedMainGroup(
        groupOptions.find(group => kid.kidMainGroup && group.value === kid.kidMainGroup.id) ||
          groupOptions[0],
      );
      setSelectedFoodOrderNumber(
        allowedFoodOrdersPerWeekOptions.find(number => number.value === kid.foodOrdersPerWeek) ||
          allowedFoodOrdersPerWeekOptions[5],
      );
    }
  }, [kid]);

  useEffect(() => {
    if (kid?.id) {
      getKidVerificationImage(kid.id)
        .then(res => {
          if (res.status === 200) {
            setSelectedImage(res.data);
          }
        })
        .catch(err => {
          setSelectedImage(null);
          console.log(err);
        });
    }
  }, [kid?.id]);

  useEffect(() => {
    /*if (kid) {
      const qr = QRCode(0, 'L'); // Create a new QR code instance (0 = QR Code version, 'L' = Error correction level)
      // Set QR code data
      const qrCodeData = kid.id; // Replace with your QR code data
      qr.addData(qrCodeData);
      qr.make();

      setQrCodeImage(qr.createDataURL());

      // Display QR code in HTML
      if (qrCodeRef.current) {
        qrCodeRef.current.src = qr.createDataURL();
      }
    }*/
  }, [qrCodeRef.current]);

  function getCountriesAndChildGroups() {
    return new Promise((resolve, reject) => {
      getGroupsForChildDropdown()
        .then(({ data }) => {
          data = data.filter(obj => !obj.globalPublicGroup);
          let newSelectedGroups = [
            { label: t('Administration.UserSection.Please select'), value: 0 },
          ].concat(data.map(group => ({ ...group, label: group.groupName, value: group.id })));

          newSelectedGroups = newSelectedGroups.filter(
            item => item.label.slice(-11) !== '(Portfolio)',
          );
          setGroupOptions(newSelectedGroups);
          /*  setSelectedGroup(
            newSelectedGroups.find(group => kid.kidGroup && group.value === kid.kidGroup.id) ||
            newSelectedGroups[0],
          ); */
        })
        .finally(() => {
          getCountries().then(({ data }) => {
            const sorted = data.sort((x, y) => {
              return x.countryName === 'Deutschland (Germany)'
                ? -1
                : y.countryName === 'Deutschland (Germany)'
                ? 1
                : 0;
            });
            const newSelectedCountries = [
              { label: t('Administration.UserSection.Not specified'), value: 0 },
            ].concat(
              sorted.map(country => ({
                ...country,
                label: country.countryName,
                value: country.id,
              })),
            );
            setCountryOptions(newSelectedCountries);
          });
        })
        .finally(() => {
          setIsLoading(false);
          resolve();
        });
    });
  }

  const onSelectedCountryChanged = country => {
    setKid({ ...kid, country: country.value });
    setSelectedCountry(country);
  };

  const onSelectedGenderChanged = gender => {
    setKid({ ...kid, gender: gender.value });
    setSelectedGender(gender);
  };

  const onSelectedBookingTimeChanged = bookingTimeCategorie => {
    setKid({ ...kid, bookingTimeCategorie: bookingTimeCategorie.value });
    setSelectedBookingTimeCategorie(bookingTimeCategorie);
  };

  const onSelectedCareRequestStatusChanged = careRequestStatus => {
    setKid({ ...kid, careRequestStatus: careRequestStatus.value });
    setSelectedCareRequestStatus(careRequestStatus);
  };

  const onSelectedFoodOrderNumberChanged = number => {
    setKid({ ...kid, foodOrdersPerWeek: number.value });
    setSelectedFoodOrderNumber(number);
  };

  const onSelectedSwitchingChildStatusChanged = switchingChildStatus => {
    setKid({ ...kid, switchingChildStatus: switchingChildStatus.value });
    setSelectedSwitchingChildStatus(switchingChildStatus);
  };

  const onSelectedGroupChanged = group => {
    setKid({ ...kid, kidGroup: { id: group.value } });
    setSelectedGroup(group);
  };

  const onSelectedDefaultGroupChanged = defaultGroup => {
    setKid({ ...kid, defaultGroup: { id: defaultGroup.value } });
    setSelectedDefaultGroup(defaultGroup);
  };

  const onSelectedMainGroupChanged = group => {
    setKid({ ...kid, kidMainGroup: { id: group.value } });
    setSelectedMainGroup(group);
  };

  const onUpdateCustomKidValue = (field) => {
    const updatedFields = customFields.map(f =>
      f.id === field.id ? { ...f, value: !f.value } : f
    );
    setCustomFields(updatedFields);
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedDefaultGroup, setSelectedDefaultGroup] = useState(null);
  const [selectedMainGroup, setSelectedMainGroup] = useState(null);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [groupError, setGroupError] = useState('');
  // const [birthdayError, setBirthdayError] = useState('');

  const [page, setPage] = useState(1);
  useEffect(() => {
    const selectedKid = kids[page - 1];

    if (selectedKid && editChildId == null) {
      setKid(selectedKid);

      setSelectedCountry(
        countryOptions.find(country => country.value === +selectedKid.country) || countryOptions[0],
      );
      setSelectedGender(
        genderOptions.find(gender => gender.value === selectedKid.gender) || genderOptions[0],
      );
      setSelectedGroup(
        groupOptions.find(
          group => selectedKid.kidGroup && group.value === selectedKid.kidGroup.id,
        ) || groupOptions[0],
      );
      setSelectedMainGroup(
        groupOptions.find(
          group => selectedKid.kidMainGroup && group.value === selectedKid.kidMainGroup.id,
        ) || groupOptions[0],
      );
    }
  }, [page, countryOptions, groupOptions]);

  const [allowParentsAddingChild, setAllowParentsAddingChild] = useState(false);

  useEffect(() => {
    getConfig().then(res => {
      const kitaConfig = res.data;
      setAllowParentsAddingChild(kitaConfig.allowParentsAddingChild);
    });
  }, []);

  const [isSubmiting, setIsSubmitting] = useState(false);
  const [creationKidCode, setCreationKidCode] = useState('');

  const saveKidHolidayBookingTimes = async () => {
    const payload = [];
    const errorData = [];
    holidayTimes.forEach(holidayTime => {
      if (
        (!holidayTime?.bookingTimeFrom && holidayTime?.bookingTimeTo) ||
        (holidayTime?.bookingTimeFrom && !holidayTime?.bookingTimeTo)
      ) {
        errorData.push('One is empty!');
      } else if (holidayTime?.bookingTimeFrom && holidayTime?.bookingTimeTo) {
        if (holidayTime.bookingTimeFrom > holidayTime.bookingTimeTo) {
          errorData.push('Start time is after End Time!');
        } else {
          payload.push(holidayTime);
        }
      }
    });
    if (errorData.length > 0) {
      console.log(errorData);
      return;
    }
    await setKidHolidayTimes(kitaId, kid.id, payload);
  };

  const submit = async () => {
    if (!kid) return;

    let isValid = true;
    if (kid.firstName.trim().length === 0) {
      isValid = false;
      setFirstNameError(t('Administration.UserSection.First name cannot be blank'));
    } else {
      setFirstNameError('');
    }

    if (kid.lastName.trim().length === 0) {
      isValid = false;
      setLastNameError(t('Administration.UserSection.Last name cannot be blank'));
    } else {
      setLastNameError('');
    }

    if (groupMandatoryField) {
      if (!selectedGroup?.value) {
        isValid = false;
        setGroupError(t('Administration.UserSection.Group cannot be blank'));
      } else {
        setGroupError('');
      }
    }

    /* if (kid.birthday === null) {
      isValid = false;
      setBirthdayError(t('Administration.UserSection.Birthday cannot be blank'));
    } else {
      setBirthdayError('');
    } */

    if (!isValid) return;

    setIsSubmitting(true);

    try {
      if (kid.id) {
        await updateKid({
          ...kid,
          birthday: kid.birthday ? new Date(kid.birthday) : null,
          requestForCareDate: kid.requestForCareDate ? new Date(kid.requestForCareDate) : null,
          contractStartDate: kid.contractStartDate ? new Date(kid.contractStartDate) : null,
          contractEndDate: kid.contractEndDate ? new Date(kid.contractEndDate) : null,
          measlesVaccinationDate: kid.measlesVaccinationDate
            ? new Date(kid.measlesVaccinationDate)
            : null,
          group: kid && kid.kidGroup && kid.kidGroup.id ? kid.kidGroup.id : '',
          mainGroup: kid && kid.kidMainGroup && kid.kidMainGroup.id ? kid.kidMainGroup.id : '',
        }, {customFields});
        await saveKidHolidayBookingTimes();
        if (selectedImage) {
          setKidVerificationImage(kid.id, selectedImage.id);
        }
      } else {
        const res = await addKid({
          ...kid,
          birthday: kid.birthday ? new Date(kid.birthday) : null,
          requestForCareDate: kid.requestForCareDate ? new Date(kid.requestForCareDate) : null,
          contractStartDate: kid.contractStartDate ? new Date(kid.contractStartDate) : null,
          contractEndDate: kid.contractEndDate ? new Date(kid.contractEndDate) : null,
          measlesVaccinationDate: kid.measlesVaccinationDate
            ? new Date(kid.measlesVaccinationDate)
            : null,
          group: kid && kid.kidGroup && kid.kidGroup.id ? kid.kidGroup.id : '',
          mainGroup: kid && kid.kidMainGroup && kid.kidMainGroup.id ? kid.kidMainGroup.id : '',
          userId: user.id,
        }, {customFields});

        await saveKidHolidayBookingTimes();
        if (selectedImage) {
          setKidVerificationImage(res.data.id, selectedImage.id);
        }
        setCreationKidCode(res.data.kidCode);
        setIsPopupVisible(true);
      }
    } finally {
      setRefresh(false);
      setIsCreation(false);
      setIsSubmitting(false);
    }
  };

  function startCreation(kidsEntity) {
    setIsCreation(true);
    setKid(kidsEntity);
  }

  const startNewCreation = () => {
    setIsCreation(true);
    setKid(defaultKid);
  };

  const deactivateChild = () => {
    deactivateKid(kid.id).then(res => {
      showBottomNotification(t('BottomNotifications.Success'));
      setRefresh(false);
      setIsCreation(false);
      setIsSubmitting(false);
    });
  };

  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isDeletionOngoing, setIsDeletionOngoing] = useState(false);

  const addPerKidCode = () => {
    setIsAddPerKidCodePopupOpen(true);
  };

  const [deleteParentMessage, setDeleteParentMessage] = useState('');

  const deleteParent = () => {
    deleteParent2(kid.id).then(res => {
      if (res.data) {
        setKid({ ...kid, parent2: null });
      } else {
        setDeleteParentMessage(t('Kid.Parent could not be removed'));
      }
    });
  };

  const deleteChild = async () => {
    setIsDeletionOngoing(true);
    if (kid.id) {
      await deleteKid(kid, user.id);
      showBottomNotification(t('BottomNotifications.Saved'));
    }

    setIsDeletionOngoing(false);
    setIsDeletePopupVisible(false);

    const newKids = kids.filter(({ id }) => id !== kid.id);
    setKids(newKids);
    setIsCreation(false);
    setKid(newKids[0]);
    setPage(1);
  };
  const [isAddPerKidCodePopupOpen, setIsAddPerKidCodePopupOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (automaticallyDisplayKidCreationScreen) {
        startNewCreation();
      }
    }, 1000);
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <UserChildrenAddPerKidCodePopup
        isOpen={isAddPerKidCodePopupOpen}
        setIsOpen={setIsAddPerKidCodePopupOpen}
        kitaId={kitaId}
        setRefresh={setRefresh}
        userId={user.id}
      />
      {!kid && !isCreation ? (
        <div className={styles.noChildren}>
          <div className={styles.noChildrenText}>
            {t('Administration.UserEditingChildren.There is no child added to this kita')}
          </div>
          {allowParentsAddingChild && (
              <Button onClick={startNewCreation} className={styles.addKidBtn}>
                {t('Administration.UserEditingChildren.Add child')}
              </Button>
          )}
          <Button onClick={addPerKidCode}>
            {t('Administration.UserEditingChildren.Add per Kid Code')}
          </Button>
        </div>
      ) : kid && !isCreation ? (
        <div className={styles.container}>
          <table>
            <thead>
              <tr>
                <th className={cn(styles.headerText, styles.headerTextWithThePadding)}>
                  <div className={styles.headerTextWrapper}>{t('Child.Name')}</div>
                </th>
                <th className={cn(styles.headerText)}>
                  <div className={styles.headerTextWrapper}>{t('Child.Status')}</div>
                </th>
                <th className={cn(styles.headerText)}>
                  <div className={styles.headerTextWrapper}>{t('Child.KidCode')}</div>
                </th>
                <th className={cn(styles.headerText)}>
                  <div className={styles.headerTextWrapper}>{t('Child.Edit')}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {kids.map(kidsEntity => (
                <tr>
                  <td>
                    <div className={cn(styles.itemName, 'notranslate')}>
                      {kidsEntity.name || `${kidsEntity.firstName} ${kidsEntity.lastName}`}
                    </div>
                  </td>
                  <td className={styles.itemInfo}>
                    <div className={cn(styles.itemName, 'notranslate')}>
                      {!kidsEntity.deactivated
                        ? kidsEntity.parent1Status === 'approved'
                          ? t('Child.Approved')
                          : kidsEntity.parent1Status === 'rejected'
                          ? t('Child.Rejected')
                          : t('Child.Pending')
                        : t('deactivated')}
                    </div>
                  </td>
                  <td>
                    {kidsEntity.kidCode ? (
                      <strong>{kidsEntity.kidCode}</strong>
                    ) : (
                      t('Kid.No kid code available')
                    )}
                  </td>
                  <td className={styles.itemInfo}>
                    <Button
                      onClick={() => startCreation(kidsEntity)}
                      type='primary'
                      className={styles.button}
                    >
                      <i className='fa fa-edit' />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <div>
            <br />
            {allowParentsAddingChild && (
              <Button onClick={startNewCreation} className={styles.addKidBtn}>
                {t('Administration.UserEditingChildren.Add child')}
              </Button>
            )}
            <Button onClick={addPerKidCode}>
              {t('Administration.UserEditingChildren.Add per Kid Code')}
            </Button>
          </div>
          <Popup
            isOpened={isPopupVisible}
            closePopup={handleClosePopUp}
            header={t('Kid.Your kid has been added header')}
            body={
              <div>
                {t('Kid.Your kid has been added')}
                <p>{t('Kid.Kid Code hint')}</p>
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.UserSection.KidCode')}</Label>
                  <div className={styles.kidCode}>
                    <strong>{creationKidCode}</strong>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ) : kid && isCreation ? (
        <div>
          {isDeletePopupVisible && (
            <DeletePopup
              isOpened={isDeletePopupVisible}
              closePopup={() => setIsDeletePopupVisible(false)}
              onDeleteBtnClick={deleteChild}
              headerText={t('Administration.UserSection.Confirm Delete Child')}
              bodyText={t('Administration.UserSection.Do you really want to delete this child?')}
              isSubmiting={isDeletionOngoing}
            />
          )}
          {kitaFields?.firstName.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.First name')} *</Label>
              <Input
                error={firstNameError}
                value={kid.firstName}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, firstName: e.target.value })}
                disabled={isFieldDisabled('firstName')}
              />
              {firstNameError && (
                <div className={styles.error}>
                  <InputErrorMsg errorMsg={firstNameError} />
                </div>
              )}
            </div>
          )}
          {kitaFields?.lastName.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.Last name')} *</Label>
              <Input
                error={lastNameError}
                value={kid.lastName}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, lastName: e.target.value })}
                disabled={isFieldDisabled('lastName')}
              />
              {lastNameError && (
                <div className={styles.error}>
                  <InputErrorMsg errorMsg={lastNameError} />
                </div>
              )}
            </div>
          )}
          {kitaFields?.gender?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.Gender')}</Label>
              <Select
                options={genderOptions}
                selected={selectedGender}
                placeholder={t('Administration.UserSection.Please select')}
                onSelect={onSelectedGenderChanged}
                disabled={isFieldDisabled('gender')}
              />
            </div>
          )}
          {/*<div className={styles.formGroup}>
            <Checkbox
              isChecked={kid.enableBirthdayNotification}
              onChange={() =>
                setKid({ ...kid, enableBirthdayNotification: !kid.enableBirthdayNotification })
              }
              name={t('Administration.UserSection.EnableBirthdayNotification')}
            />
            </div>*/}
          {kitaFields?.street?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.Street')}</Label>
              <Input
                value={kid.street}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, street: e.target.value })}
                disabled={isFieldDisabled('street')}
              />
            </div>
          )}
          {kitaFields?.zip?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.Zip')}</Label>
              <Input
                value={kid.zip}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, zip: e.target.value })}
                disabled={isFieldDisabled('zip')}
              />
            </div>
          )}
          {kitaFields?.city?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.City')}</Label>
              <Input
                value={kid.city}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, city: e.target.value })}
                disabled={isFieldDisabled('city')}
              />
            </div>
          )}
          {kitaFields?.country?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.Country')}</Label>
              <Select
                options={countryOptions}
                selected={selectedCountry}
                placeholder={t('Administration.UserSection.Please select')}
                onSelect={onSelectedCountryChanged}
                openedClassName={styles.select}
                disabled={isFieldDisabled('country')}
              />
            </div>
          )}
          {kitaFields?.group?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>
                {t('Administration.UserSection.Group')} {groupMandatoryField && <>*</>}
              </Label>
              <Select
                options={groupOptions}
                error={groupError}
                selected={selectedGroup}
                onSelect={onSelectedGroupChanged}
                openedClassName={styles.select}
                disabled={isFieldDisabled('group')}
              />
              {groupError && (
                <div className={styles.error}>
                  <InputErrorMsg errorMsg={groupError} />
                </div>
              )}
            </div>
          )}

          {kitaFields?.mainGroup?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.Main Group')}</Label>
              <Select
                options={groupOptions}
                selected={selectedMainGroup}
                onSelect={onSelectedMainGroupChanged}
                openedClassName={styles.select}
                disabled={isFieldDisabled('mainGroup')}
              />
            </div>
          )}

          {isHortType && (
            <>
              {kitaFields?.className?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.KidSection.Class')}</Label>
                  <Input
                    value={kid.className}
                    type='text'
                    maxLength={255}
                    onChange={e => setKid({ ...kid, className: e.target.value })}
                    disabled={isFieldDisabled('className')}
                  />
                </div>
              )}
              {kitaFields?.schoolName?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.KidSection.School Name')}</Label>
                  <Input
                    value={kid.schoolName}
                    type='text'
                    maxLength={255}
                    onChange={e => setKid({ ...kid, schoolName: e.target.value })}
                    disabled={isFieldDisabled('schoolName')}
                  />
                </div>
              )}
            </>
          )}
          {kitaFields?.state?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.State')}</Label>
              <Input
                value={kid.state}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, state: e.target.value })}
                disabled={isFieldDisabled('state')}
              />
            </div>
          )}
          {kitaFields?.birthPlace?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.KidSection.BirthPlace')}</Label>
              <Input
                value={kid.birthPlace}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, birthPlace: e.target.value })}
                disabled={isFieldDisabled('birthPlace')}
              />
            </div>
          )}

          {kitaFields?.kidLanguage?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.KidSection.KidLanguage')}</Label>
              <Input
                value={kid.kidLanguage}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, kidLanguage: e.target.value })}
                disabled={isFieldDisabled('kidLanguage')}
              />
            </div>
          )}
          {kitaFields?.bookingTimeCategory?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.KidSection.BookingTimeCategory')}</Label>
              <Select
                options={bookingTimeCategories}
                selected={selectedBookingTimeCategorie}
                placeholder={t('Administration.UserSection.Please select')}
                onSelect={onSelectedBookingTimeChanged}
                disabled={isFieldDisabled('bookingTimeCategory')}
              />
            </div>
          )}
          {kitaFields?.requestForCareDate?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.UserSection.RequestForCareFrom')}</Label>
              <DatePicker
                onChange={requestForCareDate => {
                  setKid({ ...kid, requestForCareDate });
                }}
                selected={kid.requestForCareDate && new Date(kid.requestForCareDate)}
                customInput={<Input />}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                langCode={user.langCode}
                disabled={isFieldDisabled('requestForCareDate')}
              />
            </div>
          )}
          {kitaFields?.ageGroupAtCareStartDate?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.KidSection.AgeGroupAtCareStartDate')}</Label>
              <Input
                value={kid.ageGroupAtCareStartDate}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, ageGroupAtCareStartDate: e.target.value })}
                disabled={isFieldDisabled('ageGroupAtCareStartDate')}
              />
            </div>
          )}
          {kitaFields?.denomination?.isShownOnKidProfile && (
            <div className={styles.formGroup}>
              <Label type='input'>{t('Administration.KidSection.Denomination')}</Label>
              <Input
                value={kid.denomination}
                type='text'
                maxLength={255}
                onChange={e => setKid({ ...kid, denomination: e.target.value })}
                disabled={isFieldDisabled('denomination')}
              />
            </div>
          )}
          {kitaId !== 2855 && kitaId !== 113 && (
            <>
              {kitaFields?.careReqestStatus?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.KidSection.CareRequestStatus')}</Label>
                  <Select
                    options={careRequestStatuses}
                    selected={selectedCareRequestStatus}
                    placeholder={t('Administration.UserSection.Please select')}
                    onSelect={onSelectedCareRequestStatusChanged}
                    disabled={isFieldDisabled('careReqestStatus')}
                  />
                </div>
              )}
              {kitaFields?.switchingChild?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.KidSection.SwitchingChild')}</Label>
                  <Select
                    options={switchingChildStatuses}
                    selected={selectedSwitchingChildStatus}
                    placeholder={t('Administration.UserSection.Please select')}
                    onSelect={onSelectedSwitchingChildStatusChanged}
                    disabled={isFieldDisabled('switchingChild')}
                  />
                </div>
              )}
              {kitaFields?.contractStartDate?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.KidSection.ContractStart')}</Label>
                  <DatePicker
                    onChange={contractStartDate => {
                      setKid({ ...kid, contractStartDate });
                    }}
                    selected={kid.contractStartDate && new Date(kid.contractStartDate)}
                    customInput={<Input />}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    langCode={user.langCode}
                    disabled={isFieldDisabled('contractStartDate')}
                  />
                </div>
              )}
              {kitaFields?.contractEndDate?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.KidSection.ContractEnd')}</Label>
                  <DatePicker
                    onChange={contractEndDate => {
                      setKid({ ...kid, contractEndDate });
                    }}
                    selected={kid.contractEndDate && new Date(kid.contractEndDate)}
                    customInput={<Input />}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    langCode={user.langCode}
                    disabled={isFieldDisabled('contractEndDate')}
                  />
                </div>
              )}
              {kitaFields?.measlesVaccinationStatus?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>
                    {t('Administration.KidSection.MeaslesVaccinationStatus')}
                  </Label>
                  <RadioButton
                    checked={kid.measlesVaccinationStatus === 'Yes'}
                    onClick={() => setKid({ ...kid, measlesVaccinationStatus: 'Yes' })}
                    label={t('Administration.KidSection.MeaslesYes')}
                    disabled={isFieldDisabled('measlesVaccinationStatus')}
                  />
                  <RadioButton
                    checked={kid.measlesVaccinationStatus === 'No'}
                    onClick={() => setKid({ ...kid, measlesVaccinationStatus: 'No' })}
                    label={t('Administration.KidSection.MeaslesNo')}
                    disabled={isFieldDisabled('measlesVaccinationStatus')}
                  />
                </div>
              )}
              {kitaFields?.measlesVaccinationDate?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>
                    {t('Administration.KidSection.MeaslesVaccinationDate')}
                  </Label>
                  <DatePicker
                    onChange={measlesVaccinationDate => {
                      setKid({ ...kid, measlesVaccinationDate });
                    }}
                    selected={kid.measlesVaccinationDate && new Date(kid.measlesVaccinationDate)}
                    customInput={<Input />}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    langCode={user.langCode}
                    disabled={isFieldDisabled('measlesVaccinationDate')}
                  />
                </div>
              )}
              {kitaFields?.birthday?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.UserSection.Birthday')}</Label>
                  <DatePicker
                    onChange={birthday => {
                      setKid({ ...kid, birthday });
                    }}
                    selected={kid.birthday && new Date(kid.birthday)}
                    customInput={<Input />}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    langCode={user.langCode}
                    maxDate={new Date()}
                    disabled={isFieldDisabled('birthday')}
                  />
                </div>
              )}

              {kitaFields?.allergies?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.UserSection.Allergies')}</Label>
                  <TextArea
                    value={kid.allergies}
                    onChange={e => setKid({ ...kid, allergies: e.target.value })}
                    disabled={isFieldDisabled('allergies')}
                  />
                </div>
              )}
              {kitaFields?.medication?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.UserSection.Medication')}</Label>
                  <TextArea
                    value={kid.medication}
                    onChange={e => setKid({ ...kid, medication: e.target.value })}
                    disabled={isFieldDisabled('medication')}
                  />
                </div>
              )}
              {kitaFields?.foodIntolerance?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.UserSection.FoodIntolerance')}</Label>
                  <TextArea
                    value={kid.foodIntolerance}
                    onChange={e => setKid({ ...kid, foodIntolerance: e.target.value })}
                    disabled={isFieldDisabled('foodIntolerance')}
                  />
                </div>
              )}
              {kitaFields?.allowedFoodOrdersPerWeek?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>
                    {t('Administration.KidSection.NumberOfAllowedFoodOrdersPerWeek')}
                  </Label>
                  <Select
                    options={allowedFoodOrdersPerWeekOptions}
                    selected={selectedFoodOrderNumber}
                    placeholder={t('Administration.UserSection.Please select')}
                    onSelect={onSelectedFoodOrderNumberChanged}
                    disabled={isFieldDisabled('allowedFoodOrdersPerWeek')}
                  />
                </div>
              )}
              {kitaFields?.about?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Label type='input'>{t('Administration.UserSection.About')}</Label>
                  <TextArea
                    value={kid.about}
                    maxLength={512}
                    onChange={e => setKid({ ...kid, about: e.target.value })}
                    disabled={isFieldDisabled('about')}
                  />
                </div>
              )}
            </>
          )}
          <table>
            <tbody>
              {kid.parent2 && kitaFields?.kidParent?.isShownOnKidProfile && (
                <tr>
                  <td>
                    <Label type='input'>{t('Administration.UserSection.Kid Parent')}</Label>
                    <div className={styles.avatarContainer}>
                      <div className={styles.avatar}>
                        <ProfileAvatar
                          id={
                            kid.parent2.userKita.userId === user.id
                              ? kid.parent1.userKita.userId
                              : kid.parent2.userKita.userId
                          }
                        />
                      </div>
                      <div className={styles.avatarName}>
                        {kid.parent2.userKita.userId === user.id
                          ? kid.parent1.firstName + ' ' + kid.parent1.lastName
                          : kid.parent2.firstName + ' ' + kid.parent2.lastName}
                      </div>
                    </div>
                  </td>
                  <td>
                    {kid.parent2.userKita.userId === user.id ? (
                      <Checkbox
                        isChecked={kid.parent1Custody}
                        onChange={() => setKid({ ...kid, parent1Custody: !kid.parent1Custody })}
                        name={t('Administration.KidSection.Parent has custody')}
                        isDisabled={isFieldDisabled('parentHasCustody')}
                      />
                    ) : (
                      <Checkbox
                        isChecked={kid.parent2Custody}
                        onChange={() => setKid({ ...kid, parent2Custody: !kid.parent2Custody })}
                        name={t('Administration.KidSection.Parent has custody')}
                        isDisabled={isFieldDisabled('parentHasCustody')}
                      />
                    )}
                  </td>
                  <td>
                    {kid.parent2.userKita.userId !== user.id && (
                      <div>
                        <Button onClick={deleteParent} type='danger' disabled={isFieldDisabled()}>
                          {t('Adminstration.UserSection.Delete Parent')}{' '}
                          <i className='fa fa-times' />
                        </Button>
                        {deleteParentMessage && <p className={styles.red}>{deleteParentMessage}</p>}
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {kid.parent1 && kitaFields?.kidParent?.isShownOnKidProfile && (
                <tr>
                  <td>
                    <div className={styles.avatarContainer}>
                      <div className={styles.avatar}>
                        <ProfileAvatar id={user.id} />
                      </div>
                      <div className={styles.avatarName}>
                        {kid.parent1.userKita.userId === user.id
                          ? kid.parent1.firstName + ' ' + kid.parent1.lastName
                          : kid.parent2.firstName + ' ' + kid.parent2.lastName}
                      </div>
                    </div>
                  </td>
                  <td>
                    {kitaFields?.parentHasCustody?.isShownOnKidProfile &&
                      (kid.parent1.userKita.userId === user.id ? (
                        <Checkbox
                          isChecked={kid.parent1Custody}
                          onChange={() => setKid({ ...kid, parent1Custody: !kid.parent1Custody })}
                          name={t('Administration.KidSection.Parent has custody')}
                          isDisabled={isFieldDisabled('parentHasCustody')}
                        />
                      ) : (
                        <Checkbox
                          isChecked={kid.parent2Custody}
                          onChange={() => setKid({ ...kid, parent2Custody: !kid.parent2Custody })}
                          name={t('Administration.KidSection.Parent has custody')}
                          isDisabled={isFieldDisabled('parentHasCustody')}
                        />
                      ))}
                  </td>
                  <td />
                </tr>
              )}
            </tbody>
          </table>

          {!isAwobamberg && kitaId !== 2855 && kitaId !== 113 && (
            <>
              {kitaFields?.participateInExcursionsAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.participateInExcursionsAllowed}
                    onChange={() =>
                      setKid({
                        ...kid,
                        participateInExcursionsAllowed: !kid.participateInExcursionsAllowed,
                      })
                    }
                    name={t('Administration.KidSection.ParticipateInExcursionsAllowed')}
                    isDisabled={isFieldDisabled('participateInExcursionsAllowed')}
                  />
                </div>
              )}

              {kitaFields?.exchangeWithOtherInstitutionsAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.exchangeWithOtherInstitutionsAllowed}
                    onChange={() =>
                      setKid({
                        ...kid,
                        exchangeWithOtherInstitutionsAllowed: !kid.exchangeWithOtherInstitutionsAllowed,
                      })
                    }
                    name={t('Administration.KidSection.ExchangeWithOtherInstitutionsAllowed')}
                    isDisabled={isFieldDisabled('exchangeWithOtherInstitutionsAllowed')}
                  />
                </div>
              )}

              {kitaFields?.photosAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.photosAllowed}
                    onChange={() => setKid({ ...kid, photosAllowed: !kid.photosAllowed })}
                    name={t('Administration.KidSection.PhotosAllowed')}
                    isDisabled={isFieldDisabled('photosAllowed')}
                  />
                </div>
              )}

              {kitaFields?.showPhotosInInstitutionAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.showPhotosInInstitutionAllowed}
                    onChange={() =>
                      setKid({
                        ...kid,
                        showPhotosInInstitutionAllowed: !kid.showPhotosInInstitutionAllowed,
                      })
                    }
                    name={t('Administration.KidSection.ShowPhotosInInstitutionAllowed')}
                    isDisabled={isFieldDisabled('showPhotosInInstitutionAllowed')}
                  />
                </div>
              )}

              {kitaFields?.usePhotosInPortfolioAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.usePhotosInPortfolioAllowed}
                    onChange={() =>
                      setKid({
                        ...kid,
                        usePhotosInPortfolioAllowed: !kid.usePhotosInPortfolioAllowed,
                      })
                    }
                    name={t('Administration.KidSection.UsePhotosInPortfolioAllowed')}
                    isDisabled={isFieldDisabled('usePhotosInPortfolioAllowed')}
                  />
                </div>
              )}

              {kitaFields?.usePhotosInOtherChildsPortfolioAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.usePhotosInOtherChildsPortfolioAllowed}
                    onChange={() =>
                      setKid({
                        ...kid,
                        usePhotosInOtherChildsPortfolioAllowed: !kid.usePhotosInOtherChildsPortfolioAllowed,
                      })
                    }
                    name={t('Administration.KidSection.UsePhotosInOtherChildsPortfolioAllowed')}
                    isDisabled={isFieldDisabled('usePhotosInOtherChildsPortfolioAllowed')}
                  />
                </div>
              )}

              {kitaFields?.usePhotosForAdvancedEducationAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.usePhotosForAdvancedEducationAllowed}
                    onChange={() =>
                      setKid({
                        ...kid,
                        usePhotosForAdvancedEducationAllowed: !kid.usePhotosForAdvancedEducationAllowed,
                      })
                    }
                    name={t('Administration.KidSection.UsePhotosForAdvancedEducationAllowed')}
                    isDisabled={isFieldDisabled('usePhotosForAdvancedEducationAllowed')}
                  />
                </div>
              )}

              {kitaFields?.exchangeWithElementarySchoolAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.exchangeWithElementarySchoolAllowed}
                    onChange={() =>
                      setKid({
                        ...kid,
                        exchangeWithElementarySchoolAllowed: !kid.exchangeWithElementarySchoolAllowed,
                      })
                    }
                    name={t('Administration.KidSection.ExchangeWithElementarySchoolAllowed')}
                    isDisabled={isFieldDisabled('exchangeWithElementarySchoolAllowed')}
                  />
                </div>
              )}

              {kitaFields?.pickupBySiblingAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.pickupBySiblingAllowed}
                    onChange={() =>
                      setKid({ ...kid, pickupBySiblingAllowed: !kid.pickupBySiblingAllowed })
                    }
                    name={t('Administration.KidSection.PickupBySiblingAllowed')}
                    isDisabled={isFieldDisabled('pickupBySiblingAllowed')}
                  />
                </div>
              )}

              {kitaFields?.noNDAWithDoctor?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.noNDAWithDoctor}
                    onChange={() => setKid({ ...kid, noNDAWithDoctor: !kid.noNDAWithDoctor })}
                    name={t('Administration.KidSection.NoNDAWithDoctor')}
                    isDisabled={isFieldDisabled('noNDAWithDoctor')}
                  />
                </div>
              )}

              {kitaFields?.noNDAWithSpecialService?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.noNDAWithSpecialService}
                    onChange={() =>
                      setKid({ ...kid, noNDAWithSpecialService: !kid.noNDAWithSpecialService })
                    }
                    name={t('Administration.KidSection.NoNDAWithSpecialService')}
                    isDisabled={isFieldDisabled('noNDAWithSpecialService')}
                  />
                </div>
              )}
              {!window.location.hostname
                .toLowerCase()
                .includes('diakoniehasenbergl-elternapp.safe2connect.org') && (
                <>
                  {kitaFields?.beeStingerRemovalForbidden?.isShownOnKidProfile && (
                    <div className={styles.formGroup}>
                      <Checkbox
                        isChecked={kid.beeStingerRemovalForbidden}
                        onChange={() =>
                          setKid({
                            ...kid,
                            beeStingerRemovalForbidden: !kid.beeStingerRemovalForbidden,
                          })
                        }
                        name={t('Administration.KidSection.BeeStingerRemovalForbidden')}
                        isDisabled={isFieldDisabled('beeStingerRemovalForbidden')}
                      />
                    </div>
                  )}

                  {kitaFields?.contactParentsAfterBeeStingerRemoval?.isShownOnKidProfile && (
                    <div className={styles.formGroup}>
                      <Checkbox
                        isChecked={kid.contactParentsAfterBeeStingerRemoval}
                        onChange={() =>
                          setKid({
                            ...kid,
                            contactParentsAfterBeeStingerRemoval: !kid.contactParentsAfterBeeStingerRemoval,
                          })
                        }
                        name={t('Administration.KidSection.ContactParentsAfterBeeStingerRemoval')}
                        isDisabled={isFieldDisabled('contactParentsAfterBeeStingerRemoval')}
                      />
                    </div>
                  )}

                  {kitaFields?.beeStingerRemovalAllowed?.isShownOnKidProfile && (
                    <div className={styles.formGroup}>
                      <Checkbox
                        isChecked={kid.beeStingerRemovalAllowed}
                        onChange={() =>
                          setKid({
                            ...kid,
                            beeStingerRemovalAllowed: !kid.beeStingerRemovalAllowed,
                          })
                        }
                        name={t('Administration.KidSection.BeeStingerRemovalAllowed')}
                        isDisabled={isFieldDisabled('beeStingerRemovalAllowed')}
                      />
                    </div>
                  )}
                </>
              )}
              {kitaFields?.forwardingAddressAndPhoneNumberAllowed?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.forwardingAddressAndPhoneNumberAllowed}
                    onChange={() =>
                      setKid({
                        ...kid,
                        forwardingAddressAndPhoneNumberAllowed: !kid.forwardingAddressAndPhoneNumberAllowed,
                      })
                    }
                    name={t('Administration.KidSection.ForwardingAddressAndPhoneNumberAllowed')}
                    isDisabled={isFieldDisabled('forwardingAddressAndPhoneNumberAllowed')}
                  />
                </div>
              )}

              {kitaFields?.kidWithSpecialNeeds?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.kidWithSpecialNeeds}
                    onChange={() =>
                      setKid({ ...kid, kidWithSpecialNeeds: !kid.kidWithSpecialNeeds })
                    }
                    name={t('Administration.KidSection.KidWithSpecialNeeds')}
                    isDisabled={isFieldDisabled('kidWithSpecialNeeds')}
                  />
                </div>
              )}

              {kitaFields?.kidCanGoHomeOnTheirOwn?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.kidCanGoHomeOnTheirOwn}
                    onChange={() =>
                      setKid({ ...kid, kidCanGoHomeOnTheirOwn: !kid.kidCanGoHomeOnTheirOwn })
                    }
                    name={t('Administration.KidSection.kidCanGoHomeOnTheirOwn')}
                    isDisabled={isFieldDisabled('kidCanGoHomeOnTheirOwn')}
                  />
                </div>
              )}

              {kitaFields?.kidComesEarly?.isShownOnKidProfile && (
                <div className={styles.formGroup}>
                  <Checkbox
                    isChecked={kid.kidComesEarly}
                    onChange={() => setKid({ ...kid, kidComesEarly: !kid.kidComesEarly })}
                    name={t('Administration.KidSection.kidComesEarly')}
                    isDisabled={isFieldDisabled('kidComesEarly')}
                  />
                </div>
              )}

              {
                customFields.map(field=>(
                  <div className={styles.formGroup}>
                    <Checkbox
                      isChecked={field.value}
                      onChange={() => onUpdateCustomKidValue(field)}
                      name={field.fieldName}
                      isDisabled={isCustomFieldDisabled(field.id)}
                    />
                  </div>
                ))
              }
            </>
          )}
          <br />
          {kitaId !== 2855 && kitaId !== 113 && kitaFields?.bookingTimes?.isShownOnKidProfile && (
            <BookingTimes setKid={setKid} kid={kid} disabled={isFieldDisabled('bookingTimes')} />
          )}
          <br />
          {kitaId !== 2855 && kitaId !== 113 && kitaFields?.holidayTimes?.isShownOnKidProfile && (
            <HolidayTimes
              kidId={kid.id}
              kitaId={kitaId}
              holidayTimes={holidayTimes}
              setHolidayTimes={setHolidayTimes}
              disabled={isFieldDisabled('holidayTimes')}
            />
          )}
          <br />
          {!isAwobamberg &&
            kitaId !== 2855 &&
            kitaId !== 113 &&
            kitaFields?.foodOrderedOverview?.isShownOnKidProfile && (
              <ChildFoodOrderedTable
                setKid={setKid}
                kid={kid}
                disabled={isFieldDisabled('foodOrderedOverview')}
              />
            )}

          <br />

          {kid.id && kitaFields?.authorizedPeople?.isShownOnKidProfile && (
            <div style={{ marginBottom: '2em' }}>
              <AuthorizedPeople
                kidId={kid.id}
                disableEditing={isFieldDisabled('authorizedPeople')}
              />
            </div>
          )}

          {kid.id && kid.kidCode && kitaFields?.kidCode?.isShownOnKidProfile && (
            <>
              <div className={styles.formGroup}>
                <Label type='input'>{t('Administration.UserSection.KidCode')}</Label>
                <div className={styles.kidCode}>
                  <strong>{kid.kidCode}</strong>
                </div>
              </div>
              <br />
            </>
          )}

          {kid.id && kid.adebisID && kitaFields?.adebisID?.isShownOnKidProfile && (
            <>
              <div className={styles.formGroup}>
                <Label type='input'>{t('Administration.UserSection.AdebisID')}</Label>
                <div className={styles.kidCode}>
                  <strong>{kid.adebisID}</strong>
                </div>
              </div>
              <br />
            </>
          )}
          {!window.location.hostname.toLowerCase().includes('gfz-app.ch') &&
            !isAwobamberg &&
            kitaId !== 2855 &&
            kitaId !== 113 &&
            kitaFields?.verificationPicture?.isShownOnKidProfile && (
              <>
                <b>{t('Administration.KidSection.Verification Images')}</b>
                <div className={styles.verificationImages}>
                  {verificationImages.map(verificationImage => (
                    <img
                      key={verificationImage.id}
                      src={verificationImage.url}
                      alt=''
                      onClick={() => setSelectedImage(verificationImage)}
                      className={verificationImage.id === selectedImage?.id && styles.selectedImage}
                      width={verificationImage.width}
                    />
                  ))}
                </div>
              </>
            )}
          <br />
          {!window.location.hostname.toLowerCase().includes('gfz-app.ch') &&
            kitaFields?.qrCode?.isShownOnKidProfile && (
              <div className={styles.QRbuttonDiv}>
                <Button onClick={generatePDF} type='primary' className={styles.QRbutton}>
                  {t('Administration.Generate QR Code')}
                </Button>
                <div className={styles.QR}>
                  <QRCodeSVG size='64' />
                </div>
              </div>
            )}
          <br />
          {groupError && (
            <div className={styles.error}>
              <InputErrorMsg errorMsg={groupError} />
            </div>
          )}
          <div className={styles.footer}>
            <ButtonWithLoader
              type='primary'
              isLoading={isSubmiting}
              onClick={submit}
              className={styles.button}
              disabled={disableEditing}
            >
              {t('Post.Save')}
            </ButtonWithLoader>
            {kid && kid.id ? (
              <>
                {!isCreation && (
                  <Button onClick={startNewCreation} type='primary' disabled={disableEditing}>
                    {t('Administration.UserSection.Add another child')}
                  </Button>
                )}
                <Button
                  onClick={() => setIsDeletePopupVisible(true)}
                  type='danger'
                  className={styles.button}
                  disabled={disableEditing}
                >
                  {t('Administration.UserSection.Delete child')}
                </Button>
                <Button
                  onClick={deactivateChild}
                  type='danger'
                  className={styles.button}
                  disabled={disableEditing}
                >
                  {kid.deactivated
                    ? t('Administration.UserSection.Activate Child')
                    : t('Administration.UserSection.Deactivate Child')}
                </Button>
              </>
            ) : null}
            {/* kids.length > 1 && !isCreation && (      //Auskommentieren wenn man die Liste nicht braucht
            <Pagination
              currentPage={page}
              history={history}
              numberOfPages={kids.length}
              onPageClick={setPage}
            />
            ) */}
          </div>
        </div>
      ) : null}
    </div>
  );
}
