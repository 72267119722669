import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getAllKitasWithAllData } from 'library/api/kita';
import { createPost } from 'library/api/posts';
import { isAwoWW } from 'library/api/tenantConfig';
import DragAndDrop from 'library/common/commonComponents/DragAndDrop';
import Card from 'library/common/commonComponents/Card';
import Label from 'library/common/commonComponents/Label';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import FilePrewiev from 'library/common/commonComponents/FilePrewiev';
import useEditor from 'library/common/commonHooks/useEditor';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import useMultipleUpload from 'library/common/commonHooks/useMultipleUpload';
import PublicGroupPostPopup from 'library/common/commonComponents/Popups/PublicGroupPostPopup';
import useBlockShowing from 'library/common/commonHooks/useBlockShowing';
import ProviderPostPopup from 'library/common/commonComponents/Popups/ProviderPostPopup';
import Dictaphone from 'library/common/commonComponents/Dictaphone';
import RecordButton from 'library/common/commonComponents/Buttons/RecordButton/RecordButtonContainer';
import PublicationTimeButton from 'library/common/commonComponents/Buttons/PublicationTimeButton';
import { checkLink } from 'library/utilities/checkLink';
import { checkIsProviderRelevant } from 'library/utilities/groups';
import { handleRNMessageHelper } from 'library/utilities/handleRNMessageHelper';
import { VOICE_MESSAGE_AVAILABLE_APPS } from 'main/config';

import styles from './createPostInput.module.scss';
import PostLoadTemplateButton from '../../Buttons/PostLoadTemplateButton';
import Checkbox from '../../Checkbox';

export default function CreatePostInput({
  user,
  groupId,
  group = {},
  onCreate,
  isInProfileStream,
  showBottomNotification,
  activeKita,
}) {
  const canBePublic = false; // getCanCreatePublicPost(group) || isInProfileStream;
  const isPortfolioGroup = group && group.groupName && group.groupName.slice(-11) === '(Portfolio)';
  const isSbMasterclass = window.location.hostname
    .toLowerCase()
    .includes('sbmasterclass.safe2connect.org');
  const { t } = useTranslation();

  const { blockClassNames, showBlock, hideBlock } = useBlockShowing();
  const { files, uploadFile, deleteFiles, setFiles } = useMultipleUpload();
  const [popupOpened, setPopupOpened] = useState(false);

  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(false);

  const [isPublic, setIsPublic] = useState(false);
  const [requestReturnReceipt, setRequestReturnReceipt] = useState(isPortfolioGroup);
  const [publicVisible, setPublicVisible] = useState(isPortfolioGroup);

  const [publicationTime, setPublicationTime] = useState(null);

  const [isVoiceMessageAvailable, setIsVoiceMessageAvailable] = useState(
    user.administrationAccess || user.employee || isSbMasterclass,
  );

  useEffect(() => {
    if (!canBePublic) {
      // setIsPublic(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canBePublic]);

  const [providers, dispatchProviders] = useReducer(providerReducer, []);
  const [openPopup, setOpenPopup] = useState(false);
  let isProviderRelevant = checkIsProviderRelevant(activeKita, group);

  useEffect(() => {
    if (isProviderRelevant) {
      getAllKitasWithAllData().then(res => {
        // eslint-disable-next-line no-unused-vars
        const kitaArray = res.data.content.map(kita => {
          return {
            id: kita.id,
            label: kita.kitaName,
            providerId: kita.providerId,
            selected: false,
            kitaProviderStatus: kita.kitaProviderStatus,
          };
        });
        const providerArray = [];

        // eslint-disable-next-line array-callback-return
        kitaArray.map(kita => {
          if (kita.providerId === activeKita.kitaId && kita.kitaProviderStatus === 'approved') {
            providerArray.push(kita);
          }
        });
        dispatchProviders({ type: 'set', providers: providerArray });
      });
    }
  }, []);

  const checkVoiceMessageAvailability = rnEvent => {
    if (!rnEvent || !rnEvent.data) {
      return;
    }

    setIsVoiceMessageAvailable(
      user.administrationAccess ||
        user.employee ||
        VOICE_MESSAGE_AVAILABLE_APPS.includes(rnEvent.data) ||
        isSbMasterclass,
    );
  };

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      return;
    }

    [window, document].forEach(el => {
      el.addEventListener(
        'message',
        handleRNMessageHelper('native.projectId', checkVoiceMessageAvailability),
      );
    });

    return () => {
      [window, document].forEach(el => {
        el.removeEventListener(
          'message',
          handleRNMessageHelper('native.projectId', checkVoiceMessageAvailability),
        );
      });
    };
  }, [window.ReactNativeWebView]);

  const { Editor, getEditorValue, insText, updateValue } = useEditor({
    placeholder: t("Editor.What's on your mind?"),
    onChange: () => {
      const value = getEditorValue();
      if (value.textContent.trim() !== '' && !value.querySelector('img')) {
        setError(null);
      }
    },
    groupId,
  });

  const moreBtnDropdownOptions = [
    {
      faIcon: isPublic ? 'fa-lock' : 'fa-unlock',
      title: isPublic ? t('Post.Make private') : t('Post.Make public'),
      onClick() {
        setIsPublic(!isPublic);
      },
      isHidden: !canBePublic,
    },
  ];

  const [progress, setProgress] = useState(null);

  async function popUp() {
    setOpenPopup(true);
  }

  async function onSubmit() {
    if (isSending) {
      return;
    }

    const editorValue = getEditorValue();

    if (editorValue.textContent.trim() === '' && !editorValue.querySelector('img')) {
      return setError(t('Post.Message cannot be blank'));
    }

    setError(null);
    setIsSending(true);
    try {
      let receivers = [];
      if (providers) {
        receivers = providers.filter(provider => provider.selected).map(provider => provider.id);
      }
      const post = {
        files,
        groupId: groupId || 0,
        text: checkLink(editorValue),
        privatePost: false,
        postType: 0,
        receivers,
        requestReturnReceipt,
        publicVisible,
      };

      if (publicationTime) {
        post.publicationTime = new Date(publicationTime).getTime();
      }

      const { data } = await createPost(post, setProgress);
      const date = new Date().getTime();
      setProgress(null);
      if (onCreate) {
        onCreate({
          ...post,
          createdAt: date,
          editedAt: date,
          comments: [],
          likes: [],
          files: data.files,
          id: data.id,
          userId: user.id,
          noRepeatAfterError: true,
          notify: data.notify,
          pinned: false,
          isTranslationAllowed: data.isTranslationAllowed,
          returnReceipt: requestReturnReceipt,
          likeFeatureEnabled: data.likeFeatureEnabled
        });
      }

      deleteFiles();
      hideBlock();
      setPublicationTime(null);
      updateValue(document.createElement('div'));
    } catch (ex) {
      // eslint-disable-next-line
      if (ex && ex.response && ex.response.status === 413) {
        showBottomNotification(t('FileManager.Size error'), { isFail: true });
      } else if (ex && ex.response && ex.response.data && ex.response.data.message) {
        setError(t(ex.response.data.message));
      } else {
        setError('Something went wrong. Try again later');
      }
      setProgress(null);
    } finally {
      setRequestReturnReceipt(false);
      setIsSending(false);
      setOpenPopup(false);
      setPublicVisible(false);
    }
  }

  return (
    <div>
      <Card onClick={showBlock} onFocus={showBlock}>
        <DragAndDrop onDrop={uploadFile}>
          <div className={styles.body}>
            <Editor />
            {error && <div className={styles.error}>{error}</div>}
            <div className={classNames(...blockClassNames)}>
              <div className={styles.publicVisible}>
                {isAwoWW() && (
                  <Checkbox
                    className={styles.checkbox}
                    onChange={() => setPublicVisible(state => !state)}
                    name={t('Post.MakePublicVisible')}
                    isChecked={publicVisible}
                  />
                )}
              </div>
              <div className={styles.buttons}>
                <div className={styles.buttonsLeft}>
                  {isProviderRelevant && (
                    <ButtonWithLoader type='info' onClick={popUp} className={styles.postButton}>
                      <i className='fa fa-paper-plane' />
                    </ButtonWithLoader>
                  )}
                  {!isProviderRelevant && (
                    <ButtonWithLoader
                      type='info'
                      onClick={() => {
                        if (group && group.globalPublicGroup && group.globalPublicGroup === true) {
                          setPopupOpened(true);
                        } else {
                          onSubmit();
                        }
                      }}
                      isLoading={isSending}
                      className={styles.postButton}
                    >
                      <i className='fa fa-paper-plane' />
                    </ButtonWithLoader>
                  )}
                  {isVoiceMessageAvailable && (
                    <RecordButton uploadFile={uploadFile} insText={insText} voiceForPost={true} />
                  )}
                  <UploadButton
                    className={styles.uploadButton}
                    onChange={uploadFile}
                    isMultiple
                    isInProfileStream={isInProfileStream}
                    groupId={groupId}
                    files={files}
                  />
                  <PublicationTimeButton
                    onChange={pt => setPublicationTime(pt)}
                    value={publicationTime}
                  />
                  <PostLoadTemplateButton
                    type='post'
                    updateText={text => {
                      const htmlContent = document.createElement('div');
                      htmlContent.innerHTML = text;
                      updateValue(htmlContent);
                    }}
                  />
                  {activeKita &&
                    activeKita.kitaId &&
                    (activeKita.kitaId === 1257 ||
                      activeKita.kitaId === 1405 ||
                      activeKita.kitaId === 1715 ||
                      activeKita.kitaId === 1763 ||
                      activeKita.kitaId === 1765 ||
                      activeKita.kitaId === 1767) && <Dictaphone insText={insText} />}
                  {(user.administrationAccess || user.employee || user.superAdminStatus) &&
                    !isPortfolioGroup && (
                      <div className={styles.checkBoxContainer}>
                        <Checkbox
                          className={styles.checkbox}
                          onChange={() => setRequestReturnReceipt(state => !state)}
                          name={t('Post.RequestReturnReceipt')}
                          isChecked={requestReturnReceipt}
                        />
                        <div className={styles.inputIcon}>
                          <i className='fa fa-eye' />
                        </div>
                      </div>
                    )}
                </div>
                {openPopup && isProviderRelevant && (
                  <ProviderPostPopup
                    providers={providers}
                    isOpened
                    closePopup={() => setOpenPopup(false)}
                    submit={onSubmit}
                    isLoading={isSending}
                    dispatchProviders={dispatchProviders}
                  />
                )}
                <div className={styles.buttonsRight}>
                  {isPublic && <Label type='info'>{t('Post.Public')}</Label>}

                  {canBePublic && (
                    <MoreBtn
                      dropdownOptions={moreBtnDropdownOptions}
                      faIcon='fa-cogs'
                      className={styles.moreBtn}
                    />
                  )}
                </div>
              </div>

              {progress && files.length > 0 && (
                <div className={styles.progressBar}>
                  <div className={styles.progressBarValue} style={{ width: progress + '%' }} />
                </div>
              )}

              <FilePrewiev
                newFiles={files}
                onDelete={deleteFiles}
                onFilesSort={updatedFiles => setFiles(updatedFiles)}
              />
            </div>
          </div>
        </DragAndDrop>
      </Card>
      {group && group.globalPublicGroup && group.globalPublicGroup === true && (
        <PublicGroupPostPopup
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
}

export function providerReducer(state, action) {
  switch (action.type) {
    case 'toggleSelected':
      return state.map(kita =>
        kita.id === action.kitaId ? { ...kita, selected: !kita.selected } : kita,
      );
    case 'selectAll':
      return state.map(kita => (kita ? { ...kita, selected: true } : kita));
    case 'set':
      return action.providers;
    default:
      return state;
  }
}
