import Input from 'library/common/commonComponents/Inputs/Input';
import React, { useState, useEffect, useRef } from 'react';


export default function NameInput({ firstName, setFirstName, lastName, setLastName, email, setEmail }) {

  return (<>
    <Input type='text' placeholder={'Vorname'} value={firstName} onChange={e => setFirstName(e.target.value)} />
    <Input type='text' placeholder={'Nachname'} style={{ marginTop: "20px" }} value={lastName} onChange={e => setLastName(e.target.value)} />
    <Input type='text' placeholder={'Mit Email registrieren (optional)'} style={{ marginTop: "20px" }} value={email} onChange={e => setEmail(e.target.value)} />
  </>);

}
