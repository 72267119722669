import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserSignatureImage, deleteUserSignatureImage } from 'library/api/formEditor';
import defaultSignatureImage from 'resources/images/formEditor/defaultSignatureImage.png';
import Button from 'library/common/commonComponents/Buttons/Button';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';

export default function UserEditingSignatureImage() {
  const { t } = useTranslation();

  const [userSignatureImage, setUserSignatureImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserSignatureImage().then(data => {
      if (data.data !== null) {
        setUserSignatureImage(data.data.signatureImage);
      }
      setIsLoading(false);
    });
  }, [])

  return (
    isLoading ? <Loader /> :
      <div>
        {t('DefaultContractEdit.User signature image')}
        <br />
        <img style={{ maxHeight: "130pt", maxWidth: "100%" }} src={userSignatureImage !== null ? userSignatureImage : defaultSignatureImage} />
        <br /><br />
        <Button
          onClick={() => {
            deleteUserSignatureImage();
            setUserSignatureImage(null);
          }}
        >{t('DefaultContractEdit.Delete user signature image')}</Button>
      </div>
  );
}
