import React from 'react';
import brandschutz from 'resources/images/bgw/brandschutz.jpg';
import arbeitsplatzgestaltung from 'resources/images/bgw/arbeitsplatzgestaltung.jpg';
import erstehilfe from 'resources/images/bgw/erstehilfe.jpg';
import gefaehrungsbeurteilung from 'resources/images/bgw/gefaehrdungsbeurteilung.jpg';
import Collapsible from 'library/common/commonComponents/Collapsible';
import checklistTableStyle from './checklisttable.module.scss';

export default function ChecklistTable() {
  return (
    <>
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'flex-start' }}>
            <Collapsible
              title={<>Arbeits&shy;schutz&shy;betreu&shy;ung (sicher&shy;heits&shy;tech&shy;nisch und
                arbeits&shy;medizinisch)</>}
              body={<>Arbeitsschutzbetreuung (sicherheitstechnisch und arbeitsmedizinisch) Nach dem
                Arbeitssicherheitsgesetz müssen Sie eine Fachkraft für Arbeitssicherheit und einen
                Betriebsarzt/eine Betriebsärztin bestellen, die Sie bei der Umsetzung Ihrer gesetzlichen
                Pflichten in Bezug auf Sicherheit und Gesundheit unterstützen ( §§ 2 ff
                Arbeitssicherheitsgesetz –AsiG- und §§2ff DGUV Vorschrift 2). Nähere Informationen dazu
                finden Sie hier:{' '}
                <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsschutzorganisation_Artikel_Download.pdf?__blob=publicationFile'>
                  https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsschutzorganisation_Artikel_Download.pdf?__blob=publicationFile
                </a></>}
            />
            <Collapsible
              title={<>Sicher&shy;heits&shy;beauftragte</>}
              body={<>Sicherheitsbeauftragte Bestellen Sie eine Mitarbeiterin/einen Mitarbeiter (aus dem Kreis
                der pädagogischen Fachkräfte), die Vorstand und Leitung bei der Umsetzung von Maßnahmen
                von Sicherheit und Gesundheit unterstützt (§ 22 Sozialgesetzbuch –SGB- VII, §20 DGUV
                Vorschrift 1, Grundsätze der Prävention). Nähere Informationen dazu finden Sie hier:{' '}
                <a href='https://www.bgw-online.de/DE/Leistungen-Beitrag/Praevention/Sicherheitsbeauftragte/Sicherheitsbeauftragte_node.html'>
                  https://www.bgw-online.de/DE/Leistungen-Beitrag/Praevention/Sicherheitsbeauftragte/Sicherheitsbeauftragte_node.html
                </a></>}
            />
            <Collapsible
              title={<>Erste Hilfe</>}
              previewImage={erstehilfe}
              body={<>Erste Hilfe Sorgen Sie dafür, dass pro Gruppe mind. eine Ersthelferin/ein Ersthelfer
              ausgebildet ist. Stellen Sie Erste Hilfe – Material bereit und führen Sie ein
              Verbandbuch für Kinder und Beschäftigte ( § 24 DGUV Vorschrift 1, Grundsätze der
              Prävention). Nähere Informationen dazu finden Sie hier:{' '}
              <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Notfallvorsorge_Artikel_Download.pdf?__blob=publicationFile'>
                https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Notfallvorsorge_Artikel_Download.pdf?__blob=publicationFile
              </a></>}
            />
            <Collapsible
              title={<>Brand&shy;schutz</>}
              previewImage={brandschutz}
              body={<>Brandschutz Benennen Sie eine Mitarbeiterin/einen Mitarbeiter zum
              Brandschutzhelfer/Brandschutzhelferin und lassen Sie diese Person ausbilden. Erarbeiten
              Sie mit einer sachkundigen Person ein Notfallkonzept (z.B. im Falle eines Brandes) mit
              Beteiligung Ihrer Fachkraft für Arbeitssicherheit und ggf. der örtlichen Feuerwehr
              (vorbeugender Brandschutz). (§10 Arbeitsschutzgesetz - ArbSchG-, §22 DGUV Vorschrift 1,
              Grundsätze der Prävention) Nähere Informationen dazu finden Sie hier:{' '}
              <a href='https://www.bgw-online.de/resource/blob/8826/7b8970139eaf067002ce443139725bbb/brandschutz-artikel-download-data.pdf'>
                https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Brandschutz_Artikel_Download.pdf?__blob=publicationFile
              </a></>}
            />
            <Collapsible
              title={<>Arbeits&shy;medizinische Vor&shy;sorge</>}
              body={<>Arbeitsmedizinische Vorsorge Beschäftigte müssen je nach den Gefährdungen, denen sie am
                Arbeitsplatz ausgesetzt sind, einer arbeitsmedizinischen Vorsorge zugeführt werden. Ob
                Infektionsgefahren beim Umgang mit Körperflüssigkeiten (biologische Arbeitsstoffe) oder
                Belastungen der Haut durch Feuchtarbeiten und Reinigungstätigkeiten bestehen, besprechen
                Sie mit Ihrem Betriebsarzt/Ihrer Betriebsärztin, welche Vorsorge in Ihrer Einrichtung
                erforderlich ist (§3ff Verordnung zur arbeitsmedizinischen Vorsorge –ArbMedVV-,
                Verordnung über biologische Arbeitsstoffe-- BioStoffV-, Technische Regel für biologische
                Arbeitsstoffe – TRBA 250-). Nähere Informationen dazu finden Sie hier:{' '}
                <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsmedizinische-Vorsorge_Artikel_Download.pdf?__blob=publicationFile'>
                  https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsmedizinische-Vorsorge_Artikel_Download.pdf?__blob=publicationFile
                </a></>}
            />
            <Collapsible
              title={<>Unter&shy;weisung</>}
              body={<>Mit Unterweisungen informieren und qualifizieren Sie Ihre Beschäftigten, sich im Betrieb
                sicher und gesundheitsförderlich zu verhalten. Bestimmte Themen müssen Sie daher
                mindestens einmal jährlich unterweisen (§12 Arbeitsschutzgesetz –ArbSchG-, §4 DGUV
                Vorschrift 1, Grundsätze der Prävention). Nähere Informationen dazu finden Sie hier:{' '}
                <a href='https://www.bgw-online.de/DE/Medien-Service/Kundenmagazin/2017-3/Richtig-unterweisen.html'>
                  https://www.bgw-online.de/DE/Medien-Service/Kundenmagazin/2017-3/Richtig-unterweisen.html
                </a></>}
            />
            <Collapsible
              title={<>Gefährdungs&shy;beurteilung</>}
              previewImage={gefaehrungsbeurteilung}
              body={<>Das Arbeitsschutzgesetz verpflichtet Sie als Unternehmer zur Durchführung und
                Dokumentation der Gefährdungsbeurteilung. Beurteilen Sie, welche Gefährdungen für welche
                Personengruppe wo in der Einrichtung vorhanden sind und setzen Sie geeignete Maßnahmen
                um, die diese Gefährdungen eliminieren oder minimieren. Auch Gefährdungen der
                psychischen Gesundheit müssen beurteilt werden. Da Stress und emotionale Überlastung
                krank machen können. Treffen Sie Maßnahmen, um das Wohlbefinden von Kindern und
                Beschäftigten zu fördern (§5 Arbeitsschutzgesetz - ArbSchG-). Nähere Informationen
                finden Sie hier:{' '}
                <a href='https://www.bgw-online.de/DE/Medien-Service/Medien-Center/Medientypen/BGW-Broschueren/Gefaehrdungsbeurteilung/BGW04-05-130_Gefaehrdungsbeurteilung-in-der-Kinderbetreuung.html'>
                  https://www.bgw-online.de/DE/Medien-Service/Medien-Center/Medientypen/BGW-Broschueren/Gefaehrdungsbeurteilung/BGW04-05-130_Gefaehrdungsbeurteilung-in-der-Kinderbetreuung.html
                </a></>}
            />
            <Collapsible
              title={<>Arbeits&shy;platz&shy;gestaltung</>}
              previewImage={arbeitsplatzgestaltung}
              body={<>Achten Sie auf die Gestaltung der einzelnen Räume. Diese sollten sowohl für die Kinder,
                als auch für die Beschäftigten eine gesunde und sichere Umgebung bieten. Achten Sie z.B.
                auf rutschfeste Bodenbeläge, Klemmschutz an den Türen, bruchsichere Verglasung,
                Lärmschutzmaßnahmen und ergonomisch geeignetes Mobiliar (z.B. DGUV Vorschrift 82 und
                Kapitel 3 der DGUV Regel 102-602). Nähere Informationen dazu finden Sie hier:{' '}
                <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsplatz_KiTa_Artikel_Download.pdf?__blob=publicationFile '>
                  https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsplatz_KiTa_Artikel_Download.pdf?__blob=publicationFile
                </a></>}
            />
            <Collapsible
              title={<>Elektrische Geräte (Prüfung)</>}
              body={<>Verwenden Sie Geräte mit CE- Kennzeichnung, listen Sie diese auf und lassen Sie
                Installationen nur von einer Elektrofachkraft vornehmen. Lassen Sie sowohl ortsfeste,
                als auch ortsveränderliche Geräte in den notwendigen Intervallen prüfen. Ziehen Sie
                hierbei ggf. Ihre Fachkraft für Arbeitssicherheit zu Rate (§5 DGUV Vorschrift 3). Nähere
                Informationen dazu finden Sie hier:{' '}
                <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Elektrische-Geraete_Artikel_Download.pdf?__blob=publicationFile'>
                  https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Elektrische-Geraete_Artikel_Download.pdf?__blob=publicationFile
                </a></>}
            />
            <Collapsible
              title={<>Spiel&shy;platz&shy;prüfung</>}
              body={<>Insbesondere um die Sicherheit der Kinder im Außengelände und auf Spielgeräten zu
                gewährleisten, sind regelmäßige Prüfungen der Spielgeräte durch eine sachkundige Person
                zu veranlassen (DIN-Norm 1176, Teil 7). Nähere Informationen dazu finden Sie hier:
                <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsmedizinische-Vorsorge_Artikel_Download.pdf?__blob=publicationFile'>
                  https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsmedizinische-Vorsorge_Artikel_Download.pdf?__blob=publicationFile
                </a></>}
            />
            <Collapsible
              title={<>Arbeits&shy;mittel prüfen und Hygiene&shy;plan erstellen (Desinfektions-, Reinigungs-
                und Pflege&shy;mittel, z.B. zur Haut&shy;pflege)</>}
              previewImage={arbeitsplatzgestaltung}
              body={<>Beachten Sie die Hygieneverordnung Ihres Bundeslandes und erstellen Sie sowohl einen
                Hautschutz- und Händehygieneplan, als auch einen Reinigungs- und Desinfektionsplan
                (§§3ff Infektionsschutzgesetz, §§ 3-9,12,14,16 Biostoffverordnung, DGUV Regel 102-602,
                Kapitel 3.10). Nähere Informationen dazu finden Sie hier:{' '}
                <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Hautschutz_Artikel_Download.pdf?__blob=publicationFile'>
                  https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsmedizinische-Vorsorge_Artikel_Download.pdf?__blob=publicationFile
                </a>
                und hier:
                <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Infektionsschutz_Artikel_Download.pdf?__blob=publicationFile'>
                  https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Infektionsschutz_Artikel_Download.pdf?__blob=publicationFile
                </a></>}
            />
    </div>
    </>
  );
}
