import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { deleteKitaKids } from 'library/api/kita';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Button from 'library/common/commonComponents/Buttons/Button';
import ConfirmPopup from 'library/common/commonComponents/Popups/ConfirmPopup';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Popup from 'library/common/commonComponents/Popups/Popup';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from '../multiKidSelectionPopup.module.scss';

const MultiKidDeletePopup = (
  {
    activeKita,
    isLoading,
    isOpen,
    setOpen,
    selectedKids,
    refresh,
  }) => {
  const { t } = useTranslation();

  const [kidsToDelete, setKidsToDelete] = useState([]);
  const [confirmPopupIsOpened, setConfirmPopupIsOpened] = useState(false);

  const handleKidChange = kid => {
    if (kidsToDelete.find(item => kid.kidId === item.kidId)) {
      setKidsToDelete(kidsToDelete.filter(item => item.kidId !== kid.kidId));
    } else {
      setKidsToDelete([...kidsToDelete, kid]);
    }
  };

  const closeKidSelectionPopup = () => {
    setOpen(false);
  };

  const openConfirmPopup = () => {
    setConfirmPopupIsOpened(true);
  };

  const handleConfirmDeleteKids = async () => {
    const kidIds = kidsToDelete.map(kid => kid.kidId);
    await deleteKitaKids(activeKita.kitaId, kidIds);
    refresh();
  };

  const onSave =  async () => {
    await handleConfirmDeleteKids();
    setConfirmPopupIsOpened(false);
    closeKidSelectionPopup();
  };

  useEffect(() => {
    setKidsToDelete(selectedKids);
  }, [selectedKids]);

  return (
    <>
      <Popup
        isOpened={isOpen}
        closePopup={closeKidSelectionPopup}
        header={t('Kid.Select Kids')}
        footer={
          <div className={styles.buttonsContainer}>
            <ButtonWithLoader
              isLoading={isLoading}
              disabled={kidsToDelete.length === 0}
              onClick={openConfirmPopup}
            >
              {t('Confirm.Save')}
            </ButtonWithLoader>
            <Button onClick={closeKidSelectionPopup}>{t('Confirm.Close')}</Button>
          </div>
        }
      >
        <div className={styles.groupList}>
          {selectedKids.map(kid => (
            <div
              key={kid.kidId}
              className={styles.groupElement}
              onClick={() => handleKidChange(kid)}
            >
              <Checkbox
                key={kid.kidId}
                isChecked={kidsToDelete.findIndex(item => item.kidId === kid.kidId) > -1}
              />
              <div className={cn('notranslate', styles.kidAvatar)}>
                <img
                  style={{ maxWidth: '40px', marginRight: '10px' }}
                  src={kid.groupLogoUrl || defaultAvatar}
                  alt=""
                />
                <div className={styles.kidAvatarText}>
                  <div className={styles.name}>
                  <span>
                    {kid.firstName} {kid.lastName}
                  </span>
                    <span className={styles.parent}>
                    {kid.parent1FirstName || ""} {kid.parent1LastName || ""}
                  </span>
                    <span className={styles.parent}>
                    {kid.parent2FirstName || ""} {kid.parent2LastName || ""}
                  </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Popup>
      <ConfirmPopup
        isOpened={confirmPopupIsOpened}
        closePopup={() => setConfirmPopupIsOpened(false)}
        onConfirm={onSave}
        text={t('MultiKidAction.KidDataDeletion')}
      />
    </>
  );
};

export default MultiKidDeletePopup;
