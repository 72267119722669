import React, { useRef, useState, useEffect } from 'react';
import styles from './radioButtonGroupContainer.module.scss';

const useResizeEffect = (onResize, deps = []) => {
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, onResize]);
};

const RadioButtonGroupContainer = ({ children, ...props }) => {
  const radioButtonGroupRef = useRef();
  const [radioButtonGroupRowWidth, setRadioButtonGroupRowWidth] = useState(null);

  const onResize = () => {
    const { current } = radioButtonGroupRef;

    // set initial row width
    if (current && !radioButtonGroupRowWidth) {
      setRadioButtonGroupRowWidth(current?.clientWidth);
    }

    // if row width is smaller than parent width, change to column
    if (current?.clientWidth > current?.parentElement?.clientWidth) {
      current.style.flexDirection = 'column';
      current.style.display = 'flex';
      current.style.alignItems = 'flex-start';
      current.style.gap = '0.5em';
    }

    // if row width is bigger than parent width, change to row
    if (radioButtonGroupRowWidth < current?.parentElement?.clientWidth) {
      current.style.display = 'inline-flex';
      current.style.flexDirection = 'row';
      current.style.alignItems = 'center';
      current.style.gap = '1em';
    }
  };

  useResizeEffect(onResize, [radioButtonGroupRef?.current]);

  return (
    <div className={styles.radioGroup} ref={radioButtonGroupRef} {...props}>
      {children}
    </div>
  );
};

export default RadioButtonGroupContainer;
