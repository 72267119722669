import React from 'react';
import { useTranslation } from 'react-i18next';

import BlockedGroupsList from 'library/common/commonComponents/BlockedGroupsList';
import styles from './profileDeleteAccountBlocked.module.scss';

export default function ProfileDeleteAccountBlocked({ groups }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.attention}>{t('AccountSettings.Your account cannot be deleted!')}</div>
      <BlockedGroupsList
        text={t('AccountSettings.You are currently the owner of following spaces')}
        groups={groups}
      />

      <div className={styles.attentionBottom}>
        {t(
          'AccountSettings.You must transfer ownership or delete these spaces before you can delete your account',
        )}
      </div>
    </div>
  );
}
