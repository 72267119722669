import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'library/common/commonComponents/Tooltip';
import { connect, useSelector } from 'react-redux';
import PT from 'prop-types';

import Input from 'library/common/commonComponents/Inputs/Input';
import { getCheckInOutKitaConfig } from 'library/api/kids';
import Button from 'library/common/commonComponents/Buttons/Button';
import ChildRow from './ChildRow';

import styles from './childesContainer.module.scss';

const ChildesContainer = ({ data, handleUpdateKidStatus }) => {
  const [searchValue, setSearchValue] = useState('');
  const [updateAll, setUpdateAll] = useState(false);
  const [notifyToParentsOnCheckInOutActive, setNotifyToParentsOnCheckInOutActive] = useState(false);

  const kita = useSelector(state => state.kitaReducer);

  const { t } = useTranslation();
  const filteredData =
    searchValue.length > 0
      ? data.filter(item => item.name.toLowerCase().includes(searchValue))
      : data;

  const isHortType = (kita.activeKita.kitaTypeTranslations || []).includes('Hort');

  useEffect(() => {
    getCheckInOutKitaConfig().then(res => {
      setNotifyToParentsOnCheckInOutActive(res.data.checkinoutNotificationsActiveParent);
    });
  }, []);

  useEffect(() => {
    if (updateAll) {
      setUpdateAll(false);
    }
  }, [updateAll]);

  const isAllCheckOut = !!filteredData.find(item => item.checkInOutStatus === 'CHECKIN');

  return (
    <div>
      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>
      <div className={styles.allKids}>
        <Button className={styles.saveButton} onClick={() => setUpdateAll(true)} type='primary'>
          {isAllCheckOut ? 'Alle abmelden' : 'Alle anmelden'}
        </Button>
      </div>
      <div className={styles.header}>
        <div className={styles.name}><div style={{ paddingLeft: "80px", marginRight: "20px" }}>{t('Checkinout.Name')}</div></div>
        {isHortType &&
          <div style={{ width: "73px", marginLeft: "30px", fontSize: "18px" }}>
            <center>
              <Tooltip text={t('Checkinout.HomeByOwn')}>
                <i className='fa fa-home' />
              </Tooltip>
            </center>
          </div>
        }
        <div style={{ width: "200px", minWidth: "93px", }}>
          <center>
            <Tooltip text={t('Checkinout.Pick up person')}>
              <i className='fa fa-taxi' />
            </Tooltip>
          </center>
        </div>
      </div>
      {filteredData.map(item => {
        return (
          <ChildRow
            childObj={item}
            handleUpdateKidStatus={handleUpdateKidStatus}
            key={item.kidId}
            sendUpdate={updateAll}
            isHortType={isHortType}
            notifyToParentsOnCheckInOutActive={notifyToParentsOnCheckInOutActive}
          />
        );
      })}
    </div>
  );
};

ChildesContainer.propTypes = {
  data: PT.arrayOf(PT.object).isRequired,
  handleUpdateKidStatus: PT.func.isRequired,
};

export default ChildesContainer;
