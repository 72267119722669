import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Checkbox from 'library/common/commonComponents/Checkbox';
import HelpText from 'library/common/commonComponents/HelpText';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import Select from 'library/common/commonComponents/Inputs/Select';

import useKitaUserFields from 'library/common/commonHooks/useKitaUserFields';
import { isAwoWW } from 'library/api/tenantConfig';

import styles from './kitaFieldsList.module.scss';

export default function KitaUserFieldsList() {
  const {
    kitas,
    kitaUserFields,
    kitaUserFieldsLoading,
    updateKitaUserFieldsLoading,
    selectedKita,
    setSelectedKita,
    updateKitaUserFields,
    kitaCustomFields
  } = useKitaUserFields();

  const awoWW = isAwoWW();
  const user = useSelector(state => state.userReducer);

  const [fields, setFields] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [errorLimitCustomField, setErrorLimitCustomField] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (kitaUserFields) {
      setFields(kitaUserFields);
    }
  }, [kitaUserFields]);

  useEffect(() => {
    if (kitaCustomFields) {
      setCustomFields(kitaCustomFields);
    }
  }, [kitaCustomFields]);

  const { t } = useTranslation();

  const handleChange = (key, subKey) => e => {
    setFields({ ...fields, [key]: { ...fields[key], [subKey]: !fields[key][subKey] } });
  };

  const handleSubmit = () => {
    updateKitaUserFields(fields, customFields)
      .then(() => {
        dispatch(showBottomNotification(<Trans i18nKey='BottomNotifications.Saved' />));
      })
      .catch(() => {
        dispatch(
          showBottomNotification(<Trans i18nKey='BottomNotifications.Something went wrong' />, {
            isFail: true,
          }),
        );
      });
  };

  const onAddMoreField = () => {
    if (customFields.length === 20) {
      setErrorLimitCustomField(t('Administration.Kita.LimitCustomField'))
      return;
    }
    const newField = {
      id: Math.random(),
      shownOnUserProfile: true,
      fieldName: ''
    };

    setCustomFields([...customFields, newField]);
  }

  const onUpdateFieldName = (field, newValue) => {
    const updatedFields = customFields.map(f =>
      f.id === field.id ? { ...f, fieldName: newValue } : f
    );
    setCustomFields(updatedFields);
  };

  const handleChangeCustomField = (field, type, value) => {
    const updatedFields = customFields.map(f =>
      f.id === field.id ? { ...f, [type]: value } : f
    );
    setCustomFields(updatedFields);
  };

  const onRemoveCustomField = (id) => {
    const updatedFields = customFields.filter(field => field.id !== id);
    setCustomFields(updatedFields);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{t('Administration.Kita.KitaUserFieldsList')}</div>
        <div className={styles.text}>
          <HelpText>{t('Administration.Kita.Kita User Fields List Helper Text')}</HelpText>
        </div>
      </div>

      <div className={styles.selectWrapper}>
        <Select options={kitas} onSelect={setSelectedKita} selected={selectedKita} />
      </div>

      <div>{kitaUserFieldsLoading && <Loader />}</div>

      {!kitaUserFieldsLoading && (
        <div className={styles.fieldContainer}>
          <div className={styles.formGroup}>
            <div className={styles.userFieldCheckboxWrapper}>
              <p className={styles.headerText}>{t('Admininstation.Kita.IsVisible?')}</p>
            </div>

            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.userName.isShownOnUserProfile}
                onChange={handleChange('userName', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Username')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.firstName.isShownOnUserProfile}
                onChange={handleChange('firstName', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.First name')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.lastName.isShownOnUserProfile}
                onChange={handleChange('lastName', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Last name')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.title.isShownOnUserProfile}
                onChange={handleChange('title', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Title')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.gender.isShownOnUserProfile}
                onChange={handleChange('gender', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Gender')}</p>
            </div>

            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.street.isShownOnUserProfile}
                onChange={handleChange('street', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Street')}</p>
            </div>

            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.zip.isShownOnUserProfile}
                onChange={handleChange('zip', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Zip')}</p>
            </div>

            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.city.isShownOnUserProfile}
                onChange={handleChange('city', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.City')}</p>
            </div>

            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.country.isShownOnUserProfile}
                onChange={handleChange('country', 'isShownOnUserProfile')}
              />

              <p className={styles.label}>{t('Administration.UserSection.Country')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.state.isShownOnUserProfile}
                onChange={handleChange('state', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.State')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.motherLanguage.isShownOnUserProfile}
                onChange={handleChange('motherLanguage', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.MotherLanguage')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.mainLanguage.isShownOnUserProfile}
                onChange={handleChange('mainLanguage', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.MainSpokenLanguageInFamily')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.denomination.isShownOnUserProfile}
                onChange={handleChange('denomination', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Denomination')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.workingPlace.isShownOnUserProfile}
                onChange={handleChange('workingPlace', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.WorkingPlace')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.maritalStatus.isShownOnUserProfile}
                onChange={handleChange('maritalStatus', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.MaritalStatus')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.personWithSoleCustody.isShownOnUserProfile}
                onChange={handleChange('personWithSoleCustody', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.PersonWithSoleCustody')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.personSingleParent.isShownOnUserProfile}
                onChange={handleChange('personSingleParent', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.PersonSingleParent')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.birthday.isShownOnUserProfile}
                onChange={handleChange('birthday', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Birthday')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.hideUser.isShownOnUserProfile}
                onChange={handleChange('hideUser', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Hide me')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.blockUsersFromMessaging.isShownOnUserProfile}
                onChange={handleChange('blockUsersFromMessaging', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.Block users')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.about.isShownOnUserProfile}
                onChange={handleChange('about', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.About')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.importId.isShownOnUserProfile}
                onChange={handleChange('importId', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.UserSection.ImportId')}</p>
            </div>
            <div className={styles.userFieldCheckboxWrapper}>
              <Checkbox
                isChecked={fields?.qrCode.isShownOnUserProfile}
                onChange={handleChange('qrCode', 'isShownOnUserProfile')}
              />
              <p className={styles.label}>{t('Administration.Generate QR Code')}</p>
            </div>
            <div className={styles.questionContainer}>
              <Button className={styles.btnAddQuestion} onClick={()=> onAddMoreField()}>
                <i className='fa fa-plus' /> {t('Administration.UserSection.AddCustomField')}
              </Button>
              {
                errorLimitCustomField && (
                  <div className={styles.errorLimitCustomField}>{errorLimitCustomField}</div>
                )
              }
            </div>
            <div className={styles.customFieldWrapper}>
              {
                customFields.map(field => (
                  <div className={styles.userFieldCheckboxWrapper}>
                    <Checkbox
                      isChecked={field.shownOnUserProfile}
                      onChange={()=> handleChangeCustomField(field, 'shownOnUserProfile', !field.shownOnUserProfile)}
                    />
                    <p className={styles.label}>
                      <Input placeholder={t('Administration.UserSection.FieldName')} value={field.fieldName} onChange={(e)=> onUpdateFieldName(field, e.target.value)}/>
                      <i className='fa fa-minus' onClick={()=> onRemoveCustomField(field.id)}/>
                    </p>
                  </div>
                ))
              }
            </div>
            <div className={styles.buttonWrapper}>
              <ButtonWithLoader
                onClick={handleSubmit}
                type='primary'
                isLoading={updateKitaUserFieldsLoading}
              >
                {t('KitaField.Update')}
              </ButtonWithLoader>
            </div>
          </div>
          {
            awoWW && user.superAdminStatus && (
              <div className={styles.formGroup}>
                <div className={styles.userFieldCheckboxWrapper}>
                  <p className={styles.headerText}>{t('Admininstation.Kita.IsVisible?')}</p>
                </div>

                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaName.isShownOnUserProfile}
                    onChange={handleChange('kitaName', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaName')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaEmail.isShownOnUserProfile}
                    onChange={handleChange('kitaEmail', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaEmail')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaChiefName.isShownOnUserProfile}
                    onChange={handleChange('kitaChiefName', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaChiefName')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaPhoneNumber.isShownOnUserProfile}
                    onChange={handleChange('kitaPhoneNumber', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaPhoneNumber')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaAddress.isShownOnUserProfile}
                    onChange={handleChange('kitaAddress', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaAddress')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaBelongToCity.isShownOnUserProfile}
                    onChange={handleChange('kitaBelongToCity', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaBelongToCity')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaIsACity?.isShownOnUserProfile}
                    onChange={handleChange('kitaIsACity', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaIsACity')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaOrganization.isShownOnUserProfile}
                    onChange={handleChange('kitaOrganization', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaOrganization')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaWebsite.isShownOnUserProfile}
                    onChange={handleChange('kitaWebsite', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaWebsite')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaDataProtector.isShownOnUserProfile}
                    onChange={handleChange('kitaDataProtector', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaDataProtector')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaReligion.isShownOnUserProfile}
                    onChange={handleChange('kitaReligion', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaReligion')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaDescription.isShownOnUserProfile}
                    onChange={handleChange('kitaDescription', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaDescription')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaCreateKitaCode.isShownOnUserProfile}
                    onChange={handleChange('kitaCreateKitaCode', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaCreateKitaCode')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaColor.isShownOnUserProfile}
                    onChange={handleChange('kitaColor', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaColor')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaImage.isShownOnUserProfile}
                    onChange={handleChange('kitaImage', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaImage')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaVerifyImages.isShownOnUserProfile}
                    onChange={handleChange('kitaVerifyImages', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaVerifyImages')}</p>
                </div>
                <div className={styles.userFieldCheckboxWrapper}>
                  <Checkbox
                    isChecked={fields?.kitaHolidayCare.isShownOnUserProfile}
                    onChange={handleChange('kitaHolidayCare', 'isShownOnUserProfile')}
                  />
                  <p className={styles.label}>{t('Administration.UserSection.KitaHolidayCare')}</p>
                </div>
              </div>
            )
          }
        </div>
      )}
    </>
  );
}
