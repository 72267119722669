import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getKitaHolidays } from 'library/api/kita';
import { getKidHolidayTimes } from 'library/api/kids';
import { formatDateObjectToTimeString } from 'library/utilities/dateMoment';

import styles from './HolidayTimes.module.scss';

const initialRowData = [
  { name: 'Herbstferien', field: 'herbstferien' },
  { name: 'Weihnachtsferien', field: 'weihnachtsferien' },
  { name: 'Winterferien', field: 'winterferien' },
  { name: 'Osterferien', field: 'osterferien' },
  { name: 'Pfingstferien', field: 'pfingstferien' },
  { name: 'Sommerferien', field: 'sommerferien' },
];

const HolidayTimes = ({ kidId, kitaId, holidayTimes, setHolidayTimes, disabled }) => {
  const [kitaHolidays, setKitaHolidays] = useState([]);

  const { t } = useTranslation();

  const handleHolidayTimeChange = (kitaHolidayId, field) => e => {
    const target = holidayTimes.find(holidayTime => holidayTime?.kitaHolidayId === kitaHolidayId);
    if (!target) {
      const kitaHoliday = kitaHolidays.find(item => item.id === kitaHolidayId);
      setHolidayTimes([
        ...holidayTimes,
        {
          kidId: kidId,
          kitaHolidayId: kitaHoliday.id,
          [field]: e.target.value,
        },
      ]);
    } else {
      const updatedDefaultHolidaysData = [];
      holidayTimes.forEach(item => {
        if (item.kitaHolidayId === kitaHolidayId) {
          if (item.bookingTimeFrom || item.bookingTimeTo || e.target.value !== '') {
            updatedDefaultHolidaysData.push({
              ...item,
              [field]: e.target.value,
            });
          }
        } else {
          updatedDefaultHolidaysData.push(item);
        }
      });

      setHolidayTimes(updatedDefaultHolidaysData);
    }
  };

  const fetchData = useCallback(async () => {
    const res = await getKidHolidayTimes(kitaId, kidId);
    if (res.status === 200 && res.data.length > 0) {
      const kidBookingTimes = [];
      res.data.forEach(row => {
        kidBookingTimes.push({
          kitaHolidayId: row.kitaHoliday.id,
          kidId: row.kid.id,
          bookingTimeFrom: formatDateObjectToTimeString(row.bookingTimeFrom),
          bookingTimeTo: formatDateObjectToTimeString(row.bookingTimeTo),
        });
      });
      setHolidayTimes(kidBookingTimes);
    }
    getKitaHolidays(kitaId).then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const holidays = [];
        res.data.forEach(day => {
          holidays.push({
            id: day.id,
            name: day.name,
            isAdditional: day.isAdditional,
          });
        });
        setKitaHolidays(holidays);
      }
    });
  }, []);

  const matchedKitaHoliday = holidayName => {
    return kitaHolidays.find(kitaHoliday => kitaHoliday.name === holidayName);
  };

  const additionalKitaHolidays = useMemo(
    () => kitaHolidays.filter(kitaHoliday => kitaHoliday.isAdditional),
    [kitaHolidays],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.holidayTimesContainer}>
      <b>{t('Administration.KidSection.Holiday times')}</b>
      <br />
      <div className={styles.holidayGrid}>
        <div className={styles.gridHeader}>
          <div>{t('Administration.KidSection.Holiday')}</div>
          <div>{t('Administration.KidSection.From')}</div>
          <div>{t('Administration.KidSection.To')}</div>
        </div>
        {initialRowData.map(({ name }) => {
          const matchedKitaHolidayId = matchedKitaHoliday(name)?.id;
          const holidayTime = holidayTimes.find(row => row?.kitaHolidayId === matchedKitaHolidayId);

          return (
            <div className={styles.gridRow} key={name}>
              <div>{t(`Administration.KidSection.${name}`)}</div>
              <input
                type='time'
                disabled={!matchedKitaHolidayId || disabled}
                value={holidayTime?.bookingTimeFrom || ''}
                onChange={handleHolidayTimeChange(matchedKitaHolidayId, 'bookingTimeFrom')}
              />
              <input
                type='time'
                disabled={!matchedKitaHolidayId || disabled}
                value={holidayTime?.bookingTimeTo || ''}
                onChange={handleHolidayTimeChange(matchedKitaHolidayId, 'bookingTimeTo')}
              />
            </div>
          );
        })}
        <p className={styles.categoryTitle}>{t('Administration.KidSection.Additional Holidays')}</p>
        {additionalKitaHolidays.map(item => (
          <div className={styles.gridRow} key={item.id}>
            <div>{item.name}</div>
            <input
              type='time'
              value={
                holidayTimes.find(row => row?.kitaHolidayId === item.id)?.bookingTimeFrom || ''
              }
              onChange={handleHolidayTimeChange(item.id, 'bookingTimeFrom')}
              disabled={disabled}
            />
            <input
              type='time'
              value={holidayTimes.find(row => row?.kitaHolidayId === item.id)?.bookingTimeTo || ''}
              onChange={handleHolidayTimeChange(item.id, 'bookingTimeTo')}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HolidayTimes;
