import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;

export const getBgwChecklist = () => {
  return axios.get(URL + '/getbgwchecklist');
};

export const postBgwChecklist = async data => {
  const apiResult = await axios.post(URL + '/updatebgwchecklist', {
    ...data,
  });
};

export const addBgwNews = textString => {
  return axios.post(URL + '/addbgwnews', textString);
};

export const getBgwNews = () => {
  return axios.get(URL + '/getbgwnews');
};
