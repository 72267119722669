import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import last from 'lodash/last';

import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Input from 'library/common/commonComponents/Inputs/Input';
import Label from 'library/common/commonComponents/Label';
import CheckBox from 'library/common/commonComponents/Checkbox';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import { getCanBePublicStatus } from 'library/utilities/posts';

import styles from './fileManagerAddDirectionPopup.module.scss';

export default function FileManagerAddDirectionPopup({
  isOpened,
  closePopup,
  group,
  breadcrumbs,
  createNewFolder,
  updateCurrentFolder,
  isEditing,
  setIsEditing,
  updateFolder,
  updateBreadcrumbs,
}) {
  const { t } = useTranslation();
  const currentFolder = last(breadcrumbs) || {};
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [title, setTitle] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(true);

  useEffect(() => {
    if (currentFolder && isEditing) {
      setTitle(currentFolder.name);
      setDescription(currentFolder.description);
      setIsPublic(currentFolder.publicFolder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs, isEditing]);

  const checkTitle = () => {
    if (!title.trim()) {
      setErrorTitle(t('FileManager.Title can not be empty'));
      return false;
    }
    if (title.trim().length < 3) {
      setErrorTitle(t('FileManager.The field should not contain less than three characters'));
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const isValidTitle = checkTitle();

    if (!isValidTitle || isSubmiting) return;

    setIsSubmiting(true);

    const updatedData = {
      id: currentFolder.id,
      parentFolderId: breadcrumbs[breadcrumbs.length - 2]
        ? breadcrumbs[breadcrumbs.length - 2].id
        : null,
      groupId: group.groupId || null,
      name: title,
      description,
      publicFolder:
        !currentFolder || !('publicFolder' in currentFolder) || currentFolder.publicFolder,
    };
    const newData = {
      parentFolderId: currentFolder.id || null,
      groupId: group.groupId || null,
      name: title,
      description,
      publicFolder:
        !currentFolder || !('publicFolder' in currentFolder) || currentFolder.publicFolder,
    };

    if (isEditing) {
      const updatedBreadcrumbs = [...breadcrumbs];
      updatedBreadcrumbs.pop();

      await updateFolder(updatedData);
      updateBreadcrumbs([
        ...updatedBreadcrumbs,
        {
          ...last(breadcrumbs),
          name: title,
          description,
          publicFolder:
            !currentFolder || !('publicFolder' in currentFolder) || currentFolder.publicFolder,
        },
      ]);
    } else {
      await createNewFolder(newData);
    }

    updateCurrentFolder();
    setIsSubmiting(false);
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setErrorTitle('');
    setTitle('');
    setDescription('');
    setIsPublic(!currentFolder || !('publicFolder' in currentFolder) || currentFolder.publicFolder);
    if (isEditing) setIsEditing(false);
    closePopup();
  };

  const handleTitle = ({ target }) => {
    setErrorTitle('');
    setTitle(target.value);
  };

  const handleDescription = ({ target }) => {
    setDescription(target.value);
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={handleClosePopup}
      header={
        isEditing ? (
          <Trans i18nKey='FileManager.Edit folder' />
        ) : (
          <Trans i18nKey='FileManager.Create Folder' />
        )
      }
      body={
        <div>
          <div className={styles.popupInputContainer}>
            <Label type='input'>
              <strong>{t('Popup.Title')}</strong>
            </Label>
            <Input
              type='text'
              error={errorTitle}
              value={title}
              onChange={handleTitle}
              maxLength={50}
            />
            {errorTitle && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={errorTitle} />
              </div>
            )}
          </div>
          <div className={styles.popupInputContainer}>
            <Label type='input'>
              <strong>{t('Popup.Description')}</strong>
            </Label>
            <Input type='text' value={description} onChange={handleDescription} maxLength={255} />
          </div>
          {/* <CheckBox
            className={styles.popupCheckbox}
            name={t('FileManager.Is Public')}
            isChecked={isPublic}
            onChange={handleCheckbox}
            isDisabled={!canBePublic}
          /> */}
          {isEditing && <div className={styles.popupNote}>{t('FileManager.Note')}</div>}
        </div>
      }
      footer={
        <div className={styles.popupBtns}>
          <ButtonWithLoader onClick={handleSubmit} type='primary' isLoading={isSubmiting}>
            {t('Popup.Save')}
          </ButtonWithLoader>
          <Button onClick={handleClosePopup}>{t('Popup.Cancel')}</Button>
        </div>
      }
    />
  );
}
