import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import HelpText from 'library/common/commonComponents/HelpText';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { changeUserPassword } from 'library/api/user';

import styles from './profileChangePassword.module.scss';

export default function ProfileChangePassword({ showBottomNotification }) {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changePassword = () =>
    changePasswordHandler({
      oldPassword,
      setOldPassword,
      setOldPasswordError,
      password,
      setPassword,
      setPasswordError,
      passwordConfirmation,
      setPasswordConfirmation,
      setPasswordConfirmationError,
      t,
      showBottomNotification,
      isSubmitting,
      setIsSubmitting,
    }).then(message => {
      if (message !== 'Error') {
        window.location.reload();
      }
    });

  const { t } = useTranslation();
  return (
    <div>
      <HelpText className={styles.selectText}>
        {t('AccountSettings.Your current password can be changed here')}
      </HelpText>
      <div className={styles.formGroup}>
        <Label type='input'>{t('AccountSettings.Current password')} *</Label>
        <Input
          value={oldPassword}
          type='password'
          onChange={e => setOldPassword(e.target.value)}
          error={oldPasswordError}
        />
        {oldPasswordError && <div className={styles.error}>{oldPasswordError}</div>}
      </div>

      <div className={styles.formGroup}>
        <Label type='input'>{t('AccountSettings.New password')} *</Label>
        <Input
          value={password}
          type='password'
          onChange={e => setPassword(e.target.value)}
          error={passwordError}
        />
        {passwordError && <div className={styles.error}>{passwordError}</div>}
      </div>

      <div className={styles.formGroup}>
        <Label type='input'>{t('AccountSettings.Confirm new password')} *</Label>
        <Input
          value={passwordConfirmation}
          type='password'
          onChange={e => setPasswordConfirmation(e.target.value)}
          error={passwordConfirmationError}
        />
        {passwordConfirmationError && (
          <div className={styles.error}>{passwordConfirmationError}</div>
        )}
      </div>
      <div className={styles.error}>
        {t('AccountSettings.Changing the password will sign you out of ALL devices')}
      </div>
      <div className={styles.buttons}>
        <ButtonWithLoader type='primary' onClick={changePassword} isLoading={isSubmitting}>
          {t('Post.Save')}
        </ButtonWithLoader>
      </div>
    </div>
  );
}

export async function changePasswordHandler({
  oldPassword,
  setOldPassword,
  setOldPasswordError,
  password,
  setPassword,
  setPasswordError,
  passwordConfirmation,
  setPasswordConfirmation,
  setPasswordConfirmationError,
  t,
  showBottomNotification,
  isSubmitting,
  setIsSubmitting,
}) {
  if (isSubmitting) {
    return;
  }
  let isError = false;

  if (oldPassword.trim().length === 0) {
    isError = true;
    setOldPasswordError(`${t('Registration.Password cannot be blank')}`);
  } else {
    setOldPasswordError('');
  }

  if (password.trim().length === 0) {
    isError = true;
    setPasswordError(`${t('Registration.Password cannot be blank')}`);
  } else if (password.trim().length < 8) {
    isError = true;
    setPasswordError(`${t('Registration.Password length should be 8 or more')}`);
  } else {
    setPasswordError('');
  }

  if (passwordConfirmation.trim().length === 0) {
    isError = true;
    setPasswordConfirmationError(`${t('Registration.Password cannot be blank')}`);
  } else if (passwordConfirmation !== password) {
    isError = true;
    setPasswordConfirmationError(`${t('Registration.Passwords do not match')}`);
  } else {
    setPasswordConfirmationError('');
  }

  if (isError) return Promise.resolve('Error');

  setIsSubmitting(true);
  try {
    await changeUserPassword({ oldPassword, newPassword: password });

    setOldPassword('');
    setPassword('');
    setPasswordConfirmation('');

    showBottomNotification(t('BottomNotifications.Saved'));
    setIsSubmitting(false);
  } catch (ex) {
    setIsSubmitting(false);
    switch (ex.response.data.message) {
      case 'Passsowrd is Incorrect':
        return setOldPasswordError(t('AccountSettings.Password is incorrect'));
      case 'Password Format is not correct':
        return setPasswordError(
          t(
            'Registration.Password should contain at least 1 letter and 1 number or special character',
          ),
        );
      default:
        break;
    }
  }
}
