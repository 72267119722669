import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import sky from 'resources/images/awoww/awosky.jpg';
import gluecksspirale from 'resources/images/awoww/gluecksspirale.png';
import awoimmerdabeilogo from 'resources/images/awoww/awoimmerdabeilogo.png';
import CardHeading from 'library/common/commonComponents/to-refactor/commonCard/CardHeading';
import Button from 'library/common/commonComponents/Buttons/Button';
import Wrapper from 'library/common/commonComponents/Wrapper';
import styles from './awoww.module.scss';


export default function SkyWrapper({ title, body, footer }) {
  const { t } = useTranslation();

  return (
    <div className={styles.invalidLinkContainer}>
      <div style={{ backgroundImage: `url(${sky})`, backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "contain", height: "100%", backgroundPosition: "center right" }}>
        <img style={{ marginTop: "70px", maxHeight: "30%", maxWidth: "30%", height: "auto", boxShadow: "3px 3px 3px grey" }} src={awoimmerdabeilogo} /><br />
        <img style={{ marginTop: "10px", maxHeight: "10%", width: "20%", maxWidth: "150px", height: "auto", paddingLeft: "7px" }} src={gluecksspirale} />
        <div style={{ marginTop: "30px", marginLeft: "20px", textAlign: "left" }}>
          <div style={{ fontSize: "33px", fontWeight: "bold" }}>
            {title}
          </div>
        </div>
        <center>
          <div style={{ width: "85%", marginTop: "30px" }}>
            {body}
          </div>
        </center>
        <div style={{ position: "absolute", bottom: "40px", width: "100%", textAlign: "center" }}>
          {footer}
        </div>

        <div style={{ position: "absolute", bottom: "10px", width: "100%", textAlign: "center" }}>
          <Link className={styles.link} to='/auth-imprint'>
            {t('Header.Bottombar.Menu.imprint')}
          </Link>
          <Link className={styles.link} to='/auth-privacy'>
            {t('Header.Bottombar.Menu.dataProtection')}
          </Link>
        </div>
      </div>
    </div>

  );
}


