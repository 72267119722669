export function sortItems(array, sortField) {
  // eslint-disable-next-line
  const specialSymbol = /^[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const withoutSpecialArray = [];
  const specialArray = [];

  array.forEach(item => {
    // eslint-disable-next-line
    specialSymbol.test(item[sortField]) ? specialArray.push(item) : withoutSpecialArray.push(item);
  });

  const sortedArray = withoutSpecialArray.sort((a, b) => {
    const aName = a[sortField][0].toLowerCase();
    const bName = b[sortField][0].toLowerCase();

    return aName > bName ? 1 : bName > aName ? -1 : 0;
  });

  const result = sortedArray.concat(specialArray);

  return result;
}

export function sortMembers(array) {
  const sorted = [...array].sort(function(a, b) {
    const aLastNameChar = a.lastName ? a.lastName.charAt(0) : '';
    const bLastNameChar = b.lastName ? b.lastName.charAt(0) : '';
    const aFirstNameChar = a.firstName ? a.firstName.charAt(0) : '';
    const bFirstNameChar = b.firstName ? b.firstName.charAt(0) : '';

    const lastName = { aLastNameChar, bLastNameChar };
    const firstName = { aFirstNameChar, bFirstNameChar };

    return compare(lastName, firstName);
  });

  return sorted;
}

const compare = (lastName, firstName) => {
  const { aLastNameChar, bLastNameChar } = lastName;
  const { aFirstNameChar, bFirstNameChar } = firstName;

  if (aLastNameChar > bLastNameChar) {
    return 1;
  } else if (aLastNameChar < bLastNameChar) {
    return -1;
  } else {
    if (aFirstNameChar > bFirstNameChar) {
      return 1;
    } else if (aFirstNameChar < bFirstNameChar) {
      return -1;
    } else {
      return 0;
    }
  }
};

export const sortKitaForFavorite = kitaList => {
  const favorites = [];
  const normal = [];
  for (let i = 0; i < kitaList.length; i++) {
    if (kitaList[i].favorite) {
      favorites.push(kitaList[i]);
    } else {
      normal.push(kitaList[i]);
    }
  }

  sortAlphabetical(favorites);
  sortAlphabetical(normal);

  return favorites.concat(normal);
};

function sortAlphabetical(list) {
  list.sort(function(a, b) {
    const textA = a.kitaName.toUpperCase();
    const textB = b.kitaName.toUpperCase();

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
}

export const sortList = (result, sorting, additionalSortField) => {
  if (result.length > 0) {
    let sorted = [];
    if (sorting.direction === 'asc') {
      sorted = [...result].sort((a, b) =>
        a[sorting.name] > b[sorting.name]
          ? 1
          : a[sorting.name] === b[sorting.name]
          ? a[additionalSortField] > b[additionalSortField]
            ? 1
            : -1
          : -1,
      );
    } else {
      sorted = [...result].sort((a, b) =>
        a[sorting.name] > b[sorting.name]
          ? -1
          : a[sorting.name] === b[sorting.name]
          ? a[additionalSortField] > b[additionalSortField]
            ? -1
            : 1
          : 1,
      );
    }
    return sorted;
  }
  return [];
};
