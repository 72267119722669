import React from 'react';
import cn from 'classnames';

import Tooltip from 'library/common/commonComponents/Tooltip';

import styles from './listItemElementCounter.module.scss';

export default function ListItemElementCounter({
  title,
  faIcon,
  onClick,
  isActive,
  theme,
  className,
  iconColor,
  tooltip,
  addClassName,
  count,
  showCounterIfZero,
}) {
  return tooltip ? (
    <Tooltip {...tooltip}>
      <div
        className={cn(styles.item, getClassByTheme(theme), className, isActive && styles.active)}
        onClick={onClick || (() => {})}
      >
        {faIcon && <i className={cn(styles.icon, 'fa', faIcon)} />}
        <div className={cn(styles.itemTitle)}>{title}</div>
        {(count !== 0 || showCounterIfZero) && (
          <div className={cn(styles.counter)}>
            <span>{count > 99 ? 99 : count}</span>
          </div>
        )}
      </div>
    </Tooltip>
  ) : (
    <div
      className={cn(styles.item, getClassByTheme(theme), className, isActive && styles.active)}
      onClick={onClick || (() => {})}
    >
      {faIcon && <i style={{ color: iconColor }} className={cn(styles.icon, 'fa', faIcon)} />}
      <div className={cn(styles.itemTitle, addClassName && styles[addClassName])}>{title}</div>
      {(count !== 0 || showCounterIfZero) && (
        <div className={cn(styles.counter)}>
          <span>{count > 99 ? 99 : count}</span>
        </div>
      )}
    </div>
  );
}

export function getClassByTheme(theme) {
  if (
    window.location.hostname.toLowerCase().includes('bvf4u.safe2connect.org') &&
    theme === 'yellow'
  ) {
    return styles.themeBlue;
  }
  if (
    window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org') &&
    theme === 'yellow'
  ) {
    return styles.themeBrown;
  }
  switch (theme) {
    case 'light':
      return styles.themeLight;
    case 'yellow':
      return styles.themeYellow;
    case 'dark':
      return styles.themeDark;

    default:
      return styles.themeDark;
  }
}

ListItemElementCounter.defaultProps = {
  iconColor: 'inherit',
};
