import React, { useEffect, useState } from 'react';

const loadedImages = {};

const date = Date.now();

export default function useCachedImage({ originalSrc, fallbackImage, props, onLoad }) {
  const hasSearchParams = originalSrc ? new URL(originalSrc).search.length : false;
  const [imageSource, setImageSource] = useState(loadedImages[originalSrc] || fallbackImage);

  const refreshImage = () => {
    const newUrl = originalSrc
      ? `${originalSrc}${hasSearchParams ? '&' : '?'}t=${date}`
      : fallbackImage;
    setImageSource(newUrl);
  };

  useEffect(() => {
    if (originalSrc) {
      const link = originalSrc
        ? `${originalSrc}${hasSearchParams ? '&' : '?'}t=${date}`
        : fallbackImage;
      if (loadedImages[originalSrc]) {
        setImageSource(loadedImages[originalSrc]);
      } else {
        loadedImages[originalSrc] = link;
        setImageSource(link);
      }
    }
  }, [originalSrc]);

  return {
    CachedImage: <img src={imageSource} alt={props.alt} onLoad={onLoad} {...props} />,
    refreshImage,
  };
}
