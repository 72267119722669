import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Wrapper from 'library/common/commonComponents/Wrapper';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import useEditor from 'library/common/commonHooks/useEditor';

import { checkLink } from 'library/utilities/checkLink';

import cn from 'classnames';
import { getUploadFilesHandler } from 'library/utilities/getUploadFilesHandler';
import styles from './suggestionBox.module.scss';
import Checkbox from '../Checkbox';
import Label from '../Label';
import Input from '../Inputs/Input';
import InputErrorMsg from '../to-refactor/commonInputErrorMsg';
import UploadButton from '../Buttons/UploadButton';
import FilePrewiev from '../FilePrewiev';
import ButtonWithLoader from '../Buttons/ButtonWithLoader';
import useMultipleUpload from '../../commonHooks/useMultipleUpload';
import Card from '../Card';
import { sendNewSuggestionMessage } from '../../../api/suggestionBox';
import { getActiveKitaAdmins } from '../../../api/user';
import { validateSuggestionBoxMessage } from '../../../utilities/suggestionBox';

export default function SuggestionBox({ showBottomNotification, user, activeKita }) {
  useSiteTitle('SuggestionBox');
  const { t } = useTranslation();
  const [receiverKita, setReceiverKita] = useState(true);
  const [anonymous, setAnonymous] = useState(true);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subject, setSubject] = useState('');
  const [error, setError] = useState({
    subject: false,
    message: false,
  });
  const [progress, setProgress] = useState(null);
  const { files, uploadFile, deleteFiles, setFiles } = useMultipleUpload(null);

  const [textareaMarginTop, setTextareaMarginTop] = useState(0);

  const { Editor, getEditorValue, resetEditorValue } = useEditor({
    message,
    placeholder: t('Chat.Write a message'),
    onOpen: height => setTextareaMarginTop(height + 10),
    onClose: () => setTextareaMarginTop(0),
    onChange: () => {
      const value = getEditorValue();
      setError(prevError => ({ ...prevError, message: false }));
      setMessage(checkLink(value));
    },
    className: styles.textareaContent,
    isChat: false,
  });

  const handleSubmit = async () => {
    const kitaAdminIds = await getActiveKitaAdmins();
    const chatInfo = {
      message,
      subject,
      receiverKita,
      anonymous,
      kitaAdminIds: kitaAdminIds.data,
      kitaId: activeKita.kitaId,
      files,
    };
    const { valid, errors } = validateSuggestionBoxMessage(message, subject, t);
    setError(errors);
    if (valid) {
      setIsSubmitting(true);
      return sendNewSuggestionMessage(chatInfo, activeKita.kitaId, files, setProgress)
        .then(() => {
          showBottomNotification(t('SuggestionBox.message has been sent'), { isFail: false });
          setIsSubmitting(false);
          setSubject('');
          setFiles([]);
          resetEditorValue();
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            showBottomNotification(t(`Chat.${err.response.data.message}`), { isFail: true });
          }
          setIsSubmitting(false);
        });
    }
  };

  const uploadFilesHandler = getUploadFilesHandler({
    chatId: 0,
    userId: user.id,
    uploadFile,
    showBottomNotification,
    t,
  });
  // TODO upload files #nico.achterberg

  return (
    <Wrapper>
      <Card
        className={styles.cardContainer}
        title={t('SuggestionBox.title')}
        titleClassName={styles.bodyTitle}
      >
        <div className={styles.container}>
          <div className={styles.usersInput}>
            <Label type='input'>
              <Trans i18nKey='Chat.Recipient' />
            </Label>
            <Checkbox
              className={styles.receiverCheckbox}
              name={t('SuggestionBox.Kita admin')}
              isChecked={receiverKita}
              onChange={() => setReceiverKita(!receiverKita)}
            />
          </div>
          <div className={styles.chatNameContainer}>
            <Label type='input'>
              <Trans i18nKey='Chat.Subject' />
            </Label>
            <Input
              type='text'
              error={error.subject}
              value={subject}
              onChange={e => setSubject(e.target.value)}
            />
            {error.subject && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={t('Chat.Subject cannot be blank')} />
              </div>
            )}
          </div>
          <div
            className={cn(styles.messageContainer, error.message && styles.messageContainerError)}
            style={{ marginTop: textareaMarginTop }}
          >
            <Editor />
            {error.message && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={t('Chat.Message cannot be blank')} />
              </div>
            )}
          </div>
          <div className={styles.row}>
            <UploadButton
              className={styles.uploadButton}
              user={user}
              onChange={uploadFilesHandler}
              isMultiple
              files={files}
              maxFileSize={200}
              activeKita={activeKita}
            />
            <Checkbox
              name={
                <>
                  <i className='fa fa-eye-slash' /> <strong>Anonym senden?</strong>
                </>
              }
              isChecked={anonymous}
              onChange={() => setAnonymous(!anonymous)}
            />
          </div>

          {progress && files.length > 0 && (
            <div className={styles.progressBar}>
              <div className={styles.progressBarValue} style={{ width: progress + '%' }} />
            </div>
          )}
          <FilePrewiev newFiles={files} onDelete={deleteFiles} />
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonSend}>
              <ButtonWithLoader onClick={handleSubmit} type='primary' isLoading={isSubmitting}>
                {t('Chat.Send')}
              </ButtonWithLoader>
            </div>
          </div>
        </div>
      </Card>
    </Wrapper>
  );
}
