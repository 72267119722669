import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';

import { isActiveNavLink } from 'library/utilities/router';
import { isAwoWW } from 'library/api/tenantConfig';
import ListItemElement from './listItemFrames/ListItemElement';

import styles from './listItem.module.scss';

export default function ListItem({ to, isNavLink, ...props }) {
  return to
    ? isNavLink
      ? (
        // eslint-disable-next-line
        <NavLink isActive={isActiveNavLink.bind(this, to)} activeClassName={cn(styles.active, isAwoWW() && styles.activeAwoWW)} to={to}>
          {/* eslint-disable-next-line */}
          <ListItemElement {...props} />
        </NavLink>
      )
      :
        <Link to={to}>
          <ListItemElement {...props} />
        </Link>
    : <ListItemElement {...props} />
}
