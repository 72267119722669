import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PT from 'prop-types';

import { getCheckInOutKitaConfig } from 'library/api/kids';
import { getKitaVerificationImages } from 'library/api/kita';
import Input from 'library/common/commonComponents/Inputs/Input';
import { GroupContext } from 'modules/Group/groupModule';
import ChildRow from './ChildRow';

import styles from './childesContainer.module.scss';

const ChildesContainer = ({ data, handleUpdateKidStatus, setKidData }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const [verificationImages, setVerificationImages] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const [locationHost] = useState(window.location.hostname);

  const activeKita = useSelector(state => state.kitaReducer.activeKita);

  const groupContext = useContext(GroupContext);
  const { groupInfo } = groupContext;

  const isHortType = (activeKita?.kitaTypeTranslations || []).includes('Hort');
  const [groupSelectionOnCheckinActive, setGroupSelectionOnCheckinActive] = useState(groupInfo.defaultGroup);
  const [notifyToParentsOnCheckInOutActive, setNotifyToParentsOnCheckInOutActive] = useState(false);

  const filteredData = useMemo(() => {
    let queryData;
    if (searchValue) {
      queryData = data.filter(
        item =>
          item.name?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
          item.groupName?.toLowerCase()?.includes(searchValue.toLowerCase()),
      );
    } else {
      queryData = data.slice();
    }

    if (sortBy && sortOrder) {
      queryData.sort((prev, next) => {
        const prevValue = prev[sortBy];
        const nextValue = next[sortBy];

        if (!prevValue && !nextValue) {
          return 0;
        }

        if (!prevValue) {
          return sortOrder === 'ASC' ? -1 : 1;
        }

        if (!nextValue) {
          return sortOrder === 'ASC' ? 1 : -1;
        }

        if (sortOrder === 'ASC') {
          if (prevValue < nextValue) {
            return -1;
          }
          if (prevValue > nextValue) {
            return 1;
          }
          return 0;
        } else {
          if (prevValue > nextValue) {
            return -1;
          }
          if (prevValue < nextValue) {
            return 1;
          }
          return 0;
        }
      });
    }

    return queryData;
  }, [data, searchValue, sortBy, sortOrder]);

  useEffect(() => {
    getCheckInOutKitaConfig().then(res => {
      setNotifyToParentsOnCheckInOutActive(res.data.checkinoutNotificationsActiveParent);
      if (groupSelectionOnCheckinActive && !res.data.checkinGroupSelectionActive) {
        setGroupSelectionOnCheckinActive(false);
      }
    });
  }, []);

  useEffect(() => {
    getKitaVerificationImages(activeKita.kitaId).then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const result = res.data;
        result.sort((a, b) => a.sequence - b.sequence);
        setVerificationImages(result);
      }
    });
  }, []);

  useEffect(() => {
    if (setKidData) {
      setKidData(data)
    }
  }, [filteredData]);

  const handleSortChange = key => {
    let sortKey = key;
    let sortDirection;

    if (!sortBy || sortBy !== key) {
      sortDirection = 'ASC';
    } else if (sortOrder === 'DESC') {
      sortKey = '';
      sortDirection = '';
    } else {
      sortDirection = 'DESC';
    }

    setSortBy(sortKey);
    setSortOrder(sortDirection);
  };

  return (
    <div className={styles.childrenContainerWrapper}>
      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>
      <div className={styles.header}>
        <div className={styles.name} onClick={() => handleSortChange('name')}>
          {t('Checkinout.Name')}
          {sortBy === 'name' && sortOrder && (
            <i className={sortOrder === 'ASC' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />
          )}
        </div>
        {isHortType && (
          <div className={styles.class} onClick={() => handleSortChange('className')}>
            {t('Checkinout.Class')}
            {sortBy === 'className' && sortOrder && (
              <i className={sortOrder === 'ASC' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />
            )}
          </div>
        )}
        <div className={styles.group} onClick={() => handleSortChange('groupName')}>
          {t('Checkinout.Group')}
          {sortBy === 'groupName' && sortOrder && (
            <i className={sortOrder === 'ASC' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />
          )}
        </div>
        {isHortType && (
          <div className={styles.group} onClick={() => handleSortChange('mainGroupName')}>
            {t('Checkinout.Main Group')}
            {sortBy === 'mainGroupName' && sortOrder && (
              <i className={sortOrder === 'ASC' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />
            )}
          </div>
        )}
      </div>
      {filteredData.map(item => {
        return (
          <ChildRow
            key={item.kidId}
            isHortType={isHortType}
            childObj={item}
            groupSelectionOnCheckinActive={groupSelectionOnCheckinActive}
            notifyToParentsOnCheckInOutActive={notifyToParentsOnCheckInOutActive}
            verificationImages={verificationImages}
            handleUpdateKidStatus={handleUpdateKidStatus}
          />
        );
      })}
    </div>
  );
};

ChildesContainer.propTypes = {
  data: PT.arrayOf(PT.object).isRequired,
  handleUpdateKidStatus: PT.func.isRequired,
};

export default ChildesContainer;
