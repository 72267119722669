import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { sanitize } from 'dompurify';
import defaultSignatureImage from 'resources/images/formEditor/defaultSignatureImage.png';
import RadioButton from 'library/common/commonComponents/RadioButton';
import styles from './defaultContractEdit.module.scss';
import Select from '../../library/common/commonComponents/Inputs/Select';
import moment from 'moment/min/moment-with-locales';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Loader from '../../library/common/commonComponents/Loader';
import {
  getSettings,
  getDefaultContractSubmission,
  saveDefaultContractSubmission,
  getAllKidsWithNameAndIdFromSelectedKita,
  getAllKidsForUserWithNameAndIdFromSelectedKita,
  getKidWithDetailsAndParentInformationById,
  storeUserSignatureImage,
  getUserSignatureImage,
  getAllUsersWithNameAndIdFromSelectedKita,
} from '../../library/api/formEditor';
import Button from '../../library/common/commonComponents/Buttons/Button';
import {
  generateContract,
  updateGenderStringToGerman,
  tableParentalContributionCalculateRelevantRow,
  determineCheckboxConditionActionEffects,
} from '../../library/utilities/forms';
import Tabs from 'library/common/commonComponents/Tabs';
import * as filesApi from 'library/api/files';
import Popup from '../../library/common/commonComponents/Popups/Popup';
import ButtonWithLoader from '../../library/common/commonComponents/Buttons/ButtonWithLoader';
import ParentalContributionTable from './DefaultContractEditFrames/ParentalContributionTable';
import BookingTimesTable from './DefaultContractEditFrames/BookingTimesTable';
import ParentalContributionWithHolidaysTable from './DefaultContractEditFrames/ParentalContributionWithHolidaysTable';
import InputFormContractContainer from './InputFormContractContainer';
import CheckboxFormContractContainer from './CheckboxFormContractContainer';
import Input from 'library/common/commonComponents/Inputs/Input';
import HelpText from 'library/common/commonComponents/HelpText';
import { EmptyStateSVG } from './emptyStateSVG';
import RadioButtonGroupContainer from 'library/common/commonComponents/RadioButtonGroupContainer/RadioButtonGroupContainer';
import { getFormConfig } from 'library/api/kita';
import { getUserById } from 'library/api/user';

function DefaultContractEdit({ user, activeKita }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [userDefaultSignatureImage, setUserDefaultSignatureImage] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [childs, setChilds] = useState([]);
  const [kidOrUser, setKidOrUser] = useState(false);

  const [submitData, setSubmitData] = useState(null);

  const [form, setForm] = useState(null);

  const [newAttachments, setNewAttachments] = useState([]);
  const [deletedAttachments, setDeletedAttachments] = useState([]);

  const isAdmin = user.administrationAccess;
  const isEmployee = user.employee;

  const [isParent, setIsParent] = useState(false);

  const getChildsFunction = (isAdmin || isEmployee)
    ? getAllKidsWithNameAndIdFromSelectedKita
    : getAllKidsForUserWithNameAndIdFromSelectedKita;

  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const [errors, setErrors] = useState([]);
  const [showFinalizedMessage, setShowFinalizedMessage] = useState(false);

  const [showCannotEditPopup, setShowCannotEditPopup] = useState(false);
  const [cannotEditPopupReason, setCannotEditPopupReason] = useState('');

  const [isSavingContract, setIsSavingContract] = useState(false);

  const [showCompleteProfilePopup, setShowCompleteProfilePopup] = useState(false);
  const [alreadyShowedCompleteProfilePopup, setAlreadyShowedCompleteProfilePopup] = useState(false);

  const history = useHistory();


  const rebuildTabs = form => {
    let pages = [];

    form.formFields.forEach(ff => {
      if (pages.filter(p => p.id === ff.page.id).length === 0) {
        pages.push(ff.page);
      }
    });

    const checkboxActionEffects = determineCheckboxConditionActionEffects(form, submitData);
    checkboxActionEffects.forEach(e => {
      switch (e.action) {
        case 'ENABLE_PAGE':
          break;
        case 'DISABLE_PAGE':
          const pageId = e.args[0];

          pages = pages.filter(p => p.id !== pageId);

          break;
      }
    });

    const pageTabs = pages.map(p => {
      return {
        title: p.name,
        component: (
          <div>
            {form.formFields
              .filter(ff => ff.page.id === p.id)
              .map(ff => {
                return renderComponent(ff);
              })}
          </div>
        ),
        path: `page${p.pageNumber}`,
        pageNumber: p.pageNumber,
      };
    });

    pageTabs.sort((a, b) => a.pageNumber - b.pageNumber);

    const filteredTabs = pageTabs.filter(
      tab =>
        tab.title !== 'Vollmacht' &&
        tab.title !== 'Einwilligungserklärung zur Nutzung des sozialen Netzwerks Stramplerbande' &&
        tab.title !== 'Seite 22',
    );

    setTabs(filteredTabs);
  };

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  function rotateBase64Image90deg(base64Image, isClockwise) {
    // create an off-screen canvas
    var offScreenCanvas = document.createElement('canvas');
    var offScreenCanvasCtx = offScreenCanvas.getContext('2d');

    // cteate Image
    var img = new Image();
    img.src = base64Image;

    // set its dimension to rotated size
    offScreenCanvas.height = img.width;
    offScreenCanvas.width = img.height;

    // rotate and draw source image into the off-screen canvas:
    if (isClockwise) {
      offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
      offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
    } else {
      offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
      offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
    }
    offScreenCanvasCtx.drawImage(img, 0, 0);

    // encode image to data-uri with base64
    return offScreenCanvas.toDataURL('image/jpeg', 100);
  }

  useEffect(() => {
    setIsLoading(true);
    getFormConfig().then((res) => {
      if (res) {
        if (res.data) {
          setKidOrUser(true);
          getAllUsersWithNameAndIdFromSelectedKita().then(res => {

            setIsLoading(false);
            let data = []
            res.data.forEach((item) => {
              data.push(item.user);
            })
            data = data.filter((item) => item.superAdmin == false);
            setChilds(data || []);

            getSettings()
              .then(res => {
                setForm(res.data.defaultContractForm);
              })
              .catch(() => {
                store.dispatch(
                  showBottomNotification(t('DefaultContractEdit.LoadFormError'), { isFail: true }),
                );
              });

          })
        } else {
          setKidOrUser(false);
          getChildsFunction()
            .then(res => {
              res.data.sort((a, b) => {
                return a.lastName < b.lastName ? -1 : 1;
              });
              setChilds(res.data || []);

              if (res.data && res.data.length > 0 && !isAdmin && !isEmployee) {
                getKidWithDetailsAndParentInformationById(res.data[0].id).then(kidData => {
                  setSelectedChild(kidData.data);
                });
              } else {
                setSelectedChild(null);
              }

              getSettings()
                .then(res => {
                  setForm(res.data.defaultContractForm);
                })
                .catch(() => {
                  store.dispatch(
                    showBottomNotification(t('DefaultContractEdit.LoadFormError'), { isFail: true }),
                  );
                });
            })
            .catch(() => {
              store.dispatch(
                showBottomNotification(t('DefaultContractEdit.LoadChildsError'), { isFail: true }),
              );
            })
            .finally(() => {
              setIsLoading(false);
            });

        }
      }
    })
  }, [activeKita]);

  function getPlaceholderValueOfTextInput(ff) {
    let value = ff.presetValueType || '';

    const isDate = ff.inputType === 'date';

    const childBirthdayDate = moment(selectedChild.birthday, 'DD.MM.yyyy');
    const childBirthdayString =
      !selectedChild.birthday || (!childBirthdayDate.isValid() && isDate)
        ? ''
        : isDate
          ? childBirthdayDate.format('yyyy-MM-DD')
          : selectedChild.birthday;

    const parent1BirthdayDate = moment(selectedChild.parent1Birthday);
    const parent1BirthdayString =
      !selectedChild.parent1Birthday || (!parent1BirthdayDate.isValid() && isDate)
        ? ''
        : isDate
          ? parent1BirthdayDate.format('yyyy-MM-DD')
          : selectedChild.parent1Birthday;

    const parent2BirthdayDate = moment(selectedChild.parent2Birthday);
    const parent2BirthdayString =
      !selectedChild.parent2Birthday || (!parent2BirthdayDate.isValid() && isDate)
        ? ''
        : isDate
          ? parent2BirthdayDate.format('yyyy-MM-DD')
          : selectedChild.parent2Birthday;

    value = value
      .replace('[[VornameKind]]', selectedChild.firstName || '')
      .replace('[[NachnameKind]]', selectedChild.lastName || '')
      .replace('[[GeburtstagKind]]', childBirthdayString)
      .replace('[[StadtKind]]', selectedChild.city || '')
      .replace('[[GeschlechtKind]]', updateGenderStringToGerman(selectedChild.gender) || '')
      .replace('[[BundeslandKind]]', selectedChild.state || '')
      .replace('[[StrasseKind]]', selectedChild.street || '')
      .replace('[[PostleitzahlKind]]', selectedChild.zip || '')

      .replace('[[VornameElternteil1]]', selectedChild.parent1FirstName || '')
      .replace('[[NachnameElternteil1]]', selectedChild.parent1LastName || '')
      .replace('[[GeburtstagElternteil1]]', parent1BirthdayString)
      .replace('[[StadtElternteil1]]', selectedChild.parent1City || '')
      .replace('[[EmailElternteil1]]', selectedChild.parent1Email || '')
      .replace(
        '[[GeschlechtElternteil1]]',
        updateGenderStringToGerman(selectedChild.parent1Gender) || '',
      )
      .replace('[[MobilTelefonElternteil1]]', selectedChild.parent1Mobile || '')
      .replace('[[PrivatTelefonElternteil1]]', selectedChild.parent1PhonePrivate || '')
      .replace('[[ArbeitTelefonElternteil1]]', selectedChild.parent1PhoneWork || '')
      .replace('[[BundeslandElternteil1]]', selectedChild.parent1State || '')
      .replace('[[LandElternteil1]]', selectedChild.parent1Country || '')
      .replace('[[StrasseElternteil1]]', selectedChild.parent1Street || '')
      .replace('[[TitelElternteil1]]', selectedChild.parent1Title || '')
      .replace('[[PostleitzahlElternteil1]]', selectedChild.parent1Zip || '')
      .replace('[[MutterspracheElternteil1]]', selectedChild.parent1MotherLanguage || '')
      .replace('[[HauptspracheElternteil1]]', selectedChild.parent1MainLanguage || '')
      .replace('[[KonfessionElternteil1]]', selectedChild.parent1Denomination || '')
      .replace('[[ArbeitsplatzElternteil1]]', selectedChild.parent1WorkingPlace || '')
      .replace('[[FamilienstandElternteil1]]', selectedChild.parent1MaritalStatus || '')

      .replace('[[VornameElternteil2]]', selectedChild.parent2FirstName || '')
      .replace('[[NachnameElternteil2]]', selectedChild.parent2LastName || '')
      .replace('[[GeburtstagElternteil2]]', parent2BirthdayString)
      .replace('[[StadtElternteil2]]', selectedChild.parent2City || '')
      .replace('[[EmailElternteil2]]', selectedChild.parent2Email || '')
      .replace(
        '[[GeschlechtElternteil2]]',
        updateGenderStringToGerman(selectedChild.parent2Gender) || '',
      )
      .replace('[[MobilTelefonElternteil2]]', selectedChild.parent2Mobile || '')
      .replace('[[PrivatTelefonElternteil2]]', selectedChild.parent2PhonePrivate || '')
      .replace('[[ArbeitTelefonElternteil2]]', selectedChild.parent2PhoneWork || '')
      .replace('[[BundeslandElternteil2]]', selectedChild.parent2State || '')
      .replace('[[LandElternteil2]]', selectedChild.parent2Country || '')
      .replace('[[StrasseElternteil2]]', selectedChild.parent2Street || '')
      .replace('[[TitelElternteil2]]', selectedChild.parent2Title || '')
      .replace('[[PostleitzahlElternteil2]]', selectedChild.parent2Zip || '')
      .replace('[[MutterspracheElternteil2]]', selectedChild.parent2MotherLanguage || '')
      .replace('[[HauptspracheElternteil2]]', selectedChild.parent2MainLanguage || '')
      .replace('[[KonfessionElternteil2]]', selectedChild.parent2Denomination || '')
      .replace('[[ArbeitsplatzElternteil2]]', selectedChild.parent2WorkingPlace || '')
      .replace('[[FamilienstandElternteil2]]', selectedChild.parent2MaritalStatus || '')

      .replace(
        '[[KitaName]]',
        activeKita.kitaName.endsWith('Betreuungsverträge')
          ? activeKita.kitaName.replace(/Betreuungsverträge(?![\s\S]*Betreuungsverträge)/, '')
          : activeKita.kitaName,
      )
      .replace('[[KitaStrasse]]', activeKita.streetName)
      .replace('[[KitaHausNr]]', activeKita.houseNo)
      .replace('[[KitaPLZ]]', activeKita.zipCode)
      .replace('[[KitaStadt]]', activeKita.city)
      .replace('[[TrägerName]]', activeKita.organizationName)
      .replace('[[TrägerVertretung]]', activeKita.organizationRepresentedBy)
      .replace('[[TrägerAdresse]]', activeKita.organizationAddress);

    if (isDate) {
      value = value.replace(' ', '');
    }

    return value;
  }

  useEffect(() => {
    if (!form || !selectedChild) {
      return;
    }

    setIsLoading(true);

    form.formFields.sort((a, b) => {
      const pageDiff = a.page.pageNumber - b.page.pageNumber;

      return pageDiff === 0 ? a.position - b.position : pageDiff;
    });

    getDefaultContractSubmission(form.id, selectedChild.kidId || selectedChild.id, kidOrUser)
      .then(res => {
        const newSubmitData = res.data;

        const findSignature = form.formFields.find(obj => obj.inputType === 'signatureImage');
        if (typeof findSignature !== 'undefined' && userDefaultSignatureImage === null) {
          getUserSignatureImage().then(data => {
            if (data.data && data.data.signatureImage) {
              setUserDefaultSignatureImage(data.data.signatureImage);
            }
          });
        }
        form.formFields.forEach(ff => {
          if (ff.id in newSubmitData.formFields) {
            return;
          }
          if (ff.type === 'checkbox') {
            newSubmitData.formFields[`${ff.id}`] = ff.defaultChecked ? 'true' : 'false';
          } else if (ff.type === 'dropdown') {
            const defaultOption = Math.max(ff.defaultOption, 0);
            newSubmitData.formFields[`${ff.id}`] = ff.options[defaultOption];
          } else if (ff.type === 'input') {
            let value = getPlaceholderValueOfTextInput(ff);
            if (value !== "" && value !== " " && value !== null) {
              newSubmitData.formFields[`${ff.id}`] = value;
            }
          } else if (ff.type === 'doubleInput') {
            if (ff.inputLeft && ff.inputLeft.presetValueType) {
              let valueLeft = getPlaceholderValueOfTextInput(ff.inputLeft);
              if (valueLeft !== "" && valueLeft !== " " && valueLeft !== null) {
                newSubmitData.formFields[`${ff.inputLeft.id}`] = valueLeft;
              }
            }
            if (ff.inputRight && ff.inputRight.presetValueType) {
              let valueRight = getPlaceholderValueOfTextInput(ff.inputRight);
              if (valueRight !== "" && valueRight !== " " && valueRight !== null) {
                newSubmitData.formFields[`${ff.inputRight.id}`] = valueRight;
              }
            }
          } else if (ff.type === 'doubleDropdown') {
            if (ff.dropdownLeft && ff.dropdownLeft.options) {
              const defaultOption = Math.max(ff.dropdownLeft.defaultOption, 0);
              newSubmitData.formFields[`${ff.dropdownLeft.id}`] =
                ff.dropdownLeft.options[defaultOption];
            }
            if (ff.dropdownRight && ff.dropdownRight.options) {
              const defaultOption = Math.max(ff.dropdownRight.defaultOption, 0);
              newSubmitData.formFields[`${ff.dropdownRight.id}`] =
                ff.dropdownRight.options[defaultOption];
            }
          }
        });

        setSubmitData(newSubmitData);
        setIsLoading(false);
      })
      .finally(() => {
        setIsInitialLoading(false);
      });
  }, [form, selectedChild]);

  useEffect(() => {
    if (!form) {
      return;
    }

    rebuildTabs(form, errors);
  }, [form, submitData, userDefaultSignatureImage]);

  useEffect(() => {
    if (selectedChild && user) {
      const isParent1 = selectedChild.parent1Id === user.id;
      const isParent2 = selectedChild.parent2Id === user.id;

      setIsParent(isParent1 || isParent2);

      if (
        !alreadyShowedCompleteProfilePopup &&
        ((isParent1 &&
          (!selectedChild.parent1Street || selectedChild.parent1Street.replace(' ', '') === '')) ||
          (isParent2 &&
            (!selectedChild.parent2Street || selectedChild.parent2Street.replace(' ', '') === '')))
      ) {
        setShowCompleteProfilePopup(true);
        setAlreadyShowedCompleteProfilePopup(true);
      }
    } else {
      setIsParent(false);
    }
  }, [selectedChild]);

  const handleCannotEditPopup = (cannotEdit, allowParentsFillOut, allowKitaFillOut) => {
    if (cannotEdit) {
      setShowCannotEditPopup(true);
      setCannotEditPopupReason(
        !allowParentsFillOut ? (!allowKitaFillOut ? 'both' : 'parent') : 'admin',
      );
    }
  };

  const handleCannotSelectDatePopup = futureIsForbidden => {
    /*setShowNotAllowedDatePopup(true);
    setCannotSelectDateReason(
      futureIsForbidden ? 'future' : 'past',
    );*/
  };

  const checkDateAllowance = (ff, targetDate) => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const dateToCompare = new Date(targetDate).setHours(0, 0, 0, 0);
    if (ff.isFutureDateForbidden) return !(dateToCompare > currentDate);
    else if (ff.isPastDateForbidden) return !(dateToCompare < currentDate);
    else return true;
  };

  const renderComponent = ff => {
    const cannotEditAsParent = !ff.allowParentsFillOut && isParent;
    const cannotEditAsAdmin = !ff.allowKitaFillOut && (isAdmin || isEmployee);
    const cannotEdit =
      (!isParent || cannotEditAsParent) && ((!isAdmin && !isEmployee) || cannotEditAsAdmin);

    if (ff.type === 'header') {
      return (
        <p
          style={{
            marginLeft: `${ff.indent}pt`,
            marginBottom: '1em',
            fontSize: `${ff.fontSize}pt`,
            fontWeight: ff.bold ? 'bold' : 'normal',
            fontStyle: ff.italic ? 'italic' : 'normal',
            textDecoration: ff.underlined ? 'underline' : 'none',
            textAlign: ff.alignment,
          }}
        >
          {user.currentWatsonLang !== 'de' ? (
            <WithWatsonTranslate
              data={{ text: ff.text, entityId: ff.id, entityType: 'formEditor' }}
              Component={({ html }) => (
                <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
              )}
            />
          ) : (
            ff.text
          )}
        </p>
      );
    } else if (ff.type === 'paragraph') {
      return (
        <p
          style={{
            marginLeft: `${ff.indent}pt`,
            marginBottom: '0.5em',
            fontSize: `${ff.fontSize}pt`,
            fontWeight: ff.bold ? 'bold' : 'normal',
            fontStyle: ff.italic ? 'italic' : 'normal',
            textDecoration: ff.underlined ? 'underline' : 'none',
            textAlign: ff.alignment,
            whiteSpace: 'pre-line',
          }}
        >
          {user.currentWatsonLang !== 'de' ? (
            <WithWatsonTranslate
              data={{ text: ff.text, entityId: ff.id, entityType: 'formEditor' }}
              Component={({ html }) => (
                <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
              )}
            />
          ) : (
            ff.text
          )}
        </p>
      );
    } else if (ff.type === 'input') {
      return (
        <InputFormContractContainer user={user} ff={ff}>
          {ff.inputType === 'signatureImage' ? (
            <>
              <br />
              {userDefaultSignatureImage === null
                ? !cannotEdit && (
                  <>
                    <Input
                      type='file'
                      style={{
                        display: 'inline',
                        width:
                          ff.labelRight == null || ff.labelRight.replace(' ', '') === ''
                            ? '100%'
                            : '35%',
                      }}
                      containerStyle={{ display: 'inline' }}
                      disabled={cannotEdit}
                      accept='image/*'
                      onChange={e => {
                        if (e.target.files[0].type.startsWith('image')) {
                          convertBase64(e.target.files[0]).then(data => {
                            storeUserSignatureImage(data).then(newData => {
                              setUserDefaultSignatureImage(data);
                              setSubmitData({
                                ...submitData,
                                formFields: {
                                  ...submitData.formFields,
                                  [`${ff.id}`]: data,
                                },
                              });
                            });
                          });
                        }
                      }}
                    />
                    <br />
                    <br />
                  </>
                )
                : !cannotEdit && (
                  <>
                    <Button
                      onClick={() => {
                        setSubmitData({
                          ...submitData,
                          formFields: {
                            ...submitData.formFields,
                            [`${ff.id}`]: userDefaultSignatureImage,
                          },
                        });
                      }}
                    >
                      {t('DefaultContractEdit.Insert default signature image')}
                    </Button>
                    <br />
                    {typeof submitData.formFields[ff.id] !== 'undefined' &&
                      submitData.formFields[ff.id] !== null &&
                      submitData.formFields[ff.id] !== '' && (
                        <>
                          <i
                            className='fa fa-rotate-right'
                            onClick={() => {
                              setIsLoading(true);
                              const rotatedImage = rotateBase64Image90deg(
                                submitData.formFields[ff.id],
                                true,
                              );
                              storeUserSignatureImage(rotatedImage).then(data => {
                                setUserDefaultSignatureImage(rotatedImage);
                                setSubmitData({
                                  ...submitData,
                                  formFields: {
                                    ...submitData.formFields,
                                    [`${ff.id}`]: rotatedImage,
                                  },
                                });
                                setIsLoading(false);
                              });
                            }}
                          />
                          &nbsp;
                          <i
                            className='fa fa-trash'
                            onClick={() => {
                              setSubmitData({
                                ...submitData,
                                formFields: {
                                  ...submitData.formFields,
                                  [`${ff.id}`]: '',
                                },
                              });
                            }}
                          />
                        </>
                      )}
                    <br />
                  </>
                )}
              <img
                style={{ maxHeight: '160pt', maxWidth: '100%' }}
                src={
                  submitData &&
                    submitData.formFields[`${ff.id}`] !== null &&
                    submitData.formFields[`${ff.id}`] !== ''
                    ? submitData.formFields[`${ff.id}`]
                    : defaultSignatureImage
                }
              />
              <div style={{ fontSize: 'x-small' }}>
                <br />
                {submitData &&
                  submitData.formFields[`lastEditedBy_${ff.id}`] !== null &&
                  submitData.formFields[`lastEditedBy_${ff.id}`] !== '' &&
                  submitData.formFields[`lastEditedAt_${ff.id}`] !== null &&
                  submitData.formFields[`lastEditedAt_${ff.id}`] !== ''
                  ? submitData.formFields[`lastEditedBy_${ff.id}`] +
                  ' ' +
                  submitData.formFields[`lastEditedAt_${ff.id}`] +
                  'UTC'
                  : ''}
              </div>
              <div />
              <br />
            </>
          ) : ff.inputType === 'image' ? (
            <div>
              {!cannotEdit && (
                <>
                  <Input
                    type='file'
                    style={{
                      display: 'inline',
                      width:
                        ff.labelRight == null || ff.labelRight.replace(' ', '') === ''
                          ? '100%'
                          : '35%',
                    }}
                    containerStyle={{ display: 'inline' }}
                    disabled={cannotEdit}
                    accept='image/*'
                    onChange={e => {
                      if (e.target.files[0].type.startsWith('image')) {
                        convertBase64(e.target.files[0]).then(data => {
                          setSubmitData({
                            ...submitData,
                            formFields: {
                              ...submitData.formFields,
                              [`${ff.id}`]: data,
                            },
                          });
                        });
                      }
                    }}
                  />
                  <br />
                  {submitData &&
                    submitData !== null &&
                    typeof submitData.formFields[ff.id] !== 'undefined' &&
                    submitData.formFields[ff.id] !== null &&
                    submitData.formFields[ff.id] !== '' && (
                      <>
                        <i
                          className='fa fa-rotate-right'
                          onClick={() => {
                            setIsLoading(true);
                            const rotatedImage = rotateBase64Image90deg(
                              submitData.formFields[ff.id],
                              true,
                            );
                            setUserDefaultSignatureImage(rotatedImage);
                            setSubmitData({
                              ...submitData,
                              formFields: {
                                ...submitData.formFields,
                                [`${ff.id}`]: rotatedImage,
                              },
                            });
                            setIsLoading(false);
                          }}
                        />
                        &nbsp;
                        <i
                          className='fa fa-trash'
                          onClick={() => {
                            setSubmitData({
                              ...submitData,
                              formFields: {
                                ...submitData.formFields,
                                [`${ff.id}`]: '',
                              },
                            });
                          }}
                        />
                      </>
                    )}
                  <br />
                </>
              )}
              {submitData &&
                submitData.formFields[`${ff.id}`] !== null &&
                submitData.formFields[`${ff.id}`] !== '' &&
                submitData.formFields[`${ff.id}`] && (
                  <img
                    style={{ maxHeight: '160pt', maxWidth: '100%' }}
                    src={submitData.formFields[`${ff.id}`]}
                  />
                )}
            </div>
          ) : ff.inputType === 'date' ? (
            <Input
              type='date'
              placeholder={ff.placeholder}
              style={{
                display: 'inline',
                width:
                  ff.labelRight == null || ff.labelRight.replace(' ', '') === '' ? '100%' : '35%',
              }}
              containerStyle={{ display: 'inline' }}
              value={
                submitData &&
                  submitData.formFields[`${ff.id}`] !== null &&
                  submitData.formFields[`${ff.id}`] !== ''
                  ? submitData.formFields[`${ff.id}`]
                  : ff.isDefaultTodaySet
                    ? new Date().toISOString().slice(0, 10)
                    : ''
              }
              readOnly={cannotEdit}
              onClick={() =>
                handleCannotEditPopup(cannotEdit, ff.allowParentsFillOut, ff.allowKitaFillOut)
              }
              onChange={e => {
                if (cannotEdit) {
                  return;
                }
                if (!checkDateAllowance(ff, e.target.value)) {
                  handleCannotSelectDatePopup(ff.isFutureDateForbidden);
                  return;
                }
                setSubmitData({
                  ...submitData,
                  formFields: {
                    ...submitData.formFields,
                    [`${ff.id}`]: e.target.value,
                  },
                });
              }}
            />
          ) : (
            <Input
              type={ff.inputType}
              placeholder={ff.placeholder}
              style={{
                display: 'inline',
                width:
                  ff.labelRight == null || ff.labelRight.replace(' ', '') === '' ? '100%' : '35%',
              }}
              containerStyle={{ display: 'inline' }}
              value={submitData ? submitData.formFields[`${ff.id}`] : ''}
              readOnly={cannotEdit}
              onClick={() =>
                handleCannotEditPopup(cannotEdit, ff.allowParentsFillOut, ff.allowKitaFillOut)
              }
              onChange={e => {
                setSubmitData({
                  ...submitData,
                  formFields: {
                    ...submitData.formFields,
                    [`${ff.id}`]: e.target.value,
                  },
                });
              }}
            />
          )}{' '}
        </InputFormContractContainer>
      );
    } else if (ff.type === 'doubleInput') {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexBasis: '50%' }}>{renderComponent(ff.inputLeft)}</div>
          <div style={{ flexBasis: '50%', marginLeft: '10px' }}>
            {renderComponent(ff.inputRight)}
          </div>
        </div>
      );
    } else if (ff.type === 'doubleDropdown') {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexBasis: '50%' }}>{renderComponent(ff.dropdownLeft)}</div>
          <div style={{ flexBasis: '50%', marginLeft: '10px' }}>
            {renderComponent(ff.dropdownRight)}
          </div>
        </div>
      );
    } else if (ff.type === 'checkbox') {
      console.log(submitData && submitData.formFields[`${ff.id}`]);
      console.log(ff);
      return (
        <CheckboxFormContractContainer user={user} ff={ff}>
          <Input
            type='checkbox'
            checked={submitData ? submitData.formFields[`${ff.id}`] === 'true' : 'false'}
            readOnly={cannotEdit}
            onClick={() =>
              handleCannotEditPopup(cannotEdit, ff.allowParentsFillOut, ff.allowKitaFillOut)
            }
            onChange={e => {
              if (cannotEdit) {
                return;
              }

              setSubmitData({
                ...submitData,
                formFields: {
                  ...submitData.formFields,
                  [`${ff.id}`]: e.target.checked ? 'true' : 'false',
                },
              });
            }}
            style={{
              display: 'inline',
              width:
                ff.labelRight == null || ff.labelRight.replace(' ', '') === '' ? '100%' : '10%',
              float: !ff.labelLeft || ff.labelLeft.length == 0 ? 'left' : 'none',
              height: '20px',
            }}
            containerStyle={{ display: 'inline' }}
          />
        </CheckboxFormContractContainer>
      );
    } else if (ff.type === 'dropdown') {
      const dropdownStyleObject = {
        marginLeft: ff.indent ? `${ff.indent}pt` : '10px',
        marginBottom: '1em',
        fontSize: '12pt',
      };

      if (ff.labelLeft && ff.labelLeft != '' && (!ff.labelRight || ff.labelRight.length === 0)) {
        dropdownStyleObject.display = 'grid';
        dropdownStyleObject.gridTemplateColumns = 'repeat(auto-fit, minmax(50%, 1fr))';
        dropdownStyleObject.gridAutoRows = 'minmax(20px, auto)';
      }
      if ((!ff.labelRight || ff.labelRight.length === 0) && ff.inputType !== 'radio') {
        dropdownStyleObject.maxWidth = '500px';
      }
      return (
        <p style={dropdownStyleObject}>
          {user.currentWatsonLang !== 'de' ? (
            <WithWatsonTranslate
              data={{ text: ff.labelLeft, entityId: ff.id, entityType: 'formEditor' }}
              Component={({ html }) => (
                <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
              )}
            />
          ) : (
            ff.labelLeft
          )}{' '}
          {ff.inputType === 'radio' ? (
            <>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {ff.options.map((op, i) => {
                  if (submitData && op === submitData.formFields[`${ff.id}`]) {
                    return (
                      <RadioButton
                        label={op}
                        checked={true}
                        onClick={() => {
                          setSubmitData({
                            ...submitData,
                            formFields: {
                              ...submitData.formFields,
                              [`${ff.id}`]: op,
                            },
                          });
                        }}
                      />
                    );
                  }

                  return (
                    <RadioButton
                      label={op}
                      checked={false}
                      onClick={() => {
                        setSubmitData({
                          ...submitData,
                          formFields: {
                            ...submitData.formFields,
                            [`${ff.id}`]: op,
                          },
                        });
                      }}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <select
              style={{ fontSize: '12pt', height: 'fit-content' }}
              onMouseDown={e => {
                if (cannotEdit) {
                  e.preventDefault();
                }
                handleCannotEditPopup(cannotEdit, ff.allowParentsFillOut, ff.allowKitaFillOut);
              }}
              onChange={e => {
                setSubmitData({
                  ...submitData,
                  formFields: {
                    ...submitData.formFields,
                    [`${ff.id}`]: e.target.value,
                  },
                });
              }}
            >
              {ff.options.map((op, i) => {
                if (submitData && op === submitData.formFields[`${ff.id}`]) {
                  return <option selected>{op}</option>;
                }

                return <option>{op}</option>;
              })}
            </select>
          )}{' '}
          {user.currentWatsonLang !== 'de' ? (
            <WithWatsonTranslate
              data={{ text: ff.labelRight, entityId: ff.id, entityType: 'formEditor' }}
              Component={({ html }) => (
                <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
              )}
            />
          ) : (
            ff.labelRight
          )}
        </p>
      );
    } else if (ff.type === 'image') {
      return (
        <div>
          {ff.data && ff.data !== '' && (
            <img
              src={ff.data}
              width={`${ff.scale}%`}
              style={{
                display: 'block',
                marginLeft: ff.alignment === 'left' ? `${ff.indent}pt` : 'auto',
                marginRight: ff.alignment === 'right' ? '0' : 'auto',
                marginBottom: '1em',
              }}
            />
          )}
        </div>
      );
    } else if (ff.type === 'tableBookingTimes') {
      return (
        <BookingTimesTable
          formField={ff}
          submitData={submitData}
          setSubmitData={setSubmitData}
          cannotEdit={cannotEdit}
          numberFromToRows={ff.numberFromToRows || 1}
        />
      );
    } else if (ff.type === 'tableParentalContribution') {
      const relevantRow = tableParentalContributionCalculateRelevantRow(
        form,
        submitData,
        ff.timeSource,
      );

      return (
        <ParentalContributionTable
          isAdmin={isAdmin || isEmployee}
          facilityType={ff.facilityType}
          relevantRow={relevantRow}
          formFieldId={ff.id}
          submitData={submitData}
          setSubmitData={setSubmitData}
        />
      );
    } else if (ff.type === 'tableParentalContributionWithHolidays') {
      const relevantRowHolidays = tableParentalContributionCalculateRelevantRow(
        form,
        submitData,
        ff.timeSourceHolidays,
      );
      const relevantRowSchoolTime = tableParentalContributionCalculateRelevantRow(
        form,
        submitData,
        ff.timeSource,
      );

      return (
        <ParentalContributionWithHolidaysTable
          formField={ff}
          facilityType={ff.facilityType}
          relevantRowSchoolTime={relevantRowSchoolTime}
          relevantRowHolidays={relevantRowHolidays}
          submitData={submitData}
          setSubmitData={setSubmitData}
        />
      );
    }
  };

  const updateChild = e => {
    if (!kidOrUser) {
      getKidWithDetailsAndParentInformationById(e.value).then(kidData => {
        setSelectedChild(kidData.data);
        if (submitData) {
          setSubmitData({
            ...submitData,
            childId: kidData.kidId,
          });
        }
      });
    } else {
      getUserById(e.value).then((res) => {
        if (res) {
          setSelectedChild(res.data);
        }
      });
      if (submitData) {
        setSubmitData({
          ...submitData,
          childId: e.value,
        });
      }
    }
  };

  const getCurrentErrors = () => {
    const curErrors = [];

    const mandatoryFields = form.formFields.filter(ff => ff.mandatory === true);

    mandatoryFields.forEach(ff => {
      if (!(ff.id in submitData.formFields) || submitData.formFields[`${ff.id}`].length === 0) {
        curErrors.push(
          t('DefaultContractEdit.MandatoryFieldError')
            .replace('{page}', ff.page.pageNumber)
            .replace('{pageName}', ff.page.name)
            .replace('{position}', ff.position)
            .replace('{label}', `${ff.labelLeft} ____ ${ff.labelRight}`),
        );
      }
    });

    return curErrors;
  }
  const saveContract = sendEmail => {
    const curErrors = getCurrentErrors();
    setErrors(curErrors);
    if (curErrors.length > 0) {
      return;
    }

    setIsSavingContract(true);
    saveDefaultContractSubmission(submitData, newAttachments, deletedAttachments, sendEmail, kidOrUser)
      .then(() => {
        store.dispatch(
          showBottomNotification(t('DefaultContractEdit.SaveSuccess'), { isFail: false }),
        );
        if (sendEmail && !user.administrationAccess) {
          setShowFinalizedMessage(true);
        } else {
          setShowFinalizedMessage(false);
        }
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('DefaultContractEdit.SaveError'), { isFail: true }),
        );
      })
      .finally(() => {
        setIsSavingContract(false);
      });
  };

  const saveAndDownloadPDF = (pageNumber) => {
    setIsSavingContract(true);
    saveDefaultContractSubmission(submitData, newAttachments, deletedAttachments, kidOrUser)
      .then(() => {
        store.dispatch(
          showBottomNotification(t('DefaultContractEdit.SaveSuccess'), { isFail: false }),
        );

        generateContract(t, selectedChild, activeKita, pageNumber, user, setProgress);
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('DefaultContractEdit.SaveError'), { isFail: true }),
        );
      })
      .finally(() => {
        setDeletedAttachments([]);
        setNewAttachments([]);
        setIsSavingContract(false);
      });
  };

  const onAttachmentSelected = e => {
    const files = e.target.files;

    files.forEach(file => {
      setNewAttachments([...newAttachments, file]);
    });
  };

  const deleteAttachment = id => {
    setSubmitData({
      ...submitData,
      attachments: submitData.attachments ? submitData.attachments.filter(a => a.id !== id) : null,
    });
    setDeletedAttachments([...deletedAttachments, id]);
  };

  return (
    <>
      <Popup
        size='small'
        isOpened={showCannotEditPopup}
        closePopup={() => setShowCannotEditPopup(false)}
        header={t('DefaultContractEdit.CannotEditPopupTitle')}
        body={
          <p>
            {cannotEditPopupReason === 'admin'
              ? t('DefaultContractEdit.CannotEditAsAdmin')
              : cannotEditPopupReason === 'both'
                ? t('DefaultContractEdit.CannotEditAsBoth')
                : t('DefaultContractEdit.CannotEditAsParent')}
          </p>
        }
        footer={
          <div>
            <Button onClick={() => setShowCannotEditPopup(false)}>{t('Popup.Close')}</Button>
          </div>
        }
      />

      <Popup
        size='small'
        isOpened={showCompleteProfilePopup}
        closePopup={() => setShowCompleteProfilePopup(false)}
        header={t('DefaultContractEdit.CompleteProfilePopupTitle')}
        body={<p>{t('DefaultContractEdit.CompleteProfilePopupText')}</p>}
        footer={
          <div className={styles.footerNavigation}>
            <Button onClick={() => history.push('/account-settings/profile/general')}>
              {t('DefaultContractEdit.CompleteProfilePopupOpenSettingsPopup')}
            </Button>
            <Button onClick={() => setShowCompleteProfilePopup(false)}>{t('Popup.Close')}</Button>
          </div>
        }
      />

      <div className={styles.container}>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {selectedChild ? (
              <div>
                <div className={styles.header}>
                  <a
                    className={styles.changeChildButton}
                    style={{ color: activeKita.kitaColorCode }}
                    onClick={() => setSelectedChild(null)}
                  >
                    <i class='fa fa-chevron-left' />
                    <span>{kidOrUser ? t('DefaultContractEdit.ChangeUser') : t('DefaultContractEdit.ChangeChild')}</span>
                  </a>
                  <div className={styles.title}>
                    {t('DefaultContractEdit.ContractFor') + ' '}
                    <span>{selectedChild.firstName}</span>
                  </div>
                </div>

                <div className={styles.formContainer}>
                  {form == null || tabs.length <= currentTab ? (
                    isInitialLoading ? (
                      <Loader />
                    ) : (
                      t('DefaultContractEdit.NoDefaultContract')
                    )
                  ) : (
                    <Tabs
                      tabs={tabs}
                      currentTab={tabs[currentTab].path}
                      onChange={(_, index) => {
                        console.log(index);
                        setCurrentTab(index);
                        saveContract(false);
                      }}
                    />
                  )}
                </div>

                <br />
                <br />
                {submitData &&
                  submitData.attachments &&
                  (submitData.attachments.length > 0 || newAttachments.length > 0) && (
                    <div>
                      <h2>{t('DefaultContractEditor.Attachments')}</h2>
                      <ul style={{ listStyleType: 'none' }}>
                        {submitData.attachments.map(a => {
                          return (
                            <li>
                              <i className='fa fa-trash' onClick={() => deleteAttachment(a.id)} />{' '}
                              {a.fileName}{' '}
                              <a
                                style={{ color: 'blue' }}
                                onClick={async () => {
                                  const { data } = await filesApi.downloadFile(a.fileId);
                                  const url = window.URL.createObjectURL(new Blob([data]));
                                  const link = document.createElement('a');
                                  link.href = url;
                                  link.setAttribute('download', a.fileName);
                                  document.body.appendChild(link);
                                  link.click();
                                }}
                              >
                                {t('DefaultContractEditor.DownloadAttachment')}
                              </a>
                            </li>
                          );
                        })}
                        {newAttachments.map((a, i) => {
                          return (
                            <li>
                              <i
                                className='fa fa-trash'
                                onClick={() =>
                                  setNewAttachments(newAttachments.filter((a2, i2) => i2 !== i))
                                }
                              />{' '}
                              {a.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                <center><>
                  {currentTab > 0 &&
                    <div
                      onClick={() => {

                        const curErrors = getCurrentErrors();
                        setErrors(curErrors);
                        if (curErrors.length > 0) {
                          return;
                        }

                        setCurrentTab(currentTab - 1);
                        saveContract(false);
                        window.scrollTo(0, 0);
                      }}
                      style={{ backgroundColor: "#f1f1f1", borderRadius: "50%", padding: "8px 16px", display: "inline-block", marginRight: "5px" }}
                    ><i className='fa fa-caret-left' /></div>
                  }

                  {currentTab < tabs.length - 1 &&
                    <div
                      onClick={() => {

                        const curErrors = getCurrentErrors();
                        setErrors(curErrors);
                        if (curErrors.length > 0) {
                          return;
                        }

                        setCurrentTab(currentTab + 1);
                        saveContract(false);
                        window.scrollTo(0, 0);
                      }}
                      style={{ backgroundColor: "#f1f1f1", borderRadius: "50%", padding: "8px 16px", display: "inline-block" }}
                    ><i className='fa fa-caret-right' /></div>
                  }
                </>
                </center>
                {showFinalizedMessage && (
                  <Popup
                    size='small'
                    isOpened={showFinalizedMessage}
                    closePopup={() => { setShowFinalizedMessage(false) }}
                    header={t('DefaultContractEdit.Finalized')}
                    body={
                      <p>
                        {t('DefaultContractEdit.InfoMessageInFinalPopup')}
                      </p>
                    }
                    footer={
                      <div>
                        <Button onClick={() => { setShowFinalizedMessage(false) }}>{t('Popup.Close')}</Button>
                      </div>
                    }
                  />
                )}

                <div className={styles.actionButtonsContainer}>
                  <ButtonWithLoader
                    onClick={() => {
                      saveContract(false);
                    }}
                    disabled={!submitData || !submitData.formId || !submitData.childId}
                    isLoading={isSavingContract}
                  >
                    {t('DefaultContractEdit.Save')}
                  </ButtonWithLoader>

                  <ButtonWithLoader
                    onClick={() => {
                      saveContract(true);
                    }}
                    disabled={!submitData || !submitData.formId || !submitData.childId}
                    isLoading={isSavingContract}
                  >
                    {t('DefaultContractEdit.SaveAndSendEmail')}
                  </ButtonWithLoader>

                  {(user.administrationAccess || user.employee) && (
                    <>
                      <ButtonWithLoader
                        onClick={() => {
                          saveAndDownloadPDF(null)
                        }}
                        disabled={!submitData || !submitData.formId || !submitData.childId}
                        isLoading={isSavingContract}
                      >
                        <i className='fa fa-file-pdf-o' />{' '}
                        {t('DefaultContractEdit.SaveAndDownloadPDF')}
                      </ButtonWithLoader>
                      <Button
                        onClick={() => {
                          saveAndDownloadPDF((currentTab + 1))
                        }}
                      >
                        <i className='fa fa-file-pdf-o' />{' '}
                        {t('DefaultContractEdit.Save page as pdf')}
                      </Button>
                    </>
                  )}

                  <Button
                    onClick={() => {
                      if (user.administrationAccess || user.employee) {
                        history.push(`/groups/${user.defaultGroupId}/dateien`);
                      } else {
                        history.push(`/profile/${user.id}/files`);
                      }
                    }}
                  >
                    {t('DefaultContractEdit.Switch to the stored files')}
                  </Button>

                  <Button
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t('DefaultContractEdit.Back to top')}
                  </Button>

                  {/*<Button onClick={() => document.getElementById('attachmentFileUpload').click()}>
                        <i className='fa fa-file' /> {t('DefaultContractEditor.AddAttachment')}
                        <input
                          id='attachmentFileUpload'
                          type='file'
                          onChange={onAttachmentSelected}
                          style={{ display: 'none' }}
                        />
                      </Button>*/}
                  {errors && errors.length > 0 && (
                    <ul style={{ marginTop: '1em' }}>
                      {errors.map(e => {
                        return <li style={{ color: 'red' }}>{e}</li>;
                      })}
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.emptyState}>
                <EmptyStateSVG color={activeKita.kitaColorCode} />

                <div className={styles.emptyStateHeader}>
                  <h3 className={styles.heading}>
                    {kidOrUser ? t('DefaultContractEdit.PleaseSelectUserToEdit') : t('DefaultContractEdit.PleaseSelectChildToEdit')}
                  </h3>
                  <HelpText className={styles.description}>
                    {kidOrUser ? t('DefaultContractEdit.PleaseSelectUserToEditDescription') : t('DefaultContractEdit.PleaseSelectChildToEditDescription')}
                  </HelpText>
                </div>

                <div className={styles.actionButtons}>
                  <Select
                    className={styles.selectInput}
                    placeholder={kidOrUser ? t('DefaultContractEdit.UserSelectLabel'): t('DefaultContractEdit.ChildSelectLabel')}
                    options={childs.map(c => {
                      return {
                        value: c.id,
                        label: `${c.lastName}, ${c.firstName}`,
                      };
                    })}
                    onSelect={e => {
                      updateChild(e);
                    }}
                    selected={
                      selectedChild == null
                        ? null
                        : {
                          value: selectedChild.kidId,
                          label: `${selectedChild.lastName}, ${selectedChild.firstName}`,
                        }
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default connect(state => ({
  user: state.userReducer,
  activeKita: state.kitaReducer.activeKita,
}))(DefaultContractEdit);
