import React from 'react';

import styles from './sortIndicator.module.scss';

export default function SortIndicator({ sort, name }) {
  if (name === sort.name) {
    return <div className={sort.direction === 'asc' ? styles.sortAsc : styles.sortDesc} />;
  }
  return null;
}
