import { connect } from 'react-redux';

import {
  setSelectedChatId,
  setChatSocketSubscribed,
} from 'library/common/commonActions/chatActions';
import { setCreateKitaMode } from 'library/common/commonActions/kitaActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import Header from '.';

export default connect(
  state => ({
    user: state.userReducer,
    chatSocketSubscribed: state.chatReducer.chatSocketSubscribed,
    openNewChatWithRecipient: state.chatReducer.openNewChatWithRecipient,
    isCreateGroupMode: state.groupReducer.isCreateGroupMode,
    isCreateKitaMode: state.kitaReducer.isCreateKitaMode,
    superAdminStatus: state.userReducer.superAdminStatus,
    modules: state.kitaReducer.modules,
    activeKita: state.kitaReducer.activeKita,
    checkInOutActiveForParentsSignal: state.moduleSettingsReducer.checkInOutActiveForParentsSignal,
    timeRangeAbsenceInfo: state.timeRangeAbsenceReducer.timeRangeAbsenceInfo
  }),
  {
    setSelectedChatId,
    setChatSocketSubscribed,
    setCreateKitaMode,
    showBottomNotification,
  },
)(Header);
