import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import DragAndDrop from 'library/common/commonComponents/DragAndDrop';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import FilePrewiev from 'library/common/commonComponents/FilePrewiev';
import useMultipleUpload from 'library/common/commonHooks/useMultipleUpload';
import RecordButton from 'library/common/commonComponents/Buttons/RecordButton/RecordButtonContainer';

import PublicationTimeButton from '../../Buttons/PublicationTimeButton';
import Button from '../../Buttons/Button';
import { handleRNMessageHelper } from 'library/utilities/handleRNMessageHelper';
import { VOICE_MESSAGE_AVAILABLE_APPS } from 'main/config';

import styles from './editorWithButton.module.scss';

export default function EditorWithButton({
  Editor,
  error,
  onSubmit,
  btnText,
  closeBtnText,
  className,
  messgeFiles,
  isSubmiting,
  isInProfileStream,
  publicationTime,
  setPublicationTime,
  onCloseEditor,
  groupId,
  user
}) {
  const { files, uploadFile, deleteFiles, setFiles } = useMultipleUpload(messgeFiles);
  const isSbMasterclass = window.location.hostname
    .toLowerCase()
    .includes('sbmasterclass.safe2connect.org');

  const [isVoiceMessageAvailable, setIsVoiceMessageAvailable] = useState(
    user?.administrationAccess || user?.employee || isSbMasterclass,
  );

  const checkVoiceMessageAvailability = rnEvent => {
    if (!rnEvent || !rnEvent.data) {
      return;
    }

    setIsVoiceMessageAvailable(
      user?.administrationAccess ||
        user?.employee ||
        VOICE_MESSAGE_AVAILABLE_APPS.includes(rnEvent.data) ||
        isSbMasterclass,
    );
  };

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      return;
    }

    [window, document].forEach(el => {
      el.addEventListener(
        'message',
        handleRNMessageHelper('native.projectId', checkVoiceMessageAvailability),
      );
    });

    return () => {
      [window, document].forEach(el => {
        el.removeEventListener(
          'message',
          handleRNMessageHelper('native.projectId', checkVoiceMessageAvailability),
        );
      });
    };
  }, [window.ReactNativeWebView]);

  return (
    <DragAndDrop onDrop={uploadFile}>
      <div className={cn(styles.editorContainer, className)}>
        {Editor && <Editor />}
        <div className={styles.editorContainerButtons}>
          {publicationTime && setPublicationTime && (
            <PublicationTimeButton
              className={styles.editPublicationTime}
              onChange={pt => setPublicationTime(pt)}
              value={publicationTime}
            />
          )}
          {isVoiceMessageAvailable && (
             <RecordButton uploadFile={uploadFile} voiceForPost={true} isCommentPage={true}/>
          )}
          <UploadButton
            className={styles.uploadButton}
            onChange={uploadFile}
            isMultiple
            isInProfileStream={isInProfileStream}
            groupId={groupId}
            files={files}
          />
          <ButtonWithLoader
            onClick={() => onSubmit(files, deleteFiles)}
            size='sm'
            type='default'
            className={styles.editorContainerSubmit}
            isLoading={isSubmiting}
          >
            {btnText}
          </ButtonWithLoader>
          {closeBtnText && onCloseEditor && (
            <Button size='sm' onClick={onCloseEditor}>
              {closeBtnText}
            </Button>
          )}
        </div>
      </div>
      {error && <div className={styles.editorError}>{error}</div>}
      <FilePrewiev
        newFiles={files}
        onDelete={deleteFiles}
        onFilesSort={updatedFiles => setFiles(updatedFiles)}
      />
    </DragAndDrop>
  );
}
