import Storage from 'library/utilities/storage';

export const setToken = data => {
  const rememberMe = Storage.getItem('rememberMe');
  if (rememberMe) {
    Storage.setItem('token', data);
  } else {
    sessionStorage.setItem('token', JSON.stringify(data));
  }
};

export const getToken = () =>
  Storage.getItem('token') || JSON.parse(sessionStorage.getItem('token'));

export const removeToken = () => Storage.removeItem('token') || sessionStorage.removeItem('token');

export const removeLoginTime = () => Storage.removeItem('loginTime') || sessionStorage.removeItem('loginTime');
