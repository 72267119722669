import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useChatMessages from 'library/common/commonHooks/useChatMessages';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import ChatList from 'library/common/commonComponents/Chat/ChatList';
import ChatMessagesView from 'library/common/commonComponents/Chat/ChatMessagesView';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import Pagination from 'library/common/commonComponents/Pagination';
import { setUserKita } from 'library/api/kita';
import { filterIsNotOnlyPublicKitaMember } from 'library/utilities/kitaChecks';

import styles from './chat.module.scss';

export default function Chat({
  match,
  history,
  user,
  unreadMessagesCountsKitas,
  setUnreadMessagesCountsKitas,
  chatId,
  showBottomNotification,
  activeKita,
  kitas,
  modules,
  timeRangeAbsenceInfo,
  ...props
}) {
  useSiteTitle('Chat');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chatIdParam = Number(searchParams.get('id')) || 0;

  const {
    isLoadingChatlists,
    isLoading,
    activeChat,
    totalPages,
    currentPage,
    setCurrentPage,
    handleSetActiveChat,
    handleAddNewChat,
    handleAddNewAbsence,
    handleDeactiveTimeRangeAbsence,
    handleLeaveChat,
    handleDeleteMessage,
    handleLeaveChatAll,
    handleSendMessage,
    handleAddUser,
    handleLoadMoreMessages,
    isKitaVirtual,
    setFilteredByText,
    setFilteredUserIds,
  } = useChatMessages({
    chatId: chatId || chatIdParam,
    page: match.params.page,
    history,
    unreadMessagesCountsKitas,
    setUnreadMessagesCountsKitas,
    showBottomNotification,
    activeKita,
    forceReload: true,
    timeRangeAbsenceInfo,
  });

  const chatsList = useSelector(state => state.chatListPopupReducer.chatsList);
  let selectedChatId = activeChat.id;
  if (props.location.state && props.location.state.selectedChatId === 0) {
    selectedChatId = 0;
  }

  const returnToChats = () => {
    selectedChatId = 0;
    handleSetActiveChat(activeChat);
  };

  useEffect(() => {
    const kitaId = Number(searchParams.get('kita'));
    if (activeKita?.kitaId && kitaId && activeKita?.kitaId !== kitaId) {
      setUserKita(kitaId).then(res => {
        if (res && res.status === 200) {
          window.location.reload();
        }
      });
    }
  }, [activeKita]);

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className={styles.container}>
          <ChatList
            isLoading={isLoadingChatlists}
            activeChat={activeChat}
            selectedChatId={selectedChatId}
            onChatClick={handleSetActiveChat}
            onAddNewChat={handleAddNewChat}
            onAddNewAbsence={handleAddNewAbsence}
            onLeaveChat={handleLeaveChat}
            onLeaveChatAll={handleLeaveChatAll}
            onDeactiveTimeRangeAbsence={handleDeactiveTimeRangeAbsence}
            timeRangeAbsenceInfo={timeRangeAbsenceInfo}
            currentPage={currentPage}
            onPageClick={setCurrentPage}
            totalPages={totalPages}
            history={history}
            user={user}
            modules={modules}
            isKitaVirtual={isKitaVirtual}
            currentWatsonLang={user.currentWatsonLang}
            isNotOnlyPublicKitaMember={filterIsNotOnlyPublicKitaMember(kitas.kitas)}
            setFilteredUserIds={setFilteredUserIds}
            setFilteredByText={setFilteredByText}
          />
          {chatsList.length > 0 && (
            <ChatMessagesView
              userId={user.id}
              activeChat={activeChat}
              openChatId={selectedChatId}
              isLoading={isLoading}
              onLeave={handleLeaveChat}
              onDelete={handleDeleteMessage}
              onSendMessage={handleSendMessage}
              onLoadMore={handleLoadMoreMessages}
              onAddUser={handleAddUser}
              isKitaVirtual={isKitaVirtual}
              currentWatsonLang={user.currentWatsonLang}
              isNotOnlyPublicKitaMember={filterIsNotOnlyPublicKitaMember(kitas.kitas)}
              user={user}
              modules={modules}
              onClickReturn={returnToChats}
            />
          )}
          <Pagination
            currentPage={currentPage}
            numberOfPages={totalPages}
            onPageClick={setCurrentPage}
            withoutRouting
            history={history}
            className={styles.paginationContainer}
            hiddenOnDesktop
            hiddenOnMobile={selectedChatId !== 0}
          />
        </div>
      </Wrapper>
    </KitaPanelWrapper>
  );
}
