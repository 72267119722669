import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from 'library/common/commonComponents/Wrapper';
import styles from './foodOrder.module.scss';
import Card from '../Card';
import MoreBtn from '../Buttons/MoreBtn';
import TextCaret from '../TextCaret';
import FoodOrderSettingsTabs from './FoodOrderSettings/FoodOrderSettingsTabs';
import FoodOrderBody from './FoodOrderBody';

export default function FoodOrder({ user, activeKita, groupInfo, showBottomNotification }) {
  const { t } = useTranslation();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const getSettingsDropdownOptions = () => {
    const dropdownOptions = [
      {
        faIcon: 'fa-cloud-upload',
        title: t('FoodOrder.Dropdown.Settings'),
        onClick: () => {
          setIsSettingsOpen(true);
        },
      },
    ];

    return dropdownOptions;
  };

  const settingsDropdownOptions = getSettingsDropdownOptions();
  return (
    <Wrapper>
      <Card
        className={styles.cardContainer}
        title={t('FoodOrder.title')}
        titleClassName={styles.bodyTitle}
      >
        {isSettingsOpen && groupInfo.groupAdminStatus ? (
          <div className={styles.container}>
            <FoodOrderSettingsTabs
              showBottomNotification={showBottomNotification}
              user={user}
              groupId={groupInfo.groupId}
              setIsSettingsOpen={setIsSettingsOpen}
              activeKita={activeKita}
              groupInfo={groupInfo}
            />
          </div>
        ) : (
          <>
            <div className={styles.container}>
              {user.lastName !== 'Caterer' && groupInfo.groupAdminStatus && (
                <MoreBtn
                  dropdownOptions={settingsDropdownOptions}
                  faIcon='fa-cog'
                  className={styles.headerMoreBtn}
                >
                  <TextCaret color='#555' />
                </MoreBtn>
              )}
            </div>
            <div className={styles.container}>
              <FoodOrderBody
                showBottomNotification={showBottomNotification}
                groupId={groupInfo.groupId}
                groupInfo={groupInfo}
                activeKita={activeKita}
                user={user}
              />
            </div>
          </>
        )}
      </Card>
    </Wrapper>
  );
}
