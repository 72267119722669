import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import useCachedImage from 'library/common/commonHooks/useCachedImage';
import { getUserLogoUrl } from 'library/utilities/user';

import styles from './profileAvatar.module.scss';

export default function ProfileAvatar({ id, withoutLink, url, className }) {
  const logoUrl = url || getUserLogoUrl(id);
  const [isLoaded, setLoaded] = useState(false);

  const { CachedImage } = useCachedImage({
    originalSrc: url !== 'default' && logoUrl,
    onLoad: () => setLoaded(true),
    fallbackImage: defaultAvatar,
    props: {
      alt: 'logo',
      className: cn(styles.avatarNotDefault, {
        [styles.hidden]: !isLoaded,
      }),
    },
  });

  const body = <div className={cn(styles.avatar, className)}>{CachedImage}</div>;

  return withoutLink ? body : <Link to={'/profile/' + id}>{body}</Link>;
}
