import axios from 'library/api/axios';

import config, { getPort, isDev } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;

export const createComment = (comment, setProgress) => {
  const payload = {
    ...comment,
    graphql:
      // eslint-disable-next-line max-len
      'id,text,files[id,fileId,path,size,mimeType],isTranslationAllowed',
  };
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      let fileNumber = 1;
      payload[key].forEach(item => {
        formData.append(`file${fileNumber}`, item);
        fileNumber++;
      });
    } else {
      formData.append(key, payload[key]);
    }
  });

  return axios.post(zuulURL + '/comment', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
    },
  });
};

export const updateComment = (id, comment) => {
  const payload = {
    ...comment,
    graphql:
      // eslint-disable-next-line max-len
      'id,text,files[id,fileId,path,size,mimeType],isTranslationAllowed',
  };
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      let fileNumber = 1;
      payload[key].forEach(item => {
        formData.append(`file${fileNumber}`, item);
        fileNumber++;
      });
    } else {
      formData.append(key, payload[key]);
    }
  });

  return axios.put(zuulURL + '/comment/' + id, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateCommentStatus = (id, status) => {
  return axios.post(`${zuulURL}/update-comment-status/${id}?status=${status}`);
};

export const deleteComment = id => axios.delete(URL + '/comment/' + id);
