import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Card from 'library/common/commonComponents/Card';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from './ProviderPostPopup.module.scss';
import Button from '../../Buttons/Button';

export default function ProviderPostPopup({
  isOpened,
  closePopup,
  providers,
  submit,
  dispatchProviders,
  isLoading,
}) {
  const { t } = useTranslation();

  const onCheck = id => {
    dispatchProviders({ type: 'toggleSelected', kitaId: id });
  };

  const onCheckAll = () => {
    dispatchProviders({ type: 'selectAll' });
  };

  return (
    <Popup
      size='normal'
      isOpened={isOpened}
      closePopup={closePopup}
      header={<strong>{t('CreatePost.Provider.Header')}</strong>}
    >
      <Card className={styles.container}>
        <p>{t('CreatePost.Provider.Who should see this content?')}</p>
        <br />
        <Button onClick={() => onCheckAll()}>{t('CreatePost.Provider.Choose all Kitas')}</Button>
        <br />
        <br />
        {providers.map(provider => (
          <Checkbox
            key={provider.id}
            className={styles.checkbox}
            name={provider.label}
            onChange={() => onCheck(provider.id)}
            isChecked={provider.selected}
          />
        ))}

        <br />
        <ButtonWithLoader type='info' onClick={submit} isLoading={isLoading}>
          {t('Editor.Submit')}
        </ButtonWithLoader>
      </Card>
    </Popup>
  );
}
