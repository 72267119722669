import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useEditor from 'library/common/commonHooks/useEditor';
import Checkbox from 'library/common/commonComponents/Checkbox';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import CustomQuestion, { changeOptionOrder } from './CustomQuestion';
import NewCustomQuestion from './NewCustomQuestion';
import Button from '../Buttons/Button';

import styles from './parentSurveyBody.module.scss';

const initialOption = {
  id: Math.random(),
  answer: '',
  orderPosition: 0,
};
const emptyQuestion = {
  id: Math.random(),
  inputType: 'text',
  question: '',
  options: [initialOption, { ...initialOption, orderPosition: 1, id: Math.random() }],
  type: 'own_question',
  multipleAnswerStatus: false,
  allowUserAddSuggestionStatus: false,
  subQuestions: [],
};

export default function ParentSurveyBody({
  availablePreparedQuestions,
  parentSurvey,
  editExisting,
  isReusing,
  newReusing,
  setNewReusing,
  questionInfos,
  parentSurveyName,
  dispatchQuestion,
  setParentSurveyName,
  reminders,
  setReminders,
  startTimeStatus,
  setStartTimeStatus,
  startTimeDate,
  setStartTimeDate,
  endTimeStatus,
  setEndTimeStatus,
  endTimeDate,
  setEndTimeDate,
  groupId,
}) {
  const [movedQuestion, setMovedQuestion] = useState(null);
  const [newQuestion, setNewQuestion] = useState(emptyQuestion);
  const [checkedPreparedQuestionIds, setCheckedPreparedQuestionIds] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const { t } = useTranslation();

  const reminderTypeOptions = [
    {
      value: 'days',
      label: t('Calendar.ReminderDaysBefore'),
    },
    {
      value: 'hours',
      label: t('Calendar.ReminderHoursBefore'),
    },
  ];

  const updateReminderSetting = (actionValue) => {
    let newReminders = [...reminders];
    switch (actionValue.type) {
      case 'clearReminders':
        newReminders = [];
        break;
      case 'addReminder':
        newReminders.push({ id: -1, type: 'days', value: 1, });
        break;
      case 'removeReminder':
        newReminders = newReminders.filter((r, i) => i !== actionValue.payload);
        break;
      case 'updateReminderType':
        newReminders = newReminders.map((r, i) => ({
          ...r,
          type: i === actionValue.payload.index ? actionValue.payload.type : r.type,
        }));
        break;
      case 'updateReminderValue':
        newReminders = newReminders.map((r, i) => ({
          ...r,
          value: i === actionValue.payload.index ? actionValue.payload.value : r.value,
        }));
        break;
      default:
        break;
    }
    setReminders(newReminders);
  };

  const selectPreparedQuestion = question => {
    if (checkedPreparedQuestionIds.includes(question.id)) {
      setCheckedPreparedQuestionIds(
        checkedPreparedQuestionIds.filter(item => item !== question.id),
      );
    } else {
      setCheckedPreparedQuestionIds([...checkedPreparedQuestionIds, question.id]);
    }
  };

  const handleAllCheckedChange = () => {
    if (allChecked) {
      setCheckedPreparedQuestionIds([]);
    } else {
      setCheckedPreparedQuestionIds(availablePreparedQuestions.map(item => item.id));
    }
    setAllChecked(!allChecked);
  };

  const removePreparedQuestion = question => {
    dispatchQuestion({ type: 'togglePreparedQuestionChecked', question, value: false });
  };

  const toggleQuestionCatalogueAllowAddSuggestion = id => {
    dispatchQuestion({ type: 'togglePreparedQuestionCatalogueAllowAddSuggestion', questionId: id });
  };
  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = parentSurveyName;

  const { Editor, getEditorValue, resetEditorValue, updateValue } = useEditor({
    placeholder:
      groupId === 6 || groupId === 188 || groupId === 189 || groupId === 190
        ? 'Geben Sie der Mitarbeiterbefragung einen Titel...'
        : t('ParentSurvey.EnterTitlePlaceholder'),
    onChange: () => {
      const value = getEditorValue();
      setParentSurveyName(value.innerHTML.toString());
      if (setNewReusing) {
        setNewReusing(false);
      }
    },
    message: wrappedMessage,
  });

  useEffect(() => {
    if (!parentSurveyName || parentSurveyName.isEmpty) {
      resetEditorValue();
    }
  }, [parentSurveyName]);

  useEffect(() => {
    if (isReusing && newReusing && parentSurvey?.title) {
      const wrappedParentSurveyName = document.createElement('div');
      wrappedParentSurveyName.innerHTML = parentSurvey?.title;
      setParentSurveyName(wrappedParentSurveyName.innerHTML.toString());
      updateValue(wrappedParentSurveyName);
      setNewReusing(false);
    }
  }, [isReusing, newReusing, parentSurvey]);

  // Adds new empty question
  const addQuestion = () => {
    if (newQuestion.question !== '' && newQuestion?.options.length > 0) {
      dispatchQuestion({ type: 'addQuestion', question: newQuestion });
      setNewQuestion(emptyQuestion);
    }
  };

  const addPreparedQuestions = () => {
    const checkedQuestions = availablePreparedQuestions.filter(item =>
      checkedPreparedQuestionIds.includes(item.id),
    );
    checkedQuestions.forEach(question => {
      dispatchQuestion({
        type: 'togglePreparedQuestionChecked',
        question,
        value: true,
      });
    });
  };

  return (
    <>
      {groupId === 6 || groupId === 188 || groupId === 189 || groupId === 190 ? (
        <h3>Titel der Mitarbeiterbefragung</h3>
      ) : (
        <h3>{t('ParentSurvey.Title of the parent Survey')}</h3>
      )}
      <br />
      <div className={styles.input}>
        <Editor />
      </div>
      <div>
        <span style={{ float: 'left' }}>
          <Checkbox
            name={t('Surveys.Survey starttime')}
            isDisabled={editExisting}
            isChecked={startTimeStatus}
            onChange={() => setStartTimeStatus(!startTimeStatus)}
          />
        </span>
        <span style={{ marginTop: '5px' }}>
          <DatePicker
            disabled={editExisting}
            selected={startTimeDate}
            onChange={date => {
              const currSelDate = new Date(startTimeDate);
              currSelDate.setMonth(date.getMonth());
              currSelDate.setFullYear(date.getFullYear());
              currSelDate.setDate(date.getDate());
              setStartTimeDate(currSelDate.getTime());
            }}
            langCode='Deutsch'
          />

          <DatePicker
            disabled={editExisting}
            selected={startTimeDate}
            onChange={date => {
              const currSelDate = new Date(startTimeDate);
              currSelDate.setHours(date.getHours());
              currSelDate.setMinutes(date.getMinutes());
              setStartTimeDate(currSelDate.getTime());
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption='Zeit'
            timeFormat='HH:mm'
            langCode='Deutsch'
          />
        </span>
      </div>
      <div>
        <span style={{ float: 'left' }}>
          <Checkbox
            name={t('Surveys.Survey endtime')}
            isDisabled={editExisting}
            isChecked={endTimeStatus}
            onChange={() => {
              if (endTimeStatus) {
                updateReminderSetting({ type: 'clearReminders' });
              }
              setEndTimeStatus(!endTimeStatus);
            }}
          />
        </span>
        <span style={{ marginTop: '5px' }}>
          <DatePicker
            disabled={editExisting}
            selected={endTimeDate}
            onChange={date => {
              const currSelDate = new Date(endTimeDate);
              currSelDate.setMonth(date.getMonth());
              currSelDate.setFullYear(date.getFullYear());
              currSelDate.setDate(date.getDate());
              setEndTimeDate(currSelDate.getTime());
            }}
            langCode='Deutsch'
          />

          <DatePicker
            disabled={editExisting}
            selected={endTimeDate}
            onChange={date => {
              const currSelDate = new Date(endTimeDate);
              currSelDate.setHours(date.getHours());
              currSelDate.setMinutes(date.getMinutes());
              setEndTimeDate(currSelDate.getTime());
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption='Zeit'
            timeFormat='HH:mm'
            langCode='Deutsch'
          />
        </span>
      </div>
      {
        <>
          <div className={styles.reminderContainer}>
            {reminders.map((r, i) => {
              return (
                <div className={styles.reminder}>
                  <Input
                    disabled={editExisting}
                    value={r.value}
                    onChange={e =>
                      updateReminderSetting({
                        type: 'updateReminderValue',
                        payload: { index: i, value: e.target.value },
                      })
                    }
                    containerStyle={{ width: 60 }}
                    className={styles.reminderValueInput}
                  />
                  <Select
                    disabled={editExisting}
                    options={reminderTypeOptions}
                    onSelect={option =>
                      updateReminderSetting({
                        type: 'updateReminderType',
                        payload: { index: i, type: option.value },
                      })
                    }
                    selected={reminderTypeOptions.find(o => o.value === r.type)}
                    className={styles.reminderTypeSelect}
                  />
                  <Button
                    disabled={editExisting}
                    onClick={() => {
                      updateReminderSetting({ type: 'removeReminder', payload: i });
                    }}
                    className={styles.reminderRemoveButton}
                  >
                    <i className='fa fa-minus' />
                  </Button>
                </div>
              );
            })}
          </div>
          <div>
            {endTimeStatus && !editExisting && (
              <Button onClick={() => updateReminderSetting({ type: 'addReminder' })}>
                {t('Calendar.ReminderAdd')}
              </Button>
            )}
          </div>
        </>
      }
      <br />
      <h3>{t('ParentSurvey.Prepared questions')}</h3>
      <Checkbox
        name={t('ParentSurvey.Select all')}
        onChange={handleAllCheckedChange}
        isChecked={allChecked}
      />
      <br />
      {availablePreparedQuestions
        .filter(
          question => questionInfos.findIndex(item => item.question === question.question) === -1,
        )
        .map(preparedQuestion => {
          return (
            <React.Fragment key={preparedQuestion.id}>
              <div className={styles.preparedQuestionWrapper}>
                <Checkbox
                  name={preparedQuestion.question}
                  onChange={() => selectPreparedQuestion(preparedQuestion)}
                  isChecked={checkedPreparedQuestionIds.includes(preparedQuestion.id)}
                />
              </div>
            </React.Fragment>
          );
        })}
      <Button onClick={addPreparedQuestions} className={styles.addPreparedQuestionBtn}>
        <i className='fa fa-plus' /> {t('ParentSurvey.Add prepared questions')}
      </Button>
      <h3>{t('ParentSurvey.Enter your own questions')}</h3>
      {questionInfos.filter(question => question.type === 'own_question').length > 0 && (
        <div>
          {t('ParentSurvey.Please delete this question if you do not want to add own questions')}
        </div>
      )}
      <br />
      <NewCustomQuestion newQuestion={newQuestion} setNewQuestion={setNewQuestion} />
      <Button onClick={addQuestion} className={styles.addQuestionBtn}>
        <i className='fa fa-plus' /> {t('ParentSurvey.Add question')}
      </Button>
      <br />
      <h3>{t('ParentSurvey.Selected questions')}</h3>
      {questionInfos.map((item, index) => (
        <>
          {(item.type === 'prepared' || item.isCustomQuestion === false) && (
            <React.Fragment key={item.id}>
              <div className={styles.preparedQuestionWrapper}>
                <Checkbox
                  name={item.question}
                  onChange={() => removePreparedQuestion(item)}
                  isChecked={item.checked || item.preChecked}
                />
                {movedQuestion && movedQuestion.id === item.id && (
                  <hr className={styles.indicator} />
                )}
                <div className={styles.moveButtonsWrapper}>
                  <button
                    className={styles.arrowIconButton}
                    disabled={index === questionInfos.length - 1}
                    onClick={changeOptionOrder(item.id, 'moveDown', dispatchQuestion)}
                  >
                    <i className='fa fa-arrow-down' />
                  </button>
                  <button
                    className={styles.arrowIconButton}
                    disabled={index === 0}
                    onClick={changeOptionOrder(item.id, 'moveUp', dispatchQuestion)}
                  >
                    <i className='fa fa-arrow-up' />
                  </button>
                </div>
              </div>
              <div className={styles.preparedQuestionContainer}>
                {item.inputType === 'text' ? (
                  <>
                    {(item.checked || item.preChecked) && item.options
                      ? item.options.map(option => (
                          <div key={option.id} className={styles.preparedQuestionOption}>
                            {option.answer}
                          </div>
                        ))
                      : ''}
                  </>
                ) : (
                  <>
                    {(item.checked || item.preChecked) && (
                      <div className={styles.preparedQuestionOption}>
                        {t('ParentSurvey.Number input')}
                      </div>
                    )}
                  </>
                )}
              </div>
              {(item.checked || item.preChecked) && item.inputType !== 'number' && (
                <div
                  className={
                    item.options
                      ? styles.addSuggestionContainer
                      : cn(styles.addSuggestionContainer, styles.mt2)
                  }
                >
                  <i className='fa fa-arrow-right' />
                  <Checkbox
                    className={styles.allowAddSuggestionPreparedQuestion}
                    name={t('Surveys.Allow user to add suggestions')}
                    isChecked={item.allowUserAddSuggestionStatus}
                    onChange={() => toggleQuestionCatalogueAllowAddSuggestion(item.id)}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {item.type === 'own_question' && (
            <React.Fragment key={item.id}>
              {index > 0 ? <hr className={styles.separator} /> : ''}
              <CustomQuestion
                index={index}
                totalQuestionLength={questionInfos.length}
                dispatchQuestion={dispatchQuestion}
                questionInfo={item}
              />
            </React.Fragment>
          )}
        </>
      ))}
    </>
  );
}
