import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HelpText from 'library/common/commonComponents/HelpText';
import Tabs from 'library/common/commonComponents/Tabs';
import Select from 'library/common/commonComponents/Inputs/Select';
import Label from 'library/common/commonComponents/Label';
import { GroupContext } from 'modules/Group/groupModule';
import { getGroupPermission, putGroupPermission } from 'library/api/groups';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import styles from './groupBodySecurityPermissions.module.scss';

function PermissionsTableRow({
  permission,
  groupInfo,
  roleId,
  updateGroupPermissions,
  showBottomNotificationFromProps,
}) {
  const { t } = useTranslation();
  const visibilitySelectOptions = [
    {
      value: 'default',
      label: `${t('GroupBody.Security.Default')} - ${
        permission.defaultStatus ? t('GroupBody.Security.Allow') : t('GroupBody.Security.Deny')
      }`,
    },
    { value: false, label: t('GroupBody.Security.Deny') },
    { value: true, label: t('GroupBody.Security.Allow') },
  ];
  const [visibility, setVisibility] = useState(() => {
    if (permission.defaultStatusSelected) {
      return visibilitySelectOptions[0];
    } else if (permission.selectedStatus) {
      return visibilitySelectOptions[2];
    } else {
      return visibilitySelectOptions[1];
    }
  });
  const onSelectHandler = value => {
    const data = {
      groupId: groupInfo.groupId,
      groupPermissionId: permission.groupPermissionId,
      groupRoleId: roleId,
      defaultStatusSelected: value.value === 'default',
      selectedStatus: value.value === 'default' ? false : value.value,
    };
    putGroupPermission(data).then(() => {
      updateGroupPermissions(data);
      showBottomNotificationFromProps(t('GroupBody.Security.Saved'));
    });
    setVisibility(value);
  };
  return (
    <tr className={styles.row}>
      <td>
        <div>
          <div className={styles.rowTitle}>{permission.groupPermissionName}</div>
          <Label type='info_gray'>{permission.groupPermissionTag}</Label>
        </div>
        <div className={styles.rowDescription}>{permission.groupPermissionDescription}</div>
      </td>
      <td>
        <Select
          options={visibilitySelectOptions}
          onSelect={onSelectHandler}
          selected={visibility}
          className={styles.select}
        />
      </td>
    </tr>
  );
}

function PermissionsTable({
  permission,
  groupInfo,
  updateGroupPermissions,
  showBottomNotificationFromProps,
}) {
  return (
    <table className={styles.table}>
      <tbody>
        {permission.groupPermissions.map(item => (
          <PermissionsTableRow
            groupInfo={groupInfo}
            permission={item}
            roleId={permission.roleId}
            showBottomNotificationFromProps={showBottomNotificationFromProps}
            updateGroupPermissions={updateGroupPermissions}
          />
        ))}
      </tbody>
    </table>
  );
}

export function GroupBodySecurityPermissions({ showBottomNotificationFromProps, userInfo }) {
  const { t } = useTranslation();
  const groupContext = useContext(GroupContext);
  const { groupInfo } = groupContext;
  const [groupPermissions, setGroupPermissions] = useState([]);
  useEffect(() => {
    getGroupPermission(groupInfo.groupId).then(res => {
      setGroupPermissions(res.data);
    });
    // eslint-disable-next-line
  }, []);
  const updateGroupPermissions = data => {
    setGroupPermissions(
      groupPermissions.map(permission =>
        permission.roleId === data.groupRoleId
          ? {
              ...permission,
              groupPermissions: permission.groupPermissions.map(groupPermission =>
                groupPermission.groupPermissionId === data.groupPermissionId
                  ? {
                      ...groupPermission,
                      selectedStatus: data.selectedStatus,
                      defaultStatusSelected: data.defaultStatusSelected,
                    }
                  : groupPermission,
              ),
            }
          : permission,
      ),
    );
  };
  const [activeTab, setActiveTab] = useState(0);
  const getTabs = () => {
    return [
      {
        title: t('GroupBody.Security.Admin'),
        path: 'admin',
        component: (
          <div className={styles.container}>
            <PermissionsTable
              showBottomNotificationFromProps={showBottomNotificationFromProps}
              updateGroupPermissions={updateGroupPermissions}
              groupInfo={groupInfo}
              permission={groupPermissions.find(item => item.adminRole)}
            />
          </div>
        ),
      },
      {
        title: t('GroupBody.Security.Members'),
        path: 'members',
        component: (
          <div className={styles.container}>
            <PermissionsTable
              showBottomNotificationFromProps={showBottomNotificationFromProps}
              updateGroupPermissions={updateGroupPermissions}
              groupInfo={groupInfo}
              permission={groupPermissions.find(item => !item.adminRole)}
            />
          </div>
        ),
      },
    ];
  };
  return !groupPermissions.length ? null : (
    <div>
      <HelpText className={styles.container}>
        {t('GroupBody.Security.Permissions are assigned')}
      </HelpText>
      {(groupInfo.groupAdminStatus || userInfo.administrationAccess) && (
        <Tabs
          type='secondary'
          tabs={getTabs()}
          onChange={(_, index) => setActiveTab(index)}
          currentTab={getTabs()[activeTab].path}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userReducer,
});

const mapDispatchToProps = {
  showBottomNotificationFromProps: showBottomNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupBodySecurityPermissions);
