import { connect } from 'react-redux';

import { setCreateGroupMode as setCreateGroupModeToDispatch } from 'library/common/commonActions/groupActions';
import DropdownSpacesSearch from '.';

const mapStateToProps = state => ({
  userInfo: state.userReducer,
  activeKita: state.kitaReducer.activeKita,
  kitas: state.kitaReducer,
});

const mapDispatchToProps = {
  setCreateGroupMode: setCreateGroupModeToDispatch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropdownSpacesSearch);
