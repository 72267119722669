import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './foodOrdersKita.module.scss';
import FoodOrdersKitaWeeklyView from '../FoodOrderKitaWeeklyView';
import FoodOrdersKita from '../FoodOrdersKita';
import Tabs from 'library/common/commonComponents/Tabs';

export default function FoodOrderKitaTabs({ groupId, activeKita, groupInfo, userId }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: t('FoodOrder.Daily View'),
      path: 'food-order-daily',
      component: (
        <FoodOrdersKita
          groupId={groupId}
          activeKita={activeKita}
          groupInfo={groupInfo}
          userId={userId}
        />
      ),
    },
    {
      title: t('FoodOrder.Weekly View'),
      path: 'food-order-weekly',
      component: (
        <FoodOrdersKitaWeeklyView groupId={groupId} activeKita={activeKita} groupInfo={groupInfo} />
      ),
    },
  ];

  return (
    <>
      <br />
      <div className={styles.wrapper}>
        <Tabs
          tabs={tabs}
          onChange={(_, index) => setActiveTab(index)}
          currentTab={tabs[activeTab].path}
        />
      </div>
    </>
  );
}
