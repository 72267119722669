import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { setCreateGroupMode } from 'library/common/commonActions/groupActions';
import CreateGroup from '.';

export const mapStateToProps = state => ({
  activeKita: state.kitaReducer.activeKita,
});

const mapDispatchToProps = {
  setCreateGroupMode,
  showBottomNotification,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CreateGroup),
);
