import Axios from 'axios';

import { getToken, removeToken } from 'library/utilities/token';
import { refreshToken } from 'library/api/refreshToken';
import store from 'main/store/configureStore';
import mainConfig, { getPort } from 'main/config';

import errorHandler from './errorHandler';

export const checkMailMessage =
  'Please follow the instructions in your mailbox to complete the registration.';

const axiosInstance = Axios.create({
  baseURL: `${mainConfig.API_BASE_URI}${getPort(8080)}/api`,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(config => {
  const token = getToken();
  const clonedConfig = config;
  let deviceType = 'web';
  if (window.ReactNativeWebView) {
    deviceType = 'mobile';
  }
  if (token) {
    clonedConfig.headers.common = {
      Authorization: `Bearer ${token.access_token}`,
      'Content-Type': 'application/json',
      'Device-Type': deviceType,
    };

    if (config.url.includes('public/translations-v2')) {
      delete config.headers.common.Authorization;
    }
  } else {
    const encodedString = btoa(`strampleradmin:strampleradmin123`);
    clonedConfig.headers.common.Authorization = `Basic ${encodedString}`;
    clonedConfig.headers.common['Device-Type'] = deviceType;
    clonedConfig.headers.post['Content-Type'] = 'multipart/form-data';
  }
  return clonedConfig;
});

axiosInstance.interceptors.response.use(
  config => config,
  error => {
    const handler = errorHandler(
      config => store.dispatch(refreshToken(config)),
      () => {
        removeToken();
        if (window.location.pathname !== '/login') {
          window.location = '/login';
        }
      },
    );

    if (error.response) {
      const { data } = error.response;
      if (data) {
        if (data && data.error_description === checkMailMessage) {
          return handler(checkMailMessage);
        }
      }
    }
    return handler(error);
  },
);

export default axiosInstance;
