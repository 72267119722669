import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import * as commentsApi from 'library/api/comments';
import { getFileIconByName, createPDF } from 'library/utilities/files';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Tooltip from 'library/common/commonComponents/Tooltip';
import CommentsPopup from 'library/common/commonComponents/Popups/CommentsPopup';
import ReceiptPopup from 'library/common/commonComponents/Popups/ReceiptPopup';
import { timeSince } from 'library/utilities/date';
import useLikes from 'library/common/commonHooks/useLikes';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import { getFilesInFolder } from 'library/api/files';
import Loader from 'library/common/commonComponents/Loader';
import FileManagerContext from '../../../../fileManagerContext';

import styles from '../../fileManagerTable.module.scss';

export default function FileManagerTableRow({
  file,
  toggleFileCheckbox,
  openFile,
  groupInfo,
  openFolder,
  selectedFiles,
  isRootFolder,
  isStreamFolder,
  breadcrumbs,
  administrationAccess,
}) {
  function isInBelegeFolder() {
    if (breadcrumbs.length > 0 && breadcrumbs[0].name === 'Belege') {
      return true;
    }
    return false;
  }

  async function getFolderColorStatus(folder, grpInfo) {
    let bestStatus = 2;

    const result = await getFilesInFolder(grpInfo.groupId, false, folder.id);

    if (result.length > 0) {
      for (const object of result) {
        if (object.taxReceipt) {
          if (object.taxReceipt.receiptConfirmed === null) {
            return 0;
          }
          if (!object.taxReceipt.receiptConfirmed) {
            bestStatus = 1;
          }
        }
      }
      if (bestStatus === 1) {
        return 1;
      }
      if (bestStatus === 2) {
        return 2;
      }
    }

    return bestStatus;
  }

  useEffect(() => {
    if (isInBelegeFolder() && file.type === 'folder') {
      getFolderColorStatus(file, groupInfo).then(result => {
        if (result === 0) {
          setIsReceiptConfirmed(null);
        }
        if (result === 1) {
          setIsReceiptConfirmed(false);
        }
        if (result === 2) {
          setIsReceiptConfirmed(true);
        }
      });
    }
  }, []);

  const { user, showBottomNotification, updateFile } = useContext(FileManagerContext);
  const { t } = useTranslation();
  const posts = file.posts || {};
  let fileArgs;
  if (file.type !== 'folder') {
    fileArgs = {
      likes: (isRootFolder ? file.likes : posts.likes) || [],
      user,
      id: isRootFolder ? file.id : posts.id,
    };
  } else {
    fileArgs = {
      likes: [],
      user,
      id: null,
    };
  }

  const { LikeButton, ...likeButtonProps } = useLikes(fileArgs);

  const [isCommentsPopupOpened, setIsCommentsPopupOpened] = useState(false);
  const [pdfDownloadOngoing, setPdfDownloadOngoing] = useState(false);
  const [isReceiptPopupOpened, setIsReceiptPopupOpened] = useState(false);
  const [isReceiptConfirmed, setIsReceiptConfirmed] = useState(checkIfReceiptIsConfirmed());

  function checkIfReceiptIsConfirmed() {
    if (file && file.taxReceipt && 'receiptConfirmed' in file.taxReceipt) {
      if (file.taxReceipt.receiptConfirmed === true) {
        return true;
      } else if (file.taxReceipt.receiptConfirmed === false) {
        return false;
      }
    }
    return null;
  }

  const onDeleteComment = id =>
    onDeleteCommentHandler({
      id,
      setComments: newComments => updateFile({ ...file, comments: newComments }),
      comments: isRootFolder ? file.comments : posts.comments,
      showBottomNotification,
      t,
      deleteComment: commentsApi.deleteComment,
    });

  const updateCommentById = (id, payload) =>
    updateCommentByIdHandler({
      id,
      payload,
      setComments: newComments => updateFile({ ...file, comments: newComments }),
      comments: isRootFolder ? file.comments : posts.comments,
      showBottomNotification,
      t,
      updateComment: commentsApi.updateComment,
    });

  const openFolderHandler = () => {
    openFolder(
      file.id,
      groupInfo.groupId,
      file.name,
      file.description,
      file.publicFolder,
      file.isFilesFromStream,
    );
  };

  return (
    <tr
      style={
        isReceiptConfirmed
          ? { backgroundColor: '#defcde' }
          : isReceiptConfirmed === false
            ? { backgroundColor: '#facaca' }
            : isInBelegeFolder()
              ? { backgroundColor: '#faefca' }
              : {}
      }
    >
      <td>
        {file.type !== 'folder' && (
          <CommentsPopup
            user={user}
            id={isRootFolder ? file.id : posts.id}
            comments={(isRootFolder ? file.comments : posts.comments) || []}
            setComments={newComments => updateFile({ id: file.id, comments: newComments })}
            onDelete={onDeleteComment}
            updateCommentById={updateCommentById}
            isOpened={isCommentsPopupOpened}
            closePopup={() => setIsCommentsPopupOpened(false)}
            title='Comments'
          />
        )}

        {file.type !== 'folder' && isReceiptPopupOpened === true && file.taxReceipt && (
          <ReceiptPopup
            user={user}
            id={isRootFolder ? file.id : posts.id}
            comments={(isRootFolder ? file.comments : posts.comments) || []}
            setComments={newComments => updateFile({ id: file.id, comments: newComments })}
            onDelete={onDeleteComment}
            updateCommentById={updateCommentById}
            isOpened={isReceiptPopupOpened}
            closePopup={() => setIsReceiptPopupOpened(false)}
            title='Comments'
            file={file}
            isReceiptConfirmed={isReceiptConfirmed}
            setIsReceiptConfirmed={setIsReceiptConfirmed}
            administrationAccess={administrationAccess}
          />
        )}

        <div className={styles.titleWrapper}>
          {!isStreamFolder && (
            <div className={styles.checkboxContainer}>
              {!file.isFilesFromStream && (
                <Checkbox
                  isChecked={!!selectedFiles.find(item => item.id === file.id) || false}
                  onChange={toggleFileCheckbox}
                  className={styles.checkbox}
                />
              )}
            </div>
          )}
          <div className={styles.title}>
            <i
              className={cn(
                styles.titleIcon,
                file.type === 'folder' ? 'fa fa-folder' : `fa ${getFileIconByName(file.fileId)}`,
              )}
            />

            <Tooltip
              fontSize={11}
              text={file.description}
              isVisibleCondition={file.type === 'folder' && file.description}
            >
              <div
                className={styles.titleValue}
                onClick={() => (file.type === 'folder' ? openFolderHandler() : openFile(file))}
              >
                {file.type === 'folder' ? (
                  <span className='translate'>{file.name}</span>
                ) : (
                  <span className='translate'>{file.fileId}</span>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </td>
      <td>
        {!pdfDownloadOngoing ? (
          <div style={{ textAlign: 'right' }}>
            {file &&
              file.path &&
              file.path !== 'undefined' &&
              file.mimeType &&
              file.mimeType !== 'undefined' &&
              file.taxReceipt &&
              file.taxReceipt !== 'undefined' &&
              (file.mimeType.substr(-4) === 'jpeg' || file.mimeType.substr(-4) === '/png') && (
                <div
                  className={cn('fa fa-download', styles.titleIcon)}
                  onClick={() => {
                    setPdfDownloadOngoing(true);
                    createPDF(file.path, file.taxReceipt, file.fileId).then(() => {
                      setPdfDownloadOngoing(false);
                    });
                  }}
                />
              )}
          </div>
        ) : (
          <div style={{ textAlign: 'right' }}>
            <Loader />
          </div>
        )}
      </td>
      <td>
        <div style={{ textAlign: 'right' }}>
          {file &&
            file.path &&
            file.path !== 'undefined' &&
            file.mimeType &&
            file.mimeType !== 'undefined' &&
            file.taxReceipt &&
            isInBelegeFolder() &&
            (file.mimeType.substr(-4) === 'jpeg' || file.mimeType.substr(-4) === '/png') && (
              <div
                className={cn('fa fa-info-circle', styles.titleIcon)}
                onClick={() => setIsReceiptPopupOpened(true)}
              />
            )}
        </div>
      </td>
      {/* <td>
        <div className={styles.privacy}>
          <Tooltip
            fontSize={11}
            text={`${
              file.type === 'folder'
                ? t('FileManager.Table.This folder')
                : t('FileManager.Table.This file')
            } ${
              file.isPublic || file.publicFolder
                ? t('FileManager.Table.is public')
                : t('FileManager.Table.is private')
            }`}
            isVisibleCondition
          >
            <i
              className={cn(
                'fa fa-fw tt',
                file.isPublic || file.publicFolder ? 'fa-unlock-alt' : 'fa-lock',
              )}
            />
          </Tooltip>
        </div>
              </td> */}

      <td className={cn(styles.textRight, styles.size)}>
        <span className='translate'>{file.size || '-'}</span>
      </td>

      <td className={cn(styles.textRight, styles.updated)}>
        <span className='translate'>
          {file.type !== 'folder' && timeSince({ date: file.createdAt, langCode: user.langCode })}
        </span>
      </td>

      <td className={cn(styles.rates, styles.textRight)}>
        {file.type !== 'folder' && (
          <div className={styles.ratesWrapper}>
            <div className={styles.ratesWrapperLikes}>
              <LikeButton withLikesModal={false} {...likeButtonProps} />
            </div>
            {groupInfo && groupInfo.createCommentStatus && (
              <div
                className={styles.ratesWrapperComments}
                onClick={() => setIsCommentsPopupOpened(true)}
              >
                | <span className='translate'>{t('FileManager.Table.Comments')}</span> (
                {isRootFolder
                  ? file.comments && <span className='translate'>{file.comments.length}</span>
                  : posts.comments && <span className='translate'>{posts.comments.length}</span>}
                )
              </div>
            )}
          </div>
        )}
      </td>

      <td className={cn(styles.textRight, styles.avatar)}>
        {file.type !== 'folder' && (
          <div className={styles.avatarImage}>
            <Tooltip
              fontSize={11}
              text={
                <span className='notranslate'>
                  {file.user.firstName} {file.user.lastName}
                </span>
              }
              isVisibleCondition
            >
              <ProfileAvatar image={file} id={file.userId} />
            </Tooltip>
          </div>
        )}
      </td>

      <td className={styles.textRight}>
        <i
          className={
            (file.type === 'folder' && !file.publicFolder) ||
              (file.type !== 'folder' && file.posts != null && file.posts.privatePost)
              ? 'fa fa-lock'
              : 'fa fa-unlock'
          }
        />
      </td>

      {file.isFilesFromStream && <td />}
    </tr>
  );
}

export const onDeleteCommentHandler = async ({
  id,
  setComments,
  comments,
  showBottomNotification,
  t,
  deleteComment,
}) => {
  await deleteComment(id);
  const newComments = comments.filter(comment => comment.id !== id);
  setComments(newComments);
  showBottomNotification(t('BottomNotifications.The content has been deleted'));
};

export const updateCommentByIdHandler = async ({
  id,
  payload,
  comments,
  setComments,
  showBottomNotification,
  t,
  updateComment,
}) => {
  const newComment = {
    ...comments.find(comment => comment.id === id),
    ...payload,
    editedAt: new Date().getTime(),
  };

  await updateComment(id, payload);
  const newComments = comments.map(comment => (comment.id === id ? newComment : comment));
  setComments(newComments);
  showBottomNotification(t('BottomNotifications.Saved'));

  return newComments;
};
