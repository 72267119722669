import React from 'react';

import { registrationServiceWithoutDispatch } from 'modules/Auth/Authorization/authorizationActions';
import { useTranslation } from 'react-i18next';
import RegistrationFrame from 'modules/Auth/Authorization/authorizationFrames/RegistrationFrame';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';

import styles from './loggedInRegistration.module.scss';
import { isAwoWW } from 'library/api/tenantConfig';

export default function LoggedInRegistration({ kita }) {

  const { t } = useTranslation();

  function getKitaHeader() {
    if (kita && kita.activeKita && kita.activeKita.kitaName) {
      return <><center><h2>{kita.activeKita.kitaName}</h2></center></>
    } else {
      return <></>;
    }
  }

  return (
    <>
      <KitaPanelWrapper>
        <Wrapper>
          <Col>
            <Row>
              <div className={styles.container}>
                <>
                  {
                    isAwoWW() ? <><center><h2>{t('SelfRegistration.AWO-Immerdabei')}</h2></center></> : getKitaHeader()
                  }
                  <div className={styles.helptextcontainer}>
                    {t('SelfRegistration.Infotext')}
                  </div>
                  <RegistrationFrame registrationService={registrationServiceWithoutDispatch} />
                </>
              </div>
            </Row>
          </Col>
        </Wrapper>
      </KitaPanelWrapper >
    </>
  );
}
