import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import EditImagePopup from 'library/common/commonComponents/Popups/EditImagePopup';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import DeleteButton from 'library/common/commonComponents/Buttons/DeleteButton';
import EditButton from 'library/common/commonComponents/Buttons/EditButton';
import useCachedImage from 'library/common/commonHooks/useCachedImage';
import defaultBanner from 'resources/images/default-profile-panel-banner.jpg';

import styles from './profileHeader.module.scss';

export default function ProfileHeader({
  profileStatus,
  header,
  headerUploadProgress,
  onUploadProfileImg,
  onEditProfileImage,
  onDeleteProfileImg,
}) {
  const { t } = useTranslation();
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);

  const { CachedImage } = useCachedImage({
    originalSrc: header.imageUrl,
    fallbackImage: defaultBanner,
    props: {
      alt: header.imageUrl ? 'header' : 'banner',
      className: cn(styles.headerImage, !header.imageUrl && styles.banner, 'animated', {
        bounceIn: isBounceAnimation,
      }),
    },
  });

  const [isBounceAnimation, setIsBounceAnimation] = useState(false);
  useEffect(() => {
    setIsBounceAnimation(true);
    setTimeout(() => {
      setIsBounceAnimation(false);
    }, 400);
  }, [header.imageUrl]);

  const image = {
    unit: '%',
    x: header.xCoordinate,
    y: header.yCoordinate,
    width: header.imageWidth,
    height: header.imageWidth / header.imageAspect,
    aspect: 5.9,
    url: header.imageUrl,
  };

  const handleEditImage = img => {
    onEditProfileImage(img, 'header');
    setIsEditPopupOpened(false);
  };

  const handleDeleteImage = () => {
    onDeleteProfileImg('header');
    setIsDeletePopupOpened(false);
  };

  return (
    <div className={styles.container}>
      <DeletePopup
        isOpened={isDeletePopupOpened}
        closePopup={() => setIsDeletePopupOpened(false)}
        onDeleteBtnClick={handleDeleteImage}
        headerText={<Trans i18nKey='ProfilePanel.Confirm image deleting' />}
        bodyText={t('ProfilePanel.Do you really want to delete your title image')}
      />
      <EditImagePopup
        isOpened={isEditPopupOpened}
        closePopup={() => setIsEditPopupOpened(false)}
        onEditBtnClick={handleEditImage}
        headerText={<Trans i18nKey='ProfilePanel.Modify your title image' />}
        image={image}
        type='header'
      />
      <div className={styles.headerContainer}>
        {CachedImage}
        {headerUploadProgress ? (
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              <div className={styles.progressInfo} style={{ width: headerUploadProgress + '%' }} />
            </div>
          </div>
        ) : (
          <div className={styles.uploadHeaderContainer}>
            {profileStatus === 'owner' && (
              <div className={styles.uploadHeader}>
                <UploadButton
                  onChange={([img]) => {
                    onUploadProfileImg(img, 'header');
                  }}
                  allowedExtensions={['image/*']}
                />
                {header.imageUrl && (
                  <>
                    <EditButton
                      onClick={() => setIsEditPopupOpened(true)}
                      className={styles.uploadHeaderButton}
                    />
                    <DeleteButton
                      onClick={() => setIsDeletePopupOpened(true)}
                      className={styles.uploadHeaderButton}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
