import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Input from 'library/common/commonComponents/Inputs/Input';

import styles from './fileManagerRenamePopup.module.scss';

export default function FileManagerRenamePopup({
  isOpened,
  closePopup,
  headerText,
  updateFileName,
  fileName,
}) {
  const { t } = useTranslation();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [newFileName, setNewFileName] = useState({ left: '', right: '' });
  const [saveError, setSaveError] = useState(false);
  function getFullNewFileName() {
    let fullFileName = newFileName.left;
    if (newFileName.right !== '') {
      fullFileName += '.' + newFileName.right;
    }
    return fullFileName;
  }

  function getFileNamePart(part, filesName) {
    const splittedFileName = filesName.split('.');

    if (splittedFileName.length <= 1) {
      if (part === 'left') {
        return filesName;
      }
      if (part === 'right') {
        return '';
      }
    } else {
      if (part === 'left') {
        let tempSplittedFileName = splittedFileName;
        tempSplittedFileName.pop();
        tempSplittedFileName = tempSplittedFileName.join('.');
        return tempSplittedFileName;
      }
      if (part === 'right') {
        return splittedFileName[splittedFileName.length - 1];
      }
    }
    return filesName;
  }

  useEffect(() => {
    setNewFileName({
      left: getFileNamePart('left', fileName),
      right: getFileNamePart('right', fileName),
    });
  }, []);

  // console.log(fileNameLeftPart + " ------ " + fileNameRightPart);

  const handleSubmit = async () => {
    if (isSubmiting) {
      return;
    }
    const regexValidWindowsFileName = /^(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^\|\*\?\\:<>/$"]*[^\.\|\*\?\\:<>/$"]+$/;

    if (
      !regexValidWindowsFileName.test(newFileName.left) ||
      newFileName.left.length + newFileName.right.lengt >= 254
    ) {
      setSaveError(true);
      return;
    }

    setIsSubmiting(true);
    await updateFileName(getFullNewFileName());
    setIsSubmiting(false);
    setSaveError(false);
    closePopup();
  };

  return (
    <Popup
      size='extraSmall'
      isOpened={isOpened}
      closePopup={closePopup}
      header={headerText}
      body={
        <div>
          <div
            className={styles.popupText}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Input
              type='text'
              placeholder={t('Filemanager.Insert new name')}
              value={newFileName.left}
              onChange={e => {
                const { value } = e.target;
                setNewFileName(prevState => {
                  const fName = { ...prevState };
                  fName.left = value;
                  return fName;
                });
              }}
              maxLength={251}
            />
            {newFileName.right !== '' && '.' + newFileName.right}
          </div>
          <div>
            {saveError && (
              <div style={{ textAlign: 'center', color: 'red' }}>
                <br />
                {t('Filemanager.Error on changing name')}
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <div className={styles.popupBtns}>
          <Button onClick={closePopup}>{t('Popup.Cancel')}</Button>
          <ButtonWithLoader onClick={handleSubmit} type='primary' isLoading={isSubmiting}>
            {t('Popup.Rename')}
          </ButtonWithLoader>
        </div>
      }
    />
  );
}
