import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { checkLink } from 'library/utilities/checkLink';
import useEditor from 'library/common/commonHooks/useEditor';

import Storage from 'library/utilities/storage';

export default function useEditing({
  text,
  updateItemById,
  id,
  placeholder,
  groupId,
  publicationTime,
}) {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = text;
  const { Editor, getEditorValue, updateValue } = useEditor({
    message: wrappedMessage,
    placeholder,
    onChange: () => {
      const value = getEditorValue();
      if (value.textContent.trim() !== '' && !value.querySelector('img')) {
        return setError(null);
      }
    },
    groupId,
  });
  const [error, setError] = useState(null);

  const setIsEditingModified = value => {
    Storage.setItem(`editor/message/${placeholder}`, wrappedMessage.innerHTML);
    setIsEditing(value);
  };

  const onSubmit = async (files, cb) => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(true);
    const value = getEditorValue();
    const hasFiles = files?.length > 0;

    if (!hasFiles && value.textContent.trim() === '' && !value.querySelector('img')) {
      setIsSubmiting(false);
      return setError(t('Post.Message cannot be blank'));
    }

    setError(null);
    await updateItemById(id, {
      text: checkLink(value),
      files,
      publicationTime,
    });

    const newValue = document.createElement('div');
    newValue.innerHTML = value.innerHTML;

    updateValue(newValue);
    setIsSubmiting(false);
    setIsEditing(false);
    if (cb) {
      cb();
    }
  };

  return {
    Editor,
    error,
    onSubmit,
    isEditing,
    setIsEditing: setIsEditingModified,
    isSubmiting,
    setIsSubmiting,
    publicationTime,
  };
}
