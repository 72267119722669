import React, { useReducer, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import SurveyBody from 'library/common/commonComponents/SurveyBody';
import { validateSurvey } from 'library/utilities/surveys';

import { stripHtml } from 'library/utilities/parentSurveys';
import styles from './feedItemSurveyEditing.module.scss';

export function editingReducer(state, action) {
  switch (action.type) {
    case 'addAnswer':
      return {
        ...state,
        surveyOptions: state.surveyOptions.concat({
          optionName: '',
          id: Math.random(),
          isNew: true,
          surveyOptionVotes: [],
        }),
      };
    case 'updateAnswerById':
      return {
        ...state,
        surveyOptions: state.surveyOptions.map(answer =>
          answer.id === action.id
            ? {
                ...answer,
                optionName: action.optionName,
              }
            : answer,
        ),
      };
    case 'moveDown':
      const newStateDown = [...state.surveyOptions];
      const tempItem = newStateDown[action.index];
      newStateDown[action.index] = newStateDown[action.index + 1];
      newStateDown[action.index + 1] = tempItem;
      return {
        ...state,
        surveyOptions: newStateDown,
      };
    case 'moveUp':
      const newStateUp = [...state.surveyOptions];
      const tempItem1 = newStateUp[action.index];
      newStateUp[action.index] = newStateUp[action.index - 1];
      newStateUp[action.index - 1] = tempItem1;
      return {
        ...state,
        surveyOptions: newStateUp,
      };
    case 'deleteAnswerById':
      return {
        ...state,
        deletedSurveyOptionIds: state.deletedSurveyOptionIds.concat(action.id),
        surveyOptions: state.surveyOptions.filter(answer => answer.id !== action.id),
      };
    case 'changeQuestion':
      return {
        ...state,
        question: action.value,
      };
    case 'changeCheckbox':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'setReminders':
      return {
        ...state,
        reminders: action.value,
      };
    default:
      return state;
  }
}

export default function FeedItemSurveyEditing({
  onEdit,
  setIsEditing,
  isSubmiting,
  ...editingFields
}) {
  const [state, dispatch] = useReducer(editingReducer, {
    ...editingFields,
    deletedSurveyOptionIds: [],
    surveyOptions: editingFields.surveyOptions.concat({
      optionName: '',
      id: Math.random(),
      surveyOptionVotes: [],
    }),
  });
  const [errors, setErrors] = useState([]);

  const toggleCheckbox = name => toggleCheckboxHandler({ name, dispatch });
  const setReminders = name => setRemindersHandler({ name, dispatch });
  const onSave = () => onSaveHandler({ onEdit, state, t, setErrors });

  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.inputDescription}>Question *</div>
      <Input
        value={stripHtml(state.question)}
        className={styles.input}
        placeholder={t('Surveys.Ask something')}
        onChange={e => dispatch({ type: 'changeQuestion', value: e.target.value })}
      />

      <div className={styles.inputDescription}>Antworten</div>
      <SurveyBody
        surveyOptions={state.surveyOptions}
        dispatch={dispatch}
        hideMultipleCheckbox={false}
        multipleAnswerStatus={state.multipleAnswerStatus}
        anonymousVotesStatus={state.anonymousVotesStatus}
        anonymousVotesForAllStatus={state.anonymousVotesForAllStatus}
        hideResultStatus={state.hideResultStatus}
        displayRandomOrderStatus={state.displayRandomOrderStatus}
        allowUserAddSuggestionStatus={state.allowUserAddSuggestionStatus}
        endTimeDate={state.endTimeDate}
        endTimeStatus={state.endTimeStatus}
        startTimeDate={state.startTimeDate}
        startTimeStatus={state.startTimeStatus}
        reminders={state.reminders}
        isEditing={true}
        setMultipleAnswerStatus={toggleCheckbox('multipleAnswerStatus')}
        setEndTimeDate={toggleCheckbox('endTimeDate')}
        setStartTimeDate={toggleCheckbox('startTimeDate')}
        setAnonymousVotesStatus={toggleCheckbox('anonymousVotesStatus')}
        setAnonymousVotesForAllStatus={toggleCheckbox('anonymousVotesForAllStatus')}
        setHideResultStatus={toggleCheckbox('hideResultStatus')}
        setDisplayRandomOrderStatus={toggleCheckbox('displayRandomOrderStatus')}
        setAllowUserAddSuggestionStatus={toggleCheckbox('allowUserAddSuggestionStatus')}
        setEndTimeStatus={toggleCheckbox('endTimeStatus')}
        setStartTimeStatus={toggleCheckbox('startTimeStatus')}
        setReminders={setReminders('setReminders')}
      />

      <div className={styles.errors}>
        {errors.map(error => (
          <div className={styles.error} key={error}>
            {error}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <ButtonWithLoader
          type='primary'
          className={styles.button}
          onClick={onSave}
          isLoading={isSubmiting}
        >
          {t('Popup.Save')}
        </ButtonWithLoader>
        <Button type='danger' className={styles.button} onClick={() => setIsEditing(false)}>
          {t('Popup.Cancel')}
        </Button>
      </div>
    </div>
  );
}

export const toggleCheckboxHandler = ({ name, dispatch }) => value =>
  dispatch({ type: 'changeCheckbox', name, value });

export const setRemindersHandler = ({ name, dispatch }) => value =>
  dispatch({ type: 'setReminders', name, value });

export function onSaveHandler({ onEdit, state, t, setErrors }) {
  const errors = validateSurvey({
    t,
    question: state.question,
    surveyOptions: state.surveyOptions,
  });

  if (errors.length) {
    return setErrors(errors);
  }

  setErrors([]);

  onEdit({
    ...state,
    surveyOptions: state.surveyOptions
      .filter(answer => answer.optionName.trim().length > 0)
      .map(({ id, isNew, ...rest }) => ({ id: isNew ? 0 : id, ...rest })),
  });
}
