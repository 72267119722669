import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';

const useKidsWithBookingTimeEvents = (getUserKids, updateUserKidStatus) => {
  const { t } = useTranslation();

  const [kids, setKids] = useState([]);

  useEffect(() => {
    getUserKids()
      .then(res => {
        if (res.status === 200) {
          setKids(res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const updateUserKidsStatus = uKidObj => {
    updateUserKidStatus(uKidObj)
      .then(res => {
        if (res.status === 200) {
          const kidsCopy = kids.slice();
          const index = kidsCopy.findIndex(kid => kid.kidId === uKidObj.kidId);
          if (index >= 0) {
            kidsCopy[index].checkInOutStatus = uKidObj.checkInOutType;
            setKids(kidsCopy);
          }
        }
      })
      .catch(err => {
        switch (err.status || err.response.status) {
          case 400:
            store.dispatch(
              showBottomNotification(t(err.response.data.message), {
                isFail: true,
              }),
            );
            return {
              success: false,
              error: 'error',
            };
          default:
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  return {
    kids,
    updateKidStatus: updateUserKidsStatus,
  };
};

export default useKidsWithBookingTimeEvents;
