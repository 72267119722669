export default class Storage {
  static prefix = 'Stramplerbande/';

  static getItem(name) {
    return JSON.parse(localStorage.getItem(this.prefix + name));
  }

  static setItem(name, value) {
    localStorage.setItem(this.prefix + name, JSON.stringify(value));
  }

  static removeItem(name) {
    return localStorage.removeItem(this.prefix + name);
  }
}
