import React, {useMemo} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CardDropdown from 'library/common/commonComponents/CardDropdown';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';
import Notification from 'library/common/commonComponents/Notification';

import styles from '../toolbarNotificationsDropdown.module.scss';

export default function DropdownNotifications({
  isOpened,
  unreadNotificationsCount,
  isLoading,
  notifications,
  onNotificationsScroll,
  markAllAsRead,
  refScroll,
  readNotification,
  user,
}) {
  const { t } = useTranslation();

  const unreadNotifications = useMemo(() => notifications.filter(({ isRead }) => !isRead), [
    notifications,
  ]);
  const restNotifications = useMemo(() => notifications.filter(({ isRead }) => isRead), [
    notifications,
  ]);
  const allNotifications = useMemo(
    () => unreadNotifications.concat(restNotifications).sort((a, b) => b.createdAt - a.createdAt),
    [restNotifications, unreadNotifications],
  );

  return (
    isOpened && (
      <div className={styles.dropdown}>
        <CardDropdown
          header={
            <div className={styles.headerContainer}>
              <div className={styles.title}>{t('Header.Topbar.Notifications.Notifications')}</div>
              {unreadNotificationsCount > 0 && (
                <div className={styles.markAllAsRead} onClick={markAllAsRead}>
                  {t('Header.Topbar.Notifications.Mark all as read')}
                </div>
              )}
            </div>
          }
          footer={
            notifications && notifications.length > 0 ? (
              <Link to='/list-notifications/1'>
                <Button type='primary' isFullWidth className={styles.button}>
                  {t('Header.Topbar.Notifications.Show all notifications')}
                </Button>
              </Link>
            ) : null
          }
        >
          {isLoading && notifications.length === 0 && <Loader className={styles.loader} isSmall />}
          {notifications && notifications.length > 0 ? (
            <>
              <div
                ref={refScroll}
                className={styles.notificationsContainer}
                onScroll={onNotificationsScroll}
              >
                {allNotifications.map((notification, i) => (
                  <Notification
                    key={
                      // eslint-disable-next-line react/no-array-index-key
                      `${i}_${notification.id}`
                    }
                    isLast={notifications.length === i + 1}
                    notification={notification}
                    readNotification={readNotification}
                    user={user}
                  />
                ))}
                {isLoading && <Loader className={styles.loader} isSmall />}
              </div>
            </>
          ) : (
            !isLoading && (
              <>
                <div className={styles.arrow} />
                <div className={styles.placeholder}>
                  {t('Header.Topbar.Notifications.There are no notifications yet')}
                </div>
              </>
            )
          )}
        </CardDropdown>
      </div>
    )
  );
}
