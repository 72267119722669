import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './tabs.module.scss';
import { isAwoWW } from 'library/api/tenantConfig';

const CustomTabs = ({ currentTab, onChange, tabs, type }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let currentIndex = 0;
    tabs.forEach((tab, i) => {
      if (tab.path === currentTab) {
        currentIndex = i;
      }
    });
    setIndex(currentIndex);
    // eslint-disable-next-line
  }, [currentTab]);

  const onTabClick = i => () => {
    onChange(tabs[i].path, i);
  };

  if (tabs && tabs.length) {
    return (
      <Tabs selectedIndex={index} onSelect={() => {}}>
        <TabList className={styles.tabList}>
          {tabs.map((tab, i) => {
            return (
              tab.title && (
                <Tab
                  className={cn(
                    styles.tab,
                    getClassByType(type),
                    index === i ? cn(styles.selected, isAwoWW() && styles.activeAwoWW) : '',
                  )}
                  key={`${i + 1}tabList`}
                  onClick={onTabClick(i)}
                >
                  {tab.title}
                </Tab>
              )
            );
          })}
        </TabList>
        <div className={styles.componentWrapper}>
          {tabs.map((tab, i) => (
            <TabPanel key={`${i + 1}tabComponent`}>{tab.component}</TabPanel>
          ))}
        </div>
      </Tabs>
    );
  }
  return null;
};

function getClassByType(type) {
  switch (type) {
    case 'secondary':
      return styles.secondary;
    default:
      return styles.main;
  }
}

CustomTabs.propTypes = {
  type: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      component: PropTypes.node,
    }),
  ).isRequired,
  onChange: PropTypes.func,
};
CustomTabs.defaultProps = {
  onChange: () => {},
  type: '',
};

export default CustomTabs;
