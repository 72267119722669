import React, { useState } from 'react';
import RadioButton from 'library/common/commonComponents/RadioButton';
import Input from 'library/common/commonComponents/Inputs/Input';
import { useTranslation } from 'react-i18next';

export default function ReturnReceiptPopupHeader(props) {
  const { t } = useTranslation();

  return (
    <div style={{ marginLeft: '15px' }}>
      <RadioButton
        checked={props.headerSelectboxState === 0}
        onClick={() => props.setHeaderSelectboxState(0)}
        label={`${t('Post.Show all')} (${props.allUserCount})`}
      />

      <RadioButton
        checked={props.headerSelectboxState === 1}
        onClick={() => props.setHeaderSelectboxState(1)}
        label={`${t('Post.Show accepted')} (${props.confirmedUserCount})`}
      />

      <RadioButton
        checked={props.headerSelectboxState === 2}
        onClick={() => props.setHeaderSelectboxState(2)}
        label={`${t('Post.Show not accepted')} (${props.notConfirmedUserCount})`}
      />
      <div style={{ marginRight: '10px' }}>
        <Input
          type='text'
          placeholder={t('Post.Search by name')}
          value={props.searchBoxContent}
          onChange={e => props.setSearchBoxContent(e.target.value)}
        />
      </div>
    </div>
  );
}
