import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getToken } from 'library/utilities/token';

import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';

import styles from './info.module.scss';

export default function InfoButton() {

  const { t } = useTranslation();

  return (
    <>
      <KitaPanelWrapper>
        <Wrapper>
          <Col>
            <Row>
              <div className={styles.container}>
                <iframe
                  className={styles.iframecl}
                  src={'https://members2connect.de/'}
                  title='Info'
                />
              </div>
            </Row>
          </Col>
        </Wrapper>
      </KitaPanelWrapper >
    </>
  );
}
