export const defaultGroupState = {
  colorCode: '',
  groupDescription: '',
  groupId: 0,
  groupAdminStatus: false,
  inviteMemberStatus: false,
  showCancelMembership: false,
  showHidePostOnDashboard: false,
  showReceiveNotification: false,
  showModules: false,
  showPosts: false,
  gallerySnippet: { galleryId: 0, files: [] },
  groupModuleList: [],
  groupName: '',
  memberCount: 0,
  userList: [],
  isPrivate: true,
  postCount: 0,
  groupJoinPolicy: {},
};
