import { useRef, useState } from 'react';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { getFileExtension } from 'library/utilities/files';
import { uploadFilesToGroup } from 'library/api/files';

export default function useFileUploading({
  userId,
  groupId,
  moduleId,
  increasePostsCount = () => {},
  folderId,
  isPrivateFolder,
  taxReceiptType,
  taxReason,
  taxPersons,
  taxDate,
}) {
  const fileInput = useRef(null);
  const [progress, setProgress] = useState(null);
  const { t } = useTranslation();

  return {
    fileInput,
    onFileUpload: files =>
      onFileUpload({
        files,
        userId,
        groupId,
        setProgress,
        t,
        moduleId,
        increasePostsCount,
        folderId,
        isPrivateFolder,
        taxReceiptType,
        taxReason,
        taxPersons,
        taxDate,
      }),
    progress,
  };
}

export async function onFileUpload({
  files,
  setProgress,
  userId,
  groupId,
  t,
  moduleId,
  increasePostsCount,
  folderId,
  isPrivateFolder,
  taxReceiptType,
  taxReason,
  taxPersons,
  taxDate,
}) {
  const formData = new FormData();
  let approvedFilesLength = 0;
  for (const file of files) {
    const extension = getFileExtension(file.name);

    if (extension === 'exe') {
      store.dispatch(
        showBottomNotification(t('FileManager.Exe file does not allow to upload'), {
          isFail: true,
        }),
      );
    } else if (extension !== 'zip') {
      formData.append('files', file);
      approvedFilesLength++;
    } else {
      const zip = new JSZip();
      try {
        await zip.loadAsync(file);
        formData.append('files', file);
        approvedFilesLength++;
      } catch (ex) {
        if (ex.message === 'Encrypted zip are not supported') {
          store.dispatch(
            showBottomNotification(t('FileManager.Zip encrypted error'), {
              isFail: true,
            }),
          );
        } else {
          formData.append('files', file);
          approvedFilesLength++;
        }
      }
    }
  }
  if (approvedFilesLength === 0) return [];
  formData.append('userId', userId);
  formData.append('privatePost', isPrivateFolder);
  formData.append('groupId', groupId);
  formData.append('moduleId', moduleId);
  if (folderId) formData.append('folderId', folderId);

  if (taxReceiptType && taxDate) {
    formData.append('taxReceiptType', taxReceiptType);
    formData.append('taxDate', taxDate);
    if (taxReason && taxPersons) {
      formData.append('taxReason', taxReason);
      formData.append('taxPersons', taxPersons);
    }
  }

  try {
    const result = await uploadFilesToGroup(formData, setProgress);
    increasePostsCount(approvedFilesLength);
    setProgress(null);
    return result.map(file => ({
      ...file,
      editedAt: new Date(file.editedAt).getTime(),
    }));
  } catch (ex) {
    if (ex && ex.response && ex.response.status === 413) {
      store.dispatch(showBottomNotification(t('FileManager.Size error'), { isFail: true }));
    } else if (ex && ex.response && ex.response.status === 400) {
      store.dispatch(showBottomNotification(t(ex.response.data.message), { isFail: true }));
    }
    return [];
  } finally {
    setProgress(null);
  }
}
