import React from 'react';
import cn from 'classnames';

import Tooltip from 'library/common/commonComponents/Tooltip';

import styles from './listItemElement.module.scss';
import { isAwoWW } from 'library/api/tenantConfig';

export default function ListItemElement({
  title,
  faIcon,
  onClick,
  isActive,
  theme,
  className,
  iconColor,
  tooltip,
  addClassName,
}) {
  // TODO #nico.achterberg menu item
  return tooltip ? (
    <Tooltip {...tooltip}>
      <div
        className={cn(
          styles.item,
          isAwoWW() && styles.itemAwoWW,
          getClassByTheme(theme),
          className,
          isActive && styles.active,
        )}
        onClick={onClick || (() => {})}
      >
        {faIcon && <i className={cn(styles.icon, 'fa', faIcon)} />}
        <div className={cn(styles.itemTitle)}>{title}</div>
      </div>
    </Tooltip>
  ) : (
    <div
      className={cn(
        styles.item,
        isAwoWW() && styles.itemAwoWW,
        getClassByTheme(theme),
        className,
        isActive && styles.active,
      )}
      onClick={onClick || (() => {})}
    >
      {faIcon && <i style={{ color: iconColor }} className={cn(styles.icon, 'fa', faIcon)} />}
      <div className={cn(styles.itemTitle, addClassName && styles[addClassName])}>{title}</div>
    </div>
  );
}

export function getClassByTheme(theme) {
  if (
    window.location.hostname.toLowerCase().includes('bvf4u.safe2connect.org') &&
    theme == 'yellow'
  ) {
    return styles.themeBlue;
  }
  if (
    window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org') &&
    theme == 'yellow'
  ) {
    return styles.themeBrown;
  }
  switch (theme) {
    case 'light':
      return styles.themeLight;
    case 'yellow':
      return styles.themeYellow;
    case 'dark':
      return styles.themeDark;

    default:
      return styles.themeDark;
  }
}

ListItemElement.defaultProps = {
  iconColor: 'inherit',
};
