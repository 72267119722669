import { useState, useEffect } from 'react';

export default function useMultipleUpload(messageFiles) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (messageFiles) {
      setFiles(messageFiles);
    }
  }, [messageFiles]);

  const uploadFile = (file, cb) => uploadFileHandler({ file, setFiles, files, cb });

  const deleteFiles = (file, cb) => deleteFilesHandler({ file, setFiles, files, cb });

  return {
    files,
    setFiles,
    uploadFile,
    deleteFiles,
  };
}

export function uploadFileHandler({ file, setFiles, files, cb }) {
  if (!file.length) return;

  const filesTmp = Object.keys(file).reduce((result, item) => {
    const itemPath = URL.createObjectURL(file[item]);
    file[item].path = itemPath;
    file[item].fileId = file[item].name;
    return (result = result.concat(file[item]));
  }, []);

  const newNotDuplicatedFiles = filesTmp.filter(fileTmp => {
    const alreadyUploaded = files.find(oldFile => {
      return oldFile.fileId === fileTmp.fileId;
    });
    return !alreadyUploaded;
  });

  setFiles(files.concat(newNotDuplicatedFiles));

  if (cb) {
    cb(files.concat(newNotDuplicatedFiles));
  }
}

export function deleteFilesHandler({ file, setFiles, files, cb }) {
  if (!file) {
    setFiles([]);
  }

  const tmpFiles = files.slice();
  const index = tmpFiles.findIndex(item => item === file);
  if (index !== -1) {
    tmpFiles.splice(index, 1);
    setFiles(tmpFiles.slice());

    if (cb) {
      cb(tmpFiles.slice(), file);
    }
  }
}
