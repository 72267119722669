import React, { useState } from 'react';
import cn from 'classnames';

import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';
import styles from './friendRequestTableRow.module.scss';

export default function FriendRequestTableRow({
  userName,
  firstName,
  lastName,
  buttons,
  history,
  id,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClick = async (fn, e) => {
    e.stopPropagation();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await fn();
    setIsSubmitting(false);
  };

  return (
    <tr className={styles.container} onClick={() => history.push(`/profile/${id}`)}>
      <td className={cn(styles.tableRow, 'notranslate')}>{userName}</td>
      <td className={cn(styles.tableRow, 'notranslate')}>{firstName}</td>
      <td className={cn(styles.tableRow, 'notranslate')}>{lastName}</td>
      <td className={styles.tableRowButton}>
        {buttons.map(button => (
          <Button
            key={button.id}
            onClick={e => handleClick(button.onClick, e)}
            className={button.className}
          >
            {isSubmitting ? (
              <Loader dotColor='#fff' dotSize='8px' className={styles.loader} />
            ) : (
              button.title
            )}
          </Button>
        ))}
      </td>
    </tr>
  );
}
