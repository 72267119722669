import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLeavingTimes, deleteKidLeavingTime } from 'library/api/kids';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import SortIndicator from 'library/common/commonComponents/SortIndicator';
import { formatDateObjectToTimeString } from 'library/utilities/dateMoment';
import { sortList } from 'library/utilities/sort';
import KidLeavingTimePopupContainer from '../KidLeavingTimePopupContainer';

import styles from './kidLeavingTimeContainer.module.scss';

export default function KidLeavingTimeContainer({ user, data }) {
  const [searchValue, setSearchValue] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [leavingTimes, setLeavingTimes] = useState([]);
  const [editPopup, setEditPopup] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const { t } = useTranslation();
  const [sorting, setSorting] = useState({ name: 'bookingDate', direction: 'desc' });

  const fetchChildrenLeavingTimes = useCallback(() => {
    setIsFetching(true);
    setLeavingTimes([]);
    getLeavingTimes()
      .then(res => {
        if (res.status === 200) {
          const data = res.data || [];
          const leavingTimeList = data.map(item => ({
            ...item,
            leavingTime: formatDateObjectToTimeString(item.leavingTime),
          }));
          setLeavingTimes(leavingTimeList);
        }
      })
      .catch()
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  const sortBy = nameOfSortObject => {
    if (sorting.name === nameOfSortObject) {
      if (sorting.direction === 'asc') {
        setSorting({ direction: 'desc', name: nameOfSortObject });
      } else {
        setSorting({ direction: 'asc', name: nameOfSortObject });
      }
    } else {
      setSorting({ direction: 'asc', name: nameOfSortObject });
    }
  };

  useEffect(() => {
    fetchChildrenLeavingTimes();
  }, [fetchChildrenLeavingTimes]);

  const prepared = leavingTimes.map(entry => ({
    name: `${entry.kid.firstName} ${entry.kid.lastName}`,
    ...entry,
  }));
  const sorted = sortList(prepared, sorting, 'bookingDate');

  const filteredData =
    searchValue.length > 0
      ? sorted.filter(item =>
          (item.kid.firstName + ' ' + item.kid.lastName)
            .toLowerCase()
            .includes(searchValue.toLowerCase()),
        )
      : sorted;

  const editRecord = item => {
    setEditPopup(true);
    setEditItem(item);
  };

  const deleteRecord = item => {
    setDeletePopup(true);
    setDeleteItem(item);
  };

  const handleCloseEditPopup = () => {
    setEditPopup(false);
    setEditItem(null);
  };

  const handleAddItem = data => {
    const updatedList = leavingTimes.slice();
    updatedList.push(data);
    setLeavingTimes(updatedList);
  };

  const handleEditItem = (id, data) => {
    const updatedList = leavingTimes.map(row => {
      if (row.id === id) {
        return {
          ...row,
          ...data,
        };
      } else {
        return row;
      }
    });
    setLeavingTimes(updatedList);
  };

  const sendFormForDelete = item => {
    setIsDeleting(true);
    deleteKidLeavingTime(item.id)
      .then(res => {
        if (res.status === 200) {
          const updatedList = leavingTimes.filter(row => row.id !== item.id);
          setLeavingTimes(updatedList);
        }
      })
      .catch()
      .finally(() => {
        setIsDeleting(false);
        setDeletePopup(false);
      });
  };

  return (
    <div>
      {editPopup && (
        <Popup
          size='normal'
          isOpened
          closePopup={() => setEditPopup(false)}
          header={<strong>{t('Checkinout.Leaving Time')}</strong>}
        >
          <KidLeavingTimePopupContainer
            user={user}
            leavingTimes={leavingTimes}
            kidOptions={data}
            editItem={editItem}
            onAdd={handleAddItem}
            onEdit={handleEditItem}
            closePopUp={handleCloseEditPopup}
          />
        </Popup>
      )}
      {deletePopup && (
        <Popup
          closePopup={() => setDeletePopup(false)}
          isOpened
          footer={
            !isDeleting ? (
              <div className={styles.buttonsContainer}>
                <div className={styles.buttonSubmit}>
                  <Button onClick={() => sendFormForDelete(deleteItem)} type='primary'>
                    {t('AccountSettings.AbsenceSettings.Yes')}
                  </Button>
                </div>
                <div className={styles.buttonSubmit}>
                  <Button onClick={() => setDeletePopup(false)} type='primary'>
                    {t('AccountSettings.AbsenceSettings.No')}
                  </Button>
                </div>
              </div>
            ) : (
              <Loader />
            )
          }
        >
          <div className={styles.containerEnable}>
            <h2>Do you want to delete this record?</h2>
          </div>
        </Popup>
      )}
      <Button className={styles.saveButton} onClick={() => setEditPopup(true)} type='primary'>
        {t('Checkinout.Add')}
      </Button>
      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>

      <table className={styles.table}>
        <tbody>
          <tr>
            <th className={styles.headerText} onClick={() => sortBy('name')}>
              <div className={styles.headerTextContainer}>
                {t('Checkinout.Name')}
                <SortIndicator sort={sorting} name='name' />
              </div>
            </th>
            <th className={styles.headerText} onClick={() => sortBy('note')}>
              <div className={styles.headerTextContainer}>
                {t('Checkinout.Note')}
                <SortIndicator sort={sorting} name='note' />
              </div>
            </th>
            <th className={styles.headerText} onClick={() => sortBy('bookingDate')}>
              <div className={styles.headerTextContainer}>
                {t('Checkinout.bookingDate')}
                <SortIndicator sort={sorting} name='bookingDate' />
              </div>
            </th>
            <th>{t('Actions')}</th>
          </tr>
          {isFetching ? (
            <tr>
              <td>
                <Loader />
              </td>
            </tr>
          ) : (
            filteredData.map(item => {
              return (
                <tr key={item.id}>
                  <td>
                    {item.kid.firstName} {item.kid.lastName}
                  </td>
                  <td>{item.note}</td>
                  <td>
                    {item.bookingDate} {item.leavingTime}
                  </td>
                  <td>
                    <div className={styles.leavingTimeItemActions}>
                      <i className='fa fa-lg fa-cog' onClick={() => editRecord(item)} />
                      <i className='fa fa-lg fa-trash' onClick={() => deleteRecord(item)} />
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
