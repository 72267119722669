import React, { useState, useEffect, useRef } from 'react';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import { getChristusErloeserConsent } from 'library/api/user';
import GeneralConsent from './GeneralConsent';
import CaptureGeneralInformation from './CaptureGeneralInformation';
import BaptismInformation from './BaptismInformation';
import CapturePaymentDecision from './CapturePaymentDecision';
import ImageConsent from './ImageConsent';

export default function ChristusErloeserConsentModule({
  activeKita,
  userInfo,
  isAdminEditing,
  type,
  currentUserActiveKita,
}) {
  const [currentPage, setCurrentPage] = useState(0);

  function handleTextStateChange(newValue, property) {
    setFormData(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  const [formData, setFormData] = useState({
    kidFirstName: '',
    kidLastName: '',
    kidBirthday: new Date().toLocaleDateString('en-CA'),
    streetAndHouseNo: '',
    zipAndCity: '',
    kidSchool: '',
    kidClass: '',
    legalGuardianPhone: '',
    legalGuardianMail: '',
    motherName: '',
    motherReligion: '',
    fatherName: '',
    fatherReligion: '',
    kidSpecialNeeds: '',
    baptismDate: new Date().toLocaleDateString('en-CA'),
    baptismCertificate: '',
    parish: '',
    parishLivingPlace: '',
    signatureDate: new Date().toLocaleDateString('en-CA'),
    checkBox1: false,
    checkBox2: false,
    checkBox3: false,
    radioButton1: false,
    radioButton2: false,
  });

  useEffect(() => {
    if (type === 'profileFrame') {
      getChristusErloeserConsent(isAdminEditing ? userInfo.id : null).then(data => {
        if (data.data !== null) {
          const result = data.data;
          let signatureDate = null;
          if (result.signatureDate && result.signatureDate.dayOfMonth) {
            const tempSignatureDate = new Date(
              result.signatureDate.year,
              result.signatureDate.monthValue - 1,
              result.signatureDate.dayOfMonth,
            );
            signatureDate = tempSignatureDate.toLocaleDateString('en-CA', {
              // you can use undefined as first argument
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
          }
          let kidBirthday = null;
          if (result.kidBirthday && result.kidBirthday.dayOfMonth) {
            const tempKidBirthday = new Date(
              result.kidBirthday.year,
              result.kidBirthday.monthValue - 1,
              result.kidBirthday.dayOfMonth,
            );
            kidBirthday = tempKidBirthday.toLocaleDateString('en-CA', {
              // you can use undefined as first argument
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
          }

          let baptismDate = null;
          if (result.baptismDate && result.baptismDate.dayOfMonth) {
            const tempBaptismDate = new Date(
              result.baptismDate.year,
              result.baptismDate.monthValue - 1,
              result.baptismDate.dayOfMonth,
            );
            baptismDate = tempBaptismDate.toLocaleDateString('en-CA', {
              // you can use undefined as first argument
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
          }

          setFormData({
            ...result,
            baptismDate: baptismDate,
            signatureDate: signatureDate,
            kidBirthday: kidBirthday,
          });
        }
      });
    }
  }, []);

  function changeCurrentPage(direction) {
    if (direction === 1) {
      setCurrentPage(currentPage + 1);
    }
    if (direction === -1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function getComponentByCurrentPage() {
    switch (currentPage) {
      case 0:
        return (
          <GeneralConsent
            changeCurrentPage={changeCurrentPage}
            activeKita={activeKita}
            currentUserActiveKita={currentUserActiveKita}
          />
        );
      case 1:
        return (
          <CaptureGeneralInformation
            changeCurrentPage={changeCurrentPage}
            handleTextStateChange={handleTextStateChange}
            formData={formData}
            type={type}
          />
        );
      case 2:
        return (
          <BaptismInformation
            changeCurrentPage={changeCurrentPage}
            handleTextStateChange={handleTextStateChange}
            formData={formData}
            setFormData={setFormData}
            type={type}
          />
        );
      case 3:
        return (
          <CapturePaymentDecision
            changeCurrentPage={changeCurrentPage}
            handleTextStateChange={handleTextStateChange}
            formData={formData}
            type={type}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <ImageConsent
            changeCurrentPage={changeCurrentPage}
            handleTextStateChange={handleTextStateChange}
            formData={formData}
            type={type}
            setFormData={setFormData}
          />
        );
    }
    return <></>;
  }

  return (
    <>
      <KitaPanelWrapper>{getComponentByCurrentPage()}</KitaPanelWrapper>
    </>
  );
}
