import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from 'react-i18next';
import {
  getUserKidsHistory,
  getKidsHistoryByGroup,
  deleteCheckinoutEntry,
  editCheckinoutEntryTime
} from 'library/api/kids';
import { getKidHistory } from 'library/api/kidDevelopmentDiary';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import { createPDFCheckinout } from 'library/utilities/files';
import { checkScrollLoading, useLoadingOnScroll } from 'library/common/commonHooks/useScrollLoading';
import store from 'main/store/configureStore';
import { GroupContext } from 'modules/Group/groupModule';

import HistoryRow from './HistoryRow';
import styles from './historyContainer.module.scss';

const HistoryContainer = ({ groupOnly }) => {
  const [searchValue, setSearchValue] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [entryToDelete, setEntryToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [entryToEdit, setEntryToEdit] = useState(null);

  const [isSavingEdits, setIsSavingEdits] = useState(false);
  const [editError, setEditError] = useState(null);

  const [editTime, setEditTime] = useState('');
  const currentPaginationPage = useRef(1);
  const isScrollLoading = useRef(false);
  const isScrollEnded = useRef(false);

  const { t } = useTranslation();
  const groupContext = useContext(GroupContext);
  const { groupInfo } = groupContext;

  const filteredDataPlus =
    searchValue.length > 0
      ? historyData.filter(item =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.initiator.toLowerCase().includes(searchValue.toLowerCase()))
      : historyData;

  const loadUserKidsHistory = () => {
    if (isScrollLoading.current || isScrollEnded.current) {
      return;
    }

    isScrollLoading.current = true;
    setIsLoading(true);

    const params = {
      page: currentPaginationPage.current
    };

    const result =
      groupOnly === true ? getKidsHistoryByGroup(groupInfo.groupId, params) : getUserKidsHistory(params);
    result
      .then(res => {
        if (res.status === 200) {
          currentPaginationPage.current += 1;
          const history = res.data || [];
          const sortedHistory = history.sort(function (a, b) {
            return new Date(b.actionDate) - new Date(a.actionDate);
          });

          if (res.data.length < 20) {
            isScrollEnded.current = true;
          }

          getKidNotesMap(sortedHistory).then(kidNotesMap => {
            isScrollLoading.current = false;
            const kidHistoryWithNotes = addNotesToKidHistory(history, kidNotesMap);
            setHistoryData(prevState => [...prevState, ...kidHistoryWithNotes]);
          });
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  };

  useLoadingOnScroll(() => checkScrollLoading(loadUserKidsHistory, 1.2));

  useEffect(() => {
    loadUserKidsHistory();
  }, []);

  async function getKidNotesMap(filteredHistory) {
    const curDate = new Date();
    const month = curDate.getUTCMonth() + 1;
    const year = curDate.getUTCFullYear();

    const monthYearString =
      month.toString().padStart(2, '0') + '.' + year.toString().padStart(4, '0');

    const kidIds = [
      ...new Set(
        filteredHistory.map(kh => {
          return kh.kidId || kh.id;
        }),
      ),
    ];

    return Object.fromEntries(
      new Map(
        await Promise.all(
          kidIds.map(async id => {
            const res = await getKidHistory(id, monthYearString);
            return [id, res.data.kidDevelopmentDiaries.filter(e => e.action === 'checkinout')];
          }),
        ),
      ),
    );
  }

  function computeHistoryNotesMap(history, kidNotesMap) {
    const result = {};

    for (const kidId in kidNotesMap) {
      const notes = kidNotesMap[kidId];

      const kidHistoryElems = history.filter(h => h.kidId === parseInt(kidId, 10));

      notes.forEach(note => {
        const noteDate = new Date(`${note.date.replace(' ', 'T')}.000+0000`);

        for (const historyElem of kidHistoryElems) {
          const historyDate = new Date(historyElem.actionDate);

          if (noteDate.getTime() >= historyDate.getTime()) {
            const mapKey = `${kidId}_${historyElem.actionDate}`;

            if (!(mapKey in result)) {
              result[mapKey] = [];
            }

            result[mapKey].push(note);

            break;
          }
        }
      });
    }

    return result;
  }

  function addNotesToKidHistory(history, kidNotesMap) {
    const historyNotesMap = computeHistoryNotesMap(history, kidNotesMap);

    return history.map(historyElem => {
      return {
        ...historyElem,
        notes: historyNotesMap[`${historyElem.kidId}_${historyElem.actionDate}`],
      };
    });
  }

  const handleDeleteEntry = id => {
    setIsDeleting(true);
    deleteCheckinoutEntry(groupInfo.groupId, id)
      .then(() => {
        setEntryToDelete(null);
        setHistoryData(historyData.filter(i => i.id !== id));
        setEditError('');
        store.dispatch(showBottomNotification(t('Checkinout.DeleteSuccess'), { isFail: false }));
      })
      .catch(() => {
        store.dispatch(showBottomNotification(t('Checkinout.DeleteError'), { isFail: true }));
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const handleEditEntry = (item, newTime) => {
    const ind1 = historyData.indexOf(item);

    const predecessors = historyData.filter((p, ind2) => ind2 > ind1 && p.kidId === item.kidId);
    const predecessor = predecessors == null || predecessors.length === 0 ? null : predecessors[0];

    const successors = historyData.filter((p, ind2) => ind2 < ind1 && p.kidId === item.kidId);
    const successor =
      successors == null || successors.length === 0 ? null : successors[successors.length - 1];

    // validate time format
    const formatValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(newTime);
    if (!formatValid) {
      setEditError(t('Checkinout.EditEntryFormatError'));
      return;
    }

    const parts = newTime.split(':');
    const newDate = new Date();
    newDate.setHours(parseInt(parts[0], 10));
    newDate.setMinutes(parseInt(parts[1], 10));
    newDate.setSeconds(parts.length > 2 ? parseInt(parts[2], 10) : 0);

    const previousDate = predecessor == null ? null : new Date(predecessor.actionDate);
    const nextDate = successor == null ? null : new Date(successor.actionDate);
    if (previousDate != null && newDate.getTime() <= previousDate.getTime()) {
      setEditError(t('Checkinout.EditEntryPredecessorError'));
      return;
    }

    if (nextDate != null && newDate.getTime() >= nextDate.getTime()) {
      setEditError(t('Checkinout.EditEntrySuccessorError'));
      return;
    }

    setIsSavingEdits(true);

    editCheckinoutEntryTime(groupInfo.groupId, item.id, newDate.getTime())
      .then(() => {
        setEntryToEdit(null);
        setHistoryData(
          historyData.map(i => {
            return {
              ...i,
              actionDate: i.id === item.id ? newDate.toISOString() : i.actionDate,
            };
          }),
        );

        store.dispatch(showBottomNotification(t('Checkinout.EditTimeSuccess'), { isFail: false }));
      })
      .catch(() => {
        store.dispatch(showBottomNotification(t('Checkinout.EditTimeError'), { isFail: true }));
      })
      .finally(() => {
        setIsSavingEdits(false);
      });
  };

  return (
    <div>
      <DeletePopup
        isOpened={entryToDelete !== null}
        closePopup={() => setEntryToDelete(null)}
        onDeleteBtnClick={() => handleDeleteEntry(entryToDelete)}
        headerText={t('Checkinout.ConfirmDeleteTitle')}
        bodyText={t('Checkinout.ConfirmDeleteText')}
        isSubmiting={isDeleting}
      />

      <Popup
        isOpened={entryToEdit != null}
        closePopup={() => {
          setEntryToEdit(null);
          setEditTime('');
          setEditError('');
        }}
        header={t('Checkinout.EditEntryHeader')}
        body={
          <div>
            <Input
              value={editTime}
              placeholder={t('Checkinout.EditEntryTimePlaceholder')}
              onChange={e => {
                const newTime = e.target.value;

                setEditTime(newTime);
              }}
            />
            {editError && <p style={{ color: 'red', marginTop: '0.5em' }}>{editError}</p>}
          </div>
        }
        footer={
          <div>
            <Button
              onClick={() => {
                setEntryToEdit(null);
                setEditTime('');
                setEditError('');
              }}
            >
              {t('Popup.Cancel')}
            </Button>
            <ButtonWithLoader
              onClick={() => handleEditEntry(entryToEdit, editTime)}
              type='primary'
              isLoading={isSavingEdits}
            >
              {t('Popup.Save')}
            </ButtonWithLoader>
          </div>
        }
      />

      <Button
        onClick={() => {
          createPDFCheckinout(historyData);
        }}
        type='light'
        size='sm'
      >
        {t('Absences.Export')}
      </Button>
      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>
      <div className={styles.historyTable}>
        <div className={styles.header}>
          <div className={styles.avatar} />
          <div className={styles.name}>{t('Checkinout.Name')}</div>
          <div className={styles.executivePersonTitle}>{t('Checkinout.Executive person')}</div>
          <div className={styles.actionTitle}>{t('Checkinout.Action')}</div>
          <div className={styles.group}>{t('Checkinout.Group')}</div>
          <div className={styles.pickupPerson}>{t('Checkinout.Pickup person')}</div>
          <div className={styles.note}>{t('Checkinout.Note')}</div>
          <div className={styles.date}>{t('Checkinout.Date')}</div>
        </div>
        {filteredDataPlus.map(item => {
          return (
            <HistoryRow
              childObj={item}
              groupOnly={groupOnly}
              setEntryToDelete={setEntryToDelete}
              setEntryToEdit={setEntryToEdit}
            />
          );
        })}
        {isLoading && (<Loader />)}
      </div>
    </div>
  );
};

export default HistoryContainer;
