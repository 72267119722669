import React, { useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import { submitVotes } from 'library/api/parentSurveys';
import Label from 'library/common/commonComponents/Label';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import useBlockShowing from 'library/common/commonHooks/useBlockShowing';
import Button from 'library/common/commonComponents/Buttons/Button';
import ParentSurveysCreateInput from 'library/common/commonComponents/ParentSurveysCreateInput';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import {
  createAndDownloadCSV,
  createAndDownloadPDF,
  validateParentSurveySubmit,
  extractVotes,
  flattenQuestions,
  getUserHasVoted,
  getTotalVotesCompleted,
  sortQuestions,
  getUserVotes,
} from 'library/utilities/parentSurveys';
import FeedItemParentSurveyFilter from './feedItemParentSurveyFilter';
import FeedItemParentSurveyQuestion from './feedItemParentSurveyQuestion';

import styles from './feedItemParentSurvey.module.scss';

export default function FeedItemParentSurvey({
  kidsCount,
  showBottomNotification,
  updateFeedById,
  isTranslationAllowed,
  id,
  parentSurvey,
  isEditing,
  setIsEditing,
  user,
  groupInfo,
  activeKita,
  endTimeDate,
  endTimeStatus,
  startTimeDate,
  startTimeStatus,
}) {
  const { t } = useTranslation();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [missedQuestionIds, setMissedQuestionIds] = useState([]);

  const [canUserVote, setCanUserVote] = useState(
    !(endTimeStatus === true && endTimeDate !== null && Date.now() > endTimeDate) &&
      !(startTimeStatus === true && startTimeDate !== null && Date.now() < startTimeDate),
  );

  parentSurvey.userHasVoted = getUserHasVoted(parentSurvey, user.id);
  parentSurvey.total_votes_completed = getTotalVotesCompleted(parentSurvey, user.id);

  parentSurvey.questions = sortQuestions(parentSurvey.questions);

  const { blockClassNames, showBlock, hideBlock } = useBlockShowing();

  const [parentSurveyDataOriginal, setParentSurveyDataOriginal] = useState(parentSurvey);

  const [usersCompletedFilteredCount, setUsersCompletedFilteredCount] = useState(null);

  const [votesGiven, setVotesGiven] = useState(getUserVotes(parentSurvey, user.id));

  const filteredSurveyData = applyFilter(
    { questionId: -1, optionId: -1 },
    parentSurveyDataOriginal,
  );

  const [parentSurveyData, parentSurveyDispatch] = useReducer(
    parentSurveyReducer,
    filteredSurveyData,
  );

  if (parentSurvey.updated) {
    parentSurvey.updated = false;
    setParentSurveyDataOriginal(parentSurvey);
    const filteredNewData = applyFilter({ questionId: -1, optionId: -1 }, parentSurvey);
    parentSurveyDispatch({
      type: 'updateParentSurvey',
      state: filteredNewData,
    });
  }
  // if updateFeedById has been called

  useEffect(() => {
    const interval = setInterval(() => {
      if (canUserVote) {
        if (endTimeStatus === true && endTimeDate !== null && Date.now() > endTimeDate) {
          setCanUserVote(false);
        }
        if (startTimeStatus === true && startTimeDate !== null && Date.now() < startTimeDate) {
          setCanUserVote(false);
        }
      } else {
        if (
          startTimeStatus === true &&
          startTimeDate !== null &&
          Date.now() > startTimeDate &&
          (!endTimeStatus || endTimeDate === null || Date.now() < endTimeDate)
        ) {
          setCanUserVote(true);
        }
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const onFilterChange = filter => {
    const filteredParentSurvey = applyFilter(
      filter,
      parentSurveyDataOriginal,
      parentSurveyDispatch,
    );
    parentSurveyDispatch({
      type: 'applyFilter',
      parentSurveyFiltered: filteredParentSurvey,
    });
    setUsersCompletedFilteredCount(
      filteredParentSurvey.filteredParticipantCount === null
        ? filteredParentSurvey.total_votes_completed
        : filteredParentSurvey.filteredParticipantCount,
    );
  };

  const resetUsersCompletedFilteredCount = () => {
    setUsersCompletedFilteredCount(null);
  };

  const returnQuestionTopic = question => {
    if (
      !(
        window.location.hostname.toLowerCase().includes('staging1.safe2connect.org') ||
        window.location.hostname.toLowerCase().includes('awobamberg.safe2connect.org')
      )
    ) {
      return;
    }
    if (question === 'Angebote im Bereich Sport und Bewegung') {
      return (
        <b>
          {t('ParentSurvey.How satisfied are you with the following offerings at your facility')}
        </b>
      );
    }
    if (question === 'Bringsituation') {
      return (
        <b>
          {t(
            'ParentSurvey.How satisfied are you with the following situations in your institution',
          )}
        </b>
      );
    }
    if (question === 'Optische Gestaltung der Räume') {
      return (
        <b>
          {t(
            'ParentSurvey.Please rate your satisfaction with the following framework conditions in your facility',
          )}
        </b>
      );
    }
    if (question === 'Informationen über die pädagogische Konzeption') {
      return (
        <b>{t('ParentSurvey.How satisfied are you with information about the following things')}</b>
      );
    }
    if (question === 'Elternabende') {
      return (
        <b>
          {t('ParentSurvey.How satisfied are you with the following things about our facility')}
        </b>
      );
    }
  };

  const collectSecondaryAnswers = questions => {
    let result = {};
    questions.forEach(q => {
      if (q.parentSurveyQuestionOptions) {
        q.parentSurveyQuestionOptions.forEach(op => {
          if (op.secondaryAnswer) {
            result[op.id] = op.secondaryAnswer;
          }
        });
      }
      if (q.subQuestions) {
        const subAnswers = collectSecondaryAnswers(q.subQuestions);
        result = { ...result, ...subAnswers };
      }
    });

    return result;
  };

  const voteAgain = () => {
    const newParentSurvey = {
      ...parentSurvey,
      userHasVoted: false,
      questions: parentSurvey.questions.map(question => ({
        ...question,
        parentSurveyQuestionOptions: question.parentSurveyQuestionOptions.map(option => ({
          ...option,
          parentSurveyQuestionOptionVote: [],
        })),
      })),
    };
    parentSurveyDispatch({
      type: 'updateParentSurvey',
      state: newParentSurvey,
    });
  };

  const onSubmit = () => {
    const submitErrors = validateParentSurveySubmit(parentSurveyData, t);
    setErrors(submitErrors.errors);
    setMissedQuestionIds(submitErrors.missingQuestions);

    const secondaryAnswers = collectSecondaryAnswers(parentSurveyData.questions, secondaryAnswers);

    if (submitErrors.errors.length === 0 && submitErrors.missingQuestions.length === 0) {
      const checkedOptionIds = extractVotes(parentSurveyData);
      onSubmitHandler(
        parentSurveyData,
        checkedOptionIds,
        secondaryAnswers,
        setIsSubmiting,
        updateFeedById,
        parentSurveyDispatch,
        showBottomNotification,
        t,
        setVotesGiven,
        user.id,
      );
    }
  };

  const renderQuestionWithSubQuestions = (question, isFirstLevel) => {
    if (question.subQuestions) {
      question.subQuestions.forEach(element => {
        element.parentSurveyQuestionOptions.sort((a, b) =>
          a.orderPosition < b.orderPosition ? -1 : 1,
        );
      });
    }
    return (
      <div style={isFirstLevel ? { display: 'block', marginLeft: '5em' } : {}} key={question.id}>
        {returnQuestionTopic(question.question)}
        <FeedItemParentSurveyQuestion
          key={question.id}
          isPublished={parentSurveyData.isPublished}
          setIsSubmiting={setIsSubmiting}
          parentSurveyIsClosed={parentSurveyData.isClosed}
          question={question}
          isTranslationAllowed={isTranslationAllowed}
          parentSurveyDispatch={parentSurveyDispatch}
          isSubmiting={isSubmiting}
          userHasVoted={parentSurveyData.userHasVoted}
          user={user}
          parentSurveyDataId={parentSurveyData.id}
          groupInfo={groupInfo}
          missedQuestionIds={missedQuestionIds}
        />
        {question.subQuestions
          ? question.subQuestions.map(subQuestion => {
              return renderQuestionWithSubQuestions(subQuestion, true);
            })
          : null}
      </div>
    );
  };

  const totalVotes = kidsCount ?? 1;
  const canVoteAgain = totalVotes > votesGiven;

  const [showSurvey, setShowSurvey] = useState(false);
  const toggleShow = () => {
    if (showSurvey) {
      hideBlock();
    } else {
      showBlock();
    }
    setShowSurvey(!showSurvey);
  };

  return (
    <>
      <div className={styles.container}>
        {isEditing ? (
          <ParentSurveysCreateInput
            groupInfo={groupInfo}
            onCreate={({ data }) => {
              updateFeedById(
                data.id,
                {
                  title: data.title,
                  parentSurveyQuestions: data.parentSurveyQuestions,
                  updated: true,
                },
                { withoutRequest: true },
              );
            }}
            parentSurvey={parentSurvey}
            setIsEditing={setIsEditing}
          />
        ) : (
          <>
            <div className={styles.labels}>
              {endTimeDate != null && endTimeDate > Date.now() && (
                <div className={styles.label}>
                  <Label type='danger'>
                    {t('Survey.Expiry')} {new Date(endTimeDate).toLocaleDateString('de-DE')}{' '}
                    {new Date(endTimeDate).toLocaleTimeString('de-DE').replace(/(.*)\D\d+/, '$1')}
                  </Label>
                </div>
              )}
              {parentSurveyData.isClosed && (
                <div className={styles.label}>
                  <Label type='danger'>{t('Surveys.Closed')}</Label>
                </div>
              )}
            </div>
            {parentSurveyData.isClosed && (
              <div className={styles.card}>
                <FeedItemParentSurveyFilter
                  questions={flattenQuestions(parentSurveyData.questions)}
                  onFilterChange={onFilterChange}
                  resetUsersCompletedFilteredCount={resetUsersCompletedFilteredCount}
                />
                {parentSurveyData.userIsKitaAdmin && (
                  <>
                    <Button
                      className={styles.exportButton}
                      size='sm'
                      onClick={() => createAndDownloadPDF(parentSurveyData, activeKita.kitaName, t)}
                    >
                      <i className='fa fa-file-pdf-o fa-2x' />
                    </Button>
                    <Button
                      className={styles.exportButton}
                      size='sm'
                      onClick={() => createAndDownloadCSV(parentSurveyData, t)}
                    >
                      <i className='fa fa-table fa-2x' />
                    </Button>
                  </>
                )}
              </div>
            )}
            <p className={styles.totalVotesCompleted}>
              {parentSurvey.total_votes_completed}{' '}
              {t('ParentSurvey.Users have completed the survey')}
            </p>
            {usersCompletedFilteredCount !== null ? (
              <p className={styles.totalVotesCompleted}>
                {usersCompletedFilteredCount} (
                {((usersCompletedFilteredCount / parentSurvey.total_votes_completed) * 100).toFixed(
                  2,
                )}
                %) {t('ParentSurvey.UsersCompletedFilteredCount')}
              </p>
            ) : null}
            {isTranslationAllowed ? (
              <WithWatsonTranslate
                data={{
                  text: parentSurveyData.title,
                  entityId: parentSurveyData.id,
                  entityType: 'parent_survey',
                }}
                Component={({ html }) => (
                  <h2 className={styles.ml2} dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                )}
              />
            ) : (
              <h2
                className={styles.ml2}
                dangerouslySetInnerHTML={{ __html: sanitize(parentSurveyData.title) }}
              />
            )}
            <Button className={styles.showSurveyBtn} onClick={() => toggleShow()}>
              {showSurvey ? (
                <>
                  {t('ParentSurvey.hideQuestions')} <i className='fa fa-arrow-up' />
                </>
              ) : (
                <>
                  {t('ParentSurvey.ShowQuestions')} <i className='fa fa-arrow-down' />
                </>
              )}{' '}
            </Button>
            <div className={blockClassNames}>
              {parentSurveyData &&
                parentSurveyData.questions &&
                parentSurveyData.questions.map(question =>
                  renderQuestionWithSubQuestions(question),
                )}
              {!parentSurveyData.isClosed && !parentSurveyData.userHasVoted && (
                <ButtonWithLoader
                  type='primary'
                  className={styles.voteButton}
                  onClick={onSubmit}
                  isLoading={isSubmiting}
                >
                  {t('Surveys.Vote')}
                </ButtonWithLoader>
              )}
            </div>
            {parentSurveyData.userHasVoted && (
              <>
                {canVoteAgain && !parentSurveyData.isClosed ? (
                  <>
                    <p className={styles.userHasVoted}>
                      {t('ParentSurvey.You have already voted')
                        .replace('{VOTES_GIVEN}', votesGiven)
                        .replace('{TOTAL_VOTES}', totalVotes)}{' '}
                      <i className='fa fa-check' />
                    </p>
                    <Button className={styles.voteAgainBtn} onClick={() => voteAgain()}>
                      {t('ParentSurvey.Vote again')}
                    </Button>
                  </>
                ) : (
                  <>
                    <p className={styles.userHasVoted}>
                      {t('ParentSurvey.You have already voted')
                        .replace('{VOTES_GIVEN}', votesGiven)
                        .replace('{TOTAL_VOTES}', totalVotes)}{' '}
                      <i className='fa fa-check' />
                    </p>
                  </>
                )}
              </>
            )}

            <div className={styles.errors}>
              {errors.map(error => (
                <div className={styles.error} key={error}>
                  {error}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );

  function changeVoteWithSubQuestions(questions, questionId, optionId, multipleAnswerStatus) {
    return questions.map(question => {
      if (questionId === question.id) {
        if (multipleAnswerStatus) {
          return {
            ...question,
            parentSurveyQuestionOptions: question.parentSurveyQuestionOptions.map(option =>
              optionId === option.id
                ? {
                    ...option,
                    checked: !option.checked,
                  }
                : option,
            ),
          };
        }

        return {
          ...question,
          parentSurveyQuestionOptions: question.parentSurveyQuestionOptions.map(option =>
            optionId === option.id
              ? {
                  ...option,
                  checked: !option.checked,
                }
              : {
                  ...option,
                  checked: false,
                },
          ),
        };
      } else {
        if (!question.subQuestions || question.subQuestions.length < 1) return question;

        return {
          ...question,
          subQuestions: changeVoteWithSubQuestions(
            question.subQuestions,
            questionId,
            optionId,
            multipleAnswerStatus,
          ),
        };
      }
    });
  }

  function changeVoteTypeNumberWithSubQuestions(questions, questionId, answer) {
    return questions.map(question => {
      if (questionId === question.id) {
        return {
          ...question,
          parentSurveyQuestionOptions: question.parentSurveyQuestionOptions.map(option =>
            option.answer === answer
              ? {
                  ...option,
                  checked: true,
                }
              : {
                  ...option,
                  checked: false,
                },
          ),
        };
      } else {
        if (!question.subQuestions || question.subQuestions.length < 1) return question;

        return {
          ...question,
          subQuestions: changeVoteTypeNumberWithSubQuestions(
            question.subQuestions,
            questionId,
            answer,
          ),
        };
      }
    });
  }

  function changeVoteSecondaryAnswerWithSubQuestions(questions, questionId, answer) {
    return questions.map(question => {
      if (questionId === question.id) {
        return {
          ...question,
          parentSurveyQuestionOptions: question.parentSurveyQuestionOptions.map(option => {
            return {
              ...option,
              secondaryAnswer: answer,
            };
          }),
        };
      } else {
        if (!question.subQuestions || question.subQuestions.length < 1) return question;

        return {
          ...question,
          subQuestions: changeVoteSecondaryAnswerWithSubQuestions(
            question.subQuestions,
            questionId,
            answer,
          ),
        };
      }
    });
  }

  function addSuggestionWithSubQuestions(questions, questionId, optionId, answer, createdBy) {
    return questions.map(question => {
      if (questionId === question.id) {
        return {
          ...question,
          parentSurveyQuestionOptions: [
            ...question.parentSurveyQuestionOptions,
            {
              id: optionId,
              answer,
              suggestionAdded: true,
              type: 'suggestion',
              checked: true,
              createdBy,
              merged: createdBy !== user.id,
              parentSurveyQuestionOptionVote: [],
            },
          ],
        };
      } else {
        if (!question.subQuestions || question.subQuestions.length < 1) return question;

        return {
          ...question,
          subQuestions: addSuggestionWithSubQuestions(
            question.subQuestions,
            questionId,
            optionId,
            answer,
            createdBy,
          ),
        };
      }
    });
  }

  function deleteSuggestionWithSubQuestions(questions, questionId, optionId) {
    return questions.map(question => {
      if (questionId === question.id) {
        return {
          ...question,
          parentSurveyQuestionOptions: question.parentSurveyQuestionOptions.filter(
            option => optionId !== option.id,
          ),
        };
      } else {
        if (!question.subQuestions || question.subQuestions.length < 1) return question;

        return {
          ...question,
          subQuestions: deleteSuggestionWithSubQuestions(
            question.subQuestions,
            questionId,
            optionId,
          ),
        };
      }
    });
  }

  function editSuggestionWithSubQuestions(questions, questionId, optionId, answer) {
    return questions.map(question => {
      if (questionId === question.id) {
        return {
          ...question,
          parentSurveyQuestionOptions: question.parentSurveyQuestionOptions.map(option =>
            optionId === option.id
              ? {
                  ...option,
                  answer,
                }
              : option,
          ),
        };
      } else {
        if (!question.subQuestions || question.subQuestions.length < 1) return question;

        return {
          ...question,
          subQuestions: editSuggestionWithSubQuestions(
            question.subQuestions,
            questionId,
            optionId,
            answer,
          ),
        };
      }
    });
  }

  function parentSurveyReducer(state, action) {
    switch (action.type) {
      case 'changeVote':
        // Toggle Vote Status depending on multipleAnswerStatus (Checkbox or Radio)
        return {
          ...state,
          questions: changeVoteWithSubQuestions(
            state.questions,
            action.questionId,
            action.optionId,
            action.multipleAnswerStatus,
          ),
        };
      case 'changeVoteTypeNumber':
        return {
          ...state,
          questions: changeVoteTypeNumberWithSubQuestions(
            state.questions,
            action.questionId,
            action.answer,
          ),
        };
      case 'changeVoteSecondaryAnswer':
        return {
          ...state,
          questions: changeVoteSecondaryAnswerWithSubQuestions(
            state.questions,
            action.questionId,
            action.answer,
          ),
        };
      case 'addSuggestion':
        // Adds a suggestion to a specific question
        return {
          ...state,
          questions: addSuggestionWithSubQuestions(
            state.questions,
            action.questionId,
            action.optionId,
            action.answer,
            action.createdBy,
          ),
        };

      case 'deleteSuggestion':
        return {
          ...state,
          questions: deleteSuggestionWithSubQuestions(
            state.questions,
            action.questionId,
            action.optionId,
          ),
        };

      case 'editSuggestion':
        return {
          ...state,
          questions: editSuggestionWithSubQuestions(
            state.questions,
            action.questionId,
            action.optionId,
            action.answer,
          ),
        };
      case 'applyFilter':
        return action.parentSurveyFiltered;

      case 'userHasVoted':
        return {
          ...state,
          userHasVoted: action.userHasVoted,
        };

      case 'updateParentSurvey':
        return action.state;

      default:
        return state;
    }
  }
}

function countVotesWithSubQuestions(questions) {
  return questions.map(q =>
    q
      ? {
          ...q,
          parentSurveyQuestionOptions: q.parentSurveyQuestionOptions.map(option =>
            option
              ? {
                  ...option,
                  counted_votes: option.parentSurveyQuestionOptionVote.length,
                }
              : option,
          ),
          subQuestions:
            q.subQuestions && q.subQuestions.length > 0
              ? countVotesWithSubQuestions(q.subQuestions)
              : [],
        }
      : q,
  );
}

function filterWithSubQuestions(questions, filterUserIds) {
  return questions.map(question =>
    question
      ? {
          ...question,
          parentSurveyQuestionOptions: question.parentSurveyQuestionOptions.map(option =>
            option
              ? {
                  ...option,
                  parentSurveyQuestionOptionVote: option.parentSurveyQuestionOptionVote.filter(
                    parentSurveyQuestionOptionVote =>
                      filterUserIds.includes(parentSurveyQuestionOptionVote.createdBy),
                  ),
                }
              : option,
          ),
          subQuestions: question.subQuestions
            ? filterWithSubQuestions(question.subQuestions, filterUserIds)
            : question.subQuestions,
        }
      : question,
  );
}

export function applyFilter(filter, parentSurvey) {
  // If no filter is selected just count votes
  let parentSurveyFiltered = Object.assign({}, parentSurvey);
  parentSurveyFiltered.filteredParticipantCount = null;
  let filteredParticipantCount = null;
  if (filter.questionId !== -1) {
    const filterQuestion = flattenQuestions(parentSurvey.questions).filter(
      question => question.id === filter.questionId,
    )[0];

    const filterOption = filterQuestion.parentSurveyQuestionOptions.filter(
      option => option.id === filter.optionId,
    )[0];

    const filterUserIds = filterOption.parentSurveyQuestionOptionVote.map(
      ({ createdBy }) => createdBy,
    );
    filteredParticipantCount = filterUserIds.length;

    parentSurveyFiltered = {
      ...parentSurveyFiltered,
      questions: filterWithSubQuestions(parentSurveyFiltered.questions, filterUserIds),
    };
  }
  // Count votes
  parentSurveyFiltered = {
    ...parentSurveyFiltered,
    questions: countVotesWithSubQuestions(parentSurveyFiltered.questions),
    filteredParticipantCount,
  };
  return parentSurveyFiltered;
}

export async function onSubmitHandler(
  parentSurvey,
  parentSurveyQuestionOptionIds,
  secondaryAnswers,
  setIsSubmiting,
  updateFeedById,
  parentSurveyDispatch,
  showBottomNotification,
  t,
  setVotesGiven,
  userId,
) {
  if (parentSurveyQuestionOptionIds.length <= 0) {
    return showBottomNotification(t('Surveys.At least one answer is required'), {
      isWarning: true,
    });
  }
  setIsSubmiting(true);

  const data = await submitVotes(parentSurvey.id, parentSurveyQuestionOptionIds, secondaryAnswers);
  setVotesGiven(getUserVotes({ questions: data.data.parentSurveyQuestions }, userId));
  parentSurveyDispatch({
    type: 'userHasVoted',
    userHasVoted: true,
  });
  showBottomNotification(t('ParentSurvey.Vote submitted'));
  // updateFeedById(parentSurvey.id, data, {withoutRequest: false });
  setIsSubmiting(false);
}
