import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getScimGlobalConfig, editScimlobalConfig, updateMsClientId } from 'library/api/kita';
import CheckBox from 'library/common/commonComponents/Checkbox';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { getToken } from 'library/utilities/token';
import store from 'main/store/configureStore';
import TagInput from 'library/common/commonComponents/Inputs/TagInput';

import styles from './scim-settings.module.scss';

function ScimSettings({ user }) {
  const { t } = useTranslation();

  const [scimEndpoint, setScimEndpoint] = useState(null);
  const [scimActive, setScimActive] = useState(false);
  const [isScimTokenVisible, setIsScimTokenVisible] = useState(false);
  const [clientId, setClientId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({ invite: '' });
  const [participantsObj, setParticipantsObj] = useState({
    users: [],
  });
  const token = getToken();

  const handleScimSetting = async () => {
    try {
      setIsLoading(true);
      await editScimlobalConfig({
        active: !scimActive,
      });
      setScimActive(prevState => !prevState);
      store.dispatch(showBottomNotification(t('BottomNotifications.Saved')));
    } catch (error) {
      store.dispatch(
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyScimToken = async () => {
    await navigator.clipboard.writeText(token.access_token);
    store.dispatch(
      showBottomNotification(t('BottomNotifications.Scim Token is copied to clipboard!')),
    );
  };

  const handleCopyScimEndpoint = async () => {
    await navigator.clipboard.writeText(scimEndpoint);
    store.dispatch(
      showBottomNotification(t('BottomNotifications.ScimEndpoint is copied to clipboard!')),
    );
  };

  const handleAddParentParticipant = data => {
    setParticipantsObj(prev => ({ ...prev, users: data.users }));
  };

  useEffect(() => {
    const fetchScimConfig = async () => {
      try {
        const res = (await getScimGlobalConfig()).data;
        if (res) {
          setScimActive(res.active);
          setClientId(res.clientId);
          setParticipantsObj({
            users: res.users || [],
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchScimConfig();

    const getCurrentDomain = () => {
      const { protocol, hostname, port } = window.location;
      setScimEndpoint(`${protocol}//${hostname}${port ? `:${port}` : ''}/usermgmt/api/scim/v2`);
    };

    getCurrentDomain();
  }, []);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const userIds = (participantsObj.users || []).map(x => x.id).join(',');
      await updateMsClientId(clientId, userIds);
      store.dispatch(showBottomNotification(t('BottomNotifications.Saved')));
    } catch (error) {
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.scimSettingsContainer}>
      <CheckBox
        className={styles.checkboxSpace}
        name={scimActive ? t('ScimSetting.Deactivate Module') : t('ScimSetting.Activate Module')}
        isChecked={scimActive}
        isLoading={isLoading}
        onChange={handleScimSetting}
      />
      {scimActive && (
        <>
          <div className={styles.scimSettingWrapper}>
            <div className={styles.scimLabel}>{t('ScimSetting.TenantUrl')}</div>
            <div className={styles.scimSettingInput}>
              <input value={scimEndpoint} readOnly />
            </div>
            <button
              className={styles.scimcopyButton}
              disabled={!scimEndpoint}
              onClick={handleCopyScimEndpoint}
            >
              <i className='fa fa-copy' />
            </button>
          </div>
          <div className={styles.scimSettingWrapper}>
            <div className={styles.scimLabel}>{t('ScimSetting.Token')}</div>
            <div className={styles.scimSettingInput}>
              <input
                placeholder={t('Login.token for MS provisioning')}
                value={token.access_token}
                type={isScimTokenVisible ? 'text' : 'password'}
                readOnly
              />
              <i
                className={`fa ${isScimTokenVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                onClick={() => setIsScimTokenVisible(!isScimTokenVisible)}
              />
            </div>
            <button
              className={styles.scimcopyButton}
              disabled={!token.access_token}
              onClick={handleCopyScimToken}
            >
              <i className='fa fa-copy' />
            </button>
          </div>
          <div className={styles.scimSettingWrapper}>
            <div className={styles.scimLabel}>{t('ScimSetting.ClientId')}</div>
            <div className={styles.scimSettingInput}>
              <input
                value={clientId}
                onChange={e => {
                  setClientId(e.target.value);
                }}
              />
            </div>
          </div>
          {user.superAdminStatus && (
            <div className={styles.scimSettingWrapper}>
              <div className={styles.scimLabel}>{t('ScimSetting.Users')}</div>
              <div className={styles.scimSettingUsers}>
                <TagInput
                  showLabel={false}
                  userId={user.id}
                  setErrors={setErrors}
                  errors={errors}
                  value={participantsObj}
                  onDataChange={handleAddParentParticipant}
                />
              </div>
            </div>
          )}

          <ButtonWithLoader
            type='primary'
            onClick={handleSubmit}
            isLoading={isSubmitting}
            className={styles.scimUpdateButton}
          >
            {t('Popup.Save')}
          </ButtonWithLoader>
        </>
      )}
    </div>
  );
}

export default ScimSettings;
