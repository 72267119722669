import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import Select from 'library/common/commonComponents/Inputs/Select';
import {
  getAllInstitutionsOfTenant,
  getAllTenants,
  startPostMigration,
} from 'library/api/institutMigration';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Loader from 'library/common/commonComponents/Loader';
import Popup from 'library/common/commonComponents/Popups/Popup';

export default function PostMigration() {
  const { t } = useTranslation();


  const [listOfInstitutionsOfTenant, setListOfInistitutionsOfTenant] = useState([]);
  const [listOfAllTenants, setListOfAllTenants] = useState([]);
  const [tenantToMigrateTo, setTenantToMigrateTo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);

  useEffect(() => {
    loadDataForSelectboxes();
  }, []);

  async function loadDataForSelectboxes() {
    const allInstitutions = await getAllInstitutionsOfTenant();

    const mappedInstitutions = allInstitutions.data.map(institution => {
      return { value: institution.id, label: `${institution.id} - ${institution.kitaName}`, checked: false };
    });
    const allTenants = await getAllTenants();
    const mappedTenants = allTenants.data.map(tenant => {
      return { value: tenant.tenant, label: `${tenant.tenant} - ${tenant.urlPrefix}` };
    });

    setListOfInistitutionsOfTenant(mappedInstitutions);
    setListOfAllTenants(mappedTenants);
    setIsLoading(false);
  }

  function updateTextboxState(id) {
    const updatedCheckboxList = listOfInstitutionsOfTenant.map(institution => {
      if (institution.value === id) {
        return { ...institution, checked: !institution.checked };
      }
      return institution;
    });

    setListOfInistitutionsOfTenant(updatedCheckboxList);
  }
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <b>{t('GobalTools.Migrate posts')}</b>
      <br />
      <br />
      {t('GobalTools.Select institution')}
      <br />
      {
        listOfInstitutionsOfTenant.map(institution => {
          return (<CheckBox
            name={institution.label}
            isChecked={institution.checked}
            onChange={() => { updateTextboxState(institution.value); }}
          />)
        })
      }
      <br />
      {t('GobalTools.Select target tenant')}
      <Select
        options={listOfAllTenants}
        onSelect={e => {
          setTenantToMigrateTo(e);
        }}
        selected={tenantToMigrateTo}
      />
      <br />
      <Button
        onClick={() => {
          setConfirmationPopupOpen(true);
        }}
      >
        {t('GobalTools.Start post migration')}
      </Button>
      {confirmationPopupOpen &&
        <Popup
          isOpened={confirmationPopupOpen}
          closePopup={() => {
            setConfirmationPopupOpen(false);
          }}
          header={t('GlobalTools.Confirm migration')}
          body={
            <div>
              {t('GlobalTools.Selected institutions')}<br /><br />
              {listOfInstitutionsOfTenant.filter(unfilteredInstitution => unfilteredInstitution.checked === true).map(institution => {
                return (<div>{institution?.label}</div>);
              })}<br /><br />
              {t('GlobalTools.Target tenant')}<br /><br />
              {tenantToMigrateTo?.label}
            </div>
          }
          footer={
            <div>
              <Button
                onClick={() => {
                  setConfirmationPopupOpen(false)
                }}
              >
                {t('Popup.Cancel')}
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  let listOfSelectedInstitutions = "";
                  listOfInstitutionsOfTenant.forEach(data => {
                    if (data.checked) {
                      if (listOfSelectedInstitutions === "") {
                        listOfSelectedInstitutions += data.value;
                      }
                      else {
                        listOfSelectedInstitutions += ',' + data.value;
                      }
                    }
                  })
                  if (listOfSelectedInstitutions !== "") {
                    startPostMigration(listOfSelectedInstitutions, tenantToMigrateTo.value);
                  }
                  setConfirmationPopupOpen(false);
                }

                }
                type='primary'
              >
                {t('GobalTools.Start post migration')}
              </Button>
            </div>
          }
        />
      }
    </>
  );
}
