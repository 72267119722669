import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'library/common/commonComponents/Card';
import Tabs from 'library/common/commonComponents/Tabs';
import ChildOverviewContainer from './ChildOverviewContainer';
import styles from './coronaTest.module.scss';


export default function CoronaTest() {
  const [currentTab, setCurrentTab] = useState(0);

  const { t } = useTranslation();

  const tabs = [
    {
      title: t('CoronaTest.Overview'),
      component: <ChildOverviewContainer />,
      path: 'children-history',
    },
  ];

  return (
    <Card className={styles.container}>
      <div className={styles.container}>
        <Tabs
          tabs={tabs}
          currentTab={tabs[currentTab].path}
          onChange={(_, index) => setCurrentTab(index)}
        />
      </div>
    </Card>
  );
}
