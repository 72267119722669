import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { changeKidCanGoHomeOnTheirOwnData, checkInOutChangePickUpPerson } from 'library/api/kids';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import Select from 'library/common/commonComponents/Inputs/Select';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { useTranslation } from 'react-i18next';
import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './childRow.module.scss';

const ChildRow = ({ childObj, handleUpdateKidStatus, sendUpdate, isHortType, notifyToParentsOnCheckInOutActive }) => {
  const { t } = useTranslation();
  const [pickUpPerson, setPickUpPerson] = useState(null);
  const [canGoHomeByOwn, setCanGoHomeByOwn] = useState(false);
  const [updateCount, setUpdateCount] = useState(1);
  function getAuthorizedToPickupChildOptions(childObj) {
    if (!childObj.authorizedToPickUpChild) {
      return null;
    }
    const authorizedPersons = childObj.authorizedToPickUpChild.map(person => {
      return { value: person, label: person };
    });

    const parent1 =
      childObj.parent1Name && childObj.parent1Name !== null && childObj.parent1Name !== ''
        ? { value: childObj.parent1Name, label: childObj.parent1Name }
        : null;
    parent1 !== null && authorizedPersons.push(parent1);
    const parent2 =
      childObj.parent2Name && childObj.parent2Name !== null && childObj.parent2Name !== ''
        ? { value: childObj.parent2Name, label: childObj.parent2Name }
        : null;
    parent2 !== null && authorizedPersons.push(parent2);
    authorizedPersons.unshift({ value: '', label: '' });
    return authorizedPersons;
  }

  function callApi() {
    if (!childObj.kidCanGoHomeOnTheirOwn) {
      changeKidCanGoHomeOnTheirOwnData(childObj.kidId, !canGoHomeByOwn);
    }
  }

  function scheduleApiCall() {
    const now = new Date();
    const targetTime = new Date(now);
    targetTime.setHours(0, 0, 0, 0); // Set the target time to 20:50:00

    const timeUntilApiCall = targetTime - now;

    if (timeUntilApiCall > 0) {
      setTimeout(() => {
        callApi(); // Call your API function
        scheduleApiCall(); // Schedule the next call for the next day
      }, timeUntilApiCall);
    } else {
      // If it's already past 20:50, schedule the call for the next day
      targetTime.setDate(targetTime.getDate() + 1);
      const timeUntilNextApiCall = targetTime - now;
      setTimeout(() => {
        callApi(); // Call your API function
        scheduleApiCall(); // Schedule the next call for the next day
      }, timeUntilNextApiCall);
    }
  }

  useEffect(() => {
    var check = false;
    if (childObj) {
      if (updateCount == 1) {
        check = childObj.kidCanGoHomeOnTheirOwn || childObj.kidCanGoHomeOnlyToday;
        setUpdateCount(updateCount + 1);
      } else {
        check = canGoHomeByOwn || childObj.kidCanGoHomeOnTheirOwn ? true : false;
      }
      setCanGoHomeByOwn(check);
    }
    if (childObj.kidCanGoHomeOnlyToday) {
      scheduleApiCall();
    }
  })

  const changeKidData = (canGoHomeByOwn) => {
    changeKidCanGoHomeOnTheirOwnData(childObj.kidId, !canGoHomeByOwn);
    setCanGoHomeByOwn(!canGoHomeByOwn);
  }

  const getStatus = () => {
    switch (childObj.checkInOutStatus) {
      case 'CHECKIN':
        return (
          <>
            <div
              className={styles.name}
              style={{ color: '#08803a', cursor: 'pointer' }}
              onClick={() => handleChangeKidStatus()}
              key={childObj.kidId}
            >
              {childObj.name}
              {childObj.parent1Name !== null && (
                <span className={styles.parent}>{childObj.parent1Name}</span>
              )}
              {childObj.parent2Name !== null && (
                <span className={styles.parent}>{childObj.parent2Name}</span>
              )}
            </div>
            {isHortType &&
              <div>
                <CheckBox
                  className={styles.checkbox}
                  isChecked={canGoHomeByOwn}
                  onChange={() => changeKidData(canGoHomeByOwn)}
                />
              </div>
            }
            <div>
              <Select
                options={getAuthorizedToPickupChildOptions(childObj)}
                selected={
                  pickUpPerson === null
                    ? childObj.pickUpPerson === null
                      ? null
                      : { value: childObj.pickUpPerson, label: childObj.pickUpPerson }
                    : pickUpPerson
                }
                onSelect={e => {
                  setPickUpPerson(e);
                  checkInOutChangePickUpPerson({
                    kidId: childObj.kidId,
                    personToPickUpChild: e.value,
                  }).then(data => {
                    store.dispatch(
                      showBottomNotification(t('Checkinout.Pick up person changed and saved'), {
                        isFail: false,
                      }),
                    );
                  });
                }}
              />
            </div>
          </>
        );
      case 'CHECKOUT':
        return (
          <>
            <div
              className={styles.name}
              style={{ color: '#ffaf00', cursor: 'pointer' }}
              onClick={() => handleChangeKidStatus()}
              key={childObj.kidId}
            >
              {childObj.name}
              {childObj.parent1Name !== null && (
                <span className={styles.parent}>{childObj.parent1Name}</span>
              )}
              {childObj.parent2Name !== null && (
                <span className={styles.parent}>{childObj.parent2Name}</span>
              )}
            </div>
            {isHortType &&
              <div>
                <CheckBox
                  className={styles.checkbox}
                  isChecked={canGoHomeByOwn}
                  onChange={() => changeKidData(canGoHomeByOwn)}
                />
              </div>
            }
            <div>
              <Select
                options={getAuthorizedToPickupChildOptions(childObj)}
                selected={pickUpPerson}
                onSelect={e => setPickUpPerson(e)}
              />
            </div>
          </>
        );
      default:
        return <span className={styles.statusAbsent}>Absent</span>;
    }
  };

  const getNewStatus = () => {
    return childObj.checkInOutStatus === 'CHECKIN' ? 'CHECKOUT' : 'CHECKIN';
  };

  const handleChangeKidStatus = async () => {
    const newStatus = getNewStatus();
    await handleUpdateKidStatus({
      checkInOutType: newStatus,
      kidId: childObj.kidId,
      personToPickUpChild:
        pickUpPerson && pickUpPerson !== null && pickUpPerson.value ? pickUpPerson.value : null,
      notifyToParents: notifyToParentsOnCheckInOutActive,
    });
  };

  if (sendUpdate) {
    handleChangeKidStatus();
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.photo}>
        <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
      </div>
      {getStatus()}
    </div>
  );
};

ChildRow.propTypes = {
  childObj: PT.shape({
    kidId: PT.number.isRequired,
    name: PT.string.isRequired,
    photo: PT.string,
    parent1Name: PT.string,
    parent2Name: PT.string,
    checkInOutStatus: PT.string.isRequired,
  }).isRequired,
  handleUpdateKidStatus: PT.func.isRequired,
};

export default ChildRow;
