import React from 'react';
import bgwLogo from 'resources/images/bgw/bgwLogo_png.png';
import klangmemory from 'resources/images/bgw/klangmemory.jpg';
import woticktdieuhr from 'resources/images/bgw/woticktdieuhr.jpg';
import dranchenzauber from 'resources/images/bgw/dranchenzauber.jpg';
import fluesterfruehstueck from 'resources/images/bgw/fluesterfruehstueck.jpg';
import wasdunichthoerst from 'resources/images/bgw/wasdunichthoerst.jpg';
import stummeglocke from 'resources/images/bgw/stummeglocke.jpg';
import vierschnellehelfer from 'resources/images/bgw/vierschnellehelfer.jpg';
import spiegelversuch from 'resources/images/bgw/spiegelversuch.jpg';
import lobkaertchen from 'resources/images/bgw/lobkaertchen.jpg';
import stimmeZaehlen from 'resources/images/bgw/stimme_zaehlen.jpg';
import stimmeToenendeAusatmung from 'resources/images/bgw/stimme_toenende_ausatmung.jpg';
import stimmeAtmung from 'resources/images/bgw/stimme_atmung.jpg';
import stimmeGaehnen from 'resources/images/bgw/stimme_gaehnen.jpg';
import stimmeKoerperhaltung from 'resources/images/bgw/stimme_koerperhaltung.jpg';
import stimmeSprechton from 'resources/images/bgw/stimme_sprechton.jpg';
import psycheWertschaetzung from 'resources/images/bgw/psyche_wertschaetzung.jpg';
import psycheEntschleunigungstag from 'resources/images/bgw/psyche_entschleunigungstag.jpg';
import psycheSprachlospause from 'resources/images/bgw/psyche_sprachlospause.jpg';
import gnugymnastik from 'resources/images/bgw/gnugymnastik.jpg';
import massagereigen from 'resources/images/bgw/massagereigen.jpg';
import Collapsible from 'library/common/commonComponents/Collapsible';
import ExerciseItem from './ExerciseItem/ExerciseItem';

import exercisesStyle from './exercises.module.scss';

export default function ExercisesContainer() {
  const exercisesData = [
    {
      title: 'Lärm:',
      modules: [
        {
          title: 'Klangmemory',
          duration: '20 min',
          goals: [
            'Sensibilisierung für unterschiedliche Klänge und Töne',
            'Positive Unterstützung der Gruppen-/Teambildung',
          ],
          materials: [
            `Döschen mit verschiedenen Inhalten, davon immer 2 Dosen mit dem jeweils gleichen Inhalt.`,
          ],
          text: `Sie spielen mit einer Gruppe von Kindern. Die Dosen werden aufgestellt und es wird nach den Regeln von Memory gespielt. Jeweils zwei Plastikdöschen sind mit denselben Materialien gefüllt (z.B. Erbsen, Sand, Reis). Nun wird durch Rütteln und Hören herausgefunden, welche Döschen zusammenpassen.`,
          image: klangmemory,
        },
        {
          title: 'Ich höre was, was du nicht hörst….',
          duration: '10 – 15 min.',
          goals: ['Stärkung der Konzentration', 'Sensibilisierung für Geräusche'],
          text: `Lässt sich gut draußen spielen, z. B. im Wald oder auf der Wiese. Die Kinder beschreiben alle Geräusche, die sie wahrnehmen, evtl. auch die Richtung, aus der sie kommen. 

          Wie viele Geräusche begleiten uns täglich, auf die wir gar nicht bewusst achten?`,
          image: wasdunichthoerst,
        },
        {
          title: 'Wo tickt die Uhr?',
          duration: '10 min',
          goals: [
            'Stärkung der Konzentration',
            'Sensibilisierung für Geräusche',
            'Förderung des Gruppengefühls',
          ],
          materials: [
            'Laut tickender Wecker oder Eieruhr/Küchenwecker',
          ],
          text: `Der Wecker wird im Gruppenraum versteckt. Wer findet ihn zuerst?`,
          image: woticktdieuhr,
        },
        {
          title: 'Stumme Glocke',
          duration: '10 min',
          goals: [
            'Stärkung der Konzentration',
            'Sensibilisierung für Geräusche',
            'Förderung des Gruppengefühls',
          ],
          materials: [
            'Eine Glocke',
          ],
          text: `Die Glocke wird vorsichtig im Kreis von Kind zu Kind weitergereicht, mit dem Ziel, nicht zu läuten.`,
          image: stummeglocke,
        },
        {
          title: 'Flüster-Frühstück',
          duration: 'Über die gesamte Frühstückszeit',
          goals: [
            'Förderung der Konzentration',
            'Reduktion von Lärm',
            'Auflockerung der Frühstückzeit',
            'Gemeinsam Spaß haben',
          ],
          text: `Erläutern Sie den Kindern mit leiser, flüsternder Stimme, dass an diesem Tag das „Flüsterfrühstück“ stattfindet. Alle dürfen sich nur flüsternd unterhalten.

          Sie können z. B. eine Geschichte um das Frühstück erzählen: 

          „Wir befinden uns in einem großen, hellhörigen Schloss, in dem ein Riese schläft. Er möchte auf keinen Fall durch Frühstückslärm geweckt werden, die anderen Geräusche aber gerne hören.“

          Nutzen Sie Hinweise auf die Umgebung, um zu unterstützen, wie leise alle flüstern müssen:

          Wir essen und flüstern so leise, dass wir die Vögel durch das Fenster singen hören. 

          Wie flüstern so leise, dass wir das Laub durch das Fenster rauschen hören.

          Lassen Sie Ihrer Phantasie freien Lauf.`,
          image: fluesterfruehstueck,
        },
        {
          title: 'Drachenzauber',
          duration: '5 Minuten',
          goals: [
            'Kinder üben, ihre Lautstärke besser einzuschätzen',
            'Sensibilisierung für Lärm',
            'Erleben kurzer Ruhephase im Alltag ',
            'Förderung des Gruppengefühls',
            'Gemeinsam Spaß haben',
          ],
          text: `Erzählen Sie den Kindern, eine Person (Erzieherin oder Kind) ist von einem Drachen (anderes Kind) verzaubert worden. Diese Person ist versteinert. Die Kinder können den Zauber nur brechen, wenn sie diese Person berühren. Dazu müssen sie ganz leise, auf Zehenspitzen und ganz langsam von allen Seiten auf die versteinerte Person zugehen. Dabei dürfen sie nichts sagen und schon gar keine lauten Geräusche machen. Sie müssen die versteinerte Person ganz sanft, zum Beispiel durch ganz leichtes Streicheln über den Rücken, wecken. Denn nur dann, wenn sie liebevoll geweckt wird, wird der Zauber gebrochen, ohne dass der Drache es merkt. Wenn der Drache merkt, dass die versteinerte Person entzaubert wird, wird er böse und versteinert alle.`,
          image: dranchenzauber,
        },
      ],
    },
    {
      title: 'Stimme:',
      modules: [
        {
          title: 'Zählen 1-15',
          duration: '5 min',
          goals: [
            'Verbesserung der Stimmqualität',
            'Aktivierung der gesamten Atemmuskulatur und aller Atemreserven',
          ],
          text: `Zählen Sie laut von 1 - 15 auf einem Atemzug soweit Sie kommen. Nehmen Sie dabei die Tonqualität wahr und beobachten Sie das Gefühl während des Zählens.\n
          Beim nächsten Durchgang tippen Sie nach jeder Zahl mit dem Fuß fest auf den Boden, als ob Sie Gas geben wollten, also: eins - tipp - zwei - tipp - drei - tipp usw.\n
          Vergleichen Sie diesen Durchgang mit dem ersten.\n
          Fällt Ihnen auf, dass Sie beim 2. Durchgang entspannter zählen konnten und die Tonqualität gleichgeblieben ist?\n
          Das liegt vor allem daran, dass Sie mit der Übung die natürlichen Atemreserven aktivieren.`,
          image: stimmeZaehlen,
        },
        {
          title: 'Tönende Ausatmung',
          duration: '5 min',
          goals: [
            'Vertiefung der Atmung in den gesamten Bauch- und Brustkorbbereich',
          ],
          text: `Stehen Sie hüftbreit.\n
          Schließen Sie die Augen.\n
          Atmen Sie durch die Nase ein.\n
          Atmen Sie auf "F" oder "SCH" wieder aus.\n
          Spüren Sie, wie sich nach der Ausatmung die Einatmung vertieft?`,
          image: stimmeToenendeAusatmung,
        },
        {
          title: 'Atmung',
          duration: '5 - 10 min',
          goals: [
            'Wahrnehmung der Atmung',
            'Beruhigung der Atemfrequenz',
          ],
          text: `Stehen Sie hüftbreit, setzen Sie sich bequem hin oder legen Sie sich auf den Rücken.\n
          Schließen Sie die Augen.\n
          Atmen Sie durch die Nase ein und denken sich: ich atme ein.\n
          Atmen Sie durch den leicht geöffneten Mund aus und denken sich: ich atme aus.\n
          Wiederholen Sie die Ein- und Ausatmung mit diesen Gedanken über mehrere Minuten.
          Beobachten Sie, wie sich mit der Einatmung Ihre Bauchdecke hebt und mit der Ausatmung wieder senkt. Spüren Sie, wie sich Ihre Atmenzüge beruhigen, langsamer werden und vertiefen.`,
          image: stimmeAtmung,
        },
        {
          title: 'Gähngymnastik',
          duration: '3 min',
          goals: [
            'Vertiefung der Atmung',
            'Belebung des Körpers',
          ],
          text: `Gähnen Sie hemmungslos mehrmals laut als wären Sie gerade aufgewacht. Lassen Sie sich von anderen "Gähnern" anstecken.\n
          Recken Sie sich wie eine Katze und genießen Sie die Entspannung.\n
          Strecken Sie ein paar Mal weit die Zunge heraus.`,
          image: stimmeGaehnen,
        },
        {
          title: 'Stimme und Körperhaltung',
          duration: '10 min',
          goals: [
            'Erspüren des Zusammenhangs von Stimme und Körperhaltung',
          ],
          materials: [
            'Kurzer Text, z.B. aus Zeitung oder Zeitschrift',
          ],
          text: `Nehmen Sie Ihren Text. Lesen Sie den Text in den folgenden drei Sitzpositionen laut vor:
          <ol>
            <li>Sitzend nach vorne gebeugt, Ellenbogen auf den Knien abstützen, Lektüre in beiden Händen halten</li>
            <li>In aufrechter Sitzhaltung wie z.B. die Nachrichtensprechenden</li>
            <li>In aufrechter Sitzhaltung mit stark angehobenen Schultern</li>
          </ol>
          Klingt Ihre Stimme am besten in einer aufrechten und lockeren Körperhaltung?`,
          image: stimmeKoerperhaltung,
        },
        {
          title: 'Sprechton finden',
          duration: '15 min',
          goals: [
            'Den persönlichen Sprechton finden',
          ],
          text: `Für den größtmöglichen Erfolg führen Sie die Übungen in der angegebenen Reihenfolge durch.\n
          Sprechen Sie vor und nach jeder Übung einen Testsatz, um Veränderungen stärker wahrzunehmen, z.B. "Herzlich Willkommen meine Damen und Herren".\n
          <h3>1. Weintrauben kauen</h3>
          Stellen Sie sich eine Handvoll leckerer, süßer Weintrauben vor. Nehmen Sie eine dieser köstlichen Trauben in den Mund. Kauen Sie diese kräftig mit ausladenden Kieferbewegungen durch, zur Hilfe sprechen Sie ein jom-jom-jom dazu. Versuchen Sie, den Mund beim "m" zu schließen. Nehmen Sie eine weitere Traube und wiederholen die Übung.\n
          Verändert sich der Klang Ihrer Stimme? Wird er voller und kräftiger?\n
          Beenden Sie die Übung, wenn Sie den Eindruck haben, dass sich Ihr Stimmklang nicht weiter verändert, sondern gleich bleibt.\n
          <h3>2. Die Kraftstimme</h3>
          Brummen Sie dreimal hintereinander ein "mmmhhh" vor sich hin, so als ob Sie etwas angenehmes riechen oder sich über ein gutes Essen freuen. Nun nicken Sie leicht bei jedem Brummton mit dem Kopf.\n
          Im nächsten Schritt bei jedem Brummton mit dem Kopf nicken und die Hand zu einer Faust ballen.\n
          Beenden Sie die Übung, sobald sich der Brummton in der gleichen Tonqualität eingependelt hat.\n
          <h3>3. Die Zauberformel "Hu-mu-nun-gnu"</h3>
          Gönnen Sie sich nun den Spaß, Ihrem durch die ersten beiden Übungen gefundenen individuellen Sprechton zu einem noch volleren Klang zu verhelfen.\n
          Sprechen Sie zuerst wieder Ihren Testsatz.\n
          Strecken Sie Ihren Arm mit einer beschwörenden Geste langsam nach vorn auf einen konkreten Gegenstand zeigend. Dazu sprechen Sie langsam und deutlich die Zauberformel: "Hu-mu-nun-gnu" dreimal hintereinander, jedes Mal mit einer Armbewegung.\n
          Wiederholen Sie Ihren Testsatz mit dieser Armbewegung und vergleichen Sie!`,
          image: stimmeSprechton,
        },
      ],
    },
    {
      title: 'Körperliche Aktivität:',
      modules: [
        {
          title: 'Vier „Schnelle Helfer“',
          duration: 'pro Übung 30 Sekunden bis 2 min',
          goals: [
            'unterstützt die Wahrnehmung der aktuellen Haltung',
            'lockert die Rumpfmuskulatur',
            'fördert die Regeneration der Bandscheiben',
          ],
          text: `<ul>
          <li>Alle Übungen können im Stehen oder Sitzen durchgeführt werden. Die sitzende Position eignet sich, wenn die Haltung am Arbeitsplatz überprüft oder verändert werden soll.</li>
          <li>Wiederholen Sie die Übungen beliebig oft, bis Sie eine Veränderung im Körper spüren.</li>
          <li>Atmen Sie während der Übungen gleichmäßig ein und aus.</li>
          </ul>
          <h3>König Neptun</h3>
          Stellen Sie sich vor, Sie tragen eine Krone auf dem Kopf.
          Richten Sie sich auf und balancieren Ihren Kopfschmuck Richtung Decke, so dass sich die Halswirbelsäule streckt.
          Schauen Sie würdig in die Runde und verweilen Sie in jeder Position ein paar Sekunden.\n
          <h3>Seegras</h3>
          Neigen Sie sich ganz langsam wie Seegras in seichtem Wasser. Beginnen Sie mit der rechten Seite. Ihre Hand bewegt sich an der "äußeren Hosennaht" Richtung Boden. Der Kopf folgt der Bewegung ohne Anstrengung, das Ohr legt sich langsam auf die Schulter. Lassen Sie sich nun ganz sanft von den Wellen nach links bewegen und wiederholen die Übung auf dieser Seite.\n
          <h3>Die Muschel</h3>
          Stellen Sie sich vor, Sie sind eine Muschel. In Ihrem Inneren - etwa auf Brustbeinhöhe - schlummert die schönste Perle aller Zeiten. Diese gilt es nach Bedarf zu schützen oder aber auch zu zeigen:
          <ul>
          <li>Zum Schützen schließt sich die Muschel. Dabei rollen Sie Ihren Oberkörper langsam nach unten. Machen Sie Ihren Rücken dabei rund bis die Nase Ihre Knie und die Fingerspitzen den Boden berühren. Der Kopf schwingt locker zwischen den Oberarmen.</li>
          <li>Zum Präsentieren der Perle öffnet sich die Muschel. Sie richten sich langsam Wirbel für Wirbel wieder auf, bis Sie ganz aufrecht sind. Die Bewegung kann weitergeführt werden, bis Kopf und Oberkörper nach hinten und die Arme zur Decke gestreckt sind.</li>
          </ul>
          <h3>Tanzender Wasserstrudel</h3>
          Verschränken Sie Ihre Arme, die linke Hand fasst and die rechte Schulter, die rechte Hand an die linke Schulter.\n
          Drehen Sie den Oberkörper ganz leicht und schnell nach rechts und links. Die Hüften bleiben dabei möglichst ruhig. Genießen Sie die Drehbewegung in der Brustwirbelsäule.`,
          image: vierschnellehelfer,
        },
        {
          title: 'Gnu-Gymnastik',
          duration: '15 Minuten',
          goals: [
            'Förderung von Bewegung',
            'Nutzung der Gymnastik für das eigene Bewegungspensum ',
            'Gemeinsam Spaß haben',
          ],
          text: `Bringen Sie gleich am frühen Morgen mit einer kleinen Sporteinheit Schwung in den Tag. Erzählen Sie z. B. folgende Geschichte:

          „Die Tiere in der Savanne lieben Bewegung. Vor allem das Gnu kann ein bisschen Bewegung am Morgen gebrauchen und ist hoch motiviert, die anderen Tiere zum Mitmachen zu bewegen.“

          Ahmen Sie nun ein Tier nach dem anderen nach und probieren aus wie seine Morgengymnastik aussehen könnte. Mit passenden Tiergeräuschen können die Bewegungen noch mehr Spaß machen.

          Im Verlauf der Woche können sie die Herkunft der Tiere verändern. Z. B. können auch der Maulwurf oder das Seepferdchen Gymnastik machen.           
          `,
          image: gnugymnastik,
        },
        {
          title: 'Massage-Reigen',
          duration: '10 Minuten',
          goals: [
            'Muskelentspannung',
            'Sensibilisierung für Entspannung und Ruhe',
            'Stärkung des Gruppengefühls',
          ],
          materials: [
            'Pro Person ein Massageroller, Tennis- oder Igelball',
          ],
          text: `Diese Übung kann nach einem anstrengenden Tag durchgeführt werden. Zum Abschluss können sich alle nochmal etwas Gutes tun. 

          Zeigen Sie den Kindern, wie man die Schultern mit Hilfe von Massageroller oder Ball massiert. 

          Anschließend setzen sich alle in einen Kreis auf den Boden, am besten auf einen Teppich. Der Hintere massiert dann immer die Schultern des Vorderen, so dass alle gleichzeitig massiert werden.          
          `,
          image: massagereigen,
        },
        {
          title: 'Pizza backen',
          duration: '20 Minuten, 10 Minuten pro Person, dann wechseln',
          goals: [
            'Muskelentspannung',
            'Sensibilisierung für Entspannung und Ruhe',
            'Stärkung des Gruppengefühls',
            'Spaß haben',
          ],
          text: `Ein Kind legt sich mit dem Bauch auf den Boden, am besten auf einen Teppich. 

          Das andere Kind darf auf dem Rücken des liegenden Kindes eine Pizza backen. 

          Zuerst werden alle Zutaten auf dem Rücken bereitgelegt. Dazu sanft über den gesamten Rücken streichen. Benötigt wird Mehl, Hefe, lauwarme Milch, ein bisschen Öl, ….

          Danach werden alle Zutaten (auf dem Rücken) zu einem Teig geknetet. Erst alles vorsichtig mischen, dann kräftiger mit beiden Händen kneten.

          Jetzt wird der Teig auf dem Blech ausgerollt, mit den Händen auf dem Rücken rollen.

          Nun wird die Pizza belegt: zuerst gründlich die Soße verteilen, Salamischeiben auflegen, Paprika, … zum Schluss kommt noch der Käse obendrauf und ab in den Ofen!          
          `,
        },
      ],
    },
    {
      title: 'Infektionsschutz:',
      modules: [
        {
          title: 'Spiegelversuch zur Händereinigung',
          duration: ' 5 min',
          goals: ['Verstehen des Vorgangs der Entfettung der Haut durch Händewaschen'],
          materials: [
            '2 Spiegel (ca. DIN A 5 Größe)',
            'Waschlotion',
            'Handcreme',
            'Handdesinfektionsmittel',
          ],
          text: `Legen Sie eine Hand auf den Spiegel (notfalls auch Fensterscheibe) und hinterlassen ihren Handabdruck. Waschen Sie anschließend Ihre Hände. Führen Sie erneut einen Handabdruck auf dem zweiten Spiegel aus. 

          Beide Spiegel werden verglichen.

          Der Handabdruck ist nach dem Waschen deutlich fettärmer. 

          Wenn die Hände vorher durch Eincremen präpariert werden, wird das Ergebnis noch deutlicher.\n
          <h4>Variante</h4>
          Um die fettlösende Wirkung vom Händewaschen im Vergleich zur Händedesinfektion zu demonstrieren, kann auch Creme auf beide Spiegel aufgebracht werden. Der Spiegel wird mit einem Einmalhandtuch mit Wasser und Waschlotion abgerieben, der andere mit einem in Händedesinfektion getränktem Einmalhandtuch. 

          Die Waschlotion löst die Creme vom Spiegel ab, während das Desinfektionsmittel die Creme nur auf dem Spiegel verreibt.`,
          image: spiegelversuch,
        },
      ],
    },
    {
      title: 'Psychische Beanspruchung:',
      modules: [
        {
          title: 'Lobkärtchen',
          duration: '10 min',
          goals: [
            'Entwicklung eines guten Gefühls bei Lob',
            'Entwicklung/Verbesserung der Teamkultur',
            'Förderung einer positiven Kommunikation',
          ],
          materials: [
            'Bereiten Sie Zettel oder Karten mit Themen vor. Dies können z.B. sein: Freispiel, Vormittagsaktivität, Mittagessen, Frühstück, Umgang mit Kolleginnen/Kollegen, Elternabend etc.',
          ],
          text: `Erweitern Sie diese Themensammlung mit Ihren eigenen Ideen.

          Sinn der Übung ist es, eine Kollegin oder einen Kollegen so richtig ehrlich zu loben. Die Übung kann regelmäßig, z. B. in jeder Teamsitzung durchgeführt werden.

          Ziehen Sie aus dem Stapel eine Karte. Lassen Sie sich Zeit und überlegen in Ruhe ein richtiges Lob zu dem Thema auf der Karte. Z. B.: 

          An Deiner Vorbereitung des letzten Elternabends hat mir gefallen, dass Du….

          An Dir finde ich toll….

          Heute morgen beim Frühstück fand ich es richtig klasse, dass Du….

          Erfahrungsgemäß fällt es vielen Menschen schwer, andere zu loben. Negative Äußerungen scheinen leichter über die Lippen zu gehen. Daher kann diese Übung zunächst sehr schwer, unangenehm oder auch „peinlich“ sein. Haben Sie Geduld und üben in kleinen Schritten.`,
          image: lobkaertchen,
        },
        {
          title: 'Wertschätzung',
          duration: '5 min',
          goals: ['Entwicklung/Verbesserung der Teamkultur'],
          text: `Diese Übung kann regelmäßig, z. B. am Ende jeder Teambesprechung durchgeführt werden.

          Alle Beteiligten schreiben ihren Namen auf eine Karte. Alle Karten liegen in einem Korb. Daraus wird eine Karte gezogen. Diese Person erhält nun von allen Kolleginnen und Kollegen einige Minuten ausschließlich positive Rückmeldungen.          
          `,
          image: psycheWertschaetzung,
        },
        {
          title: 'Entschleunigungstag',
          duration: 'einen Tag',
          goals: [
            'Stressreduktion',
            'Lärmreduktion',
            'Bewusste Wahrnehmung des Kitaalltags',
            'Konzentrationsförderung',
          ],
          text: `Am Entschleunigungstag werden alle Tätigkeiten/Handlungen ganz, ganz langsam ausgeführt. Sie können den Kindern z.B. eine Geschichte erzählen:\n
          "Ein böser Wissenschaftler hat die Zeit verändert und nun laufen alle Uhren langsamer. Alle Menschen müssen alles ganz, ganz langsam machen. Wenn wir das einen Tag lang aushalten, macht er die Zeit wieder normal."\n
          Das bedeutet, dass Sie und die Kinder viel langsamer reden als normal. Sie essen langsamer, Sie spielen langsamer, Sie gehen langsamer, Sie singen langsamer, Sie waschen sich langsamer, ziehen sich langsamer an etc. Wenn es geht, machen Sie alles langsamer.\n
          Dabei ergeben sich manche lustige Situationen. Versuchen Sie einmal ein Lied auf der Gitarre ganz langsam zu spielen und die Kinder ganz langsam mitsingen zu lassen.\n
          <h4>Variante</h4>
          Falls Ihnen ein ganzer Tag zu lange erscheint, kann mit einer Entschleunigungsminute begonnen werden. Dazu beobachten Sie auf Ihrer Armbanduhr den Lauf des Sekundenzeigers über eine Minute. Atmen Sie dabei tief ein und aus und genießen die Zeit.`,
          image: psycheEntschleunigungstag,
        },
        {
          title: 'Sprachlos-Pause',
          duration: 'Pausenzeit',
          goals: [
            'Anspannungen lösen',
            'zur Ruhe kommen',
            'Pausen bewusst erleben und genießen'
          ],
          text: `Pausenzeiten sind zur Erholung gedacht und sollten bewusst erlebt werden. Vereinbaren Sie hin und wieder eine Pause, in der alle schweigen. Gehen Sie spazieren, verbringen Sie die Pause außerhalb der Kita, verzichten Sie auf Ihr Mobiltelefon. Es wird zunächst ungewohnt sein, während der Pause nicht mit den Kolleginnen und Kollegen zu sprechen.\n
          Vielleicht erleben Sie, wie entlastend es sein kann, sich für einen Moment bewusst aus dem Arbeitsalltag zurückzuziehen.
          `,
          image: psycheSprachlospause,
        },
      ],
    },
  ];

  const styles = {
    rowTitleColor: 'blue',
    width: '40%',
  };

  return (
    <div>
      <div className={exercisesStyle.title}>
        Übungen und Spiele für den Kita-Alltag
        <img
          src={bgwLogo}
          style={{ float: 'right', marginTop: '-4px', height: '50px' }}
          alt='Bgw'
        />
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'flex-start' }}>
        {exercisesData.map((exercise, index) => {
          return (
            <Collapsible
              title={exercise.title.substring(0, exercise.title.length - 1)}
              previewImage={exercise.modules[0].image}
              body={
                <ExerciseItem
                  key={exercise.title}
                  title={exercise.title}
                  modules={exercise.modules}
                />
              }
            />
          );
        })}
      </div>
      <div>
        Quelle: <a href='https://www.bgw-online.de/'>BGW</a>, Veranschaulichungsbilder: ©BGW/Michael Hüter
      </div>
    </div>
  );
}
