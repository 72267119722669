export function isActiveNavLink(path, _, location) {
  return location.pathname.indexOf(path) !== -1;
}

export function isAuthPage(pathname) {
  const authPagesUrls = [
    '/login',
    '/password-recovery',
    '/recovery-link-error',
    '/reset-password',
    '/registration',
    '/auth-privacy',
    '/auth-imprint',
  ];

  return (
    authPagesUrls.findIndex(pageUrl => {
      return pathname.indexOf(pageUrl) !== -1;
    }) !== -1
  );
}
