import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';
import PT from 'prop-types';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import Tooltip from 'library/common/commonComponents/Tooltip';
import CheckInOutTimePopup from '../../TimePopup/CheckInOutTimePopup';
import { createKidDevelopmentDiaryEntry } from 'library/api/kidDevelopmentDiary';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { formatDateObjectToTimeString } from '../../../../../../utilities/dateMoment';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import styles from './childRow.module.scss';
import { buyWrap, sendWrapMessage } from '../../../../../../api/kids';
import cn from 'classnames';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import diaperIcon from 'resources/images/checkinout/diaper.png';
import clothingIcon from 'resources/images/checkinout/clothing.png';
import napkinIcon from 'resources/images/checkinout/napkin.png';
import handkerchief from 'resources/images/checkinout/handkerchief.png';

const ChildRow = ({ childObj, prepareDelete, prepareEdit }) => {
  const { t } = useTranslation();
  const [wickelCounter, setWickelCounter] = useState(childObj.wrapTodayCount);
  const [timePopupOpened, setTimePopupOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);
  const [message, setMessage] = useState('');

  const [todayComments, setTodayComments] = useState(childObj.wrapTodayComments);
  const [time, setTime] = useState('');
  const [isValidTime, setIsValidTime] = useState(true);
  useEffect(() => {
    const date = new Date();
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const currentTime = `${hours}:${minutes}`;
    setTime(currentTime);
  }, []);
  const handleTimeChange = e => {
    const selectedTime = e.target.value;
    if (selectedTime < '06:00') {
      setIsValidTime(false);
      setTime('06:00');
    } else if (selectedTime > '18:00') {
      setIsValidTime(false);
      setTime('18:00');
    } else {
      setIsValidTime(true);
      setTime(selectedTime);
    }
  };

  const toUnixTimestamp = timeString => {
    const date = new Date();

    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');

    // Set the hours and minutes on the date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Return the UNIX timestamp (in seconds)
    return Math.floor(date.getTime());
  };
  const handleSubmitFromTimePopup = (time, text) => {
    setIsSaving(true);
    createKidDevelopmentDiaryEntry(childObj.kidId, 'wrap', text, time)
      .then(res => {
        const sortedTodayComments = [...todayComments, res.data];
        sortedTodayComments.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });

        setTodayComments(sortedTodayComments);
        store.dispatch(
          showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
            isFail: true,
          }),
        );
      })
      .finally(() => {
        setIsSaving(false);
        setTimePopupOpened(false);
      });
  };

  const buyWrapForKid = item => {
    buyWrap(item).then(res => {
      store.dispatch(
        showBottomNotification(t('Checkinout.BuyWrapMessageSent'), { isFail: false }),
      );
    });
  };

  const handleClothingIsOff = item => {
    sendWrapMessage(item, {
      subject: t('Checkinout.ClothingIsOffSubject'),
      message: t('Checkinout.ClothingIsOffMessage'),
    }).then(() => {
      store.dispatch(
        showBottomNotification(t('Checkinout.ClothingIsOffMessageSent'), { isFail: false }),
      );
    });
  };

  const handleWetWipes = item => {
    sendWrapMessage(item, {
      subject: t('Checkinout.WetWipesSubject'),
      message: t('Checkinout.WetWipesMessage'),
    }).then(() => {
      store.dispatch(
        showBottomNotification(t('Checkinout.WetWipesMessageSent'), { isFail: false }),
      );
    });
  };

  const handleHandkerchief = item => {
    sendWrapMessage(item, {
      subject: t('Checkinout.HandkerchiefSubject'),
      message: t('Checkinout.HandkerchiefMessage'),
    }).then(() => {
      store.dispatch(
        showBottomNotification(t('Checkinout.HandkerchiefMessageSent'), { isFail: false }),
      );
    });
  };

  const handleDeleteEntry = entry => {
    setWickelCounter(prevState => {
      return prevState - 1;
    });
    createKidDevelopmentDiaryEntry(childObj.kidId, 'wrap-undo', null, toUnixTimestamp(time));
  };

  const handleMessageChange = e => {
    setMessage(e.target.value);
  };

  const handleSendMessage = (item, type) => {
    const msgObj = {
      note: {
        subject: t('Checkinout.Note.Subject'),
        notification: t('Checkinout.NoteMessageSent'),
        message: message
      }
    }
    sendWrapMessage(item, {
      subject: msgObj[type].subject,
      message: msgObj[type].message,
    }).then(() => {
      if (type === 'note') {
        setMessage('');
      }
      store.dispatch(showBottomNotification(msgObj[type].notification, { isFail: false }));
    });
  };

  const wickeln = () => {
    return (
      <>
        <CheckInOutTimePopup
          submit={handleSubmitFromTimePopup}
          isSaving={isSaving}
          handleCloseTimePopup={() => setTimePopupOpened(false)}
          timePopupOpened={timePopupOpened}
        />
        <DeletePopup
          isOpened={entryToDelete !== null}
          closePopup={() => setEntryToDelete(null)}
          onDeleteBtnClick={() => handleDeleteEntry(entryToDelete)}
          headerText={t('KidDevelopmentDiary.ConfirmDeleteTitle')}
          bodyText={t('KidDevelopmentDiary.ConfirmDeleteText')}
        />
        <div
          className={cn(styles.changeStatusCheckOut, styles.changeStatus)}
          onClick={() => {
            if (wickelCounter >= 1) {
              setEntryToDelete(true);
            }
          }}
        >
          <i className='fa fa-minus' />
        </div>
        <div className={styles.wickelCounter}>{wickelCounter}</div>
        <div
          className={cn(styles.changeStatusCheckIn, styles.changeStatus)}
          onClick={() => {
            setWickelCounter(prevState => {
              return prevState + 1;
            });
            createKidDevelopmentDiaryEntry(childObj.kidId, 'wrap', null, toUnixTimestamp(time));
          }}
        >
          <i className='fa fa-plus' />
        </div>

        <Input type={'time'} value={time} onChange={e => handleTimeChange(e)} />

        <Button onClick={() => setTimePopupOpened(true)}>{t('Checkinout.AddNote')}</Button>
        <Tooltip text={t('Checkinout.BuyWrap')}>
          <div className={styles.buyButton} onClick={() => buyWrapForKid(childObj.kidId)}>
            <img src={diaperIcon} />
          </div>
        </Tooltip>
        <Tooltip text={t('Checkinout.ClothingIsOff')}>
          <div className={styles.buyButton} onClick={() => handleClothingIsOff(childObj.kidId)}>
            <img src={clothingIcon} />
          </div>
        </Tooltip>
        <Tooltip text={t('Checkinout.WetWipes')}>
          <div className={styles.buyButton} onClick={() => handleWetWipes(childObj.kidId)}>
            <img src={napkinIcon} />
          </div>
        </Tooltip>
        <Tooltip text={t('Checkinout.Handkerchief')}>
          <div className={styles.buyButton} onClick={() => handleHandkerchief(childObj.kidId)}>
            <img src={handkerchief} />
          </div>
        </Tooltip>
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.photo}>
        <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
      </div>
      <div className={styles.name}>
        {childObj.name}
        {childObj.parent1Name !== null && (
          <span className={styles.parent}>{childObj.parent1Name}</span>
        )}
        {childObj.parent2Name !== null && (
          <span className={styles.parent}>{childObj.parent2Name}</span>
        )}
      </div>
      <div className={styles.statusWrap}>
        <div className={styles.wickelnWrap}>{wickeln()}</div>
        <div className={styles.messageWrap}>
          <Input
            type={'text'}
            value={message}
            onChange={handleMessageChange}
            placeholder={t('Checkinout.Note')}
          />
          <Button className={styles.btnNote} onClick={() => handleSendMessage(childObj.kidId, 'note')}><i className='fa fa-send' /></Button>
          {todayComments && todayComments.length > 0 && (
            <div>
              <h5>{t('Checkinout.TodaysComments')}</h5>
              <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                {todayComments.map(c => {
                  return (
                    <li>
                      {formatDateObjectToTimeString(c.date)} "{c.message}"{' '}
                      <a
                        onClick={() =>
                          prepareDelete(c.id, () => {
                            setTodayComments(todayComments.filter(c2 => c2.id !== c.id));
                          })
                        }
                      >
                        <i className='fa fa-trash' />
                      </a>
                      <a
                        onClick={() =>
                          prepareEdit(c, (newText, newDate) => {
                            const sortedTodayComments = todayComments.map(c2 => {
                              if (c2.id === c.id) {
                                return {
                                  ...c2,
                                  message: newText,
                                  date: newDate,
                                };
                              } else return c2;
                            });
                            sortedTodayComments.sort(function (a, b) {
                              return new Date(a.date) - new Date(b.date);
                            });

                            setTodayComments(sortedTodayComments);
                          })
                        }
                        style={{ marginLeft: '5px' }}
                      >
                        <i className='fa fa-pencil' />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ChildRow.propTypes = {
  childObj: PT.shape({
    kidId: PT.number.isRequired,
    name: PT.string.isRequired,
    photo: PT.string,
    parent1Name: PT.string,
    parent2Name: PT.string,
    checkInOutStatus: PT.string.isRequired,
  }).isRequired,
};

export default ChildRow;
