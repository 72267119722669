import { SET_ACTIVE_2FA, SET_FORCE_RUN_2FA } from '../commonConstants/auth2faConstants';

const initialState = {
  isActive: false,
  forceRun: false,
};
const auth2FaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_2FA:
      return {
        ...state,
        isActive: action.payload,
      };
    case SET_FORCE_RUN_2FA:
      return {
        ...state,
        forceRun: action.payload,
      };
    default:
      return state;
  }
};
export default auth2FaReducer;
