export function getGalleryFiles(gallery) {
  return gallery.galleryPosts
    .map(({ id, files }) => files.flat().map(file => ({ ...file, postId: id })))
    .flat();
}

export function filterDependingOnBazaar(isBazaarTab, galleries) {
  const filteredGalleries = [];
  for (let i = 0; i < galleries.length; i++) {
    if (galleries[i].isBazaar === null) {
      galleries[i].isBazaar = false;
    }
    if (galleries[i].isBazaar === isBazaarTab) {
      filteredGalleries.push(galleries[i]);
    }
  }
  return filteredGalleries;
}
