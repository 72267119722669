import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from '../../members.module.scss';

const longGroupPanelKey =
  'GroupPanel.Are you sure? All Members of this group will be deleted along with all their posts, like and comments';

const RemoveMembersPopup = ({
  isOpenedRemove,
  closeRemovePopup,
  multipleRemove,
  isRemoveLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Popup
      isOpened={isOpenedRemove}
      showHeader={false}
      size='small'
      footer={
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonCancel}>
            <Button onClick={closeRemovePopup}>{t('Confirm.Cancel')}</Button>
          </div>
          <div>
            <ButtonWithLoader onClick={multipleRemove} type='primary' isLoading={isRemoveLoading}>
              {t('GroupPanel.Remove')}
            </ButtonWithLoader>
          </div>
        </div>
      }
    >
      <div className={styles.headerPopup}>
        <Trans i18nKey='Confirm.Confirm Action' />
      </div>
      <div className={styles.subheaderPopup}>{t(longGroupPanelKey)}</div>
    </Popup>
  );
};

export default RemoveMembersPopup;
