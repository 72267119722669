import React, { useEffect, useState, useRef } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { getWhiteboard, deleteWhiteboard } from 'library/api/whiteboard';
import RadioButton from 'library/common/commonComponents/RadioButton';
import Loader from 'library/common/commonComponents/Loader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from 'library/common/commonComponents/Popups/Popup';
import { formatDateNoTime } from 'library/utilities/dateMoment';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { useTranslation } from 'react-i18next';
import styles from './whiteboardoverview.module.scss';

export default function WhiteboardOverview({ groupId, isNotInKidsMode, setActiveTab, setEditingData }) {
  const [whiteboards, setWhiteboards] = useState([]);
  const [kidsModeRadio, setKidsModeRadio] = useState(!isNotInKidsMode);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDeleting, setConfirmDeleting] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const book = useRef();
  const container = useRef();
  const { t } = useTranslation();
  useEffect(() => {
    setIsLoading(true);
    getWhiteboard(groupId, kidsModeRadio).then(data => {
      setWhiteboards(data.data);
      setIsLoading(false);
    });
  }, [kidsModeRadio]);

  function createBlobUrlFromBase64Data(dataURI) {
    const BASE64_MARKER = ';base64,';
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    const blob = new Blob([array], { type: 'audio/mpeg' });
    return URL.createObjectURL(blob);
  }

  return (
    <>
      {!isLoading ? (
        whiteboards.length > 0 ? (
          <>
            <div style={{ display: 'flex', gap: '15px', paddingBottom: '10px' }}>
              {isNotInKidsMode && (
                <>
                  <RadioButton
                    checked={kidsModeRadio}
                    label={t('Whiteboard.Kid')}
                    onClick={() => {
                      setKidsModeRadio(true);
                    }}
                  />
                  <RadioButton
                    checked={!kidsModeRadio}
                    label={t('Whiteboard.Educator')}
                    onClick={() => {
                      setKidsModeRadio(false);
                    }}
                  />

                </>

              )}
              <div
                style={{ display: "flex", marginLeft: "auto", alignSelf: "center", gap: "10px", marginRight: kidsModeRadio ? "130px" : "20px" }}
                className={styles.editIcon}
              >
                {isNotInKidsMode && (
                  <>
                    {kidsModeRadio && (
                      confirmStatus === false ?
                        <div style={{ lineHeight: "20px" }}>
                          <Button type='primary' onClick={() => {
                            setConfirmStatus(true);
                            store.dispatch(
                              showBottomNotification(t('Freigegeben'), {
                                isFail: false,
                              }),
                            );
                          }}>Freigeben</Button>
                        </div> :
                        <div><i className='fa fa-check' /></div>
                    )}
                    {confirmDeleting === false ?
                      <div
                        onClick={() => {
                          setConfirmDeleting(true);
                        }}
                      >
                        <i className='fa fa-trash' />
                      </div>
                      : <div>
                        <Popup
                          isOpened={confirmDeleting}
                          closePopup={() => setConfirmDeleting(false)}
                          header={t('Whiteboard.Please confirm to delete the selected whiteboard')}
                          footer={
                            <div>
                              <div>
                                <Button type='primary' onClick={() => {
                                  setConfirmDeleting(false);
                                  const whiteboardId = whiteboards[book.current.pageFlip().getCurrentPageIndex()].id;
                                  deleteWhiteboard(whiteboardId).then(data => {
                                    const tempArray = whiteboards.filter(item => item.id !== whiteboardId);

                                    store.dispatch(
                                      showBottomNotification(t('Whiteboard.Whiteboard deleted'), {
                                        isFail: false,
                                      }),
                                    );
                                    window.location.reload();
                                  }

                                  )
                                }}>{t('Whiteboard.Ok')}</Button>&nbsp;&nbsp;
                                <Button onClick={() => setConfirmDeleting(false)}>{t('Whiteboard.Cancel')}</Button>
                              </div>
                            </div>
                          }
                        >
                        </Popup>
                      </div>}
                  </>)}
                <div
                  onClick={() => {
                    setEditingData({ whiteboardData: whiteboards[book.current.pageFlip().getCurrentPageIndex()].whiteboardData, audioData: whiteboards[book.current.pageFlip().getCurrentPageIndex()].audioData })
                    setActiveTab(0);

                  }}
                >
                  <i className='fa fa-pencil-square-o' />
                </div>
              </div>
            </div>
          </>
        ) : <div>{t('Whiteboard.No data available')}</div>
      ) : (
        <div>
          <Loader />
          <br />
        </div>
      )
      }
      <div
        className={styles.container}
        ref={container}
        style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
      >
        <HTMLFlipBook
          width={container.current ? container.current.offsetWidth : 1}
          height={550}
          autoSize
          ref={book}
          onInit={() => {
            book.current.pageFlip().turnToPage(whiteboards.length - 1);
          }}
          onFlip={() => {
            setConfirmStatus(false);
          }}
        >
          {whiteboards.map((whiteboard, index) => {
            return (
              <div key={whiteboard.key}>
                <div className={styles.pageHeader}>
                  {`${t('Whiteboard.Page')} ${index + 1} ${t('Whiteboard.Of')} ${whiteboards.length} - ${new Date(whiteboard.createdAt).toLocaleDateString('de-DE', { day: "2-digit", month: "2-digit", year: "numeric" })}`}
                </div>
                {whiteboard.audioData != null && (
                  <div className={styles.audio}><audio
                    src={
                      whiteboard.audioData != null &&
                      createBlobUrlFromBase64Data(whiteboard.audioData)
                    }
                    controls
                  /></div>
                )}
                <img
                  src={whiteboard.whiteboardData}
                  width={container.current ? container.current.offsetWidth : 1}
                />
              </div>
            );
          })}
        </HTMLFlipBook>
      </div>
    </>
  );
}
