import {
  SET_LANGUAGE,
  SET_GOOGLE_TRANSLATE_AGREE,
  SET_GOOGLE_TRANSLATE_LANG,
} from 'library/common/commonConstants/commonConstants';

const initialState = {
  language: 'de',
  locale: 'de-DE',
  isGoogleTranslateAgree: false,
  googleTranslateLang: null,
};

const LanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
        locale: action.payload.locale,
      };
    case SET_GOOGLE_TRANSLATE_AGREE:
      return {
        ...state,
        isGoogleTranslateAgree: action.payload,
      };
    case SET_GOOGLE_TRANSLATE_LANG:
      return {
        ...state,
        googleTranslateLang: action.payload,
      };
    default:
      return state;
  }
};

export default LanguageReducer;
