import React from 'react';
import cn from 'classnames';

import styles from './deleteButton.module.scss';

export default function DeleteButton({ onClick, className }) {
  return (
    <div className={cn(styles.delete, className)} onClick={onClick}>
      <i className='fa fa-times' />
    </div>
  );
}
