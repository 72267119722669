import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserKidsHistory } from 'library/api/kids';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import { checkPortalScrollLoading, useLoadingOnPortalScroll } from 'library/common/commonHooks/useScrollLoading';

import ExportRow from './HistoryRow';
import styles from './historyContainer.module.scss';

const HistoryContainer = () => {
  const [searchValue, setSearchValue] = useState('');
  const [kidsHistory, setKidsHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentPaginationPage = useRef(1);
  const isScrollLoading = useRef(false);
  const isScrollEnded = useRef(false);

  const { t } = useTranslation();

  const loadUserKidsHistory = () => {
    if (isScrollLoading.current || isScrollEnded.current) {
      return;
    }

    isScrollLoading.current = true;
    setIsLoading(true);
    const params = {
      page: currentPaginationPage.current
    };

    getUserKidsHistory(params)
      .then(res => {
        if (res.status === 200) {
          currentPaginationPage.current += 1;
          isScrollLoading.current = false;

          if (res.data.length < 20) {
            isScrollEnded.current = true;
          }

          setKidsHistory(prevState => [...prevState, ...res.data]);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useLoadingOnPortalScroll(() => checkPortalScrollLoading(loadUserKidsHistory, 1.2));

  useEffect(() => {
    loadUserKidsHistory();
    // eslint-disable-next-line
  }, []);

  const filteredData =
    searchValue.length > 0
      ? kidsHistory.filter(
        item =>
          item.name.toLowerCase().includes(searchValue) ||
          item.initiator.toLowerCase().includes(searchValue),
      )
      : kidsHistory;

  return (
    <div>
      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>
      <div className={styles.header}>
        <div className={styles.avatar} />
        <div className={styles.name}>{t('Checkinout.Name')}</div>
        <div className={styles.executivePersonTitle}>{t('Checkinout.Executive person')}</div>
        <div className={styles.actionTitle}>{t('Checkinout.Action')}</div>
        <div className={styles.date}>{t('Checkinout.Date')}</div>
      </div>
      {filteredData.map(item => {
        return <ExportRow childObj={item} key={item.id} />;
      })}
      {isLoading && (<Loader />)}
    </div>
  );
};

export default HistoryContainer;
