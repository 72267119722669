import Input from 'library/common/commonComponents/Inputs/Input';
import Label from 'library/common/commonComponents/Label';
import RadioButton from 'library/common/commonComponents/RadioButton';
import { createNewClientUser, loadClientUser } from 'library/api/finAPI';
import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import React, { useState, useEffect } from 'react';

export default function PaymentFinApi() {

  const [settingsObject, setSettingsObject] = useState({
    clientId: '',
    clientSecret: '',
    isProductionUser: false,
  });

  const [clientUsers, setClientUsers] = useState([]);

  useEffect(() => {
    loadClientUser().then(data => {
      setClientUsers(data.data);
    });
  }, []);

  function handleTextStateChange(newValue, property) {
    setSettingsObject(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  const { t } = useTranslation();

  return (
    <>
      <h2>{t('FoodOrder.FinAPI user')}</h2>
      <br />
      <Label type='input'>{t('FoodOrder.ClientId')}</Label>
      <Input
        type='text'
        value={settingsObject.clientId}
        onChange={e => handleTextStateChange(e.target.value, 'clientId')}
      />
      <br />
      <Label type='input'>{t('FoodOrder.ClientSecret')}</Label>
      <Input
        type='text'
        value={settingsObject.clientSecret}
        onChange={e => handleTextStateChange(e.target.value, 'clientSecret')}
      />
      <br />
      <Label type='input'>{t('FoodOrder.User environment')}</Label>
      <RadioButton
        checked={!settingsObject.isProductionUser}
        label={t('FoodOrder.Sandbox')}
        onClick={() => setSettingsObject(prev => {
          return { ...prev, isProductionUser: !prev.isProductionUser };
        })}
      />
      <RadioButton
        checked={settingsObject.isProductionUser}
        label={t('FoodOrder.Production')}
        onClick={() => setSettingsObject(prev => {
          return { ...prev, isProductionUser: !prev.isProductionUser };
        })}
      />
      <br />
      <Button onClick={() => {
        createNewClientUser(settingsObject).then(() => {

        });
      }}>{t('FoodOrder.Create finApi user')}</Button>
      <br />
      <br />
      {clientUsers.map(clientUser => {
        return <table>
          <tr><td>clientId</td><td>{clientUser.clientId}</td></tr>
          <tr><td>isProductionUser</td><td>{clientUser.isProductionUser === true ? "true" : "false"}</td></tr>
        </table>
      })}
    </>

  );
}
