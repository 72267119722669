import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import {Link} from "react-router-dom";
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ButtonWithLoader from "../../../../library/common/commonComponents/Buttons/ButtonWithLoader";
import Popup from "../../../../library/common/commonComponents/Popups/Popup";
import Input from "../../../../library/common/commonComponents/Inputs/Input";
import { postGfzNannyChanges } from "../../../../library/api/observationSheet";
import ConfirmPopup from "../../../../library/common/commonComponents/Popups/ConfirmPopup";

export default function NannyEditPopup({ isOpen, setOpen, nannyList, setNannyList, selectedNanny, setSelectedNanny, resetForm }) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deletedNannies, setDeletedNannies] = useState([]);
  const [newNannies, setNewNannies] = useState([]);
  const [newNannyIdCounter, setNewNannyIdCounter] = useState(1);
  const [newNannyName, setNewNannyName] = useState('');
  const [tmpNannyList, setTmpNannyList] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  useEffect(() => {
    setTmpNannyList(nannyList);
  }, [nannyList]);

  const prepareSave = () => {
    if (deletedNannies.length > 0)
    {
      setDeleteConfirmOpen(true)
    }
    else
    {
      handleSave();
    }
  };

  const handleSave = () => {
    setDeleteConfirmOpen(false);
    setIsSubmitting(true);

    postGfzNannyChanges(deletedNannies, newNannies).then((res) => {
      const newNannyList = res.data.map(n => {
        return { label: n.name, value: n.id };
      });

      if (selectedNanny && deletedNannies.includes(selectedNanny.value))
      {
        setSelectedNanny(null);
        resetForm();
      }

      setNannyList(newNannyList);
      setDeletedNannies([]);
      setNewNannies([]);
      setNewNannyIdCounter(1);
      setNewNannyName('');
      setTmpNannyList(newNannyList);

      setIsSubmitting(false);
      setOpen(false);
    }).catch(() => {
      store.dispatch(
        showBottomNotification(
          t('GfzForms.NannyProtocol_NannyEditPopupTryAgain'),
          { isFail: true },
        ),
      );
      setIsSubmitting(false);
    })
  };

  const handleDelete = (id) => {
    setDeletedNannies([...deletedNannies, id]);
    setTmpNannyList(tmpNannyList.filter(tn => {
      return tn.value !== id;
    }));
  };

  const handleAddNewNanny = () => {
    if (newNannyName === '')
    {
      return;
    }

    setNewNannies([...newNannies, newNannyName]);
    setTmpNannyList([...tmpNannyList, { label: newNannyName, value: -newNannyIdCounter }]);
    setNewNannyName('');
    setNewNannyIdCounter(newNannyIdCounter + 1);
  };

  const handleCancel = () => {
    setTmpNannyList(nannyList);
    setDeletedNannies([]);
    setNewNannies([]);
    setNewNannyName('');
    setNewNannyIdCounter(1);
    setOpen(false);
  };
  const confirmPopupText = (deletedNannies.length === 1)?`${t('GfzForms.NannyProtocol_NannyEditPopupYouAreIn')} ${t('GfzForms.NannyProtocol_NannyEditPopupOneNanny')} ${t('GfzForms.NannyProtocol_NannyEditPopupToDelete')}. ${t('GfzForms.NannyProtocol_NannyEditPopupAssociatedLog')} ${t('GfzForms.NannyProtocol_NannyEditPopupAreYouSure')} ${t('GfzForms.NannyProtocol_NannyEditPopupNanny')} ${t('GfzForms.NannyProtocol_NannyEditPopupWantToDelete')}?`
  :`${t('GfzForms.NannyProtocol_NannyEditPopupYouAreIn')} ${deletedNannies.length} ${t('GfzForms.NannyProtocol_NannyEditPopupFamiliyDays')} ${t('GfzForms.NannyProtocol_NannyEditPopupToDelete')}. ${t('GfzForms.NannyProtocol_NannyEditPopupAssociatedLogs')} ${t('GfzForms.NannyProtocol_NannyEditPopupAreYouSure')} ${t('GfzForms.NannyProtocol_NannyEditPopupNannies')} ${t('GfzForms.NannyProtocol_NannyEditPopupWantToDelete')}?`;
  return (
    <Popup
      size='small'
      isOpened={isOpen}
      closePopup={handleCancel}
      header={<strong>Tagesfamilien verwalten</strong>}
      footer={
        <div>
          <Button style={{marginRight: '10px'}} onClick={handleCancel}>{t('Popup.Cancel')}</Button>
          <ButtonWithLoader onClick={prepareSave} type='primary' isLoading={isSubmitting}>
            {t('Popup.Save')}
          </ButtonWithLoader>
        </div>
        }
      >
      <ConfirmPopup
        isOpened={deleteConfirmOpen}
        closePopup={() => setDeleteConfirmOpen(false)}
        onConfirm={handleSave}
        text={confirmPopupText}
      />
      <div>
        <br />
        <div>
          <table>
            <td>
              <Input
                value={newNannyName}
                placeholder={t('GfzForms.NannyProtocol_NannyEditPopupNewNanny')}
                onChange={e => setNewNannyName(e.target.value)}
              />
            </td>
            <td>
              <Button onClick={handleAddNewNanny}>
                <i className="fa fa-plus" /> {t('GfzForms.NannyProtocol_NannyEditPopupAddNanny')}
              </Button>
            </td>
          </table>
        </div>
        <br />
        <ul style={{listStyleType: 'none'}}>
          {tmpNannyList.map(n => {
            return (
              <li>
                <div>
                  <Link
                    onClick={() => handleDelete(n.value)}
                    style={{marginRight: '10px'}}
                  >
                    <i className="fa fa-trash" />
                  </Link>
                  {n.label}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Popup>
  );
}
