import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../../../../../library/common/commonComponents/Popups/Popup';
import Button from '../../../../../../../../library/common/commonComponents/Buttons/Button';
import styles from './AddFormComponentPopup.module.scss';

export default function AddFormComponentPopup({
  isOpened,
  setIsOpened,
  instantiateComponent,
  templates,
}) {
  const { t } = useTranslation();

  const [components, setComponents] = useState([]);
  const [curTemplateComponent, setCurTemplateComponent] = useState(null);

  const closePopup = () => {
    setIsOpened(false);
  };

  const initialComponents = [
    {
      name: t('FormsEditor.ComponentNameHeader'),
      icon: 'fa-header',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'header',
        indent: 0,
        text: '',
        fontSize: 25,
        bold: true,
        italic: false,
        underlined: false,
        alignment: 'center',
        presetValueType: '',
      },
    },
    {
      name: t('FormsEditor.ComponentNameParagraph'),
      icon: 'fa-paragraph',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'paragraph',
        indent: 0,
        presetValueType: '',
        text: '',
        fontSize: 15,
        bold: false,
        italic: false,
        underlined: false,
        alignment: 'left',
      },
    },
    {
      name: t('FormsEditor.ComponentNameInput'),
      icon: 'fa-keyboard-o',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'input',
        indent: 0,
        presetValueType: '',
        labelLeft: '',
        labelRight: '',
        inputType: 'text',
        placeholder: '',
      },
    },
    {
      name: t('FormsEditor.ComponentNameDoubleInput'),
      icon: 'fa-keyboard-o',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'doubleInput',
        inputLeft: {
          id: 0,
          name: '',
          allowParentsFillOut: true,
          allowKitaFillOut: true,
          type: 'input',
          indent: 0,
          presetValueType: '',
          labelLeft: '',
          labelRight: '',
          inputType: 'text',
          placeholder: '',
        },
        inputRight: {
          id: 0,
          name: '',
          allowParentsFillOut: true,
          allowKitaFillOut: true,
          type: 'input',
          indent: 0,
          presetValueType: '',
          labelLeft: '',
          labelRight: '',
          inputType: 'text',
          placeholder: '',
        },
      },
    },
    {
      name: t('FormsEditor.ComponentNameCheckbox'),
      icon: 'fa-check-square',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'checkbox',
        indent: 0,
        presetValueType: '',
        labelLeft: '',
        labelRight: '',
        defaultChecked: false,
        conditionActions: [],
      },
    },
    {
      name: t('FormsEditor.ComponentNameDropdown'),
      icon: 'fa-caret-square-o-down',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'dropdown',
        inputType: 'dropdown',
        indent: 0,
        presetValueType: '',
        labelLeft: '',
        labelRight: '',
        options: [],
        defaultOption: 0,
      },
    },
    {
      name: t('FormsEditor.ComponentNameDoubleDropdown'),
      icon: 'fa-caret-square-o-down',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'doubleDropdown',
        dropdownLeft: {
          id: 0,
          name: '',
          allowParentsFillOut: true,
          allowKitaFillOut: true,
          type: 'dropdown',
          inputType: 'dropdown',
          indent: 0,
          presetValueType: '',
          labelLeft: '',
          labelRight: '',
          options: [],
          defaultOption: 0,
        },
        dropdownRight: {
          id: 0,
          name: '',
          allowParentsFillOut: true,
          allowKitaFillOut: true,
          type: 'dropdown',
          indent: 0,
          presetValueType: '',
          labelLeft: '',
          labelRight: '',
          options: [],
          defaultOption: 0,
        },
      },
    },
    {
      name: t('FormsEditor.ComponentNamePagebreak'),
      icon: 'fa-file',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'pagebreak',
        indent: 0,
      },
    },
    {
      name: t('FormsEditor.ComponentNameImage'),
      icon: 'fa-picture-o',
      component: {
        id: 0,
        name: '',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'image',
        indent: 0,
        data: '',
        scale: 100,
        alignment: 'left',
      },
    },
    {
      name: t('FormsEditor.ComponentNameTableBookingTimes'),
      icon: 'fa-clock-o',
      component: {
        id: 0,
        name: 'Buchungszeiten',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'tableBookingTimes',
        indent: 0,
        numberFromToRows: 1,
      },
    },
    {
      name: t('FormsEditor.ComponentNameTableBookingTimesTwoRows'),
      icon: 'fa-clock-o',
      component: {
        id: 0,
        name: 'BuchungszeitenZwei',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'tableBookingTimes',
        indent: 0,
        numberFromToRows: 2,
      },
    },
    {
      name: t('FormsEditor.ComponentNameTableParentalContributionCrib'),
      icon: 'fa-money',
      component: {
        id: 0,
        name: '',
        timeSource: 'Buchungszeiten',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'tableParentalContribution',
        indent: 0,
        facilityType: 'krippe',
      },
    },
    {
      name: t('FormsEditor.ComponentNameTableParentalContributionKindergarden'),
      icon: 'fa-money',
      component: {
        id: 0,
        name: '',
        timeSource: 'Buchungszeiten',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'tableParentalContribution',
        indent: 0,
        facilityType: 'kindergarten',
      },
    },
    {
      name: t('FormsEditor.ComponentNameTableParentalContributionDayCare'),
      icon: 'fa-money',
      component: {
        id: 0,
        name: '',
        timeSource: 'Buchungszeiten',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'tableParentalContribution',
        indent: 0,
        facilityType: 'hort',
      },
    },
    {
      name: t('FormsEditor.ComponentNameTableParentalContributionWithHolidaysDayCare'),
      icon: 'fa-money',
      component: {
        id: 0,
        name: '',
        timeSource: 'BuchungszeitenZwei',
        timeSourceHolidays: 'Buchungszeiten',
        allowParentsFillOut: true,
        allowKitaFillOut: true,
        type: 'tableParentalContributionWithHolidays',
        indent: 0,
        facilityType: 'hort',
      },
    },
  ];

  const setupComponents = () => {
    const categories = {};

    templates.forEach(template => {
      if (template.tags == null || template.tags.length === 0) {
        if ('' in categories) {
          categories[''] = [...categories[''], template];
        } else {
          categories[''] = [template];
        }
      } else {
        template.tags.forEach(tag => {
          if (tag in categories) {
            categories[tag] = [...categories[tag], template];
          } else {
            categories[tag] = [template];
          }
        });
      }
    });

    const templateComponents = [];

    for (const [category, catTemps] of Object.entries(categories)) {
      templateComponents.push({
        name:
          category === ''
            ? t('FormsEditor.UncategorizedTemplates')
            : `${t('FormsEditor.Templates')} '${category}'`,
        icon: 'fa-tags',
        isTemplateComponent: true,
        components: catTemps.map(template => {
          return {
            name: template.name,
            icon: 'fa-tag',
            component: {
              ...template.formField,
              template: template,
              id: 0,
            },
          };
        }),
      });
    }

    setComponents([...initialComponents, ...templateComponents]);
    setCurTemplateComponent(null);
  };

  useEffect(() => {
    setupComponents();
  }, [isOpened]);

  return (
    <Popup
      size='normal'
      isOpened={isOpened}
      closePopup={closePopup}
      header={t('Administration.FormsEditorAddComponentPopupHeader')}
      footer={
        <div>
          <Button onClick={closePopup}>{t('Popup.Close')}</Button>
        </div>
      }
    >
      {curTemplateComponent && (
        <div>
          <h2>{curTemplateComponent.name}</h2>
          <Button onClick={setupComponents}>
            {t('Administration.FormsEditorAddComponentPopupTemplateComponentBackButtonLabel')}
          </Button>
        </div>
      )}
      <div className={styles.componentTilesContainer}>
        {components.map(c => {
          return c.isTemplateComponent ? (
            <div
              className={styles.componentTile}
              onClick={() => {
                setCurTemplateComponent(c);
                setComponents(c.components);
              }}
            >
              <h5 className={styles.componentHeader}>{c.name}</h5>
              <i className={`fa ${c.icon}`} />
            </div>
          ) : (
            <div
              className={styles.componentTile}
              onClick={() => {
                instantiateComponent(c.component);
                closePopup();
              }}
            >
              <h5 className={styles.componentHeader}>{c.name}</h5>
              <i className={`fa ${c.icon}`} />
            </div>
          );
        })}
      </div>
    </Popup>
  );
}
