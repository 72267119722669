import React from 'react';
import { useTranslation } from 'react-i18next';

import useEditing from 'library/common/commonHooks/useEditing';
import Button from 'library/common/commonComponents/Buttons/Button';
import DataProtectionFeedCommon from './DataProtectionFeedCommon';

export default function DataProtectionFeed({ text, id, setFunction, option, type }) {
  const { t } = useTranslation();

  const { isEditing, setIsEditing, ...editorProps } = useEditing({
    text,
    placeholder: t('Editor.Edit your post'),
  });

  return (
    <div>
      <DataProtectionFeedCommon
        isEditing={isEditing}
        editorProps={editorProps}
        setIsEditing={setIsEditing}
        text={text}
        id={id}
        setFunction={setFunction}
        option={option}
        type={type}
      />
      <br />
      {!isEditing ? (
        <Button onClick={setIsEditing}>
          <i className='fa fa-edit' style={{ marginRight: 5 }} />
        </Button>
      ) : null}
    </div>
  );
}
