import React, { useState } from 'react';
import { CirclePicker } from 'react-color';

export const ColorPickerButtonCircle = ({ onClick, color, onMouseUp, className }) => {
  const [currentColor, setCurrentColor] = useState(color);

  return (
    <div>
      <CirclePicker
        styles={{ zindex: 10000 }}
        color={currentColor}
        onChange={hex => setCurrentColor(hex)}
        onChangeComplete={onClick}
        onMouseUp={onMouseUp}
        disableAlpha={true}
        colors={["#f44336", "#e91e63", "#e100ff", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#00ffdd", "#009688", "#4caf50", "#8bc34a", "#11ff00", "#cddc39", "#f2ff00", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#ff0000", "#795548", "#607d8b", "#000001"]}
      />
    </div>
  );
};

export default ColorPickerButtonCircle;
