import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useEditor from 'library/common/commonHooks/useEditor';
import Select from 'library/common/commonComponents/Inputs/Select';
import Input from 'library/common/commonComponents/Inputs/Input';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Label from 'library/common/commonComponents/Label';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import { checkLink } from 'library/utilities/checkLink';

import styles from './createEditTaskBasic.module.scss';

export default function CreateEditTaskBasic({
  createEditTaskState,
  createEditTaskdispatch,
  taskLists,
  canBePublic,
}) {
  const { t } = useTranslation();

  const handleChange = value => {
    createEditTaskdispatch({
      type: 'setError',
      payload: { ...createEditTaskState.error, title: false },
    });
    createEditTaskdispatch({ type: 'setTitle', payload: value });
  };

  const handleChangeMaxAssignedUserCount = value => {
    createEditTaskdispatch({
      type: 'setError',
      payload: { ...createEditTaskState.error, maxAssignedUserCount: false },
    });
    createEditTaskdispatch({ type: 'setMaxAssignedUserCount', payload: value });
  };

  const taskListsOptions =
    taskLists && taskLists.length
      ? taskLists.map(item => {
        if (item.taskListTitle === 'Other Tasks') {
          item.taskListTitle = t('Tasks.Other Tasks');
        }
        return item.id === 1
          ? { value: `1`, label: t('Tasks.Other Tasks') }
          : { value: `${item.id}`, label: item.taskListTitle };
      })
      : [{ value: `1`, label: t('Tasks.Other Tasks') }];

  const taskListIndex = taskListsOptions.findIndex(
    item => item.value === `${createEditTaskState.taskListId}`,
  );
  const [taskListsOption, setTaskListsOption] = useState(
    taskListIndex !== -1 ? taskListsOptions[taskListIndex] : taskListsOptions[0],
  );
  const setTaskList = option => {
    createEditTaskdispatch({ type: 'setTaskList', payload: option });
    setTaskListsOption(option);
  };

  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = createEditTaskState.description;
  const { Editor, getEditorValue } = useEditor({
    message: wrappedMessage,
    onChange: () => {
      const value = getEditorValue();
      createEditTaskdispatch({ type: 'setDescription', payload: checkLink(value) });
    },
    className: styles.textareaContent,
  });

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Label type='input'>{t('Tasks.Title')}</Label>
        <Input
          error={createEditTaskState.error.title}
          value={createEditTaskState.title}
          onChange={e => handleChange(e.target.value)}
        />
        {createEditTaskState.error.title && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={t('Calendar.Title cannot be blank')} />
          </div>
        )}
      </div>
      <div className={styles.titleContainer}>
        <Label type='input'>{t('Tasks.MaxAssignedUserCount')}</Label>
        <Input
          type='text'
          error={createEditTaskState.error.maxAssignedUserCount}
          value={createEditTaskState.maxAssignedUserCount}
          onChange={e => handleChangeMaxAssignedUserCount(e.target.value.replace(/\D/g, ""))}
        />
        {createEditTaskState.error.maxAssignedUserCount && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={t('Tasks.MaxAssignedUserCountCannotBeNegative')} />
          </div>
        )}
      </div>
      <div className={styles.taskListsContainer}>
        <Label type='input'>{t('Tasks.List')}</Label>
        <Select options={taskListsOptions} onSelect={setTaskList} selected={taskListsOption} />
      </div>
      <div className={styles.descriptionContainer}>
        <Label type='input'>{t('Tasks.Description')}</Label>
        <Editor />
      </div>
      <div className={styles.checkboxContainer}>
        {canBePublic && (
          <CheckBox
            className={styles.checkbox}
            name={t('Tasks.Public')}
            isChecked={createEditTaskState.isPublic}
            onChange={() => createEditTaskdispatch({ type: 'setIsPublic' })}
          />
        )}
        <CheckBox
          className={styles.checkbox}
          name={t('Tasks.Scheduling')}
          isChecked={createEditTaskState.isScheduling}
          onChange={() => createEditTaskdispatch({ type: 'setIsScheduling' })}
        />
      </div>
    </div>
  );
}
