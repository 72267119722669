import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import EditImagePopup from 'library/common/commonComponents/Popups/EditImagePopup';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import DeleteButton from 'library/common/commonComponents/Buttons/DeleteButton';
import EditButton from 'library/common/commonComponents/Buttons/EditButton';
import useCachedImage from 'library/common/commonHooks/useCachedImage';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from './profileLogo.module.scss';

export default function ProfileLogo({
  profileStatus,
  avatar,
  avatarUploadProgress,
  onUploadProfileImg,
  onEditProfileImage,
  onDeleteProfileImg,
}) {
  const { t } = useTranslation();
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);

  const [isBounceAnimation, setIsBounceAnimation] = useState(false);
  useEffect(() => {
    setIsBounceAnimation(true);
    setTimeout(() => {
      setIsBounceAnimation(false);
    }, 400);
  }, [avatar.imageUrl]);

  const image = {
    unit: '%',
    width: avatar.imageWidth,
    height: avatar.imageWidth / avatar.imageAspect,
    x: avatar.xCoordinate,
    y: avatar.yCoordinate,
    aspect: avatar.imageAspect,
    url: avatar.imageUrl,
    rotation: avatar.rotation
  };

  const handleEditImage = img => {
    onEditProfileImage(img, 'avatar');
    setIsEditPopupOpened(false);
  };

  const handleDeleteImage = () => {
    onDeleteProfileImg('avatar');
    setIsDeletePopupOpened(false);
  };

  const { CachedImage } = useCachedImage({
    originalSrc: avatar.imageUrl,
    props: {
      alt: 'avatar',
      className: cn(styles.uploadAvatarImage),
    },
  });

  return (
    <div className={styles.container}>
      <DeletePopup
        isOpened={isDeletePopupOpened}
        closePopup={() => setIsDeletePopupOpened(false)}
        onDeleteBtnClick={handleDeleteImage}
        headerText={<Trans i18nKey='ProfilePanel.Confirm image deleting' />}
        bodyText={t('ProfilePanel.Do you really want to delete your profile image')}
      />
      <EditImagePopup
        isOpened={isEditPopupOpened}
        closePopup={() => setIsEditPopupOpened(false)}
        onEditBtnClick={handleEditImage}
        headerText={<Trans i18nKey='ProfilePanel.Modify your profile image' />}
        image={image}
        type='avatar'
        showRotation={true}
      />
      <div
        className={cn(styles.avatarContainer, 'animated', {
          bounceIn: isBounceAnimation,
        })}
      >
        {avatarUploadProgress ? (
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              <div className={styles.progressInfo} style={{ width: avatarUploadProgress + '%' }} />
            </div>
          </div>
        ) : (
          <div className={styles.uploadAvatarContainer}>
            {profileStatus === 'owner' && (
              <div className={styles.uploadAvatar}>
                <UploadButton
                  onChange={([img]) => {
                    onUploadProfileImg(img, 'avatar');
                  }}
                  allowedExtensions={['image/*']}
                />
                {avatar.imageUrl && (
                  <>
                    <EditButton
                      onClick={() => setIsEditPopupOpened(true)}
                      className={styles.uploadAvatarButton}
                    />
                    <DeleteButton
                      onClick={() => setIsDeletePopupOpened(true)}
                      className={styles.uploadAvatarButton}
                    />
                  </>
                )}
              </div>
            )}
            {avatar.imageUrl ? (
              <div className={styles.uploadAvatarImageContainer}>{CachedImage}</div>
            ) : (
              <img src={defaultAvatar} alt='avatar' />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
