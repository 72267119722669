import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getCalendarEvents,
  getCalendarSettings,
  deleteCalendarEvent,
  addCalendarEvent,
  updateCalendarEvent,
} from 'library/api/calendar';
import useCalendarEvents from 'library/common/commonHooks/useCalendarEvents';
import Calendar from 'library/common/commonComponents/Calendar';

export default function ProfileCalendar({ showBottomNotification, match, currentUser }) {
  const { t } = useTranslation();
  const [locationHost] = useState(window.location.hostname);

  const defaultFilters = [
    { id: 1, name: 'attending', checked: false },
    { id: 2, name: 'myEvents', checked: false },
    { id: 3, name: 'currentViewDate', value: new Date() },
    { id: 4, name: 'showAbsences', checked: locationHost.toLowerCase().includes('gfz-app.ch') },
    { id: 5, name: 'publicHolidays', checked: false },
  ];

  const getEditableValue = (ownerId, task, groupInfo) => {
    groupInfo = groupInfo || {
      editDeleteCalenderEntry: true,
      groupAdminStatus: false,
    };
    if (!task) {
      return (
        groupInfo.editDeleteCalenderEntry &&
        (groupInfo.groupAdminStatus || currentUser.id === ownerId)
      );
    }
  };
  const {
    events,
    calendarSettings,
    deleteEvent,
    addEvent,
    updateEvent,
    getEventInfo,
    filters,
    setFilters,
  } = useCalendarEvents(
    getCalendarEvents,
    getCalendarSettings,
    deleteCalendarEvent,
    addCalendarEvent,
    updateCalendarEvent,
    defaultFilters,
    currentUser.id,
    getEditableValue,
    'userProfileCalendar',
    false,
  );

  const filterList = [
    {
      title: t('Calendar.Filter events'),
      filters: [
        {
          id: 1,
          name: t("Calendar.I'm attending"),
          checked: filters.reduce((result, item) => {
            return (result = item.id === 1 ? item.checked : result);
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 1 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 2,
          name: t('Calendar.My events'),
          checked: filters.reduce((result, item) => {
            return (result = item.id === 2 ? item.checked : result);
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 2 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 3,
          name: t('Calendar.Show absences'),
          checked: filters.reduce((result, item) => {
            return (result = item.id === 4 ? item.checked : result);
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 4 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
        {
          id: 4,
          name: t('Calendar.Public Holidays'),
          checked: filters.reduce((result, item) => {
            return item.id === 5 ? item.checked : result;
          }, ''),
          onChange: () =>
            setFilters(
              filters.map(item => {
                return item.id === 5 ? { ...item, checked: !item.checked } : item;
              }),
            ),
        },
      ],
    },
  ];

  const onAddEvent = event => {
    return addEvent(
      {
        ...event,
        groupId: 0,
        calendarId: 0,
        userId: currentUser.id,
      },
      'userProfileCalendar',
    );
  };

  return (
    <Calendar
      showBottomNotification={showBottomNotification}
      events={events}
      calendarSettings={calendarSettings}
      canCreateEvent
      getEventInfo={getEventInfo}
      addEvent={onAddEvent}
      updateEvent={updateEvent}
      deleteEvent={deleteEvent}
      isAdmin
      match={match}
      filterList={filterList}
      user={currentUser}
      filters={filters}
      setFilters={setFilters}
      calendarModulePlace='userProfileCalendar'
      getEditableValue={getEditableValue}
    />
  );
}
