import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { updateFolders, updateBreadcrumbs } from 'library/common/commonActions/fileActions';

import FileManager from '.';

const mapStateToProps = state => ({
  user: state.userReducer,
  folders: state.fileReducer.folders,
  breadcrumbs: state.fileReducer.breadcrumbs,
});

export default connect(
  mapStateToProps,
  {
    showBottomNotification,
    updateFolders,
    updateBreadcrumbs,
  },
)(FileManager);
