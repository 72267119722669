import { connect } from 'react-redux';

import UploadButton from '.';

export default connect(state => ({
  userPermissions: state.userReducer.userPermissions,
  user: state.userReducer,
  group: state.groupReducer,
  activeKita: state.kitaReducer.activeKita,
}))(UploadButton);
