import React from 'react';
import { useTranslation } from 'react-i18next';

import ListRow from '../ListRow/ListRowContainer';

import styles from '../usersFrameChildren.module.scss';

export default function ListTable({ kids }) {
  const { t } = useTranslation();
  return (
    <div className={styles.tableOfChildren}>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.tableOfChildrenHeader}>
            <td className={styles.headerText}>
              <div className={styles.headerTextContainer}>
                {t('Administration.UserEditingChildren.Name')}
              </div>
            </td>
            <td className={styles.headerText}>
              <div className={styles.headerTextContainer}>
                {t('Administration.UserEditingChildren.Address')}
              </div>
            </td>
            <td className={styles.headerText}>
              <div className={styles.headerTextContainer}>
                {t('Administration.UserEditingChildren.Parent')}
              </div>
            </td>
            <td className={styles.headerText}>
              <div className={styles.headerTextContainer}>
                {t('Administration.UserEditingChildren.Actions')}
              </div>
            </td>
          </tr>

          {kids.map(kid => (
            <ListRow key={kid.id} kid={kid} parentId={kid.parentUserId} />
          ))}
        </tbody>
      </table>

      <div className={styles.altTable}>
        {kids.map(kid => (
          <ListRow key={kid.id} kid={kid} parentId={kid.parentUserId} />
        ))}
      </div>
    </div>
  );
}
