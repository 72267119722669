import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8095)}/api/suggestionbox`;
const zuulURL = `${config.API_BASE_URI}/zuul${getPort(8095)}/api/suggestionbox`;

export const sendNewSuggestionMessage = (newChatInfo, kitaId, files, setProgress) => {
  const payload = newChatInfo;

  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      let fileNumber = 1;
      payload[key].forEach(item => {
        formData.append(`file${fileNumber}`, item);
        fileNumber++;
      });
    } else {
      formData.append(key, payload[key]);
    }
  });

  return axios.post(zuulURL + "/send", formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
    },
  });
};
