import React from 'react';
import { Link } from 'react-router-dom';

import ListItemWithLogoElement from './listItemWithLogoFrames/ListItemWithLogoElement';

export default function ListItemWithLogo({ to, ...listItemWithLogoElementProps }) {
  return to ? (
    <Link to={to}>
      <ListItemWithLogoElement {...listItemWithLogoElementProps} />
    </Link>
  ) : (
    <ListItemWithLogoElement {...listItemWithLogoElementProps} />
  );
}
