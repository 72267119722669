import React, { useState } from 'react';
import Tooltip from 'library/common/commonComponents/Tooltip';
import { popupKids } from 'library/api/user';
import { useTranslation } from 'react-i18next';

import styles from './tooltipWithChildren.module.scss';

export default function(props) {
  const { t } = useTranslation();
  const [kids, setKids] = useState('');

  const getChildren = () => {
    popupKids(props.userId).then(res => {
      setKids(res.data);
    });
  };

  return (
    <div onMouseEnter={getChildren} className={styles.wrapper}>
      {
        <Tooltip isVisibleCondition={kids.length} text={t('Tooltip.Child') + ' ' + t(kids)}>
          {props.children}
        </Tooltip>
      }
    </div>
  );
}
