import React, { useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Label from 'library/common/commonComponents/Label';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import useBlockShowing from 'library/common/commonHooks/useBlockShowing';
import ParentSurveyBody from 'library/common/commonComponents/ParentSurveyBody';
import { createParentSurvey, updateParentSurvey } from 'library/api/parentSurveys';
import {
  validateParentSurvey,
  buildParentSurveyData,
  getQuestionCatalogue,
  getQuestionCatalogueEmployee,
  getQuestionCatalogueAwoOnline,
  stripHtml,
} from 'library/utilities/parentSurveys';

import styles from './parentSurveysCreateInput.module.scss';

export function answersReducer(state, action) {
  switch (action.type) {
    case 'addAnswer':
      return state.concat({ optionName: '', id: Math.random() });
    case 'updateAnswerById':
      return state.map(answer =>
        answer.id === action.id
          ? {
              ...answer,
              optionName: action.optionName,
            }
          : answer,
      );
    case 'resetAnswers':
      return [{ optionName: '', id: Math.random() }, { optionName: '', id: Math.random() }];
    case 'deleteAnswerById':
      return state.filter(answer => answer.id !== action.id);
    default:
      return state;
  }
}

const initialOption = {
  id: Math.random(),
  answer: '',
  orderPosition: 0,
};

export default function ParentSurveysCreateInput({
  groupInfo,
  onCreate,
  parentSurvey,
  newReusing,
  setNewReusing,
  setIsEditing,
}) {
  const { t } = useTranslation();

  function getStartTimeStatus() {
    if (parentSurvey?.startTimeStatus) {
      return parentSurvey.startTimeStatus;
    }
    return false;
  }

  function getStartTimeDate() {
    if (parentSurvey?.startTimeDate) {
      return parentSurvey.startTimeDate;
    }
    return Date.now();
  }

  const [parentSurveyName, setParentSurveyName] = useState(stripHtml(parentSurvey?.title ?? ''));
  const [startTimeStatus, setStartTimeStatus] = useState(false);
  const [startTimeDate, setStartTimeDate] = useState(Date.now());
  const [endTimeStatus, setEndTimeStatus] = useState(false);
  const [endTimeDate, setEndTimeDate] = useState(Date.now());
  const [reminders, setReminders] = useState([]);

  const { blockClassNames, showBlock, hideBlock } = useBlockShowing();
  const editExisting = !!parentSurvey && parentSurvey?.id;
  const isReusing = !!parentSurvey && !parentSurvey?.id;
  const hasContent = !!parentSurvey;

  useEffect(() => {
    if (editExisting) {
      showBlock();
    }
  }, []);

  // get all prepared questions available for parent surveys

  let availablePreparedQuestions = getQuestionCatalogue(t);
  if (
    window.location.hostname.toLowerCase().includes('staging1.safe2connect.org') ||
    window.location.hostname.toLowerCase().includes('awobamberg.safe2connect.org')
  ) {
    availablePreparedQuestions = getQuestionCatalogueAwoOnline(t);
  }
  if (
    groupInfo.groupId === 6 ||
    groupInfo.groupId === 188 ||
    groupInfo.groupId === 189 ||
    groupInfo.groupId === 190
  ) {
    availablePreparedQuestions = getQuestionCatalogueEmployee(t);
  }

  let parentSurveyQuestions = [];

  if (editExisting && parentSurvey?.questions) {
    const preparedQuestionIds = [];
    availablePreparedQuestions.forEach(catalogQuestion => {
      const questionExists = parentSurvey.questions.find(
        existingQuestion => stripHtml(existingQuestion.question) === catalogQuestion.question,
      );
      if (questionExists) {
        preparedQuestionIds.push(questionExists.id);
      }
    });

    parentSurvey.questions
      .sort((a, b) => a.orderPosition - b.orderPosition)
      .forEach(question => {
        parentSurveyQuestions.push({
          ...question,
          question: stripHtml(question.question),
          preChecked: true,
          isCustomQuestion: !preparedQuestionIds.includes(question.id),
          type: preparedQuestionIds.includes(question.id) ? 'prepared' : 'own_question',
          options: question.parentSurveyQuestionOptions,
        });
      });
  }

  const [questionInfos, dispatchQuestion] = useReducer(questionReducer, [...parentSurveyQuestions]);

  const canBePublic = false; // getCanCreatePublicPost(groupInfo);
  const [isPublic, setIsPublic] = useState(false);
  useEffect(() => {
    if (!canBePublic) {
      // setIsPublic(false);
    }
  }, [canBePublic]);

  const moreBtnDropdownOptions = getMoreBtnDropdownOptions(isPublic, setIsPublic, t);

  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState([]);

  const onSubmit = () => {
    const parentSurveyData = buildParentSurveyData(questionInfos, parentSurveyName);
    return onSubmitHandler({
      groupId: groupInfo.groupId,
      isPublic,
      setErrors,
      t,
      onCreate: data => {
        dispatchQuestion({ type: 'resetQuestion' });
        onCreate(data);
      },
      setBodyIsOpen,
      hideBlock,
      setIsSending,
      isSending,
      parentSurveyData,
      toggleBody,
      groupInfo,
      editExisting,
      parentSurveyId: parentSurvey?.id,
      setIsEditing,
      startTimeStatus,
      startTimeDate,
      endTimeStatus,
      endTimeDate,
      reminders,
    });
  };

  const [bodyIsOpen, setBodyIsOpen] = useState(editExisting);
  const toggleBody = () => {
    if (bodyIsOpen) {
      hideBlock();
    } else {
      showBlock();
    }
    setBodyIsOpen(!bodyIsOpen);
  };

  function questionReducer(state, action) {
    switch (action.type) {
      case 'setAll':
        return action.questions;
      case 'addQuestion':
        // Adds a new question
        return [...state, { ...action.question, id: Math.random() }];
      case 'deleteQuestion':
        // Delete the question
        const questionToDelete = state.find(questionInfo => questionInfo.id === action.questionId);
        return state
          .filter(questionInfo => questionInfo.id !== action.questionId)
          .map(item =>
            item.orderPosition > questionToDelete.orderPosition
              ? { ...item, orderPosition: item.orderPosition - 1 }
              : item,
          );
      case 'updateQuestion':
        // Updates the question text
        return state.map(questionInfo =>
          questionInfo.id === action.questionId
            ? { ...questionInfo, question: action.question }
            : questionInfo,
        );
      case 'moveQuestion':
        const newState = [...state];
        const sourceIndex = newState.findIndex(item => item.id === action.questionId);
        const targetIndex = action.direction === 'moveDown' ? sourceIndex + 1 : sourceIndex - 1;
        const tempQuestion = newState[sourceIndex];
        newState[sourceIndex] = {
          ...newState[targetIndex],
          orderPosition: tempQuestion.orderPosition,
        };
        newState[targetIndex] = {
          ...tempQuestion,
          orderPosition: newState[targetIndex].orderPosition,
        };
        return newState;
      case 'updateMultipleAnswerStatus':
        return state.map(questionInfo =>
          questionInfo.id === action.questionId
            ? { ...questionInfo, multipleAnswerStatus: action.value }
            : questionInfo,
        );

      case 'updateAllowUserAddSuggestionStatus':
        return state.map(questionInfo =>
          questionInfo.id === action.questionId
            ? { ...questionInfo, allowUserAddSuggestionStatus: action.value }
            : questionInfo,
        );

      case 'addOption':
        // Adds a new option to that specific question
        return state.map(questionInfo =>
          questionInfo.id === action.questionId
            ? {
                ...questionInfo,
                options: [
                  ...questionInfo.options,
                  {
                    ...initialOption,
                    orderPosition: questionInfo.options.length,
                    id: Math.random(),
                  },
                ],
              }
            : questionInfo,
        );
      case 'deleteOption':
        // Deletes that specific option
        return state.map(questionInfo =>
          questionInfo.id === action.questionId
            ? {
                ...questionInfo,
                options: questionInfo.options.filter(option => option.id !== action.optionId),
              }
            : questionInfo,
        );

      case 'updateOption':
        // Updates the option
        return state.map(questionInfo =>
          questionInfo.id === action.questionId
            ? {
                ...questionInfo,
                options: questionInfo.options.map(option =>
                  option.id === action.optionId
                    ? {
                        ...option,
                        answer: action.answer,
                      }
                    : option,
                ),
              }
            : questionInfo,
        );
      case 'togglePreparedQuestionChecked':
        if (action.value === false) {
          return state
            .filter(questionInfo => questionInfo.question !== action.question.question)
            .map(item =>
              item.orderPosition > action.question.orderPosition
                ? { ...item, orderPosition: item.orderPosition - 1 }
                : item,
            );
        } else {
          const tempState = [...state];
          tempState.push({
            ...action.question,
            checked: true,
          });
          return tempState;
        }
      case 'togglePreparedQuestionCatalogueAllowAddSuggestion':
        return state.map(questionInfo =>
          questionInfo.id === action.questionId
            ? {
                ...questionInfo,
                allowUserAddSuggestionStatus: !questionInfo.allowUserAddSuggestionStatus,
              }
            : questionInfo,
        );
      case 'resetQuestion':
        // Updates the option
        setParentSurveyName('');
        return [];
      default:
        return state;
    }
  }

  useEffect(() => {
    if (hasContent) {
      setParentSurveyName(stripHtml(parentSurvey.title ?? ''));
      setStartTimeStatus(parentSurvey.startTimeStatus);
      setStartTimeDate(parentSurvey.startTimeDate);
      setEndTimeStatus(parentSurvey.endTimeStatus);
      setEndTimeDate(parentSurvey.endTimeDate);
      setBodyIsOpen(hasContent);
      setReminders(parentSurvey.reminders);
      showBlock();
    }
  }, [
    hasContent,
    parentSurvey?.title,
    parentSurvey?.startTimeStatus,
    parentSurvey?.startTimeDate,
    parentSurvey?.endTimeStatus,
    parentSurvey?.endTimeDate,
    parentSurvey?.reminders
  ]);

  useEffect(() => {
    if (hasContent && parentSurvey?.questions.length > 0) {
      let parentSurveyQuestions = [];
      const preparedQuestionIds = [];
      availablePreparedQuestions.forEach(catalogQuestion => {
        const questionExists = parentSurvey.questions.find(
          existingQuestion => stripHtml(existingQuestion.question) === catalogQuestion.question,
        );
        if (questionExists) {
          preparedQuestionIds.push(questionExists.id);
        }
      });

      parentSurvey.questions
        .sort((a, b) => a.orderPosition - b.orderPosition)
        .forEach(question => {
          parentSurveyQuestions.push({
            ...question,
            question: stripHtml(question.question),
            preChecked: true,
            isCustomQuestion: !preparedQuestionIds.includes(question.id),
            type: preparedQuestionIds.includes(question.id) ? 'prepared' : 'own_question',
            options: question.parentSurveyQuestionOptions,
          });
        });
      dispatchQuestion({
        type: 'setAll',
        questions: parentSurveyQuestions,
      });
    }
  }, [hasContent, parentSurvey?.questions]);

  return (
    <div className={cn(styles.container, hasContent ? styles.insidePanel : '')}>
      {!editExisting && (
        <Button onClick={toggleBody} className={cn(styles.buttons, styles.createBtn)}>
          {!bodyIsOpen ? (
            <>
              {groupInfo.groupId === 6 ||
              groupInfo.groupId === 188 ||
              groupInfo.groupId === 189 ||
              groupInfo.groupId === 190 ? (
                <>
                  <span>{t('Erstellen Sie eine neue Mitarbeiterbefragung')} </span>{' '}
                  <i className='fa fa-arrow-down' />
                </>
              ) : (
                <>
                  <span>{t('ParentSurvey.Create')} </span> <i className='fa fa-arrow-down' />
                </>
              )}
            </>
          ) : (
            <>
              <span>{t('ParentSurvey.Close')} </span> <i className='fa fa-arrow-up' />
            </>
          )}
        </Button>
      )}

      <div className={cn(styles.body, ...blockClassNames)}>
        <ParentSurveyBody
          availablePreparedQuestions={availablePreparedQuestions}
          parentSurvey={parentSurvey}
          editExisting={editExisting}
          isReusing={isReusing}
          newReusing={newReusing}
          setNewReusing={setNewReusing}
          dispatchQuestion={dispatchQuestion}
          questionInfos={questionInfos}
          parentSurveyName={parentSurveyName}
          setParentSurveyName={setParentSurveyName}
          startTimeStatus={startTimeStatus}
          setStartTimeStatus={setStartTimeStatus}
          startTimeDate={startTimeDate}
          setStartTimeDate={setStartTimeDate}
          endTimeStatus={endTimeStatus}
          setEndTimeStatus={setEndTimeStatus}
          endTimeDate={endTimeDate}
          setEndTimeDate={setEndTimeDate}
          reminders={reminders}
          setReminders={setReminders}
          groupId={groupInfo.groupId}
        />
        <div className={styles.errors}>
          {errors.map(error => (
            <div className={styles.error} key={error}>
              {error}
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonsLeft}>
            <ButtonWithLoader type='info' onClick={onSubmit} isLoading={isSending}>
              {editExisting ? 'Umfrage speichern ' : 'Umfrage starten'}
            </ButtonWithLoader>
          </div>

          <div className={styles.buttonsRight}>
            {isPublic && <Label type='info'>{t('Post.Public')}</Label>}

            {canBePublic && (
              <MoreBtn
                dropdownOptions={moreBtnDropdownOptions}
                faIcon='fa-cogs'
                className={styles.moreBtn}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function getMoreBtnDropdownOptions(isPublic, setIsPublic, t) {
  return [
    {
      faIcon: isPublic ? 'fa-lock' : 'fa-unlock',
      title: isPublic ? t('Post.Make private') : t('Post.Make public'),
      onClick() {
        setIsPublic(!isPublic);
      },
    },
  ];
}

export async function onSubmitHandler({
  groupId,
  setErrors,
  t,
  setIsSending,
  isSending,
  parentSurveyData,
  toggleBody,
  groupInfo,
  onCreate,
  editExisting,
  parentSurveyId,
  setIsEditing,
  startTimeStatus,
  startTimeDate,
  endTimeStatus,
  endTimeDate,
  reminders
}) {
  const errors = validateParentSurvey({ t, parentSurveyData });

  if (isSending) {
    return;
  }
  if (errors.length) {
    return setErrors(errors);
  }
  setErrors([]);

  setIsSending(true);

  if (!groupInfo.groupAdminStatus) {
    setErrors(['You dont have permissions to perform this action.']);
    return;
  }

  if (editExisting) {
    updateParentSurvey({
      id: parentSurveyId,
      parentSurvey: parentSurveyData,
    })
      .then(data => {
        setIsSending(false);
        toggleBody();
        setIsEditing(false);
        onCreate(data);
      })
      .catch(err => {
        setErrors([t(err.response.data.message)]);
        return { data: null };
      });
  } else {
    createParentSurvey({
      groupId: groupId || 0,
      privatePost: false,
      moduleId: 9,
      startTimeStatus,
      startTimeDate,
      endTimeStatus,
      endTimeDate,
      reminders,
      parentSurvey: parentSurveyData,
    })
      .then(data => {
        setIsSending(false);
        toggleBody();
        onCreate(data);
      })
      .catch(err => {
        setErrors([t(err.response.data.message)]);
        return { data: null };
      });
  }
}
