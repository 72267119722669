import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Label from 'library/common/commonComponents/Label';
import Tabs from 'library/common/commonComponents/Tabs';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import { importUsers } from 'library/api/user';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import csvExample from 'resources/others/csv-import-example.csv';
import GeneralImport from './ImportTabs/GeneralImport';
import AdebisImport from './ImportTabs/AdebisImport';
import WinKitaImport from './ImportTabs/WinKitaImport';
import KitaPortalImport from './ImportTabs/KitaPortalImport';
import AdebisGfzImport from './ImportTabs/AdebisGfzImport';
import AdebisIdImport from './ImportTabs/AdebisIdImport';
import MemberImport from './ImportTabs/MemberImport';
import ImportUsersToGroup from './ImportTabs/ImportUsersToGroup';

import styles from './usersFrameImportPopup.module.scss';

export default function UsersFrameImportPopup({
  isOpened,
  closePopup,
  showBottomNotification,
  superAdminStatus,
  reloadPage,
}) {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);
  const [showTabs, setShowTabs] = useState(!superAdminStatus);
  const tabs = [
    {
      title: t('Administration.Import general'),
      component: (
        <GeneralImport
          closePopup={closePopup}
          showBottomNotification={showBottomNotification}
          reloadPage={reloadPage}
        />
      ),
      path: 'general-import',
    },
    {
      title: t('Administration.Import Adebis'),
      component: <AdebisImport showBottomNotification={showBottomNotification} />,
      path: 'adebis-import',
    },
    {
      title: t('Administration.Import WinKita'),
      component: <WinKitaImport showBottomNotification={showBottomNotification} />,
      path: 'winkita-import',
    },
    {
      title: t('Administration.ImportKitaPortal'),
      component: <KitaPortalImport showBottomNotification={showBottomNotification} />,
      path: 'kitaportal-import',
    },
    {
      title: t('Administration.Import Adebis gfz'),
      component: <AdebisGfzImport showBottomNotification={showBottomNotification} />,
      path: 'adebis-gfz-import',
    },
    {
      title: t('Administration.Import Member'),
      component: <MemberImport showBottomNotification={showBottomNotification} />,
      path: 'member-import',
    },
    {
      title: t('Administration.Import AdebisId'),
      component: <AdebisIdImport showBottomNotification={showBottomNotification} />,
      path: 'adebis-id-import',
    },
  ];

  if (superAdminStatus) {
    tabs.push({
      title: t('Administration.Import users to group'),
      component: <ImportUsersToGroup showBottomNotification={showBottomNotification} />,
      path: 'import-users-to-group',
    });
  }

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      header={<strong>{t('Administration.Import')}</strong>}
    >
      {showTabs ? (
        <div className={styles.wrapper}>
          <Tabs
            tabs={tabs}
            currentTab={tabs[currentTab].path}
            onChange={(_, index) => setCurrentTab(index)}
          />
        </div>
      ) : (
        <>
          <br />
          <center>
            <div>
              <Button
                onClick={() => {
                  setShowTabs(true);
                }}
              >
                {t(
                  'Administration.Import as superadmin. All imported users are going to be admins',
                )}
              </Button>
            </div>
          </center>
        </>
      )}
    </Popup>
  );
}
