import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from 'library/common/commonComponents/Tooltip';
import { isAwoWW } from 'library/api/tenantConfig';

import styles from './addNewKita.module.scss';

export function AddNewKita({ onClick, isOpened }) {
  const { t } = useTranslation();
  return (
    <div
      className={cn(styles.addKitaListItemContainer, isOpened && styles.opened)}
      onClick={onClick}
    >
      <Tooltip text={t('KITAsPanel.Add new KITAs')} isVisibleCondition position='right'>
        <div className={cn(styles.kitaListItemAvatarWrapper)}>
          <div
            className={styles.kitaListItemAvatarContainer}
            style={
              isAwoWW()
                ? { color: '#e2001A', border: '1px solid #e2001A' }
                : { color: '#6fdbe8', border: '1px solid #6fdbe8' }
            }
          >
            <i className='fa fa-plus' />
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default AddNewKita;
