import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateGroupPermissionTranslation } from 'library/api/groups';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import Label from 'library/common/commonComponents/Label';
import Popup from 'library/common/commonComponents/Popups/Popup';
import store from 'main/store/configureStore';

import styles from './UpdateGroupPermissionPopup.module.scss';

const errorsTemplate = {
  permissionKey: '',
  groupPermissionDescription: '',
  groupPermissionName: '',
  groupPermissionTag: '',
};

const langOptions = [
  { langCode: 'en', languageName: 'English' },
  { langCode: 'de', languageName: 'Deutsch' },
];

const UpdateGroupPermissionTranslationPopup = ({ isOpen, setOpen }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(langOptions[0].langCode);
  const [newGroupPermissionData, setNewGroupPermissionData] = useState({
    permissionKey: '',
    groupPermissionDescription: '',
    groupPermissionName: '',
    groupPermissionTag: '',
  });

  const [errors, setErrors] = useState(errorsTemplate);

  const handleDataChange = (key, value) =>  {
    setErrors(errorsTemplate);
    setNewGroupPermissionData({
      ...newGroupPermissionData,
      [key]: value,
    })
  };

  const changeLanguage = e => {
    setSelectedLanguage(e.target.value);
  };

  const closePopup = () => {
    setOpen(false);
  };

  const validateForm = () => {
    setErrors({
      permissionKey: newGroupPermissionData['permissionKey'] === '' ? t("GroupPermission.Permission Key field is required") : '',
      groupPermissionDescription: newGroupPermissionData['groupPermissionDescription'] === '' ? t('GroupPermission.Description field is required') : '',
      groupPermissionName: newGroupPermissionData['groupPermissionName'] === '' ? t('GroupPermission.Permission Name field is required') : '',
      groupPermissionTag: newGroupPermissionData['groupPermissionTag'] === '' ? t('GroupPermission.Permission Tag field is required') : '',
    });
    const dataKeys = Object.keys(newGroupPermissionData);
    return dataKeys.some(dataKey => newGroupPermissionData[dataKey] === '');
  };

  const onSave = async () => {
    const isError = validateForm();
    if (!isError) {
      setIsLoading(true);
      updateGroupPermissionTranslation({
        ...newGroupPermissionData,
        langCode: selectedLanguage
      }).then(res => {
        if (res.status === 200) {
          store.dispatch(
            showBottomNotification(t('GroupPermission.Translation was updated successfully!'), {
              isFail: false,
            }),
          );
          closePopup();
        }
      }).catch((error) => {
        if (error.response.status === 404) {
          setErrors({ ...errorsTemplate, permissionKey: t('GroupPermission.PermissionKeyNotFound') });
          store.dispatch(
            showBottomNotification(t('GroupPermission.PermissionKeyNotFound'), {
              isFail: true,
            }),
          );
        } else {
          store.dispatch(
            showBottomNotification(t('GroupPermission.Something went wrong'), {
              isFail: true,
            }),
          );
        }
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <Popup
      isOpened={isOpen}
      closePopup={closePopup}
      header={t('GlobalTools.Update Group Permission Translation')}
      footer={
        <div className={styles.buttonsContainer}>
          <ButtonWithLoader isLoading={isLoading} onClick={onSave}>
            {t('Confirm.Save')}
          </ButtonWithLoader>
          <Button onClick={closePopup}>{t('Confirm.Close')}</Button>
        </div>
      }
    >
      <div className={styles.formGroup}>
        <div className={styles.formInput}>
          <Label type='input'>{t('GroupPermission.Permission Key')} *</Label>
          <Input
            error={errors.permissionKey !== ''}
            value={newGroupPermissionData.permissionKey}
            placeholder={t('GroupPermission.Permission Key')}
            onChange={e => handleDataChange('permissionKey', e.target.value)}
            maxLength={45}
          />
          {errors.permissionKey !== '' && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={errors.permissionKey} />
            </div>
          )}
        </div>
        <div className={styles.formInput}>
          <Label type='input'>{t('GroupPermission.Permission Description')} *</Label>
          <Input
            error={errors.groupPermissionDescription !== ''}
            value={newGroupPermissionData.groupPermissionDescription}
            placeholder={t('GroupPermission.Permission Description')}
            onChange={e => handleDataChange('groupPermissionDescription', e.target.value)}
            maxLength={45}
          />
          {errors.groupPermissionDescription !== '' && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={errors.groupPermissionDescription} />
            </div>
          )}
        </div>
        <div className={styles.formInput}>
          <Label type='input'>{t('GroupPermission.Permission Name')} *</Label>
          <Input
            error={errors.groupPermissionName !== ''}
            value={newGroupPermissionData.groupPermissionName}
            placeholder={t('GroupPermission.Permission Name')}
            onChange={e => handleDataChange('groupPermissionName', e.target.value)}
            maxLength={45}
          />
          {errors.groupPermissionName !== '' && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={errors.groupPermissionName} />
            </div>
          )}
        </div>
        <div className={styles.formInput}>
          <Label type='input'>{t('GroupPermission.Permission Tag')} *</Label>
          <Input
            error={errors.groupPermissionTag !== ''}
            value={newGroupPermissionData.groupPermissionTag}
            placeholder={t('GroupPermission.Permission Tag')}
            onChange={e => handleDataChange('groupPermissionTag', e.target.value)}
            maxLength={45}
          />
          {errors.groupPermissionTag !== '' && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={errors.groupPermissionTag} />
            </div>
          )}
        </div>
        <div className={styles.languageDropDown}>
          <div className={styles.chooseLanguageText}>{t('Login.Choose language')}</div>
          <select
            value={selectedLanguage}
            name='ChooseLanguage'
            className={styles.chooseLanguage}
            onChange={changeLanguage}
          >
            {langOptions.map(lang => (
              <option value={lang.langCode} key={lang.langCode}>
                {lang.languageName}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Popup>
  );
};

export default UpdateGroupPermissionTranslationPopup;
