import React from 'react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { isAwoWW } from 'library/api/tenantConfig';
import PageWithSidebar from 'library/common/commonComponents/PageWithSidebar';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import SanitizedTranslation from 'library/common/commonComponents/SanitizedTranslation';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import UsersFrame from './administrationFrames/UsersFrame';
import GroupsFrame from './administrationFrames/GroupsFrame/groupsFrameContainer';
import ModulesFrame from './administrationFrames/ModulesFrame';
import SettingsFrame from './administrationFrames/SettingsFrame';
import NotificationFrame from './administrationFrames/NotificationFrame';
import KitaFrame from './administrationFrames/KitaFrame/KitaFrameContainer';
import FormsFrame from './administrationFrames/FormsFrame';
import { invitationService } from './administrationActions';

export function Administration(props) {
  const { t } = useTranslation();


  let menuItems = {
    users: {
      title: t('Administration.Users'),
      bodyTitle: (
        <SanitizedTranslation value={t('Administration.UserSection.User administration')} />
      ),
      faIcon: 'fa-user',
      to: '/administration/users/list/1',
      path: 'users',
      component: (
        <UsersFrame
          location={props.location}
          match={props.match}
          history={props.history}
          user={props.user}
        />
      ),
    },
  };
  if (props.user.administrationAccess) {
    menuItems = {
      ...menuItems, ...{
        groups: {
          title: t('Administration.Spaces'),
          bodyTitle: <SanitizedTranslation value={t('Administration.SpacesSection.Manage spaces')} />,
          faIcon: 'fa-inbox',
          to: '/administration/groups/1',
          path: 'groups',
          component: <GroupsFrame />,
        },
        modules: {
          title: t('Administration.Modules'),
          faIcon: 'fa-rocket',
          to: '/administration/modules',
          path: 'modules',
          component: <ModulesFrame showBottomNotification={props.showBottomNotification} />,
        },
        settings: {
          title: t('Administration.Settings'),
          bodyTitle: (
            <SanitizedTranslation
              value={t('Administration.SettingsSection.Settings and configuration')}
            />
          ),
          faIcon: 'fa-gears',
          to: '/administration/settings/language',
          path: 'settings',
          component: (
            <SettingsFrame
              currentTab={props.match.params.tab}
              history={props.history}
              user={props.user}
              modules={props.modules}
              activeKita={props.activeKita}
            />
          ),
        },
        kita: {
          title: t('Administration.Kita.Kita'),
          bodyTitle: <SanitizedTranslation value={t('Administration.Kita.Kita')} />,
          faIcon: 'fa-cloud-download',
          to: '/administration/kita/1',
          path: 'kita',
          component: <KitaFrame />,
        },
      }
    };
  }
  if (!isAwoWW()) {
    menuItems = {
      ...menuItems, ...{
        forms: {
          title: t('Administration.Forms'),
          bodyTitle: t('Administration.Forms'),
          faIcon: 'fa-file',
          to: '/administration/forms',
          path: 'forms',
          component: <FormsFrame />,
        }
      }
    }
  }
  if (props.user.superAdminStatus) {
    menuItems.notification = {
      title: t('Administration.Notification.Notification'),
      bodyTitle: (
        <SanitizedTranslation value={t('Administration.Notification.Global Notification')} />
      ),
      faIcon: 'fa-envelope',
      to: '/administration/notification',
      path: 'notification',
      component: <NotificationFrame showBottomNotification={props.showBottomNotification} />,
    };
  }

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <PageWithSidebar
          currentPage={props.match.params.page}
          menuItems={menuItems}
          menuTitle={<Trans i18nKey='Administration.Administration menu' />}
        />
      </Wrapper>
    </KitaPanelWrapper>
  );
}

const mapStateToProps = state => ({
  user: state.userReducer,
  modules: state.kitaReducer.modules,
  activeKita: state.kitaReducer.activeKita,
});

const mapDispatchToProps = {
  invitationService,
  showBottomNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Administration);
