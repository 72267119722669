import React, { useState } from 'react';
import ButtonWithLoader from "library/common/commonComponents/Buttons/ButtonWithLoader";
import { useTranslation } from 'react-i18next';
import HelpText from 'library/common/commonComponents/HelpText';
import { downloadEmployeeLastLoginExcel } from 'library/api/kita';

const KitaExportFrame = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    async function exportEmployees() {
        setIsLoading(true);

        const { data } = await downloadEmployeeLastLoginExcel();
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Employee List.xlsx');
        document.body.appendChild(link);
        link.click();

        setIsLoading(false);
    }

    return (
        <div>
            <div>
                <ButtonWithLoader type="primary" onClick={exportEmployees} isLoading={isLoading}>
                    { t("Administration.Kita.ExportEmployees") }
                </ButtonWithLoader>
                <HelpText>{ t("Administration.Kita.ExportEmployees.HelpText") }</HelpText>
            </div>
        </div>
    )
}

export default KitaExportFrame;