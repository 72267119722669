import React, { useState } from 'react';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';

import styles from './recordAudioFileButton.module.scss';

RecordAudioFileButton.defaultProps = {
  allowedExtensions: ['*'],
};

export default function RecordAudioFileButton({setAudioData}) {
  const [recordState, setRecordState] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const start = () => {
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  function convertDataURIToBinary(dataURI) {
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
  
    for(let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  const onStopp = audioData => {
    var reader = new FileReader();
    reader.readAsDataURL(audioData.blob); 
    reader.onloadend = function() {
      var base64data = reader.result;                
      setAudioData({base64data, blobData: audioData.blob});
    }
  };

  return (
    <div>
      {isListening === false ? (
        <div
          className={styles.toolBarIcon}
          onClick={() => {
            start();
            setIsListening(true);
          }}
        >
          <i className='fa fa-microphone' />
        </div>
      ) : (
        <div
          className={styles.toolBarIcon}
          style={{ color: 'red' }}
          onClick={() => {
            stop();
            setIsListening(false);
          }}
        >
          <i className='fa fa-circle' />
        </div>
      )}
      <AudioReactRecorder
        state={recordState}
        type='audio/mpeg'
        onStop={onStopp}
        canvasWidth={1}
        canvasHeight={1}
      />
    </div>
  );
}
