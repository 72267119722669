import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../FoodOrderMealPlan/foodOrderMealPlan.module.scss';
import Loader from '../../../../Loader';
import OrderedMealItem from './OrderedMealItem';
import Button from '../../../../Buttons/Button';
import { kitchenGetOrders } from '../../../../../../api/foodOrder';
import {
  formatDate,
  generateKitchenFoodOrdersPDF, getWeekDayIndexForGermanDateString,
  orderByDateASC,
} from '../../../../../../utilities/foodOrder';

export default function FoodOrders({ groupId, from, to, activeKita, groupInfo }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [orderedMeals, setOrderedMeals] = useState(null);

  const downloadPDF = () => {
    generateKitchenFoodOrdersPDF(t, orderedMeals, activeKita.kitaName, groupInfo.groupName);
  };

  // Query ordered meals on start and when calendarWeek changes
  useEffect(() => {
    setIsLoading(true);

    kitchenGetOrders(groupId, formatDate(from), formatDate(to)).then(res => {
      const data = [...res.data];
      orderByDateASC(data);
      const filteredDataWithoutSatAndSun = data.filter(meal => getWeekDayIndexForGermanDateString(meal.date) !== 0 && getWeekDayIndexForGermanDateString(meal.date) !== 6 )
      setOrderedMeals(filteredDataWithoutSatAndSun);
    });

    // Remove timeout
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [from]);

  return (
    <div>
      <h2>{t('FoodOrder.Food orders')}</h2>
      <br />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Button onClick={() => downloadPDF()}>
            <i className='fa fa-file-pdf-o' /> {t('FoodOrder.export pdf')}
          </Button>
          <br />
          <br />
          <hr className={styles.hr} />
          <br />
          {orderedMeals && (
            <div className={styles.foodContainer}>
              {orderedMeals.length > 0 ? (
                orderedMeals.map(meal => (
                  <OrderedMealItem
                    id={meal.id}
                    date={meal.date}
                    foods={meal.foods}
                    foodCounts={meal.foodCounts}
                    isActive={!meal.inactive}
                  />
                ))
              ) : (
                <>
                  <strong>
                    <i className={cn('fa fa-info-circle', styles.mr1)} />
                    {t('FoodOrder.No meals available')}
                  </strong>
                  <br /> <br />
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
