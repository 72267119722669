import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as groupApi from 'library/api/groups';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Input from 'library/common/commonComponents/Inputs/Input';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import styles from './GroupHolidayCareFrame.module.scss';

export function GroupHolidayCareFrame({ groupId, showBottomNotification }) {
  const { t } = useTranslation();

  const [holidayCares, setHolidayCares] = useState([]);
  const [additionalHolidayCareLabel, setAdditionalHolidayCareLabel] = useState('');
  const [additionalHolidayCareStartDate, setAdditionalHolidayCareStartDate] = useState('');
  const [additionalHolidayCareEndDate, setAdditionalHolidayCareEndDate] = useState('');
  const [additionalHolidayCareStartTime, setAdditionalHolidayCareStartTime] = useState('');
  const [additionalHolidayCareEndTime, setAdditionalHolidayCareEndTime] = useState('');

  const [isDisabled, setIsDisabled] = useState(false);

  const isAddNewHolidayCareDisabled = useMemo(
    () =>
      !additionalHolidayCareLabel ||
      !additionalHolidayCareStartDate ||
      !additionalHolidayCareEndDate ||
      !additionalHolidayCareStartTime ||
      !additionalHolidayCareEndTime ||
      additionalHolidayCareStartDate > additionalHolidayCareEndDate ||
      additionalHolidayCareStartTime > additionalHolidayCareEndDate,
    [
      additionalHolidayCareLabel,
      additionalHolidayCareStartDate,
      additionalHolidayCareEndDate,
      additionalHolidayCareStartTime,
      additionalHolidayCareEndTime,
    ],
  );

  const resetInputState = () => {
      setAdditionalHolidayCareLabel('');
      setAdditionalHolidayCareStartDate('');
      setAdditionalHolidayCareEndDate('');
      setAdditionalHolidayCareStartTime('');
      setAdditionalHolidayCareEndTime('');
  }

  const handleAddNewHolidayCare = () => {
    if (isAddNewHolidayCareDisabled) {
      return;
    }
    if (!holidayCares.find(holidayCare => holidayCare.name === additionalHolidayCareLabel)) {
      setHolidayCares([
        ...holidayCares,
        {
          name: additionalHolidayCareLabel,
          startDate: additionalHolidayCareStartDate,
          endDate: additionalHolidayCareEndDate,
          startTime: additionalHolidayCareStartTime,
          endTime: additionalHolidayCareEndTime,
          isAdditional: true,
        },
      ]);
      resetInputState();
    }
  };

  const handleRemoveHolidayCare = index => {
    const updatedHolidayCaresData = holidayCares.slice();
    updatedHolidayCaresData.splice(index, 1);
    setHolidayCares(updatedHolidayCaresData);
  };

  const handleHolidayCareChange = (name, field) => e => {
    const updatedHolidayCaresData = holidayCares.map(item => {
      if (item.name === name) {
        return {
          ...item,
          [field]: e.target.value,
        };
      } else {
        return item;
      }
    });

    setHolidayCares(updatedHolidayCaresData);
  };

  const updateGroupHolidayCare = async () => {
    try {
      await groupApi.updateGroupHolidayCares({
        groupId: groupId,
        groupHolidayCares: holidayCares,
      });
      showBottomNotification(t('BottomNotifications.Saved'));
    } catch (e) {
      showBottomNotification(t('BottomNotifications.Something went wrong'));
      console.log(e)
    }
  };

  const resetGroupHolidayCare = async () => {
    if (!groupId) return;
    setIsDisabled(true);
    try {
      const { data: groupHolidayCares } = await groupApi.getGroupHolidayCares(groupId);
      setHolidayCares(groupHolidayCares);
      resetInputState();
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisabled(false);
    }
  }

  useEffect(() => {
    resetGroupHolidayCare();
  }, []);

  return (
    <div className={styles.holidayCareFrame}>
      <div className={styles.holidaysCareContainer}>
        <div className={styles.holidayCare}>
          <div className={styles.holidayCareHeader}>
            <div>{t('GroupBody.Settings.HolidayCares Label')}</div>
            <div>{t('GroupBody.Settings.HolidayCares FromDate')}</div>
            <div>{t('GroupBody.Settings.HolidayCares ToDate')}</div>
          </div>
          {holidayCares.map((holidayCare, index) => (
            <div className={styles.holidayCareRow} key={index}>
              <div className={styles.holidayCareFields}>
                <div className={styles.holidayCareDateRow}>
                  <Input
                    value={holidayCare.name}
                    onChange={handleHolidayCareChange(holidayCare.name, 'name')}
                  />
                  <Input
                    type='date'
                    value={holidayCare.startDate}
                    onChange={handleHolidayCareChange(holidayCare.name, 'startDate')}
                  />
                  <Input
                    type='date'
                    value={holidayCare.endDate}
                    onChange={handleHolidayCareChange(holidayCare.name, 'endDate')}
                  />
                </div>
                <div className={styles.holidayCareTimeRow}>
                  <div />
                  <Input
                    type='time'
                    value={holidayCare.startTime}
                    onChange={handleHolidayCareChange(holidayCare.name, 'startDate')}
                  />
                  <Input
                    type='time'
                    value={holidayCare.endTime}
                    onChange={handleHolidayCareChange(holidayCare.name, 'endTime')}
                  />
                </div>
              </div>
              <div>
                <Button size='sm' onClick={() => handleRemoveHolidayCare(index)}>
                  <i className='fa fa-minus' />
                </Button>
              </div>
            </div>
          ))}
          <div className={styles.holidayCareRow}>
            <div className={styles.holidayCareFields}>
              <div className={styles.holidayCareDateRow}>
                <Input
                  placeholder='name'
                  value={additionalHolidayCareLabel}
                  onChange={e => setAdditionalHolidayCareLabel(e.target.value)}
                />
                <Input
                  type='date'
                  value={additionalHolidayCareStartDate}
                  onChange={e => setAdditionalHolidayCareStartDate(e.target.value)}
                />
                <Input
                  type='date'
                  value={additionalHolidayCareEndDate}
                  onChange={e => setAdditionalHolidayCareEndDate(e.target.value)}
                />
              </div>
              <div className={styles.holidayCareTimeRow}>
                <div />
                <Input
                  type='time'
                  value={additionalHolidayCareStartTime}
                  onChange={e => setAdditionalHolidayCareStartTime(e.target.value)}
                />
                <Input
                  type='time'
                  value={additionalHolidayCareEndTime}
                  onChange={e => setAdditionalHolidayCareEndTime(e.target.value)}
                />
              </div>
            </div>
            <div>
              <Button
                size='sm'
                disabled={isAddNewHolidayCareDisabled}
                onClick={handleAddNewHolidayCare}
              >
                <i className='fa fa-plus' />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonWithLoader type='primary' onClick={updateGroupHolidayCare} isLoading={isDisabled}>
          {t('GroupBody.Settings.Save')}
        </ButtonWithLoader>
        <ButtonWithLoader onClick={resetGroupHolidayCare} type='danger' isLoading={isDisabled}>
          {t('GroupBody.Settings.Cancel')}
        </ButtonWithLoader>
      </div>
    </div>
  );
}

export default connect(
  state => ({ groupId: state.groupReducer.groupId }),
  { showBottomNotification },
)(GroupHolidayCareFrame);
