import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import { QRCodeSVG } from 'qrcode.react';

import { generateQRCodeForUser } from 'library/api/kids';
import { isAwoWW } from 'library/api/tenantConfig';
import { getCountries } from 'library/api/user';

import DatePicker from 'library/common/commonComponents/DatePicker';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Select from 'library/common/commonComponents/Inputs/Select';
import CheckBox from 'library/common/commonComponents/Checkbox';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import RadioButton from 'library/common/commonComponents/RadioButton';
import Button from 'library/common/commonComponents/Buttons/Button';

import styles from '../../userEditing.module.scss';

export default function UserEditingGeneral({
  editingFields,
  setEditingFields,
  firstNameError,
  lastNameError,
  userNameError,
  user,
  shouldDisableContactSettings,
  memberOptionVisible,
  kitaUserFields,
}) {
  const { t } = useTranslation();

  const genderOptions = [
    { label: t('Administration.UserSection.Not specified'), value: null },
    { label: t('Administration.UserSection.Male'), value: 'Male' },
    { label: t('Administration.UserSection.Female'), value: 'Female' },
    { label: t('Administration.UserSection.Custom'), value: 'Custom' },
  ];
  const [selectedGender, setSelectedGender] = useState(
    genderOptions.find(option => option.value === editingFields.gender) || genderOptions[0],
  );

  useEffect(() => {
    setEditingFields({ ...editingFields, gender: selectedGender.value });
    // eslint-disable-next-line
  }, [selectedGender]);

  console.log('editingFields', editingFields, 'user', user);

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    editingFields.country
      ? { ...editingFields.country, label: editingFields.country.countryName }
      : { label: t('Administration.UserSection.Not specified'), value: 0 },
  );
  useEffect(() => {
    setEditingFields({
      ...editingFields,
      countryId: selectedCountry.value,
      countryName: selectedCountry.label,
    });
    // eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    getCountries().then(({ data }) => {
      const sorted = data.sort((x, y) => {
        return x.countryName === 'Deutschland (Germany)'
          ? -1
          : y.countryName === 'Deutschland (Germany)'
          ? 1
          : 0;
      });

      const newSelectedCountries = [
        { label: t('Administration.UserSection.Not specified'), value: 0 },
      ].concat(
        sorted.map(country => ({ ...country, label: country.countryName, value: country.id })),
      );
      setCountryOptions(newSelectedCountries);
    });
    // eslint-disable-next-line
  }, []);

  const generatePDF = () => {
    const uploadToFilesModuleData = { uploadToFilesModule: false };
    const qrCodeUser = {
      firstName: editingFields.firstName,
      lastName: editingFields.lastName,
      kidGroup: null,
    };
    let qr = null;

    const idString = editingFields.naturalId;
    QRCode.toDataURL(idString + ' employee', function(err, url) {
      qr = url;
    });

    generateQRCodeForUser(t, qrCodeUser, uploadToFilesModuleData, qr);
  };

  const userIsEmployeeAdminOrSuperAdmin =
    user.employee || user.superAdminStatus || user.administrationAccess;

  return (
    <div>
      {(user.superAdminStatus || user.id === editingFields.id) &&
        kitaUserFields?.userName.isShownOnUserProfile && (
          <div className={styles.formGroup}>
            <Label type='input'>{t('Administration.UserSection.Username')} *</Label>
            <Input
              error={userNameError}
              value={editingFields.userName}
              type='text'
              maxLength={255}
              onChange={e => setEditingFields({ ...editingFields, userName: e.target.value })}
              tabIndex={1}
            />
            {userNameError && (
              <div className={styles.error}>
                <InputErrorMsg errorMsg={userNameError} />
              </div>
            )}
          </div>
        )}
      {kitaUserFields?.firstName.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.First name')} *</Label>
          <Input
            error={firstNameError}
            value={editingFields.firstName}
            type='text'
            maxLength={255}
            onChange={e => setEditingFields({ ...editingFields, firstName: e.target.value })}
            tabIndex={1}
          />
          {firstNameError && (
            <div className={styles.error}>
              <InputErrorMsg errorMsg={firstNameError} />
            </div>
          )}
        </div>
      )}
      {editingFields.lastName !== 'Caterer' && kitaUserFields?.lastName.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Last name')} *</Label>
          <Input
            error={lastNameError}
            value={editingFields.lastName}
            type='text'
            maxLength={255}
            onChange={e => setEditingFields({ ...editingFields, lastName: e.target.value })}
            tabIndex={2}
          />
          {lastNameError && (
            <div className={styles.error}>
              <InputErrorMsg errorMsg={lastNameError} />
            </div>
          )}
        </div>
      )}
      {kitaUserFields?.title.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Title')}</Label>
          <Input
            value={editingFields.title}
            type='text'
            maxLength={255}
            onChange={e => setEditingFields({ ...editingFields, title: e.target.value })}
            tabIndex={3}
          />
        </div>
      )}

      {kitaUserFields?.gender.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Gender')}</Label>
          <Select
            options={genderOptions}
            placeholder={t('Administration.UserSection.Please select')}
            selected={selectedGender}
            onSelect={setSelectedGender}
            tabIndex={4}
          />
        </div>
      )}

      {kitaUserFields?.street.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Street')}</Label>
          <Input
            value={editingFields.street}
            type='text'
            maxLength={255}
            onChange={e => setEditingFields({ ...editingFields, street: e.target.value })}
            tabIndex={5}
            disabled={shouldDisableContactSettings}
          />
        </div>
      )}

      {kitaUserFields?.zip.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Zip')}</Label>
          <Input
            value={editingFields.zip}
            type='text'
            maxLength={255}
            onChange={e => setEditingFields({ ...editingFields, zip: e.target.value })}
            tabIndex={6}
            disabled={shouldDisableContactSettings}
          />
        </div>
      )}

      {kitaUserFields?.city.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.City')}</Label>
          <Input
            value={editingFields.city}
            type='text'
            maxLength={255}
            onChange={e => setEditingFields({ ...editingFields, city: e.target.value })}
            tabIndex={7}
            disabled={shouldDisableContactSettings}
          />
        </div>
      )}

      {kitaUserFields?.country.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Country')}</Label>
          <Select
            options={countryOptions}
            selected={selectedCountry}
            placeholder={t('Administration.UserSection.Please select')}
            onSelect={setSelectedCountry}
            openedClassName={styles.select}
            tabIndex={8}
            disabled={shouldDisableContactSettings}
          />
        </div>
      )}

      {kitaUserFields?.state.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.State')}</Label>
          <Input
            value={editingFields.state}
            type='text'
            maxLength={255}
            onChange={e => setEditingFields({ ...editingFields, state: e.target.value })}
            tabIndex={9}
            disabled={shouldDisableContactSettings}
          />
        </div>
      )}
      {!window.location.hostname.toLowerCase().includes('matchand.de') &&
        kitaUserFields?.motherLanguage.isShownOnUserProfile && (
          <div className={styles.formGroup}>
            <Label type='input'>{t('Administration.UserSection.MotherLanguage')}</Label>
            <Input
              value={editingFields.motherLanguage}
              type='text'
              maxLength={255}
              onChange={e => setEditingFields({ ...editingFields, motherLanguage: e.target.value })}
              tabIndex={9}
              disabled={shouldDisableContactSettings}
            />
          </div>
        )}
      {!window.location.hostname.toLowerCase().includes('matchand.de') &&
        kitaUserFields?.mainLanguage.isShownOnUserProfile && (
          <div className={styles.formGroup}>
            <Label type='input'>{t('Administration.UserSection.MainSpokenLanguageInFamily')}</Label>
            <Input
              value={editingFields.mainLanguage}
              type='text'
              maxLength={255}
              onChange={e => setEditingFields({ ...editingFields, mainLanguage: e.target.value })}
              tabIndex={9}
              disabled={shouldDisableContactSettings}
            />
          </div>
        )}
      {!window.location.hostname.toLowerCase().includes('matchand.de') &&
        kitaUserFields?.denomination.isShownOnUserProfile && (
          <div className={styles.formGroup}>
            <Label type='input'>{t('Administration.UserSection.Denomination')}</Label>
            <Input
              value={editingFields.denomination}
              type='text'
              maxLength={255}
              onChange={e => setEditingFields({ ...editingFields, denomination: e.target.value })}
              tabIndex={9}
              disabled={shouldDisableContactSettings}
            />
          </div>
        )}

      {kitaUserFields?.workingPlace.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.WorkingPlace')}</Label>
          <Input
            value={editingFields.workingPlace}
            type='text'
            maxLength={255}
            onChange={e => setEditingFields({ ...editingFields, workingPlace: e.target.value })}
            tabIndex={9}
            disabled={shouldDisableContactSettings}
          />
        </div>
      )}
      {!window.location.hostname.toLowerCase().includes('matchand.de') &&
        kitaUserFields?.maritalStatus.isShownOnUserProfile && (
          <div className={styles.formGroup}>
            <Label type='input'>{t('Administration.UserSection.MaritalStatus')}</Label>
            <Input
              value={editingFields.maritalStatus}
              type='text'
              maxLength={255}
              onChange={e => setEditingFields({ ...editingFields, maritalStatus: e.target.value })}
              tabIndex={9}
              disabled={shouldDisableContactSettings}
            />
          </div>
        )}
      {!window.location.hostname.toLowerCase().includes('matchand.de') &&
        kitaUserFields?.personWithSoleCustody.isShownOnUserProfile && (
          <div className={styles.formGroup}>
            <Label type='input'>{t('Administration.UserSection.PersonWithSoleCustody')}</Label>
            <RadioButton
              checked={editingFields.personWithSoleCustody === 'Yes'}
              onClick={() => setEditingFields({ ...editingFields, personWithSoleCustody: 'Yes' })}
              label={t('Administration.UserSection.PersonWithSoleCustodyYes')}
            />
            <RadioButton
              checked={editingFields.personWithSoleCustody !== 'Yes'}
              onClick={() => setEditingFields({ ...editingFields, personWithSoleCustody: 'No' })}
              label={t('Administration.UserSection.PersonWithSoleCustodyNo')}
            />
          </div>
        )}
      {!window.location.hostname.toLowerCase().includes('matchand.de') &&
        kitaUserFields?.personSingleParent.isShownOnUserProfile && (
          <div className={styles.formGroup}>
            <Label type='input'>{t('Administration.UserSection.PersonSingleParent')}</Label>
            <RadioButton
              checked={editingFields.personSingleParent === 'Yes'}
              onClick={() => setEditingFields({ ...editingFields, personSingleParent: 'Yes' })}
              label={t('Administration.UserSection.PersonSingleParentYes')}
            />
            <RadioButton
              checked={editingFields.personSingleParent !== 'Yes'}
              onClick={() => setEditingFields({ ...editingFields, personSingleParent: 'No' })}
              label={t('Administration.UserSection.PersonSingleParentNo')}
            />
          </div>
        )}
      {kitaUserFields?.birthday.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Birthday')}</Label>
          <DatePicker
            onChange={birthday => {
              setEditingFields({ ...editingFields, birthday });
            }}
            selected={editingFields.birthday && new Date(editingFields.birthday)}
            customInput={<Input />}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            langCode={user.langCode}
            maxDate={new Date()}
            tabIndex={10}
          />
        </div>
      )}
      {(kitaUserFields?.hideUser.isShownOnUserProfile ||
        kitaUserFields?.blockUsersFromMessaging.isShownOnUserProfile) && (
        <>
          <Label type='input'>{t('Administration.UserSection.Hide user')}</Label>
          <div className={styles.formGroup}>
            {kitaUserFields?.hideUser.isShownOnUserProfile && (
              <CheckBox
                isChecked={editingFields.hideUser}
                name={t('Administration.UserSection.Hide me')}
                onChange={() => {
                  setEditingFields({ ...editingFields, hideUser: !editingFields.hideUser });
                }}
              />
            )}
            {kitaUserFields?.blockUsersFromMessaging.isShownOnUserProfile && (
              <CheckBox
                isChecked={editingFields.blockUsersFromMessaging}
                name={t('Administration.UserSection.Block users')}
                onChange={() => {
                  setEditingFields({
                    ...editingFields,
                    blockUsersFromMessaging: !editingFields.blockUsersFromMessaging,
                  });
                }}
              />
            )}
          </div>
        </>
      )}
      {kitaUserFields?.about.isShownOnUserProfile && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.About')}</Label>
          <TextArea
            value={editingFields.about}
            onChange={e => setEditingFields({ ...editingFields, about: e.target.value })}
            tabIndex={11}
          />
        </div>
      )}
      {!window.location.hostname.toLowerCase().includes('matchand.de') &&
        kitaUserFields?.importId.isShownOnUserProfile && (
          <div className={styles.formGroup}>
            <Label type='input'>{t('Administration.UserSection.ImportId')}</Label>
            <Input value={editingFields.importId} type='text' maxLength={255} disabled={true} />
          </div>
        )}

      {isAwoWW() && user.administrationAccess && memberOptionVisible && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Set AWO Member')}</Label>
          <CheckBox
            isChecked={editingFields.awoMember}
            name={t('Administration.UserSection.AWO Member')}
            onChange={() => {
              setEditingFields({ ...editingFields,
                awoMember: !editingFields.awoMember,
              });
            }}
          />
        </div>
      )}

      {/* hide qr generator on gfz-app.ch */}
      {/* show when user visits own profile page and is either employee, admin, or superadmin

      */}
      {!window.location.hostname.toLowerCase().includes('gfz-app.ch') &&
        ((user.id === editingFields.id && userIsEmployeeAdminOrSuperAdmin) ||
          (editingFields.employee && (user.superAdminStatus || user.administrationAccess))) &&
        kitaUserFields?.qrCode.isShownOnUserProfile && (
          <div className={styles.QRbuttonDiv}>
            <Button onClick={generatePDF} type='primary' className={styles.QRbutton}>
              {t('Administration.Generate QR Code')}
            </Button>
            <div className={styles.QR}>
              <QRCodeSVG size='64' />
            </div>
          </div>
        )}
    </div>
  );
}
