import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import Loader from 'library/common/commonComponents/Loader';
import { importUsersToGroup } from 'library/api/user';
import CheckBox from 'library/common/commonComponents/Checkbox';
import styles from './usersFrameImportPopup.module.scss';

export default function ImportUsersToGroup({ showBottomNotification }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const uploadFile = files => {
    const data = new FormData();
    data.append('file', files[0]);
    setIsLoading(true);
    importUsersToGroup(data)
      .then(() => {
        showBottomNotification(t('BottomNotifications.Success'));
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <h2>{t('Administration.Import users to group')}</h2>
      <br />
      <div>{t('Administration.Group import description')}</div>
      <br />
      {!isLoading ? (
        <div>
          <UploadButton onChange={uploadFile} isMultiple={false} className={styles.uploadButton}>
            &nbsp;{t('Administration.Select and upload Adebis Excel file')}
          </UploadButton>
        </div>
      ) : (
        <div>
          {t('Administration.Upload is in progress, please wait until it finished')}
          <br />
          <Loader />
        </div>
      )}
    </>
  );
}
