import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import FilePrewiev from 'library/common/commonComponents/FilePrewiev';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Label from 'library/common/commonComponents/Label';
import useFileUploading from 'library/common/commonHooks/useFileUploading';
import Input from 'library/common/commonComponents/Inputs/Input';
import RadioButton from 'library/common/commonComponents/RadioButton';
import cn from 'classnames';
import Button from 'library/common/commonComponents/Buttons/Button';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import { convertDateToFolderName, isValidDateTaxReceipt } from 'library/utilities/taxConsultant';
import { getGroups } from 'library/api/groups';
import { getFolders, createFolder } from 'library/api/folder';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import useMultipleUpload from 'library/common/commonHooks/useMultipleUpload';
import { getUploadFilesHandler } from 'library/utilities/getUploadFilesHandler';
import Loader from 'library/common/commonComponents/Loader';
import styles from './taxconsultant.module.scss';

export default function TaxConsultantModule(user) {
  const [receiptSelectbox, setReceiptSelectbox] = useState(0);
  const [folderData, setFolderData] = useState({});
  const { onFileUpload } = useFileUploading(folderData);
  const [taxReason, setTaxReason] = useState('');
  const [taxPersons, setTaxPersons] = useState('');
  const [taxDate, setTaxDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [uploadButtonActivated, setUploadButtonActivated] = useState(false);
  const [folderCreated, setFolderCreate] = useState(false);
  const [fileUploadSuccesfull, setFileUploadSuccessfull] = useState(false);

  const { files, uploadFile, deleteFiles } = useMultipleUpload(null);
  const inputRef = React.useRef(null);

  const uploadFilesHandler = getUploadFilesHandler({
    chatId: 0,
    userId: user.id,
    uploadFile,
    showBottomNotification,
    t,
  });

  function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue(v => v + 1);
  }
  const forceUpdate = useForceUpdate();

  function checkFieldsForValidity() {
    let isValid = true;
    if (receiptSelectbox === 0) {
      if (taxReason === '' || taxPersons === '' || taxDate === '') {
        isValid = false;
      }
    }
    isValid = isValidDateTaxReceipt(taxDate);
    if (files.length < 1) {
      isValid = false;
    }
    setUploadButtonActivated(isValid);
  }

  const uploadFileHandler = async file => {
    setIsLoading(true);
    setFolderData(prevState => {
      prevState.taxReceiptType = receiptSelectbox === 0 ? 'Bewirtungsbeleg' : 'Andere Belege';
      return prevState;
    });
    setFolderData(prevState => {
      prevState.taxReason = taxReason;
      return prevState;
    });
    setFolderData(prevState => {
      prevState.taxPersons = taxPersons;
      return prevState;
    });
    setFolderData(prevState => {
      prevState.taxDate = taxDate;
      return prevState;
    });

    await onFileUpload(file);
    store.dispatch(
      showBottomNotification('Upload erfolgreich', {
        isFail: false,
      }),
    );
    setIsLoading(false);
    setFileUploadSuccessfull(true);
  };

  useEffect(() => {
    loadUserAndFolderData();
  }, []);

  useEffect(() => {
    setFolderData(prevState => {
      prevState.taxReceiptType = receiptSelectbox === 0 ? 'Bewirtungsbeleg' : 'Andere Belege';
      return prevState;
    });
    checkFieldsForValidity();
  }, [receiptSelectbox]);

  useEffect(() => {
    setFolderData(prevState => {
      prevState.taxReason = taxReason;
      return prevState;
    });
    checkFieldsForValidity();
  }, [taxReason]);

  useEffect(() => {
    setFolderData(prevState => {
      prevState.taxPersons = taxPersons;
      return prevState;
    });
    checkFieldsForValidity();
  }, [taxPersons]);

  useEffect(() => {
    setFolderData(prevState => {
      prevState.taxDate = taxDate;
      return prevState;
    });
    checkFieldsForValidity();
  }, [taxDate]);

  useEffect(() => {
    checkFieldsForValidity();
  }, [files]);

  async function getBelegeSubFolderId(groupId, parentFolderId, folderName) {
    let subFolderId;
    const fileModuleFolders = await getFolders({
      graphql: 'id,name',
      groupId,
      parentFolderId,
    });

    fileModuleFolders.forEach(object => {
      if (object.name === folderName) {
        subFolderId = object.id;
      }
    });
    if (typeof subFolderId !== 'undefined') {
      return subFolderId;
    }
    return null;
  }

  async function getBelegeFolderId(groupId) {
    let belegeFolderId;
    const fileModuleFolders = await getFolders({
      graphql: 'id,name',
      groupId,
    });

    fileModuleFolders.forEach(object => {
      if (object.name === 'Belege') {
        belegeFolderId = object.id;
      }
    });
    if (typeof belegeFolderId !== 'undefined') {
      return belegeFolderId;
    }
    return null;
  }

  async function loadUserAndFolderData() {
    const userAndFolderData = {};
    const groupInfo = await getGroups();
    if (groupInfo.data.length > 0) {
      groupInfo.data.forEach(object => {
        if (object.defaultGroup) {
          userAndFolderData.groupId = object.id;
        }
      });

      userAndFolderData.belFolderId = await getBelegeFolderId(userAndFolderData.groupId);
      if (userAndFolderData.belFolderId == null) {
        const belegeFolderData = {
          description: '',
          groupId: userAndFolderData.groupId,
          name: 'Belege',
          parentFolderId: null,
          publicFolder: true,
        };
        await createFolder(belegeFolderData);
        userAndFolderData.belFolderId = await getBelegeFolderId(userAndFolderData.groupId);
      }
    }
    userAndFolderData.folderId = null;
    userAndFolderData.taxReceiptType = 'Bewirtungsbeleg';
    userAndFolderData.userId = user.userId;
    setFolderData(userAndFolderData);
    setIsLoading(false);
  }
  const { t } = useTranslation();

  return (
    <div>
      <KitaPanelWrapper>
        <Wrapper>
          <Col>
            <Row>
              <div className={styles.title}>{t('Header.Bottombar.Menu.taxconsultant')}</div>
              <div className={styles.container}>
                {fileUploadSuccesfull && (
                  <div style={{ fontWeight: 'bold', color: 'green' }}>
                    Upload erfolgreich.{' '}
                    <Button
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Neuen Beleg hochladen
                    </Button>
                    <br />
                    <br />
                  </div>
                )}
                <RadioButton
                  checked={receiptSelectbox === 0}
                  onClick={() => setReceiptSelectbox(0)}
                  label={t('FileManager.Hospitality receipt')}
                  disabled={fileUploadSuccesfull || isLoading}
                />
                <RadioButton
                  checked={receiptSelectbox === 1}
                  onClick={() => setReceiptSelectbox(1)}
                  label={t('FileManager.Other receipts')}
                  disabled={fileUploadSuccesfull || isLoading}
                />
                {receiptSelectbox === 0 && (
                  <div>
                    <div className={styles.formGroup}>
                      <Label type='input'>{t('FileManager.Occasion')}</Label>
                      <Input
                        type='text'
                        value={taxReason}
                        onChange={e => setTaxReason(e.target.value)}
                        maxLength={1024}
                        disabled={fileUploadSuccesfull || isLoading}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <Label type='input'>{t('FileManager.People')}</Label>
                      <Input
                        type='text'
                        value={taxPersons}
                        onChange={e => setTaxPersons(e.target.value)}
                        maxLength={1024}
                        disabled={fileUploadSuccesfull || isLoading}
                      />
                    </div>
                  </div>
                )}
                <div className={styles.formGroup}>
                  <Label type='input'>{t('FileManager.Date')}</Label>
                  <Input
                    type='date'
                    disabled={fileUploadSuccesfull || isLoading}
                    value={taxDate}
                    onChange={e => setTaxDate(e.target.value)}
                  />
                </div>
                <br />
                <div className={styles.formGroup}>
                  <UploadButton
                    className={styles.uploadButton}
                    onChange={uploadFilesHandler}
                    files={files}
                    disabled={fileUploadSuccesfull || files.length > 0 || isLoading}
                  />
                  {files.length > 0 && files[0].type.startsWith('image') && (
                    <div>
                      <br />
                      <img style={{ maxHeight: '300px' }} src={files[0].path} alt='' />
                    </div>
                  )}
                  {!fileUploadSuccesfull && <FilePrewiev newFiles={files} onDelete={deleteFiles} />}
                </div>
                <br />
                {!isLoading ? (
                  <div
                    className={cn(
                      styles.button,
                      styles.buttonPrimary,
                      styles.buttonMedium,
                      (!uploadButtonActivated || fileUploadSuccesfull) && styles.buttonDisabled,
                    )}
                    onClick={async () => {
                      if (folderCreated === false) {
                        setFolderCreate(true);
                        let subFolderId = await getBelegeSubFolderId(
                          folderData.groupId,
                          folderData.belFolderId,
                          convertDateToFolderName(taxDate),
                        );

                        if (subFolderId == null) {
                          const belegeFolderData = {
                            description: '',
                            groupId: folderData.groupId,
                            name: convertDateToFolderName(taxDate),
                            parentFolderId: folderData.belFolderId,
                            publicFolder: true,
                          };
                          if (folderData.folderId == null) {
                            await createFolder(belegeFolderData);
                          }
                          subFolderId = await getBelegeSubFolderId(
                            folderData.groupId,
                            folderData.belFolderId,
                            convertDateToFolderName(taxDate),
                          );
                          setFolderCreate(false);
                        }
                        setFolderData(prevState => {
                          prevState.folderId = subFolderId;
                          return prevState;
                        });
                        forceUpdate();
                        inputRef.current.click();
                      }
                    }}
                  >
                    Speichern
                  </div>
                ) : (
                  <Loader />
                )}

                <div
                  style={{ display: 'none' }}
                  ref={inputRef}
                  onClick={() => {
                    uploadFileHandler(files);
                    setFolderData(prevState => {
                      prevState.folderId = null;
                      return prevState;
                    });
                    forceUpdate();
                  }}
                />
              </div>
            </Row>
          </Col>
        </Wrapper>
      </KitaPanelWrapper>
    </div>
  );
}
