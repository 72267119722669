import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import deLocale from '@fullcalendar/core/locales/de';

const calendarConfig = {
  defaultView: 'dayGridMonth',
  headerToolbar: {
    start: 'prev,next today',
    center: 'title',
    end: 'dayGridMonth,timeGridWeek,timeGridDay',
  },
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  firstDay: 1,
  selectable: true,
  displayEventTime: true,
  editable: true,
  eventLimit: true,
  contentHeight: 'auto',
  eventLimitClick: 'popover',
  showNonCurrentDates: false,
  dayMaxEventRows: 6,
  locales: [deLocale],
};

export default calendarConfig;
