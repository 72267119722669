import {
  SET_TIMERANGE_ABSENCE,
  SET_TIMERANGE_ABSENCE_LOADING,
  SET_TIMERANGE_ABSENCE_END_LOADING,
  DISABLE_TIMERANGE_ABSENCE,
} from 'library/common/commonConstants/timerangeAbsenceConstants';

const initialState = {
  timeRangeAbsenceInfo: {
    timeRangeAbsence: null,
    isLoading: false,
  },
};

export default function TimeRangeAbsenceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TIMERANGE_ABSENCE:
      return {
        ...state,
        timeRangeAbsenceInfo: {
          timeRangeAbsence: action.payload,
          isLoading: false,
        },
      };
    case SET_TIMERANGE_ABSENCE_LOADING:
      return {
        ...state,
        timeRangeAbsenceInfo: { ...state.timeRangeAbsenceInfo, isLoading: true },
      };
    case SET_TIMERANGE_ABSENCE_END_LOADING:
      return {
        ...state,
        timeRangeAbsenceInfo: { ...state.timeRangeAbsenceInfo, isLoading: false },
      };
    case DISABLE_TIMERANGE_ABSENCE:
      return {
        ...state,
        timeRangeAbsenceInfo: {
          timeRangeAbsence: null,
          isLoading: false,
        },
      };
    default:
      return state;
  }
}
