import React, { useState, useEffect } from 'react';

import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import { getBgwChecklist, postBgwChecklist } from 'library/api/bgwChecklist';
import Loader from 'library/common/commonComponents/Loader';
import inputTableStyle from './inputtable.module.scss';

export default function InputTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [bgwChecklistData, setBgwChecklistData] = useState({
    occuptionalSafetyName: '',
    occuptionalSafetyDate: '',
    occuptionalSafetyPerformer: '',
    securityOfficerName: '',
    securityOfficerDate: '',
    securityOfficerSeminarPerformed: '',
    firstResponderName: '',
    instructionName: '',
    instructionDate: '',
    riskAssesmentName: '',
    riskAssesmentDate: '',
    electronicalDevicesName: '',
    electronicalDevicesDate: '',
    electronicalDevicesInitiator: '',
    playgroundCheckName: '',
    playgroundCheckDate: '',
    playgroundCheckInitiator: '',
  });

  const fetchBgwChecklistData = () => {
    setIsLoading(true);
    getBgwChecklist()
      .then(data => {
        const newData = {};
        if (data.data != null) {
          for (const [key, val] of Object.entries(data.data)) {
            newData[key] = val;
            if (val === null) {
              newData[key] = '';
            }
          }
          setBgwChecklistData(newData);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveChecklist = async () => {
    await postBgwChecklist(bgwChecklistData);
  };

  const handleInputChange = (e, property) => {
    const text = e.target.value;
    setBgwChecklistData(prev => {
      return { ...prev, [property]: text };
    });
  };

  useEffect(() => {
    fetchBgwChecklistData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid',
              borderColor: '#ededed',
              width: '240px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                backgroundColor: '#ededed',
                padding: '5px',
                minHeight: '50px',
                fontWeight: 'bold',
              }}
            >
              Fachkraft für Arbeitssicherheit und Betriebsarzt/Betriebsärztin
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Name:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.occuptionalSafetyName}
                onChange={e => handleInputChange(e, 'occuptionalSafetyName')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Erledigt durch:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.occuptionalSafetyPerformer}
                onChange={e => handleInputChange(e, 'occuptionalSafetyPerformer')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Erledigt am:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.occuptionalSafetyDate}
                onChange={e => handleInputChange(e, 'occuptionalSafetyDate')}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid',
              borderColor: '#ededed',
              width: '240px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                backgroundColor: '#ededed',
                padding: '5px',
                minHeight: '50px',
                fontWeight: 'bold',
              }}
            >
              Sicherheitsbeauftragte(r)
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Name:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.securityOfficerName}
                onChange={e => handleInputChange(e, 'securityOfficerName')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Seminar durchgeführt:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.securityOfficerSeminarPerformed}
                onChange={e => handleInputChange(e, 'securityOfficerSeminarPerformed')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Bestellt am:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.securityOfficerDate}
                onChange={e => handleInputChange(e, 'securityOfficerDate')}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid',
              borderColor: '#ededed',
              width: '240px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                backgroundColor: '#ededed',
                padding: '5px',
                minHeight: '50px',
                fontWeight: 'bold',
              }}
            >
              Ersthelfer(innen)
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Name:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.firstResponderName}
                onChange={e => handleInputChange(e, 'firstResponderName')}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid',
              borderColor: '#ededed',
              width: '240px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                backgroundColor: '#ededed',
                padding: '5px',
                minHeight: '50px',
                fontWeight: 'bold',
              }}
            >
              Unterweisung
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Namen:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.instructionName}
                onChange={e => handleInputChange(e, 'instructionName')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Durchgeführt am:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.instructionDate}
                onChange={e => handleInputChange(e, 'instructionDate')}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid',
              borderColor: '#ededed',
              width: '240px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                backgroundColor: '#ededed',
                padding: '5px',
                minHeight: '50px',
                fontWeight: 'bold',
              }}
            >
              Gefährdungsbeurteilung
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Erstellt durch:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.riskAssesmentName}
                onChange={e => handleInputChange(e, 'riskAssesmentName')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Erstellt am:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.riskAssesmentDate}
                onChange={e => handleInputChange(e, 'riskAssesmentDate')}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid',
              borderColor: '#ededed',
              width: '240px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                backgroundColor: '#ededed',
                padding: '5px',
                minHeight: '50px',
                fontWeight: 'bold',
              }}
            >
              Elektrische Geräte
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Geprüft am:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.electronicalDevicesDate}
                onChange={e => handleInputChange(e, 'electronicalDevicesDate')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Geprüft durch:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.electronicalDevicesName}
                onChange={e => handleInputChange(e, 'electronicalDevicesName')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Veranlasst durch: </label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.electronicalDevicesInitiator}
                onChange={e => handleInputChange(e, 'electronicalDevicesInitiator')}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid',
              borderColor: '#ededed',
              width: '240px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                backgroundColor: '#ededed',
                padding: '5px',
                minHeight: '50px',
                fontWeight: 'bold',
              }}
            >
              Spielplatzprüfung
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Geprüft am: </label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.playgroundCheckDate}
                onChange={e => handleInputChange(e, 'playgroundCheckDate')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Geprüft durch:</label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.playgroundCheckName}
                onChange={e => handleInputChange(e, 'playgroundCheckName')}
              />
            </div>
            <div style={{ padding: '5px' }}>
              <div className={inputTableStyle.cellHeader}>
                <label>Veranlasst durch: </label>
              </div>
              <Input
                type='text'
                value={bgwChecklistData.playgroundCheckInitiator}
                onChange={e => handleInputChange(e, 'playgroundCheckInitiator')}
              />
            </div>
          </div>
        </div>
      )}
      <br />
      <Button type='primary' onClick={() => handleSaveChecklist()}>
        Speichern
      </Button>
    </>
  );
}
