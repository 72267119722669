import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { isAwoWW } from 'library/api/tenantConfig';
import { getToken } from 'library/utilities/token';
import awoimmerdabeilogo from 'resources/images/awoww/awoimmerdabeilogo.png';

import styles from './listItemWithLogoAvatar.module.scss';

function addTkval(urlString) {
  const url = new URL(urlString);

  const token = getToken();

  url.searchParams.set('tkval', encodeURIComponent(token.tkval));

  return url.toString();
}

export default function ListItemWithLogoAvatar({ letter, backgroundColor, groupInfo, className, route }) {

  const [groupLogoUrlGiven, setGroupLogoUrlGiven] = useState(false);

  useEffect(() => {
    if(route) {
      if(groupInfo && groupInfo.logoUrl && groupInfo.groupLogoUrl) {
        setGroupLogoUrlGiven(true);
      }
    } else {
      if(groupInfo && groupInfo.logoUrl) {
        setGroupLogoUrlGiven(true);
      }
    }
  }, [])

  if (isAwoWW() && groupInfo.globalPublicGroup) {
    return (
      <div className={styles.linkAvatarWithImage}>
        <div className={cn(styles.linkAvatarWithImageContainer, className)}>
          <img
            src={awoimmerdabeilogo}
            alt='AWO Public Logo'
            className={styles.logoImg}
          />
        </div>
      </div>
    )
  }

  return groupLogoUrlGiven ? (
    <div className={styles.linkAvatarWithImage}>
      <div className={cn(styles.linkAvatarWithImageContainer, className)}>
        <img
          src={`${groupInfo.logoUrl}?t=${Date.now()}${addTkval(groupInfo.logoUrl)}`}
          alt='logo'
          className={styles.logoImg}
        />
      </div>
    </div>
  ) : (
    <div className={cn(styles.linkAvatar, className)} style={{ backgroundColor }}>
      {letter}
    </div>
  );
}
