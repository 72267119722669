import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import FoodOrder from '../../library/common/commonComponents/FoodOrder';

export default connect(
  state => ({
    user: state.userReducer,
    activeKita: state.kitaReducer.activeKita,
    groupInfo: state.groupReducer,
  }),
  { showBottomNotification },
)(FoodOrder);
