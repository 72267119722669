import React, { useState, useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cn from 'classnames';

import { GroupContext } from 'modules/Group/groupModule';
import Card from 'library/common/commonComponents/Card';
import Button from 'library/common/commonComponents/Buttons/Button';
import Select from 'library/common/commonComponents/Inputs/Select';
import HelpText from 'library/common/commonComponents/HelpText';
import { setGroupGallerySnippet } from 'library/api/groups';

import styles from './gallerySettings.module.scss';

export default function GallerySettings({
  goBack,
  groupInfo,
  patchGroup,
  galleries,
  showBottomNotification,
}) {
  const { t } = useTranslation();

  const options = getOptionsFromGalleries(galleries, t);

  const { reloadGroupInfo } = useContext(GroupContext);
  // eslint-disable-next-line
  useEffect(() => () => reloadGroupInfo(), []);

  const [selectedGallery, setSelectedGallery] = useState(
    groupInfo.gallerySnippet.galleryId
      ? options.find(
          ({ value, isLatest }) =>
            value === groupInfo.gallerySnippet.galleryId &&
            isLatest === groupInfo.gallerySnippet.latestSelected,
        )
      : options[0],
  );

  const saveGroupGallerySnippet = () =>
    saveGroupGallerySnippetHandler({
      groupId: groupInfo.groupId,
      galleryId: selectedGallery.value,
      files: selectedGallery.files,
      showBottomNotification,
      t,
      patchGroup,
      isLatest: selectedGallery.isLatest,
    });
  return (
    <Card className={styles.container} title={<Trans i18nKey='Gallery.Gallery settings' />}>
      {galleries.length === 0 && (
        <div className={styles.noGalleries}>
          There are no galleries available for this space. In order to configure the gallery
          snippet, please create a new gallery
        </div>
      )}
      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={goBack}>
          <Button size='sm'>
            <i className={cn('fa fa-arrow-left', styles.buttonIcon)} />{' '}
            {t('Gallery.Back to overview')}
          </Button>
        </div>
      </div>

      {galleries.length > 0 && (
        <div className={styles.wrapper}>
          <div className={styles.title}>{t('Gallery.Choose snippet gallery')}</div>
          <Select options={options} onSelect={setSelectedGallery} selected={selectedGallery} />
          <HelpText className={styles.selectText}>
            {t('Gallery.In case the gallery is not visible')}
          </HelpText>
          <Button type='primary' onClick={saveGroupGallerySnippet}>
            {t('Post.Save')}
          </Button>
        </div>
      )}
    </Card>
  );
}

export function saveGroupGallerySnippetHandler({
  groupId,
  galleryId,
  showBottomNotification,
  t,
  patchGroup,
  files,
  isLatest,
}) {
  setGroupGallerySnippet({ groupId, galleryId, isLatest });
  patchGroup({ gallerySnippet: { galleryId, files, latestSelected: isLatest } });
  showBottomNotification(t('BottomNotifications.Saved'));
}

export function getOptionsFromGalleries(galleries, t) {
  const latestGallery = galleries[galleries.length - 1];
  return galleries.length > 0
    ? [
        { value: 0, label: t('Gallery.Dont show snippet'), isLatest: false },
        {
          value: latestGallery.id,
          label: `${t('Gallery.Latest gallery')}${getGalleryLabel(latestGallery, t)}`,
          isLatest: true,
        },
        ...galleries.map(gallery => ({
          value: gallery.id,
          label: getGalleryLabel(gallery, t),
          isLatest: false,
        })),
      ]
    : [];
}

export function getGalleryLabel(gallery, t) {
  return `${gallery.galleryName} (${t(
    gallery.privateGallery ? 'GroupBody.Security.Private' : 'GroupBody.Security.Public',
  )})`;
}
