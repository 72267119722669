import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './mealItem.module.scss';
import FoodItem from './FoodItem';
import Button from '../../../../../Buttons/Button';
import Checkbox from '../../../../../Checkbox';
import { isChangeFoodMenuAllowed } from '../../../../../../../utilities/foodOrder';

export default function MealItem({
  id,
  day,
  date,
  foods,
  initialFood,
  dispatchMealItems,
  isActive,
  showBottomNotification,
}) {
  const { t } = useTranslation();
  const [firstRender, setFirstRender] = useState(true);
  const addFood = () => {
    if (isChangeFoodMenuAllowed(date)) {
      dispatchMealItems({
        type: 'addFood',
        mealId: id,
        food: { ...initialFood, id: Math.random() },
      });
      setIsActive(id, true);
    } else {
      showBottomNotification(t('FoodOrder.The menu has already been served'), {
        isFail: true,
      });
    }
  };

  const updateFood = food => {
    if (isChangeFoodMenuAllowed(date)) {
      dispatchMealItems({ type: 'updateFood', mealId: id, food });
    } else {
      if (!firstRender) {
        showBottomNotification(t('FoodOrder.The menu has already been served'), {
          isFail: true,
        });
      }
    }
  };

  const removeFood = foodId => {
    if (isChangeFoodMenuAllowed(date)) {
      dispatchMealItems({ type: 'removeFood', mealId: id, foodId });
    } else {
      showBottomNotification(t('FoodOrder.The menu has already been served'), {
        isFail: true,
      });
    }
  };

  useEffect(() => {
    setFirstRender(false);
  }, []);

  const setIsActive = (mealId, active) => {
    if (isChangeFoodMenuAllowed(date)) {
      dispatchMealItems({ type: 'setIsActive', mealId, active });
    } else {
      showBottomNotification(t('FoodOrder.The menu has already been served'), {
        isFail: true,
      });
    }
  };

  return (
    <div className={styles.mealItem}>
      <div className={styles.mealItemHeader}>
        <strong>
          {day} - {date}
        </strong>
        <Checkbox
          className={styles.isActiveCheckbox}
          name={t('FoodOrder.meal takes place')}
          isChecked={isActive}
          onChange={() => {
            setIsActive(id, !isActive);
          }}
        />
      </div>
      <div className={styles.mealItemBody}>
        {foods.length > 0 ? (
          <>
            {foods.map(food => (
              <>
                <br />
                <FoodItem
                  id={food.id}
                  name={food.name}
                  description={food.description}
                  price={food.price}
                  removeFood={removeFood}
                  updateFood={updateFood}
                />
                <br />
                <hr className={styles.hr} />
              </>
            ))}
          </>
        ) : (
          t('FoodOrder.mealItem.empty')
        )}
        <p>
          <Button type='secondary' className={styles.addButton} onClick={() => addFood()}>
            <i className='fa fa-plus' /> {t('FoodOrder.Add meal')}
          </Button>
        </p>
      </div>
    </div>
  );
}
