import React from 'react';
import {
  getSiblingsDiscount,
  getSiblingsDiscountActive, getSubsidyValue,
  getTableParentalContributionPriceByFacilityTypeAndRow, SUBSIDY_AMOUNT,
  tableParentalContributionCalculateTotalPriceForRow
} from "../../../../library/utilities/forms";
import style from '../../../Administration/administrationFrames/FormsFrame/FormsFrameFrames/FormsEditor/FormsEditor.module.scss';
import Checkbox from "../../../../library/common/commonComponents/Checkbox";

export default function ParentalContributionTable({ isAdmin, facilityType, relevantRow, formFieldId, submitData, setSubmitData }) {

  const priceRow0 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 0);
  const priceRow1 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 1);
  const priceRow2 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 2);
  const priceRow3 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 3);
  const priceRow4 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 4);
  const priceRow5 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 5);
  const priceRow6 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 6);
  const priceRow7 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 7);
  const priceRow8 = getTableParentalContributionPriceByFacilityTypeAndRow(facilityType, 8);

  return (
    <div>
      <table className={style.table}>
        <tr>
          <th>Durchschnittliche tägliche Buchungszeit</th>
          <th colSpan='3'>Elternbeitrag pro Monat</th>
          <th style={{ minWidth: '100px' }}>Zu zahlender Beitrag pro Monat</th>
        </tr>
        <tr>
          <td />
          <td style={{ minWidth: '100px' }}>
            Beiträge{' '}
            {facilityType === 'krippe'
              ? 'Krippe'
              : facilityType === 'hort'
                ? 'Hort'
                : 'Kindergarten'}
          </td>
          <td>
            Ermäßigung für Geschwisterkinder <br />
            <Checkbox
              name='trifft zu'
              isChecked={getSiblingsDiscountActive(submitData, formFieldId)}
              onChange={() => {
                setSubmitData({
                  ...submitData,
                  formFields: {
                    ...submitData.formFields,
                    [`${formFieldId}-siblingsDiscount`]: getSiblingsDiscountActive(submitData, formFieldId) ? "false" : "true",
                  },
                });
              }}
              isDisabled={!isAdmin}
            />
          </td>
          <td>
            Elternbeitragszuschuss gemäß Art. 23 BayKiBiG **) <br />
            <Checkbox
              name='trifft zu'
              isChecked={getSubsidyValue(submitData, formFieldId) > 0}
              onChange={() => {
                setSubmitData({
                  ...submitData,
                  formFields: {
                    ...submitData.formFields,
                    [`${formFieldId}-subsidy`]: getSubsidyValue(submitData, formFieldId) === "0" ? `${SUBSIDY_AMOUNT}` : '0',
                  },
                });
              }}
              isDisabled={!isAdmin}
            />
          </td>
          <td />
        </tr>
        <tr>
          <td>> 1 - 2 *)</td>
          <td>{priceRow0 ? `${priceRow0} €` : ''}</td>
          <td>{relevantRow === 0 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 0 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 0 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow0, submitData, formFieldId)} €` : ''}</td>
        </tr>
        <tr>
          <td>> 2 - 3 *)</td>
          <td>{priceRow1 ? `${priceRow1} €` : ''}</td>
          <td>{relevantRow === 1 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 1 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 1 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow1, submitData, formFieldId)} €` : ''}</td>
        </tr>
        <tr>
          <td>> 3 - 4 *)</td>
          <td>{priceRow2 ? `${priceRow2} €` : ''}</td>
          <td>{relevantRow === 2 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 2 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 2 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow2, submitData, formFieldId)} €` : ''}</td>
        </tr>
        <tr>
          <td>> 4 - 5</td>
          <td>{priceRow3 ? `${priceRow3} €` : ''}</td>
          <td>{relevantRow === 3 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 3 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 3 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow3, submitData, formFieldId)} €` : ''}</td>
        </tr>
        <tr>
          <td>> 5 - 6</td>
          <td>{priceRow4 ? `${priceRow4} €` : ''}</td>
          <td>{relevantRow === 4 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 4 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 4 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow4, submitData, formFieldId)} €` : ''}</td>
        </tr>
        <tr>
          <td>> 6 - 7</td>
          <td>{priceRow5 ? `${priceRow5} €` : ''}</td>
          <td>{relevantRow === 5 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 5 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 5 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow5, submitData, formFieldId)} €` : ''}</td>
        </tr>
        <tr>
          <td>> 7 - 8</td>
          <td>{priceRow6 ? `${priceRow6} €` : ''}</td>
          <td>{relevantRow === 6 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 6 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 6 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow6, submitData, formFieldId)} €` : ''}</td>
        </tr>
        <tr>
          <td>> 8 - 9</td>
          <td>{priceRow7 ? `${priceRow7} €` : ''}</td>
          <td>{relevantRow === 7 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 7 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 7 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow7, submitData, formFieldId)} €` : ''}</td>
        </tr>
        <tr>
          <td>> 9 - 10</td>
          <td>{priceRow8 ? `${priceRow8} €` : ''}</td>
          <td>{relevantRow === 8 ? `${getSiblingsDiscountActive(submitData, formFieldId) ? getSiblingsDiscount() : 0} €` : ''}</td>
          <td>{relevantRow === 8 ? `${getSubsidyValue(submitData, formFieldId)} €` : ''}</td>
          <td>{relevantRow === 8 ? `${tableParentalContributionCalculateTotalPriceForRow(priceRow8, submitData, formFieldId)} €` : ''}</td>
        </tr>
      </table>
      <p>
        *) Diese Kategorien sind für Kinder ab 3 Jahren bis zur Einschulung nicht förderfähig.
      </p>
      <p>
        **) Der in Art. 23 BayKiBiG geregelte Elternbeitragszuschuss wird an die Eltern
        weitergegeben. Der aufgeführte Elternbeitrag verringert sich dementsprechend: "Der
        Zuschuss beträgt 100 Euro pro Monat und wird für die Zeit vom 1. September des
        Kalenderjahres, in dem das Kind das dritte Lebensjahr vollendet, bis zum Schuleintritt
        gewährt." (Art. 23 Abs. 3 Satz 2 BayKiBiG)
      </p>
    </div>
  );
}
