import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './usersFrameUserGroupsPopup.module.scss';
import Popup from '../../../../../../../../../library/common/commonComponents/Popups/Popup';
import Button from '../../../../../../../../../library/common/commonComponents/Buttons/Button';
import { getGroupsByUserIdAndKitaId } from '../../../../../../../../../library/api/user';
import Loader from '../../../../../../../../../library/common/commonComponents/Loader';
import ListItemWithLogoAvatar from '../../../../../../../../../library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import { getLetterInLogo } from '../../../../../../../../../library/utilities/groups';
import { isAwoWW } from 'library/api/tenantConfig';

export default function UsersFrameUserGroupsPopup({ user, selectedKita }) {
  const { t } = useTranslation();
  const isAWO = isAwoWW();
  const [isOpen, setIsOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [awoGroups, setAWOGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const popupTitle = isAWO ? `${user.user.firstName} ${user.user.lastName}` : t('UsersFrame.User.Groups');
  const closePopup = () => {
    setIsOpen(false);
  };

  const groupByKey = (array, key) => {
    return array.reduce((acc, item) => {
      const groupKey = item[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    }, {});
  }


  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getGroupsByUserIdAndKitaId(user.user.id, selectedKita.value, isAWO).then(res => {
        // filter out groups depending on the current tenant
        const filteredGroups = window.location.hostname.toLowerCase().includes('stramplerbande')
          ? res.data
          : res.data.filter(group => group.groupName !== 'Public group');

        if (isAWO) {
          const newFilterGroups = filteredGroups.filter(x=>x.kitaName);
          const groupByGroup =  groupByKey(newFilterGroups, 'kitaName');
          setAWOGroups(groupByGroup)
        }

        setGroups(filteredGroups);
        setIsLoading(false);
      });
    }
  }, [isOpen]);
  const redirect = groupId => {
    window.location.href = '/groups/' + groupId;
  };
  return (
    <div>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t('UsersFrame.User.Show groups')}
      </Button>
      <Popup
        isOpened={isOpen}
        closePopup={closePopup}
        size='small'
        header={<div> {popupTitle}</div>}
        footer={
          <div>
            <Button type='secondary' onClick={() => closePopup()}>
              {t('CreateGroup.Back')}
            </Button>
          </div>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {
              !isAWO && (
                <div className={styles.groupHint}>{t('UsersFrame.GroupsText')}:</div>
              )
            }
            {
              !isAWO ? (
                <div className={styles.groupContainer}>
                  {groups.map(group => (
                    <div className={styles.groupElement} onClick={() => redirect(group.id)}>
                      <ListItemWithLogoAvatar
                        groupInfo={{
                          logoUrl: group.groupLogoUrl,
                          groupLogoXCoordinate: group.groupLogoXCoordinate,
                          groupLogoYCoordinate: group.groupLogoYCoordinate,
                          groupLogoWidth: group.groupLogoWidth,
                          groupLogoAspect: group.groupLogoAspect,
                        }}
                        letter={getLetterInLogo(group.groupName)}
                        backgroundColor={group.colourCode}
                        className={styles.groupLogo}
                      />
                      <div className={styles.groupName}>{group.groupName}</div>
                    </div>
                  ))}
                </div>
              ): (
                <div className={styles.awoGroupContainer}>
                  {
                    Object.keys(awoGroups).map(key=> (
                      <div>
                        <div className={styles.kitaNameText}>{key}</div>
                        <div className={styles.groupItems}>
                          {awoGroups[key].map(group => (
                            <div className={styles.groupElement} onClick={() => redirect(group.id)}>
                              <ListItemWithLogoAvatar
                                groupInfo={{
                                  logoUrl: group.groupLogoUrl,
                                  groupLogoXCoordinate: group.groupLogoXCoordinate,
                                  groupLogoYCoordinate: group.groupLogoYCoordinate,
                                  groupLogoWidth: group.groupLogoWidth,
                                  groupLogoAspect: group.groupLogoAspect,
                                }}
                                letter={getLetterInLogo(group.groupName)}
                                backgroundColor={group.colourCode}
                                className={styles.groupLogo}
                              />
                              <div className={styles.groupName}>{group.groupName} - {group.isGroupAdmin ? t('GroupPanel.Administrators'): t('GroupPanel.Members')} - ({group.userRole === 'Administrator' ? t('Administration.UserSection.Kita admin') : group.userRole === 'Employee' ? t('UserEditingAccount.Employee'): t('Administration.UserSection.Kita user')})</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }

          </div>
        )}
      </Popup>
    </div>
  );
}
