import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Popup from 'library/common/commonComponents/Popups/Popup';

import styles from './deletePopup.module.scss';

export default function DeletePopup({
  isOpened,
  closePopup,
  onDeleteBtnClick,
  headerText,
  bodyText,
  isSubmiting,
}) {
  const { t } = useTranslation();
  const handleClick = async () => {
    if (isSubmiting) {
      return;
    }
    await onDeleteBtnClick();
    closePopup();
  };

  return (
    <Popup
      size='extraSmall'
      isOpened={isOpened}
      closePopup={closePopup}
      header={headerText}
      body={<div className={styles.popupText}>{bodyText}</div>}
      footer={
        <div className={styles.popupBtns}>
          <Button onClick={closePopup}>{t('Popup.Cancel')}</Button>
          <ButtonWithLoader onClick={handleClick} type='primary' isLoading={isSubmiting}>
            {t('Popup.Delete')}
          </ButtonWithLoader>
        </div>
      }
    />
  );
}
