import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Storage from 'library/utilities/storage';
import { getTranslations } from 'library/api/settings';

const locale = Storage.getItem('locale');
if (!locale) {
  Storage.setItem('locale', 'de');
}

const lng = locale || Storage.getItem('locale');

export default async function() {
  let osOK = true;
  await getTranslations(lng)
    .then(res => {
      i18n.use(initReactI18next).init({
        lng,
        resources: {
          [lng]: { translation: res.data },
        },

        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });
    })
    .catch(() => {
      osOK = false;
    });

  return osOK;
}
