import React from 'react';


export default function HimmelsAppConsentText({ activeKita }) {

  return (<>
    <b>Einwilligungserklärung zur Nutzung des sozialen Netzwerks Himmels.App</b>
    <br />
    <br />
    <p>
      Die „{activeKita.kitaName}“ stellt den Gemeindemitgliedern in Zusammenarbeit mit der Link IT isi GmbH aus Nürnberg ein Soziales Netzwerk zur Information und Kommunikation zur Verfügung. Für die Link IT isi GmbH genießt der Schutz Ihrer personenbezogenen Daten höchste Priorität. Ihre Daten gehören nicht zum Geschäftsmodell und Ihre Daten werden nur entsprechend der für den Zweck unseres sozialen Netzwerks erforderlichen Maße verarbeitet. Dabei halten wir uns stets an gesetzliche Grundlagen – insbesondere an die Anforderungen der Datenschutzgrundverordnung.
    </p>
    <p>
      In dieser Einwilligungserklärung informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten und über Ihre Rechte im Rahmen des Online-Angebots. Das Netzwerk wird in zertifizierten deutschen Rechenzentren betrieben, welche redundant ausgelegt sind und somit für eine hohe Stabilität und Sicherheit sorgen.
    </p>
    <br />

    <br />
    <p>
      Um Ihnen diesen Dienst anbieten zu können, benötigen wir allerdings Ihre Einwilligungserklärung sowie die auf der nachfolgenden Seite genannten personenbezogene Daten. Wir bitten Sie, die Daten zu vervollständigen und die Seite unterschrieben an uns zurückzusenden.
    </p>
    <br />
    <b>Informationspflichten</b>
    <br />
    <b>-	Erhebung von Daten bei der betroffenen Person Art. 13 DSGVO</b>
    <br />
    <br />
    <p>
      <b>1. Bezeichnung der Verarbeitungstätigkeit</b>
    </p>
    <br />
    <p>
      <b>Himmels.App</b>
    </p>
    <br />
    <p>
      <b>2. Name und Kontaktdaten des Verantwortlichen</b>
    </p>
    <br />
    <p>
      <b>Verantwortliche Stelle für die Datenerhebung:</b>
    </p>
    <br />
    <table>
      <tr>
        <td>
          <b>Name der Einrichtung:</b>
        </td>
        <td>{activeKita.kitaName}</td>
      </tr>
      <tr>
        <td>
          <b>Adresse der Einrichtung:</b>
        </td>
        <td>{`${activeKita.streetName} ${activeKita.houseNo}`}</td>
      </tr>
      <tr>
        <td />
        <td>{`${activeKita.zipCode} ${activeKita.city}`}</td>
      </tr>
      <tr>
        <td>
          <b>Telefon:</b>
        </td>
        <td>{activeKita.phoneNo}</td>
      </tr>
      <tr>
        <td>
          <b>E-Mail:</b>
        </td>
        <td>{activeKita.email}</td>
      </tr>
    </table>
    <br />
    <br />
    <p>
      <b>3. Kontaktdaten des Datenschutzbeauftragten</b>
    </p>
    <br />
    <table>
      <tr>
        <td>
          <b>Name:</b>
        </td>
        <td>{activeKita.dataProcessingName}</td>
      </tr>
      <tr>
        <td>
          <b>Telefon:</b>
        </td>
        <td>{activeKita.dataProcessingPhone}</td>
      </tr>
      <tr>
        <td>
          <b>E-Mail:</b>
        </td>
        <td>{activeKita.dataProcessingEmail}</td>
      </tr>
    </table>
    <br />
    <br />
    <p>
      <b>4. Zwecke und Rechtsgrundlagen der Datenverarbeitung</b>
    </p>
    <br />
    <p>
      Wir verarbeiten personenbezogene Daten unter Beachtung der Bestimmungen der EU-Datenschutz-Grundverordnung (EU-DSGVO), des Bayerischen Datenschutzgesetzes sowie aller weiteren maßgeblichen Gesetze.
    </p>
    <br />
    <p>
      Die Verarbeitung personenbezogener Daten erfolgt auf Basis Ihrer erteilten Einwilligungserklärung und dient ausschließlich dem Zweck der oben benannten Verarbeitungstätigkeit.
    </p>
    <br />
    <p>
      Sämtliche in das Netzwerk eingestellten Inhalte werden auf freiwilliger Basis und entsprechend der ergänzenden Nutzungshinweise eingestellt.
    </p>
    <br />
    <p>
      Die Rechtsgrundlage der Verarbeitung Ihrer personenbezogenen Daten richtet sich nach
      Art. 6 Abs. 1 lit. a DS-GVO.

    </p>
    <br />
    <p>
      <b>5. Empfänger oder Kategorien von Empfängern der personenbezogenen Daten</b>
    </p>
    <br />
    <p>
      Personenbezogene Daten sowie weitere in das Netzwerk eingestellte Inhalte werden seitens der Verantwortlichen Stelle in keiner Weise weitergegeben.
    </p>
    <br />
    <p>
      <b>6. Übermittlung von personenbezogenen Daten an ein Drittland</b>
    </p>
    <br />
    <p>
      Eine Übermittlung der durch uns erhobenen personenbezogenen Daten an ein Drittland erfolgt
      nicht.
    </p>
    <br />
    <p>
      <b>7. Dauer der Speicherung der personenbezogenen Daten</b>
    </p>
    <br />
    <p>
      Die mit Ihrer Einwilligungserklärung erhobenen personenbezogenen Daten werden mit Widerruf
      Ihrer Einwilligung bzw. mit Beendigung der Nutzung des Dienstes gelöscht. Sobald die
      Einrichtung dauerhaft verlassen wird, werden die Daten des Nutzers sowie der Account des
      Nutzers gelöscht. Inhaltsdaten werden nach 2 Jahren automatisiert gelöscht.
    </p>
    <br />
    <p>
      <b>8. Betroffenenrechte</b>
    </p>
    <br />
    <p>Nach der Datenschutz-Grundverordnung stehen Ihnen folgende Rechte zu:</p>
    <br />
    <ul>
      <li>
        Werden Ihre personenbezogenen Daten verarbeitet, so besteht das Recht, Auskunft über die zu Ihrer Person gespeicherten Daten zu erhalten (Art. 15 DSGVO).
      </li>
      <li>
        Sollten unrichtige personenbezogene Daten verarbeitet werden, steht das Recht auf Berichtigung zu (Art. 16 DSGVO).
      </li>
      <li>
        Liegen die gesetzlichen Voraussetzungen vor, so kann die Löschung oder Einschränkung der Verarbeitung verlangt sowie Widerspruch gegen die Verarbeitung eingelegt werden (Art. 17, 18 und 21 DSGVO).
      </li>
      <li>
        Mit Einwilligung in die Datenverarbeitung oder sofern ein Vertrag zur Datenverarbeitung besteht und die Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird, steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu (Art. 20 DSGVO).
      </li>
      <li>
        Sollten Sie von oben genannten Rechten Gebrauch machen, prüft die Seelsorgeeinheit, ob die gesetzlichen Voraussetzungen hierfür erfüllt sind.
      </li>
      <li>
        Weiterhin besteht ein Beschwerderecht beim Bayerischen Landesbeauftragten für den Datenschutz.
      </li>
      <li>
        Der Bayerische Landesbeauftragte für den Datenschutz <br />
        Postfach 22 12 19 <br />
        80502 München
      </li>
    </ul>
    <br />
    <p>
      <b>9. Widerrufsrecht bei Einwilligung</b>
    </p>
    <br />
    <p>
      Die Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten durch eine entsprechende Erklärung kann jederzeit für die Zukunft widerrufen werden. Gleiches gilt für freiwillig zur Verfügung gestellte personenbezogene Daten. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung aufgrund der Einwilligung oder freiwilligen Angabe, wird durch diesen Einspruch nicht berührt.
    </p>
    <br />
    <p>
      Mit Widerruf der Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten können ggf.
      Leistungen für die wir diese Daten benötigen nicht durchgeführt werden. Den Widerruf Ihrer Einwilligung richten Sie bitte schriftlich an o.g. Postadresse.
    </p>
    <br />
    <p>
      <b>10. Pflicht zur Bereitstellung der Daten</b>
    </p>
    <br />
    <p>
      Ohne Angabe Ihrer personenbezogenen Daten kann die Nutzung der Anwendung nicht angeboten
      werden.
    </p>
    <br />
    <p>
      <b>Ergänzende Nutzungshinweise</b>
    </p>
    <br />
    <p>
      <b>
        Allgemeine Pflichten des Nutzers und besondere Bestimmungen für die Nutzung der
        Anwendung
      </b>
    </p>
    <br />
    <p>
      Der Nutzer ist verpflichtet, bei der Nutzung der Himmels.App sowie der Inhalte geltendes Recht sowie alle Rechte Dritter zu beachten. Es ist dem Nutzer insbesondere untersagt:
    </p>
    <br />
    <li>
      Bild- und Videodateien, welche auf Himmels.App veröffentlicht werden, dürfen nur zum eigenen Gebrauch gespeichert werden und nicht weiterverbreitet werden.
    </li>
    <li>
      Verwendung beleidigender oder verleumderischer Inhalte, unabhängig davon, ob diese Inhalte andere Nutzer, Himmels.App Mitarbeiter oder andere Personen oder Unternehmen betreffen.
    </li>
    <li>
      Verwendung pornografischer, gewaltverherrlichender, missbräuchlicher, sittenwidriger oder Jugendschutzgesetze verletzende Inhalte oder Bewerbung, Angebot und/oder Vertrieb von pornografischen, gewaltverherrlichenden, missbräuchlichen, sittenwidrigen oder Jugendschutzgesetze verletzende Waren oder Dienstleistungen.
    </li>
    <li>
      Verwendung von gesetzlich (z. B. durch das Urheber-, Marken-, Patent-, Geschmacksmuster- oder Gebrauchsmusterrecht) geschützten Inhalten, ohne dazu berechtigt zu sein.
    </li>
    <li>
      Änderungen bezüglich erziehungsberechtigter Personen sind unmittelbar an die Leitung des Kindergartens zu kommunizieren, insbesondere wenn damit die Sperre eines Accounts einhergeht.
    </li>
    <li>
      Der Account (Benutzername, E-Mail-Adresse, Registrierungs-Code) für den Zugang zum sozialen Netzwerk Himmels.App darf unter keinen Umständen an Dritte Personen weitergegeben werden.
    </li>
    <br />
    <p>
      <b>Folgen bei missbräuchlichem Gebrauch der Himmels.App</b>
    </p>
    <br />
    <p>
      Der Account von Nutzern kann unmittelbar und ohne Angabe von Gründen durch den Gruppenadministrator oder Mitarbeiter der Himmels.App gesperrt werden. Dies gilt insbesondere bei missbräuchlichem Gebrauch der Himmels.App.
    </p>
    <br />
    <br />
  </>)
}