import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Label from 'library/common/commonComponents/Label';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import { importUsers } from 'library/api/user';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import csvExample from 'resources/others/csv-import-example.csv';
import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './usersFrameImportPopup.module.scss';

export default function GeneralImport({
  closePopup,
  showBottomNotification,
  reloadPage,
}) {

  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [dontSendInvitationMail, setDontSendInvitationMail] = useState(false);

  const submitFile = async () => {
    if (!file) {
      return setFileError(t('Administration.Please upload a file'));
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      await importUsers(formData, dontSendInvitationMail);
      showBottomNotification(t('BottomNotifications.Saved'));
      closePopup();
      reloadPage();
    } catch (ex) {
      setFile(null);

      if (ex.response.data.message.includes('Invalid column')) {
        setFileError(t('Administration.File has invalid structure'));
      } else {
        setFileError(ex.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const downloadExample = () => {
    const link = document.createElement('a');
    link.href = csvExample;
    link.setAttribute('download', 'Import-list-example.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.notes}>
        <div className={styles.notesHeader}>
          <strong>{t('Administration.Notes Title')}</strong>
        </div>
        <div className={styles.notesItem}>1) {t('Administration.Note 1')}</div>
        <div className={styles.notesItem}>2) {t('Administration.Note 2')}</div>
        <div className={styles.notesItem}>3) {t('Administration.Note 3')}</div>
      </div>

      <div className={styles.inputGroup}>
        <Label type='input'>
          {t('Administration.You can download file example')}
        </Label>
        <div>
          <Button type='success' size='sm' onClick={downloadExample}>
            {t('Gallery.Dropdown.Download')}
          </Button>
        </div>
      </div>

      <div className={styles.inputGroup}>
        <Label type='input'>{t('Administration.CSV file')}</Label>
        <div className={styles.uploadButtons}>
          <UploadButton
            onChange={files => {
              setFileError('');
              setFile(files[0]);
            }}
            allowedExtensions={['.csv']}
          />
        </div>
        {fileError && <InputErrorMsg errorMsg={fileError} />}
        {file && file.name}
      </div>
      <div className={styles.checkboxContainer}>
        <CheckBox
          className={styles.checkbox}
          name={t('Administration.Do not send Invitation Mail')}
          isChecked={dontSendInvitationMail}
          onChange={() => setDontSendInvitationMail(!dontSendInvitationMail)}
        />
      </div>
      &nbsp;
      <div className={styles.buttonsContainer}>
        <div className={styles.buttonSave}>
          <ButtonWithLoader onClick={submitFile} type='primary' isLoading={isLoading}>
            {t('Calendar.Save')}
          </ButtonWithLoader>
        </div>
        <div className={styles.buttonCancel}>
          <Button onClick={closePopup}>{t('Calendar.Cancel')}</Button>
        </div>
      </div>
    </div>
  )
}