import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8080)}/api`;

export const getAllInstitutionsOfTenant = () => axios.get(`${URL}/getallinstitutionsoftenant`);

export const getAllTenants = () => axios.get(`${URL}/getalltenants`);

export const startInstitutionMigration = (kitaId, tenantId) =>
  axios.post(`${URL}/startinstitutionmigration/${kitaId}/${tenantId}`);

export const startPostMigration = (kitaId, tenantId) =>
  axios.post(`${URL}/startpostmigration/${kitaId}/${tenantId}`);