import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import Profile from '.';

export default connect(
  state => ({
    user: state.userReducer,
    activeKita: state.kitaReducer.activeKita,
  }),
  { showBottomNotification },
)(Profile);
