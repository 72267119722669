import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { transferKitaKidsToGroup } from 'library/api/kita';
import { getGroupsOfKita } from 'library/api/groups';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import ConfirmPopup from 'library/common/commonComponents/Popups/ConfirmPopup';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Select from 'library/common/commonComponents/Inputs/Select';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import styles from '../multiKidSelectionPopup.module.scss';

const MultiKidTransferPopup = (
  {
    activeKita,
    isOpen,
    setOpen,
    selectedKids,
    refresh
  }) => {
  const { t } = useTranslation();

  const [kitaGroups, setKitaGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [kidsToTransfer, setKidsToTransfer] = useState([]);
  const [confirmPopupIsOpened, setConfirmPopupIsOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleKidChange = kid => {
    if (kidsToTransfer.find(item => kid.kidId === item.kidId)) {
      setKidsToTransfer(kidsToTransfer.filter(item => item.kidId !== kid.kidId));
    } else {
      setKidsToTransfer([...kidsToTransfer, kid]);
    }
  };

  const closeKidSelectionPopup = () => {
    setOpen(false);
  };

  const openConfirmPopup = () => {
    setConfirmPopupIsOpened(true);
  };

  const handleConfirmTransferKids = async () => {
    const kidIds = kidsToTransfer.map(kid => kid.kidId);
    await transferKitaKidsToGroup(activeKita.kitaId, selectedGroup.id, kidIds);
    refresh();
  };

  const onSave =  async () => {
    await handleConfirmTransferKids();
    setConfirmPopupIsOpened(false);
    closeKidSelectionPopup();
  };

  useEffect(() => {
    setKidsToTransfer(selectedKids);
  }, [selectedKids]);

  useEffect(() => {
    getGroupsOfKita({
      page: 1,
      sort: { name: 'groupName', direction: 'asc' },
      searchValue: '',
      kitaId: activeKita.kitaId,
      visibility: false,
      onlyPortfolioGroups: false,
    }).then(({ data }) => {
      const groupData = data.content.map(item => {
        return {
          id: item.id,
          label: item.groupName,
          value: item.groupName,
        };
      });
      setKitaGroups(groupData);
    });
  }, [activeKita.kitaId]);

  return (
    <>
      <Popup
        isOpened={isOpen}
        closePopup={closeKidSelectionPopup}
        header={<h3>{`${t('Kid.Select Kids To Transfer')}:`}</h3>}
        body={
          <div>
            <div className={styles.groupList}>
              {selectedKids.map(kid => (
                <div
                  key={kid.kidId}
                  className={styles.groupElement}
                  onClick={() => handleKidChange(kid)}
                >
                  <Checkbox
                    key={kid.kidId}
                    isChecked={kidsToTransfer.findIndex(item => item.kidId === kid.kidId) > -1}
                  />
                  <div className={cn('notranslate', styles.kidAvatar)}>
                    <img
                      style={{ maxWidth: '40px', marginRight: '10px' }}
                      src={kid.groupLogoUrl || defaultAvatar}
                      alt=""
                    />
                    <div className={styles.kidAvatarText}>
                      <div className={styles.name}>
                        <span>
                          {kid.firstName} {kid.lastName}
                        </span>
                        <span className={styles.parent}>
                          {kid.parent1FirstName || ""} {kid.parent1LastName || ""}
                        </span>
                        <span className={styles.parent}>
                          {kid.parent2FirstName || ""} {kid.parent2LastName || ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Select
              placeholder={t('Kids.KidsToTransfer')}
              options={kitaGroups}
              onSelect={setSelectedGroup}
              selected={selectedGroup}
              openedClassName={styles.filtersKitaOpened}
            />
          </div>
        }
        footer={
          <div className={styles.buttonsContainer}>
            <ButtonWithLoader
              onClick={openConfirmPopup}
              isLoading={isSubmitting}
              disabled={selectedGroup === null || kidsToTransfer.length === 0}
            >
              {t('Confirm.Save')}
            </ButtonWithLoader>
            <Button type='primary' onClick={closeKidSelectionPopup}>
              {t('Confirm.Close')}
            </Button>
          </div>
        }
      />
      <ConfirmPopup
        isOpened={confirmPopupIsOpened}
        closePopup={() => setConfirmPopupIsOpened(false)}
        onConfirm={onSave}
        text={t('MultiKidAction.KidDataTransfer')}
      />
    </>
  )
};

export default MultiKidTransferPopup;
