import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

const useCalendarExportEventsGroup = (
  getExportsGroup,
  deleteExportGroup,
  saveExportGroup,
  importFile,
  groupInfo,
) => {
  const { t } = useTranslation();
  const [exportsGroup, setExportsGroup] = useState([]);

  useEffect(() => {
    if (groupInfo !== undefined) {
      getExportsGroup(groupInfo.groupId)
        .then(res => {
          if (res.status === 200) {
            setExportsGroup(res.data || []);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  const updateExportsGroup = (action, uExport) => {
    const exportsCopy = exportsGroup.slice();
    let index;
    switch (action) {
      case 'delete':
        index = exportsCopy.findIndex(exp => exp.id === uExport.id);
        if (index !== -1) {
          exportsCopy.splice(index, 1);
        }
        setExportsGroup(exportsCopy);
        break;

      case 'add':
        setExportsGroup(exportsCopy.concat(uExport));
        break;

      default:
    }
  };

  const onDeleteExportGroup = uExport => {
    return deleteExportGroupRequest(uExport);
  };

  const deleteExportGroupRequest = uExport => {
    return deleteExportGroup(uExport.id)
      .then(res => {
        if (res.status === 200) {
          updateExportsGroup('delete', uExport);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onAddExport = exportData => {
    return saveExportGroup(exportData, groupInfo.groupId)
      .then(res => {
        if (res.status === 200) {
          updateExportsGroup('add', res.data);
        }
      })
      .catch(err => {
        switch (err.status || err.response.status) {
          case 400:
            store.dispatch(
              showBottomNotification(t(err.response.data.message), {
                isFail: true,
              }),
            );
            return {
              success: false,
              error: 'error',
            };
          default:
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  const onImportGroupCalendar = formData => {
    return importFile(formData, groupInfo.groupId)
      .then(res => {
        console.log({ res });
        if (res.status === 200) {
          store.dispatch(
            showBottomNotification(t('BottomNotifications.Saved'), {
              isFail: true,
            }),
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return {
    exportsGroup,
    deleteExportGroup: onDeleteExportGroup,
    saveExportGroup: onAddExport,
    importGroupFile: onImportGroupCalendar,
  };
};

export default useCalendarExportEventsGroup;
