import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {
  setChatSocketSubscribed,
  setUnreadMessagesCountsKitas,
} from 'library/common/commonActions/chatActions';

import Chat from '.';

export default connect(
  state => ({
    user: state.userReducer,
    unreadMessagesCountsKitas: state.chatReducer.unreadMessagesCountsKitas,
    chatId: state.chatReducer.chatId,
    chatSocketSubscribed: state.chatReducer.chatSocketSubscribed,
    activeKita: state.kitaReducer.activeKita,
    kitas: state.kitaReducer,
    modules: state.kitaReducer.modules,
    timeRangeAbsenceInfo: state.timeRangeAbsenceReducer.timeRangeAbsenceInfo
  }),
  { showBottomNotification, setChatSocketSubscribed, setUnreadMessagesCountsKitas },
)(Chat);
