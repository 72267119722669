import React from 'react';

import Faq from 'react-faq-component';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import bgwLogo from 'resources/images/bgw/bgwLogo_png.png';

import stimme from 'resources/images/bgw/faq/FAQ-Stimme.jpg';
import laerm from 'resources/images/bgw/faq/FAQ_Laerm.jpg';
import ausfluege from 'resources/images/bgw/faq/FAQ-Ausfluege.jpg';
import psychischeBelastungen from 'resources/images/bgw/faq/FAQ_psychische_belastungen.jpg';
import koerperlicheAktivitaet from 'resources/images/bgw/faq/FAQ-koerperliche_aktivitaet.jpg';
import infektionsschutz from 'resources/images/bgw/faq/FAQ_Infektionsschutz.jpg';
import bgwLarge from 'resources/images/bgw/faq/FAQ_Bgwlogo_large.jpg';
import bgwStyles from './faq.module.scss';
import Collapsible from "../../../library/common/commonComponents/Collapsible";

export default function BgwModule() {
  const data1 = {
    title: 'Psychische Belastungen',
    image: psychischeBelastungen,
    rows: [
      {
        title: 'Was kann ich tun, wenn ich mich gestresst fühle?',
        content: `<p style='margin-left:20px'>Überprüfen Sie Ihre innere Haltung, die Ansprüche, die sie an sich selbst haben, nehmen Sie Ihre Grenzen wahr und ernst, achten Sie auf sich und Ihre Bedürfnisse.
              Analysieren Sie, ob es bestimmte Situationen gibt, die wiederholt bei Ihnen zu Stress führen.
              Lässt sich hier -ggf. nach Rücksprache im Team- Abhilfe schaffen? Z.B. durch Änderung der zeitlichen oder organisatorischen Strukturen/Abläufe?</p>`,
      },
      {
        title: 'Was mache ich, wenn die Hol- und Bringsituation stressig wird?',
        content: `<p style='margin-left:20px'>Richten Sie ggf. eine Rezeption ein. Stellen Sie auf jeden Fall feste Regelungen zur Hol- und Bringsituation mit den Eltern auf.</p>`,
      },
      {
        title: 'Halten Sie Ihre Pausenzeiten ein? ',
        content: `<p style='margin-left:20px'>Sprechen Sie ggf. die Wichtigkeit von Pausen in Teambesprechungen an.
              Wenn kein Pausenraum zur Verfügung steht, suchen Sie sich eine Alternative, verlassen Sie die Gruppe oder das Gebäude. Halten Sie Ihre Pause unbedingt ein. Dies dient Ihnen, dem Team und den Kindern.
              </p>`,
      },
      {
        title: 'Was mache ich, wenn Konflikte mit Eltern auftreten?',
        content: `<p style='margin-left:20px'>Besprechen Sie diese immer im Team und/oder mit der Leitung, um sich selbst zu entlasten. Zudem erhalten Sie so im günstigsten Fall wertvolle Tipps und Ratschläge der Kolleginnen und Kollegen, die Ihnen in dieser Situation helfen können.</p>`,
      },
      {
        title: 'Was mache ich, wenn Zuständigkeiten unklar sind?',
        content: `<p style='margin-left:20px'>Klären Sie schriftlich (z.B. im Protokoll der Teambesprechung), wer für welche Belange zuständig ist (Träger, Fachberatung, Leitung, päd. Fachkraft).
            Klären Sie im Team konkret die Aufgabenverteilung und die Zuständigkeiten auch unter Beachtung der zeitlichen Ressourcen der Teammitglieder.
            Halten Sie Ausschau nach „Verbündeten“ – im Team sind Sie immer stärker. Wie regeln andere Einrichtungen bestimmte Situationen? Bilden Sie Netzwerke oder bringen Sie sich in solchen ein.</p>`,
      },
      {
        title: 'Was mache ich, wenn problematische Situationen auftreten?',
        content: `<p style='margin-left:20px'>Stellen Sie Regeln auf! Regeln Sie mögliche problematische Situationen bereits im Vorfeld und informieren Sie alle Beteiligten, zum Beispiel bei Gefahr im Verzug, Umgang mit kranken Kindern (Hausregeln), Aufsichtspflicht bei Festen, Umgang mit Besuchskindern, Ausflügen, Mitarbeit der Eltern in der Kita, Verkehrssicherheit, Abholberechtigung (zum Beispiel Geschwisterkinder oder Eltern, die in Trennung leben), Alkoholproblemen von Abholberechtigten, Aufsichtspflicht und Haftung, Erste Hilfe, Zeckenbissen, etc.</p>`,
      },
      {
        title:
          'Was mache ich, wenn ich im Zusammenhang mit der Arbeit ein Extremerlebnis habe, z.B. bei aggressivem Verhalten von Eltern?',
        content: `<p style='margin-left:20px'>Besprechen Sie das Geschehene im Team. Wenden Sie sich bei bestehenden Beschwerden ggfls. an Ihren Betriebsarzt, Ihre Betriebsärztin, an einen Durchgangsarzt, eine Durchgangsärztin oder direkt an Ihren zuständigen Unfallversicherungsträger.
            <br /><br /><b>Beachten Sie hierzu auch unsere Übungen!</b></p>`,
      },
    ],
  };

  const data2 = {
    title: 'Körperliche Aktivität',
    image: koerperlicheAktivitaet,
    rows: [
      {
        title: 'Was mache ich, wenn Rücken oder Knie schmerzen?',
        content: `<p style='margin-left:20px'>Achten Sie auf eine möglichst ergonomisch sinnvolle Arbeitsplatzgestaltung. Die Mitarbeitenden sollten in der Benutzung aller Arbeitsmittel unterwiesen sein.
            Zu ergonomisch sinnvollem Mobiliar gehören u.a.:
            <br /><br />
            <ul>
              <li>Stühle für Erwachsene (nicht nur in Besprechungsräumen), </li>
              <li>höhenverstellbare Stühle in Abhängigkeit von Gewicht und Größe der Beschäftigten (Erzieherstühle),</li>
              <li>Tische für Erwachsene in der Gruppe (gegebenenfalls mit Treppenhochstühlen für die Kinder),</li>
              <li>effiziente Raumgestaltung, z.B. durch die Nutzung von Klapptischen, die bspw. an der Wand befestigt werden, damit Sie mehr Raum für Bewegung haben,</li>
              <li>Wickeltische mit Aufstiegshilfe, </li>
              <li>zargenfreie Kindertische,</li>
              <li>Sitzkissen oder Bodenstühle für Tätigkeiten auf dem Boden,</li>
              <li>ein Stehpult für Dokumentationen,</li>
              <li>möglichst rollbare (Kinder-) Tische und Schränke. Erkundigen Sie sich gegebenenfalls nach Möglichkeiten der Umrüstung,</li>
              <li>möglichst leichte Möbel (z.B. Kinderbetten).</li>
              <li>Verwenden Sie eine Anziehhilfe für Kinder.</li>
            </ul>
            </p>
            <br />
            <p style='margin-left:20px'>
            Versuchen Sie bei allen Tätigkeiten auf Ihre (Körper-) Haltung zu achten.
            Nutzen Sie tägliche Bewegungsangebote auch für sich selbst.
            Vermeiden Sie, soweit möglich, die Kinder hochzuheben und zu tragen. 
            Infos zur ergonomisch sinnvollen Gestaltung Ihres Arbeitsplatzes erhalten Sie hier:
            <a href='https://www.bgw-online.de/SharedDocs/Downloads/DE/Arbeitssicherheit_und_Gesundheitsschutz/Gesund-und-sicher-Arbeiten/Sichere-Seiten/Kinderbetreuung/Arbeitsplatz_KiTa_Artikel_Download.pdf?__blob=publicationFile'>Arbeitsplatz in der Kindertagesstätte, </a>
            <a href='https://www.sichere-kita.de/'>Sichere Kita</a>
            </p>`,
      },
    ],
  };

  const data3 = {
    title: 'Infektions- und Hautschutz',
    image: infektionsschutz,
    rows: [
      {
        title:
          'Worauf muss ich achten, wenn ich infektionsgefährdende/infektionsträchtige Tätigkeiten durchführe?',
        content: `
        <p style='margin-left:20px'>
          <ul>
            <li>Nutzen Sie Einmalhandschuhe beim Umgang mit Körperflüssigkeiten.</li>
            <li>Tragen Sie keinen Schmuck, verzichten Sie auf lange, lackierte und künstliche Fingernägel.</li>
            <li>Verwenden Sie Händedesinfektionsmittel.</li>
            <li>Achten Sie auf Ihren Impfschutz und besprechen Sie notwendige und aufzufrischende Impfungen mit Betriebsarzt oder Betriebsärztin.</li>
            <li>Schützen Sie regelmäßig Ihre Hände mit Hautschutzcreme und pflegen Sie Ihre Hände in arbeitsfreien Zeiten mit Hautpflegecreme.</li>
            <li>Regeln Sie im Vorfeld mit den Eltern, wie zu verfahren ist, wenn Kinder erkranken. Kinder sollten bei einer Erkrankung nicht in die Kita gebracht werden (<a href='https://www.kinderkinder.dguv.de/wp-content/uploads/2019/12/Hausregeln_kranke_Kinder_dt.pdf'>Hausregeln</a>).
            </li>
          </ul>
            </p>`,
      },
      {
        title:
          'Worauf muss ich achten, wenn ich hautgefährdende Tätigkeiten (z.B. Reinigungsarbeiten) durchführe?',
        content: `
        <p style='margin-left:20px'>
        <ul>
          <li>Verwenden Sie chemikalienbeständige (Haushalts-) Handschuhe bei der Reinigung von Flächen.</li>
          <li>Verwenden Sie keine Desinfektionsmittel zum Sprühen, da Sie dadurch die Inhaltstoffe über die Atemwege aufnehmen können.</li>
          <li>Schützen Sie regelmäßig Ihre Hände mit Hautschutzcreme und pflegen Sie Ihre Hände in arbeitsfreien Zeiten mit Hautpflegecreme.</li>
        </ul>
          </p>`,
      },
      {
        title: 'Was mache ich, wenn sich Hauterscheinungen an den Händen bilden?',
        content: `<p style='margin-left:20px'>Wenden Sie sich an Ihren Betriebsarzt oder Ihre Betriebsärztin oder einen Hautarzt oder eine Hautärztin.</p>`,
      },
    ],
  };

  const data4 = {
    title: 'Lärm',
    image: laerm,
    rows: [
      {
        title: 'Was mache ich, wenn die Lärmbelästigung in der Kita für mich zu groß ist?',
        content: `<p style='margin-left:20px'>
          Wurden raumakustische Maßnahmen in ihrer Einrichtung ergriffen (Schallschutzdecken- oder Elemente)?
          Lassen Sie bereits installierte Lärmschutzdecken nur von Sachkundigen streichen, damit die Funktion nicht eingeschränkt wird.
          Sorgen Sie selbst für Unterstützung der Funktionen dieser Maßnahmen:
          </p>
          <br />
          <ul>
            <li>Nutzen Sie z.B. Filzgleiter unter Tischen und Stühlen</li>
            <li>Legen Sie Spielkisten (zum Beispiel aus Holz) mit Filzmatten aus oder verwenden Sie alternative Aufbewahrungsbehälter (zum Beispiel Körbe, Beutel).</li>
            <li>Legen Sie ein Filzdeckchen unter, wenn Sie Spiele mit Würfeln spielen</li>
            <li>Suchen sie gemeinsam mit den Kindern nach alternativen Materialien oder Handhabungen für leise Lösungen.</li>
          </ul>
          <br />
          <p style='margin-left:20px'>
          Zudem helfen pädagogische und organisatorische Maßnahmen um den Lärmpegel zu reduzieren:
          </p>
          <br />
          <ul>
            <li>Teilen Sie die Kinder auf alle nutzbaren Räumlichkeiten und das Außengelände auf.</li>
            <li>Erarbeiten Sie Ruhephasen mit den Kindern und führen Sie diese regelmäßig durch.</li>
            <li>Nutzen Sie optische und akustische Signale wie z.B. Glocke, Klangschale und den „Leisefuchs“.</li>
            <li>Versuchen Sie nicht gegen den Lärm anzusprechen, um ein gegenseitiges Aufschaukeln zu verhindern.</li>
            <li>Spielen Sie mit den Kindern Spiele, die zu einer Lärmreduktion beitragen (z.B. „Stille Glocke“)</li>
          </ul>
          <br />
          <p style='margin-left:20px'>
          <b>Beachten Sie hierzu auch unsere Übungen!</b>
            </p>`,
      },
    ],
  };

  const data5 = {
    title: 'Stimme',
    image: stimme,
    rows: [
      {
        title: 'Was mache ich, wenn meine Stimme angegriffen ist?',
        content: `
          <ul>
            <li>Trinken Sie ausreichend und halten Sie Getränke jederzeit im Sichtfeld griffbereit.</li>
            <li>Holen Sie sich bei anhaltenden Beschwerden ärztlichen oder logopädischen Rat.</li>
            <li>Achten Sie auf eine aufrechte Haltung beim Sprechen.</li>
            <li>Vermeiden Sie häufiges Räuspern, hüsteln Sie stattdessen leicht.</li>
            <li>Vermeiden Sie es, zu schreien und gegen Lärm anzusprechen.</li>
            <li>Vermeiden Sie es, zu flüstern und sprechen Sie lieber ganz leise.</li>
            <li>Versuchen Sie in Ihrer natürlicher Sprechstimmlage zu sprechen und vermeiden Sie eine zu hohe Stimmlage.</li>
          </ul>
          <br />
          <p style='margin-left:20px'>
          <b>Beachten Sie hierzu auch unsere Übungen!</b>
            </p>`,
      },
    ],
  };

  const data6 = {
    title: 'Ausflüge',
    image: ausfluege,
    rows: [
      {
        title: 'Wie gehe ich vor, wenn wir einen Ausflug planen?',
        content: `
          <ul>
            <li>Klären Sie im Vorfeld eventuelle Gefährdungen des externen Aufenthaltsortes ab: Sind Toiletten vorhanden? Gibt es ausreichend Schatten? Sind besondere Gefährdungen vorhanden, z.B. Verkehr oder Wasser?</li>
            <li>Überprüfen Sie bei externen Angeboten, ob diese für Kinder geeignet sind und entsprechend vor Ort kompetentes, verantwortlich handelndes Personal eingesetzt wird. </li>
            <li>Planen Sie die Anzahl der Betreuenden in einem angemessenen Verhältnis zur Anzahl der Kinder.</li>
            <li>Klären Sie alle anfallenden Aufgaben mit der jeweiligen Begleitperson.</li>
            <li>Stellen Sie sicher, dass Maßnahmen getroffen werden, die sowohl die Kinder als auch die Begleitpersonen vor widriger Witterung schützen. Insbesondere sollte auf Sonnenschutz, genügend Wasser/ Tee, Proviant und witterungsbedingte Kleidung geachtet werden. </li>
            <li>Wählen Sie Krippenwagen mit Bedacht aus und testen Sie die unterschiedlichen Modelle.</li>
            <li>Erste Hilfe bei Ausflügen:</li>
            <li>Stellen Sie sicher, dass Sorgeberechtigte im Notfall schnell benachrichtigt werden können (z.B. mittels Mobiltelefon). </li>
            <li>Führen Sie Erste-Hilfe-Material mit.</li>
            <li>Beachten Sie bestehende besondere Bedingungen und Erkrankungen der Kinder (z.B. Allergien) und den Umgang damit (z.B. Verhalten bei anaphylaktischer Schockreaktion). Nehmen Sie, wenn erforderlich, personenbezogene Medikamente der Kinder mit und beachten Sie die ärztlichen Anordnungen und Vorgaben. 
            <a href='https://www.kinderkinder.dguv.de/wp-content/uploads/2021/08/Wir_sind_dann_mal_weg.pdf'>Wir sind dann mal weg!</a>
            </li>
          </ul>
            `,
      },
    ],
  };

  const data7 = {
    title: 'Unfallversicherung',
    image: bgwLarge,
    rows: [
      {
        title: 'Wie zeige ich einen Arbeitsunfall an?',
        content: `<p style='margin-left:20px'>In der Regel wird ein Unfall durch den behandelnden (Durchgang-)Arzt angezeigt.
          Bei einem meldepflichtigen Unfall muss auch der Betrieb eine Unfallanzeige erstellen. Diese finden Sie in der Regel auf der Homepage des jeweiligen Unfallversicherungsträgers unter dem Reiter „Formulare“. 
          </p>`,
      },
      {
        title: 'Wer ist denn wo versichert?',
        content: `<p style='margin-left:20px'>Kinder und Erwachsene sind beim regulären Aufenthalt in der KiTa und bei Aktivitäten der KiTa versichert. Für die Beschäftigten bei privaten und wohltätigen Trägern ist die BGW der zuständige Unfallversicherungsträger, für die Beschäftigten in kommunalen Einrichtungen und für die Kinder ist die Zuständigkeit der jeweiligen Unfallkasse oder des Gemeindeunfallversicherungsverbandes gegeben, solange Gemeinnützigkeit gegeben ist. Für gewerbsmäßige Einrichtungen ist die BGW zuständig (Beschäftigte und Kinder), für rechtlich unselbständige Betriebskindergärten ist der Unfallversicherungsträger des Hauptunternehmens zuständig. 
        Unentgeltlich mithelfende Eltern (z.B. Ausflugsbegleitung, Aufsicht, Sommerfest, Renovierungsarbeiten) sind ebenfalls versichert. Die Personalabteilung Ihres Trägers kann Ihnen ggf. weiterhelfen, wenn Sie hier unsicher sind. 
        </p>`,
      },
    ],
  };

  const dataList = [data1, data2, data3, data4, data5, data6, data7];

  const styles = {
    // bgColor: 'green',
    // titleTextColor: "blue",
    rowTitleColor: 'grey',
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };

  return (
    <Col>
      <Row>
        <div className={bgwStyles.title}>
          FAQs
          <img
            src={bgwLogo}
            style={{ float: 'right', marginTop: '-4px', height: '50px' }}
            alt='Bgw'
          />
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'flex-start' }}>
          {dataList.map(d => {
            return (
              <Collapsible
                title={d.title}
                previewImage={d.image}
                body={
                  <Faq data={d} styles={styles} config={config} />
                }
              />
            );
          })}
        </div>
        <br />
        <div>
          Quelle: <a href='https://www.bgw-online.de/'>BGW</a>, Veranschaulichungsbilder: ©BGW/Michael Hüter
        </div>
        <br />
      </Row>
    </Col>
  );
}
