import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { getKidsStatusAndAbsencesByGroup } from 'library/api/kids';
import { getConfig } from 'library/api/kita';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from './groupKidsListPopup.module.scss';

export default function GroupKidsListPopup({
  isOpened,
  closePopup,
  title,
  groupId,
  groupAdminStatus,
  groupModules,
  userInfo,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const goChildPage = (kidId, parent1Id) => {
    history.push({
      pathname: '/administration/users/list/1',
      search: `?editChild=${kidId}&parentId=${parent1Id}`,
    });
  };

  const [initialUsers, setInitialUsers] = useState([]);
  const [initialUsersLoaded, setInitialUsersLoaded] = useState(false);
  const [selectedKid, setSelectedKid] = useState(null);
  const [isKidContactPopupOpened, setIsKidContactPopupOpened] = useState(false);
  const [kidContactAvailableForEmployee, setKidContactAvailableForEmployee] = useState(false);

  useEffect(() => {
    setInitialUsersLoaded(true);
    getKidsStatusAndAbsencesByGroup(groupId).then(res => {
      setInitialUsers(res.data);
      setInitialUsersLoaded(true);
    });
  }, [groupId]);

  const hasCheckinOutModule = groupModules.find(
    module => module.moduleKey === 'checkinout' && module.activeStatus,
  );

  // either: "VACATION", "SICK", "CHECKOUT", "CHECKIN" or null
  const getKidsStatus = kid => {
    if (kid.kidAbsence) {
      return kid.kidAbsence.vacation ? 'VACATION' : 'SICK';
    } else if (hasCheckinOutModule) {
      return kid.checkInOutStatus;
    } else {
      return null;
    }
  };

  const handleKidContactPopupOpen = kid => {
    setIsKidContactPopupOpened(true);
    setSelectedKid(kid);
  };

  useEffect(() => {
    getConfig().then((res) => {
      const kitaConfig = res.data;
      setKidContactAvailableForEmployee(kitaConfig.kidContactAvailableForEmployee);
    })
  }, [])

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      header={title}
      footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
    >
      {initialUsersLoaded ? (
        <div>
          <div className={styles.container}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={568}>
              {initialUsers.map(kid => {
                const groupId = kid.groupId ? kid.groupId : '';
                const kidStatus = getKidsStatus(kid);
                return (
                  <div key={kid.kidId} className={styles.itemContainer}>
                    <div className={styles.item}>
                      <div className={styles.itemAvatar}>
                        <img
                          key='bottomGroupLogo'
                          src={kid.groupLogoUrl ? `${kid.groupLogoUrl}` : defaultAvatar}
                          alt='logo'
                          style={{ width: '100%' }}
                          className={styles.logoImg}
                        />
                      </div>

                      {/* add styling according to checkin/out status and whne module is active */}
                      <div className={styles.itemInfo}>
                        <div
                          className={cn(
                            styles.itemName,
                            kidStatus != 'CHECKIN' && styles.itemNameCheckout,
                            'notranslate',
                          )}
                        >
                          {kid.name}
                        </div>
                        {kidStatus && (
                          <div
                            className={cn(
                              styles.itemStatus,
                              kidStatus === 'CHECKIN' && styles.itemStatusCheckin,
                              kidStatus === 'CHECKOUT' && styles.itemStatusCheckout,
                              kidStatus === 'VACATION' && styles.itemStatusVacation,
                              kidStatus === 'SICK' && styles.itemStatusSick,
                            )}
                          >
                            {kidStatus === 'CHECKIN' && t('Checkinout.Checked In')}
                            {kidStatus === 'CHECKOUT' && t('Checkinout.Checked Out')}
                            {kidStatus === 'VACATION' && 'Urlaub'} {/* TODO Translation*/}
                            {kidStatus === 'SICK' && 'Krank'} {/* TODO Translation*/}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.buttonGroup}>
                      {groupId ? (
                        <Link to={`/groups/${groupId}`}>
                          <Button size='sm' isFullWidth={true} alignText='left'>
                            <i className='fa fa-lg fa-address-card' /> Portfolio
                            {/* TODO translation*/}
                          </Button>
                        </Link>
                      ) : null}

                      {kid.parent1Id ? (
                        <Button
                          size='sm'
                          isFullWidth={true}
                          alignText='left'
                          onClick={() => {
                            goChildPage(kid.kidId, kid.parent1Id);
                          }}
                        >
                          <i className='fa fa-lg fa fa-pencil-square-o' /> Bearbeiten
                          {/* TODO translation*/}
                        </Button>
                      ) : null}
                      {(userInfo.administrationAccess || groupAdminStatus || userInfo.employee) &&
                        kidContactAvailableForEmployee && (
                          <Button
                            size='sm'
                            isFullWidth={true}
                            alignText='left'
                            onClick={() => handleKidContactPopupOpen(kid)}
                          >
                            <i className='fa fa-lg fa-address-book' /> {t('Kid.ContactInfo')}
                          </Button>
                        )}
                    </div>
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <Popup
        size='large'
        isOpened={isKidContactPopupOpened}
        closePopup={() => setIsKidContactPopupOpened(false)}
        header={`${t('Kid.Contact')} ${selectedKid?.name}`}
        footer={
          <Button onClick={() => setIsKidContactPopupOpened(false)}>{t('Popup.Close')}</Button>
        }
      >
        <div className={styles.kidContactInfo}>
          <span className={styles.parentName}>{selectedKid?.parent1Name}</span>
          <div className={styles.contactInfoList}>
            {selectedKid?.parent1Phone && (
              <div className={styles.contactInfoItem}>
                <span>{t('Kid.Phone')}:</span>
                <span>{selectedKid.parent1Phone}</span>
              </div>
            )}
            {selectedKid?.parent1Mobile && (
              <div className={styles.contactInfoItem}>
                <span>{t('Kid.Mobile')}:</span>
                <span>{selectedKid.parent1Mobile}</span>
              </div>
            )}
            <div className={styles.contactInfoItem}>
              <span>{t('Kid.Mail')}:</span>
              <span>{selectedKid?.parent1Email}</span>
            </div>
          </div>
        </div>
        {selectedKid?.parent2Name && (
          <div className={styles.kidContactInfo}>
            <span className={styles.parentName}>{selectedKid?.parent2Name}</span>
            <div className={styles.contactInfoList}>
              {selectedKid?.parent2Phone && (
                <div className={styles.contactInfoItem}>
                  <span>{t('Kid.Phone')}:</span>
                  <span>{selectedKid.parent2Phone}</span>
                </div>
              )}
              {selectedKid?.parent2Mobile && (
                <div className={styles.contactInfoItem}>
                  <span>{t('Kid.Mobile')}:</span>
                  <span>{selectedKid.parent2Mobile}</span>
                </div>
              )}
              <div className={styles.contactInfoItem}>
                <span>{t('Kid.Mail')}:</span>
                <span>{selectedKid?.parent2Email}</span>
              </div>
            </div>
          </div>
        )}
        <p className={styles.authorizedPersonsTitle}>{t('Kid.Authorized Persons')}</p>
        {selectedKid?.authorizedPersons
          ? selectedKid?.authorizedPersons.map(authorizedPerson => (
              <div className={styles.kidContactInfo}>
                <div className={styles.parentName}>
                  <div className={styles.avatar}>
                    <img
                      key='bottomGroupLogo'
                      src={authorizedPerson.image ? `${authorizedPerson.image}` : defaultAvatar}
                      alt='logo'
                      style={{ width: '100%' }}
                    />
                  </div>
                  <span>
                    {authorizedPerson.firstName} {authorizedPerson.lastName}
                  </span>
                </div>
                <div className={styles.contactInfoList}>
                  <div className={styles.contactInfoItem}>
                    <span>{t('Kid.Phone')}:</span>
                    <span>
                      {authorizedPerson.phonenumber !== ''
                        ? authorizedPerson.phonenumber
                        : t('Kid.No Phone')}
                    </span>
                  </div>
                  {authorizedPerson.mobile && (
                    <div className={styles.contactInfoItem}>
                      <span>{t('Kid.Mobile')}:</span>
                      <span>{authorizedPerson.mobile}</span>
                    </div>
                  )}
                  <div className={styles.contactInfoItem}>
                    <span>{t('Kid.Mail')}:</span>
                    <span>{authorizedPerson.email}</span>
                  </div>
                </div>
              </div>
            ))
          : t('kid.No AuthorizedPersons')}
      </Popup>
    </Popup>
  );
}
