import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  getDetailsOfParticipatingKidsForCalendarEvent,
} from 'library/api/calendar';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';

import styles from './usersListPopup.module.scss';
import Loader from '../../Loader';

export default function CalendarKidParticipantsPopup({ eventId, isOpened, closePopup }) {
  const { t } = useTranslation();
  const [participationData, setParticipationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetailsOfParticipatingKidsForCalendarEvent(eventId).then(data => {
      if (data.data !== null) {
        setParticipationData(data.data);
      }
      setIsLoading(false);
    })
  }, [])

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='large'
      header={t('Popup.Kid participation overview')}
      footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
    >
      <div className={styles.container}>
        {!isLoading ?
          <table>
            <tr><th>{t('Popup.Kid full name')}</th><th>{t('Popup.Kid birthday')}</th><th>{t('Popup.Parent names')}</th><th>{t('Popup.Parent mobiles')}</th></tr>
            {participationData.map(row => {
              return <tr><td>{`${row.kidFirstName} ${row.kidLastName}`}</td><td>{row.kidBirthday !== null ? row.kidBirthday : ""}</td><td>{row.parent1FirstName !== null ? `${row.parent1FirstName} ${row.parent1LastName}` : ""}{row.parent2FirstName !== null ? (<><br />{`${row.parent2FirstName} ${row.parent2LastName}`}</>) : ""}</td><td>{row.parent1Mobile !== null ? `${row.parent1Mobile}` : ""}{row.parent2Mobile !== null ? (<><br />{`${row.parent2Mobile}`}</>) : ""}</td></tr>
            })}
          </table> : <Loader />}
      </div>
    </Popup>
  );
}

