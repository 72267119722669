import React from 'react';
import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';
import store from 'main/store/configureStore';
import { Trans } from 'react-i18next';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

export const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;

// eslint-disable-next-line
const galleryGraphQL =
  'id,galleryName,description,isBazaar,isTranslationAllowed,privateGallery,groupId,createdBy,defaultGallery,createdAt,editedAt,firstName,lastName,latestImageURL';

export const getGalleries = ({ groupId, userId, kitaId, isProfilePage }) =>
  axios.post(`${URL}/galleries`, {
    page: 1,
    size: 10000,
    sort: ['createdAt', 'asc'],
    filter: getFiltersForGetGalleries({ groupId, userId, kitaId, isProfilePage }),
    graphql: galleryGraphQL,
  });

export function getFiltersForGetGalleries({ groupId, userId, kitaId, isProfilePage }) {
  const filter = ['deleted:eq:false'];

  if (isProfilePage) {
    filter.push('and', `userId:eq:${userId}`, 'and', `kitaId:eq:${kitaId}`, 'and', 'groupId:eq:0');
  } else {
    filter.push('and', `groupId:eq:${groupId}`);
  }

  return filter;
}

export const createGallery = ({ payload, groupId, userId, kitaId, isProfilePage }) =>
  axios.post(
    `${URL}/gallery?${getFiltersForCreateGallery({ groupId, userId, kitaId, isProfilePage })}`,
    payload,
  );

export function getFiltersForCreateGallery({ groupId, userId, kitaId, isProfilePage }) {
  if (isProfilePage) {
    return `userId=${userId}&kitaId=${kitaId}&groupId=0`;
  } else {
    return `groupId=${groupId}`;
  }
}

export const deleteGallery = ({ id }) => axios.delete(`${URL}/gallery/${id}`);

export const editGallery = ({ id, payload }) =>
  axios.put(`${URL}/gallery/${id}`, {
    ...payload,
    graphql: galleryGraphQL,
  });

export const uploadFilesToGallery = ({ id, images, privatePost, setProgress, isBazaar }) => {
  const formData = new FormData();
  images.forEach(image => formData.append('images', image));
  formData.append('privatePost', false);
  formData.append(
    'graphql',
    'id,files[id,fileId,path,userId,posts{id,permanent},comments{id,posts{id}},description,bazaarItemInfo[price,description]],isTranslationAllowed',
  );

  return axios
    .post(`${zuulURL}/gallery/${id}/post`, formData, {
      onUploadProgress: progressEvent => {
        setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
      },
    })
    .catch(err => {
      store.dispatch(
        showBottomNotification(<Trans i18nKey={err.response.data.message} />, {
          isFail: true,
        }),
      );
    });
};

export const updateImageDescription = ({
  postId,
  fileId,
  description,
  price,
  bazaarItemInfoDescription,
}) =>
  axios.put(`${URL}/gallery/post/${postId}/file/${fileId}`, {
    description,
    price,
    bazaarItemInfoDescription,
  });

export const deleteImageFromGallery = ({ postId, fileId, groupId }) =>
  axios.delete(`${URL}/gallery/${groupId}/post/${postId}/file/${fileId}`);

export const deleteAllImagesFromGallery = ({ galleryId, groupId }) => {
  return axios.delete(`${URL}/gallery/${groupId}/${galleryId}/deleteAll`);
};

export const getDefaultGalleryFiles = ({ groupId, page, pageSize, filters }) =>
  axios.get(`${URL}/gallery/default`, {
    params: {
      groupId,
      page,
      pageSize,
      graphql: 'id,fileId,path,posts{id,permanent},comments{id,posts{id}}',
      ...getFiltersForGallery(filters),
    },
  });

export const getNonDefaultGalleryFiles = ({ galleryId, page, pageSize, filters }) =>
  axios.post(
    `${URL}/gallery/${galleryId}/files`,
    {
      page,
      size: pageSize,
      graphql:
        'id,userId,fileId,path,posts{id,permanent},comments{id,posts{id}},description,isTranslationAllowed,bazaarItemInfo[price,description]',
    },
    { params: getFiltersForGallery(filters) },
  );

export function getFiltersForGallery(filters) {
  const result = {};

  const userId = store.getState().userReducer.id;
  const userIds = [];
  if (filters.createdByMe) userIds.push(userId);
  if (filters.users.users && filters.users.users.length > 0) {
    filters.users.users.forEach(({ id }) => userIds.push(id));
  }
  if (userIds.length > 0) result.userIds = userIds.toString();

  if (filters.showDateFilters) {
    result.fromDate = filters.fromDate;
    result.toDate = filters.toDate;
  }

  return result;
}

export const movePictures = (galleryId, originGalleryId, fileIds) =>
  axios.post(
    `${URL}/gallery/${galleryId}/${originGalleryId}/move`,
    {fileIds},
  );
