import { MenuItem, Dropdown } from 'prosemirror-menu';
import { toggleMark } from 'prosemirror-commands';

export function createDropdownItem(content, label) {
  return new Dropdown(content, {
    label,
    title: label,
    class: 'color',
  });
}

export function createMenuItem({ mark, title, isColor }) {
  const dom = document.createElement('div');
  const icon = dom.appendChild(document.createElement('i'));
  icon.classList.add('fa', 'fa-underline');

  return markItem(mark, {
    title,
    label: title,
    icon: {
      dom,
    },
    class: 'ProseMirror-icon-underline',
    css: isColor && `background-color: ${getColor(mark.name)}`,
  });
}

function getColor(colorString) {
  const bgName = 'background';
  const backgroundIndex = colorString.indexOf(bgName);

  return backgroundIndex !== -1 ? colorString.slice(bgName.length) : colorString;
}

function markItem(markType, options) {
  const passedOptions = {
    active(state) {
      return markActive(state, markType);
    },
    enable: true,
  };
  for (const prop in options) passedOptions[prop] = options[prop];
  return cmdItem(toggleMark(markType), passedOptions);
}

function markActive(state, type) {
  const { from, $from, to, empty } = state.selection;
  if (empty) return type.isInSet(state.storedMarks || $from.marks());
  else return state.doc.rangeHasMark(from, to, type);
}

function cmdItem(cmd, options) {
  const passedOptions = {
    ...options,
    label: options.title,
    run: cmd,
  };

  if ((!options.enable || options.enable === true) && !options.select) {
    passedOptions[options.enable ? 'enable' : 'select'] = state => cmd(state);
  }

  return new MenuItem(passedOptions);
}

export function createEmojisItem(content) {
  return new Dropdown(content, {
    class: 'ProseMirror-emoji-dropdown',
    label: '😀',
    icon: content.icon,
  });
}
