import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useRouter from 'use-react-router';

import { getKitaAdmins } from 'library/api/user';
import UserEditing from 'library/common/commonComponents/UserEditing';
import UsersFrameKitaAdmins from '../UsersFrameKitaAdmins';

export default function UsersFrameKitaAdminsWrapper({
  activeKita,
  editingProfile,
  setEditingProfile,
  user
}) {
  const [dataUsers, setData] = useState(null);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const queryChildId = query.get('editChild');
  const queryUserId = query.get('editUserId');

  const [queryChildIdState, setQueryChildIdState] = useState(
    query && queryChildId ? queryChildId : null,
  );
  const [queryUserIdState, setQueryUserIdState] = useState(
    query && queryUserId ? queryUserId : null,
  );
  const { history, location } = useRouter();
  const [deletionProfile, setDeletionProfile] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getKitaAdmins(-1, activeKita.kitaId).then((data) => {
      setData(data)
      setIsLoading(false);
    });
  }, [editingProfile]);

  return editingProfile ? (
    <UserEditing
      isAdminEditing={user.administrationAccess || user.superAdminStatus}
      kitaId={activeKita.kitaId}
      activeKita={activeKita}
      editingProfile={editingProfile}
      setEditingProfile={setEditingProfile}
      setDeletionProfile={setDeletionProfile}
      user={user}
      history={history}
      editChildId={queryChildIdState ? parseInt(queryChildIdState, 10) : null}
      setQueryChildIdState={setQueryChildIdState}
      setQueryUserIdState={setQueryUserIdState}
    />
  ) : dataUsers ? (
    <UsersFrameKitaAdmins
      activeKita={activeKita}
      data={dataUsers}
      setEditingProfile={setEditingProfile}
    />
  ) : null;
}
