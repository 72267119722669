import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Label from 'library/common/commonComponents/Label';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import styles from './employeeAbsencesOverview.module.scss';
import cn from 'classnames';
import ReviewButtons from 'library/common/commonComponents/Buttons/ReviewButtons';
import { useSelector } from 'react-redux';
import Tooltip from 'library/common/commonComponents/Tooltip';
import { getWorkingDaysCount } from 'library/utilities/employeeCheckInOut';
import {
  EMPLOYEE_ABSENCE_TYPES,
  EMPLOYEE_ABSENCE_ICONS,
} from 'library/common/commonConstants/employee';

export default function EmployeeAbsencesOverview({
  employeeAbsences,
  workingDaysPerUser,
  handleReviewButtonClick,
  handleSubmitUpdateAbsence,
  employeeKitaAbsenceTypes,
}) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startHalfDay, setStartHalfDay] = useState(false);
  const [endHalfDay, setEndHalfDay] = useState(false);
  const user = useSelector(state => state.userReducer);
  const [editAbsence, setEditAbsence] = useState('');

  const getTooltipText = absenceType => {
    switch (absenceType) {
      case EMPLOYEE_ABSENCE_TYPES.HOLIDAY:
        return t('Checkinout.Vacation');
      case EMPLOYEE_ABSENCE_TYPES.SICK:
        return t('Checkinout.Sick note');
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_ONE:
        return employeeKitaAbsenceTypes[2]?.label;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_TWO:
        return employeeKitaAbsenceTypes[3]?.label;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_THREE:
        return employeeKitaAbsenceTypes[4]?.label;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_FOUR:
        return employeeKitaAbsenceTypes[5]?.label;
      case EMPLOYEE_ABSENCE_TYPES.ABSENCE_FIVE:
        return employeeKitaAbsenceTypes[6]?.label;
      case EMPLOYEE_ABSENCE_TYPES.HOLIDAY_ON_OVERTIME:
        return t('Checkinout.Holiday on overtime');
      default:
        return '';
    }
  };

  const formatStartEndDate = (unformattedData, isHalfDay) => {
    const date = new Date(unformattedData);
    const dateString = date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return isHalfDay ? `${dateString} (½)` : dateString;
  };

  return (
    <section className={styles.holidaysHistorySection}>
      <h2>{t('Checkinout.AbsencesOverview')}</h2>

      <table className={styles.absencesTable}>
        <thead className={styles.holidaysTableHeader}>
          <tr>
            <th className={styles.statusColumn}>{t('Checkinout.Status')}</th>
            <th className={styles.nameColumn}>{t('Checkinout.Full Name')}</th>
            <th className={styles.periodColumn}>{t('Checkinout.Period')}</th>
            <th className={styles.daysColumn}>{t('Checkinout.Days')}</th>
            <th className={styles.typeColumn}>{t('Checkinout.Type')}</th>
            <th className={styles.reviewColumn} />
          </tr>
        </thead>
        <tbody>
          {employeeAbsences
            ? employeeAbsences.map(absence => {
                return (
                  <tr key={absence.id}>
                    <td
                      className={cn(
                        styles.statusColumn,
                        absence.status === 'APPROVED' && styles.statusAccepted,
                        absence.status === 'REJECTED' && styles.statusDeclined,
                        absence.status === 'OPEN' && styles.statusOpen,
                      )}
                    >
                      <i
                        className={cn(
                          'fa',
                          'fa-2x',
                          absence.status === 'APPROVED' && 'fa-check-circle',
                          absence.status === 'REJECTED' && 'fa-minus-circle',
                          absence.status === 'OPEN' && 'fa-question-circle',
                        )}
                        aria-hidden='true'
                      />
                    </td>
                    <td>{`${absence.user.firstName} ${absence.user.lastName}`}</td>
                    <td className={cn(editAbsence === absence.id && styles.highlightField)}>
                      {editAbsence !== absence.id ? (
                        <span className={styles.fromToDate}>
                          {`${formatStartEndDate(
                            absence.startDate,
                            absence.startHalfDay,
                          )} - ${formatStartEndDate(absence.endDate, absence.endHalfDay)}`}
                        </span>
                      ) : (
                        <div className={styles.dateInputsContainer}>
                          <div className={styles.datePickerContainer}>
                            <div className={styles.dateStart}>
                              <Label type='input'>{t('Calendar.Start Date')}</Label>
                              <DatePicker
                                selected={startDate}
                                onChange={date => {
                                  setStartDate(date);
                                }}
                                langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
                                minDate={new Date().setDate(new Date().getDate() - 20)}
                              />
                              <Checkbox
                                className={styles.halfDayCheckbox}
                                name={t('Checkinout.Half day')}
                                isChecked={startHalfDay}
                                onChange={() => setStartHalfDay(prev => !prev)}
                              />
                            </div>

                            <div className={styles.fromToBorder} />
                            <div className={styles.dateStart}>
                              <Label type='input'>{t('Calendar.End Date')}</Label>
                              <DatePicker
                                selected={endDate}
                                onChange={date => {
                                  setEndDate(date);
                                }}
                                langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
                                minDate={new Date().setDate(new Date().getDate() - 20)}
                              />

                              <Checkbox
                                className={styles.halfDayCheckbox}
                                name={t('Checkinout.Half day')}
                                isChecked={endHalfDay}
                                onChange={() => setEndHalfDay(prev => !prev)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {editAbsence === absence.id ? (
                        <div className={styles.confirmCancelContainer}>
                          <Button
                            className={styles.confirmCancelButton}
                            size='sm'
                            onClick={() => {
                              setEditAbsence('');
                              setStartDate('');
                              setEndDate('');
                            }}
                          >
                            <i className='fa fa-times' />
                            {t('Checkinout.Cancel')}
                          </Button>

                          <Button
                            className={styles.confirmCancelButton}
                            size='sm'
                            onClick={() => {
                              if (!startDate || !endDate) return;

                              handleSubmitUpdateAbsence(
                                absence.id,
                                absence.user.id,
                                startDate,
                                endDate,
                                startHalfDay,
                                endHalfDay,
                              );
                            }}
                            type='primary'
                          >
                            <i className='fa fa-check' />
                            {t('Checkinout.Confirm')}
                          </Button>
                        </div>
                      ) : (
                        <Button
                          size='sm'
                          onClick={() => {
                            setStartDate(new Date(absence.startDate));
                            setEndDate(new Date(absence.endDate));
                            setStartHalfDay(absence.startHalfDay);
                            setEndHalfDay(absence.endHalfDay);
                            setEditAbsence(absence.id);
                          }}
                          className={styles.editAbsenceButton}
                        >
                          <i className='fa fa-pencil' />
                        </Button>
                      )}
                    </td>
                    <td className={styles.daysColumn}>
                      {getWorkingDaysCount(
                        absence.startDate,
                        absence.endDate,
                        absence.startHalfDay,
                        absence.endHalfDay,
                        workingDaysPerUser?.[absence.user.id],
                      )}
                    </td>
                    <td className={styles.typeColumn}>
                      <Tooltip
                        text={getTooltipText(absence.absenceType)}
                        className={styles.tooltip}
                      >
                        <span>
                          <i
                            className={`fa fa-lg ${EMPLOYEE_ABSENCE_ICONS[absence.absenceType]}`}
                          />
                        </span>
                      </Tooltip>
                    </td>
                    <td>
                      <ReviewButtons
                        handleReviewButtonClick={handleReviewButtonClick}
                        id={absence.id}
                        reviewStatus={absence.status}
                        onlyReject={!user.administrationAccess && !user.superAdminStatus}
                      />
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </section>
  );
}
