import React, { useState, useEffect } from 'react';
import Button from 'library/common/commonComponents/Buttons/Button';
import SettingsPopup from 'modules/Administration/administrationFrames/FormsFrame/FormsFrameFrames/FormsOverview/FormsOverviewFrames/SettingsPopup';
import { getForms } from "library/api/formEditor";
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { useTranslation } from 'react-i18next';
import { settingsChangedSignal } from "library/common/commonActions/formEditorActions";
import { getSettings, saveSettings } from "library/api/formEditor";

export default function DefaultContractChangePopup({ settingsOpen, setSettingsOpen }) {

  const { t } = useTranslation();
  const [forms, setForms] = useState([]);
  const [allowParentsFillOut, setAllowParentsFillOut] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [settings, setSettings] = useState(null);

  const [defaultContractForm, setDefaultContractForm] = useState(null);

  useEffect(() => {
    reloadForms();
    reloadSettings();
  }, []);




  const reloadForms = () => {
    setIsSavingSettings(true);
    getForms().then(res => {
      setForms(res.data);
    }).catch(err => {
      store.dispatch(
        showBottomNotification(
          t('FormsEditor.LoadFormsError'),
          { isFail: true },
        ),
      );
    }).finally(() => {
      setIsSavingSettings(false);
    });
  };


  const reloadSettings = () => {
    getSettings().then(res => {
      setSettings(res.data);
      resetSettingsInputs(res.data);
    });
  };

  const resetSettingsInputs = (s) => {
    if (!s || !s.allowParentsFillOut) {
      setAllowParentsFillOut(false);
    }
    else {
      setAllowParentsFillOut(s.allowParentsFillOut);
    }
    if (!s || !s.defaultContractForm) {
      setDefaultContractForm({
        value: -1,
        label: t('Administration.FormsSettingsDefaultContractFormNone'),
      });
    }
    else {
      setDefaultContractForm({
        value: s.defaultContractForm.id,
        label: s.defaultContractForm.name,
      });
    }
  };

  const doSaveSettings = () => {
    setIsSavingSettings(true);
    saveSettings(defaultContractForm === null ? null : defaultContractForm.value, allowParentsFillOut).then(() => {
      store.dispatch(
        showBottomNotification(
          t('Administration.FormsSettingsSaveSuccess'),
          { isFail: false },
        ),
      );
      reloadSettings();
      setSettingsOpen(false);
      store.dispatch(settingsChangedSignal());
    }).catch(() => {
      store.dispatch(
        showBottomNotification(
          t('Administration.FormsSettingsSaveError'),
          { isFail: true },
        ),
      );
    }).finally(() => {
      setIsSavingSettings(false);
      window.location.reload();
    });
  };


  return <>
    <div style={{ float: "right" }}>
      <Button onClick={() => setSettingsOpen(true)}><i className="fa fa-cog" /></Button>
    </div>

    <SettingsPopup
      isOpened={settingsOpen}
      closePopup={() => {
        setSettingsOpen(false);
        resetSettingsInputs(settings);
      }}
      onCancelClick={() => {
        setSettingsOpen(false);
        resetSettingsInputs(settings);
      }}
      onSaveClick={() => doSaveSettings()}
      forms={forms}
      setDefaultContractForm={setDefaultContractForm}
      defaultContractForm={defaultContractForm}
      allowParentsFillOut={allowParentsFillOut}
      setAllowParentsFillOut={setAllowParentsFillOut}
      isSavingSettings={isSavingSettings}
    />
  </>

}