import { SET_CHATS_LIST } from '../commonConstants/chatListPopupConstants';

const initialState = {
  chatsList: [],
};
const chatListPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATS_LIST:
      return {
        ...state,
        chatsList: action.payload,
      };
    default:
      return state;
  }
};
export default chatListPopupReducer;
